import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { Planning } from 'actions';
import { isGlobeInvestor } from 'utils/helpers';
import Modal from 'components/Modal';

@connect((state) => ({
  tableData: state.Planning.cppEstimator.tableData,
  partnerName: state.Storage['partner-name'],
}))
export default class TableModal extends Component {
  state = {
    isOpen: false,
  };

  componentDidMount = () => {
    const { dispatch } = this.props;

    return dispatch(Planning.getAverageEarnings());
  };

  render() {
    const { tableData, partnerName } = this.props;
    const { isOpen } = this.state;

    if (!tableData.length) {
      return null;
    }

    return (
      <span>
        <Trigger onClick={() => this.setState({ isOpen: true })}>table</Trigger>

        <Modal
          open={isOpen}
          style={{
            minWidth: !isGlobeInvestor(partnerName) && 900,
          }}
        >
          <Modal.Header>
            <Header size="medium" content="Canada National Annual Earnings" />
            <CloseIcon onClick={() => this.setState({ isOpen: false })}>
              +
            </CloseIcon>
          </Modal.Header>

          <Modal.Content style={{ display: 'flex', justifyContent: 'center' }}>
            <Table isGlobeInvestor={isGlobeInvestor(partnerName)}>
              <thead>
                <tr>
                  <th />
                  <th>Earnings</th>
                </tr>
              </thead>

              <tbody>
                {tableData.slice(0, 15).map((key) => (
                  <tr key={key.year}>
                    <td>{key.year}</td>
                    <td>
                      $
                      {key.earning.toLocaleString(undefined, {
                        maxFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Table isGlobeInvestor={isGlobeInvestor(partnerName)}>
              <thead>
                <tr>
                  <th />
                  <th>Earnings</th>
                </tr>
              </thead>

              <tbody>
                {tableData.slice(15, 30).map((key) => (
                  <tr key={key.year}>
                    <td>{key.year}</td>
                    <td>
                      $
                      {key.earning.toLocaleString(undefined, {
                        maxFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Table isGlobeInvestor={isGlobeInvestor(partnerName)}>
              <thead>
                <tr>
                  <th />
                  <th>Earnings</th>
                </tr>
              </thead>

              <tbody>
                {tableData.slice(30, 45).map((key) => (
                  <tr key={key.year}>
                    <td>{key.year}</td>
                    <td>
                      $
                      {key.earning.toLocaleString(undefined, {
                        maxFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Table isGlobeInvestor={isGlobeInvestor(partnerName)}>
              <thead>
                <tr>
                  <th />
                  <th>Earnings</th>
                </tr>
              </thead>

              <tbody>
                {tableData.slice(45).map((key) => (
                  <tr key={key.year}>
                    <td>{key.year}</td>
                    <td>
                      $
                      {key.earning.toLocaleString(undefined, {
                        maxFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div style={{ clear: 'both' }} />
          </Modal.Content>
        </Modal>
      </span>
    );
  }
}

TableModal.propTypes = {
  dispatch: PropTypes.func,
  tableData: PropTypes.array,
  partnerName: PropTypes.string,
};

TableModal.defaultProps = {
  dispatch: () => false,
  tableData: [],
  partnerName: '',
};

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 100;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

const Table = styled.table`
  text-align: center;
  padding: 0;
  float: left;
  margin: 0 0.5rem;
  width: ${(props) => (!props.isGlobeInvestor ? '200px' : '150px')};

  tr {
    width: 100%;
  }

  td,
  th {
    border: 1px solid #eaecef;
    padding: 6px 12px;

    &:first-child {
      color: rgba(0, 0, 0, 0.8);
      background: #fafbfc;
      font-weight: 700;
    }

    &:hover {
      background: #fafbfc;
    }
  }

  th {
    background: #fafbfc;
  }

  &.fixed {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 168px;
    background: white;
    padding-top: 79px;

    td {
      border-right: 1px solid #eaecef;
    }
  }
`;

const Trigger = styled.span`
  color: #2185d0;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
  }
`;
