import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Button, Modal } from 'components';

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))
export default class EditModal extends Component {
  constructor(props) {
    super(props);

    this.state = !props.portfolio
      ? {
          name: '',
          description: '',
        }
      : {
          name: props.portfolio.name || '',
          description: props.portfolio.description || '',
        };
  }

  componentWillReceiveProps = (nextProps) =>
    this.setState({
      name: nextProps.portfolio.name,
      description: nextProps.portfolio.description,
    });

  handleChange = (e) => {
    const { name, value } = e.target;

    if (
      (name === 'description' && value.length < 40) ||
      (name === 'name' && value.length < 60)
    ) {
      return this.setState({ [name]: value });
    }

    return false;
  };

  updatePortfolio = () => {
    const { portfolio, dispatch } = this.props;
    const { region, symbols, weights, gic_info } = portfolio;
    const { name, description } = this.state;

    const body = { name, description, region, symbols, weights, gic_info };

    this.setState({ loading: true });

    return dispatch(Portfolio.update(portfolio.id, body)).then((res) => {
      if (res && res.err) {
        return this.setState({ error: res.err, loading: false });
      }

      return this.closeModal();
    });
  };

  closeModal = () => {
    const { close, portfolio } = this.props;

    this.setState({
      loading: false,
      name: portfolio.name,
      description: portfolio.description,
      error: false,
    });

    return close();
  };

  render() {
    const { open, offsetY, partnerName } = this.props;
    const { error, loading, name, description } = this.state;

    return (
      <Modal
        basicFlex
        open={open}
        dimmerClick={this.closeModal}
        offsetY={offsetY}
        vb={notWealthica(partnerName)}
      >
        <Modal.Header>
          <Header
            icon="pencil"
            content="Update Portfolio"
            style={{ color: colors.blue }}
          />
        </Modal.Header>
        <Modal.Content>
          <Field vb={notWealthica(partnerName)}>
            Name:
            <input
              name="name"
              value={name}
              onChange={this.handleChange}
              placeholder="(required)"
            />
          </Field>
          <Field vb={notWealthica(partnerName)}>
            Description:
            <input
              name="description"
              value={description}
              onChange={this.handleChange}
              placeholder="(optional)"
            />
          </Field>
          {error && <Error>{error}</Error>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: loading }
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: !name.trim(), loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={this.updatePortfolio}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  portfolio: PropTypes.object,
  dispatch: PropTypes.func,
  offsetY: PropTypes.number,
  partnerName: PropTypes.string,
};

EditModal.defaultProps = {
  portfolio: {},
  dispatch: () => false,
  offsetY: 0,
  partnerName: '',
};

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;

const Error = styled.div`
  color: ${colors.red};
  font-weight: 700;
  font-size: 1.14rem;
  padding: 30px 0 10px 0;
`;
