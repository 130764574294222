import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Icon, Header, Statistic, Divider } from 'semantic-ui-react';
import cn from 'classnames';
import { Alert, Planning, Api } from 'actions';
import { colors } from 'utils/colors';
import { Button, Message, Selection } from 'components';

class OasEstimator extends Component {
  state = {
    oasThreshold: null,
  };

  componentDidMount = () => {
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown || {};
    const { oasAge, oasAge2, resident_years, resident_years2, activeSpouse } =
      inputs || {};

    dispatch(Planning.oasUpdateInput({ field: 'oasAge', value: oasAge }));
    dispatch(Planning.oasUpdateInput({ field: 'oasAge2', value: oasAge2 }));
    dispatch(
      Planning.oasUpdateInput({
        field: 'years',
        value:
          activeSpouse === 'spouse'
            ? Number(resident_years)
            : Number(resident_years2),
      })
    );

    return dispatch(Api.request('get', `/planning/oas-threshold/`)).then(
      (data) =>
        this.setState({
          oasThreshold: data[data.length - 1],
        }),
      (err) =>
        dispatch(
          Alert.show({
            type: 'error',
            msg: err.non_field_errors
              ? err.non_field_errors[0]
              : err.msg || err.detail || 'Server Offline',
          })
        )
    );
  };

  componentWillUnmount = () => this.props.dispatch(Planning.oasClearErrors());

  changeSelection = (value) => {
    const { dispatch } = this.props;
    dispatch(Planning.oasChangeEligible(value));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown;
    const field =
      inputs.activeSpouse === 'spouse2' && name === 'oasAge' ? 'oasAge2' : name;

    return dispatch(Planning.oasUpdateInput({ field, value }));
  };

  submit = (e) => {
    e.preventDefault();
    const { dispatch, oasEstimator, drawdown } = this.props;
    const { years, oasAge, oasAge2 } = oasEstimator;
    const { activeSpouse } = drawdown.inputs;
    const activeOasAge = activeSpouse === 'spouse2' ? oasAge2 : oasAge;

    if (!years.value || Number(years.value) < 10) {
      return dispatch(
        Planning.oasUpdateInput({
          field: 'years',
          value: years.value,
          error:
            'To begin collecting OAS you must have lived in Canada for at least 10 years',
        })
      );
    }

    if (!activeOasAge.value || Number(activeOasAge.value) < 65) {
      return dispatch(
        Planning.oasUpdateInput({
          field: activeSpouse === 'spouse2' ? 'oasAge2' : 'oasAge',
          value: activeOasAge.value,
          error: 'You may not start collecting OAS before 65',
        })
      );
    }

    return dispatch(
      Planning.calcOas({
        resident_years: years.value,
        is_resident_10: true,
        start_age: activeOasAge.value,
      })
    );
  };

  render() {
    const { dispatch, oasEstimator, drawdown } = this.props;
    const { isFetching, result, years, years2, oasAge, oasAge2, eligible } =
      oasEstimator;
    const { activeSpouse } = drawdown.inputs;
    const { oasThreshold } = this.state;

    const activeOasAge = activeSpouse === 'spouse2' ? oasAge2 : oasAge;
    const activeYears = activeSpouse === 'spouse2' ? years2 : years;

    return (
      <div>
        <Header
          size="large"
          textAlign="center"
          style={{ marginBottom: '2rem' }}
        >
          OAS Estimator
          <Header.Subheader style={{ marginTop: '1rem', textAlign: 'left' }}>
            If you have resided in Canada for at least 10 years since the age of
            18, you may be eligible to receive an OAS pension.
          </Header.Subheader>
          <Header.Subheader style={{ marginTop: '2rem', textAlign: 'left' }}>
            You can take a standard pension at age 65, or an increased pension
            after age 65. If you take your pension late, your monthly payment
            amount will increase by 0.6% per month after age 65 (but before age
            70), or 7.2% per year. This means that an individual who starts
            receiving OAS benefits at the age of 70 will receive 36% (7.2% x 5)
            more per year for life than if it was taken at age 65.&nbsp;
            <Popup
              trigger={
                <Icon
                  name="info circle"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="bottom center"
              hoverable
              wide
            >
              For your information, there is an OAS clawback (called a recovery
              tax) of 15% if your net income exceeds a certain level ($
              {oasThreshold && oasThreshold.min_income.toLocaleString()} in{' '}
              {oasThreshold && oasThreshold.income_year}). If your net income is
              higher than $
              {oasThreshold && oasThreshold.max_income.toLocaleString()} ( $
              {oasThreshold && oasThreshold.max_income75.toLocaleString()} if
              you are age 75 or older), you are not eligible for OAS pension.
              For more information, see{' '}
              <a
                href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/recovery-tax.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </Popup>
          </Header.Subheader>
        </Header>
        <Divider />
        <Form>
          <Selection
            style={{ top: 0 }}
            text="Between the ages of 18 and 65 will you have lived in Canada for at least 10 years?"
            selected={eligible}
            changeSelection={this.changeSelection}
          />

          <Message icon visible={!eligible} warning>
            <Icon name="warning circle" style={{ fontSize: '2rem' }} />
            <Message.Content style={{ textAlign: 'center' }}>
              Unfortunately, you are not eligible to receive OAS pension.
            </Message.Content>
          </Message>

          {eligible && (
            <div>
              <Field>
                <label>
                  How many years?
                  <Popup
                    wide
                    hoverable
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content={
                      <div>
                        Note that to receive the full pension amount, there may
                        be additional residency requirements for the 10 years
                        immediately before you apply for OAS pension. See full
                        details{' '}
                        <a
                          href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/benefit-amount.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        .
                      </div>
                    }
                  />
                </label>
                <input
                  // className={cn({ invalid: years.error })}
                  name="years"
                  type="number"
                  placeholder="years"
                  // value={years.value || ''}
                  onChange={this.handleChange}
                />
              </Field>

              <Field>
                <label>
                  When do you plan to start collecting OAS pension? The earliest
                  is age 65, and the latest is age 70.
                </label>
                <input
                  // className={cn({ invalid: activeOasAge.error })}
                  name="oasAge"
                  type="number"
                  placeholder="years"
                  // value={activeOasAge.value || ''}
                  onChange={this.handleChange}
                />
              </Field>

              {result ? (
                <Statistic
                  size="small"
                  style={{ margin: '2rem auto 0', width: '100%' }}
                >
                  <Statistic.Label>
                    Your OAS pension per year, in today's dollars, is estimated
                    to be
                  </Statistic.Label>
                  <Statistic.Value style={{ position: 'relative' }}>
                    <Icon name="dollar" />
                    {Math.round(result).toLocaleString()}
                  </Statistic.Value>
                </Statistic>
              ) : null}
              {/* (
                <Error visible={years.error || activeOasAge.error}>
                  {years.error || activeOasAge.error}
                </Error>
              )} */}

              <Button
                className={cn('left', { loading: isFetching })}
                color={result ? colors.green : colors.blue}
                icon={result ? 'checkmark' : 'calculator'}
                onClick={
                  result
                    ? () => {
                        this.props.updateOasInfo(
                          activeOasAge.value,
                          Math.round(result)
                        );
                        this.props.toggle(false);

                        dispatch(
                          Planning.drawdownUpdateInput({
                            inputs: {
                              resident_years: activeYears.value,
                            },
                          })
                        );
                      }
                    : this.submit
                }
                style={{ display: 'block', margin: '2rem auto 0' }}
              >
                {result ? 'Confirm' : 'Calculate'}
              </Button>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

OasEstimator.propTypes = {
  dispatch: PropTypes.func,
  oasEstimator: PropTypes.object,
  updateOasInfo: PropTypes.func,
  toggle: PropTypes.func,
  drawdown: PropTypes.object,
};

OasEstimator.defaultProps = {
  dispatch: () => false,
  oasEstimator: {},
  updateOasInfo: () => false,
  toggle: () => false,
  drawdown: {},
};

export default connect((state) => ({
  oasEstimator: state.Planning.oasEstimator,
  drawdown: state.Planning.drawdown,
}))(OasEstimator);

const Form = styled.form`
  position: relative;
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
`;

const Field = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 32px;

  label {
    display: block;
    width: 100%;
    max-width: 80%;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 73px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const Error = styled.div`
  margin: 0.5rem 0;
  color: ${colors.red};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  text-align: center;
  font-weight: 700;
`;
