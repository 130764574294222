import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';
import { Header, Grid, Divider, Flag, Popup, Icon } from 'semantic-ui-react';

import { Accounts, Alert, Portfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import { Segment, Button } from 'components';
import { getHoldTotalMarketValue, hasNegativeWeight } from 'utils/helpers';
import { HoldingTable, GicTable, AssetChart } from '../components';

class MultipleAccount extends Component {
  componentDidMount = () => {
    const { selectedAccountList, history } = this.props;

    if (selectedAccountList.length === 0) {
      history.push('/accounts/user');
    }
  };

  getFlag = (currency) => (currency === 'USD' ? 'us' : 'ca');

  round = (num) => (!num ? 0 : Math.round(num * 10000) / 10000);

  analyze = (holdings, region) => {
    const { dispatch, history, selectedAccountList } = this.props;
    const gicHoldings = holdings.filter((holding) => holding.gic_ticker);
    const concatAccountNames = selectedAccountList
      .map((acc) => acc.user_institution)
      .join(' - ');

    return dispatch(
      Accounts.analyze(
        holdings,
        history,
        { user_institution: concatAccountNames },
        gicHoldings,
        region
      )
    );
  };

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  render() {
    const {
      dispatch,
      history,
      selectedAccountOverview,
      selectedAccountList,
      pwpaExchangeRate,
      user_region,
    } = this.props;
    const validHoldings =
      selectedAccountOverview !== null
        ? selectedAccountOverview.holdings.filter(
            (holding) => !!holding.security_detail
          )
        : null;
    const usdHolding = validHoldings.find((holding) => {
      const price = holding.security_detail
        ? holding.security_detail.unadjusted_closing_price.USD
        : holding.unadjusted_closing_price.USD;

      return holding.default_currency === 'USD' && !!price;
    });
    const usdAccount = selectedAccountList.find(
      (account) => account.currency === 'USD'
    );
    const excludeGicHoldings =
      selectedAccountOverview &&
      selectedAccountOverview.holdings.filter((holding) => !holding.gic_ticker);
    const gicHoldings =
      selectedAccountOverview &&
      selectedAccountOverview.holdings.filter((holding) => holding.gic_ticker);
    const hasNegativeWeights = hasNegativeWeight(
      selectedAccountOverview.holdings
    );

    return (
      <div>
        {selectedAccountOverview !== null && selectedAccountList.length !== 0 && (
          <Segment>
            <Header
              size="large"
              content="Grouped Portfolios"
              subheader={`${selectedAccountList.length} portfolios selected`}
            />
            <Divider fitted />
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={7}>
                  <div style={{ marginTop: '1.3rem' }}>
                    <div style={{ float: 'left', padding: '0 2rem 1rem 0' }}>
                      <Header
                        size="medium"
                        content="Account Value:"
                        sub
                        style={{ margin: 0, fontWeight: 700 }}
                      />
                      <Value className="main">
                        {`$${this.parseNum(selectedAccountOverview.balance)}`}
                        <Flag
                          name={
                            user_region === 'US'
                              ? 'us'
                              : this.getFlag(selectedAccountOverview.currency)
                          }
                          style={{
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: 7,
                          }}
                        />
                      </Value>
                    </div>

                    <div style={{ float: 'left', padding: '0 0 1rem 0' }}>
                      <Header
                        size="medium"
                        content="Daily Change:"
                        sub
                        style={{ margin: 0 }}
                      />
                      <Value>{`${selectedAccountOverview.change || 0}%`}</Value>
                    </div>
                  </div>

                  <div style={{ clear: 'both' }}>
                    <Header
                      size="medium"
                      content="Holdings Value:"
                      sub
                      style={{ margin: 0 }}
                    />
                    <Value>{`$${this.parseNum(
                      selectedAccountOverview.balance -
                        selectedAccountOverview.cash
                    )}`}</Value>
                  </div>
                  <div style={{ marginTop: '1.3rem' }}>
                    <Header
                      sub
                      size="medium"
                      style={{ margin: 0 }}
                      content={<div>cash value:</div>}
                    />
                    <Value>{`$${this.parseNum(
                      selectedAccountOverview.cash
                    )}`}</Value>
                  </div>
                </Grid.Column>
                <Grid.Column width={9}>
                  {!selectedAccountOverview.holdings.length ||
                  !validHoldings.length ? null : (
                    <Segment basic compact>
                      <Header
                        size="medium"
                        textAlign="center"
                        style={{ margin: '0 0 1rem 0' }}
                      >
                        Holdings by Asset Class&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content="This is your net asset allocation, reflecting any balanced funds/asset allocation funds that you may have."
                          wide
                        />
                      </Header>
                      <AssetChart
                        accounts={selectedAccountList}
                        overview={selectedAccountOverview}
                      />
                    </Segment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {hasNegativeWeights ? (
              getHoldTotalMarketValue(selectedAccountOverview) < 0 ? (
                <p
                  style={{
                    color: `${colors.red}`,
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '2rem',
                    right: '15rem',
                  }}
                >
                  <Icon name="warning" style={{}} />
                  Analysis will not be performed for net short portfolio(s).
                </p>
              ) : (
                <p
                  style={{
                    color: `${colors.red}`,
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '2rem',
                    right: '15rem',
                  }}
                >
                  <Icon name="warning" style={{}} />
                  Note that short positions will be excluded from the analysis.
                </p>
              )
            ) : null}
            <Button
              className={cn('left', {
                disabled: getHoldTotalMarketValue(selectedAccountOverview) < 0,
              })}
              icon="line chart"
              color={colors.darkBlue}
              onClick={() => {
                dispatch(
                  Storage.setItem('toggle_region', this.props.user_region)
                );
                this.analyze(selectedAccountOverview.holdings, user_region);
                dispatch(Portfolio.notSaveUrlSc());
              }}
              style={{
                position: 'absolute',
                top: '1.6rem',
                right: '5rem',
                zIndex: 3,
              }}
            >
              Analyze
            </Button>
            <MoreActions>
              <div className="trigger">...</div>
              <div className="action-item">
                <Button
                  className="left"
                  color={colors.red}
                  style={{
                    backgroundColor: 'white',
                    display: 'block',
                    width: '100%',
                    margin: '5px',
                  }}
                  icon="law"
                  onClick={() => {
                    if (excludeGicHoldings.length !== 0) {
                      history.push('/accounts/multiple/compare');
                    } else {
                      dispatch(
                        Alert.show({
                          type: 'warning',
                          msg: 'Not enough matched holdings to compare this account',
                        })
                      );
                    }
                  }}
                >
                  What-if
                </Button>
                <Button
                  className="left"
                  color={colors.red}
                  style={{
                    backgroundColor: 'white',
                    display: 'block',
                    width: '100%',
                    margin: '5px',
                  }}
                  icon="money"
                  onClick={() => history.push('/accounts/multiple/cash')}
                >
                  Cash
                </Button>
              </div>
            </MoreActions>
            <div style={{ clear: 'both' }} />

            <HoldingTable
              accountList={selectedAccountList}
              totalMarketValue={
                selectedAccountOverview.balance - selectedAccountOverview.cash
              }
              holdings={excludeGicHoldings.slice(0, 10)}
              accountName=""
              title="Top 10 Holdings"
              rate={pwpaExchangeRate}
              user_region={user_region}
            />
            <GicTable
              user_region={user_region}
              gicHoldings={gicHoldings}
              totalMarketValue={
                selectedAccountOverview.balance - selectedAccountOverview.cash
              }
              title="GIC/HISAs"
              rate={pwpaExchangeRate}
            />
            {excludeGicHoldings.length !== 0 && (
              <P>
                {user_region === 'CA' ? (
                  <React.Fragment>
                    Currently, we have data for Canadian and US stocks/ETFs,
                    Canadian mutual and segregated funds, and CAD/USD HISA
                    funds.
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{ fontSize: '15px', verticalAlign: 'initial' }}
                        />
                      }
                      position="top center"
                      content="CAD/USD HISA funds are included using the historical rates of a representative bank-owned CAD/USD HISA fund."
                    />
                    <br />
                    Any other securities will be excluded from the analysis.
                  </React.Fragment>
                ) : (
                  'Currently we only have data for U.S. listed stocks and ETFs, U.S. mutual funds, Bitcoin, and Ethereum. Any other security will be excluded from the analysis.'
                )}
              </P>
            )}
            {(!!usdHolding || !!usdAccount) &&
              excludeGicHoldings.length !== 0 &&
              user_region === 'CA' && (
                <P>USD/CAD exchange rate used is {pwpaExchangeRate || 1.3}</P>
              )}
          </Segment>
        )}
      </div>
    );
  }
}

MultipleAccount.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  selectedAccountList: PropTypes.array,
  selectedAccountOverview: PropTypes.object,
  pwpaExchangeRate: PropTypes.number,
  user_region: PropTypes.string,
};

MultipleAccount.defaultProps = {
  dispatch: () => {},
  history: {},
  selectedAccountOverview: {},
  selectedAccountList: [],
  pwpaExchangeRate: 0,
  user_region: 'CA',
};

export default connect((state) => ({
  selectedAccountList: state.Accounts.selectedAccountList,
  selectedAccountOverview: state.Accounts.selectedAccountOverview,
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region || 'CA',
}))(MultipleAccount);

const Value = styled.div`
  font-size: 1.8rem;
  padding: 7px 0;

  &.main {
    font-size: 2.4rem;
    font-weight: 700;
    color: ${colors.orange};
  }
`;

const MoreActions = styled.div`
  .action-item {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 2rem 0.5rem 0.5rem 0.5rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, top 150ms ease;

    &:hover {
      opacity: 1;
      visibility: visible;
      top: 40px;
    }
  }

  .trigger {
    z-index: 2;
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    font-size: 30px;
    background: white;
    color: rgba(0, 0, 0, 0.6);
    height: 35px;
    width: 35px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
      border: 1px solid ${colors.darkBlue};
      color: ${colors.darkBlue};

      & ~ .action-item {
        opacity: 1;
        visibility: visible;
        top: 40px;
      }
    }
  }

  &:hover ~ .trigger {
    border: 1px solid ${colors.darkBlue};
    color: ${colors.darkBlue};
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  margin: 0.7rem auto;
  max-width: 100%;
  line-height: 1.1rem;
  text-align: center;
`;
