import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { decideCurrency } from 'utils/helpers';
import { Dropdown, Header, Icon, Popup } from 'semantic-ui-react';
import { nbGraph, nbColors } from 'utils/colors';
import styled from 'styled-components';
import { Analytics } from 'actions';
import { PortfolioHistoryChart } from '../../../../../components';
import { translateBenchmarkDescription } from '../../../../../utils/helpers';

@connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))
class PortfolioHistory extends Component {
  state = {
    compare: this.props.compare,
    displayPortfolio: true,
    displayCompare: true,
  };

  shouldDrawBar = () => {
    const { data } = this.props;

    return (
      data &&
      data.crisisRets &&
      Object.values(data.crisisRets).reduce((result, event) => {
        result = result || Object.keys(event.rets).length;

        return result;
      }, false)
    );
  };

  parseDate = (portfolio, short) => {
    const { french } = this.props;
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = french
      ? {
          '01': 'Janvier',
          '02': 'Février',
          '03': 'Mars',
          '04': 'Avril',
          '05': 'Mai',
          '06': 'Juin',
          '07': 'Juillet',
          '08': 'Août',
          '09': 'Septembre',
          10: 'Octobre',
          11: 'Novembre',
          12: 'Décembre',
        }
      : {
          '01': 'January',
          '02': 'February',
          '03': 'March',
          '04': 'April',
          '05': 'May',
          '06': 'June',
          '07': 'July',
          '08': 'August',
          '09': 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };

    return short
      ? `${init[1]}/${init[0]} - ${end[1]}/${end[0]}`
      : `${french ? 'de ' : ''}${months[init[1]]} ${init[0]} ${
          french ? 'à' : 'to'
        } ${months[end[1]]} ${end[0]}`;
  };

  handleChange = (e, { value }) => {
    this.setState({ compare: value });

    const { dispatch } = this.props;

    return dispatch(Analytics.updatePorfolioHistoryCompareto(value));
  };

  capitalize = (str) => str.substring(0, 1).toUpperCase() + str.slice(1);

  render() {
    const { data, benchmark, user_region, french } = this.props;
    const { compare, displayPortfolio, displayCompare } = this.state;

    return (
      <div style={{ clear: 'both' }}>
        <Header>
          <Header.Content>
            {french
              ? 'Historique hypothetique du portefeuille'
              : 'Hypothetical Portfolio History'}
          </Header.Content>
          <Header.Subheader>
            {french
              ? 'Selon la composition actuelle de votre portefeuille'
              : 'Based on the current composition of your portfolio'}
            &nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              content={
                french
                  ? 'En utilisant les mêmes avoirs et les mêmes pondérations.'
                  : 'Using the same holdings and the same weights.'
              }
              wide
            />
          </Header.Subheader>
          <Header.Subheader>
            {`${this.parseDate(data.portfolio)} ${
              french ? '(inclusivement) en' : '(inclusive) in'
            } ${decideCurrency(data, user_region || 'CA')}`}
          </Header.Subheader>
        </Header>
        <Dropdown
          button
          basic
          floating
          className="icon"
          labeled
          icon="law"
          text={
            <p>
              {french ? 'Comparer à : ' : 'Compare to: '}{' '}
              {french
                ? this.capitalize(compare) === 'Market'
                  ? 'Marché boursier'
                  : this.capitalize(compare) === 'Benchmark'
                  ? 'Référence'
                  : this.capitalize(compare)
                : this.capitalize(compare) === 'Market'
                ? 'Stock Market'
                : this.capitalize(compare)}
            </p>
          }
          options={[
            {
              key: 'mkt',
              value: 'market',
              text: french ? 'La bourse' : 'Stock Market',
            },
            {
              key: 'bmk',
              value: 'benchmark',
              text: french ? 'L’indice de référence' : 'Benchmark',
            },
          ]}
          onChange={this.handleChange}
        />

        <div style={{ textAlign: 'center', margin: '1rem 0 0 0' }}>
          <Note>
            {french
              ? 'Rendement total placements compris'
              : 'Total Returns including Distributions'}
          </Note>
          <Legend
            color={nbGraph.mediumDark}
            onClick={() =>
              this.setState({ displayPortfolio: !displayPortfolio })
            }
            lineThrough={!displayPortfolio}
          >
            {french ? 'Portefeuille' : 'Portfolio'}
          </Legend>
          <Legend
            color={nbColors.red}
            onClick={() => this.setState({ displayCompare: !displayCompare })}
            lineThrough={!displayCompare}
          >
            {french
              ? this.capitalize(compare) === 'Market'
                ? 'Marché boursier'
                : this.capitalize(compare) === 'Benchmark'
                ? 'Référence'
                : this.capitalize(compare)
              : this.capitalize(compare) === 'Market'
              ? 'Stock Market'
              : this.capitalize(compare)}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ fontSize: '1rem', opacity: '0.5' }}
                />
              }
              position="bottom center"
              wide
              content={
                compare === 'market'
                  ? translateBenchmarkDescription(
                      data.cumrets.market[`${benchmark}Desc`],
                      french
                    )
                  : translateBenchmarkDescription(
                      data.cumrets.benchmark[`${benchmark}Desc`],
                      french
                    )
              }
            />
          </Legend>
          {!!this.shouldDrawBar() && (
            <div style={{ fontSize: '1.1rem', color: 'rgba(0,0,0,.6)' }}>
              {french
                ? 'Glissez votre curseur sur les bandes grises pour voir l’historique des performances pendant les périodes de turbulences des marchés.'
                : 'Hover over the grey bands to see historical performances during periods of market turmoil.'}
            </div>
          )}
        </div>
        <PortfolioHistoryChart
          portfolioData={data}
          compare={compare}
          benchmark={benchmark}
          displayDataSet={{ displayPortfolio, displayCompare }}
        />
        <div
          style={{
            fontSize: '1.1rem',
            color: 'rgba(0,0,0,.6)',
            textAlign: 'center',
          }}
        >
          {french
            ? 'Ces dates représentent les fins de mois.'
            : 'These dates represent end-of-month values.'}
        </div>
      </div>
    );
  }
}

PortfolioHistory.propTypes = {
  data: PropTypes.object.isRequired,
  benchmark: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  compare: PropTypes.string.isRequired,
  user_region: PropTypes.string,
  french: PropTypes.bool,
};

PortfolioHistory.defaultProps = {
  user_region: 'CA',
  french: false,
};

export default PortfolioHistory;

const Legend = styled.span`
  position: relative;
  color: black;
  font-size: 0.9rem;
  cursor: default;
  width: 100px;
  margin: 0 0 0 4rem;
  vertical-align: center;
  cursor: pointer;
  text-decoration: ${(props) => (props.lineThrough ? 'line-through' : 'none')};

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -3.2rem;
    height: 0.8rem;
    width: 2.8rem;
    background: ${(props) => props.color || 'grey'};
    vertical-align: middle;
  }
`;

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.5rem auto;
`;
