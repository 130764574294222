import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Icon,
  Popup,
  Header,
  Button as SemanticButton,
} from 'semantic-ui-react';
import cn from 'classnames';

import { RebalanceAccount } from 'actions';
import { colors } from 'utils/colors';
import { convertToTickersAndWeights } from 'utils/helpers';
import { Message, Table, Button, Segment } from 'components';
import Weight from './Weight';
import Deviation from './Deviation';
import SaveModal from './SaveModal';
import RebalanceModal from './RebalanceModal';
import DeleteModal from './DeleteModal';

@connect((state) => ({
  rebalanceAcc: state.RebalanceAccount,
  french: state.Storage.language === 'fr',
}))
export default class RebalanceAccountView extends Component {
  state = {
    isSaveModalOpen: false,
    isDeleteModalOpen: false,
    isDescriptionVisible: !this.props.account.rebal_set,
    isRebalanceModalOpen: false,
    cashAmount: 0,
    isDeposit: true,
    useCash: false,
  };

  componentWillMount = () => {
    const { dispatch, match, rebalanceAcc } = this.props;
    const { id } = match.params;

    if (
      Number(id) &&
      (!rebalanceAcc.holdings || !Object.keys(rebalanceAcc.holdings).length)
    ) {
      return dispatch(RebalanceAccount.fetchId(id));
    }

    return false;
  };

  componentWillUnmount = () => {
    const { dispatch, history } = this.props;

    return history.listen(() => {
      if (history.action === 'POP') {
        dispatch(RebalanceAccount.removeAll());
      }
    });
  };

  getOriginalWeight = (ticker) => {
    const { weights, tickers } = this.props.rebalanceAcc.original;
    const originalWeights = weights.split(',');
    const originalTickers = tickers.split(',');
    const idx = originalTickers.findIndex((val) => val === ticker);

    return (Math.round(originalWeights[idx] * 100 * 100) / 100).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  };

  handleChange = (e) => this.setState({ cashAmount: e.target.value });

  round = (num) => Math.round(num * 100) / 100;

  rebalanceAccount = () => {
    const { isDeposit, cashAmount } = this.state;
    const { dispatch, rebalanceAcc } = this.props;
    const holdings = Object.values(rebalanceAcc.holdings).filter(
      (val) => !!val.security_detail
    );
    const { tickers, quantities } = convertToTickersAndWeights(holdings);
    const weights = tickers.split(',').reduce((acc, ticker) => {
      const w = rebalanceAcc.holdings[ticker].weight / 100;

      acc += acc.length ? `,${w}` : `${w}`;

      return acc;
    }, '');

    const body = {
      tickers,
      quantities,
      target_weights: weights,
      cash_currency: 'CAD',
      cash_amount: isDeposit ? Math.abs(cashAmount) : 0 - Math.abs(cashAmount),
    };

    dispatch(RebalanceAccount.rebalance(body)).then(() =>
      this.setState({ isRebalanceModalOpen: true })
    );
  };

  isDisabled = () => {
    const { holdings } = this.props.rebalanceAcc;

    const totalWeight = Object.values(holdings).reduce(
      (acc, val) => acc + Number(val.weight),
      0
    );

    return totalWeight !== 100;
  };

  labelData = () => {
    const { holdings } = this.props.rebalanceAcc;
    const totalValue = Object.values(holdings).reduce(
      (total, value) => (total += Number(value.weight)),
      0
    );

    if (!totalValue || this.round(totalValue) === 100) {
      return false;
    }

    return {
      val: this.round(Math.abs(100 - totalValue)),
      msg: 100 - totalValue > 0 ? 'under' : 'over',
    };
  };

  render() {
    const { isDeposit, useCash, isDescriptionVisible, cashAmount } = this.state;
    const { account, match, rebalanceAcc, french } = this.props;
    const filteredHoldings = Object.values(rebalanceAcc.holdings).filter(
      (val) => !!val.security_detail
    );

    return (
      <div style={{ height: '100%' }}>
        <Segment
          margin="1rem"
          style={{ minHeight: '100%', textAlign: 'center' }}
        >
          <Header
            dividing
            size="large"
            content={account.user_institution}
            subheader={account.label}
          />
          <Header size="medium" content="Rebalancing System" />
          {french ? (
            <P>
              <strong>Attention : </strong>Nos alertes de rééquilibrage sont
              simplement un service de suivi, à titre informatif uniquement.
              Elles ne doivent pas être interprétées comme des instructions de
              négociation.
            </P>
          ) : (
            <P>
              <strong>Disclaimer:</strong> our rebalancing alerts are simply a
              monitoring service, for your information only. They should not be
              interpreted as instructions to trade.
            </P>
          )}

          <CollapseWrapper
            open={isDescriptionVisible}
            style={{ maxWidth: 870, margin: '2rem auto 0 auto' }}
          >
            <Header
              size="medium"
              content="Description"
              style={{
                position: 'absolute',
                top: isDescriptionVisible ? '-10px' : '5px',
                left: '10px',
                background: 'white',
                padding: '0 5px',
                transition: 'all 300ms ease',
              }}
            />
            <Toggle
              open={isDescriptionVisible}
              onClick={() =>
                this.setState({ isDescriptionVisible: !isDescriptionVisible })
              }
            >
              {french
                ? isDescriptionVisible
                  ? 'fermer'
                  : 'ouvrir'
                : isDescriptionVisible
                ? 'hide'
                : 'show'}
            </Toggle>

            <div style={{ padding: '2rem 1rem 1rem 2rem' }}>
              <OL>
                <li>
                  {french
                    ? 'Entrez les pondérations du modèle et l’écart maximal* pour chaque titre du compte. Sauvegardez vos paramètres.'
                    : 'Input the model weight and the maximum deviation* for each security in the account. Save your parameters.'}
                </li>
                <li>
                  {french
                    ? `Sur l’onglet Compte, le symbole ${(
                        <Icon name="random" style={{ marginRight: 0 }} />
                      )} apparaîtra pour indiquer qu’un système de rééquilibrage a été mis en place pour le compte. Le compte sera surveillé quotidiennement à l’aide des cours de clôture.`
                    : `
                  On the account tab, a{' '}
                  ${(
                    <Icon name="random" style={{ marginRight: 0 }} />
                  )} will appear
                  to indicate that a rebalancing system has been set up for the
                  account. The account will be monitored daily using closing
                  prices.`}
                </li>
                <li>
                  {french
                    ? `Vous verrez le symbole ${(
                        <Icon
                          name="exclamation triangle"
                          style={{ marginRight: 0 }}
                        />
                      )} sur l’onglet de votre compte lorsqu’un ou plusieurs titres dépassent leur écart maximal, ou lorsque des actifs ont été vendus et/ou ajoutés au compte.`
                    : `You will see a{' '}
                  ${(
                    <Icon
                      name="exclamation triangle"
                      style={{ marginRight: 0 }}
                    />
                  )}{' '}
                  on your account tab when one or more weights exceed their
                  maximum deviation, or when holdings in the account have been
                  sold and/or added.`}
                </li>
                <li>
                  {french
                    ? 'Si vous avez des liquidités à déployer ou à retirer, vous pouvez également utiliser ce système pour calculer les transactions nécessaires pour maintenir l’équilibre de votre compte.'
                    : 'If you have cash to deploy to or withdraw from the holdings, you can also use this system to calculate the trades required to keep your account balanced.'}
                </li>
              </OL>
              <P>
                {french
                  ? '*L’écart maximal définit les limites supérieure et inférieure de la pondération d’un titre dans le compte. Un écart maximal de 50 % pour une pondération cible de 40 % signifie qu’une alerte sera déclenchée si la pondération actuelle dépasse 60 % ou descend en dessous de 20 %.'
                  : '*Maximum deviation defines the upper and the lower bounds for a security’s weight in the account. A 50% maximum deviation for a model weight of 40% means that an alert will be triggered if the current weight goes above 60% or falls below 20%'}
              </P>
              <P>
                {french
                  ? 'Remarquez : Le choix d’un petit écart maximal entraînera de fréquentes alertes de rééquilibrage, ce qui peut s’avérer inutile, car il faut s’attendre à des fluctuations du marché à court terme.'
                  : 'Note: setting a small maximum deviation will lead to frequent rebalancing alerts, which may be unnecessary as short-term market fluctuations are to be expected.'}
              </P>
            </div>
          </CollapseWrapper>

          <Message warning icon visible={rebalanceAcc.needsUpdate}>
            <Message.Content>
              <p style={{ maxWidth: '100%' }}>
                {french
                  ? 'Votre compte a été modifié - certains avoirs ont été vendus et/ou ajoutés. Veuillez ajuster vos paramètres de rééquilibrage pour continuer avec ce service.'
                  : 'There has been a change in your account - some holdings were sold and/or added. Please reset your rebalancing parameters if you wish to continue this service.'}
              </p>
            </Message.Content>
          </Message>

          {!!filteredHoldings.length ? (
            <Segment className="basic compact">
              <div style={{ position: 'relative' }}>
                <Table textAlign="center" style={{ marginBottom: '0rem' }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        style={{ background: 'rgba(245,93,90,0.2)' }}
                      >
                        {french ? 'Symbole/Nom' : 'Ticker/Name'}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ background: 'rgba(245,93,90,0.2)' }}
                      >
                        {french ? 'Bourse' : 'Exchange'}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ background: 'rgba(245,93,90,0.2)' }}
                      >
                        {french ? 'Classe d’actifs' : 'Class'}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ background: 'rgba(245,93,90,0.2)' }}
                      >
                        {french ? 'Pondération actuelle' : 'Current weight'}
                        <Popup
                          trigger={<Icon name="question circle outline" />}
                          position="left center"
                          content={
                            french
                              ? 'Les pondérations sont arrondies au nombre entier le plus proche ; la somme pourrait ne pas correspondre précisément à 100 %.'
                              : 'Weights rounded to the nearest whole number; may not add up to 100% exactly.'
                          }
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ background: 'rgba(245,93,90,0.2)' }}
                      >
                        {french ? 'Pondération du modèle' : 'Model weight'}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ background: 'rgba(245,93,90,0.2)' }}
                      >
                        {french ? 'Écart maximal' : 'Max deviation'}
                        <Popup
                          trigger={<Icon name="question circle outline" />}
                          position="left center"
                          content={
                            french
                              ? 'Définit l’écart maximal permis avant qu’une alerte soit déclenchée. Par exemple, un écart maximal de 50 % pour une pondération cible de 40 % signifie qu’une alerte sera déclenchée si la pondération actuelle dépasse 60 % ou descend en dessous de 20 %.'
                              : 'This sets the maximum deviation allowed before an alert is triggered. For example, 50% maximum deviation for a model weight of 40% means that an alert will be triggered if the current weight goes above 60% or below 20%.'
                          }
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {filteredHoldings.map((holding) => (
                      <StyledRow
                        key={holding.security_detail.ticker}
                        style={{ height: 52 }}
                      >
                        <Table.Cell style={{ position: 'relative' }} width={5}>
                          {(holding.gic_ticker
                            ? holding.gic_ticker
                            : holding.security_detail.ticker) || 'N/A'}
                          <SecurityName>
                            {(holding.gic_ticker
                              ? holding.gic_name
                              : holding.security_detail.long_name) || 'N/A'}
                          </SecurityName>
                        </Table.Cell>
                        <Table.Cell>
                          {holding.security_detail.exchange_symbol || 'N/A'}
                        </Table.Cell>
                        <Table.Cell>
                          {holding.security_detail.global_asset_class || 'N/A'}
                        </Table.Cell>
                        <Table.Cell>
                          {this.getOriginalWeight(
                            holding.security_detail.ticker,
                            filteredHoldings
                          )}{' '}
                          %
                        </Table.Cell>
                        <Table.Cell width={2}>
                          {!!holding.security_detail.unadjusted_closing_price
                            .CAD ? (
                            <Weight
                              isEditing={rebalanceAcc.isEditing}
                              ticker={holding.security_detail.ticker}
                              value={holding.weight}
                            />
                          ) : (
                            'N/A'
                          )}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          {!!holding.security_detail.unadjusted_closing_price
                            .CAD ? (
                            <Deviation
                              isEditing={rebalanceAcc.isEditing}
                              ticker={holding.security_detail.ticker}
                              value={holding.deviation}
                            />
                          ) : (
                            'N/A'
                          )}
                        </Table.Cell>
                      </StyledRow>
                    ))}
                  </Table.Body>
                </Table>

                {!!this.labelData() && (
                  <Label>{`${this.labelData().val}% ${
                    this.labelData().msg
                  }`}</Label>
                )}
              </div>

              {rebalanceAcc.isEditing && (
                <Button
                  className={cn('left centered', {
                    loading: rebalanceAcc.isFetching,
                    disabled: this.isDisabled(),
                  })}
                  color={colors.red}
                  icon="checkmark"
                  onClick={() => {
                    if (window.parentIFrame) {
                      window.parentIFrame.scrollTo(0, 0);
                    } else {
                      window.scrollTo(0, 0);
                    }

                    this.setState({ isSaveModalOpen: true });
                  }}
                  style={{
                    display: 'inline-block',
                    minWidth: 120,
                    margin: '3rem 0 0 1rem',
                  }}
                >
                  {french ? 'Sauvegarder' : 'Save'}
                </Button>
              )}
              {!rebalanceAcc.isEditing && (
                <Button
                  className={cn('left centered', {
                    loading: rebalanceAcc.isFetching,
                  })}
                  color={colors.red}
                  icon="pencil"
                  onClick={() =>
                    this.props.dispatch(RebalanceAccount.initEditing())
                  }
                  style={{
                    display: 'inline-block',
                    minWidth: 120,
                    margin: '3rem 0 0 1rem',
                  }}
                >
                  {french ? 'Modifier' : 'Edit'}
                </Button>
              )}

              {rebalanceAcc.isSet && (
                <Button
                  className={cn('left centered', {
                    loading: rebalanceAcc.isFetching,
                  })}
                  color={colors.red}
                  icon="close"
                  onClick={() => this.setState({ isDeleteModalOpen: true })}
                  style={{
                    display: 'inline-block',
                    minWidth: 120,
                    margin: '3rem 0 0 1rem',
                  }}
                >
                  {french ? 'Supprimer' : 'Delete'}
                </Button>
              )}

              <Message
                error
                icon
                inlineButton
                visible={!rebalanceAcc.isBalanced}
                style={{ maxWidth: 550, margin: '3rem auto' }}
              >
                <Icon name="exclamation triangle" />
                <Message.Content>
                  <p>
                    {french
                      ? 'Votre compte est en déséquilibre.'
                      : 'Your account is out of balance.'}
                  </p>
                </Message.Content>
                <Message.Actions>
                  <Button
                    className="basic"
                    onClick={() => {
                      if (window.parentIFrame) {
                        window.parentIFrame.scrollTo(0, 0);
                      } else {
                        window.scrollTo(0, 0);
                      }

                      this.setState({ isRebalanceModalOpen: true });
                    }}
                  >
                    {french ? 'Suivant' : 'Next'}
                  </Button>
                </Message.Actions>
              </Message>

              {rebalanceAcc.isSet && rebalanceAcc.isBalanced && (
                <CollapseWrapper open={useCash}>
                  <Header
                    size="medium"
                    content={
                      french
                        ? 'Déployer / Retirer des liquidités?'
                        : 'Deploy / Withdraw Cash?'
                    }
                    style={{
                      position: 'absolute',
                      top: useCash ? '-10px' : '5px',
                      left: '10px',
                      background: 'white',
                      padding: '0 5px',
                      transition: 'all 300ms ease',
                      textAlign: 'left',
                      marginBottom: '16px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledButton
                        icon
                        onClick={() => this.setState({ useCash: !useCash })}
                        isOpen={!!useCash}
                      >
                        {useCash ? '-' : '+'}
                      </StyledButton>
                      {french
                        ? 'Déployer / Retirer des liquidités? '
                        : 'Deploy / Withdraw Cash? '}
                    </div>

                    <Header.Subheader style={{ paddingLeft: '2.8rem' }}>
                      {french
                        ? 'Calculez les transactions nécessaires si vous souhaitez ajouter ou retirer des fonds de ce compte.'
                        : 'Calculate the trades required if you want to add money to or take money out from this account.'}
                    </Header.Subheader>
                  </Header>

                  <div>
                    <Segment
                      basic
                      compact
                      style={{ textAlign: 'left', position: 'relative' }}
                    >
                      How much?
                      <Input
                        name="cashAmount"
                        type="number"
                        placeholder="$"
                        value={cashAmount || ''}
                        onChange={this.handleChange}
                      />
                      <OptionsWrapper style={{ top: 17 }}>
                        <div
                          className={cn('select', { active: isDeposit })}
                          onClick={() => this.setState({ isDeposit: true })}
                          style={{
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                          }}
                        >
                          {french ? 'Deployer' : 'Deploy'}
                        </div>
                        <div
                          className={cn('select', { active: !isDeposit })}
                          onClick={() => this.setState({ isDeposit: false })}
                          style={{
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                          }}
                        >
                          {french ? 'Retirer' : 'Withdraw'}
                        </div>
                      </OptionsWrapper>
                    </Segment>
                  </div>

                  <Button
                    className={cn('left centered', {
                      loading: rebalanceAcc.isFetching,
                    })}
                    color={colors.red}
                    icon="chart bar"
                    onClick={this.rebalanceAccount}
                  >
                    {french ? 'Afficher les transactions' : 'Show trades'}
                  </Button>
                </CollapseWrapper>
              )}
            </Segment>
          ) : (
            <Segment
              loading={rebalanceAcc.isFetching}
              className="basic compact"
            >
              <Header
                size="small"
                content={
                  french
                    ? 'Ce compte ne contient aucun titre'
                    : 'This account does not have any holdings'
                }
              />
            </Segment>
          )}

          <SaveModal
            open={this.state.isSaveModalOpen}
            closeModal={() => this.setState({ isSaveModalOpen: false })}
            holdings={filteredHoldings}
            accountId={match.params.id}
          />

          <DeleteModal
            open={this.state.isDeleteModalOpen}
            closeModal={() => this.setState({ isDeleteModalOpen: false })}
            accountId={match.params.id}
          />

          <RebalanceModal
            open={this.state.isRebalanceModalOpen}
            closeModal={() => this.setState({ isRebalanceModalOpen: false })}
            holdings={filteredHoldings}
            account={account}
          />
        </Segment>
      </div>
    );
  }
}

RebalanceAccountView.propTypes = {
  dispatch: PropTypes.func,
  rebalanceAcc: PropTypes.object,
  match: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  french: PropTypes.bool,
};

RebalanceAccountView.defaultProps = {
  dispatch: () => null,
  rebalanceAcc: {},
  french: false,
};

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative;

  .close {
    display: block;
    margin: -10px;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  margin-left: 20px;
  text-align: justify;
  max-width: 840px !important;
  margin: 10px auto;
  font-size: 1.2rem;
`;

const OL = styled.ol`
  display: block;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 800px !important;
  margin: 0 auto;

  li {
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

const Label = styled.div`
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  padding: 1px 20px;
  font-size: 0.9rem;
  background: ${colors.red};
  text-align: center;
  border-radius: 12px;
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 840px;
  padding: 0 0 1rem 0.2rem;
  padding-top: ${(props) => (props.open ? '2rem' : '4rem')};
  margin: 2rem auto 0 auto;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '1000px' : '57px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const Toggle = styled.div`
  position: absolute;
  top: ${(props) => (props.open ? '-11px' : '3px')};
  right: 10px;
  background: ${colors.red};
  color: white;
  padding: 1px 10px 3px 10px;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 11px;
`;

const Input = styled.input`
  margin-left: 1rem;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 11px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.red};
      color: white;
    }
  }
`;

const StyledButton = styled(SemanticButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem !important;
  min-width: 2rem !important; // Ensure the button has a fixed width
  padding: 0 !important; // Remove any default padding
  font-size: 1rem; // Adjust the font size if needed
  line-height: 1;
`;
