import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import cn from 'classnames';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
  isCppModalOpen: state.Planning.isCppModalOpen,
  isOasModalOpen: state.Planning.isOasModalOpen,
}))
export default class RetirementIncome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {},
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const {
      dispatch,
      drawdown,
      cppDataset,
      oasDataset,
      cppEstimator,
    } = this.props;
    const { inputs } = drawdown;
    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;
    if (
      (name === 'otherHorizon' && value.includes('.')) ||
      value.includes('-')
    ) {
      return;
    }

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
      ];

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) {
        return 70;
      }

      if (isOas && Number(age) < 65) {
        return 65;
      }

      if (Number(age) < 60) {
        return 60;
      } else if (Number(age) > 70) {
        return 70;
      }

      return age;
    };

    if (name === 'age') {
      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), true))
      ).amount;
      if (Number(value) < 60) {
        this.changeSelection('cppStarted', false);
        this.changeSelection('oasStarted', false);
      } else if (Number(value) < 65) {
        this.changeSelection('oasStarted', false);
        this.changeSelection('cppStarted', true);
      } else {
        this.changeSelection('oasStarted', true);
        this.changeSelection('cppStarted', true);
      }
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            age: parsedValue(name, value),
            ...(Number(value) > 60 && {
              cppAge: Number(value) >= 70 ? 70 : parsedValue(name, value),
            }),
            ...(Number(value) > 65 && {
              oasAge: Number(value) >= 70 ? 70 : parsedValue(name, value),
            }),
          },
          errors: {
            age: false,
            ...(Number(value) > 60 && inputs.cppAge && { cppAge: false }),
            ...(Number(value) > 65 && inputs.oasAge && { oasAge: false }),
            // ...(inputs.cppStarted &&
            //   inputs.cpp > cppMax && {
            //     cpp:
            //       'The benefit amount you entered exceeded the maximum for your current age.',
            //   }),
            ...(inputs.oasStarted &&
              inputs.oas > oasMax && {
                oas: 'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs.cppStarted && inputs.cpp <= cppMax && { cpp: null }),
            ...(inputs.oasStarted && inputs.oas <= oasMax && { oas: null }),
            ...(!inputs.cppStarted && { cppAge: null }),
            ...(!inputs.oasStarted && { oasAge: null }),
          },
          modified: true,
        })
      );
    }

    if (name === 'cppAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { cppAge: parsedValue(name, value), cpp: '' },
          errors: {
            cppAge:
              value < 60 || value > 70
                ? 'You can only start collecting CPP benefits between age 60 and age 70'
                : false,
            cpp: value < 60,
          },
          modified: true,
        })
      );
    }

    if (name === 'oasAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oasAge: parsedValue(name, value), oas: '' },
          errors: {
            oasAge:
              value < 65 || value > 70
                ? 'You can only start collecting OAS benefits between age 65 and age 70'
                : false,
            oas: value < 65,
          },
          modified: true,
        })
      );
    }

    if (name === 'cpp') {
      dispatch(
        Planning.drawdownUpdateInput({
          errors: { myCpp: false },
        })
      );
      if (inputs.oas) {
        dispatch(Planning.drawdownUpdateInput({ errors: { myOas: false } }));
      }
      if (!inputs.cppAge && inputs.cppStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();

      const body = {
        retirement_year:
          Number(currentYear) + Number(inputs.cppAge) - Number(inputs.age),
        start_cpp_age: inputs.age > 70 ? 70 : inputs.cppAge,
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs.cppAge !== '' && inputs.cppAge >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, cpp: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cpp: money },
            errors: { ...inputs.errors, cpp: false },
            modified: true,
          })
        );
      }
    }

    if (name === 'oas') {
      dispatch(
        Planning.drawdownUpdateInput({
          errors: { myOas: false },
        })
      );
      if (!inputs.oasAge && inputs.oasStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age > 70 ? 70 : inputs.age || 0 },
            modified: true,
          })
        );
      }

      if (!inputs.oasAge || inputs.oasAge > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs.oasAge < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs.oasAge)
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs.oasAge,
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, oas: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oas: money },
          errors: { ...inputs.errors, oas: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { ...inputs.errors, otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { ...inputs.errors, [name]: false },
        modified: true,
      })
    );
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;

    if (field === 'isRiskFree') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [field]: value,
            pctCapGain: 0,
            divYield: 0,
            pctEligibleDiv: 0,
            ...(!value && { portfolio: drawdown.inputs.portfolio }),
          },
          errors: {
            pctCapGain: false,
            divYield: false,
            pctEligibleDiv: false,
            portfolio: false,
            constantRets: false,
          },
          modified: true,
        })
      );
    }

    if (field === 'cppStarted') {
      if (drawdown.inputs.cppStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, cppAge: '', cpp: '' },
            errors: { cppAge: false, cpp: false },
            modified: true,
          })
        );

        return this.setState({ warning: { cpp: '' } });
      }
    }

    if (field === 'oasStarted') {
      if (drawdown.inputs.oasStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, oasAge: '', oas: '' },
            errors: { oasAge: false, oas: false },
            modified: true,
          })
        );

        return this.setState({ warning: { oas: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (portfolioId, modelType) => {
    const { portfolio } = this.props;

    return modelType === 'annuityRate'
      ? false
      : !portfolio.model
          .concat(portfolio.user)
          .find((port) => port.id === portfolioId);
  };

  render() {
    const { warning } = this.state;
    const { inputs, errors } = this.props;

    return (
      <div>
        <Section single>
          <div className="title">CPP/QPP</div>
          <ul>
            <li className="visible">
              <label>Have you started collecting CPP/QPP?</label>
              {inputs.cppStarted && (
                <Error
                  style={{
                    margin: 0,
                    paddingLeft: '1rem',
                    display: 'block',
                  }}
                  visible={typeof errors.cppAge === 'string'}
                >
                  {errors.cppAge}
                </Error>
              )}
              <OptionsWrapper>
                <div
                  className={cn('select', {
                    active: inputs.cppStarted,
                  })}
                  onClick={() => this.changeSelection('cppStarted', true)}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', {
                    active: !inputs.cppStarted,
                  })}
                  onClick={() => this.changeSelection('cppStarted', false)}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </li>

            <li className={cn({ visible: inputs.cppStarted })}>
              <label>
                Annual CPP/QPP benefits
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={
                    typeof warning.cpp === 'string' ||
                    typeof errors.cpp === 'string' ||
                    errors.myCpp
                  }
                >
                  {warning.cpp || errors.cpp}
                </Error>
              </label>
              <Input
                className={cn({
                  invalid: errors.cpp || errors.myCpp ? 'true' : undefined,
                })}
                name="cpp"
                type="number"
                placeholder="$"
                value={inputs.cpp || ''}
                onChange={this.handleChange}
                onFocus={() =>
                  this.setState({
                    warning: { ...this.state.warning, cpp: null },
                  })
                }
                onBlur={() =>
                  this.setState({
                    warning: { ...this.state.warning, cpp: null },
                  })
                }
                onClick={() =>
                  this.setState({
                    warning: { ...this.state.warning, cpp: null },
                  })
                }
              />
            </li>

            <li className={cn({ visible: !inputs.cppStarted })}>
              <label>
                At what age do you plan to start?
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content={
                    <div>
                      Read our article{' '}
                      <a
                        href="https://wealthscope.ca/cpp-benefits"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CPP/QPP Benefits: To Defer or Not to Defer
                      </a>
                    </div>
                  }
                  hoverable
                  wide
                />
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof errors.cppAge === 'string'}
                >
                  {errors.cppAge}
                </Error>
              </label>
              <Input
                className={cn('absolute', {
                  invalid:
                    errors.cppAge || (errors.myCpp && !inputs.cppAge)
                      ? 'true'
                      : undefined,
                })}
                name="cppAge"
                type="number"
                placeholder="years"
                value={inputs.cppAge || ''}
                onChange={this.handleChange}
              />
            </li>

            <li
              className={cn({
                visible: !inputs.cppStarted,
                invalid: errors.myCpp ? 'true' : undefined,
              })}
            >
              <label>
                Expected CPP/QPP annual benefits in today's dollars (
                <Span
                  style={{ color: '#4183C3' }}
                  onClick={() => this.props.toggleCppModal(true)}
                >
                  <b>CPP/QPP Estimator</b>
                </Span>
                )
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof warning.cpp === 'string'}
                >
                  {warning.cpp}
                </Error>
              </label>
              {!inputs.cppAge ? (
                <Popup
                  trigger={
                    <Input
                      name="cpp"
                      type="number"
                      placeholder="$"
                      value={inputs.cpp || ''}
                      onChange={this.handleChange}
                      style={{
                        backgroundColor: colors.lightGrey,
                        opacity: '.8',
                      }}
                      disabled
                    />
                  }
                  position="top center"
                  content="Please enter CPP/QPP start age."
                />
              ) : (
                <Input
                  className={cn({
                    invalid: errors.cpp || errors.myCpp ? 'true' : undefined,
                  })}
                  name="cpp"
                  type="number"
                  placeholder="$"
                  value={inputs.cpp || ''}
                  onChange={this.handleChange}
                  onFocus={() =>
                    this.setState({
                      warning: { ...this.state.warning, cpp: null },
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      warning: { ...this.state.warning, cpp: null },
                    })
                  }
                  onClick={() =>
                    this.setState({
                      warning: { ...this.state.warning, cpp: null },
                    })
                  }
                />
              )}
            </li>
          </ul>

          <div className="title">OAS</div>
          <ul>
            <li className="visible">
              <label>Have you started collecting OAS?</label>
              {inputs.oasStarted && (
                <Error
                  style={{
                    margin: 0,
                    paddingLeft: '1rem',
                    display: 'block',
                  }}
                  visible={typeof errors.oasAge === 'string'}
                >
                  {errors.oasAge}
                </Error>
              )}
              <OptionsWrapper>
                <div
                  className={cn('select', { active: inputs.oasStarted })}
                  onClick={() => this.changeSelection('oasStarted', true)}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', { active: !inputs.oasStarted })}
                  onClick={() => this.changeSelection('oasStarted', false)}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </li>

            <li className={cn({ visible: inputs.oasStarted })}>
              <label>
                Annual OAS benefits
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={
                    typeof warning.oas === 'string' ||
                    typeof errors.oas === 'string'
                  }
                >
                  {warning.oas || errors.oas}
                </Error>
              </label>
              <Input
                className={cn({
                  invalid: errors.oas || errors.myOas ? 'true' : undefined,
                })}
                name="oas"
                type="number"
                placeholder="$"
                value={inputs.oas || ''}
                onChange={this.handleChange}
                onFocus={() =>
                  this.setState({
                    warning: { ...this.state.warning, oas: null },
                  })
                }
                onBlur={() =>
                  this.setState({
                    warning: { ...this.state.warning, oas: null },
                  })
                }
                onClick={() =>
                  this.setState({
                    warning: { ...this.state.warning, oas: null },
                  })
                }
              />
            </li>

            <li
              className={cn({
                visible: !inputs.oasStarted,
                invalid: errors.myOas ? 'true' : undefined,
              })}
            >
              <label>
                At what age do you plan to start?
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof errors.oasAge === 'string'}
                >
                  {errors.oasAge}
                </Error>
              </label>
              <Input
                className={cn('absolute', {
                  invalid: errors.oasAge || errors.myOas ? 'true' : undefined,
                })}
                name="oasAge"
                type="number"
                placeholder="years"
                value={inputs.oasAge || ''}
                onChange={this.handleChange}
              />
            </li>

            <li className={cn({ visible: !inputs.oasStarted })}>
              <label>
                Expected OAS annual benefits in today's dollars (
                <Span
                  style={{ color: '#4183C3' }}
                  onClick={() => this.props.toggleOasModal(true)}
                >
                  <b>OAS Estimator</b>
                </Span>
                )
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof warning.oas === 'string'}
                >
                  {warning.oas}
                </Error>
              </label>
              {!inputs.oasAge ? (
                <Popup
                  trigger={
                    <Input
                      name="oas"
                      type="number"
                      placeholder="$"
                      value={inputs.oas || ''}
                      onChange={this.handleChange}
                      style={{
                        backgroundColor: colors.lightGrey,
                        opacity: '.8',
                      }}
                      disabled
                      className={cn({
                        invalid: errors.myOas ? 'true' : undefined,
                      })}
                    />
                  }
                  position="top center"
                  content="Please enter OAS start age."
                />
              ) : (
                <Input
                  className={cn({
                    invalid: errors.oas || errors.myOas ? 'true' : undefined,
                  })}
                  name="oas"
                  type="number"
                  placeholder="$"
                  value={inputs.oas || ''}
                  onChange={this.handleChange}
                  onFocus={() =>
                    this.setState({
                      warning: { ...this.state.warning, oas: null },
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      warning: { ...this.state.warning, oas: null },
                    })
                  }
                  onClick={() =>
                    this.setState({
                      warning: { ...this.state.warning, oas: null },
                    })
                  }
                />
              )}
            </li>
          </ul>

          <div>
            <div className="title">
              Annual employer DB pension (optional)
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="top center"
                content="Defined Benefits pension plan"
                wide
              />
            </div>
            <Input
              className={cn('large', {
                invalid: errors.pension ? 'true' : undefined,
              })}
              name="pension"
              type="number"
              placeholder="$"
              value={inputs.pension}
              onChange={this.handleChange}
            />

            <ul>
              <li className="visible">
                <label>Is this pension indexed to inflation?</label>
                <OptionsWrapper>
                  <div
                    className={cn('select', { active: inputs.pensionInf })}
                    onClick={() => this.changeSelection('pensionInf', true)}
                    style={{
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className={cn('select', { active: !inputs.pensionInf })}
                    onClick={() => this.changeSelection('pensionInf', false)}
                    style={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    No
                  </div>
                </OptionsWrapper>
              </li>
            </ul>
          </div>

          <div>
            <div className="title">
              Annual other taxable income (optional)
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="top center"
                content="This includes annuity and rental income. Dividends and interest income from non-registered accounts will be calculated below."
                wide
              />
            </div>
            <Input
              className={cn('large', {
                invalid: errors.other ? 'true' : undefined,
              })}
              name="other"
              type="number"
              placeholder="$"
              value={inputs.other}
              onChange={this.handleChange}
            />

            <ul>
              <li className="visible">
                <label>Is it indexed to inflation?</label>
                <OptionsWrapper>
                  <div
                    className={cn('select', { active: inputs.otherInf })}
                    onClick={() => this.changeSelection('otherInf', true)}
                    style={{
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className={cn('select', { active: !inputs.otherInf })}
                    onClick={() => this.changeSelection('otherInf', false)}
                    style={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    No
                  </div>
                </OptionsWrapper>
              </li>
              <li className="visible">
                <label>How long will it last?</label>
                <Input
                  className={cn({
                    invalid: errors.otherHorizon ? 'true' : undefined,
                  })}
                  name="otherHorizon"
                  type="number"
                  placeholder="years"
                  value={inputs.otherHorizon || ''}
                  onChange={this.handleChange}
                />
                <Error long visible={typeof errors.otherHorizon === 'string'}>
                  {errors.otherHorizon}
                </Error>
              </li>
            </ul>
          </div>
        </Section>
      </div>
    );
  }
}

RetirementIncome.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  cppEstimator: PropTypes.object,
  toggleOasModal: PropTypes.func,
  toggleCppModal: PropTypes.func,
  inputs: PropTypes.object,
};

RetirementIncome.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  cppDataset: [],
  oasDataset: [],
  cppEstimator: {},
  toggleOasModal: () => false,
  toggleCppModal: () => false,
  inputs: {},
};

const Section = styled.section`
  padding-left: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 0 auto 2rem auto;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    top: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }
  ol {
    position: relative;
    top: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Error = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 1rem;
  font-style: normal;
  color: ${colors.red};

  ${(props) =>
    props.long &&
    css`
      position: absolute;
      bottom: 0;
      left: 4px;
    `}
`;

const Span = styled.span`
  color: #4183c4;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
  }
`;
