import { FundAllocator } from 'actions';

const initialState = {
  isFetching: false,
  search: [],
  portfolio: {},
  cashAmount: 0.0,
  calculating: false,
  output: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FundAllocator.NEWACCOUNTSETU_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FundAllocator.FUNDALLOCATOR_ADD_SUCCESS:
      return !action.security
        ? { ...state, isFetching: false }
        : {
            ...state,
            isFetching: false,
            portfolio: {
              ...state.portfolio,
              [action.security.ticker]: {
                ...action.security,
                weight: action.weight || 0,
              },
            },
          };
    case FundAllocator.FUNDALLOCATOR_LOAD_PORTFOLIO:
      return {
        ...state,
        isFetching: false,
        portfolio: action.portfolio,
      };
    case FundAllocator.FUNDALLOCATOR_UPDATE_WEIGHT:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [action.ticker]: {
            ...state.portfolio[action.ticker],
            weight: action.value,
          },
        },
      };
    case FundAllocator.FUNDALLOCATOR_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        search: action.data,
      };
    case FundAllocator.FUNDALLOCATOR_REMOVE_SECURITY:
      return {
        ...state,
        isFetching: false,
        portfolio: Object.values(state.portfolio).reduce(
          (newPortfolio, item) => {
            if (item.ticker !== action.ticker) {
              newPortfolio[item.ticker] = item;
            }

            return newPortfolio;
          },
          {}
        ),
      };
    case FundAllocator.FUNDALLOCATOR_REMOVE_ALL:
      return {
        ...state,
        isFetching: false,
        portfolio: {},
      };
    case FundAllocator.FUNDALLOCATOR_CALCULATE_REQUEST:
      return {
        ...state,
        calculating: true,
      };
    case FundAllocator.FUNDALLOCATOR_CALCULATED:
      return {
        ...state,
        calculating: false,
        output: action.data,
      };
    case FundAllocator.FUNDALLOCATOR_CLEAR:
      return initialState;
    default:
      return state;
  }
}
