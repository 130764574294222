import { BuildETF } from 'actions';

const initialState = {
  isFetching: false,
  data: null,
  selectedAssets: {},
  initMonth: '04',
  initYear: '2007',
  oldestCommonInceptionDateYear: '2007',
  oldestCommonInceptionDateMonth: '04',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BuildETF.BUILDETF_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case BuildETF.BUILDETF_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        selectedAssets: action.selectedAssets,
      };
    case BuildETF.BUILDETF_TOGGLE_ASSET:
      return {
        ...state,
        selectedAssets: !state.selectedAssets[action.asset.symbol]
          ? { ...state.selectedAssets, [action.asset.symbol]: action.asset }
          : Object.values(state.selectedAssets).reduce((result, item) => {
              if (item.symbol !== action.asset.symbol) {
                result[item.symbol] = item;
              }

              return result;
            }, {}),
      };
    case BuildETF.BUILDETF_UPDATE_WEIGHT:
      return {
        ...state,
        selectedAssets: {
          ...state.selectedAssets,
          [action.symbol]: {
            ...state.selectedAssets[action.symbol],
            weight:
              Number(action.weight) > 100
                ? 100
                : Math.round(action.weight * 100) / 100,
          },
        },
      };
    case BuildETF.BUILDETF_UPDATE_OLDEST_COMMON_INCEPTION_DATE:
      return {
        ...state,
        oldestCommonInceptionDateYear: action.year,
        oldestCommonInceptionDateMonth: action.month,
        initYear: action.year,
        initMonth: action.month,
      };
    case BuildETF.BUILDETF_SET_INITMONTH:
      return {
        ...state,
        initMonth: action.month,
      };
    case BuildETF.BUILDETF_SET_INITYEAR:
      // if a user switches back to the oldestCommonInceptionDateYear ensure that
      // initMonth is actually valid. If it isn't then switch it back to the initialState.initMonth
      return {
        ...state,
        initYear: action.year,
        initMonth:
          action.year === state.oldestCommonInceptionDateYear &&
          state.initMonth < state.oldestCommonInceptionDateMonth
            ? state.oldestCommonInceptionDateMonth
            : state.initMonth,
      };
    case BuildETF.BUILDETF_CLEAR:
    case BuildETF.BUILDETF_FAIL:
      return initialState;
    default:
      return state;
  }
}
