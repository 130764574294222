import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Icon,
  Dropdown,
  Form,
  Input,
  Header,
  Popup,
  Radio,
} from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import { Accounts, CacheInputs, Planning, Portfolio } from 'actions';
import {
  AccountsDropdown,
  Message,
  Segment,
  ErrorLabel,
  Button,
} from 'components';
import Result from './Result';

class TargetSaving extends Component {
  componentWillMount = () => {
    const { dispatch, targetSavingInputs } = this.props;
    const { inf } = targetSavingInputs;

    dispatch(Portfolio.fetchModel());
    dispatch(Portfolio.fetchUser());
    dispatch(Accounts.fetchAll());
    if (!inf) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          inf: 2,
          errors: { inf: null },
        })
      );
    }

    return false;
  };

  getPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    const date = !port ? [0] : port.init_date.split('-');

    if (Number(date[0]) < Number(date[0]) - 5) {
      return false;
    }

    return `${date[1]}/${date[0]}`;
  };

  getNewPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    if (!port || !port.init_date) {
      return false;
    }
    const date = new Date(port.init_date);
    const today = new Date();
    const monthDifference =
      today.getMonth() -
      date.getMonth() +
      12 * (today.getFullYear() - date.getFullYear());
    if (monthDifference > 60) {
      return false;
    }

    const init = date.toDateString().toString().split(/\s+/);

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sept: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    return `${months[init[1]]} ${init[3]}`;
  };

  getDropOptions = () => {
    const { portfolios, user_region, partnerName } = this.props;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return portfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      text:
        user_region === 'US' || isGlobeInvestor(partnerName)
          ? this.nameForUsVersion(portf.name)
          : portf.name,
    }));
  };

  nameForUsVersion = (name) => {
    if (name === 'WS All-In') {
      return '100 Equities / 0 Bonds';
    } else if (name === 'WS Adventurous') {
      return '80 Equities / 20 Bonds';
    } else if (name === 'WS Classic') {
      return '60 Equities / 40 Bonds';
    } else if (name === 'WS Cautious') {
      return '40 Equities / 60 Bonds';
    } else if (name === 'WS Playing-It-Safe') {
      return '20 Equities / 80 Bonds';
    }
    return name;
  };

  handleSubmit = () => {
    const { dispatch, portfolios, targetSavingInputs } = this.props;
    const { targ, inf, init, horz, portID } = targetSavingInputs;

    if (this.hasEmptyValue(Object.keys({ targ, init, inf, horz, portID }))) {
      return false;
    }

    const portfolio = portfolios.find((item) => item.id === portID);
    const params = {
      symbols: portfolio.symbols,
      weights: portfolio.weights,
      region: portfolio.region,
      gic_info: portfolio.gic_info,
      init_date: portfolio.init_date,
      targ,
      inf: inf === 0 ? 0 : inf / 100 || 0.02,
      init,
      horz,
      is_targ_nominal: false,
    };

    return dispatch(Planning.analyzeSaving(params)).then(() =>
      this.scrollHandler(
        document.documentElement,
        document.getElementById('ref').offsetHeight,
        600
      )
    );
  };

  handlePortfolioChange = (e, { value }) => {
    const { dispatch, targetSavingInputs } = this.props;
    const { errors } = targetSavingInputs;

    dispatch(Planning.clearSectionData('savingResult'));

    return dispatch(
      CacheInputs.updateField('planning', 'target_saving', {
        portID: value,
        errors: { ...errors, portID: null },
      })
    );
  };

  isCalculateDisabled = () => {
    const { targetSavingInputs } = this.props;
    const { initFromAcc, initAccList } = targetSavingInputs;

    if (initAccList && initAccList.length === 0 && initFromAcc) return true;
    return false;
  };

  hasEmptyValue = (values) =>
    values.some((field) => {
      const { dispatch, targetSavingInputs } = this.props;

      if (!targetSavingInputs[field] && targetSavingInputs[field] !== 0) {
        return dispatch(
          CacheInputs.updateField('planning', 'target_saving', {
            errors: {
              [field]:
                field === 'portID'
                  ? 'Please select a portfolio'
                  : 'This field is required',
            },
          })
        );
      }

      return false;
    });

  scrollHandler = (element, to, duration) => {
    if (duration <= 0) return false;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      element.scrollTop += perTick;
      // for safari compatibility
      if (element.scrollTop === 0) {
        document.body.scrollTop += perTick;
      }
      if ((element.scrollTop || document.body.scrollTop) >= to) return false;
      return this.scrollHandler(element, to, duration - 10);
    }, 10);
  };

  handleChange = (e, { name, value }) => {
    const { dispatch, targetSavingInputs } = this.props;
    const { errors } = targetSavingInputs;
    const num = Number(value);
    const limitRegex = /^[0-9]{1,9}$/;
    if (value !== '' && !limitRegex.test(value)) return;
    if (name === 'horz' && value === '0') return;
    if (name === 'inf' && num < -100) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: 0,
          errors: { ...errors, inf: null },
        })
      );
    } else if (name === 'inf' && num > 100) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: 100,
          errors: { ...errors, inf: null },
        })
      );
    } else if (name === 'horz' && num > 100) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: 100,
          errors: { ...errors, horz: null },
        })
      );
    } else {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: value ? Number(value) : undefined,
          errors: { ...errors, [name]: null },
        })
      );
    }
  };

  render() {
    const {
      accountList,
      portfolios,
      isFetching,
      targetSavingInputs,
      dispatch,
      user_region,
      partnerName,
    } = this.props;
    const { targ, inf, init, initFromAcc, initAccList, horz, portID, errors } =
      targetSavingInputs;
    const portDate = this.getNewPortDate(
      portfolios.find((port) => Number(port.id) === portID)
    );

    return (
      <div style={{ overflowX: 'hidden' }}>
        <Segment textAlign="center">
          <div id="ref">
            <Header
              textAlign="center"
              size="large"
              content="Target Saving"
              subheader="In this segment, you can determine how much money you will need to save each month, with the chosen portfolio, to reach a target amount in your future. This target could be for your retirement, or for your childrens’ education, etc."
            />

            <Form>
              <Header
                size="medium"
                content="Enter Your Inputs"
                style={{ margin: '1rem 0 0 0' }}
              />

              <Form.Group
                grouped
                style={{
                  width: '100%',
                  maxWidth: '20rem',
                  display: 'inline-block',
                  textAlign: 'left',
                  marginTop: '1rem',
                }}
              >
                <div
                  style={{ display: 'flex', transform: 'translateX(-6rem)' }}
                >
                  <Form.Field
                    style={{ position: 'relative', marginRight: '3rem' }}
                  >
                    <label>Target amount in today&#8217;s dollars:</label>
                    <Input
                      name="targ"
                      value={targ === 0 ? '0' : targ || ''}
                      label={{ basic: true, content: '$' }}
                      onChange={this.handleChange}
                    />
                    <ErrorLabel msg={errors.targ} />
                  </Form.Field>
                  <Form.Field style={{ position: 'relative' }}>
                    <label>
                      Expected annual inflation rate:
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                      >
                        Central Bank&#8217;s target is 2% for a general basket
                        of goods and services. Adjust it for your specific
                        savings goal or leave it at 2%.
                      </Popup>
                    </label>
                    <Input
                      name="inf"
                      value={inf === 0 ? '0' : inf || ''}
                      label={{ basic: true, content: '%' }}
                      labelPosition="right"
                      defaultValue="2"
                      onChange={this.handleChange}
                    />
                    <ErrorLabel msg={errors.inf} />
                  </Form.Field>
                </div>
                <Form.Field
                  style={{
                    position: 'relative',
                  }}
                >
                  <label
                    style={{
                      width: '100vw',
                      transform: isGlobeInvestor(partnerName)
                        ? 'none'
                        : 'translateX(-5rem)',
                      marginTop: isGlobeInvestor(partnerName) ? '1rem' : 'auto',
                    }}
                  >
                    How much you have now:{' '}
                    <Form style={{ display: 'inline-block' }}>
                      {!isGlobeInvestor(partnerName) ? (
                        <div>
                          <Form.Field
                            style={{
                              display: 'inline-block',
                              margin: '0 1rem',
                            }}
                          >
                            <Radio
                              name="initFromAcc"
                              checked={!initFromAcc}
                              onChange={() =>
                                dispatch(
                                  CacheInputs.updateField(
                                    'planning',
                                    'target_saving',
                                    {
                                      init: 0,
                                      initFromAcc: false,
                                      initAccList: [],
                                    }
                                  )
                                )
                              }
                              label="Input Value"
                            />
                          </Form.Field>
                          <Form.Field style={{ display: 'inline-block' }}>
                            <Radio
                              name="initFromAcc"
                              checked={initFromAcc}
                              onChange={() =>
                                dispatch(
                                  CacheInputs.updateField(
                                    'planning',
                                    'target_saving',
                                    {
                                      init: 0,
                                      initFromAcc: true,
                                      initAccList: [],
                                    }
                                  )
                                )
                              }
                              label={
                                partnerName === 'quotemedia_sandbox' ||
                                partnerName === 'quotemedia_retail' ||
                                partnerName === 'quotemedia_pro'
                                  ? 'Select from Portfolios'
                                  : 'Select from Accounts'
                              }
                            />
                          </Form.Field>
                        </div>
                      ) : null}
                    </Form>
                  </label>
                  {initFromAcc ? (
                    <AccountsDropdown
                      style={{ transform: 'translateX(5rem)' }}
                      inputs={{}}
                      accountList={accountList}
                      selectedAccList={initAccList || []}
                      excludedList={[]}
                      savedAccountsValue={init || 0}
                      assignAccountsValue={(
                        selectedAccounts,
                        accountsValue
                      ) => {
                        this.props.dispatch(
                          CacheInputs.updateField('planning', 'target_saving', {
                            init: accountsValue,
                            initAccList: selectedAccounts,
                            errors: {
                              ...errors,
                              init: accountsValue ? null : errors.init,
                            },
                          })
                        );
                      }}
                      float={false}
                      error={errors.init}
                      zIndex={0}
                      isQuoteMedia={
                        partnerName === 'quotemedia_sandbox' ||
                        partnerName === 'quotemedia_retail' ||
                        partnerName === 'quotemedia_pro'
                      }
                    />
                  ) : (
                    <div>
                      <Input
                        name="init"
                        value={init === 0 ? '0' : init || ''}
                        label={{ basic: true, content: '$' }}
                        onChange={this.handleChange}
                      />
                      <ErrorLabel msg={errors.init} />
                    </div>
                  )}
                </Form.Field>
                <Form.Field style={{ zIndex: '0' }}>
                  <label
                    style={{
                      zIndex: '-1',
                      width: '100vw',
                      marginTop: isGlobeInvestor(partnerName) ? '1rem' : 'auto',
                    }}
                  >
                    Years to target date:{' '}
                  </label>
                  <Input
                    name="horz"
                    value={horz || ''}
                    label={{ basic: true, content: 'years' }}
                    labelPosition="right"
                    onChange={this.handleChange}
                    style={{ width: '100%', position: 'static' }}
                  />
                  <ErrorLabel msg={errors.horz} />
                </Form.Field>
              </Form.Group>
            </Form>

            <Header
              size="medium"
              style={{ margin: '2rem auto 0 auto' }}
              textAlign="center"
            >
              {partnerName === 'quotemedia_pro' ||
              partnerName === 'quotemedia_sandbox' ||
              partnerName === 'quotemedia_retail'
                ? 'Select Simulation'
                : 'Select Portfolio'}
              <Header.Subheader>
                <div
                  style={{
                    textAlign: 'center',
                    maxWidth: '45rem',
                    margin: '0 auto 1rem auto',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  {user_region === 'US'
                    ? 'This is the portfolio you will be investing in for your financial goal. In addition to five simple models (using a combination of S&P 500 and U.S. Aggregate Bonds ETFs), you can also use your saved portfolios from the "Portfolios" segment here. You will see them in the dropdown list below.'
                    : isGlobeInvestor(partnerName)
                    ? 'There are five simple index model portfolios in the dropdown list below. They hold a combination of Canadian universe bond and equity (50% Canadian and 50% U.S.) ETFs.'
                    : `This is the portfolio you will be investing in for your financial goal. In addition to Wealthscope (WS) model portfolios, you can also use your saved portfolios from the ${
                        partnerName === 'quotemedia_pro' ||
                        partnerName === 'quotemedia_retail' ||
                        partnerName === 'quotemedia_sandbox'
                          ? '"Simulations"'
                          : '"Portfolios"'
                      } segment here. You will see them in the dropdown list below.`}
                </div>
              </Header.Subheader>
            </Header>
            <div
              style={{ position: 'relative', width: '20rem', margin: '0 auto' }}
            >
              <Dropdown
                upward
                placeholder={
                  partnerName === 'quotemedia_sandbox' ||
                  partnerName === 'quotemedia_retail' ||
                  partnerName === 'quotemedia_pro'
                    ? 'Simulation'
                    : 'Portfolio'
                }
                selection
                options={this.getDropOptions(portfolios)}
                value={portID}
                onChange={this.handlePortfolioChange}
                style={{ width: '100%', maxWidth: '20rem', margin: '0 auto' }}
              />
              <ErrorLabel msg={errors.portID} />
            </div>
            <Message
              icon
              visible={!!portDate}
              warning
              style={{
                margin: '1rem auto 0 auto',
                maxWidth: '45rem',
                textAlign: 'left',
              }}
            >
              <Icon name="exclamation" />
              <Message.Content>
                This portfolio has a start date of <strong>{portDate}</strong>.
                You may wish to use a portfolio with a longer history. You can
                use the ETF Portfolio Builder in the "Portfolios" segment. Once
                saved, the portfolio will appear in the dropdown list here.
              </Message.Content>
            </Message>

            <Button
              className={cn('centered right', {
                loading: isFetching,
                disabled: this.isCalculateDisabled(),
              })}
              color={colors.orange}
              icon="calculator"
              onClick={this.handleSubmit}
            >
              Calculate
            </Button>
          </div>
        </Segment>
        <Result inf={inf} />
      </div>
    );
  }
}

TargetSaving.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  targetSavingInputs: PropTypes.object,
  accountList: PropTypes.array,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

TargetSaving.defaultProps = {
  portfolios: [],
  targetSavingInputs: {},
  accountList: [],
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  isFetching: state.Planning.isFetching,
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  targetSavingInputs: state.CacheInputs.planning.target_saving,
  accountList: state.Accounts.accountList,
  user_region: state.Storage.user_region,
  partnerName: state.Storage['partner-name'],
}))(TargetSaving);
