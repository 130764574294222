import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { isGlobeInvestor } from 'utils/helpers';
import { colors } from 'utils/colors';
import { Analytics, Storage } from 'actions';

import Overview from './Overview';
import Performance from './Performance';
import PortfolioHistory from './PortfolioHistory';
import Downside from './Downside';
import Fees from './Fees';
import Income from './Income';
import Diversification from './Diversification';
import AssetClass from './Diversification/AssetClass';
import IndividualAsset from './Diversification/IndividualAsset';
import Region from './Diversification/Region';
import GlobalSector from './Diversification/GlobalSector';
import MacroFactor from './Diversification/MacroFactor';
import EquityStyle from './Diversification/EquityStyle';
import ESG from './ESG';
import ObjectiveSummary from './ObjectiveSummary';

@withRouter
@connect((state) => ({
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
  esg: state.Analytics.esg,
  partnerName: state.Storage['partner-name'],
  globe_destination: state.Storage.globe_destination,
  pwpaObjective: state.Storage['pwpa-objective'],
}))
export default class Result extends Component {
  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount = () => {
    // const { id } = match.params;

    // resize charts on print
    this.state.mql.addListener(this.printListener);

    // if (Number(id) && !drawdown.name) {
    //  return dispatch(Planning.fetchDrawdownPlanId(id));
    // }

    return false;
  };

  componentDidMount = () => {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  };

  componentWillUnmount = () => {
    const { history, dispatch } = this.props;
    const overviewRegex = /^\/portfolio\/.*\/overview$/;
    const pdfRegex = /^\/portfolio\/.*\/pdf$/;

    window.clearTimeout(this.timeoutId);
    this.state.mql.removeListener(this.printListener);

    if (
      !overviewRegex.test(history.location.pathname) &&
      !pdfRegex.test(history.location.pathname)
    ) {
      dispatch(Analytics.clearAnalytics());
      dispatch(Storage.removeItem('pwpa-has-exclude-holding'));
      dispatch(Storage.removeItem('pwpa-check-history'));
    }

    // Reset printLetterGrade everytime after print to prevent scores are removed from scorecard on the app
    dispatch(
      Analytics.updatePrintParams({
        printLetterGrade: null,
      })
    );
  };

  printListener = (e) => {
    const { history, match, partnerName, globe_destination } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else if (isGlobeInvestor(partnerName)) {
      if (globe_destination === 'SmartRiskPortfolioBuilder') {
        history.push(`/portfolio/find`);
      }
      if (globe_destination === 'ETFPortfolioBuilder') {
        history.push(
          `/portfolio/build-etf/${match.params.id}/analytics/overview`
        );
      }
    } else {
      history.push(`/portfolio/${match.params.id}/overview`);
    }
  };

  timeoutId = null;

  formatNum = (num) => {
    if (!num) {
      return '$0';
    }
    const options = {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    const format = new Intl.NumberFormat('en-US', options);

    return `$${format.format(num)}`;
  };

  generateSectors = (esg) => {
    const hasEsg = Boolean(Object.keys(esg).length);
    const componentsName = [
      'Overview',
      'PortfolioHistory',
      'Performance',
      'Downside',
      'Fees',
      'Income',
      'Diversification',
      'AssetClass',
      'IndividualAsset',
      'Region',
      'GlobalSector',
      'MacroFactor',
      'EquityStyle',
    ];
    const components = {
      Overview,
      PortfolioHistory,
      Performance,
      Downside,
      Fees,
      Income,
      Diversification,
      AssetClass,
      IndividualAsset,
      Region,
      GlobalSector,
      MacroFactor,
      EquityStyle,
      ...(Object.keys(esg).length && { ESG }),
    };
    const headers = {
      Overview: 'Overview',
      PortfolioHistory: 'Historical Performance Graph',
      Performance: 'Performance',
      Downside: 'Downside',
      Fees: 'Fees',
      Income: 'Income',
      Diversification: 'Diversification',
      AssetClass: 'Asset Class',
      IndividualAsset: 'Individual',
      Region: 'Region',
      GlobalSector: 'Global Stock Sector',
      MacroFactor: 'Macro Factor',
      EquityStyle: 'Equity Style',
      ...(hasEsg && { ESG: 'ESG' }),
    };

    const subClass = [
      'AssetClass',
      'IndividualAsset',
      'Region',
      'GlobalSector',
      'MacroFactor',
      'EquityStyle',
    ];

    const { analytics, pwpaObjective } = this.props;
    const { printParams } = analytics;

    if (Object.keys(esg).length) componentsName.push('ESG');

    return componentsName
      .filter((name) => printParams[headers[name]])
      .map((comp, index) => {
        const marginTop = index === 0 ? null : { marginTop: '45px' };
        const RenderComponent = components[comp];
        const headingFont = subClass.includes(comp)
          ? null
          : { fontWeight: '900', fontSize: '1.6em' };
        const headerStyle = {
          position: 'absolute',
          top: '-12px',
          left: 15,
          background: 'white',
          padding: '0 5px',
          margin: 0,
          ...headingFont,
        };

        return (
          <div>
            <div style={marginTop}>
              <Section>
                <Header
                  size="medium"
                  content={headers[comp]}
                  style={headerStyle}
                />
                <RenderComponent
                  analytics={analytics}
                  esg={esg}
                  data={pwpaObjective}
                  ratios={analytics.data.fratios.portfolio}
                />
              </Section>
            </div>
            <div style={{ pageBreakAfter: 'always' }} />
          </div>
        );
      });
  };

  renderFootNote = () => {
    const { user_region, esg } = this.props;
    const hasEsg = Boolean(Object.keys(esg).length);
    const { printLetterGrade } = this.props.analytics;

    if (printLetterGrade) {
      return user_region === 'CA' ? (
        <div>
          <P>
            1. Wealthscope Portfolio Scorecard guidelines:
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To protect the integrity of the
            statistical analysis, Wealthscope implements the following rules
            when analyzing your portfolio:
            <br />
            <ul
              style={{ listStyleType: 'disc', transform: 'translateX(12px)' }}
            >
              <li>
                If all of the securities in the portfolio have more than or
                equal to 48 months of history, all of the analysis will be
                performed. Otherwise, we exclude the following securities:
                <ul style={{ listStyleType: 'circle' }}>
                  <li>Securities that have less than 36 months of history;</li>
                  <li>
                    Securities that have between 36 and 47 months of history and
                    collectively weigh less than 10% of the portfolio.
                  </li>
                </ul>
              </li>
              <li>
                For securities included in the analysis, the period of analysis
                will be the longest common history.
              </li>
              <li>
                If all of the securities in the portfolio have less than 36
                months of history, we provide only basic summary information for
                the portfolio using the longest common history.
              </li>
              <li>
                If a passive ETF has insufficient history but there is a close
                substitute, the latter will be used in the analysis. For some
                passive ETFs with short history, we may use the underlying index
                returns (minus the MER) prior to inception.
              </li>
            </ul>
          </P>
          <P>
            2. The Overall Score is the average score of the individual
            dimensions. Each individual score is out of 100 for the specific
            time period in question. A higher score always means a more
            desirable characteristic.
          </P>
          <P>3. All key statistics are reported in CAD.</P>
          <P>
            4. The benchmark for your portfolio is automatically selected
            depending on your asset allocation. It represents a simple, low-cost
            two-ETF (one broad equity and one broad fixed income) alternative
            for your portfolio.
          </P>
          <P>
            5. The following are risk-adjusted return ratios, and a higher ratio
            is better: The Sharpe Ratio measures the portfolio’s reward (excess
            returns over the risk-free rate) per unit of risk. The Roy’s Safety
            First Criterion measures the portfolio’s reward (excess returns over
            the target rate) per unit of risk. The Sortino Ratio measures the
            portfolio’s reward (excess returns over the target rate) per unit of
            downside risk.
          </P>
          <P>
            6. The following are portfolio risk measures: Total risk represents
            the volatility of the portfolio’s returns as measured by its
            standard deviation. Downside risk is the volatility of the
            portfolio’s return below a target rate. Maximum drawdown is the
            maximum peak to trough loss during a given period. Downside capture
            measures whether the portfolio lost less than the market when there
            was a market downturn.
          </P>
          <P>
            7. The default target rate is set to zero, representing nominal
            capital preservation.
          </P>
          <P>
            8. Fees capture product fees if you have mutual funds/ETFs. It can
            include a trailing commission for your advisor, depending on the
            share class you have (e.g., Class A). A weight fee of 3% will
            receive a score of 0%.
          </P>
          <P>
            9. A weighted income (dividends and other distributions) yield of 5%
            or higher will receive a score of 100%.
          </P>
          <P>
            10. Diversification is scored using your portfolio’s average
            correlation and four other dimensions across i) asset class, ii)
            geographical region, iii) business sectors, and iv) macroeconomic
            factors.
          </P>
          <P>
            11. For regional stock market, global stock sector, macroeconomic,
            and equity style diversification, we show you your portfolio’s risk
            exposures. The latter measures how much the volatility of your
            portfolio can be attributed to each factor. Portfolio-specific risk
            is the portion of your portfolio’s volatility that cannot be
            explained by the factors in question.
          </P>
          {hasEsg ? (
            <P>
              12. The Owl Analytics ESG scores are on a 0-100 scale, with a
              higher score being more desirable. The Wealthscope grading scheme
              differs from the ESG scores as the grading scheme reflects the
              distribution of the scores, which is tight around the middle for
              funds (see the distribution chart).
            </P>
          ) : null}
        </div>
      ) : (
        <div>
          <P>
            1. Wealthscope Portfolio Scorecard guidelines:
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To protect the integrity of the
            statistical analysis, Wealthscope implements the following rules
            when analyzing your portfolio:
            <br />
            <ul
              style={{ listStyleType: 'disc', transform: 'translateX(12px)' }}
            >
              <li>
                If all of the securities in the portfolio have more than or
                equal to 48 months of history, all of the analysis will be
                performed. Otherwise, we exclude the following securities:
                <ul style={{ listStyleType: 'circle' }}>
                  <li>Securities that have less than 36 months of history;</li>
                  <li>
                    Securities that have between 36 and 47 months of history and
                    collectively weigh less than 10% of the portfolio.
                  </li>
                </ul>
              </li>
              <li>
                For securities included in the analysis, the period of analysis
                will be the longest common history.
              </li>
              <li>
                If all of the securities in the portfolio have less than 36
                months of history, we provide only basic summary information for
                the portfolio using the longest common history.
              </li>
              <li>
                If a passive ETF has insufficient history but there is a close
                substitute, the latter will be used in the analysis. For some
                passive ETFs with short history, we may use the underlying index
                returns (minus the MER) prior to inception.
              </li>
            </ul>
          </P>
          <P>
            2. The Overall Score is the average score of the individual
            dimensions. Each individual score is out of 100 for the specific
            time period in question. A higher score always means a more
            desirable characteristic.
          </P>
          <P>
            3. The benchmark for your portfolio is automatically selected
            depending on your asset allocation. It represents a simple, low-cost
            two-ETF (one broad equity and one broad fixed income) alternative
            for your portfolio.
          </P>
          <P>
            4. The following are risk-adjusted return ratios, and a higher ratio
            is better: The Sharpe Ratio measures the portfolio’s reward (excess
            returns over the risk-free rate) per unit of risk. The Roy’s Safety
            First Criterion measures the portfolio’s reward (excess returns over
            the target rate) per unit of risk. The Sortino Ratio measures the
            portfolio’s reward (excess returns over the target rate) per unit of
            downside risk.
          </P>
          <P>
            5. The following are portfolio risk measures: Total risk represents
            the volatility of the portfolio’s returns as measured by its
            standard deviation. Downside risk is the volatility of the
            portfolio’s return below a target rate. Maximum drawdown is the
            maximum peak to trough loss during a given period. Downside capture
            measures whether the portfolio lost less than the market when there
            was a market downturn.
          </P>
          <P>
            6. The default target rate is set to zero, representing nominal
            capital preservation.
          </P>
          <P>
            7. Fees capture product fees if you have mutual funds/ETFs. It can
            include a trailing commission for your advisor, depending on the
            share class you have (e.g., Class A). A weight fee of 3% will
            receive a score of 0%.
          </P>
          <P>
            8. A weighted income (dividends and other distributions) yield of 5%
            or higher will receive a score of 100%.
          </P>
          <P>
            9. Diversification is scored using your portfolio’s average
            correlation and four other dimensions across i) asset class, ii)
            geographical region, iii) business sectors, and iv) macroeconomic
            factors.
          </P>
          <P>
            10. For regional stock market, global stock sector, macroeconomic,
            and equity style diversification, we show you your portfolio’s risk
            exposures. The latter measures how much the volatility of your
            portfolio can be attributed to each factor. Portfolio-specific risk
            is the portion of your portfolio’s volatility that cannot be
            explained by the factors in question.
          </P>
          {hasEsg ? (
            <P>
              11. The Owl Analytics ESG scores are on a 0-100 scale, with a
              higher score being more desirable. The Wealthscope grading scheme
              differs from the ESG scores as the grading scheme reflects the
              distribution of the scores, which is tight around the middle for
              funds (see the distribution chart).
            </P>
          ) : null}
        </div>
      );
    }

    return (
      <div>
        <P>
          1. Wealthscope Portfolio Scorecard guidelines:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To protect the integrity of the
          statistical analysis, Wealthscope implements the following rules when
          analyzing your portfolio:
          <br />
          <ul style={{ listStyleType: 'disc', transform: 'translateX(12px)' }}>
            <li>
              If all of the securities in the portfolio have more than or equal
              to 48 months of history, all of the analysis will be performed.
              Otherwise, we exclude the following securities:
              <ul style={{ listStyleType: 'circle' }}>
                <li>Securities that have less than 36 months of history;</li>
                <li>
                  Securities that have between 36 and 47 months of history and
                  collectively weigh less than 10% of the portfolio.
                </li>
              </ul>
            </li>
            <li>
              For securities included in the analysis, the period of analysis
              will be the longest common history.
            </li>
            <li>
              If all of the securities in the portfolio have less than 36 months
              of history, we provide only basic summary information for the
              portfolio using the longest common history.
            </li>
            <li>
              If a passive ETF has insufficient history but there is a close
              substitute, the latter will be used in the analysis. For some
              passive ETFs with short history, we may use the underlying index
              returns (minus the MER) prior to inception.
            </li>
          </ul>
        </P>
        <P>2. All key statistics are reported in CAD.</P>
        <P>
          3. The benchmark for your portfolio is automatically selected
          depending on your asset allocation. It represents a simple, low-cost
          two-ETF (one broad equity and one broad fixed income) alternative for
          your portfolio.
        </P>
        <P>
          4. The following are risk-adjusted return ratios, and a higher ratio
          is better: The Sharpe Ratio measures the portfolio’s reward (excess
          returns over the risk-free rate) per unit of risk. The Roy’s Safety
          First Criterion measures the portfolio’s reward (excess returns over
          the target rate) per unit of risk. The Sortino Ratio measures the
          portfolio’s reward (excess returns over the target rate) per unit of
          downside risk.
        </P>
        <P>
          5. The following are portfolio risk measures: Total risk represents
          the volatility of the portfolio’s returns as measured by its standard
          deviation. Downside risk is the volatility of the portfolio’s return
          below a target rate. Maximum drawdown is the maximum peak to trough
          loss during a given period. Downside capture measures whether the
          portfolio lost less than the market when there was a market downturn.
        </P>
        <P>
          6. The default target rate is set to zero, representing nominal
          capital preservation.
        </P>
        <P>
          7. Fees capture product fees if you have mutual funds/ETFs. It can
          include a trailing commission for your advisor, depending on the share
          class you have (e.g., Class A).
        </P>
        <P>
          8. Diversification is assessed using your portfolio’s average
          correlation and four other dimensions across i) asset class, ii)
          geographical region, iii) business sectors, and iv) macroeconomic
          factors.
        </P>
        <P>
          9. For regional stock market, global stock sector, macroeconomic, and
          equity style diversification, we show you your portfolio’s risk
          exposures. The latter measures how much the volatility of your
          portfolio can be attributed to each factor. Portfolio-specific risk is
          the portion of your portfolio’s volatility that cannot be explained by
          the factors in question.
        </P>
        {hasEsg ? (
          <P>
            10. The Owl Analytics ESG scores are on a 0-100 scale, with a higher
            score being more desirable. The Wealthscope grading scheme differs
            from the ESG scores as the grading scheme reflects the distribution
            of the scores, which is tight around the middle for funds (see the
            distribution chart).
          </P>
        ) : null}
      </div>
    );
  };

  render() {
    const { analytics, esg, pwpaObjective } = this.props;
    const { data, printParams } = analytics || {};
    const { portfolio } = data || {};

    return (
      <PDF>
        <Header
          size="large"
          content={
            printParams && printParams.printLetterGrade
              ? 'Portfolio Scorecard'
              : 'Portfolio Review'
          }
          subheader={
            portfolio.account && Object.keys(portfolio.account).length
              ? portfolio.account.user_institution
              : portfolio.name
          }
          style={{ textAlign: 'left', margin: '0' }}
        />
        <Header
          size="medium"
          subheader={
            portfolio.account && Object.keys(portfolio.account).length
              ? portfolio.account.label
              : portfolio.description
          }
          style={{ textAlign: 'left', margin: '0 0 30px 0' }}
        />
        {/* Add Objective summary  */}
        {this.props.globe_destination === 'SmartRiskPortfolioBuilder' && (
          <div>
            <div style={{ marginTop: '45px' }}>
              <Section>
                <Header
                  size="medium"
                  content="Selection Summary"
                  style={{
                    position: 'absolute',
                    top: '-12px',
                    left: 15,
                    background: 'white',
                    padding: '0 5px',
                    margin: 0,
                  }}
                />
                <ObjectiveSummary
                  analytics={analytics}
                  esg={esg}
                  data={pwpaObjective}
                  ratios={analytics.data.fratios.portfolio}
                />
              </Section>
            </div>
            <div style={{ pageBreakAfter: 'always' }} />
          </div>
        )}
        {/* Add Objective Summary */}
        {this.generateSectors(esg)}
        <div style={{ pageBreakAfter: 'always' }} />
        <Header
          size="large"
          content="Footnotes"
          style={{ textAlign: 'left', marginBottom: '10px' }}
        />
        {this.renderFootNote()}
      </PDF>
    );
  }
}

Result.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  analytics: PropTypes.object,
  dispatch: PropTypes.func,
  user_region: PropTypes.string,
  esg: PropTypes.object,
  partnerName: PropTypes.string,
  globe_destination: PropTypes.string,
  pwpaObjective: PropTypes.object,
};

Result.defaultProps = {
  match: {},
  history: {},
  analytics: {},
  dispatch: () => false,
  user_region: 'CA',
  esg: {},
  partnerName: '',
  globe_destination: '',
  pwpaObjective: {},
};

const Section = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${(props) => (props.single ? '45rem' : '99.5%')};
  border: 1px solid ${colors.blue};
  border-radius: 8px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
  padding: 1rem;
`;

const PDF = styled.div`
  position: relative;
  background: white;
  text-align: center;
  padding: 0.5rem;
  border-radius: 8px;
  width: 1160px;
  margin: -55px auto 5px auto;

  @media only print {
    print-color-adjust: exact;

    @page {
      size: auto;
      margin: 0.5cm;
    }
  }
`;

const P = styled.p`
  text-align: left;
  max-width: 100%;
`;
