import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider, Header } from 'semantic-ui-react';
import cn from 'classnames';
import styled from 'styled-components';

import { Portfolio, Planning, Storage } from 'actions';
import { colors } from 'utils/colors';
import {
  formatDollarAmount,
  filterOutKeysFromObject,
  isGlobeInvestor,
} from 'utils/helpers';
import { Loading, Button } from 'components';
import PortfoliosList from 'modules/Planning/components/PortfoliosList';
import { NavigationButtons } from '../components';

import Result from './Result';
import numSubpagesInEachSection from '../numSubPages';

@withRouter
class Wealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      numSubPages: numSubpagesInEachSection.targetWealth,
      resultIsDisabled: false,
    };
  }

  componentWillMount() {
    const { dispatch, history, match, modelPortfolios, data } = this.props;

    const { currentSubPage } = data.targetWealth || {};
    const { numSubPages } = this.state;

    const modifiedTargetWealthData = {
      currentSubPage: currentSubPage
        ? Math.min(numSubPages, currentSubPage)
        : 1,
    };

    dispatch(
      Planning.updateBlueprintData('targetWealth', modifiedTargetWealthData)
    );

    dispatch(
      Planning.updateCompletedSectionsPageNumbers('targetWealth', {
        ...data, // ensure we are using most recent data (store might not update in time)
        targetWealth: {
          ...data.targetWealth,
          ...modifiedTargetWealthData,
        },
      })
    );

    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    if (!modelPortfolios.length) {
      dispatch(Portfolio.fetchModel());
    }

    if (!data || !data.targetWealth) {
      return history.push(`/planning/blueprint/${match.params.id}/overview`);
    }

    return false;
  }

  componentDidMount() {
    const { dispatch, data, cacheTW, cacheWR, cacheTS, cacheSR } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { age, retAge, age2, retAge2 } = data.personalDetails || {};

    dispatch(
      Planning.updateBlueprintData('targetWealth', {
        horz:
          is_couple_plan === 'true'
            ? Math.min(retAge - age, retAge2 - age2)
            : retAge - age,
      })
    );

    if (!cacheTW) {
      dispatch(Storage.setItem('cacheTW', data.targetWealth));
    }

    if (!cacheWR) {
      dispatch(Storage.setItem('cacheWR', data.wealthResult));
    }

    if (!cacheTS) {
      dispatch(Storage.setItem('cacheTS', data.targetSaving));
    }

    if (!cacheSR) {
      dispatch(Storage.setItem('cacheSR', data.savingResult));
    }

    const isChangedFromPrevious = () => {
      const { match, cacheInputChanged } = this.props;

      if (match.params.id === 'new') {
        return false;
      }
      return cacheInputChanged;
    };
    if (isChangedFromPrevious()) {
      this.setState({ resultIsDisabled: true });
    }
  }

  componentDidUpdate(prevProp) {
    const { dispatch, data } = this.props;
    if (prevProp.data && data) {
      const excludedKeys = ['currentSubPage'];

      const prev = filterOutKeysFromObject(
        prevProp.data.targetWealth,
        excludedKeys
      );
      const curr = filterOutKeysFromObject(data.targetWealth, excludedKeys);
      if (
        JSON.stringify(prev, Object.keys(prev).sort()) !==
        JSON.stringify(curr, Object.keys(prev).sort())
      ) {
        // TS values no longer need to be cleared if other values are modified.
        // dispatch(Planning.clearBlueprintSection('targetSaving'));
        dispatch(Planning.clearBlueprintSection('savingResult'));
        dispatch(Planning.clearBlueprintSection('summary'));
      }
    }
  }

  componentWillUnmount() {
    const { history, dispatch, data, cacheTW, cacheWR } = this.props;
    const { targetWealth, wealthResult } = data;

    dispatch(Storage.removeItem('cacheInputChanged'));
    // Following two items removed to ensure target savings tab is enabled after all
    // sections have been filled out. Necessary after TS details are now kept even after
    // previous details have been updated, since we now disable the tab by checking if the prev
    // sections were changed in addition to if there is no content in that blueprint section.
    dispatch(Storage.removeItem('cachePortfolioChanged'));
    dispatch(Storage.removeItem('cacheTargetChanged'));

    if (!data.wealthResult && cacheWR) {
      dispatch(Planning.updateBlueprintData('wealthResult', cacheWR));
      dispatch(Planning.updateBlueprintData('targetWealth', cacheTW));
    }

    dispatch(Storage.removeItem('cacheTW'));
    dispatch(Storage.removeItem('cacheWR'));

    if (history.location.pathname.includes('personal_details')) {
      dispatch(Storage.setItem('cacheTW', targetWealth));
      dispatch(Storage.setItem('cacheWR', wealthResult));
    }
  }

  handlePreviousPage = () => {
    const { match, history } = this.props;
    return history.push(
      `/planning/blueprint/${match.params.id}/personal_details`
    );
  };

  handlePortfolioChange = (e, { name, value }) => {
    const { dispatch, cachePortfolioChanged, data } = this.props;
    if (!cachePortfolioChanged) {
      dispatch(Storage.setItem('cachePortfolioChanged', true));
    }
    dispatch(Planning.clearBlueprintSection('wealthResult'));
    const limitRegex = /^[0-9]{1,9}$/;
    if (name === 'annuityRate') {
      if (value === '' || !limitRegex.test(value)) {
        dispatch(
          Planning.updateBlueprintData('targetWealth', {
            ...data.targetWealth,
            wealth_portfolio: null,
            modelType: 'annuityRate',
            targ: null,
            annuityRate: null,
          })
        );
        return;
      }

      const num = Number(value) > 100 ? 100 : Number(value);

      dispatch(
        Planning.updateBlueprintData('targetWealth', {
          ...data.targetWealth,
          wealth_portfolio: null,
          modelType: 'annuityRate',
          annuityRate: num,
          targ: null,
        })
      );
    } else {
      dispatch(
        Planning.updateBlueprintData('targetWealth', {
          ...data.targetWealth,
          wealth_portfolio: value,
          modelType: name,
          annuityRate: null,
          targ: null,
        })
      );
    }

    this.setState({ errors: {} });
  };

  handleRadioChange = (e, { value }) => {
    const { dispatch, cachePortfolioChanged, data } = this.props;
    if (!cachePortfolioChanged) {
      dispatch(Storage.setItem('cachePortfolioChanged', true));
    }

    dispatch(Planning.clearBlueprintSection('wealthResult'));
    dispatch(
      Planning.updateBlueprintData('targetWealth', {
        ...data.targetWealth,
        wealth_portfolio: null,
        modelType: value,
        annuityRate: null,
      })
    );

    return this.setState({ errors: {} });
  };

  // eslint-disable-next-line consistent-return
  handleSubmit = () => {
    const { modelPortfolios, dispatch, data, is_wealthica_trial } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const {
      gender,
      cpp,
      cppAge,
      oas,
      oasAge,
      age,
      retAge,
      pension,
      other,
      age2,
      retAge2,
      gender2,
      cpp2,
      cppAge2,
      oas2,
      oasAge2,
      pension2,
      other2,
      spending_amount,
      wage,
      wage2,
      declinePattern,
    } = data.personalDetails || {};
    const { annuityRate, wealth_portfolio, modelType } =
      data.targetWealth || {};
    const spouseParams =
      is_couple_plan === 'true'
        ? {
            age2,
            retAge2,
            gender2,
            cpp2,
            cppAge2,
            oas2,
            oasAge2,
            pension2,
            other2,
            wage2: wage2 || 0,
            ...(pension2 && { pensionAge2: retAge2 }),
            ...(pension2 && { pensionInf2: false }),
            ...(other2 && { otherAge2: retAge2 }),
            ...(other2 && { otherInf2: false }),
            ...(!!declinePattern && {
              declinePattern2: {
                percent: declinePattern.percent,
                duration: declinePattern.duration,
              },
            }),
          }
        : {};
    const params = {
      spouse: is_couple_plan === 'true',
      exp: spending_amount,
      gender,
      cpp,
      cppAge,
      oas,
      oasAge,
      age,
      retAge,
      pension,
      other,
      wage: wage || 0,
      ...(pension && { pensionAge: retAge }),
      ...(pension && { pensionInf: false }),
      ...(other && { otherAge: retAge }),
      ...(other && { otherInf: false }),
      ...(!!declinePattern && {
        declinePattern: {
          percent: declinePattern.percent,
          duration: declinePattern.duration,
        },
      }),
      ...spouseParams,
    };

    this.setState({ resultIsDisabled: false });

    if (
      (wealth_portfolio === null || wealth_portfolio === undefined) &&
      modelType !== 'annuityRate'
    ) {
      return this.setState({
        errors: { portfolioID: 'Please select a portfolio.' },
      });
    }
    if (!annuityRate && annuityRate !== 0 && modelType === 'annuityRate') {
      this.setState({
        errors: { portfolioID: 'Please enter a risk-free rate.' },
      });
    }

    if (annuityRate || annuityRate === 0) {
      // targ = selection (50%, 75%, 90%)
      return dispatch(
        Planning.analyzeBlueprintWealth({
          ...params,
          region: 'CA',
          annuityRate: annuityRate / 100,
        })
      ).then((wealthResult) => {
        // this.scrollHandler(
        //   document.getElementById('wealth-ref').offsetHeight + 50,
        //   600
        // );

        dispatch(
          Planning.updateBlueprintData('targetWealth', {
            targ: wealthResult.result.Annuity,
          })
        );
        dispatch(
          Planning.updateBlueprintData('wealthResult', wealthResult.result)
        );
      });
    }

    const selectedPortfolio = modelPortfolios.find(
      (port) => Number(port.id) === Number(wealth_portfolio)
    );

    if (selectedPortfolio) {
      return dispatch(
        Planning.analyzeBlueprintWealth({
          ...params,
          symbols: selectedPortfolio.symbols,
          weights: selectedPortfolio.weights,
          region: selectedPortfolio.region,
          gic_info: selectedPortfolio.gic_info,
        })
      ).then((wealthResult) => {
        if (this.scrollToResult) {
          this.scrollToResult();
        }

        // this.scrollHandler(
        //   document.getElementById('wealth-ref').offsetHeight + 50,
        //   600
        // );

        dispatch(
          Planning.updateBlueprintData('wealthResult', wealthResult.result)
        );

        if (is_wealthica_trial) dispatch(Planning.fetchWealthicaUser());
      });
    }
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }

      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  isCalculateDisabled = () => {
    const { match, data } = this.props;
    const { wealthResult } = data || {};
    const { resultIsDisabled } = this.state;

    if (match.params.id === 'new' || resultIsDisabled) {
      return false;
    }

    return wealthResult;
  };

  render() {
    const {
      match,
      dispatch,
      history,
      modelPortfolios,
      isFetching,
      data,
      wealthicaUser,
      is_wealthica_trial,
      user_region,
      partnerName,
    } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { net_spending, spending_amount } = data.personalDetails || {};
    const { modelType, wealth_portfolio, annuityRate, targ, horz } =
      data.targetWealth || {};
    const { errors, resultIsDisabled } = this.state;
    const isCouplePlan = is_couple_plan === 'true';
    const { planning_restrictions } = wealthicaUser;
    const wealthicaTrialAllowed = is_wealthica_trial
      ? planning_restrictions && planning_restrictions.blueprint_allowed
      : true;

    return (
      <div style={{ backgroundColor: colors.lightGrey }}>
        <div
          style={{
            backgroundColor: colors.white,
            marginBottom: '2rem',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <BlueprintContent>
            <Loading fixed active={!modelPortfolios.length} />
            <div id="wealth-ref">
              <Header size="large">
                Target Wealth
                <Header.Subheader
                  style={{
                    marginTop: '.6rem',
                    letterSpacing: isCouplePlan ? '-.2px' : 'unset',
                  }}
                >
                  Your {isCouplePlan ? 'combined' : null} annual spending needs
                  net of expected retirement income is{' '}
                  <span style={{ fontSize: '1.25rem', color: colors.red }}>
                    <b>{formatDollarAmount(net_spending)}</b>
                  </span>
                  . We are now ready to estimate the nest egg that you require
                  at retirement.
                </Header.Subheader>
              </Header>
              <Header size="medium" style={{ position: 'relative' }}>
                Select a Model Portfolio
                <Header.Subheader style={{ marginTop: '.3rem' }}>
                  This is your post-retirement asset allocation. Equity is half
                  Canadian and half U.S. large-cap equity; bonds are Canadian
                  universe bonds. We assume that you withdraw funds for spending
                  at the beginning of each year, and the rest stays invested in
                  this portfolio.{' '}
                  {!isGlobeInvestor(partnerName) &&
                    'Details of the Wealthscope (WS) model portfolios can be found in the “Portfolios” segment.'}
                </Header.Subheader>
                <br />
                <Header.Subheader
                  style={{
                    marginBottom: '1.5rem',
                    textAlign: 'left',
                    letterSpacing: '-.1px',
                  }}
                >
                  In making your decision, you should consider the percentage of
                  spending that will be covered by your retirement income. Since
                  the latter is fixed income, the higher this percentage is, the
                  more financial risk you could potentially take on. Based on
                  your inputs, this percentage is{' '}
                  <span style={{ color: colors.red, fontSize: '1.25rem' }}>
                    <b>
                      {(
                        ((spending_amount - net_spending) / spending_amount) *
                        100
                      ).toFixed(1)}
                      %
                    </b>
                  </span>
                  .
                </Header.Subheader>
              </Header>
              <Divider />

              <div style={{ position: 'relative' }}>
                <PortfoliosList
                  portfolios={modelPortfolios}
                  portfolioID={
                    wealth_portfolio ? wealth_portfolio.toString() : null
                  }
                  annuityRate={annuityRate}
                  handlePortfolioChange={this.handlePortfolioChange}
                  handleRadioChange={this.handleRadioChange}
                  modelType={modelType}
                  errors={errors}
                  partnerName={partnerName}
                  user_region={user_region}
                />
              </div>
              <Button
                id="calc-button"
                className={cn('centered right', {
                  loading: isFetching && !data.wealthResult,
                  disabled:
                    this.isCalculateDisabled() || !wealthicaTrialAllowed,
                })}
                style={{
                  width: resultIsDisabled && '10rem',
                }}
                color={colors.teal}
                icon="calculator"
                onClick={this.handleSubmit}
                popup={
                  !wealthicaTrialAllowed
                    ? {
                        position: 'top center',
                        content:
                          'You have reached the trial period limit. Once the trial period is complete, you can save up to 5 Blueprints.',
                        wide: true,
                      }
                    : null
                }
              >
                {resultIsDisabled ? 'Re-calculate' : 'Calculate'}
              </Button>
              <NavigationButtons
                showNextButton={false}
                handleSubmit={this.handleSubmit}
                handlePreviousPage={this.handlePreviousPage}
              />
            </div>
          </BlueprintContent>
        </div>
        {!resultIsDisabled && (
          <Result
            data={data}
            result={data.wealthResult}
            targ={targ}
            horz={horz}
            isFetching={isFetching}
            annuityRate={annuityRate}
            dispatch={dispatch}
            history={history}
            match={match}
          />
        )}
      </div>
    );
  }
}

Wealth.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modelPortfolios: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  cacheWR: PropTypes.object,
  cacheTW: PropTypes.object,
  cacheTS: PropTypes.object,
  cacheSR: PropTypes.object,
  wealthicaUser: PropTypes.object,
  is_wealthica_trial: PropTypes.bool,
  cachePortfolioChanged: PropTypes.bool,
  cacheInputChanged: PropTypes.bool,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

Wealth.defaultProps = {
  modelPortfolios: [],
  history: {},
  match: {},
  cacheTW: null,
  cacheWR: null,
  cacheTS: null,
  cacheSR: null,
  wealthicaUser: {},
  is_wealthica_trial: false,
  cachePortfolioChanged: false,
  cacheInputChanged: false,
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  isFetching: state.Planning.isFetching,
  modelPortfolios: state.Portfolio.model,
  cacheTW: state.Storage.cacheTW,
  cacheWR: state.Storage.cacheWR,
  cacheTS: state.Storage.cacheTS,
  cacheSR: state.Storage.cacheSR,
  wealthicaUser: state.Planning.wealthicaUser,
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  cachePortfolioChanged: state.Storage.cachePortfolioChanged,
  cacheInputChanged: state.Storage.cacheInputChanged,
  user_region: state.Storage.user_region,
  partnerName: state.Storage['partner-name'],
}))(Wealth);

const BlueprintContent = styled.div`
  padding: 2.5rem;
  background-color: ${colors.white};
  border-radius: 8px;

  #wealth-ref {
    text-align: left;
    padding-bottom: 15px;

    .header {
      width: 50rem;
      .sub.header {
        padding-left: 2rem;
        font-size: 1.2rem;
      }
    }
    .header:nth-of-type(2) {
      padding-left: 2rem;

      .sub.header {
        padding-left: 0rem;
      }
    }
  }

  #calc-button {
    position: absolute;
    left: calc(50% - 57px);
    width: 120px;
  }
`;
