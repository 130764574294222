import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Icon, Popup, Button, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Api, Analytics } from 'actions';

import { Button as CustomButton, Modal } from 'components';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import Bar from './Bar';

class Holdings extends Component {
  state = {
    tickerExchanges: {},
  };
  componentWillMount = () => {
    const { holdings, dispatch } = this.props;
    const tickers_string = holdings
      .filter((item) => item.name !== 'GIC or HISA')
      .map((item) => item.ticker)
      .join(',');
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${tickers_string}`,
      })
    ).then((result) => {
      this.setState({ tickerExchanges: result });
    });
  };
  getExchangeSymbol(ticker, tickerExchanges) {
    for (let i = 0; i < tickerExchanges.length; i++) {
      if (tickerExchanges[i].ticker === ticker) {
        return tickerExchanges[i].exchange_symbol;
      }
    }
    return '';
  }

  isExecuteVisible = (portfolio) => {
    const { partnerName } = this.props;
    if (!portfolio) {
      return false;
    }
    if (isGlobeInvestor(partnerName)) {
      return false;
    }
    if (
      portfolio.is_linked ||
      portfolio.portfolio_type === 'custom' ||
      portfolio.portfolio_type === 'quantified'
    ) {
      return false;
    }

    return true;
  };

  handleClick = (to) => {
    const { history } = this.props;

    if (to) {
      return history.push(to);
    }

    return false;
  };

  isExecuteVisible = (portfolio) => {
    const { partnerName } = this.props;
    if (!portfolio) {
      return false;
    }
    if (isGlobeInvestor(partnerName)) {
      return false;
    }
    if (
      portfolio.is_linked ||
      portfolio.portfolio_type === 'custom' ||
      portfolio.portfolio_type === 'quantified'
    ) {
      return false;
    }

    return true;
  };

  handleClick = (to) => {
    const { history } = this.props;

    if (to) {
      return history.push(to);
    }

    return false;
  };

  isLinked = (port) => {
    if (!port) {
      return false;
    }

    if (
      port.portfolio_type === 'custom' ||
      port.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return port.is_linked;
  };

  generatePDF = () => {
    const { history, match } = this.props;
    return history.push(`/portfolio/unsaved/pdf`);
  };

  handlePrint = () => {
    const { partnerName, dispatch, history, match } = this.props;
    if (isGlobeInvestor(partnerName)) {
      return history.push(`/portfolio/unsaved/pdf`);
    }
    return dispatch(
      Analytics.updatePrintParams({
        printModal: true,
      })
    );
  };

  renderCheckboxes = () => {
    const { dispatch, printParams, esg, portfolio } = this.props;
    const leftArr = [
      'Overview',
      'Historical Performance Graph',
      'Performance',
      'Downside',
      'Fees',
      'Income',
    ];
    const rightArr = [
      'Diversification',
      'Correlation',
      'Asset Class',
      'Individual',
      'Region',
      'Global Stock Sector',
      'Macro Factor',
      'Equity Style',
      'ESG',
    ];
    const subClass = [
      'Correlation',
      'Asset Class',
      'Individual',
      'Region',
      'Global Stock Sector',
      'Macro Factor',
      'Equity Style',
    ];
    const isSubclass = (name) => subClass.includes(name);
    const fromToggle = true;
    const checkbox = (name) => (
      <div
        className="ui checkbox"
        style={{
          display: 'block',
          margin: isSubclass(name) ? '1rem 0 0 2rem' : '1rem 0 0 0',
        }}
      >
        <input
          type="checkbox"
          // If esg has been retrieved, default checkes as others
          // If not, esg default to unchecked
          checked={printParams[name]}
          onChange={() => {
            if (
              name === 'ESG' &&
              Object.keys(esg).length === 0 &&
              !printParams.ESG
            ) {
              dispatch(Analytics.fetchEsg(portfolio, fromToggle));
            }

            dispatch(
              Analytics.updatePrintParams({
                [name]: !printParams[name],
              })
            );
          }}
        />
        <label style={{ fontSize: '1.2em', fontWeight: '700' }}>{name}</label>
      </div>
    );

    return (
      <div style={{ padding: '0.5rem 1.5rem' }}>
        <div style={{ width: '49%', float: 'left' }}>
          {(leftArr || []).map((elem) => checkbox(elem))}
        </div>
        <div style={{ width: '49%', float: 'right' }}>
          {(rightArr || []).map((elem) => checkbox(elem))}
        </div>
      </div>
    );
  };

  render() {
    const {
      dispatch,
      holdings,
      portfolio,
      hasExcludedHolding,
      printParams,
      match,
      history,
      partnerName,
      id,
    } = this.props;
    const { tickerExchanges } = this.state;
    const { printModal, printLetterGrade } = printParams;
    const letterGradeInclHint =
      printLetterGrade === null
        ? ''
        : printLetterGrade
        ? 'Letter grades and percentage scores included'
        : 'Letter grades and percentage scores not included';
    return (
      <Container>
        <Header size="medium" style={{ position: 'relative' }}>
          {'Portfolio Overview'}
          {this.isExecuteVisible(portfolio) && (
            <ETFButton
              style={{
                position: 'absolute',
                top: '0',
                right: '175px',
                fontSize: '0.85em',
                padding: '0.35em 1.3em',
                fontWeight: '400',
              }}
              onClick={() => this.handleClick(`/portfolio/${id}/execute`)}
            >
              View ETFs
            </ETFButton>
          )}
          {this.isExecuteVisible(portfolio) && (
            <ETFButton
              style={{
                position: 'absolute',
                top: '0',
                right: '175px',
                fontSize: '0.85em',
                padding: '0.35em 1.3em',
                fontWeight: '400',
              }}
              onClick={() => this.handleClick(`/portfolio/${id}/execute`)}
            >
              View ETFs
            </ETFButton>
          )}
          <Button
            active
            basic
            icon
            labelPosition="right"
            style={{
              position: 'absolute',
              top: '0',
              right: !this.isLinked(portfolio) ? '0' : '170px',
              fontSize: '0.85em',
              padding: '0.35em',
            }}
            onClick={this.handlePrint}
          >
            Print/PDF
            <Icon
              name="download"
              style={{
                fontSize: '12px',
                verticalAlign: 'initial',
                color: 'dimgrey',
              }}
            />
          </Button>
          {!this.isLinked(portfolio) ? null : (
            <Popup
              trigger={
                <Button
                  active
                  basic
                  icon
                  labelPosition="right"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    fontSize: '0.85em',
                    padding: '0.35em',
                  }}
                >
                  Guidelines
                  <Icon
                    name="info circle"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                </Button>
              }
              position="top center"
              style={{ maxWidth: '650px' }}
            >
              <Popup.Content>
                To protect the integrity of the statistical analysis,
                Wealthscope implements the following rules when analyzing your
                portfolio:
                <br />
                <br />
                If all of the securities in the portfolio have more than or
                equal to 48 months of history, all of the analysis will be
                performed. Otherwise, we exclude the following securities:
                <br />
                <ul>
                  <li>securities that have less than 36 months of history;</li>
                  <li>
                    securities that have between 36 and 47 months of history and
                    collectively weigh less than 10% of the portfolio.
                  </li>
                </ul>
                For securities included in the analysis, the period of analysis
                will be the longest common history.
                <br />
                <br />
                If all of the securities in the portfolio have less than 36
                months of history, we provide only basic summary information for
                the portfolio using the longest common history.
                <br />
                <br />
                If a passive ETF has insufficient history but there is a close
                substitute, the latter will be used in the analysis. For some
                passive ETFs with short history, we may use the index returns
                (minus the MER) prior to inception.
                <br />
                <br />
                Securities listed outside of outside of U.S. will not be
                included in the analysis.
                <br />
              </Popup.Content>
            </Popup>
          )}
          {portfolio.account && Object.keys(portfolio.account).length ? (
            <Header.Subheader style={{ transform: 'translateY(5px)' }}>
              {portfolio.account.user_institution ===
              'Overview; All Accounts' ? (
                <React.Fragment>
                  Overview <br />
                  All Accounts
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {portfolio.account.user_institution ? (
                    <Value
                      className={
                        portfolio.account.user_institution &&
                        portfolio.account.user_institution.length > 35
                          ? 'long'
                          : ''
                      }
                    >
                      {portfolio.account.user_institution}
                    </Value>
                  ) : null}
                  <br />
                  {portfolio.account.label}
                </React.Fragment>
              )}
            </Header.Subheader>
          ) : portfolio.name ? (
            <Header.Subheader style={{ transform: 'translateY(5px)' }}>
              {portfolio.name}
              {portfolio.description ? (
                <div>{portfolio.description}</div>
              ) : null}
            </Header.Subheader>
          ) : null}
        </Header>

        <Divider
          style={{
            margin:
              !isGlobeInvestor(partnerName) &&
              ((!portfolio.name && !portfolio.description) ||
                !portfolio.account)
                ? '12px 0 12px 0'
                : '12px 0',
          }}
        />

        <Wrapper limitHeight={holdings.length > 8}>
          {holdings.map((val) => (
            <Bar
              key={val.ticker}
              ticker={this.isLinked(portfolio) ? val.ticker : ''}
              name={this.isLinked(portfolio) ? val.name : val.wsName}
              exchange_symbol={this.getExchangeSymbol(
                val.ticker,
                tickerExchanges
              )}
              weight={val.weight}
              error={val.isExcluded}
              replaced={val.replacedBy}
              dispatch={dispatch}
              id={match.params.id || 'unsaved'}
              history={history}
            />
          ))}
        </Wrapper>
        {hasExcludedHolding ? (
          <p style={{ color: colors.hintRed, fontSize: '1rem' }}>
            Please check the list of holdings to see if any of your holdings
            have been excluded from the analysis. Hover over "Guidelines" above
            for our rules. To use all of the holdings in the analysis, use the
            toggle at the top right.
          </p>
        ) : null}

        <Modal open={printModal}>
          <CloseIcon
            onClick={() => {
              dispatch(
                Analytics.updatePrintParams({
                  printModal: false,
                  printLetterGrade: null,
                })
              );
            }}
          >
            +
          </CloseIcon>
          <Header
            content="Would you like to include the letter grades and percentage scores in the PDF of your scorecard?"
            subheader='Note: To save the scorecard to your computer, please select "Save
              as PDF" as your Print destination.'
            style={{ padding: '2rem 0 0 2rem' }}
          />
          <div style={{ padding: '1rem', textAlign: 'right' }}>
            <CustomButton
              className="left"
              color={colors.red}
              icon="cancel"
              margin="0 0.5rem 0 0"
              onClick={() =>
                dispatch(
                  Analytics.updatePrintParams({
                    printLetterGrade: false,
                  })
                )
              }
            >
              No
            </CustomButton>
            <CustomButton
              className="left"
              color={colors.green}
              icon="checkmark"
              onClick={() =>
                dispatch(
                  Analytics.updatePrintParams({
                    printLetterGrade: true,
                  })
                )
              }
            >
              Yes
            </CustomButton>
          </div>

          <Header
            size="small"
            style={{ textAlign: 'right', margin: '0 1rem 0 0' }}
          >
            <i>{letterGradeInclHint}</i>
          </Header>

          {printLetterGrade !== null ? (
            <div style={{ padding: '2rem' }}>
              <Header>Which pages would you like to include?</Header>
              {this.renderCheckboxes()}
              <div
                style={{
                  clear: 'both',
                  paddingTop: '2rem',
                  marginBottom: '2rem',
                  textAlign: 'center',
                }}
              >
                <CustomButton
                  className="left"
                  color={colors.green}
                  icon="checkmark"
                  onClick={() => {
                    dispatch(
                      Analytics.updatePrintParams({
                        printModal: false,
                      })
                    );
                    this.generatePDF();
                  }}
                >
                  Done
                </CustomButton>
              </div>
            </div>
          ) : null}
        </Modal>
      </Container>
    );
  }
}

Holdings.propTypes = {
  portfolio: PropTypes.object.isRequired,
  holdings: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hasExcludedHolding: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  printParams: PropTypes.object,
  esg: PropTypes.object,
  partnerName: PropTypes.string,
  id: PropTypes.string.isRequired,
};

Holdings.defaultProps = {
  printParams: {},
  esg: {},
  partnerName: '',
};

export default connect((state) => ({
  printParams: state.Analytics.printParams,
  esg: state.Analytics.esg,
  partnerName: state.Storage['partner-name'],
}))(Holdings);

const Container = styled.div`
  border-radius: 8px;
  width: 50%;
  background: white;
  margin-bottom: 50px;
`;

const Wrapper = styled.div`
  height: ${(props) => (props.limitHeight ? '430px' : 'auto')};
  overflow: auto;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

const ETFButton = styled(Button)`
  background-color: ${colors.teal} !important;
  color: rgba(0, 0, 0, 0.6) !important;
`;

const Value = styled.span`
  position: absolute;
  left: 0rem;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  transition: background 0.1s ease;
  z-index: 9999;
  &.long:hover {
    background: ${colors.orange};
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    width: auto;
    max-width: unset;
    white-space: normal;
    overflow: unset;
  }
`;
