import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Dropdown } from 'semantic-ui-react';
import cn from 'classnames';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class GeneralInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {},
      error: {},
    };
  }

  handleProvinceChange = (e, { value }) => {
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { prov: value },
        errors: { prov: false },
        modified: true,
      })
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown, cppDataset, oasDataset, cppEstimator } =
      this.props;

    const { inputs } = drawdown;

    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;
    if (
      (name === 'age' || name === 'horizon') &&
      (value.includes('.') || value.includes('-'))
    ) {
      return;
    }

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
      ];

      if (field === 'horizon') {
        const maxHorizon = 110;
        const sum = Number(val) + Number(inputs.age);
        if (Number(val) < 1) {
          this.setState({
            warning: {
              ...this.state.warning,
              horizon: 'Please enter a number greater than 0.',
            },
          });
          return val;
        }

        if (sum > maxHorizon) {
          this.setState({
            warning: {
              ...this.state.warning,
              horizon:
                'We restrict the sum of your age and planning horizon to be no more than 110 years.',
            },
          });
          return val;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) return 70;
      if (isOas && Number(age) < 65) return 65;
      if (Number(age) < 60) return 60;
      if (Number(age) > 70) return 70;
      return age;
    };

    // Detect age change and set error message if needed
    if (name === 'age') {
      const { cpp, oas } = inputs;
      const cppOrOasSet = cpp || oas;
      const newAge = Number(parsedValue(name, value));
      if (value < 49) {
        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { ...drawdown.inputs, age: value },
            errors: {
              ...drawdown.errors,
              age: 'Minimum age is 49',
            },
          })
        );
      }

      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(newAge, false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(newAge, true))
      ).amount;
      if (newAge < 60) {
        if (cppOrOasSet) {
          this.setState({
            error: {
              age: 'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                myCpp: true,
                myOas: true,
              },
            })
          );
        }
        this.changeSelection('cppStarted', false);
        this.changeSelection('oasStarted', false);
      } else if (newAge < 65) {
        if (cppOrOasSet) {
          this.setState({
            error: {
              age: 'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                myCpp: true,
                myOas: true,
              },
            })
          );
        }
        this.changeSelection('oasStarted', false);
        this.changeSelection('cppStarted', true);
      } else {
        if (cppOrOasSet) {
          this.setState({
            error: {
              age: 'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                myCpp: true,
                myOas: true,
              },
            })
          );
        }
        this.changeSelection('oasStarted', true);
        this.changeSelection('cppStarted', true);
      }

      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            age: newAge,
            ...(newAge > 60 && { cppAge: newAge }),
            ...(newAge > 65 && { oasAge: newAge }),
          },
          errors: {
            ...drawdown.errors,
            age: false,
            ...(newAge > 60 && inputs.cppAge && { cppAge: false }),
            ...(newAge > 65 && inputs.oasAge && { oasAge: false }),
            ...(inputs.oasStarted &&
              inputs.oas > oasMax && {
                oas: 'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs.cppStarted && inputs.cpp <= cppMax && { cpp: null }),
            ...(inputs.oasStarted && inputs.oas <= oasMax && { oas: null }),
            ...(!inputs.cppStarted && { cppAge: null }),
            ...(!inputs.oasStarted && { oasAge: null }),
          },
          modified: true,
        })
      );
    }

    if (name === 'cppAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { cppAge: parsedValue(name, value), cpp: '' },
          errors: { cppAge: false, cpp: false },
          modified: true,
        })
      );
    }

    if (name === 'oasAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oasAge: parsedValue(name, value), oas: '' },
          errors: { oasAge: false, oas: false },
          modified: true,
        })
      );
    }

    if (name === 'cpp') {
      if (!inputs.cppAge && inputs.cppStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();
      const body = {
        retirement_year:
          Number(currentYear) + Number(inputs.cppAge) - Number(inputs.age),
        start_cpp_age: inputs.cppAge,
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs.cppAge !== '' && inputs.cppAge >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, cpp: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cpp: money },
            errors: { cpp: false },
            modified: true,
          })
        );
      }
    }

    if (name === 'oas') {
      if (!inputs.oasAge && inputs.oasStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      if (!inputs.oasAge || inputs.oasAge > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs.oasAge < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs.oasAge)
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs.oasAge,
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, oas: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oas: money },
          errors: { oas: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;

    if (field === 'cppStarted') {
      if (drawdown.inputs.cppStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, cppAge: '', cpp: '' },
            errors: { cppAge: false, cpp: false },
            modified: true,
          })
        );

        return this.setState({ warning: { cpp: '' } });
      }
    }

    if (field === 'oasStarted') {
      if (drawdown.inputs.oasStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, oasAge: '', oas: '' },
            errors: { oasAge: false },
            modified: true,
          })
        );

        return this.setState({ warning: { oas: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  render() {
    const { warning } = this.state;
    const { inputs, errors } = this.props.drawdown;
    const { error } = this.state;

    return (
      <div>
        <Section single>
          <p
            style={{
              margin: 0,
              transform: 'translateY(-1px)',
              display: 'block',
              fontSize: '1rem',
              fontStyle: 'normal',
              color: `${colors.red}`,
            }}
            visible={
              typeof warning.horizon === 'string' ||
              typeof errors.horizon === 'string'
            }
          >
            {!inputs.horizon ||
            !inputs.age ||
            inputs.prov === undefined ||
            inputs.prov === null ? (
              <React.Fragment>
                Please complete the Personal Information section to proceed with
                your plan
              </React.Fragment>
            ) : null}
          </p>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div className="title">
              Current age
              <p
                style={{
                  margin: 0,
                  transform: 'translateY(-1px)',
                  display: 'block',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  color: `${colors.red}`,
                }}
                visible={typeof errors.age === 'string' || error.age}
              >
                {errors.age || error.age}
              </p>
            </div>
            <Input
              className={cn('large', {
                invalid: errors.age ? 'true' : undefined,
              })}
              name="age"
              type="number"
              placeholder="years"
              value={inputs.age || ''}
              onChange={this.handleChange}
            />
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div className="title">
              Planning for how many years?
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="top center"
                content="e.g. if you are 65 and expect you will live until 85, then input 20 years."
              />
              (
              <a
                href="https://www.projectbiglife.ca/life-expectancy-home"
                target="_blank"
                rel="noopener noreferrer"
              >
                Project Big Life
              </a>
              )
              <p
                style={{
                  margin: 0,
                  transform: 'translateY(-1px)',
                  display: 'block',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  color: `${colors.red}`,
                }}
                visible={
                  typeof warning.horizon === 'string' ||
                  typeof errors.horizon === 'string'
                }
              >
                {warning.horizon || errors.horizon ? (
                  <React.Fragment>
                    {warning.horizon || errors.horizon}
                  </React.Fragment>
                ) : null}
              </p>
            </div>
            <Input
              className={cn('large', {
                invalid: errors.horizon || warning.horizon ? 'true' : undefined,
              })}
              name="horizon"
              type="number"
              placeholder="years"
              value={inputs.horizon || ''}
              onChange={this.handleChange}
              onFocus={() =>
                this.setState({
                  warning: { ...this.state.warning, horizon: null },
                })
              }
              onBlur={() =>
                this.setState({
                  warning: { ...this.state.warning, horizon: null },
                })
              }
              onClick={() =>
                this.setState({
                  warning: { ...this.state.warning, horizon: null },
                })
              }
            />
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div className="title">
              Province of residence{' '}
              <p
                style={{
                  margin: 0,
                  transform: 'translateY(-1px)',
                  display: 'block',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  color: `${colors.red}`,
                }}
                visible={typeof errors.prov === 'string'}
              >
                {errors.prov}
              </p>
            </div>
            <StyledDropdown
              className={cn('large', {
                invalid: errors.prov ? 'true' : undefined,
              })}
              placeholder="Province"
              selection
              options={[
                { key: 'AB', value: 'AB', text: 'Alberta' },
                { key: 'BC', value: 'BC', text: 'British Columbia' },
                { key: 'MB', value: 'MB', text: 'Manitoba' },
                { key: 'NB', value: 'NB', text: 'New Brunswick' },
                { key: 'NL', value: 'NL', text: 'Newfoundland & Labrador' },
                { key: 'NS', value: 'NS', text: 'Nova Scotia' },
                { key: 'ON', value: 'ON', text: 'Ontario' },
                { key: 'PE', value: 'PE', text: 'Prince Edward Island' },
                { key: 'QC', value: 'QC', text: 'Quebec' },
                { key: 'SK', value: 'SK', text: 'Saskatchewan' },
              ]}
              value={inputs.prov}
              onChange={this.handleProvinceChange}
            />
          </div>
        </Section>
      </div>
    );
  }
}

GeneralInformation.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  cppEstimator: PropTypes.object,
};

GeneralInformation.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  cppDataset: [],
  oasDataset: [],
  cppEstimator: {},
};

const Section = styled.section`
  display: flex; /* Add this line */
  flex-direction: column;
  padding-left: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Input = styled.input`
  max-height: 30px;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: unset !important;
  min-height: unset !important;
  max-height: 30px;
  padding: 0.5rem !important;
  min-width: unset !important;
  width: 35% !important;

  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }

  i {
    padding: 0.5rem !important;
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }
`;
