import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Popup, Icon } from 'semantic-ui-react';
import cn from 'classnames';

import { colors, nbColors } from 'utils/colors';

const Selection = ({
  text,
  popuptext,
  selected,
  changeSelection,
  popupBottom,
  french,
  isNB,
  ...rest
}) => (
  <StyledSelection {...rest}>
    <div className="text">
      {text}
      {popuptext && (
        <Popup
          trigger={
            <Icon
              name="question circle outline"
              style={{
                fontSize: '15px',
                verticalAlign: 'initial',
                color: 'dimgrey',
              }}
            />
          }
          position={popupBottom ? 'bottom center' : 'top center'}
          wide
          content={popuptext}
        />
      )}
    </div>

    <OptionsWrapper isNB={isNB}>
      <div
        className={cn('select', { active: selected })}
        onClick={() => changeSelection(true)}
        style={{
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        }}
      >
        {french ? 'Oui' : 'Yes'}
      </div>
      <div
        className={cn('select', { active: !selected })}
        onClick={() => changeSelection(false)}
        style={{
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        {french ? 'Non' : 'No'}
      </div>
    </OptionsWrapper>
  </StyledSelection>
);

Selection.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  changeSelection: PropTypes.func,
  popuptext: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  popupBottom: PropTypes.bool,
  french: PropTypes.bool,
  isNB: PropTypes.bool,
};

Selection.defaultProps = {
  popuptext: false,
  changeSelection: () => {},
  popupBottom: false,
  french: false,
  isNB: false,
};

export default Selection;

const StyledSelection = styled.div`
  position: relative;
  width: 100%;
  margin: 0.5rem 0;
  min-height: 32px;

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}

  .text {
    width: calc(100% - 100px);
  }

  img {
    height: 17px;
    width: 17px;
    vertical-align: middle;
    margin: 0 0 2px 7px;
    transform: rotate(43deg);
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: calc((28px - 22px) / 2) 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .select {
    display: inline-block;
    padding: 0.1rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }

    &.active {
      box-shadow: none;
      background-color: ${(props) =>
        props.isNB ? nbColors.blue : colors.blue};
      color: white;
    }
  }
`;
