import { request } from './api';

import * as Alert from './alert';
import * as FundComparison from './fundcomparison';

/**
 * Action constants
 */
export const CHART_DATA_REQUEST = 'CHART_DATA_REQUEST';

export const CHART_DATA_FETCH_SUCCESS = 'CHART_DATA_FETCH_SUCCESS';

export const CHART_UPDATE_INPUTS = 'CHART_UPDATE_INPUTS';

export const CHART_FAIL = 'CHART_FAIL';

export const CHART_DATA_CLEAR = 'CHART_DATA_CLEAR';

export const CHART_PARTIAL_FAIL = 'CHART_PARTIAL_FAIL';

export const CHART_TOOL_CLEAR = 'CHART_TOOL_CLEAR';

/**
 * Action creators
 */
export function fetchChartData(info, portfolioList, region) {
  return (dispatch) => {
    dispatch({ type: CHART_DATA_REQUEST });

    return dispatch(
      request('post', '/portfolio/chart-tool/', {
        headers: JSON.stringify({ 'Content-Type': 'applications/json' }),
        body: {
          period_start_date: info.periodStart,
          period_end_date: info.periodEnd,
          compare_security_objects: info.securityList,
          compare_portfolio_objects: info.portfolioList,
          region: region || 'CA',
        },
      })
    ).then(
      (data) => {
        if (data.oldest_end_date === '') {
          if (Number(data.error_entity)) {
            const portName = portfolioList.find(
              (item) => item.id === Number(data.error_entity)
            ).name;

            dispatch(
              Alert.showLong({
                type: 'error',
                msg: `Could not retrieve chart data for ${portName}.`,
              })
            );
          } else {
            dispatch(
              Alert.showLong({
                type: 'error',
                msg: `Could not retrieve chart data for ${data.error_entity}.`,
              })
            );
            dispatch(FundComparison.addChartErrorList(data.error_entity));
          }

          return dispatch({ type: CHART_PARTIAL_FAIL, data });
        }

        return dispatch({ type: CHART_DATA_FETCH_SUCCESS, data });
      },
      () => {
        dispatch(
          Alert.showLong({
            type: 'error',
            msg: 'Server Error. Chart data could not be retrieved.',
          })
        );
        return dispatch({ type: CHART_FAIL });
      }
    );
  };
}

export function clearChartData() {
  return (dispatch) => {
    dispatch({ type: CHART_DATA_CLEAR });
  };
}

export function updateInputs(inputs) {
  return (dispatch) => {
    dispatch({ type: CHART_UPDATE_INPUTS, inputs });
  };
}

// Clears all local chart data when leaving client mode
export function clearChartTool() {
  return { type: CHART_TOOL_CLEAR };
}
