/* eslint-disable react/jsx-fragments */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Icon,
  Dropdown,
  Input,
  Header,
  Form,
  Radio,
  Popup,
} from 'semantic-ui-react';
import cn from 'classnames';
import styled from 'styled-components';

import { Accounts, Planning, Portfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import { AccountsDropdown, Message, Button } from 'components';
import { filterOutKeysFromObject, getProvinceCode } from 'utils/helpers';
import { NavigationButtons } from '../components';
import Result from './Result';
import numSubpagesInEachSection from '../numSubPages';

@withRouter
class Saving extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      updated: false,
      numSubPages: numSubpagesInEachSection.targetSaving,
      resultIsDisabled: false,
      isSpouse: false,
    };
  }

  componentWillMount() {
    const { dispatch, history, match, data, modelPortfolios } = this.props;
    const { currentSubPage } = data.targetSaving || {};
    const { numSubPages } = this.state;

    const modifiedTargetSavingData = {
      currentSubPage: currentSubPage
        ? Math.min(numSubPages, currentSubPage)
        : 1,
    };

    const isChangedFromPrevious = () => {
      const { cacheInputChanged, cachePDChanged, cacheRadioChanged } =
        this.props;
      if (match.params.id === 'new') {
        return false;
      }
      return cacheInputChanged || cachePDChanged || cacheRadioChanged;
    };
    if (isChangedFromPrevious()) {
      this.setState({ resultIsDisabled: true });
    }

    dispatch(
      Planning.updateBlueprintData('targetSaving', modifiedTargetSavingData)
    );

    dispatch(
      Planning.updateCompletedSectionsPageNumbers('targetSaving', {
        ...data, // ensure we are using most recent data (store might not update in time)
        targetSaving: {
          ...data.targetSaving,
          ...modifiedTargetSavingData,
        },
      })
    );

    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    if (!modelPortfolios.length) {
      dispatch(Portfolio.fetchModel());
    }

    if (!data || !data.targetSaving) {
      return history.push(`/planning/blueprint/${match.params.id}/overview`);
    }

    return false;
  }

  componentDidMount() {
    const {
      dispatch,
      data,
      cacheTS,
      cacheSR,
      cacheRadioChanged,
      cacheTargetChanged,
    } = this.props;

    dispatch(Accounts.fetchAll());

    if (!cacheTS) {
      dispatch(Storage.setItem('cacheTS', data.targetSaving));
    }

    if (!cacheSR) {
      dispatch(Storage.setItem('cacheSR', data.savingResult));
    }

    if (cacheRadioChanged || cacheTargetChanged) {
      this.setState({ resultIsDisabled: true });
    }
  }

  componentDidUpdate(prevProp) {
    const { dispatch, data } = this.props;
    if (prevProp.data && data) {
      const excludedKeys = ['currentSubPage'];

      const prev = filterOutKeysFromObject(
        prevProp.data.targetSaving,
        excludedKeys
      );
      const curr = filterOutKeysFromObject(data.targetSaving, excludedKeys);
      if (
        JSON.stringify(prev, Object.keys(prev).sort()) !==
        JSON.stringify(curr, Object.keys(prev).sort())
      ) {
        dispatch(Planning.clearBlueprintSection('summary'));
      }
    }
  }

  componentWillUnmount() {
    const { history, data, dispatch } = this.props;

    if (history.location.pathname.includes('blueprint')) {
      dispatch(Storage.setItem('cacheTS', data.targetSaving));
    }

    dispatch(Storage.removeItem('cacheRadioChanged'));
    dispatch(Storage.removeItem('cacheTargetChanged'));
  }

  getDropOptions = () => {
    const { modelPortfolios, userPortfolios } = this.props;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return modelPortfolios.concat(userPortfolios).map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      text: portf.name,
    }));
  };

  getNewPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    if (!port || !port.init_date) {
      return false;
    }
    const date = new Date(port.init_date);
    const today = new Date();
    const monthDifference =
      today.getMonth() -
      date.getMonth() +
      12 * (today.getFullYear() - date.getFullYear());
    if (monthDifference > 60) {
      return false;
    }

    const init = date.toDateString().toString().split(/\s+/);

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sept: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    return `${months[init[1]]} ${init[3]}`;
  };

  isNewBlueprint = () => {
    const { match } = this.props;
    return match.params.id === 'new';
  };

  handlePreviousPage = () => {
    const { match, history } = this.props;
    return history.push(`/planning/blueprint/${match.params.id}/target_wealth`);
  };

  isCalculateDisabled = () => {
    const { match, data } = this.props;
    const { savingResult } = data || {};
    const { initAccList, initFromAcc } = data.targetSaving || {};
    const { resultIsDisabled } = this.state;

    if (initAccList && initAccList.length === 0 && initFromAcc) return true;
    if (match.params.id === 'new' || resultIsDisabled) {
      return false;
    }

    return savingResult;
  };

  handlePortfolioClick = () => {
    const { dispatch, cacheSavingChanged } = this.props;
    if (!cacheSavingChanged) {
      dispatch(Storage.setItem('cacheSavingChanged', true));
    }

    return dispatch(Planning.clearBlueprintSection('savingResult'));
  };

  handleSubmit = () => {
    const {
      dispatch,
      modelPortfolios,
      userPortfolios,
      data,
      is_wealthica_trial,
    } = this.props;
    const { targ, horz } = data.targetWealth;
    const {
      init,
      savings_portfolio,
      annual_income,
      rrsp_room,
      tfsa_room,
      annual_income2,
      rrsp_room2,
      tfsa_room2,
    } = data.targetSaving;
    const { province } = data.personalDetails;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const isCouplePlan = is_couple_plan === 'true';
    const selectedPortfolio = modelPortfolios
      .concat(userPortfolios)
      .find((port) => Number(port.id) === Number(savings_portfolio));

    this.setState({ resultIsDisabled: false });
    if (!init && init !== 0) {
      return this.setState({ errors: { init: 'This field is required' } });
    }
    if (!selectedPortfolio) {
      return this.setState({
        errors: { portfolio: 'Please select a portfolio' },
      });
    }

    if (!annual_income && annual_income !== 0) {
      this.setState({ isSpouse: false });
      return this.setState({
        errors: { annual_income: 'This field is required' },
      });
    }

    if (isCouplePlan && !annual_income2 && annual_income2 !== 0) {
      this.setState({ isSpouse: true });
      return this.setState({
        errors: { annual_income2: 'This field is required' },
      });
    }

    const params = {
      symbols: selectedPortfolio.symbols,
      weights: selectedPortfolio.weights
        .split(',')
        .reduce((weights, w) => {
          weights.push(Number(w).toFixed(4));

          return weights;
        }, [])
        .join(','),
      region: selectedPortfolio.region,
      gic_info: selectedPortfolio.gic_info,
      init_date: selectedPortfolio.init_date,
      targ,
      init,
      horz,
      annual_income,
      annual_income2,
      rrsp_room,
      rrsp_room2,
      tfsa_room,
      tfsa_room2,
      prov: getProvinceCode(province),
    };

    const analyzeFn = Planning.analyzeBlueprintSaving;

    return dispatch(analyzeFn(params)).then((savingResult) => {
      // this.scrollHandler(
      //   document.getElementById('saving-ref').offsetHeight + 100,
      //   600
      // );

      dispatch(Storage.removeItem('cacheTW'));
      dispatch(Storage.setItem('cacheTW', data.targetWealth));
      dispatch(Storage.removeItem('cacheWR'));
      dispatch(Storage.setItem('cacheWR', data.wealthResult));
      dispatch(Storage.removeItem('cacheTS'));
      dispatch(Storage.setItem('cacheTS', data.targetSaving));
      dispatch(Storage.removeItem('cacheSR'));
      dispatch(Storage.setItem('cacheSR', savingResult.result));
      dispatch(
        Planning.updateBlueprintData('targetSaving', {
          monthlySavingChosen: null,
        })
      );

      this.setState({ updated: true });

      if (is_wealthica_trial) dispatch(Planning.fetchWealthicaUser());
    });
  };

  handlePortfolioChange = (e, { value }) => {
    const { dispatch, cacheSavingChanged } = this.props;
    if (!cacheSavingChanged) {
      dispatch(Storage.setItem('cacheSavingChanged', true));
    }

    dispatch(Planning.clearBlueprintSection('savingResult'));
    dispatch(
      Planning.updateBlueprintData('targetSaving', {
        savings_portfolio: value,
      })
    );

    this.setState((prevState) => ({
      errors: { ...prevState.errors, portfolio: null },
    }));
  };

  handleChange = (e, { name, value }) => {
    const { dispatch, cacheSavingChanged } = this.props;

    const limitRegex = /^[0-9]{1,9}$/;

    if (value !== '' && !limitRegex.test(value)) return;

    if (!cacheSavingChanged) {
      dispatch(Storage.setItem('cacheSavingChanged', true));
    }

    dispatch(Planning.clearBlueprintSection('savingResult'));

    dispatch(
      Planning.updateBlueprintData('targetSaving', {
        [name]: value ? Number(value) : undefined,
      })
    );
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [name]: null },
    }));
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  renderCoupleSwitcher = () => {
    const { data } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { isSpouse } = this.state;
    return (
      is_couple_plan === 'true' && (
        <OptionsWrapper>
          <div
            className={cn('select', { active: !isSpouse })}
            onClick={() => {
              this.setState({ isSpouse: false });
            }}
            style={{
              fontSize: '1.2rem',
              padding: '6px',
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            You
          </div>
          <div
            className={cn('select', { active: isSpouse })}
            onClick={() => {
              this.setState({ isSpouse: true });
            }}
            style={{
              fontSize: '1.2rem',
              padding: '6px',
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            Spouse
          </div>
        </OptionsWrapper>
      )
    );
  };

  render() {
    const {
      match,
      history,
      isFetching,
      data,
      modelPortfolios,
      userPortfolios,
      accountList,
      dispatch,
      wealthicaUser,
      is_wealthica_trial,
      cacheSavingChanged,
    } = this.props;
    const { errors, updated, isSpouse, resultIsDisabled } = this.state;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const {
      init,
      initAccList,
      initFromAcc,
      savings_portfolio,
      monthlySavingChosen,
      annual_income,
      rrsp_room,
      tfsa_room,
      annual_income2,
      rrsp_room2,
      tfsa_room2,
    } = data.targetSaving || {};
    const allPortfolios = modelPortfolios.concat(userPortfolios);
    const selectedPortfolio = allPortfolios.find(
      (port) => Number(port.id) === Number(savings_portfolio)
    );
    const selectedPortfolioName = selectedPortfolio
      ? selectedPortfolio.name
      : '';
    const selectedPortfoDate = this.getNewPortDate(selectedPortfolio);
    const isCouplePlan = is_couple_plan === 'true';
    const { planning_restrictions } = wealthicaUser;
    const wealthicaTrialAllowed = is_wealthica_trial
      ? planning_restrictions && planning_restrictions.blueprint_allowed
      : true;

    return (
      <div style={{ backgroundColor: colors.lightGrey, paddingBottom: '5rem' }}>
        <div
          style={{
            backgroundColor: colors.white,
            marginBottom: '2rem',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <BlueprintContent id="saving-ref">
            <Header size="large">Target Saving</Header>
            <Section>
              <div
                className="title"
                style={{ width: '100%', marginBottom: '1.5rem' }}
              >
                Here you will determine how much money you will need to{' '}
                {isCouplePlan ? 'collectively ' : null}save each month to reach
                your target wealth.
              </div>

              <BlueprintGrid>
                <div className="grid-item item1">
                  <div className="title">
                    {isCouplePlan
                      ? 'Please enter the amount you and your spouse have saved for retirement to date:'
                      : 'Please enter the amount you have saved for retirement to date:'}
                    <Error>{errors.init}</Error>
                    <Form>
                      <Form.Field>
                        <Radio
                          name="initFromAcc"
                          checked={!initFromAcc}
                          onChange={() => {
                            dispatch(
                              Planning.updateBlueprintData('targetSaving', {
                                init: null,
                                initFromAcc: false,
                                initAccList: [],
                              })
                            );
                            if (!cacheSavingChanged) {
                              Storage.setItem('cacheSavingChanged', true);
                            }
                          }}
                          label="Input Value"
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          name="initFromAcc"
                          checked={initFromAcc}
                          onChange={() => {
                            dispatch(
                              Planning.updateBlueprintData('targetSaving', {
                                init: null,
                                initFromAcc: true,
                                initAccList: [],
                              })
                            );
                            if (!cacheSavingChanged) {
                              Storage.setItem('cacheSavingChanged', true);
                            }
                          }}
                          label="Select From Accounts"
                        />
                      </Form.Field>
                    </Form>
                  </div>
                </div>
                <div className="grid-item item2">
                  <div style={{ width: '200px' }}>
                    {initFromAcc ? (
                      <AccountsDropdown
                        inputs={{}}
                        accountList={accountList}
                        selectedAccList={initAccList || []}
                        excludedList={[]}
                        savedAccountsValue={init || 0}
                        assignAccountsValue={(
                          selectedAccounts,
                          accountsValue
                        ) => {
                          dispatch(
                            Planning.clearBlueprintSection('savingResult')
                          );

                          dispatch(
                            Planning.updateBlueprintData('targetSaving', {
                              init: accountsValue,
                              initAccList: selectedAccounts,
                            })
                          );

                          this.setState({
                            errors: {
                              ...errors,
                              init: accountsValue ? null : errors.init,
                            },
                          });
                        }}
                        float={false}
                        error={errors.init}
                        height={40}
                        zIndex={0}
                      />
                    ) : (
                      <Input
                        name="init"
                        value={init === 0 ? '0' : init || ''}
                        label={{ basic: true, content: '$' }}
                        onChange={this.handleChange}
                        className={cn('', { invalid: errors.init })}
                        style={{ height: '40px', width: '83%' }}
                      />
                    )}
                  </div>
                </div>
                <div className="grid-item item3">
                  <div className="title">
                    Which portfolio will you be investing in from now until
                    retirement?
                  </div>
                  {allPortfolios.length === 0 && (
                    <div style={{ marginBottom: '5px', color: 'red' }}>
                      You currently have no saved portfolios.
                    </div>
                  )}
                  <div
                    style={{
                      fontStyle: 'italic',
                      width: '500px',
                    }}
                  >
                    <div>
                      Saved portfolios from our Portfolio Builders and those
                      saved from Accounts will appear in the dropdown menu.
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{ color: 'black', fontSize: '15px' }}
                          />
                        }
                        position="right center"
                        content="To save an account for use in Planning, click 'Analyze' and then the 'disk' icon on the left-hand-side of the Scorecard."
                      />
                    </div>
                    <Error>{errors.portfolio}</Error>
                  </div>
                </div>
                <div className="grid-item item4">
                  <div style={{ width: '200px' }}>
                    <StyledDropdown
                      placeholder="Select a portfolio"
                      selection
                      disabled={allPortfolios.length === 0}
                      options={this.getDropOptions()}
                      selectOnBlur={false}
                      onChange={this.handlePortfolioChange}
                      onClick={this.handlePortfolioClick}
                      text={selectedPortfolioName}
                      value={selectedPortfolioName ? true : ''}
                      fluid
                      style={{ width: '100%', height: '40px' }}
                      error={errors.portfolio && errors.portfolio.length > 0}
                    />
                  </div>
                </div>

                <React.Fragment>
                  <div className="grid-item item5">
                    <div
                      className="titleTax"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        maxWidth: '58.5rem',
                      }}
                    >
                      The following two questions help us calculate your tax
                      implications for next year:
                      <div>{this.renderCoupleSwitcher()}</div>
                    </div>
                  </div>
                  <div className="grid-item item6">
                    <div className="title">
                      What is your current annual income before tax?{' '}
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="right center"
                        content="To determine your tax brackets."
                      />
                      <Error>
                        {errors.annual_income || errors.annual_income2}
                      </Error>
                    </div>
                  </div>
                  <div className="grid-item item7">
                    <div style={{ width: '200px' }}>
                      <Input
                        name={isSpouse ? 'annual_income2' : 'annual_income'}
                        value={
                          isSpouse
                            ? annual_income2 === 0
                              ? '0'
                              : annual_income2 || ''
                            : annual_income === 0
                            ? '0'
                            : annual_income || ''
                        }
                        label={{ basic: true, content: '$' }}
                        onChange={this.handleChange}
                        className={cn('', {
                          invalid:
                            (isSpouse && errors.annual_income2) ||
                            (!isSpouse && errors.annual_income)
                              ? 'true'
                              : undefined,
                        })}
                        style={{ height: '40px', width: '83%' }}
                      />
                    </div>
                  </div>
                  <div className="grid-item item8">
                    <div className="contributionTitle">
                      Contribution room in this coming year
                    </div>
                    <div
                      style={{
                        fontStyle: 'italic',
                        width: '420px',
                      }}
                    >
                      Please also enter "0" if you do not plan to contribute to
                      a specific account
                    </div>
                  </div>
                  <div className="grid-item item9">
                    <div style={{ display: 'flex' }}>
                      <div className="labelContainer">
                        <div
                          className="rrspLabel"
                          style={{
                            height: '40px',
                            margin: '1rem',
                            width: '83%',
                            fontWeight: 'bold',
                          }}
                        >
                          RRSP
                        </div>
                        <div
                          className="tfsaLabel"
                          style={{
                            height: '40px',
                            margin: '1rem',
                            width: '83%',
                            fontWeight: 'bold',
                          }}
                        >
                          TFSA
                        </div>
                      </div>
                      <div className="inputContainer">
                        <div style={{ width: '200px' }}>
                          <Input
                            style={{
                              height: '40px',
                              marginBottom: '1rem',
                              width: '83%',
                            }}
                            name={isSpouse ? 'rrsp_room2' : 'rrsp_room'}
                            value={
                              isSpouse
                                ? rrsp_room2 === 0
                                  ? '0'
                                  : rrsp_room2 || ''
                                : rrsp_room === 0
                                ? '0'
                                : rrsp_room || ''
                            }
                            label={{ basic: true, content: '$' }}
                            onChange={this.handleChange}
                            className={cn('', {
                              invalid: isSpouse
                                ? errors.rrsp_room2
                                  ? 'true'
                                  : undefined
                                : errors.rrsp_room
                                ? 'true'
                                : undefined,
                            })}
                          />
                        </div>
                        <div style={{ width: '200px' }}>
                          <Input
                            style={{ height: '40px', width: '83%' }}
                            name={isSpouse ? 'tfsa_room2' : 'tfsa_room'}
                            value={
                              isSpouse
                                ? tfsa_room2 === 0
                                  ? '0'
                                  : tfsa_room2 || ''
                                : tfsa_room === 0
                                ? '0'
                                : tfsa_room || ''
                            }
                            label={{ basic: true, content: '$' }}
                            onChange={this.handleChange}
                            className={cn('', {
                              invalid: isSpouse
                                ? errors.tfsa_room2
                                  ? 'true'
                                  : undefined
                                : errors.tfsa_room
                                ? 'true'
                                : undefined,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </BlueprintGrid>
              <Message
                icon
                visible={!!selectedPortfoDate}
                warning
                style={{
                  margin: '1rem auto 0',
                  maxWidth: '45rem',
                  textAlign: 'left',
                }}
              >
                <Icon name="exclamation" />
                <Message.Content>
                  This portfolio has a start date of{' '}
                  <strong>{selectedPortfoDate}</strong>. You may wish to use a
                  portfolio with a longer history. You can use the ETF Portfolio
                  Builder in the "Portfolios" segment. Once saved, the portfolio
                  will appear in the dropdown list here.
                </Message.Content>
              </Message>
            </Section>
            <Button
              id="calc-button"
              className={cn('centered right', {
                loading: isFetching,
                disabled: this.isCalculateDisabled() || !wealthicaTrialAllowed,
              })}
              color={colors.teal}
              style={{
                width: resultIsDisabled && '10rem',
              }}
              icon="calculator"
              onClick={this.handleSubmit}
              popup={
                !wealthicaTrialAllowed
                  ? {
                      position: 'top center',
                      content:
                        'You have reached the trial period limit. Once the trial period is complete, you can save up to 5 Blueprints.',
                      wide: true,
                    }
                  : null
              }
            >
              {resultIsDisabled && !this.isNewBlueprint()
                ? 'Re-calculate'
                : 'Calculate'}
            </Button>
            <NavigationButtons
              showNextButton={false}
              handleNextPage={this.handleNextPage}
              handleSubmit={this.handleSubmit}
              handlePreviousPage={this.handlePreviousPage}
            />
          </BlueprintContent>
        </div>
        {!resultIsDisabled && (
          <Result
            result={data.savingResult}
            monthlySavingChosen={monthlySavingChosen}
            isFetching={isFetching}
            data={data}
            history={history}
            match={match}
            updated={updated}
            isCouplePlan={isCouplePlan}
          />
        )}
      </div>
    );
  }
}

Saving.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modelPortfolios: PropTypes.array,
  userPortfolios: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  cacheTS: PropTypes.object,
  cacheSR: PropTypes.object,
  accountList: PropTypes.array,
  wealthicaUser: PropTypes.object,
  is_wealthica_trial: PropTypes.bool,
  cacheSavingChanged: PropTypes.bool,
  cacheInputChanged: PropTypes.bool,
  cachePDChanged: PropTypes.bool,
  cacheRadioChanged: PropTypes.bool,
  cacheTargetChanged: PropTypes.bool,
};

Saving.defaultProps = {
  modelPortfolios: [],
  userPortfolios: [],
  history: {},
  match: {},
  cacheTS: null,
  cacheSR: null,
  accountList: [],
  wealthicaUser: {},
  is_wealthica_trial: false,
  cacheSavingChanged: false,
  cacheInputChanged: false,
  cachePDChanged: false,
  cacheRadioChanged: false,
  cacheTargetChanged: false,
};

export default connect((state) => ({
  isFetching: state.Planning.isFetching,
  modelPortfolios: state.Portfolio.model,
  userPortfolios: state.Portfolio.user,
  cacheTS: state.Storage.cacheTS,
  cacheSR: state.Storage.cacheSR,
  accountList: state.Accounts.accountList,
  wealthicaUser: state.Planning.wealthicaUser,
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  cacheSavingChanged: state.Storage.cacheSavingChanged,
  cacheInputChanged: state.Storage.cacheInputChanged,
  cachePDChanged: state.Storage.cachePDChanged,
  cacheRadioChanged: state.Storage.cacheRadioChanged,
  cacheTargetChanged: state.Storage.cacheTargetChanged,
}))(Saving);

const OptionsWrapper = styled.div`
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }
  .custom {
    top: 20px;
    right: 10px;
  }
  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }
    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const BlueprintGrid = styled.div`
  display: grid;
  grid-template-columns: 500px 300px;
  grid-template-rows: auto auto auto auto auto;
  text-align: left;
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1rem;
    font-size: 14px;
  }
  .item1 {
    place-self: center start;
  }
  .item2 {
    place-self: center end;
  }
  .item4 {
    place-self: center end;
  }
  .item5 {
    grid-column: 1 / span 2;
    padding-bottom: 0;
  }
  .item7 {
    place-self: center end;
  }
  .item9 {
    place-self: center end;
  }
  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }
  .titleTax {
    width: 100%;
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }
  .you-spouse {
    font-size: 1.2rem;
    color: rgb(108, 135, 252);
  }
  .contributionTitle {
    width: 460px;
    font-size: 1.2rem;
    margin: 1rem 0;
  }
`;

const BlueprintContent = styled.div`
  padding: 2rem 3rem;
  background-color: ${colors.white};
  border-radius: 8px;

  #calc-button {
    position: absolute;
    left: calc(50% - 57px);
    width: 120px;
    margin-top: 0rem;
  }
`;

const Section = styled.section`
  padding: 1rem;
  padding-top: 0;
  position: relative;
  text-align: left;
  margin: 0 auto 2rem;
  transition: all 200ms ease;

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .invalid {
    input {
      border: 1px solid ${colors.red} !important;
      background: rgb(255, 240, 240) !important;
    }
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }
  .titleTax {
    width: 100%;
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }
  .you-spouse {
    font-size: 1.2rem;
    color: rgb(108, 135, 252);
  }
  .contributionTitle {
    width: 460px;
    margin: 14px 0;
    font-size: 1.2rem;
  }
  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Error = styled.div`
  font-size: 1rem;
  font-style: normal;
  color: ${colors.red};
  padding-bottom: 0.5rem;
  height: 1.5rem;
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  .menu {
    height: 150px;
  }
`;
