/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import { ComparePortfolio } from 'actions';

const initialState = {
  addedCheckWeight: 0,
  snapshotCheckWeight: 0,
  checkedTickers: [],
  replacementTickers: [],
  isFetching: true,
  isFetchingSearchResults: false,
  search: [],
  portfolio: {},
  originalPortfolio: {},
  addGicToggle: false,
  addBtcToggle: false,
  advisorFee: null,
  gic_new_generic_ticker: null, // the GIC#:MKT that the user will be editing in what-if
  original: {
    gic_info: {},
  },
  name: null,
  gic_info: {},
  isWhatIf: false,
  barCompare: false,
  printLetterGrade: true,
  analyticsSnapshot: {},
  initialSnapshot: false,
  initialAnalyticsSnapshot: {},
  initialPortfolio: {},
  snapshotAfterReplace: {},
  snapshotLoad: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ComparePortfolio.COMPAREPORTFOLIO_RESCALE:
      Number.prototype.round = function (places) {
        return +`${Math.round(`${this}e+${places}`)}e-${places}`;
      };

      const portfolioKeys = Object.keys(state.portfolio);

      // Check if the weight received is zero
      if (action.weight === 100) {
        return {
          ...state,
          portfolio: portfolioKeys.reduce((res, key) => {
            res[key] = {
              ...state.portfolio[key],
              weight: (100 / portfolioKeys.length).round(2),
            };
            return res;
          }, {}),
        };
      }
      return {
        ...state,
        portfolio: portfolioKeys.reduce((res, key) => {
          res[key] = {
            ...state.portfolio[key],
            weight: (
              ((state.portfolio[key].weight || 0) / (100 - action.weight)) *
              100
            ).round(2),
          };
          return res;
        }, {}),
      };

    case ComparePortfolio.COMPAREPORTFOLIO_LOAD_SNAPSHOT_AFTER_REPLACE:
      const mySnapshot = state.snapshotAfterReplace;
      const newReplacementTickers = state.replacementTickers.filter(
        (e) => Object.hasOwn(mySnapshot, e) && mySnapshot[e].isReplaceWhatIf
      );
      return {
        ...state,
        replacementTickers: newReplacementTickers,
        portfolio: {
          ...mySnapshot,
        },
        snapshotLoad: true,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_TAKE_SNAPSHOT_AFTER_REPLACE:
      return {
        ...state,
        snapshotAfterReplace: action.portfolio,
        snapshotLoad: false,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_UNDO_INITIAL_PORTFOLIO:
      return {
        ...state,
        portfolio: state.initialPortfolio,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_PORTFOLIO_INITIAL_SNAPSHOT:
      return {
        ...state,
        initialPortfolio: action.portfolio,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_ANALYTICS_INITIAL_SNAPSHOT:
      return {
        ...state,
        initialSnapshot: action.value,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_TAKE_INITIAL_ANALYTICS:
      return {
        ...state,
        initialAnalyticsSnapshot: action.analytics,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_SEARCH_REQUEST:
      return {
        ...state,
        isFetchingSearchResults: true,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_ADD_SUCCESS:
      const originalPortfolio = state.portfolio;
      const myPortfolio = Object.keys(state.portfolio).reduce(function (
        acc,
        val
      ) {
        if (state.portfolio[val].isToggled !== true)
          acc[val] = state.portfolio[val];
        return acc;
      },
      {});
      const repeatElement =
        Object.keys(state.portfolio).find(
          (item) => item === action.security.ticker
        ) || [];
      const repeatElementWeight =
        repeatElement.length > 0 ? state.portfolio[repeatElement].weight : 0;
      return !action.security
        ? { ...state, isFetching: false }
        : {
            ...state,
            isFetching: false,
            originalPortfolio,
            portfolio: {
              ...myPortfolio,
              [action.security.ticker]: {
                ...action.security,
                isReplaceWhatIf: state.addedCheckWeight < 0 ? true : false,
                isToggled: false,
                weight:
                  state.addedCheckWeight < 0
                    ? Math.round(
                        -(
                          state.addedCheckWeight -
                          repeatElementWeight +
                          Number.EPSILON
                        ) * 100
                      ) / 100
                    : 0,
              },
            },
            replacementTickers:
              state.addedCheckWeight < 0
                ? [...state.replacementTickers, action.security.ticker]
                : state.replacementTickers,
            addedCheckWeight: 0,
            checkedTickers: [],
          };
    case ComparePortfolio.COMPAREPORTFOLIO_UNDO_BARCOMPARE:
      return {
        ...state,
        portfolio: state.initialPortfolio,
        addedCheckWeight: 0,
        replacementTickers: [],
      };
    case ComparePortfolio.COMPAREPORTFOLIOUPDATECHECK:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [action.ticker]: {
            ...state.portfolio[action.ticker],
            // eslint-disable-next-line no-unneeded-ternary
            isToggled:
              state.portfolio[action.ticker].isToggled === true ? false : true,
          },
        },
        checkedTickers:
          state.portfolio[action.ticker].isToggled === true
            ? state.checkedTickers.filter((item) => item !== action.ticker)
            : [...state.checkedTickers, action.ticker],
        addedCheckWeight: state.portfolio[action.ticker].isToggled
          ? state.addedCheckWeight + action.weight
          : state.addedCheckWeight - action.weight,
        snapshotCheckWeight: state.portfolio[action.ticker].isToggled
          ? state.addedCheckWeight + action.weight
          : state.addedCheckWeight - action.weight,
        analyticsSnapshot: action.analytics,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_LOAD_PORTFOLIO:
      return {
        ...state,
        isFetching: false,
        portfolio: action.portfolio,
        initialPortfolio: state.initialSnapshot
          ? action.initialPortfolio
          : state.initialPortfolio,
        initialSnapshot: action.initialSnapshot ? false : state.initialSnapshot,
        name: action.overwriteOriginal ? 'What-if' : action.name,
        isWhatIf: action.overwriteOriginal,
        init_date: action.init_date,
        gic_info: action.gic_info,
        editing_gic_ticker: action.editing_gic_ticker,
        gic_new_generic_ticker: action.gic_new_generic_ticker,
        original: action.overwriteOriginal
          ? {
              ...state.original,
              portfolio: action.portfolio,
              gic_info: action.gic_info,
              advisorFee: state.advisorFee,
              name: action.name,
            }
          : { ...state.original },
      };
    case ComparePortfolio.COMPAREPORTFOLIO_UPDATE_WEIGHT:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [action.ticker]: {
            ...state.portfolio[action.ticker],
            weight: action.value,
          },
        },
      };
    case ComparePortfolio.COMPAREPORTFOLIO_UPDATE_INTEREST:
      return {
        ...state,
        gic_info: {
          ...state.gic_info,
          [action.ticker]: {
            ...state.gic_info[action.ticker],
            gic_rate: action.interest_rate,
          },
        },
      };
    case ComparePortfolio.COMPAREPORTFOLIO_SEARCH_SUCCESS:
      return {
        ...state,
        isFetchingSearchResults: false,
        search: action.data,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_REMOVE_SECURITY:
      return {
        ...state,
        isFetching: false,
        portfolio: Object.values(state.portfolio).reduce(
          (newPortfolio, item) => {
            if (item.ticker !== action.ticker) {
              newPortfolio[item.ticker] = item;
            }

            return newPortfolio;
          },
          {}
        ),
        gic_info: Object.keys(state.gic_info)
          .filter((key) => key !== action.ticker)
          .reduce((prev, curr) => {
            prev[curr] = state.gic_info[curr];
            return prev;
          }, {}),
      };
    case ComparePortfolio.COMPAREPORTFOLIO_UPDATE_GIC:
      return {
        ...state,
        gic_info: state.gic_new_generic_ticker
          ? {
              ...state.gic_info,
              [state.gic_new_generic_ticker]: {
                ...(state.gic_info[state.gic_new_generic_ticker] || {}),
                [action.field]: action.value,
              },
            }
          : { ...state.gic_info },
      };
    case ComparePortfolio.COMPAREPORTFOLIO_UPDATE_TOGGLE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_UPDATE_ADVISOR_FEE:
      return {
        ...state,
        advisorFee: action.fee,
      };
    case ComparePortfolio.COMPAREPORTFOLIO_REMOVE_ALL_HOLDINGS:
      return {
        ...state,
        isFetching: false,
        portfolio: Object.keys(state.portfolio)
          .filter(
            (key) =>
              /^GIC\d*:MKT$/.test(key) || ['~BTCUSDT', '~ETHUSDT'].includes(key)
          )
          .reduce((obj, key) => {
            obj[key] = state.portfolio[key];
            return obj;
          }, {}),
      };
    case ComparePortfolio.COMPAREPORTFOLIO_REMOVE_ALL_GICS:
      return {
        ...state,
        isFetching: false,
        portfolio: Object.keys(state.portfolio)
          .filter(
            (key) =>
              !/^GIC\d*:MKT$/.test(key) ||
              ['~BTCUSDT', '~ETHUSDT'].includes(key)
          )
          .reduce((obj, key) => {
            obj[key] = state.portfolio[key];
            return obj;
          }, {}),
        gic_new_generic_ticker: 'GIC1:MKT',
        gic_info: {},
      };
    case ComparePortfolio.COMPAREPORTFOLIO_CLEAR:
      return initialState;
    case ComparePortfolio.COMPAREPORTFOLIO_COMPARE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        compareResult: {
          ...(action.analysis1 && {
            analysis1: action.analysis1,
          }),
          ...(action.analysis2 && {
            analysis2: action.analysis2,
          }),
        },
      };
    case ComparePortfolio.COMPAREPORTFOLIO_ROUND_WEIGHTS_TO_WHOLE_NUMBERS:
      return {
        ...state,
        portfolio: Object.keys(state.portfolio).reduce((res, key) => {
          res[key] = {
            ...state.portfolio[key],
            weight: Math.round(state.portfolio[key].weight || 0),
          };
          return res;
        }, {}),
      };
    case ComparePortfolio.COMPAREPORTFOLIO_RESET_CHECKWEIGHT:
      return {
        ...state,
        addedCheckWeight: 0,
        snapshotCheckWeight: 0,
      };

    default:
      return state;
  }
}
