import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

export default class Range extends Component {
  getMax = () => {
    const { age, cpp } = this.props;
    const cppMultiplier = (num) => {
      if (num < -5) {
        return 1.42;
      }
      if (num > 5) {
        return 0.64;
      }
      if (num === 0) {
        return 1;
      }

      if (num < 0) {
        return 1 + 0.084 * Math.abs(num);
      }

      return 1 - 0.072 * num;
    };
    const oasMultiplier = (num) => {
      if (num < 0) {
        return 1;
      }
      if (num > 5) {
        return 1.36;
      }

      return 1 + 0.072 * num;
    };

    return cpp
      ? Math.round(13610 * cppMultiplier(65 - age))
      : Math.round(7160 * oasMultiplier(age - 65));
  };

  getAvg = () => {
    const { age } = this.props;
    const cppMultiplier = (num) => {
      if (num < -5) {
        return 1.42;
      }
      if (num > 5) {
        return 0.64;
      }
      if (num === 0) {
        return 1;
      }

      if (num < 0) {
        return 1 + 0.084 * Math.abs(num);
      }

      return 1 - 0.072 * num;
    };

    return Math.round(8303 * cppMultiplier(65 - age));
  };

  render() {
    const { cpp, value, handleChange } = this.props;

    return (
      <Popup
        trigger={<Trigger>{value || 'Select'}</Trigger>}
        position="top center"
        hoverable
        hideOnScroll
        style={{ paddingBottom: cpp ? '3.5rem' : '1rem', width: '20rem' }}
      >
        <Popup.Content style={{ position: 'relative' }}>
          <div style={{ textAlign: 'center', fontWeight: 700 }}>
            {cpp ? 'CPP/QPP benefits' : 'OAS benefits'}
          </div>
          <Input
            type="range"
            min={0}
            max={this.getMax()}
            name={cpp ? 'cpp' : 'oas'}
            placeholder="$"
            value={value || 8303}
            onChange={handleChange}
          />
          {cpp && (
            <Avg
              percent={this.getAvg() / this.getMax() / 0.01}
              className="ui label pointing"
            >
              Average: {`$${this.getAvg().toLocaleString()}`}
            </Avg>
          )}
        </Popup.Content>
      </Popup>
    );
  }
}

Range.propTypes = {
  age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired,
  cpp: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Range.defaultProps = {
  cpp: false,
  value: null,
};

const Trigger = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
  font-size: 0.9rem;
  color: #2185d0;
  width: 100%;
  max-width: 73px;
  text-align: center;
  cursor: pointer;
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    &[type='range'] {
      border: 0;
    }
  }
`;

const Avg = styled.span`
  position: absolute !important;
  top: 2rem;
  left: ${(props) => `calc(${props.percent}% - 25px)`};
  min-width: 117px;
  text-align: center;
  margin-left: -36px !important;
`;
