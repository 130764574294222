import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Popup, Icon } from 'semantic-ui-react';
import cn from 'classnames';

import { colors, nbColors } from 'utils/colors';
import { isNB } from '../utils/helpers';

@connect((state) => ({
  user: state.Auth.user,
  partnerName: state.Storage['partner-name'],
}))
export default class SelectionDashboard extends Component {
  render() {
    const {
      user,
      text,
      popuptext,
      selected,
      changeSelection,
      popupBottom,
      partnerName,
      french,
      ...rest
    } = this.props;

    return (
      <StyledSelection {...rest}>
        <div className="text">
          {text}
          {popuptext && (
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="right center"
              wide
              content={popuptext}
            />
          )}
        </div>

        <OptionsWrapper partnerName={partnerName}>
          <div
            className={cn('select', { active: selected })}
            onClick={() => changeSelection(true)}
            style={{
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
          >
            {french ? 'Oui' : 'Yes'}
          </div>
          <div
            className={cn('select', { active: !selected })}
            onClick={() => changeSelection(false)}
            style={{
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            {french ? 'Non' : 'No'}
          </div>
        </OptionsWrapper>
      </StyledSelection>
    );
  }
}

SelectionDashboard.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  changeSelection: PropTypes.func.isRequired,
  popuptext: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  user: PropTypes.object,
  popupBottom: PropTypes.bool,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

SelectionDashboard.defaultProps = {
  popuptext: false,
  user: {},
  popupBottom: false,
  partnerName: '',
  french: false,
};

const StyledSelection = styled.div`
  position: relative;
  width: 100%;
  min-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}
  .text {
    font-size: 1rem;
    text-align: center;
  }
  img {
    height: 17px;
    width: 17px;
    vertical-align: middle;
    margin: 0 0 2px 7px;
    transform: rotate(43deg);
  }
`;

const OptionsWrapper = styled.div`
  padding: calc((28px - 22px) / 2) 0;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }
  .select {
    margin-top: 1rem;
    display: inline-block;
    padding: 0.1rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }
    &.active {
      box-shadow: none;
      background-color: ${(props) =>
        isNB(props.partnerName) ? nbColors.blue : colors.blue};
      color: white;
    }
  }
`;
