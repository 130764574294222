/**
 * Action constants
 */
export const STORAGE_SET_ITEM = 'STORAGE_SET_ITEM';
export const STORAGE_REMOVE_ITEM = 'STORAGE_REMOVE_ITEM';
export const STORAGE_CLEAR = 'STORAGE_CLEAR';
export const STORAGE_GET_ITEM = 'STORAGE_GET_ITEM';

/**
 * Action creators
 */
export function setItem(key, value) {
  const data = { [key]: value };

  return (dispatch) => {
    dispatch({ type: STORAGE_SET_ITEM, data });
  };
}

export function removeItem(key) {
  const data = { key };

  return (dispatch) => {
    dispatch({ type: STORAGE_REMOVE_ITEM, data });
  };
}

export function clear() {
  return (dispatch) => {
    dispatch({ type: STORAGE_CLEAR });
  };
}
