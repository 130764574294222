import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { Header, Segment, NeuCard } from 'components';
import { colors } from 'utils/colors';
import asset_3 from 'images/asset_3.png';
import cn from 'classnames';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  wealthica_plan: state.Storage.wealthica_plan,
  french: state.Storage.language === 'fr',
}))
class Landing extends Component {
  socialMediaIcon = (name) => (
    <Icon className={cn(name)} style={{ color: colors.blue }} />
  );
  showLabel = (labelId) => {
    const label = document.getElementById(labelId);
    label.style.display = 'inline-block';
    setTimeout(() => {
      label.style.display = 'none';
    }, 3000);
  };

  render() {
    const { partnerName, french } = this.props;
    return (
      <Segment expand>
        <div>
          <img
            src={asset_3}
            height="120"
            width="120"
            alt="Portfolio"
            style={{ marginBottom: '1.5rem' }}
          />
          <div>
            <Header size="2rem" color="black" style={{ fontWeight: 'bold' }}>
              {french
                ? 'Bienvenue dans l’Analytique de Portefeuille'
                : 'Welcome to Portfolio Analytics'}
            </Header>
            <P>
              {french
                ? 'Vos comptes Banque Nationale Courtage direct sont automatiquement connectés à cette application. Vous pouvez ajouter manuellement vos comptes avec d’autres institutions financières afin de visualiser et d’analyser tous vos investissements de manière centralisée.'
                : `Your ${
                    partnerName === 'nbdb_sandbox' || partnerName === 'nbdb'
                      ? 'National Bank Direct Brokerage'
                      : 'CI Direct Trading'
                  } accounts are linked automatically to this app. You can manually
                add your accounts from other financial institutions to view and
                analyze all your investments in one place.`}
            </P>

            <P>
              {french
                ? 'Les comptes en USD seront convertis en CAD lorsque vous regroupez des comptes libellés en devises différentes.'
                : 'USD accounts will be converted to CAD for the analysis when you group accounts in different currencies.'}
            </P>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <NeuCard
              iconName="list"
              title={french ? 'Comptes' : 'Accounts'}
              description={
                <P>
                  {french
                    ? 'Analysez vos comptes de placement de Courtage direct de Banque Nationale, ainsi que tous les autres comptes saisis manuellement.'
                    : `Analyze your ${
                        partnerName === 'nbdb_sandbox' || partnerName === 'nbdb'
                          ? 'National Bank Direct Brokerage'
                          : 'CI Direct Trading'
                      } investment accounts, as well as any manually entered ones`}
                </P>
              }
              color={colors.red}
              style={{ marginTop: '1.5rem' }}
              to="/accounts/user"
            />
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

Landing.defaultProps = {
  partnerName: '',
  french: false,
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 750px;

  &.strike {
    text-decoration: line-through;
  }
`;
