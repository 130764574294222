import * as Alert from './alert';
import { removeItem, setItem } from './storage';
import { request } from './api';
import numSubPages from '../modules/Planning/Blueprint/numSubPages';

/**
 * Action constants
 */
export const PLANNING_REQUEST = 'PLANNING_REQUEST';

export const CLEAR_STORE = 'CLEAR_STORE';
export const CLEAR_SECTION_DATA = 'CLEAR_SECTION_DATA';
export const CLEAR_BLUEPRINT_DATA = 'CLEAR_BLUEPRINT_DATA';
export const CLEAR_BLUEPRINT_SECTION = 'CLEAR_BLUEPRINT_SECTION';
export const UPDATE_BLUEPRINT_DATA = 'UPDATE_BLUEPRINT_DATA';
export const DELETE_BLUEPRINT_DATA = 'DELETE_BLUEPRINT_DATA';

export const FETCH_BLUEPRINT = 'FETCH_BLUEPRINT';
export const FETCH_ALL_BLUEPRINTS = 'FETCH_ALL_BLUEPRINTS';
export const SAVE_BLUEPRINT = 'SAVE_BLUEPRINT';
export const REMOVE_BLUEPRINT = 'REMOVE_BLUEPRINT';
export const DRAWDOWN_UPDATE_JUSTSAVEORFETCH =
  'DRAWDOWN_UPDATE_JUSTSAVEORFETCH';

export const ANALYZE_LONGEVITY_SUCCESS = 'ANALYZE_LONGEVITY_SUCCESS';
export const ANALYZE_WEALTH_SUCCESS = 'ANALYZE_WEALTH_SUCCESS';
export const ANALYZE_SAVING_SUCCESS = 'ANALYZE_SAVING_SUCCESS';
export const ANALYZE_BLUEPRINT_WEALTH_SUCCESS =
  'ANALYZE_BLUEPRINT_WEALTH_SUCCESS';
export const ANALYZE_BLUEPRINT_SAVING_SUCCESS =
  'ANALYZE_BLUEPRINT_SAVING_SUCCESS';

export const OAS_CALCULATE_REQUEST = 'OAS_CALCULATE_REQUEST';
export const OAS_CALCULATE_SUCCESS = 'OAS_CALCULATE_SUCCESS';
export const OAS_CHANGE_ELIGIBLE = 'OAS_CHANGE_ELIGIBLE';
export const OAS_UPDATE_INPUT = 'OAS_UPDATE_INPUT';
export const OAS_CLEAR_ERRORS = 'OAS_CLEAR_ERRORS';
export const OAS_CLEAR_INPUTS = 'OAS_CLEAR_INPUTS';

export const CPP_OAS_DATASET = 'CPP_OAS_DATASET';

export const CPP_CALCULATE_REQUEST = 'CPP_CALCULATE_REQUEST';
export const CPP_CALCULATE_SUCCESS = 'CPP_CALCULATE_SUCCESS';
export const CPP_UPDATE_INPUT = 'CPP_UPDATE_INPUT';
export const CPP_TABLEDATA = 'CPP_TABLEDATA';
export const CPP_CLEAR_ERRORS = 'CPP_CLEAR_ERRORS';
export const CPP_CLEAR_INPUTS = 'CPP_CLEAR_INPUTS';

export const DRAWDOWN_ANALYZE_REQUEST = 'DRAWDOWN_ANALYZE_REQUEST';
export const DRAWDOWN_ANALYZE_SUCCESS = 'DRAWDOWN_ANALYZE_SUCCESS';
export const DRAWDOWN_UPDATE_INPUT = 'DRAWDOWN_UPDATE_INPUT';

export const FETCH_DRAWDOWN_PLAN = 'FETCH_DRAWDOWN_PLAN';
export const FETCH_ALL_DRAWDOWN_PLANS = 'FETCH_ALL_DRAWDOWN_PLANS';
export const FETCH_ALL_COUPLE_DRAWDOWN_PLANS =
  'FETCH_ALL_COUPLE_DRAWDOWN_PLANS';
export const FETCH_ALL_TARGET_DRAWDOWN_PLANS =
  'FETCH_ALL_TARGET_DRAWDOWN_PLANS';
export const SAVE_DRAWDOWN_PLAN = 'SAVE_DRAWDOWN_PLAN';
export const REMOVE_DRAWDOWN_PLAN = 'REMOVE_DRAWDOWN_PLAN';
export const SELECT_DRAWDOWN_PLAN = 'SELECT_DRAWDOWN_PLAN';

export const PLANNING_CLEAR = 'PLANNING_CLEAR';
export const NEXT_SUB_PAGE = 'NEXT_SUB_PAGE';
export const PREVIOUS_SUB_PAGE = 'PREVIOUS_SUB_PAGE';
export const UPDATE_COMPLETED_SECTION_PAGE_NUMBER =
  'UPDATE_COMPLETED_SECTION_PAGE_NUMBER';
export const DRAWDOWN_NAVIGATE = 'DRAWDOWN_NAVIGATE';

export const DRAWDOWN_SET_DISPLAY_SCENARIO = 'DRAWDOWN_SET_DISPLAY_SCENARIO';

const DRAWDOWN_ANALYZE_FAILURE = 'DRAWDOWN_ANALYZE_FAILURE';

export const SET_OAS_MODAL_OPEN = 'SET_OAS_MODAL_OPEN';

export const SET_CPP_MODAL_OPEN = 'SET_CPP_MODAL_OPEN';

export const COUPLE_DRAWDOWN_ANALYZE_REQUEST =
  'COUPLE_DRAWDOWN_ANALYZE_REQUEST';

export const COUPLE_DRAWDOWN_ANALYZE_SUCCESS =
  'COUPLE_DRAWDOWN_ANALYZE_SUCCESS';

export const COUPLE_DRAWDOWN_ANALYZE_FAILURE =
  'COUPLE_DRAWDOWN_ANALYZE_FAILURE';

export const COUPLE_PARTIAL_SUCCESS = 'COUPLE_PARTIAL_SUCCESS';

export const COUPLE_NO_SUCCESS = 'COUPLE_NO_SUCCESS';

export const DRAWDOWN_ANALYZE_TASK_ID_RECEIVED =
  'DRAWDOWN_ANALYZE_TASK_ID_RECEIVED';

export const FETCH_WEALTHICA_USER = 'FETCH_WEALTHICA_USER';
/**
 * Action creators
 */
export function clearStore() {
  return {
    type: CLEAR_STORE,
  };
}

export function clearBlueprintData(section) {
  return {
    type: CLEAR_BLUEPRINT_DATA,
    section,
  };
}

export function clearSectionData(section) {
  return {
    type: CLEAR_SECTION_DATA,
    section,
  };
}

export function clearBlueprintSection(section) {
  return {
    type: CLEAR_BLUEPRINT_SECTION,
    section,
  };
}

export function updateBlueprintData(section, input) {
  return {
    type: UPDATE_BLUEPRINT_DATA,
    section,
    input,
  };
}

export function deleteBlueprintData(section, input) {
  return {
    type: DELETE_BLUEPRINT_DATA,
    section,
    input,
  };
}

export function fetchBlueprintById(id) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(request('get', `/planning/${id}/`)).then(
      (data) => {
        // For comparing is saved blueprint being editted
        dispatch(
          setItem('cache-pwpa-blueprint', {
            ...parseBlueprintData(data),
          })
        );

        return dispatch({
          type: FETCH_BLUEPRINT,
          blueprintData: parseBlueprintData(data),
        });
      },
      (error) => {
        dispatch({ type: FETCH_BLUEPRINT, blueprintData: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchAll() {
  return (dispatch) => {
    const url = '/planning/';

    dispatch({ type: PLANNING_REQUEST });

    return dispatch(request('get', url)).then(
      (data) => dispatch({ type: FETCH_ALL_BLUEPRINTS, data }),
      (error) => {
        dispatch({ type: FETCH_ALL_BLUEPRINTS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function save(body, history) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(request('post', '/planning/', { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Blueprint saved' }));
        dispatch({ type: SAVE_BLUEPRINT, data: parseBlueprintData(data) });

        dispatch(removeItem('cache-pwpa-blueprint'));
        dispatch(
          setItem('cache-pwpa-blueprint', {
            ...parseBlueprintData(data),
          })
        );

        resetStorageBP(parseBlueprintData(data), dispatch);

        dispatch(fetchAll());
        return history.push(`/planning/blueprint/${data.id}/summary`);
      },
      () => {
        dispatch({ type: SAVE_BLUEPRINT, data: null });

        return dispatch(
          Alert.show({ type: 'error', msg: 'Unable to save blueprint' })
        );
      }
    );
  };
}

export function update(id, body) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(request('put', `/planning/${id}/`, { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Blueprint updated' }));
        dispatch({ type: SAVE_BLUEPRINT, data: parseBlueprintData(data) });

        dispatch(removeItem('cache-pwpa-blueprint'));
        dispatch(
          setItem('cache-pwpa-blueprint', {
            ...parseBlueprintData(data),
          })
        );

        resetStorageBP(parseBlueprintData(data), dispatch);

        return dispatch(fetchAll());
      },
      () => {
        dispatch({ type: SAVE_BLUEPRINT, data: null });

        return dispatch(
          Alert.show({ type: 'error', msg: 'Unable to update blueprint' })
        );
      }
    );
  };
}

export function remove(id) {
  return (dispatch) =>
    dispatch(request('delete', `/planning/${id}/`)).then(
      () => {
        dispatch({ type: REMOVE_BLUEPRINT, id });

        return dispatch(
          Alert.show({ type: 'success', msg: 'Blueprint deleted' })
        );
      },
      () =>
        dispatch(
          Alert.show({ type: 'error', msg: 'Unable to delete this blueprint' })
        )
    );
}

export function analyzeWealth(params) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(
      request('post', '/planning/post-retirement/', { body: params })
    ).then(
      (data) => dispatch({ type: ANALYZE_WEALTH_SUCCESS, result: data }),
      (error) => {
        dispatch({ type: ANALYZE_WEALTH_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function analyzeSaving(params) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(
      request('post', '/planning/pre-retirement/', {
        body: params,
      })
    ).then(
      (data) => dispatch({ type: ANALYZE_SAVING_SUCCESS, data }),
      (error) => {
        dispatch({ type: ANALYZE_SAVING_SUCCESS, data: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function analyzeBlueprintWealth(params) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(
      request(
        'post',
        `/planning/${params.spouse ? 'couple-' : ''}post-retirement/`,
        { body: params }
      )
    ).then(
      (data) =>
        dispatch({ type: ANALYZE_BLUEPRINT_WEALTH_SUCCESS, result: data }),
      (error) => {
        dispatch({ type: ANALYZE_BLUEPRINT_WEALTH_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function analyzeBlueprintSaving(params) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(
      request('post', '/planning/target-saving-tax/', {
        body: params,
      })
    ).then(
      (data) =>
        dispatch({
          type: ANALYZE_BLUEPRINT_SAVING_SUCCESS,
          result: {
            ...data.savings,
            paths: data.paths,
            prob_without_savings: data.prob_without_savings,
            tax_implications: data.tax_implications,
          },
        }),
      (error) => {
        dispatch({ type: ANALYZE_BLUEPRINT_SAVING_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function analyzeLongevity(params) {
  return (dispatch) => {
    dispatch({ type: PLANNING_REQUEST });

    return dispatch(
      request('post', '/planning/longevity/', { body: params })
    ).then(
      (data) => dispatch({ type: ANALYZE_LONGEVITY_SUCCESS, result: data }),
      (error) => {
        dispatch({ type: ANALYZE_LONGEVITY_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function calcDrawdown(params, isCouple = false) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(
      request('post', `/planning/drawdown/`, { body: params })
    ).then(
      (data) => {
        const { taskId } = data;
        dispatch({
          type: DRAWDOWN_ANALYZE_TASK_ID_RECEIVED,
          taskId,
          isCouple,
        });

        // Polling for the result with the task ID
        return pollDrawdownResult(taskId, dispatch, isCouple);
      },
      (err) => {
        dispatch({ type: DRAWDOWN_ANALYZE_FAILURE, result: null });
        return dispatch(Alert.show({ type: 'error', msg: parseErr(err) }));
      }
    );
  };
}

function pollDrawdownResult(taskId, dispatch, isCouple) {
  const pollInterval = 5000; // 5 seconds

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(() => {
      dispatch(
        request('get', `/planning/drawdown/task-results/?task=${taskId}`)
      )
        .then((data) => {
          if (data.taskStatus === 'SUCCESS') {
            clearInterval(intervalId);
            dispatch({
              type: DRAWDOWN_ANALYZE_SUCCESS,
              result: data,
              isCouple,
            });
            resolve({ success: true, result: data });
          } else if (data.taskStatus.indexOf('TIMEOUT') === 0) {
            clearInterval(intervalId);
            dispatch({ type: DRAWDOWN_ANALYZE_FAILURE, result: null });
            reject({ success: false, result: null });
          }
        })
        .catch((err) => {
          clearInterval(intervalId);
          dispatch({ type: DRAWDOWN_ANALYZE_FAILURE, result: null });
          reject(dispatch(Alert.show({ type: 'error', msg: parseErr(err) })));
        });
    }, pollInterval);
  });
}

export function calcCoupleDrawdown(params, spouseResult, spouse2Result) {
  return (dispatch) => {
    dispatch({ type: COUPLE_DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(
      request('post', `/planning/couple-drawdown/`, {
        body: params,
      })
    ).then(
      (data) => {
        dispatch({
          type: COUPLE_DRAWDOWN_ANALYZE_SUCCESS,
          result: data,
          spouseResult,
          spouse2Result,
        });
        return {
          success: !Array.isArray(data),
          result: data,
          params,
          spouse: spouseResult,
          spouse2: spouse2Result,
        };
      },
      (err) => {
        dispatch({ type: COUPLE_DRAWDOWN_ANALYZE_FAILURE, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(err) }));
      }
    );
  };
}

export function calcNewDrawdown(params) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(
      request('post', '/planning/drawdown-given-spending/', { body: params })
    ).then(
      (data) => {
        dispatch({ type: DRAWDOWN_ANALYZE_SUCCESS, result: data });

        return { success: !Array.isArray(data) };
      },
      (err) => {
        dispatch({ type: DRAWDOWN_ANALYZE_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(err) }));
      }
    );
  };
}

export function drawdownUpdateInput(params) {
  return {
    type: DRAWDOWN_UPDATE_INPUT,
    params,
  };
}

export function calcOas(params) {
  return (dispatch) => {
    dispatch({ type: OAS_CALCULATE_REQUEST });

    return dispatch(
      request('post', '/planning/oas-estimator/', { body: params })
    ).then(
      ({ oas }) => {
        dispatch({ type: OAS_CALCULATE_SUCCESS, result: oas });
      },
      (err) => {
        dispatch({ type: OAS_CALCULATE_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(err) }));
      }
    );
  };
}

export function oasChangeEligible(value) {
  return {
    type: OAS_CHANGE_ELIGIBLE,
    value,
  };
}

export function oasUpdateInput(params) {
  return {
    type: OAS_UPDATE_INPUT,
    params,
  };
}

export function oasClearErrors() {
  return {
    type: OAS_CLEAR_ERRORS,
  };
}

export function oasClearInputs() {
  return {
    type: OAS_CLEAR_INPUTS,
  };
}

export function getCppOasDataset() {
  return (dispatch) =>
    dispatch(request('get', '/planning/public-pension/')).then(
      (data) => {
        const cppDataset = data.reduce((cppData, cpp) => {
          if (cpp.pension_name === 'CPP') {
            cppData.push(cpp);
          }

          return cppData;
        }, []);
        const oasDataset = data.reduce((oasData, oas) => {
          if (oas.pension_name === 'OAS') {
            oasData.push(oas);
          }

          return oasData;
        }, []);

        return dispatch({
          type: CPP_OAS_DATASET,
          cppDataset,
          oasDataset,
        });
      },
      (err) =>
        dispatch(
          Alert.show({
            type: 'error',
            msg: () =>
              err.non_field_errors
                ? err.non_field_errors[0]
                : err.msg || err.detail || 'Server Offline',
          })
        )
    );
}

export function getAverageEarnings() {
  return (dispatch) =>
    dispatch(request('get', '/planning/average-earnings/')).then(
      (data) => {
        dispatch({ type: CPP_TABLEDATA, tableData: data });
      },
      (err) => {
        dispatch({ type: CPP_TABLEDATA, tableData: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(err) }));
      }
    );
}

export function calcCpp(body) {
  return (dispatch) => {
    dispatch({ type: CPP_CALCULATE_REQUEST });

    return dispatch(request('post', '/planning/cpp-estimator/', { body })).then(
      ({ estimated_cpp }) => {
        dispatch({ type: CPP_CALCULATE_SUCCESS, result: estimated_cpp });
      },
      (err) => {
        dispatch({ type: CPP_CALCULATE_SUCCESS, result: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(err) }));
      }
    );
  };
}

export function cppUpdateInput(params) {
  return {
    type: CPP_UPDATE_INPUT,
    params,
  };
}

export function cppClearInputs() {
  return {
    type: CPP_CLEAR_INPUTS,
  };
}

export function cppClearErrors() {
  return {
    type: CPP_CLEAR_ERRORS,
  };
}

export function fetchDrawdownPlanId(id) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('get', `/planning/dd-plans/${id}/`)).then(
      (data) => {
        if (data.constantRets) {
          data.constantRets = data.constantRets.toString().split('.')[1]
            ? (data.constantRets * 100).toFixed(
                Math.max(
                  data.constantRets.toString().split('.')[1].length - 2,
                  0
                )
              )
            : (data.constantRets * 100).toFixed(0);
        }

        dispatch({
          type: FETCH_DRAWDOWN_PLAN,
          id,
          inputs: parseDrawdownInputs(data),
          result: data.outputData,
          name: data.name,
          description: data.description,
        });
      },
      (error) => {
        dispatch({
          type: FETCH_DRAWDOWN_PLAN,
          id: null,
          name: '',
          description: '',
          inputs: {
            cppStarted: true,
            oasStarted: true,
            pensionInf: false,
            otherInf: false,
            port: null,
          },
          errors: {},
          result: null,
        });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchCoupleDrawdownPlanId(id) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('get', `/planning/couple-dd-plans/${id}/`)).then(
      (data) => {
        if (data.constantRets) {
          data.constantRets = data.constantRets.toString().split('.')[1]
            ? (data.constantRets * 100).toFixed(
                Math.max(
                  data.constantRets.toString().split('.')[1].length - 2,
                  0
                )
              )
            : (data.constantRets * 100).toFixed(0);
        }
        if (data.constantRets2) {
          data.constantRets2 = data.constantRets2.toString().split('.')[1]
            ? (data.constantRets2 * 100).toFixed(
                Math.max(
                  data.constantRets2.toString().split('.')[1].length - 2,
                  0
                )
              )
            : (data.constantRets2 * 100).toFixed(0);
        }

        return dispatch({
          type: FETCH_DRAWDOWN_PLAN,
          id,
          inputs: {
            ...parseDrawdownInputs(data),
            activeSpouse: 'spouse',
          },
          coupleDrawdown: { isFetching: false, result: data.coupleOutput },
          result: data.outputData,
          result2: data.outputData2,
          name: data.name,
          description: data.description,
        });
      },
      (error) => {
        dispatch({
          type: FETCH_DRAWDOWN_PLAN,
          id: null,
          name: '',
          description: '',
          inputs: {
            cppStarted: true,
            oasStarted: true,
            pensionInf: false,
            otherInf: false,
          },
          errors: {},
          result: null,
        });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchAllDrawdownPlans() {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('get', '/planning/dd-plans/')).then(
      (data) => dispatch({ type: FETCH_ALL_DRAWDOWN_PLANS, data }),
      (error) => {
        dispatch({ type: FETCH_ALL_DRAWDOWN_PLANS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchAllTargetDrawdownPlans() {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(
      request('get', `/planning/dd-plans/?given_spending=true`)
    ).then(
      (data) => dispatch({ type: FETCH_ALL_TARGET_DRAWDOWN_PLANS, data }),
      (error) => {
        dispatch({ type: FETCH_ALL_DRAWDOWN_PLANS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchAllCoupleDrawdownPlans() {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('get', `/planning/couple-dd-plans/`)).then(
      (data) => {
        dispatch({ type: FETCH_ALL_COUPLE_DRAWDOWN_PLANS, data });
      },
      (error) => {
        dispatch({ type: FETCH_ALL_COUPLE_DRAWDOWN_PLANS, data: [] });
        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function saveDrawdownPlan(body, history) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('post', '/planning/dd-plans/', { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan saved' }));

        if (data.constantRets) {
          data.constantRets =
            data.constantRets >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets * 10000) / 100).toFixed(2).toString()
                );
        }
        dispatch({
          type: SAVE_DRAWDOWN_PLAN,
          inputs: parseDrawdownInputs(data),
          result: data.outputData,
          name: data.name,
          description: data.description,
        });
        dispatch(fetchAllDrawdownPlans());

        return history.push(`/planning/drawdown/${data.id}`);
      },
      () =>
        dispatch(
          Alert.show({ type: 'error', msg: 'Unable to save Drawdown Plan' })
        )
    );
  };
}

export function saveCoupleDrawdownPlan(body, history) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(
      request('post', `/planning/couple-dd-plans/`, { body })
    ).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan saved' }));

        if (data.constantRets) {
          data.constantRets =
            data.constantRets >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets * 10000) / 100).toFixed(2).toString()
                );
        }
        if (data.constantRets2) {
          data.constantRets2 =
            data.constantRets2 >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets2 * 10000) / 100).toFixed(2).toString()
                );
        }

        dispatch({
          type: SAVE_DRAWDOWN_PLAN,
          inputs: parseDrawdownInputs(data),
          coupleOutput: data.coupleOutput,
          result: data.outputData,
          result2: data.outputData2,
          name: data.name,
          description: data.description,
        });

        return history.push(`/planning/drawdown-couples/${data.id}`);
      },
      (error) => {
        console.log(`Update drawdown plan error: ${parseErr(error)}`);

        dispatch(
          Alert.show({ type: 'error', msg: 'Unable to save Drawdown Plan' })
        );
      }
    );
  };
}

export function saveTargetDrawdownPlan(body, history) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('post', '/planning/dd-plans/', { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan saved' }));

        if (data.constantRets) {
          data.constantRets =
            data.constantRets >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets * 10000) / 100).toFixed(2).toString()
                );
        }
        dispatch({
          type: SAVE_DRAWDOWN_PLAN,
          inputs: parseDrawdownInputs(data),
          result: data.outputData,
          name: data.name,
          description: data.description,
        });
        dispatch(fetchAllDrawdownPlans());

        return history.push(`/planning/drawdown-target-spending/${data.id}`);
      },
      () =>
        dispatch(
          Alert.show({ type: 'error', msg: 'Unable to save Drawdown Plan' })
        )
    );
  };
}

export function updateDrawdownPlan(id, body) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('put', `/planning/dd-plans/${id}/`, { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan updated' }));

        if (data.constantRets) {
          data.constantRets =
            data.constantRets >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets * 10000) / 100).toFixed(2).toString()
                );
        }

        dispatch({
          type: SAVE_DRAWDOWN_PLAN,
          inputs: parseDrawdownInputs(data),
          result: data.outputData,
          name: data.name,
          description: data.description,
        });

        return dispatch(fetchAllDrawdownPlans());
      },
      () =>
        dispatch(
          Alert.show({ type: 'error', msg: 'Unable to update drawdown plan' })
        )
    );
  };
}

export function updateCoupleDrawdownPlan(id, body) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(
      request('put', `/planning/couple-dd-plans/${id}/`, { body })
    ).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan updated' }));

        if (data.constantRets) {
          data.constantRets =
            data.constantRets >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets * 10000) / 100).toFixed(2).toString()
                );
        }
        if (data.constantRets2) {
          data.constantRets2 =
            data.constantRets2 >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets2 * 10000) / 100).toFixed(2).toString()
                );
        }

        dispatch({
          type: SAVE_DRAWDOWN_PLAN,
          inputs: parseDrawdownInputs(data),
          result: data.outputData,
          name: data.name,
          description: data.description,
        });

        return dispatch(fetchAllCoupleDrawdownPlans());
      },
      (error) => {
        console.log(`Update drawdown plan error: ${parseErr(error)}`);
        return dispatch(
          Alert.show({ type: 'error', msg: 'Unable to update drawdown plan' })
        );
      }
    );
  };
}

export function updateTargetDrawdownPlan(id, body) {
  return (dispatch) => {
    dispatch({ type: DRAWDOWN_ANALYZE_REQUEST });

    return dispatch(request('put', `/planning/dd-plans/${id}/`, { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan updated' }));

        if (data.constantRets) {
          data.constantRets =
            data.constantRets >= 1
              ? 100
              : Number.parseFloat(
                  ((data.constantRets * 10000) / 100).toFixed(2).toString()
                );
        }

        dispatch({
          type: SAVE_DRAWDOWN_PLAN,
          inputs: parseDrawdownInputs(data),
          result: data.outputData,
          name: data.name,
          description: data.description,
        });

        return dispatch(fetchAllTargetDrawdownPlans());
      },
      () =>
        dispatch(
          Alert.show({ type: 'error', msg: 'Unable to update drawdown plan' })
        )
    );
  };
}

export function removeDrawdownPlan(id) {
  return (dispatch) =>
    dispatch(request('delete', `/planning/dd-plans/${id}/`)).then(
      () => {
        dispatch({ type: REMOVE_DRAWDOWN_PLAN, id });

        dispatch(Alert.show({ type: 'success', msg: 'Drawdown Plan deleted' }));

        dispatch(fetchAllTargetDrawdownPlans());
        return dispatch(fetchAllDrawdownPlans());
      },
      () =>
        dispatch(
          Alert.show({
            type: 'error',
            msg: 'Unable to delete this Drawdown Plan',
          })
        )
    );
}

export function updateJustsaveOrFetch(value) {
  return {
    type: DRAWDOWN_UPDATE_JUSTSAVEORFETCH,
    justSaveOrFetch: value,
  };
}

export function clearPlanning() {
  return { type: PLANNING_CLEAR };
}

export function nextSubPage(section) {
  return {
    type: NEXT_SUB_PAGE,
    section,
  };
}

export function previousSubPage(section) {
  return {
    type: PREVIOUS_SUB_PAGE,
    section,
  };
}
export function navigateDrawdown(page) {
  return {
    type: DRAWDOWN_NAVIGATE,
    page,
  };
}
export function setScenario(scenario) {
  return {
    type: DRAWDOWN_SET_DISPLAY_SCENARIO,
    scenario,
  };
}

export function removeCoupleDrawdownPlan(id) {
  return (dispatch) =>
    dispatch(request('delete', `/planning/couple-dd-plans/${id}/`)).then(
      () => {
        dispatch({ type: REMOVE_DRAWDOWN_PLAN, id });

        dispatch(
          Alert.show({ type: 'success', msg: 'Couple Drawdown Plan deleted' })
        );
        return dispatch(fetchAllCoupleDrawdownPlans());
      },
      () =>
        dispatch(
          Alert.show({
            type: 'error',
            msg: 'Unable to delete this Drawdown Plan',
          })
        )
    );
}

export const setCppModalOpen = (isOpen) => ({
  type: 'SET_CPP_MODAL_OPEN',
  isOpen,
});

export const setOasModalOpen = (isOpen) => ({
  type: 'SET_OAS_MODAL_OPEN',
  isOpen,
});

export function updateCompletedSectionsPageNumbers(section, data) {
  // ensure all *completed* sections that the user is not actively clicked on
  // are on page number numSubPages[<section>] + 1 (to display the full red progress bar)
  const section_names = Object.keys(numSubPages);
  // the conditions for determining whether or not each section is 'complete'.
  // note that the sperate checks for undefined and null are required here.
  const section_complete = [
    data[section_names[0]] &&
      data[section_names[0]].is_couple_plan !== undefined &&
      data[section_names[0]].is_couple_plan !== null,
    data[section_names[1]] &&
      data[section_names[1]].oasAge !== null &&
      data[section_names[1]].oasAge !== undefined,
    data[section_names[2]] &&
      data[section_names[2]].targ !== null &&
      data[section_names[2]].targ !== undefined,
    data[section_names[3]] &&
      data[section_names[3]].monthlySavingChosen !== null &&
      data[section_names[3]].monthlySavingChosen !== undefined,
    data[section_names[4]],
  ];

  let name;
  for (let i = 0; i < section_names.length; i += 1) {
    name = section_names[i];
    if (
      name === section || // disregard the current section the user is on
      !data[name] || // or if section name doesn't exist yet
      data[name].currentSubPage === undefined // or if the user hasn't got to it yet
    )
      // eslint-disable-next-line no-continue
      continue; // skip this iteration of the loop
    if (section_complete[i]) {
      data[name].currentSubPage = numSubPages[name] + 1;
    }
  }

  return {
    type: UPDATE_COMPLETED_SECTION_PAGE_NUMBER,
    data,
  };
}

export function fetchWealthicaUser() {
  return (dispatch) =>
    dispatch(request('get', '/auth/wealthica-partner-user/')).then(
      (data) => dispatch({ type: FETCH_WEALTHICA_USER, data }),
      (error) => {
        dispatch({ type: FETCH_WEALTHICA_USER, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}

function parseBlueprintData(data) {
  const blueprintData = {
    id: data.id,
    updated_on: data.updated_on,
    name: data.name,
    description: data.description,
    retirementBlueprint: {
      currentSubPage: numSubPages.retirementBlueprint + 1,
      is_couple_plan: data.is_couple_plan ? 'true' : 'false',
    },
    personalDetails: {
      currentSubPage: numSubPages.personalDetails + 1,
      province: data.province,
      spending_amount: data.spending_amount,
      spendingToolData: data.spending_tool_data,
      selectedSpendingTool: data.selected_spending_tool,
      net_spending: data.net_spending,
      age: data.age,
      age2: data.age2,
      cpp: data.cpp,
      cpp2: data.cpp2,
      cppAge: data.cppAge,
      cppAge2: data.cppAge2,
      ...(data.pension && { pension: data.pension }),
      ...(data.pension2 && { pension2: data.pension2 }),
      gender: data.gender,
      gender2: data.gender2,
      oas: data.oas,
      oas2: data.oas2,
      oasAge: data.oasAge,
      oasAge2: data.oasAge2,
      ...(data.other && { other: data.other }),
      ...(data.other2 && { other2: data.other2 }),
      retAge: data.retAge,
      retAge2: data.retAge2,

      wage: data.wage || 0,
      wage2: data.wage2 || 0,
      ...(data.declinePattern &&
        Object.keys(data.declinePattern).length && {
          declinePattern: data.declinePattern,
        }),

      years_contrib: data.years_contrib,
      primary_caregiver: data.primary_caregiver,
      years_primary_caregiver: data.years_primary_caregiver,
      is_earning_over_average:
        data.is_earning_over_average === null
          ? true
          : data.is_earning_over_average,
      perc_under_average: data.perc_under_average,
      ...(data.resident_years && { resident_years: data.resident_years }),

      years_contrib2: data.years_contrib2,
      primary_caregiver2: data.primary_caregiver2,
      years_primary_caregiver2: data.years_primary_caregiver2,
      is_earning_over_average2:
        data.is_earning_over_average2 === null
          ? true
          : data.is_earning_over_average2,
      perc_under_average2: data.perc_under_average2,
      ...(data.resident_years2 && { resident_years2: data.resident_years2 }),
    },
    targetWealth: {
      currentSubPage: numSubPages.targetWealth + 1,
      annuityRate: data.annuityRate,
      horz: data.horz,
      targ: data.targ,
      wealth_portfolio: data.wealth_portfolio,
      wealth_portfolio_detail: data.wealth_portfolio_detail,
      modelType: data.modelType,
    },
    wealthResult: {
      0.5: data.targetWealth50,
      0.75: data.targetWealth75,
      0.9: data.targetWealth90,
      0.95: data.targetWealth95,
    },
    targetSaving: {
      currentSubPage: numSubPages.targetSaving + 1,
      init: data.init,
      annual_income: data.annual_income,
      annual_income2: data.annual_income2,
      rrsp_room: data.rrsp_room,
      rrsp_room2: data.rrsp_room2,
      tfsa_room: data.tfsa_room,
      tfsa_room2: data.tfsa_room2,
      monthlySavingChosen: data.monthlySavingChosen,
      savings_portfolio: data.savings_portfolio,
      savings_portfolio_detail: data.savings_portfolio_detail,
      ...(data.selectedAccounts && { initFromAcc: true }),
    },
    savingResult: {
      prob_without_savings: data.prob_without_savings,
      0.5: data.monthlySaving50,
      0.75: data.monthlySaving75,
      0.9: data.monthlySaving90,
      tax_implications: {
        0.5: {
          expected_taxes: data.expected_taxes50,
          expected_taxes2: data.expected_taxes50b,
        },
        0.75: {
          expected_taxes: data.expected_taxes75,
          expected_taxes2: data.expected_taxes75b,
        },
        0.9: {
          expected_taxes: data.expected_taxes90,
          expected_taxes2: data.expected_taxes90b,
        },
      },
      ...(data.savingPaths && { paths: data.savingPaths }),
    },
    summary: {
      currentSubPage: numSubPages.summary + 1,
      id: data.id,
    },
  };

  if (data.modelType === 'annuityRate') {
    Object.assign(blueprintData, {
      wealthResult: { Annuity: data.targ },
    });
  } else {
    Object.assign(blueprintData, {
      wealthResult: {
        0.5: data.targetWealth50,
        0.75: data.targetWealth75,
        0.9: data.targetWealth90,
        0.95: data.targetWealth95,
      },
    });
  }

  return blueprintData;
}

function parseDrawdownInputs(data) {
  const { isRiskFree, lumpSumPayment, lumpSumPaymentAge } = data;
  const unusedKeys = [
    'id',
    'name',
    'description',
    'outputData',
    'updated_on',
    'symbols',
    'weights',
  ];

  return Object.keys(data).reduce(
    (acc, key) => {
      const val = data[key];

      if (key === 'portfolio') {
        if (!isRiskFree) {
          acc[key] = val;
        }

        return acc;
      }
      if (key === 'selectedRegular') {
        acc.initRegAccList = [];

        if (val !== '') {
          acc.initRegularFromAcc = true;
        }

        return acc;
      }
      if (key === 'selectedRRIF') {
        acc.initRRIFAccList = [];

        return acc;
      }
      if (key === 'selectedTFSA') {
        acc.initTFSAAccList = [];

        if (lumpSumPayment > 0 && lumpSumPaymentAge > 0) {
          acc.businessStarted = true;
        }
        return acc;
      }
      if (!unusedKeys.includes(key)) {
        acc[key] = val;
      }
      if (['symbols', 'weights'].includes(key) && !data.isRiskFree) {
        acc.port = `${data.symbols}-${data.weights}`;
      } else if (data.isRiskFree) {
        acc.port = null;
      }
      acc[key] = val;

      return acc;
    },
    { rrifAge: 0 }
  );
}

function resetStorageBP(data, dispatch) {
  dispatch(removeItem('cachePB'));
  dispatch(removeItem('cacheTW'));
  dispatch(removeItem('cacheWR'));
  dispatch(removeItem('cacheTS'));
  dispatch(removeItem('cacheSR'));

  dispatch(setItem('cachePB', data.personalDetails));
  dispatch(setItem('cacheTW', data.targetWealth));
  dispatch(setItem('cacheWR', data.wealthResult));
  dispatch(setItem('cacheTS', data.targetSaving));
  dispatch(setItem('cacheSR', data.savingResult));
}
