import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Statistic, Icon, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Planning } from 'actions';
import { colors } from 'utils/colors';
import BeforeTaxPie from './BeforeTaxPie';
import AfterTaxPie from './AfterTaxPie';
import InvestmentAccountLine from './InvestmentAccountLine';
import PensionIncomeBar from './PensionIncomeBar';
import InvestmentIncomeBar from './InvestmentIncomeBar';
import TaxesBar from './TaxesBar';
import TaxTable from '../TaxTable';

@withRouter
@connect((state) => ({
  drawdown: state.Planning.drawdown,
  coupleDrawdown: state.Planning.drawdown.coupleDrawdown,
  savedCoupleDrawdownPlans: state.Planning.savedCoupleDrawdownPlans,
  user: state.Auth.user,
}))
export default class Result extends Component {
  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount = () => {
    const { dispatch, match, history, drawdown } = this.props;
    const { id } = match.params;
    dispatch(Planning.fetchAllCoupleDrawdownPlans());
    // resize charts on print
    this.state.mql.addListener(this.printListener);

    if (id === 'new' && !drawdown.result) {
      return history.push('/planning/drawdown-couples/new');
    }

    if (Number(id) && !drawdown.name) {
      return dispatch(Planning.fetchCoupleDrawdownPlanId(id));
    }

    return false;
  };

  componentDidMount = () => {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  };

  componentWillUnmount = () => {
    window.clearTimeout(this.timeoutId);
    this.state.mql.removeListener(this.printListener);
  };

  printListener = (e) => {
    const { history, match } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else {
      history.push(`/planning/drawdown-couples/${match.params.id}`);
    }
  };

  timeoutId = null;

  formatNum = (num) => {
    if (!num) {
      return '$0';
    }
    const options = {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    const format = new Intl.NumberFormat('en-US', options);

    return `$${format.format(num)}`;
  };

  render() {
    const { drawdown, coupleDrawdown, savedCoupleDrawdownPlans, user } =
      this.props;
    const { inputs } = drawdown;
    const { isRiskFree, scenario } = inputs;
    const { id } = this.props.match.params;
    const result = coupleDrawdown.spouse;
    const result2 = coupleDrawdown.spouse2;

    if (!result || !result.yearlyDetails) {
      return null;
    }
    const ages = Object.entries(result.yearlyDetails).map(([age, details]) => ({
      age: Number(age),
      ...details,
    }));

    const prefiltered = ages.map((entry) => entry.age);
    const filtered = [
      ...prefiltered.slice(0, 5),
      ...prefiltered.slice(5).filter((age, index) => index % 5 === 0),
    ];

    const ages2 = Object.entries(result2.yearlyDetails).map(
      ([age, details]) => ({
        age: Number(age),
        ...details,
      })
    );

    const prefiltered2 = ages2.map((entry) => entry.age);
    const filtered2 = [
      ...prefiltered2.slice(0, 5),
      ...prefiltered2.slice(5).filter((age, index) => index % 5 === 0),
    ];

    const selectedDrawdownPlan = savedCoupleDrawdownPlans.find(
      (plan) => String(plan.id) === String(id)
    );

    return (
      <PDF>
        <Section>
          <LeftHalf>
            <h1
              size="huge"
              style={{ textAlign: 'left', margin: '0', fontSize: '2.5rem' }}
            >
              Optimal Drawdown Plan for Couples
            </h1>

            <SubHeader>
              <span>For:</span> {inputs.spouse} & {inputs.spouse2}
            </SubHeader>

            {selectedDrawdownPlan && (
              <SubHeader>
                <span>Date:</span> {selectedDrawdownPlan.updated_on}
              </SubHeader>
            )}
          </LeftHalf>
          <RightHalf />
        </Section>

        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              Joint Drawdown Plan Summary
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <Header
              size="medium"
              content="Tax Savings when Filing as a Couple"
              style={{ margin: '0.3rem' }}
            />
            <P style={{ marginTop: '1rem' }}>
              Tax savings over the entire Planning Horizon:{' '}
              <b style={{ color: `${colors.blue}` }}>
                {this.formatNum(coupleDrawdown.result.pv_total_savings)}
              </b>
              <br />
              <br />
              See the plan for the first five years below. The full plan is
              available in an Excel file. <br />
              <span style={{ fontWeight: 'bold' }}>Note:</span> a positive
              transfer amount indicates which spouse/partner is making the
              transfer.{' '}
            </P>
            <Header size="medium">
              Year 1 of 5
              <Header.Subheader>
                Tax savings:{' '}
                <b style={{ color: `${colors.blue}` }}>
                  {this.formatNum(
                    coupleDrawdown.result.combined_results[1].total_savings
                  )}
                </b>
              </Header.Subheader>
            </Header>
            <TaxTable
              t={1}
              data={coupleDrawdown}
              spouse={inputs.spouse || inputs.personName1}
              spouse2={inputs.spouse2 || inputs.personName2}
              drawdown={drawdown}
            />
          </CenteredDiv>
        </Section>
        <Section>
          <CenteredDiv>
            <Header size="medium">
              Year 2 of 5
              <Header.Subheader>
                Tax savings:{' '}
                <b style={{ color: `${colors.blue}` }}>
                  {this.formatNum(
                    coupleDrawdown.result.combined_results[2].total_savings
                  )}
                </b>
              </Header.Subheader>
            </Header>
            <TaxTable
              t={2}
              data={coupleDrawdown}
              spouse={inputs.spouse || inputs.personName1}
              spouse2={inputs.spouse2 || inputs.personName2}
              drawdown={drawdown}
            />
          </CenteredDiv>
        </Section>
        <Section>
          <CenteredDiv>
            <Header size="medium">
              Year 3 of 5
              <Header.Subheader>
                Tax savings:{' '}
                <b style={{ color: `${colors.blue}` }}>
                  {this.formatNum(
                    coupleDrawdown.result.combined_results[3].total_savings
                  )}
                </b>
              </Header.Subheader>
            </Header>
            <TaxTable
              t={3}
              data={coupleDrawdown}
              spouse={inputs.spouse || inputs.personName1}
              spouse2={inputs.spouse2 || inputs.personName2}
              drawdown={drawdown}
            />
          </CenteredDiv>
        </Section>
        <Section>
          <CenteredDiv>
            <Header size="medium">
              Year 4 of 5
              <Header.Subheader>
                Tax savings:{' '}
                <b style={{ color: `${colors.blue}` }}>
                  {this.formatNum(
                    coupleDrawdown.result.combined_results[4].total_savings
                  )}
                </b>
              </Header.Subheader>
            </Header>
            <TaxTable
              t={4}
              data={coupleDrawdown}
              spouse={inputs.spouse || inputs.personName1}
              spouse2={inputs.spouse2 || inputs.personName2}
              drawdown={drawdown}
            />
          </CenteredDiv>
        </Section>
        <Section>
          <CenteredDiv>
            <Header size="medium">
              Year 5 of 5
              <Header.Subheader>
                Tax savings:{' '}
                <b style={{ color: `${colors.blue}` }}>
                  {this.formatNum(
                    coupleDrawdown.result.combined_results[5].total_savings
                  )}
                </b>
              </Header.Subheader>
            </Header>
            <TaxTable
              t={5}
              data={coupleDrawdown}
              spouse={inputs.spouse || inputs.personName1}
              spouse2={inputs.spouse2 || inputs.personName2}
              drawdown={drawdown}
            />
          </CenteredDiv>
        </Section>

        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`${inputs.spouse}'s Summary`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>

            <div>
              <Header
                size="medium"
                content="How much can I afford to spend each year?"
              />
              <P margin>
                Knowing how much retirement income you will receive is just a
                start. You need to know how much you should withdraw from your
                savings each year and understand the tax consequences of your
                withdrawal decision. In the results below, we show the plan that
                will maximize your after-tax income, while keeping a smooth
                consumption path in real terms.
              </P>
            </div>
            <div style={{ height: '60px', width: '100%' }} />
            <Flex style={{ flexDirection: 'row' }}>
              <div className="flex-item">
                <Header
                  size="small"
                  content="What you can afford to spend per year"
                  subheader="After tax, in today's dollars"
                />
                <Flex className="circle blue">
                  {this.formatNum(
                    result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                      .incPlot
                  )}
                </Flex>
              </div>
              <div className="flex-item">
                <Header
                  size="small"
                  content="Tax implications over the planning horizon"
                  subheader="Sum in today’s dollars"
                />
                <Flex className="circle orange">
                  {this.formatNum(result.taxPV)}
                </Flex>
              </div>
            </Flex>
          </CenteredDiv>
        </Section>
        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`1st Yr Income Sources - ${inputs.spouse}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div style={{ alignItems: 'flex-start' }}>
              <Header
                size="medium"
                content={`Income sources at age ${filtered[0]}`}
                subheader="before-tax"
                style={{ marginTop: 0 }}
              />
              <P style={{ margin: '0 auto 2.5rem auto' }}>
                Below, you’ll find a breakdown of the amounts to withdraw from
                your various income sources in the{' '}
                <span style={{ fontWeight: 'bold' }}>1st year</span> of your
                plan to optimize your after-tax retirement income. Remember,
                optimality means that your after-tax income is maximized, on a
                smooth path, while satisfying the bequest amount you indicated,
                if any.
              </P>
              <div style={{ height: '30px', width: '100%' }} />
              <Flex>
                <div className="flex-item" style={{ height: 400 }}>
                  <Header
                    size="small"
                    content={`Income sources at age ${filtered[0]}`}
                    subheader="before-tax"
                    style={{ marginTop: 0 }}
                  />
                  <BeforeTaxPie
                    data={
                      result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                    }
                  />
                </div>
                <div className="flex-item" style={{ height: 356 }}>
                  <Header
                    size="small"
                    content={`Spending and taxes at age ${filtered[0]}`}
                    subheader="after-tax"
                    style={{ marginTop: 0 }}
                  />
                  <AfterTaxPie
                    data={
                      result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                    }
                  />
                </div>
              </Flex>
            </div>
          </CenteredDiv>
        </Section>

        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Pension income Breakdown -  ${inputs.spouse}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content="Pension income over the planning horizon"
                subheader="before-tax"
              />
              <P margin>
                See below for a breakdown of your pension income from various
                sources, based on your inputs. The Pension Income chart helps
                you understand how government benefits and other pension income
                will contribute to your annual income throughout your
                retirement. CPP & OAS are automatically indexed to inflation.
              </P>
            </div>
            <PensionIncomeBar data={result.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>
        <Section center style={{ marginBottom: '3rem' }}>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Investment income Breakdown -  ${inputs.spouse}`}{' '}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content="Investment Income over the planning horizon"
                subheader="before-tax"
              />
              <P margin>
                We’ve analyzed the optimal withdrawal schedule from your various
                investment accounts to minimize your tax burden. Based on the
                information you provided and simulated returns data for your
                planning horizon, the following chart represents when and how
                much you should withdraw from each of your accounts annually.
              </P>
            </div>
            <InvestmentIncomeBar data={result.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>
        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Income tax Breakdown -  ${inputs.spouse}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header size="medium" content="Taxes over the planning horizon" />
              <P margin>
                The following chart represents the minimized amount of income
                lost to taxes in each year of your drawdown plan. See Model
                Features & Assumptions for more information on our strategy.
              </P>
            </div>
            <TaxesBar data={result.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>
        <Section>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Investment Account Projections -  ${inputs.spouse}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content={`Projection of Investment Account Balances -  ${inputs.spouse}`}
              />
              <P margin>
                Here are the projections of your investment accounts throughout
                your planning horizon. Based on your investment income schedule
                and the simulated returns of the holdings in your portfolio,
                your account balances over the years are broken down as follows.
              </P>
            </div>
            <InvestmentAccountLine data={result.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>

        <Section2 center>
          <Header
            size="medium"
            style={{
              position: 'absolute',
              top: '-7px',
              left: 15,
              background: 'white',
              padding: '0 5px',
              margin: 0,
              transition: 'all 300ms ease',
              zIndex: 8,
            }}
          >
            {`Year-by-year Details -  ${inputs.spouse}`}
            <Header.Subheader style={{ textAlign: 'left', color: colors.blue }}>
              {!isRiskFree && scenario
                ? scenario === 'median'
                  ? 'Median Outcome'
                  : 'Sequence Of Returns Risk Outcome'
                : 'Median Outcome'}
            </Header.Subheader>
          </Header>
          <div style={{ width: '100%' }}>
            <P1
              style={{
                margin: '0.3rem auto',
                width: '100%',
                zIndex: 600,
                transform: 'translateX(15px)',
              }}
            >
              The table below displays data annually for the first 5 years, then
              displays in 5-year intervals. All figures in the table are
              nominal, reflecting future values, except{' '}
              <strong>real spending</strong>.
            </P1>
            <div style={{ position: 'relative' }}>
              <TableWrapper>
                <Header
                  size="medium"
                  content="Spending"
                  textAlign="left"
                  style={{
                    margin: '10px 0 0 0',
                    transform: 'translateY(20px)',
                  }}
                />
                <Table>
                  <thead>
                    <tr>
                      <th />
                      {filtered.map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="title">Gross spending</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].incTotal !== undefined &&
                            this.formatNum(result.yearlyDetails[key].incTotal)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Net spending</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].incNet !== undefined &&
                            this.formatNum(result.yearlyDetails[key].incNet)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Real net spending</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].incPlot !== undefined &&
                            this.formatNum(result.yearlyDetails[key].incPlot)}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Retirement income"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">CPP/QPP</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incCPP !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incCPP)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">OAS</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incOAS !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incOAS)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">GIS</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incGIS !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incGIS)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Employment pension</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incPension !== undefined
                            ? this.formatNum(
                                result.yearlyDetails[key].incPension
                              )
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Other</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incOther !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incOther)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Investments"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">Minimum RRIF</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdReqMin)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Non-registered</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdRegular)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">RRIF</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdRRIF)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">TFSA</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdTFSA)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">
                        Dividends
                        <Popup
                          trigger={<Icon name="question circle outline" />}
                          position="top center"
                          content="From non-registered accounts."
                          wide
                        />
                      </td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(
                              result.yearlyDetails[key].incDividend
                            )}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Taxes"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">Taxes</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].taxBoth)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">OAS clawback</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].taxOAS &&
                            this.formatNum(result.yearlyDetails[key].taxOAS)}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </TableWrapper>
            </div>

            <div
              style={{ textAlign: 'center', transform: 'translateY(-10px)' }}
            >
              <Statistic size="tiny">
                <Statistic.Label>
                  Future value of bequest
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  />
                </Statistic.Label>
                <Statistic.Value>
                  {this.formatNum(result.terminalValue)}
                </Statistic.Value>
              </Statistic>
            </div>
          </div>
        </Section2>
        {/* // spouse 2  */}

        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`${inputs.spouse2}'s Summary`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content="How much can I afford to spend each year?"
              />
              <P margin>
                Knowing how much retirement income you will receive is just a
                start. You need to know how much you should withdraw from your
                savings each year and understand the tax consequences of your
                withdrawal decision. In the results below, we show the plan that
                will maximize your after-tax income, while keeping a smooth
                consumption path in real terms.
              </P>
            </div>
            <div style={{ height: '60px', width: '100%' }} />
            <Flex style={{ flexDirection: 'row' }}>
              <div className="flex-item">
                <Header
                  size="small"
                  content="What you can afford to spend per year"
                  subheader="After tax, in today's dollars"
                />
                <Flex className="circle blue">
                  {this.formatNum(
                    result2.yearlyDetails[Object.keys(result2.yearlyDetails)[0]]
                      .incPlot
                  )}
                </Flex>
              </div>
              <div className="flex-item">
                <Header
                  size="small"
                  content="Tax implications over the planning horizon"
                  subheader="Sum in today’s dollars"
                />
                <Flex className="circle orange">
                  {this.formatNum(result2.taxPV)}
                </Flex>
              </div>
            </Flex>
          </CenteredDiv>
        </Section>
        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`1st Yr Income Sources - ${inputs.spouse2}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div style={{ alignItems: 'flex-start' }}>
              <Header
                size="medium"
                content={`Income sources at age ${filtered2[0]}`}
                subheader="before-tax"
                style={{ marginTop: 0 }}
              />
              <P style={{ margin: '0 auto 2.5rem auto' }}>
                Below, you’ll find a breakdown of the amounts to withdraw from
                your various income sources in the{' '}
                <span style={{ fontWeight: 'bold' }}>1st year</span> of your
                plan to optimize your after-tax retirement income. Remember,
                optimality means that your after-tax income is maximized, on a
                smooth path, while satisfying the bequest amount you indicated,
                if any.
              </P>
              <div style={{ height: '30px', width: '100%' }} />
              <Flex>
                <div className="flex-item" style={{ height: 400 }}>
                  <Header
                    size="small"
                    content={`Income sources at age ${filtered2[0]}`}
                    subheader="before-tax"
                    style={{ marginTop: 0 }}
                  />
                  <BeforeTaxPie
                    data={
                      result2.yearlyDetails[
                        Object.keys(result2.yearlyDetails)[0]
                      ]
                    }
                  />
                </div>
                <div className="flex-item" style={{ height: 356 }}>
                  <Header
                    size="small"
                    content={`Spending and taxes at age ${filtered2[0]}`}
                    subheader="after-tax"
                    style={{ marginTop: 0 }}
                  />
                  <AfterTaxPie
                    data={
                      result2.yearlyDetails[
                        Object.keys(result2.yearlyDetails)[0]
                      ]
                    }
                  />
                </div>
              </Flex>
            </div>
          </CenteredDiv>
        </Section>

        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Pension income Breakdown - ${inputs.spouse2}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content="Pension income over the planning horizon"
                subheader="before-tax"
              />
              <P margin>
                See below for a breakdown of your pension income from various
                sources, based on your inputs. The Pension Income chart helps
                you understand how government benefits and other pension income
                will contribute to your annual income throughout your
                retirement. CPP & OAS are automatically indexed to inflation.
              </P>
            </div>
            <PensionIncomeBar data={result2.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>
        <Section center style={{ marginBottom: '3rem' }}>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Investment Income Breakdown - ${inputs.spouse2}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content="Investment income over the planning horizon"
                subheader="before-tax"
              />
              <P margin>
                We’ve analyzed the optimal withdrawal schedule from your various
                investment accounts to minimize your tax burden. Based on the
                information you provided and simulated returns data for your
                planning horizon, the following chart represents when and how
                much you should withdraw from each of your accounts annually.
              </P>
            </div>
            <InvestmentIncomeBar data={result2.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>
        <Section center>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Income Tax Breakdown - ${inputs.spouse2}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header size="medium" content="Taxes over the planning horizon" />
              <P margin>
                The following chart represents the minimized amount of income
                lost to taxes in each year of your drawdown plan. See Model
                Features & Assumptions for more information on our strategy.
              </P>
            </div>
            <TaxesBar data={result2.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>
        <Section>
          <CenteredDiv>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              {`Investment Account Projections - ${inputs.spouse2}`}
              <Header.Subheader
                style={{ textAlign: 'left', color: colors.blue }}
              >
                {!isRiskFree && scenario
                  ? scenario === 'median'
                    ? 'Median Outcome'
                    : 'Sequence Of Returns Risk Outcome'
                  : 'Median Outcome'}
              </Header.Subheader>
            </Header>
            <div>
              <Header
                size="medium"
                content="Projection of investment account balances"
              />
              <P margin>
                Here are the projections of your investment accounts throughout
                your planning horizon. Based on your investment income schedule
                and the simulated returns of the holdings in your portfolio,
                your account balances over the years are broken down as follows.
              </P>
            </div>
            <InvestmentAccountLine data={result2.yearlyDetails} size={920} />
          </CenteredDiv>
        </Section>

        <Section2 center>
          <Header
            size="medium"
            style={{
              position: 'absolute',
              top: '-7px',
              left: 15,
              background: 'white',
              padding: '0 5px',
              margin: 0,
              zIndex: 8,
              transition: 'all 300ms ease',
            }}
          >
            {`Year-by-Year Details - ${inputs.spouse2}`}
            <Header.Subheader style={{ textAlign: 'left', color: colors.blue }}>
              {!isRiskFree && scenario
                ? scenario === 'median'
                  ? 'Median Outcome'
                  : 'Sequence Of Returns Risk Outcome'
                : 'Median Outcome'}
            </Header.Subheader>
          </Header>
          <div>
            <P1
              style={{
                margin: '0.3rem auto',
                width: '100%',
                zIndex: 600,
                transform: 'translateX(15px)',
              }}
            >
              The table below displays data annually for the first 5 years, then
              displays in 5-year intervals. All figures in the table are
              nominal, reflecting future values, except{' '}
              <strong>real spending</strong>.
            </P1>
            <div style={{ position: 'relative' }}>
              <TableWrapper>
                <Header
                  size="medium"
                  content="Spending"
                  textAlign="left"
                  style={{
                    margin: '10px 0 0 0',
                    transform: 'translateY(20px)',
                  }}
                />
                <Table>
                  <thead>
                    <tr>
                      <th />
                      {filtered2.map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="title">Gross spending</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            result2.yearlyDetails[key].incTotal !== undefined &&
                            this.formatNum(result2.yearlyDetails[key].incTotal)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Net spending</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            result2.yearlyDetails[key].incNet !== undefined &&
                            this.formatNum(result2.yearlyDetails[key].incNet)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Real net spending</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            result2.yearlyDetails[key].incPlot !== undefined &&
                            this.formatNum(result2.yearlyDetails[key].incPlot)}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Retirement income"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">CPP/QPP</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                          result2.yearlyDetails[key].incCPP !== undefined
                            ? this.formatNum(result2.yearlyDetails[key].incCPP)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">OAS</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                          result2.yearlyDetails[key].incOAS !== undefined
                            ? this.formatNum(result2.yearlyDetails[key].incOAS)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">GIS</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                          result2.yearlyDetails[key].incGIS !== undefined
                            ? this.formatNum(result2.yearlyDetails[key].incGIS)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Employment pension</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                          result2.yearlyDetails[key].incPension !== undefined
                            ? this.formatNum(
                                result2.yearlyDetails[key].incPension
                              )
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Other</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                          result2.yearlyDetails[key].incOther !== undefined
                            ? this.formatNum(
                                result2.yearlyDetails[key].incOther
                              )
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Investments"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">Minimum RRIF</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            this.formatNum(result2.yearlyDetails[key].wdReqMin)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Non-registered</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            this.formatNum(
                              result2.yearlyDetails[key].wdRegular
                            )}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">RRIF</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            this.formatNum(result2.yearlyDetails[key].wdRRIF)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">TFSA</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            this.formatNum(result2.yearlyDetails[key].wdTFSA)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">
                        Dividends
                        <Popup
                          trigger={<Icon name="question circle outline" />}
                          position="top center"
                          content="From non-registered accounts."
                          wide
                        />
                      </td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            this.formatNum(
                              result2.yearlyDetails[key].incDividend
                            )}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Taxes"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">Taxes</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            this.formatNum(result2.yearlyDetails[key].taxBoth)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">OAS clawback</td>
                      {filtered2.map((key) => (
                        <td key={key}>
                          {result2.yearlyDetails[key] &&
                            result2.yearlyDetails[key].taxOAS &&
                            this.formatNum(result2.yearlyDetails[key].taxOAS)}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </TableWrapper>
            </div>

            <div
              style={{ textAlign: 'center', transform: 'translateY(-10px)' }}
            >
              <Statistic size="tiny">
                <Statistic.Label>
                  Future value of bequest
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  />
                </Statistic.Label>
                <Statistic.Value>
                  {this.formatNum(result2.terminalValue)}
                </Statistic.Value>
              </Statistic>
            </div>
          </div>
        </Section2>

        <Section2>
          <Header
            size="medium"
            style={{
              position: 'absolute',
              top: '-7px',
              left: 15,
              background: 'white',
              padding: '0 5px',
              margin: 0,
              transition: 'all 300ms ease',
            }}
          >
            Model Features & Assumptions
          </Header>
          <div style={{ height: '50px', width: '100%' }} />
          <Assumptions>
            <Item>
              <Header size="small" content="Optimality" />
              <p style={{ fontSize: '1rem' }}>
                Optimality means allocating withdrawals between taxable and RRIF
                accounts so that:
              </p>
              <ul>
                <li>The after-tax income path is smooth</li>
                <li>The present value of after-tax income is maximized</li>
                <li>The client-specific bequest amount is satisfied</li>
              </ul>
            </Item>

            <Item>
              <Header
                size="small"
                content="The drawdown plan is not constrained to a sequential withdrawal strategy"
              />
              <ul>
                <li>
                  Because a sequential strategy (taxable accounts then RRIF or
                  vice versa) is not necessarily optimal in minimizing taxes.
                  Therefore, our algorithm solves for the optimal combination of
                  RRIF and taxable accounts to draw from in each year of the
                  planning horizon.
                </li>
                <li>
                  Since withdrawing from TFSA has no tax consequences, it is
                  best set aside for a rainy day and for one’s bequest.
                </li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Tax credits" />
              <ul>
                <li>
                  Basic Personal amount
                  <ul>
                    <li>Federal enhanced basic personal amount</li>
                    <li>Indexed to inflation except for NS, PE</li>
                    <li>
                      Additional basic personal amount in NS is not included
                    </li>
                  </ul>
                </li>
                <li>
                  Age amount
                  <ul>
                    <li>Indexed to inflation except for NS, PE, MB</li>
                    <li>Additional age amount in NS is not included</li>
                    <li>Not included in QC</li>
                  </ul>
                </li>
                <li>
                  Pension income amount
                  <ul>
                    <li>Indexed to inflation only in ON</li>
                    <li>Not included in QC</li>
                  </ul>
                </li>
                <li>Dividend tax credit</li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Taxable accounts" />
              <ul>
                <li>Dividend tax, tax credit for eligible dividends</li>
                <li>Capital gains tax</li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Public pensions" />
              <ul>
                <li>
                  Accommodates standard, deferral or early withdrawal of CPP/OAS
                </li>
                <li>OAS clawback</li>
                <li>CPP/QPP/OAS estimators are provided</li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Taxes" />
              <ul>
                <li>
                  Federal and provincial tax brackets, indexed to inflation
                  (except for NS, PE)
                </li>
                <li>
                  Provincial surtax (ON and PE), surtax brackets indexed to
                  inflation in ON
                </li>
                <li>OHIP premium (ON)</li>
                <li>OAS clawback (recovery tax)</li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Inflation" />
              <ul>
                <li>2%</li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Bequest" />
              <ul>
                <li>Client-specific value, adjusted for inflation</li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Company pension" />
              <ul>
                <li>
                  Client specifies value and whether it is adjusted for
                  inflation
                </li>
              </ul>
            </Item>

            <Item>
              <Header
                size="small"
                content="RRSP has been converted into a RRIF"
              />
              <ul>
                <li>
                  Calculates minimum RRIF and additional withdrawal (if
                  required) from RRIF
                </li>
                <li>
                  Excess RRIF withdrawals will be reallocated to TFSA and/or
                  non-registered investment accounts.
                </li>
              </ul>
            </Item>

            <Item>
              <Header
                size="small"
                content="Rental / part-time income... etc."
              />
              <ul>
                <li>
                  Client specifies how long they last and whether this income is
                  adjusted for inflation
                </li>
              </ul>
            </Item>

            <Item>
              <Header size="small" content="Portfolio options" />
              <ul>
                <li>Flat rate of return (fixed income without risk), or</li>
                <li>
                  10,000 simulations using monthly historical returns of an
                  integrated investment portfolio
                </li>
                <li>Sequence-of-returns risk option</li>
              </ul>
            </Item>
          </Assumptions>
          <Section2>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: '-7px',
                left: 15,
                background: 'white',
                padding: '0 5px',
                margin: 0,
                transition: 'all 300ms ease',
              }}
            >
              Model Features & Assumptions
            </Header>
            <div style={{ height: '40px', width: '100%' }} />
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <Item style={{ marginRight: '0.3rem' }}>
                <Header size="small" content="Income Splitting and Credits" />
                <ul>
                  <li>
                    The joint planning horizon for income splitting and credit
                    purposes is the shorter one of the two, between you and your
                    spouse/partner.
                  </li>
                  <li>
                    Tax savings (if any) are assumed to be added to spending and
                    not reinvested. It is advisable to check back every year,
                    particularly if your financial circumstances have changed.
                  </li>
                </ul>
              </Item>
              <Item>
                <Header size="small" content="CPP/QPP Income Sharing" />
                <ul>
                  <li>
                    CPP/QPP benefit sharing is not included in the optimization.
                    The sharing percentage is fixed and may not be optimal for
                    every year in the planning horizon. Also, the amounts that
                    can be shared are relatively small.
                  </li>
                </ul>
              </Item>
            </div>
          </Section2>
        </Section2>
      </PDF>
    );
  }
}

Result.propTypes = {
  drawdown: PropTypes.object,
  coupleDrawdown: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object.isRequired,
  savedCoupleDrawdownPlans: PropTypes.array,
};

Result.defaultProps = {
  drawdown: {},
  coupleDrawdown: {},
  match: {},
  history: {},
  dispatch: () => false,
  savedCoupleDrawdownPlans: [],
};

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem auto;
  max-width: 99.5%;
  height: 99vh;
  border: 2px solid ${colors.periwinkle};
  border-radius: 8px;
  padding: 0;
  text-align: left;
  background: white;
  page-break-inside: avoid;
`;

const Section2 = styled.div`
  position: relative;
  display: 'flex';
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0 auto;
  max-width: 99.5%;
  max-height: ${(props) => (props.table ? '200vh' : '98.5vh')};
  height: 98.5vh;
  border: 2px solid ${colors.periwinkle};
  border-radius: 8px;
  padding: 1rem;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  page-break-inside: avoid;
`;
const LeftHalf = styled.div`
  background-color: white;
  width: 75%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

const RightHalf = styled.div`
  background-color: ${colors.periwinkle}; /* Periwinkle */
  opacity: 0.6;
  width: 25%;
  height: 100%;
  border-radius: 0 8px 8px 0;
`;

const SubHeader = styled.h2`
  font-size: 1.25rem; /* Slightly smaller size for subheaders */
  font-weight: 400; /* Regular weight */
  margin: 0.25rem 0;
  color: #333; /* Dark color for subheaders */

  span {
    font-weight: 700; /* Bold for the labels like "Prepared by:" */
    font-style: italic;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const Table = styled.table`
  text-align: center;
  padding: 0;
  width: 100%;

  tr {
    width: 100%;
  }

  td,
  th {
    border: 1px solid #eaecef;
    padding: 6px 12px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      color: rgba(0, 0, 0, 0.8);
      width: 168px;
    }

    &:last-child {
      border-right: 0;
    }

    &.title {
      font-weight: 700;
      min-width: 12rem;
      text-align: left;
    }

    &.short {
      width: 15%;
      max-width: 11rem;
      min-width: 7rem;
    }

    &.text {
      font-size: 0.9rem;
    }
  }

  th {
    background: #fafbfc;

    &:first-child {
      background: white;
      border-top: 0;
    }
  }

  &.fixed {
    display: block;
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 168px;
    background: white;
    padding-top: 79px;

    td {
      border-right: 1px solid #eaecef;
    }
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  transform: translateY(-20px);
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: ${(props) => (props.margin ? '1rem auto' : '0 auto')};
  text-align: center;
`;

const P1 = styled.p`
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;

  &.margin {
    background: lightgreen;
  }

  &.circle {
    align-items: center;
    justify-content: space-around;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 2.5rem;

    &.blue {
      background: ${colors.blue};
    }

    &.orange {
      background: ${colors.orange};
    }
  }

  .flex-item {
    width: 45%;
    min-width: 300px;
    min-height: 250px;
    padding-bottom: 5rem;
    align-self: flex-start !important;
  }
`;

const PDF = styled.div`
  position: relative;
  background: white;
  text-align: center;
  padding: 0.5rem;
  border-radius: 8px;
  width: 1160px;
  margin: -105px auto 0 auto;

  @media print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    html,
    body {
      height: 95% !important;
      page-break-after: avoid;
    }

    @page {
      size: landscape !important;
      margin: 0.5cm !important;
      page-break-after: avoid;
    }
  }
`;

const Assumptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Item = styled.div`
  display: block;
  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 33%;
  margin: 3px 0;
  padding: 5px;
  border-radius: 4px;
`;
