/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import {
  Popup,
  Label,
  Button,
  Icon,
  Segment,
  Divider,
  List,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ChartTool } from 'actions';
import { chartingToolColors } from 'utils/colors';

@withRouter
class DateMenu extends Component {
  state = {
    absoluteYoungestStartDate: this.props.absoluteYoungestStartDate,
    startFocusDate: this.props.startDate,
    endFocusDate: this.props.endDate,
    absoluteOldestEndDate: this.props.absoluteOldestEndDate,
    selectedPredefinedPeriod: '',
    isStartSelectorOpen: false,
    isEndSelectorOpen: false,
  };

  componentDidUpdate(oldProps) {
    const propsChanged =
      oldProps.startDate !== this.props.startDate ||
      oldProps.endDate !== this.props.endDate ||
      oldProps.absoluteYoungestStartDate !==
        this.props.absoluteYoungestStartDate;

    if (propsChanged) {
      const startFocusDate = this.removeDayFromDate(this.props.startDate);
      const endFocusDate = this.removeDayFromDate(this.props.endDate);
      const absoluteYoungestStartDate = this.removeDayFromDate(
        this.props.absoluteYoungestStartDate
      );
      const absoluteOldestEndDate = this.removeDayFromDate(
        this.props.absoluteOldestEndDate
      );

      this.setState({
        startFocusDate,
        endFocusDate,
        absoluteYoungestStartDate,
        absoluteOldestEndDate,
      });
    }
  }

  componentWillUnmount = () => {
    const { history, dispatch } = this.props;
    const { location } = history;

    if (location && location.pathname !== '/tools/fund-comparison/pdf') {
      dispatch(ChartTool.clearChartData());
      this.clearPredefinedPeriod();
    }
  };

  getDateYear = (date) => Number(date.split('-')[0]);

  getDateMonth = (date) => Number(date.split('-')[1]);

  getDateInHuman = (date) =>
    `${
      monthNames[Number(this.getDateMonth(date)) - 1]
    }\u00A0\u00A0' ${this.getDateYear(date).toString().substring(2)}`;

  formatDate = (yr, mnth) => {
    const valMnth = Number(mnth) < 10 ? `0${mnth}` : mnth;
    return `${yr}-${valMnth}`;
  };

  removeDayFromDate = (date) => {
    let year = this.getDateYear(date);
    let month = this.getDateMonth(date) + 1;
    if (month === 13) {
      month = 1; // Reset to January of the next year
      year += 1; // Increment year
    }
    return this.formatDate(year, month);
  };

  selectPredefinedPeriod = (period) => {
    const mostRecentDateYear = this.getDateYear(
      this.state.absoluteOldestEndDate
    );
    const mostRecentDateMonth = this.getDateMonth(
      this.state.absoluteOldestEndDate
    );
    const mostRecentDate = new Date(mostRecentDateYear, mostRecentDateMonth);

    if (period === 'YTD') {
      const startDate = new Date(mostRecentDate);
      startDate.setMonth(0);
      const startDateStr = this.formatDate(
        startDate.getFullYear(),
        startDate.getMonth() + 1
      );
      this.props.updateStartEndDate(
        startDateStr,
        this.state.absoluteOldestEndDate
      );
    } else {
      const monthDelta = predefinedPeriodsLengths[period];
      const startDate = new Date(mostRecentDate);
      startDate.setMonth(startDate.getMonth() - monthDelta);

      const startDateStr = this.formatDate(
        startDate.getFullYear(),
        startDate.getMonth() + 1
      );

      this.props.updateStartEndDate(
        startDateStr,
        this.state.absoluteOldestEndDate
      );
    }
    this.setState({ selectedPredefinedPeriod: period });
  };

  clearPredefinedPeriod = () => {
    this.setState({ selectedPredefinedPeriod: '' });
    this.props.updateStartEndDate('', '');
  };

  isActivePeriod = (item) => {
    if (item === this.state.selectedPredefinedPeriod) {
      if (this.props.chartData.length === 0) {
        this.clearPredefinedPeriod();
        return false;
      }
      return true;
    }
    return false;
  };

  checkInvalidInterval = (period) => {
    let invalidInterval = false;
    if (period !== 'YTD') {
      const mostRecentDateYear = this.getDateYear(
        this.state.absoluteOldestEndDate
      );
      const mostRecentDateMonth = this.getDateMonth(
        this.state.absoluteOldestEndDate
      );

      const leastRecentDateYear = this.getDateYear(
        this.state.absoluteYoungestStartDate
      );
      const leastRecentDateMonth = this.getDateMonth(
        this.state.absoluteYoungestStartDate
      );

      const curMaxMonthDelta = this.countMonths(
        leastRecentDateYear,
        leastRecentDateMonth,
        mostRecentDateYear,
        mostRecentDateMonth
      );
      const totalMonthDelta = predefinedPeriodsLengths[period];

      if (curMaxMonthDelta < totalMonthDelta) {
        invalidInterval = true;
      }
    } else {
      invalidInterval =
        this.getDateMonth(this.state.absoluteOldestEndDate) === 1;
    }
    if (invalidInterval && period === this.state.selectedPredefinedPeriod) {
      this.setState({ selectedPredefinedPeriod: '' });
    }
    return invalidInterval;
  };

  countMonths = (startYear, startMonth, endYear, endMonth) => {
    if (startYear === endYear) {
      return endMonth - startMonth;
    }
    let totalMonths = 0;
    totalMonths += 12 - startMonth;
    while (startYear < endYear) {
      totalMonths += 12;
      startYear += 1;
    }
    totalMonths += endMonth - 1;
    return totalMonths;
  };

  drawCalendar = (isEnd) => {
    // constant definitions
    const focusYear = isEnd
      ? this.getDateYear(this.state.endFocusDate)
      : this.getDateYear(this.state.startFocusDate);
    const focusMonth = isEnd
      ? this.getDateMonth(this.state.endFocusDate)
      : this.getDateMonth(this.state.startFocusDate);
    const otherFocusYear = isEnd
      ? this.getDateYear(this.state.startFocusDate)
      : this.getDateYear(this.state.endFocusDate);
    const otherFocusMonth = isEnd
      ? this.getDateMonth(this.state.startFocusDate)
      : this.getDateMonth(this.state.endFocusDate);
    const absStartYear = this.getDateYear(this.state.absoluteYoungestStartDate);
    const absStartMonth = this.getDateMonth(
      this.state.absoluteYoungestStartDate
    );
    const absEndYear = this.getDateYear(this.state.absoluteOldestEndDate);
    const absEndMonth = this.getDateMonth(this.state.absoluteOldestEndDate);

    return (
      <Segment
        style={{
          margin: '0rem',
          padding: '0rem',
          border: 'none',
          boxShadow: 'none',
          textAlign: 'center',
        }}
      >
        <Button.Group
          compact
          widths="7"
          size="medium"
          style={{
            margin: '0rem',
            padding: '0rem',
            borderStyle: 'none',
            boxShadow: 'none',
          }}
        >
          <Button
            disabled={
              isEnd
                ? focusYear === otherFocusYear ||
                  (otherFocusMonth === 12 && focusYear - 1 === otherFocusYear)
                : focusYear === absStartYear
            }
            onClick={
              isEnd
                ? () => {
                    if (
                      focusMonth <= otherFocusMonth &&
                      focusYear - 1 === otherFocusYear
                    ) {
                      this.setState({
                        endFocusDate: this.formatDate(
                          focusYear - 1,
                          otherFocusMonth + 1
                        ),
                      });
                    } else {
                      this.setState({
                        endFocusDate: this.formatDate(
                          focusYear - 1,
                          focusMonth
                        ),
                      });
                    }
                  }
                : () => {
                    if (
                      focusMonth < absStartMonth &&
                      focusYear - 1 === absStartYear
                    ) {
                      this.setState({
                        startFocusDate: this.formatDate(
                          focusYear - 1,
                          absStartMonth
                        ),
                      });
                    } else {
                      this.setState({
                        startFocusDate: this.formatDate(
                          focusYear - 1,
                          focusMonth
                        ),
                      });
                    }
                  }
            }
            icon="caret left"
            style={{
              backgroundColor: 'white',
              padding: '0rem 0.3rem',
              float: 'left',
            }}
          />

          <Button
            disabled
            style={
              isEnd
                ? focusYear - 2 >= otherFocusYear
                  ? {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: chartingToolColors.grey,
                    }
                  : {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: 'white',
                    }
                : focusYear - 2 >= absStartYear
                ? {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: chartingToolColors.grey,
                  }
                : {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: 'white',
                  }
            }
            content={focusYear - 2}
          />
          <Button
            disabled
            style={
              isEnd
                ? focusYear - 1 >= otherFocusYear
                  ? {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: chartingToolColors.darkGrey,
                    }
                  : {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: 'white',
                    }
                : focusYear - 1 >= absStartYear
                ? {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: chartingToolColors.darkGrey,
                  }
                : {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: 'white',
                  }
            }
            content={focusYear - 1}
          />
          <Button
            id="currentYear"
            style={{ backgroundColor: 'white', padding: '0rem 0.3rem' }}
            content={focusYear}
          />
          <Button
            disabled
            style={
              isEnd
                ? focusYear + 1 <= absEndYear
                  ? {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: chartingToolColors.darkGrey,
                    }
                  : {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: 'white',
                    }
                : focusYear + 1 <= otherFocusYear
                ? {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: chartingToolColors.darkGrey,
                  }
                : {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: 'white',
                  }
            }
            content={focusYear + 1}
          />
          <Button
            disabled
            style={
              isEnd
                ? focusYear + 2 <= absEndYear
                  ? {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: chartingToolColors.grey,
                    }
                  : {
                      backgroundColor: 'white',
                      padding: '0rem 0.4rem',
                      fontSize: '0.9rem',
                      color: 'white',
                    }
                : focusYear + 2 <= otherFocusYear
                ? {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: chartingToolColors.grey,
                  }
                : {
                    backgroundColor: 'white',
                    padding: '0rem 0.4rem',
                    fontSize: '0.9rem',
                    color: 'white',
                  }
            }
            content={focusYear + 2}
          />

          <Button
            disabled={
              isEnd
                ? focusYear === absEndYear
                : focusYear === otherFocusYear ||
                  (focusYear + 1 === otherFocusYear && otherFocusMonth === 1)
            }
            onClick={
              isEnd
                ? () => {
                    if (
                      focusMonth >= absEndMonth &&
                      focusYear + 1 === absEndYear
                    ) {
                      this.setState({
                        endFocusDate: this.formatDate(
                          focusYear + 1,
                          absEndMonth
                        ),
                      });
                    } else {
                      this.setState({
                        endFocusDate: this.formatDate(
                          focusYear + 1,
                          focusMonth
                        ),
                      });
                    }
                  }
                : () => {
                    if (
                      focusMonth >= otherFocusMonth &&
                      focusYear + 1 === otherFocusYear
                    ) {
                      this.setState({
                        startFocusDate: this.formatDate(
                          focusYear + 1,
                          otherFocusMonth - 1
                        ),
                      });
                    } else {
                      this.setState({
                        startFocusDate: this.formatDate(
                          focusYear + 1,
                          focusMonth
                        ),
                      });
                    }
                  }
            }
            icon="caret right"
            style={{
              backgroundColor: 'white',
              padding: '0rem 0.3rem',
              float: 'right',
            }}
          />
        </Button.Group>
        <Divider style={{ margin: '0.4rem' }} />
        <Button.Group
          compact
          fluid
          size="tiny"
          style={{
            margin: '0rem',
            padding: '0rem',
            borderStyle: 'none',
            boxShadow: 'none',
          }}
        >
          {monthNames.slice(0, 6).map((item) => (
            <Button
              disabled={
                (!isEnd &&
                  ((monthNames.indexOf(item) + 1 < absStartMonth &&
                    focusYear === absStartYear) ||
                    (monthNames.indexOf(item) + 2 > otherFocusMonth &&
                      focusYear === otherFocusYear))) ||
                (isEnd &&
                  ((monthNames.indexOf(item) < otherFocusMonth &&
                    otherFocusYear === focusYear) ||
                    (monthNames.indexOf(item) + 1 > absEndMonth &&
                      focusYear === absEndYear)))
              }
              onClick={
                isEnd
                  ? () => {
                      this.setState({
                        isEndSelectorOpen: false,
                        endFocusDate: this.formatDate(
                          focusYear,
                          monthNames.indexOf(item) + 1
                        ),
                      });
                    }
                  : () => {
                      this.setState({
                        isStartSelectorOpen: false,
                        startFocusDate: this.formatDate(
                          focusYear,
                          monthNames.indexOf(item) + 1
                        ),
                      });
                    }
              }
              style={
                (isEnd && monthNames[focusMonth - 1] === item) ||
                (!isEnd && monthNames[focusMonth - 1] === item)
                  ? {
                      backgroundColor: 'white',
                      padding: '0.4rem',
                      color: chartingToolColors.blue,
                    }
                  : { backgroundColor: 'white', padding: '0.4rem' }
              }
              key={item}
              content={item}
            />
          ))}
        </Button.Group>
        <br />
        <Button.Group
          compact
          fluid
          size="tiny"
          style={{
            margin: '0rem',
            padding: '0rem',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          {monthNames.slice(6).map((item) => (
            <Button
              disabled={
                (!isEnd &&
                  ((monthNames.indexOf(item) + 1 < absStartMonth &&
                    focusYear === absStartYear) ||
                    (monthNames.indexOf(item) + 2 > otherFocusMonth &&
                      focusYear === otherFocusYear))) ||
                (isEnd &&
                  ((monthNames.indexOf(item) < otherFocusMonth &&
                    otherFocusYear === focusYear) ||
                    (monthNames.indexOf(item) + 1 > absEndMonth &&
                      focusYear === absEndYear)))
              }
              onClick={
                isEnd
                  ? () => {
                      this.setState({
                        isEndSelectorOpen: false,
                        endFocusDate: this.formatDate(
                          focusYear,
                          monthNames.indexOf(item) + 1
                        ),
                      });
                    }
                  : () => {
                      this.setState({
                        isStartSelectorOpen: false,
                        startFocusDate: this.formatDate(
                          focusYear,
                          monthNames.indexOf(item) + 1
                        ),
                      });
                    }
              }
              style={
                (isEnd && monthNames[focusMonth - 1] === item) ||
                (!isEnd && monthNames[focusMonth - 1] === item)
                  ? {
                      backgroundColor: 'white',
                      padding: '0.4rem',
                      color: chartingToolColors.blue,
                    }
                  : { backgroundColor: 'white', padding: '0.4rem' }
              }
              key={item}
              content={item}
            />
          ))}
        </Button.Group>
      </Segment>
    );
  };

  incrementLabelMonth = (startFocusDate) => {
    const year = startFocusDate.split('-')[0];
    const month = startFocusDate.split('-')[1];

    return `${year}-${Number(month).toString()}`; // send actual month for start date instead of adding 1 year
  };

  render() {
    const { startFocusDate, endFocusDate, isEndSelectorOpen } = this.state;

    return (
      <Segment
        style={{
          border: 'none',
          boxShadow: 'none',
          padding: '0rem 0rem 2rem 0rem',
          margin: '2rem',
        }}
      >
        <List horizontal floated="left" style={{ marginLeft: '4rem' }}>
          <List.Item>
            {Object.keys(predefinedPeriodsLengths).map((item, index) => (
              <Button
                size="mini"
                toggle
                active={this.isActivePeriod(item)}
                disabled={
                  this.checkInvalidInterval(item) ||
                  this.props.chartData.length === 0
                }
                compact
                key={index}
                content={item}
                onClick={(e, value) =>
                  this.selectPredefinedPeriod(value.content)
                }
              />
            ))}
            <Button
              size="mini"
              compact
              content="Max"
              style={{ backgroundColor: chartingToolColors.skyBlue }}
              onClick={() => this.clearPredefinedPeriod()}
            />
          </List.Item>
        </List>

        <List horizontal floated="right">
          <List.Item>
            Start: &nbsp;&nbsp;
            <Popup
              open={this.state.isStartSelectorOpen}
              onOpen={() => {
                this.setState({ isStartSelectorOpen: true }, () => {
                  this.setState({ isEndSelectorOpen: false });
                });
              }}
              onClose={() => {
                this.setState({ isStartSelectorOpen: false });
              }}
              on={['click']}
              style={{
                padding: '0.4rem',
              }}
              trigger={
                <Label
                  as="a"
                  basic
                  style={{
                    padding: '0.4rem 0rem 0.4rem 0.6rem',
                  }}
                  horizontal
                >
                  {this.getDateInHuman(startFocusDate)}
                  <Icon
                    fitted
                    style={{ marginLeft: '0.6rem' }}
                    name="caret down"
                  />
                </Label>
              }
              position="bottom center"
              content={this.drawCalendar(false)}
            />
          </List.Item>
          <List.Item>
            End (inclusive): &nbsp;&nbsp;
            <Popup
              open={isEndSelectorOpen}
              onOpen={() => {
                this.setState({ isEndSelectorOpen: true }, () => {
                  this.setState({ isStartSelectorOpen: false });
                });
              }}
              onClose={() => {
                this.setState({ isEndSelectorOpen: false });
              }}
              on={['click']}
              style={{
                padding: '0.4rem',
              }}
              trigger={
                <Label
                  as="a"
                  basic
                  style={{
                    padding: '0.4rem 0rem 0.4rem 0.6rem',
                  }}
                  horizontal
                >
                  {this.getDateInHuman(endFocusDate)}
                  <Icon
                    fitted
                    style={{ marginLeft: '0.6rem' }}
                    name="caret down"
                  />
                </Label>
              }
              position="bottom center"
              content={this.drawCalendar(true)}
            />
          </List.Item>
          <List.Item>
            <Button
              size="mini"
              compact
              style={{
                backgroundColor: chartingToolColors.skyBlue,
                marginRight: '1rem',
              }}
              icon="chevron right"
              onClick={() => {
                this.props.updateStartEndDate(
                  this.incrementLabelMonth(startFocusDate),
                  endFocusDate
                );
                this.setState({ selectedPredefinedPeriod: '' });
              }}
            />
          </List.Item>
        </List>
      </Segment>
    );
  }
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const predefinedPeriodsLengths = {
  '3M': 3,
  '6M': 6,
  YTD: -1,
  '1Y': 12,
  '3Y': 36,
  '5Y': 60,
};

DateMenu.defaultProps = {
  chartData: [],
  history: {},
};

DateMenu.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  absoluteYoungestStartDate: PropTypes.string.isRequired,
  absoluteOldestEndDate: PropTypes.string.isRequired,
  updateStartEndDate: PropTypes.func.isRequired,
  chartData: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default connect((state) => ({
  chartData: state.ChartTool.securityChartData.concat(
    state.ChartTool.portfolioChartData
  ),
  startDate:
    state.ChartTool.startDate !== undefined && state.ChartTool.startDate !== ''
      ? state.ChartTool.startDate
      : '2007-04',
  absoluteYoungestStartDate:
    state.ChartTool.absoluteYoungestStartDate !== undefined &&
    state.ChartTool.absoluteYoungestStartDate !== ''
      ? state.ChartTool.absoluteYoungestStartDate
      : '2007-04',
  endDate:
    state.ChartTool.endDate !== undefined && state.ChartTool.endDate !== ''
      ? state.ChartTool.endDate
      : `${new Date().getFullYear().toString()}-${
          new Date().getMonth() < 9
            ? `0${new Date().getMonth() + 1}`
            : new Date().getMonth() + 1
        }`,
  absoluteOldestEndDate:
    state.ChartTool.absoluteOldestEndDate !== undefined &&
    state.ChartTool.absoluteOldestEndDate !== ''
      ? state.ChartTool.absoluteOldestEndDate
      : `${new Date().getFullYear().toString()}-${
          new Date().getMonth() < 9
            ? `0${new Date().getMonth() + 1}`
            : new Date().getMonth() + 1
        }`,
}))(DateMenu);
