import { Auth } from 'actions';

const initialState = {
  isFetching: false,
  token: null,
  featureRestrictions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Auth.AUTH_START:
      return {
        ...state,
        isFetching: true,
      };
    case Auth.AUTH_END:
      return initialState;
    case Auth.VALIDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        token: action.token,
        featureRestrictions: action.featureRestrictions,
      };
    default:
      return state;
  }
}
