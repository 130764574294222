import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header as SemanticHeader, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';

import { Planning } from 'actions';
import {
  Segment,
  Card,
  Header,
  Modal,
  Button,
  LastUpdatedTag,
} from 'components';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';

class SavedDrawdownPlans extends Component {
  constructor(props) {
    super(props);
    const { name, description } = this.props;
    this.state = {
      isOpen: false,
      loading: false,
      id: null,
      name: name || '',
      description: description || '',
      currentTab: 'drawdown',
      ddIndex: null,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(Planning.fetchAllDrawdownPlans());
    dispatch(Planning.fetchAllTargetDrawdownPlans());
    dispatch(Planning.fetchAllCoupleDrawdownPlans());
    dispatch(Planning.clearPlanning());

    return false;
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value });
  };

  handleCancel = () => {
    const { name, description } = this.props;

    return this.setState({
      isOpen: false,
      name: name || '',
      description: description || '',
    });
  };

  handleSave = () => {
    const { dispatch, drawdown } = this.props;
    const { name, description, id, currentTab } = this.state;
    const { isRiskFree, port, constantRets } = drawdown.inputs;
    const body = {
      outputData: drawdown.result,
      ...drawdown.inputs,
      ...(isRiskFree
        ? {
            isRiskFree: true,
            constantRets: Number(constantRets) / 100,
          }
        : {
            isRiskFree: false,
            symbols: port.split('-')[0],
            weights: port.split('-')[1],
          }),
      name,
      description,
    };
    if (currentTab === 'couple') {
      dispatch(Planning.updateCoupleDrawdownPlan(id, body));
    } else if (currentTab === 'target') {
      dispatch(Planning.updateTargetDrawdownPlan(id, body));
    } else {
      dispatch(Planning.updateDrawdownPlan(id, body));
    }

    return this.setState({ isOpen: false, loading: false });
  };

  removeDrawdownPlan = (id) => {
    const { dispatch } = this.props;
    const { currentTab } = this.state;

    this.setState({ loading: true });
    if (currentTab === 'couple') {
      return dispatch(Planning.removeCoupleDrawdownPlan(id)).then(
        () => this.setState({ isOpen: false, loading: false }),
        () => this.setState({ isOpen: false, loading: false })
      );
    }

    return dispatch(Planning.removeDrawdownPlan(id)).then(
      () => this.setState({ isOpen: false, loading: false }),
      () => this.setState({ isOpen: false, loading: false })
    );
  };

  renderTab = (currentTab) => (
    <div>
      <Tab
        onClick={() => this.setState({ currentTab: 'drawdown' })}
        active={currentTab === 'drawdown'}
      >
        Optimal Drawdown
      </Tab>
      <Tab
        onClick={() => this.setState({ currentTab: 'target' })}
        active={currentTab === 'target'}
      >
        Drawdown with Target Spending
      </Tab>
      <Tab
        onClick={() => this.setState({ currentTab: 'couple' })}
        active={currentTab === 'couple'}
      >
        Drawdown for couples
      </Tab>
    </div>
  );

  render() {
    const {
      savedDrawdownPlans,
      savedTargetDrawdownPlans,
      savedCoupleDrawdownPlans,
      dispatch,
      partnerName,
    } = this.props;
    const { isOpen, name, description, loading, currentTab, ddIndex, id } =
      this.state;

    return (
      <Segment margin="1rem">
        <Header
          size="1.5rem"
          color="black"
          subheader={
            <div
              dangerouslySetInnerHTML={{
                __html:
                  'These are all your saved Drawdown Plans. Once you save a Drawdown Plan (up to 5 <b>of each kind</b>), it will appear here. Revisit and update your Drawdown(s) whenever there has been a substantial change in your personal and/or financial situation.',
              }}
            />
          }
          long
        >
          Your Saved Drawdown Plans
        </Header>
        <Container>{this.renderTab(currentTab)}</Container>
        {currentTab === 'drawdown' && !savedDrawdownPlans.length && (
          <div style={{ marginTop: 15 }}>
            When you save Drawdown Plans, they will appear here.
          </div>
        )}{' '}
        {currentTab === 'drawdown' &&
          savedDrawdownPlans.length > 0 &&
          savedDrawdownPlans
            .sort(
              (a, b) =>
                new Date(b.updated_on).getTime() -
                new Date(a.updated_on).getTime()
            )
            .map((data, curr_ddIndex) => (
              <span key={data.id} style={{ position: 'relative' }}>
                <Card
                  header={data.name}
                  content={data.description}
                  to={`/planning/drawdown/${data.id}`}
                  color="rgb(252,196,123)"
                  style={{ width: '45%', margin: '0px 10px 10px 0px' }}
                  key={data.id}
                  icon="tree"
                >
                  <Popup
                    trigger={
                      <Icon
                        className="ui icon pencil"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(Planning.fetchDrawdownPlanId(data.id));
                          this.setState({
                            isOpen: 'edit',
                            name: data.name,
                            description: data.description,
                            id: data.id,
                            ddIndex: curr_ddIndex,
                          });
                        }}
                      />
                    }
                    position="bottom right"
                  >
                    Edit plan name and notes.
                  </Popup>
                  <Icon
                    className="ui icon close"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({
                        isOpen: 'delete',
                        name: data.name,
                        id: data.id,
                        ddIndex: curr_ddIndex,
                      });
                    }}
                  />
                  <LastUpdatedTag>
                    Last updated on {data.updated_on}
                  </LastUpdatedTag>
                </Card>
              </span>
            ))}
        {currentTab === 'target' && !savedTargetDrawdownPlans.length && (
          <div style={{ marginTop: 15 }}>
            When you save Drawdown Plans with Target Spending, they will appear
            here.
          </div>
        )}
        {currentTab === 'target' &&
          savedTargetDrawdownPlans.length > 0 &&
          savedTargetDrawdownPlans
            .sort(
              (a, b) =>
                new Date(b.updated_on).getTime() -
                new Date(a.updated_on).getTime()
            )
            .map((data, curr_ddIndex) => (
              <span key={data.id} style={{ position: 'relative' }}>
                <Card
                  header={data.name}
                  content={data.description}
                  to={`/planning/drawdown-target-spending/${data.id}`}
                  color="rgb(252,196,123)"
                  style={{ width: '45%', margin: '0px 10px 10px 0px' }}
                  key={data.id}
                  icon="tree"
                >
                  <Popup
                    trigger={
                      <Icon
                        className="ui icon pencil"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(Planning.fetchDrawdownPlanId(data.id));
                          this.setState({
                            isOpen: 'edit',
                            name: data.name,
                            description: data.description,
                            id: data.id,
                            ddIndex: curr_ddIndex,
                          });
                        }}
                      />
                    }
                    position="bottom right"
                  >
                    Edit plan name and notes.
                  </Popup>
                  <Icon
                    className="ui icon close"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({
                        isOpen: 'delete',
                        name: data.name,
                        id: data.id,
                        ddIndex: curr_ddIndex,
                      });
                    }}
                  />
                  <LastUpdatedTag>
                    Last updated on {data.updated_on}
                  </LastUpdatedTag>
                </Card>
              </span>
            ))}{' '}
        {currentTab === 'couple' && !savedCoupleDrawdownPlans.length && (
          <div style={{ marginTop: 15 }}>
            When you save Drawdown plans for couples, they will appear here.
          </div>
        )}
        {currentTab === 'couple' &&
          savedCoupleDrawdownPlans.length > 0 &&
          savedCoupleDrawdownPlans
            .sort(
              (a, b) =>
                new Date(b.updated_on).getTime() -
                new Date(a.updated_on).getTime()
            )
            .map((data) => (
              <span key={data.id} style={{ position: 'relative' }}>
                <Card
                  header={data.name}
                  content={data.description}
                  to={`/planning/drawdown-couples/${data.id}`}
                  color="orange"
                  style={{ width: '45%', margin: '0px 10px 10px 0px' }}
                  key={data.id}
                  icon="tree"
                >
                  <Popup
                    trigger={
                      <Icon
                        className="ui icon pencil"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(Planning.fetchCoupleDrawdownPlanId(data.id));
                          this.setState({
                            isOpen: 'edit',
                            name: data.name,
                            description: data.description,
                            id: data.id,
                          });
                        }}
                      />
                    }
                    position="bottom right"
                  >
                    Edit plan name and notes.
                  </Popup>
                  <Icon
                    className="ui icon close"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({
                        isOpen: 'delete',
                        name: data.name,
                        id: data.id,
                      });
                    }}
                  />
                  <LastUpdatedTag>
                    Last updated on {data.updated_on}
                  </LastUpdatedTag>
                </Card>
              </span>
            ))}
        <Modal
          basicFlex
          open={isOpen === 'delete'}
          offsetY={Math.floor(ddIndex / 2) * 70}
          dimmerClick={() => this.setState({ isOpen: false })}
          vb={notWealthica(partnerName)}
        >
          <Modal.Header>
            <SemanticHeader
              icon="warning sign"
              content="Delete Drawdown Plan"
              style={{ color: colors.red }}
            />
          </Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete Drawdown Plan{' '}
              <span style={{ color: colors.red }}>{name}</span> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              color={colors.red}
              icon="cancel"
              margin="0 0.5rem 0 0"
              onClick={() => this.setState({ isOpen: false })}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                { loading }
              )}
              color={colors.green}
              icon="checkmark"
              onClick={() => this.removeDrawdownPlan(id)}
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          basicFlex
          open={isOpen === 'edit'}
          offsetY={Math.floor(ddIndex / 2) * 70}
          dimmerClick={() => this.setState({ isOpen: false })}
          vb={notWealthica(partnerName)}
        >
          <Modal.Header>
            <SemanticHeader
              icon="briefcase"
              content="Edit Plan Name and Notes"
              style={{ color: colors.green }}
            />
            <SemanticHeader.Subheader>
              To edit plan details, please revisit the plan.
            </SemanticHeader.Subheader>{' '}
          </Modal.Header>
          <Modal.Content>
            <Field vb={notWealthica(partnerName)}>
              Plan name:
              <input
                placeholder="(required)"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Field>
            <Field vb={notWealthica(partnerName)}>
              Notes:
              <input
                placeholder="(optional)"
                name="description"
                value={description}
                onChange={this.handleChange}
              />
            </Field>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              icon="cancel"
              color={colors.red}
              margin="0 0.5rem 0 0"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                { loading, disabled: !name || !name.trim() }
              )}
              icon="checkmark"
              color={colors.green}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

SavedDrawdownPlans.propTypes = {
  dispatch: PropTypes.func.isRequired,
  savedDrawdownPlans: PropTypes.array.isRequired,
  drawdown: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.string,
  partnerName: PropTypes.string,
  savedTargetDrawdownPlans: PropTypes.array.isRequired,
  savedCoupleDrawdownPlans: PropTypes.array.isRequired,
};

SavedDrawdownPlans.defaultProps = {
  drawdown: {},
  name: '',
  description: '',
  partnerName: '',
};

export default connect((state) => ({
  drawdown: state.Planning.drawdown,
  savedDrawdownPlans: state.Planning.savedDrawdownPlans,
  partnerName: state.Storage['partner-name'],
  savedTargetDrawdownPlans: state.Planning.savedTargetDrawdownPlans,
  savedCoupleDrawdownPlans: state.Planning.savedCoupleDrawdownPlans,
}))(SavedDrawdownPlans);

const Icon = styled.i`
  display: inline-block;
  position: absolute;
  top: -1px;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
  margin: 0 !important;
  padding: 0 !important;
  height: 30px !important;
  width: 24px !important;
  z-index: 4;
  cursor: pointer;
  vertical-align: bottom;
  line-height: 2rem;

  &.close {
    right: 0;
    font-size: 1.3rem;

    &:hover {
      color: ${colors.red};
    }
  }

  &.pencil {
    right: 22px;

    &:hover {
      color: ${colors.blue};
    }
  }

  &.share {
    right: 46px;
    font-size: 16px !important;

    &:hover {
      color: ${colors.green};
    }
  }
`;

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;

const Container = styled.div`
  margin: 0;
  border-radius: 8px;
  background-color: white;
`;
const Tab = styled(({ className, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className={className} {...rest}>
    {children}
  </div>
))`
  display: inline-block;
  padding: 1rem;
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  text-align: left;
  font-weight: ${(props) => (props.active ? '700' : 'init')};
  background: ${(props) => (props.active ? 'rgb(246,246,250)' : 'init')};
  border: 1px solid rgb(236, 236, 240);
  &:first-child {
    border-top-left-radius: 8px;
    &:after {
      border-top-left-radius: 10px;
    }
  }
  &:last-child {
    border-top-right-radius: 8px;
    &:after {
      border-top-right-radius: 10px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${(props) => (props.active ? colors.red : 'init')};
  }
  &:hover {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`;
