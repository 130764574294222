import { injectGlobal } from 'styled-components';

import { colors } from 'utils/colors';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
  }

  .normalbody {    height: auto;
    overflow-x: visible;
    width: 100%;
    background: rgb(236,236,240);
    margin: 0;
    padding: 0;
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-print-color-adjust: exact;
    
    a {
      font-weight: bold;
      .lastUpdatedTag {
        font-weight: normal !important;
      }
    }

    @media print {
      background: white;
    }}

  .globebody {    height: auto;
    overflow-x: visible;
    width: 100%;
    background: white;
    margin: 0;
    padding: 0;
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-print-color-adjust: exact;

    @media print {
      background: white;
    }}

  #app {
  }

  // chartjs properties edited to fix Chrome bug detailed here  https://insiderpaper.com/google-chrome-html-print-preview-issue-reported-by-users/
  // we may remove this code once the Chrome issue is fixed in a future update.
  .chartjs-size-monitor-expand > div {
    position: fixed !important; // cannot stay “absolute”
  }

  chartjs-size-monitor,
  .chartjs-size-monitor-shrink,
  .chartjs-size-monitor-expand,
  .chartjs-size-monitor-expand > div {
    position: fixed !important; // cannot stay “absolute”
  }
  // chartjs bug fix end

  .appContent {
    min-height: 625px;
    padding: 60px 0 15px;
  }

  .appContent2 {
    min-height: 625px;
    padding: 0 0 15px;
  }
  .unauthorized {
    min-height: 100%;
  }

  .globeInvestorAppContent {
    .ui.secondary.pointing.menu {
    background: white;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 5;
  }
  }

  .globeContent {
    min-height: 550px;
    padding-bottom: 1rem;
  }

  .dimmer:not(.inverted) {
    background: rgba(0,0,0,0.8);
  }

  p {
    max-width: 51rem;
    font-size: 1.14rem;
  }

  .ui.form .field {
    clear: none !important;
  }

  input[type=number] {
    -moz-appearance: textfield !important;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  } 

  &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.2);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .ui.menu .item > img:not(.ui) {
    display: inline-block;
    vertical-align: middle;
    margin: -0.3em 0em;
    width: 2.5em;
    margin-right: 1.5em;
  }

  .ui.structured.table tr td,
  .ui.structured.table tr th {
    border-right: 0 !important;
  }

  .ui.menu.fixed {
    z-index: 102;
  }

  .ui.header .sub.header {
    font-size: 1.14rem;
  }

  .ui.center.aligned.header {
    max-width: 47rem;
    margin: 0 auto;
  }

  .ui.search .prompt {
    width: 300px;
      @media (max-width: 700px) {
        max-width: 220px;
    }
  }

  .ui.search.category > .results {
    width: 50rem;
    transform: translateX(calc(-50% + 150px));
    @media (max-width: 700px) {
        width: 20rem !important;
        transform: translateX(calc(-50% + 90px));
    }
  }
  .ui.search.category > .results.globe {
    width: 50rem;
    left: 200px;
  }

  .ui.category.search .results {
    width: 50rem;
    .result {
      border-bottom: 1px solid rgba(34,36,38,.1) !important;
    }
  }

  .ui.search > .results {
    max-height: 18.5rem;
    overflow-x: hidden;
    overflow-y: auto;

    .result .title {
      display: inline-block;
      margin-right: 0.5rem;
    }

    .result .description {
      display: inline-block;
    }

    .result .price {
      color: ${colors.blue};
    }
  }

  .ui.disabled.segment {
    opacity: 0.2 !important;
  }

  .ui.selection.dropdown {
    min-height: 2.7rem;
    height: 2.7rem;
  }

  .ui.secondary.pointing.menu {
    background: white;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 5;
  }

  .ui.secondary.pointing.menu .item {
    border-bottom: 2px solid ${colors.lightGrey};
    height: 100%;

    &.active {
      background: white;
      border-bottom: 2px solid ${colors.red};

      &:hover {
        background: white;
        border-bottom: 2px solid ${colors.red};
      }
    }

    &:hover {
      background-color: white !important;
      border-bottom: 2px solid ${colors.red};
    }
  }

  input {
    outline: none;
  }

  input[type=range] {
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      height: 3px;
      background: #e6e6e6;
      border: none;
      border-radius: 2px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 13px;
      width: 13px;
      border-radius: 50%;
      background: ${colors.lightBlue};
      border: 1px solid ${colors.blue};
      margin-top: -5px;
    }

    &:focus {
      outline: none;

      &::-webkit-slider-runnable-track {
        background: #ccc;
      }

      &::-webkit-slider-thumb {
        background: ${colors.blue};
      }
    }

    &::-moz-range-track {
      height: 5px;
      background: #ddd;
      border: none;
      border-radius: 3px;
    }

    &::-moz-range-thumb {
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: goldenrod;
    }

    &:-moz-focusring {
      outline: 1px solid white;
      outline-offset: -1px;
    }
  }

  .youtube.play.icon {
    margin-left: 5px;
    color: rgb(255,2,2);
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
