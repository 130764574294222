import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { colors } from '../utils/colors';

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleTabClick = (index) => {
    this.setState({ selectedTab: index });
  };

  render() {
    const { selectedTab } = this.state;
    const { tabs, activeColor, hoverColor } = this.props;
    // `tabs` should be an array of { title, content }

    return (
      <TabsContainer>
        <TabList>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              isActive={selectedTab === index}
              onClick={() => this.handleTabClick(index)}
              hover={hoverColor}
              active={activeColor}
            >
              <Header className="text" size="medium">
                {tab.title}
              </Header>
            </Tab>
          ))}
        </TabList>
        <TabContent>
          {tabs[selectedTab] && tabs[selectedTab].content}
        </TabContent>
      </TabsContainer>
    );
  }
}

export default Tabs;

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeColor: PropTypes.string,
  hoverColor: PropTypes.string,
};

Tabs.defaultProps = {
  activeColor: colors.darkBlue,
  hoverColor: colors.mediumBlue,
};

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabList = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid ${colors.darkGrey};
  height: 100%;
`;

const Tab = styled.div`
  padding: 10px 35px;
  height: 40px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;

  ${(props) =>
    `
    .text {
      color: ${colors.darkGrey};
      &:hover {
        color: ${props.hover};
      }
      
      ${
        props.isActive &&
        `
      font-weight: bold;
      color: ${props.active};
          
      &:hover {
      color: ${props.active};
      }`
      }
    }
    `}

  ${(props) => props.isActive && `border-bottom: 3px solid ${props.active}`}
`;

const TabContent = styled.div`
  padding: 20px;
`;
