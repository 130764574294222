import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { CustomAccount } from 'actions';

class CashAmount extends Component {
  state = {
    focused: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = (e) => {
    const { dispatch, value } = this.props;

    // Firefox issue
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return false;
    }

    if (value === '') {
      dispatch(CustomAccount.updateCash(0));
    }
    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;
    e.target.value = '';
    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  updateCashAmount = (e, { value }) => {
    const { dispatch } = this.props;
    this.setState({ focused: true });

    return dispatch(CustomAccount.updateCash(value));
  };

  focus = () => {
    const { dispatch, value } = this.props;

    if (!value) {
      dispatch(CustomAccount.updateCash(0));
    }

    return this.setState({ focused: true });
  };

  render() {
    const { value } = this.props;
    const { focused } = this.state;
    return focused ? (
      <Input
        // name={ticker}
        size="mini"
        type="number"
        value={value}
        onChange={this.updateCashAmount}
        style={{ maxWidth: 60 }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    ) : (
      <Value onClick={this.focus}>{`$${value}`}</Value>
    );
  }
}

CashAmount.propTypes = {
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect()(CashAmount);

const Value = styled.span`
  position: relative;
  cursor: pointer;
  color: #2185d0;
  padding: 11px 5px;
  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;
