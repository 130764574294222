import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, Icon, Header, Flag } from 'semantic-ui-react';

import { Segment, Table } from 'components';

class GicTable extends Component {
  getMarketPercentage = (value) => {
    const { totalMarketValue } = this.props;

    return `${Number(
      (100 * (value / totalMarketValue)).toFixed(2).toLocaleString()
    )}%`;
  };

  round = (num) =>
    (!num ? 0 : Math.round(num * 10000) / 10000).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  render() {
    const { gicHoldings, title, rate, user_region, linked } = this.props;

    return gicHoldings && gicHoldings.length ? (
      <Segment
        basic
        compact
        textAlign="center"
        style={{ marginTop: '0px', paddingTop: '0px' }}
      >
        <Header size="medium" content={title} />
        <div style={{ position: 'relative' }}>
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Ticker/Name</Table.HeaderCell>
                <Table.HeaderCell>Asset Class</Table.HeaderCell>
                <Table.HeaderCell>
                  Value&nbsp;
                  {this.props.individual && !linked && (
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      wide
                      content="Update this value when you are paid interest. Use the “Edit” button in the bottom left corner."
                    />
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>Weight</Table.HeaderCell>
                <Table.HeaderCell>Unit Price</Table.HeaderCell>
                <Table.HeaderCell>Interest Rate</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {gicHoldings.map((gic) => {
                const gicValue =
                  gic.currency === 'USD' && user_region !== 'US'
                    ? (gic.quantity * this.round(rate || 1.3)).toFixed(2)
                    : gic.market_value;

                return (
                  <Table.Row key={gic.id}>
                    <Table.Cell style={{ position: 'relative' }}>
                      {gic.gic_ticker}
                      <SubHeading>{gic.gic_name}</SubHeading>
                    </Table.Cell>
                    <Table.Cell>Fixed Income</Table.Cell>
                    <Table.Cell>
                      ${Number(gicValue).toLocaleString()}
                      <Flag name={gic.currency === 'USD' ? 'us' : 'ca'} />
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMarketPercentage(gicValue)}
                    </Table.Cell>
                    <Table.Cell>
                      $1 <Flag name={gic.currency === 'USD' ? 'us' : 'ca'} />
                    </Table.Cell>
                    <Table.Cell>
                      {Number((gic.gic_rate * 100).toFixed(2))}%
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    ) : null;
  }
}

GicTable.propTypes = {
  title: PropTypes.string,
  individual: PropTypes.bool,
  gicHoldings: PropTypes.array,
  totalMarketValue: PropTypes.func.isRequired,
  rate: PropTypes.number,
  user_region: PropTypes.string,
  linked: PropTypes.bool,
};

GicTable.defaultProps = {
  title: '',
  individual: false,
  gicHoldings: [],
  rate: 0,
  user_region: 'CA',
  linked: false,
};

export default GicTable;

const SubHeading = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.5);
`;
