import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Header, Icon, Popup } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import styled from 'styled-components';

import { Analytics } from 'actions';
import { DateLabel, PortfolioHistoryChart } from 'components';

class PortfolioHistory extends Component {
  state = {
    compare: this.props.compare,
    displayPortfolio: true,
    displayCompare: true,
  };

  shouldDrawBar = () => {
    const { data } = this.props;

    return (
      data &&
      data.crisisRets &&
      Object.values(data.crisisRets).reduce((result, event) => {
        result = result || Object.keys(event.rets).length;

        return result;
      }, false)
    );
  };

  parseDate = (portfolio, short) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return short
      ? `${init[1]}/${init[0]} - ${end[1]}/${end[0]}`
      : `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  handleChange = (e, { value }) => {
    this.setState({ compare: value });

    const { dispatch } = this.props;

    return dispatch(Analytics.updatePorfolioHistoryCompareto(value));
  };

  capitalize = (str) => str.substring(0, 1).toUpperCase() + str.slice(1);

  render() {
    const { data, benchmark } = this.props;
    const { compare, displayPortfolio, displayCompare } = this.state;

    return (
      <div style={{ clear: 'both' }}>
        <Header>
          <Header.Content>Hypothetical Portfolio History</Header.Content>
          <Header.Subheader>
            Based on the current composition of your portfolio&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              content="Using the same holdings and the same weights."
              wide
            />
          </Header.Subheader>
          <Header.Subheader>
            <DateLabel str={`${this.parseDate(data.portfolio)} (inclusive)`} />
          </Header.Subheader>
        </Header>
        <Dropdown
          button
          basic
          floating
          className="icon"
          labeled
          icon="law"
          text={`Compare to: ${
            this.capitalize(compare) === 'Market'
              ? 'Stock Market'
              : this.capitalize(compare)
          }`}
          options={[
            { key: 'mkt', value: 'market', text: 'Stock Market' },
            { key: 'bmk', value: 'benchmark', text: 'Benchmark' },
          ]}
          onChange={this.handleChange}
        />

        <div style={{ textAlign: 'center', margin: '1rem 0 0 0' }}>
          <Note>Total Returns including Distributions</Note>
          <Legend
            color={colors.blue}
            onClick={() =>
              this.setState({ displayPortfolio: !displayPortfolio })
            }
            lineThrough={!displayPortfolio}
          >
            Portfolio
          </Legend>
          <Legend
            color={colors.red}
            onClick={() => this.setState({ displayCompare: !displayCompare })}
            lineThrough={!displayCompare}
          >
            {this.capitalize(compare) === 'Market'
              ? 'Stock Market'
              : this.capitalize(compare)}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ fontSize: '1rem', opacity: '0.5' }}
                />
              }
              position="bottom center"
              wide
              content={
                compare === 'market'
                  ? data.cumrets.market[`${benchmark}Desc`]
                  : data.cumrets.benchmark[`${benchmark}Desc`]
              }
            />
          </Legend>
          {!!this.shouldDrawBar() && (
            <div style={{ fontSize: '1.1rem', color: 'rgba(0,0,0,.6)' }}>
              Hover over the grey bands to see historical performances during
              periods of market turmoil.
            </div>
          )}
        </div>
        <PortfolioHistoryChart
          portfolioData={data}
          compare={compare}
          benchmark={benchmark}
          displayDataSet={{ displayPortfolio, displayCompare }}
        />
        <div
          style={{
            fontSize: '1.1rem',
            color: 'rgba(0,0,0,.6)',
            textAlign: 'center',
          }}
        >
          These dates represent end-of-month values.
        </div>
      </div>
    );
  }
}

PortfolioHistory.propTypes = {
  data: PropTypes.object.isRequired,
  benchmark: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  compare: PropTypes.string.isRequired,
};

export default PortfolioHistory;

const Legend = styled.span`
  position: relative;
  color: black;
  font-size: 0.9rem;
  cursor: default;
  width: 100px;
  margin: 0 0 0 4rem;
  vertical-align: center;
  cursor: pointer;
  text-decoration: ${(props) => (props.lineThrough ? 'line-through' : 'none')};

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -3.2rem;
    height: 0.8rem;
    width: 2.8rem;
    background: ${(props) => props.color || 'grey'};
    vertical-align: middle;
  }
`;

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.1rem auto;
`;
