import { Analytics } from 'actions';

const initialState = {
  isFetching: false,
  data: null,
  compare: {},
  compareEsg: {},
  objective: null,
  benchmark: 'domestic',
  portfolioHistoryCompareto: 'benchmark',
  income: true,
  selectedAssets: {},
  selectedClass: {
    bonds: false,
    equity: false,
    alt: false,
  },
  printParams: {
    printModal: false,
    printLetterGrade: null,
    Overview: true,
    'Historical Performance Graph': true,
    Performance: true,
    Downside: true,
    Fees: true,
    Income: true,
    Diversification: true,
    Correlation: true,
    'Asset Class': true,
    Individual: true,
    Region: true,
    'Global Stock Sector': true,
    'Macro Factor': true,
    'Equity Style': true,
    ESG: false,
  },
  esg: {},
  esgToggle: false,
  esgAlert: false,
  printLetterGrade: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Analytics.ANALYTICS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Analytics.ANALYTICS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        compare: {},
        objective: action.objective,
        data: { ...action.data, portfolio: action.portfolio },
      };
    case Analytics.ANALYTICS_COMPARE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        compare: {
          ...(action.analysis1 &&
            Object.keys(action.analysis1).length && {
              analysis1: action.analysis1,
            }),
          ...(action.analysis2 &&
            Object.keys(action.analysis2).length && {
              analysis2: action.analysis2,
            }),
        },
      };
    case Analytics.ANALYTICS_COMPARE_ESG_SUCCESS:
      return {
        ...state,
        compareEsg: {
          portfolio1esg: action.portfolio1esg,
          portfolio2esg: action.portfolio2esg,
        },
      };
    case Analytics.ANALYTICS_UPDATE_BENCHMARK:
      return {
        ...state,
        benchmark: action.benchmark,
      };
    case Analytics.ANALYTICS_UPDATE_INCOME:
      return {
        ...state,
        income: action.income,
      };
    case Analytics.ANALYTICS_UPDATE_PORTFOLIO:
      return {
        ...state,
        data: {
          ...state.data,
          portfolio: !state.data
            ? { ...action.portfolio }
            : { ...state.data.portfolio, ...action.portfolio },
        },
      };
    case Analytics.ANALYTICS_UPDATE_PARAMETER:
      return {
        ...state,
        [action.field]: action.value,
      };
    case Analytics.ANALYTICS_UPDATE_PRINTPARAMS:
      return {
        ...state,
        printParams: {
          ...state.printParams,
          ...action.value,
        },
      };
    case Analytics.ANALYTICS_FETCH_ESG:
      return { ...state, isFetching: false, esg: action.esg };
    case Analytics.ANALYTICS_ESG_ALERT:
      return { ...state, esgAlert: action.esgAlert };
    case Analytics.ANALYTICS_CLEAR:
      return initialState;
    case Analytics.ANALYTICS_FAIL:
      return initialState;
    case Analytics.ANALYTICS_PORTFOLIO_HISTORY_COMPARETO:
      return {
        ...state,
        portfolioHistoryCompareto: action.portfolioHistoryCompareto,
      };
    default:
      return state;
  }
}
