import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Accounts as AccountsActions, CacheInputs } from 'actions';
import { Loading, NotFound } from 'components';

import Overview from './Overview';
import AccountsList from './AccountsList';
import IndividualAccount from './IndividualAccount';
import CompareAccount from './CompareAccount';
import Result from './CompareAccount/Result';
import WhatIfPDF from './CompareAccount/PDF';
import RebalanceAccount from './RebalanceAccount';
import Custom from './Custom';
import Cash from './Cash';
import OverviewCash from './OverviewCash';
import MultipleAccountCash from './MultipleAccountCash';
import MultipleAccount from './MultipleAccount';
import StockExposure from '../../Accounts/StockExposure';

class Accounts extends Component {
  componentWillMount = () => {
    const { dispatch, accountList } = this.props;

    if (!accountList) {
      return dispatch(AccountsActions.fetchAll());
    }

    return false;
  };

  componentWillUnmount = () => {
    const { dispatch, location } = this.props;

    return dispatch(CacheInputs.captureUrl('accounts', location.pathname));
  };

  render() {
    const { accountList } = this.props;

    return !accountList ? (
      <Loading active />
    ) : (
      <div style={{ height: 'calc(100% - 2rem)' }}>
        <Switch>
          <Route exact path="/accounts" component={AccountsList} />
          <Route exact path="/accounts/overview" component={Overview} />
          <Route exact path="/accounts/user" component={AccountsList} />
          <Route
            exact
            path="/accounts/stock-exposure"
            component={StockExposure}
          />
          <Route
            exact
            path="/accounts/Custom/:id"
            render={() => <Custom accountList={accountList} />}
          />
          <Route
            exact
            path="/accounts/multiple"
            render={(props) => <MultipleAccount {...props} />}
          />
          <Route
            exact
            path="/accounts/multiple/cash"
            component={(props) => <MultipleAccountCash {...props} />}
          />
          <Route
            exact
            path="/accounts/overview/cash"
            component={(props) => <OverviewCash {...props} />}
            render={(props) => {
              const account = accountList.filter(
                (item) => item.id === Number(props.match.params.id)
              )[0];
              return account ? <OverviewCash {...props} /> : <NotFound />;
            }}
          />
          <Route
            exact
            path="/accounts/:id"
            render={(props) => {
              const account = accountList.filter(
                (item) => item.id === Number(props.match.params.id)
              )[0];

              return account ? (
                <IndividualAccount account={account} {...props} />
              ) : (
                <NotFound />
              );
            }}
          />
          <Route
            exact
            path="/accounts/:id/compare"
            component={(props) => {
              if (props.match.params.id === 'multiple') {
                return <CompareAccount type="multiple" {...props} />;
              } else if (props.match.params.id === 'overview') {
                return <CompareAccount type="overview" {...props} />;
              }

              const account = accountList.filter(
                (item) => item.id === Number(props.match.params.id)
              )[0];

              return account ? (
                <CompareAccount account={account} {...props} />
              ) : (
                <NotFound />
              );
            }}
          />
          <Route
            exact
            path="/accounts/:id/compare/result"
            component={(props) => <Result {...props} />}
          />
          <Route exact path="/accounts/:id/compare/pdf" component={WhatIfPDF} />
          <Route
            exact
            path="/accounts/:id/cash"
            component={(props) => {
              const account = accountList.filter(
                (item) => item.id === Number(props.match.params.id)
              )[0];

              return account ? (
                <Cash account={account} {...props} />
              ) : (
                <NotFound />
              );
            }}
          />
          <Route
            exact
            path="/accounts/:id/rebalance"
            component={(props) => {
              const account = accountList.filter(
                (item) => item.id === Number(props.match.params.id)
              )[0];

              return account ? (
                <RebalanceAccount account={account} {...props} />
              ) : (
                <NotFound />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

Accounts.propTypes = {
  accountList: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

Accounts.defaultProps = {
  accountList: null,
};

export default connect((state) => ({
  accountList: state.Accounts.accountList,
}))(Accounts);
