import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Divider, Popup, Icon, Button } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import Graph from '../../ChartingTool/Graph';

// eslint-disable-next-line react/prefer-stateless-function
class KeyInfo extends Component {
  render() {
    const {
      allNamesList,
      portfolioNames,
      updateStartEndDate,
      isOpen,
      toggleOpen,
    } = this.props;

    return (
      <div>
        <Header textAlign="left">
          <div style={{ display: 'flex' }}>
            <StyledButton onClick={toggleOpen} isOpen={isOpen}>
              {isOpen ? '-' : '+'}
            </StyledButton>
            Growth Chart&nbsp;
            <Popup
              trigger={
                <Icon
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                  name="question circle outline"
                />
              }
              position="right center"
              wide
              content="Chart up to the longest common history of the selected funds."
            />
          </div>
        </Header>
        <Divider />
        <div style={{ display: isOpen ? 'block' : 'none' }}>
          <Graph
            allNamesList={allNamesList}
            portfolioNames={portfolioNames}
            updateStartEndDate={updateStartEndDate}
          />
        </div>
      </div>
    );
  }
}

KeyInfo.propTypes = {
  updateStartEndDate: PropTypes.func.isRequired,
  allNamesList: PropTypes.array.isRequired,
  portfolioNames: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

KeyInfo.defaultProps = {};

export default KeyInfo;

const StyledButton = styled(Button)`
  &&& {
    font-size: 1.3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.isOpen ? colors.blue : 'white'} !important;
    color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
    border: ${(props) =>
      props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
    border-radius: 50% !important;
    height: 2rem !important;
    width: 2rem !important;
    padding: 0 !important;
    transition: all 0.9s ease-in-out !important;
    margin: 0 0.75rem 0 0;
  }
`;
