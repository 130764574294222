import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { Accounts, Portfolio, Storage } from 'actions';
import { Header, Segment, Button } from 'components';
import { getHoldTotalMarketValue, hasNegativeWeight } from 'utils/helpers';
import { colors } from 'utils/colors';

class MultipleAccountCash extends Component {
  /*
    Creates a "Cash holding" in order to account for the amount of cash in the analysis
    The security_detail parameter is required for the "convertToTickersAndWeights" function in the utils/helpers.js file
  */
  getCash = (account) => {
    const cash = {
      account: 'Overview',
      account_id: 'Overview',
      currency: 'CAD',
      market_value: account.cash,
      name: 'Cash',
      security: 'CASH:MKT',
      ticker: 'CASH:MKT',
      security_detail: {
        exchange_symbol: 'CASH:MKT',
        ticker: 'CASH:MKT',
        long_name: 'Cash',
        unadjusted_closing_price: {
          CAD: 1,
          USD: 1,
        },
      },
    };
    const holdingsCash = account.holdings.concat(cash);
    return holdingsCash;
  };

  analyze = (holdings, region) => {
    const { dispatch, history, selectedAccountList } = this.props;
    const gicHoldings = holdings.filter((holding) => holding.gic_ticker);
    Storage.removeItem('pwpa-has-exclude-holding');
    const concatAccountNames = selectedAccountList
      .map((acc) => acc.user_institution)
      .join(' - ');

    return dispatch(
      Accounts.analyze(
        holdings,
        history,
        { user_institution: concatAccountNames },
        gicHoldings,
        region
      )
    );
  };

  render() {
    const { dispatch, selectedAccountOverview, user_region } = this.props;
    const hasNegativeWeights = hasNegativeWeight(
      selectedAccountOverview.holdings
    );

    return (
      <div style={{ height: '100%' }}>
        <Segment expand>
          <div style={{ position: 'relative', padding: '2rem' }}>
            <Header size="1.5rem" color="black">
              Include cash in your account for portfolio analysis
            </Header>
            <P>
              The main “Analyze” button assumes that the cash in your account
              balance (0% interest) is temporary, and excludes it from the
              analysis. Here you can include cash in the analysis and see the
              impact of the so-called “cash drag” on your account’s scores.
            </P>
            <P>
              What is a cash drag? Since cash has no market exposure, it will
              likely drag down returns over time relative to the benchmark,
              which has no cash. (Your account’s benchmark portfolio is
              automatically picked for you based on your asset allocation).
            </P>
            {hasNegativeWeights ? (
              getHoldTotalMarketValue(selectedAccountOverview) < 0 ? (
                <p
                  style={{
                    color: `${colors.red}`,
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '2rem',
                    right: '15rem',
                  }}
                >
                  <Icon name="warning" style={{}} />
                  Analysis will not be performed for net short portfolio(s).
                </p>
              ) : (
                <p
                  style={{
                    color: `${colors.red}`,
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '2rem',
                    right: '15rem',
                  }}
                >
                  <Icon name="warning" style={{}} />
                  Note that short positions will be excluded from the analysis.
                </p>
              )
            ) : null}
            <Button
              className={cn('left', {
                disabled: getHoldTotalMarketValue(selectedAccountOverview) < 0,
              })}
              icon="line chart"
              color={colors.darkBlue}
              style={{ top: '20px' }}
              onClick={() => {
                dispatch(Storage.setItem('toggle_region', user_region));
                this.analyze(
                  this.getCash(selectedAccountOverview),
                  user_region
                );
                dispatch(Portfolio.notSaveUrlSc());
              }}
            >
              Analyze
            </Button>
          </div>
        </Segment>
      </div>
    );
  }
}

MultipleAccountCash.propTypes = {
  selectedAccountOverview: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  selectedAccountList: PropTypes.array,
  user_region: PropTypes.string,
};

MultipleAccountCash.defaultProps = {
  dispatch: () => {},
  history: {},
  selectedAccountOverview: {},
  selectedAccountList: [],
  user_region: 'CA',
};

export default connect((state) => ({
  selectedAccountOverview: state.Accounts.selectedAccountOverview,
  selectedAccountList: state.Accounts.selectedAccountList,
  user_region: state.Storage.user_region,
}))(MultipleAccountCash);

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;

  &.strike {
    text-decoration: line-through;
  }
`;
