import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Table, Modal, Button } from 'components';
import { RebalanceAccount } from 'actions';
import { translateTableHeadings } from '../../../utils/helpers';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))
export default class SaveModal extends Component {
  save = () => {
    const { closeModal, dispatch, holdings, accountId } = this.props;
    const body = {
      account_id: accountId,
      ...holdings.reduce(
        (acc, val) => {
          acc.rebal_targets[val.security_detail.ticker] = val.weight * 0.01;
          acc.rebal_tolerances[val.security_detail.ticker] =
            (val.deviation || 0) * 0.01;
          return acc;
        },
        {
          rebal_targets: {},
          rebal_tolerances: {},
        }
      ),
    };

    return dispatch(RebalanceAccount.saveSystem(body)).then(() => {
      closeModal();
    });
  };

  render() {
    const { open, closeModal, holdings, french } = this.props;

    return (
      <Modal rebalance open={open} dimmerClick={() => closeModal()}>
        <Modal.Content style={{ textAlign: 'center', color: 'black' }}>
          <Header
            textAlign="center"
            content={french ? 'Système de rééquilibrage' : 'Rebalancing System'}
            color="green"
          />
          <div>
            {french
              ? 'Êtes-vous certain de vouloir enregistrer un système de rééquilibrage avec ces paramètres ?'
              : 'Are you sure you want to save a rebalancing system with these parameters?'}
          </div>
          <Table textAlign="center" style={{ marginBottom: '2rem' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                  {translateTableHeadings('Asset', french)}
                </Table.HeaderCell>
                <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                  {translateTableHeadings('Weight', french)}
                </Table.HeaderCell>
                <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                  {translateTableHeadings('Deviation', french)}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.values(holdings).map((holding) => (
                <StyledRow
                  key={holding.security_detail.ticker}
                  style={{ height: 52 }}
                >
                  <Table.Cell style={{ position: 'relative' }} width={5}>
                    {(holding.gic_ticker
                      ? holding.gic_ticker
                      : holding.security_detail.ticker) || 'N/A'}
                    <SecurityName>
                      {(holding.gic_ticker
                        ? holding.gic_name
                        : holding.security_detail.long_name) || 'N/A'}
                    </SecurityName>
                  </Table.Cell>
                  <Table.Cell width={2}>{`${
                    holding.weight || 0
                  } %`}</Table.Cell>
                  <Table.Cell width={2}>{`${
                    holding.deviation || 0
                  } %`}</Table.Cell>
                </StyledRow>
              ))}
            </Table.Body>
          </Table>
          <Button
            className={cn('left')}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={() => closeModal()}
          >
            {french ? 'Annuler' : 'Cancel'}
          </Button>
          <Button
            className={cn('left')}
            color={colors.green}
            icon="checkmark"
            onClick={this.save}
          >
            {french ? 'Sauvegarder' : 'Save'}
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
}

SaveModal.propTypes = {
  dispatch: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  holdings: PropTypes.array.isRequired,
  accountId: PropTypes.string.isRequired,
  french: PropTypes.bool,
};

SaveModal.defaultProps = {
  dispatch: () => false,
  french: false,
};

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative;

  .close {
    display: block;
    margin: -10px;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;
