import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { Accounts } from 'actions';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Button, Modal } from 'components';

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))
export default class EditModal extends Component {
  state = {
    label: this.props.account.label || '',
    user_institution: this.props.account.user_institution || '',
  };

  componentWillReceiveProps = (nextProps) =>
    this.setState({
      label: nextProps.account.label,
      user_institution: nextProps.account.user_institution,
    });

  handleChange = (e) => {
    const { name, value } = e.target;

    if (
      (name === 'user_institution' && value.length < 40) ||
      (name === 'label' && value.length < 60)
    ) {
      return this.setState({ [name]: value });
    }

    return false;
  };

  updateAccount = () => {
    const { account, dispatch } = this.props;
    const { label, user_institution } = this.state;
    const body = { label, user_institution };

    this.setState({ loading: true });

    dispatch(Accounts.saveSingleAccInfo(user_institution, label));

    return dispatch(Accounts.edit(account.id, body)).then(() =>
      this.closeModal()
    );
  };

  closeModal = () => {
    const { close, account } = this.props;

    this.setState({
      loading: false,
      label: account.label,
      user_institution: account.user_institution,
    });

    return close();
  };

  render() {
    const { open, partnerName, french } = this.props;
    const { loading, label, user_institution } = this.state;

    return (
      <Modal
        basicTop
        open={open}
        dimmerClick={this.closeModal}
        vb={notWealthica(partnerName)}
      >
        <Modal.Header>
          <Header
            icon="edit"
            content={french ? 'Modifier le nom' : 'Edit Name'}
            style={{ color: colors.blue }}
          />
        </Modal.Header>
        <Modal.Content>
          <Field vb={notWealthica(partnerName)}>
            {french ? 'Institution :' : 'Institution:'}
            <input
              name="user_institution"
              value={user_institution}
              onChange={this.handleChange}
            />
          </Field>
          <Field vb={notWealthica(partnerName)}>
            {french ? 'Nom :' : 'Name:'}
            <input name="label" value={label} onChange={this.handleChange} />
          </Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: loading }
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.closeModal}
          >
            {french ? 'Annuler' : 'Cancel'}
          </Button>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: !label.trim() || !user_institution.trim(), loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={this.updateAccount}
          >
            {french ? 'Sauvegarder' : 'Save'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

EditModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  account: PropTypes.object,
  dispatch: PropTypes.func,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

EditModal.defaultProps = {
  account: {},
  dispatch: () => false,
  partnerName: '',
  french: false,
};

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;
