import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';

import { ExposureChart, ExposureTable } from '../components';

export default class IndividualAsset extends Component {
  getChartData = () => {
    const { data } = this.props;
    const { tickersValid, riskCont } = data;
    const sorted = Object.keys(tickersValid).sort(
      (a, b) => riskCont.asset[b] - riskCont.asset[a]
    );
    const labels = sorted.map((key) => this.getName(key));
    const risk = sorted.map((key) => riskCont.asset[key]);
    const otherRisk = sorted.slice(10).reduce((total, key) => {
      if (key) {
        total += riskCont.asset[key];
      }

      return total;
    }, 0);
    const weight = sorted.map((key) => tickersValid[key].weight);
    const otherWeight = sorted.slice(10).reduce((total, key) => {
      if (key) {
        total += tickersValid[key].weight;
      }

      return total;
    }, 0);

    return {
      labels: otherRisk
        ? [...labels.slice(0, 10), 'All Other Holdings']
        : labels,
      risk: otherRisk ? [...risk.slice(0, 10), otherRisk] : risk,
      weight: otherRisk ? [...weight.slice(0, 10), otherWeight] : weight,
    };
  };

  getName = (ticker) => {
    const { data } = this.props;
    const { tickersOriginal, tickersValid } = data;
    const matchedTicker = tickersOriginal.find(
      (val) => val.replacedBy.proxyTicker === ticker
    );

    if (matchedTicker) {
      return this.isLinked(data) ? matchedTicker.name : matchedTicker.wsName;
    }

    return this.isLinked(data)
      ? tickersValid[ticker].name
      : tickersValid[ticker].wsName;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  render() {
    const { data } = this.props;

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content="Risk Contribution Analysis"
          subheader="By Individual Asset"
        />

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content="Asset Allocation"
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                position="bottom"
                graphHeight={
                  Object.keys(data.tickersValid).length > 10 ? 450 : 0
                }
                labels={this.getChartData().labels}
                data={this.getChartData().weight}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                size="medium"
                content="Risk Contribution"
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                position="bottom"
                graphHeight={
                  Object.keys(data.tickersValid).length > 10 ? 450 : 0
                }
                labels={this.getChartData().labels}
                data={this.getChartData().risk}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ExposureTable
          align="left"
          headers={[
            'Asset',
            'Asset Allocation',
            <div>
              Risk Contribution
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
                content="A negative risk contribution happens when a low volatility asset has a negative correlation with the other asset(s). (In statistical terms, when an asset has a small variance relative to its covariance with the other asset(s).) It can also happen if you have a short position in the portfolio."
              />
            </div>,
            'Return Contribution',
          ]}
          totals={['Total', <div>100%</div>, <div>100%</div>, <div>100%</div>]}
          data={Object.keys(data.tickersValid).reduce((total, key) => {
            total.push({
              ticker: this.getName(key),
              assetAllocation: data.tickersValid[key].weight,
              riskCont: data.riskCont.asset[key],
              retCont: data.retCont.asset[key],
            });

            return total;
          }, [])}
        />
      </Container>
    );
  }
}

IndividualAsset.propTypes = {
  data: PropTypes.object.isRequired,
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
