import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Icon, Form, Input, Header, Dropdown } from 'semantic-ui-react';
import cn from 'classnames';

import { CacheInputs, Planning, Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { Message, ErrorLabel, Button, Segment } from 'components';
import Result from './Result';

@connect((state) => ({
  isFetching: state.Planning.isFetching,
  longevity: state.Planning.longevity,
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  portLongevityInputs: state.CacheInputs.planning.portfolio_longevity,
}))
export default class RuinProbability extends Component {
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    return false;
  };

  getPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    const date = !port ? [0] : port.init_date.split('-');

    if (Number(date[0]) < 2008) {
      return false;
    }

    return `${date[1]}/${date[0]}`;
  };

  getNewPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    if (!port || !port.init_date) {
      return false;
    }
    const date = new Date(port.init_date);
    const today = new Date();
    const monthDifference =
      today.getMonth() -
      date.getMonth() +
      12 * (today.getFullYear() - date.getFullYear());
    if (monthDifference > 60) {
      return false;
    }

    const init = date.toDateString().toString().split(/\s+/);

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sept: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    return `${months[init[1]]} ${init[3]}`;
  };

  handleKeyPress = (e) => e.key === '.' && e.preventDefault();

  handlePortfolioChange = (e, { value }) =>
    this.props.dispatch(
      CacheInputs.updateField('planning', 'portfolio_longevity', {
        portfolio: value,
        errors: { portfolio: null },
      })
    );

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    if (name === 'gender') {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          gender: value,
          errors: { gender: null },
        })
      );
    }

    if (
      (name === 'retAge' || name === 'annuityRate' || name === 'lifeExp') &&
      Number(value) > 100
    ) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: 100,
          errors: { [name]: null },
        })
      );
    }

    return (
      value.length < 10 &&
      dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: value,
          errors: { [name]: null },
        })
      )
    );
  };

  scrollHandler = (element, to, duration) => {
    if (duration <= 0) return false;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      element.scrollTop += perTick;
      if (element.scrollTop === to) return false;
      return this.scrollHandler(element, to, duration - 10);
    }, 10);
  };

  hasEmptyValue = (values) =>
    values.some((field) => {
      const { dispatch, portLongevityInputs } = this.props;

      if (!portLongevityInputs[field]) {
        return dispatch(
          CacheInputs.updateField('planning', 'portfolio_longevity', {
            errors: { [field]: 'This field is required' },
          })
        );
      }

      return false;
    });

  handleSubmit = () => {
    const { dispatch, portfolios, portLongevityInputs } = this.props;
    const {
      useDefault,
      lifeExp,
      portfolio,
      annuityRate,
      retAge,
      init,
      gender,
      cf,
      riskFree,
    } = portLongevityInputs;
    const params = {
      retAge,
      init,
      gender,
      cf,
      init_date: portfolio
        ? portfolios.find((p) => p.id === portfolio).init_date
        : null,
      region: 'CA',
      targetProb: 0.05,
    };

    if (this.hasEmptyValue(Object.keys({ retAge, init, gender, cf }))) {
      return false;
    }

    if (!useDefault && !lifeExp) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { lifeExp: 'This field is required' },
        })
      );
    }

    if (riskFree && !annuityRate) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { annuityRate: 'This field is required' },
        })
      );
    }

    if (!riskFree && !portfolio) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { portfolio: 'This field is required' },
        })
      );
    }

    if (annuityRate) {
      params.annuityRate = annuityRate / 100;
    }

    if (lifeExp) {
      params.lifeExp = lifeExp;
    }

    if (portfolio) {
      const idx = portfolios.reduce((result, item, index) => {
        if (item.id === portfolio && !result) {
          result = index;
        }

        return result;
      }, false);

      params.symbols = portfolios[idx].symbols;
      params.weights = portfolios[idx].weights;
      params.gic_info = portfolios[idx].gic_info;
    }

    return dispatch(Planning.analyzeLongevity(params)).then((action) => {
      const element = document.documentElement;
      const target = document.getElementById('longevity-ref').offsetHeight;

      return (
        action && !!action.result && this.scrollHandler(element, target, 300)
      );
    });
  };

  render() {
    const { isFetching, longevity, portfolios, portLongevityInputs } =
      this.props;
    const {
      retAge,
      init,
      cf,
      gender,
      portfolio,
      riskFree,
      annuityRate,
      errors,
    } = portLongevityInputs;
    const portDate = this.getNewPortDate(
      portfolios.find((port) => Number(port.id) === portfolio)
    );

    return (
      <Segment>
        <Header
          size="large"
          content="Probability of Ruin"
          textAlign="center"
          subheader={
            <div className="sub header">
              What are the chances that you will outlive your money? <br /> We
              show you below by comparing your human longevity and your
              portfolio longevity.
            </div>
          }
        />

        <Form
          onSubmit={this.handleSubmit}
          style={{ width: '37rem', margin: '2rem auto 0 auto' }}
        >
          <Form.Group widths="equal">
            <Form.Field style={{ position: 'relative' }}>
              <label>Age:</label>
              <Input
                name="retAge"
                type="number"
                fluid
                placeholder="years"
                value={retAge || ''}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              <ErrorLabel className="top" msg={errors.retAge} />
            </Form.Field>

            <Form.Field style={{ position: 'relative' }}>
              <label>
                Sex:&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content="We are using mortality rates from Statistics Canada in the calculations and the data is available for males and females only."
                />
              </label>
              <Dropdown
                name="gender"
                compact
                placeholder="Sex"
                selection
                options={[
                  { key: 'm', value: 'M', text: 'Male' },
                  { key: 'f', value: 'F', text: 'Female' },
                ]}
                value={gender}
                onChange={this.handleChange}
              />
              <ErrorLabel className="top" msg={errors.gender} />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field style={{ position: 'relative' }}>
              <label>
                Savings:<SubLabel>How much you currently have.</SubLabel>
              </label>
              <Input
                name="init"
                type="number"
                fluid
                value={init || ''}
                icon="dollar"
                iconPosition="left"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              <ErrorLabel className="top" msg={errors.init} />
            </Form.Field>

            <Form.Field style={{ position: 'relative' }}>
              <label>
                Withdrawal per year:
                <SubLabel>
                  How much you withdraw from your savings.
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ fontSize: '13px', verticalAlign: 'initial' }}
                      />
                    }
                    position="right center"
                    content="This amount is what you spend per year over and above any retirement income you may receive, i.e., spending that has to be financed by your savings. We assume that this amount grows with inflation at 2% over time."
                  />
                </SubLabel>
              </label>
              <Input
                name="cf"
                type="number"
                fluid
                value={cf || ''}
                icon="dollar"
                iconPosition="left"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              <ErrorLabel className="top" msg={errors.cf} />
            </Form.Field>
          </Form.Group>

          <Form.Field
            style={{
              position: 'relative',
              width: '100%',
              margin: '0 auto',
              maxWidth: '17rem',
            }}
          >
            <label>
              Choose the portfolio for your savings:
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{ fontSize: '13px', verticalAlign: 'initial' }}
                  />
                }
                position="right center"
                wide
                content="You can choose a WS model portfolio or any of your saved portfolios here. See the “Portfolios” segment."
              />
            </label>
            <Dropdown
              name="portfolio"
              compact
              placeholder="Port"
              selection
              options={portfolios.reduce((total, item) => {
                total.push({
                  key: item.id,
                  value: item.id,
                  text: item.name,
                });

                return total;
              }, [])}
              value={portfolio}
              onChange={this.handlePortfolioChange}
            />
            <ErrorLabel msg={errors.portfolio} />
          </Form.Field>

          <Message
            icon
            visible={!!portDate}
            warning
            style={{
              margin: '1rem auto 0 auto',
              maxWidth: '45rem',
              textAlign: 'left',
            }}
          >
            <Icon name="exclamation" />
            <Message.Content>
              This portfolio has a start date of <strong>{portDate}</strong>.
              You may wish to use a portfolio with a longer history. You can use
              the ETF Portfolio Builder in the "Portfolios" segment. Once saved,
              the portfolio will appear in the dropdown list here.
            </Message.Content>
          </Message>

          {riskFree && (
            <Form.Field
              style={{ position: 'relative', width: '10rem', margin: '0 auto' }}
            >
              <label>Enter the annual return:</label>
              <Input
                type="number"
                step="0.01"
                name="annuityRate"
                icon="percent"
                value={annuityRate || ''}
                onChange={this.handleChange}
              />
              <ErrorLabel msg={errors.annuityRate} />
            </Form.Field>
          )}

          <Button
            className={cn('centered right', { loading: isFetching })}
            color={colors.orange}
            icon="calculator"
          >
            Calculate
          </Button>
        </Form>

        <Result isFetching={isFetching} result={longevity} />
      </Segment>
    );
  }
}

RuinProbability.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  portfolios: PropTypes.array,
  longevity: PropTypes.object,
  portLongevityInputs: PropTypes.object,
};

RuinProbability.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  longevity: null,
  isFetching: false,
  portLongevityInputs: {},
};

const SubLabel = styled.div`
  color: rgba(0, 0, 0, 0.5);
  line-height: 0.8rem;
  font-style: italic;
  font-weight: 100;
`;
