import { CustomAccount } from 'actions';

const initialState = {
  isFetching: false,
  account: {},
  search: [],
  holdings: {},
  gic_ticker: '',
  gic_name: '',
  gic_quantity: 0,
  gic_rate: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CustomAccount.CUSTOMACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CustomAccount.CUSTOMACCOUNT_CREATE_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        account: action.account,
        cash: 0,
      };
    case CustomAccount.CUSTOMACCOUNT_FETCH_ID: {
      const parsedHoldings = action.account.holdings.reduce((acc, val) => {
        acc[val.security_detail.ticker] = { ...val, quantity: val.quantity };

        return acc;
      }, {});
      const gic = Object.values(parsedHoldings).find(
        (holding) =>
          holding.gic_quantity || holding.gic_rate || holding.gic_ticker
      );

      return {
        ...state,
        isFetching: false,
        account: action.account,
        holdings: parsedHoldings,
        cash: action.account.cash !== null ? action.account.cash : 0,
        ...(gic && {
          gic_quantity: gic.quantity,
          gic_rate: gic.gic_rate * 100,
          gic_ticker: gic.gic_ticker,
          gic_name: gic.gic_name,
        }),
      };
    }
    case CustomAccount.CUSTOMACCOUNT_ADD_SUCCESS:
      return !action.security
        ? { ...state, isFetching: false }
        : {
            ...state,
            isFetching: false,
            holdings: {
              ...state.holdings,
              [action.security.ticker]: {
                security_detail: {
                  ...action.security,
                },
                quantity: 0,
                book_value: 0,
              },
            },
          };
    case CustomAccount.CUSTOMACCOUNT_UPDATE:
      return {
        ...state,
        holdings: {
          ...state.holdings,
          [action.ticker]: {
            ...state.holdings[action.ticker],
            [action.field]: action.value,
          },
        },
      };
    case CustomAccount.CUSTOMACCOUNT_UPDATE_CASH:
      return {
        ...state,
        cash: action.value,
      };
    case CustomAccount.CUSTOMACCOUNT_UPDATE_GIC:
      return {
        ...state,
        [action.field]: action.value,
      };
    case CustomAccount.CUSTOMACCOUNT_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        search: action.data,
      };
    case CustomAccount.CUSTOMACCOUNT_REMOVE_SECURITY:
      return {
        ...state,
        isFetching: false,
        holdings: Object.keys(state.holdings).reduce((newHoldings, key) => {
          if (key !== action.ticker) {
            newHoldings[key] = state.holdings[key];
          }

          return newHoldings;
        }, {}),
      };
    case CustomAccount.CUSTOMACCOUNT_REMOVE_ALL:
      return {
        ...state,
        isFetching: false,
        holdings: {},
      };
    case CustomAccount.CUSTOMACCOUNT_HOLDING_REQUEST:
      return {
        ...state,
        isFetching: true,
        holdings: Object.keys(state.holdings).reduce((acc, key) => {
          if (state.holdings[key].quantity) {
            acc[key] = {
              ...state.holdings[key],
              status: 'pending',
            };
          } else {
            acc[key] = state.holdings[key];
          }

          return acc;
        }, []),
      };
    case CustomAccount.CUSTOMACCOUNT_HOLDING_UPDATE:
      return {
        ...state,
        isFetching: false,
        holdings: Object.keys(state.holdings).reduce((acc, key) => {
          if (state.holdings[key].quantity) {
            acc[key] = {
              ...state.holdings[key],
              status: 'success',
            };
          } else {
            acc[key] = state.holdings[key];
          }

          return acc;
        }, []),
      };
    case CustomAccount.CUSTOMACCOUNT_HOLDING_FAIL:
      return {
        ...state,
        isFetching: false,
        holdings: Object.keys(state.holdings).reduce((acc, key) => {
          if (state.holdings[key].quantity) {
            acc[key] = {
              ...state.holdings[key],
              status: 'fail',
            };
          } else {
            acc[key] = state.holdings[key];
          }

          return acc;
        }, []),
      };
    case CustomAccount.CUSTOMACCOUNT_CLEAR:
      return initialState;
    default:
      return state;
  }
}
