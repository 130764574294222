/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import cn from 'classnames';
import { isQuoteMedia, isGlobeInvestor } from 'utils/helpers';
import { CacheInputs, Portfolio, Storage, Accounts } from 'actions';
import { ACCOUNTS_SAVE_AS_PORTFOLIO, ACCOUNTS_COMPARE } from 'utils/features';
import { Feature } from 'components';
import { colors } from 'utils/colors';
import SaveModal from './SaveModal';
import './SideBar.css'; // Created css stylesheet as the :before element (arrow) for the new feature popup needed it to be targeted

class SideBar extends Component {
  state = {
    modalIsOpen: false,
    reminderIsOpen: false,
  };

  componentWillMount = () => {
    const { ui_compare_checked } = this.props;
    if (!ui_compare_checked) {
      this.setReminderVisibility(true);
    }
  };

  componentWillUnmount = () => {
    const { dispatch, location, not_save_url_scorecard, history } = this.props;
    const portfolioPaths = /^\/portfolio\/.*$/;
    dispatch(Storage.removeItem('to_scorecard'));
    if (
      not_save_url_scorecard &&
      !portfolioPaths.test(history.location.pathname)
    ) {
      dispatch(CacheInputs.captureUrl('portfolio', '/portfolio/landing'));

      return dispatch(Storage.removeItem('not_save_url_scorecard'));
    }

    return dispatch(CacheInputs.captureUrl('portfolio', location.pathname));
  };

  setReminderVisibility = (isOpen) => this.setState({ reminderIsOpen: isOpen });

  handleReminderClick = () => {
    const { dispatch } = this.props;
    dispatch(Storage.setItem('ui_compare_checked', true));
    dispatch(Accounts.checkUiCompare());
    this.setState({ reminderIsOpen: false });
  };

  handleCompareClick = () => {
    const { history, id, portfolio, dispatch } = this.props;
    const portfolioRegex = /^\/portfolio\/.*\/compare$/;
    if (portfolio.name) {
      const port_id = portfolio.is_mp ? '' : id;
      dispatch(
        Portfolio.setEditing(port_id, portfolio.name, portfolio.description)
      );
    }

    this.handleReminderClick();

    if (!portfolioRegex.test(history.location.pathname)) {
      return this.handleClick(`/portfolio/${id}/compare`);
    }
    return this.handleClick(`/portfolio/${id}/overview`);
  };

  isSaveVisible = (portfolio) => {
    const { match, partnerName } = this.props;

    if (!portfolio || isGlobeInvestor(partnerName)) {
      return false;
    }

    if (match.params.id !== 'unsaved') {
      if (!portfolio.is_mp) {
        return false;
      }
    }

    return true;
  };

  redirect = (portfolio) => {
    const { id, history, dispatch } = this.props;

    if (portfolio.name) {
      const port_id = portfolio.is_mp ? '' : id;
      dispatch(
        Portfolio.setEditing(port_id, portfolio.name, portfolio.description)
      );
    }

    if (portfolio.portfolio_type === 'quantified') {
      return history.push(`/portfolio/build-model/${id}`);
    }

    if (portfolio.portfolio_type === 'custom') {
      return history.push(`/portfolio/build-custom/${id}`);
    }

    return history.push(`/portfolio/build-etf/${id}/assets`);
  };

  closeModal = () => this.setState({ modalIsOpen: false });

  handleClick = (to, name) => {
    const { history } = this.props;

    if (to) {
      return history.push(to);
    }

    return this.setState({ modalIsOpen: name });
  };

  moreThanOneGic = () => {
    const { portfolio } = this.props;

    return portfolio.gic_info && Object.keys(portfolio.gic_info).length > 1;
  };

  hasTenGic = () => {
    const { portfolio } = this.props;

    return portfolio.gic_info && Object.keys(portfolio.gic_info).length === 10;
  };

  render() {
    const {
      portfolio,
      id,
      hidden,
      to_scorecard,
      partnerName,
      globe_destination,
    } = this.props;
    const { modalIsOpen, reminderIsOpen } = this.state;

    return (
      <div>
        <Container hidden={hidden} partnerName={partnerName}>
          <ul>
            <Feature name={ACCOUNTS_SAVE_AS_PORTFOLIO}>
              {this.isSaveVisible(portfolio) && !this.moreThanOneGic() && (
                <Popup
                  style={{ zIndex: 3 }}
                  trigger={
                    <li
                      onClick={() => this.handleClick(false, 'save')}
                      className={cn({ active: modalIsOpen === 'save' })}
                    >
                      <button>
                        <i className="ui icon save outline" />
                      </button>
                    </li>
                  }
                  position="right center"
                  content="Save this portfolio for use in planning and compare"
                  basic
                  wide
                />
              )}
            </Feature>
            {(portfolio && (portfolio.is_linked || portfolio.is_mp)) ||
            globe_destination === 'SmartRiskPortfolioBuilder' ? null : (
              <Popup
                style={{ zIndex: 3 }}
                trigger={
                  <li onClick={() => this.redirect(portfolio)}>
                    <button>
                      <i className="ui icon pencil" />
                    </button>
                  </li>
                }
                position="right center"
                content={`Edit this ${
                  isQuoteMedia(partnerName) ? 'Simulation' : 'Portfolio'
                }`}
                basic
              />
            )}

            {isGlobeInvestor(partnerName) ? null : (
              <Feature name={ACCOUNTS_COMPARE}>
                {!this.hasTenGic() && !reminderIsOpen && (
                  <Popup
                    style={{
                      zIndex: 3,
                      left: '55px',
                      width: isGlobeInvestor(partnerName)
                        ? window.location.pathname
                            .split('/')
                            .indexOf('compare') !== -1
                          ? '160px'
                          : '90px'
                        : window.location.pathname
                            .split('/')
                            .indexOf('compare') !== -1
                        ? '160px'
                        : to_scorecard
                        ? '220px'
                        : '150px',
                    }}
                    trigger={
                      <li
                        onClick={this.handleCompareClick}
                        className={cn({
                          active:
                            window.location.pathname
                              .split('/')
                              .indexOf('compare') !== -1,
                        })}
                      >
                        <button>
                          <i className="ui icon law" />
                        </button>
                      </li>
                    }
                    position="right center"
                    content={
                      isGlobeInvestor(partnerName)
                        ? window.location.pathname
                            .split('/')
                            .indexOf('compare') !== -1
                          ? 'Return to Scorecard'
                          : 'Compare'
                        : window.location.pathname
                            .split('/')
                            .indexOf('compare') !== -1
                        ? 'Return to Scorecard'
                        : to_scorecard
                        ? 'Return to Compare / What-if'
                        : 'Compare / What-If'
                    }
                    basic
                  />
                )}
                {!this.hasTenGic() && reminderIsOpen && (
                  <Popup
                    className="newPopup"
                    onClick={this.handleReminderClick}
                    trigger={
                      <li
                        onClick={this.handleCompareClick}
                        className={cn({
                          active:
                            window.location.pathname
                              .split('/')
                              .indexOf('compare') !== -1,
                        })}
                      >
                        <button>
                          <i className="ui icon law" />
                        </button>
                      </li>
                    }
                    position="bottom center"
                    open={reminderIsOpen}
                  >
                    <Popup.Header>Check this out!</Popup.Header>
                    <Popup.Content>
                      <br />
                      Click this scale icon to try our “Compare/What-If”
                      function.
                      <br />
                      <br />
                      <span
                        style={{
                          fontWeight: 'bold',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        Got it.
                      </span>
                    </Popup.Content>
                  </Popup>
                )}
              </Feature>
            )}

            {isGlobeInvestor(partnerName) &&
            globe_destination === 'SmartRiskPortfolioBuilder' ? (
              <Popup
                style={{
                  zIndex: 3,
                  left: '55px',
                  width: '190px',
                }}
                trigger={
                  <li onClick={() => this.handleClick(`/portfolio/find/`)}>
                    <button>
                      <i className="ui icon history" />
                    </button>
                  </li>
                }
                position="right center"
                content="Back to Portfolio Builder"
                basic
              />
            ) : null}
          </ul>
        </Container>
        <SaveModal
          portfolio={portfolio}
          closeModal={this.closeModal}
          open={modalIsOpen === 'save'}
        />
      </div>
    );
  }
}

SideBar.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  not_save_url_scorecard: PropTypes.bool,
  ui_compare_checked: PropTypes.bool,
  to_scorecard: PropTypes.bool,
  partnerName: PropTypes.string,
  globe_destination: PropTypes.string,
};

SideBar.defaultProps = {
  hidden: false,
  not_save_url_scorecard: false,
  ui_compare_checked: false,
  to_scorecard: false,
  partnerName: '',
  globe_destination: '',
};

export default withRouter(
  connect((state) => ({
    editing: state.Portfolio.editing,
    not_save_url_scorecard: state.Storage.not_save_url_scorecard,
    ui_compare_checked: state.Storage.ui_compare_checked,
    to_scorecard: state.Storage.to_scorecard,
    partnerName: state.Storage['partner-name'],
    globe_destination: state.Storage.globe_destination,
  }))(SideBar)
);

const Container = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  position: fixed;
  top: ${(props) => (!isGlobeInvestor(props.partnerName) ? '60px' : '0')};
  left: 0;
  width: 4.8rem;
  height: 100%;
  background: ${colors.red};
  padding: 0;
  z-index: 2;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      background: rgba(255, 255, 255, 0.2);
      color: white;
      text-align: center;
      width: 45px;
      margin: 1.2rem auto;
      min-height: 45px;
      border-radius: 50%;
      font-size: 1.5rem;
      line-height: 40px;
      transition: all 200ms ease;
      cursor: pointer;

      &.active {
        color: white;
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.5);
      }

      &:hover {
        color: white;
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.5);
      }

      button {
        background: transparent;
        border: none;
        outline: none;
        padding: 1px 6px !important;
      }
    }
  }

  i {
    margin: 0;
    width: fit-content;
    height: fit-content;

    &.save {
      padding: 2px 4px;
    }

    &.pencil {
      padding: 2px 4px;
    }

    &.money {
      padding: 2px 1px;
    }

    &.law {
      font-size: 1.3rem;
      padding: 1px 0;
    }
  }
`;
