import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';

import { ExposureTable, ExposureChart } from '../components';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class AssetClass extends Component {
  render() {
    const { analytics } = this.props;
    const { data } = analytics;
    const { french } = this.props;

    const assetAllocation = Object.values(data.tickersValid).reduce(
      (total, value) => {
        if (total[value.class]) {
          total[value.class] += value.weight;
        } else {
          total[value.class] = value.weight;
        }
        return total;
      },
      {}
    );

    const assetAllocationClass = {
      Alternative:
        'Alternative' in data.assetAllocation
          ? data.assetAllocation.Alternative
          : 0,
      Equity:
        'Equity' in data.assetAllocation ? data.assetAllocation.Equity : 0,
      'Fixed Income':
        'Fixed Income' in data.assetAllocation
          ? data.assetAllocation['Fixed Income']
          : 0,
      Cash: 'Cash' in data.assetAllocation ? data.assetAllocation.Cash : 0,
      'Misc.': 'Misc' in data.assetAllocation ? data.assetAllocation.Misc : 0,
    };

    const riskContClass = {
      Alternative:
        'Alternative' in data.riskCont.class
          ? data.riskCont.class.Alternative
          : 0,
      Equity: 'Equity' in data.riskCont.class ? data.riskCont.class.Equity : 0,
      'Fixed Income':
        'Fixed Income' in data.riskCont.class
          ? data.riskCont.class['Fixed Income']
          : 0,
      Cash: 'Cash' in data.riskCont.class ? data.riskCont.class.Cash : 0,
      Other: 'Other' in data.riskCont.class ? data.riskCont.class.Other : 0,
    };

    const customOrder = [
      'Alternative',
      'Equity',
      'Fixed Income',
      'Cash',
      'Other',
      'Misc',
    ];

    const sortedData = customOrder.map((key) => ({
      ticker: key,
      assetAllocation: assetAllocation[key] || 0,
      riskCont: riskContClass[key] || 0,
      retCont: data.retCont.class[key] || 0,
    }));

    const customSortOrder = (a, b) =>
      customOrder.indexOf(a.ticker) - customOrder.indexOf(b.ticker);

    sortedData.sort(customSortOrder);

    const filteredAssetAllocationClass = Object.entries(assetAllocationClass)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => value !== 0)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content={
            french ? "Analyse de la classe d'actifs" : 'Asset Class Analysis'
          }
          style={{ fontSize: '1.38rem' }}
        />
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header size="medium" style={{ margin: 0 }}>
                {french
                  ? 'Répartition nette des actifs'
                  : 'Net Asset Allocation'}
              </Header>
              <p style={{ marginTop: '1rem' }}>
                {french
                  ? 'La répartition nette des actifs de votre portefeuille reflète la répartition des actifs de tous les fonds équilibrés/fonds de fonds que vous pourriez avoir.'
                  : 'Your portfolio’s net asset allocation reflects the asset allocation of any balanced funds/funds of funds that you may have.'}
                <br />
                <br />
                {french
                  ? 'Par exemple, si vous investissez 50 % de votre argent dans un fonds 60/40 et 50 % dans un fonds 80/20, alors votre répartition nette des actifs est de 70/30.'
                  : 'For example, if you put 50% of your money in a 60/40 fund and 50% in an 80/20 fund, then your net asset allocation is 70/30.'}
                <br />
                <br />
                {french
                  ? "Les liquidités sont des liquidités non rémunérées dans votre compte si vous avez choisi d'analyser avec des liquidités."
                  : 'Cash is non-interest bearing cash in your account if you chose to analyze with cash.'}
                <br />
                <br />
                {french
                  ? 'Divers comprend les fonds de fonds, les actifs convertibles (à l’exclusion des obligations et des actions privilégiées), les contrats dérivés et autres.'
                  : 'Miscellaneous includes funds of funds, convertibles assets (excluding bonds and preferred shares), derivative contracts and others.'}
              </p>
            </Grid.Column>
            <Grid.Column>
              <Header size="medium" style={{ margin: 0 }}>
                {french
                  ? "Titres par classe d'actifs"
                  : 'Holdings by Asset Class'}
              </Header>
              <ExposureChart
                title=""
                labels={Object.keys(filteredAssetAllocationClass)}
                data={Object.values(filteredAssetAllocationClass)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header
          size="medium"
          content={french ? 'Contribution au risque' : 'Risk Contribution'}
          subheader={french ? "Par classe d'actifs" : 'By Asset Class'}
          style={{ marginTop: 0 }}
        />
        <p>
          {french
            ? "Si vous avez des fonds équilibrés/fonds de répartition d'actifs : veuillez noter qu'ils sont classés comme \"Autres\" par les fournisseurs de données. Malheureusement, nous ne sommes pas en mesure d'estimer la contribution au risque des sous-portefeuilles au sein d'un fonds (par exemple, la partie actions d'un fonds équilibré) car nous ne disposons pas de données de rendements séparés pour les sous-portefeuilles. Dans ce cas, votre graphique en anneau de répartition d'actifs ci-dessous sera différent de celui ci-dessus."
            : 'If you have balanced/asset allocation funds: please note that they are classified as "Other" by data vendors. Unfortunately, we are not able to estimate the risk contribution of the sub-portfolios within a fund (e.g., the equity portion of a balanced fund) because we do not have separate sub-portfolio returns data. In this case, your asset allocation ring chart below will be different from the one above.'}
        </p>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content={french ? 'Répartition des actifs' : 'Asset Allocation'}
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                labels={Object.keys(riskContClass)}
                data={Object.keys(riskContClass).map((key) =>
                  key in assetAllocation ? assetAllocation[key] : 0
                )}
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                size="medium"
                content={
                  french ? 'Contribution au risque' : 'Risk Contribution'
                }
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                labels={Object.keys(riskContClass)}
                data={Object.values(riskContClass)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ExposureTable
          headers={[
            <div>
              {french ? 'Classe' : 'Class'}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
                content={
                  french
                    ? 'Classification des titres/niveau de fonds'
                    : 'Security / fund level classification'
                }
              />
            </div>,
            french ? 'Répartition' : 'Allocation',
            french ? 'Contribution au risque' : 'Risk Contribution',
            french ? 'Contribution au rendement' : 'Return Contribution',
          ]}
          totals={[
            french ? 'Total' : 'Total',
            <div>100%</div>,
            <div>100%</div>,
            <div>100%</div>,
          ]}
          data={sortedData}
        />
      </Container>
    );
  }
}

AssetClass.propTypes = {
  analytics: PropTypes.object,
  french: PropTypes.bool,
};

AssetClass.defaultProps = {
  analytics: {},
  french: false,
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
