import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Radar, Line } from 'react-chartjs-2';
import cn from 'classnames';
import {
  Divider,
  Statistic,
  Header,
  Grid,
  Icon,
  Popup,
} from 'semantic-ui-react';

import {
  getOverAllScore,
  getOverAllGrade,
  decideCurrency,
} from 'utils/helpers';
import { CompareAccount } from 'actions';
import { colors } from 'utils/colors';
import {
  CustomBenchmark,
  Segment,
  Table,
  Button,
  Loading,
  Selection,
} from 'components';
import SaveModal from './SaveModal';

@withRouter
class CompareResult extends Component {
  state = {
    saveModalOpen: false,
  };
  componentWillMount = () => {
    const { compareResult, history, match } = this.props;

    if (!compareResult) {
      return history.push(`/accounts/${match.params.id}/compare`);
    }

    return false;
  };

  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0 %');

  getRadarData = (port1, port2, portOneName, portTwoName) => {
    const { benchmark, compareEsgResult, esgToggle, is_esg_enabled } =
      this.props;
    const portfolio1esg = compareEsgResult
      ? compareEsgResult.portfolio1esg.portfolio
      : null;
    const portfolio2esg = compareEsgResult
      ? compareEsgResult.portfolio2esg.portfolio
      : null;
    const hash = {
      Diversification: 'Diversification',
      Fees: 'Low Fees',
      Income: 'Income',
      Performance: 'Performance',
    };
    const portScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port1.score[key][benchmark][0]
          : port1.score[key][0];

      return total;
    }, {});
    const compareScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port2.score[key][benchmark][0]
          : port2.score[key][0];

      return total;
    }, {});

    return {
      labels:
        is_esg_enabled && esgToggle
          ? ['Downside Protection', ...Object.keys(portScores), 'ESG']
          : ['Downside Protection', ...Object.keys(portScores)],
      datasets: [
        {
          label: 'Current portfolio',
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data:
            is_esg_enabled &&
            esgToggle &&
            portfolio1esg &&
            portfolio1esg.esg_score
              ? [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                  portfolio1esg.ws_grade[0] || 0,
                ]
              : [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                ],
        },
        {
          label: portTwoName,
          backgroundColor: 'rgba(255,184,182,0.5)',
          borderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointBorderColor: colors.red,
          lineTension: 0.3,
          data:
            is_esg_enabled &&
            esgToggle &&
            portfolio2esg &&
            portfolio2esg.esg_score
              ? [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                  portfolio2esg.ws_grade[0] || 0,
                ]
              : [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                ],
        },
      ],
    };
  };

  getChartData = (port1, port2, portOneName, portTwoName) => ({
    labels: Object.keys(port1.cumrets.portfolio),
    datasets: [
      {
        data: Object.values(port1.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: 'Current Portfolio',
        lineTension: 0,
        fill: false,
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        pointBackgroundColor: colors.blue,
        pointBorderColor: colors.blue,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        data: Object.values(port2.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: portTwoName,
        lineTension: 0,
        fill: false,
        backgroundColor: colors.red,
        borderColor: colors.red,
        pointBackgroundColor: colors.red,
        pointBorderColor: colors.red,
        pointRadius: 0,
        pointHitRadius: 5,
      },
    ],
  });

  changeBenchmark = (benchmark) => {
    const { dispatch } = this.props;

    return dispatch(
      CompareAccount.updateBenchmark(benchmark ? 'global' : 'domestic')
    );
  };

  clearCompareResult = () => {
    const { match, history, dispatch } = this.props;

    dispatch(CompareAccount.clearResult());
    return history.push(`/accounts/${match.params.id}/compare`);
  };

  changeEsgToggle = (esgToggle) => {
    const { dispatch, compareEsgResult } = this.props;

    if (!compareEsgResult || !Object.keys(compareEsgResult).length) {
      this.fetchEsg();
    }

    return dispatch(CompareAccount.updateToggle('esgToggle', esgToggle));
  };

  didUserAddNewHoldingsToWhatIf = () => {
    const { compareAccState } = this.props;
    // eslint-disable-next-line prefer-const
    const { holdings, original } = compareAccState;

    // removing all holdings that have a weight of 0%
    const portfolioTickers = Object.keys(holdings).filter(
      (ticker) => holdings[ticker].weight > 0
    );
    const originalPortfolioTickers = Object.keys(original.holdings);

    const sortedPortfolioTickers = [...portfolioTickers].sort();
    const sortedOriginalPortfolioTickers = [...originalPortfolioTickers].sort();

    // using sets to decrease overall average time complexity
    const sortedOriginalPortfolioSet = new Set(sortedOriginalPortfolioTickers);

    // user must have added some new holdings
    if (sortedOriginalPortfolioTickers.length < sortedPortfolioTickers.length) {
      return true;
    } else if (
      sortedOriginalPortfolioTickers.length > sortedPortfolioTickers.length
    ) {
      // user could have deleted a bunch and added a new one
      for (let i = 0; i < sortedPortfolioTickers.length; i += 1) {
        if (!sortedOriginalPortfolioSet.has(sortedPortfolioTickers[i])) {
          return true;
        }
      }
    } else {
      // original and what-if have an equal number of holdings. user may have added new holdings (could have added one and deleted one)
      for (let i = 0; i < sortedPortfolioTickers.length; i += 1) {
        if (sortedPortfolioTickers[i] !== sortedOriginalPortfolioTickers[i]) {
          return true;
        }
      }
    }
    return false;
  };

  fetchEsg = () => {
    const { dispatch, compareResult } = this.props;
    const port1 = compareResult.analysis1.paramsAnalyzed;
    const port2 = compareResult.analysis2.paramsAnalyzed;

    dispatch(
      CompareAccount.fetchCompareESG(
        {
          symbols: port1.symbols.join(','),
          weights: port1.weights.join(','),
        },
        {
          symbols: port2.symbols.join(','),
          weights: port2.weights.join(','),
        },
        !this.didUserAddNewHoldingsToWhatIf()
      )
    );
  };

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  parseDate = () => {
    const { init_date, end_date } =
      this.props.compareResult.analysis1.paramsAnalyzed;
    const init = init_date.split('-');
    const end = end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  generatePDF = () => {
    const { match, history } = this.props;

    return history.push(`/accounts/${match.params.id}/compare/pdf`);
  };

  displayEsg = (esg) => {
    if (!esg) return '-';

    const { portfolio } = esg;

    if (!portfolio.esg_score) return '-';

    return !portfolio.esg_score ? '-' : portfolio.esg_score;
  };

  esgCoverage = (esg) => {
    if (!esg) return '(0%)';

    const { portfolio } = esg;
    const weight = (portfolio.weight * 100).toFixed(2);

    if (!weight || !Number(weight)) return '(0%)';

    const convertWeight = weight.split('.');

    if (convertWeight[1][0] === '0' && convertWeight[1][1] === '0')
      return `(${convertWeight[0]}%)`;
    if (convertWeight[1][1] === '0')
      return `(${convertWeight[0]}.${convertWeight[1][0]}%)`;

    return `(${weight}%)`;
  };

  formatDataForSaving = () => {
    const { compareAccState } = this.props;
    const { holdings, gic_info } = compareAccState;
    const { analysis2 } = compareAccState.compareResult;
    const { paramsAnalyzed } = analysis2;

    // ex. {VTI:{...}, ABC: {...}} -> 'VTI,ABC'
    const symbols = Object.keys(holdings)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);

    // ex. {VTI:{..., weight:3}, ABC: {..., weight:5}} -> '3,5'
    const weights = Object.keys(holdings)
      .map((ticker) => (holdings[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);

    return {
      ...paramsAnalyzed,
      gic_info, // need to use this info from compareportfolio due to the renaming of the gic tickers at the time of comparison
      symbols,
      weights,
      check_history: !!analysis2.check_history, // use the check_history value that was used in the comparison
      is_linked: true, // will show in 'from accounts' tab on the 'Revisit a Saved Portfolio page'
    };
  };

  renderSubheader = () => (
    <div className="sub header">
      Comparison Period: {this.parseDate()}
      <Popup
        trigger={
          <Icon
            name="info circle"
            style={{ fontSize: '15px', verticalAlign: 'initial' }}
          />
        }
        position="bottom center"
        wide
      >
        The comparison period is set to the longest common history of the two
        portfolios you are comparing. Therefore, the scores of the current
        portfolio may change, as its history may now be shorter.
      </Popup>
    </div>
  );

  renderError = () => {
    const { history } = this.props;

    return (
      <Error>
        Sorry, all securities in one of your portfolio are too new to be
        analyzed.
        <div style={{ display: 'block', margin: '10px auto 0' }}>
          <Button
            margin="0 0.5rem 0 0"
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>
        </div>
      </Error>
    );
  };

  render() {
    const {
      benchmark,
      compareResult,
      isFetching,
      compareEsgResult,
      is_esg_enabled,
      esgToggle,
      compareAccState,
      analytics,
      user_region,
    } = this.props;
    const analysis1 = compareResult ? compareResult.analysis1 : null;
    const analysis2 = compareResult ? compareResult.analysis2 : null;
    const portfolio1esg = compareEsgResult
      ? compareEsgResult.portfolio1esg
      : null;
    const portfolio2esg = compareEsgResult
      ? compareEsgResult.portfolio2esg
      : null;

    if (isFetching || !compareResult) {
      return <Loading />;
    }

    if (
      !analysis1 ||
      !analysis2 ||
      !Object.keys(analysis1).length ||
      !Object.keys(analysis2).length
    ) {
      return this.renderError();
    }

    const portOneName = compareAccState.original.name;
    const portTwoName = compareAccState.name;

    const compareResultsUseSameBenchmark =
      JSON.stringify(analysis1.fratios[benchmark]) ===
      JSON.stringify(analysis2.fratios[benchmark]);

    return (
      <Segment
        margin="1rem"
        style={{ minHeight: '100%', textAlign: 'center', minWidth: '820px' }}
      >
        <CloseIcon onClick={this.clearCompareResult}>+</CloseIcon>
        <Container centered>
          <Header
            size="large"
            content={`Current Portfolio ${
              portOneName ? `(${portOneName})` : ''
            } vs. ${portTwoName}`}
            subheader={this.renderSubheader()}
            style={{ margin: '0 0 3rem' }}
          />

          <CustomBenchmark benchmark={benchmark} feature="compareAccounts" />

          {is_esg_enabled ? (
            <EsgToggleBlock>
              <p>Include ESG score in my overall score</p>
              {esgToggle ? (
                <div
                  style={{
                    textAlign: 'right',
                    fontStyle: 'italic',
                    color: colors.hintRed,
                    fontSize: '1rem',
                  }}
                >
                  Check the table below to see if the two portfolios have
                  sufficient and
                  <br />
                  comparable ESG coverage for a meaningful comparison. If not,
                  toggle back to "No".
                </div>
              ) : null}
              <div
                style={{
                  width: '112px',
                  position: 'absolute',
                  top: '0',
                  right: '0',
                }}
              >
                <Selection
                  selected={esgToggle}
                  changeSelection={this.changeEsgToggle}
                  style={{ margin: '0' }}
                />
              </div>
            </EsgToggleBlock>
          ) : null}

          <Grid style={{ margin: '0 0 3rem 0' }}>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <Statistic
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    right: 0,
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Grade color={colors.blue}>
                    {getOverAllGrade({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio1esg,
                      data: analysis1,
                    })}
                  </Grade>
                  <Statistic.Label>current portfolio score</Statistic.Label>
                  <Statistic.Value>
                    {getOverAllScore({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio1esg,
                      data: analysis1,
                    })}
                  </Statistic.Value>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={8}>
                <Radar
                  id="radarChart"
                  data={this.getRadarData(
                    analysis1,
                    analysis2,
                    portOneName || 'Current Portfolio',
                    portTwoName
                  )}
                  options={radarOptions}
                  height={250}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Statistic
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    right: 0,
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Grade color={colors.red}>
                    {getOverAllGrade({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio2esg,
                      data: analysis2,
                    })}
                  </Grade>
                  <Statistic.Label>{portTwoName} score</Statistic.Label>
                  <Statistic.Value>
                    {getOverAllScore({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio2esg,
                      data: analysis2,
                    })}
                  </Statistic.Value>
                </Statistic>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header>
            <Header.Content>Key Statistics</Header.Content>
            <Header.Subheader>
              {`${this.parseDate()} (inclusive) in ${decideCurrency(
                analytics.data,
                user_region || 'CA'
              )}`}
            </Header.Subheader>
          </Header>

          <Divider />

          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4} />
                <Table.HeaderCell width={3}>Annualized Return</Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  Sharpe Ratio&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content="This ratio measures the 'reward' of the portfolio per unit of 'risk', where reward is the average return of the portfolio over and above the risk-free rate, and risk is the standard deviation (or volatility) of the portfolio. The risk-free rate we use is the 12-month Treasury bill yield."
                    wide
                  />
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  Roy&#8217;s Safety First Criterion&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    wide
                  >
                    Like the Sharpe Ratio, this ratio measures the
                    portfolio&#8217;s reward per unit of risk. However, the
                    reward is defined as the portfolio&#8217;s average return
                    over your target return (or minimum acceptable rate),
                    instead of the risk-free rate. Currently, the target return
                    is set to zero, i.e., money preservation (not losing money
                    in nominal terms).
                  </Popup>
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  Sortino Ratio&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    wide
                  >
                    This ratio measures the portfolio&#8217;s reward per unit of
                    downside risk. The reward is the portfolio&#8217;s average
                    return over your target return (or minimum acceptable rate).
                    Downside risk is the volatility of the portfolio returns
                    below your target. Currently, the target return is set to
                    zero, i.e., money preservation (not losing money in nominal
                    terms).
                  </Popup>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={4}>Current Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.annualizedRet)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis1.fratios.portfolio.sharpe)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis1.fratios.portfolio.roys)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis1.fratios.portfolio.sortino)}
                </Table.Cell>
              </Table.Row>
              {!compareResultsUseSameBenchmark && (
                <Table.Row>
                  <Table.Cell width={4}>
                    {analysis1.fratios[benchmark].name}
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content={analysis1.fratios[benchmark].description}
                      wide
                    />
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(
                      analysis1.fratios[benchmark].annualizedRet
                    )}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios[benchmark].sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios[benchmark].roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios[benchmark].sortino)}
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row positive>
                <Table.Cell width={4}>{portTwoName}</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.annualizedRet)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis2.fratios.portfolio.sharpe)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis2.fratios.portfolio.roys)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis2.fratios.portfolio.sortino)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  {analysis2.fratios[benchmark].name}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content={analysis2.fratios[benchmark].description}
                    wide
                  />
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].annualizedRet)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis2.fratios[benchmark].sharpe)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis2.fratios[benchmark].roys)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.formatNum(analysis2.fratios[benchmark].sortino)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Divider />

          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  Total Risk&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content="This is the variability of your portfolio's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk."
                    wide
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Downside Risk&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content="This is the variability of your portfolio's returns below a target. The target used here is capital preservation; that is, a non-negative return."
                    wide
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Maximum Drawdown&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content="This is the maximum peak to trough loss observed during the period in question. It gives a different perspective of a portfolio's downside risk."
                    wide
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Downside Capture&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content="This measures whether your portfolio lost less than the stock market when there was a market downturn. A downside capture of less than 100% indicates that your portfolio lost less than the stock market during those periods. A downside capture that is negative indicates that your portfolio actually generated positive returns when the stock market was in the red."
                    wide
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={4}>Current Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(
                    analysis1.fratios.portfolio.dscapture[benchmark]
                  )}
                </Table.Cell>
              </Table.Row>
              {!compareResultsUseSameBenchmark && (
                <Table.Row>
                  <Table.Cell width={4}>
                    {analysis1.fratios[benchmark].name}
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content={analysis1.fratios[benchmark].description}
                      wide
                    />
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].stdDev)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].dsr)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].maxDd)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].dscapture)}
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row positive>
                <Table.Cell width={4}>{portTwoName}</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(
                    analysis2.fratios.portfolio.dscapture[benchmark]
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  {analysis2.fratios[benchmark].name}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content={analysis2.fratios[benchmark].description}
                    wide
                  />
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].dscapture)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Divider />

          {is_esg_enabled ? (
            <Table basic textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell width={3}>
                    Fees&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content="Weighted average product fees (MERs)"
                      wide
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Income&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content="Weighted average distributions"
                      wide
                    />
                  </Table.HeaderCell>
                  {is_esg_enabled && esgToggle ? (
                    <Table.HeaderCell>
                      ESG Coverage
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="Portfolio average ESG score and percentage of holdings that have ESG ratings (in brackets)."
                        wide
                      />
                    </Table.HeaderCell>
                  ) : null}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row positive>
                  <Table.Cell width={4}>Current Portfolio</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.portMER)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.portYield)}
                  </Table.Cell>

                  {esgToggle ? (
                    <Table.Cell width={3}>
                      {this.displayEsg(portfolio1esg)}&nbsp;
                      {this.esgCoverage(portfolio1esg)}
                    </Table.Cell>
                  ) : (
                    <Table.Cell width={3} />
                  )}
                  <Table.Cell width={3} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>{portTwoName}</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis2.portMER)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis2.portYield)}
                  </Table.Cell>

                  {esgToggle ? (
                    <Table.Cell width={3}>
                      {this.displayEsg(portfolio2esg)}&nbsp;
                      {this.esgCoverage(portfolio2esg)}
                    </Table.Cell>
                  ) : (
                    <Table.Cell width={3} />
                  )}
                  <Table.Cell width={3} />
                </Table.Row>
              </Table.Body>
            </Table>
          ) : (
            <Table basic textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>
                    Fees&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content="Weighted average product fees (MERs)"
                      wide
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Income&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content="Weighted average distributions"
                      wide
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row positive>
                  <Table.Cell width={4}>Current Portfolio</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.portMER)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.portYield)}
                  </Table.Cell>

                  <Table.Cell width={3} />

                  <Table.Cell width={3} />
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>{portTwoName}</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis2.portMER)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis2.portYield)}
                  </Table.Cell>

                  <Table.Cell width={3} />

                  <Table.Cell width={3} />
                </Table.Row>
              </Table.Body>
            </Table>
          )}

          <Divider />

          <Header>
            <Header.Content>Hypothetical Portfolio History</Header.Content>
            <Header.Subheader>
              Based on the current composition of your portfolio&nbsp;
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                content="Using the same holdings and the same weights."
                wide
              />
            </Header.Subheader>
            <Header.Subheader>
              {`${this.parseDate()} (inclusive) in ${decideCurrency(
                analytics.data,
                user_region || 'CA'
              )}`}
            </Header.Subheader>
          </Header>
          <Line
            data={this.getChartData(
              analysis1,
              analysis2,
              portOneName || 'Current Portfolio',
              portTwoName
            )}
            options={chartOptions}
          />
          <div
            style={{
              display: 'flex',
              margin: 'auto',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <Button
              className={cn('left')}
              icon="file text"
              color={colors.darkGrey}
              onClick={this.generatePDF}
              style={{ marginTop: '30px' }}
            >
              Print/PDF
            </Button>
            <Button
              className={cn('left')}
              icon="ui icon save outline"
              color={colors.red}
              onClick={() => this.setState({ saveModalOpen: true })}
              style={{ marginTop: '30px' }}
            >
              Save{' '}
              <span style={{ fontStyle: 'italic' }}> As a New Portfolio</span>
            </Button>
          </div>
        </Container>
        <SaveModal
          closeModal={() => this.setState({ saveModalOpen: false })}
          open={this.state.saveModalOpen}
          portfolio={this.formatDataForSaving()}
          redirectToSavedPortfolioOnSuccess={false}
        />
      </Segment>
    );
  }
}

CompareResult.propTypes = {
  dispatch: PropTypes.func.isRequired,
  compareResult: PropTypes.object,
  benchmark: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  compareEsgResult: PropTypes.object,
  is_esg_enabled: PropTypes.bool,
  esgToggle: PropTypes.bool,
  compareAccState: PropTypes.object.isRequired,
  analytics: PropTypes.object,
  user_region: PropTypes.string,
};

CompareResult.defaultProps = {
  compareResult: null,
  compareEsgResult: null,
  is_esg_enabled: false,
  esgToggle: false,
  analytics: {},
  user_region: 'CA',
};

export default connect((state) => ({
  compareAccState: state.CompareAccount,
  compareResult: state.CompareAccount.compareResult,
  benchmark: state.CompareAccount.benchmark,
  isFetching: state.CompareAccount.isFetching,
  is_esg_enabled: state.Storage.is_esg_enabled,
  compareEsgResult: state.CompareAccount.compareEsgResult,
  esgToggle: state.CompareAccount.esgToggle,
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
}))(CompareResult);

const Grade = styled.div`
  font-family: 'Kalam', cursive;
  color: ${(props) => props.color};
  font-size: 5rem;
  padding: 1.5rem 0;
`;

const Container = styled.div`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const radarOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: { position: 'bottom', usePointStyle: true },
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
  layout: {
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
  hover: {
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const chartOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (label) => `$${label}`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${data.datasets[tooltipItems.datasetIndex].label}: $${
          tooltipItems.yLabel
        }`,
    },
  },
  hover: {
    animationDuration: 0,
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const CloseIcon = styled.div`
  position: absolute;
  top: 2px;
  right: 3px;
  height: 21px;
  width: 21px;
  background: white;
  text-align: center;
  font-size: 24px;
  line-height: 17px;
  border: 1px solid #e4e5e5;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  z-index: 3;

  &:hover {
    background: #fff6f6;
    border: 1px solid #db2828;
  }
`;

const EsgToggleBlock = styled.div`
  font-size: 1.15rem;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;

  p {
    font-size: 1rem;
    width: calc(100% - 112px);
    text-align: right;
    max-width: none;
    margin-bottom: 0.5rem;
  }
`;

const Error = styled.div`
  position: absolute;
  left: calc(50% + 26rem - 400px);
  width: 400px;
  background: white;
  margin: 4rem auto;
  text-align: center;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 8px;
  font-size: 1.4rem;
  box-shadow: 0 0 3px dimgrey;
`;
