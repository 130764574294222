import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, Icon, Header, Grid, Image, Statistic } from 'semantic-ui-react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Button } from 'components';
import { colors } from 'utils/colors';
import { Portfolio, Planning, Storage } from 'actions';
import Fifty from 'images/50.png';
import SeventyFive from 'images/75.png';
import Ninety from 'images/90.png';
import { NavigationButtons } from '../components';
import { ErrorLabel } from '../../../../components';

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = { prevInputChanged: false, error: '' };
    this.wealthResultDiv = null;
  }

  componentWillMount() {
    const { dispatch, modelPortfolios, userPortfolios } = this.props;

    if (!modelPortfolios.length) {
      dispatch(Portfolio.fetchModel());
    }

    if (!userPortfolios.length) {
      dispatch(Portfolio.fetchUser());
    }
  }

  componentDidMount() {
    const { cacheInputChanged } = this.props;
    if (cacheInputChanged) {
      this.setState({ prevInputChanged: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { result } = this.props;
    if (result !== prevProps.result) {
      this.scrollToResult();
    }
  }

  scrollToResult = () => {
    if (this.wealthResultDiv) {
      this.wealthResultDiv.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  setTargetSaving = (isNewBlueprint) => {
    const {
      match,
      history,
      dispatch,
      result,
      targ,
      cacheTW,
      cacheTS,
      cacheSR,
      modelPortfolios,
      userPortfolios,
    } = this.props;

    if (isNewBlueprint) {
      dispatch(Planning.updateBlueprintData('targetSaving', {}));

      return history.push(
        `/planning/blueprint/${match.params.id}/target_saving`
      );
    }

    if (this.isTargOrHorzChanged()) {
      const { savings_portfolio } = cacheTS || {};

      const selectedPortfolio = modelPortfolios
        .concat(userPortfolios)
        .find((port) => Number(port.id) === Number(savings_portfolio));
      dispatch(Storage.setItem('cacheWealthChanged', true));

      if (!selectedPortfolio) {
        dispatch(Planning.updateBlueprintData('targetSaving', {}));

        return history.push(
          `/planning/blueprint/${match.params.id}/target_saving`
        );
      }

      dispatch(
        Storage.setItem('cacheTW', {
          ...cacheTW,
          targ: result.Annuity ? result.Annuity : targ,
        })
      );
      dispatch(
        Planning.updateBlueprintData('targetSaving', {
          ...cacheTS,
        })
      );
      return history.push(
        `/planning/blueprint/${match.params.id}/target_saving`
      );
    }
    dispatch(
      Planning.updateBlueprintData('targetSaving', {
        ...cacheTS,
      })
    );
    dispatch(
      Planning.updateBlueprintData('savingResult', {
        ...cacheSR,
      })
    );

    return history.push(`/planning/blueprint/${match.params.id}/target_saving`);
  };

  hasEmptyTarg = (values) => {
    if (values === null || values === undefined) {
      this.setState({ error: 'Select one of the three scenarios' });

      return true;
    }

    return false;
  };

  isTargOrHorzChanged = () => {
    const { match, targ, horz, data, cacheBlueprint } = this.props;
    const { targetWealth } = data || {};
    const { targetWealth: cacheTW } = cacheBlueprint || {};

    if (
      match.params.id === 'new' ||
      !cacheTW ||
      Object.keys(cacheTW).length === 0
    ) {
      return false;
    }

    return targetWealth.horz !== horz || cacheTW.targ !== targ;
  };

  updatetarg = (e, { value }) => {
    const { dispatch, cacheTargetChanged } = this.props;
    if (!cacheTargetChanged) {
      dispatch(Storage.setItem('cacheTargetChanged', true));
    }

    dispatch(Planning.updateBlueprintData('targetWealth', { targ: value }));
    this.setState({ error: '' });
  };

  nextStep = () => {
    const { match, dispatch, data } = this.props;

    if (this.hasEmptyTarg(data.targetWealth.targ)) {
      return false;
    }

    dispatch(Planning.nextSubPage('targetWealth'));
    dispatch(
      Planning.updateBlueprintData('targetSaving', { currentSubPage: 1 })
    );

    return this.setTargetSaving(match.params.id === 'new');
  };

  isChangedFromPrevious = () => {
    const { match, data, cacheTW, cacheWR } = this.props;
    const { targetWealth, wealthResult } = data || {};

    if (match.params.id === 'new') {
      return false;
    }

    if (
      (targetWealth.annuityRate && !cacheTW.annuityRate) ||
      (!targetWealth.annuityRate && cacheTW.annuityRate)
    ) {
      return false;
    }

    if (targetWealth.annuityRate) {
      return (
        targetWealth.annuityRate === cacheTW.annuityRate &&
        JSON.stringify(wealthResult) !== JSON.stringify(cacheWR)
      );
    }

    return (
      targetWealth.wealth_portfolio === cacheTW.wealth_portfolio &&
      JSON.stringify(wealthResult) !== JSON.stringify(cacheWR)
    );
  };

  renderAnnuity = () => {
    const { result } = this.props;

    return (
      <BlueprintContent>
        <div>
          <Header
            textAlign="center"
            size="medium"
            content="Target Wealth at Retirement"
            style={{ margin: '0', display: 'inline-block' }}
          />
          <Header textAlign="center" style={{ margin: '0px auto' }}>
            <Header.Subheader style={{ marginTop: '.3rem' }}>
              Using a portfolio for your post-retirement savings that is
              risk-free (i.e. fixed return), how much you need to have saved by
              your retirement age is a certain amount.
            </Header.Subheader>
          </Header>
        </div>
        {this.isChangedFromPrevious() && (
          <div style={{ marginTop: '1.5rem', color: colors.red }}>
            Note: These values have been updated to reflect changes made in the
            previous section(s).
          </div>
        )}
        <Statistic size="mini" style={{ display: 'block' }}>
          <Statistic.Label>Target Wealth</Statistic.Label>
          <Statistic.Value>
            {`$${result.Annuity.toLocaleString()}`}{' '}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ color: 'dimgrey' }}
                />
              }
              content="Future (Nominal) Value at Retirement"
              wide
              position="top center"
            />
          </Statistic.Value>
        </Statistic>{' '}
        <div
          style={{
            color: 'rgba(0,0,0,0.6)',
            width: '47rem',
            margin: '0 auto 2rem',
          }}
        >
          This amount will help determine how much you need to save per month
          from now until you retire. Note that a risk-free investment such as a
          GIC may be safe but it has lower returns. If the percentage of annual
          retirement spending that is covered by your expected retirement income
          is high - meaning that you have a good amount of fixed income in
          retirement - you could consider taking on a bit more financial risk in
          your portfolio post retirement. You can pick a different portfolio
          above and see how it changes the outcome.
        </div>
        <div id="wealthResultDiv" ref={(el) => (this.wealthResultDiv = el)}>
          <NavigationButtons
            handleNextPage={this.nextStep}
            showPreviousButton={false}
            nextButtonText="Next"
          />
        </div>
        <div style={{ clear: 'both' }} />
      </BlueprintContent>
    );
  };

  renderWealth = () => {
    const { result, targ } = this.props;
    const { error, prevInputChanged } = this.state;

    return (
      <BlueprintContent>
        <div>
          <Header
            content="Select Target Wealth at Retirement"
            textAlign="center"
            style={{ margin: '0', display: 'inline-block' }}
          />
        </div>
        <Header textAlign="center" style={{ margin: '0px auto' }}>
          <Header.Subheader style={{ fontSize: '1.2rem', marginTop: '.3rem' }}>
            Since the portfolio you picked for your post-retirement years has an
            uncertain outcome, how much you need to have saved by your
            retirement age is associated with a probability. This probability is
            the likelihood of being able to sustain your net spending needs in
            retirement. We generate the results based on 10,000 simulations.
          </Header.Subheader>
        </Header>
        {this.isChangedFromPrevious() && (
          <div style={{ marginTop: '1.5rem', color: colors.red }}>
            Note: These values have been updated to reflect changes made in the
            previous section(s).
          </div>
        )}
        <Grid
          style={{ position: 'relative', width: '100%', margin: '1rem auto' }}
        >
          <Grid.Row>
            <Grid.Column textAlign="center">
              <LessHeader>Likelihood of Success</LessHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} style={{ padding: 0, marginTop: '10px' }}>
            <Grid.Column
              onClick={() => {
                this.updatetarg(null, { value: result['0.5'] });
                this.setState({ prevInputChanged: false });
              }}
            >
              <Image src={Fifty} style={{ width: '70%', margin: '0 auto' }} />
              <Statistic size="mini" style={{ display: 'block' }}>
                <Statistic.Label>Target Wealth</Statistic.Label>
                <Statistic.Value>
                  {Object.keys(result).length
                    ? `$${result['0.5'].toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}`
                    : '--'}{' '}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ color: 'dimgrey' }}
                      />
                    }
                    content="Future (Nominal) Value at Retirement"
                    wide
                    position="top center"
                  />
                </Statistic.Value>
              </Statistic>

              <Button
                className={cn('left')}
                style={{ margin: '0 auto' }}
                value={result[0.5]}
                name="targ"
                color={
                  targ === result[0.5] && prevInputChanged === false
                    ? colors.teal
                    : colors.lightGrey
                }
                icon={
                  targ === result[0.5] && prevInputChanged === false
                    ? 'check'
                    : 'circle outline'
                }
              >
                {targ === result[0.5] && prevInputChanged === false
                  ? 'Selected'
                  : 'Select'}
              </Button>
            </Grid.Column>
            <Grid.Column
              onClick={() => {
                this.updatetarg(null, { value: result['0.75'] });
                this.setState({ prevInputChanged: false });
              }}
            >
              <Image
                src={SeventyFive}
                style={{ width: '70%', margin: '0 auto' }}
              />
              <Statistic size="mini" style={{ display: 'block' }}>
                <Statistic.Label>Target Wealth</Statistic.Label>
                <Statistic.Value>
                  {Object.keys(result).length
                    ? `$${result['0.75'].toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}`
                    : '--'}{' '}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ color: 'dimgrey' }}
                      />
                    }
                    content="Future (Nominal) Value at Retirement"
                    wide
                    position="top center"
                  />
                </Statistic.Value>
              </Statistic>

              <Button
                className={cn('left')}
                style={{ margin: '0 auto' }}
                value={result[0.75]}
                name="targ"
                color={
                  targ === result[0.75] && prevInputChanged === false
                    ? colors.lightBlue
                    : colors.lightGrey
                }
                icon={
                  targ === result[0.75] && prevInputChanged === false
                    ? 'check'
                    : 'circle outline'
                }
              >
                {targ === result[0.75] && prevInputChanged === false
                  ? 'Selected'
                  : 'Select'}
              </Button>
              <ErrorLabel
                className="bottom"
                style={{ top: '17.5rem' }}
                msg={error}
              />
            </Grid.Column>
            <Grid.Column
              onClick={() => {
                this.updatetarg(null, { value: result['0.9'] });
                this.setState({ prevInputChanged: false });
              }}
            >
              <Image src={Ninety} style={{ width: '70%', margin: '0 auto' }} />
              <Statistic size="mini" style={{ display: 'block' }}>
                <Statistic.Label>Target Wealth</Statistic.Label>
                <Statistic.Value>
                  {Object.keys(result).length
                    ? `$${result['0.9'].toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}`
                    : '--'}{' '}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ color: 'dimgrey' }}
                      />
                    }
                    content="Future (Nominal) Value at Retirement"
                    wide
                    position="top center"
                  />
                </Statistic.Value>
              </Statistic>

              <Button
                className={cn('left')}
                style={{ margin: '0 auto' }}
                value={result[0.9]}
                name="targ"
                color={
                  targ === result[0.9] && prevInputChanged === false
                    ? colors.blue
                    : colors.lightGrey
                }
                icon={
                  targ === result[0.9] && prevInputChanged === false
                    ? 'check'
                    : 'circle outline'
                }
              >
                {targ === result[0.9] && prevInputChanged === false
                  ? 'Selected'
                  : 'Select'}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div id="wealthResultDiv" ref={(el) => (this.wealthResultDiv = el)}>
          <NavigationButtons
            handleNextPage={this.nextStep}
            showPreviousButton={false}
            nextButtonText="Next"
          />
        </div>
        <div style={{ clear: 'both' }} />
      </BlueprintContent>
    );
  };

  render() {
    const { result, match } = this.props;

    if (!result) {
      return null;
    }

    return result.Annuity
      ? this.renderAnnuity(match.params.id)
      : this.renderWealth(match.params.id);
  }
}

Result.propTypes = {
  result: PropTypes.object,
  horz: PropTypes.number,
  targ: PropTypes.number,
  history: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  modelPortfolios: PropTypes.array,
  userPortfolios: PropTypes.array,
  cacheWR: PropTypes.object,
  cacheTW: PropTypes.object,
  cacheTS: PropTypes.object,
  cacheSR: PropTypes.object,
  cacheBlueprint: PropTypes.object,
  data: PropTypes.object,
  cacheTargetChanged: PropTypes.bool,
  cacheInputChanged: PropTypes.bool,
};

Result.defaultProps = {
  result: null,
  horz: null,
  targ: null,
  cacheTW: {},
  cacheWR: {},
  cacheTS: {},
  cacheSR: {},
  cacheBlueprint: {},
  history: {},
  match: {},
  modelPortfolios: [],
  userPortfolios: [],
  data: {},
  cacheTargetChanged: false,
  cacheInputChanged: false,
};

export default connect((state) => ({
  modelPortfolios: state.Portfolio.model,
  userPortfolios: state.Portfolio.user,
  isFetching: state.Planning.isFetching,
  cacheTW: state.Storage.cacheTW,
  cacheTS: state.Storage.cacheTS,
  cacheWR: state.Storage.cacheWR,
  cacheSR: state.Storage.cacheSR,
  cacheBlueprint: state.Storage['cache-pwpa-blueprint'],
  cacheTargetChanged: state.Storage.cacheTargetChanged,
  cacheInputChanged: state.Storage.cacheInputChanged,
}))(Result);

const BlueprintContent = styled.div`
  padding: ${(props) =>
    props.isClientMode ? '2rem 3rem 4rem 3rem' : '2rem 3rem'};
  text-align: center;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 2rem;
  div {
    .ui.header {
      .sub.header {
        font-size: 1.2rem;
      }
    }
  }
  .grid {
    .column {
      cursor: pointer;
      .mini.statistic {
        font-size: 17.9px;
        .label {
          text-transform: none !important;
          line-height: 2rem;
        }
        .value {
          font-size: 21px !important;
        }
      }
    }
  }
`;

const LessHeader = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  font-size: 1.2em;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 64%;
    background-color: rgba(0, 0, 0, 0.4);
    left: 18%;
  }
`;
