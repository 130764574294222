import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Chatbot, Accounts, Storage } from 'actions';
import { Icon, Popup } from 'semantic-ui-react';
import { Button } from 'components';
import { colors } from 'utils/colors';
import bot from 'images/bot.png';

const mapStateToProps = (state) => ({
  messages: state.Chatbot.messages,
  ui_chatbot_checked: state.Storage.ui_chatbot_checked,
});
class ChatbotNav extends Component {
  state = {
    isChatOpen: false,
    inputValue: '',
    isDisclaimerClicked: localStorage.getItem('isDisclaimerClicked'),
    isChatReminderClicked: localStorage.getItem('isChatReminderClicked'),
    dragging: false,
    position: { x: window.innerWidth - 520, y: 100 },
    width: window.innerWidth,
    height: window.innerHeight,
    initialPosition: {
      x: window.innerWidth - 520,
      y: 100,
    },
  };
  componentDidMount = () => {
    document.addEventListener('click', this.closeReportBug);
    window.addEventListener('resize', this.updateDimensions);
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updateDimensions();
    }
    window.addEventListener('resize', this.updateDimensions);
    if (prevProps.messages.length !== this.props.messages.length) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.closeReportBug, false);
    window.removeEventListener('resize', this.updateDimensions);
  };

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      initialPosition: {
        x: window.innerWidth - 520,
        y: window.innerHeight - 15,
      },
    });
  };

  scrollToBottom() {
    const chatContainer = document.getElementById('chatContainer');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

  handleMouseDown = (e) => {
    this.setState({
      dragging: true,
      initialPosition: { x: e.clientX, y: e.clientY },
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = (e) => {
    if (this.state.dragging) {
      const deltaX = e.clientX - this.state.initialPosition.x;
      const deltaY = e.clientY - this.state.initialPosition.y;

      // Proposed new position
      let newX = this.state.position.x + deltaX;
      let newY = this.state.position.y + deltaY;

      // Chat container dimensions
      const chatWidth = 500; // Width of the chat container
      const chatHeight = Math.min(window.innerHeight * 0.75, 595);

      // Boundary conditions
      const minX = 0;
      const minY = 0;
      const maxX = this.state.width - chatWidth;
      const maxY = this.state.height - chatHeight;

      // Constrain within boundaries
      newX = Math.max(minX, Math.min(newX, maxX));
      newY = Math.max(minY, Math.min(newY, maxY));

      // Update the position in the state
      this.setState({
        position: { x: newX, y: newY },
        initialPosition: { x: e.clientX, y: e.clientY },
      });
    }
  };

  handleMouseUp = () => {
    // Set dragging to false and remove event listeners
    this.setState({ dragging: false });
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 200) {
      this.setState({ inputValue: newValue });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { inputValue } = this.state;
    if (inputValue) {
      dispatch(Chatbot.runChat(inputValue));
      this.setState({ inputValue: '' });
    }
  };

  handleDisclaimerCheck = () => {
    localStorage.setItem('isDisclaimerClicked', true);
    this.setState({ isDisclaimerClicked: true });
  };

  handleReminderClick = () => {
    const { dispatch } = this.props;
    localStorage.setItem('isChatReminderClicked', true);
    dispatch(Storage.setItem('ui_chatbot_checked', true));
    dispatch(Accounts.checkUiBot());
    this.setState({ isChatReminderClicked: true });
    this.setState({ isChatOpen: true });
  };

  handleOpenIconClick = () => {
    const { isChatOpen } = this.state;

    this.setState({
      position: {
        x: window.innerWidth - 520,
        y: 100,
      },
    });
    this.setState({
      isChatOpen: !isChatOpen,
    });
  };

  render() {
    const { messages, dispatch, ui_chatbot_checked } = this.props;
    const {
      isChatOpen,
      inputValue,
      isDisclaimerClicked,
      isChatReminderClicked,
      position,
    } = this.state;

    return (
      <div style={{ zIndex: '1500' }}>
        <Content
          style={{ display: isChatOpen ? 'block' : 'none' }}
          onMouseDown={this.handleMouseDown}
          x={position.x}
          y={position.y}
        >
          <div
            style={{
              width: '100%',
              height: isDisclaimerClicked ? 'calc(100% - 75px)' : '100%',
            }}
            ref={(el) => (this.chatContainerRef = el)}
          >
            {!isDisclaimerClicked ? (
              <div
                style={{
                  color: `white`,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '2.8rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  textAlign: 'left',
                  overflow: 'auto',
                  fontSize: '1rem',
                }}
              >
                <span style={{ fontWeight: '700', fontSize: '1.2rem' }}>
                  Disclaimer:
                </span>
                <br />
                This is the beta version of “Sandra”, Wealthscope’s AI
                Assistant. She leverages our internal documentation to support
                you in navigating and using our platform.
                <br /> Please note the following: <br />
                <ul>
                  <li>She does not provide investment advice;</li>
                  <li>She does not perform calculations;</li>
                  <li>
                    She does not have access to any personal or financial data.
                  </li>
                </ul>
                <div>
                  Sandra complements our user guides and is designed to provide
                  timely support. For your privacy, we do NOT store your
                  questions or Sandra’s responses. We would however, appreciate
                  your help in improving her responses over time. If you find
                  any incorrect or unhelpful responses, please send us an email
                  to:{' '}
                  <a
                    href="mailto:contact@wealthscope.ca"
                    target="blank"
                    style={{ color: 'white' }}
                  >
                    contact@wealthscope.ca
                  </a>
                  .
                </div>
                <br />
                <Button
                  className="right"
                  icon="check"
                  color={colors.orange}
                  background="white"
                  style={{
                    background: 'white',
                    minHeight: '36px',
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    this.handleDisclaimerCheck();
                  }}
                >
                  Got it!
                </Button>
              </div>
            ) : (
              <React.Fragment>
                <ChatContainer id="chatContainer">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      position: 'sticky',
                      top: 0, // Adjust this value as needed
                      zIndex: 1, // Add z-index to ensure it stays above other content
                      background: 'white', // Add a background color to ensure visibility
                      paddingTop: '1.5rem',
                    }}
                  >
                    <Popup
                      trigger={
                        <Icon
                          name="undo"
                          style={{
                            fontSize: '1.1rem',
                            cursor: 'pointer',
                            marginRight: '1rem',
                            position: 'sticky',
                            marginBottom: '1rem',
                          }}
                          onClick={() => {
                            dispatch(Chatbot.clear());
                          }}
                        />
                      }
                      position="top center"
                      wide
                      content="Reset Chat"
                    />
                    <Popup
                      trigger={
                        <div
                          style={{
                            transform: 'rotate(-45deg)',
                            cursor: 'pointer',
                            fontSize: '2.1rem',
                            fontWeight: 'bold',
                            color: `${colors.red}`,
                            marginTop: '-2px',
                          }}
                          onClick={() => {
                            this.handleOpenIconClick();
                          }}
                        >
                          +
                        </div>
                      }
                      position="top center"
                      wide
                      content="Close"
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Fade right>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '10px',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <ChatBubble role="assistant">
                          <RoleLabel role="assistant">Sandra: </RoleLabel>
                          Hello! I’m here to assist you with any questions about
                          Wealthscope’s tools, functionalities, and model
                          assumptions.
                        </ChatBubble>
                      </div>
                    </Fade>
                    {messages.map((message, index) => (
                      <Fade
                        left={message.role === 'user'}
                        right={message.role === 'assistant'}
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginBottom: '10px',
                            justifyContent:
                              message.role === 'user'
                                ? 'flex-end'
                                : 'flex-start',
                          }}
                          key={index}
                        >
                          <ChatBubble role={message.role}>
                            <RoleLabel role={message.role}>
                              {message.role === 'assistant' ? 'Sandra' : 'User'}
                              {': '}
                            </RoleLabel>
                            {message.content}
                          </ChatBubble>
                        </div>
                      </Fade>
                    ))}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignSelf: 'flex-end',
                      marginTop: 'auto',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        color: 'rgba(0, 0, 0, 0.5)',
                        fontSize: '0.8rem',
                      }}
                    >
                      {inputValue.length}/200
                    </div>
                    <div
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        localStorage.removeItem('isDisclaimerClicked');
                        this.setState({ isDisclaimerClicked: false });
                      }}
                    >
                      Disclaimer
                    </div>
                  </div>
                </ChatContainer>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                />
                <InputContainer onSubmit={this.handleSubmit}>
                  <Input
                    value={inputValue}
                    onChange={this.handleChange}
                    placeholder="Type your message here"
                    maxLength={200}
                  />
                  <SendButton type="submit">
                    <Icon name="send" style={{ fontSize: '15px' }} />
                  </SendButton>
                </InputContainer>
              </React.Fragment>
            )}
          </div>
        </Content>

        <div ref={(el) => (this.bugRef = el)}>
          {isChatOpen ? (
            <Bug
              onMouseDown={this.handleMouseDown}
              x={position.x}
              y={position.y}
              hidden={window.location.pathname.includes('pdf')}
              open={isChatOpen}
              onClick={() => this.handleOpenIconClick()}
            >
              <img
                alt="Wealthscope Chatbot"
                height="35px"
                width="35px"
                src={bot}
              />
            </Bug>
          ) : !isChatReminderClicked && !ui_chatbot_checked ? (
            <Popup
              className="newPopup"
              onClick={this.handleReminderClick}
              trigger={
                <Bug
                  onMouseDown={this.handleMouseDown}
                  // x={position.x}
                  // y={position.y}
                  hidden={window.location.pathname.includes('pdf')}
                  open={isChatOpen}
                  onClick={() => this.handleOpenIconClick()}
                >
                  <img
                    alt="Wealthscope Chatbot"
                    height="35px"
                    width="35px"
                    src={bot}
                  />
                </Bug>
              }
              position="bottom right"
              open={!isChatReminderClicked}
            >
              <Popup.Header>Check this out!</Popup.Header>
              <Popup.Content>
                <br />
                Try the new Wealthscope AI Assistant!
                <br />
                <br />
                <span
                  style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Got it.
                </span>
              </Popup.Content>
            </Popup>
          ) : (
            <Popup
              trigger={
                <Bug
                  onMouseDown={this.handleMouseDown}
                  x={position.x}
                  y={position.y}
                  hidden={window.location.pathname.includes('pdf')}
                  open={isChatOpen}
                  onClick={() => this.handleOpenIconClick()}
                >
                  <img
                    alt="Wealthscope Chatbot"
                    height="35px"
                    width="35px"
                    src={bot}
                  />
                </Bug>
              }
              position="top center"
              content="Ask questions about model assumptions, functionalities, and more!"
            />
          )}
        </div>
      </div>
    );
  }
}

ChatbotNav.propTypes = {
  messages: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  ui_chatbot_checked: PropTypes.bool,
};

ChatbotNav.defaultProps = {
  messages: [],
  location: {},
  ui_chatbot_checked: false,
};

export default connect(mapStateToProps)(ChatbotNav);

const Bug = styled.div`
  box-sizing: border-box !important;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  height: 100%;
  border-radius: 50%;
  z-index: 1500;
  color: ${colors.darkBlue};
  text-align: center;
  transition: all 0.3s ease;
  transform-origin: 50%;
  cursor: pointer;
  user-select: none;

  & img {
    font-size: ${(props) => (props.open ? '0.1rem' : '1.8rem')};
    margin: 0 !important;
    padding: 0;
    transition: all 0.3s ease;
  }
`;

const Content = styled.div`
  box-sizing: border-box !important;
  position: fixed;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;
  width: 500px;
  height: 75%;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background: ${colors.darkBlue};
  overflow: hidden;
  z-index: 1500;
  cursor: move;
  @media screen and (min-height: 750px) {
    height: 600px;
  }
`;

const ChatContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 2rem 2rem 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 12px 20px 0 rgb(59 105 142 / 33%);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  i:hover {
    opacity: 70%;
  }
  .close-icon:hover {
    opacity: 70%;
  }
`;

const ChatBubble = styled.div`
  align-self: ${({ role }) =>
    role !== 'assistant' ? 'flex-end' : 'flex-start'};
  background-color: ${({ role }) =>
    role !== 'assistant' ? colors.blue : 'rgb(242, 242, 242)'};
  border: ${({ role }) =>
    role !== 'assistant'
      ? `1px solid ${colors.blue}`
      : '1px solid rgb(242, 242, 242)'};
  border-radius: ${({ role }) =>
    role !== 'user' ? '20px 20px 20px 5px' : '20px 20px 5px 20px'};
  max-width: 70%;
  padding: 10px;
  margin-bottom: 10px;
  color: ${({ role }) => (role !== 'assistant' ? 'white' : 'black')};
  display: inline-block;
  word-break: break-word;
  flex-shrink: 0;
  flex-grow: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

const RoleLabel = styled.span`
  font-weight: bold;
  color: ${({ role }) => (role !== 'assistant' ? 'white' : '#595959')};
  text-transform: capitalize;
  margin-right: 5px;
`;

const InputContainer = styled.form`
  position: absolute;
  bottom: 15px;
  width: 95%;
  height: 65px;
  display: flex;
  align-items: center;
  margin: auto;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Input = styled.input`
  border: none;
  border-radius: 20px;
  margin-top: 20px;
  padding: 10px;
  flex: 1;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgb(59 105 142 / 25%);
  transition: box-shadow 0.2s ease-in-out;
  :focus {
    outline: none;
    box-shadow: 0px 3px 10px rgb(59 105 142 / 40%);
  }
`;

const SendButton = styled.button`
  margin-top: 20px;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-left: 10px;
  transition: box-shadow 0.2s ease-in-out;
  background-color: ${colors.blue};
  :hover {
    box-shadow: 0px 3px 10px rgb(59 105 142 / 40%);
  }
`;
