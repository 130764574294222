import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Form, Radio } from 'semantic-ui-react';
import cn from 'classnames';

import { AccountsDropdown } from 'components';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class AccountValues extends Component {
  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch } = this.props;

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
      ];

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };
    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (inputs, portfolios) => {
    if (inputs.isRiskFree === undefined) return false;
    if (inputs.isRiskFree) return false;
    if (inputs.portfolio === undefined) return false;

    return !portfolios.find((p) => p.id === inputs.portfolio);
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;

    if (field === 'businessStarted') {
      if (drawdown.inputs.businessStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [field]: value,
              lumpsumPayment: '0',
              lumpsumPaymentAge: '0',
            },
            errors: { lumpsumPayment: false, lumpsumPaymentAge: false },
            modified: true,
          })
        );
      } else {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [field]: value,
              lumpsumPayment: '',
              lumpsumPaymentAge: '',
            },
            errors: { lumpsumPayment: true, lumpsumPaymentAge: true },
            modified: true,
          })
        );
      }
    }

    if (field === 'isRiskFree') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [field]: value,
            pctCapGain: 0,
            divYield: 0,
            pctEligibleDiv: 0,
            ...(!value && { portfolio: drawdown.inputs.portfolio }),
          },
          errors: {
            pctCapGain: false,
            divYield: false,
            pctEligibleDiv: false,
            portfolio: false,
            constantRets: false,
          },
          modified: true,
        })
      );
    }

    if (field === 'cppStarted') {
      if (drawdown.inputs.cppStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, cppAge: '', cpp: '' },
            errors: { cppAge: false, cpp: false },
            modified: true,
          })
        );

        return this.setState({ warning: { cpp: '' } });
      }
    }

    if (field === 'oasStarted') {
      if (drawdown.inputs.oasStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, oasAge: '', oas: '' },
            errors: { oasAge: false, oas: false },
            modified: true,
          })
        );

        return this.setState({ warning: { oas: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  handleRadioChange = (e, { value, name }) => {
    this.setState({ [name]: value });
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );

    switch (name) {
      case 'initRegularFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initRegular: null, initRegAccList: [] },
            modified: true,
          })
        );
        break;
      case 'initRRIFFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initRRIF: null, initRRIFAccList: [] },
            modified: true,
          })
        );
        break;
      case 'totalTfsaFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initTFSA: null, initTFSAAccList: [] },
            modified: true,
          })
        );
        break;
      default:
    }
  };

  render() {
    const { inputs, errors } = this.props.drawdown;
    const { accountList } = this.props;

    return (
      <div>
        <Section single>
          {/* <div className="heading">Account Values</div> */}

          <div>
            <div className="title">Total non-registered (taxable)</div>
            {inputs.initRegularFromAcc ? (
              <AccountsDropdown
                inputs={inputs}
                error={errors.initRegular}
                accountList={accountList}
                selectedAccList={inputs.initRegAccList}
                excludedList={inputs.initRRIFAccList.concat(
                  inputs.initTFSAAccList
                )}
                savedAccountsValue={inputs.initRegular || 0}
                assignAccountsValue={(selectedAccounts, accountsValue) => {
                  this.props.dispatch(
                    Planning.drawdownUpdateInput({
                      inputs: {
                        initRegAccList: selectedAccounts,
                        initRegular: accountsValue,
                      },

                      errors: { initRegular: false },
                      modified: true,
                    })
                  );
                }}
                float
              />
            ) : (
              <Input
                className={cn('large', {
                  invalid: errors.initRegular ? 'true' : undefined,
                })}
                name="initRegular"
                type="number"
                placeholder="$"
                value={inputs.initRegular}
                onChange={this.handleChange}
              />
            )}
            <div style={{ margin: '0 0 0.5rem 1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name="initRegularFromAcc"
                    value={false}
                    checked={!inputs.initRegularFromAcc}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="initRegularFromAcc"
                    value
                    checked={inputs.initRegularFromAcc}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          {!inputs.isRiskFree ? (
            <ul>
              <li className="visible">
                <label>
                  Roughly what % of this value is from capital gains?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="To calculate this percentage, subtract total book costs from total market value (giving you the amount of capital gains) and divide by the total market value. We require this information as capital gains are taxed differently from dividends."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors.pctCapGain ? 'true' : undefined,
                  })}
                  name="pctCapGain"
                  type="number"
                  placeholder="%"
                  value={inputs.pctCapGain || ''}
                  onChange={this.handleChange}
                />
              </li>

              <li className="visible">
                <label>
                  What is the dividend yield?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="We assume that all dividends are withdrawn from taxable accounts for spending. The dividend yield is pre-populated given the portfolio you selected. This figure is used to calculate the dividend tax."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors.divYield ? 'true' : undefined,
                  })}
                  name="divYield"
                  type="number"
                  placeholder="%"
                  value={inputs.divYield || ''}
                  onChange={this.handleChange}
                />
              </li>

              <li className="visible">
                <label>
                  Percentage of dividends from Canadian stocks?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Canadian dividends are eligible for a tax credit."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors.pctEligibleDiv ? 'true' : undefined,
                  })}
                  name="pctEligibleDiv"
                  type="number"
                  placeholder="%"
                  value={inputs.pctEligibleDiv || ''}
                  onChange={this.handleChange}
                />
              </li>
            </ul>
          ) : null}

          <div>
            <div>
              <div className="title">
                Total Registered (RRIF/LIF)
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content="Your RRSP/Defined Contribution (DC) Plan turn into an income fund (RRIF/LIF) at retirement."
                  wide
                />
              </div>
              {inputs.initRRIFFromAcc ? (
                <AccountsDropdown
                  inputs={inputs}
                  error={errors.initRRIF}
                  accountList={accountList}
                  excludedList={inputs.initRegAccList.concat(
                    inputs.initTFSAAccList
                  )}
                  selectedAccList={inputs.initRRIFAccList}
                  savedAccountsValue={inputs.initRRIF || 0}
                  assignAccountsValue={(selectedAccounts, accountsValue) => {
                    this.props.dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          initRRIFAccList: selectedAccounts,
                          initRRIF: accountsValue,
                        },
                        errors: { initRRIF: false },
                        modified: true,
                      })
                    );
                  }}
                  float
                />
              ) : (
                <Input
                  className={cn('large', {
                    invalid: errors.initRRIF ? 'true' : undefined,
                  })}
                  name="initRRIF"
                  type="number"
                  placeholder="$"
                  value={inputs.initRRIF}
                  onChange={this.handleChange}
                />
              )}
            </div>

            <div style={{ marginLeft: '1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name="initRRIFFromAcc"
                    value={false}
                    checked={!inputs.initRRIFFromAcc}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="initRRIFFromAcc"
                    value
                    checked={inputs.initRRIFFromAcc}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          <div>
            <div>
              <div className="title">Total TFSA</div>
              {inputs.totalTfsaFromAcc ? (
                <AccountsDropdown
                  inputs={inputs}
                  error={errors.initTFSA}
                  accountList={accountList}
                  excludedList={inputs.initRegAccList.concat(
                    inputs.initRRIFAccList
                  )}
                  selectedAccList={inputs.initTFSAAccList}
                  savedAccountsValue={inputs.initTFSA || 0}
                  assignAccountsValue={(selectedAccounts, accountsValue) => {
                    this.props.dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          initTFSAAccList: selectedAccounts,
                          initTFSA: accountsValue,
                        },
                        errors: { initTFSA: false },
                        modified: true,
                      })
                    );
                  }}
                  float
                />
              ) : (
                <Input
                  className={cn('large', {
                    invalid: errors.initTFSA ? 'true' : undefined,
                  })}
                  name="initTFSA"
                  type="number"
                  placeholder="$"
                  value={inputs.initTFSA}
                  onChange={this.handleChange}
                />
              )}
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name="totalTfsaFromAcc"
                    value={false}
                    checked={!inputs.totalTfsaFromAcc}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="totalTfsaFromAcc"
                    value
                    checked={inputs.totalTfsaFromAcc}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          <ul>
            <li className="title">
              <label>
                Will you have proceeds from property/business disposition?
              </label>
              <OptionsWrapper>
                <div
                  className={cn('select', {
                    active: inputs.businessStarted,
                  })}
                  onClick={() => {
                    this.changeSelection('businessStarted', true);
                  }}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', {
                    active: !inputs.businessStarted,
                  })}
                  onClick={() => {
                    this.changeSelection('businessStarted', false);
                  }}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </li>
            <li className={cn({ visible: inputs.businessStarted })}>
              <label>How old will you be when this disposition occurs?</label>

              <Input
                style={{ marginTop: '0' }}
                className={cn('large', {
                  invalid: errors.lumpsumPaymentAge,
                })}
                name="lumpsumPaymentAge"
                type="number"
                placeholder="years"
                value={inputs.lumpsumPaymentAge || ''}
                onChange={this.handleChange}
              />
            </li>
            <li className={cn({ visible: inputs.businessStarted })}>
              <label>After-tax amount from the disposition?</label>
              <Input
                className={cn({ invalid: errors.lumpsumPayment })}
                name="lumpsumPayment"
                type="number"
                placeholder="$"
                value={inputs.lumpsumPayment || ''}
                onChange={this.handleChange}
              />
            </li>
          </ul>
        </Section>
      </div>
    );
  }
}

AccountValues.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  accountList: PropTypes.array,
};

AccountValues.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  accountList: [],
};

const Section = styled.section`
  padding-left: 2rem;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;
