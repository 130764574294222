import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';
import { StockExposure as StockAction } from 'actions';
import { Segment, SearchTicker } from 'components';
import { colors } from 'utils/colors';
import { isQuoteMedia } from 'utils/helpers';
import Showcase from './Showcase';

class StockExposure extends Component {
  state = { headerfixed: false };

  getResults = () => {
    const { search } = this.props.stockExposure;

    return search.reduce((total, security) => {
      if (security) {
        if (!total.STOCK) {
          total.STOCK = {
            name: 'STOCK',
            results: [
              {
                title: security.ticker,
                description: security.name,
                price: security.exchange,
              },
            ],
          };
        } else {
          total.STOCK.results.push({
            title: security.ticker,
            description: security.name,
            price: security.exchange,
          });
        }
      }
      return total;
    }, {});
  };

  handleResultSelect = (title) => {
    const { dispatch } = this.props;

    dispatch(StockAction.fetch(title));
    dispatch(StockAction.addPrevSearch(title));
  };

  handleSearchChange = (value) => {
    const { dispatch } = this.props;

    return (
      !!value.trim() &&
      dispatch(StockAction.search(window.encodeURIComponent(value)))
    );
  };

  handleScroll = () => {
    const { stockExposure } = this.props;

    if (!Object.keys(stockExposure).length) return;
    if (window.scrollY >= 100) {
      this.setState({ headerfixed: true });
    } else {
      this.setState({ headerfixed: false });
    }
  };

  render() {
    const { stockExposure, partnerName } = this.props;
    const { prevSearch } = stockExposure;
    const showSecurityContent =
      stockExposure &&
      stockExposure.security &&
      Object.keys(stockExposure.security.total_direct_exposures).length !== 0 &&
      stockExposure.security.total_direct_exposures !== null;
    const accountsAreEmpty =
      stockExposure &&
      stockExposure.security &&
      Object.keys(stockExposure.security.total_direct_exposures).length === 0;
    const { headerfixed } = this.state;

    return (
      <Segment expand>
        <HeaderContainer
          showSecurityContent={showSecurityContent}
          scrollYPos={window.scrollY}
          headerfixed={headerfixed}
        >
          <Header size="large" textAlign="center">
            <Header.Content>Stock Exposure Lookup</Header.Content>
            <Header.Subheader>
              {isQuoteMedia(partnerName)
                ? 'In all of your Quotestream portfolios'
                : 'In all of your Wealthscope Accounts, both linked and manually entered.'}
            </Header.Subheader>
          </Header>

          <div style={{ position: 'relative' }}>
            <SearchTicker
              loading={stockExposure.isFetching}
              placeholder="Search for ticker or name"
              results={this.getResults()}
              customResultSelect={this.handleResultSelect}
              customSearchChange={this.handleSearchChange}
              setMargin
              style={{
                display: 'inline-block',
                margin: '0.7rem',
                textAlign: 'left',
              }}
            />
          </div>
          {accountsAreEmpty ? (
            <WarningMessage>
              <Icon name="warning sign" /> It appears that your{' '}
              {isQuoteMedia(partnerName) ? 'portfolios' : 'accounts'} are empty.{' '}
              <br /> Please add holdings to your account(s) to analyze their
              stock exposure.
            </WarningMessage>
          ) : null}
        </HeaderContainer>

        {prevSearch ? (
          <ContentContainer>
            <Fade duration={3500}>
              <Showcase />
            </Fade>
          </ContentContainer>
        ) : null}
      </Segment>
    );
  }
}

StockExposure.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stockExposure: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
};
StockExposure.defaultProps = { partnerName: '' };

export default connect((state) => ({
  stockExposure: state.StockExposure,
  inputs: state.CorrelationMatrix.inputs,
  data: state.CorrelationMatrix.data,
  analytics: state.Analytics,
  user: state.Auth.user,
  prevSearch: state.StockExposure.prevSearch,
  partnerName: state.Storage['partner-name'],
}))(StockExposure);

const HeaderContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  height: 100px;
  position: absolute;
  left: 50%;
  top: 5rem;
  transform: ${(props) =>
    !props.showSecurityContent
      ? 'translate(-50%, 160%)'
      : 'translate(-50%, 0)'};
  transition: transform 1s;
  background-color: white;
  z-index: 2;
  width: 100%;
  padding-bottom: 30px;
`;

const ContentContainer = styled(
  ({ className, children, hasErrorMessage, ...rest }) => (
    <div className={className} {...rest}>
      {children}
    </div>
  )
)`
  margin-top: 190px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const WarningMessage = styled.div`
  color: ${colors.red};
  font-weight: bold;
  margin: 2rem 0 0 0;
`;
