import React, { Component } from 'react';

const HW_CONFIG_ACCOUNT = 'x8AKE7';
const ELEMENT_ID = 'headway-updates-widget';

class HeadwayWidget extends Component {
  initWidget = (element) => {
    if (element) {
      // eslint-disable-next-line no-undef
      Headway.init({
        selector: `#${ELEMENT_ID}`,
        account: HW_CONFIG_ACCOUNT,
        trigger: '#updates',
      });
    }
  };

  render() {
    return (
      <div
        style={{
          height: '10px',
          display: 'inline-block',
          overflow: 'visible',
          transform: 'translateY(-11px)',
        }}
      >
        <div id={ELEMENT_ID} ref={this.initWidget} />
      </div>
    );
  }
}

HeadwayWidget.propTypes = {};

HeadwayWidget.defaultProps = {};

export default HeadwayWidget;
