import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { BuildCustom, Portfolio, Storage } from 'actions';
import { colors, darker } from 'utils/colors';
import { Header, Segment, Card, Loading, LastUpdatedTag } from 'components';
import { EditModal, DeleteModal } from './components';

class OpenUser extends Component {
  state = {
    isOpen: false,
    selected: null,
    index: 0,
    currentTab: 'builders',
  };

  componentWillMount = () => {
    const { dispatch, portfolios, user_region } = this.props;
    dispatch(Storage.setItem('toggle_region', user_region)); // Set toggle_region to user_region to avoid interference with Accounts
    if (!portfolios.length) {
      return dispatch(Portfolio.fetchUser());
    }

    dispatch(Portfolio.setEditing('', '', ''));

    return false;
  };

  closeModal = () => this.setState({ isOpen: false });

  handleOnClick = (portfolio) => {
    const { dispatch, user_region } = this.props;

    dispatch(
      Storage.setItem('pwpa-portfolio', {
        portfolio_type:
          portfolio.portfolio_type === 'custom' ? 'custom' : 'unknown',
        region: user_region,
        symbols: portfolio.symbols,
        weights: portfolio.weights,
        gic_info: portfolio.gic_info || {},
        ...(portfolio.is_linked && { is_linked: portfolio.is_linked }),
      })
    );

    dispatch(BuildCustom.fetchId(portfolio.id));
  };

  handleOnClick = (portfolio) => {
    const { dispatch } = this.props;

    dispatch(
      Storage.setItem('pwpa-portfolio', {
        name: portfolio.name,
        description: portfolio.description,
        portfolio_type:
          portfolio.portfolio_type === 'custom' ? 'custom' : 'unknown',
        region: 'CA',
        symbols: portfolio.symbols,
        weights: portfolio.weights,
        gic_info: portfolio.gic_info || {},
        ...(portfolio.is_linked && { is_linked: portfolio.is_linked }),
      })
    );

    dispatch(BuildCustom.fetchId(portfolio.id));
  };

  pickPortfolioGroup = (currentTab, portfolios) => {
    if (portfolios.length) {
      switch (currentTab) {
        case 'builders':
          return portfolios.filter((port) => !port.is_linked);
        case 'accounts':
          return portfolios.filter((port) => port.is_linked);
        default:
          return portfolios;
      }
    }

    return portfolios;
  };

  renderTab = (currentTab) => (
    <div>
      <Tab
        onClick={() => this.setState({ currentTab: 'builders' })}
        active={currentTab === 'builders'}
      >
        From Simulations
      </Tab>
      <Tab
        onClick={() => this.setState({ currentTab: 'accounts' })}
        active={currentTab === 'accounts'}
      >
        From Portfolios
      </Tab>
    </div>
  );

  renderPortfolioCards = (portfolios) => (
    <div>
      {!portfolios.length ? (
        <div>When you save simulations, they will appear here.</div>
      ) : (
        portfolios
          .sort((a, b) =>
            a.name.localeCompare(b.name, 'en', {
              sensitivity: 'accent',
            })
          )
          .map((port) => (
            <span key={port.id} style={{ position: 'relative' }}>
              <Card
                // className={cn({ disabled: !subscription.is_subscribed && port.owner.is_me })}
                to={`/portfolio/${port.id}/overview`}
                id={port.id}
                color={colors.teal}
                hoverside={darker.teal}
                header={port.name}
                content={
                  port.description ||
                  'This simulation does not have a description.'
                }
                icon="folder open icon"
                iconColor={port.account ? 'rgba(0,0,0,0.6)' : ''}
                // owner={!port.owner.is_me ? `${port.owner.first_name} ${port.owner.last_name}` : false}
                owner={false}
                onClick={() => {
                  this.handleOnClick(port);
                  this.props.dispatch(Portfolio.notSaveUrlSc());
                }}
              >
                <Icon
                  className="ui icon pencil"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ isOpen: 'edit', selected: port });
                  }}
                  onMouseMove={this._onMouseMove}
                />
                <Icon
                  className="ui icon close"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ isOpen: 'delete', selected: port });
                  }}
                  onMouseMove={this._onMouseMove}
                />
                <LastUpdatedTag>
                  Last updated on {port.updated_on}
                </LastUpdatedTag>
              </Card>
            </span>
          ))
      )}
    </div>
  );

  render() {
    const { portfolios, isFetching } = this.props;
    const { selected, isOpen, currentTab } = this.state;

    return (
      <Segment style={{ minHeight: '550px' }}>
        <Loading active={isFetching} />
        <Header
          size="1.5rem"
          color="black"
          subheader="These are all your saved simulations."
        >
          Your Saved Portfolios
        </Header>
        <Container>
          {this.renderTab(currentTab)}
          {this.renderPortfolioCards(
            this.pickPortfolioGroup(currentTab, portfolios)
          )}
        </Container>
        <EditModal
          open={isOpen === 'edit'}
          portfolio={selected}
          close={this.closeModal}
          offsetY={Math.floor(this.state.index / 2) * 70}
        />
        <DeleteModal
          open={isOpen === 'delete'}
          portfolio={selected}
          close={this.closeModal}
          offsetY={Math.floor(this.state.index / 2) * 70}
        />
      </Segment>
    );
  }
}

OpenUser.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  portfolios: PropTypes.array,
  user_region: PropTypes.string,
};

OpenUser.defaultProps = {
  isFetching: false,
  portfolios: [],
  user_region: 'CA',
};

export default connect((state) => ({
  portfolios: state.Portfolio.user,
  isFetching: state.Portfolio.isFetching,
  editing: state.Portfolio.editing,
  user_region: state.Storage.user_region || 'CA',
}))(OpenUser);

const Icon = styled.i`
  display: inline-block;
  position: absolute;
  top: -1px;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
  margin: 0 !important;
  padding: 0 !important;
  height: 30px !important;
  width: 24px !important;
  z-index: 4;
  cursor: pointer;
  vertical-align: bottom;
  line-height: 2rem;

  &.close {
    right: 0;
    font-size: 1.3rem;

    &:hover {
      color: ${colors.red} !important;
    }
  }

  &.pencil {
    right: 22px;

    &:hover {
      color: ${colors.blue} !important;
    }
  }
`;

const Container = styled.div`
  margin: 0;
  border-radius: 8px;
  background-color: white;
`;

const Tab = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  display: inline-block;
  padding: 1rem;
  width: 145px;
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  text-align: left;
  font-weight: ${(props) => (props.active ? '700' : 'init')};
  background: ${(props) => (props.active ? 'rgb(246,246,250)' : 'init')};
  border: 1px solid rgb(236, 236, 240);
  &:first-child {
    border-top-left-radius: 8px;
    &:after {
      border-top-left-radius: 10px;
    }
  }
  &:last-child {
    border-top-right-radius: 8px;
    &:after {
      border-top-right-radius: 10px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: ${(props) => (props.active ? colors.red : 'init')};
  }
  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
`;
