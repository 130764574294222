import { BuildCustom } from 'actions';

const initialState = {
  isFetching: false,
  search: [],
  portfolio: {},
  addGicToggle: false,
  addBtcToggle: false,
  gic_info: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BuildCustom.BUILDCUSTOM_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case BuildCustom.BUILDCUSTOM_ADD_SUCCESS:
      return !action.security
        ? { ...state, isFetching: false }
        : {
            ...state,
            isFetching: false,
            portfolio: {
              ...state.portfolio,
              [action.security.ticker]: {
                ...action.security,
                weight: 0,
              },
            },
          };
    case BuildCustom.BUILDCUSTOM_LOAD_PORTFOLIO:
      return {
        ...state,
        isFetching: false,
        portfolio: action.portfolio,
        gic_info: action.gicInfo,
      };
    case BuildCustom.BUILDCUSTOM_UPDATE_WEIGHT:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          [action.ticker]: {
            ...state.portfolio[action.ticker],
            weight: action.value,
          },
        },
      };
    case BuildCustom.BUILDCUSTOM_UPDATE_GIC:
      return {
        ...state,
        gic_info: {
          ...state.gic_info,
          [action.field]: action.value,
        },
      };
    case BuildCustom.BUILDCUSTOM_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        search: action.data,
      };
    case BuildCustom.BUILDCUSTOM_REMOVE_SECURITY:
      return {
        ...state,
        isFetching: false,
        portfolio: Object.values(state.portfolio).reduce(
          (newPortfolio, item) => {
            if (item.ticker !== action.ticker) {
              newPortfolio[item.ticker] = item;
            }

            return newPortfolio;
          },
          {}
        ),
        gic_info: action.ticker === 'GIC1:MKT' ? {} : state.gic_info,
      };
    case BuildCustom.BUILDCUSTOM_UPDATE_TOGGLE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case BuildCustom.BUILDCUSTOM_REMOVE_ALL:
      return {
        ...state,
        isFetching: false,
        portfolio: {},
        gic_info: {},
      };
    default:
      return state;
  }
}
