import * as Alert from './alert';
import * as Accounts from './accounts';
import { request } from './api';

/**
 * Action constants
 */
export const CUSTOMACCOUNT_REQUEST = 'CUSTOMACCOUNT_REQUEST';
export const CUSTOMACCOUNT_CREATE_ACCOUNT = 'CUSTOMACCOUNT_CREATE_ACCOUNT';
export const CUSTOMACCOUNT_FETCH_ID = 'CUSTOMACCOUNT_FETCH_ID';

export const CUSTOMACCOUNT_SEARCH_SUCCESS = 'CUSTOMACCOUNT_SEARCH_SUCCESS';
export const CUSTOMACCOUNT_ADD_SUCCESS = 'CUSTOMACCOUNT_ADD_SUCCESS';
export const CUSTOMACCOUNT_UPDATE = 'CUSTOMACCOUNT_UPDATE';
export const CUSTOMACCOUNT_REMOVE_SECURITY = 'CUSTOMACCOUNT_REMOVE_SECURITY';
export const CUSTOMACCOUNT_SAVE_SECURITY = 'CUSTOMACCOUNT_SAVE_SECURITY';
export const CUSTOMACCOUNT_REMOVE_ALL = 'CUSTOMACCOUNT_REMOVE_ALL';
export const CUSTOMACCOUNT_UPDATE_CASH = 'CUSTOMACCOUNT_UPDATE_CASH';
export const CUSTOMACCOUNT_UPDATE_GIC = 'CUSTOMACCOUNT_UPDATE_GIC';

export const CUSTOMACCOUNT_HOLDING_REQUEST = 'CUSTOMACCOUNT_HOLDING_REQUEST';
export const CUSTOMACCOUNT_HOLDING_UPDATE = 'CUSTOMACCOUNT_HOLDING_UPDATE';
export const CUSTOMACCOUNT_HOLDING_FAIL = 'CUSTOMACCOUNT_HOLDING_FAIL';
export const CUSTOMACCOUNT_CLEAR = 'CUSTOMACCOUNT_CLEAR';
/**
 * Action creators
 */
export function createAccount(params) {
  return (dispatch) => {
    dispatch({ type: CUSTOMACCOUNT_REQUEST });

    return dispatch(request('post', '/accounts/', { body: params })).then(
      (data) => {
        dispatch({ type: CUSTOMACCOUNT_CREATE_ACCOUNT, account: data });
        dispatch(Accounts.fetchAll());
        return data;
      },
      (error) => {
        dispatch({ type: CUSTOMACCOUNT_FETCH_ID, account: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchId(id) {
  return (dispatch) => {
    dispatch({ type: CUSTOMACCOUNT_REQUEST });

    return dispatch(request('get', `/accounts/${id}/`)).then(
      (data) => dispatch({ type: CUSTOMACCOUNT_FETCH_ID, account: data }),
      (error) => {
        dispatch({ type: CUSTOMACCOUNT_FETCH_ID, account: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function add(id) {
  return (dispatch) => {
    dispatch({ type: CUSTOMACCOUNT_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) => dispatch({ type: CUSTOMACCOUNT_ADD_SUCCESS, security: data }),
      (error) => {
        dispatch({ type: CUSTOMACCOUNT_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function saveBulk(params) {
  return (dispatch) => {
    dispatch({ type: CUSTOMACCOUNT_HOLDING_REQUEST });

    return dispatch(
      request('post', '/accounts/holdings/bulk/', { body: params })
    ).then(
      () => {
        dispatch({ type: CUSTOMACCOUNT_HOLDING_UPDATE });
      },
      (error) =>
        dispatch({ type: CUSTOMACCOUNT_HOLDING_FAIL, msg: parseErr(error) })
    );
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: CUSTOMACCOUNT_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: CUSTOMACCOUNT_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: CUSTOMACCOUNT_SEARCH_SUCCESS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function update(field, ticker, value) {
  return {
    type: CUSTOMACCOUNT_UPDATE,
    field,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function updateCash(value) {
  return {
    type: CUSTOMACCOUNT_UPDATE_CASH,
    value: value === '' ? '' : Number(value),
  };
}

export function updateGic(field, value) {
  return {
    type: CUSTOMACCOUNT_UPDATE_GIC,
    field,
    value,
  };
}

export function remove(ticker) {
  return {
    type: CUSTOMACCOUNT_REMOVE_SECURITY,
    ticker,
  };
}

export function removeAll() {
  return {
    type: CUSTOMACCOUNT_REMOVE_ALL,
  };
}

export function clearCustomAccount() {
  return { type: CUSTOMACCOUNT_CLEAR };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
