import { Chatbot } from 'actions';

const initialState = {
  messages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Chatbot.SEND_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'sent',
            role: 'user',
            content: action.question,
          },
        ],
      };

    case Chatbot.RECEIVE_ANSWER:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'received',
            role: 'assistant',
            content: action.answer,
          },
        ],
      };

    case Chatbot.CLEAR:
      return {
        ...state,
        messages: [],
      };

    default:
      return state;
  }
}
