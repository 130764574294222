import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { CustomAccount } from 'actions';
import { ErrorLabel } from 'components';
import { translateText } from '../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
class Quantity extends Component {
  state = {
    focused: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = (e) => {
    const { dispatch, ticker, value, field } = this.props;

    // Firefox issue
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return false;
    }

    if (value === '') {
      dispatch(CustomAccount.update(field, ticker, 0));
    }

    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;

    e.target.value = '';

    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  updateQuantity = (e, { name, value }) => {
    const { dispatch, field } = this.props;
    const num = Number(value);
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);
    const roundNum =
      value === ''
        ? ''
        : Number.isInteger(num)
        ? num
        : value.split('.')[1].length >= 2
        ? Number(
            `${value.split('.')[0]}.${value.split('.')[1].substring(0, 2)}`
          )
        : num;

    if (negative) {
      dispatch(CustomAccount.update(field, name, null));
    }

    this.setState({ focused: true });

    return dispatch(CustomAccount.update(field, name, roundNum));
  };

  focus = () => {
    const { value, ticker, dispatch, field } = this.props;

    if (!value) {
      dispatch(CustomAccount.update(field, ticker, ''));
    }

    return this.setState({ focused: true });
  };

  render() {
    const { ticker, value, field, isMarketValue, french } = this.props;
    const { focused } = this.state;

    return focused ? (
      <Input
        name={ticker}
        size="mini"
        type="number"
        value={value}
        onChange={this.updateQuantity}
        style={{ maxWidth: 60 }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    ) : (
      <Value onClick={this.focus}>
        {field === 'book_value' || isMarketValue ? `$${value || 0}` : value}
        {field === 'quantity' || field === 'marketValue' ? (
          <ErrorLabel
            msg={
              !value
                ? isMarketValue
                  ? translateText('Update market value', french)
                  : translateText('Update quantity', french)
                : null
            }
          />
        ) : null}
      </Value>
    );
  }
}

Quantity.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticker: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isMarketValue: PropTypes.bool,
  french: PropTypes.bool,
};

Quantity.defaultProps = {
  isMarketValue: false,
  french: false,
};

export default connect()(Quantity);

const Value = styled.span`
  position: relative;
  cursor: pointer;
  color: #2185d0;
  padding: 11px 5px;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;
