import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { NotFound, Loading } from 'components';
import { Analytics as AnalyticsActions, Accounts } from 'actions';
import Menu from './Menu';
import { translateText } from '../../../utils/helpers';

class Analytics extends Component {
  componentWillMount() {
    const {
      history,
      location,
      dispatch,
      match,
      pwpaPortfolio,
      pwpaCheckHistory,
      toggle_region,
      user_region,
    } = this.props;

    const checkHistory = pwpaCheckHistory === null ? false : !pwpaCheckHistory;

    const decideRegion = () => {
      if (user_region === 'US') {
        return 'US';
      }
      return toggle_region || 'CA';
    };

    if (Number(match.params.id)) {
      return dispatch(
        AnalyticsActions.fetchId(match.params.id, decideRegion())
      );
    }

    if (pwpaPortfolio) {
      return dispatch(
        AnalyticsActions.fetchParams(
          pwpaPortfolio,
          location.state,
          checkHistory,
          decideRegion()
        )
      );
    }

    return history.push('/');
  }

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (
      !history.location.pathname.includes('/accounts/') &&
      !history.location.pathname.includes('/pdf')
    ) {
      dispatch(Accounts.toggleMultipleMode());
    }
  }

  renderError = (data) => {
    const { french } = this.props;
    return data.detail ? (
      <Error>
        {french ? "L'analyse a échoué." : 'Analysis failed'}.{' '}
        {translateText(data.detail, french)}.
        <Link to="/accounts/overview" className="close">
          +
        </Link>
      </Error>
    ) : (
      <Error>
        {french
          ? 'Désolé, tous les titres de votre portefeuille sont trop récents pour être analysés.'
          : 'Sorry, all securities in your portfolio are too new to be analyzed.'}
        <Link to="/accounts/overview" className="close">
          +
        </Link>
      </Error>
    );
  };

  render() {
    const { match, analytics } = this.props;

    if (!analytics.data || analytics.isFetching) {
      return <Loading active fixed />;
    }

    if (analytics.data.detail === 'Not found.') {
      return <NotFound />;
    }

    return !analytics.data.score ? (
      this.renderError(analytics.data)
    ) : (
      <Menu id={match.params.id} />
    );
  }
}

Analytics.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  analytics: PropTypes.object,
  pwpaPortfolio: PropTypes.object,
  pwpaCheckHistory: PropTypes.bool,
  toggle_region: PropTypes.string,
  user_region: PropTypes.string,
  french: PropTypes.bool,
};

Analytics.defaultProps = {
  analytics: {},
  pwpaPortfolio: null,
  pwpaCheckHistory: null,
  toggle_region: 'CA',
  user_region: 'CA',
  french: false,
};

export default connect((state) => ({
  analytics: state.Analytics,
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
  pwpaCheckHistory: state.Storage['pwpa-check-history'],
  toggle_region: state.Storage.toggle_region || 'CA',
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))(Analytics);

const Error = styled.div`
  position: relative;
  width: 400px;
  background: white;
  margin: 4rem auto;
  text-align: center;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 8px;
  font-size: 1.4rem;
  box-shadow: 0 0 3px dimgrey;

  .close {
    position: absolute;
    color: black;
    top: 4px;
    right: 4px;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 1.7rem;
    border-radius: 50%;
    line-height: 1.4rem;
    transform: rotate(45deg);
    transition: all 0.1s ease;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
      color: #fff6f6;
    }
  }
`;
