import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Icon, Header, Grid, Image, Statistic } from 'semantic-ui-react';

import { Segment } from 'components';
import Fifty from 'images/50.png';
import SeventyFive from 'images/75.png';
import Ninety from 'images/90.png';

class WealthResult extends Component {
  renderAnnuity = (result) => (
    <Segment textAlign="center">
      <div>
        <Header
          content="Target Wealth at Retirement"
          style={{ margin: '0', display: 'inline-block' }}
        />
      </div>
      <Header.Subheader>
        Using a portfolio for your post-retirement savings that is risk-free
        (i.e. fixed return), how much you need to have saved by your retirement
        age is a certain amount.
      </Header.Subheader>
      <Statistic size="mini">
        <Statistic.Label>Target Wealth</Statistic.Label>
        <Statistic.Value>
          {`$${result.Annuity.toLocaleString()}`}{' '}
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ color: 'dimgrey' }}
              />
            }
            content="Future (Nominal) Value at Retirement"
            wide
            position="top center"
          />
        </Statistic.Value>
      </Statistic>
    </Segment>
  );

  renderWealth = (result) => (
    <Segment textAlign="center">
      <div>
        <Header
          content="Target Wealth at Retirement"
          style={{ margin: '0', display: 'inline-block' }}
        />
      </div>
      <Header.Subheader>
        Since the portfolio you picked for your post-retirement years has an
        uncertain outcome, how much you need to have saved by your retirement
        age is associated with a probability. We generate the results based on
        10,000 simulations.
      </Header.Subheader>
      <Grid style={{ margin: '1rem 0 0 0' }}>
        <Grid.Row>
          <Grid.Column>
            <div>
              <LessHeader>Likelihood of Success</LessHeader>
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{ color: 'dimgrey' }}
                  />
                }
                position="right center"
                content="The probability of being able to sustain your net spending needs in retirement."
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Image src={Fifty} style={{ width: '70%', margin: '0 auto' }} />
            <Statistic size="mini">
              <Statistic.Label>Target Wealth</Statistic.Label>
              <Statistic.Value>
                {Object.keys(result).length
                  ? `$${result['0.5'].toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}`
                  : '--'}{' '}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ color: 'dimgrey' }}
                    />
                  }
                  content="Future (Nominal) Value at Retirement"
                  wide
                  position="top center"
                />
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Image
              src={SeventyFive}
              style={{ width: '70%', margin: '0 auto' }}
            />
            <Statistic size="mini">
              <Statistic.Label>Target Wealth</Statistic.Label>
              <Statistic.Value>
                {Object.keys(result).length
                  ? `$${result['0.75'].toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}`
                  : '--'}{' '}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ color: 'dimgrey' }}
                    />
                  }
                  content="Future (Nominal) Value at Retirement"
                  wide
                  position="top center"
                />
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column>
            <Image src={Ninety} style={{ width: '70%', margin: '0 auto' }} />
            <Statistic size="mini">
              <Statistic.Label>Target Wealth</Statistic.Label>
              <Statistic.Value>
                {Object.keys(result).length
                  ? `$${result['0.9'].toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}`
                  : '--'}{' '}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ color: 'dimgrey' }}
                    />
                  }
                  content="Future (Nominal) Value at Retirement"
                  wide
                  position="top center"
                />
              </Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );

  render() {
    const { result } = this.props;

    if (!result) {
      return null;
    }

    return result.Annuity
      ? this.renderAnnuity(result)
      : this.renderWealth(result);
  }
}

WealthResult.propTypes = {
  result: PropTypes.object,
};

WealthResult.defaultProps = {
  result: null,
};

export default connect((state) => ({
  result: state.Planning.wealthResult,
}))(WealthResult);

const LessHeader = styled.div`
  display: inline-block;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.2em;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 64%;
    background-color: rgba(0, 0, 0, 0.4);
    left: 18%;
  }
`;
