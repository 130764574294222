import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { Radar, Line } from 'react-chartjs-2';

import {
  Divider,
  Statistic,
  Header,
  Grid,
  Icon,
  Popup,
  Button as SemanticButton,
} from 'semantic-ui-react';
import cn from 'classnames';

import { Analytics, ComparePortfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import {
  getOverAllScore,
  getOverAllGrade,
  decideCurrency,
  isGlobeInvestor,
} from 'utils/helpers';
import { displayLineChartDots, marginOfError } from 'utils/constants';
import { withRouter } from 'react-router-dom';
import { Table, Button } from 'components';
import SaveModal from '../Menu/SaveModal';

class Result extends Component {
  state = {
    id: null,
    saveModalOpen: false,
  };

  componentDidMount = () => {
    const { esgToggle, comparePortfolioState } = this.props;

    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    if (esgToggle) {
      const { analysis1, analysis2 } = comparePortfolioState.compareResult;

      this.fetchEsg(
        {
          symbols: analysis1.paramsAnalyzed.symbols.join(','),
          weights: analysis1.paramsAnalyzed.weights.join(','),
          gic_info: comparePortfolioState.original.gic_info,
        },
        {
          symbols: analysis2.paramsAnalyzed.symbols.join(','),
          weights: analysis2.paramsAnalyzed.weights.join(','),
          gic_info: comparePortfolioState.original.gic_info,
        }
      );
    }
  };

  componentWillUnmount = () => {
    const { dispatch, history } = this.props;
    const portfolioRegex = /^\/portfolio\/.*$/;

    if (!portfolioRegex.test(history.location.pathname)) {
      dispatch(ComparePortfolio.clearComparison());
      dispatch(Analytics.clearAnalytics());
    }
  };

  getPortfoliosAssociatedAccountName = () => {
    const { analytics } = this.props;
    if (analytics.data.paramsPassed.account)
      if (!analytics.data.paramsPassed.account.label)
        return `${analytics.data.paramsPassed.account.user_institution || ''}`;
      else
        return `${
          analytics.data.paramsPassed.account.user_institution || ''
        }: ${analytics.data.paramsPassed.account.label || ''}`;

    return null;
  };

  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0 %');

  getRadarData = (port1, port2, esg1, esg2, port1Name, port2Name) => {
    const { analytics, esgToggle } = this.props;
    const { benchmark } = analytics;
    const hash = {
      Diversification: 'Diversification',
      Fees: 'Low Fees',
      Income: 'Income',
      Performance: 'Performance',
    };
    const portScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port1.score[key][benchmark][0]
          : port1.score[key][0];

      return total;
    }, {});
    const compareScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port2.score[key][benchmark][0]
          : port2.score[key][0];

      return total;
    }, {});

    return {
      labels: esgToggle
        ? ['Downside Protection', ...Object.keys(portScores), 'ESG']
        : ['Downside Protection', ...Object.keys(portScores)],
      datasets: [
        {
          label: 'Current portfolio',
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data:
            esgToggle && esg1 && esg1.portfolio.esg_score
              ? [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                  esg1.portfolio.ws_grade[0] || 0,
                ]
              : [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                ],
        },
        {
          label: port2Name,
          backgroundColor: 'rgba(255,184,182,0.5)',
          borderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointBorderColor: colors.red,
          lineTension: 0.3,
          data:
            esgToggle && esg2 && esg2.portfolio.esg_score
              ? [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                  esg2.portfolio.ws_grade[0] || 0,
                ]
              : [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                ],
        },
      ],
    };
  };

  getChartData = (port1, port2, port1Name, port2Name) => ({
    labels: Object.keys(port1.cumrets.portfolio),
    datasets: [
      {
        data: Object.values(port1.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: 'Current Portfolio',
        lineTension: 0,
        fill: false,
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        pointBackgroundColor: colors.blue,
        pointBorderColor: colors.blue,
        pointRadius:
          Object.keys(port1.cumrets.portfolio).length > displayLineChartDots
            ? 0
            : 2,
        pointHitRadius: 5,
      },
      {
        data: Object.values(port2.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: port2Name,
        lineTension: 0,
        fill: false,
        backgroundColor: colors.red,
        borderColor: colors.red,
        pointBackgroundColor: colors.red,
        pointBorderColor: colors.red,
        pointRadius:
          Object.keys(port2.cumrets.portfolio).length > displayLineChartDots
            ? 0
            : 2,
        pointHitRadius: 5,
      },
    ],
  });

  getPortfolios = (portfolios) => {
    const { id } = this.state;

    return portfolios.reduce((total, portfolio) => {
      if (portfolio) {
        total.push({
          key: portfolio.id,
          value: portfolio.id,
          selected: portfolio.id === id,
          icon: portfolio.is_global ? (
            <Icon name="briefcase" style={{ color: colors.red }} />
          ) : (
            'briefcase'
          ),
          text: portfolio.name,
        });
      }

      return total;
    }, []);
  };

  getNonTypicalWeight = (ticker) => {
    const { comparePortfolioState } = this.props;
    const nonTypicalTicker = Object.values(
      comparePortfolioState.portfolio
    ).find((port) => port.ticker === ticker);

    return nonTypicalTicker ? nonTypicalTicker.weight : null;
  };

  decideRegion = () => {
    if (this.props.user_region === 'US') {
      return 'US';
    } else if (this.props.toggle_region === 'US') {
      return 'US';
    }
    return 'CA';
  };

  analyzeWhatIf = () => {
    const { history, comparePortfolioState, dispatch, location, analytics } =
      this.props;
    const gicFormat = /^GIC\d*:MKT$/;
    Storage.removeItem('pwpa-has-exclude-holding');
    const params = Object.values(comparePortfolioState.portfolio).reduce(
      (total, item) => {
        if (
          (item.weight && gicFormat.test(item.ticker)) ||
          (item.weight && item.ticker === '~BTCUSDT') ||
          (item.weight && item.ticker === '~ETHUSDT') ||
          (item.weight &&
            item.unadjusted_closing_price &&
            item.unadjusted_closing_price.CAD)
        ) {
          const weight = item.weight / 100;
          total.symbols.push(item.ticker);
          total.weights.push(weight);
        }

        return total;
      },
      { symbols: [], weights: [] }
    );

    const rebalanceInterval = analytics.data.paramsPassed.rebalance_interval;
    const myPortfolio = {
      portfolio_type: 'custom',
      region: this.decideRegion(),
      symbols: params.symbols.join(','),
      weights: params.weights.join(','),
      ...(comparePortfolioState.gic_info &&
        Object.keys(comparePortfolioState.gic_info).length && {
          gic_info: comparePortfolioState.gic_info,
        }),
      is_linked: analytics.data.paramsPassed.is_linked,
    };

    dispatch(Storage.setItem('pwpa-portfolio', myPortfolio));

    dispatch(
      Analytics.fetchParams(
        myPortfolio,
        location.state,
        null,
        this.decideRegion(),
        rebalanceInterval
      )
    );

    return history.push('/portfolio/unsaved/overview');
  };

  isDisabled = () => {
    const { comparePortfolioState } = this.props;
    const { portfolio, gic_new_generic_ticker, gic_info } =
      comparePortfolioState;
    const { gic_rate, gic_ticker } = gic_new_generic_ticker
      ? gic_info[gic_new_generic_ticker] || {}
      : {};

    const totalWeight = Object.values(portfolio)
      .reduce((total, value) => (total += Number(value.weight)), 0)
      .toFixed(2);

    const gicWeight = this.getNonTypicalWeight(gic_new_generic_ticker);

    return (
      ((gicWeight || gic_rate || gic_ticker) &&
        !(gicWeight && gic_rate && gic_ticker)) ||
      totalWeight < 100 - marginOfError ||
      totalWeight > 100 + marginOfError
    );
  };

  closeModal = () => this.setState({ saveModalOpen: false });

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  submit = () => {
    const { portfolios, dispatch, analytics, esgToggle, user_region } =
      this.props;
    const { data } = analytics;
    const portfolio = portfolios.find((port) => port.id === this.state.id);
    const params1 = {
      ...data.portfolio,
    };
    const params2 = {
      ...portfolio,
    };

    if (esgToggle) {
      this.fetchEsg(params1, params2);
    }

    return dispatch(Analytics.fetchCompare(params1, params2, user_region));
  };

  fetchEsg = (port1, port2) => {
    const { dispatch } = this.props;

    return dispatch(Analytics.fetchCompareEsg(port1, port2));
  };

  parseDate = () => {
    const { comparePortfolioState } = this.props;
    const { analysis1 } = comparePortfolioState.compareResult;
    const { init_date, end_date } = analysis1.paramsAnalyzed;
    const init = init_date.split('-');
    const end = end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  generatePDF = () => {
    const { match, history } = this.props;

    return history.push(`/portfolio/${match.params.id}/compare/pdf`);
  };

  displayEsg = (esg) => {
    if (!esg) return '-';

    const { portfolio } = esg;

    if (!portfolio.esg_score) return '-';

    return !portfolio.esg_score ? '-' : portfolio.esg_score;
  };

  esgCoverage = (esg) => {
    if (!esg) return '(0%)';

    const { portfolio } = esg;
    const weight = (portfolio.weight * 100).toFixed(2);

    if (!weight || !Number(weight)) return '(0%)';

    const convertWeight = weight.split('.');

    if (convertWeight[1][0] === '0' && convertWeight[1][1] === '0')
      return `(${convertWeight[0]}%)`;
    if (convertWeight[1][1] === '0')
      return `(${convertWeight[0]}.${convertWeight[1][0]}%)`;

    return `(${weight}%)`;
  };

  formatDataForSaving = () => {
    const { comparePortfolioState } = this.props;
    const { portfolio, gic_info } = comparePortfolioState;
    const { analysis2 } = comparePortfolioState.compareResult;
    const { paramsAnalyzed } = analysis2;

    const symbols = Object.keys(portfolio)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const weights = Object.keys(portfolio)
      .map((ticker) => (portfolio[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);

    return {
      ...paramsAnalyzed,
      portfolio_type: 'custom',
      gic_info, // need to use this info from compareportfolio due to the renaming of the gic tickers at the time of comparison
      symbols,
      weights,
      check_history: !!analysis2.check_history, // use the check_history value that was used in the comparison
    };
  };

  renderSubheader = () => (
    <div className="sub header">
      Comparison Period: {this.parseDate()}
      <Popup
        trigger={
          <Icon
            name="info circle"
            style={{ fontSize: '15px', verticalAlign: 'initial' }}
          />
        }
        position="bottom center"
        wide
      >
        The comparison period is set to the longest common history of the two
        portfolios you are comparing. Therefore, the scores of the current
        portfolio may change, as its history may now be shorter.
      </Popup>
    </div>
  );

  render() {
    const {
      analytics,
      user_region,
      comparePortfolioState,
      dispatch,
      setView,
      partnerName,
    } = this.props;
    const { benchmark, income, compareEsg, esgToggle } = analytics;
    const { analysis1, analysis2 } = comparePortfolioState.compareResult;
    const { isWhatIf } = comparePortfolioState;
    const { portfolio1esg, portfolio2esg } = compareEsg;
    let portOneName = comparePortfolioState.original.name;
    const portTwoName = comparePortfolioState.name;

    // if portOneName is null, check to see if this portfolio is an account that was analyzed
    // if it is, set the name to '<account.user_institution>: <account.label>'
    portOneName = portOneName || this.getPortfoliosAssociatedAccountName();
    const compareResultsUseSameBenchmark =
      JSON.stringify(analysis1.fratios[benchmark]) ===
      JSON.stringify(analysis2.fratios[benchmark]);

    return (
      <Fade>
        <StyledDiv>
          {analysis1 && analysis2 && portTwoName === 'What-if' && (
            <Popup
              position="right center"
              trigger={
                <SemanticButton
                  className="back"
                  onClick={() => {
                    dispatch(ComparePortfolio.setSnapshotLoad(true));
                    // dispatch(ComparePortfolio.loadSnapshotAfterReplace());
                    // dispatch(ComparePortfolio.backWhatIf());
                    setView('WhatIfCustomization');
                  }}
                >
                  Back
                </SemanticButton>
              }
              content="Return to What-If Editor"
            />
          )}
          {analysis1 && !analysis2 ? (
            <Error>
              Sorry, all securities in one of your portfolio are too new to be
              analyzed.
            </Error>
          ) : analysis1 && analysis2 ? (
            <Container centered>
              <Header
                id="result-vs-header"
                size="large"
                content={`Current Portfolio ${
                  portOneName ? `(${portOneName})` : ''
                } vs. ${portTwoName}`}
                subheader={this.renderSubheader()}
                style={{ marginTop: 0 }}
              />
              <Grid style={{ margin: '0 0 3rem 0' }}>
                <Grid.Row columns={3}>
                  <Grid.Column width={4}>
                    <Statistic
                      size="small"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Grade color={colors.blue}>
                        {getOverAllGrade({
                          income,
                          benchmark,
                          esgToggle,
                          esg: portfolio1esg,
                          data: analysis1,
                        })}
                      </Grade>
                      <Statistic.Label>Current portfolio score</Statistic.Label>
                      <Statistic.Value>
                        {getOverAllScore({
                          income,
                          benchmark,
                          esgToggle,
                          esg: portfolio1esg,
                          data: analysis1,
                        })}
                      </Statistic.Value>
                    </Statistic>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Radar
                      id="radarChart"
                      data={this.getRadarData(
                        analysis1,
                        analysis2,
                        portfolio1esg,
                        portfolio2esg,
                        portOneName || 'Current Portfolio',
                        portTwoName
                      )}
                      options={radarOptions}
                      height={250}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Statistic
                      size="small"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Grade color={colors.red}>
                        {getOverAllGrade({
                          income,
                          benchmark,
                          esgToggle,
                          esg: portfolio2esg,
                          data: analysis2,
                        })}
                      </Grade>
                      <Statistic.Label>{portTwoName} score</Statistic.Label>
                      <Statistic.Value>
                        {getOverAllScore({
                          income,
                          benchmark,
                          esgToggle,
                          esg: portfolio2esg,
                          data: analysis2,
                        })}
                      </Statistic.Value>
                    </Statistic>
                    {portTwoName === 'What-if' && (
                      <Popup
                        position="left center"
                        content="Analyze your What-If Portfolio"
                        trigger={
                          <Button
                            className={cn('left centered', {
                              disabled: this.isDisabled(),
                            })}
                            style={{
                              position: 'absolute',
                              top: '70%',
                              left: 0,
                              right: 0,
                              transform: 'translateY(-50%)',
                              width: '8rem',
                            }}
                            color={colors.darkBlue}
                            icon="line chart"
                            onClick={this.analyzeWhatIf}
                          >
                            Analyze
                          </Button>
                        }
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Header>
                <Header.Content>Key Statistics</Header.Content>
                <Header.Subheader>
                  {this.parseDate()} (inclusive) in{' '}
                  {decideCurrency(analytics.data, user_region || 'CA')}
                </Header.Subheader>
              </Header>

              <Divider />

              <Table basic textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4} />
                    <Table.HeaderCell width={3}>
                      Annualized Return
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Sharpe Ratio&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="This ratio measures the 'reward' of the portfolio per unit of 'risk', where reward is the average return of the portfolio over and above the risk-free rate, and risk is the standard deviation (or volatility) of the portfolio. The risk-free rate we use is the 12-month Treasury bill yield."
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Roy&#8217;s Safety First Criterion&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                      >
                        Like the Sharpe Ratio, this ratio measures the
                        portfolio&#8217;s reward per unit of risk. However, the
                        reward is defined as the portfolio&#8217;s average
                        return over your target return (or minimum acceptable
                        rate), instead of the risk-free rate. Currently, the
                        target return is set to zero, i.e., money preservation
                        (not losing money in nominal terms).
                      </Popup>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      Sortino Ratio&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="left center"
                        wide
                      >
                        This ratio measures the portfolio&#8217;s reward per
                        unit of downside risk. The reward is the
                        portfolio&#8217;s average return over your target return
                        (or minimum acceptable rate). Downside risk is the
                        volatility of the portfolio returns below your target.
                        Currently, the target return is set to zero, i.e., money
                        preservation (not losing money in nominal terms).
                      </Popup>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell width={4}>Current Portfolio</Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis1.fratios.portfolio.annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios.portfolio.sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios.portfolio.roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios.portfolio.sortino)}
                    </Table.Cell>
                  </Table.Row>
                  {!compareResultsUseSameBenchmark && (
                    <Table.Row>
                      <Table.Cell width={4}>
                        {analysis1.fratios[benchmark].name}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content={analysis1.fratios[benchmark].description}
                          wide
                        />
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(
                          analysis1.fratios[benchmark].annualizedRet
                        )}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.formatNum(analysis1.fratios[benchmark].sharpe)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.formatNum(analysis1.fratios[benchmark].roys)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.formatNum(analysis1.fratios[benchmark].sortino)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row positive>
                    <Table.Cell width={4}>{portTwoName}</Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis2.fratios.portfolio.annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios.portfolio.sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios.portfolio.roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios.portfolio.sortino)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {analysis2.fratios[benchmark].name}
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={analysis2.fratios[benchmark].description}
                        wide
                      />
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis2.fratios[benchmark].annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios[benchmark].sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios[benchmark].roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios[benchmark].sortino)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />

              <Table basic textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>
                      Total Risk&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="This is the variability of your portfolio's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk."
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Downside Risk&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="This is the variability of your portfolio's returns below a target. The target used here is capital preservation; that is, a non-negative return."
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Maximum Drawdown&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="This is the maximum peak to trough loss observed during the period in question. It gives a different perspective of a portfolio's downside risk."
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Downside Capture&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="left center"
                        content="This measures whether your portfolio lost less than the stock market (as selected) when there was a market downturn. A downside capture of less than 100% indicates that your portfolio lost less than the stock market during those periods. A downside capture that is negative indicates that your portfolio actually generated positive returns when the stock market was in the red."
                        wide
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell width={4}>Current Portfolio</Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.fratios.portfolio.stdDev)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.fratios.portfolio.dsr)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.fratios.portfolio.maxDd)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis1.fratios.portfolio.dscapture[benchmark]
                      )}
                    </Table.Cell>
                  </Table.Row>
                  {!compareResultsUseSameBenchmark && (
                    <Table.Row>
                      <Table.Cell width={4}>
                        {analysis1.fratios[benchmark].name}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content={analysis1.fratios[benchmark].description}
                          wide
                        />
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(analysis1.fratios[benchmark].stdDev)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(analysis1.fratios[benchmark].dsr)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(analysis1.fratios[benchmark].maxDd)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(
                          analysis1.fratios[benchmark].dscapture
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row positive>
                    <Table.Cell width={4}>{portTwoName}</Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios.portfolio.stdDev)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios.portfolio.dsr)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios.portfolio.maxDd)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis2.fratios.portfolio.dscapture[benchmark]
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {analysis2.fratios[benchmark].name}
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={analysis2.fratios[benchmark].description}
                        wide
                      />
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].stdDev)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].dsr)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].maxDd)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].dscapture)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />

              <Table basic textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell width={3}>
                      Fees&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="Weighted average product fees (MERs)"
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Income&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="Weighted average distributions"
                        wide
                      />
                    </Table.HeaderCell>
                    {esgToggle ? (
                      <Table.HeaderCell>
                        ESG Coverage&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content="Portfolio average ESG score and percentage of holdings that have ESG ratings (in brackets)."
                          wide
                        />
                      </Table.HeaderCell>
                    ) : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell width={4}>Current Portfolio</Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.portMER)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.portYield)}
                    </Table.Cell>

                    {esgToggle ? (
                      <Table.Cell width={3}>
                        {this.displayEsg(portfolio1esg)}&nbsp;
                        {this.esgCoverage(portfolio1esg)}
                      </Table.Cell>
                    ) : (
                      <Table.Cell width={3} />
                    )}
                    <Table.Cell width={3} />
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>{portTwoName}</Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.portMER)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.portYield)}
                    </Table.Cell>

                    {esgToggle ? (
                      <Table.Cell width={3}>
                        {this.displayEsg(portfolio2esg)}&nbsp;
                        {this.esgCoverage(portfolio2esg)}
                      </Table.Cell>
                    ) : (
                      <Table.Cell width={3} />
                    )}
                    <Table.Cell width={3} />
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />

              <Header>
                <Header.Content>Hypothetical Portfolio History</Header.Content>
                <Header.Subheader>
                  Based on the current composition of your portfolio&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    content="Using the same holdings and the same weights."
                    wide
                  />
                </Header.Subheader>
                <Header.Subheader>
                  {this.parseDate()} (inclusive) in{' '}
                  {decideCurrency(analytics.data, user_region || 'CA')}
                </Header.Subheader>
              </Header>
              <Note>Total Returns including Distributions</Note>
              <Line
                data={this.getChartData(
                  analysis1,
                  analysis2,
                  portOneName || 'Current Portfolio',
                  portTwoName
                )}
                options={chartOptions}
              />
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  justifyContent: 'center',
                  gap: '1rem',
                }}
              >
                <Button
                  className={cn('left')}
                  icon="file text"
                  color={colors.darkGrey}
                  onClick={this.generatePDF}
                  style={{ marginTop: '30px' }}
                >
                  Print/PDF
                </Button>
                {isWhatIf && !isGlobeInvestor(partnerName) ? (
                  <Button
                    className={cn('left')}
                    icon="save outline"
                    color={colors.red}
                    onClick={() => this.setState({ saveModalOpen: true })}
                    style={{ marginTop: '30px' }}
                  >
                    Save <span style={{ fontStyle: 'italic' }}>What-if</span> As
                    a New Portfolio
                  </Button>
                ) : null}
              </div>
            </Container>
          ) : null}
        </StyledDiv>
        <SaveModal
          closeModal={this.closeModal}
          open={this.state.saveModalOpen}
          portfolio={this.formatDataForSaving()}
          redirectToSavedPortfolioOnSuccess={false}
          forceSaveAsNewPortfolio
        />
      </Fade>
    );
  }
}

Result.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  history: PropTypes.object.isRequired,
  comparePortfolioState: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  esgToggle: PropTypes.bool,
  analytics: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  setView: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
  location: PropTypes.object.isRequired,
  toggle_region: PropTypes.string,
};

Result.defaultProps = {
  portfolios: [],
  esgToggle: false,
  user_region: 'CA',
  partnerName: '',
  toggle_region: 'CA',
};

export default withRouter(
  connect((state) => ({
    portfolios: state.Portfolio.user,
    comparePortfolioState: state.ComparePortfolio,
    analytics: state.Analytics,
    esgToggle: state.Analytics.esgToggle,
    user_region: state.Storage.user_region,
    partnerName: state.Storage['partner-name'],
    toggle_region: state.Storage.toggle_region || 'CA',
  }))(Result)
);

const StyledDiv = styled.div`
  min-height: 75vh;
  @media only screen and (max-width: 968px) {
    #result-vs-header {
      width: 30rem !important;
      margin: auto !important;
    }
  }
  button.back {
    position: absolute;
    top: 2rem;
    left: 2rem;
    background-color: ${colors.blue} !important;
    color: white !important;
    z-index: 1;
  }
`;

const Grade = styled.div`
  font-family: 'Kalam', cursive;
  color: ${(props) => props.color};
  font-size: 5rem;
  padding: 1.5rem 0;
`;

const Container = styled.div`
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const radarOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: { position: 'bottom', usePointStyle: true },
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
  hover: {
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const chartOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (label) => `$${label}`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${data.datasets[tooltipItems.datasetIndex].label}: $${
          tooltipItems.yLabel
        }`,
    },
  },
  hover: {
    animationDuration: 0,
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const Error = styled.div`
  width: 400px;
  background: white;
  margin: 0 auto;
  text-align: center;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 8px;
  font-size: 1.4rem;
  box-shadow: 0 0 3px dimgrey;
`;

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.1rem auto;
`;
