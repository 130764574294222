import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Card, Grid } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';

import { Security } from 'actions';
import { exchange } from 'utils/constants';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import { Segment } from 'components';
import Breakdown from './Breakdown';
import History from './History';
import Credit from './Credit';
import Holdings from './Holdings';

class Overview extends Component {
  state = { currentTab: 'history' };
  componentWillMount = () => {
    const { dispatch, fundLookupSecurity } = this.props;
    if (fundLookupSecurity.length)
      return dispatch(Security.fetch(fundLookupSecurity));
    return null;
  };

  hasValidData = (arr) => !!arr.filter((item) => item).length;

  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker, exchangeSymbol) => {
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };

  handleClick = (event, ticker, exchangeSymbol) => {
    event.preventDefault();
    window.open(
      this.createUrl(
        this.gbmConvert(
          this.appendExchangeSymbol(ticker, exchangeSymbol.toUpperCase())
        )
      )
    ),
      '_blank';
  };

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  render() {
    const { data, user_region, partnerName } = this.props;
    const { currentTab } = this.state;

    return !data ? null : (
      <Fade duration={3500}>
        <Segment basic compact textAlign="left">
          <Container>
            <Card fluid style={{ width: '90%', margin: '0 auto' }}>
              <Card.Content>
                <Card.Header style={{ textAlign: 'center' }}>
                  {isGlobeInvestor(partnerName) ? (
                    <a
                      style={{
                        zIndex: '9999999',
                        display: 'block',
                        cursor: 'pointer',
                        pointerEvents: 'all',
                      }}
                      onClick={() =>
                        this.handleClick(
                          event,
                          data.ticker,
                          data.exchange_symbol
                        )
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.long_name}
                    </a>
                  ) : (
                    data.long_name
                  )}
                </Card.Header>
                <Card.Description>
                  <Grid>
                    <Grid.Row divided columns={2}>
                      <Grid.Column>
                        <strong>Ticker: </strong>
                        <Value>{data.ticker}</Value>
                        <br />
                        <strong>Exchange: </strong>
                        <Value>{exchange[data.exchange_symbol]}</Value>
                        <br />
                        <strong>Net Assets: </strong>
                        <Value>
                          {data.fund_net_assets
                            ? `$${Math.round(
                                data.fund_net_assets
                              ).toLocaleString()}`
                            : 'N/A'}
                        </Value>
                        <br />
                        <strong>Fund Benchmark: </strong>
                        <Value
                          className={
                            data.fund_benchmark &&
                            data.fund_benchmark.length > 25
                              ? 'long'
                              : ''
                          }
                        >
                          {data.fund_benchmark || 'N/A'}
                        </Value>
                        <br />
                        <div>
                          <strong>Management Expense Ratio (MER):</strong>
                          <Popup
                            trigger={<i className="icon question circle" />}
                            position="top center"
                            style={{ height: 'initial' }}
                          >
                            MER includes management fees, operating expenses and
                            sales tax.
                          </Popup>
                          <Value>{data.mer ? `${data.mer}%` : '-'}</Value>
                          <br />
                        </div>
                        {data.avg_duration && (
                          <div>
                            <strong>Average duration: </strong>
                            <Value>
                              {this.parseNum(data.avg_duration)} years
                            </Value>
                          </div>
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <strong>Inception Date: </strong>
                        <Value>{data.effective_date}</Value>
                        <br />
                        <div>
                          <strong>Distribution Yield:</strong>
                          <Popup
                            trigger={<i className="icon question circle" />}
                            position="top center"
                            style={{ height: 'initial' }}
                          >
                            Trailing 12-month income and capital gain
                            distributions divided by the previous {"month's"}{' '}
                            net assets.
                          </Popup>
                          <Value>
                            {data._yield
                              ? `${this.parseNum(data._yield)}%`
                              : 'N/A'}
                          </Value>
                          <br />
                        </div>
                        <strong>Distribution Frequency: </strong>
                        <Value>{data.dividend_freq}</Value>
                        <br />
                        <strong>Total Number of Holdings: </strong>
                        <Value>{data.total_holdings || 'N/A'}</Value>
                        <br />
                        <strong>P/E Ratio: </strong>
                        <Value>
                          {data.price_to_earnings
                            ? this.parseNum(data.price_to_earnings)
                            : '-'}
                        </Value>
                        <br />
                        {data.avg_maturity && (
                          <div>
                            <strong>Average maturity: </strong>
                            <Value>
                              {this.parseNum(data.avg_maturity)} years
                            </Value>
                          </div>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Description>
              </Card.Content>
            </Card>

            <Segment basic compact>
              <Menu>
                <Tab
                  onClick={() => this.setState({ currentTab: 'history' })}
                  className={currentTab === 'history' && 'active'}
                >
                  Historical Performance
                </Tab>
                {this.hasValidData([
                  ...Object.values(data.regional_exposure),
                  ...Object.values(data.asset_allocation),
                  ...Object.values(data.sector_allocation),
                ]) && (
                  <Tab
                    onClick={() => this.setState({ currentTab: 'breakdown' })}
                    className={currentTab === 'breakdown' && 'active'}
                  >
                    Fund Breakdown
                  </Tab>
                )}
                {this.hasValidData(Object.values(data.credit_quality)) && (
                  <Tab
                    onClick={() => this.setState({ currentTab: 'credit' })}
                    className={currentTab === 'credit' && 'active'}
                  >
                    Credit Quality
                  </Tab>
                )}
                {this.hasValidData(Object.values(data.top_holdings)) && (
                  <Tab
                    onClick={() => this.setState({ currentTab: 'holdings' })}
                    className={currentTab === 'holdings' && 'active'}
                  >
                    Top Holdings
                  </Tab>
                )}
              </Menu>
            </Segment>
            <div>
              {currentTab === 'history' && (
                <History data={data} user_region={user_region} />
              )}
              {currentTab === 'breakdown' && <Breakdown data={data} />}
              {currentTab === 'credit' && <Credit data={data} />}
              {currentTab === 'holdings' && <Holdings data={data} />}
            </div>
          </Container>
        </Segment>
      </Fade>
    );
  }
}

Overview.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  user_region: PropTypes.string,
  fundLookupSecurity: PropTypes.string,
  partnerName: PropTypes.string,
};

Overview.defaultProps = {
  data: {},
  user_region: 'CA',
  fundLookupSecurity: '',
  partnerName: '',
};

export default connect((state) => ({
  security: state.Security,
  user_region: state.Storage.user_region,
  fundLookupSecurity: state.Storage.fundLookupSecurity,
  partnerName: state.Storage['partner-name'],
}))(Overview);

const Value = styled.span`
  position: absolute;
  right: 1rem;
  text-overflow: ellipsis;
  text-align: right;
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  transition: background 0.1s ease;
  &.long:hover {
    background: ${colors.orange};
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    width: auto;
    max-width: unset;
    white-space: normal;
    overflow: unset;
  }
`;

const Tab = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  cursor: pointer;
  display: inline-block;
  padding: 1rem;
  width: 25%;
  line-height: 1rem;
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
  }
  &.active {
    font-weight: 700;
    &:after {
      background: black;
    }
  }
  &:hover {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const Menu = styled.div`
  text-align: center;
`;

const Container = styled.div`
  @media only screen and (max-width: 1000px) {
    .ui.grid .divided.row .column {
      width: 100% !important;
    }
  }
`;
