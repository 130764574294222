/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { Button } from 'components';
import cn from 'classnames';

const NavigationButtons = ({
  nextButtonSubmits,
  handlePreviousPage,
  handleNextPage,
  handleSubmit,
  showPreviousButton,
  showNextButton,
  nextButtonText,
  previousButtontext,
  nextButtonDisabled,
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '2rem 0 -2rem 0',
    }}
  >
    {!showPreviousButton ? (
      <div style={{ height: '4rem' }}>
        <Button
          className="left"
          style={{ visibility: 'hidden' }}
          icon="chevron left"
        >
          {previousButtontext}
        </Button>
      </div>
    ) : (
      <div style={{ height: '4rem' }}>
        <Button
          className="left"
          color={colors.lightGrey}
          icon="chevron left"
          onClick={handlePreviousPage}
        >
          {previousButtontext}
        </Button>
      </div>
    )}
    {!showNextButton ? (
      <div style={{ height: '4rem' }}>
        <Button
          className="left"
          style={{ visibility: 'hidden' }}
          icon="chevron left"
        >
          {nextButtonText}
        </Button>
      </div>
    ) : (
      <div style={{ height: '4rem' }}>
        <Button
          className={cn('right', { disabled: nextButtonDisabled })}
          color={colors.teal}
          icon="chevron right"
          onClick={nextButtonSubmits ? handleSubmit : handleNextPage}
        >
          {nextButtonText}
        </Button>
      </div>
    )}
  </div>
);

NavigationButtons.propTypes = {
  nextButtonSubmits: PropTypes.bool,
  showPreviousButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleSubmit: PropTypes.func,
  nextButtonText: PropTypes.string,
  previousButtontext: PropTypes.string,
  nextButtonDisabled: PropTypes.bool,
};

NavigationButtons.defaultProps = {
  nextButtonSubmits: false,
  showPreviousButton: true,
  showNextButton: true,
  handlePreviousPage: () => null,
  handleNextPage: () => null,
  handleSubmit: () => null,
  nextButtonText: 'Next',
  previousButtontext: 'Previous',
  nextButtonDisabled: false,
};

export default NavigationButtons;
