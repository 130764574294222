import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Header as SemanticHeader,
  Flag,
  Popup,
  Icon,
  Label,
} from 'semantic-ui-react';
import { Header, Segment, NeuCard } from 'components';
import { colors } from 'utils/colors';
import cn from 'classnames';
import ChartToggle from './ChartToggle';
import InfoPanel from './InfoPanel';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  wealthica_plan: state.Storage.wealthica_plan,
  user_region: state.Storage.user_region,
  cacheUrl: state.CacheInputs.cacheUrl,
  featureRestrictions: state.Storage['feature-restrictions'],
}))
class MainDash extends Component {
  socialMediaIcon = (name) => (
    <Icon className={cn(name)} style={{ color: colors.blue }} />
  );
  showLabel = (labelId) => {
    const label = document.getElementById(labelId);
    label.style.display = 'inline-block';
    setTimeout(() => {
      label.style.display = 'none';
    }, 3000);
  };
  getUrl = (module) => {
    const { location, cacheUrl, featureRestrictions } = this.props;
    let landingUrl = module === 'accounts' ? '/accounts' : `/${module}/landing`;

    if (module === 'planning') {
      landingUrl = featureRestrictions.includes('PLANNING_DECUMULATION')
        ? `/${module}/accumulation`
        : `/${module}/landing`;
    }

    return location.pathname.includes(module)
      ? landingUrl
      : cacheUrl[module] || landingUrl;
  };

  render() {
    const { is_wealthica_trial, partnerName, user_region } = this.props;
    return (
      <Segment>
        <InfoPanel />
        <ChartToggle />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}
        >
          <NeuCard
            narrow
            big
            iconName="chart line"
            title="Accounts"
            description={
              partnerName === 'wealthica'
                ? 'Analyze your linked investment accounts from Wealthica and accounts you manually enter in the Wealthscope Power-Up.'
                : 'Analyze your CI Direct Trading investment accounts, as well as any manually entered ones.'
            }
            color={colors.red}
            style={{ marginTop: '1.5rem' }}
            to="/accounts"
          />
          <NeuCard
            narrow
            big
            iconName="briefcase"
            title="Portfolios"
            description="Let us help you build and analyze a brand new portfolio. Once created, you will see the Wealthscope portfolio scorecard and all the relevant analytics."
            color="rgb(202, 232, 238)"
            style={{ marginTop: '1.5rem' }}
            to={this.getUrl('portfolio')}
          />
          <NeuCard
            narrow
            big
            iconName="money bill alternate outline"
            title="Planning"
            description="We have crafted tools for the accumulation phase of retirement planning, such as calculating target wealth at retirement, and monthly target savings."
            color={colors.orange}
            style={{ marginTop: '1.5rem' }}
            to={this.getUrl('planning')}
          />
          <NeuCard
            narrow
            big
            iconName="cogs"
            title="Tools"
            description="Useful tools at your fingertips to help you better understand your investments."
            color={`${colors.blue}`}
            style={{ marginTop: '1.5rem' }}
            to={this.getUrl('tools')}
          />
        </div>
      </Segment>
    );
  }
}

export default MainDash;

MainDash.propTypes = {
  history: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  is_wealthica_trial: PropTypes.bool,
  wealthica_plan: PropTypes.string,
  user_region: PropTypes.string,
  location: PropTypes.object,
  cacheUrl: PropTypes.object.isRequired,
  featureRestrictions: PropTypes.array,
};

MainDash.defaultProps = {
  partnerName: '',
  is_wealthica_trial: false,
  wealthica_plan: '',
  match: {},
  location: {},
  partnerName: '',
  featureRestrictions: [],
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 750px;

  &.strike {
    text-decoration: line-through;
  }
`;

const SocialMediaList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  margin-bottom: 0;
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  transition: font-size 0.3s;

  li {
    display: inline-block;
    cursor: pointer;
    position: relative;

    :not(:last-child) {
      margin-right: 1rem;
    }

    a i.icon,
    a i.icons {
      font-size: 1.8rem !important;
      transition: transform 0.3s;
      z-index: 1;
    }

    a:hover i.icon,
    a:hover i.icons {
      opacity: 70%;
      transform: scale(1.2);
    }
  }

  .ui.label {
    position: absolute;
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: none;
  }
`;

const Aggregates = styled.div`
  position: absolute;
  display: inline-block;
  margin: 0% 10%;
  top: ${(props) => props.top};
  left: ${(props) => props.left};

  .header {
    color: white;
    margin: 0;
  }
`;
