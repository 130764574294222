/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Radio, Header, Progress, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { ErrorLabel } from 'components';
import { isGlobeInvestor } from 'utils/helpers';

const map = {
  'WS All-In': {
    percent: 100,
    label: '100% Equities / 0% Bonds',
    icon: 'lightning',
  },
  'WS Adventurous': {
    percent: 80,
    label: '80% Equities / 20% Bonds',
    icon: 'map signs',
  },
  'WS Classic': {
    percent: 60,
    label: '60% Equities / 40% Bonds',
    icon: 'leaf',
  },
  'WS Cautious': {
    percent: 40,
    label: '40% Equities / 60% Bonds',
    icon: 'cloud',
  },
  'WS Playing-It-Safe': {
    percent: 20,
    label: '20% Equities / 80% Bonds',
    icon: 'anchor',
  },
  'Risk-free': {
    icon: 'database',
    description: 'Fixed rate of return',
  },
};

class PortfoliosList extends Component {
  render() {
    const {
      portfolios,
      handlePortfolioChange,
      portfolioID,
      annuityRate,
      modelType,
      handleRadioChange,
      errors,
      user_region,
      partnerName,
      ...rest
    } = this.props;

    const removeWsForUsVersion = (name) => {
      if (user_region === 'US' || isGlobeInvestor(partnerName)) {
        const removeWs = name.split(' ');
        removeWs.shift();
        return removeWs.join(' ');
      }
      return name;
    };

    return (
      <List {...rest}>
        {portfolios.map((port) => (
          <StyledListItem
            name="portfolio"
            key={port.name}
            equityPercentage={map[port.name].percent}
            onClick={(e) =>
              handlePortfolioChange(e, {
                value: port.id.toString(),
                name: port.name,
              })
            }
          >
            <Radio
              name={port.name}
              value={port.id}
              checked={port.name === modelType}
              onChange={handlePortfolioChange}
            />
            <List.Content>
              <Header
                as="h5"
                icon={map[port.name].icon}
                style={{ paddingBottom: '10px' }}
                content={removeWsForUsVersion(port.name)}
              />

              <StyledProgress
                percent={map[port.name].percent}
                progress
                color="blue"
                inverted
              />
            </List.Content>
          </StyledListItem>
        ))}
        <StyledListItem
          onClick={(e) =>
            handlePortfolioChange(e, {
              value: null,
              name: 'annuityRate',
            })
          }
        >
          <Radio
            name="annuityRate"
            value="annuityRate"
            checked={modelType === 'annuityRate'}
            onChange={handlePortfolioChange}
            className="radio-annuity"
          />
          <List.Content style={{ marginTop: '-25px' }}>
            <Header as="h5" icon={map['Risk-free'].icon} content="Risk-free" />
            <StyledInput
              name="annuityRate"
              placeholder="%"
              size="mini"
              value={!annuityRate && annuityRate !== 0 ? '' : annuityRate}
              onChange={handlePortfolioChange}
              label={{ tag: true, content: map['Risk-free'].description }}
              disabled={modelType !== 'annuityRate'}
              labelPosition="right"
              style={{ width: '100%', marginTop: '10px' }}
            />
            <ErrorLabel className="bottom" msg={errors.portfolioID} />
          </List.Content>
        </StyledListItem>
      </List>
    );
  }
}

PortfoliosList.propTypes = {
  portfolios: PropTypes.array.isRequired,
  handlePortfolioChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  portfolioID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  annuityRate: PropTypes.number,
  errors: PropTypes.object,
  modelType: PropTypes.string,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

PortfoliosList.defaultProps = {
  annuityRate: null,
  portfolioID: undefined,
  modelType: '',
  errors: {},
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
}))(PortfoliosList);

const StyledProgress = styled(({ className, children, ...rest }) => (
  <Progress className={className} {...rest}>
    {children}
  </Progress>
))`
  margin-bottom: 25px !important;
  background-color: red;
  background-color: ${colors.periwinkle} !important;
  height: 17px;
  color: white !important;
  border-radius: 50px !important;
  .bar {
    background-color: ${colors.navyBlue} !important;
    height: 17px !important;
    border-radius: 50px !important;
    border-top-right-radius: ${(props) =>
      props.percent >= 100 ? '50px' : '0'} !important;
    border-bottom-right-radius: ${(props) =>
      props.percent >= 100 ? '50px' : '0'} !important;
    .progress {
      right: 0.3em !important;
      padding-right: ${(props) => (props.percent >= 100 ? '50px' : '42px')};
    }
    .progress::after {
      content: '${(props) =>
        props.percent >= 100
          ? ' Equity'
          : ` Equity     ${100 - props.percent}% Bonds`}';
      position: absolute;
      white-space: pre;
    }
  }
`;
const StyledListItem = styled(({ className, children, ...rest }) => (
  <List.Item className={className} {...rest}>
    {children}
  </List.Item>
))`
  display: inline-flex !important;
  width: 50%;
  @media (max-width: 700px) {
    width: 100%;
  }
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  .header {
    text-align: center;
    width: auto !important;
    .icon,
    .content {
      display: initial !important;
    }
  }
  .content {
    width: 90%;
    margin-left: 10px;
  }
  .ui.list:nth-last-child(1) > .item,
  .ui.list:nth-last-child(2) > .item {
    border-bottom: initial;
  }
`;

const StyledInput = styled(({ children, className, ...rest }) => (
  <Input className={className} {...rest}>
    {children}
  </Input>
))`
  &.disabled {
    opacity: 1 !important;
    input {
      opacity: 1 !important;
    }
  }
`;
