import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { Api } from 'actions';

import { Table } from 'components';

export default class Execute extends Component {
  state = {
    tickersValidExchanges: {},
    tickersValidWsMatches: {},
  };
  componentDidMount = () => {
    const { data, dispatch } = this.props;
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${Object.keys(data.tickersValid).join()}`,
      })
    ).then((result) => {
      this.setState({ tickersValidExchanges: result });
    });
    const matchTickerValues = Object.values(data.tickersValid)
      .flatMap(({ wsMatches }) => wsMatches)
      .map(({ matchTicker }) => matchTicker)
      .join();
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${matchTickerValues}`,
      })
    ).then((result) => {
      this.setState({ tickersValidWsMatches: result });
    });
  };
  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker) => {
    const { tickersValidWsMatches } = this.state;
    const exchangeSymbol = tickersValidWsMatches.find(
      (item) => item.ticker === ticker
    ).exchange_symbol;
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };
  appendExchangeSymbol2 = (ticker) => {
    const { tickersValidExchanges } = this.state;
    const exchangeSymbol = tickersValidExchanges.find(
      (item) => item.ticker === ticker
    ).exchange_symbol;
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };
  handleClick = (event, ticker) => {
    event.preventDefault();
    window.open(
      this.createUrl(this.gbmConvert(this.appendExchangeSymbol(ticker)))
    ),
      '_blank';
  };
  handleClick2 = (event, ticker) => {
    event.preventDefault();
    window.open(
      this.createUrl(this.gbmConvert(this.appendExchangeSymbol2(ticker)))
    ),
      '_blank';
  };

  getHeaders = (response) =>
    Object.values(response).reduce(
      (total, value) => {
        value.wsMatches.forEach((match) => {
          if (total.indexOf(match.matchProvider.split(' ')[0]) === -1) {
            total.push(match.matchProvider.split(' ')[0]);
          }
        });

        return total;
      },
      ['Asset', 'Weight (%)', 'Fund Benchmark', 'Proxy ETF']
    );

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  render() {
    const { data } = this.props;

    return (
      <Container>
        <Header size="large" content="Execute" />
        <p>
          To execute your portfolio, you need to choose an ETF for each asset
          class, and purchase the ETFs through your broker.
        </p>
        <p>
          Please read the prospectus of each ETF (available on the ETF
          provider&#8217;s website) carefully before making a decision and
          consult a financial advisor. The list below should not be construed as
          a recommendation for any security.
        </p>
        <p>
          Should you choose a Canadian or U.S.-listed ETF? Read our short
          article,
          <br />
          <a
            href="https://www.wealthscope.ca/foreignwithholdingtax"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>
              Investing Abroad - Canadian or U.S. Listed ETFs? Some Tax
              Considerations
            </b>
          </a>
        </p>

        <StyledDiv>
          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                {this.getHeaders(data.tickersValid).map((header) => (
                  <Table.HeaderCell key={header}>
                    {header === 'Proxy ETF' ? (
                      <div>
                        Proxy <br />
                        ETF
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content="The actual ETF that was used in the analytics."
                          wide
                          style={{ display: 'inline-block' }}
                        />
                      </div>
                    ) : (
                      header
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(data.tickersValid).map((key) => (
                <Table.Row key={key}>
                  <Table.Cell>
                    {this.isLinked(data)
                      ? data.tickersValid[key].name
                      : data.tickersValid[key].wsName}
                  </Table.Cell>
                  <Table.Cell>{`${
                    Math.round(data.tickersValid[key].weight * 10000) / 100
                  }%`}</Table.Cell>
                  <Table.Cell>{data.tickersValid[key].wsBnmk}</Table.Cell>
                  <Table.Cell>
                    <strong>
                      <a
                        style={{
                          zIndex: '9999999',
                          display: 'block',
                          cursor: 'pointer',
                          pointerEvents: 'all',
                        }}
                        onClick={() => this.handleClick2(event, key)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {key}
                      </a>
                    </strong>
                    <div style={{ lineHeight: '1.2rem', fontSize: '0.9rem' }}>
                      {data.tickersValid[key].name}
                    </div>
                  </Table.Cell>
                  {this.getHeaders(data.tickersValid)
                    .slice(4)
                    .map((item) => {
                      let found = null;

                      data.tickersValid[key].wsMatches.forEach((match, index) =>
                        match.matchProvider.split(' ')[0] === item
                          ? (found = index)
                          : false
                      );

                      if (found !== null) {
                        return (
                          <Table.Cell key={item}>
                            <strong>
                              {
                                data.tickersValid[key].wsMatches[found]
                                  .matchTicker
                              }
                            </strong>
                            <strong>
                              <a
                                style={{
                                  zIndex: '9999999',
                                  display: 'block',
                                  cursor: 'pointer',
                                  pointerEvents: 'all',
                                }}
                                onClick={() =>
                                  this.handleClick(
                                    event,
                                    data.tickersValid[key].wsMatches[found]
                                      .matchTicker
                                  )
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {
                                  data.tickersValid[key].wsMatches[found]
                                    .matchTicker
                                }
                              </a>
                            </strong>
                            <div
                              style={{
                                lineHeight: '1.2rem',
                                fontSize: '0.9rem',
                              }}
                            >
                              {
                                data.tickersValid[key].wsMatches[found]
                                  .matchName
                              }
                            </div>
                          </Table.Cell>
                        );
                      }

                      return <Table.Cell key={item} />;
                    })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledDiv>
        <div
          style={{
            textAlign:
              this.getHeaders(data.tickersValid).length > 8 ? 'center' : 'left',
            fontStyle: 'italic',
          }}
        >
          *Note: the ETFs in each category are chosen because they track the
          same fund benchmark, or are highly correlated.
        </div>
      </Container>
    );
  }
}

Execute.propTypes = {
  data: PropTypes.object,
};

Execute.defaultProps = {
  data: {},
};

const StyledDiv = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 2rem;

  th {
    padding: 1rem !important;
  }

  th,
  td {
    padding: 0.7rem;
    min-width: 100px;
    max-width: 100px;
  }

  thead tr {
    display: block;
    background: #ffedd6;
  }

  tbody {
    display: block;
    overflow: auto;
    max-height: 520px;
  }

  .table {
    width: initial;
  }
`;

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
