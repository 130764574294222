/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header, Button, Popup, Icon } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import Fade from 'react-reveal/Fade';
import { Storage } from 'actions';

function Intro({ setView, analytics, user_region, dispatch, partnerName }) {
  const isCompareToPortVisible = (args) => {
    const { portfolio } = args.data;

    if (!portfolio) return false;
    if (portfolio.region !== user_region) return false;

    return true;
  };
  dispatch(Storage.setItem('to_scorecard', true));
  if (isGlobeInvestor(partnerName)) {
    setView('WhatIfCustomization');
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StyledDiv>
        <Fade duration={1500}>
          <div>
            <Header>What would you like to compare this portfolio to?</Header>
            <div>
              <Button onClick={() => setView('WhatIfCustomization')}>
                A <span style={{ fontStyle: 'italic' }}>What-if</span>{' '}
                portfolio&nbsp;
                <Popup
                  style={{ marginTop: '20px' }}
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'white',
                        margin: '0',
                      }}
                    />
                  }
                  position="bottom center"
                  wide
                  content="A copy of your current portfolio that you can tweak/edit without modifying your current portfolio."
                />
              </Button>
              {isCompareToPortVisible(analytics) ? (
                <Button onClick={() => setView('ExistingPortfolioSelection')}>
                  A saved portfolio&nbsp;
                  <Popup
                    style={{ marginTop: '20px' }}
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'white',
                          margin: '0',
                        }}
                      />
                    }
                    position="bottom center"
                    wide
                    content="Select one of your saved portfolios or one of our model portfolios."
                  />
                </Button>
              ) : null}
            </div>
          </div>
        </Fade>
      </StyledDiv>
      {!isCompareToPortVisible(analytics) && (
        <Note>
          <b>Note: </b>when you analyze your USD account in USD, there is no
          "Compare" (to saved portfolios) function. Use the "What-if" function
          instead to create a portfolio for comparison.
        </Note>
      )}
    </div>
  );
}

Intro.propTypes = {
  setView: PropTypes.func.isRequired,
  analytics: PropTypes.object,
  user_region: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

Intro.defaultProps = {
  analytics: {},
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
}))(Intro);

const StyledDiv = styled.div`
  & {
    // border: 2px solid ${colors.blue};
    // border-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    flex-direction: column;
    justify-content: center;
    div {
      margin-top: -4rem;
      div {
        margin-top: 1.5rem;
        display: flex;
        gap: 2rem;
        justify-content: center;
        button {
          background-color: ${colors.blue} !important;
          color: white !important;
        }
      }
    }
  }
`;

const Note = styled.div`
  position: absolute;
  font-size: 1.1rem;
  width: 50%;
  text-align: center;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  bottom: 30px;
`;
