import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Header } from 'semantic-ui-react';
import { colors } from 'utils/colors';

import { Accounts, Planning, Portfolio } from 'actions';
import { LogoSpinner, Segment } from 'components';
import Introduction from './introduction';
import GeneralInformation from './GeneralInformation';
import RetirementIncome from './RetirementIncome';
import InvestmentPortfolio from './InvestmentPortfolio';
import AccountValues from './AccountValues';
import InvestmentScenarios from './InvestmentScenarios';
import Result from './Result';
import Container from './Container';

import { OasModal, CppModal, NavigationButtons } from './components';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
  isCppModalOpen: state.Planning.isCppModalOpen,
  isOasModalOpen: state.Planning.isOasModalOpen,
}))
export default class Drawdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scenario: null,
    };
  }
  componentWillMount = () => {
    const { dispatch, match, portfolios, drawdown } = this.props;
    const { inputs } = drawdown;
    const { id } = match.params;

    dispatch(Planning.getCppOasDataset());
    dispatch(Accounts.fetchAll());

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    if (!inputs.isRiskFree && inputs.scenario) {
      this.setState({
        scenario: inputs.scenario,
        displayScenario: inputs.scenario,
      });
    }

    if (Number(id) && !drawdown.id) {
      dispatch(Planning.fetchDrawdownPlanId(id));
      dispatch(Planning.navigateDrawdown(6));
    }

    return false;
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { drawdown } = this.props;
      const { displayScenario } = this.state;

      if (drawdown) {
        const { inputs, modified } = drawdown;
        const { scenario } = inputs;

        if (scenario) {
          this.setState({ scenario });

          if (!modified && !displayScenario) {
            this.setState({ displayScenario: scenario });
          }
        }
      }
    }
  };
  tabHasErrors = (tab) => {
    const { drawdown } = this.props;
    const { errors } = drawdown;
    const requiredInputs = {
      Introduction: [],
      'Personal Information': ['age', 'horizon', 'prov', 'age2', 'horizon2'],
      'Retirement Income': [
        'cppAge',
        'oasAge',
        'cpp',
        'oas',
        'myCpp',
        'myOas',
        'cppAge2',
        'oasAge2',
        'cpp2',
        'oas2',
        'myCpp2',
        'myOas2',
      ], // Include myCpp and myOas here
      'Investment Portfolio': [
        'portfolio',
        'constantRets',
        'portfolio2',
        'constantRets2',
      ],
      'Account Values': [
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
        'initRegular2',
        'initRRIF2',
        'initTFSA2',
        'endValue2',
      ],
      'Investment Scenario': ['scenario'],
    };

    const tabRequiredInputs = requiredInputs[tab] || [];

    // Check if any of the required inputs for this tab have errors
    return tabRequiredInputs.some((input) => errors[input]);
  };

  handleMenuItemClick = (page) => {
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown;

    if (page > 1) {
      const errors = { ...drawdown.errors };
      let hasErrors = false;

      if (!inputs.age || Number(inputs.age) === 0) {
        errors.age = 'Age is required';
        hasErrors = true;
      }
      if (!inputs.horizon || Number(inputs.horizon) === 0) {
        errors.horizon = 'Horizon is required';
        hasErrors = true;
      }
      if (!inputs.prov) {
        errors.prov = 'Province is required';
        hasErrors = true;
      }

      if (hasErrors) {
        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { ...inputs },
            errors,
          })
        );
      }
    }

    dispatch(Planning.navigateDrawdown(page));
  };

  toggleOasModal = (val) => {
    const { dispatch } = this.props;
    dispatch(Planning.setOasModalOpen(val));
  };

  toggleCppModal = (val) => {
    const { dispatch } = this.props;
    dispatch(Planning.setCppModalOpen(val));
  };

  updateCppInfo = (cppAge, cpp) => {
    const { dispatch } = this.props;

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { cppAge, cpp },
        errors: { cppAge: false, cpp: false },
        modified: true,
      })
    );
  };

  updateOasInfo = (oasAge, oas) => {
    const { dispatch } = this.props;

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { oasAge, oas },
        errors: { oasAge: false, oas: false },
        modified: true,
      })
    );
  };

  render() {
    const {
      drawdown,
      portfolios,
      dispatch,
      cppDataset,
      match,
      isCppModalOpen,
      isOasModalOpen,
    } = this.props;
    const { activePage, result, isFetching } = drawdown;
    const { scenario } = this.state;

    const components = [
      <Introduction activePage={activePage} />,
      <GeneralInformation
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
      />,
      <RetirementIncome
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        toggleOasModal={this.toggleOasModal}
        toggleCppModal={this.toggleCppModal}
      />,
      <InvestmentPortfolio
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
      />,
      <AccountValues
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
      />,

      <InvestmentScenarios
        scenario={scenario}
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        match={match}
      />,

      result !== null ? (
        <Result
          dispatch={dispatch}
          drawdown={drawdown}
          inputs={drawdown.inputs}
          errors={drawdown.errors}
          isFetching={drawdown.isFetching}
          portfolios={portfolios}
          displayScenario={drawdown.displayScenario}
        />
      ) : null,
    ];

    if (isFetching) {
      return (
        <Segment margin="1rem" textAlign="center" className="segment" expand>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '6rem',
              opacity: '0.6',
            }}
          >
            <LogoSpinner active />
          </div>
        </Segment>
      );
    }
    const { inputs } = drawdown;
    const showNextButton =
      activePage !== 5 &&
      (activePage !== 1 ||
        (inputs.age !== undefined &&
          Number(inputs.age) !== 0 &&
          inputs.horizon !== undefined &&
          Number(inputs.horizon) !== 0 &&
          inputs.prov !== undefined));

    return (
      <Container margin="1rem" textAlign="center" activePage={activePage}>
        <OasModal
          isOpen={isOasModalOpen}
          toggle={this.toggleOasModal}
          updateOasInfo={this.updateOasInfo}
        />
        <CppModal
          isOpen={isCppModalOpen}
          toggle={this.toggleCppModal}
          updateCppInfo={this.updateCppInfo}
          cppDataset={cppDataset}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom:
              (activePage === 1 || activePage === 3 || activePage === 5) &&
              '50px',
          }}
        >
          {activePage !== 6 && (
            <Header
              textAlign="center"
              size="large"
              style={{ marginBottom: activePage !== 0 && '2rem' }}
            >
              Optimal Drawdown{' '}
            </Header>
          )}
          {activePage !== 6 && activePage !== 0 && (
            <div
              style={{
                color: '#4183C3',
                fontWeight: 'bold',
                transform: 'translateY(-20px)',
                cursor: 'pointer',
              }}
              onClick={() => dispatch(Planning.navigateDrawdown(0))}
            >
              Model Features and Assumptions
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {activePage !== 6 && activePage !== 0 && (
              <MenuWrapper>
                {menuItemIcons.map((icon, index) => {
                  // Skip Result component when there's no result, when fetching data, or if it's risk-free with no scenario
                  if (
                    (index === 6 && (drawdown.isFetching || result === null)) ||
                    index === 0
                  )
                    return null;

                  return (
                    <MenuItem
                      key={index}
                      active={activePage === index}
                      onClick={() => this.handleMenuItemClick(index)}
                      hasErrors={this.tabHasErrors(menuItemNames[index])}
                    >
                      <div className="icon-wrapper">
                        <Icon name={icon} />
                      </div>
                      <MenuText>{menuItemNames[index]}</MenuText>
                      {this.tabHasErrors(menuItemNames[index]) && (
                        <ErrorIcon
                          style={{ fontSize: '1.3em' }}
                          name="exclamation"
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </MenuWrapper>
            )}
            <div
              style={{
                width: activePage !== 6 ? '700px' : '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {activePage !== 0 && <Introduction activePage={activePage} />}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  padding: '0 0 0 20px',
                }}
              >
                {components
                  .map((_, index) => ({ index, component: components[index] }))
                  .filter(
                    ({ index }) =>
                      (index !== 1 && index !== 7) ||
                      (index === 1 && activePage === 1) ||
                      drawdown.displayScenario
                  ) // Filter out the Result component if there is no result and only display General Information when activePage is 1
                  .map(({ index, component }) => {
                    if (index === activePage) {
                      return <div key={index}>{component}</div>;
                    }
                    return null;
                  })}
                {activePage !== 6 && (
                  <NavigationButtons
                    style={{ alignSelf: 'end', margin: 0 }}
                    long={
                      activePage === 1 || activePage === 3 || activePage === 5
                    }
                    handlePreviousPage={() =>
                      this.handleMenuItemClick(activePage - 1)
                    }
                    handleNextPage={() =>
                      this.handleMenuItemClick(activePage + 1)
                    }
                    showPreviousButton={activePage !== 0}
                    showNextButton={showNextButton}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

Drawdown.propTypes = {
  dispatch: PropTypes.func,
  portfolios: PropTypes.array,
  drawdown: PropTypes.object,
  match: PropTypes.object,
  cppDataset: PropTypes.array,
  isCppModalOpen: PropTypes.bool,
  isOasModalOpen: PropTypes.bool,
};

Drawdown.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  drawdown: {},
  match: {},
  cppDataset: [],
  isCppModalOpen: false,
  isOasModalOpen: false,
};

const MenuWrapper = styled.div`
  width: 200px;
  color: black;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: ${(props) => (props.active ? '#b7c1fe' : '#f0f0f0')};
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: ${(props) => (props.active ? '#b7c1fe' : '#ddd')};
  }

  .icon-wrapper {
    margin-right: 10px;
  }
`;

const ErrorIcon = styled(Icon)`
  left: 45px;
  color: ${colors.red};
`;

const MenuText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const menuItemIcons = [
  'info circle',
  'user',
  'money bill alternate outline',
  'chart line',
  'dollar',
  'sliders horizontal',
];

const menuItemNames = [
  'Introduction',
  'Personal Information',
  'Retirement Income',
  'Investment Portfolio',
  'Account Values',
  'Investment Scenario',
];
