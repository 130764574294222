import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';

import { colors } from 'utils/colors';

const Bar = ({ error, name, ticker, weight }) => (
  <Container width={weight * 100} error={error} placeholder={weight === null}>
    {ticker}
    <Name>{name}</Name>
    {weight !== null ? (
      <Weight>{Math.round(weight * 10000) / 100}%</Weight>
    ) : null}
    {!error ? null : (
      <PopupWrapper>
        <Popup
          trigger={<Icon name="exclamation circle" />}
          position="bottom center"
          wide
          content={
            <div>
              <strong>{ticker}</strong> has been excluded in the analysis
              because it has insufficient history. See the Guidelines button at
              the top for more information.
            </div>
          }
        />
      </PopupWrapper>
    )}
  </Container>
);

Bar.propTypes = {
  name: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
};

export default Bar;

const Container = styled.div`
  position: relative;
  background: rgb(241, 242, 247);
  margin: 0.5rem 0;
  border: 1px solid transparent;
  padding: 0.3rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 18px;
  min-height: 46px;
  opacity: ${(props) => (props.placeholder ? 0.5 : 1)};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.width}%;
    background: ${colors.darkBlue};
    opacity: 0.2;
    z-index: 0;
  }

  ${(props) =>
    props.error &&
    css`
      background: #fff6f6;
      color: ${colors.hintRed};

      &:before {
        background: ${colors.red};
      }
    `}
`;

const Weight = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
`;

const Name = styled.div`
  font-weight: 100;
  font-size: 1rem;
`;

const PopupWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 70px;
  font-size: 1.4rem;
  color: ${colors.red};
`;
