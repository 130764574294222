import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ErrorLabel = ({ children, msg, ...rest }) =>
  !msg ? null : <Label {...rest}>{msg}</Label>;

export default ErrorLabel;

ErrorLabel.propTypes = {
  children: PropTypes.string,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ErrorLabel.defaultProps = {
  children: '',
  msg: false,
};

const Label = styled.div`
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  left: calc(100% + 10px);
  text-transform: initial;
  bottom: 2px;

  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(3%, 0) rotate(45deg);
    background: inherit;
    left: -5px;
    margin-top: -4px;
    top: 50%;
    border-width: 0 0 1px 1px;
    border-style: solid;
  }

  &.bottom {
    left: 50%;
    top: 120%;
    bottom: unset;
    transform: translateX(-50%);

    &:before {
      left: 50%;
      top: -1px;
      transform: translate(-50%, 0) rotate(135deg);
    }
  }

  &.top {
    left: 50%;
    top: -20px;
    bottom: unset;
    transform: translateX(-50%);

    &:before {
      left: 50%;
      top: unset;
      bottom: -5px;
      transform: translateX(-50%) rotate(-45deg);
    }
  }

  &.left {
    left: unset;
    right: calc(100% + 10px);

    &:before {
      right: -5px;
      left: unset;
      margin-top: -3px;
      top: 50%;
      transform: rotate(-135deg) translate(5%, 0);
    }
  }

  &.responsive {
    @media only screen and (max-width: 999px) {
      left: unset;
      right: calc(100% + 10px);

      &:before {
        right: -5px;
        left: unset;
        margin-top: -3px;
        top: 50%;
        transform: rotate(-135deg) translate(5%, 0);
      }
    }

    @media only screen and (max-width: 550px) {
      left: 50%;
      right: unset;
      top: ${(props) => props.top || '-40px'};
      bottom: unset;
      transform: translateX(-50%);

      &.bottom {
        left: 50%;
        top: 130%;
        bottom: unset;
        transform: translateX(-50%);

        &:before {
          left: 50%;
          top: -2px;
          transform: translate(-50%, 0) rotate(135deg);
        }
      }

      &:before {
        left: 50%;
        top: unset;
        bottom: -5px;
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }
`;
