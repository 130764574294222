import { request } from './api';
import * as Alert from './alert';
import * as Accounts from './accounts';

/**
 * Action constants
 */
export const COMPAREACCOUNT_REQUEST = 'COMPAREACCOUNT_REQUEST';
export const COMPAREACCOUNT_SEARCH_REQUEST = 'COMPAREACCOUNT_SEARCH_REQUEST';
export const COMPAREACCOUNT_FETCH_ID = 'COMPAREACCOUNT_FETCH_ID';

export const COMPAREACCOUNT_SEARCH_SUCCESS = 'COMPAREACCOUNT_SEARCH_SUCCESS';
export const COMPAREACCOUNT_ADD_SUCCESS = 'COMPAREACCOUNT_ADD_SUCCESS';
export const COMPAREACCOUNT_UPDATE = 'COMPAREACCOUNT_UPDATE';
export const COMPAREACCOUNT_UPDATE_BENCHMARK =
  'COMPAREACCOUNT_UPDATE_BENCHMARK';
export const COMPAREACCOUNT_UPDATE_WEIGHT = 'COMPAREACCOUNT_UPDATE_WEIGHT';
export const COMPAREACCOUNT_UPDATE_TOGGLE = 'COMPAREACCOUNT_UPDATE_TOGGLE';
export const COMPAREACCOUNT_UPDATE_DIGITALCURRENCY =
  'COMPAREACCOUNT_UPDATE_DIGITALCURRENCY';
export const COMPAREACCOUNT_REMOVE_SECURITY = 'COMPAREACCOUNT_REMOVE_SECURITY';
export const COMPAREACCOUNT_REMOVE_DIGITALCURRENCY =
  'COMPAREACCOUNT_REMOVE_DIGITALCURRENCY';
export const COMPAREACCOUNT_SAVE_COMPAREPORTS =
  'COMPAREACCOUNT_SAVE_COMPAREPORTS';
export const COMPAREACCOUNT_SAVE_SECURITY = 'COMPAREACCOUNT_SAVE_SECURITY';
export const COMPAREACCOUNT_REMOVE_ALL = 'COMPAREACCOUNT_REMOVE_ALL';

export const COMPAREACCOUNT_HOLDING_REQUEST = 'COMPAREACCOUNT_HOLDING_REQUEST';
export const COMPAREACCOUNT_HOLDING_UPDATE = 'COMPAREACCOUNT_HOLDING_UPDATE';
export const COMPAREACCOUNT_HOLDING_FAIL = 'COMPAREACCOUNT_HOLDING_FAIL';
export const COMPAREACCOUNT_COMPARE_SUCCESS = 'COMPAREACCOUNT_COMPARE_SUCCESS';
export const COMPAREACCOUNT_ESG_SUCCESS = 'COMPAREACCOUNT_ESG_SUCCESS';
export const COMPAREACCOUNT_CLEAR_RESULT = 'COMPAREACCOUNT_CLEAR_RESULT';
export const COMPAREACCOUNT_CLEAR = 'COMPAREACCOUNT_CLEAR';
export const COMPAREACCOUNT_ROUND_WEIGHTS_TO_WHOLE_NUMBERS =
  'COMPAREACCOUNT_ROUND_WEIGHTS_TO_WHOLE_NUMBERS';

export const COMPAREACCOUNT_REMOVE_ALL_HOLDINGS =
  'COMPAREACCOUNT_REMOVE_ALL_HOLDINGS';
export const COMPAREACCOUNT_REMOVE_ALL_GICS = 'COMPAREACCOUNT_REMOVE_ALL_GICS';
export const COMPAREACCOUNT_UPDATE_INTEREST = 'COMPAREACCOUNT_UPDATE_INTEREST';
export const COMPAREACCOUNT_UPDATE_GIC = 'COMPAREACCOUNT_UPDATE_GIC';

/**
 * Action creators
 */
export function fetchId(id, aggregatedHoldings) {
  return (dispatch) => {
    dispatch(updateToggle('addBtcToggle', false));
    dispatch(updateToggle('addGicToggle', false));
    if (aggregatedHoldings)
      return dispatch({
        type: COMPAREACCOUNT_FETCH_ID,
        account: aggregatedHoldings,
      });

    dispatch({ type: COMPAREACCOUNT_REQUEST });

    return dispatch(request('get', `/accounts/${id}/`)).then(
      (data) => dispatch({ type: COMPAREACCOUNT_FETCH_ID, account: data }),
      (error) => {
        dispatch({ type: COMPAREACCOUNT_FETCH_ID, account: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function roundWeightsToWholeNumbers() {
  return { type: COMPAREACCOUNT_ROUND_WEIGHTS_TO_WHOLE_NUMBERS };
}

export function add(id) {
  return (dispatch) => {
    dispatch({ type: COMPAREACCOUNT_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) => dispatch({ type: COMPAREACCOUNT_ADD_SUCCESS, security: data }),
      (error) => {
        dispatch({ type: COMPAREACCOUNT_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function saveBulk(params) {
  return (dispatch) => {
    dispatch({ type: COMPAREACCOUNT_HOLDING_REQUEST });

    return dispatch(
      request('post', '/accounts/holdings/bulk/', { body: params })
    ).then(
      () => {
        dispatch({ type: COMPAREACCOUNT_HOLDING_UPDATE });
        dispatch(Accounts.fetchAll());
      },
      () => dispatch({ type: COMPAREACCOUNT_HOLDING_FAIL })
    );
  };
}

export function addGicBtcEth(ticker) {
  return {
    type: COMPAREACCOUNT_ADD_SUCCESS,
    security: { ticker },
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: COMPAREACCOUNT_SEARCH_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: COMPAREACCOUNT_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: COMPAREACCOUNT_SEARCH_SUCCESS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function update(field, ticker, value) {
  return {
    type: COMPAREACCOUNT_UPDATE,
    field,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function updateToggle(field, value) {
  return {
    type: COMPAREACCOUNT_UPDATE_TOGGLE,
    field,
    value,
  };
}

export function updateWeight(ticker, value) {
  return {
    type: COMPAREACCOUNT_UPDATE_WEIGHT,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function updateInterestRate(ticker, value) {
  return {
    type: COMPAREACCOUNT_UPDATE_INTEREST,
    ticker,
    interest_rate: value === '' ? '' : Number(value),
  };
}

export function remove(ticker) {
  return {
    type: COMPAREACCOUNT_REMOVE_SECURITY,
    ticker,
  };
}

export function updateGicInfo(field, value) {
  return {
    type: COMPAREACCOUNT_UPDATE_GIC,
    field,
    value,
  };
}

export function removeDigitalCurrency(ticker) {
  return {
    type: COMPAREACCOUNT_REMOVE_DIGITALCURRENCY,
    ticker,
  };
}

export function removeAll() {
  return {
    type: COMPAREACCOUNT_REMOVE_ALL,
  };
}

export function removeAllHoldings() {
  return {
    type: COMPAREACCOUNT_REMOVE_ALL_HOLDINGS,
  };
}
export function removeAllGics() {
  return {
    type: COMPAREACCOUNT_REMOVE_ALL_GICS,
  };
}

export function clearResult() {
  return {
    type: COMPAREACCOUNT_CLEAR_RESULT,
  };
}

export function fetchCompare(
  port1,
  port2,
  gic_info_1,
  gic_info_2,
  region,
  customBenchmark,
  check_history,
  rebalanceInterval = 1
) {
  // making copies of all the objects to avoid changing the data in the store
  port1 = { ...port1 };
  port2 = { ...port2 };
  gic_info_1 = { ...gic_info_1 };
  gic_info_2 = { ...gic_info_2 };
  return (dispatch) => {
    const gic_info_1_keys = Object.keys(gic_info_1).filter(
      (key) => !!port1[key]
    ); // filter out any keys in gic_info that do no exist in the portfolio
    // (this can happen when a gic has a value of 0);
    const gic_info_2_keys = Object.keys(gic_info_2).filter(
      (key) => !!port2[key]
    );

    // For example say you have gic_info_1={GIC1:MKT: {...}, GIC2:MKT: {...}}
    // and gic_info_2={GIC1:MKT: {...}, GIC2:MKT: {...}}
    // after getting through the if block below, gic_info_1={GIC1:MKT: {...}, GIC2:MKT: {...}}
    // and gic_info_2={GIC10:MKT: {...}, GIC9:MKT: {...}} (these changes will also apply to port1 & port2)

    if (gic_info_1_keys.length && gic_info_2_keys.length) {
      // the back-end can only accept at most 10 GICs so these are the only options for tickers
      let gic_names = [
        'GIC1:MKT',
        'GIC2:MKT',
        'GIC3:MKT',
        'GIC4:MKT',
        'GIC5:MKT',
        'GIC6:MKT',
        'GIC7:MKT',
        'GIC8:MKT',
        'GIC9:MKT',
        'GIC10:MKT',
      ];
      // getting all available unused gic_names that we can use to rename the intersection later
      gic_names = gic_names.filter(
        (name) =>
          !gic_info_1_keys.includes(name) && !gic_info_2_keys.includes(name)
      );

      const gic_intersection = gic_info_1_keys.filter((key) =>
        gic_info_2_keys.includes(key)
      );

      if (gic_names.length < gic_intersection.length) {
        return dispatch(
          Alert.show({
            type: 'error',
            msg: 'You are limited to a combined total of 10 GICs/HISAs across both portfolios in this comparison. ',
          })
        );
      }

      // rename every key in the what-if portfolio that also appears in gic_intersection
      gic_intersection.forEach((key) => {
        const newName = gic_names.pop();
        gic_info_2[newName] = { ...gic_info_2[key] };
        port2[newName] = { ...port2[key] };
        delete port2[key];
        delete gic_info_2[key];
      });
    }

    const all_gic_info = { ...gic_info_1, ...gic_info_2 };

    const symbols1 = Object.keys(port1)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const weights1 = Object.keys(port1)
      .map((ticker) => (port1[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const symbols2 = Object.keys(port2)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const weights2 = Object.keys(port2)
      .map((ticker) => (port2[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);

    dispatch({ type: COMPAREACCOUNT_REQUEST });
    dispatch({ type: COMPAREACCOUNT_REQUEST });

    return dispatch(
      request('post', '/portfolio/analytics/compare/', {
        body: {
          symbols1,
          weights1,
          symbols2,
          weights2,
          check_history,
          gic_info: all_gic_info,
          ...(customBenchmark && { custom_bm: customBenchmark }),
          region: region || 'CA',
          rebalance_interval: rebalanceInterval,
        },
      })
    ).then(
      ({ portfolio1, portfolio2 }) =>
        dispatch({
          type: COMPAREACCOUNT_COMPARE_SUCCESS,
          analysis1: {
            ...portfolio1,
            // back-end doesnt return portfolio name, id, etc...
            ...(portfolio1 &&
              Object.keys(portfolio1).length && { portfolio: port1 }),
            check_history,
          },
          analysis2: {
            ...portfolio2,
            // back-end doesnt return portfolio name, id, etc...
            ...(portfolio2 &&
              Object.keys(portfolio2).length && { portfolio: port2 }),
            check_history,
          },
        }),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({
          type: COMPAREACCOUNT_COMPARE_SUCCESS,
          analysis1: null,
          analysis2: null,
        });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchCompareESG(port1, port2, check_history) {
  return (dispatch) =>
    dispatch(
      request('post', '/portfolio/esg/compare/', {
        body: {
          symbols1: port1.symbols,
          weights1: port1.weights,
          ...(port1.gic_info && {
            gic_info: {
              ...port1.gic_info,
            },
          }),
          check_history,
          symbols2: port2.symbols,
          weights2: port2.weights,
        },
      })
    ).then(
      ({ portfolio1esg, portfolio2esg }) =>
        dispatch({
          type: COMPAREACCOUNT_ESG_SUCCESS,
          portfolio1esg,
          portfolio2esg,
        }),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({
          type: COMPAREACCOUNT_COMPARE_SUCCESS,
          portfolio1esg: null,
          portfolio2esg: null,
        });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
}

export function saveComparePorts(port1, port2) {
  return {
    type: COMPAREACCOUNT_SAVE_COMPAREPORTS,
    port1,
    port2,
  };
}

export function updateBenchmark(benchmark) {
  return {
    type: COMPAREACCOUNT_UPDATE_BENCHMARK,
    benchmark,
  };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
