/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Grid, Flag } from 'semantic-ui-react';
import { Segment } from 'components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { colors } from 'utils/colors';
import Fade from 'react-reveal/Fade';
import Charts from './Charts';

class ChartToggle extends Component {
  top3Accounts = (accounts) => {
    const topAccounts = accounts.reduce((acc, account) => {
      let value = 0;
      account.holdings.forEach((holding) => {
        const marketValue =
          holding.currency === 'USD'
            ? holding.market_value * account.usd2cad
            : holding.market_value;
        value += marketValue;
      });
      acc.push({
        ...account,
        value,
      });
      return acc;
    }, []);

    topAccounts.sort((a, b) => b.value - a.value);

    return topAccounts.slice(0, 3).map((account) => {
      const currencyFlag = account.currency === 'USD' ? 'us' : 'ca';
      return {
        ...account,
        institutionName: account.institution.name,
        currencyFlag,
      };
    });
  };

  render() {
    const { fullState, accountList } = this.props;

    return (
      <WindowWrapper
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Fade left>
          <Segment
            style={{
              height: '100%',
              width: '100%',
              display: 'inline-block',
              float: 'left',
              margin: '0 0 1rem 0',
            }}
          >
            <Header
              size="large"
              style={{
                color: colors.blue,
                textAlign: 'center',
                padding: '0',
                margin: '0 auto',
              }}
              content={`Your Summary`}
            />
            <Grid>
              <Grid.Row columns={2} style={{ padding: '0' }}>
                <Grid.Column
                  width={4}
                  style={{ borderRight: '2px solid rgba(0, 0, 0, 0.1)' }}
                >
                  <Header
                    size="medium"
                    style={{
                      color: colors.darkGrey,
                      textAlign: 'center',
                      margin: '0 0 1rem 0 !important',
                      fontSize: '1.5em',
                    }}
                    content="Work Saved"
                  />
                  <div style={{ display: 'inline-block' }}>
                    <div>
                      <StyledSmallHeader>
                        Retirement Blueprints
                      </StyledSmallHeader>
                      <ul style={{ marginTop: '0' }}>
                        <li>
                          <Value>Blueprint 1</Value>
                        </li>
                        <li>
                          <Value>Blueprint 2</Value>
                        </li>
                        <li>
                          <Value>Blueprint 3</Value>
                        </li>
                      </ul>

                      <StyledSmallHeader>Drawdown plans</StyledSmallHeader>
                      <ul style={{ marginTop: '0' }}>
                        <li>
                          <Value>Drawdown 1</Value>
                        </li>
                        <li>
                          <Value>Drawdown 2</Value>
                        </li>
                        <li>
                          <Value>Drawdown 3</Value>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width={12}>
                  <div
                    style={{
                      width: '65%',
                      display: 'inline-block',
                      paddingLeft: '1rem',
                      passingRight: '1rem',
                      paddingTop: '3rem',
                    }}
                  >
                    <Charts />
                  </div>
                  <div style={{ width: '33%', float: 'right' }}>
                    <Header
                      size="medium"
                      style={{ margin: 0, color: colors.darkBlue }}
                    >
                      Your Top 3 Accounts
                    </Header>
                    {this.top3Accounts(accountList).map((account) => (
                      <div key={account.id}>
                        <Value className="main">
                          {account.institution}
                          <Flag
                            name={account.currencyFlag}
                            style={{
                              position: 'relative',
                              bottom: '7px',
                              marginLeft: 7,
                            }}
                          />
                        </Value>
                        <div style={{ display: 'inline-block' }}>
                          <StyledSmallHeader>Daily Change</StyledSmallHeader>
                          <Value>{`${Number(0.5).toFixed(2)}%`}</Value>
                        </div>
                      </div>
                    ))}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Fade>
      </WindowWrapper>
    );
  }
}

export default withRouter(
  connect((state) => ({
    overview: state.Accounts.overview,
    accountList: state.Accounts.accountList,
    fullState: state,
  }))(ChartToggle)
);

ChartToggle.propTypes = {
  dispatch: PropTypes.func.isRequired,
  aggregate: PropTypes.array,
  history: PropTypes.object.isRequired,
  accountList: PropTypes.array,
  fullState: PropTypes.object.isRequired,
};

ChartToggle.defaultProps = {
  aggregate: [],
  accountList: null,
};

const Note = styled.div`
  padding: 0;
  float: ${(props) => props.float};

  &:before {
    content: ${(props) => props.type};
  }
`;

const WindowWrapper = styled.div`
  height: 100%;
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .active {
    background-color: rgba(255, 255, 255, 0.2);
    /* border-left: 10px solid rgb(252, 193, 116); */
    box-shadow: 10px 0px rgb(252, 193, 116) inset;
  }

  .item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding-left: 2rem;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      cursor: pointer;
    }
  }
  div {
    .react-reveal {
      height: 100%;
      width: 100%;
    }
  }
`;

const Value = styled.div`
  font-size: ${(props) => (props.small ? '1rem' : '1.4rem')};
  padding: 7px 0;
  font-weight: 700;
  color: ${colors.blue};

  &.money {
    color: ${colors.blue};
  }

  &.main {
    font-size: 1.5rem;
    color: ${colors.orange};
  }
`;

const StyledSmallHeader = styled(({ children, ...rest }) => (
  <Header size="large" sub {...rest}>
    {children}
  </Header>
))`
  margin: 0 !important;
  color: ${colors.darkBlue} !important;
`;
