import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { NotFound, Loading } from 'components';
import { Analytics as AnalyticsActions, Accounts } from 'actions';
import Menu from './Menu';

class ETFAnalytics extends Component {
  componentWillMount = () => {
    const {
      analytics,
      history,
      location,
      dispatch,
      match,
      pwpaPortfolio,
      pwpaCheckHistory,
      toggle_region,
      user_region,
    } = this.props;
    const { selectedAssets, benchmark } = analytics;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );
    const checkHistory = pwpaCheckHistory === null ? false : !pwpaCheckHistory;

    const decideRegion = () => {
      if (user_region === 'US') {
        return 'US';
      }
      return toggle_region || 'CA';
    };

    const customBenchmark = {
      symbols: Object.keys(selectedAssets).join(','),
      weights: benchmarkWeights.join(','),
    };
    const ableToUseCustomBenchmark = this.isAbleToUseCustomBenchmark();

    if (Number(match.params.id)) {
      return benchmark === 'custom' && ableToUseCustomBenchmark
        ? dispatch(
            AnalyticsActions.fetchId(
              match.params.id,
              null,
              customBenchmark,
              decideRegion()
            )
          )
        : dispatch(
            AnalyticsActions.fetchId(
              match.params.id,
              null,
              null,
              decideRegion()
            )
          );
    }

    if (pwpaPortfolio) {
      const updatedPortfolio = {
        ...pwpaPortfolio,
        ...(benchmark === 'custom' &&
          ableToUseCustomBenchmark && { custom_bm: customBenchmark }),
      };

      return dispatch(
        AnalyticsActions.fetchParams(
          updatedPortfolio,
          location.state,
          pwpaCheckHistory === null ? pwpaCheckHistory : checkHistory,
          decideRegion()
        )
      );
    }

    return history.push('/');
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (
      !history.location.pathname.includes('/accounts/') &&
      !history.location.pathname.includes('/pdf')
    ) {
      dispatch(Accounts.toggleMultipleMode());
    }
  }

  isAbleToUseCustomBenchmark = () => {
    const { partnerName, user_region } = this.props;

    if (partnerName === 'quotemedia_pro' || user_region === 'US') {
      return true;
    }

    return false;
  };

  renderError = (data) =>
    data.detail ? (
      <Error>
        Analysis failed. {data.detail}
        <Link to="/accounts/overview" className="close">
          +
        </Link>
      </Error>
    ) : (
      <Error>
        Sorry, all securities in your portfolio are too new to be analyzed.
        <Link to="/accounts/overview" className="close">
          +
        </Link>
      </Error>
    );

  render() {
    const { match, analytics } = this.props;

    if (!analytics.data || analytics.isFetching) {
      return <Loading active fixed />;
    }

    if (analytics.data.detail === 'Not found.') {
      return <NotFound />;
    }

    return !analytics.data.score ? (
      this.renderError(analytics.data)
    ) : (
      <Menu id={match.params.id} />
    );
  }
}

ETFAnalytics.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  analytics: PropTypes.object,
  pwpaPortfolio: PropTypes.object,
  pwpaCheckHistory: PropTypes.bool,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  toggle_region: PropTypes.string,
};

ETFAnalytics.defaultProps = {
  analytics: {},
  pwpaPortfolio: null,
  pwpaCheckHistory: null,
  partnerName: '',
  user_region: 'CA',
  toggle_region: 'CA',
};

export default connect((state) => ({
  analytics: state.Analytics,
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
  pwpaCheckHistory: state.Storage['pwpa-check-history'],
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
  toggle_region: state.Storage.toggle_region || 'CA',
}))(ETFAnalytics);

const Error = styled.div`
  position: relative;
  width: 400px;
  background: white;
  margin: 4rem auto;
  text-align: center;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 8px;
  font-size: 1.4rem;
  box-shadow: 0 0 3px dimgrey;

  .close {
    position: absolute;
    color: black;
    top: 4px;
    right: 4px;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 1.7rem;
    border-radius: 50%;
    line-height: 1.4rem;
    transform: rotate(45deg);
    transition: all 0.1s ease;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
      color: #fff6f6;
    }
  }
`;
