import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const DateLabel = ({ str, user_region, french }) => (
  <p>
    {str} {user_region === 'CA' ? (french ? 'en CAD' : 'in CAD') : ''}
  </p>
);

DateLabel.propTypes = {
  str: PropTypes.string,
  user_region: PropTypes.string,
  french: PropTypes.bool,
};

DateLabel.defaultProps = {
  str: '',
  user_region: 'CA',
  french: false,
};

export default connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))(DateLabel);
