import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Header, Divider, Button, Dropdown } from 'semantic-ui-react';
import cn from 'classnames';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class Introduction extends Component {
  state = {
    isAssumptionsOpen: false,
  };

  handleProvinceChange = (e, { value }) => {
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { prov: value },
        errors: { prov: false },
        modified: true,
      })
    );
  };

  handleNameChange = (e) => {
    const { name, value } = e.target;
    const { dispatch } = this.props;
    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  render() {
    const { isAssumptionsOpen } = this.state;
    const { activePage, drawdown } = this.props;
    const { inputs, errors } = drawdown;

    return (
      <BlueprintContent style={{ paddingTop: activePage === 0 && '.5rem' }}>
        <div>
          {activePage === 0 ? (
            <Header textAlign="center" size="large">
              <Header.Subheader
                style={{
                  marginTop: '1rem',
                  letterSpacing: '.08px',
                  textAlign: 'left',
                  color: colors.darkerBlack,
                }}
              >
                In the first step, this tool derives the optimal drawdown plan
                for each spouse/partner separately. Then, it checks whether
                pension income splitting can reduce your total tax bill and
                whether you qualify for the spousal credit and transferral of
                unused age and pension credits from your spouse. The estimated
                amounts will be provided for each year of the joint planning
                horizon.
              </Header.Subheader>
              <Header.Subheader
                style={{
                  textAlign: 'left',
                  color: colors.darkerBlack,
                  marginTop: '1rem',
                }}
              >
                One of the first questions many retirees ask is, how long should
                I be planning for? In Canada, a 65-year-old male is expected to
                live for another 19 years, and a 65- year-old female is expected
                to live for another 22 years. But these are just averages. For a
                more customized estimate, you may wish to take this
                questionnaire from Canadian scientists at{' '}
                <a
                  href="https://www.projectbiglife.ca/life-expectancy-home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Project Big Life
                </a>
                .
              </Header.Subheader>
            </Header>
          ) : null}

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {activePage === 0 && (
                <CollapseWrapper open={isAssumptionsOpen}>
                  <Header
                    size="medium"
                    style={{
                      position: 'absolute',
                      top: isAssumptionsOpen ? '-11px' : '2px',
                      left: '.5rem',
                      background: 'white',
                      transition: 'all 300ms ease',
                      padding: '0 0rem 0 .5rem',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledButton
                        icon
                        onClick={() =>
                          this.setState({
                            isAssumptionsOpen: !isAssumptionsOpen,
                          })
                        }
                        isOpen={isAssumptionsOpen}
                      >
                        {isAssumptionsOpen ? '-' : '+'}
                      </StyledButton>
                      Model Features & Assumptions
                    </div>
                  </Header>

                  <Assumptions>
                    <Item>
                      <Header size="small" content="Optimality" />
                      <p style={{ fontSize: '1rem' }}>
                        Optimality means allocating withdrawals between taxable
                        and RRIF accounts so that:
                      </p>
                      <ul>
                        <li>The after-tax income path is smooth</li>
                        <li>
                          The present value of after-tax income is maximized
                        </li>
                        <li>The client-specific bequest amount is satisfied</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="The drawdown plan is not constrained to a sequential withdrawal strategy"
                      />
                      <ul>
                        <li>
                          Because a sequential strategy (taxable accounts then
                          RRIF or vice versa) is not necessarily optimal in
                          minimizing taxes. Therefore, our algorithm solves for
                          the optimal combination of RRIF and taxable accounts
                          to draw from in each year of the planning horizon.
                        </li>
                        <li>
                          Since withdrawing from TFSA has no tax consequences,
                          it is best set aside for a rainy day and for one’s
                          bequest.
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Taxable accounts" />
                      <ul>
                        <li>Dividend tax, tax credit for eligible dividends</li>
                        <li>Capital gains tax</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Public pensions" />
                      <ul>
                        <li>
                          Accommodates standard, deferral or early withdrawal of
                          CPP/OAS
                        </li>
                        <li>OAS clawback</li>
                        <li>CPP/QPP/OAS estimators are provided</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Taxes" />
                      <ul>
                        <li>
                          Federal and provincial tax brackets, indexed to
                          inflation (except for NS, PE)
                        </li>
                        <li>
                          Provincial surtax (ON and PE), surtax brackets indexed
                          to inflation in ON
                        </li>
                        <li>OHIP premium (ON)</li>
                        <li>OAS clawback (recovery tax)</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Tax credits" />
                      <ul>
                        <li>
                          Basic Personal amount
                          <ul>
                            <li>Federal enhanced basic personal amount</li>
                            <li>Indexed to inflation except for NS, PE</li>
                            <li>
                              Additional basic personal amount in NS is not
                              included
                            </li>
                          </ul>
                        </li>
                        <li>
                          Age amount
                          <ul>
                            <li>Indexed to inflation except for NS, PE, MB</li>
                            <li>Additional age amount in NS is not included</li>
                            <li>Not included in QC</li>
                          </ul>
                        </li>
                        <li>
                          Pension income amount
                          <ul>
                            <li>Indexed to inflation only in ON</li>
                            <li>Not included in QC</li>
                          </ul>
                        </li>
                        <li>Dividend tax credit</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Inflation" />
                      <ul>
                        <li>2%</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="RRSP has been converted into a RRIF"
                      />
                      <ul>
                        <li>
                          Calculates minimum RRIF and additional withdrawal (if
                          required) from RRIF
                        </li>
                        <li>
                          Excess RRIF withdrawals will be reallocated to TFSA
                          and/or non-registered investment accounts.
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Bequest" />
                      <ul>
                        <li>Client-specific value, adjusted for inflation</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Company pension" />
                      <ul>
                        <li>
                          Client specifies value and whether it is adjusted for
                          inflation
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="Rental / part-time income... etc."
                      />
                      <ul>
                        <li>
                          Client specifies how long they last and whether this
                          income is adjusted for inflation
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Portfolio options" />
                      <ul>
                        <li>
                          Flat rate of return (fixed income without risk), or
                        </li>
                        <li>
                          10,000 simulations using monthly historical returns of
                          an integrated investment portfolio
                        </li>
                        <li>Sequence-of-returns risk option</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="Income Splitting and Credits"
                      />
                      <ul>
                        <li>
                          The joint planning horizon for income splitting and
                          credit purposes is the shorter one of the two, between
                          you and your spouse/partner.
                        </li>
                        <li>
                          Tax savings (if any) are assumed to be added to
                          spending and not reinvested. It is advisable to check
                          back every year, particularly if your financial
                          circumstances have changed.
                        </li>
                      </ul>
                    </Item>
                    <Item>
                      <Header size="small" content="CPP/QPP Income Sharing" />
                      <ul>
                        <li>
                          CPP/QPP benefit sharing is not included in the
                          optimization. The sharing percentage is fixed and may
                          not be optimal for every year in the planning horizon.
                          Also, the amounts that can be shared are relatively
                          small.
                        </li>
                      </ul>
                    </Item>
                  </Assumptions>
                </CollapseWrapper>
              )}
            </div>
            {activePage === 0 && (
              <p
                style={{
                  color: `${colors.red}`,
                  marginTop: '2rem',
                  textAlign: 'center',
                }}
              >
                All fields are required unless otherwise noted.
              </p>
            )}

            {activePage === 0 ? (
              <React.Fragment>
                <Divider style={{ width: '100%', margin: '3.5rem 0' }} />
                <Header textAlign="center" size="large">
                  <Header.Subheader
                    style={{
                      marginTop: '1rem',
                      textAlign: 'left',
                      color: 'black',
                    }}
                  >
                    Let's start! What is the first name of ….
                  </Header.Subheader>
                </Header>
                <Section single>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div className="title">Spouse / Partner 1</div>
                    <Input
                      name="spouse"
                      type="text"
                      placeholder="Name"
                      value={inputs.spouse}
                      onChange={this.handleNameChange}
                    />
                  </div>
                </Section>
                <Section single>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div className="title">
                      Spouse / Partner 2
                      {/* <Error visible={typeof errors.age === 'string'}>
                {errors.age}
              </Error> */}
                    </div>
                    <Input
                      // className={cn('large', {
                      //   invalid: errors.age ? 'true' : undefined,
                      // })}
                      name="spouse2"
                      type="text"
                      value={inputs.spouse2}
                      placeholder="Name"
                      onChange={this.handleNameChange}
                    />
                  </div>
                </Section>

                <Section single style={{ marginTop: 'rem' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div className="title">
                      <span style={{ fontSize: '1.2rem' }}>
                        Province of residence
                      </span>
                      {/* <Error visible={typeof errors.prov === 'string'}>
                        {errors.prov}
                      </Error> */}
                    </div>
                    <StyledDropdown
                      className={cn('large', {
                        invalid: errors.prov ? 'true' : undefined,
                      })}
                      placeholder="Province"
                      selection
                      options={[
                        { key: 'AB', value: 'AB', text: 'Alberta' },
                        { key: 'BC', value: 'BC', text: 'British Columbia' },
                        { key: 'MB', value: 'MB', text: 'Manitoba' },
                        { key: 'NB', value: 'NB', text: 'New Brunswick' },
                        {
                          key: 'NL',
                          value: 'NL',
                          text: 'Newfoundland & Labrador',
                        },
                        { key: 'NS', value: 'NS', text: 'Nova Scotia' },
                        { key: 'ON', value: 'ON', text: 'Ontario' },
                        {
                          key: 'PE',
                          value: 'PE',
                          text: 'Prince Edward Island',
                        },
                        { key: 'QC', value: 'QC', text: 'Quebec' },
                        { key: 'SK', value: 'SK', text: 'Saskatchewan' },
                      ]}
                      value={inputs.prov}
                      onChange={this.handleProvinceChange}
                    />
                  </div>
                </Section>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </BlueprintContent>
    );
  }
}

Introduction.propTypes = {
  activePage: PropTypes.number.isRequired,
  drawdown: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const BlueprintContent = styled.div`
  width: 100%;
  text-align: left;

  .radio > label {
    font-size: 1.2rem !important;
  }
  .radio {
    margin: 0.5rem 2rem;
  }
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 55rem;
  padding: 0 1rem;
  padding-top: ${(props) => (props.open ? '0rem' : '2rem')};
  margin: 3rem auto 0 auto;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '2000px' : '28px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const Assumptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  text-align: left;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);

  .header {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Item = styled.div`
  display: block;
  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 49.5%;
  margin: 0.5% 0;
  padding: 5px;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;

const Section = styled.section`
  display: flex;
  width: 90%;
  flex-direction: column;
  margin: 1rem 0 1rem 3rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;

    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Input = styled.input`
  padding: 0.4rem;
  max-height: 30px;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 15% !important;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: unset !important;
  min-height: unset !important;
  max-height: 28px;
  padding: 0.5rem !important;
  min-width: unset !important;
  width: 35% !important;
  margin: 0 !important;

  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }

  i {
    padding: 0.5rem !important;
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }
`;
