export const PORTFOLIOS = 'PORTFOLIOS';
export const PLANNING = 'PLANNING';
export const TOOLS = 'TOOLS';
export const ACCOUNTS_ANALYZE = 'ACCOUNTS_ANALYZE';
export const ACCOUNTS_ADDITIONAL_FEATURES = 'ACCOUNTS_ADDITIONAL_FEATURES';
export const ACCOUNTS_REBALANCE = 'ACCOUNTS_REBALANCE';
export const ACCOUNTS_SAVE_AS_PORTFOLIO = 'ACCOUNTS_SAVE_AS_PORTFOLIO';
export const ACCOUNTS_COMPARE = 'ACCOUNTS_COMPARE';

export const PORTFOLIOS_CURATE = 'PORTFOLIOS_CURATE';
export const PORTFOLIOS_CREATE_ETF = 'PORTFOLIOS_CREATE_ETF';
export const PORTFOLIOS_CREATE_CUSTOM = 'PORTFOLIOS_CREATE_CUSTOM';
export const PORTFOLIOS_CREATE_NEW = 'PORTFOLIOS_CREATE_NEW';
export const PORTFOLIOS_CREATE_ASSET_ALLOCATION =
  'PORTFOLIOS_CREATE_ASSET_ALLOCATION';
export const PORTFOLIOS_VIEW_SAVED = 'PORTFOLIOS_VIEW_SAVED';
export const PORTFOLIOS_VIEW_MODEL = 'PORTFOLIOS_VIEW_MODEL';

export const PLANNING_ACCUMULATION = 'PLANNING_ACCUMULATION';
export const PLANNING_ACCUMULATION_RETIREMENT_BLUEPRINT =
  'PLANNING_ACCUMULATION_RETIREMENT_BLUEPRINT';
export const PLANNING_ACCUMULATION_REVISIT_BLUEPRINT =
  'PLANNING_ACCUMULATION_REVISIT_BLUEPRINT';
export const PLANNING_ACCUMULATION_TARGET_WEALTH =
  'PLANNING_ACCUMULATION_TARGET_WEALTH';
export const PLANNING_ACCUMULATION_TARGET_SAVING =
  'PLANNING_ACCUMULATION_TARGET_SAVING';

export const PLANNING_DECUMULATION = 'PLANNING_DECUMULATION';
export const PLANNING_DECUMULATION_PORTFOLIO_LONGEVITY =
  'PLANNING_DECUMULATION_PORTFOLIO_LONGEVITY';
export const PLANNING_DECUMULATION_RUIN_PROBABILITY =
  'PLANNING_DECUMULATION_RUIN_PROBABILITY';
export const PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN =
  'PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN';
export const PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_TARGET_SPENDING =
  'PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_TARGET_SPENDING';
export const PLANNING_DECUMULATION_REVISIT_DRAWDOWN =
  'PLANNING_DECUMULATION_REVISIT_DRAWDOWN';

export const TOOLS_FUND_LOOKUP = 'TOOLS_FUND_LOOKUP';
export const TOOLS_FUND_COMPARISON = 'TOOLS_FUND_COMPARISON';
export const TOOLS_OAS_ESTIMATOR = 'TOOLS_OAS_ESTIMATOR';
export const TOOLS_CPP_ESTIMATOR = 'TOOLS_CPP_ESTIMATOR';
export const TOOLS_CHARTING_TOOL = 'TOOLS_CHARTING_TOOL';
export const TOOLS_ACCOUNTSETUP_TOOL = 'TOOLS_ACCOUNTSETUP_TOOL';
export const TOOLS_CORRELATION_MATRIX = 'TOOLS_CORRELATION_MATRIX';
export const TOOLS_STOCK_EXPOSURE = 'TOOLS_STOCK_EXPOSURE';
export const PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_COUPLES =
  'PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_COUPLES';
