import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Table } from 'components';
import { translateText } from '../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
class ExposureTable extends Component {
  isInt = (num) => (num * 100) % 1 === 0;

  isLong = (data) => data.length > 11;

  renderText = (value) => {
    const { french } = this.props;
    const displayText = translateText(value, french);

    if (value === 'Port. Specific Risk') {
      return (
        <div>
          {displayText}
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ fontSize: '15px', verticalAlign: 'initial' }}
              />
            }
            position="right center"
            wide
          >
            Portfolio-specific risk is the portion of your portfolio's
            volatility that cannot be explained by movements in regional stock
            markets, global stock sectors, macroeconomic variables, or equity
            style factors (depending on the type of analysis in question). For
            example, your risk exposure to regional stock markets may be minimal
            if you hold predominantly fixed income products.
          </Popup>
        </div>
      );
    } else if (value === 'Other') {
      return (
        <div>
          {displayText}
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ fontSize: '15px', verticalAlign: 'initial' }}
              />
            }
            position="right center"
            wide
          >
            See important information about balanced funds/asset allocation
            funds above.
          </Popup>
        </div>
      );
    } else if (value === 'Cash') {
      return (
        <div>
          {displayText}
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ fontSize: '15px', verticalAlign: 'initial' }}
              />
            }
            position="right center"
          >
            Cash is non-interest bearing cash in your account if you chose to
            analyze with cash.
          </Popup>
        </div>
      );
    }

    return <div>{displayText}</div>;
  };

  render() {
    const { align, headers, isExcluded, totals, data } = this.props;

    return (
      <div style={{ overflow: 'auto', height: 'auto', marginTop: '3rem' }}>
        <Table basic textAlign={align || 'center'}>
          <Table.Header>
            <Table.Row>
              {headers.map((header, idx) => (
                <Table.HeaderCell key={idx}>{header}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              /* eslint-disable */
              data.map((row, idx) => (
                <Table.Row key={idx} negative={isExcluded}>
                  {Object.values(row).map((value, idx2) =>
                    Number(value) ? (
                      <Table.Cell key={idx2}>
                        {this.props.portfolioLevel !== undefined &&
                        this.props.portfolioLevel
                          ? `${Math.round(value * 10000) / 10000}`
                          : this.isInt(value)
                          ? `${Math.round(value * 1000) / 10}%`
                          : `${Math.round(value * 10000) / 100}%`}
                      </Table.Cell>
                    ) : (
                      <Table.Cell key={idx2} style={{ maxWidth: '21rem' }}>
                        {!value ? '-' : this.renderText(value)}
                      </Table.Cell>
                    )
                  )}
                </Table.Row>
              ))
              /* eslint-enable */
            }
            {totals !== undefined ? (
              <Table.Row>
                {
                  /* eslint-disable */
                  totals.map((total, idx) => (
                    <Table.Cell key={idx}>
                      <b>{total}</b>
                    </Table.Cell>
                  ))
                }
              </Table.Row>
            ) : (
              <br />
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

ExposureTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  align: PropTypes.string,
  isExcluded: PropTypes.bool,
  totals: PropTypes.array,
  portfolioLevel: PropTypes.bool,
  french: PropTypes.bool,
};

ExposureTable.defaultProps = {
  align: '',
  isExcluded: false,
  totals: [],
  portfolioLevel: false,
  french: false,
};

export default ExposureTable;
