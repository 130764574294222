import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Popup, Icon, Header, Grid, Divider, Flag } from 'semantic-ui-react';

import { Accounts, Portfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import {
  getHoldTotalMarketValue,
  notWealthica,
  hasNegativeWeight,
} from 'utils/helpers';
import { ACCOUNTS_ANALYZE, ACCOUNTS_ADDITIONAL_FEATURES } from 'utils/features';
import { Segment, Button, Message, Feature } from 'components';
import { DeleteModal, EditModal } from './components';
import { HoldingTable, GicTable, AssetChart } from '../components';

@connect((state) => ({
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
}))
export default class IndividualAccount extends Component {
  state = {
    isOpen: false,
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (
      history.location.pathname !== '/accounts/user' &&
      history.location.pathname !== '/portfolio/unsaved/overview'
    ) {
      dispatch(Accounts.saveSingleAccInfo(''));
    }
  }

  getFlag = (currency) => (currency === 'USD' ? 'us' : 'ca');

  closeModal = () =>
    this.setState({
      isOpen: false,
    });

  analyze = (holdings, region) => {
    const { dispatch, history, account } = this.props;
    const gicHoldings = holdings.filter((holding) => holding.gic_ticker);
    Storage.removeItem('pwpa-has-exclude-holding');

    return dispatch(
      Accounts.analyze(holdings, history, account, gicHoldings, region)
    );
  };

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  round = (num) => (!num ? 0 : Math.round(num * 10000) / 10000);

  relinkAccount = (account, type) => {
    const { history } = this.props;

    return history.push('/accounts/yodlee/link', {
      id: account.siteAccountId,
      type,
    });
  };

  hasCurrencyOptions = (region, currency) =>
    region === 'CA' && currency === 'USD';

  renderErrorMessage = (update_status) => {
    switch (update_status) {
      case 'LOGIN_REQUIRED':
        return 'There was an error refreshing your account. If you have changed your password or security questions at your financial institution, please re-link your account and provide the correct information by clicking on the button on the right.';
      case 'MFA_REQUIRED':
        return 'There was an error refreshing your account, because your financial institution uses multi-factor authentication. Please re-link your account and provide the additional information by clicking on the button on the right.';
      default:
        return 'There was an error refreshing your account, because our aggregation service provider, Yodlee, is having issues connecting to your financial institution.';
    }
  };

  render() {
    const { isOpen } = this.state;
    const {
      match,
      history,
      account,
      dispatch,
      pwpaExchangeRate,
      user_region,
      partnerName,
    } = this.props;
    const validHoldings = account.holdings.filter(
      (holding) => !!holding.security_detail
    );
    const usdHolding = validHoldings.find((holding) => {
      const price = holding.security_detail
        ? holding.security_detail.unadjusted_closing_price.USD
        : holding.unadjusted_closing_price.USD;

      return holding.default_currency === 'USD' && !!price;
    });
    const excludeGicHoldings = account.holdings.filter(
      (holding) => !holding.gic_ticker
    );
    const gicHolding = account.holdings.filter((holding) => holding.gic_ticker);
    const linked = account.provider !== 'wsc' && !notWealthica(partnerName);
    const hasNegativeWeights = hasNegativeWeight(account.holdings);

    return (
      <Segment>
        <Header size="large" content={account.user_institution} />

        <Feature name={ACCOUNTS_ADDITIONAL_FEATURES}>
          <MoreActions>
            <div className="trigger">...</div>
            <div className="action-item">
              <Popup
                trigger={
                  <Button
                    className="left"
                    color={colors.red}
                    style={{
                      backgroundColor: 'white',
                      display: 'block',
                      width: '100%',
                    }}
                    icon="law"
                    // onClick commented out per request to remove this functionality. Leaving as comment for future reference. Requires dispatch import and Alert import from Actions.
                    // onClick={() => {
                    //   if (excludeGicHoldings.length !== 0) {
                    //     history.push('/accounts/overview/compare');
                    //   } else {
                    //     dispatch(
                    //       Alert.show({
                    //         type: 'warning',
                    //         msg: 'Not enough matched holdings to compare this account',
                    //       })
                    //     );
                    //   }
                    // }}
                  >
                    What-if
                  </Button>
                }
                position="bottom"
                wide
              >
                <Popup.Header>
                  The “What-if” scenario analysis has been moved to the
                  Portfolio Scorecard
                </Popup.Header>
                <Popup.Content>
                  <br />
                  Analyze your account(s) first, and then click the{' '}
                  <Icon
                    name="balance scale"
                    size="medium"
                    color={colors.red}
                  />{' '}
                  icon on the left-hand-side of the Scorecard.
                </Popup.Content>
              </Popup>
              <Button
                className="left"
                color={colors.red}
                icon="money"
                onClick={() => history.push(`/accounts/${account.id}/cash`)}
                style={{ width: '130px' }}
              >
                Cash
              </Button>
              <Button
                className="left"
                icon="random"
                color={colors.red}
                onClick={() =>
                  history.push(`/accounts/${match.params.id}/rebalance`)
                }
                style={{
                  backgroundColor: 'white',
                  display: 'block',
                  width: '130px',
                  margin: '5px 0 0 0',
                }}
              >
                Rebalance
              </Button>
            </div>
          </MoreActions>
        </Feature>

        <Divider fitted />

        <Message
          warning
          icon
          visible={account.update_status && account.update_status !== 'OK'}
          inlineButton={
            account.update_status === 'MFA_REQUIRED' ||
            account.update_status === 'LOGIN_REQUIRED'
          }
        >
          <Icon name="exclamation triangle" />
          <Message.Content>
            {this.renderErrorMessage(account.update_status)}
          </Message.Content>
          {account.update_status === 'MFA_REQUIRED' ||
          account.update_status === 'LOGIN_REQUIRED' ? (
            <Message.Actions>
              <Button
                className="basic"
                onClick={() => {
                  const type =
                    account.update_status === 'MFA_REQUIRED' ? 'MFA' : 'LOGIN';

                  return this.relinkAccount(account, type);
                }}
              >
                Re-link account
              </Button>
            </Message.Actions>
          ) : null}
        </Message>

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={7}>
              <div style={{ marginTop: '1.3rem' }}>
                <div style={{ float: 'left', padding: '0 2rem 1rem 0' }}>
                  <Header
                    size="medium"
                    content="Account Value:"
                    sub
                    style={{ margin: 0, fontWeight: 700 }}
                  />
                  <Value className="main">
                    {`$${this.parseNum(account.balance)}`}
                    <Flag
                      name={this.getFlag(account.currency)}
                      style={{
                        position: 'relative',
                        bottom: '7px',
                        marginLeft: 7,
                      }}
                    />
                  </Value>
                </div>

                <div style={{ float: 'left', padding: '0 0 1rem 0' }}>
                  <Header
                    size="medium"
                    content="Daily Change:"
                    sub
                    style={{ margin: 0 }}
                  />
                  <Value>{`${account.change || 0}%`}</Value>
                </div>
              </div>

              <div style={{ clear: 'both' }}>
                <Header
                  size="medium"
                  content="Holdings Value:"
                  sub
                  style={{ margin: 0 }}
                />
                <Value>{`$${this.parseNum(
                  account.balance - account.cash
                )}`}</Value>
              </div>
              <div style={{ marginTop: '1.3rem' }}>
                <Header
                  sub
                  size="medium"
                  style={{ margin: 0 }}
                  content={
                    <div>
                      cash value:
                      {account.provider === 'wsc' && (
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{ fontSize: '1rem', verticalAlign: 'top' }}
                            />
                          }
                          position="right center"
                          content="Currently, cash value is not included in custom-build accounts."
                        />
                      )}
                    </div>
                  }
                />
                <Value>{`$${this.parseNum(account.cash)}`}</Value>
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              {!account.holdings.length || !validHoldings.length ? null : (
                <Segment basic compact>
                  <Header
                    size="medium"
                    textAlign="center"
                    style={{ margin: '0 0 1rem 0' }}
                  >
                    Holdings by Asset Class&nbsp;
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="top center"
                      content="This is your net asset allocation, reflecting any balanced funds/asset allocation funds that you may have."
                      wide
                    />
                  </Header>
                  <AssetChart accounts={[account]} overview={account} />
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <HoldingTable
          totalMarketValue={getHoldTotalMarketValue(
            account,
            account.usd2cad,
            user_region
          )}
          individualAccount
          holdings={excludeGicHoldings}
          account={account}
          title="Holdings"
          rate={account.usd2cad}
          user_region={user_region}
        />
        <GicTable
          user_region={user_region}
          totalMarketValue={getHoldTotalMarketValue(
            account,
            account.usd2cad,
            user_region
          )}
          gicHoldings={gicHolding}
          title="GIC/HISAs"
          individual
          rate={pwpaExchangeRate}
          linked={linked}
        />
        {excludeGicHoldings.length !== 0 && (
          <P>
            {user_region === 'CA' ? (
              <React.Fragment>
                Currently, we have data for Canadian and US stocks/ETFs,
                Canadian mutual and segregated funds, and CAD/HISA funds.
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ fontSize: '15px', verticalAlign: 'initial' }}
                    />
                  }
                  position="top center"
                  content="CAD/USD HISA funds are included using the historical rates of a representative bank-owned CAD/USD HISA fund."
                />
                <br />
                Any other securities will be excluded from the analysis.
              </React.Fragment>
            ) : (
              'Currently we only have data for U.S. listed stocks and ETFs, U.S. mutual funds, Bitcoin, and Ethereum. Any other security will be excluded from the analysis.'
            )}
          </P>
        )}
        {(!!usdHolding || account.currency === 'USD') &&
          excludeGicHoldings.length !== 0 &&
          user_region === 'CA' && (
            <P>USD/CAD exchange rate used is {pwpaExchangeRate || 1.3}</P>
          )}
        <div style={{ marginTop: '1.5rem' }}>
          {account.provider === 'wsc' && (
            <Button
              className="left"
              icon="edit"
              margin="0 0.5rem 0 0"
              color={colors.blue}
              floated="left"
              onClick={() => history.push(`/accounts/custom/${account.id}`)}
            >
              Edit Account
            </Button>
          )}
          {account.provider === 'wsc' && (
            <Button
              className="left"
              icon="delete"
              color={colors.red}
              floated="left"
              onClick={() => this.setState({ isOpen: 'delete' })}
            >
              Delete Account
            </Button>
          )}
        </div>
        <Feature name={ACCOUNTS_ANALYZE}>
          {/* {this.hasCurrencyOptions(user_region, account.currency) ? (
            <CurrencyOption>
              <Button
                className="left grey-focus2"
                icon="line chart"
                color={colors.darkBlue}
              >
                Analyze
              </Button>
              <div className="currency_item">
                <StyledButton
                  className="basic"
                  color={colors.darkBlue}
                  onClick={() => {
                    this.analyze(account.holdings, 'US');
                    dispatch(Storage.setItem('toggle_region', 'US'));
                    dispatch(Portfolio.notSaveUrlSc());
                  }}
                >
                  <div className="flag">
                    <Flag name="us" style={{ height: 'inherit' }} />
                  </div>
                  <div style={{ marginLeft: '36px' }}>In USD</div>
                </StyledButton>
                <StyledButton
                  className="basic"
                  color={colors.darkBlue}
                  onClick={() => {
                    this.analyze(account.holdings, 'CA');
                    dispatch(Storage.setItem('toggle_region', 'CA'));
                    dispatch(Portfolio.notSaveUrlSc());
                  }}
                >
                  <div className="flag">
                    <Flag name="ca" style={{ height: 'inherit' }} />
                  </div>
                  <div style={{ marginLeft: '36px' }}>In CAD</div>
                </StyledButton>
              </div>
            </CurrencyOption>
          ) : (
            <Button
              className="left"
              icon="line chart"
              color={colors.darkBlue}
              onClick={() => {
                this.analyze(account.holdings, user_region);
                dispatch(Storage.setItem('toggle_region', user_region));
                dispatch(Portfolio.notSaveUrlSc());
              }}
              style={{
                position: 'absolute',
                top: '1.6rem',
                right: '5rem',
                zIndex: 3,
              }}
            >
              Analyze
            </Button>
          )} */}
          {hasNegativeWeights ? (
            getHoldTotalMarketValue(account) < 0 ? (
              <p
                style={{
                  color: `${colors.red}`,
                  fontWeight: 'bold',
                  position: 'absolute',
                  top: '2rem',
                  right: '15rem',
                }}
              >
                <Icon name="warning" style={{}} />
                Analysis will not be performed for net short portfolio(s).
              </p>
            ) : (
              <p
                style={{
                  color: `${colors.red}`,
                  fontWeight: 'bold',
                  position: 'absolute',
                  top: '2rem',
                  right: '15rem',
                }}
              >
                <Icon name="warning" style={{}} />
                Note that short positions will be excluded from the analysis.
              </p>
            )
          ) : null}
          <Button
            className={cn('left', {
              disabled: getHoldTotalMarketValue(account) < 0,
            })}
            icon="line chart"
            color={colors.darkBlue}
            onClick={() => {
              this.analyze(account.holdings);
              dispatch(Portfolio.notSaveUrlSc());
            }}
            style={{ position: 'absolute', top: '1.6rem', right: '5rem' }}
            disabled={getHoldTotalMarketValue(account) < 0}
          >
            Analyze
          </Button>
        </Feature>
        <div style={{ clear: 'both' }} />

        <EditModal
          account={account}
          close={this.closeModal}
          open={isOpen === 'edit'}
        />
        <DeleteModal
          account={account}
          close={this.closeModal}
          open={isOpen === 'delete'}
        />
      </Segment>
    );
  }
}

IndividualAccount.propTypes = {
  account: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  pwpaExchangeRate: PropTypes.number,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

IndividualAccount.defaultProps = {
  account: {},
  dispatch: () => {},
  history: {},
  match: {},
  pwpaExchangeRate: 0,
  user_region: 'CA',
  partnerName: '',
};

const Value = styled.div`
  font-size: 1.8rem;
  padding: 7px 0;

  &.main {
    font-size: 2.4rem;
    font-weight: 700;
    color: ${colors.orange};
  }
`;

const MoreActions = styled.div`
  .action-item {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 2rem 0.5rem 0.5rem 0.5rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, top 150ms ease;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: 28px;
      left: 0;
      height: 135px;
      width: 133px;
      background: white;
      border-radius: 4px;
    }

    &:hover {
      opacity: 1;
      visibility: visible;
      top: 40px;
    }
  }

  .trigger {
    z-index: 2;
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    font-size: 30px;
    background: white;
    color: rgba(0, 0, 0, 0.6);
    height: 35px;
    width: 35px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
      border: 1px solid ${colors.darkBlue};
      color: ${colors.darkBlue};

      & ~ .action-item {
        opacity: 1;
        visibility: visible;
        top: 40px;
      }
    }
  }

  &:hover ~ .trigger {
    border: 1px solid ${colors.darkBlue};
    color: ${colors.darkBlue};
  }

  button {
    display: block;
    margin: 5px auto;
    background-color: white;
  }
`;

// const CurrencyOption = styled.div`
//   position: absolute;
//   top: 1.6rem;
//   right: 5rem;
//   z-index: 3;
//   cursor: pointer;
//   &:hover {
//     & .currency_item {
//       opacity: 1;
//       visibility: visible;
//       top: 18px;
//       transition: opacity 200ms ease, top 150ms ease;
//     }
//   }
//   .currency_item {
//     position: absolute;
//     top: 0px;
//     right: 0;
//     padding: 2rem 0 0 0;
//     opacity: 0;
//     visibility: hidden;
//   }
// `;

// const StyledButton = styled.div`
//   position: relative;
//   border-radius: 4px;
//   box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
//   font-size: 0.9rem;
//   display: inline-block;
//   min-height: 1em;
//   min-width: 110px;
//   height: 36px;
//   border: none;
//   text-align: center;
//   outline: 0;
//   transition: all 0.3s ease;
//   overflow: hidden;
//   color: rgba(0, 0, 0, 0.6);
//   background-color: transparent;
//   vertical-align: top;
//   line-height: 36px !important;
//   user-select: none !important;
//   margin-top: 5px !important;
//   background-color: white;
//   &:hover {
//     box-shadow: inset 0 0 0 1.5px ${colors.darkBlue} !important;
//     color: rgba(0, 0, 0, 0.6);
//   }
//   .flag {
//     height: 100%;
//     position: absolute;
//     color: white;
//     top: 0;
//     width: 36px;
//     background-color: ${colors.darkBlue};
//     padding-right: 36px;
//     &:before {
//       position: absolute;
//       line-height: 1;
//       font-size: 1em;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//   }
// `;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  margin: 0.7rem auto;
  max-width: 100%;
  line-height: 1.1rem;
  text-align: center;
`;
