import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Icon, Popup, Button, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Api } from 'actions';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import Bar from './Bar';

class Holdings extends Component {
  state = {
    tickerExchanges: {},
  };
  componentWillMount = () => {
    const { holdings, dispatch } = this.props;

    const tickers_string = holdings
      .filter((item) => item.name !== 'GIC or HISA')
      .map((item) => item.ticker)
      .join(',');

    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${tickers_string}`,
      })
    ).then((result) => {
      this.setState({ tickerExchanges: result });
    });
  };

  getExchangeSymbol(ticker, tickerExchanges) {
    for (let i = 0; i < tickerExchanges.length; i++) {
      if (tickerExchanges[i].ticker === ticker) {
        return tickerExchanges[i].exchange_symbol;
      }
    }
    return '';
  }

  isExecuteVisible = (portfolio) => {
    if (!portfolio) {
      return false;
    }

    if (
      portfolio.is_linked ||
      portfolio.portfolio_type === 'custom' ||
      portfolio.portfolio_type === 'quantified'
    ) {
      return false;
    }

    return true;
  };

  handleClick = (to) => {
    const { history } = this.props;

    if (to) {
      return history.push(to);
    }

    return false;
  };

  isExecuteVisible = (portfolio) => {
    if (!portfolio) {
      return false;
    }

    if (
      portfolio.is_linked ||
      portfolio.portfolio_type === 'custom' ||
      portfolio.portfolio_type === 'quantified'
    ) {
      return false;
    }

    return true;
  };

  handleClick = (to) => {
    const { history } = this.props;

    if (to) {
      return history.push(to);
    }

    return false;
  };

  isLinked = (port) => {
    if (!port) {
      return false;
    }

    if (
      port.portfolio_type === 'custom' ||
      port.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return port.is_linked;
  };

  generatePDF = () => {
    const { history, match } = this.props;

    return history.push(`/portfolio/${match.params.id}/pdf`);
  };

  render() {
    const {
      holdings,
      hasExcludedHolding,
      portfolio,
      dispatch,
      match,
      history,
      id,
      partnerName,
      french,
    } = this.props;
    const { tickerExchanges } = this.state;

    return (
      <Container>
        <Header size="medium" style={{ position: 'relative' }}>
          {french ? 'Portefeuille' : 'Portfolio'}
          {this.isExecuteVisible(portfolio) && (
            <ETFButton
              style={{
                position: 'absolute',
                top: '0',
                right: '175px',
                fontSize: '0.85em',
                padding: '0.35em 1.3em',
                fontWeight: '400',
              }}
              onClick={() => this.handleClick(`/portfolio/${id}/execute`)}
            >
              {french ? 'Voir les FNBs' : 'View ETFs'}
            </ETFButton>
          )}
          {this.isExecuteVisible(portfolio) && (
            <ETFButton
              style={{
                position: 'absolute',
                top: '0',
                right: '175px',
                fontSize: '0.85em',
                padding: '0.35em 1.3em',
                fontWeight: '400',
              }}
              onClick={() => this.handleClick(`/portfolio/${id}/execute`)}
            >
              {french ? 'Voir les FNBs' : 'View ETFs'}
            </ETFButton>
          )}
          <Button
            active
            basic
            icon
            labelPosition="right"
            style={{
              position: 'absolute',
              top: '0',
              right: !this.isLinked(portfolio) ? '0' : '150px',
              fontSize: '0.85em',
              padding: '0.35em 0.25em',
              width: french ? '160px' : '140px',
            }}
            onClick={this.generatePDF}
          >
            {french ? 'Imprimer/PDF' : 'Print/PDF'}
            <Icon
              name="download"
              style={{
                fontSize: '12px',
                verticalAlign: 'initial',
                color: 'dimgrey',
              }}
            />
          </Button>
          {!this.isLinked(portfolio) ? null : (
            <Popup
              trigger={
                <Button
                  active
                  basic
                  icon
                  labelPosition="right"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    fontSize: '0.85em',
                    padding: '0.35em 0.20em',
                    width: '140px',
                    margin: '0 !important',
                  }}
                >
                  {french ? 'Directives' : 'Guidelines'}
                  <Icon
                    name="info circle"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                </Button>
              }
              position="top center"
              style={{ maxWidth: '650px' }}
            >
              <Popup.Content>
                {french
                  ? 'Afin de veiller à la pertinence de l’analyse statistique, Wealthscope impose les règles suivantes lors de l’analyse de votre portefeuille'
                  : 'To protect the integrity of the statistical analysis, Wealthscope implements the following rules when analyzing your portfolio:'}
                <br />
                <br />
                {french
                  ? 'Si tous les titres du portefeuille ont un historique supérieur ou égal à 48 mois, l’analyse sera effectuée au complet. Autrement, nous excluons les titres suivants'
                  : 'If all of the securities in the portfolio have more than or equal to 48 months of history, all of the analysis will be performed. Otherwise, we exclude the following securities:'}
                <br />
                <ul>
                  <li>
                    {french
                      ? 'Les titres dont l’historique est inférieur à 36 mois;'
                      : 'securities that have less than 36 months of history;'}
                  </li>
                  <li>
                    {french
                      ? 'Les titres dont l’historique se trouve entre 36 et 47 mois et qui comptent collectivement pour moins de 10 % du portefeuille'
                      : 'securities that have between 36 and 47 months of history and collectively weigh less than 10% of the portfolio.'}
                  </li>
                </ul>
                {french
                  ? 'La période d’analyse pour les titres de cette catégorie qui se retrouvent dans l’analyse sera l’historique commun le plus long.'
                  : 'For securities included in the analysis, the period of analysis will be the longest common history.'}
                <br />
                <br />
                {french
                  ? 'Si tous les titres du portefeuille ont un historique inférieur à 36 mois, nous ne fournissons que des informations sommaires pour le portefeuille en utilisant l’historique commun le plus long.'
                  : 'If all of the securities in the portfolio have less than 36 months of history, we provide only basic summary information for the portfolio using the longest common history.'}
                <br />
                <br />
                {french
                  ? 'Si l’historique d’un FNB de gestion passive est insuffisant, mais qu’il existe un substitut semblable, ce dernier servira aux fins de l’analyse. Pour certains FNB de gestion passive dont l’historique est court, nous pouvons utiliser le rendement de l’indice (moins le RFG) avant la création.'
                  : 'If a passive ETF has insufficient history but there is a close substitute, the latter will be used in the analysis. For some passive ETFs with short history, we may use the index returns (minus the MER) prior to inception.'}
                <br />
              </Popup.Content>
            </Popup>
          )}
          {portfolio.account && Object.keys(portfolio.account).length ? (
            <Header.Subheader style={{ transform: 'translateY(5px)' }}>
              {portfolio.account.user_institution ===
              'Overview; All Accounts' ? (
                <React.Fragment>
                  {french ? 'Vue d’ensemble' : 'Overview'}
                  <br />
                  {french ? 'Tous les Comptes' : 'All Accounts'}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {portfolio.account.user_institution ? (
                    <Value
                      className={
                        portfolio.account.user_institution &&
                        portfolio.account.user_institution.length > 35
                          ? 'long'
                          : ''
                      }
                    >
                      {portfolio.account.user_institution}
                    </Value>
                  ) : null}
                  <br />
                  {portfolio.account.label}
                </React.Fragment>
              )}
            </Header.Subheader>
          ) : portfolio.name ? (
            <Header.Subheader style={{ transform: 'translateY(5px)' }}>
              {portfolio.name}
              {portfolio.description ? (
                <div>{portfolio.description}</div>
              ) : null}
            </Header.Subheader>
          ) : null}
        </Header>

        <Divider
          style={{
            margin:
              !isGlobeInvestor(partnerName) &&
              ((!portfolio.name && !portfolio.description) ||
                !portfolio.account)
                ? '12px 0 12px 0'
                : '12px 0',
          }}
        />
        <Wrapper limitHeight={holdings.length > 8}>
          {holdings.map((val) => (
            <Bar
              key={val.ticker}
              ticker={this.isLinked(portfolio) ? val.ticker : ''}
              name={this.isLinked(portfolio) ? val.name : val.wsName}
              exchange_symbol={this.getExchangeSymbol(
                val.ticker,
                tickerExchanges
              )}
              // the value I need
              weight={val.weight}
              error={val.isExcluded}
              replaced={val.replacedBy}
              dispatch={dispatch}
              id={match.params.id}
              history={history}
            />
          ))}
        </Wrapper>
        {hasExcludedHolding ? (
          <p style={{ color: colors.hintRed, fontSize: '1rem' }}>
            {french
              ? 'Veuillez vérifier la liste des avoirs pour savoir si certains sont exclus de l’analyse. Glissez votre curseur sur « Lignes directrices » ci-dessus pour connaître nos règles. Pour utiliser tous les avoirs dans l’analyse, utilisez le bouton de basculement en haut à gauche.'
              : 'Please check the list of holdings to see if any of your holdings have been excluded from the analysis. Hover over "Guidelines" above for our rules. To use all of the holdings in the analysis, use the toggle at the top left.'}
          </p>
        ) : null}
      </Container>
    );
  }
}

Holdings.propTypes = {
  portfolio: PropTypes.object.isRequired,
  holdings: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hasExcludedHolding: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  partnerName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  french: PropTypes.bool,
};

export default connect((state) => ({
  printParams: state.Analytics.printParams,
  esg: state.Analytics.esg,
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))(Holdings);

Holdings.defaultProps = { french: false };

const Container = styled.div`
  border-radius: 8px;
  width: 50%;
  background: white;
  margin-bottom: 50px;
`;

const Wrapper = styled.div`
  height: ${(props) => (props.limitHeight ? '430px' : 'auto')};
  overflow: auto;
`;

const ETFButton = styled(Button)`
  background-color: ${colors.teal} !important;
  color: rgba(0, 0, 0, 0.6) !important;
`;

const Value = styled.span`
  position: absolute;
  left: 0rem;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  transition: background 0.1s ease;
  z-index: 9999;
  &.long:hover {
    background: ${colors.orange};
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    width: auto;
    max-width: unset;
    white-space: normal;
    overflow: unset;
  }
`;
