import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';

import { colors } from 'utils/colors';

class AccountsDropdown extends Component {
  state = {
    accountsValue: 0,
    expand: false,
    selectedAccounts: {},
    updateMesssage: false,
  };
  componentDidMount = () => {
    const {
      accountList,
      selectedAccList = [],
      savedAccountsValue,
    } = this.props;

    // Initialize state with props received at mount time
    this.setState({
      accountsValue: savedAccountsValue,
      updateMesssage: savedAccountsValue && !selectedAccList.length,
      selectedAccounts: selectedAccList.length
        ? selectedAccList.reduce((result, accountId) => {
            const account = (accountList || []).find(
              (acc) => Number(acc.id) === Number(accountId)
            );

            if (account) result[accountId] = account.balanceCad;

            return result;
          }, {})
        : {},
    });
  };

  componentDidUpdate = (prevProps) => {
    const { accountList, selectedAccList, savedAccountsValue } = this.props;

    if (
      prevProps.accountList !== accountList ||
      prevProps.selectedAccList !== selectedAccList ||
      prevProps.savedAccountsValue !== savedAccountsValue
    ) {
      this.setState({
        accountsValue: savedAccountsValue,
        updateMesssage: savedAccountsValue && !selectedAccList.length,
        selectedAccounts:
          selectedAccList && selectedAccList.length >= 1
            ? selectedAccList.reduce((result, accountId) => {
                const account = (accountList || []).find(
                  (acc) => Number(acc.id) === Number(accountId)
                );

                if (account) result[accountId] = account.balance;

                return result;
              }, {})
            : {},
      });
    }
  };

  getAccountLabel = (id) => {
    const { accountList } = this.props;
    const account = accountList.find((acc) => Number(acc.id) === Number(id));

    return account.label || account.user_institution;
  };

  getConvertedList = () => {
    const { accountList, user_region, exchangeRate } = this.props;
    const accountListCopy = Object.create(accountList);
    if (user_region === 'US') {
      for (let i = 0; i < accountListCopy.length; i += 1) {
        accountListCopy[i].balanceCad = accountListCopy[i].balance;
      }
    } else if (user_region === 'CA') {
      for (let i = 0; i < accountListCopy.length; i += 1) {
        if (
          accountListCopy[i].currency === 'USD' &&
          !accountListCopy[i].balanceCad
        ) {
          accountListCopy[i].balanceCad =
            Math.round(
              (accountListCopy[i].balance * exchangeRate + Number.EPSILON) * 100
            ) / 100;
        } else if (
          accountListCopy[i].currency === 'CAD' &&
          !accountListCopy[i].balanceCad
        ) {
          accountListCopy[i].balanceCad = accountListCopy[i].balance;
        }
      }
    }
    return accountListCopy;
  };

  getCurrency = () => {
    const { user_region } = this.props;
    if (user_region === 'US') return 'USD';
    return 'CAD';
  };

  selectAccount = (account) => {
    const { selectedAccounts } = this.state;

    let updateSelectedAccList;
    const allAccountsSelected =
      Object.keys(selectedAccounts).length === this.getConvertedList().length;

    if (account.id === 'select-all') {
      // If "Select All" is clicked, toggle all accounts
      if (allAccountsSelected) {
        updateSelectedAccList = {}; // Unselect all
      } else {
        // Select all accounts
        updateSelectedAccList = this.getConvertedList().reduce((acc, curr) => {
          acc[curr.id] = curr.balanceCad;
          return acc;
        }, {});
      }
    } else {
      // Toggle individual account selection
      updateSelectedAccList = {
        ...selectedAccounts,
        [account.id]:
          selectedAccounts[account.id] === undefined
            ? account.balanceCad
            : undefined,
      };

      // Remove the account if deselected (null or undefined)
      if (updateSelectedAccList[account.id] === undefined) {
        delete updateSelectedAccList[account.id];
      }
    }

    this.setState({
      accountsValue: Object.values(updateSelectedAccList).reduce(
        (sum, value) => (sum += value || 0),
        0
      ),
      selectedAccounts: updateSelectedAccList,
      updateMesssage: false,
    });
  };

  renderAccounts = (accountList, selectedAccounts, excludedListMap) => {
    const allSelected =
      Object.keys(selectedAccounts).length === accountList.length;

    return (
      <React.Fragment>
        {/* Add the "Select All" option at the top */}
        <div
          aria-checked={allSelected}
          aria-selected="false"
          className="item"
          onClick={() => this.selectAccount({ id: 'select-all' })}
        >
          <input
            type="checkbox"
            name="select-all"
            className="item"
            checked={allSelected}
            onChange={() => this.selectAccount({ id: 'select-all' })}
            style={{ cursor: 'pointer' }}
          />
          &nbsp;&nbsp;Select all
        </div>

        {/* Render the rest of the accounts */}
        {accountList.map((account) =>
          excludedListMap.has(account.id) ? (
            <div
              aria-checked="false"
              aria-selected="false"
              className="item"
              onClick={() => {
                if (!excludedListMap.has(account.id))
                  this.selectAccount(account);
              }}
            >
              <input
                type="checkbox"
                name="account"
                className="item"
                checked={
                  selectedAccounts[account.id] !== undefined ||
                  excludedListMap.has(account.id)
                }
                onChange={() => {
                  this.selectAccount(account);
                }}
                style={{ cursor: 'pointer' }}
                disabled={excludedListMap.has(account.id)}
              />
              &nbsp;&nbsp;{account.label || account.user_institution}
            </div>
          ) : (
            <Popup
              trigger={
                // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                <div
                  role="option"
                  aria-checked="false"
                  aria-selected="false"
                  className="item"
                  onClick={() => {
                    if (!excludedListMap.has(account.id))
                      this.selectAccount(account);
                  }}
                >
                  <input
                    type="checkbox"
                    name="account"
                    className="item"
                    checked={
                      selectedAccounts[account.id] !== undefined ||
                      excludedListMap.has(account.id)
                    }
                    onChange={() => {
                      this.selectAccount(account);
                    }}
                    style={{ cursor: 'pointer' }}
                    disabled={excludedListMap.has(account.id)}
                  />
                  &nbsp;&nbsp;{account.label || account.user_institution}
                </div>
              }
              position="top center"
              wide
            >
              {account.user_institution ? `${account.user_institution}` : ``}
            </Popup>
          )
        )}
      </React.Fragment>
    );
  };

  render() {
    const { error, assignAccountsValue, excludedList, float, height, zIndex } =
      this.props;
    const { accountsValue, expand, selectedAccounts, updateMesssage } =
      this.state;
    const selectedAccountsAmount = Object.values(selectedAccounts).filter(
      (value) => value !== undefined
    );
    const excludedListMap = new Set(excludedList.map((item) => Number(item)));

    return !this.getConvertedList() || !this.getConvertedList().length ? (
      <div
        style={{
          float: float ? 'right' : 'none',
          marginTop: '1rem',
          color: 'red',
        }}
      >
        You currently have no saved accounts.
      </div>
    ) : (
      <span
        onBlur={() => {
          this.setState({ expand: false });

          assignAccountsValue(
            Object.keys(selectedAccounts).reduce((result, id) => {
              if (selectedAccounts[id] !== undefined) result.push(id);
              return result;
            }, []),
            accountsValue
          );
        }}
        onMouseDown={(e) => {
          if (e.target.nodeName === 'INPUT') {
            e.preventDefault();
          }
        }}
      >
        <div
          style={
            float
              ? {
                  float: 'right',
                  margin: '0.7rem 3px 0 0',
                }
              : updateMesssage
              ? { whiteSpace: 'nowrap' }
              : {
                  margin: '0 0 1.4rem 3px',
                }
          }
        >
          {updateMesssage ? (
            <div style={{ color: `${colors.red}` }}>
              <i>
                To update your account values or selection, <br />
                please select your accounts again.
              </i>
            </div>
          ) : null}
        </div>
        <StyledDropdown
          role="listbox"
          ariaDisabled="false"
          ariaExpanded={expand}
          className={cn(
            expand
              ? 'ui active visible selection dropdown'
              : 'ui selection dropdown',
            { invalid: error }
          )}
          tabIndex="0"
          onClick={(e) => {
            if (!expand) this.setState({ expand: true });

            if (expand && e.target.className !== 'item') {
              this.setState({ expand: false });
            }
          }}
          moveDown={updateMesssage}
          float={float}
          height={height}
          zIndex={zIndex}
        >
          <div
            className={selectedAccountsAmount.length ? 'text' : 'default text'}
            role="alert"
            aria-live="polite"
          >
            {selectedAccountsAmount.length
              ? selectedAccountsAmount.length === this.getConvertedList().length
                ? 'All accounts'
                : selectedAccountsAmount.length >= 2
                ? 'Multiple Accounts'
                : this.getAccountLabel(
                    Object.keys(selectedAccounts).find(
                      (acc) => selectedAccounts[acc] !== undefined
                    )
                  )
              : 'Select an account'}
          </div>
          {expand ? (
            <i ariaHidden="true" className="delete icon" />
          ) : (
            <i ariaHidden="true" className="dropdown icon" />
          )}
          <div
            className={expand ? 'visible menu transition' : 'menu transition'}
          >
            {/* Render the accounts with the "Select All" option */}
            {this.renderAccounts(
              this.getConvertedList(),
              selectedAccounts,
              excludedListMap
            )}
          </div>
        </StyledDropdown>
      </span>
    );
  }
}

AccountsDropdown.propTypes = {
  accountList: PropTypes.array.isRequired,
  selectedAccList: PropTypes.array.isRequired,
  excludedList: PropTypes.array.isRequired,
  error: PropTypes.object.isRequired,
  assignAccountsValue: PropTypes.func.isRequired,
  savedAccountsValue: PropTypes.number.isRequired,
  float: PropTypes.bool,
  height: PropTypes.number,
  zIndex: PropTypes.number,
  user_region: PropTypes.string,
  exchangeRate: PropTypes.number,
};

AccountsDropdown.defaultProps = {
  float: true,
  height: 0,
  zIndex: 999,
  user_region: 'CA',
  exchangeRate: 0,
};

export default connect((state) => ({
  user_region: localStorage.getItem('user_region') || 'CA',
  exchangeRate: state.Storage['pwpa-exchange-rate'],
}))(AccountsDropdown);

const StyledDropdown = styled.div`
  height: ${(props) =>
    props.height ? `${props.height}px !important` : 'unset !important'};
  line-height: ${(props) =>
    props.height ? `${props.height / 2}px !important` : 'none !important'};

  min-height: unset !important;
  padding: 0.5rem !important;
  min-width: unset !important;
  float: ${(props) => (props.float ? 'right' : 'none')};
  clear: both;
  width: ${(props) => (props.float ? '32%' : '100%')};
  z-index: ${(props) => `${props.zIndex} !important`};
  transform: ${(props) =>
    props.moveDown
      ? 'translateY(0.25rem) !important'
      : 'translateY(-1rem) !important'};

  &.visible {
    z-index: 1000 !important;
  }

  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }

  i {
    padding: 0.5rem !important;
  }
`;
