/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Header,
  Table,
  Divider,
  Checkbox,
  Popup,
  Icon,
  Button,
} from 'semantic-ui-react';
import { chartingToolColorsLessOpacity, colors } from 'utils/colors';
import { maxNumberOfFundsToCompare } from 'utils/constants';

const EMPTY_CELL_BACKGROUND_COLOR = '#F9FAFB';
export default class TopHoldings extends Component {
  state = {
    viewCommonHoldings: false,
  };

  componentDidUpdate = () => {
    if (
      this.state.viewCommonHoldings &&
      !this.displayViewCommonHoldingsToggle(
        this.getCombinedTopHoldingTickerList()
      )
    ) {
      this.setState({ viewCommonHoldings: false });
    }
  };

  getCombinedTopHoldingTickerList = () => {
    const { securityTickerList, securityData } = this.props;

    const combinedTopHoldingTickerList = securityTickerList.reduce(
      (acc, curr) => {
        acc.push(...Object.keys(securityData[curr].top_holdings));
        return acc;
      },
      []
    ); // create an array containing all the top holding tickers

    // remove duplicates and sort
    return [...new Set(combinedTopHoldingTickerList)].sort();
  };

  datasetKeyProvider = () => btoa(Math.random()).substring(0, 12);

  isHoldingUniqueToOneFund = (holdingTicker) => {
    const { securityData, securityTickerList } = this.props;
    let count = 0;
    let fundsTopHoldings;
    securityTickerList.forEach((fundTicker) => {
      fundsTopHoldings = Object.keys(securityData[fundTicker].top_holdings);
      if (fundsTopHoldings.includes(holdingTicker)) count++;
    });
    return count <= 1;
  };

  displayViewCommonHoldingsToggle = () => false;
  // Hide View Common Holdings toggle for now
  // return true iff at least one holding is shared among at least two funds
  /* const combinedTopHoldingTickerList = this.getCombinedTopHoldingTickerList();
    let count = 0;
    combinedTopHoldingTickerList.forEach((holdingTicker) => {
      count += !this.isHoldingUniqueToOneFund(holdingTicker);
    });

    return count >= 1; */

  renderEmptyHeaderCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => (
      <Table.HeaderCell key={this.datasetKeyProvider()} />
    ));
  renderEmptyCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => (
      <Table.Cell
        key={this.datasetKeyProvider()}
        style={{ backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }}
      />
    ));

  renderTable = (fundTicker, tickerIndex) => {
    const { securityData } = this.props;
    const data = securityData[fundTicker];
    const topHoldings = data.top_holdings || {}; // default to an empty object if top_holdings is undefined or null

    const filtered = Object.keys(topHoldings)
      .sort((a, b) => topHoldings[b] - topHoldings[a])
      .reduce((total, key) => {
        total[key] = topHoldings[key];
        return total;
      }, {});

    const headerColor = Object.values(chartingToolColorsLessOpacity)[
      tickerIndex
    ];
    const headerCellStyles = {
      backgroundColor: headerColor,
      color: 'black',
    };

    const rows = Object.keys(filtered)
      .sort()
      .map((key) => (
        <Table.Row key={key}>
          <Table.Cell>{key || 'N/A'}</Table.Cell>
          <Table.Cell>
            {topHoldings[key] ? `${topHoldings[key].toFixed(2)}%` : '-'}
          </Table.Cell>
        </Table.Row>
      ));

    if (Object.keys(topHoldings).length === 0) {
      for (let i = rows.length; i < 10; i++) {
        rows.push(
          <Table.Row key={`N/A-${i}`}>
            <Table.Cell>N/A</Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
        );
      }
    }

    return (
      <Table
        style={{
          margin: '0 0 0 4px',
          maxWidth: '24.5%',
        }}
        key={this.datasetKeyProvider()}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={headerCellStyles}>
              Security name
            </Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>Weight</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  renderCommonHoldingsTable = () => {
    const { securityTickerList, securityData } = this.props;
    const combinedTopHoldings = this.getCombinedTopHoldingTickerList();

    const numEmptyCols = maxNumberOfFundsToCompare - securityTickerList.length;

    return (
      <Table compact celled key={this.datasetKeyProvider()}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ color: 'black' }}>
              Holding
            </Table.HeaderCell>
            {securityTickerList.map((security, index) => (
              <Table.HeaderCell
                key={security}
                width={3}
                style={{
                  backgroundColor: Object.values(chartingToolColorsLessOpacity)[
                    index
                  ],
                  color: 'black',
                }}
              >
                {security}
              </Table.HeaderCell>
            ))}
            {this.renderEmptyHeaderCells(numEmptyCols)}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {combinedTopHoldings.map((holding) =>
            this.isHoldingUniqueToOneFund(holding) ? null : (
              <Table.Row key={holding}>
                <Table.Cell
                  style={{ backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }}
                  width={3}
                >
                  {holding}
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell
                    key={security}
                    style={
                      !securityData[security].top_holdings[holding]
                        ? { backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }
                        : {}
                    }
                  >
                    {securityData[security].top_holdings[holding]
                      ? `${securityData[security].top_holdings[holding]}%`
                      : ''}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { securityTickerList, isOpen, toggleOpen } = this.props;

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Header
            textAlign="left"
            size="medium"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Header.Content>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledButton onClick={toggleOpen} isOpen={isOpen}>
                  {isOpen ? '-' : '+'}
                </StyledButton>
                Top 10 Holdings
              </div>
            </Header.Content>
            <div
              style={{
                display: this.displayViewCommonHoldingsToggle()
                  ? 'flex'
                  : 'none',
              }}
            >
              <span
                style={{
                  fontWeight: 'normal',
                  fontSize: '15px',
                  marginRight: '0.5rem',
                }}
              >
                View Common Holdings{' '}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="Toggle to display the common top ten holdings across the selected funds. Holdings that are unique to a single fund will not be displayed. This action is available when your selected funds share common holdings."
                />
              </span>
              <Checkbox
                toggle
                checked={this.state.viewCommonHoldings}
                onClick={() =>
                  this.setState({
                    viewCommonHoldings: !this.state.viewCommonHoldings,
                  })
                }
              />
            </div>
          </Header>
        </div>

        <Divider />

        {isOpen && (
          <React.Fragment>
            <StyledNote>
              For funds with a fund-of-funds (FOF) structure, we may show the top
              10 holdings of the underlying funds.
            </StyledNote>
            {this.state.viewCommonHoldings ? (
              this.renderCommonHoldingsTable()
            ) : (
              <div
                style={{ display: 'flex', position: 'relative' }}
                key={this.datasetKeyProvider()}
              >
                {securityTickerList.map((ticker, index) =>
                  this.renderTable(ticker, index)
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

TopHoldings.propTypes = {
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

const StyledButton = styled(Button)`
  &&& {
    font-size: 1.3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.isOpen ? colors.blue : 'white'} !important;
    color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
    border: ${(props) =>
      props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
    border-radius: 50% !important;
    height: 2rem !important;
    width: 2rem !important;
    padding: 0 !important;
    transition: all 0.9s ease-in-out !important;
    margin: 0 0.75rem 0 0;
  }
`;

const StyledNote = styled.div`
  &&& {
    display: block;
    font-weight: 400;
    padding: 0;
    margin: 2rem;
    line-height: 1.2em;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.14rem;
  }
`;
