import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default class FrFootNote extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { hasEsg } = this.props;

    return (
      <div>
        <P>
          1. Lignes directrices:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Afin de veiller à la pertinence de
          l’analyse statistique, Wealthscope impose les règles suivantes lors de
          l’analyse de votre portefeuille:
          <br />
          <ul style={{ listStyleType: 'disc', transform: 'translateX(12px)' }}>
            <li>
              Si tous les titres du portefeuille ont un historique supérieur ou
              égal à 48 mois, l’analyse sera effectuée au complet. Autrement,
              nous excluons les titres suivants:
              <ul style={{ listStyleType: 'circle' }}>
                <li>Les titres dont l’historique est inférieur à 36 mois; </li>
                <li>
                  Les titres dont l’historique se trouve entre 36 et 47 mois et
                  qui comptent collectivement pour moins de 10 % du portefeuille
                </li>
              </ul>
            </li>
            <li>
              La période d’analyse pour les titres de cette catégorie qui se
              retrouvent dans l’analyse sera l’historique commun le plus long.
            </li>
            <li>
              Si tous les titres du portefeuille ont un historique inférieur à
              36 mois, nous ne fournissons que des informations sommaires pour
              le portefeuille en utilisant l’historique commun le plus long.
            </li>
            <li>
              Si l’historique d’un FNB de gestion passive est insuffisant, mais
              qu’il existe un substitut semblable, ce dernier servira aux fins
              de l’analyse. Pour certains FNB de gestion passive dont
              l’historique est court, nous pouvons utiliser le rendement de
              l’indice (moins le RFG) avant la création.
            </li>
          </ul>
        </P>
        <P>
          2. La note globale est la note moyenne des différentes dimensions.
          Chaque note individuelle est sur 100 pour la période spécifique en
          question. Un score plus élevé signifie toujours une caractéristique
          plus souhaitable.
        </P>
        <P>3. Toutes les statistiques clés sont rapportées en CAD.</P>
        <P>
          4. L'indice de référence de votre portefeuille est automatiquement
          sélectionné en fonction de votre allocation d'actifs. Il représente
          une alternative simple et peu coûteuse à deux ETF (un large actions et
          un large revenu fixe) pour votre portefeuille.
        </P>
        <P>
          5. Voici les ratios de rendement ajustés au risque, et un ratio plus
          élevé est préférable : Le ratio de Sharpe mesure la récompense du
          portefeuille (rendements excédentaires par rapport au taux sans
          risque) par unité de risque. Le critère Roy’s Safety First mesure la
          récompense du portefeuille (rendements excédentaires par rapport au
          taux cible) par unité de risque. Le ratio Sortino mesure la récompense
          du portefeuille (rendements excédentaires par rapport au taux cible)
          par unité de risque à la baisse.
        </P>
        <P>
          6. Voici les mesures du risque du portefeuille : Le risque total
          représente la volatilité des rendements du portefeuille telle que
          mesurée par son écart type. Le risque de baisse est la volatilité du
          rendement du portefeuille en dessous d’un taux cible. Le rabattement
          maximum est la perte maximale entre le sommet et le creux au cours
          d’une période donnée. La capture des baisses mesure si le portefeuille
          a perdu moins que le marché en cas de ralentissement du marché.
        </P>
        <P>
          7. Le taux cible par défaut est fixé à zéro, ce qui représente la
          préservation du capital nominal.
        </P>
        <P>
          8. Les frais comprennent les frais liés aux produits si vous possédez
          des fonds communs de placement/ETF. Il peut inclure une commission de
          suivi pour votre conseiller, selon la catégorie d'actions que vous
          détenez (par exemple, catégorie A). Des frais de poids de 3 %
          recevront une note de 0 %.
        </P>
        <P>
          9. Un rendement pondéré du revenu (dividendes et autres distributions)
          de 5 % ou plus recevra une note de 100 %.
        </P>
        <P>
          10. La diversification est notée à l’aide de la corrélation moyenne de
          votre portefeuille et de quatre autres dimensions i) de la classe
          d’actifs, ii) de la région géographique, iii) des secteurs d’activité
          et iv) des facteurs macroéconomiques.
        </P>
        <P>
          11. Pour la diversification des marchés boursiers régionaux, du
          secteur boursier mondial, de la macroéconomie et du style d’actions,
          nous vous montrons l’exposition au risque de votre portefeuille. Ce
          dernier mesure dans quelle mesure la volatilité de votre portefeuille
          peut être attribuée à chaque facteur. Le risque spécifique au
          portefeuille est la partie de la volatilité de votre portefeuille qui
          ne peut être expliquée par les facteurs en question.
        </P>
        {hasEsg ? (
          <P>
            12. The Owl Analytics ESG scores are on a 0-100 scale, with a higher
            score being more desirable. The Wealthscope grading scheme differs
            from the ESG scores as the grading scheme reflects the distribution
            of the scores, which is tight around the middle for funds (see the
            distribution chart).
          </P>
        ) : null}
      </div>
    );
  }
}

FrFootNote.propTypes = { hasEsg: PropTypes.bool.isRequired };

FrFootNote.defaultProps = {};

const P = styled.p`
  text-align: left;
  max-width: 100%;
`;
