import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { Planning } from 'actions';
import { Header, Segment, Feature, NeuCard } from 'components';
import { colors } from 'utils/colors';
import {
  PLANNING_ACCUMULATION_RETIREMENT_BLUEPRINT,
  PLANNING_ACCUMULATION_REVISIT_BLUEPRINT,
  PLANNING_ACCUMULATION_TARGET_WEALTH,
  PLANNING_ACCUMULATION_TARGET_SAVING,
} from 'utils/features';
import asset4 from 'images/asset_4.png';

@withRouter
@connect((state) => ({
  account: state.CustomAccount.account,
}))
class Landing extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(Planning.clearStore());
  };

  render() {
    return (
      <Segment expand>
        <Feature name={PLANNING_ACCUMULATION_REVISIT_BLUEPRINT}>
          <Link to="/planning/saved-blueprints">
            <MyButton
              className="left"
              icon="line chart"
              style={{ position: 'absolute', top: '1rem', right: '4.5rem' }}
              onClick={this.handleRedirect}
            >
              <i className="folder open icon" style={{ float: 'left' }} />
              See Saved Blueprints
            </MyButton>
          </Link>
        </Feature>
        <div>
          <img src={asset4} height="80" width="120" alt="Portfolio" />
          <Header size="1.5rem" color="black">
            Accumulation
          </Header>
          <P>
            Here are a couple of simple-to-use tools to help you calculate your
            target wealth at retirement and how much you need to save in order
            to reach a particular goal, such as your children’s education or
            retirement.
          </P>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <Feature name={PLANNING_ACCUMULATION_RETIREMENT_BLUEPRINT}>
              <NeuCard
                to="/planning/blueprint/new/overview"
                iconName="tree"
                title="Retirement Blueprint"
                description="Let's start a new blueprint. We will help you determine your spending needs, and create a goal-oriented investing plan."
                color={colors.orange}
              />
            </Feature>
            <Feature name={PLANNING_ACCUMULATION_TARGET_WEALTH}>
              <NeuCard
                to="/planning/wealth"
                iconName="leaf"
                title="Target Wealth"
                description="Calculate your Target Wealth at retirement if you know how much you want to spend per year in retirement."
                color={colors.orange}
              />
            </Feature>
            <Feature name={PLANNING_ACCUMULATION_TARGET_SAVING}>
              <NeuCard
                to="/planning/saving"
                iconName="dollar"
                title="Target Saving"
                description="Calculate how much you need to save per month starting now to achieve your goals."
                color={colors.orange}
              />
            </Feature>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 650px;
  margin: 0 auto 3rem auto;

  &.strike {
    text-decoration: line-through;
  }
`;

const MyButton = styled.button`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 10px 0;
  padding: 0px;
  height: 50px;
  border-width: 0;
  color: black;
  background-color: transparent;
  text-decoration: none;
  line-height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  overflow: visible;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
    color: ${colors.blue};
  }
`;
