import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header as SemanticHeader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';

import { Planning, Storage } from 'actions';
import { notWealthica } from 'utils/helpers';
import {
  Segment,
  Card,
  Header,
  Modal,
  Button,
  LastUpdatedTag,
} from 'components';
import { colors, darker } from 'utils/colors';

class SavedBlueprints extends Component {
  state = {
    isOpen: false,
    loading: false,
    id: null,
    name: this.props.name || '',
    description: this.props.description || '',
    bpIndex: null,
  };

  componentWillMount = () => {
    const { dispatch, blueprints } = this.props;

    if (!blueprints.length) {
      dispatch(Planning.fetchAll());
    }

    return false;
  };

  componentDidUpdate = () => {
    const { dispatch, blueprints } = this.props;

    if (!blueprints.length) {
      dispatch(Planning.fetchAll());
    }

    return false;
  };

  removeBlueprint = (id) => {
    const { dispatch } = this.props;

    this.setState({ loading: true });

    return dispatch(Planning.remove(id)).then(
      () => this.setState({ isOpen: false, loading: false }),
      () => this.setState({ isOpen: false, loading: false })
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value });
  };

  handleCancel = () => {
    const { name, description } = this.props;

    return this.setState({
      isOpen: false,
      name: name || '',
      description: description || '',
    });
  };

  handleSave = () => {
    const { dispatch, blueprint } = this.props;
    const { id, name, description } = this.state;

    const params = {
      // general
      age: blueprint.personalDetails.age,
      cpp: blueprint.personalDetails.cpp,
      cppAge: blueprint.personalDetails.cppAge,
      gender: blueprint.personalDetails.gender,
      pension: blueprint.personalDetails.pension || 0,
      oas: blueprint.personalDetails.oas,
      oasAge: blueprint.personalDetails.oasAge,
      other: blueprint.personalDetails.other || 0,
      retAge: blueprint.personalDetails.retAge,
      net_spending: blueprint.personalDetails.net_spending,
      province: blueprint.personalDetails.province,
      spending_amount: blueprint.personalDetails.spending_amount,
      ...(blueprint.personalDetails.resident_years && {
        resident_years: blueprint.personalDetails.resident_years,
      }),

      // wealth
      annuityRate: blueprint.targetWealth.annuityRate,
      horz: blueprint.targetWealth.horz,
      targ: blueprint.targetWealth.targ,
      wealth_portfolio: blueprint.targetWealth.wealth_portfolio,
      modelType: blueprint.targetWealth.modelType,
      targ50: blueprint.wealthResult['0.5'],
      targ75: blueprint.wealthResult['0.75'],
      targ90: blueprint.wealthResult['0.9'],
      targ95: blueprint.wealthResult['0.95'],
      // saving
      init: blueprint.targetSaving.init,
      prob_without_savings: blueprint.savingResult.prob_without_savings,
      monthlySavingChosen: blueprint.targetSaving.monthlySavingChosen,
      monthlySaving50: blueprint.savingResult['0.5'],
      monthlySaving75: blueprint.savingResult['0.75'],
      monthlySaving90: blueprint.savingResult['0.9'],
      savings_portfolio: blueprint.targetSaving.savings_portfolio,
      saving_portfolio_symbols:
        blueprint.targetSaving.savings_portfolio_detail.symbols,
      saving_portfolio_weights:
        blueprint.targetSaving.savings_portfolio_detail.weights,
      saving_portfolio_region:
        blueprint.targetSaving.savings_portfolio_detail.region,
      // spouse
      spouse: blueprint.personalDetails.spouse,
      age2: blueprint.personalDetails.age2,
      wage: blueprint.personalDetails.wage,
      wage2: blueprint.personalDetails.wage2,
      cppAge2: blueprint.personalDetails.cppAge2,
      cpp2: blueprint.personalDetails.cpp2,
      emp2: blueprint.personalDetails.emp2 || 0,
      oas2: blueprint.personalDetails.oas2,
      oasAge2: blueprint.personalDetails.oasAge2,
      gender2: blueprint.personalDetails.gender2,
      other2: blueprint.personalDetails.other2 || 0,
      retAge2: blueprint.personalDetails.retAge2,
      years2: blueprint.personalDetails.years2,
      ...(blueprint.personalDetails.resident_years2 && {
        resident_years2: blueprint.personalDetails.resident_years2,
      }),
    };

    this.setState({ loading: true });

    dispatch(
      Planning.update(id, {
        name,
        description,
        updated_on: blueprint.updated_on,
        ...params,
      })
    );

    return this.setState({ isOpen: false, loading: false });
  };

  render() {
    const { blueprints, dispatch, partnerName } = this.props;
    const { isOpen, name, description, loading } = this.state;

    return (
      <Segment>
        <Header
          size="1.5rem"
          color="black"
          subheader="There are all your saved blueprints. Once you save a Retirement Blueprint, it will appear here. Revisit and update your Blueprint whenever there has been a substantial change in your personal and/or financial situation."
          long
        >
          Your Saved Retirement Blueprints
        </Header>
        {!blueprints.length ? (
          <div>When you save retirement blueprints, they will appear here.</div>
        ) : (
          blueprints
            .sort(
              (a, b) =>
                new Date(b.updated_on).getTime() -
                new Date(a.updated_on).getTime()
            )
            .map((data, bpIndex) => (
              <span key={data.id} style={{ position: 'relative' }}>
                <Card
                  header={data.name}
                  color={colors.orange}
                  hoverside={darker.orange}
                  content={data.description}
                  to={`/planning/blueprint/${data.id}/summary`}
                  key={data.id}
                  icon="tree"
                  onClick={() =>
                    dispatch(Storage.removeItem('cache-pwpa-blueprint'))
                  }
                >
                  <Icon
                    className="ui icon pencil"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(Planning.fetchBlueprintById(data.id));
                      this.setState({
                        isOpen: 'edit',
                        name: data.name,
                        description: data.description,
                        id: data.id,
                        bpIndex,
                      });
                    }}
                  />
                  <Icon
                    className="ui icon close"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({
                        isOpen: 'delete',
                        name: data.name,
                        id: data.id,
                        bpIndex,
                      });
                    }}
                  />
                  <LastUpdatedTag>
                    Last updated on {data.updated_on}
                  </LastUpdatedTag>
                </Card>
              </span>
            ))
        )}

        <Modal
          basicFlex
          open={isOpen === 'delete'}
          offsetY={Math.floor(this.state.bpIndex / 2) * 70}
          dimmerClick={() => this.setState({ isOpen: false })}
          vb={notWealthica(partnerName)}
        >
          <Modal.Header>
            <SemanticHeader
              icon="warning sign"
              content="Delete Blueprint"
              style={{ color: colors.red }}
            />
          </Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete blueprint{' '}
              <span style={{ color: colors.red }}>{name}</span> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              color={colors.red}
              icon="cancel"
              margin="0 0.5rem 0 0"
              onClick={() => this.setState({ isOpen: false })}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                { loading }
              )}
              color={colors.green}
              icon="checkmark"
              onClick={() => this.removeBlueprint(this.state.id)}
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          basicFlex
          open={isOpen === 'edit'}
          offsetY={Math.floor(this.state.bpIndex / 2) * 70}
          dimmerClick={() => this.setState({ isOpen: false })}
        >
          <Modal.Header>
            <SemanticHeader
              icon="briefcase"
              content="Update Blueprint"
              style={{ color: colors.green }}
            />
          </Modal.Header>
          <Modal.Content>
            <Field vb={notWealthica(partnerName)}>
              Name:
              <input
                placeholder="(required)"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Field>
            <Field vb={notWealthica(partnerName)}>
              Description:
              <input
                placeholder="(optional)"
                name="description"
                value={description}
                onChange={this.handleChange}
              />
            </Field>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              icon="cancel"
              color={colors.red}
              margin="0 0.5rem 0 0"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                { loading, disabled: !name || !name.trim() }
              )}
              icon="checkmark"
              color={colors.green}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

SavedBlueprints.propTypes = {
  blueprint: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  blueprints: PropTypes.array.isRequired,
  description: PropTypes.string,
  name: PropTypes.string,
  partnerName: PropTypes.string,
};

SavedBlueprints.defaultProps = {
  blueprint: null,
  description: '',
  name: '',
  partnerName: '',
};

export default connect((state) => ({
  blueprint: state.Planning.blueprint,
  blueprints: state.Planning.savedBlueprints,
  partnerName: state.Storage['partner-name'],
}))(SavedBlueprints);

const Icon = styled.i`
  display: inline-block;
  position: absolute;
  top: -1px;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
  margin: 0 !important;
  padding: 0 !important;
  height: 30px !important;
  width: 24px !important;
  z-index: 4;
  cursor: pointer;
  vertical-align: bottom;
  line-height: 2rem;

  &.close {
    right: 0;
    font-size: 1.3rem;

    &:hover {
      color: ${colors.red} !important;
    }
  }

  &.pencil {
    right: 22px;

    &:hover {
      color: ${colors.blue} !important;
    }
  }

  &.share {
    right: 46px;
    font-size: 16px !important;

    &:hover {
      color: ${colors.green};
    }
  }
`;

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;
