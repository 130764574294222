import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Popup,
  Icon,
  Form,
  Input,
  Header,
  Dropdown,
  Divider,
} from 'semantic-ui-react';
import cn from 'classnames';

import { CacheInputs, Planning, Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import { Message, ErrorLabel, Button, Segment } from 'components';
import PortfoliosList from '../components/PortfoliosList';
import Result from './Result';

@connect((state) => ({
  isFetching: state.Planning.isFetching,
  longevity: state.Planning.longevity,
  modelPortfolios: state.Portfolio.model,
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  portLongevityInputs: state.CacheInputs.planning.portfolio_longevity,
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
}))
export default class RuinProbability extends Component {
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    return false;
  };

  getPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    const date = !port ? [0] : port.init_date.split('-');

    if (Number(date[0]) < 2008) {
      return false;
    }

    return `${date[1]}/${date[0]}`;
  };

  getNewPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    if (!port || !port.init_date) {
      return false;
    }
    const date = new Date(port.init_date);
    const today = new Date();
    const monthDifference =
      today.getMonth() -
      date.getMonth() +
      12 * (today.getFullYear() - date.getFullYear());
    if (monthDifference > 60) {
      return false;
    }

    const init = date.toDateString().toString().split(/\s+/);

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sept: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    return `${months[init[1]]} ${init[3]}`;
  };

  handleKeyPress = (e) => e.key === '.' && e.preventDefault();

  handlePortfolioChange = (e, { name, value }) => {
    const limitRegex = /^[0-9]{1,9}$/;
    const { dispatch, portLongevityInputs } = this.props;

    if (name === 'annuityRate') {
      if (value === '' || !limitRegex.test(value)) {
        dispatch(
          CacheInputs.updateField('planning', 'portfolio_longevity', {
            ...portLongevityInputs,
            riskFree: true,
            useDefault: true,
            portfolio: null,
            modelType: 'annuityRate',
            annuityRate: null,
          })
        );
        return;
      }
      const num = Number(value) > 100 ? 100 : Number(value);
      dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          ...portLongevityInputs,
          modelType: 'annuityRate',
          annuityRate: num,
        })
      );
    } else
      dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          riskFree: false,
          annuityRate: '',
          portfolio: value,
          name,
          modelType: name,
          portfolioID: value,
          errors: { portfolio: null },
        })
      );
  };

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    if (name === 'gender') {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          gender: value,
          errors: { gender: null },
        })
      );
    }

    if (
      (name === 'retAge' || name === 'annuityRate' || name === 'lifeExp') &&
      Number(value) > 100
    ) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: 100,
          errors: { [name]: null },
        })
      );
    }

    return (
      value.length < 10 &&
      dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: value,
          errors: { [name]: null },
        })
      )
    );
  };
  handleRadioChange = (e, { name, value }) => {
    const { dispatch, portLongevityInputs } = this.props;
    const { errors } = portLongevityInputs;
    dispatch(Planning.clearSectionData('wealthResult'));

    dispatch(
      CacheInputs.updateField('planning', 'portfolio_longevity', {
        portfolio: value,
        modelType: name,
        annuityRate: null,
        errors: { ...errors, portfolioID: null },
      })
    );
  };

  scrollHandler = (element, to, duration) => {
    if (duration <= 0) return false;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      element.scrollTop += perTick;
      if (element.scrollTop === to) return false;
      return this.scrollHandler(element, to, duration - 10);
    }, 10);
  };

  hasEmptyValue = (values) =>
    values.some((field) => {
      const { dispatch, portLongevityInputs } = this.props;

      if (!portLongevityInputs[field]) {
        return dispatch(
          CacheInputs.updateField('planning', 'portfolio_longevity', {
            errors: { [field]: 'This field is required' },
          })
        );
      }

      return false;
    });

  handleSubmit = () => {
    const { dispatch, portfolios, portLongevityInputs } = this.props;
    const {
      useDefault,
      lifeExp,
      portfolio,
      annuityRate,
      retAge,
      init,
      gender,
      cf,
      riskFree,
    } = portLongevityInputs;

    const params = {
      retAge,
      init,
      gender,
      cf,
      init_date: portfolio
        ? portfolios.find((p) => p.id.toString() === portfolio.toString())
            .init_date
        : null,
      region: 'CA',
      targetProb: 0.05,
    };

    if (this.hasEmptyValue(Object.keys({ retAge, init, gender, cf }))) {
      return false;
    }

    if (!useDefault && !lifeExp) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { lifeExp: 'This field is required' },
        })
      );
    }

    if (riskFree && !annuityRate) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { annuityRate: 'This field is required' },
        })
      );
    }

    if (!riskFree && !portfolio) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { portfolio: 'This field is required' },
        })
      );
    }

    if (annuityRate) {
      params.annuityRate = annuityRate / 100;
    }

    if (lifeExp) {
      params.lifeExp = lifeExp;
    }

    if (portfolio) {
      const idx = portfolios.reduce((result, item, index) => {
        if (item.id.toString() === portfolio.toString() && !result) {
          result = index;
        }

        return result;
      }, false);

      params.symbols = portfolios[idx].symbols;
      params.weights = portfolios[idx].weights;
      params.gic_info = portfolios[idx].gic_info;
    }

    return dispatch(Planning.analyzeLongevity(params)).then((action) => {
      const element = document.documentElement;
      const target = document.getElementById('longevity-ref').offsetHeight;

      return (
        action && !!action.result && this.scrollHandler(element, target, 300)
      );
    });
  };

  getDropOptions = () => {
    const { portfolios, user_region, partnerName } = this.props;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return portfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      text:
        user_region === 'US' || isGlobeInvestor(partnerName)
          ? this.nameForUsVersion(portf.name)
          : portf.name,
    }));
  };

  nameForUsVersion = (name) => {
    if (name === 'WS All-In') {
      return '100 Equities / 0 Bonds';
    } else if (name === 'WS Adventurous') {
      return '80 Equities / 20 Bonds';
    } else if (name === 'WS Classic') {
      return '60 Equities / 40 Bonds';
    } else if (name === 'WS Cautious') {
      return '40 Equities / 60 Bonds';
    } else if (name === 'WS Playing-It-Safe') {
      return '20 Equities / 80 Bonds';
    }
    return name;
  };

  render() {
    const {
      dispatch,
      isFetching,
      longevity,
      portfolios,
      portLongevityInputs,
      partnerName,
      user_region,
      modelPortfolios,
    } = this.props;
    const {
      lifeExp,
      useDefault,
      retAge,
      init,
      cf,
      gender,
      portfolio,
      riskFree,
      annuityRate,
      errors,
      modelType,
      portfolioID,
    } = portLongevityInputs;
    const portDate = this.getNewPortDate(
      portfolios.find((port) => Number(port.id) === portfolio)
    );

    return (
      <Segment
        style={{
          border: isGlobeInvestor(partnerName)
            ? '1px solid rgba(0, 0, 0, 0.2)'
            : 'none',
          borderRadius: isGlobeInvestor(partnerName) && '0',
        }}
      >
        {!isGlobeInvestor(partnerName) && (
          <Header
            size="large"
            content="Probability of Ruin"
            textAlign="center"
            subheader={
              <div className="sub header">
                What are the chances that you will outlive your money? <br /> We
                show you below by comparing your human longevity and your
                portfolio longevity.
              </div>
            }
          />
        )}

        <Form
          onSubmit={this.handleSubmit}
          style={{
            width: !isGlobeInvestor(partnerName) ? '37rem' : '',
            margin: '2rem auto 0 auto',
            padding: isGlobeInvestor(partnerName) ? '0 2rem 0 2rem' : '0',
          }}
        >
          <Section globe={isGlobeInvestor(partnerName)}>
            {isGlobeInvestor(partnerName) && (
              <div className="heading">Portfolio Longevity Inputs</div>
            )}
            <Form.Group widths="equal">
              <Form.Field style={{ position: 'relative' }}>
                <label>Age:</label>
                <Input
                  name="retAge"
                  type="number"
                  fluid
                  placeholder="years"
                  value={retAge || ''}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
                <ErrorLabel className="top" msg={errors.retAge} />
              </Form.Field>

              <Form.Field style={{ position: 'relative' }}>
                <label>
                  Sex:&nbsp;
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    wide
                    content="We are using mortality rates from Statistics Canada in the calculations and the data is available for males and females only."
                  />
                </label>
                <Dropdown
                  name="gender"
                  compact
                  placeholder="Sex"
                  selection
                  options={[
                    { key: 'm', value: 'M', text: 'Male' },
                    { key: 'f', value: 'F', text: 'Female' },
                  ]}
                  value={gender}
                  onChange={this.handleChange}
                />
                <ErrorLabel className="top" msg={errors.gender} />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field style={{ position: 'relative' }}>
                <label>
                  Savings:<SubLabel>How much you currently have.</SubLabel>
                </label>
                <Input
                  name="init"
                  type="number"
                  fluid
                  value={init || ''}
                  icon="dollar"
                  iconPosition="left"
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
                <ErrorLabel className="top" msg={errors.init} />
              </Form.Field>

              <Form.Field style={{ position: 'relative' }}>
                <label>
                  Withdrawal per year:
                  <SubLabel>
                    How much you withdraw from your savings.
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{ fontSize: '13px', verticalAlign: 'initial' }}
                        />
                      }
                      position="right center"
                      content="This amount is what you spend per year over and above any retirement income you may receive, i.e., spending that has to be financed by your savings. We assume that this amount grows with inflation at 2% over time."
                    />
                  </SubLabel>
                </label>
                <Input
                  name="cf"
                  type="number"
                  fluid
                  value={cf || ''}
                  icon="dollar"
                  iconPosition="left"
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
                <ErrorLabel className="top" msg={errors.cf} />
              </Form.Field>
            </Form.Group>
          </Section>

          {!isGlobeInvestor(partnerName) && (
            <Form.Field
              style={{
                position: 'relative',
                width: '100%',
                margin: '0 auto',
                maxWidth: '17rem',
              }}
            >
              <label>
                Choose the portfolio for your savings:
                {!isGlobeInvestor(partnerName) && (
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ fontSize: '13px', verticalAlign: 'initial' }}
                      />
                    }
                    position="right center"
                    wide
                    content="You can choose a WS model portfolio or any of your saved portfolios here. If you would like to save a Streamer portfolio, click the file icon on the left of the relevant Portfolio Scorecard."
                  />
                )}
              </label>
              <Dropdown
                name="portfolio"
                compact
                placeholder="Port"
                selection
                options={this.getDropOptions(portfolios)}
                value={portfolio}
                onChange={this.handlePortfolioChange}
              />
              <ErrorLabel msg={errors.portfolio} />
            </Form.Field>
          )}
          {isGlobeInvestor(partnerName) && (
            <Section globe={isGlobeInvestor(partnerName)}>
              <div className="heading">Select a Model Portfolio</div>
              <div
                style={{
                  textAlign: 'center',
                  maxWidth: '45rem',
                  margin: '0 auto',
                  color: 'rgba(0,0,0,0.6)',
                }}
              >
                This is your post-retirement asset allocation. We assume that
                you withdraw funds at the beginning of each year for spending,
                and the rest stays invested in the portfolio. Equity is half
                Canadian and half U.S. large-cap equity; bonds are Canadian
                universe bonds.
              </div>
              <Divider />
              <div style={{ position: 'relative' }}>
                <PortfoliosList
                  portfolios={modelPortfolios}
                  portfolioID={portfolioID ? portfolioID.toString() : null}
                  annuityRate={annuityRate}
                  handlePortfolioChange={this.handlePortfolioChange}
                  handleRadioChange={this.handleRadioChange}
                  modelType={modelType || ''}
                  style={{ margin: 'initial' }}
                  user_region={user_region}
                  partnerName={partnerName}
                  longevity
                />
                <ErrorLabel
                  className="bottom"
                  msg={errors.portfolioID}
                  style={{ top: '130%' }}
                />
              </div>
            </Section>
          )}

          <Message
            icon
            visible={!!portDate}
            warning
            style={{
              margin: '1rem auto 0 auto',
              maxWidth: '45rem',
              textAlign: 'left',
            }}
          >
            <Icon name="exclamation" />
            <Message.Content>
              This portfolio has a start date of <strong>{portDate}</strong>.
              You may wish to use a portfolio with a longer history. You can use
              the ETF Portfolio Builder in the "Portfolios" segment. Once saved,
              the portfolio will appear in the dropdown list here.
            </Message.Content>
          </Message>

          <Button
            className={cn('centered right', { loading: isFetching })}
            color={colors.orange}
            icon="calculator"
            style={{ marginBottom: '24px' }}
          >
            Calculate
          </Button>
        </Form>

        <Result
          isFetching={isFetching}
          result={longevity}
          partnerName={partnerName}
        />
      </Segment>
    );
  }
}

RuinProbability.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  portfolios: PropTypes.array,
  longevity: PropTypes.object,
  portLongevityInputs: PropTypes.object,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  modelPortfolios: PropTypes.array,
};

RuinProbability.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  longevity: null,
  isFetching: false,
  portLongevityInputs: {},
  user_region: 'CA',
  partnerName: '',
  modelPortfolios: [],
};

const SubLabel = styled.div`
  color: rgba(0, 0, 0, 0.5);
  line-height: 0.8rem;
  font-style: italic;
  font-weight: 100;
`;

const Section = styled.section`
  border: ${(props) => (props.globe ? '1px solid rgba(0, 0, 0, 0.2)' : 'none')};
  padding: ${(props) => (props.globe ? '2rem' : '0')};
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: ${(props) => (props.globe ? '2rem auto' : '0')};
  line-height: 1.5rem;
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
      padding-right: 3rem;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
