import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { Planning, Storage } from 'actions';
import { formatDollarAmount } from 'utils/helpers';
import { NavigationButtons } from '../components';

class TaxImplications extends Component {
  componentWillUnmount() {
    const { history, result } = this.props;

    if (history.location.pathname.includes('target_wealth') && result) {
      Storage.setItem('cacheSR', JSON.stringify(result));
    }
  }

  isDisabled = () => {
    const { monthlySavingChosen } = this.props;
    const { result } = this.props;
    const requiredSavings = result.prob_without_savings !== 1;

    if (!requiredSavings) return true;

    return monthlySavingChosen === null || monthlySavingChosen === undefined;
  };

  nextStep = () => {
    const { history, dispatch, match } = this.props;

    dispatch(
      Planning.updateBlueprintData('summary', { id: Number(match.params.id) })
    );
    dispatch(Planning.nextSubPage('targetSaving'));
    dispatch(Planning.updateBlueprintData('summary', { currentSubPage: 1 }));
    return history.push(`/planning/blueprint/${match.params.id}/summary`);
  };

  render() {
    const { result, isFetching, monthlySavingChosen, tax_implications } =
      this.props;

    return !result || !monthlySavingChosen ? null : (
      <BlueprintContent style={{ opacity: isFetching ? 0.4 : 1 }}>
        <Header textAlign="center" size="medium" style={{ maxWidth: '55rem' }}>
          Tax Implications for the First Year
          <Header.Subheader style={{ margin: '.3rem auto .6rem' }}>
            You may need to set aside extra money for tax purposes, since
            distributions and gains from your investments may be taxable. Your
            investment tax liability (if any) will rise with your income and the
            size of your portfolio.
          </Header.Subheader>
        </Header>
        {tax_implications !== undefined &&
        tax_implications[monthlySavingChosen] &&
        tax_implications[monthlySavingChosen].expected_taxes !== 0 &&
        !tax_implications[monthlySavingChosen].expected_taxes ? (
          <div className="sub-statistic">
            <p className="sub-warning">
              This is a new feature. Please complete two additional questions at
              the top of the page.
            </p>
          </div>
        ) : (
          <div
            style={{
              color: `${colors.blue}`,
              fontSize: '3.5rem',
              fontWeight: '400',
              padding: '3rem',
            }}
          >
            {`${formatDollarAmount(
              tax_implications[monthlySavingChosen].expected_taxes.toFixed(2)
            )}`}
          </div>
        )}
        <div className="sub-statistic">
          <p className="sub-text">
            This amount is a baseline estimate of the extra tax you may have to
            pay for next year, based on your current income, contribution room,
            and portfolio simulations.
          </p>
        </div>
        <NavigationButtons
          handleNextPage={this.nextStep}
          showPreviousButton={false}
          nextButtonDisabled={this.isDisabled()}
        />
        <div style={{ clear: 'both' }} />
      </BlueprintContent>
    );
  }
}

TaxImplications.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  result: PropTypes.object,
  monthlySavingChosen: PropTypes.number,
  tax_implications: PropTypes.object,
};

TaxImplications.defaultProps = {
  result: null,
  monthlySavingChosen: null,
  history: {},
  match: {},
  tax_implications: undefined,
};

export default connect(() => ({}))(TaxImplications);

const BlueprintContent = styled.div`
  padding: 2rem 3rem;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 2rem;
  .header {
    .sub.header {
      font-size: 1.2rem;
    }
  }
  .sub-statistic {
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .p {
      max-width: 770px;
    }
    .sub-text {
      color: rgba(0, 0, 0, 0.6);
    }
    .sub-warning {
      color: ${colors.red} !important;
      font-weight: bold;
      margin: 0.7rem 0 0.7rem 0;
    }
  }
`;
