import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';

import { Message } from 'components';
import { arrangeSector } from 'utils/helpers';
import { ExposureChart, ExposureTable } from '../components';
import { filterData } from '../../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class MacroFactor extends Component {
  render() {
    const { data, french } = this.props;
    const filteredRiskF =
      typeof data.riskExp.F === 'object' &&
      filterData(arrangeSector(data.riskExp.F));

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content={
            french
              ? "Analyse de l'exposition au risque"
              : 'Risk Exposure Analysis'
          }
          subheader={
            french ? 'Par facteur macroéconomique' : 'By Macroeconomic Factor'
          }
        />
        {typeof data.riskExp.F === 'string' ? (
          <Message visible warning>
            {data.riskExp.F}
          </Message>
        ) : (
          <div>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <p>
                    {french
                      ? 'Cette section montre la répartition du risque de votre portefeuille à sept facteurs macroéconomiques.'
                      : 'This section shows your portfolio’s risk attribution to seven macroeconomic factors.'}
                  </p>
                  <Header
                    size="medium"
                    content={
                      french
                        ? 'Exposition aux risques macroéconomiques'
                        : 'Macroeconomic Risk Exposure'
                    }
                    style={{ margin: 0 }}
                  />
                  <ExposureChart
                    title=""
                    labels={Object.keys(filteredRiskF)}
                    data={Object.values(filteredRiskF)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      french ? 'Actif' : 'Asset',
                      <div>
                        {french
                          ? 'Exposition aux risques macroéconomiques'
                          : 'Macroeconomic Risk Exposure'}
                        &nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                          style={{ height: 'initial' }}
                          content={
                            french
                              ? 'Un chiffre négatif signifie que votre portefeuille est couvert contre ce facteur. Le risque de votre portefeuille est inversement lié à l’évolution de ce facteur..'
                              : 'A negative number means that your portfolio is hedged against that factor. The risk of your portfolio is negatively related to movements in that factor. Factors with {"-"} risk exposure are statistically insignificant.'
                          }
                        />
                      </div>,
                    ]}
                    totals={[french ? 'Total' : 'Total', <div>100%</div>]}
                    data={Object.keys(data.riskExp.F).reduce((total, key) => {
                      total.push({
                        ticker: key,
                        macroRiskExp: data.riskExp.F[key],
                      });

                      return total;
                    }, [])}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Container>
    );
  }
}

MacroFactor.propTypes = {
  data: PropTypes.object.isRequired,
  french: PropTypes.bool,
};

MacroFactor.defaultProps = { french: false };

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
