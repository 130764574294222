import React, { Component } from 'react';
import "babel-polyfill";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Form, Radio } from 'semantic-ui-react';
import cn from 'classnames';
import { Planning } from 'actions';
import { Button } from 'components';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class InvestmentScenarios extends Component {
  state = {
    warning: {},
  };

  getPortfolio = (portfolios, portfolioId) =>
    portfolios.find((port) => port.id === Number(portfolioId));

  submit = async () => {
    const { dispatch, drawdown, portfolios } = this.props;
    const { inputs, errors } = drawdown;

    const validateInputs = (inputs, spouse) => {
      const spouseSuffix = spouse === 'spouse' ? '' : '2';
      return Object.keys(inputs).reduce(
        (acc, val) => {
          if (spouse === 'spouse' && val.endsWith('2')) return acc;
          if (spouse === 'spouse2' && !val.endsWith('2')) return acc;

          const cleanVal = val.replace('2', '');

          if (
            cleanVal === 'constantRets' &&
            !inputs[`isRiskFree${spouseSuffix}`]
          ) {
            return acc;
          } else if (
            cleanVal === 'constantRets' &&
            inputs[`isRiskFree${spouseSuffix}`]
          ) {
            if (Number(inputs[val]) <= 0) {
              return acc;
            } else if (inputs[val].toString().split('.')[1]) {
              acc[cleanVal] = Number.parseFloat(inputs[val]).toFixed(2);
              return acc;
            }
          }

          if (inputs[val] !== '' && cleanVal !== 'activePage') {
            acc[cleanVal] = inputs[val];
          }

          return acc;
        },
        {
          rrifAge: inputs[`cppAge${spouseSuffix}`] || 0,
        }
      );
    };

    const validateRequiredFields = (validated, spouse) => {
      const spouseSuffix = spouse === 'spouse' ? '' : '2';
      const requiredFields = [
        `age`,
        `horizon`,
        `endValue`,
        `cppAge`,
        `oasAge`,
        `cpp`,
        `oas`,
        `initRegular`,
        !inputs[`isRiskFree${spouseSuffix}`] ? `pctCapGain` : '',
        !inputs[`isRiskFree${spouseSuffix}`] ? 'divYield' : '',
        !inputs[`isRiskFree${spouseSuffix}`] ? 'pctEligibleDiv' : '',
        'initRRIF',
        'initTFSA',
        !inputs[`isRiskFree${spouseSuffix}`] ? 'portfolio' : 'constantRets',
        !!Number(inputs.other) && 'otherHorizon',
      ];

      const errorObject = requiredFields.reduce((acc, val) => {
        const errorKey = val ? `${val}${spouseSuffix}` : val;

        if (val && validated[val] === undefined) {
          acc[errorKey] = true;
        }

        // Add specific field validations (age, cppAge, oasAge, etc.)
        if (val === 'age' && Number(validated[val]) < 49) {
          acc[errorKey] = '(cannot be less than 49)';
        }

        if (val === 'cpp') {
          if (typeof errors.cpp === 'string') {
            acc[errorKey] = errors.cpp;
          }
        }

        if (val === 'oas') {
          if (typeof errors.oas === 'string') {
            acc[errorKey] = errors.oas;
          }
        }

        if (val === 'cppAge') {
          if (inputs.cppStarted) {
            if (Number(validated[val]) < 60) {
              acc[errorKey] =
                'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
            }
          } else if (!inputs.cppStarted) {
            if (Number(validated[val]) > 70 || Number(validated[val]) < 60) {
              acc[errorKey] =
                'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
            }
          }
        }

        if (val === 'oasAge') {
          if (inputs.oasStarted) {
            if (Number(validated[val]) < 65) {
              acc[errorKey] =
                'You can only start collecting OAS benefits between age 65 and age 70.';
            }
          } else if (!inputs.oasStarted) {
            if (Number(validated[val]) > 70 || Number(validated[val]) < 65) {
              acc[errorKey] =
                'You can only start collecting OAS benefits between age 65 and age 70.';
            }
          }
        }

        if (val === 'horizon' && Number(validated[val]) < 1) {
          acc[errorKey] = '(cannot be less than 1)';
        }

        if (
          val === 'otherHorizon' &&
          Number(validated[val]) > Number(validated.horizon || 0)
        ) {
          acc[errorKey] = '(cannot be longer than your planning horizon)';
        }

        if (
          val === 'initRegAccList' &&
          (!inputs.initRegAccList || !inputs.initRegAccList.length)
        ) {
          acc.initRegular = true;
        }

        if (
          val === 'initRRIFAccList' &&
          (!inputs.initRRIFAccList || !inputs.initRRIFAccList.length)
        ) {
          acc.initRRIF = true;
        }

        if (
          val === 'initTFSAAccList' &&
          (!inputs.initTFSAAccList || !inputs.initTFSAAccList.length)
        ) {
          acc.initTFSA = true;
        }

        return acc;
      }, {});

      const hasError = Object.values(errorObject).find((val) => val);
      if (hasError) {
        dispatch(
          Planning.drawdownUpdateInput({
            errors: errorObject,
            modified: true,
          })
        );
        return true;
      }
      return false;
    };

    const validatedSpouse = validateInputs(inputs, 'spouse');
    const validatedSpouse2 = validateInputs(inputs, 'spouse2');

    if (
      validateRequiredFields(validatedSpouse, 'spouse') ||
      validateRequiredFields(validatedSpouse2, 'spouse2')
    ) {
      return;
    }

    const selectedPortfolioSpouse = this.getPortfolio(
      portfolios,
      inputs.portfolio
    );
    const selectedPortfolioSpouse2 = this.getPortfolio(
      portfolios,
      inputs.portfolio2
    );

    const submitSpouse = async (spouse, validated, selectedPortfolio) => {
      const spouseSuffix = spouse === 'spouse' ? '' : '2';
      const isRiskFreeField = `isRiskFree${spouseSuffix}`;
      const constantRetsField = `constantRets${spouseSuffix}`;

      const portfolio = !inputs[isRiskFreeField]
        ? {
            isRiskFree: false,
            portId: selectedPortfolio.id,
            symbols: selectedPortfolio.symbols,
            weights: selectedPortfolio.weights,
            gic_info: selectedPortfolio.gic_info,
          }
        : {
            constantRets: Number(validated.constantRets) / 100,
            isRiskFree: true,
          };

      const scenario = inputs.scenario || 'median'; // Default to "median" if not provided

      return dispatch(
        Planning.calcDrawdown(
          {
            ...validated,
            ...portfolio,
            init_date: portfolio.init_date,
            otherHorizon: Number(validated.otherHorizon) || 0,
            divYield: inputs[isRiskFreeField]
              ? 0
              : Number(validated.divYield) / 100,
            pctCapGain: inputs[isRiskFreeField]
              ? 0
              : Number(validated.pctCapGain) / 100,
            pctEligibleDiv: inputs[isRiskFreeField]
              ? 0
              : Number(validated.pctEligibleDiv) / 100,
            pensionInf: validated.pensionInf,
            scenario,
            prov: inputs.prov,
          },
          true
        )
      );
    };

    try {
      const spouseResult = await submitSpouse(
        'spouse',
        validatedSpouse,
        selectedPortfolioSpouse
      );
      const spouse2Result = await submitSpouse(
        'spouse2',
        validatedSpouse2,
        selectedPortfolioSpouse2
      );

      const spouseYearlyDetails =
        spouseResult &&
        spouseResult.result &&
        spouseResult.result.yearlyDetails;
      const spouse2YearlyDetails =
        spouse2Result &&
        spouse2Result.result &&
        spouse2Result.result.yearlyDetails;

      if (
        spouseResult.success &&
        spouse2Result.success &&
        spouseYearlyDetails &&
        spouse2YearlyDetails
      ) {
        const coupleDrawdownParams = {
          prov: inputs.prov,
          person1_yearly_taxdata: spouseYearlyDetails,
          person2_yearly_taxdata: spouse2YearlyDetails,
        };

        dispatch(
          Planning.calcCoupleDrawdown(
            coupleDrawdownParams,
            spouseResult.result,
            spouse2Result.result
          )
        );
        dispatch(Planning.navigateDrawdown(6));
      } else if (
        spouseResult &&
        spouseResult.result &&
        spouseResult.result.error ===
          'Sorry, no optimal solution was found for the parameters you entered.' &&
        spouse2Result &&
        spouse2Result.result &&
        spouse2Result.result.error ===
          'Sorry, no optimal solution was found for the parameters you entered.'
      ) {
        dispatch(Planning.coupleDrawdownBothError());
        dispatch(Planning.navigateDrawdown(6));
      } else {
        if (
          spouseResult.success &&
          spouseYearlyDetails &&
          typeof spouseResult.result.error !== 'string'
        ) {
          dispatch(
            Planning.couplePartialSuccess(spouseResult.result, 'spouse')
          );
        } else if (
          spouse2Result.success &&
          spouse2YearlyDetails &&
          spouse2Result.result &&
          typeof spouse2Result.result.error !== 'string'
        ) {
          dispatch(
            Planning.couplePartialSuccess(spouse2Result.result, 'spouse2')
          );
        }
        dispatch(Planning.navigateDrawdown(6));
      }
    } catch (error) {
      console.error('Error in submitting drawdown:', error);
      dispatch(Planning.navigateDrawdown(6));
    }
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;
    const { activeSpouse } = drawdown.inputs;
    const getField = (field) =>
      activeSpouse === 'spouse' ? field : `${field}2`;

    if (field === 'isRiskFree') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [getField(field)]: value,
            [getField('pctCapGain')]: 0,
            [getField('divYield')]: 0,
            [getField('pctEligibleDiv')]: 0,
            ...(!value && {
              [getField('portfolio')]: drawdown.inputs[getField('portfolio')],
            }),
          },
          errors: {
            [getField('pctCapGain')]: false,
            [getField('divYield')]: false,
            [getField('pctEligibleDiv')]: false,
            [getField('portfolio')]: false,
            [getField('constantRets')]: false,
          },
          modified: true,
        })
      );
    }

    if (field === 'cppStarted') {
      if (drawdown.inputs[getField('cppStarted')] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [getField(field)]: value,
              [getField('cppAge')]: '',
              [getField('cpp')]: '',
            },
            errors: { [getField('cppAge')]: false, [getField('cpp')]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { cpp: '' } });
      }
    }

    if (field === 'oasStarted') {
      if (drawdown.inputs[getField('oasStarted')] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [getField(field)]: value,
              [getField('oasAge')]: '',
              [getField('oas')]: '',
            },
            errors: { [getField('oasAge')]: false, [getField('oas')]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { oas: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [getField(field)]: value },
        errors: {},
        modified: true,
      })
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const {
      dispatch,
      drawdown,
      cppDataset,
      oasDataset,
      cppEstimator,
    } = this.props;
    const { inputs } = drawdown;
    const { activeSpouse } = inputs;
    const getField = (field) =>
      activeSpouse === 'spouse' ? field : `${field}2`;
    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
      ];

      if (field === 'horizon') {
        const maxHorizon = 80;
        if (Number(val) >= maxHorizon) {
          // Display an error message for horizon exceeding the maximum
          this.setState({
            warning: {
              ...this.state.warning,
              [getField(field)]:
                'Please input the number of years you are expected to be retired for, rather than the age you expect to live until.',
            },
          });
          return maxHorizon;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) {
        return 70;
      }

      if (isOas && Number(age) < 65) {
        return 65;
      }

      if (Number(age) < 60) {
        return 60;
      } else if (Number(age) > 70) {
        return 70;
      }

      return age;
    };

    if (name === 'age') {
      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), true))
      ).amount;
      if (Number(value) < 60) {
        this.changeSelection('cppStarted', false);
        this.changeSelection('oasStarted', false);
      } else if (Number(value) < 65) {
        this.changeSelection('oasStarted', false);
        this.changeSelection('cppStarted', true);
      } else {
        this.changeSelection('oasStarted', true);
        this.changeSelection('cppStarted', true);
      }
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [getField('age')]: parsedValue(name, value),
            ...(Number(value) > 60 && {
              [getField('cppAge')]: parsedValue(name, value),
            }),
            ...(Number(value) > 65 && {
              [getField('oasAge')]: parsedValue(name, value),
            }),
          },
          errors: {
            [getField('age')]: false,
            ...(Number(value) > 60 &&
              inputs[getField('cppAge')] && { [getField('cppAge')]: false }),
            ...(Number(value) > 65 &&
              inputs[getField('oasAge')] && { [getField('oasAge')]: false }),
            // ...(inputs.cppStarted &&
            //   inputs.cpp > cppMax && {
            //     cpp:
            //       'The benefit amount you entered exceeded the maximum for your current age.',
            //   }),
            ...(inputs[getField('oasStarted')] &&
              inputs[getField('oas')] > oasMax && {
                [getField('oas')]:
                  'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs[getField('cppStarted')] &&
              inputs[getField('cpp')] <= cppMax && { [getField('cpp')]: null }),
            ...(inputs[getField('oasStarted')] &&
              inputs[getField('oas')] <= oasMax && { [getField('oas')]: null }),
            ...(!inputs[getField('cppStarted')] && {
              [getField('cppAge')]: null,
            }),
            ...(!inputs[getField('oasStarted')] && {
              [getField('oasAge')]: null,
            }),
          },
          modified: true,
        })
      );
    }

    if (name === 'cppAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [getField('cppAge')]: parsedValue(name, value),
            [getField('cpp')]: '',
          },
          errors: { [getField('cppAge')]: false, [getField('cpp')]: false },
          modified: true,
        })
      );
    }

    if (name === 'oasAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [getField('oasAge')]: parsedValue(name, value),
            [getField('oas')]: '',
          },
          errors: { [getField('oasAge')]: false, [getField('oas')]: false },
          modified: true,
        })
      );
    }

    if (name === 'cpp') {
      if (!inputs[getField('cppAge')] && inputs[getField('cppStarted')]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [getField('cppAge')]: inputs[getField('age')] || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();

      const body = {
        retirement_year:
          Number(currentYear) +
          Number(inputs[getField('cppAge')]) -
          Number(inputs[getField('age')]),
        start_cpp_age: inputs[getField('cppAge')],
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (
        inputs[getField('cppAge')] !== '' &&
        inputs[getField('cppAge')] >= 60
      ) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, [getField('cpp')]: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [getField('cpp')]: money },
            errors: { [getField('cpp')]: false },
            modified: true,
          })
        );
      }
    }

    if (name === 'oas') {
      if (!inputs[getField('oasAge')] && inputs[getField('oasStarted')]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [getField('oasAge')]: inputs[getField('age')] || 0 },
            modified: true,
          })
        );
      }

      if (!inputs[getField('oasAge')] || inputs[getField('oasAge')] > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs[getField('oasAge')] < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs[getField('oasAge')])
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs[getField('oasAge')],
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, [getField('oas')]: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [getField('oas')]: money },
          errors: { [getField('oas')]: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [getField('other')]: parsedValue(name, value) },
          errors: { [getField('otherHorizon')]: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [getField(name)]: parsedValue(name, value) },
        errors: { [getField(name)]: false },
        modified: true,
      })
    );
  };

  hasErrors = () => {
    const { errors } = this.props.drawdown;
    return Object.values(errors).some((error) => error);
  };

  portfolioIsDeleted = (inputs, portfolios) => {
    const { activeSpouse } = inputs;
    const getField = (field) =>
      activeSpouse === 'spouse' ? field : `${field}2`;

    if (inputs[getField('isRiskFree')] === undefined) return false;
    if (inputs[getField('isRiskFree')]) return false;
    if (inputs[getField('portfolio')] === undefined) return false;

    return !portfolios.find((p) => p.id === inputs[getField('portfolio')]);
  };

  handleRadioChange = (e, { value, name }) => {
    this.setState({ [name]: value });
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );

    switch (name) {
      case 'initRegularFromAcc':
      case 'initRegularFromAcc2':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [getField('initRegular')]: null,
              [getField('initRegAccList')]: [],
            },
            modified: true,
          })
        );
        break;
      case 'initRRIFFromAcc':
      case 'initRRIFFromAcc2':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [getField('initRRIF')]: null,
              [getField('initRRIFAccList')]: [],
            },
            modified: true,
          })
        );
        break;
      case 'totalTfsaFromAcc':
      case 'totalTfsaFromAcc2':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [getField('initTFSA')]: null,
              [getField('initTFSAAccList')]: [],
            },
            modified: true,
          })
        );
        break;
      default:
    }
  };

  render() {
    const { inputs, isFetching } = this.props.drawdown;
    const { portfolios } = this.props;
    const { scenario } = this.props;
    const { activeSpouse } = inputs;
    const getField = (field) =>
      activeSpouse === 'spouse' ? field : `${field}2`;

    return (
      <div>
        {!(inputs.isRiskFree && inputs.isRiskFree2) ? (
          <div>
            <Section single>
              <div className="title">
                We will run 10,000 simulations using your investment portfolio's
                historical returns.
              </div>
              <div style={{ marginLeft: '1rem' }}>
                <Form onSubmit={this.submit}>
                  <Form.Field>
                    <Radio
                      name="scenario"
                      value="median"
                      checked={scenario === 'median'}
                      onChange={this.handleRadioChange}
                      label="Show me the median outcome."
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      name="scenario"
                      value="worst3"
                      checked={scenario === 'worst3'}
                      onChange={this.handleRadioChange}
                      label="Show me a poor outcome with sequence of returns risk."
                    />
                    <Popup
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      wide
                      content="This scenario assumes that in the first three years of the planning horizon, the portfolio experiences the three worst annualized returns (beginning with the lowest in year 1) based on 10,000 simulations of historical monthly return paths."
                    />
                  </Form.Field>
                </Form>
              </div>
            </Section>
            {this.hasErrors() && (
              <div
                style={{
                  color: `${colors.red}`,
                  marginTop: '2rem',
                  fontWeight: 'bold',
                }}
              >
                Please ensure all your inputs are complete before generating
                your plan.
              </div>
            )}
            <Button
              className={cn('left', { loading: isFetching })}
              color={colors.blue}
              icon="calculator"
              onClick={this.submit}
              style={{
                margin: '1rem 0',
                position: 'absolute',
                zIndex: '3',
                bottom: '15px',
                right: '25px',
                opacity: this.portfolioIsDeleted(inputs, portfolios)
                  ? '0.45'
                  : '1',
              }}
              disabled={this.portfolioIsDeleted(inputs, portfolios)}
            >
              Calculate
            </Button>
          </div>
        ) : (
          <div>
            <Section single>
              <div className="title">
                Since you chose to put your retirement savings in a risk free
                investment, your investment scenario can be calculated with
                certainty. Please click "Calculate" to proceed.
              </div>
            </Section>
            {this.hasErrors() && (
              <div
                style={{
                  color: `${colors.red}`,
                  marginTop: '2rem',
                  fontWeight: 'bold',
                }}
              >
                Please ensure all your inputs are complete before generating
                your plan.
              </div>
            )}
            <Button
              className={cn('left', { loading: isFetching })}
              color={colors.blue}
              icon="calculator"
              onClick={this.submit}
              style={{
                margin: '1rem 0',
                position: 'absolute',
                zIndex: '3',
                bottom: '15px',
                right: '25px',
                opacity: this.portfolioIsDeleted(inputs, portfolios)
                  ? '0.45'
                  : '1',
              }}
              disabled={this.portfolioIsDeleted(inputs, portfolios)}
            >
              Calculate
            </Button>
          </div>
        )}
      </div>
    );
  }
}

InvestmentScenarios.propTypes = {
  dispatch: PropTypes.func,
  portfolios: PropTypes.array,
  drawdown: PropTypes.object,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  cppEstimator: PropTypes.object,
  scenario: PropTypes.string,
};

InvestmentScenarios.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  drawdown: {},
  cppDataset: [],
  oasDataset: [],
  cppEstimator: {},
  scenario: '',
};

const Section = styled.section`
  display: flex; /* Add this line */
  flex-direction: column;
  padding-left: 2rem;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
