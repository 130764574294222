import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Statistic } from 'semantic-ui-react';

const Result = ({ cpp }) =>
  !cpp ? null : (
    <Statistic size="small" style={{ margin: '0' }}>
      <Statistic.Label>
        Your CPP/QPP pension per year, in today’s dollars, is estimated to be
      </Statistic.Label>
      <Statistic.Value style={{ position: 'relative' }}>
        <Icon name="dollar" />
        {Math.round(cpp).toLocaleString()}
      </Statistic.Value>
    </Statistic>
  );

Result.propTypes = {
  cpp: PropTypes.number,
};

Result.defaultProps = {
  cpp: null,
};

export default Result;
