import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label, Header, Form, Popup } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Segment, Button } from 'components';

class ExcludeSector extends Component {
  handleChange = (e, { name, value }) =>
    this.props.updateSelection({ [name]: [value] });

  handleSubmit = () => {
    const { moveToNextSection, pData, sector } = this.props;

    return moveToNextSection({ sector: [sector, pData[sector].total] }, 2);
  };

  renderUnavailable = (sector) => {
    const { data } = this.props;
    const hash = {
      adventurous: 'Adventurous',
      allin: 'All-In',
      cautious: 'Cautious',
      classic: 'Classic',
      safe: 'Playing-It-Safe',
    };

    const result = Object.keys(data).reduce((acc, val) => {
      if (data[val][sector]) {
        return acc;
      }

      return acc.concat(hash[val]);
    }, []);

    return (
      <span>
        {result.map((item, idx) => {
          if (idx === result.length - 1) {
            return <b>{item}.</b>;
          }

          return <b>{item}, </b>;
        })}
      </span>
    );
  };

  render() {
    const { previousTab, pData, sector } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Header size="medium" textAlign="center">
            Do you want to exclude a sector?
            <Header.Subheader style={{ fontSize: '1rem' }}>
              One reason you may wish to do so is human capital risk. If you are
              employed in a particular sector you may want to exclude it from
              your investment portfolio to diversify risk.
            </Header.Subheader>
          </Header>
          <Segment basic compact textAlign="center">
            <Form.Group
              grouped
              style={{ display: 'inline-block', textAlign: 'left' }}
            >
              <Form.Radio
                toggle
                name="sector"
                label="Energy"
                value="energy"
                checked={sector === 'energy'}
                onChange={this.handleChange}
              />
              <Form.Radio
                toggle
                name="sector"
                label="Financial"
                value="fin"
                checked={sector === 'fin'}
                onChange={this.handleChange}
              />
              <Form.Radio
                toggle
                name="sector"
                label="Information Technology"
                value="infotech"
                checked={sector === 'infotech'}
                onChange={this.handleChange}
              />
              {this.props.pData.materials ? (
                <Form.Radio
                  toggle
                  name="sector"
                  label="Materials"
                  value="materials"
                  checked={sector === 'materials'}
                  onChange={this.handleChange}
                />
              ) : (
                <Popup
                  trigger={
                    <Form.Radio
                      toggle
                      disabled
                      style={{ pointerEvents: 'all' }}
                      name="sector"
                      label="Materials"
                      value="materials"
                      checked={sector === 'materials'}
                      onChange={this.handleChange}
                    />
                  }
                  position="right center"
                  style={{ height: 'initial' }}
                  offset={10}
                >
                  Not available for these investors:{' '}
                  {this.renderUnavailable('materials')} Most portfolios in these
                  categories have exposure to the Materials sector.
                </Popup>
              )}
              <Form.Radio
                toggle
                name="sector"
                label="I do not want to exclude any of the above sectors"
                value="none"
                checked={sector === 'none'}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Label
              basic
              color="blue"
              size="large"
              attached="bottom"
              style={{
                width: '60%',
                left: '20%',
                bottom: '-4.4rem',
                border: 0,
                lineHeight: '1.4rem',
              }}
            >
              <div style={{ fontSize: '1.5rem' }}>
                {sector
                  ? pData[sector].total.toLocaleString()
                  : pData.total.toLocaleString()}
              </div>
              matching portfolios
            </Label>
          </Segment>
          <Button
            className="left"
            color={colors.orange}
            icon="chevron left"
            onClick={previousTab}
          >
            Back
          </Button>
          <Button
            className={cn('right', { disabled: !sector })}
            color={colors.orange}
            icon="chevron right"
            floated="right"
          >
            Next
          </Button>
        </Form>
      </div>
    );
  }
}

ExcludeSector.propTypes = {
  pData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateSelection: PropTypes.func.isRequired,
  moveToNextSection: PropTypes.func.isRequired,
  previousTab: PropTypes.func.isRequired,
  sector: PropTypes.string,
};

ExcludeSector.defaultProps = {
  sector: '',
};

export default connect()(ExcludeSector);
