import * as Alert from './alert';
import { request } from './api';

/**
 * Action constants
 */
export const STOCKEXPOSURE_REQUEST = 'STOCKEXPOSURE_REQUEST';
export const STOCKEXPOSURE_SEARCH_REQUEST = 'STOCKEXPOSURE_SEARCH_REQUEST';

export const STOCKEXPOSURE_FETCH_SUCCESS = 'STOCKEXPOSURE_FETCH_SUCCESS';
export const STOCKEXPOSURE_SEARCH_SUCCESS = 'STOCKEXPOSURE_SEARCH_SUCCESS';

export const STOCKEXPOSURE_CLEAR = 'STOCKEXPOSURE_CLEAR';
export const STOCKEXPOSURE_FAIL = 'STOCKEXPOSURE_FAIL';
export const STOCKEXPOSURE_PREV_SEARCH = 'STOCKEXPOSURE_PREV_SEARCH';

/**
 * Action creators
 */
export function fetch(id) {
  return (dispatch) => {
    dispatch({ type: STOCKEXPOSURE_REQUEST });

    return dispatch(request('get', `/accounts/stock-lookup?ticker=${id}`)).then(
      (data) => dispatch({ type: STOCKEXPOSURE_FETCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: STOCKEXPOSURE_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function search(id) {
  return (dispatch) => {
    dispatch({ type: STOCKEXPOSURE_SEARCH_REQUEST });

    return dispatch(request('get', `/security/search/stock?term=${id}`)).then(
      (data) => dispatch({ type: STOCKEXPOSURE_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: STOCKEXPOSURE_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function searchAll(id) {
  return (dispatch) => {
    dispatch({ type: STOCKEXPOSURE_REQUEST });

    return dispatch(request('get', `/security/search?term=${id}`)).then(
      (data) => dispatch({ type: STOCKEXPOSURE_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: STOCKEXPOSURE_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}
export function addPrevSearch(ticker) {
  return { type: STOCKEXPOSURE_PREV_SEARCH, ticker };
}

export function clearSecurity() {
  return (dispatch) => dispatch({ type: STOCKEXPOSURE_CLEAR });
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
