import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Api } from 'actions';
import { colors } from 'utils/colors';
import { Table } from 'components';
import { isGlobeInvestor } from 'utils/helpers';

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))
export default class Execute extends Component {
  state = {
    tickersValidExchanges: {},
    tickersValidWsMatches: {},
    currentPage: 1,
    etfsPerPage: 3, // Number of Highly Correlated ETFs to display per page
  };
  componentDidMount = () => {
    const { data, dispatch } = this.props;
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${Object.keys(data.tickersValid).join()}`,
      })
    ).then((result) => {
      this.setState({ tickersValidExchanges: result });
    });
    const matchTickerValues = Object.values(data.tickersValid)
      .flatMap(({ wsMatches }) => wsMatches)
      .map(({ matchTicker }) => matchTicker)
      .join();
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${matchTickerValues}`,
      })
    ).then((result) => {
      this.setState({ tickersValidWsMatches: result });
    });
  };
  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker) => {
    const { tickersValidWsMatches } = this.state;
    const exchangeSymbol = tickersValidWsMatches.find(
      (item) => item.ticker === ticker
    ).exchange_symbol;
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };
  appendExchangeSymbol2 = (ticker) => {
    const { tickersValidExchanges } = this.state;
    const exchangeSymbol = tickersValidExchanges.find(
      (item) => item.ticker === ticker
    ).exchange_symbol;
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };
  handleClick = (event, ticker) => {
    event.preventDefault();
    window.open(
      this.createUrl(this.gbmConvert(this.appendExchangeSymbol(ticker)))
    ),
      '_blank';
  };
  handleClick2 = (event, ticker) => {
    event.preventDefault();
    window.open(
      this.createUrl(this.gbmConvert(this.appendExchangeSymbol2(ticker)))
    ),
      '_blank';
  };
  handleClickLeft = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage - 1,
      }));
    }
  };

  handleClickRight = () => {
    const { currentPage, etfsPerPage } = this.state;
    const { data } = this.props;
    const totalEtfs = this.findLongestWsMatchesCount(data.tickersValid);
    const totalPages = Math.ceil(Number(totalEtfs) / Number(etfsPerPage));
    if (currentPage < totalPages) {
      this.setState((prevState) => ({
        currentPage: prevState.currentPage + 1,
      }));
    }
  };

  getDisplayedETFs = () => {
    const { data } = this.props;
    const { currentPage, etfsPerPage } = this.state;
    const sortedTickers = this.sortTicker(data.tickersValid);
    const displayedETFs = [];

    sortedTickers.forEach((key) => {
      const etf = data.tickersValid[key];
      const { wsMatches } = etf;
      const startIndex = (currentPage - 1) * etfsPerPage;
      const endIndex = startIndex + etfsPerPage;
      const displayedMatches = wsMatches.slice(startIndex, endIndex);

      displayedETFs.push({
        key,
        etf,
        displayedMatches,
      });
    });

    return displayedETFs;
  };

  getMaxCorETFs = (data) =>
    Object.keys(data.tickersValid).reduce((max, ticker) => {
      if (data.tickersValid[ticker].wsMatches) {
        if (data.tickersValid[ticker].wsMatches.length > max) {
          max = data.tickersValid[ticker].wsMatches.length;
        }
      }

      return max;
    }, 0);

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  sortTicker = (data) => {
    const tickerCorEtfLenPair = Object.keys(data).reduce((result, ticker) => {
      result.push([ticker, data[ticker].wsMatches.length]);

      return result;
    }, []);

    return tickerCorEtfLenPair
      .sort((a, b) => b[1] - a[1])
      .reduce((result, ticker) => {
        result.push(ticker[0]);

        return result;
      }, []);
  };

  renderDummyCell = (corEtfsLength, maxCorETFs) => {
    const dummyCell = [];
    for (let i = 0; i < maxCorETFs - corEtfsLength; i += 1) {
      dummyCell.push(<Table.Cell key={i} />);
    }

    return dummyCell;
  };
  renderDummyHeaderCell = (displayedMatchesLength) => {
    const dummyCells = [];
    const numDummyCells = displayedMatchesLength - 1;

    for (let i = 0; i < numDummyCells; i++) {
      dummyCells.push(<Table.HeaderCell key={i} />);
    }

    return dummyCells;
  };

  findLongestWsMatchesCount(tickersValid) {
    return Object.values(tickersValid).reduce((longestCount, { wsMatches }) => {
      const wsMatchesCount = wsMatches.length;
      return wsMatchesCount > longestCount ? wsMatchesCount : longestCount;
    }, 0);
  }

  render() {
    const { data, partnerName } = this.props;
    const { currentPage, etfsPerPage } = this.state;
    const maxCorETFs = this.getMaxCorETFs(data);
    const displayedETFs = this.getDisplayedETFs();
    const totalEtfs = this.findLongestWsMatchesCount(data.tickersValid);
    const totalPages = Math.ceil(totalEtfs / etfsPerPage);

    return (
      <Container>
        <Header size="large" content="Execute" />
        <p>
          To execute your portfolio, you need to choose an ETF for each asset
          class, and purchase the ETFs through your broker.
        </p>
        <p>
          Please read the prospectus of each ETF (available on the ETF
          provider&#8217;s website) carefully before making a decision and
          consult a financial advisor. The list below should not be construed as
          a recommendation for any security.
        </p>
        {this.props.user_region === 'CA' && (
          <p>
            Should you choose a Canadian or U.S.-listed ETF? Read our short
            article,
            <br />
            <a
              href="https://www.wealthscope.ca/foreignwithholdingtax"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                Investing Abroad - Canadian or U.S. Listed ETFs? Some Tax
                Considerations
              </b>
            </a>
          </p>
        )}
        <StyledDiv notGlobe={!isGlobeInvestor(partnerName)}>
          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                {(maxCorETFs
                  ? [
                      'Asset',
                      'Weight (%)',
                      'Fund Benchmark',
                      'Proxy ETF',
                      'Highly Correlated ETFs',
                    ]
                  : ['Asset', 'Weight (%)', 'Fund Benchmark', 'Proxy ETF']
                ).map((header) => (
                  <Table.HeaderCell key={header}>
                    {header === 'Proxy ETF' ? (
                      <div>
                        Proxy <br />
                        ETF
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content="The actual ETF that was used in the analytics."
                          wide
                          style={{ display: 'inline-block' }}
                        />
                      </div>
                    ) : (
                      header
                    )}
                  </Table.HeaderCell>
                ))}
                {maxCorETFs && (
                  <React.Fragment>
                    <Table.HeaderCell
                      style={{
                        cursor: currentPage === 1 ? 'unset' : 'pointer',
                      }}
                      onClick={this.handleClickLeft}
                    >
                      <Icon
                        name="chevron left"
                        style={{
                          color: currentPage === 1 ? 'unset' : colors.blue,
                          opacity: currentPage === 1 ? '0.1' : '1',
                        }}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        cursor:
                          currentPage === totalPages ? 'unset' : 'pointer',
                      }}
                      onClick={this.handleClickRight}
                    >
                      <Icon
                        name="chevron right"
                        style={{
                          color:
                            currentPage === totalPages ? 'unset' : colors.blue,
                          opacity: currentPage === totalPages ? '0.1' : '1',
                        }}
                      />
                    </Table.HeaderCell>
                  </React.Fragment>
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body style={{ overflowX: 'hidden' }}>
              {displayedETFs.map(({ key, etf, displayedMatches }) => (
                <Table.Row key={key}>
                  <Table.Cell>
                    {this.isLinked(data) ? etf.name : etf.wsName}
                  </Table.Cell>
                  <Table.Cell>{`${
                    Math.round(etf.weight * 10000) / 100
                  }%`}</Table.Cell>
                  <Table.Cell>{etf.wsBnmk}</Table.Cell>
                  <Table.Cell>
                    <strong>
                      {isGlobeInvestor(partnerName) ? (
                        <a
                          style={{
                            zIndex: '9999999',
                            display: 'block',
                            cursor: 'pointer',
                            pointerEvents: 'all',
                          }}
                          onClick={() => this.handleClick2(event, key)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {key}
                        </a>
                      ) : (
                        key
                      )}
                    </strong>
                    <div style={{ lineHeight: '1.2rem', fontSize: '0.9rem' }}>
                      {etf.name}
                    </div>
                  </Table.Cell>
                  {displayedMatches.map((item) => (
                    <Table.Cell key={item}>
                      <strong>
                        {isGlobeInvestor(partnerName) ? (
                          <a
                            style={{
                              zIndex: '9999999',
                              display: 'block',
                              cursor: 'pointer',
                              pointerEvents: 'all',
                            }}
                            onClick={() =>
                              this.handleClick(event, item.matchTicker)
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.matchTicker}
                          </a>
                        ) : (
                          item.matchTicker
                        )}
                      </strong>
                      <div style={{ lineHeight: '1.2rem', fontSize: '0.9rem' }}>
                        {item.matchName}
                      </div>
                    </Table.Cell>
                  ))}
                  {this.renderDummyCell(displayedMatches.length, etfsPerPage)}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledDiv>
      </Container>
    );
  }
}

Execute.propTypes = {
  data: PropTypes.object,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

Execute.defaultProps = {
  data: {},
  user_region: 'CA',
  partnerName: '',
};

const StyledDiv = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 2rem;

  th {
    padding: 1rem !important;
  }

  th,
  td {
    padding: 0.7rem;
    min-width: 100px;
    max-width: 100px;
  }

  thead tr {
    display: block;
    background: #ffedd6;
  }

  tbody {
    display: block;
    overflow: auto;
    max-height: ${(props) => (props.notGlobe ? '520px' : '')};
  }

  .table {
    width: initial;
  }
`;

const Container = styled.div`
  padding: 0 2rem 1rem 2rem;
`;
