import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Checkbox, Divider, Header, Form } from 'semantic-ui-react';

import { BuildETF, Storage } from 'actions';
import { colors } from 'utils/colors';
import { Segment, Button, Loading, ErrorLabel } from 'components';
import Tabs from '../../../components/SimpleTabs';

class SelectAssets extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
    };
  }

  componentWillMount() {
    const { dispatch, user_region } = this.props;
    dispatch(Storage.setItem('toggle_region', user_region)); // Set toggle region to user_region to avoid interference from Accounts
  }

  toggleAsset = (e, { value }) => {
    const { dispatch } = this.props;

    this.setState({ error: null });

    return dispatch(BuildETF.toggleAsset({ ...value, weight: 0 }));
  };

  handleSubmit = () => {
    const { selectedAssets, history, match } = this.props;

    if (!Object.keys(selectedAssets).length) {
      return this.setState({
        error: 'Please select at least 1 asset to continue',
      });
    }

    return history.push(`/portfolio/build-etf/${match.params.id}/weights`);
  };

  renderCheckboxes = (arr) => {
    const { selectedAssets } = this.props;

    return (
      <div id="checkboxes">
        {arr.map((elem) => (
          <Checkbox
            key={elem.symbol}
            name={elem.symbol}
            label={elem.name}
            value={elem}
            checked={!!selectedAssets[elem.symbol]}
            onChange={this.toggleAsset}
            style={{ margin: '2px 0', display: 'block' }}
          />
        ))}
      </div>
    );
  };

  renderTab = (data, tab) => {
    const renderCategory = (category, subCategory) =>
      Object.keys(subCategory).map((subCategoryName) => (
        <div id="subcategory" className="subcategory" key={subCategoryName}>
          <SubHeader>{subCategoryName}</SubHeader>
          {this.renderCheckboxes(data[category][subCategoryName])}
        </div>
      ));

    if (tab === 'stocks') {
      return (
        <TabCategoryRow id="tabContent">
          {Object.keys(data).map((category) => (
            <Segment
              id="category"
              basic
              compact
              key={category}
              style={{ margin: 0 }}
            >
              <Header size="small">
                {category.toUpperCase() === 'US'
                  ? 'U.S.'
                  : category.toUpperCase()}
              </Header>
              <Divider fitted />
              <AssetCategory>
                {renderCategory(category, data[category])}
              </AssetCategory>
            </Segment>
          ))}
        </TabCategoryRow>
      );
    }

    return (
      <div id="tabContent">
        {Object.keys(data).map((category) => (
          <Segment
            id="category"
            basic
            compact
            key={category}
            style={{ margin: 0 }}
          >
            <Header size="small">
              {category.toUpperCase() === 'US'
                ? 'U.S.'
                : category.toUpperCase()}
            </Header>
            <Divider fitted />
            <AssetCategory>
              {renderCategory(category, data[category])}
            </AssetCategory>
          </Segment>
        ))}
      </div>
    );
  };

  render() {
    const { data } = this.props;
    const { error } = this.state;

    return !data ? (
      <Loading active />
    ) : (
      <Form
        onSubmit={this.handleSubmit}
        style={{ padding: '1rem', overflow: 'hidden' }}
      >
        <Header
          textAlign="center"
          size="medium"
          style={{ padding: '1rem 0 3rem 0' }}
        >
          Select Assets
          <Header.Subheader>
            Passive, index-based categories with minimum 10 years of history,
            ideal for creating core portfolios and benchmarks.
          </Header.Subheader>
          <Header.Subheader style={{ marginTop: '1rem' }}>
            Should you choose a Canadian or U.S.-listed ETF? Read our short
            article,
            <br />
            <a
              href="https://www.wealthscope.ca/foreignwithholdingtax"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                Investing Abroad - Canadian or U.S. Listed ETFs? Some Tax
                Considerations
              </b>
            </a>
          </Header.Subheader>
        </Header>
        <Tabs
          tabs={[
            {
              title: 'Stocks',
              content: this.renderTab(data.Equity, 'stocks'),
            },
            {
              title: 'Bonds',
              content: this.renderTab(data['Fixed Income']),
            },
            {
              title: 'Alternative',
              content: this.renderTab(data.Alternative),
            },
          ]}
        />

        <ErrorWrapper>
          <Button
            className="right"
            color={colors.orange}
            icon="chevron right"
            floated="right"
          >
            Next
          </Button>
          <ErrorLabel className="left" msg={error} />
        </ErrorWrapper>

        <div style={{ clear: 'both' }} />
      </Form>
    );
  }
}

SelectAssets.propTypes = {
  data: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedAssets: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user_region: PropTypes.string,
};

SelectAssets.defaultProps = {
  data: null,
  user_region: 'CA',
};

export default connect((state) => ({
  data: state.BuildETF.data,
  selectedAssets: state.BuildETF.selectedAssets,
  user_region: state.Storage.user_region || 'CA',
}))(SelectAssets);

const SubHeader = styled.div`
  border: 0;
  margin: 0.5rem 0;
  padding: 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
`;

const AssetCategory = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: start;
  align-items: start;
  .subcategory {
    width: 300px;
  }
`;

const TabCategoryRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ErrorWrapper = styled.div`
  position: relative;
  float: right;
  clear: both;
`;
