/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import cn from 'classnames';

import { CacheInputs, Portfolio, Storage, Accounts } from 'actions';
import { ACCOUNTS_SAVE_AS_PORTFOLIO, ACCOUNTS_COMPARE } from 'utils/features';
import { Feature } from 'components';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import SaveModal from './SaveModal';
import './SideBar.css'; // Created css stylesheet as the :before element (arrow) for the new feature popup needed it to be targeted

class SideBar extends Component {
  state = {
    modalIsOpen: false,
    reminderIsOpen: false,
  };

  componentWillMount = () => {
    const { ui_compare_checked } = this.props;
    if (!ui_compare_checked) {
      this.setReminderVisibility(true);
    }
  };

  componentWillUnmount = () => {
    const { dispatch, location, not_save_url_scorecard, history } = this.props;
    const portfolioPaths = /^\/portfolio\/.*$/;
    dispatch(Storage.removeItem('to_scorecard'));

    if (
      not_save_url_scorecard &&
      !portfolioPaths.test(history.location.pathname)
    ) {
      dispatch(CacheInputs.captureUrl('portfolio', '/portfolio/landing'));

      return dispatch(Storage.removeItem('not_save_url_scorecard'));
    }

    return dispatch(CacheInputs.captureUrl('portfolio', location.pathname));
  };

  setReminderVisibility = (isOpen) => this.setState({ reminderIsOpen: isOpen });

  handleReminderClick = () => {
    const { dispatch } = this.props;
    dispatch(Storage.setItem('ui_compare_checked', true));
    dispatch(Accounts.checkUiCompare());
    this.setState({ reminderIsOpen: false });
  };

  isSaveVisible = (portfolio) => {
    const { match, partnerName } = this.props;

    if (
      !portfolio ||
      isGlobeInvestor(partnerName) ||
      partnerName === 'nbdb_sandbox' ||
      partnerName === 'nbdb'
    ) {
      return false;
    }

    if (match.params.id !== 'unsaved') {
      if (!portfolio.is_mp) {
        return false;
      }
    }

    return true;
  };

  redirect = (portfolio) => {
    const { id, history, dispatch } = this.props;

    if (portfolio.name) {
      const port_id = portfolio.is_mp ? '' : id;
      dispatch(
        Portfolio.setEditing(port_id, portfolio.name, portfolio.description)
      );
    }

    if (portfolio.portfolio_type === 'quantified') {
      return history.push(`/portfolio/build-model/${id}`);
    }

    if (portfolio.portfolio_type === 'custom') {
      if (portfolio.portfolio_sub_type === 'ml_asset_allocation')
        return history.push('/portfolio/build-ml/new');

      return history.push(`/portfolio/build-custom/${id}`);
    }

    return history.push(`/portfolio/build-etf/${id}/assets`);
  };

  closeModal = () => this.setState({ modalIsOpen: false });

  handleClick = (to, name) => {
    const { history } = this.props;

    if (to) {
      return history.push(to);
    }

    return this.setState({ modalIsOpen: name });
  };

  handleCompareClick = () => {
    const { history, id, portfolio, dispatch } = this.props;
    if (portfolio.name) {
      const port_id = portfolio.is_mp ? '' : id;
      dispatch(
        Portfolio.setEditing(port_id, portfolio.name, portfolio.description)
      );
    }
    const portfolioRegex = /^\/portfolio\/.*\/compare$/;
    this.handleReminderClick();
    if (!portfolioRegex.test(history.location.pathname)) {
      return this.handleClick(`/portfolio/${id}/compare`);
    }
    return this.handleClick(`/portfolio/${id}/overview`);
  };

  moreThanOneGic = () => {
    const { portfolio } = this.props;

    return portfolio.gic_info && Object.keys(portfolio.gic_info).length > 1;
  };

  hasTenGic = () => {
    const { portfolio } = this.props;

    return portfolio.gic_info && Object.keys(portfolio.gic_info).length === 10;
  };

  render() {
    const { portfolio, hidden, to_scorecard, french } = this.props;
    const { modalIsOpen, reminderIsOpen } = this.state;

    return (
      <div>
        <Container hidden={hidden}>
          <ul>
            <Feature name={ACCOUNTS_SAVE_AS_PORTFOLIO}>
              {this.isSaveVisible(portfolio) && !this.moreThanOneGic() && (
                <Popup
                  style={{ zIndex: 3 }}
                  trigger={
                    <li
                      onClick={() => this.handleClick(false, 'save')}
                      className={cn({ active: modalIsOpen === 'save' })}
                    >
                      <button>
                        <i className="ui icon save outline" />
                      </button>
                    </li>
                  }
                  position="right center"
                  content={
                    french
                      ? "Enregistrer ce portefeuille pour l'utiliser dans la planification et comparer"
                      : 'Save this portfolio for use in planning and compare'
                  }
                  basic
                  wide
                />
              )}
            </Feature>
            {portfolio && (portfolio.is_linked || portfolio.is_mp) ? null : (
              <Popup
                style={{ zIndex: 3 }}
                trigger={
                  <li onClick={() => this.redirect(portfolio)}>
                    <button>
                      <i className="ui icon pencil" />
                    </button>
                  </li>
                }
                position="right center"
                content={
                  french ? 'Modifier ce portefeuille' : 'Edit this Portfolio'
                }
                basic
              />
            )}

            <Feature name={ACCOUNTS_COMPARE}>
              {!this.hasTenGic() && !reminderIsOpen && (
                <Popup
                  style={{
                    zIndex: 3,
                    left: '55px',
                    width:
                      window.location.pathname.split('/').indexOf('compare') !==
                      -1
                        ? '160px'
                        : to_scorecard
                        ? '220px'
                        : '150px',
                  }}
                  trigger={
                    <li
                      onClick={this.handleCompareClick}
                      className={cn({
                        active:
                          window.location.pathname
                            .split('/')
                            .indexOf('compare') !== -1,
                      })}
                    >
                      <button>
                        <i className="ui icon law" />
                      </button>
                    </li>
                  }
                  position="right center"
                  content={
                    window.location.pathname.split('/').indexOf('compare') !==
                    -1
                      ? french
                        ? 'Retour à la fiche de pointage'
                        : 'Return to Scorecard'
                      : to_scorecard
                      ? french
                        ? 'Retour à Comparer / Et si'
                        : 'Return to Compare / What-if'
                      : french
                      ? 'Comparer / Et si'
                      : 'Compare / What-If'
                  }
                  basic
                />
              )}
              {!this.hasTenGic() && reminderIsOpen && (
                <Popup
                  className="newPopup"
                  onClick={this.handleReminderClick}
                  trigger={
                    <li
                      onClick={this.handleCompareClick}
                      className={cn({
                        active:
                          window.location.pathname
                            .split('/')
                            .indexOf('compare') !== -1,
                      })}
                    >
                      <button>
                        <i className="ui icon law" />
                      </button>
                    </li>
                  }
                  position="bottom center"
                  open={reminderIsOpen}
                >
                  <Popup.Header>
                    {french ? 'Regardez ça !' : 'Check this out!'}
                  </Popup.Header>
                  <Popup.Content>
                    <br />
                    {french
                      ? 'Cliquez sur cette icône d\'échelle pour essayer notre fonction "Comparer/Et si".'
                      : 'Click this scale icon to try our “Compare/What-If” function.'}
                    <br />
                    <br />
                    <span
                      style={{
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      {french ? 'Compris.' : 'Got it.'}
                    </span>
                  </Popup.Content>
                </Popup>
              )}
            </Feature>
          </ul>
        </Container>
        <SaveModal
          portfolio={portfolio}
          closeModal={this.closeModal}
          open={modalIsOpen === 'save'}
        />
      </div>
    );
  }
}

SideBar.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  not_save_url_scorecard: PropTypes.bool,
  ui_compare_checked: PropTypes.bool,
  to_scorecard: PropTypes.bool,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

SideBar.defaultProps = {
  hidden: false,
  not_save_url_scorecard: false,
  ui_compare_checked: false,
  to_scorecard: false,
  partnerName: '',
  french: false,
};

export default withRouter(
  connect((state) => ({
    editing: state.Portfolio.editing,
    not_save_url_scorecard: state.Storage.not_save_url_scorecard,
    ui_compare_checked: state.Storage.ui_compare_checked,
    to_scorecard: state.Storage.to_scorecard,
    partnerName: state.Storage['partner-name'],
    french: state.Storage.language === 'fr',
  }))(SideBar)
);

const Container = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  position: fixed;
  top: 60px;
  left: 0;
  width: 4.8rem;
  height: 100%;
  background: ${colors.red};
  padding: 0;
  z-index: 2;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      background: rgba(255, 255, 255, 0.2);
      color: white;
      text-align: center;
      width: 45px;
      margin: 1.2rem auto;
      min-height: 45px;
      border-radius: 50%;
      font-size: 1.5rem;
      line-height: 40px;
      transition: all 200ms ease;
      cursor: pointer;

      &.active {
        color: white;
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.5);
      }

      &:hover {
        color: white;
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.5);
      }

      button {
        background: transparent;
        border: none;
        outline: none;
        padding: 1px 6px !important;
      }
    }
  }

  i {
    margin: 0;
    width: fit-content;
    height: fit-content;

    &.save {
      padding: 2px 4px;
    }

    &.pencil {
      padding: 2px 4px;
    }

    &.money {
      padding: 2px 1px;
    }

    &.law {
      font-size: 1.3rem;
      padding: 1px 0;
    }
  }
`;
