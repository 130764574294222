import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { notWealthica } from 'utils/helpers';
import { colors } from 'utils/colors';
import { Button, Modal } from 'components';
import { translateText } from '../../../../utils/helpers';

@withRouter
class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  closeModal = () => {
    const { close } = this.props;

    this.setState({ loading: false, error: null });

    return close();
  };

  renderConflictAccounts = () => {
    const { accountList, conflictAccounts, french } = this.props;
    let conflictAccountsLength = conflictAccounts
      ? conflictAccounts.length
      : null;

    return conflictAccounts
      ? accountList.map((account) => {
          if (conflictAccounts.includes(account.id.toString())) {
            if (conflictAccounts.length === 1) {
              return (
                <span
                  style={{ color: colors.red }}
                >{`${account.user_institution} ${account.label}`}</span>
              );
            }
            conflictAccountsLength -= 1;

            return conflictAccountsLength ? (
              <span style={{ color: colors.red }}>
                {`${account.user_institution} ${account.label}`}
                <span style={{ color: colors.white }}>, </span>
              </span>
            ) : (
              <span>
                {' '}
                {french ? 'et' : 'and'}{' '}
                <span
                  style={{ color: colors.red }}
                >{`${account.user_institution} ${account.label}`}</span>
              </span>
            );
          }

          return false;
        })
      : null;
  };

  renderHeading = () => {
    const { account, french } = this.props;

    return account.holdings && account.holdings.length === 0
      ? translateText('Add GIC/HISA', french)
      : translateText('Update GIC/HISA', french);
  };

  renderWording = () => {
    const { account, french } = this.props;

    return account.holdings && account.holdings.length === 0
      ? translateText('have entered', french)
      : translateText('are changing', french);
  };

  render() {
    const { open, partnerName, french } = this.props;
    const { error, loading } = this.state;

    return (
      <Modal
        vb={notWealthica(partnerName)}
        dimmerClick={this.closeModal}
        basic
        open={open}
      >
        <Modal.Header>
          <Header
            icon="edit"
            content={this.renderHeading()}
            style={{ color: colors.blue }}
          />
        </Modal.Header>
        <Modal.Content>
          <p style={{ maxWidth: '100%' }}>
            {translateText('The GIC/HISA ticker you', french)}{' '}
            {this.renderWording()}{' '}
            {translateText('the interest rate for is also saved in', french)}{' '}
            {this.renderConflictAccounts()}.{' '}
            {translateText(
              'The interest rate will be changed in all of the accounts with this ticker (unless you change the ticker). Would you like to proceed?',
              french
            )}
          </p>
          {error && <Error>{error}</Error>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            className={cn(
              `${
                notWealthica(partnerName)
                  ? 'left grey-focus'
                  : 'inverted left grey-focus'
              }`,
              { loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={() => {
              this.setState({ loading: true });
              // eslint-disable-next-line react/destructuring-assignment
              this.props.setResolveGicConflict();
            }}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect((state) => ({
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))(ConfirmModal);

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  account: PropTypes.object,
  setResolveGicConflict: PropTypes.func.isRequired,
  conflictAccounts: PropTypes.array,
  accountList: PropTypes.array,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  account: {},
  accountList: [],
  conflictAccounts: [],
  partnerName: '',
  french: false,
};

const Error = styled.div`
  color: ${colors.red};
  font-weight: 700;
  font-size: 1.14rem;
  padding: 10px 0;
`;
