import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, nbColors } from 'utils/colors';

const Button = ({ className, children, ...rest }) => (
  <StyledButton className={className} {...rest}>
    {rest.icon && <Icon className={className} name={rest.icon} />}
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  icon: PropTypes.string,
  floated: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  large: PropTypes.bool,
  popup: PropTypes.object,
  isQM: PropTypes.bool,
  enabledPopup: PropTypes.object,
};

Button.defaultProps = {
  children: '',
  icon: '',
  floated: '',
  color: '',
  className: '',
  large: false,
  popup: null,
  isQM: false,
  enabledPopup: null,
};

export default Button;

const StyledButton = styled(
  ({
    large,
    margin,
    color,
    floated,
    className,
    children,
    icon,
    popup,
    isQM,
    enabledPopup,
    ...rest
  }) => {
    const isDisabled = className
      ? className.split(' ').includes('disabled')
      : false;
    const ButtonComponent = rest.to ? (
      <Link className={className} {...rest}>
        {children}
      </Link>
    ) : (
      <button className={className} {...rest}>
        {children}
      </button>
    );

    if (isDisabled && popup) {
      return (
        <Popup
          trigger={
            <button
              className={className.replace('disabled', 'disabledClickOnly')}
              {...rest}
              onClick={() => false}
            >
              {children}
            </button>
          }
          position={popup.position}
          content={popup.content}
          wide={popup.wide || false}
        />
      );
    } else if (!isDisabled && enabledPopup) {
      return (
        <Popup
          trigger={ButtonComponent}
          position={enabledPopup.position}
          content={enabledPopup.content}
          wide={enabledPopup.wide || false}
        />
      );
    }
    return ButtonComponent;
  }
)`
  position: relative;
  border-radius: ${(props) =>
    props.modalButton ? '0.28571429rem, 0.28571429rem, 0, 0' : '4px'};
  box-shadow: ${(props) =>
    props.isQM
      ? 'inset 0 0 0 1.5px rgb(46,204,64)'
      : '0 0 0 1px rgba(34, 36, 38, 0.15) inset'};
  font-size: ${(props) => (props.large ? '1.1rem' : '0.9rem')};
  display: inline-block;
  min-height: 1em;
  min-width: ${(props) => (props.large ? '250px' : '110px')};
  height: ${(props) => (props.large ? '50px' : '36px')};
  border: none;
  text-align: center;
  outline: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  color: ${(props) => (props.isQM ? 'rgb(46,204,64)' : 'rgba(0, 0, 0, 0.6)')};
  background-color: transparent;
  padding: ${(props) =>
    props.large ? '0.5em 2.1em 0.5em 4.7em' : '0.65em 1em'};
  vertical-align: top;
  float: ${(props) => props.floated || 'initial'};
  margin: ${(props) => props.margin || 0};
  line-height: 1.4em !important;
  user-select: none !important;

  &:hover {
    box-shadow: inset 0 0 0 1.5px ${(props) => props.color} !important;
    color: rgba(0, 0, 0, 0.6);
  }

  &:focus {
    box-shadow: inset 0 0 0 1.5px ${(props) => props.color};
  }

  &:active {
    background-color: #f8f8f8;
    color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;
  }

  &.loading {
    cursor: default;
    color: transparent !important;
    pointer-events: none !important;
    transition: none !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.6em 0 0 -0.6em;
      width: 16px;
      height: 16px;
      border-radius: 500rem;
      border: 0.2em solid rgba(0, 0, 0, 0.15);
      animation: spin 0.6s linear infinite;
      border-top-color: #767676;
    }
    &:focus,
    &:active {
      color: transparent !important;
    }
    .icon {
      display: none;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none !important;
    opacity: 0.45;
  }

  &.disabledClickOnly {
    cursor: default;
    opacity: 0.45;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset !important;
    }
  }

  &.basic {
    font-size: 1rem;
    padding: 0.78571429em 1.5em;
    line-height: 1rem !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px
        ${(props) => props.color || 'rgba(34, 36, 38, 0.35)'} inset !important;
      color: ${(props) => props.color || 'rgba(0,0,0,0.6)'};
    }
  }

  &.inverted {
    box-shadow: ${(props) =>
      props.isQM
        ? 'rgb(46,204,64)'
        : '0 0 0 2px rgba(255, 255, 255, 0.5) inset'} !important;
    color: ${(props) => (props.isQM ? 'rgb(46,204,64)' : '#fff')};
    font-size: 1rem;
    line-height: 1.3rem !important;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 2px ${(props) => props.color} inset !important;
      color: ${(props) => props.color};
      background-color: transparent;
    }
  }

  &.negative {
    && {
      box-shadow: 0 0 0 1px #db2828 inset !important;
      color: #db2828 !important;
    }
  }

  &.left {
    padding: 0.65em 1.5em 0.65em 4em;
  }

  &.right {
    padding: 0.65em 4em 0.65em 1.5em;
  }

  &.grey-focus {
    &:focus {
      color: #fff;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5) inset !important;
    }
  }

  &.grey-focus2 {
    &:focus {
      box-shadow: 0 0 0 2px rgba(34, 36, 38, 0.15) inset !important;
    }
  }

  &.centered {
    display: block;
    margin: 1rem auto 0 auto;
  }

  &.fluid {
    width: 100%;
  }

  .icon {
    margin: 0;
    height: 100%;
    position: absolute;
    color: ${(props) => determineIconColor(props.color)};
    top: 0;
    width: ${(props) => (props.large ? '50px' : '36px')};
    background-color: ${(props) => props.color};

    &:before {
      position: absolute;
      line-height: 1;
      font-size: 1.1em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.left {
      left: 0;
      right: initial;
    }

    &.right {
      right: 0;
      left: initial;
    }

    &.edit:before {
      top: 53%;
      left: 55%;
    }

    &.check:before {
      left: 53%;
    }

    &.flip-icon {
      transform: scaleX(-1);
    }
  }
`;

const determineIconColor = (color) => {
  switch (color) {
    case colors.darkBlue:
    case colors.red:
    case colors.blue:
    case colors.green:
    case colors.darkGrey:
    case nbColors.blue:
    case nbColors.red:
      return 'white';
    default:
      return 'rgba(0,0,0,0.6)';
  }
};
