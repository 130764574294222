import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, NotFound } from 'components';
import { tokenRefresh } from 'decorators';
import { connect } from 'react-redux';
import { Accounts as AccountsActions } from 'actions';
import { isGlobeInvestor } from 'utils/helpers';
import Accounts from 'modules/Accounts';
import Planning from 'modules/Planning';
import Portfolio from 'modules/Portfolio';
import Tools from 'modules/Tools';
import QtAccounts from 'modules/Quotemedia/Accounts';
import QtPlanning from 'modules/Quotemedia/Planning';
import QtSimulation from 'modules/Quotemedia/Portfolio';
import QtTools from 'modules/Quotemedia/Tools';
import Dashboard from 'modules/Dashboard';
import Globe from 'modules/Globe';
import QtNavBar from 'modules/Quotemedia/QtNavBar';
import NBAccounts from '../NationalBank/Accounts';
import NBDashboard from '../NationalBank/Dashboard';
import NBPortfolio from '../NationalBank/Portfolio';
import NavBar from './NavBar';
import packageJson from '../../../package.json';
import TermsModal from '../../components/TermsModal';
import { setItem } from '../../actions/storage';

@tokenRefresh()
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  ui_agree_checked: state.Storage['ui_agree_checked'],
}))
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isDialogueVisible: true,
    };
    this.handleCloseTermsModal = this.handleCloseTermsModal.bind(this);
  }

  componentWillMount() {
    const { partnerName } = this.props;
    this.caching();
    if (isGlobeInvestor(partnerName)) {
      document.body.classList.add('globebody');
    } else {
      document.body.classList.add('normalbody');
    }
  }

  handleCloseTermsModal() {
    const { dispatch } = this.props;
    dispatch(setItem('ui_agree_checked', true));
    dispatch(AccountsActions.checkUiAgree());
  }

  // toggleDialogueVisibility = () => {
  //   this.setState((prevState) => ({
  //     isDialogueVisible: !prevState.isDialogueVisible,
  //   }));
  // };

  // renderDialogue = () => {
  //   const { partnerName } = this.props;
  //   const { isDialogueVisible } = this.state;

  //   if (isNB(partnerName)) {
  //     return isDialogueVisible ? (
  //       <Dialogue className="dialogue">
  //         <Icon
  //           name="info"
  //           style={{ color: 'black', fontSize: '15px', marginRight: '1.5rem' }}
  //         />
  //         By using Wealthscope, you agree to our Terms of Service and Privacy
  //         Policy
  //         <Button
  //           color={nbColors.red}
  //           name="close"
  //           className="left"
  //           style={{
  //             cursor: 'pointer',
  //             marginLeft: '1.5rem',
  //             background: 'white',
  //           }}
  //           onClick={this.toggleDialogueVisibility}
  //           icon="checkmark left"
  //         >
  //           I agree
  //         </Button>
  //       </Dialogue>
  //     ) : null;
  //   }
  // };

  caching = () => {
    const version = localStorage.getItem('version');
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // UPDATE: removing to prevent loss of authentication token once page finishes reloading.
        // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload();
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  renderView = () => {
    const { partnerName, location, ui_agree_checked } = this.props;

    if (partnerName === 'globeandmail_test' || partnerName === 'globeandmail') {
      return (
        <div className="globeContent">
          <Switch>
            <Route path="/globe" component={Globe} />

            <Route component={NotFound} />
          </Switch>

          <Alert />
        </div>
      );
    }

    if (
      partnerName === 'quotemedia_retail' ||
      partnerName === 'quotemedia_sandbox' ||
      partnerName === 'quotemedia_pro'
    ) {
      return (
        <div className="appContent">
          <QtNavBar partnerName={partnerName} />
          <Switch>
            <Route path="/accounts" component={QtAccounts} />
            <Route path="/planning" component={QtPlanning} />
            <Route path="/portfolio" component={QtSimulation} />
            <Route path="/tools" component={QtTools} />

            <Route component={NotFound} />
          </Switch>

          <Alert />
        </div>
      );
    }

    if (partnerName === 'nbdb_sandbox' || partnerName === 'nbdb') {
      return (
        <div
          className={
            location.pathname.includes('dashboard')
              ? 'appContent2'
              : 'appContent'
          }
        >
          <TermsModal
            closeModal={this.handleCloseTermsModal}
            open={ui_agree_checked === false}
          />
          <NavBar partnerName={partnerName} />
          <Switch>
            <Route path="/dashboard" component={NBDashboard} />
            <Route path="/accounts" component={NBAccounts} />
            <Route path="/portfolio" component={NBPortfolio} />

            <Route component={NotFound} />
          </Switch>

          <Alert />
        </div>
      );
    }

    if (isGlobeInvestor(partnerName)) {
      return (
        <div className="globeInvestorAppContent">
          <Switch>
            <Route path="/accounts" component={QtAccounts} />
            <Route path="/planning" component={QtPlanning} />
            <Route path="/portfolio" component={QtSimulation} />
            <Route path="/tools" component={QtTools} />
            <Route component={NotFound} />
          </Switch>
          <Alert />
        </div>
      );
    }
    return (
      <div
        className={
          location.pathname.includes('dashboard') ? 'appContent2' : 'appContent'
        }
      >
        <NavBar partnerName={partnerName} />
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/accounts" component={Accounts} />
          <Route path="/planning" component={Planning} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/tools" component={Tools} />

          <Route component={NotFound} />
        </Switch>

        <Alert />
      </div>
    );
  };

  render() {
    return this.renderView();
  }
}

App.propTypes = {
  partnerName: PropTypes.string,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  ui_agree_checked: PropTypes.bool,
};

App.defaultProps = {
  partnerName: '',
  location: {},
  dispatch: () => false,
  ui_agree_checked: true,
};

// const Dialogue = styled.div`
//   width: 100%;
//   box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
//   padding: 1em;
//   border-radius: 0;
//   background: ${nbColors.lightBlue} !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: justify;
//   max-width: 100%;
//   height: auto;
//   font-size: 15px;
// `;
