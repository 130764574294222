import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PortfolioHistoryChart, Table } from 'components';
import { Header, Divider } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import styled from 'styled-components';
import { decideCurrency } from 'utils/helpers';

@connect((state) => ({
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
}))
class PortfolioHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compare: this.props.analytics.portfolioHistoryCompareto,
    };
  }

  getSubHeader = (date) => {
    const { analytics, user_region } = this.props;

    return `${this.parseDate(date)} (inclusive) in ${decideCurrency(
      analytics.data,
      user_region || 'CA'
    )}`;
  };

  getChartData = (data) => {
    const { compare } = this.state;
    const { analytics } = this.props;
    const { benchmark } = analytics;

    return {
      labels: Object.keys(data.cumrets.portfolio),
      datasets: [
        {
          data: Object.values(data.cumrets.portfolio).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 0,
          label: 'Portfolio',
          lineTension: 0,
          fill: false,
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          pointBorderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointRadius: 0,
          pointHitRadius: 5,
        },
        {
          data: Object.values(data.cumrets[compare][benchmark]).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 0,
          label: compare,
          lineTension: 0,
          fill: false,
          backgroundColor: colors.red,
          borderColor: colors.red,
          pointBorderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointRadius: 0,
          pointHitRadius: 5,
        },
      ],
    };
  };

  parseDate = (portfolio, short) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return short
      ? `${init[1]}/${init[0]} - ${end[1]}/${end[0]}`
      : `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  capitalize = (str) => str.substring(0, 1).toUpperCase() + str.slice(1);

  shouldDrawBar = () => {
    const { data } = this.props.analytics;

    return (
      data &&
      data.crisisRets &&
      Object.values(data.crisisRets).reduce((result, event) => {
        result = Object.keys(event.rets).length ? result + 1 : result;

        return result;
      }, 0)
    );
  };

  renderCrisisTable = () => {
    const crisisAmount = this.shouldDrawBar();

    if (crisisAmount) {
      const { benchmark, data, portfolioHistoryCompareto } =
        this.props.analytics;
      const dummy = crisisAmount === 1 ? 9 : crisisAmount === 2 ? 6 : 5;

      return (
        <div style={{ marginTop: '2.5rem' }}>
          <Header>
            <Header.Content>Crisis-Period Returns</Header.Content>
          </Header>

          <Divider />

          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3} />
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.HeaderCell>
                    Financial Crisis
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2007-12 to 2009-05)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.HeaderCell>
                    Oil Price Shock
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2014-06 to 2016-01)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.HeaderCell>
                    Covid-19 Pandemic
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2020-02 to 2021-06)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.HeaderCell>
                    Ukraine War Onset
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2022-03 to 2022-08)
                    </span>
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell width={dummy} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={3}>My Portfolio</Table.Cell>
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Financial Crisis'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Oil Price Shock'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Covid-19 Pandemic'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Ukraine War'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                <Table.Cell width={dummy} />
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  {portfolioHistoryCompareto[0].toUpperCase() +
                    portfolioHistoryCompareto.slice(1)}
                </Table.Cell>
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Financial Crisis'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Oil Price Shock'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Covid-19 Pandemic'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Ukraine War'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                <Table.Cell width={dummy} />
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      );
    }

    return null;
  };

  render() {
    const { analytics } = this.props;
    const { benchmark, data } = analytics;
    const { compare } = this.state;

    return (
      <div style={{ clear: 'both', paddingTop: '5px' }}>
        <Header dividing size="medium" style={{ textAlign: 'left' }}>
          Hypothetical Portfolio History
          <Header.Subheader>
            Based on the current composition of your portfolio
          </Header.Subheader>
          <Header.Subheader>
            {this.getSubHeader(data.portfolio)}
          </Header.Subheader>
        </Header>
        <Header>
          {`Compare to: ${
            this.capitalize(compare) === 'Market'
              ? 'Stock Market'
              : this.capitalize(compare)
          }`}
          <Header.Subheader>
            {compare === 'market'
              ? data.cumrets.market[`${benchmark}Desc`]
              : data.cumrets.benchmark[`${benchmark}Desc`]}
          </Header.Subheader>
        </Header>
        <div style={{ textAlign: 'center', margin: '1rem 0 0 0' }}>
          <Legend color={colors.blue}>Portfolio</Legend>
          <Legend color={colors.red}>
            {this.capitalize(compare) === 'Market'
              ? 'Stock Market'
              : this.capitalize(compare)}
          </Legend>
        </div>
        <PortfolioHistoryChart
          portfolioData={data}
          compare={compare}
          benchmark={benchmark}
        />
        {this.renderCrisisTable()}
      </div>
    );
  }
}

PortfolioHistory.propTypes = {
  analytics: PropTypes.object,
  user_region: PropTypes.string,
};

PortfolioHistory.defaultProps = {
  analytics: {},
  user_region: 'CA',
};

export default PortfolioHistory;

const Legend = styled.span`
  position: relative;
  color: black;
  font-size: 0.9rem;
  cursor: default;
  width: 100px;
  margin: 0 0 0 4rem;
  vertical-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -3.2rem;
    height: 0.8rem;
    width: 2.8rem;
    background: ${(props) => props.color || 'grey'};
    vertical-align: middle;
  }
`;
