/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { colors } from 'utils/colors';

const widths = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];
const convertPropToClassName = (prop, className) => prop && className;

class Table extends Component {
  static Header = ({ children, ...rest }) => (
    <Header {...rest}>{children}</Header>
  );

  static Body = ({ children, ...rest }) => <tbody {...rest}>{children}</tbody>;

  static Row = ({
    children,
    className,
    positive,
    negative,
    warning,
    ...rest
  }) => {
    const classes = cn(
      className,
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(warning, 'warning')
    );

    return (
      <Row className={classes} {...rest}>
        {children}
      </Row>
    );
  };

  static Cell = ({
    children,
    className,
    positive,
    negative,
    warning,
    ...rest
  }) => {
    const classes = cn(
      className,
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(warning, 'warning'),
      { [`${widths[rest.width - 1]}`]: rest.width }
    );

    return (
      <Cell className={classes} {...rest}>
        {children}
      </Cell>
    );
  };

  static HeaderCell = ({
    children,
    className,
    positive,
    negative,
    warning,
    hidden,
    ...rest
  }) => {
    const classes = cn(
      className,
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(warning, 'warning'),
      convertPropToClassName(hidden, 'hidden'),
      { [`${widths[rest.width - 1]}`]: rest.width }
    );

    return (
      <HeaderCell className={classes} {...rest}>
        {children}
      </HeaderCell>
    );
  };

  render() {
    const {
      children,
      className,
      celled,
      definition,
      compact,
      basic,
      responsive,
      fixed,
      fixedColumn,
      correlation,
      ...rest
    } = this.props;
    const classes = cn(
      'table',
      className,
      convertPropToClassName(celled, 'celled'),
      convertPropToClassName(definition, 'definition'),
      convertPropToClassName(compact, 'compact'),
      convertPropToClassName(basic, 'basic'),
      convertPropToClassName(responsive, 'responsive'),
      convertPropToClassName(fixed, 'fixed'),
      convertPropToClassName(fixedColumn, 'fixed-column'),
      convertPropToClassName(correlation, 'correlation')
    );

    return (
      <StyledTable {...rest} className={classes}>
        {children}
      </StyledTable>
    );
  }
}

Table.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  celled: PropTypes.bool,
  definition: PropTypes.bool,
  compact: PropTypes.bool,
  basic: PropTypes.bool,
  responsive: PropTypes.bool,
  fixed: PropTypes.bool,
  fixedColumn: PropTypes.bool,
  correlation: PropTypes.bool,
};

Table.defaultProps = {
  className: '',
  celled: false,
  definition: false,
  compact: false,
  basic: false,
  responsive: false,
  fixed: false,
  fixedColumn: false,
  correlation: false,
};

export default Table;

const StyledTable = styled.table`
  width: 100%;
  background: #fff;
  margin: 1em 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0;
  text-align: ${(props) => props.textAlign || 'inherit'};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  thead {
    tr:first-child {
      & > th:first-child {
        border-radius: 0.28571429rem 0 0;
      }

      & > th:last-child {
        border-radius: 0 0.28571429rem 0 0;
      }
    }

    th {
      cursor: auto;
      background: #f9fafb;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.9em 0.8em;
      vertical-align: inherit;
      font-weight: 700;
      text-transform: none;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      border-left: none;
    }
  }

  td {
    border-top: 1px solid rgba(34, 36, 38, 0.1);

    &.positive {
      box-shadow: 0 0 0 #a3c293 inset;
      background: #f5fbfd !important;
    }

    &.negative {
      box-shadow: 0 0 0 #e0b4b4 inset;
      background: #fff6f6 !important;
      color: ${colors.hintRed} !important;
    }

    &.warning {
      box-shadow: 0 0 0 #c9ba9b inset;
      background: #fffaf3 !important;
      color: #573a08 !important;
    }
  }

  tr {
    &:first-child td {
      border-top: none;
    }

    &.positive {
      box-shadow: 0 0 0 #a3c293 inset;
      background: #f5fbfd !important;
    }

    &.negative {
      box-shadow: 0 0 0 #e0b4b4 inset;
      background: #fff6f6 !important;
      color: ${colors.hintRed} !important;
    }

    &.warning {
      box-shadow: 0 0 0 #c9ba9b inset;
      background: #fffaf3 !important;
      color: #573a08 !important;
    }
  }

  ${widths.map(
    (width, index) => css`
      td.${width}, th.${width} {
        width: ${6.25 * (index + 1)}%;
      }
    `
  )};

  &.celled {
    border-collapse: collapse;

    tr td,
    tr th {
      border-left: 1px solid rgba(34, 36, 38, 0.1);
    }

    tr td:first-child,
    tr th:first-child {
      border-left: none;
    }

    &.definition {
      thead th:first-child {
        box-shadow: 0 -1px 0 1px #fff;
      }
    }
  }

  &.correlation {
    border-collapse: collapse;

    tr td,
    tr th {
      border-left: 1px solid rgba(34, 36, 38, 0.1);
    }

    &.definition {
      thead th:first-child {
        box-shadow: 0 -1px 0 1px #fff;
      }
    }
  }

  &.definition {
    thead {
      th {
        background: #ffedd6;

        &:first-child {
          pointer-events: none;
          background: 0 0;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          box-shadow: -1px -1px 0 1px #fff;
        }

        &:nth-child(2) {
          border-left: 1px solid rgba(34, 36, 38, 0.15);
        }
      }
    }

    tr {
      td {
        &:first-child {
          background: #ffedd6;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.95);
        }

        &:nth-child(2) {
          border-left: 1px solid rgba(34, 36, 38, 0.15);
        }
      }
    }
  }

  &.compact {
    th {
      padding-left: 8px;
      padding-right: 8px;
    }

    td {
      padding: 5px 8px;
    }
  }

  &.basic {
    border: none;
    background: 0 0;
    box-shadow: none;

    thead tr:first-child th {
      padding-top: 0;
    }

    td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      background: 0 0;
      border-left: none;
    }
  }

  &.responsive {
    @media only screen and (max-width: 1100px) {
      width: 600px !important;
    }
  }

  &.fixed {
    table-layout: fixed;
  }

  &.fixed-column {
    position: absolute;
    bottom: 10px;
    width: 172px;
    border: 0px;
    margin-bottom: 0;
    border-bottom: 1px solid lightgrey;
    border-radius: 0;

    th {
      background: white;
      color: white;
      padding: 0.95em 1.1em;
      user-select: none;
    }

    tbody {
      background-color: #fff;
    }

    tr th {
      border-right: 1px solid rgba(34, 36, 38, 0.1) !important;
    }

    tr td {
      border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
      border-right: 1px solid rgba(34, 36, 38, 0.1) !important;
    }
  }
`;

const Header = styled.thead`
  box-shadow: none;
`;

const Row = styled.tr`
  text-align: ${(props) => props.textAlign || 'inherit'};
`;

const Cell = styled.td`
  padding: 0.78571429em;
  text-align: ${(props) => props.textAlign || 'inherit'};
`;

const HeaderCell = styled.th`
  text-align: ${(props) => props.textAlign || 'inherit'};
`;
