export const translation = {
  en: {
    PORTFOLIO_REVIEW: 'Portfolio Review',
    PORTFOLIO: 'Portfolio',
    DIVERSIFICATION: 'Diversification',
    LOW_FEES: 'Low Fees',
    INCOME: 'Income',
    PERFORMANCE: 'Performance',
    DOWNSIDE_PROTECTION: 'Downside Protection',
    PORTFOLIO_OVERVIEW: 'Portfolio Overview',
    OVERVIEW: 'Overview',
    PORTFOLIO_HISTORY: 'Historical Performance Graph',
    DOWNSIDE: 'Downside',
    FEES: 'Fees',
    ASSET_CLASS: 'Asset Class',
    INDIVIDUAL_ASSET: 'Individual',
    REGION: 'Region',
    GLOBAL_SECTOR: 'Global Stock Sector',
    MACRO_FACTOR: 'Macro Factor',
    EQUITY_STYLE: 'Equity Style',
    BENCHMARK: 'Benchmark',
    REBALANCING_FREQUENCY: 'Rebalancing Frequency',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    ANNUALLY: 'Annually',
    HYPOTHETICAL_PORTFOLIO_HISTORY: 'Hypothetical Portfolio History',
    BASE_CURRENT_PORTFOLIO:
      'Based on the current composition of your portfolio',
    COMPARE_TO: 'Compare to',
    RETURNS_INCLUDING_DISTRIBUTIONS: 'Total Returns including Distributions',
    CRISIS_PERIOD_RETURNS: 'Crisis-Period Returns',
    UKRAINE_WAR_ONSET: 'Ukraine War Onset',
    COVID_19_PANDEMIC: 'Covid-19 Pandemic',
    OIL_PRICE_SHOCK: 'Oil Price Shock',
    FINANCIAL_CRISIS: 'Financial Crisis',
    MY_PORTFOLIO: 'My Portfolio',
    PERFORMANCE_DESCRIPTION_1:
      'The information below provides a snapshot of how well your portfolio is doing. You will find the traditional measures, such as annualized returns for different investment horizons. Most importantly, we show you your portfolio’s risk adjusted ratios, which tell you how much risk was taken to generate the returns.',
    KEY_STATISTICS: 'Key Statistics',
    INCLUSIVE: 'inclusive',
    ANNUALIZED_RETURN: 'Annualized Return',
    ANNUALIZED_RETURNS: 'Annualized Returns',
    SHARPE_RATIO: 'Sharpe Ratio',
    ROY_SAFETY: "Roy's Safety First Criterion",
    SORTINO_RATIO: 'Sortino Ratio',
    ONE_YEAR: '1-year',
    THREE_YEAR: '3-year',
    FIVE_YEAR: '5-year',
    SEVEN_YEAR: '7-year',
    TEN_YEAR: '10-year',
    TOTAL_RISK: 'Total Risk',
    DOWNSIDE_RISK: 'Downside Risk',
    MAXIMUM_DRAWDOWN: 'Maximum Drawdown',
    DOWNSIDE_CAPTURE: 'Downside Capture',
    FEE_IN_PORTFOLIO: 'Fees In This Portfolio',
    ASSET: 'Asset',
    WEIGHT: 'Weight',
    MER: 'MER',
    WEIGHTED_AVERAGE_MER: 'Weighted Average MER',
    YIELD: 'Yield',
    WEIGHTED_AVERAGE_YIELD: 'Weighted Average Yield',
    RISK_EXPOSURE_ANALYSIS: 'Risk Exposure Analysis',
    BY_MACRO_FACTOR: 'By Macroeconomic Factor',
    MACRO_RISK_EXPOSURE: 'Macroeconomic Risk Exposure',
    MONTH: {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
  },
  fr: {
    PORTFOLIO_REVIEW: 'Examen du portefeuille',
    PORTFOLIO: 'Portefeuille',
    DIVERSIFICATION: 'Diversification',
    LOW_FEES: 'Frais faibles',
    INCOME: 'Revenu',
    PERFORMANCE: 'Performance',
    DOWNSIDE_PROTECTION: 'Protection contre les baisses',
    PORTFOLIO_OVERVIEW: 'Aperçu du portefeuille',
    OVERVIEW: 'Aperçu',
    PORTFOLIO_HISTORY: 'Graphique de performance hypothétique',
    DOWNSIDE: 'Risque de pertes',
    FEES: 'Frais',
    ASSET_CLASS: "Analyse de la classe d'actifs",
    INDIVIDUAL_ASSET: 'Individuelle',
    REGION: 'Région',
    GLOBAL_SECTOR: 'Secteur Mondial',
    MACRO_FACTOR: 'Facteur macro',
    EQUITY_STYLE: "Style d'équité",
    BENCHMARK: 'Référence',
    REBALANCING_FREQUENCY: 'Fréquence de rééquilibrage',
    MONTHLY: 'Mensuel',
    QUARTERLY: 'Trimestriel',
    ANNUALLY: 'Annuel',
    HYPOTHETICAL_PORTFOLIO_HISTORY: 'Historique hypothetique du portefeuille',
    BASE_CURRENT_PORTFOLIO:
      'Selon la composition actuelle de votre portefeuille',
    COMPARE_TO: 'Comparer à',
    RETURNS_INCLUDING_DISTRIBUTIONS: 'Rendement total placements compris',
    CRISIS_PERIOD_RETURNS: 'Périodes de turbulences des marchés',
    UKRAINE_WAR_ONSET: 'Début de la guerre en Ukraine',
    COVID_19_PANDEMIC: 'Pandémie de covid-19',
    OIL_PRICE_SHOCK: 'Choc des prix du pétrole',
    FINANCIAL_CRISIS: 'Crise financière',
    MY_PORTFOLIO: 'Mon portefeuille',
    PERFORMANCE_DESCRIPTION_1:
      'Les informations ci-dessous donnent un aperçu de la performance de votre portefeuille. Vous y trouverez les indicateurs traditionnels, tels que les rendements annualisés pour différents horizons de placement. Plus importants encore, nous vous présentons les ratios ajustés au risque de votre portefeuille, qui vous indiquent le niveau de risque auquel vous vous exposez pour générer ces rendements.',
    KEY_STATISTICS: 'Statistiques clés',
    INCLUSIVE: 'inclusivement',
    ANNUALIZED_RETURN: 'Rendements annualisés',
    ANNUALIZED_RETURNS: 'Rendement annualisé',
    SHARPE_RATIO: 'Ratio de sharpe',
    ROY_SAFETY: 'Le Critère de sécurité de Roy',
    SORTINO_RATIO: 'Ratio de sortino',
    YTD: 'YTD',
    ONE_YEAR: '1 an',
    THREE_YEAR: '3 an',
    FIVE_YEAR: '5 an',
    SEVEN_YEAR: '7 an',
    TEN_YEAR: '10 an',
    TOTAL_RISK: 'Risque total',
    DOWNSIDE_RISK: 'Risque de perte',
    MAXIMUM_DRAWDOWN: 'Baisse maximale',
    DOWNSIDE_CAPTURE: 'Capture à la baisse',
    FEE_IN_PORTFOLIO: 'Frais dans ce portefeuille',
    ASSET: 'Actif',
    WEIGHT: 'Prépondérance',
    MER: 'RFG',
    WEIGHTED_AVERAGE_MER: 'RFG moyen pondéré',
    YIELD: 'Rendement',
    WEIGHTED_AVERAGE_YIELD: 'Rendement Moyen Pondéré',
    RISK_EXPOSURE_ANALYSIS: "Analyse de l'exposition au risque",
    BY_MACRO_FACTOR: 'Par facteur macroéconomique',
    MACRO_RISK_EXPOSURE: 'Exposition aux risques macroéconomiques',
    MONTH: {
      '01': 'Janvier',
      '02': 'Février',
      '03': 'Mars',
      '04': 'Avril',
      '05': 'Mai',
      '06': 'Juin',
      '07': 'Juillet',
      '08': 'Août',
      '09': 'Septembre',
      10: 'Octobre',
      11: 'Novembre',
      12: 'Décembre',
    },
  },
};
