import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { BuildCustom } from 'actions';
import { ErrorLabel } from 'components';

class Weight extends Component {
  state = {
    focused: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = (e) => {
    const { dispatch, ticker, value } = this.props;

    // Firefox issue
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return false;
    }

    if (value === '') {
      dispatch(BuildCustom.updateWeight(ticker, 0));
    }

    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;

    e.target.value = '';

    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  handleChange = (e, { name, value }) => {
    const { dispatch, ticker } = this.props;
    const num = Number(value);
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);

    if (ticker && num > 100) {
      if (name === 'CASH:MKT') {
        return dispatch(BuildCustom.updateWeight(name, 100));
      } else if (ticker && num > 100) {
        return dispatch(BuildCustom.updateWeight(name, 100));
      }
    }

    if (ticker && !negative) {
      return dispatch(
        BuildCustom.updateWeight(
          name,
          value === ''
            ? ''
            : Number.isInteger(num)
            ? num
            : value.split('.')[1].length >= 2
            ? Number(
                `${value.split('.')[0]}.${value.split('.')[1].substring(0, 2)}`
              )
            : num
        )
      );
    }

    return null;
  };

  focus = () => {
    const { value, ticker, dispatch } = this.props;

    if (!value) {
      dispatch(BuildCustom.updateWeight(ticker, ''));
    }

    return this.setState({ focused: true });
  };

  render() {
    const { ticker, value } = this.props;
    const { focused } = this.state;

    return !focused ? (
      <Value onClick={this.focus}>
        {value}
        <ErrorLabel msg={!value ? 'Update weight' : null} /> %
      </Value>
    ) : (
      <Input
        name={ticker}
        size="mini"
        type="number"
        value={value}
        onChange={this.handleChange}
        style={{ maxWidth: 80 }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    );
  }
}

Weight.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticker: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect()(Weight);

const Value = styled.span`
  position: relative;
  cursor: pointer;
  color: #2185d0;
  padding: 11px 5px;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;
