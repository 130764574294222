import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { notWealthica } from 'utils/helpers';
import { colors } from 'utils/colors';
import { Button, Modal } from 'components';

@withRouter
class ConfirmModal extends Component {
  state = {
    loading: false,
    error: null,
  };

  closeModal = () => {
    const { close } = this.props;

    this.setState({ loading: false, error: null });

    return close();
  };

  renderConflictAccounts = () => {
    const { accountList, conflictAccounts } = this.props;
    let conflictAccountsLength = conflictAccounts
      ? conflictAccounts.length
      : null;

    return conflictAccounts
      ? accountList.map((account) => {
          if (conflictAccounts.includes(account.id.toString())) {
            if (conflictAccounts.length === 1) {
              return (
                <span
                  style={{ color: colors.red }}
                >{`${account.user_institution} ${account.label}`}</span>
              );
            }
            conflictAccountsLength -= 1;

            return conflictAccountsLength ? (
              <span style={{ color: colors.red }}>
                {`${account.user_institution} ${account.label}`}
                <span style={{ color: colors.white }}>, </span>
              </span>
            ) : (
              <span>
                {' '}
                and{' '}
                <span
                  style={{ color: colors.red }}
                >{`${account.user_institution} ${account.label}`}</span>
              </span>
            );
          }

          return false;
        })
      : null;
  };

  renderHeading = () => {
    const { account, user_region } = this.props;

    return account.holdings && account.holdings.length === 0
      ? user_region === 'CA'
        ? 'Add GIC/HISA'
        : 'Add CD'
      : user_region === 'CA'
      ? 'Update GIC/HISA'
      : 'Update CD';
  };

  renderWording = () => {
    const { account } = this.props;

    return account.holdings && account.holdings.length === 0
      ? ' have entered '
      : ' are changing ';
  };

  render() {
    const { open, partnerName } = this.props;
    const { error, loading } = this.state;

    return (
      <Modal dimmerClick={this.closeModal} basic open={open}>
        <Modal.Header>
          <Header
            icon="edit"
            content={this.renderHeading()}
            style={{ color: colors.blue }}
          />
        </Modal.Header>
        <Modal.Content>
          <p style={{ maxWidth: '100%' }}>
            The GIC/HISA ticker you {this.renderWording()} the interest rate for
            is also saved in {this.renderConflictAccounts()}. The interest rate
            will be changed in all of the accounts with this ticker (unless you
            change the ticker). Would you like to proceed?
          </p>
          {error && <Error>{error}</Error>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            className={cn(
              `${
                notWealthica(partnerName)
                  ? 'left grey-focus'
                  : 'inverted left grey-focus'
              }`,
              { loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={() => {
              this.setState({ loading: true });
              this.props.setResolveGicConflict();
            }}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect((state) => ({
  partnerName: state.Storage['partner-name'],
  user_region: state.Storage.user_region || 'CA',
}))(ConfirmModal);

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  account: PropTypes.object,
  setResolveGicConflict: PropTypes.func.isRequired,
  conflictAccounts: PropTypes.array,
  accountList: PropTypes.array,
  partnerName: PropTypes.string,
  user_region: PropTypes.string,
};

ConfirmModal.defaultProps = {
  account: {},
  accountList: [],
  conflictAccounts: [],
  partnerName: '',
  user_region: 'CA',
};

const Error = styled.div`
  color: ${colors.red};
  font-weight: 700;
  font-size: 1.14rem;
  padding: 10px 0;
`;
