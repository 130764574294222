import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Header } from 'semantic-ui-react';

import { ExposureTable, ExposureChart } from '../components';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class IndividualAsset extends Component {
  getChartData = () => {
    const { tickersValid, riskCont } = this.props.analytics.data;
    const sorted = Object.keys(tickersValid).sort(
      (a, b) => riskCont.asset[b] - riskCont.asset[a]
    );
    const labels = sorted.map((key) => this.getName(key));
    const risk = sorted.map((key) => riskCont.asset[key]);
    const otherRisk = sorted.slice(10).reduce((total, key) => {
      if (key) {
        total += riskCont.asset[key];
      }

      return total;
    }, 0);
    const weight = sorted.map((key) => tickersValid[key].weight);
    const otherWeight = sorted.slice(10).reduce((total, key) => {
      if (key) {
        total += tickersValid[key].weight;
      }

      return total;
    }, 0);

    return {
      labels: otherRisk ? [...labels.slice(0, 10), 'Other'] : labels,
      risk: otherRisk ? [...risk.slice(0, 10), otherRisk] : risk,
      weight: otherRisk ? [...weight.slice(0, 10), otherWeight] : weight,
    };
  };

  getName = (ticker) => {
    const { tickersOriginal, tickersValid } = this.props.analytics.data;
    const matchedTicker = tickersOriginal.find(
      (val) => val.replacedBy.proxyTicker === ticker
    );

    if (matchedTicker) {
      return this.isLinked(this.props.analytics.data)
        ? matchedTicker.name
        : matchedTicker.wsName;
    }

    return this.isLinked(this.props.analytics.data)
      ? tickersValid[ticker].name
      : tickersValid[ticker].wsName;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  render() {
    const { data } = this.props.analytics;
    const { french } = this.props;

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content={
            french
              ? 'Analyse de la contribution au risque'
              : 'Risk Contribution Analysis'
          }
          subheader={french ? 'Par actif individuel' : 'By Individual Asset'}
        />

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content={french ? 'Répartition des actifs' : 'Asset Allocation'}
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                position="bottom"
                graphHeight={
                  Object.keys(data.tickersValid).length > 10 ? 450 : 0
                }
                labels={this.getChartData().labels}
                data={this.getChartData().weight}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                size="medium"
                content={
                  french ? 'Contribution au risque' : 'Risk Contribution'
                }
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                position="bottom"
                graphHeight={
                  Object.keys(data.tickersValid).length > 10 ? 450 : 0
                }
                labels={this.getChartData().labels}
                data={this.getChartData().risk}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ExposureTable
          align="left"
          headers={[
            french ? 'Actif' : 'Asset',
            french ? 'Répartition des actifs' : 'Asset Allocation',
            french ? 'Contribution au risque' : 'Risk Contribution',
            french ? 'Contribution au rendement' : 'Return Contribution',
          ]}
          totals={[
            french ? 'Total' : 'Total',
            <div>100%</div>,
            <div>100%</div>,
            <div>100%</div>,
          ]}
          data={Object.keys(data.tickersValid).reduce((total, key) => {
            total.push({
              ticker: this.getName(key),
              assetAllocation: data.tickersValid[key].weight,
              riskCont: data.riskCont.asset[key],
              retCont: data.retCont.asset[key],
            });

            return total;
          }, [])}
        />
      </Container>
    );
  }
}

IndividualAsset.propTypes = {
  analytics: PropTypes.object,
  french: PropTypes.bool,
};

IndividualAsset.defaultProps = {
  analytics: {},
  french: false,
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
