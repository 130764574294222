/* eslint-disable no-else-return */
/**
 * Converts holdings array into:
 * { tickers: 'x,y', weights: '0.5,0.5' }
 */
export function convertToTickersAndWeights(holdings) {
  const totalValue = holdings.reduce(
    (total, holding) =>
      holding.security_detail ? (total += getMktValue(holding)) : total,
    0
  );

  return holdings.reduce(
    (parsed, holding) => {
      if (!holding.security_detail) {
        return parsed;
      }

      const { ticker } = holding.security_detail;
      const weight = getMktValue(holding) / totalValue;
      const mktVal = getMktValue(holding);
      const { quantity } = holding;

      if (!weight) {
        return parsed;
      }

      parsed.tickers += parsed.tickers.length ? `,${ticker}` : `${ticker}`;
      parsed.weights += parsed.weights.length ? `,${weight}` : `${weight}`;
      parsed.marketValues += parsed.marketValues.length
        ? `,${mktVal}`
        : `${mktVal}`;
      parsed.quantities += parsed.quantities.length
        ? `,${quantity}`
        : `${quantity}`;

      return parsed;
    },
    { tickers: '', weights: '', marketValues: '', quantities: '' }
  );
}

/**
 * Returns a market value of a holding
 */
export function getMktValue(holding, outputCurrency = 'CAD', exchangeRate = 1) {
  // Ensure market_value is a valid number, defaulting to 0 if it's not
  let mktVal =
    typeof holding.market_value === 'number' &&
    !Number.isNaN(holding.market_value)
      ? holding.market_value
      : 0;

  // Ensure price is calculated correctly using unadjusted_closing_price
  const price =
    holding.unadjusted_closing_price &&
    holding.unadjusted_closing_price.CAD &&
    typeof holding.unadjusted_closing_price.CAD === 'number'
      ? holding.unadjusted_closing_price.CAD
      : holding.security_detail &&
        holding.security_detail.unadjusted_closing_price &&
        holding.security_detail.unadjusted_closing_price.CAD &&
        typeof holding.security_detail.unadjusted_closing_price.CAD === 'number'
      ? holding.security_detail.unadjusted_closing_price.CAD
      : 0;

  // Ensure quantity is a valid number, default to 0 if invalid
  const quantity =
    typeof holding.quantity === 'number' && !Number.isNaN(holding.quantity)
      ? holding.quantity
      : 0;

  const value = price * quantity;

  // Convert to target currency if market value exists & currency is USD
  if (holding.currency === 'USD' && outputCurrency !== 'USD') {
    mktVal *=
      typeof exchangeRate === 'number' && exchangeRate > 0 ? exchangeRate : 1;
  }

  // Return the market value, or fall back to calculated value
  return mktVal || value;
}

export function getHoldTotalMarketValue(account, rate, region = 'CA') {
  return account.holdings.reduce((result, holding) => {
    if (!holding.market_value && !holding.quantity) {
      return result;
    }

    const price = holding.security_detail
      ? holding.security_detail.unadjusted_closing_price.CAD
      : holding.unadjusted_closing_price.CAD || 0;

    let mktValue = holding.market_value || price * holding.quantity;

    if (holding.currency === 'USD' && region === 'CA') {
      mktValue *= Number(rate);
    }

    return result + mktValue;
  }, 0);
}

export function notWealthica(partner) {
  return !(partner === 'wealthica' || partner === 'wealthica_test');
}

export function isWealthica(partner) {
  return partner === 'wealthica' || partner === 'wealthica_test';
}

export function isQuoteMedia(partner) {
  return (
    partner === 'quotemedia_sandbox' ||
    partner === 'quotemedia_retail' ||
    partner === 'quotemedia_pro'
  );
}

export function isGlobeInvestor(partner) {
  return partner === 'globeinvestor' || partner === 'globeinvestor_sandbox';
}

export function arrangeSector(data) {
  if (!data) {
    return;
  }

  const obj2Arr = Object.entries(data).sort((a, b) => {
    if (a[0] === 'Port. Specific Risk') {
      return {};
    }

    if (a[0] && b[0]) {
      if (a[0] < b[0]) {
        return -1;
      }
      if (a[0] > b[0]) {
        return 1;
      }
    }

    return 0;
  });

  // eslint-disable-next-line consistent-return
  return obj2Arr.reduce(
    (obj, sector) => ({
      ...obj,
      [sector[0]]: sector[1],
    }),
    {}
  );
}

export function getTickerSearchResult(search) {
  return search.reduce((total, security) => {
    if (security) {
      if (!total[security.asset_type]) {
        total[security.asset_type] = {
          name: security.asset_type,
          results: [
            {
              title: security.ticker,
              description: security.long_name,
              price: security.exchange_name,
            },
          ],
        };
      } else {
        total[security.asset_type].results.push({
          title: security.ticker,
          description: security.long_name,
          price: security.exchange_name,
        });
      }
    }

    return total;
  }, {});
}

export function nestData(data) {
  return Object.values(data).reduce((result, item) => {
    if (!result[item.assetclass]) {
      result[item.assetclass] = {};
    }

    if (!result[item.assetclass][item.title]) {
      result[item.assetclass][item.title] = {};
    }

    if (!result[item.assetclass][item.title][item.subtitle]) {
      result[item.assetclass][item.title][item.subtitle] = [];
    }

    result[item.assetclass][item.title][item.subtitle].push(item);
    result[item.assetclass][item.title][item.subtitle].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return result;
  }, {});
}

export function stringToDate(dateString) {
  // returns a Date object representing the string dateString which has the format yyyy-mm-dd
  const parts = dateString.split('-');

  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function formatDollarAmount(amount) {
  if (!amount) return '$0';
  else if (amount < 0) {
    return `-$${(Math.round(-1 * amount * 100) / 100).toLocaleString()}`;
  }
  return `$${(Math.round(amount * 100) / 100).toLocaleString()}`;
}

export function filterOutKeysFromObject(data, excludedKeys) {
  if (!data) return data;
  return Object.keys(data)
    .filter((key) => !excludedKeys.includes(key))
    .reduce((prev, curr) => {
      prev[curr] = data[curr];
      return prev;
    }, {});
}

export function getOverAllScore(params) {
  const { data, benchmark, esg, income, esgToggle } = params;
  const { score } = data;
  const keys = [
    'Diversification',
    'Fees',
    'Performance',
    'Volatility',
    'ESG',
    ...(income ? ['Income'] : []),
  ];
  const { portfolio } = esg || {};

  if (!esgToggle || !portfolio || !portfolio.ws_grade[0]) {
    return income
      ? `${data.score.Overall5[benchmark][0]}%`
      : `${data.score.Overall4[benchmark][0]}%`;
  }

  const overAllScore = Math.round(
    keys.reduce((sum, key) => {
      if (key === 'Performance' || key === 'Volatility') {
        sum += score[key][benchmark][0];
      } else if (key === 'ESG') {
        sum += portfolio.ws_grade[0];
      } else {
        sum += score[key][0];
      }

      return sum;
    }, 0) / keys.length
  );

  return `${overAllScore}%`;
}

export function getOverAllGrade(params) {
  const { data, benchmark, esg, income, esgToggle } = params;
  const { score } = data;
  const keys = [
    'Diversification',
    'Fees',
    'Performance',
    'Volatility',
    'ESG',
    ...(income ? ['Income'] : []),
  ];
  const { portfolio } = esg || {};

  if (!esgToggle || !portfolio || !portfolio.ws_grade[0]) {
    return income
      ? data.score.Overall5[benchmark][1]
      : data.score.Overall4[benchmark][1];
  }

  const grading = (grade) => {
    if (grade >= 80 && grade <= 100) return 'A';
    else if (grade >= 70 && grade < 80) return 'B';
    else if (grade >= 60 && grade < 70) return 'C';
    else if (grade > 21 && grade < 60) return 'D';

    return 'F';
  };
  const overAllScore = Math.round(
    keys.reduce((sum, key) => {
      if (key === 'Performance' || key === 'Volatility') {
        sum += score[key][benchmark][0];
      } else if (key === 'ESG') {
        sum += portfolio.ws_grade[0];
      } else {
        sum += score[key][0];
      }

      return sum;
    }, 0) / keys.length
  );

  return grading(overAllScore);
}

export function toTitleCase(string) {
  if (!string) return '';
  return string.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export function decideCurrency(data, userRegion) {
  if (userRegion === 'US') return 'USD';

  const { paramsPassed } = data;

  if (paramsPassed.region === 'US') return 'USD';

  return 'CAD';
}

export function getProvinceCode(province) {
  const provinces = {
    Alberta: 'AB',
    'British Columbia': 'BC',
    Manitoba: 'MB',
    'New Brunswick': 'NB',
    'Newfoundland and Labrador': 'NL',
    'Northwest Territories': 'NT',
    'Nova Scotia': 'NS',
    Nunavut: 'NU',
    Ontario: 'ON',
    'Prince Edward Island': 'PE',
    Quebec: 'QC',
    Saskatchewan: 'SK',
    Yukon: 'YT',
  };

  return provinces[province] || null;
}

export function hasNegativeWeight(holdings) {
  // First, we use the existing convertToTickersAndWeights function
  const { weights } = convertToTickersAndWeights(holdings);

  // Split the weights string into an array of numbers
  const weightArray = weights.split(',').map(parseFloat);

  // Check if any of the weights are negative
  return weightArray.some((weight) => weight < 0);
}

// Returns translated text if french is true, otherwise returns original.
// If translation is not found, returns original text.
export function translateText(text, isFr) {
  const fr = {
    Alternative: 'Alternatif',
    Equity: 'Capitaux propres',
    'Fixed Income': 'Revenu fixe',
    Cash: 'Liquidités',
    Misc: 'Divers',
    'Misc.': 'Divers',
    'Consumer Disc.': 'Biens de consommation cyclique',
    'Consumer Staples': 'Biens de consommation non cyclique',
    Energy: 'Énergie',
    Financials: 'Finance',
    Industrials: 'Industrie',
    Materials: 'Matériaux',
    'Info Tech': "Technologies de l'information",
    Telecom: 'Télécommunications',
    Utilities: 'Services aux collectivités',
    Healthcare: 'Santé',
    'Real Estate': 'Immobilier',
    'Port. Specific Risk': 'Risque spécifique au portefeuille',
    Production: 'Production',
    'Credit Risk': 'Risque lié au crédit',
    'Interest Rate': "Taux d'intérêt",
    Oil: 'Pétrole',
    Gold: 'Or',
    Inflation: 'Inflation',
    'US Dollars': 'Dollars US',
    US: 'États-Unis',
    'Developed Markets': 'Marchés développés',
    'Emerging Markets': 'Marchés émergents',
    Market: 'Marché',
    Size: 'Taille',
    Value: 'Valeur',
    Momentum: 'Momentum',
    'Frontier Markets': 'Marchés frontaliers',
    'What-if': 'et si',
    'Downside Protection': 'Protection contre les baisses',
    Save: 'Sauvegarder',
    Cancel: 'Annuler',
    Confirm: 'Confirmer',
    Optional: 'Facultatif',
    Required: 'Obligatoire',
    required: 'obligatoire',
    'Interest Rates': 'Taux d’intérêt',
    'Update interest rate': 'Ajoutez un taux d’intérêt',
    'Update value': 'Ajoutez une valeur',
    Ticker: 'Symbole',
    'For display in the Portfolio Scorecard.':
      'Pour affichage dans le tableau de bord du portefeuille',
    'Update ticker': 'Ajoutez un symbole',
    Name: 'Nom',
    'The limit is 10 GICs/HISAs.': 'La limite est de 10 GICs/HISAs.',
    'Search for ticker or name': 'Rechercher par symbole ou par nom',
    'Update market value': 'Ajoutez une valeur de marché',
    'Update quantity': 'Ajoutez une quantité',
    'Go to User Guide': 'Accéder au guide de l’utilisateur',
    'CDN Mutual Funds': 'Fonds communs de placement canadiens',
    'GIC or HISA': 'CPG ou CÉIÉ',
    'By using Wealthscope, you agree to our':
      'En utilisant Wealthscope, vous acceptez nos',
    'Terms of Service': 'conditions d’utilisation',
    'Privacy Policy': 'politique de confidentialité',
    'I agree': 'J’accepte',
    'The variance of the portfolio returns is zero':
      'La variance des rendements du portefeuille est égale à zéro',
    'All Other Holdings': 'Toutes autres positions',
    'The GIC/HISA ticker you': 'Le symbole boursier du CPG ou CEIE dont vous',
    'are changing': 'modifiez',
    'have entered': 'avez entré',
    'the interest rate for is also saved in':
      'le taux d’intérêt est également enregistré dans le compte',
    'The interest rate will be changed in all of the accounts with this ticker (unless you change the ticker). Would you like to proceed?':
      'Le taux d’intérêt sera modifié dans tous les comptes contenant ce symbole (à moins que vous ne changiez le symbole). Voulez-vous continuer?',
    'Update GIC/HISA': 'Modifier votre CPG/CEIE',
    'Add GIC/HISA': 'Ajouter un CPG/CEIE',
    'Note: for cash, while the covariance between cash and any asset is zero, the correlation coefficient is undefined because it has the standard deviation of cash in the denominator, which is also zero.':
      'Notez : Bien que la covariance entre les espèces et tout actif soit nulle, le coefficient de corrélation est indéfini car il a l’écart type des espèces au dénominateur, qui est également nul.',
    'Correlation coefficients greater than or equal to 0.70 will be flagged.':
      'Les coefficients de corrélation qui dépassent ou sont égaux à 0,70 sont indiqués.',
  };

  return (isFr ? (text in fr ? fr[text] : text) : text) || text;
}

export function translateBenchmarkHeading(heading, isFr) {
  let translatedHeading = heading;

  if (heading === 'Stock Market') {
    translatedHeading = 'Bourse';
  } else if (heading === 'Bond Market') {
    translatedHeading = 'Marché obligataire';
  } else if (heading === 'Benchmark') {
    translatedHeading = 'Référence';
  } else {
    translatedHeading = `Portefeuille ${heading.replace('Portfolio', '')}`;
  }

  return isFr ? translatedHeading : heading;
}

export function translateBenchmarkDescription(description, isFr) {
  const translatedBenchmark = {
    'TSX Capped Composite ETF': 'FNB composé plafonné TSX',
    'Core Canadian Universe Bonds ETF':
      "FNB d'obligations universelles canadiennes de base",
    'MSCI ACWI ETF (CAD unhedged)': 'FNB MSCI ACWI (CAD non couvert)',
    'S&P 500 ETF': 'FNB S&P 500',
    'U.S. Aggregate Bonds ETF': "FNB d'obligations globales américaines",
    'MSCI ACWI ETF (USD)': 'FNB MSCI ACWI (EUR)',
  };

  let translatedDescription = description;

  Object.keys(translatedBenchmark).forEach((benchmark) => {
    if (translatedBenchmark[benchmark]) {
      translatedDescription = translatedDescription.replace(
        benchmark,
        translatedBenchmark[benchmark]
      );
    }
  });

  return isFr ? translatedDescription.replace('and', 'et') : description;
}

export function isNB(partnerName) {
  return partnerName === 'nbdb' || partnerName === 'nbdb_sandbox';
}

// For filtering out labels and data that should not appear in charts.
export function filterData(data) {
  return (
    Object.entries(data)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => value > 0.001)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {})
  );
}

export function translateAssetClass(french, assetClass) {
  const assetClassMap = {
    Alternative: french ? 'Alternatif' : 'Alternative',
    Equity: french ? 'Capitaux propres' : 'Equity',
    'Fixed Income': french ? 'Revenu fixe' : 'Fixed Income',
    Cash: french ? 'Liquidités' : 'Cash',
    'Misc.': french ? 'Divers.' : 'Misc.',
    Other: french ? 'Autres' : 'Other',
  };
  return assetClassMap[assetClass] || 'N/A';
}

export function translateAlert(alert, french) {
  const alertMap = {
    error: 'Erreur',
    success: 'Succès',
    warning: 'Attention',
    'Not enough matched holdings to analyze this account':
      'Nombre insuffisant de positions pour analyser ce compte',
    'Account Information Updated': 'Les données du compte sont mises à jour',
    'Server Offline': 'Serveur est hors ligne',
    'Account deleted': 'Compte supprimé',
    'Server Error': 'Erreur de serveur',
    'Server Error. Chart data could not be retrieved.':
      'Erreur du serveur. Les données du diagramme n’ont pas pu être récupérées.',
    'Error with request': 'Erreur avec la demande',
    'You are limited to a combined total of 10 GICs/HISAs across both portfolios in this comparison.':
      'Dans cette comparaison, vous êtes limité à un total combiné de 10 CPG/CELI pour les deux portefeuilles.',
    'Rebalancing system saved': 'Système de rééquilibrage sauvegardé',
    'Rebalancing system deleted': 'Système de rééquilibrage supprimé',
  };
  return french && alert in alertMap ? alertMap[alert] : alert || alert;
}

export function translateTableHeadings(heading, french) {
  const headingMap = {
    Asset: 'Actifs',
    'Buy/Sell': 'Acheter/Vendre',
    Quantity: 'Quantité',
    Weight: 'Pondérations',
    Deviation: 'Écart',
    Exchange: 'Bourse',
    Class: 'Classe',
    Price: 'Prix',
  };
  return french && heading in headingMap
    ? headingMap[heading]
    : heading || heading;
}
