import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Button, Modal } from 'components';

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))
export default class DeleteModal extends Component {
  state = {
    loading: false,
  };

  deletePortfolio = () => {
    const { portfolio, dispatch } = this.props;

    this.setState({ loading: true });

    return dispatch(Portfolio.remove(portfolio.id)).then(() =>
      this.closeModal()
    );
  };

  closeModal = () => {
    const { close } = this.props;

    this.setState({ loading: false });

    return close();
  };

  render() {
    const { portfolio, open, offsetY, partnerName } = this.props;
    const { loading } = this.state;

    return !portfolio ? null : (
      <Modal
        basicFlex
        dimmerClick={this.closeModal}
        open={open}
        offsetY={offsetY}
        vb={notWealthica(partnerName)}
      >
        <Modal.Header>
          <Header
            icon="warning sign"
            content="Delete Portfolio"
            style={{ color: colors.red }}
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete portfolio{' '}
            <span style={{ color: colors.red }}>{portfolio.name}</span>?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: loading }
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={this.deletePortfolio}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  portfolio: PropTypes.object,
  dispatch: PropTypes.func,
  offsetY: PropTypes.number,
  partnerName: PropTypes.string,
};

DeleteModal.defaultProps = {
  portfolio: {},
  dispatch: () => false,
  offsetY: 0,
  partnerName: '',
};
