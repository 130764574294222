import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Table, Icon, Popup, Divider, Button } from 'semantic-ui-react';
import { chartingToolColorsLessOpacity, colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';

const EMPTY_CELL_BACKGROUND_COLOR = '#F9FAFB';
class Performance extends Component {
  state = {};

  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker, exchangeSymbol) =>
    `${ticker.split(':')[0]}-${exchangeSymbol}`;
  handleClick = (event, ticker, exchangeSymbol) => {
    event.preventDefault();
    window.open(
      this.createUrl(
        this.gbmConvert(
          this.appendExchangeSymbol(ticker, exchangeSymbol.toUpperCase())
        )
      )
    ),
      '_blank';
  };

  formatNum = (num) => (num ? num.toFixed(2) : '--');
  renderEmptyHeaderCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => <Table.HeaderCell />);
  renderEmptyCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => (
      <Table.Cell style={{ backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }} />
    ));

  renderTable = (annual, peer, ticker, tickerIndex) => {
    const headerColor = Object.values(chartingToolColorsLessOpacity)[
      tickerIndex
    ];
    const headerCellStyles = {
      backgroundColor: headerColor,
      color: 'black',
    };
    const { securityData, partnerName } = this.props;
    return (
      <Table textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4} style={headerCellStyles}>
              {ticker}
            </Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>YTD</Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>1-Year</Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>3-Year</Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>5-Year</Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>
              10-Year
            </Table.HeaderCell>
            <Table.HeaderCell style={headerCellStyles}>
              Since Inception
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ textAlign: 'left' }}>
              Fund Return (%)
            </Table.Cell>
            <Table.Cell>{this.formatNum(annual.year_to_date)}</Table.Cell>
            <Table.Cell>{this.formatNum(annual.one_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(annual.three_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(annual.five_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(annual.ten_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(annual.since_inception)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ textAlign: 'left' }}>
              Peer Group Return
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="right center"
                wide
                content={`${securityData[ticker].global_category} peer group returns.`}
              />
              (%)
            </Table.Cell>
            <Table.Cell>{this.formatNum(peer.year_to_date)}</Table.Cell>
            <Table.Cell>{this.formatNum(peer.one_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(peer.three_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(peer.five_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(peer.ten_year)}</Table.Cell>
            <Table.Cell>{this.formatNum(peer.since_inception)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  render() {
    const {
      securityData,
      securityTickerList,
      user_region,
      isOpen,
      toggleOpen,
    } = this.props;

    return (
      <div>
        <Header textAlign="left" size="medium">
          <Header.Content>
            <div style={{ display: 'flex' }}>
              <StyledButton onClick={toggleOpen} isOpen={isOpen}>
                {isOpen ? '-' : '+'}
              </StyledButton>
              Annual Returns&nbsp;
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                content="This data is updated once a month on the 15th."
                wide
              />
            </div>
          </Header.Content>
        </Header>

        <Divider />
        {isOpen && (
          <React.Fragment>
            <StyledNote>
              {user_region === 'CA' ? (
                <Header.Subheader>
                  US funds in USD; Canadian funds in CAD
                </Header.Subheader>
              ) : null}
            </StyledNote>
            {securityTickerList.map((ticker, index) =>
              this.renderTable(
                securityData[ticker].annual_returns,
                securityData[ticker].peer_annual_returns,
                ticker,
                index
              )
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

Performance.propTypes = {
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.object.isRequired,
  user_region: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

Performance.defaultProps = { partnerName: '' };

export default Performance;

const StyledButton = styled(Button)`
  &&& {
    font-size: 1.3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.isOpen ? colors.blue : 'white'} !important;
    color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
    border: ${(props) =>
      props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
    border-radius: 50% !important;
    height: 2rem !important;
    width: 2rem !important;
    padding: 0 !important;
    transition: all 0.9s ease-in-out !important;
    margin: 0 0.75rem 0 0;
  }
`;

const StyledNote = styled.div`
  &&& {
    display: block;
    font-weight: 400;
    padding: 0;
    margin: 2rem;
    line-height: 1.2em;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.14rem;
  }
`;
