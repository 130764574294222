import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = ({ children, subheader, long, ...rest }) => (
  <StyledHeader {...rest}>
    {children}
    <SubHeader long={long}>{subheader}</SubHeader>
  </StyledHeader>
);

Header.propTypes = {
  children: PropTypes.any.isRequired,
  subheader: PropTypes.any,
  long: PropTypes.bool,
};

Header.defaultProps = {
  subheader: '',
  long: false,
};

export default Header;

const StyledHeader = styled.div`
  margin: 0 0 2rem 0;
  color: ${(props) => props.color || 'inherit'};
  font-size: ${(props) => props.size || '1rem'};
`;

const SubHeader = styled.div`
  color: rgb(149, 149, 149);
  margin-top: 8px;
  font-size: 1.14rem;
  max-width: ${(props) => (props.long ? '45rem' : '39rem')};
`;
