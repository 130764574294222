import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Grid, Header } from 'semantic-ui-react';

import { Message } from 'components';
import { ExposureTable, ExposureChart } from '../components';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class Region extends Component {
  renderSubheader = (type) => {
    const { french } = this.props;
    return (
      <div className="sub header">
        {type === 'domicile'
          ? french
            ? 'Par région de domicile'
            : 'By Region of Domicile'
          : french
          ? 'Aux marchés boursiers régionaux'
          : 'To Regional Stock Markets'}
        &nbsp;
        <Popup
          trigger={
            <Icon
              name="question circle outline"
              style={{
                fontSize: '15px',
                verticalAlign: 'initial',
                color: 'dimgrey',
              }}
            />
          }
          position="top center"
          wide
          content={
            type === 'domicile'
              ? french
                ? 'Comprends les actions et les obligations dans votre portefeuille'
                : 'Including stocks and bonds in your portfolio'
              : french
              ? 'Une analyse statistique nous permet d’estimer l’exposition de votre portefeuille aux fluctuations des différents marchés boursiers régionaux. Si votre portefeuille est majoritairement composé d’obligations, les chiffres peuvent être très différents de ceux figurant dans la répartition des avoirs.'
              : 'Using statistical analysis, we estimate the exposure of your portfolio to fluctuations in different regional stock markets. If you have a predominantly bonds portfolio, the numbers may be very different from those under holdings breakdown.'
          }
        />
      </div>
    );
  };

  render() {
    const { data } = this.props.analytics;
    const { french } = this.props;

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content={
            french
              ? 'Analyse des avoirs et de l’exposition au risque'
              : 'Holdings and Risk Exposure Analysis'
          }
          subheader={french ? 'Par région' : 'By Region'}
        />

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content={
                  french ? 'Répartition des avoirs' : 'Holdings Breakdown'
                }
                subheader={this.renderSubheader('domicile')}
                style={{ margin: 0 }}
              />
              {typeof data.domicile === 'object' ? (
                <ExposureChart
                  title=""
                  labels={Object.keys(data.domicile)}
                  data={Object.values(data.domicile)}
                  frontierMarkets
                />
              ) : (
                <Message visible warning>
                  {data.domicile}
                </Message>
              )}
            </Grid.Column>
            <Grid.Column>
              <Header
                size="medium"
                content={french ? 'Exposition au risque' : 'Risk Exposure'}
                subheader={this.renderSubheader('market')}
                style={{ margin: 0 }}
              />
              {typeof data.riskExp.C === 'object' ? (
                <ExposureChart
                  title=""
                  labels={Object.keys(data.riskExp.C)}
                  data={Object.values(data.riskExp.C)}
                  frontierMarkets
                />
              ) : (
                <Message visible warning>
                  {data.riskExp.C}
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {typeof data.riskExp.C === 'string' &&
        typeof data.domicile === 'string' ? null : (
          <ExposureTable
            headers={[
              french ? 'Région' : 'Region',
              <div>
                {french ? 'Répartition des titres' : 'Holdings Breakdown'}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content={
                    french
                      ? 'Incluant les actions et obligations dans votre portefeuille'
                      : 'Including stocks and bonds in your portfolio'
                  }
                />
              </div>,
              <div>
                {french ? 'Exposition au risque' : 'Risk Exposure'}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content={
                    french
                      ? 'Une analyse statistique nous permet d’estimer l’exposition de votre portefeuille aux fluctuations des différents marchés boursiers régionaux. Si votre portefeuille est majoritairement composé d’obligations, les chiffres peuvent être très différents de ceux figurant dans la répartition des avoirs.'
                      : 'Using statistical analysis, we estimate the exposure of your portfolio to fluctuations in different regional stock markets. If you have a predominantly bonds portfolio, the numbers may be very different from those under holdings breakdown.'
                  }
                />
              </div>,
            ]}
            totals={[
              french ? 'Total' : 'Total',
              <div>100%</div>,
              <div>100%</div>,
            ]}
            data={[...Object.keys(data.domicile), 'Port. Specific Risk'].reduce(
              (total, key) => {
                if (key === 'Other') {
                  key = french ? 'Marchés frontaliers' : 'Frontier Markets';
                }
                total.push({
                  region: key,
                  holdingsBreakdown: data.domicile[key],
                  riskExposure: data.riskExp ? data.riskExp.C[key] : null,
                });

                return total;
              },
              []
            )}
          />
        )}
      </Container>
    );
  }
}

Region.propTypes = {
  analytics: PropTypes.object,
  french: PropTypes.bool,
};

Region.defaultProps = {
  analytics: {},
  french: false,
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
