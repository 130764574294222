import { RebalanceAccount } from 'actions';
import { convertToTickersAndWeights } from 'utils/helpers';

const initialState = {
  isFetching: false,
  isSet: false,
  search: [],
  holdings: {},
  original: {},
  isBalanced: true,
  isEditing: false,
  needsUpdate: false,
  rebalanceData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RebalanceAccount.REBALANCEACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case RebalanceAccount.REBALANCEACCOUNT_FETCH_ID: {
      const originalHoldings = convertToTickersAndWeights(
        action.account.holdings
      );
      const { tickers, weights, deviations } = !action.account.rebal_targets
        ? {
            tickers: action.account.holdings
              .map(
                (h) => (h.security_detail && h.security_detail.ticker) || 'N/A'
              )
              .join(','),
            weights: '',
            deviations: '',
          }
        : {
            tickers: Object.keys(action.account.rebal_targets).join(','),
            weights: Object.values(action.account.rebal_targets).join(','),
            deviations: Object.values(action.account.rebal_tolerances).join(
              ','
            ),
          };

      const tickersArray = tickers.split(',');
      const weightsArray = weights.split(',');
      const deviationsArray = deviations ? deviations.split(',') : null;
      const keyedHoldings = tickersArray.reduce((acc, val, idx) => {
        const holding = action.account.holdings.find(
          (h) => h.security_detail && h.security_detail.ticker === val
        );

        acc[val] = {
          ...holding,
          weight: Math.round((weightsArray[idx] * 1000) / 10) || 0,
          deviation: deviations
            ? Math.round((deviationsArray[idx] * 1000) / 10)
            : 0,
        };

        return acc;
      }, {});

      return {
        ...state,
        isFetching: false,
        isEditing: !action.account.rebal_set || action.account.rebal_update,
        isSet: action.account.rebal_set && !action.account.rebal_update,
        holdings: keyedHoldings,
        original: originalHoldings,
        isBalanced: !action.account.rebal_alert,
        needsUpdate: action.account.rebal_update,
      };
    }
    case RebalanceAccount.REBALANCEACCOUNT_REMOVE_ALL:
      return {
        ...state,
        isFetching: false,
        holdings: {},
      };
    case RebalanceAccount.REBALANCEACCOUNT_SAVE_SYSTEM:
      return {
        ...state,
        isFetching: false,
        isEditing: !!action.error,
        isSet: !action.error,
      };
    case RebalanceAccount.REBALANCEACCOUNT_REMOVE_SYSTEM:
      return {
        ...state,
        isFetching: false,
        isEditing: false,
        holdings: {},
        isSet: false,
      };
    case RebalanceAccount.REBALANCEACCOUNT_UPDATE_WEIGHT:
      return {
        ...state,
        holdings: {
          ...state.holdings,
          [action.ticker]: {
            ...state.holdings[action.ticker],
            weight: action.value,
          },
        },
      };
    case RebalanceAccount.REBALANCEACCOUNT_UPDATE_DEVIATION:
      return {
        ...state,
        holdings: {
          ...state.holdings,
          [action.ticker]: {
            ...state.holdings[action.ticker],
            deviation: action.value,
          },
        },
      };
    case RebalanceAccount.REBALANCEACCOUNT_REBALANCE:
      return {
        ...state,
        isFetching: false,
        rebalanceData: action.data,
      };
    case RebalanceAccount.REBALANCEACCOUNT_CLEAR_DATA:
      return {
        ...state,
        isFetching: false,
        rebalanceData: null,
      };
    case RebalanceAccount.REBALANCEACCOUNT_INIT_EDITING:
      return {
        ...state,
        isEditing: true,
      };
    default:
      return state;
  }
}
