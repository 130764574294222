import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Icon, Popup, Button, Divider } from 'semantic-ui-react';
import { Api } from 'actions';

import { colors } from 'utils/colors';
import Bar from './Bar';

class Holdings extends Component {
  state = {
    tickerExchanges: {},
  };
  componentWillMount = () => {
    const { holdings, dispatch } = this.props;
    const tickers_string = holdings
      .filter((item) => item.name !== 'GIC or HISA')
      .map((item) => item.ticker)
      .join(',');
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${tickers_string}`,
      })
    ).then((result) => {
      this.setState({ tickerExchanges: result });
    });
  };
  getExchangeSymbol(ticker, tickerExchanges) {
    for (let i = 0; i < tickerExchanges.length; i++) {
      if (tickerExchanges[i].ticker === ticker) {
        return tickerExchanges[i].exchange_symbol;
      }
    }
    return '';
  }

  isLinked = (port) => {
    if (!port) {
      return false;
    }

    if (
      port.portfolio_type === 'custom' ||
      port.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return port.is_linked;
  };

  generatePDF = () => {
    const { history } = this.props;

    return history.push('/globe/pdf');
  };

  render() {
    const { holdings, portfolio, hasExcludedHolding } = this.props;
    const { tickerExchanges } = this.state;

    return (
      <Container>
        <Header size="medium" style={{ position: 'relative' }}>
          {'Portfolio Overview'}
          <Button
            active
            basic
            icon
            labelPosition="right"
            style={{
              position: 'absolute',
              top: '0',
              right: !this.isLinked(portfolio) ? '0' : '170px',
              fontSize: '0.85em',
              padding: '0.35em',
            }}
            onClick={this.generatePDF}
          >
            Print
            <Icon
              name="download"
              style={{
                fontSize: '12px',
                verticalAlign: 'initial',
                color: 'dimgrey',
              }}
            />
          </Button>
          {!this.isLinked(portfolio) ? null : (
            <Popup
              trigger={
                <Button
                  active
                  basic
                  icon
                  labelPosition="right"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    fontSize: '0.85em',
                    padding: '0.35em',
                  }}
                >
                  Guidelines
                  <Icon
                    name="info circle"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                </Button>
              }
              position="top center"
              style={{ maxWidth: '650px' }}
            >
              <Popup.Content>
                To protect the integrity of the statistical analysis,
                Wealthscope implements the following rules when analyzing your
                portfolio:
                <br />
                <br />
                If all of the securities in the portfolio have more than or
                equal to 48 months of history, all of the analysis will be
                performed. Otherwise, we exclude the following securities:
                <br />
                <ul>
                  <li>securities that have less than 36 months of history;</li>
                  <li>
                    securities that have between 36 and 47 months of history and
                    collectively weigh less than 10% of the portfolio.
                  </li>
                </ul>
                For securities included in the analysis, the period of analysis
                will be the longest common history.
                <br />
                <br />
                If all of the securities in the portfolio have less than 36
                months of history, we provide only basic summary information for
                the portfolio using the longest common history.
                <br />
                <br />
                If a passive ETF has insufficient history but there is a close
                substitute, the latter will be used in the analysis. For some
                passive ETFs with short history, we may use the index returns
                (minus the MER) prior to inception.
                <br />
              </Popup.Content>
            </Popup>
          )}
        </Header>

        <Divider style={{ margin: '12px 0' }} />

        <Wrapper limitHeight={holdings.length > 8}>
          {holdings.map((val) => (
            <Bar
              key={val.ticker}
              ticker={this.isLinked(portfolio) ? val.ticker : ''}
              exchange_symbol={this.getExchangeSymbol(
                val.ticker,
                tickerExchanges
              )}
              name={this.isLinked(portfolio) ? val.name : val.wsName}
              weight={val.weight}
              error={val.isExcluded}
              replaced={val.replacedBy}
            />
          ))}
        </Wrapper>
        {hasExcludedHolding ? (
          <p style={{ color: colors.hintRed, fontSize: '1rem' }}>
            Please check the list of holdings to see if any of your holdings
            have been excluded from the analysis. Hover over "Guidelines" above
            for our rules. To use all of the holdings in the analysis, use the
            toggle at the top left.
          </p>
        ) : null}
      </Container>
    );
  }
}

Holdings.propTypes = {
  portfolio: PropTypes.object.isRequired,
  holdings: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  hasExcludedHolding: PropTypes.bool.isRequired,
};

export default Holdings;

const Container = styled.div`
  border-radius: 8px;
  width: 50%;
  background: white;
  margin-bottom: 50px;
`;

const Wrapper = styled.div`
  height: ${(props) => (props.limitHeight ? '430px' : 'auto')};
  overflow: auto;
`;
