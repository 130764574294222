import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Statistic, Header, Popup, Icon } from 'semantic-ui-react';

import { colors } from 'utils/colors';
import { Table, Score } from 'components';

export default class Income extends Component {
  getClass = (row) => {
    if (row.isExcluded || (row.dist_yield !== 0 && !row.dist_yield)) {
      return 'negative';
    }

    return false;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  renderHeader = () => (
    <div>
      Securities excluded from analysis due to insufficient history
      <Popup
        trigger={
          <Icon
            name="question circle outline"
            style={{
              fontSize: '15px',
              verticalAlign: 'initial',
              color: 'dimgrey',
            }}
          />
        }
        position="top center"
        style={{ maxWidth: '650px' }}
      >
        <Popup.Content>See "Overview" page for more information</Popup.Content>
      </Popup>
    </div>
  );

  render() {
    const { data } = this.props;

    return (
      <Container>
        <Score
          name="Income "
          score={
            data.score.Income[0] || data.score.Income[0] === 0
              ? `${data.score.Income[0]}%`
              : null
          }
          grade={data.score.Income[1] ? data.score.Income[1] : 'N/A'}
          description="Scored on your portfolio's weighted average dividend/distribution yield. The maximum score is given to portfolios with a yield of 5% or more."
          portfolio={data.portfolio}
        />

        <p>
          Income refers to cash dividends from a stock, or cash distributions
          from an ETF or a mutual fund.&nbsp;
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ margin: 0, color: 'dimgrey' }}
              />
            }
            position="top center"
            content="The score is calculated using a maximum yield of 5%, i.e., a portfolio yield of 5% will receive a 100% score."
            style={{ height: 'initial' }}
          />
        </p>
        <p>
          Whether you desire a high income score depends on your saving
          objective. If you are investing for the long term, or if you are not
          dependent on your portfolio for everyday expenses, a high income
          portfolio is not necessary.
        </p>

        <Header dividing size="medium" content="Portfolio Distribution Yield" />

        <Table basic fixed textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Asset</Table.HeaderCell>
              <Table.HeaderCell width={2}>Weight</Table.HeaderCell>
              <Table.HeaderCell width={2}>
                Yield&nbsp;
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  content="TTM (Trailing 12-Month) Yield)"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.tickersOriginal.map((row) => (
              <Table.Row key={row.ticker} negative={this.getClass(row)}>
                <Table.Cell textAlign="left">
                  {this.isLinked(data) ? row.name : row.wsName}
                </Table.Cell>
                <Table.Cell>
                  {this.getClass(row) ? (
                    <Error>
                      <span style={{ transform: 'translateX(1.5rem)' }}>
                        {Math.round(row.weight * 10000) / 100}%
                      </span>
                      <Popup
                        trigger={<Icon name="exclamation circle" />}
                        position="bottom center"
                        wide
                        content={
                          row.isExcluded ? (
                            <div>
                              <strong>{row.name}</strong> has been excluded in
                              the analysis because it has insufficient history.
                            </div>
                          ) : (
                            <div>
                              The distribution yield is missing for{' '}
                              <strong>{row.name}</strong>; please contact the
                              fund provider for more information.
                            </div>
                          )
                        }
                      />
                    </Error>
                  ) : (
                    `${Math.round(row.weight * 10000) / 100}%`
                  )}
                </Table.Cell>
                <Table.Cell>
                  {row.dist_yield || row.dist_yield === 0
                    ? `${Math.round(row.dist_yield * 10000) / 100}%`
                    : `N/A`}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {!data.portYield && data.portYield !== 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: colors.hintRed,
              }}
            >
              Unfortunately, there is no yield data for this portfolio.
            </p>
          </div>
        ) : null}
        <Statistic
          size="tiny"
          label="Weighted Average Yield"
          value={
            data.portYield || data.portYield === 0
              ? `${(data.portYield * 100).toFixed(2)}%`
              : `N/A`
          }
          style={{ display: 'block' }}
        />
      </Container>
    );
  }
}

Income.propTypes = {
  data: PropTypes.object.isRequired,
};

const Error = styled.div`
  position: relative;
  text-align: center;

  span {
    font-size: 1rem;
  }

  i {
    color: ${colors.red};
    font-size: 1.4rem;
    position: absolute;
    top: 0;
    left: -1.5rem;
  }
`;

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
