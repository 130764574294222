import jwtDecode from 'jwt-decode';
import { login, logout } from '../actions/auth';
import { setItem, removeItem } from '../actions/storage';

const SDK_READY = 'SDK_READY';

export default class SdkMessageService {
  static init(store, history) {
    this.store = store;
    this.history = history;
  }

  static listen() {
    // eslint-disable-next-line no-console
    console.log('Enabling SdkMessageService');

    // start listening for messages
    this.listener = window.addEventListener(
      'message',
      (event) => {
        this.handle(event);
      },
      false
    );

    // emit a `ready` signal
    window.parent.postMessage({ type: SDK_READY }, '*');
  }

  static _auth(event) {
    const data = typeof event === 'string' ? JSON.parse(event) : event.data;
    const { token } = data;
    const decodedToken = jwtDecode(token);
    const partnerName =
      (decodedToken && decodedToken.partner_name) || 'wealthscope'; // Default is wealthscope
    const user_region = (decodedToken && decodedToken.region) || 'CA';

    // Store the partner name in local storage
    this.store.dispatch(setItem('partner-name', partnerName));
    this.store.dispatch(setItem('user_region', user_region));

    return this.store.dispatch(login(token, this.history));
  }

  static _logout() {
    this.store.dispatch(removeItem('partner-name'));
    return this.store.dispatch(logout(this.history));
  }

  static handle(event) {
    switch (event.data.type) {
      case 'auth':
        this._auth(event);
        break;
      case 'logout':
        this._logout(event);
        break;
      default:
        break;
    }
    // eslint-disable-next-line no-console
    // console.log('message received', event);
  }
}
