import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Header, Grid, Image, Statistic, Popup, Icon } from 'semantic-ui-react';
import { Line } from 'react-chartjs-2';
import cn from 'classnames';

import { Segment, Button } from 'components';
import { graph, colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import Fifty from 'images/50.png';
import SeventyFive from 'images/75.png';
import Ninety from 'images/90.png';

class SavingResult extends Component {
  state = {
    monthlySavingChosen: null,
    paths: null,
    maxOfChart: null,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props)
      this.setState({
        maxOfChart: null,
        monthlySavingChosen: null,
      });
  };

  getChartData = () => {
    const { paths } = this.state;

    if (paths) {
      const { current, high, low } = paths;

      return {
        labels: new Array(Object.keys(current).length)
          .fill(0)
          .map((n, index) => Object.keys(current).length - index - 1),
        datasets: [
          {
            data: Object.values(current),
            borderWidth: 3,
            label: 'Meets Goal',
            lineTension: 0,
            fill: false,
            backgroundColor: graph.darkBlue,
            borderColor: graph.darkBlue,
            pointBackgroundColor: graph.darkBlue,
            pointBorderColor: graph.darkBlue,
            pointRadius: 0,
            pointHitRadius: 5,
          },
          {
            data: Object.values(low),
            borderWidth: 3,
            label: 'Low - 5th Percentile',
            lineTension: 0,
            fill: false,

            backgroundColor: graph.teal,
            borderColor: graph.teal,
            pointBackgroundColor: graph.teal,
            pointBorderColor: graph.teal,
            pointRadius: 0,
            pointHitRadius: 5,
          },
          {
            data: Object.values(high),
            borderWidth: 3,
            label: 'High - 95th Percentile',
            lineTension: 0,
            fill: false,
            backgroundColor: graph.lightBlue,
            borderColor: graph.lightBlue,
            pointBackgroundColor: graph.lightBlue,
            pointBorderColor: graph.lightBlue,

            pointRadius: 0,
            pointHitRadius: 5,
          },
        ],
      };
    }

    return {};
  };

  setChartMax = (label, index, values) => {
    const { maxOfChart } = this.state;

    if (values[0] > maxOfChart) {
      this.setState({ maxOfChart: values[0] });
    }
  };

  isAble = (input) => {
    const { result } = this.props;

    if (!result) return false;

    return !!result.savings[input];
  };

  updateMonthlySavingChosen = (value, option) => {
    const { result } = this.props;

    this.setState({
      monthlySavingChosen: option,
      paths: result.paths[option],
    });
    this.scrollToBottom();
  };

  displayYearMonth = (month) => {
    const year = month / 12;

    let str = '';

    if (year === 1) str = '1 year ';
    else str = `${year} years `;

    return (str += `(${month} months)`);
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  scrollToBottom = () => this.scrollHandler(window.screen.height + 100, 500);

  render() {
    const { result, inf, partnerName } = this.props;
    const { monthlySavingChosen, maxOfChart } = this.state;
    const chartOptions = {
      hover: {
        animationDuration: 0,
        onHover: (e) => {
          e.target.style.cursor = 'pointer';
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: 'Months to target date',
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (label) => `$${label.toLocaleString()}`,
              max: maxOfChart,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => `${data.labels[tooltipItem[0].index]}`,
          label: (tooltipItems, data) =>
            `${data.datasets[tooltipItems.datasetIndex].label}: $${Math.ceil(
              Number(tooltipItems.yLabel)
            ).toLocaleString()}`,
        },
        mode: 'index',
        bodySpacing: 5,
      },
      legend: {
        position: 'bottom',
      },
    };

    return !result ? null : (
      <Segment textAlign="center">
        <div
          style={{
            visibility: 'hidden',
            height: '2rem',
          }}
        >
          <Line
            setChartMax={this.setChartMax}
            data={() => {
              const dataSets = [];
              const values = Object.values(result.paths);

              values.forEach((v) => {
                if (v) dataSets.push(...Object.values(v));
              });
              const temp = dataSets.map((d, index) => ({
                data: Object.values(d),
                borderWidth: 2,
                label: index,
                lineTension: 0,
                fill: false,
                backgroundColor: graph.red,
                borderColor: graph.red,
                pointBackgroundColor: graph.red,
                pointBorderColor: graph.red,
                pointRadius: 0,
                pointHitRadius: 5,
              }));

              return {
                datasets: temp,
              };
            }}
            options={{
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      callback: (label, index, values) =>
                        this.setChartMax(label, index, values),
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  title: (tooltipItem, data) =>
                    `${data.labels[tooltipItem[0].index]}`,
                  label: (tooltipItems, data) =>
                    `${
                      data.datasets[tooltipItems.datasetIndex].label
                    }: $${Math.ceil(
                      Number(tooltipItems.yLabel)
                    ).toLocaleString()}`,
                },
              },
            }}
          />
        </div>
        <Header
          as="h3"
          content="How Much to Save Per Month"
          subheader="Add this amount to your portfolio. Since the portfolio has uncertain outcomes, how much you need to save is associated with a probability. The probability represents the likelihood of reaching your goal by the target date. The results are generated based on 10,000 simulations."
          style={{ maxWidth: '50rem', margin: '0 auto' }}
        />
        <Grid style={{ margin: '1rem 0' }}>
          <Grid.Row>
            <Grid.Column>
              <LessHeader>Likelihood of Success</LessHeader>
              <Header size="medium">
                <Header.Subheader style={{ margin: '.3rem auto .6rem' }}>
                  Without additional savings, the probability of reaching your
                  target wealth is{' '}
                  <span
                    style={{
                      margin: '0 0.2rem 0 0.2rem',
                      color: colors.navyBlue,
                      fontWeight: 'bold',
                      fontSize: '1.3rem',
                    }}
                  >
                    {`${Math.trunc(result.prob_without_savings * 100)} %`}
                  </span>
                  .
                  <br />
                  To improve the probability of reaching your goal, please
                  select a monthly savings target below:
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} style={{ padding: 0 }}>
            <Grid.Column
              key="0.5"
              onClick={() =>
                this.isAble('0.5')
                  ? this.updateMonthlySavingChosen(result.savings['0.5'], 0.5)
                  : {}
              }
            >
              <Image
                src={Fifty}
                style={{
                  width: '70%',
                  margin: '0 auto',
                  cursor: this.isAble('0.5') ? 'pointer' : 'default',
                }}
              />
              <StyledStatistic size="mini" isAble={this.isAble('0.5')}>
                <Statistic.Label>Required Monthly Savings</Statistic.Label>
                <Statistic.Value>{`$ ${result.savings['0.5']}`}</Statistic.Value>
              </StyledStatistic>
              {result && result.savings['0.5'] ? (
                <Button
                  className={cn('left')}
                  style={{ margin: '0 auto' }}
                  color={
                    monthlySavingChosen === 0.5 ? colors.teal : colors.lightGrey
                  }
                  icon="chart line"
                >
                  {isGlobeInvestor(partnerName) ? 'Graph' : 'Show Graph'}
                </Button>
              ) : null}
            </Grid.Column>
            <Grid.Column
              key="0.75"
              onClick={() =>
                this.isAble('0.75')
                  ? this.updateMonthlySavingChosen(result.savings['0.75'], 0.75)
                  : {}
              }
            >
              <Image
                src={SeventyFive}
                style={{
                  width: '70%',
                  margin: '0 auto',
                  cursor: this.isAble('0.75') ? 'pointer' : 'default',
                }}
              />
              <StyledStatistic size="mini" isAble={this.isAble('0.75')}>
                <Statistic.Label>Required Monthly Savings</Statistic.Label>
                <Statistic.Value>{`$ ${result.savings['0.75']}`}</Statistic.Value>
              </StyledStatistic>
              {result && result.savings['0.75'] ? (
                <Button
                  className={cn('left')}
                  style={{ margin: '0 auto' }}
                  color={
                    monthlySavingChosen === 0.75
                      ? colors.lightBlue
                      : colors.lightGrey
                  }
                  icon="chart line"
                >
                  {isGlobeInvestor(partnerName) ? 'Graph' : 'Show Graph'}
                </Button>
              ) : null}
            </Grid.Column>
            <Grid.Column
              key="0.9"
              onClick={() =>
                this.isAble('0.9')
                  ? this.updateMonthlySavingChosen(result.savings['0.9'], 0.9)
                  : {}
              }
            >
              <Image
                src={Ninety}
                style={{
                  width: '70%',
                  margin: '0 auto',
                  cursor: this.isAble('0.9') ? 'pointer' : 'default',
                }}
              />
              <StyledStatistic size="mini" isAble={this.isAble('0.9')}>
                <Statistic.Label>Required Monthly Savings</Statistic.Label>
                <Statistic.Value>{`$ ${result.savings['0.9']}`}</Statistic.Value>
              </StyledStatistic>
              {result && result.savings['0.9'] ? (
                <Button
                  className={cn('left')}
                  style={{ margin: '0 auto' }}
                  color={
                    monthlySavingChosen === 0.9 ? colors.blue : colors.lightGrey
                  }
                  icon="chart line"
                >
                  {isGlobeInvestor(partnerName) ? 'Graph' : 'Show Graph'}
                </Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {monthlySavingChosen !== null ? (
          <div>
            <Header
              as="h3"
              style={{
                margin: '4rem auto 0.25rem',
              }}
            >
              {'Simulated saving outcomes'.toUpperCase()}
            </Header>
            <Header
              as="h4"
              style={{ colors: colors.darkGrey, margin: '0 auto 0.25rem' }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: colors.darkGrey,
                }}
              >
                {Math.round(monthlySavingChosen * 100)}%
              </span>{' '}
              of the final outcomes fall between the middle and the top series
            </Header>
            {result.paths[0.5] ? (
              <Header.Subheader style={{ marginBottom: '2rem' }}>
                The future value of your goal is $
                {Math.ceil(
                  result.paths[0.5].current[
                    result.paths[0.5].current.length - 1
                  ]
                ).toLocaleString()}{' '}
                in {this.displayYearMonth(result.paths[0.5].current.length)}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content={`Assuming an average annual inflation rate of ${inf}%`}
                />
              </Header.Subheader>
            ) : null}
            <Line data={this.getChartData()} options={chartOptions} />
          </div>
        ) : null}
      </Segment>
    );
  }
}

SavingResult.propTypes = {
  result: PropTypes.object,
  inf: PropTypes.number,
  partnerName: PropTypes.string,
};

SavingResult.defaultProps = {
  result: null,
  inf: 2,
  partnerName: '',
};

export default connect((state) => ({
  result: state.Planning.savingResult,
  partnerName: state.Storage['partner-name'],
}))(SavingResult);

const StyledStatistic = styled(({ children, className, ...rest }) => (
  <Statistic className={className} {...rest}>
    {children}
  </Statistic>
))`
  cursor: ${(props) => (props.isAble ? 'pointer' : 'dafault')};
  display: block !important;
  margin: 0 !important;
  padding: 1em 0 !important;
`;

const LessHeader = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  margin: 0 0 1rem 0;
  font-size: 1.2em;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 64%;
    background-color: rgba(0, 0, 0, 0.4);
    left: 18%;
  }
`;
