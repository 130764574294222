import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Popup, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { colors, nbColors } from 'utils/colors';
import { isNB } from '../utils/helpers';

class Score extends Component {
  parseDate = (portfolio) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');

    return `${init[1]}/${init[0]} - ${end[1]}/${end[0]}`;
  };

  parseDescription = (description) => {
    if (typeof description === 'string') {
      return description.split('<br />').map((paragraph, index) => (
        <p
          style={{
            fontSize: '1rem',
          }}
          key={index}
        >
          {paragraph}
        </p>
      ));
    }
    return description;
  };

  render() {
    const {
      name,
      score,
      portfolio,
      grade,
      description,
      boldDate,
      analytics,
      french,
      partnerName,
      pdf,
    } = this.props;
    const { printParams } = analytics;
    const { printLetterGrade, printModal } = printParams;
    return (
      <div>
        <StyledScore grade={grade} partnerName={partnerName}>
          {printLetterGrade === false && !printModal ? (
            <div>
              <span style={{ visibility: 'hidden' }}>
                {french ? 'Note globale ' : 'Overall Score '}
              </span>
              {portfolio && (
                <span className="date">
                  {boldDate ? (
                    <b>{this.parseDate(portfolio)}</b>
                  ) : (
                    this.parseDate(portfolio)
                  )}
                </span>
              )}
            </div>
          ) : (
            <div
              style={{
                marginBottom: pdf
                  ? '1rem'
                  : portfolio &&
                    portfolio.account &&
                    portfolio.account.user_institution ===
                      'Overview; All Accounts'
                  ? '3.6rem'
                  : portfolio &&
                    portfolio.account &&
                    portfolio.account.user_institution &&
                    !portfolio.account.institution // comment
                  ? '2.1rem'
                  : portfolio &&
                    portfolio.name &&
                    portfolio.description &&
                    portfolio.description.length > 90
                  ? '4.9rem'
                  : (portfolio &&
                      portfolio.account &&
                      Object.keys(portfolio.account).length) ||
                    (portfolio && portfolio.name && portfolio.description) // comment
                  ? '3.6rem'
                  : portfolio && portfolio.name
                  ? '2.14rem'
                  : '1.8rem',
              }}
            >
              {name || (french ? 'Note globale ' : 'Overall Score ')}
              {!!description && (
                <Popup
                  style={{ fontSize: '1rem' }}
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                        marginRight: '1rem',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  hoverable
                >
                  <Popup.Content
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    {this.parseDescription(description)}
                  </Popup.Content>
                </Popup>
              )}
              {score}
              <Popup
                style={{
                  fontSize: '1rem',
                }}
                trigger={
                  <a
                    className={`grade ${isNB(partnerName) && 'nb'}`}
                    href={
                      french
                        ? 'https://www.wealthscope.ca/why-scores-fr'
                        : 'https://www.wealthscope.ca/whyscores'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {grade}
                  </a>
                }
                position="right center"
              >
                <Popup.Header>
                  {french ? 'Pourquoi les scores?' : 'Why Scores?'}
                </Popup.Header>
                <Popup.Content
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  {french
                    ? 'Cliquez sur la note pour lire un article sur le fonctionnement de nos scores.'
                    : 'Click the letter grade to read an article on how our scores work.'}
                </Popup.Content>
              </Popup>
              {portfolio && (
                <span className="date">
                  {boldDate ? (
                    <b>{this.parseDate(portfolio)}</b>
                  ) : (
                    this.parseDate(portfolio)
                  )}
                </span>
              )}
            </div>
          )}
        </StyledScore>
        <Divider />
      </div>
    );
  }
}

Score.propTypes = {
  portfolio: PropTypes.object.isRequired,
  name: PropTypes.string,
  score: PropTypes.string,
  grade: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  boldDate: PropTypes.bool,
  analytics: PropTypes.object,
  french: PropTypes.bool,
  partnerName: PropTypes.string,
  pdf: PropTypes.bool,
};

Score.defaultProps = {
  name: '',
  score: '',
  grade: '',
  description: '',
  boldDate: false,
  analytics: {},
  french: false,
  partnerName: '',
  pdf: false,
};

export default connect((state) => ({
  analytics: state.Analytics,
  french: state.Storage.language === 'fr',
  partnerName: state.Storage['partner-name'],
}))(Score);

const StyledScore = styled.div`
  position: relative;
  width: 100%;
  font-size: 1.3em;
  font-weight: 700;

  & .grade {
    font-family: 'Kalam', cursive;
    font-size: ${(props) => (props.grade === 'N/A' ? '2.5rem' : '3rem')};
    color: ${(props) => determineColor(props.grade)};
    padding: 0 0.5rem;
    vertical-align: middle;

    &:hover {
      color: ${(props) =>
        isNB(props.partnerName) ? nbColors.blue : 'rgb(97, 124, 246)'};
    }
  }

  & .date {
    font-size: 1rem;
    float: right;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }

  Popup {
    font-size: 1rem;
  }
`;

const determineColor = (grade) => {
  if (grade === 'A') {
    return colors.darkBlue;
  }
  if (grade === 'B') {
    return colors.blue;
  }
  if (grade === 'C') {
    return colors.orange;
  }
  if (grade === 'N/A') {
    return 'rgba(0,0,0,0.6)';
  }

  return colors.red;
};
