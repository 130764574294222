import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LastUpdatedTag = ({ children, ...rest }) => (
  <Tag {...rest} className='lastUpdatedTag'>{children}</Tag>
);

export default LastUpdatedTag;

LastUpdatedTag.propTypes = {
  children: PropTypes.any.isRequired,
};

const Tag = styled.p`
  font-weight: normal !important!;
  position: absolute;
  opacity: 1;
  font-size: 1rem;
  bottom: 1.2rem;
  right: 1.5rem;
  margin: 0;
`;
