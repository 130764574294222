/* eslint-disable no-shadow */
import 'whatwg-fetch';
import { removeItem } from '../../actions/storage';

const hostname = window && window.location && window.location.hostname;
const host = (() => {
  if (hostname === 'staging-bus.wealthscope.ca') {
    return 'https://api.staging-bus.wealthscope.ca/v1';
  }

  if (hostname === 'staging.wealthscope.ca') {
    return 'https://staging-api.wealthscope.ca/v1';
  }

  if (hostname === 'beta.wealthscope.ca') {
    return 'https://api.wealthscope.ca/v1';
  }

  if (hostname === 'bus.wealthscope.ca') {
    return 'https://api.bus.wealthscope.ca/v1';
  }

  // This allows development on localhost environments. Ideally, this whole method should be replaced with something more
  // configuration driven. For now, I'm using this `if` block to keep things consistent.
  if (hostname === 'localhost') {
    // Uncomment the following line to use remote API
    // return 'https://api.bus.wealthscope.ca/v1';
    return 'https://api.staging-bus.wealthscope.ca/v1';
  }

  return 'https://api.wealthscope.ca/v1';
})();

/**
 * Http wrapper
 */
class Api {
  constructor({ host }) {
    if (!host) {
      throw new Error('[api] Host is not specified');
    }

    this.host = host;
  }

  request(method, path, options = {}) {
    const { dispatch, token } = options;
    const url = this.host + path;

    const req = {
      url,
      method,
      headers: {},
      body: options.body,
      query: options.query,
    };

    if (options.query) {
      req.url += options.query;
    }

    if (options.body) {
      req.body = JSON.stringify(options.body);
    }

    if (token) {
      req.headers.Authorization = `JWT ${token}`;
    }

    req.headers.Accept = 'application/json';
    req.headers['Content-Type'] = 'application/json';

    return new Promise((resolve, reject) =>
      fetch(req.url, req).then(
        (response) => {
          if (response.status === 401) {
            dispatch(removeItem('pwpa-token'));

            return window.location.reload();
          }

          if (response.status === 404) {
            return response.json().then(
              (data) => reject({ data, status: response.status }),
              (error) => reject({ error, status: response.status })
            );
          }

          if (response.status >= 200 && response.status < 300) {
            if (method === 'delete') {
              return resolve();
            }

            return response.json().then(
              (data) => resolve(data),
              (error) => reject(error)
            );
          }

          return response.json().then(
            (data) => reject(data),
            (error) => reject(error)
          );
        },
        () => reject({ type: 'error', msg: 'Server Offline' })
      )
    );
  }
}

export default new Api({ host });
