import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { Header } from 'semantic-ui-react';

import { colors } from 'utils/colors';

export default class InvestmentIncomeBar extends Component {
  getChartData = (data) => {
    const validKeys = Object.keys(data).filter((key) => Number(key));

    return {
      labels: validKeys,
      datasets: [
        {
          data: validKeys.map((key) => data[key].endingRRIF),
          borderWidth: 0,
          label: 'RRIF',
          lineTension: 0,
          fill: false,
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          pointRadius: 0,
          pointHitRadius: 5,
        },
        {
          data: validKeys.map((key) => data[key].endingRegular),
          borderWidth: 0,
          label: 'Non-registered',
          lineTension: 0,
          fill: false,
          backgroundColor: colors.red,
          borderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointBorderColor: colors.red,
          pointRadius: 0,
          pointHitRadius: 5,
        },
        {
          data: validKeys.map((key) => data[key].endingTFSA),
          borderWidth: 0,
          label: 'TFSA',
          lineTension: 0,
          fill: false,
          backgroundColor: colors.orange,
          borderColor: colors.orange,
          pointBackgroundColor: colors.orange,
          pointBorderColor: colors.orange,
          pointRadius: 0,
          pointHitRadius: 5,
        },
      ],
    };
  };

  render() {
    const { data, size } = this.props;

    return (
      <div style={{ maxWidth: size, width: size, margin: '2rem auto' }}>
        <Line data={this.getChartData(data)} options={lineChartOptions} />
      </div>
    );
  }
}

InvestmentIncomeBar.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
};

const lineChartOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (label) => `$${label.toLocaleString()}`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${
          data.datasets[tooltipItems.datasetIndex].label
        }: $${tooltipItems.yLabel.toLocaleString()}`,
    },
  },
};
