import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import {
  Accounts,
  CompareAccount,
  CustomAccount,
  Portfolio,
  RebalanceAccount,
  Storage,
} from 'actions';
import { Button, Card, Segment, Loading, Feature } from 'components';
import { Radio, Label, Popup } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { TOOLS_STOCK_EXPOSURE } from 'utils/features';

@connect((state) => ({
  accounts: state.Accounts.accountList,
  isFetching: state.Accounts.isFetching,
  selectedAccountList: state.Accounts.selectedAccountList,
  user_region: state.Storage.user_region,
  overview: state.Accounts.overview,
  exchangeRate: state.Storage['pwpa-exchange-rate'],
  partnerName: state.Storage['partner-name'],
  ui_exposure_checked: state.Storage.ui_exposure_checked,
}))
export default class AccountsList extends Component {
  state = {
    multipleSelect: !!this.props.selectedAccountList.length,
    invalidSubmission: false,
    reminderIsOpen: false,
  };

  componentWillMount = () => {
    const { accounts, dispatch, ui_exposure_checked } = this.props;

    dispatch(Portfolio.setEditing('', '', ''));
    dispatch(CompareAccount.clearResult());
    dispatch(CompareAccount.removeAll());
    dispatch(RebalanceAccount.removeAll());
    dispatch(CustomAccount.clearCustomAccount());
    if (!ui_exposure_checked) {
      this.setReminderVisibility(true);
    }
    if (!accounts.length) {
      dispatch(Accounts.fetchAll());
    }

    return false;
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (!history.location.pathname.includes('/accounts/')) {
      dispatch(Accounts.toggleMultipleMode());
    }
  }

  setReminderVisibility = (isOpen) => this.setState({ reminderIsOpen: isOpen });

  canUseStockExposure = (accountList) => accountList.length > 0;

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  handleReminderClick = () => {
    const { dispatch } = this.props;
    dispatch(Storage.setItem('ui_exposure_checked', true));
    dispatch(Accounts.checkUiExposure());
    this.setState({ reminderIsOpen: false });
  };

  render() {
    const {
      accounts,
      isFetching,
      history,
      dispatch,
      selectedAccountList,
      user_region,
      overview,
      exchangeRate,
      partnerName,
    } = this.props;
    const { multipleSelect, invalidSubmission, reminderIsOpen } = this.state;

    return (
      <Segment style={{ height: '100%', overflow: 'auto' }}>
        <Loading active={isFetching} />
        <StyledHeader>
          My Streamer Portfolios
          <SubHeader>
            Choose a portfolio, or group portfolios together, and then click the
            “Analyze” button at the top right-hand corner to receive the
            Wealthscope Portfolio Scorecard.
          </SubHeader>
          {user_region === 'CA' ? (
            <SubHeader>
              If you are grouping portfolios for analysis, USD portfolios will
              be converted to CAD. Please note that only the following
              securities will be analyzed: Canadian and U.S. stocks, Canadian
              and U.S. ETFs, and Canadian mutual and segregated funds. All other
              securities will be excluded from the analysis.
            </SubHeader>
          ) : null}
        </StyledHeader>
        <div>
          <Paragraph
            style={{ paddingRight: '0.9rem', paddingTop: '0.8rem' }}
            disabled={accounts.length < 2}
          >
            Group portfolios for analysis?
          </Paragraph>
          <ToggleWrapper
            onClick={() => {
              if (accounts.length > 1) {
                this.setState({
                  multipleSelect: !multipleSelect,
                  invalidSubmission: false,
                });
                dispatch(Accounts.toggleMultipleMode());
              }
            }}
            groupDisabled={accounts.length < 2}
          >
            {accounts.length < 2 ? (
              <Popup
                hoverable
                trigger={
                  <CustomRadio
                    toggle
                    checked={multipleSelect}
                    onClick={() => {
                      dispatch(Accounts.saveSingleAccInfo(''));
                    }}
                    disabled
                  />
                }
                position="top center"
                content="You can use this function when you have 2 or more portfolios."
              />
            ) : (
              <CustomRadio
                toggle
                checked={multipleSelect}
                onClick={() => {
                  dispatch(Accounts.saveSingleAccInfo(''));
                }}
              />
            )}
          </ToggleWrapper>
          <Button
            className="left"
            icon="book"
            color={colors.blue}
            onClick={() => {
              if (selectedAccountList.length >= 2) {
                return history.push('/accounts/multiple');
              }
              return this.setState({ invalidSubmission: true });
            }}
            style={{
              display: `${multipleSelect ? 'inline-block' : 'none'}`,
              transform: 'translate(15px, 3px)',
            }}
          >
            View Group
          </Button>
          <Label
            basic
            color="red"
            pointing="left"
            content="Please select at least two portfolios."
            style={{
              display: `${invalidSubmission ? 'inline-block' : 'none'}`,
              transform: 'translate(18px, -3px)',
            }}
          />
          {partnerName === 'quotemedia_pro' || user_region === 'US' ? (
            <Feature name={TOOLS_STOCK_EXPOSURE}>
              <Popup
                className="stockPopup"
                onClick={this.handleReminderClick}
                style={{ position: 'static' }}
                trigger={
                  <div
                    style={{
                      position: 'absolute',
                      top: '2rem',
                      right: '2rem',
                    }}
                  >
                    <Button
                      className={cn('left', {
                        disabled: !this.canUseStockExposure(accounts),
                      })}
                      icon="line chart"
                      onClick={() => {
                        this.handleReminderClick();
                        history.push('/accounts/stock-exposure');
                      }}
                      color={colors.blue}
                      popup={{
                        position: 'left center',
                        content:
                          'This tool requires you to have saved portfolios in Quotestream. Please link your Portfolios to get started.',
                        wide: true,
                      }}
                      style={{ width: '15rem' }}
                    >
                      Stock Exposure Lookup
                    </Button>
                    <span
                      className="notification heartbeat"
                      style={{
                        backgroundColor: `${colors.orange}`,
                        display: `${reminderIsOpen ? 'flex' : 'none'}`,
                      }}
                    >
                      !
                      {/* You can put the notification count or any other content here */}
                    </span>
                  </div>
                }
                position="bottom center"
                open={reminderIsOpen}
              >
                <Popup.Header>New Tool!</Popup.Header>
                <Popup.Content>
                  <br />
                  Use it to discover your exposure to any stock across all of
                  your portfolios. Be sure to go deeper and unveil the breakdown
                  of your direct and indirect (within a fund) holdings.
                  <br />
                  <br />
                  <span
                    style={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Got it.
                  </span>
                </Popup.Content>
              </Popup>
            </Feature>
          ) : null}
        </div>

        {!accounts.length ? null : (
          <Card
            to="/accounts/overview"
            color="red"
            hoverside="orange"
            header="Overview"
            content="Portfolios overview"
            currency={user_region === 'US' ? 'USD' : 'CAD'}
            style={{
              opacity: `${multipleSelect ? '0.4' : '1'}`,
              pointerEvents: `${multipleSelect ? 'none' : 'auto'}`,
              width: '45%',
              margin: '0px 10px 10px 0px',
            }}
            balance={this.parseNum(overview.balance)}
            onClick={() => {
              dispatch(Accounts.saveSingleAccInfo('overview'));
            }}
          />
        )}
        {!accounts.length ? (
          <div>You don't have any saved portfolios</div>
        ) : (
          accounts.map((acc) => (
            <Card
              to={multipleSelect ? '/accounts/user' : `/accounts/${acc.id}`}
              accoundId={acc.id}
              selectable={multipleSelect}
              selected={selectedAccountList.includes(acc)}
              key={acc.id}
              icon="chart line"
              color="red"
              hoverside="orange"
              header={acc.user_institution}
              content={<br />}
              shiftheader="true"
              style={{ width: '45%', margin: '0px 10px 10px 0px' }}
              warning={acc.rebal_alert || acc.rebal_update}
              rebal={acc.rebal_set}
              currency={user_region === 'US' ? 'USD' : acc.currency}
              isActive={() => {
                if (multipleSelect) {
                  return selectedAccountList.includes(acc);
                }

                return false;
              }}
              balance={this.parseNum(acc.balance)}
              onClick={() => {
                dispatch(
                  Accounts.saveSingleAccInfo(
                    acc.id.toString(),
                    acc.user_institution,
                    acc.label
                  )
                );

                if (multipleSelect) {
                  if (selectedAccountList.includes(acc)) {
                    return dispatch(
                      Accounts.deselectMultipleAccount(
                        acc,
                        selectedAccountList,
                        user_region,
                        exchangeRate
                      )
                    );
                  }

                  this.setState({ invalidSubmission: false });
                  return dispatch(
                    Accounts.selectMultipleAccount(
                      acc,
                      selectedAccountList,
                      user_region,
                      exchangeRate
                    )
                  );
                }

                return false;
              }}
            />
          ))
        )}
      </Segment>
    );
  }
}

AccountsList.propTypes = {
  accounts: PropTypes.array,
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  history: PropTypes.object,
  selectedAccountList: PropTypes.array,
  user_region: PropTypes.string,
  overview: PropTypes.object,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  partnerName: PropTypes.string,
  ui_exposure_checked: PropTypes.bool,
};

AccountsList.defaultProps = {
  accounts: [],
  dispatch: () => false,
  isFetching: false,
  history: {},
  selectedAccountList: [],
  user_region: 'CA',
  overview: {},
  exchangeRate: 1,
  partnerName: '',
  ui_exposure_checked: false,
};

const Paragraph = styled.p`
  color: black;
  opacity: 0.9;
  font-size: 1.14rem;
  max-width: 16rem;
  font-weight: 600;
  display: inline-block;
`;

const ToggleWrapper = styled.div`
  display: inline-block;
  transform: translateY(5px);

  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background: ${(props) =>
      props.groupDisabled ? 'rgba(0, 0, 0, 0.3) !important' : ''};
  }

  .ui.toggle.checkbox input:focus:checked~.box:before,
  .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: ${colors.blue} !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: ${colors.blue} !important;
`;

const StyledHeader = styled.div`
  margin: 0 0 1rem 0;
  color: black;
  font-size: 1.5rem;
`;

const SubHeader = styled.div`
  color: rgb(149, 149, 149);
  margin-top: 8px;
  font-size: 1.14rem;
  max-width: 39rem;
`;

const CustomRadio = styled(({ className, children, ...rest }) => (
  <Radio className={className} {...rest}>
    {children}
  </Radio>
))`
  label:before {
    background: rgba(0, 0, 0, 0.3) !important;
  }

  label:hover:before {
    background: rgba(0, 0, 0, 0.2) !important;
  }
`;
