/* eslint-disable consistent-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Dropdown,
  Popup,
  Icon,
  Header,
  Divider,
  Button as SemanticButton,
} from 'semantic-ui-react';
import cn from 'classnames';

import { Planning, Api } from 'actions';
import { colors } from 'utils/colors';
import { Button } from 'components';
import Result from './Result';
import Tip from './Tip';
import TableModal from './TableModal';

class CppEstimator extends Component {
  state = {
    isDisclaimerOpen: false,
    tableData: [],
  };

  componentDidMount = () => {
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown || {};
    const {
      years_contrib,
      years_contrib2,
      primary_caregiver,
      primary_caregiver2,
      years_primary_caregiver,
      years_primary_caregiver2,
      is_earning_over_average,
      is_earning_over_average2,
      perc_under_average,
      perc_under_average2,
      cppAge,
      cppAge2,
      activeSpouse,
      age,
      age2,
    } = inputs || {};

    dispatch(Api.request('get', `/planning/average-earnings/`)).then((data) => {
      this.setState({ tableData: data });
    });

    return dispatch(
      Planning.cppUpdateInput({
        inputs: {
          cppAge,
          cppAge2,
          yearsWorked: years_contrib,
          yearsWorked2: years_contrib2,
          caregiver: primary_caregiver || false,
          caregiver2: primary_caregiver2 || false,
          yearsCare: years_primary_caregiver,
          yearsCare2: years_primary_caregiver2,
          age,
          age2,
          activeSpouse,
          exceedIncome: is_earning_over_average,
          exceedIncome2: is_earning_over_average2,
          exceedIncomeBy: perc_under_average && perc_under_average.toString(),
          exceedIncomeBy2:
            perc_under_average2 && perc_under_average2.toString(),
        },
      })
    );
  };

  componentWillUnmount = () => this.props.dispatch(Planning.cppClearErrors());

  parseNum = (num) => num.toLocaleString(undefined, { maxFractionDigits: 2 });

  changeSelection = (name, val) => {
    const { dispatch, cppEstimator } = this.props;
    const { inputs } = cppEstimator;
    const field =
      inputs.activeSpouse === 'spouse2' && !name.endsWith('2')
        ? `${name}2`
        : name;

    return dispatch(
      Planning.cppUpdateInput({
        inputs: { [field]: val },
      })
    );
  };

  handleSecondChange = (e, item) => {
    const { dispatch, cppEstimator } = this.props;
    const { inputs } = cppEstimator;
    const field =
      inputs.activeSpouse === 'spouse2'
        ? 'pct_over_average2'
        : 'pct_over_average';

    if (item) {
      return dispatch(
        Planning.cppUpdateInput({
          inputs: { [field]: item.value },
          errors: { [field]: false },
        })
      );
    }
  };

  handleChange = (e, semantic) => {
    const { cppEstimator, dispatch } = this.props;
    const { inputs } = cppEstimator;

    if (semantic) {
      const field =
        inputs.activeSpouse === 'spouse2'
          ? 'exceedIncomeBy2'
          : 'exceedIncomeBy';
      return dispatch(
        Planning.cppUpdateInput({
          inputs: { [field]: semantic.value },
          errors: { [field]: false },
        })
      );
    }

    const { name, value } = e.target;
    const field =
      inputs.activeSpouse === 'spouse2' && !name.endsWith('2')
        ? `${name}2`
        : name;
    const updatedInputs = { ...inputs, [field]: value };

    // Calculate the retirement year
    const currentYear = new Date().getFullYear();
    const retirementYear =
      currentYear +
      (inputs.activeSpouse === 'spouse2'
        ? updatedInputs.cppAge2
        : updatedInputs.cppAge) -
      (inputs.activeSpouse === 'spouse2'
        ? updatedInputs.age2
        : updatedInputs.age);

    if (name === 'cppAge' || name === 'cppAge2') {
      if (value > 70 || value < 60) {
        return dispatch(
          Planning.cppUpdateInput({
            inputs: updatedInputs,
            errors: { ...inputs.errors, [field]: true },
            result: null,
          })
        );
      }
    }

    return dispatch(
      Planning.cppUpdateInput({
        inputs: updatedInputs,
        errors: { ...inputs.errors, [field]: false },
        result: null,
      })
    );
  };

  submit = (e) => {
    e.preventDefault();
    const { cppEstimator, dispatch } = this.props;
    const { inputs } = cppEstimator;
    const currentAge =
      inputs.activeSpouse === 'spouse2' ? inputs.age2 : inputs.age;
    const currentYear = new Date().getFullYear();
    const retirementYear =
      Number(currentYear) +
      Number(
        (inputs.activeSpouse === 'spouse2'
          ? Number(inputs.cppAge2)
          : Number(inputs.cppAge)) - Number(currentAge)
      );
    const required = [
      inputs.activeSpouse === 'spouse2' ? 'cppAge2' : 'cppAge',
      inputs.activeSpouse === 'spouse2' ? 'yearsWorked2' : 'yearsWorked',
      inputs.exceedIncome
        ? null
        : inputs.activeSpouse === 'spouse2'
        ? 'exceedIncomeBy2'
        : 'exceedIncomeBy',
      inputs.caregiver
        ? inputs.activeSpouse === 'spouse2'
          ? 'yearsCare2'
          : 'yearsCare'
        : null,
      inputs.exceedIncome &&
      retirementYear >= 2026 &&
      !inputs.is_earning_14pct_higher
        ? inputs.activeSpouse === 'spouse2'
          ? 'pct_over_average2'
          : 'pct_over_average'
        : 0,
    ];
    const errors = required.reduce((acc, val) => {
      if (val && !inputs[val]) {
        acc[val] = true;
      }

      if (
        val === 'cppAge' &&
        (Number(inputs[val]) < 60 || Number(inputs[val]) > 70)
      ) {
        acc[val] = true;
      }

      return acc;
    }, {});
    const hasError = Object.values(errors).find((val) => val);

    if (hasError) {
      return dispatch(Planning.cppUpdateInput({ errors }));
    }

    const body = {
      retirement_year: retirementYear,
      start_cpp_age:
        inputs.activeSpouse === 'spouse2' ? inputs.cppAge2 : inputs.cppAge,
      years_contrib:
        inputs.activeSpouse === 'spouse2'
          ? inputs.yearsWorked2
          : inputs.yearsWorked,
      primary_caregiver:
        inputs.activeSpouse === 'spouse2'
          ? inputs.caregiver2
          : inputs.caregiver,
      years_primary_caregiver:
        inputs.activeSpouse === 'spouse2' && inputs.caregiver2
          ? inputs.yearsCare2
          : inputs.caregiver && inputs.activeSpouse === 'spouse'
          ? inputs.yearsCare
          : 0,
      is_earning_over_average:
        inputs.activeSpouse === 'spouse2'
          ? inputs.exceedIncome2
            ? inputs.exceedIncome2
            : false
          : inputs.exceedIncome
          ? inputs.exceedIncome
          : false,
      pct_under_average: inputs.exceedIncome
        ? 0
        : Number(
            inputs.activeSpouse === 'spouse2'
              ? inputs.exceedIncomeBy2
              : inputs.exceedIncomeBy
          ),
      is_earning_14pct_higher: !!inputs.is_earning_14pct_higher,
      pct_over_average:
        inputs.is_earning_14pct_higher ||
        !inputs.exceedIncome ||
        (inputs.activeSpouse === 'spouse2'
          ? !inputs.pct_over_average2
          : !inputs.pct_over_average)
          ? 0
          : Number(
              inputs.activeSpouse === 'spouse2'
                ? inputs.pct_over_average2
                : inputs.pct_over_average
            ),
    };

    return dispatch(Planning.calcCpp({ ...body }));
  };

  render() {
    const { dispatch, cppEstimator } = this.props;
    const { isFetching, result, inputs, errors } = cppEstimator;
    const { isDisclaimerOpen, tableData } = this.state;
    const currentAge =
      inputs.activeSpouse === 'spouse2' ? inputs.age2 : inputs.age;
    const currentYear = new Date().getFullYear();
    const retirementYear =
      Number(currentYear) +
      Number(
        (inputs.activeSpouse === 'spouse2'
          ? Number(inputs.cppAge2)
          : Number(inputs.cppAge)) - Number(currentAge)
      );
    const lastYearAverage = tableData.length
      ? tableData[tableData.length - 1].earning +
        tableData[tableData.length - 1].earning * 0.14
      : 0;

    return (
      <div>
        <Header
          content="CPP/QPP Estimator"
          size="large"
          textAlign="center"
          style={{ marginBottom: '2rem' }}
        />

        <Header textAlign="center" style={{ marginTop: '2rem' }}>
          <Header.Subheader>
            <P>There are two scenarios you should be aware of:</P>
            <P title="true">Reduced benefits</P>
            <P style={{ textAlign: 'left' }}>
              If you start collecting CPP/QPP benefits prior to age 65 (but not
              before age 60), your benefits are reduced by 0.6% per month, or
              7.2% per year compared to the standard benefits at age 65.
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="bottom center"
                wide
                content="This means that an individual who starts receiving CPP/QPP benefits at age 60 will receive 36% (7.2% x 5) less per year for life than if it was taken at age 65."
              />
            </P>
            <P title="true">Increased benefits</P>
            <P style={{ textAlign: 'left' }}>
              If you start after age 65 (but before age 70), your benefits are
              increased by 0.8% per month, or 8.4% per year compared to the
              standard benefits at age 65.
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="bottom center"
                wide
                content="This means that an individual who starts receiving CPP/QPP benefits at age 70 will receive 42% (8.4% x 5) more per year for life than if it was taken at age 65."
              />
            </P>
            <Divider />
          </Header.Subheader>
        </Header>

        <Header textAlign="center">
          <Header.Subheader>
            <P style={{ textAlign: 'left' }}>
              You do not have to start collecting CPP/QPP benefits at the same
              time you retire - you can delay it if you wish. If you would like
              more information regarding this decision, read a short article -{' '}
              <a
                href="https://wealthscope.ca/cpp-benefits"
                target="_blank"
                rel="noopener noreferrer"
              >
                CPP/QPP Benefits: To Defer or Not to Defer
              </a>
              .
            </P>
          </Header.Subheader>
        </Header>
        <Divider />
        <CollapseWrapper open={isDisclaimerOpen}>
          <Header
            size="medium"
            content="Disclaimer"
            style={{
              position: 'absolute',
              top: isDisclaimerOpen ? '-11px' : '3px',
              left: '.5rem',
              background: 'white',
              transition: 'all 300ms ease',
              padding: '0 0.75rem 0 .5rem',
            }}
          >
            <StyledButton
              icon
              onClick={() =>
                this.setState({ isDisclaimerOpen: !isDisclaimerOpen })
              }
              isOpen={isDisclaimerOpen}
            >
              {isDisclaimerOpen ? '-' : '+'}
            </StyledButton>{' '}
            Disclaimer
          </Header>

          <div>
            <ul style={{ margin: '2rem auto' }}>
              <li>
                This estimator is provided for your convenience while using
                Wealthscope. Because it is based on a limited set of information
                we collect from you, it is only a rough estimate. For a more
                accurate view, please log into your{' '}
                <a
                  href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  My Service Canada Account
                </a>
                , where you will be able to see your expected CPP/QPP benefits,
                calculated based on the actual history of your pensionable
                earnings.
              </li>
              <li>
                This estimator works best for individuals who have had a
                relatively stable career in Canada. If your earnings during your
                working years had been below the national averages (see{' '}
                <TableModal setTableOpen={this.props.setTableOpen} />) and had
                fluctuated considerably, this estimator is not suitable for you.
              </li>
            </ul>
          </div>
        </CollapseWrapper>

        <Form>
          <Section left>
            <OL compact>
              <li>
                At what age do you plan to start collecting CPP/QPP?
                <Input
                  className={cn({ invalid: errors.cppAge })}
                  name={
                    inputs.activeSpouse === 'spouse2' ? 'cppAge2' : 'cppAge'
                  }
                  type="number"
                  placeholder="years"
                  value={
                    inputs.activeSpouse === 'spouse2'
                      ? inputs.cppAge2 || ''
                      : inputs.cppAge || ''
                  }
                  onChange={this.handleChange}
                />
                {errors.cppAge && (
                  <Error>
                    You can only start collecting CPP/QPP benefits between 60
                    and 70
                  </Error>
                )}
              </li>
              <li>
                How many years will you have worked and contributed to the
                CPP/QPP by the time you retire, or until age 65, whichever is
                earlier?
                <Input
                  className={cn({ invalid: errors.yearsWorked })}
                  name={
                    inputs.activeSpouse === 'spouse2'
                      ? 'yearsWorked2'
                      : 'yearsWorked'
                  }
                  type="number"
                  placeholder="years"
                  value={
                    inputs.activeSpouse === 'spouse2'
                      ? inputs.yearsWorked2 || ''
                      : inputs.yearsWorked || ''
                  }
                  onChange={this.handleChange}
                />
                {errors.yearsWorked && (
                  <Error>
                    Has to be less than /QPP age or 65 (whichever is less)
                  </Error>
                )}
              </li>
              <li>
                If you have children, were you the primary caregiver and stayed
                at home (or worked substantially less) when your kids were under
                the age of 7?
                <Popup
                  hoverable
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content={
                    <div>
                      If so, you can apply for the{' '}
                      <a
                        href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/child-rearing.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Child-Rearing Provision
                      </a>
                      , which allows you to exclude those low earning years from
                      your CPP/QPP benefits calculation
                    </div>
                  }
                  wide
                />
                <Selection>
                  <span
                    className={cn({
                      active:
                        inputs.activeSpouse === 'spouse2'
                          ? inputs.caregiver2
                          : inputs.caregiver,
                    })}
                    onClick={() => this.changeSelection('caregiver', true)}
                  >
                    Yes
                  </span>
                  <span
                    className={cn({
                      active:
                        inputs.activeSpouse === 'spouse2'
                          ? !inputs.caregiver2
                          : !inputs.caregiver,
                    })}
                    onClick={() => this.changeSelection('caregiver', false)}
                  >
                    No
                  </span>
                </Selection>
                <Transition
                  visible={
                    inputs.activeSpouse === 'spouse2'
                      ? inputs.caregiver2
                      : inputs.caregiver
                  }
                >
                  How many years were your earnings affected?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="For example, if you have two children and they are two years apart, you have a total of 9 years when you had children under the age of 7 at home. In this case, the maximum number of years you can apply for is 9, under the child­rearing provision."
                    wide
                  />
                  <Input
                    className={cn({ invalid: errors.yearsCare })}
                    name={
                      inputs.activeSpouse === 'spouse2'
                        ? 'yearsCare2'
                        : 'yearsCare'
                    }
                    type="number"
                    placeholder="years"
                    value={
                      inputs.activeSpouse === 'spouse2'
                        ? inputs.yearsCare2 || ''
                        : inputs.yearsCare || ''
                    }
                    onChange={this.handleChange}
                  />
                  {errors.yearsCare && (
                    <Error>
                      Child-rearing years have to be less than CPP/QPP
                      contributory years
                    </Error>
                  )}
                </Transition>
              </li>
            </OL>
          </Section>
          <Section right>
            <OL start="4">
              <li>
                Starting from age 26 (and excluding the years you stayed at home
                with your children), would you say that your Canadian employment
                earnings meet or exceed the national averages (see{' '}
                <TableModal setTableOpen={this.props.setTableOpen} />
                )?
                <Tip content="If you’re working beyond age 65 and have earnings at or above the current national average in the table, you can replace an equal number of lower earnings years with these higher after-­65 earnings, for the purpose of answering this question." />
                <Tip content="If you start collecting CPP/QPP after age 65, and have low or zero income between ages 65 and 70, these post-­65 years can also be dropped for the purpose of the CPP/QPP calculations." />
                <Selection>
                  <span
                    className={cn({
                      active:
                        inputs.activeSpouse === 'spouse2'
                          ? inputs.exceedIncome2
                          : inputs.exceedIncome,
                    })}
                    onClick={() => this.changeSelection('exceedIncome', true)}
                  >
                    Yes
                  </span>
                  <span
                    className={cn({
                      active:
                        inputs.activeSpouse === 'spouse2'
                          ? !inputs.exceedIncome2
                          : !inputs.exceedIncome,
                    })}
                    onClick={() => this.changeSelection('exceedIncome', false)}
                  >
                    No
                  </span>
                </Selection>
                <Transition
                  visible={
                    inputs.activeSpouse === 'spouse2'
                      ? !inputs.exceedIncome2
                      : !inputs.exceedIncome
                  }
                >
                  By how much – on average over your working years – were your
                  annual earnings below the national averages? If you are not
                  sure, focus on the last few years.
                  <StyledDropdown
                    className={cn({ invalid: errors.exceedIncomeBy })}
                    placeholder="annual earnings"
                    selection
                    upward
                    options={[
                      { key: '10', value: '10', text: 'Lower by 10%' },
                      { key: '20', value: '20', text: 'Lower by 20%' },
                      { key: '30', value: '30', text: 'Lower by 30%' },
                      { key: '40', value: '40', text: 'Lower by 40%' },
                      { key: '50', value: '50', text: 'Lower by 50%' },
                    ]}
                    value={
                      inputs.activeSpouse === 'spouse2'
                        ? inputs.exceedIncomeBy2 || ''
                        : inputs.exceedIncomeBy || ''
                    }
                    onChange={this.handleChange}
                  />
                </Transition>
                <Transition
                  visible={
                    (inputs.activeSpouse === 'spouse2'
                      ? inputs.exceedIncome2
                      : inputs.exceedIncome) && retirementYear >= 2026
                  }
                >
                  Do you expect your earnings in 2025 and beyond to be higher
                  than ${this.parseNum(lastYearAverage)} (estimated national
                  average plus 14%, which is the higher earnings ceiling for the
                  CPP enhancement) in today's dollars?
                  <Selection>
                    <span
                      className={cn({
                        active:
                          inputs.activeSpouse === 'spouse2'
                            ? inputs.is_earning_14pct_higher2
                            : inputs.is_earning_14pct_higher,
                      })}
                      onClick={() =>
                        this.changeSelection('is_earning_14pct_higher', true)
                      }
                    >
                      Yes
                    </span>
                    <span
                      className={cn({
                        active:
                          inputs.activeSpouse === 'spouse2'
                            ? !inputs.is_earning_14pct_higher2
                            : !inputs.is_earning_14pct_higher,
                      })}
                      onClick={() =>
                        this.changeSelection('is_earning_14pct_higher', false)
                      }
                    >
                      No
                    </span>
                  </Selection>
                </Transition>
                <Transition
                  visible={
                    (inputs.activeSpouse === 'spouse2'
                      ? inputs.exceedIncome2
                      : inputs.exceedIncome) &&
                    retirementYear >= 2026 &&
                    (inputs.activeSpouse === 'spouse2'
                      ? !inputs.is_earning_14pct_higher2
                      : !inputs.is_earning_14pct_higher)
                  }
                >
                  If you do not expect your earnings to be higher than the
                  estimated national average (in today's dollars) by the full
                  14%, is it closest to
                  <StyledDropdown
                    className={cn({ invalid: errors.pct_over_average })}
                    placeholder="percent over average"
                    selection
                    options={[
                      { key: '5', value: '5', text: '5% higher' },
                      { key: '10', value: '10', text: '10% higher' },
                    ]}
                    value={
                      inputs.activeSpouse === 'spouse2'
                        ? inputs.pct_over_average2
                        : inputs.pct_over_average
                    }
                    onChange={this.handleSecondChange}
                  />
                </Transition>
              </li>
            </OL>
          </Section>

          <div
            style={{ textAlign: 'center', clear: 'both', margin: '2rem auto' }}
          >
            <Result
              cpp={result}
              style={{
                clear: 'both',
                maxWidth: '500px',
                margin: '0 auto 1rem auto',
              }}
            />
          </div>
          <Button
            className={cn('left', { loading: isFetching })}
            color={result ? colors.green : colors.blue}
            icon={result ? 'checkmark' : 'calculator'}
            onClick={
              result
                ? () => {
                    this.props.updateCppInfo(
                      inputs.activeSpouse === 'spouse2'
                        ? inputs.cppAge2
                        : inputs.cppAge,
                      Math.round(result)
                    );
                    this.props.toggle(false);

                    dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          years_contrib:
                            inputs.activeSpouse === 'spouse2'
                              ? inputs.yearsWorked2
                              : inputs.yearsWorked,
                          primary_caregiver:
                            inputs.activeSpouse === 'spouse2'
                              ? inputs.caregiver2
                              : inputs.caregiver,
                          years_primary_caregiver:
                            inputs.activeSpouse === 'spouse2'
                              ? inputs.yearsCare2
                              : inputs.yearsCare,
                          is_earning_over_average:
                            inputs.activeSpouse === 'spouse2'
                              ? inputs.exceedIncome2
                              : inputs.exceedIncome,
                          perc_under_average:
                            inputs.activeSpouse === 'spouse2'
                              ? inputs.exceedIncomeBy2
                              : inputs.exceedIncomeBy,
                        },
                      })
                    );
                  }
                : this.submit
            }
            style={{ display: 'block', margin: '0 auto', clear: 'both' }}
          >
            {result ? 'Confirm' : 'Calculate'}
          </Button>
        </Form>
      </div>
    );
  }
}

CppEstimator.propTypes = {
  dispatch: PropTypes.func,
  cppEstimator: PropTypes.object,
  drawdown: PropTypes.object,
  updateCppInfo: PropTypes.func,
  toggle: PropTypes.func,
  setTableOpen: PropTypes.bool,
};

CppEstimator.defaultProps = {
  dispatch: () => false,
  cppEstimator: {},
  drawdown: {},
  updateCppInfo: () => false,
  toggle: () => false,
  setTableOpen: false,
};

export default connect((state) => ({
  cppEstimator: state.Planning.cppEstimator,
  drawdown: state.Planning.drawdown,
}))(CppEstimator);

const Form = styled.form`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const Input = styled.input`
  display: block;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }
`;

const OL = styled.ol`
  padding: ${(props) => (props.compact ? '0' : '0 0 0 20px')};
  margin: 0;
`;

const Section = styled.section`
  width: 50%;
  float: ${(props) => (props.left ? 'left' : 'right')};
  padding: ${(props) => (props.left ? '0 20px' : '0')};
  margin: 1rem 0;
`;

const Selection = styled.div`
  text-align: left;
  margin: 1rem 0;

  span {
    padding: 0.3rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    border: 1px solid #dcdcdc;
    user-select: none;
    color: black;

    &.active {
      background-color: ${colors.blue};
      border: 1px solid transparent;
      color: white;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      border: 1px solid ${colors.blue};
    }
  }
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: unset !important;
  min-height: unset !important;
  min-width: unset !important;
  padding: 0.7rem !important;
  margin: 0.5rem 0 !important;
  font-size: 0.9rem !important;
  width: 100%;

  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }

  i {
    padding: 0.7rem !important;
  }
`;

const Transition = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  transition: opacity 200ms ease, transform 300ms ease;
`;

const Error = styled.div`
  color: red;
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${(props) => (props.open ? '0rem 1rem 0' : '2rem 1rem 0')};
  margin: 1.5rem auto 0;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '2000px' : '28px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const StyledButton = styled(SemanticButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;

const P = styled.p`
  margin: ${(props) => (props.title ? '1rem 0 0.5rem 0' : '0 0 0.5rem 0')};
  line-height: 1.3rem;
  font-style: ${(props) => (props.title ? 'italic' : 'normal')};
  color: ${(props) => (props.title ? 'black' : 'inherit')};
`;
