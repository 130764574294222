/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Icon, Header, Label } from 'semantic-ui-react';
import {
  Security,
  FundComparison as FundComparisonActions,
  ChartTool,
} from 'actions';
import cn from 'classnames';

import { Button, Segment, SearchTicker } from 'components';
import { colors, chartingToolColors } from 'utils/colors';
import Fade from 'react-reveal/Fade';
import { maxNumberOfFundsToCompare } from 'utils/constants';
import ComparisonContent from './ComparisonContent';

class FundComparison extends Component {
  state = { headerFixed: false };

  componentDidMount = () => {
    const { securityTickerList } = this.props;

    window.addEventListener('scroll', this.handleScroll, true);

    if (securityTickerList && Object.keys(securityTickerList).length) {
      this.drawChart();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { securityTickerList, fundComparisonState } = this.props;

    if (
      JSON.stringify(prevProps.fundComparisonState) !==
        JSON.stringify(fundComparisonState) &&
      securityTickerList.length
    ) {
      this.drawChart();
    }
  };

  componentWillUnmount = () => {
    window.addEventListener('scroll');
  };

  getResults = () => {
    const { securityTickerList } = this.props;
    const { search } = this.props.security;

    return search.reduce((total, security) => {
      if (security) {
        if (!total[security.asset_type]) {
          total[security.asset_type] = {
            name: security.asset_type,
            results: [
              {
                title: security.ticker,
                description: security.long_name,
                price: security.exchange_name,
              },
            ],
          };
        } else {
          total[security.asset_type].results.push({
            title: security.ticker,
            description: security.long_name,
            price: security.exchange_name,
          });
        }
      }

      if (total.ETF !== undefined) {
        const etfArray = total.ETF.results;
        const filteredETFs = etfArray.filter(
          (securityItem) => !securityTickerList.includes(securityItem.title)
        );
        total.ETF.results = filteredETFs;
      }

      if (total.Stock !== undefined) {
        const stockArray = total.Stock.results;

        const filteredStocks = stockArray.filter(
          (securityItem) => !securityTickerList.includes(securityItem.title)
        );
        total.Stock.results = filteredStocks;
      }
      return total;
    }, {});
  };

  generatePDF = () => {
    const { history } = this.props;

    return history.push('/tools/fund-comparison/pdf');
  };

  nameForUsVersion = (name) => {
    if (name === 'WS All-In') {
      return '100 Equities / 0 Bonds';
    } else if (name === 'WS Adventurous') {
      return '80 Equities / 20 Bonds';
    } else if (name === 'WS Classic') {
      return '60 Equities / 40 Bonds';
    } else if (name === 'WS Cautious') {
      return '40 Equities / 60 Bonds';
    } else if (name === 'WS Playing-It-Safe') {
      return '20 Equities / 80 Bonds';
    }

    return name;
  };

  handleSearchChange = (value) => {
    const { dispatch } = this.props;

    return (
      !!value.trim() &&
      dispatch(Security.search(window.encodeURIComponent(value)))
    );
  };

  handleDeletion = (ticker) => {
    const { dispatch } = this.props;
    dispatch(FundComparisonActions.deleteFund(ticker));
  };

  handleSearchResultSelect = (ticker) => {
    const { dispatch, securityTickerList } = this.props;
    if (securityTickerList.length < maxNumberOfFundsToCompare) {
      dispatch(FundComparisonActions.addFund(ticker));
    }
  };

  // CHART METHODS
  drawChart = () => {
    const { dispatch, securityTickerList, graphParams, chartErrorList } =
      this.props;

    dispatch(
      ChartTool.fetchChartData(
        {
          securityList: securityTickerList.filter(
            (s) => !chartErrorList.has(s)
          ),
          portfolioList: [],
          periodStart: graphParams.startDate,
          periodEnd: graphParams.endDate,
        },
        {}
      )
    );
  };

  updateStartEndDate = (startDate, endDate) => {
    const { dispatch } = this.props;
    dispatch(FundComparisonActions.updateGraphDates({ startDate, endDate }));
  };

  // END CHART METHODS

  handleScroll = () => {
    if (!this.props.securityTickerList.length) return;
    if (window.scrollY >= 88) {
      this.setState({ headerFixed: true });
    } else {
      this.setState({ headerFixed: false });
    }
  };

  render() {
    const {
      security,
      securityTickerList,
      securityData,
      user_region,
      partnerName,
      history,
    } = this.props;
    const showcomparisoncontent = securityTickerList.length >= 1;

    return (
      <Segment
        margin="1rem"
        padding="0rem"
        textAlign="center"
        style={{
          position: 'relative',
          minHeight: '700px',
        }}
      >
        <HeaderContainer
          showcomparisoncontent={showcomparisoncontent}
          scrollYPos={window.scrollY}
          headerFixed={this.state.headerFixed}
        >
          <Header
            size="large"
            textAlign="center"
            content="Fund Comparison"
            subheader="Compare up to four ETFs and/or mutual funds."
          />
          <Note>
            {user_region === 'CA'
              ? 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'
              : 'Note: Currently we only have data for US-listed stocks and ETFs, and US mutual funds.'}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
            />
            Always check the fund code or ticker symbol to ensure you have the
            correct share class. Use US instead of U.S..
          </Note>

          {showcomparisoncontent ? (
            <Button
              className={cn('left')}
              icon="file text"
              color={colors.darkGrey}
              onClick={this.generatePDF}
              style={{
                position: 'absolute',
                top: '1rem',
                right: '2rem',
                zIndex: '999',
              }}
            >
              Print/PDF
            </Button>
          ) : null}

          <div style={{ position: 'relative' }}>
            <SearchTicker
              loading={security.isFetching}
              results={this.getResults()}
              customResultSelect={this.handleSearchResultSelect}
              customSearchChange={this.handleSearchChange}
              setMargin
              style={{
                display: 'inline-block',
                margin: '0.7rem',
                textAlign: 'left',
              }}
            />

            <Labels style={{ position: 'relative', height: '2rem' }}>
              {securityTickerList.map((item, index) => (
                <Label
                  style={{
                    backgroundColor: Object.values(chartingToolColors)[index],
                    color: 'white',
                  }}
                  key={index}
                >
                  {item}{' '}
                  <Icon
                    name="delete"
                    link
                    onClick={() => this.handleDeletion(item)}
                  />
                </Label>
              ))}
            </Labels>
          </div>
        </HeaderContainer>
        {showcomparisoncontent && (
          <Fade duration={3500}>
            <ContentContainer>
              <ComparisonContent
                securityTickerList={securityTickerList}
                securityData={securityData}
                user_region={user_region}
                partnerName={partnerName}
                chartUpdateStartEndDate={this.updateStartEndDate}
                isLoading
                history={history}
              />
            </ContentContainer>
          </Fade>
        )}
      </Segment>
    );
  }
}

const HeaderContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  height: 100px;
  position: ${(props) => (props.headerFixed ? 'fixed' : 'absolute')};
  left: 50%;
  top: ${(props) => (props.headerFixed ? '4rem' : '2rem')};
  transform: ${(props) =>
    !props.showcomparisoncontent
      ? 'translate(-50%, 180%)'
      : 'translate(-50%, 0)'};
  transition: transform 1s;
  background-color: white;
  z-index: 2;
  width: 100%;
  width: ${(props) => (props.headerFixed ? 'calc(100% - 2rem)' : '100%')};
`;

const ContentContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  // display: ${(props) => (!props.showcomparisoncontent ? 'none' : 'block')};
  margin-top: 200px;
`;

const Labels = styled.div`
  height: 1rem;
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 1rem auto 0rem auto;
  max-width: 55rem;
`;

FundComparison.propTypes = {
  dispatch: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.array.isRequired,
  graphParams: PropTypes.object.isRequired,
  fundComparisonState: PropTypes.object.isRequired,
  history: PropTypes.object,
  chartErrorList: PropTypes.object,
  partnerName: PropTypes.string,
};
FundComparison.defaultProps = {
  user_region: 'CA',
  history: {},
  chartErrorList: new Set(),
  partnerName: '',
};

export default connect((state) => ({
  security: state.Security,
  errorEntity:
    state.ChartTool.errorEntity !== undefined
      ? state.ChartTool.errorEntity
      : '',
  user_region: state.Storage.user_region || 'CA',
  user: state.Auth.user,
  fundComparisonState: state.FundComparison,
  securityData: state.FundComparison.securityData,
  securityTickerList: Object.keys(state.FundComparison.securityData || {}),
  graphParams: state.FundComparison.graph,
  chartErrorList: state.FundComparison.chartErrorList,
  partnerName: state.Storage['partner-name'],
}))(FundComparison);
