import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter, NavLink, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { BuildETF } from 'actions';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import ETFAnalytics from './Analytics';
import History from './History';
import SelectAssets from './SelectAssets';
import AllocateWeights from './AllocateWeights';

@withRouter
class BuildETFPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historySubmitted: false, // Add a state variable to track History tab submission
    };
  }

  componentWillMount() {
    const { dispatch, match, pwpaPortfolio, selectedAssets } = this.props;
    if (Number(match.params.id)) {
      return dispatch(BuildETF.fetchId(match.params.id));
    }
    if (Object.keys(selectedAssets).length) {
      return dispatch(BuildETF.fetchParams({ selectedAssets }));
    }

    return dispatch(BuildETF.fetchParams(pwpaPortfolio));
  }

  goBack = (tab) => {
    const { history, match } = this.props;

    return history.push(`/portfolio/build-etf/${match.params.id}/${tab}`);
  };

  disableResult = () => {
    this.setState({ historySubmitted: false });
  };

  enableResult = () => {
    this.setState({ historySubmitted: true });
  };

  checkDisabled = (tab) => {
    const { selectedAssets } = this.props;
    const totalWeight = Object.values(selectedAssets).reduce(
      (result, item) => (result += item.weight),
      0
    );

    if (tab === 'weights' && !Object.keys(selectedAssets).length) {
      return true;
    }

    if (tab === 'history' && totalWeight !== 100) {
      return true;
    }

    if (tab === 'results' && !this.state.historySubmitted) {
      return true;
    }

    return false;
  };

  render() {
    const { partnerName } = this.props;
    const { id } = this.props.match.params;

    return (
      <Container>
        <div
          style={{
            border: isGlobeInvestor(partnerName) ? '1px solid #e5e5e5' : 'none',
          }}
        >
          <div
            style={{
              position: 'relative',
            }}
          >
            <Tab
              to={`/portfolio/build-etf/${id}/assets`}
              globe={isGlobeInvestor(partnerName)}
            >
              Select Assets
            </Tab>
            <Tab
              disabled={this.checkDisabled('weights')}
              to={`/portfolio/build-etf/${id}/weights`}
              globe={isGlobeInvestor(partnerName)}
            >
              Allocated Weights
            </Tab>
            <Tab
              disabled={this.checkDisabled('history')}
              to={`/portfolio/build-etf/${id}/history`}
              globe={isGlobeInvestor(partnerName)}
            >
              History
            </Tab>
            {isGlobeInvestor(partnerName) && (
              <Tab
                disabled={
                  this.checkDisabled('weights') ||
                  this.checkDisabled('history') ||
                  this.checkDisabled('results')
                }
                to={`/portfolio/build-etf/${id}/analytics/overview`}
                globe={isGlobeInvestor(partnerName)}
              >
                Results
              </Tab>
            )}
          </div>

          <Switch>
            <Route
              path="/portfolio/build-etf/:id/assets"
              component={SelectAssets}
            />
            <Route
              path="/portfolio/build-etf/:id/weights"
              render={(props) => (
                <AllocateWeights
                  goBack={() => this.goBack('assets')}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/build-etf/:id/history"
              render={(props) => (
                <History
                  disableResult={this.disableResult}
                  enableResult={this.enableResult}
                  goBack={() => this.goBack('weights')}
                  {...props}
                  match={this.props.match}
                />
              )}
            />
            <Route
              path="/portfolio/build-etf/:id/analytics"
              render={(props) => (
                <ETFAnalytics
                  {...props}
                  history={this.props.history}
                  location={this.props.location}
                  match={this.props.match}
                />
              )}
            />
          </Switch>
        </div>
      </Container>
    );
  }
}

BuildETFPortfolio.propTypes = {
  selectedAssets: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  pwpaPortfolio: PropTypes.object,
  partnerName: PropTypes.string,
};

BuildETFPortfolio.defaultProps = {
  pwpaPortfolio: null,
  partnerName: '',
};

export default connect((state) => ({
  selectedAssets: state.BuildETF.selectedAssets,
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
  partnerName: state.Storage['partner-name'],
}))(BuildETFPortfolio);

const Container = styled.div`
  border-radius: 8px;
  background: white;
  margin: 1rem;
`;

const Tab = styled(({ className, children, ...rest }) => (
  <NavLink className={className} {...rest}>
    {children}
  </NavLink>
))`
  display: inline-block;
  padding: 1rem 0;
  width: ${(props) => (props.globe ? 'calc(100% / 4)' : 'calc(100% / 3)')};
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  color: ${colors.black};
  text-align: center;
  border-left: 1px solid rgb(236, 236, 240);
  border-bottom: 1px solid rgb(236, 236, 240);

  &:first-child {
    border-left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
  }

  &.active {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
    background: rgb(246, 246, 250);

    &:after {
      background: ${colors.red};
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      color: rgba(0, 0, 0, 0.25);
      pointer-events: none;
    `}

  &:hover {
    color: rgba(0, 0, 0, 0.8);
    background: rgb(249, 249, 253);
  }
`;
