import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Segment, ErrorLabel } from 'components';
import { CacheInputs } from 'actions';
import { isGlobeInvestor } from 'utils/helpers';
import RiskProfile from './RiskProfile';
import ExcludeSector from './ExcludeSector';
import OilExposure from './OilExposure';
import InterestRisk from './InterestRisk';
import Reit from './Reit';
import ForeignMarkets from './ForeignMarkets';
import pData from '../../../Portfolio/FindETF/static/portfolio-data.json';
import ETFAnalytics from './Analytics';

class FindETF extends Component {
  componentWillUnmount = () => clearTimeout(this.msgId);

  updateSelection = (updateValue) => {
    const { dispatch, smartRiskBuilder } = this.props;
    const { activeIndex, selection } = smartRiskBuilder;

    // Clear selections in tabs after the current active tab
    const clearedSelection = Object.keys(selection).reduce(
      (acc, key, index) => {
        if (index >= activeIndex) {
          acc[key] = null;
        } else {
          acc[key] = selection[key];
        }
        return acc;
      },
      {}
    );

    return dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        ...smartRiskBuilder,
        selection: { ...clearedSelection, ...updateValue },
        maxIndex: activeIndex + 1,
        error: false,
      })
    );
  };

  moveToNextSection = (newSelection, newMaxIndex) => {
    const { dispatch, smartRiskBuilder } = this.props;
    const { activeIndex, selection } = smartRiskBuilder;
    const index = activeIndex + 1;

    return dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        selection: { ...selection, ...newSelection },
        activeIndex: index,
        maxIndex: newMaxIndex,
        error: false,
      })
    );
  };

  showErrorMsg = () => {
    const { dispatch } = this.props;

    dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        error: true,
      })
    );

    return (this.msgId = setTimeout(
      () =>
        dispatch(
          CacheInputs.updateField('portfolios', 'smart_risk_builder', {
            error: false,
          })
        ),
      4000
    ));
  };

  previousTab = (e) => {
    e.preventDefault();
    const { dispatch, smartRiskBuilder } = this.props;
    const { activeIndex } = smartRiskBuilder;

    return dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        activeIndex: activeIndex - 1,
        error: false,
      })
    );
  };

  handleTabChange = (e, { activeIndex }) => {
    const { smartRiskBuilder, partnerName } = this.props;
    const { maxIndex, selection } = smartRiskBuilder;

    if (activeIndex === 0 && maxIndex === 0) return false;
    if (isGlobeInvestor(partnerName)) return false;

    // Check if any required values are undefined
    const requiredValues = ['risk', 'sector', 'oil', 'interest', 'reit'];
    const currentTab = activeIndex;
    const previousTab = currentTab - 1;

    for (let i = 0; i <= previousTab; i++) {
      const value = requiredValues[i];
      if (!selection[value] || !selection[value][0]) {
        this.showErrorMsg();
        return false;
      }
    }

    return this.props.dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        activeIndex,
        error: false,
      })
    );
  };

  render() {
    const { smartRiskBuilder, partnerName } = this.props;
    const { selection, error, activeIndex } = smartRiskBuilder;
    const { risk, sector, oil, interest, reit, foreign } = selection;

    const panes = [
      {
        menuItem: 'Risk Profile',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <RiskProfile
              selection={selection}
              pData={pData}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              risk={risk ? risk[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Exclude sector',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <ExcludeSector
              data={pData}
              pData={pData[risk[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              sector={sector ? sector[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Oil exposure',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <OilExposure
              pData={pData[risk[0]][sector[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              oil={oil ? oil[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Interest rate risk',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <InterestRisk
              pData={pData[risk[0]][sector[0]][oil[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              interest={interest ? interest[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'REIT',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <Reit
              pData={pData[risk[0]][sector[0]][oil[0]][interest[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              reit={reit ? reit[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Foreign Markets',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <ForeignMarkets
              pData={pData[risk[0]][sector[0]][oil[0]][interest[0]][reit[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              selection={selection}
              foreign={
                foreign || {
                  isForeign: false,
                  markets: {
                    us: false,
                    dev: false,
                    emer: false,
                  },
                  error: null,
                }
              }
            />
          </Tab.Pane>
        ),
      },
      isGlobeInvestor(partnerName) && {
        menuItem: 'Result',
        render: (props) => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <ETFAnalytics
              {...props}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              selection={selection}
              history={this.props.history}
              location={this.props.location}
              match={this.props.match}
            />
          </Tab.Pane>
        ),
      },
    ].filter(Boolean);
    return (
      <div
        style={{
          border:
            isGlobeInvestor(partnerName) && '1px solid rgb(229, 229, 229)',
        }}
      >
        {isGlobeInvestor(partnerName) && (
          <div
            style={{
              height: '60px',
              width: '100%',
              position: 'fixed',
              top: '0',
              backgroundColor: 'transparent', // cover up tabs on Globe Investor
              zIndex: '9999',
            }}
          />
        )}
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            widths: isGlobeInvestor(partnerName) ? 7 : 6,
          }}
          onTabChange={this.handleTabChange}
          panes={panes}
          activeIndex={activeIndex}
        />

        {error && (
          <ErrorLabel
            className="basic"
            msg="Select your answer and click next."
            style={{ left: '55vw', top: '8rem', bottom: 'initial' }}
          />
        )}
      </div>
    );
  }
}

FindETF.propTypes = {
  dispatch: PropTypes.func,
  smartRiskBuilder: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  partnerName: PropTypes.string,
};

FindETF.defaultProps = {
  dispatch: () => false,
  smartRiskBuilder: {},
  history: {},
  partnerName: '',
};

export default withRouter(
  connect((state) => ({
    smartRiskBuilder: state.CacheInputs.portfolios.smart_risk_builder,
    analytics: state.Analytics,
    check_history:
      state.Storage['pwpa-check-history'] === undefined
        ? true
        : state.Storage['pwpa-check-history'],
    pwpaPortfolio: state.Storage['pwpa-portfolio'],
    pwpaHasExcludeHolding: state.Storage['pwpa-has-exclude-holding'],
    user_region: state.Storage.user_region || 'CA',
    toggle_region: state.Storage.toggle_region || 'CA',
    esg: state.Analytics.esg,
    esgAlert: state.Analytics.esgAlert,
    is_esg_enabled: state.Storage.is_esg_enabled,
    partnerName: state.Storage['partner-name'],
  }))(FindETF)
);
