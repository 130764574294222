import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label, Header, Form, Popup } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Segment, Button } from 'components';

class OilExposure extends Component {
  handleChange = (e, { name, value }) =>
    this.props.updateSelection({ [name]: [value] });

  handleSubmit = () => {
    const { moveToNextSection, pData, oil } = this.props;

    return moveToNextSection({ oil: [oil, pData[oil].total] }, 3);
  };

  render() {
    const { previousTab, pData, oil } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Header size="medium" textAlign="center">
            How much risk exposure to oil do you want in your portfolio?
            <Header.Subheader style={{ fontSize: '1rem' }}>
              Note that we have no model portfolios with higher than 20% risk
              exposure to oil.
            </Header.Subheader>
          </Header>
          <Segment basic compact textAlign="center">
            <Form.Group
              grouped
              style={{ display: 'inline-block', textAlign: 'left' }}
            >
              <Form.Radio
                toggle
                name="oil"
                label="None"
                value="none"
                checked={oil === 'none'}
                onChange={this.handleChange}
              />

              <Popup
                trigger={
                  <Form.Radio
                    toggle
                    disabled={!pData.low}
                    style={{ pointerEvents: 'all' }}
                    name="oil"
                    label="Low"
                    value="low"
                    checked={oil === 'low'}
                    onChange={this.handleChange}
                  />
                }
                position="right center"
                style={{ height: 'initial' }}
                offset={10}
              >
                {pData.low ? (
                  <Popup.Content>
                    Between <b>0%</b> and <b>10%</b> of total portfolio risk.
                  </Popup.Content>
                ) : (
                  <Popup.Content>
                    Not available. Few portfolios in this category have low risk
                    exposure to oil.
                  </Popup.Content>
                )}
              </Popup>

              <Popup
                trigger={
                  <Form.Radio
                    toggle
                    disabled={!pData.med}
                    style={{ pointerEvents: 'all' }}
                    name="oil"
                    label="Medium"
                    value="med"
                    checked={oil === 'med'}
                    onChange={this.handleChange}
                  />
                }
                position="right center"
                style={{ height: 'initial' }}
                offset={10}
              >
                {pData.med ? (
                  <Popup.Content>
                    Between <b>10%</b> and <b>20%</b> of total portfolio risk.
                  </Popup.Content>
                ) : (
                  <Popup.Content>
                    Not available. Few portfolios in this category have medium
                    risk exposure to oil.
                  </Popup.Content>
                )}
              </Popup>
            </Form.Group>
            <Label
              basic
              color="blue"
              size="large"
              attached="bottom"
              style={{
                width: '60%',
                left: '20%',
                bottom: '-4.4rem',
                border: 0,
                lineHeight: '1.4rem',
              }}
            >
              <div style={{ fontSize: '1.5rem' }}>
                {oil
                  ? pData[oil].total.toLocaleString()
                  : pData.total.toLocaleString()}
              </div>
              matching portfolios
            </Label>
          </Segment>
          <Button
            className="left"
            color={colors.orange}
            icon="chevron left"
            onClick={previousTab}
          >
            Back
          </Button>
          <Button
            className={cn('right', { disabled: !oil })}
            color={colors.orange}
            icon="chevron right"
            floated="right"
          >
            Next
          </Button>
        </Form>
      </div>
    );
  }
}

OilExposure.propTypes = {
  pData: PropTypes.object.isRequired,
  updateSelection: PropTypes.func.isRequired,
  moveToNextSection: PropTypes.func.isRequired,
  previousTab: PropTypes.func.isRequired,
  oil: PropTypes.string,
};

OilExposure.defaultProps = {
  oil: '',
};

export default connect()(OilExposure);
