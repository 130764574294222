import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Divider, Button } from 'semantic-ui-react';

import { colors } from 'utils/colors';

export default class Introduction extends Component {
  state = {
    isAssumptionsOpen: false,
  };
  render() {
    const { isAssumptionsOpen } = this.state;
    const { activePage } = this.props;

    return (
      <BlueprintContent style={{ paddingTop: activePage === 0 && '.5rem' }}>
        <div>
          {activePage === 0 ? (
            <Header textAlign="center" size="large">
              <Header.Subheader
                style={{
                  marginTop: '1rem',
                  letterSpacing: '.08px',
                  textAlign: 'left',
                  color: colors.darkerBlack,
                }}
              >
                With the retirement income and savings you have, let's work out
                how much you can spend each year, and where that money should
                come from to optimize taxes and maintain a smooth consumption
                path.
              </Header.Subheader>
              <Header.Subheader
                style={{
                  textAlign: 'left',
                  color: colors.darkerBlack,
                  marginTop: '1rem',
                }}
              >
                One of the first questions many retirees ask is, how long should
                I be planning for? In Canada, a 65-year-old male is expected to
                live for another 19 years, and a 65- year-old female is expected
                to live for another 22 years. But these are just averages. For a
                more customized estimate, you may wish to take this
                questionnaire from Canadian scientists at{' '}
                <a
                  href="https://www.projectbiglife.ca/life-expectancy-home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Project Big Life
                </a>
                .
              </Header.Subheader>
              <Divider style={{ width: '94%', marginTop: '3.5rem' }} />
            </Header>
          ) : null}

          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {activePage === 0 && (
                <CollapseWrapper open={isAssumptionsOpen}>
                  <Header
                    size="medium"
                    style={{
                      position: 'absolute',
                      top: isAssumptionsOpen ? '-11px' : '2px',
                      left: '.5rem',
                      background: 'white',
                      transition: 'all 300ms ease',
                      padding: '0 0rem 0 .5rem',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledButton
                        icon
                        onClick={() =>
                          this.setState({
                            isAssumptionsOpen: !isAssumptionsOpen,
                          })
                        }
                        isOpen={isAssumptionsOpen}
                      >
                        {isAssumptionsOpen ? '-' : '+'}
                      </StyledButton>
                      Model Features & Assumptions
                    </div>
                  </Header>

                  <Assumptions>
                    <Item>
                      <Header size="small" content="Optimality" />
                      <p style={{ fontSize: '1rem' }}>
                        Optimality means allocating withdrawals between taxable
                        and RRIF accounts so that:
                      </p>
                      <ul>
                        <li>The after-tax income path is smooth</li>
                        <li>
                          The present value of after-tax income is maximized
                        </li>
                        <li>The client-specific bequest amount is satisfied</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="The drawdown plan is not constrained to a sequential withdrawal strategy"
                      />
                      <ul>
                        <li>
                          Because a sequential strategy (taxable accounts then
                          RRIF or vice versa) is not necessarily optimal in
                          minimizing taxes. Therefore, our algorithm solves for
                          the optimal combination of RRIF and taxable accounts
                          to draw from in each year of the planning horizon.
                        </li>
                        <li>
                          Since withdrawing from TFSA has no tax consequences,
                          it is best set aside for a rainy day and for one’s
                          bequest.
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Taxable accounts" />
                      <ul>
                        <li>Dividend tax, tax credit for eligible dividends</li>
                        <li>Capital gains tax</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Public pensions" />
                      <ul>
                        <li>
                          Accommodates standard, deferral or early withdrawal of
                          CPP/OAS
                        </li>
                        <li>OAS clawback</li>
                        <li>CPP/QPP/OAS estimators are provided</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Taxes" />
                      <ul>
                        <li>
                          Federal and provincial tax brackets, indexed to
                          inflation (except for NS, PE)
                        </li>
                        <li>
                          Provincial surtax (ON and PE), surtax brackets indexed
                          to inflation in ON
                        </li>
                        <li>OHIP premium (ON)</li>
                        <li>OAS clawback (recovery tax)</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Tax credits" />
                      <ul>
                        <li>
                          Basic Personal amount
                          <ul>
                            <li>Federal enhanced basic personal amount</li>
                            <li>Indexed to inflation except for NS, PE</li>
                            <li>
                              Additional basic personal amount in NS is not
                              included
                            </li>
                          </ul>
                        </li>
                        <li>
                          Age amount
                          <ul>
                            <li>Indexed to inflation except for NS, PE, MB</li>
                            <li>Additional age amount in NS is not included</li>
                            <li>Not included in QC</li>
                          </ul>
                        </li>
                        <li>
                          Pension income amount
                          <ul>
                            <li>Indexed to inflation only in ON</li>
                            <li>Not included in QC</li>
                          </ul>
                        </li>
                        <li>Dividend tax credit</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Inflation" />
                      <ul>
                        <li>2%</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="RRSP has been converted into a RRIF"
                      />
                      <ul>
                        <li>
                          Calculates minimum RRIF and additional withdrawal (if
                          required) from RRIF
                        </li>
                        <li>
                          Excess RRIF withdrawals will be reallocated to TFSA
                          and/or non-registered investment accounts.
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Bequest" />
                      <ul>
                        <li>Client-specific value, adjusted for inflation</li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Company pension" />
                      <ul>
                        <li>
                          Client specifies value and whether it is adjusted for
                          inflation
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header
                        size="small"
                        content="Rental / part-time income... etc."
                      />
                      <ul>
                        <li>
                          Client specifies how long they last and whether this
                          income is adjusted for inflation
                        </li>
                      </ul>
                    </Item>

                    <Item>
                      <Header size="small" content="Portfolio options" />
                      <ul>
                        <li>
                          Flat rate of return (fixed income without risk), or
                        </li>
                        <li>
                          10,000 simulations using monthly historical returns of
                          an integrated investment portfolio
                        </li>
                        <li>Sequence-of-returns risk option</li>
                      </ul>
                    </Item>
                  </Assumptions>
                </CollapseWrapper>
              )}
            </div>
            {activePage === 0 && (
              <p
                style={{
                  color: `${colors.red}`,
                  marginTop: '2rem',
                  textAlign: 'center',
                }}
              >
                All fields are required unless otherwise noted.
              </p>
            )}
          </div>
        </div>
      </BlueprintContent>
    );
  }
}

Introduction.propTypes = {
  activePage: PropTypes.number.isRequired,
};

const BlueprintContent = styled.div`
  width: 100%;
  text-align: left;

  .radio > label {
    font-size: 1.2rem !important;
  }
  .radio {
    margin: 0.5rem 2rem;
  }
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 55rem;
  padding: 0 1rem;
  padding-top: ${(props) => (props.open ? '0rem' : '2rem')};
  margin: 3rem auto 0 auto;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '2000px' : '28px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const Assumptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  text-align: left;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);

  .header {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Item = styled.div`
  display: block;
  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 49.5%;
  margin: 0.5% 0;
  padding: 5px;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;
