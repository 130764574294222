import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label, Header, Form, Popup } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Segment, Button, Message } from 'components';
import { CacheInputs } from 'actions';

class InterestRisk extends Component {
  componentDidMount = () => {
    const { dispatch, pData, smartRiskBuilder } = this.props;
    const { activeIndex } = smartRiskBuilder;

    if (pData['N/A']) {
      return dispatch(
        CacheInputs.updateField('portfolios', 'smart_risk_builder', {
          ...smartRiskBuilder,
          maxIndex: activeIndex + 1,
        })
      );
    }

    return false;
  };

  handleChange = (e, { name, value }) =>
    this.props.updateSelection({ [name]: [value] });

  handleSubmit = () => {
    const { moveToNextSection, pData, interest } = this.props;

    if (pData['N/A']) {
      return moveToNextSection({ interest: ['N/A', pData.total] }, 4);
    }

    return moveToNextSection(
      { interest: [interest, pData[interest].total] },
      4
    );
  };

  renderOptions() {
    const { pData, interest } = this.props;

    return (
      <div>
        <Segment basic compact textAlign="center">
          <Form.Group
            grouped
            style={{ display: 'inline-block', textAlign: 'left' }}
          >
            <Popup
              trigger={
                <Form.Radio
                  toggle
                  style={{ pointerEvents: 'all' }}
                  name="interest"
                  label="Low"
                  value="low"
                  checked={interest === 'low'}
                  onChange={this.handleChange}
                />
              }
              position="right center"
              style={{ height: 'initial' }}
              offset={10}
            >
              Less than 20% of the total portfolio risk.
            </Popup>

            <Popup
              trigger={
                <Form.Radio
                  toggle
                  style={{ pointerEvents: 'all' }}
                  name="interest"
                  label="Medium"
                  value="med"
                  checked={interest === 'med'}
                  onChange={this.handleChange}
                />
              }
              position="right center"
              style={{ height: 'initial' }}
              offset={10}
            >
              Between 20% and 40% of the total portfolio risk.
            </Popup>
            <Popup
              trigger={
                <Form.Radio
                  toggle
                  style={{ pointerEvents: 'all' }}
                  disabled={!this.props.pData.high}
                  name="interest"
                  label="High"
                  value="high"
                  checked={interest === 'high'}
                  onChange={this.handleChange}
                />
              }
              position="right center"
              style={{ height: 'initial' }}
              offset={10}
            >
              {pData.high
                ? 'Greater than 40% of the total portfolio risk'
                : 'Not available. Few portfolios in this category have high risk exposure to interest rates'}
            </Popup>
          </Form.Group>
          <Label
            basic
            color="blue"
            size="large"
            attached="bottom"
            style={{
              width: '60%',
              left: '20%',
              bottom: '-4.4rem',
              border: 0,
              lineHeight: '1.4rem',
            }}
          >
            <div style={{ fontSize: '1.5rem' }}>
              {interest
                ? pData[interest].total.toLocaleString()
                : pData.total.toLocaleString()}
            </div>
            matching portfolios
          </Label>
        </Segment>
      </div>
    );
  }

  renderMessage = () => (
    <Message
      visible
      warning
      style={{ marginBottom: '14px', textAlign: 'center' }}
    >
      <Message.Header>Not enough variations</Message.Header>
      <Message.Content>
        Unfortunately, there are not enough variations in the universe of{' '}
        <b>WS All-in</b> portfolios to incorporate interest rate risk exposure
        preferences. The main reason is that they hold a relatively small
        portion or no fixed income securities.
      </Message.Content>
      <br />
      <Message.Content>Please proceed to the next question.</Message.Content>
    </Message>
  );

  render() {
    const { previousTab, pData, interest } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Header size="medium" textAlign="center">
            How much interest rate risk exposure do you want ?
          </Header>
          {pData['N/A'] ? this.renderMessage() : this.renderOptions()}
          <Button
            className="left"
            color={colors.orange}
            icon="chevron left"
            onClick={previousTab}
          >
            Back
          </Button>
          <Button
            className={cn('right', {
              disabled: pData['N/A'] ? false : !interest,
            })}
            color={colors.orange}
            icon="chevron right"
            floated="right"
          >
            Next
          </Button>
        </Form>
      </div>
    );
  }
}

InterestRisk.propTypes = {
  pData: PropTypes.object.isRequired,
  updateSelection: PropTypes.func.isRequired,
  moveToNextSection: PropTypes.func.isRequired,
  previousTab: PropTypes.func.isRequired,
  interest: PropTypes.string,
  dispatch: PropTypes.func,
  smartRiskBuilder: PropTypes.object,
};

InterestRisk.defaultProps = {
  interest: '',
  dispatch: () => false,
  smartRiskBuilder: {},
};

export default connect((state) => ({
  smartRiskBuilder: state.CacheInputs.portfolios.smart_risk_builder,
}))(InterestRisk);
