import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Message } from 'components';
import { ExposureTable } from '../components';

class EquityStyle extends Component {
  renderContent = (data) => {
    const { french } = this.props;
    const filteredData = { ...data.partialCorr['G.EQ'] };
    delete filteredData.ignoredNegFac;

    const determineStyleTilt = (factor, value) => {
      if (value > 0) return tiltLabels[factor].plus;
      else if (value === 0) return tiltLabels[factor].neutral;
      return tiltLabels[factor].minus;
    };

    const tiltLabels = {
      Market: {
        plus: french ? 'Marché' : 'Market',
        neutral: french ? 'Neutre' : 'Neutral',
        minus: french ? 'Couverture' : 'Hedge',
      },
      Size: {
        plus: french ? 'Petite Capitalisation' : 'Small Cap',
        neutral: french ? 'Neutre' : 'Neutral',
        minus: french ? 'Grande Capitalisation' : 'Large Cap',
      },
      Value: {
        plus: french ? 'Valeur' : 'Value',
        neutral: french ? 'Neutre' : 'Neutral',
        minus: french ? 'Croissance' : 'Growth',
      },
      Momentum: {
        plus: french ? 'Momentum élevé' : 'High Momentum',
        neutral: french ? 'Neutre' : 'Neutral',
        minus: french ? 'Renversement de tendance' : 'Reversal',
      },
    };

    return (
      <div>
        <Header
          dividing
          size="large"
          content={
            french
              ? 'Analyse du style au niveau du portefeuille'
              : 'Portfolio-Level Style Analysis'
          }
          subheader={french ? "Par style de l'équité" : 'By Equity Style'}
        />
        <p>
          {french
            ? "Cette section montre les inclinaisons de style de votre portefeuille en utilisant les facteurs de style de l'équité Fama-French-Carhart : marché, taille, valeur et momentum. Dans le tableau, les inclinaisons de style au niveau du portefeuille sont évaluées en utilisant la corrélation partielle entre les rendements de votre portefeuille d'actions et chaque facteur. Si elle est positive (et statistiquement significative), alors votre portefeuille d'actions est exposé au marché, à la petite taille, à la valeur et au momentum. Si elle est négative, alors votre portefeuille d'actions est couvert au marché, et est exposé à la grande taille, à la croissance et à la réversion. De plus, comme la corrélation partielle se situe entre -1 et 1, son ampleur montre la force de la corrélation."
            : "This section shows your portfolio's equity style tilts using the Fama-French-Carhart equity style factors: market, size, value, and momentum. In the table, portfolio-level style tilts are assessed using the partial correlation between your equity portfolio returns and each factor. If it is positive (and statistically significant), then your equity portfolio has exposure to the market, small size, value, and momentum. If it is negative, then your equity portfolio is hedged to the market, and has exposure to large size, growth, and reversal. Further, since the partial correlation lies between -1 and 1, its magnitude shows the strength of the correlation."}
        </p>
        {data.partialCorr && typeof data.partialCorr['G.EQ'] === 'string' ? (
          this.renderMessage(data.partialCorr['G.EQ'])
        ) : (
          <ExposureTable
            portfolioLevel
            headers={[
              french ? 'Facteur de style' : 'Style Factor',
              french
                ? 'Corrélation partielle entre le portefeuille et le facteur'
                : 'Partial Correlation between Portfolio and Factor',
              french ? 'Inclinaison de style' : 'Style Tilt',
            ]}
            data={Object.entries(filteredData).map(([factor, value]) => ({
              ticker: factor,
              macroRiskExp: value,
              styleTilt: determineStyleTilt(factor, value),
            }))}
          />
        )}
      </div>
    );
  };

  renderMessage = (message) => {
    const { french } = this.props;

    if (typeof message !== 'string') {
      return null; // or some default message
    }

    return (
      <div>
        <Header
          dividing
          size="large"
          content={
            french
              ? 'Analyse du style au niveau du portefeuille'
              : 'Portfolio-Level Style Analysis'
          }
          subheader={french ? "Par style de l'équité" : 'By Equity Style'}
        />
        <p>
          {french
            ? "Cette section montre les inclinaisons de style de votre portefeuille en utilisant les facteurs de style de l'équité Fama-French-Carhart : marché, taille, valeur et momentum. Dans le tableau, les inclinaisons de style au niveau du portefeuille sont évaluées en utilisant la corrélation partielle entre les rendements de votre portefeuille d'actions et chaque facteur. Si elle est positive (et statistiquement significative), alors votre portefeuille d'actions est exposé au marché, à la petite taille, à la valeur et au momentum. Si elle est négative, alors votre portefeuille d'actions est couvert au marché, et est exposé à la grande taille, à la croissance et à la réversion. De plus, comme la corrélation partielle se situe entre -1 et 1, son ampleur montre la force de la corrélation."
            : "This section shows your portfolio's equity style tilts using the Fama-French-Carhart equity style factors: market, size, value, and momentum. In the table, portfolio-level style tilts are assessed using the partial correlation between your equity portfolio returns and each factor. If it is positive (and statistically significant), then your equity portfolio has exposure to the market, small size, value, and momentum. If it is negative, then your equity portfolio is hedged to the market, and has exposure to large size, growth, and reversal. Further, since the partial correlation lies between -1 and 1, its magnitude shows the strength of the correlation."}
        </p>
        <Message visible warning>
          {message}
        </Message>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <Container>
        {data.partialCorr && typeof data.partialCorr['G.EQ'] === 'string'
          ? this.renderMessage(data.partialCorr['G.EQ'])
          : this.renderContent(data)}
      </Container>
    );
  }
}

EquityStyle.propTypes = {
  data: PropTypes.object,
  french: PropTypes.bool,
};

EquityStyle.defaultProps = {
  data: {},
  french: false,
};

export default connect((state) => ({
  data: state.Analytics.data,
  french: state.Storage.language === 'fr',
}))(EquityStyle);

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
