import { FundComparison } from 'actions';

const initialState = {
  isFetching: false,
  security: null,
  securityData: {},
  graph: {
    startDate: '',
    endDate: '',
  },
  chartErrorList: new Set(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FundComparison.FUNDCOMPARISON_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FundComparison.FUNDCOMPARISON_ADD_FUND_DATA:
      return {
        ...state,
        isFetching: false,
        securityData: {
          ...state.securityData,
          [action.ticker]: action.securityData,
        },
      };
    case FundComparison.FUNDCOMPARISON_DELETE_FUND_DATA:
      return {
        ...state,
        isFetching: false,
        securityData: Object.keys(state.securityData)
          .filter((key) => key !== action.ticker)
          .reduce((acc, val) => {
            acc[val] = { ...state.securityData[val] };
            return acc;
          }, {}),
      };
    case FundComparison.FUNDCOMPARISON_UPDATE_GRAPH_DATES:
      return {
        ...state,
        graph: {
          startDate:
            action.dates.startDate === ''
              ? ''
              : action.dates.startDate || state.graph.startDate,

          endDate:
            action.dates.endDate === ''
              ? ''
              : action.dates.endDate || state.graph.endDate,
        },
      };
    case FundComparison.FUNDCOMPARISON_ADD_CHARTERROR_LIST:
      return {
        ...state,
        chartErrorList: state.chartErrorList.add(...action.ticker),
      };
    default:
      return state;
  }
}
