import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Radar, Line } from 'react-chartjs-2';
import { Statistic, Header, Divider, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { getOverAllScore, getOverAllGrade } from 'utils/helpers';
import { DateLabel, Table, CustomBenchmark, Selection } from 'components';

import { colors } from 'utils/colors';

@withRouter
class Result extends Component {
  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount = () => {
    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    // resize charts on print
    this.state.mql.addListener(this.printListener);

    return false;
  };

  componentDidMount = () => {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  };

  componentWillUnmount = () => {
    window.clearTimeout(this.timeoutId);
    this.state.mql.removeListener(this.printListener);
  };

  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0 %');

  getRadarData = (port1, port2, portOneName, portTwoName) => {
    const { benchmark, compareEsgResult, esgToggle } = this.props;
    const portfolio1esg = compareEsgResult
      ? compareEsgResult.portfolio1esg.portfolio
      : null;
    const portfolio2esg = compareEsgResult
      ? compareEsgResult.portfolio2esg.portfolio
      : null;
    const hash = {
      Diversification: 'Diversification',
      Fees: 'Low Fees',
      Income: 'Income',
      Performance: 'Performance',
    };
    const portScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port1.score[key][benchmark][0]
          : port1.score[key][0];

      return total;
    }, {});
    const compareScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port2.score[key][benchmark][0]
          : port2.score[key][0];

      return total;
    }, {});

    return {
      labels: esgToggle
        ? ['Downside Protection', ...Object.keys(portScores), 'ESG']
        : ['Downside Protection', ...Object.keys(portScores)],
      datasets: [
        {
          label: 'Current portfolio',
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data:
            esgToggle && portfolio1esg && portfolio1esg.esg_score
              ? [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                  portfolio1esg.ws_grade[0] || 0,
                ]
              : [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                ],
        },
        {
          label: portTwoName,
          backgroundColor: 'rgba(255,184,182,0.5)',
          borderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointBorderColor: colors.red,
          lineTension: 0.3,
          data:
            esgToggle && portfolio2esg && portfolio2esg.esg_score
              ? [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                  portfolio2esg.ws_grade[0] || 0,
                ]
              : [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                ],
        },
      ],
    };
  };

  getChartData = (port1, port2, portOneName, portTwoName) => ({
    labels: Object.keys(port1.cumrets.portfolio),
    datasets: [
      {
        data: Object.values(port1.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: 'Current Portfolio',
        lineTension: 0,
        fill: false,
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        pointBackgroundColor: colors.blue,
        pointBorderColor: colors.blue,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        data: Object.values(port2.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: portTwoName,
        lineTension: 0,
        fill: false,
        backgroundColor: colors.red,
        borderColor: colors.red,
        pointBackgroundColor: colors.red,
        pointBorderColor: colors.red,
        pointRadius: 0,
        pointHitRadius: 5,
      },
    ],
  });

  parseDate = () => {
    const { init_date, end_date } =
      this.props.compareResult.analysis1.paramsAnalyzed;
    const init = init_date.split('-');
    const end = end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  printListener = (e) => {
    const { history, match } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else {
      history.push(`/accounts/${match.params.id}/compare/result`);
    }
  };

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  timeoutId = null;

  displayEsg = (esg) => {
    if (!esg) return '-';

    const { portfolio } = esg;

    if (!portfolio.esg_score) return '-';

    return !portfolio.esg_score ? '-' : portfolio.esg_score;
  };

  esgCoverage = (esg) => {
    if (!esg) return '(0%)';

    const { portfolio } = esg;
    const weight = (portfolio.weight * 100).toFixed(2);

    if (!weight || !Number(weight)) return '(0%)';

    const convertWeight = weight.split('.');

    if (convertWeight[1][0] === '0' && convertWeight[1][1] === '0')
      return `(${convertWeight[0]}%)`;
    if (convertWeight[1][1] === '0')
      return `(${convertWeight[0]}.${convertWeight[1][0]}%)`;

    return `(${weight}%)`;
  };

  renderSubheader = () => (
    <div className="sub header">Comparison Period: {this.parseDate()}</div>
  );

  renderFootNote = () => {
    const { user_region } = this.props;
    const points = [
      'Please note that it is only meaningful to compare the scores of portfolios with similar asset allocation (i.e., in the same risk category), because each portfolio’s score is benchmarked to a specific asset allocation.',
      'The comparison period is set to the longest common history of the two portfolios. As such, the score of the “Current Portfolio” may change when it is being compared.',
      'All key statistics are reported in CAD.',
      'The following are risk-adjusted return ratios, and a higher ratio is better: The Sharpe Ratio measures the portfolio’s reward (excess returns over the risk-free rate) per unit of risk. The Roy’s Safety First Criterion measures the portfolio’s reward (excess returns over the target rate) per unit of risk. The Sortino Ratio measures the portfolio’s reward (excess returns over the target rate) per unit of downside risk.',
      'The following are portfolio risk measures: Total risk represents the volatility of the portfolio’s returns as measured by its standard deviation. Downside risk is the volatility of the portfolio’s return below a target rate. Maximum drawdown is the maximum peak to trough loss during a given period. Downside capture measures whether the portfolio lost less than the market when there was a market downturn.',
      'The default target rate is set to zero, representing nominal capital preservation.',
      'Fees capture product fees if you have mutual funds/ETFs. It can include a trailing commission for your advisor, depending on the share class.',
      'Income includes dividends from stocks and distributions from mutual funds/ETFs.',
    ];

    if (user_region === 'US') {
      points.splice(2, 1);
    }

    return (
      <div>
        {points.map((point, index) => (
          <P>
            {index + 1}.&nbsp;{point}
          </P>
        ))}
      </div>
    );
  };

  render() {
    const { benchmark, compareEsgResult, esgToggle, compareAccState } =
      this.props;
    const { analysis1, analysis2 } = this.props.compareResult;
    const portfolio1esg = compareEsgResult
      ? compareEsgResult.portfolio1esg
      : null;
    const portfolio2esg = compareEsgResult
      ? compareEsgResult.portfolio2esg
      : null;

    const portOneName = compareAccState.original.name;
    const portTwoName = compareAccState.name;
    const compareResultsUseSameBenchmark =
      JSON.stringify(analysis1.fratios[benchmark]) ===
      JSON.stringify(analysis2.fratios[benchmark]);

    return (
      <PDF>
        <Container centered>
          <Header
            size="large"
            content={`Current Portfolio ${
              portOneName ? `(${portOneName})` : ''
            } vs. ${portTwoName}`}
            subheader={this.renderSubheader()}
            style={{ marginTop: 0 }}
          />
          <CustomBenchmark benchmark={benchmark} feature="compareAccounts" />

          <EsgToggleBlock>
            <p>Include ESG score in my overall score</p>
            {esgToggle ? (
              <div
                style={{
                  textAlign: 'right',
                  fontStyle: 'italic',
                  color: colors.hintRed,
                  fontSize: '1rem',
                }}
              >
                Check the table below to see if the two portfolios have
                sufficient and
                <br />
                comparable ESG coverage for a meaningful comparison. If not,
                toggle back to "No".
              </div>
            ) : null}
            <div
              style={{
                width: '112px',
                position: 'absolute',
                top: '0',
                right: '0',
              }}
            >
              <Selection selected={esgToggle} style={{ margin: '0' }} />
            </div>
          </EsgToggleBlock>

          <Grid style={{ margin: '0 0 3rem 0' }}>
            <Grid.Row columns={3}>
              <Grid.Column width={4}>
                <Statistic
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    right: 0,
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Grade color={colors.blue}>
                    {getOverAllGrade({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio1esg,
                      data: analysis1,
                    })}
                  </Grade>
                  <Statistic.Label>Current portfolio score</Statistic.Label>
                  <Statistic.Value>
                    {getOverAllScore({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio1esg,
                      data: analysis1,
                    })}
                  </Statistic.Value>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={8}>
                <Radar
                  id="radarChart"
                  data={this.getRadarData(
                    analysis1,
                    analysis2,
                    portOneName,
                    portTwoName
                  )}
                  options={radarOptions}
                  height={250}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Statistic
                  size="small"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    right: 0,
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Grade color={colors.red}>
                    {getOverAllGrade({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio2esg,
                      data: analysis2,
                    })}
                  </Grade>
                  <Statistic.Label>{portTwoName} score</Statistic.Label>
                  <Statistic.Value>
                    {getOverAllScore({
                      income: true,
                      benchmark,
                      esgToggle,
                      esg: portfolio2esg,
                      data: analysis2,
                    })}
                  </Statistic.Value>
                </Statistic>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header>
            <Header.Content>Key Statistics</Header.Content>
            <Header.Subheader>
              <DateLabel str={`${this.parseDate()} (inclusive)`} />
            </Header.Subheader>
          </Header>

          <Divider />

          <TableWrapper>
            <Table basic textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={3}>
                    Annualized Return
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Sharpe Ratio&nbsp;
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Roy&#8217;s Safety First Criterion&nbsp;
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Sortino Ratio&nbsp;
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row positive>
                  <Table.Cell width={4}>Current Portfolio</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios.portfolio.annualizedRet)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios.portfolio.sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios.portfolio.roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios.portfolio.sortino)}
                  </Table.Cell>
                </Table.Row>
                {!compareResultsUseSameBenchmark && (
                  <Table.Row>
                    <Table.Cell width={4}>
                      {analysis1.fratios[benchmark].name}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis1.fratios[benchmark].annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios[benchmark].sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios[benchmark].roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios[benchmark].sortino)}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row positive>
                  <Table.Cell width={4}>{portTwoName}</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis2.fratios.portfolio.annualizedRet)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios.portfolio.sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios.portfolio.roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios.portfolio.sortino)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>
                    {analysis2.fratios[benchmark].name}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(
                      analysis2.fratios[benchmark].annualizedRet
                    )}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios[benchmark].sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios[benchmark].roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios[benchmark].sortino)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </TableWrapper>

          <Divider />

          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Total Risk&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Downside Risk&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Maximum Drawdown&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Downside Capture&nbsp;</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={4}>Current Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(
                    analysis1.fratios.portfolio.dscapture[benchmark]
                  )}
                </Table.Cell>
              </Table.Row>
              {!compareResultsUseSameBenchmark && (
                <Table.Row>
                  <Table.Cell width={4}>
                    {analysis1.fratios[benchmark].name}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].stdDev)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].dsr)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].maxDd)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].dscapture)}
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row positive>
                <Table.Cell width={4}>{portTwoName}</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(
                    analysis2.fratios.portfolio.dscapture[benchmark]
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  {analysis2.fratios[benchmark].name}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].dscapture)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Divider />

          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell width={3}>Fees&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Income&nbsp;</Table.HeaderCell>
                {esgToggle ? <Table.HeaderCell>ESG</Table.HeaderCell> : null}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={4}>Current Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.portMER)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.portYield)}
                </Table.Cell>
                {esgToggle ? (
                  <Table.Cell width={3}>
                    {this.displayEsg(portfolio1esg)}&nbsp;
                    {this.esgCoverage(portfolio1esg)}
                  </Table.Cell>
                ) : (
                  <Table.Cell width={3} />
                )}
                <Table.Cell width={3} />
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>{portTwoName}</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.portMER)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.portYield)}
                </Table.Cell>

                {esgToggle ? (
                  <Table.Cell width={3}>
                    {this.displayEsg(portfolio2esg)}&nbsp;
                    {this.esgCoverage(portfolio2esg)}
                  </Table.Cell>
                ) : (
                  <Table.Cell width={3} />
                )}
                <Table.Cell width={3} />
              </Table.Row>
            </Table.Body>
          </Table>

          <div style={{ pageBreakAfter: 'always' }} />

          <Header size="medium">
            <Header.Content>Hypothetical Portfolio History</Header.Content>
            <Header.Subheader>
              Based on the current composition of your portfolio
            </Header.Subheader>
            <Header.Subheader>
              <DateLabel str={`${this.parseDate()} (inclusive)`} />
            </Header.Subheader>
          </Header>
          <Note>Total Returns including Distributions</Note>
          <Line
            data={this.getChartData(
              analysis1,
              analysis2,
              portOneName,
              portTwoName
            )}
            options={chartOptions}
          />

          <div style={{ pageBreakAfter: 'always' }} />
          <Header
            size="large"
            content="Footnotes"
            style={{ textAlign: 'left', marginBottom: '15px' }}
          />
          {this.renderFootNote()}
        </Container>
      </PDF>
    );
  }
}

Result.propTypes = {
  compareAccState: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  compareResult: PropTypes.object,
  compareEsgResult: PropTypes.object,
  benchmark: PropTypes.string,
  user_region: PropTypes.string,
  esgToggle: PropTypes.bool,
};

Result.defaultProps = {
  match: {},
  history: {},
  compareResult: {},
  compareEsgResult: {},
  benchmark: '',
  user_region: 'CA',
  esgToggle: false,
};

export default connect((state) => ({
  compareAccState: state.CompareAccount,
  compareResult: state.CompareAccount.compareResult,
  compareEsgResult: state.CompareAccount.compareEsgResult,
  benchmark: state.CompareAccount.benchmark,

  user_region: state.Storage.user_region || 'CA',
  esgToggle: state.CompareAccount.esgToggle,
}))(Result);

const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
`;

const P = styled.p`
  text-align: left;
  max-width: 100%;
`;

const PDF = styled.div`
  position: relative;
  background: white;
  text-align: center;
  padding: 2.5rem;
  border-radius: 8px;
  width: 1160px;
  margin: -55px auto 5px auto;

  @media only print {
    print-color-adjust: exact;

    @page {
      size: auto;
      margin: 0.5cm;
    }
  }
`;

const Grade = styled.div`
  font-family: 'Kalam', cursive;
  color: ${(props) => props.color};
  font-size: 5rem;
  padding: 1.5rem 0;
`;

const Container = styled.div`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const radarOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: { position: 'bottom', usePointStyle: true },
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
  layout: {
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
};

const chartOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (label) => `$${label}`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${data.datasets[tooltipItems.datasetIndex].label}: $${
          tooltipItems.yLabel
        }`,
    },
  },
};

const EsgToggleBlock = styled.div`
  font-size: 1.15rem;
  position: relative;
  margin-bottom: 2rem;

  p {
    font-size: 1rem;
    width: calc(100% - 112px);
    text-align: right;
    max-width: none;
    margin-bottom: 0.5rem;
  }
`;

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.5rem auto;
`;
