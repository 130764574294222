import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { NotFound } from 'components';
import { CacheInputs } from 'actions';
import FundLookup from './FundLookup';
import FundLookupPdf from './FundLookup/PDF';
import OasEstimator from './OasEstimator';
import CppEstimator from './CppEstimator';
import ChartingTool from './ChartingTool';
import Landing from './Landing';
import FundAllocator from './FundAllocator';
import FundComparisonPdf from './FundComparison/PDF';
import FundComparison from './FundComparison';
import CorrelationMatrix from './CorrelationMatrix';
import StockExposure from '../../Accounts/StockExposure';

class Tools extends Component {
  componentWillUnmount = () => {
    const { dispatch, location } = this.props;

    return dispatch(CacheInputs.captureUrl('tools', location.pathname));
  };
  // bookmark
  render() {
    return (
      <div style={{ height: '90%' }}>
        <Switch>
          <Route path="/tools/landing" component={Landing} />
          <Route
            exact
            path="/tools/fund-lookup/pdf"
            component={FundLookupPdf}
          />
          <Route path="/tools/fund-lookup" component={FundLookup} />
          <Route
            exact
            path="/tools/fund-comparison/pdf"
            component={FundComparisonPdf}
          />
          <Route path="/tools/fund-comparison" component={FundComparison} />
          <Route exact path="/tools/oas-estimator" component={OasEstimator} />
          <Route exact path="/tools/cpp-estimator" component={CppEstimator} />
          <Route exact path="/tools/charting-tool" component={ChartingTool} />
          <Route
            exact
            path="/tools/correlation-matrix"
            component={CorrelationMatrix}
          />
          <Route exact path="/tools/stock-exposure" component={StockExposure} />
          <Route
            exact
            path="/tools/new-account-setup"
            component={FundAllocator}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

Tools.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(connect()(Tools));
