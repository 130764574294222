import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { colors } from 'utils/colors';
import { Modal, Button } from 'components';
import { isNB, notWealthica, translateText } from '../utils/helpers';
import { nbColors } from '../utils/colors';

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))
export default class TermsModal extends Component {
  save = () => {
    const { closeModal } = this.props;

    return closeModal();
  };

  render() {
    const { open, closeModal, partnerName, french } = this.props;
    return (
      <Modal
        vb={notWealthica(partnerName)}
        basicTop
        terms
        open={open}
        closable="false"
      >
        <Modal.Content
          style={{
            fontSize: '17px',
            lineHeight: '25px',
            textAlign: 'center',
            bottom: '70px',
          }}
        >
          {translateText('By using Wealthscope, you agree to our', french)}{' '}
          <a
            href={
              french
                ? 'https://www.wealthscope.ca/terms-of-use-french'
                : 'https://www.wealthscope.ca/terms-of-use'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {translateText('Terms of Service', french)}
          </a>{' '}
          {french ? 'et notre' : 'and'}{' '}
          <a
            href={
              french
                ? 'https://www.wealthscope.ca/privacy-policy-french'
                : 'https://www.wealthscope.ca/privacy-policy'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {translateText('Privacy Policy', french)}
          </a>
          .
        </Modal.Content>
        <Modal.Actions style={{ border: 'none', textAlign: 'center' }}>
          <Button
            className={notWealthica(partnerName) ? 'left' : 'inverted left'}
            color={isNB(partnerName) ? nbColors.blue : colors.blue}
            icon="checkmark"
            margin="0 0.5rem 0 0"
            onClick={() => {
              this.save();
              closeModal();
            }}
          >
            {translateText('I agree', french)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

TermsModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

TermsModal.defaultProps = {
  open: false,
  partnerName: '',
  french: false,
};
