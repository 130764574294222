import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { Header, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Table } from 'components';
import { graph } from 'utils/colors';

export default class Breakdown extends Component {
  getChartData = (data) => ({
    labels: Object.keys(data).map((key) => mappings[key]),
    datasets: [
      {
        data: Object.values(data).map((value) => (value < 0 ? 0 : value * 100)),
        backgroundColor: [...Object.values(graph)].slice(
          0,
          Object.values(data).length
        ),
      },
    ],
  });

  isAvailable = (data) => !!Object.values(data).filter((value) => value).length;

  renderTable = (data, params) => (
    <Table textAlign="center" style={{ minWidth: '250px' }}>
      <Table.Header>
        <Table.Row>
          {params.headers.map((header) => (
            <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(data).map((key) => (
          <Table.Row key={key}>
            <Table.Cell>
              {mappings[key]}
              {mappings[key] === 'Misc.' ? (
                <Popup
                  content="Miscellaneous includes funds of funds, convertibles assets (excluding bonds and preferred shares), derivative contracts and others."
                  trigger={<i className="icon question circle" />}
                  position="top center"
                />
              ) : null}
            </Table.Cell>
            <Table.Cell>
              {data[key] < 0 ? '0.0%' : `${(data[key] * 100).toFixed(1)}%`}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  render() {
    const { data } = this.props;
    const options = {
      showAllTooltips: true,
      maintainAspectRatio: false,
      cutoutPercentage: 65,
      legend: {
        position: 'left',
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, chartData) =>
            `${
              Math.round(
                chartData.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ] * 10
              ) / 10
            }%`,
        },
      },
      layout: {
        padding: {
          right: 30,
        },
      },
    };

    return (
      <div style={{ overflow: 'hidden', padding: '1rem 0' }}>
        {!this.isAvailable(data.regional_exposure) ? null : (
          <StyledDiv>
            <Header size="medium" content="Holdings by Region" />
            <div className="fund-chart">
              <Pie
                height={280}
                data={this.getChartData(data.regional_exposure)}
                options={options}
              />
            </div>
            <div className="fund-table">
              {this.renderTable(data.regional_exposure, {
                headers: ['Region', 'Holdings'],
              })}
            </div>
          </StyledDiv>
        )}

        {!this.isAvailable(data.asset_allocation) ? null : (
          <StyledDiv>
            <Header size="medium" content="Asset Allocation" />
            <div className="fund-chart">
              <Pie
                height={280}
                data={this.getChartData(data.asset_allocation)}
                options={options}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '25%' }}
            >
              <div className="fund-table">
                {this.renderTable(data.asset_allocation, {
                  headers: ['Asset Type', 'Holdings'],
                })}
              </div>
              <P>
                Miscellaneous includes funds of funds, convertibles assets
                (excluding bonds and preferred shares), derivative contracts and
                others.
              </P>
            </div>
          </StyledDiv>
        )}

        <div style={{ pageBreakAfter: 'always' }} />

        {!this.isAvailable(data.sector_allocation) ? null : (
          <StyledDiv>
            <Header size="medium" content="Equity Sector Allocation" />
            <div className="fund-chart">
              <Pie
                height={280}
                data={this.getChartData(data.sector_allocation)}
                options={options}
              />
            </div>
            <div className="fund-table">
              {this.renderTable(data.sector_allocation, {
                headers: ['Sector', 'Holdings'],
              })}
            </div>
          </StyledDiv>
        )}
      </div>
    );
  }
}

const mappings = {
  other: 'Other                           ',
  usa: 'US                                ',
  developed: 'Developed ex. US            ',
  emerging: 'Emerging                     ',
  canada: 'Canada                         ',
  frontier: 'Frontier                     ',
  stock: 'Stock                           ',
  cash: 'Cash & Equiv.                    ',
  bond: 'Bond                             ',
  financials: 'Financials                 ',
  utilities: 'Utilities                   ',
  healthcare: 'Healthcare                 ',
  materials: 'Materials                   ',
  energy: 'Energy                         ',
  telecom: 'Telecom                       ',
  info_tech: 'IT                          ',
  consumer_staples: 'Consumer Staples',
  consumer_discretionary: 'Consumer Discretionary',
  real_estate: 'Real Estate           ',
  industrials: 'Industrials           ',
  misc: 'Misc.',
};

Breakdown.propTypes = {
  data: PropTypes.object,
};

Breakdown.defaultProps = {
  data: {},
};

const StyledDiv = styled.div`
  margin: 1rem 0 2.5rem 0;

  .ui.header {
    text-align: left;
    clear: both;
  }

  .fund-chart {
    width: 65%;
    float: left;
  }

  .fund-table {
    float: left;
  }

  .table-container {
    width: 25%;
  }

  @media only screen and (max-width: 1150px) {
    .fund-chart,
    .fund-table,
    .table-container {
      width: 80%;
      float: initial;
      margin: 0 auto 1rem auto;
    }

    .ui.header {
      text-align: center;
    }
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 20rem;
  font-style: italic;
  text-align: justify;
  font-size: 1rem;
  margin-top: 0.5rem;
`;
