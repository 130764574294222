import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'components';

const Credit = ({ data }) => (
  <Table textAlign="center" style={{ margin: '4rem 0 0 0' }}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Credit Rating</Table.HeaderCell>
        <Table.HeaderCell>Percentage of Portfolio</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>AAA</Table.Cell>
        <Table.Cell>{data.credit_quality.aaa}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>AA</Table.Cell>
        <Table.Cell>{data.credit_quality.aa}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>A</Table.Cell>
        <Table.Cell>{data.credit_quality.a}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>BBB</Table.Cell>
        <Table.Cell>{data.credit_quality.bbb}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>BB</Table.Cell>
        <Table.Cell>{data.credit_quality.bb}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>B</Table.Cell>
        <Table.Cell>{data.credit_quality.b}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Below B</Table.Cell>
        <Table.Cell>{data.credit_quality.below_b}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Not Rated</Table.Cell>
        <Table.Cell>{data.credit_quality.not_rated}%</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

Credit.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Credit;
