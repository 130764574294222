import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { FundComparison as FundComparisonActions } from 'actions';
import { isGlobeInvestor } from 'utils/helpers';
import ComparisonContent from './../ComparisonContent';

@withRouter
class Result extends Component {
  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount = () => {
    window.scrollTo(0, 0);

    // resize charts on print
    this.state.mql.addListener(this.printListener);

    return false;
  };

  componentDidMount = () => {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  };

  componentWillUnmount = () => {
    window.clearTimeout(this.timeoutId);
    this.state.mql.removeListener(this.printListener);
  };

  printListener = (e) => {
    const { history } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else {
      history.goBack();
    }
  };

  updateStartEndDate = (startDate, endDate) => {
    const { dispatch } = this.props;
    dispatch(FundComparisonActions.updateGraphDates({ startDate, endDate }));
  };

  render() {
    const { securityTickerList, securityData, user_region, partnerName } =
      this.props;

    return (
      <PDF globe={isGlobeInvestor(partnerName)}>
        <Container centered>
          <Header size="large">Fund Comparison</Header>
          <ComparisonContent
            securityTickerList={securityTickerList}
            securityData={securityData}
            user_region={user_region}
            chartUpdateStartEndDate={this.updateStartEndDate}
            isLoading
            pdf
          />
        </Container>
      </PDF>
    );
  }
}

Result.propTypes = {
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.array.isRequired,
  history: PropTypes.object,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

Result.defaultProps = {
  history: {},
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  securityData: state.FundComparison.securityData,
  securityTickerList: Object.keys(state.FundComparison.securityData || {}),
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
}))(Result);

const PDF = styled.div`
  position: relative;
  background: white;
  text-align: center;
  padding: 2.5rem;
  border-radius: 8px;
  width: 1160px;
  margin: ${(props) =>
    props.globe ? 'auto auto 5px auto' : '-45px auto 5px auto'};

  @media only print {
    print-color-adjust: exact;

    @page {
      size: auto;
      margin: 0.5cm;
    }
  }
`;

const Container = styled.div`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;
