import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Popup, Flag } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { ACCOUNTS_REBALANCE } from '../utils/features';
import Feature from './Feature';

const getIconColor = (name) => {
  switch (name) {
    case 'warning':
      return colors.red;
    case 'rebal':
      return colors.orange;
    case 'linked':
      return colors.gray;
    default:
      return colors.yellow;
  }
};

const getFlag = (currency) => (currency === 'USD' ? 'us' : 'ca');

const Card = ({
  className,
  children,
  rebal,
  selectable,
  selected,
  warning,
  linked,
  header,
  content,
  icon,
  iconColor,
  color,
  hoverside,
  model,
  accoundId,
  balance,
  currency,
  ...rest
}) => (
  <StyledCard
    className={className}
    color={color}
    hoverside={hoverside}
    {...rest}
  >
    <div style={{ display: 'flex' }}>
      <IconBlock model={model}>
        {icon && <Icon style={{ fontSize: '50px', color }} name={`${icon}`} />}
      </IconBlock>
      <WordBlock>
        <CardHeader>
          {header}
          {typeof balance === 'string' && (
            <Flag style={{ marginLeft: '10px' }} name={getFlag(currency)} />
          )}
        </CardHeader>
        <CardContent>{content}</CardContent>
        {typeof balance === 'string' && (
          <CardHeader>{`$${balance}`}</CardHeader>
        )}
      </WordBlock>
    </div>
    <Icons>
      <Feature name={ACCOUNTS_REBALANCE}>
        <div style={{ marginBottom: '6px' }}>
          {warning && (
            <Popup
              trigger={
                <Link to={`/accounts/${accoundId}/rebalance`}>
                  <Icon
                    name="exclamation triangle"
                    className="warn"
                    style={{ fontSize: '20px', color: getIconColor('warning') }}
                  />
                </Link>
              }
              position="top center"
              content="Your attention is required for the rebalancing system."
            />
          )}
          {rebal && (
            <Popup
              trigger={
                <Icon
                  name="random"
                  className="warn"
                  style={{ fontSize: '20px', color: getIconColor('rebal') }}
                />
              }
              position="top center"
              content="A rebalancing system has been set up."
              wide
            />
          )}
        </div>
      </Feature>
      {linked && (
        <Popup
          trigger={
            <Icon
              name="linkify icon"
              className="warn"
              style={{ fontSize: '20px', color: getIconColor('linked') }}
            />
          }
          position="top center"
          content="Linked account"
          wide
        />
      )}
      {selectable && (
        <Icon
          name={selected ? 'check square' : 'square outline'}
          className="checkbox"
          size="large"
        />
      )}
    </Icons>
    {children}
  </StyledCard>
);

Card.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  warning: PropTypes.bool,
  rebal: PropTypes.bool,
  linked: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  color: PropTypes.string,
  hoverside: PropTypes.string,
  model: PropTypes.bool,
  accoundId: PropTypes.number,
  balance: PropTypes.string,
  currency: PropTypes.string,
  hover: PropTypes.string,
};

Card.defaultProps = {
  warning: false,
  rebal: false,
  linked: false,
  content: '',
  icon: '',
  iconColor: '',
  children: null,
  className: '',
  selected: false,
  selectable: false,
  color: 'red',
  hoverside: 'orange',
  model: false,
  accoundId: null,
  balance: null,
  currency: null,
  hover: '',
};

export default Card;

const StyledCard = styled(
  ({
    warning,
    remove,
    className,
    children,
    content,
    header,
    transparent,
    ...rest
  }) => (
    <NavLink className={className} {...rest}>
      {children}
    </NavLink>
  )
)`
  position: relative;
  display: inline-block;
  height: 10.5rem;
  max-height: 12rem;
  background-color: white;
  width: 45%;
  margin: 0px 10px 10px 0px;
  max-width: 50%;
  min-width: 20rem;
  padding: 1.5rem 1.2rem 1rem 2rem;
  color: inherit;
  border-bottom: 1px solid rgba(250, 250, 250, 0.5);
  border: ${(props) => `1px solid ${props.color}`};
  vertical-align: top;

  i:hover {
    color: ${(props) =>
      props.color === 'red'
        ? `${colors.white} !important;`
        : `${colors.black} !important;`};
  }

  &.active {
    background-color: ${(props) =>
      props.hover === '' ? props.color : props.hover};
    color: ${(props) =>
      props.color === 'red'
        ? `${colors.white} !important;`
        : `${colors.black} !important;`};
    border:  ${(props) =>
      props.hover === '' ? 'unset' : `1px solid ${props.color}`};

    &:after {
      background-color: ${(props) => props.hoverside};
      border: 1px solid ${(props) => props.hoverside};
    }

    i {
      color: ${(props) =>
        props.color === 'red'
          ? `${colors.white} !important;`
          : `${colors.black} !important;`};
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    background-color: ${(props) =>
      props.hover === ''
        ? props.color === 'red'
          ? colors.red
          : props.color
        : props.hover};
    color: ${(props) =>
      props.color === 'red'
        ? `${colors.white} !important;`
        : `${colors.black} !important;`};
    cursor: pointer;
    border: ${(props) => (props.color === 'red' ? 'unset' : 'init')};
    opacity: 1;

    i {
      color: ${(props) =>
        props.color === 'red'
          ? `${colors.white} !important;`
          : `${colors.black} !important;`};
    }

    &:after {
      background-color: ${(props) => props.hoverside};
      border: 1px solid ${(props) => props.hoverside};
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    background-color: ${(props) => props.color};

  @media only screen and (max-width: 850px) {
    min-width: initial;
    max-width: initial;
  }
`;

const CardHeader = styled.div`
  font-size: 1.14rem;
  font-weight: bold;
  word-wrap: break-word;
  padding-bottom: 0.2rem;
`;

const CardContent = styled.div`
  font-size: 1rem;
  line-height: 1.3rem;
  word-wrap: break-word;
  font-weight: normal !important;
  padding-bottom: 0.6rem;
`;

const Icons = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0.5rem;
  font-size: 18px;

  ${
    '' /* .warn {
    color: ${colors.orange} !important;
  } */
  }

  .checkbox {
    margin: -5px 0px 0px 10px;
  }
`;

const IconBlock = styled.div`
  display: inline-block;
  line-height: 4rem;
`;

const WordBlock = styled.div`
  display: inline-block;

  margin-left: 2rem;
  width: 75%;
`;
