import styled from 'styled-components';
import { colors } from 'utils/colors'; // Ensure this path is correct

// Styled table component
export const Table = styled.table`
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0;
  width: 80%;
  overflow-x: auto;

  tr {
    width: 100%;
  }
  tbody tr.filing-together td {
    background-color: rgba(250, 196, 123, 0.35);
    padding-left: 12px;
  }

  td,
  th {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    font-size: 1rem; // Default font size
    &.filing-together {
      background-color: rgba(250, 196, 123, 0.2);
    }

    &:first-child {
      text-align: left;
      border-left: 0;
      color: rgba(0, 0, 0, 0.8);
      width: 230px;
    }

    &:last-child {
      border-right: 0;
    }

    &.title {
      font-weight: 700;
      min-width: 12rem;
      text-align: left;
      font-size: 1.2rem; // Largest text size
    }

    &.short {
      width: 15%;
      max-width: 11rem;
      min-width: 7rem;
    }

    &.text {
      font-size: 1.1rem;
    }

    &.peter,
    &.mary {
      font-size: 1.1rem;
      background-color: rgba(108, 135, 252, 0.4);
    }

    &.section-header {
      font-weight: bold;
    }
  }

  th {
    background: #fafbfc;
    font-size: 1.1rem;

    &:first-child {
      border-top: 0;
    }
  }

  &.fixed {
    display: block;
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 168px;
    background: white;
    padding-top: 79px;

    td {
      border-right: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
`;

// Styled table wrapper component
export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
`;

// Styled paragraph component
export const P = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: ${(props) => (props.margin ? '1rem auto' : '0 auto')};
`;

// Styled flexible div component
export const Flex = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-around;
  text-align: center;
  margin: 2rem auto 0 auto;
  flex-wrap: wrap;

  &.margin {
    background: lightgreen;
  }

  &.circle {
    align-items: center;
    justify-content: space-around;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 2rem;

    &.blue {
      background: #2185d0;
    }

    &.orange {
      background: #f2711c;
    }
  }

  .flex-item {
    width: 45%;
    min-width: 300px;
    min-height: 250px;
    padding-bottom: 5rem;
  }
`;
