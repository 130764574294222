import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Icon, Header, Flag } from 'semantic-ui-react';
import { Segment, Table } from 'components';
import { colors } from 'utils/colors';
import { exchange } from 'utils/constants';
import { translateText } from '../../../utils/helpers';

class HoldingTable extends Component {
  state = {
    holdingTableColumn: 0,
  };

  getPriceFlag = (holding) => {
    const currency = {
      CAD: 'ca',
      USD: 'us',
    };

    // if security_detail does not exist, use yodlee currency for flag
    return holding.security_detail
      ? currency[holding.security_detail.default_currency]
      : currency[holding.default_currency];
  };

  getName = (holding) => {
    const { name, security_detail } = holding;

    if (name) {
      return name;
    }

    return security_detail ? security_detail.long_name : 'N/A';
  };

  getSecLink = (holding) => {
    const cik = holding.item.security_detail
      ? holding.item.security_detail.cik
        ? holding.item.security_detail.cik
        : false
      : false;

    // Check if cik is available and truthy, then construct the URL using template literals
    if (cik) {
      return `https://www.sec.gov/edgar/browse/?CIK=${cik}`;
    }
    return false;
  };

  getSector(holding) {
    const { french } = this.props;
    const { security_detail } = holding;
    if (!security_detail) {
      return '-';
    }
    const { sector } = security_detail;
    if (!sector) {
      return '-';
    }

    const sectorMapping = {
      consumer_discretionary: 'Consommation discrétionnaire',
      consumer_staples: 'Produits de base',
      energy: 'Énergie',
      financials: 'Finances',
      industrials: 'Industrie',
      materials: 'Matériaux',
      info_tech: 'Technologie de l’information',
      telecommunications: 'Télécommunications',
      telecom: 'Télécommunications',
      utilities: 'Services publics',
      healthcare: 'Soins de santé',
      real_estate: 'Immobilier',
    };

    if (french) {
      return sectorMapping[sector] || sector;
    }

    if (sector === 'info_tech') {
      return 'Info Tech';
    }

    const words = sector.split('_');
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const formattedSector = formattedWords.join('<br />');

    return formattedSector;
  }

  getAssetClass = (holding) => {
    const { security_detail } = holding.item;
    const { global_asset_class } = security_detail || {};
    const { french } = this.props;

    const assetClassMap = {
      Alternative: french ? 'Alternatif' : 'Alternative',
      Equity: french ? 'Capitaux propres' : 'Equity',
      'Fixed Income': french ? 'Revenu fixe' : 'Fixed Income',
      Cash: french ? 'Liquidités' : 'Cash',
      'Misc.': french ? 'Divers.' : 'Misc.',
      Other: french ? 'Autres' : 'Other',
    };

    if (global_asset_class) {
      // Normalize the key for lookup (optional, in case of case mismatch)
      const normalizedKey = global_asset_class.trim();

      return assetClassMap[normalizedKey] || 'N/A';
    }

    return 'N/A';
  };

  getMarketValue = (holding) => {
    const { account, individualAccount } = this.props;

    if (!holding.market_value && !holding.quantity) {
      return 'N/A';
    }

    // use our generated price if exists, else use yodlee price
    const price = holding.security_detail
      ? holding.security_detail.unadjusted_closing_price.CAD
      : holding.unadjusted_closing_price.CAD || 0;

    // use yodlee market_value if exists, else use our market_value
    let mktValue = holding.market_value || price * holding.quantity;

    if (individualAccount && account && account.currency === 'USD') {
      mktValue *= account.usd2cad;
    }

    return mktValue;
  };

  getMarketPercentage = (holding) => {
    const { totalMarketValue, account, individualAccount, user_region } =
      this.props;

    // Return null if total market value or holding quantity/market value is missing
    if (!totalMarketValue || (!holding.market_value && !holding.quantity)) {
      return null;
    }

    // Use market_value if available, else calculate it using unadjusted_closing_price
    let mktValue = holding.market_value;

    if (!mktValue) {
      const price = holding.security_detail
        ? holding.security_detail.unadjusted_closing_price.CAD
        : holding.unadjusted_closing_price.CAD || 0;
      mktValue = price * holding.quantity;
    }

    // Adjust for currency conversion if needed
    if (
      individualAccount &&
      account &&
      account.currency === 'USD' &&
      user_region === 'CA'
    ) {
      mktValue *= account.usd2cad;
    }

    // Calculate the percentage of total market value
    return `${parseFloat(
      (100 * (mktValue / totalMarketValue)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    )}%`;
  };

  getPrice = (holding) =>
    holding.security_detail
      ? this.formatNum(
          holding.security_detail.unadjusted_closing_price[
            holding.default_currency
          ],
          '$'
        )
      : this.formatNum(
          holding.unadjusted_closing_price[holding.default_currency],
          '$'
        );

  getDailyChange = (holding) => {
    const daily = holding.security_detail
      ? holding.security_detail.daily_change
      : holding.daily_change;

    return this.formatNum(daily, '%');
  };

  getGainAmountAndPercentage = (holding) => {
    const { isCombined, pwpaExchangeRate } = this.props;

    if (!holding.unrealized_gain_amount || !holding.unrealized_gain_percent) {
      return 'N/A';
    }

    let unrealizedGainAmount = holding.unrealized_gain_amount;

    // Overview, Group accounts have converted currency at Account.getAccountOverview, so this is only for single linked/manually-entered account
    if (holding.currency === 'USD') {
      unrealizedGainAmount *= pwpaExchangeRate;
    }

    return (
      <div
        style={{
          color:
            holding.unrealized_gain_amount < 0
              ? colors.red
              : holding.unrealized_gain_amount > 0
              ? colors.darkGreen
              : 'inherit',
        }}
      >
        {this.formatNum(unrealizedGainAmount, '$')}
        &nbsp;
        <Flag name="ca" />
        <br />
        {isCombined
          ? `${Number(
              (
                (holding.unrealized_gain_amount / holding.book_value) *
                100
              ).toFixed(2)
            ).toLocaleString()}%`
          : `${Number(
              (holding.unrealized_gain_percent * 100).toFixed(2)
            ).toLocaleString()}%`}
        {holding.unrealized_gain_amount < 0 ? (
          <Icon name="long arrow alternate down" />
        ) : null}
        {holding.unrealized_gain_amount > 0 ? (
          <Icon name="long arrow alternate up" />
        ) : null}
      </div>
    );
  };

  getBookValue = (holding) => {
    const { isCombined, pwpaExchangeRate } = this.props;

    if (!holding.book_value) {
      if (isCombined) {
        return (
          <div>
            N/A&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              wide
              content="The book value is missing in at least one account."
            />
          </div>
        );
      }

      return 'N/A';
    }

    let bookValue = holding.book_value;

    if (holding.currency === 'USD') {
      bookValue *= pwpaExchangeRate;
    }

    return (
      <div>
        {this.formatNum(bookValue, '$')}
        &nbsp;
        <Flag name="ca" />
      </div>
    );
  };

  round = (num) => Math.round(num * 10000) / 10000;

  formatNum = (num, sign) => {
    if ((!num && num !== 0) || num === 'N/A') {
      return 'N/A';
    }

    const formatted = Number(num.toFixed(2)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return sign === '$' ? `$${formatted}` : `${formatted}%`;
  };

  isUnmatched = (holding) => !holding.security_detail;

  isNoValue = (holding) => {
    // If no security_detail, catch by "isUnmatched"
    if (!holding.security_detail) {
      return false;
    }

    // use yodlee price if exists, else use our generated price
    const price = holding.unadjusted_closing_price[holding.default_currency]
      ? holding.unadjusted_closing_price[holding.default_currency]
      : holding.security_detail
      ? holding.security_detail.unadjusted_closing_price[
          holding.default_currency
        ]
      : 0;

    return !(holding.market_value || price * holding.quantity);
  };

  hasUsdAccount = (accountList) =>
    !!accountList.find((acc) => acc.currency === 'USD');

  tableMoveLeft = (tableLeftBoundary, isDisabled) => {
    if (!isDisabled) {
      this.props.decreaseTablePage();
    }
    this.setState({ holdingTableColumn: tableLeftBoundary });
  };

  tableMoveRight = (tableRightBoundary, isDisabled) => {
    if (!isDisabled) {
      this.props.increaseTablePage();
    }
    this.setState({ holdingTableColumn: tableRightBoundary });
  };

  isNegative = (holding) => {
    if (!holding.security_detail) {
      return true;
    }

    // use yodlee price if exists, else use our generated price
    const price = holding.unadjusted_closing_price[holding.default_currency]
      ? holding.unadjusted_closing_price[holding.default_currency]
      : holding.security_detail
      ? holding.security_detail.unadjusted_closing_price[
          holding.default_currency
        ]
      : 0;

    return !(holding.market_value || price * holding.quantity);
  };

  render() {
    const {
      holdings,
      title,
      account,
      accountList = [],
      partnerName,
    } = this.props;
    const filtered = holdings.reduce(
      (sorted, item, index) => {
        if (this.isUnmatched(item) || this.isNoValue(item)) {
          sorted.invalid.push({ key: index, item });
        } else {
          sorted.valid.push({ key: index, item });
        }

        return sorted;
      },
      {
        valid: [],
        invalid: [],
      }
    );
    const sortedHoldings = [...filtered.valid, ...filtered.invalid];
    const { holdingTableColumn } = this.state;
    const { french } = this.props;
    const tableLeftBoundary = 0;
    const tableRightBoundary = 5;
    const isWealthica = partnerName === 'wealthica';

    return (
      <Segment basic compact textAlign="center">
        <Header size="medium" content={title} />
        {!holdings.length ? (
          <Header
            disabled
            size="small"
            content={
              french
                ? account && account.provider === 'ydl'
                  ? 'Nous ne pouvons pas récupérer les titres pour ce compte'
                  : 'Ce compte ne contient aucun titre'
                : account && account.provider === 'ydl'
                ? 'We are unable to retrieve holdings for this account'
                : 'This account does not have any holdings'
            }
          />
        ) : isWealthica ? (
          <div style={{ position: 'relative' }}>
            <Table textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>
                    {french ? 'Symbole/nom' : 'Ticker/Name'}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    style={{
                      borderLeft: '2px solid #DADADA',
                      cursor:
                        holdingTableColumn === tableLeftBoundary
                          ? 'unset'
                          : 'pointer',
                    }}
                    onClick={() =>
                      this.tableMoveLeft(
                        tableLeftBoundary,
                        holdingTableColumn === tableLeftBoundary
                      )
                    }
                  >
                    <Icon
                      name="chevron left"
                      style={{
                        color:
                          holdingTableColumn === tableLeftBoundary
                            ? 'unset'
                            : colors.blue,
                        opacity:
                          holdingTableColumn === tableLeftBoundary
                            ? '0.1'
                            : '1',
                      }}
                    />
                  </Table.HeaderCell>
                  {holdingTableColumn < 1 && (
                    <Table.HeaderCell>
                      {french ? 'Classe d’actifs' : 'Asset Class'}
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn < 2 && (
                    <Table.HeaderCell>
                      {french ? 'Secteur' : 'Sector'}
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn < 3 && (
                    <Table.HeaderCell>
                      {french ? 'Quantité' : 'Quantity'}
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn < 4 && (
                    <Table.HeaderCell>
                      {french ? 'Prépondérance' : 'Weight'}
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn < 5 && (
                    <Table.HeaderCell>
                      {french ? 'Valeur à la cote' : 'Market Value '}
                      {((account && account.currency === 'USD') ||
                        this.hasUsdAccount(accountList)) && (
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          wide
                          content={
                            french
                              ? 'Converti en CAD pour regrouper des comptes en différentes devises. Sachez que cela n’affecte pas l’analyse du portefeuille du ou des comptes seulement en USD, car les pondérations de vos avoirs en tiennent compte.'
                              : 'Converted to CAD for the purpose of aggregating accounts in different currencies. Note that this does not affect USD account(s)-only portfolio analysis as the weights of your holdings are preserved.'
                          }
                        />
                      )}
                    </Table.HeaderCell>
                  )}

                  {holdingTableColumn > 0 && (
                    <Table.HeaderCell>
                      {french ? 'Valeur comptable totale' : 'Total Book Value'}
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn > 1 && (
                    <Table.HeaderCell>
                      Unrealized Gain/Loss{' '}
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="top center"
                        content={
                          french
                            ? 'Les avoirs qui n’ont pas de valeur comptable auront cette valeur définie sur « N/A ».'
                            : 'Holdings that don’t have a book value will have this value set to ‘N/A’.'
                        }
                      />
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn > 2 && (
                    <Table.HeaderCell>Traded in</Table.HeaderCell>
                  )}
                  {holdingTableColumn > 3 && (
                    <Table.HeaderCell>
                      {french ? 'Valeur' : 'Previous'}
                      <br />
                      {french ? 'à la fermeture' : 'Close'}
                    </Table.HeaderCell>
                  )}
                  {holdingTableColumn > 4 && (
                    <Table.HeaderCell>
                      {french ? 'Gain/perte' : 'Daily'}
                      <br />
                      {french ? 'quotidien' : 'Gain/Loss'}
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    width={1}
                    style={{
                      cursor:
                        holdingTableColumn === tableRightBoundary
                          ? 'unset'
                          : 'pointer',
                    }}
                    onClick={() =>
                      this.tableMoveRight(
                        tableRightBoundary,
                        holdingTableColumn === tableRightBoundary
                      )
                    }
                  >
                    <Icon
                      name="chevron right"
                      style={{
                        color:
                          holdingTableColumn === tableRightBoundary
                            ? 'unset'
                            : colors.blue,
                        opacity:
                          holdingTableColumn === tableRightBoundary
                            ? '0.1'
                            : '1',
                      }}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedHoldings.map((holding) => (
                  <Table.Row
                    key={holding.key}
                    negative={this.isNegative(holding.item)}
                  >
                    <Table.Cell
                      style={{
                        backgroundColor: 'rgb(249,250,251)',
                        position: 'relative',
                      }}
                    >
                      {this.getSecLink(holding) && isWealthica ? (
                        <Popup
                          trigger={
                            <a
                              href={this.getSecLink(holding)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {!!holding.item.security
                                ? holding.item.security
                                : holding.item.ticker || 'N/A'}
                            </a>
                          }
                          content="Click for SEC Filings"
                          position="right center"
                        />
                      ) : !!holding.item.security ? (
                        holding.item.security
                      ) : (
                        holding.item.ticker || 'N/A'
                      )}

                      <SecurityName negative={!holding.item.security_detail}>
                        {this.getName(holding.item)}
                      </SecurityName>
                      {this.isNegative(holding.item) && (
                        <Error>
                          <Popup
                            trigger={<Icon name="exclamation circle" />}
                            position="left center"
                            content={
                              french
                                ? "Nous n'avons pas pu faire correspondre cette valeur mobilière à notre base de données, elle ne sera donc pas utilisée dans l'analyse."
                                : 'Unfortunately, we could not match this security to our database, thus it will not be used in analysis.'
                            }
                          />
                        </Error>
                      )}
                    </Table.Cell>
                    <Table.Cell style={{ borderLeft: '2px solid #DADADA' }} />
                    {holdingTableColumn < 1 && (
                      <Table.Cell>{this.getAssetClass(holding)}</Table.Cell>
                    )}
                    {holdingTableColumn < 2 && (
                      <Table.Cell>
                        {holding.item.security_detail ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.getSector(holding.item),
                            }}
                          />
                        ) : (
                          'N/A'
                        )}
                      </Table.Cell>
                    )}
                    {holdingTableColumn < 3 && (
                      <Table.Cell>
                        {holding.item.quantity
                          ? parseFloat(holding.item.quantity.toFixed(2))
                          : 'N/A'}
                      </Table.Cell>
                    )}
                    {holdingTableColumn < 4 && (
                      <Table.Cell>
                        <div
                          style={{
                            color: !holding.item.security_detail
                              ? 'rgba(159,58,56, 0.6)'
                              : 'inherit',
                          }}
                        >
                          {`${this.getMarketPercentage(holding.item) || 'N/A'}`}
                        </div>
                      </Table.Cell>
                    )}
                    {holdingTableColumn < 5 && (
                      <Table.Cell>
                        {this.getMarketValue(holding.item) === 'N/A' ? (
                          'N/A'
                        ) : (
                          <div>
                            {this.formatNum(
                              this.getMarketValue(holding.item),
                              '$'
                            )}
                            &nbsp;
                            <Flag name="ca" />
                          </div>
                        )}
                      </Table.Cell>
                    )}

                    {holdingTableColumn > 0 && (
                      <Table.Cell>
                        {!holding.item
                          ? 'N/A'
                          : this.getBookValue(holding.item)}
                      </Table.Cell>
                    )}
                    {holdingTableColumn > 1 && (
                      <Table.Cell>
                        {!holding.item ? (
                          'N/A'
                        ) : (
                          <div>
                            {this.getGainAmountAndPercentage(holding.item)}
                          </div>
                        )}
                      </Table.Cell>
                    )}
                    {holdingTableColumn > 2 && (
                      <Table.Cell>
                        {holding.item.security_detail
                          ? translateText(
                              exchange[
                                holding.item.security_detail.exchange_symbol
                              ] || holding.item.security_detail.exchange_symbol,
                              french
                            )
                          : 'N/A'}
                      </Table.Cell>
                    )}
                    {holdingTableColumn > 3 && (
                      <Table.Cell>
                        {this.getPrice(holding.item) === 'N/A' ? (
                          'N/A'
                        ) : (
                          <div>
                            {this.getPrice(holding.item)}{' '}
                            <Flag name={this.getPriceFlag(holding.item)} />
                          </div>
                        )}
                      </Table.Cell>
                    )}
                    {holdingTableColumn > 4 && (
                      <Table.Cell>
                        {this.getDailyChange(holding.item)}
                      </Table.Cell>
                    )}
                    <Table.Cell />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <div style={{ position: 'relative' }}>
            <Table textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>
                    {french ? 'Symbole/nom' : 'Ticker/Name'}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {french ? 'Classe d’actifs' : 'Asset Class'}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {french ? 'Secteur' : 'Sector'}
                    <Popup
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content={
                        french
                          ? 'S’applique aux titres individuels'
                          : 'Applicable for Individual Stocks'
                      }
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {french ? 'Valeur à la cote' : 'Market Value'}
                    {((account && account.currency === 'USD') ||
                      this.hasUsdAccount(accountList)) && (
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                        content={
                          french
                            ? 'Converti en CAD pour regrouper des comptes en différentes devises. Sachez que cela n’affecte pas l’analyse du portefeuille du ou des comptes seulement en USD, car les pondérations de vos avoirs en tiennent compte.'
                            : 'Converted to CAD for the purpose of aggregating accounts in different currencies. Note that this does not affect USD account(s)-only portfolio analysis as the weights of your holdings are preserved.'
                        }
                      />
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {french ? 'Coté sur le' : 'Traded in'}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {french ? 'Valeur à la fermeture' : 'Previous Close'}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {french ? 'Gain/perte quotidien' : 'Daily Gain/Loss'}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedHoldings.map((holding) => (
                  <Table.Row
                    key={holding.key}
                    negative={
                      this.isUnmatched(holding.item) ||
                      this.isNoValue(holding.item)
                    }
                  >
                    <Table.Cell style={{ position: 'relative' }}>
                      {!!holding.item.ticker
                        ? holding.item.security
                        : holding.item.security || 'N/A'}
                      <SecurityName negative={!holding.item.security_detail}>
                        {this.getName(holding.item)}
                      </SecurityName>
                      {this.isUnmatched(holding.item) && (
                        <Error>
                          <Popup
                            trigger={<Icon name="exclamation circle" />}
                            position="left center"
                            content={
                              french
                                ? "Nous n'avons pas pu faire correspondre cette valeur mobilière à notre base de données, elle ne sera donc pas utilisée dans l'analyse."
                                : 'Unfortunately, we could not match this security to our database, thus it will not be used in analysis.'
                            }
                          />
                        </Error>
                      )}
                      {this.isNoValue(holding.item) && (
                        <Error>
                          <Popup
                            trigger={<Icon name="exclamation circle" />}
                            position="left center"
                            content={
                              french
                                ? "Aucune quantité ou valeur marchande n'a été récupérée pour ce titre, il ne sera donc pas inclus dans l'analyse."
                                : 'No quantity or market value was retrieved for this holding, thus it will not be included in the analysis.'
                            }
                          />
                        </Error>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {holding.item.security_detail
                        ? holding.item.security_detail.global_asset_class[0].toUpperCase() +
                          holding.item.security_detail.global_asset_class.slice(
                            1
                          )
                        : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {holding.item.security_detail ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.getSector(holding.item),
                          }}
                        />
                      ) : (
                        'N/A'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.formatNum(
                        this.getMarketValue(holding.item),
                        '$'
                      ) === 'N/A' ? (
                        'N/A'
                      ) : (
                        <div>
                          {this.formatNum(
                            this.getMarketValue(holding.item),
                            '$'
                          )}
                          &nbsp;
                          <Flag name="ca" />
                          <ValuePercentage
                            negative={!holding.item.security_detail}
                          >
                            {this.getMarketPercentage(holding.item)
                              ? `(${this.getMarketPercentage(holding.item)})`
                              : null}
                          </ValuePercentage>
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {holding.item.security_detail
                        ? translateText(
                            exchange[
                              holding.item.security_detail.exchange_symbol
                            ] || holding.item.security_detail.exchange_symbol,
                            french
                          )
                        : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getPrice(holding.item) === 'N/A' ? (
                        'N/A'
                      ) : (
                        <div>
                          {this.getPrice(holding.item)}{' '}
                          <Flag name={this.getPriceFlag(holding.item)} />
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>{this.getDailyChange(holding.item)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </Segment>
    );
  }
}

export default connect((state) => ({
  partnerName: state.Storage['partner-name'],
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  french: state.Storage.language === 'fr',
}))(HoldingTable);

HoldingTable.propTypes = {
  holdings: PropTypes.array,
  account: PropTypes.object,
  title: PropTypes.string,
  totalMarketValue: PropTypes.number,
  accountList: PropTypes.array,
  individualAccount: PropTypes.bool,
  isCombined: PropTypes.bool,
  partnerName: PropTypes.string,
  pwpaExchangeRate: PropTypes.number,
  increaseTablePage: PropTypes.func,
  decreaseTablePage: PropTypes.func,
  french: PropTypes.bool,
};

HoldingTable.defaultProps = {
  holdings: [],
  account: null,
  title: '',
  totalMarketValue: 0,
  accountList: [],
  individualAccount: false,
  isCombined: false,
  partnerName: '',
  pwpaExchangeRate: 0,
  increaseTablePage: () => {},
  decreaseTablePage: () => {},
  french: false,
};

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;

const ValuePercentage = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;
