import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Statistic, Header, Popup, Icon } from 'semantic-ui-react';

import { colors } from 'utils/colors';
import { Table, Score } from 'components';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class Income extends Component {
  getClass = (row) => {
    if (row.isExcluded || (row.dist_yield !== 0 && !row.dist_yield)) {
      return 'negative';
    }

    return false;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  renderHeader = () => (
    <div>
      Securities excluded from analysis due to insufficient history
      <Popup
        trigger={
          <Icon
            name="question circle outline"
            style={{
              fontSize: '15px',
              verticalAlign: 'initial',
              color: 'dimgrey',
            }}
          />
        }
        position="top center"
        style={{ maxWidth: '650px' }}
      >
        <Popup.Content>See "Overview" page for more information</Popup.Content>
      </Popup>
    </div>
  );

  render() {
    const { data, french } = this.props;

    return (
      <Container>
        <Score
          name={french ? 'Revenu ' : 'Income '}
          score={
            data.score.Income[0] || data.score.Income[0] === 0
              ? `${data.score.Income[0]}%`
              : null
          }
          grade={data.score.Income[1] ? data.score.Income[1] : 'N/A'}
          description={
            french
              ? 'Noté sur la base du rendement moyen pondéré des dividendes/distributions de votre portefeuille.'
              : "Scored on your portfolio's weighted average dividend/distribution yield. The maximum score is given to portfolios with a yield of 5% or more."
          }
          portfolio={data.portfolio}
        />

        <p>
          {french
            ? 'Le revenu désigne les dividendes liquides d’une action ou les distributions liquides d’un FNB ou d’un fonds commun.'
            : 'Income refers to cash dividends from a stock, or cash distributions from an ETF or a mutual fund.'}
          &nbsp;
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ margin: 0, color: 'dimgrey' }}
              />
            }
            position="top center"
            content={
              french
                ? "La note maximale est attribuée aux portefeuilles dont le rendement est supérieur ou égal à 5 %. - c'est-à-dire qu'un rendement de portefeuille de 5 % recevra un score de 100 %"
                : 'The score is calculated using a maximum yield of 5%, i.e., a portfolio yield of 5% will receive a 100% score.'
            }
            style={{ height: 'initial' }}
          />
        </p>
        <p>
          {french
            ? 'Avoir une note de revenu élevée dépend de votre objectif d’épargne. Si vous investissez à long terme ou si vous ne dépendez pas de votre portefeuille pour vos dépenses quotidiennes, un portefeuille à revenu élevé n’est pas nécessaire.'
            : 'Whether you desire a high income score depends on your saving objective. If you are investing for the long term, or if you are not dependent on your portfolio for everyday expenses, a high income portfolio is not necessary.'}
        </p>

        <Header
          dividing
          size="medium"
          content={
            french
              ? 'Distribution du rendement du portefeuille'
              : 'Portfolio Distribution Yield'
          }
        />

        <Table basic fixed textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">
                {french ? 'Actif' : 'Asset'}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {french ? 'Prépondérance' : 'Weight'}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {french ? 'Rendement' : 'Yield'}&nbsp;
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  content={
                    french
                      ? 'Rendement DDM (12 derniers mois)'
                      : 'TTM (Trailing 12-Month) Yield)'
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.tickersOriginal.map((row) => (
              <Table.Row key={row.ticker} negative={this.getClass(row)}>
                <Table.Cell textAlign="left">
                  {this.isLinked(data) ? row.name : row.wsName}
                </Table.Cell>
                <Table.Cell>
                  {this.getClass(row) ? (
                    <Error>
                      <span style={{ transform: 'translateX(1.5rem)' }}>
                        {Math.round(row.weight * 10000) / 100}%
                      </span>
                      <Popup
                        trigger={<Icon name="exclamation circle" />}
                        position="bottom center"
                        wide
                        content={
                          row.isExcluded ? (
                            <div>
                              <strong>{row.name}</strong>{' '}
                              {french
                                ? "a été exclu de l'analyse car il n'a pas suffisamment d'historique."
                                : 'has been excluded in the analysis because it has insufficient history.'}
                            </div>
                          ) : (
                            <div>
                              {french
                                ? 'Le rendement de distribution est manquant pour '
                                : 'The distribution yield is missing for '}
                              <strong>{row.name}</strong>;{' '}
                              {french
                                ? "veuillez contacter le fournisseur du fonds pour plus d'informations."
                                : 'please contact the fund provider for more information.'}
                            </div>
                          )
                        }
                      />
                    </Error>
                  ) : (
                    `${Math.round(row.weight * 10000) / 100}%`
                  )}
                </Table.Cell>
                <Table.Cell>
                  {row.dist_yield || row.dist_yield === 0
                    ? `${Math.round(row.dist_yield * 10000) / 100}%`
                    : `N/A`}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {!data.portYield && data.portYield !== 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                textAlign: 'center',
                color: colors.hintRed,
              }}
            >
              {french
                ? "Malheureusement, il n'y a pas de données de rendement pour ce portefeuille."
                : 'Unfortunately, there is no yield data for this portfolio.'}
            </p>
          </div>
        ) : null}
        <Statistic size="tiny" style={{ display: 'block' }}>
          <Statistic.Value>
            {data.portYield || data.portYield === 0
              ? `${(data.portYield * 100).toFixed(2)}%`
              : `N/A`}
          </Statistic.Value>
          <Statistic.Label>
            {french ? 'Rendement moyen pondéré' : 'Weighted Average Yield'}
          </Statistic.Label>
        </Statistic>
      </Container>
    );
  }
}

Income.propTypes = {
  data: PropTypes.object.isRequired,
  french: PropTypes.bool,
};

Income.defaultProps = { french: false };

const Error = styled.div`
  position: relative;
  text-align: center;

  span {
    font-size: 1rem;
  }

  i {
    color: ${colors.red};
    font-size: 1.4rem;
    position: absolute;
    top: 0;
    left: -1.5rem;
  }
`;

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
