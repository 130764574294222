import { Storage } from 'actions';

export default function (state = {}, action) {
  const { type, data } = action;

  switch (type) {
    case Storage.STORAGE_SET_ITEM:
      return { ...state, ...data };
    case Storage.STORAGE_REMOVE_ITEM:
      return Object.keys(state)
        .filter((key) => key !== data.key)
        .reduce((acc, key) => ({ ...acc, [key]: state[key] }), {});
    case Storage.STORAGE_CLEAR:
      return {};
    default:
      return state;
  }
}
