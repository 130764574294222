import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup, Icon, Header, Flag, Dropdown } from 'semantic-ui-react';
import ReactExport from 'react-data-export';
import styled from 'styled-components';
import { saveModalCharLimits, marginOfError } from 'utils/constants';
import { colors } from 'utils/colors';
import { Button, Table, Segment, SearchTicker, Modal } from 'components';
import { CacheInputs, FundAllocator, CustomAccount, Portfolio } from 'actions';
import {
  notWealthica,
  getTickerSearchResult,
  isGlobeInvestor,
} from 'utils/helpers';
import cn from 'classnames';
import Weight from './Weight';

const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

class FundAllocatorTool extends Component {
  state = {
    isOpen: false,
    errors: {},
    institution: '',
    name: '',
    isOnPortfolio: false,
    allNamesList: [],
  };
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    if (!portfolios.length) {
      dispatch(Portfolio.fetchModel());
      dispatch(Portfolio.fetchUser());
    }
    return false;
  };
  // eslint-disable-next-line consistent-return
  componentDidUpdate = (prevProps) => {
    if (prevProps.fundallocator && this.props.fundallocator) {
      const { fundallocator } = this.props || {};
      const { output } = fundallocator;

      if (output && prevProps.fundallocator.output !== output) {
        const notes = output._notes;
        if (notes.new_fund_allocation_failed) {
          return this.props.dispatch(
            CacheInputs.updateField('tools', 'fund_allocator', {
              error: { investment: true },
            })
          );
        }
        this.props.dispatch(
          CacheInputs.updateField('tools', 'fund_allocator', {
            cacheOutput: Object.keys(output)
              .filter((key) => key !== '_notes')
              .map((key) => ({
                [key]: output[key],
              })),
          })
        );
      }
    }
  };

  getCashLeft = () => {
    const { fundAllocatorInputs, fundallocator } = this.props;
    const { input_currency } = fundAllocatorInputs;
    const { output } = fundallocator;
    if (input_currency === 'USD') {
      return `${(
        -output._notes.extra_cash_cad_after / output._notes.usd2cad
      ).toFixed(2)} USD`;
    }
    return `${(-output._notes.extra_cash_cad_after).toFixed(2)} CAD`;
  };

  handleDropdownChange = (e, { value }) => {
    const { dispatch } = this.props;
    dispatch(
      CacheInputs.updateField('tools', 'fund_allocator', {
        input_currency: value,
      })
    );
  };

  handleChange = (e) => {
    const { dispatch, fundAllocatorInputs } = this.props;
    const { error } = fundAllocatorInputs;
    const { name, value } = e.target;

    if (value === '') {
      return dispatch(
        CacheInputs.updateField('tools', 'fund_allocator', { [name]: null })
      );
    }

    if (name === 'commissionfree') {
      dispatch(
        CacheInputs.updateField('tools', 'fund_allocator', {
          [name]: value,
          error: { ...error, [name]: null },
        })
      );
    }

    const num = Number(value);
    const limitRegex =
      name === 'commission' ? /^\d*\.?\d*$/ : /^[0-9]{1,9}(\.[0-9]{1,9})?$/;
    const negative = !limitRegex.test(num);

    if (!negative) {
      dispatch(
        CacheInputs.updateField('tools', 'fund_allocator', { [name]: num })
      );

      return dispatch(
        CacheInputs.updateField('tools', 'fund_allocator', {
          error: { ...error, [name]: null },
        })
      );
    }

    return false;
  };

  handleModalChange = (e) => {
    const { name, value } = e.target;
    if (name === 'institution') {
      if (value.length > saveModalCharLimits[name])
        this.setState({
          errors: {
            ...this.state.errors,
            institution: `Maximum ${saveModalCharLimits[name]} characters`,
          },
        });
      else {
        this.setState({
          errors: {
            ...this.state.errors,
            institution: null,
          },
        });
      }
    }
    if (name === 'name') {
      if (value.length > saveModalCharLimits.accountType)
        this.setState({
          errors: {
            ...this.state.errors,
            accountType: `Maximum ${saveModalCharLimits.accountType} characters`,
          },
        });
      else {
        this.setState({
          errors: {
            ...this.state.errors,
            accountType: null,
          },
        });
      }
    }
    return this.setState({ [name]: value });
  };

  handleResultSelect = (title) => {
    const { dispatch } = this.props;

    dispatch(
      CacheInputs.updateField('tools', 'fund_allocator', {
        value: title,
      })
    );

    return dispatch(FundAllocator.add(title));
  };

  handleSearchChange = (value) => {
    const { dispatch } = this.props;

    dispatch(CacheInputs.updateField('tools', 'fund_allocator', { value }));

    return (
      !!value.trim() &&
      dispatch(FundAllocator.search(window.encodeURIComponent(value)))
    );
  };

  remove = (ticker) => {
    const { dispatch } = this.props;

    return dispatch(FundAllocator.remove(ticker));
  };

  removeAll = () => this.props.dispatch(FundAllocator.removeAll());

  resetSearch = () =>
    this.props.dispatch(
      CacheInputs.updateField('tools', 'fund_allocator', { value: '' })
    );

  filterPortfolioCash = (portfolio) =>
    Object.keys(portfolio)
      .filter((key) => key !== 'CASH:MKT')
      .reduce((obj, key) => {
        obj[key] = portfolio[key];
        return obj;
      }, {});

  labelData = () => {
    const { fundallocator } = this.props;
    const totalValue = Object.values(fundallocator.portfolio).reduce(
      (total, value) => (total += Number(value.weight)),
      0
    );

    if (
      !totalValue ||
      (this.round(totalValue) >= 100 - marginOfError &&
        this.round(totalValue) <= 100 + marginOfError)
    ) {
      return false;
    }

    return `${this.round(Math.abs(100 - totalValue))}% ${
      100 - totalValue > 0 ? 'under' : 'over'
    }`;
  };

  getDropOptions = () => {
    const { portfolios, user_region } = this.props;
    const { allNamesList } = this.state;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    const filteredPortfolios = portfolios.filter(
      (portfolio) => !allNamesList.includes(portfolio.name)
    );

    return filteredPortfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      text:
        user_region === 'US' ? this.nameForUsVersion(portf.name) : portf.name,
    }));
  };

  nameForUsVersion = (name) => {
    if (name === 'WS All-In') {
      return '100 Equities / 0 Bonds';
    } else if (name === 'WS Adventurous') {
      return '80 Equities / 20 Bonds';
    } else if (name === 'WS Classic') {
      return '60 Equities / 40 Bonds';
    } else if (name === 'WS Cautious') {
      return '40 Equities / 60 Bonds';
    } else if (name === 'WS Playing-It-Safe') {
      return '20 Equities / 80 Bonds';
    }

    return name;
  };

  parseNum = (num) => num.toLocaleString(undefined, { maxFractionDigits: 2 });

  round = (num) => Math.round(num * 100) / 100;

  submit = (e) => {
    e.preventDefault();
    const { fundallocator, fundAllocatorInputs, dispatch, user_region } =
      this.props;
    const { portfolio } = fundallocator;
    const { investment, commission, commissionfree, input_currency } =
      fundAllocatorInputs;

    if (commissionfree > Object.keys(portfolio).length) {
      return dispatch(
        CacheInputs.updateField('tools', 'fund_allocator', {
          error: { commissionfree: true },
        })
      );
    }

    const tickers = Object.keys(portfolio).join(',');
    const quantities = Object.keys(portfolio)
      .reduce((result) => {
        result.push('0');

        return result;
      }, [])
      .join(',');
    const targetWeights = Object.values(portfolio)
      .reduce((result, value) => {
        if (value && value.weight) {
          result.push((value.weight / 100).toFixed(4));
        }

        return result;
      }, [])
      .join(',');

    const inputCurrency = () => {
      if (user_region === 'US') {
        return 'USD';
      }
      return input_currency || 'CAD';
    };

    const body = {
      tickers,
      quantities,
      target_weights: targetWeights,
      cash_currency: inputCurrency(),
      cash_amount: investment.toFixed(2),
      fee_per_trade: commission,
    };

    dispatch(
      CacheInputs.updateField('tools', 'fund_allocator', {
        cachePortfolio: { ...portfolio },
        cacheOutput: [],
      })
    );

    return dispatch(FundAllocator.calculate(body)).then(() => {
      this.scrollHandler(1000, 600);
      if (window.parentIFrame) {
        window.parentIFrame.scrollTo(0, 600);
      }
    });
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  validateSection = (section) => {
    const { fundAllocatorInputs } = this.props;
    const { error } = fundAllocatorInputs;
    const sections = {
      commissionSection: ['investment'],
      investment: ['investment'],
    };

    return !!Object.keys(error).find(
      (key) => sections[section].indexOf(key) !== -1 && error[key]
    );
  };

  isDisable = () => {
    const { fundallocator, fundAllocatorInputs } = this.props;
    const { portfolio } = fundallocator;
    const { investment, commission, commissionfree } = fundAllocatorInputs;

    if (!Object.keys(portfolio).length) {
      return true;
    }

    const hasZeorWeight = Object.values(
      this.filterPortfolioCash(portfolio)
    ).find((security) => !security.weight);

    if (hasZeorWeight) {
      return true;
    }

    if (!investment || commission === null) {
      return true;
    }

    if (
      commissionfree &&
      (!Number.isInteger(Number(commissionfree)) || commissionfree < 0)
    ) {
      return true;
    }

    return !!this.labelData();
  };

  createAccount = () => {
    const { name, institution } = this.state;
    const { dispatch, fundallocator, history, fundAllocatorInputs } =
      this.props;
    const { cacheOutput, input_currency } = fundAllocatorInputs;
    const { portfolio, output } = fundallocator;
    dispatch(CustomAccount.updateCash(0));
    dispatch(
      CustomAccount.createAccount({
        name,
        institution,
        currency: input_currency,
        label: name,
        user_institution: institution,
      })
    ).then((result) => {
      const account = result;
      const params = {
        account_id: account.id,
        cash_amount: !output._notes.extra_cash_cad_after
          ? 0
          : input_currency === 'USD'
          ? (
              -output._notes.extra_cash_cad_after / output._notes.usd2cad
            ).toFixed(2)
          : -output._notes.extra_cash_cad_after.toFixed(2),
        holdings: Object.keys(portfolio),
        tickers: Object.keys(portfolio).toString(),
        quantities: Object.values(this.filterPortfolioCash(portfolio))
          .map(
            (security, index) =>
              cacheOutput[index][security.ticker].rebalancing_buy
          )
          .toString(),
      };
      dispatch(CustomAccount.saveBulk(params));
      setTimeout(() => history.push(`/accounts/${account.id}`), 900);
    });
  };
  closeModal = () =>
    this.setState({
      isOpen: false,
    });

  handleDropdownSelection = (e, data) => {
    const { dispatch, portfolios } = this.props;
    const { value } = data;

    const selectedPortfolio = portfolios.find(
      (portfolio) => portfolio.id === value
    );
    if (!selectedPortfolio) return;

    const symbols = selectedPortfolio.symbols.split(',') || [];
    const weights =
      selectedPortfolio.weights
        .split(',')
        .map((weight) => parseFloat(weight) * 100) || [];

    symbols.forEach((symbol, index) => {
      const weight = weights[index] || 0;
      dispatch(
        CacheInputs.updateField('tools', 'fund_allocator', { value: symbol })
      );
      dispatch(FundAllocator.add(symbol, this.parseNum(weight)));
    });
  };

  handleResultSelect = (title) => {
    const { dispatch } = this.props;

    dispatch(
      CacheInputs.updateField('tools', 'fund_allocator', {
        value: title,
      })
    );

    return dispatch(FundAllocator.add(title));
  };

  renderOutput = () => {
    const { fundAllocatorInputs, fundallocator, pwpaExchangeRate } = this.props;
    const { cachePortfolio, cacheOutput } = fundAllocatorInputs;
    const { output } = fundallocator;

    const totalValue = cacheOutput.length
      ? cacheOutput.reduce((result, security) => {
          const price = Object.values(security)[0].security_price;
          const exchangeRate = Number(pwpaExchangeRate);
          const quanties = Object.values(security)[0].rebalancing_buy;
          const currency = Object.values(security)[0].security_currency;

          return (result +=
            currency === 'USD'
              ? price * quanties * exchangeRate
              : price * quanties);
        }, 0)
      : null;

    const result = {};
    for (const item of Object.values(this.filterPortfolioCash(cacheOutput))) {
      const ticker = Object.keys(item)[0];
      const security = item[ticker];
      if (this.filterPortfolioCash(cachePortfolio).hasOwnProperty(ticker)) {
        this.filterPortfolioCash(cachePortfolio)[ticker].quantity_buy =
          security.quantity_buy;
        this.filterPortfolioCash(cachePortfolio)[ticker].rebalancing_buy =
          security.rebalancing_buy;
        this.filterPortfolioCash(cachePortfolio)[ticker].security_currency =
          security.security_currency;
        this.filterPortfolioCash(cachePortfolio)[ticker].security_price =
          security.security_price;
        result[ticker] = this.filterPortfolioCash(cachePortfolio)[ticker];
      }
    }

    return cacheOutput.length ? (
      <Segment style={{ paddingBottom: '2rem' }}>
        <div
          style={{ fontSize: '1.28em', fontWeight: '700', textAlign: 'center' }}
        >
          Quantity of Shares to Purchase
        </div>
        <Table textAlign="center" style={{ marginBottom: '0rem' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                colSpan="2"
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Asset
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Exchange
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Class
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Price
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Weight
                <Popup
                  wide
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ fontSize: '15px', verticalAlign: 'initial' }}
                    />
                  }
                  position="top left"
                >
                  <Popup.Content>
                    There may be a discrepancy between your target weights and
                    the calculated weights as the number of shares are rounded
                    to the nearest whole number.
                  </Popup.Content>
                </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Quantity
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(this.filterPortfolioCash(cachePortfolio)).map(
              (security, index) => (
                <StyledRow
                  negative={!security.unadjusted_closing_price.CAD}
                  key={security.ticker}
                  style={{ height: 52, position: 'relative' }}
                >
                  <div>&nbsp;</div>
                  <Table.Cell width={5} style={{ position: 'relative' }}>
                    {security.ticker || 'N/A'}
                    <SecurityName>{security.long_name || 'N/A'}</SecurityName>
                    {!security.unadjusted_closing_price.CAD && (
                      <Error>
                        <Popup
                          trigger={
                            <Icon
                              style={{
                                transform: 'translate(-20px, -4px)',
                                position: 'absolute',
                                display: 'block',
                                marginTop: '0',
                              }}
                              name="exclamation circle"
                            />
                          }
                          position="left center"
                          content="Unfortunately, this security cannot be added as its price is $0"
                        />
                      </Error>
                    )}
                  </Table.Cell>
                  <Table.Cell>{security.exchange_symbol || 'N/A'}</Table.Cell>
                  <Table.Cell>
                    {security.global_asset_class || 'N/A'}
                  </Table.Cell>
                  <Table.Cell>
                    $
                    {this.round(
                      security.unadjusted_closing_price[
                        security.default_currency
                      ] || 0
                    )}
                    <Flag
                      name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                      style={{ marginLeft: '0.3rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {(() => {
                      const value =
                        cacheOutput[index][security.ticker]
                          .security_currency === 'USD'
                          ? cacheOutput[index][security.ticker]
                              .rebalancing_buy *
                            cacheOutput[index][security.ticker].security_price *
                            Number(pwpaExchangeRate)
                          : cacheOutput[index][security.ticker]
                              .rebalancing_buy *
                            cacheOutput[index][security.ticker].security_price;

                      return value / totalValue
                        ? `${((value / totalValue) * 100).toFixed(2)}%`
                        : '0';
                    })()}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <div style={{ color: colors.blue }}>
                      {cacheOutput[index][security.ticker].rebalancing_buy}
                    </div>
                  </Table.Cell>
                </StyledRow>
              )
            )}
          </Table.Body>
        </Table>
        {output._notes.extra_cash_cad_after && (
          <Header.Subheader style={{ textAlign: 'center', marginTop: '1rem' }}>
            {`There is an extra $${this.getCashLeft()} in cash leftover after the allocation.`}
          </Header.Subheader>
        )}

        <div
          style={{
            display: 'flex',
            margin: '20px auto 5px',
            justifyContent: 'center',
          }}
        >
          <ExcelFile
            element={
              <Button
                className={cn('left grey-focus2', {
                  disabled: this.isDisable(),
                })}
                icon="download"
                color={colors.red}
              >
                Download CSV
              </Button>
            }
            filename="Fund_Allocation"
          >
            <ExcelSheet data={Object.values(result)} name="Allocation">
              <ExcelColumn label="Ticker Symbol" value="ticker" />
              <ExcelColumn label="Security Long Name" value="long_name" />
              <ExcelColumn label="Exchange" value="exchange_symbol" />
              <ExcelColumn label="Class" value="global_asset_class" />
              <ExcelColumn label="Default Currency" value="default_currency" />
              <ExcelColumn label="Price" value="security_price" />
              <ExcelColumn label="Weight" value="weight" />
              <ExcelColumn label="Quantity" value="rebalancing_buy" />
            </ExcelSheet>
          </ExcelFile>
          {/* <Button
          className={cn('left grey-focus2', { disabled: this.isDisable() })}
          color={colors.green}
          icon="save"
          style={{ display: 'block', margin: '20px auto 5px' }}
          onClick={() => this.setState({ isOpen: true })}
        >
          Save as New Account
        </Button> */}
        </div>
      </Segment>
    ) : null;
  };

  render() {
    const {
      fundallocator,
      fundAllocatorInputs,
      dispatch,
      user_region,
      partnerName,
      portfolios,
    } = this.props;
    const { isOpen, isOnPortfolio } = this.state;
    const { investment, commission, error } = fundAllocatorInputs;
    const { portfolio, search } = fundallocator;
    const dropdownOptions = [
      { key: 'CAD', value: 'CAD', text: 'CAD', flag: 'ca' },
      { key: 'USD', value: 'USD', text: 'USD', flag: 'us' },
    ];

    return (
      <div
        style={{
          border: isGlobeInvestor(partnerName)
            ? '1px solid rgba(0, 0, 0, 0.2)'
            : 'none',
        }}
      >
        <Segment style={{ paddingBottom: '3rem' }}>
          <div>
            {isGlobeInvestor(partnerName) ? null : (
              <Header
                content="Fund Allocator"
                subheader={
                  isGlobeInvestor(partnerName)
                    ? ''
                    : "This tool is for investors who are setting up a new account and want to invest in a portfolio with target weights in mind. It will calculate the number of shares that you should purchase net of trading commissions. Please note that the security prices used in the calculation will be the previous day's market close. As such, the results are approximations only."
                }
                size="large"
                textAlign="center"
                style={{ marginBottom: '2rem' }}
              />
            )}
            <Wrapper globe={isGlobeInvestor(partnerName)}>
              <Section single>
                {isGlobeInvestor(partnerName) ? null : (
                  <div className="heading">Input your Portfolio</div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '1.28em', fontWeight: '700' }}>
                    {isGlobeInvestor(partnerName)
                      ? 'Enter Portfolio assets and proportional weights'
                      : 'Find your securities and enter their corresponding weights'}
                  </div>
                  <Note>
                    {isGlobeInvestor(partnerName) ? null : (
                      <React.Fragment>
                        {user_region === 'CA'
                          ? 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'
                          : 'Note: Currently, we only have data for US-listed stocks and ETFs, and US mutual funds.'}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
                        />
                        Always check the fund code or ticker symbol to ensure
                        you have the correct share class. Use US instead of
                        U.S..
                      </React.Fragment>
                    )}
                  </Note>

                  <PortfoliosToggle>
                    {isOnPortfolio ? (
                      <div>
                        {portfolios.length === 0 && (
                          <div style={{ marginBottom: '5px', color: 'red' }}>
                            You currently have no saved portfolios.
                          </div>
                        )}
                        <div
                          className="wrapper"
                          style={{ width: '100%', marginBottom: '0.5rem' }}
                        >
                          <Popup
                            content="Securities"
                            trigger={
                              <span
                                className={cn('toggle', {
                                  active: !isOnPortfolio,
                                })}
                                onClick={() =>
                                  this.setState({ isOnPortfolio: false })
                                }
                              >
                                Search Tickers
                                <Icon
                                  fitted
                                  className="icon-money"
                                  style={{ marginLeft: '0.3rem' }}
                                />
                              </span>
                            }
                            position="top center"
                            style={{
                              padding: '0.4rem 0.5rem',
                              pointerEvents: 'none',
                            }}
                          />
                          <Popup
                            content="Portfolios"
                            trigger={
                              <span
                                className={cn('toggle', {
                                  active: !!isOnPortfolio,
                                })}
                                onClick={() =>
                                  this.setState({ isOnPortfolio: true })
                                }
                              >
                                <Icon
                                  fitted
                                  name="folder open"
                                  style={{ marginRight: '0.3rem' }}
                                />
                                Saved Portfolios
                              </span>
                            }
                            position="top center"
                            style={{
                              padding: '0.4rem 0.5rem',
                              pointerEvents: 'none',
                            }}
                          />
                        </div>
                        <Dropdown
                          placeholder="Select a portfolio"
                          selection
                          options={this.getDropOptions()}
                          disabled={
                            portfolios.length === 0 ||
                            this.getDropOptions().length === 0
                          }
                          selectOnBlur={false}
                          value=""
                          onChange={this.handleDropdownSelection}
                          style={{
                            width: '100%',
                            margin: '0.7rem',
                            maxWidth: '20rem',
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <div
                          className="wrapper"
                          style={{ width: '100%', marginBottom: '0.5rem' }}
                        >
                          <Popup
                            content="Securities"
                            trigger={
                              <span
                                className={cn('toggle', {
                                  active: !isOnPortfolio,
                                })}
                                onClick={() =>
                                  this.setState({ isOnPortfolio: false })
                                }
                              >
                                Search Tickers
                                <Icon
                                  fitted
                                  className="icon-money"
                                  style={{ marginLeft: '0.3rem' }}
                                />
                              </span>
                            }
                            position="top center"
                            style={{
                              padding: '0.4rem 0.5rem',
                              pointerEvents: 'none',
                            }}
                          />
                          <Popup
                            content="Portfolios"
                            trigger={
                              <span
                                className={cn('toggle', {
                                  active: !!isOnPortfolio,
                                })}
                                onClick={() =>
                                  this.setState({ isOnPortfolio: true })
                                }
                              >
                                <Icon
                                  fitted
                                  name="folder open"
                                  style={{ marginRight: '0.3rem' }}
                                />
                                Saved Portfolios
                              </span>
                            }
                            position="top center"
                            style={{
                              padding: '0.4rem 0.5rem',
                              pointerEvents: 'none',
                            }}
                          />
                        </div>
                        <SearchTicker
                          loading={fundallocator.isFetching}
                          results={getTickerSearchResult(search)}
                          customResultSelect={this.handleResultSelect}
                          customSearchChange={this.handleSearchChange}
                          setMargin
                          style={{
                            display: 'inline-block',
                            margin: '0.7rem',
                            textAlign: 'left',
                          }}
                        />
                      </div>
                    )}
                  </PortfoliosToggle>

                  {!!Object.keys(this.filterPortfolioCash(portfolio))
                    .length && (
                    <Segment
                      className="basic compact"
                      style={{
                        marginTop: '2rem',
                        padding: '0rem',
                        marginBottom: '0rem',
                        opacity: fundallocator.isFetching ? 0.5 : 1,
                      }}
                    >
                      <RemoveAll onClick={this.removeAll}>clear</RemoveAll>
                      <Table
                        textAlign="center"
                        style={{
                          marginBottom: '0rem',
                          borderColor: !!this.labelData() && colors.red,
                        }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              colSpan="2"
                              style={{ background: 'rgba(203,232,238,0.3)' }}
                            >
                              Asset
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ background: 'rgba(203,232,238,0.3)' }}
                            >
                              Exchange
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ background: 'rgba(203,232,238,0.3)' }}
                            >
                              Class
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ background: 'rgba(203,232,238,0.3)' }}
                            >
                              Price
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ background: 'rgba(203,232,238,0.3)' }}
                            >
                              Weight
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Object.values(
                            this.filterPortfolioCash(portfolio)
                          ).map((security) => (
                            <StyledRow
                              negative={!security.unadjusted_closing_price.CAD}
                              key={security.ticker}
                              style={{ height: 52, position: 'relative' }}
                            >
                              <i
                                className="close"
                                onClick={() => this.remove(security.ticker)}
                              >
                                +
                              </i>
                              <Table.Cell
                                width={5}
                                style={{ position: 'relative' }}
                              >
                                {security.ticker || 'N/A'}
                                <SecurityName>
                                  {security.long_name || 'N/A'}
                                </SecurityName>
                                {!security.unadjusted_closing_price.CAD && (
                                  <Error>
                                    <Popup
                                      trigger={
                                        <Icon
                                          style={{
                                            transform: 'translate(-20px, -4px)',
                                            position: 'absolute',
                                            display: 'block',
                                            marginTop: '0',
                                          }}
                                          name="exclamation circle"
                                        />
                                      }
                                      position="left center"
                                      content="Unfortunately, this security cannot be added as its price is $0"
                                    />
                                  </Error>
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {security.exchange_symbol || 'N/A'}
                              </Table.Cell>
                              <Table.Cell>
                                {security.global_asset_class || 'N/A'}
                              </Table.Cell>
                              <Table.Cell>
                                $
                                {this.round(
                                  security.unadjusted_closing_price[
                                    security.default_currency
                                  ] || 0
                                )}
                                <Flag
                                  name={
                                    security.default_currency === 'CAD'
                                      ? 'ca'
                                      : 'us'
                                  }
                                  style={{ marginLeft: '0.3rem' }}
                                />
                              </Table.Cell>
                              <Table.Cell width={2}>
                                {!!security.unadjusted_closing_price.CAD ? (
                                  <Weight
                                    ticker={security.ticker}
                                    value={security.weight}
                                  />
                                ) : (
                                  'N/A'
                                )}
                              </Table.Cell>
                            </StyledRow>
                          ))}
                        </Table.Body>
                      </Table>
                      <Segment
                        style={{
                          margin: '0',
                          padding: '0',
                          transform: 'translateY(3rem)',
                        }}
                      >
                        {!!this.labelData() && (
                          <Label>{this.labelData()}</Label>
                        )}
                      </Segment>
                    </Segment>
                  )}
                </div>
              </Section>
              <Section
                single
                className={cn({
                  invalid: this.validateSection('investment'),
                })}
              >
                <div className="heading">Portfolio Size and Commission</div>
                <Field>
                  <label>How much are you allocating to this portfolio?</label>
                  {error.investment ? (
                    <label style={{ color: colors.red }}>
                      (Insufficient capital allocated to fulfill target weights.
                      Please adjust your inputs.)
                    </label>
                  ) : null}
                  <input
                    className={cn({ invalid: error.investment })}
                    name="investment"
                    type="number"
                    placeholder="$"
                    min="1"
                    value={investment || ''}
                    onChange={this.handleChange}
                    onClick={() =>
                      dispatch(
                        CacheInputs.updateField('tools', 'fund_allocator', {
                          error: { ...error, investment: null },
                        })
                      )
                    }
                  />
                </Field>
                <Field>
                  <label>
                    What is the commission per trade?
                    <Popup
                      hoverable
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content="If your broker charges by the share, enter the maximum commission."
                      style={{ textAlign: 'justify' }}
                    />
                  </label>
                  <input
                    className={cn({ invalid: error.commission })}
                    name="commission"
                    type="number"
                    placeholder="$"
                    value={commission === 0 ? '0' : commission || ''}
                    min="0"
                    onChange={this.handleChange}
                  />
                </Field>
                {user_region !== 'US' && (
                  <Field>
                    <label>Is this for a CAD or USD account?</label>
                    <Dropdown
                      style={{ maxWidth: '100px', height: '28.64px' }}
                      className="dropdown"
                      name="input_currency"
                      fluid
                      selection
                      options={dropdownOptions}
                      defaultValue={dropdownOptions[0].value}
                      onChange={this.handleDropdownChange}
                    />
                  </Field>
                )}
                {/* <Field>
                <label>
                  How many of the securities will be commission-free?
                  <Popup
                    wide
                    hoverable
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Some brokerages waive the commission for certain ETFs. Please check with your broker."
                    style={{ textAlign: 'justify' }}
                  />
                </label>
                {error.commissionfree ? (
                  <label style={{ color: colors.red }}>
                    (The number of securities commission-free needs to be less
                    than total securities.)
                  </label>
                ) : null}
                <input
                  className={cn({ invalid: error.commissionfree })}
                  name="commissionfree"
                  type="number"
                  min="0"
                  value={commissionfree === 0 ? '0' : commissionfree || ''}
                  onChange={this.handleChange}
                  onClick={() =>
                    dispatch(
                      CacheInputs.updateField('tools', 'fund_allocator', {
                        error: { ...error, commissionfree: null },
                      })
                    )
                  }
                />
              </Field> */}
              </Section>
              <Button
                className={cn('left', { disabled: this.isDisable() })}
                color={colors.blue}
                icon="calculator"
                style={{ display: 'block', margin: '20px auto 5px' }}
                onClick={this.submit}
              >
                Calculate
              </Button>
            </Wrapper>
            {isGlobeInvestor(partnerName) ? this.renderOutput() : null}
          </div>
        </Segment>

        {!isGlobeInvestor(partnerName) && this.renderOutput()}
        <Modal basic open={isOpen}>
          <Modal.Header>
            <Header icon="book" content="Name New Account" color="green" />
          </Modal.Header>
          <div style={{ maxWidth: '800px', minWidth: '540px' }}>
            <Modal.Content>
              <ModalField
                style={{ position: 'relative' }}
                vb={notWealthica(partnerName)}
              >
                Institution:
                <input
                  placeholder="(required)"
                  name="institution"
                  value={this.state.institution || ''}
                  onChange={this.handleModalChange}
                />
                {this.state.institution.trim() &&
                  this.state.errors.institution && (
                    <SaveModalErrorLabel>
                      {this.state.errors.institution}
                    </SaveModalErrorLabel>
                  )}
              </ModalField>
              <ModalField
                style={{ position: 'relative' }}
                vb={notWealthica(partnerName)}
              >
                Account type:
                <input
                  placeholder="(required)"
                  name="name"
                  value={this.state.name || ''}
                  onChange={this.handleModalChange}
                />
                {this.state.name.trim() && this.state.errors.accountType && (
                  <SaveModalErrorLabel
                    style={{
                      top: '8px',
                    }}
                  >
                    {this.state.errors.accountType}
                  </SaveModalErrorLabel>
                )}
              </ModalField>
              {/* <Field>	
                Currency:	
                <div style={{ width: '80%', float: 'right' }}>	
                  <StyledDropdown	
                    className={cn({ invalid: null })}	
                    placeholder="Currency"	
                    name="currency"	
                    selection	
                    options={[	
                      {	
                        key: 'cad',	
                        text: 'CAD',	
                        value: 'CAD',	
                        icon: <Flag name="ca" />,	
                      },	
                      {	
                        key: 'usd',	
                        text: 'USD',	
                        value: 'USD',	
                        icon: <Flag name="us" />,	
                      },	
                    ]}	
                    value={this.state.currency || ''}	
                    onChange={(e, data) =>	
                      this.setState({ currency: data.value })	
                    }	
                    style={{ width: '95px' }}	
                  />	
                </div>	
              </Field> */}
            </Modal.Content>
            <Modal.Actions>
              <Button
                className="left"
                color={colors.red}
                icon="cancel"
                margin="0 0.5rem 0 0"
                onClick={() => this.setState({ isOpen: false })}
              >
                Cancel
              </Button>
              <Button
                className={cn('left', {
                  loading: this.state.loading,
                })}
                color={colors.green}
                icon="checkmark"
                onClick={this.createAccount}
              >
                Save
              </Button>
            </Modal.Actions>
          </div>
        </Modal>
      </div>
    );
  }
}

FundAllocatorTool.propTypes = {
  fundallocator: PropTypes.object,
  fundAllocatorInputs: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  user_region: PropTypes.string,
  portfolios: PropTypes.array,
  pwpaExchangeRate: PropTypes.number,
  history: PropTypes.object,
  partnerName: PropTypes.string,
};

FundAllocatorTool.defaultProps = {
  dispatch: () => false,
  fundallocator: {},
  user_region: 'CA',
  portfolios: [],
  pwpaExchangeRate: 0,
  history: {},
  partnerName: '',
};

export default connect((state) => ({
  fundallocator: state.FundAllocator,
  fundAllocatorInputs: state.CacheInputs.tools.fund_allocator,
  user_region: state.Storage.user_region || 'CA',
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  partnerName: state.Storage['partner-name'],
}))(FundAllocatorTool);

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative !important;

  .close {
    position: absolute;
    margin: 20px 0 0 -10px;
    z-index: 100;
    display: block;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => !props.globe && '55rem'};
  padding: ${(props) => (props.globe ? '0' : '1rem')};
  margin: ${(props) => (props.globe ? '0' : '3rem auto 0 auto')};
  border: ${(props) => (props.globe ? 'white' : `1px solid ${colors.blue}`)};
  border-radius: 8px;
  overflow: visible;
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 1rem 0;
`;

const Field = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 38px;

  label {
    display: block;
    width: 100%;
    max-width: 80%;
  }

  .dropdown {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.9rem;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 100px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;
    min-height: 2.7142em;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const RemoveAll = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  width: 50px;
  font-size: 0.9rem;
  border-radius: 10px;
  line-height: 20px;
  border: 1px solid #dededf;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 1px solid #db2828;
    background: #fff6f6;
  }
`;

const Label = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 32px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  padding: 1px 20px;
  font-size: 0.9rem;
  background: ${colors.red};
  text-align: center;
  border-radius: 12px;
`;

const Section = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 2rem auto;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Error = styled.div`
  color: red;
`;

const SaveModalErrorLabel = styled.div`
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  text-transform: initial;
  top: -6px;
  right: -155px;
  left: auto;
  width: fit-content;
  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(3%, 0) rotate(45deg);
    background: inherit;
    left: -5px;
    margin-top: -4px;
    top: 50%;
    border-width: 0 0 1px 1px;
    border-style: solid;
  }
`;

const ModalField = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;
  line-height: 1.3em;
  border-bottom: 1px solid grey;
  padding: 0 0 1rem 0;

  &:nth-child(2) {
    padding: 1rem 0;
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;

const PortfoliosToggle = styled.div`
  height: 5rem;
  position: relative;
  margin: 0 0 1rem 0;

  img {
    height: 17px;
    width: 17px;
    vertical-align: middle;
    margin: 0 0 2px 7px;
    transform: rotate(43deg);
  }

  .wrapper {
    width: 20rem;
    top: 0;
    left: 0;
  }

  .toggle {
    padding: 0.3rem 0.7rem;
    cursor: pointer;
    font-size: 1rem;
    background-color: white;
    border: 1px solid #dcdcdc;
    user-select: none;
    -moz-box-shadow: inset 0 0 4px ${colors.lightGrey};
    -webkit-box-shadow: inset 0 0 4px ${colors.lightGrey};
    box-shadow: inset 0 0 4px ${colors.lightGrey};

    &.active {
      background-color: ${colors.teal};
      border: 1px solid transparent;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      border: 1px solid ${colors.teal};
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`;
