import * as Alert from './alert';
import * as Storage from './storage';
import { ANALYTICS_UPDATE_PORTFOLIO } from './analytics';
import { request } from './api';

/**
 * Action constants
 */
export const PORTFOLIO_REQUEST = 'PORTFOLIO_REQUEST';

export const PORTFOLIO_USER_SUCCESS = 'PORTFOLIO_USER_SUCCESS';
export const PORTFOLIO_MODEL_SUCCESS = 'PORTFOLIO_MODEL_SUCCESS';
export const PORTFOLIO_REMOVE_SUCCESS = 'PORTFOLIO_REMOVE_SUCCESS';
export const PORTFOLIO_UPDATE_SUCCESS = 'PORTFOLIO_UPDATE_SUCCESS';

export const PORTFOLIO_EDITING_UPDATE = 'PORTFOLIO_EDITING_UPDATE';

export const PORTFOLIO_FAIL = 'PORTFOLIO_FAIL';

/**
 * Action creators
 */
export function fetchUser() {
  return (dispatch) => {
    dispatch({ type: PORTFOLIO_REQUEST });

    return dispatch(request('get', '/portfolio/')).then(
      (data) => dispatch({ type: PORTFOLIO_USER_SUCCESS, data }),
      (error) => {
        dispatch({ type: PORTFOLIO_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchModel() {
  return (dispatch) => {
    dispatch({ type: PORTFOLIO_REQUEST });

    return dispatch(request('get', '/portfolio/modelportfolio/')).then(
      (data) => dispatch({ type: PORTFOLIO_MODEL_SUCCESS, data }),
      (error) => {
        dispatch({ type: PORTFOLIO_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function remove(id) {
  return (dispatch) =>
    dispatch(request('delete', `/portfolio/${id}/`)).then(
      () => {
        dispatch({ type: PORTFOLIO_REMOVE_SUCCESS, id });

        return dispatch(
          Alert.show({ type: 'success', msg: 'Portfolio deleted' })
        );
      },
      (error) => dispatch(Alert.show({ type: 'error', msg: parseErr(error) }))
    );
}

export function save(body, history, redirectToSavedPortfolioOnSuccess = true) {
  return (dispatch) =>
    dispatch(request('post', '/portfolio/', { body })).then(
      (data) => {
        dispatch(Alert.show({ type: 'success', msg: 'Portfolio saved' }));
        if (redirectToSavedPortfolioOnSuccess)
          dispatch({ type: ANALYTICS_UPDATE_PORTFOLIO, portfolio: data });
        dispatch(fetchUser());
        if (history && redirectToSavedPortfolioOnSuccess) {
          return history.push(`/portfolio/${data.id}/overview`);
        }

        return false;
      },
      (error) => dispatch(Alert.show({ type: 'error', msg: parseErr(error) }))
    );
}

export function update(id, body) {
  return (dispatch) =>
    dispatch(request('put', `/portfolio/${id}/`, { body })).then(
      (data) => {
        dispatch({ type: PORTFOLIO_UPDATE_SUCCESS, data });

        return dispatch(
          Alert.show({ type: 'success', msg: 'Portfolio updated' })
        );
      },
      (error) => ({ err: error.detail || 'Server Offline' })
    );
}

export function setEditing(id, name, description) {
  return (dispatch) => {
    dispatch({ type: PORTFOLIO_EDITING_UPDATE, id, name, description });
  };
}

/**
For just one-click cases ("Analyze" of Accounts, saved Portfolios, and Model portfolios), redirect to the Portfolio landing page when return from other sectors.
For more than one click cases (Portfolio builders), redirect to Scorecard when return from other sectors and it can keep availability of the pencil(Edit) on the Scorecard's sidebar.
This function is for one-click cases.
 */
export function notSaveUrlSc() {
  return (dispatch) => {
    dispatch(Storage.setItem('not_save_url_scorecard', true));
  };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
