import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';

import { ExposureChart, ExposureTable } from '../components';
import { translateText } from '../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class IndividualAsset extends Component {
  getChartData = () => {
    const { data, french } = this.props;
    const { tickersValid, riskCont } = data;
    const sorted = Object.keys(tickersValid).sort(
      (a, b) => riskCont.asset[b] - riskCont.asset[a]
    );
    const labels = sorted.map((key) => this.getName(key));
    const risk = sorted.map((key) => riskCont.asset[key]);
    const otherRisk = sorted.slice(10).reduce((total, key) => {
      if (key) {
        total += riskCont.asset[key];
      }

      return total;
    }, 0);
    const weight = sorted.map((key) => tickersValid[key].weight);
    const otherWeight = sorted.slice(10).reduce((total, key) => {
      if (key) {
        total += tickersValid[key].weight;
      }

      return total;
    }, 0);

    return {
      labels: otherRisk
        ? [...labels.slice(0, 10), translateText('All Other Holdings', french)]
        : labels,
      risk: otherRisk ? [...risk.slice(0, 10), otherRisk] : risk,
      weight: otherRisk ? [...weight.slice(0, 10), otherWeight] : weight,
    };
  };

  getName = (ticker) => {
    const { data } = this.props;
    const { tickersValid } = data;

    return this.isLinked(data)
      ? tickersValid[ticker].name
      : tickersValid[ticker].wsName;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  render() {
    const { data, french } = this.props;

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content={
            french
              ? 'Analyse de la contribution au risque'
              : 'Risk Contribution Analysis'
          }
          subheader={french ? 'Par actif individuel' : 'By Individual Asset'}
        />

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content={french ? 'Répartition des actifs' : 'Asset Allocation'}
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                position="bottom"
                graphHeight={
                  Object.keys(data.tickersValid).length > 10 ? 450 : 0
                }
                labels={this.getChartData().labels}
                data={this.getChartData().weight}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                size="medium"
                content={
                  french ? 'Contribution au risque' : 'Risk Contribution'
                }
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                position="bottom"
                graphHeight={
                  Object.keys(data.tickersValid).length > 10 ? 450 : 0
                }
                labels={this.getChartData().labels}
                data={this.getChartData().risk}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ExposureTable
          align="left"
          headers={[
            french ? 'Actif' : 'Asset',
            french ? 'Répartition des actifs' : 'Asset Allocation',
            <div>
              {french ? 'Contribution au risque' : 'Risk Contribution'}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
                content={
                  french
                    ? "Une contribution au risque négative se produit lorsqu'un actif à faible volatilité a une corrélation négative avec les autres actifs. (En termes statistiques, lorsqu'un actif a une variance faible par rapport à sa covariance avec les autres actifs.) Cela peut également se produire si vous avez une position courte dans le portefeuille."
                    : 'A negative risk contribution happens when a low volatility asset has a negative correlation with the other asset(s). (In statistical terms, when an asset has a small variance relative to its covariance with the other asset(s).) It can also happen if you have a short position in the portfolio.'
                }
              />
            </div>,
            french ? 'Contribution au rendement' : 'Return Contribution',
          ]}
          totals={[
            french ? 'Total' : 'Total',
            <div>100%</div>,
            <div>100%</div>,
            <div>100%</div>,
          ]}
          data={Object.keys(data.tickersValid).reduce((total, key) => {
            total.push({
              ticker: this.getName(key),
              assetAllocation: data.tickersValid[key].weight,
              riskCont: data.riskCont.asset[key],
              retCont: data.retCont.asset[key],
            });

            return total;
          }, [])}
        />
      </Container>
    );
  }
}

IndividualAsset.propTypes = {
  data: PropTypes.object.isRequired,
  french: PropTypes.bool,
};

IndividualAsset.defaultProps = { french: false };

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
