import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translateAlert } from '../utils/helpers';

const Alert = ({ alert, french }) => (
  <StyledAlert active={alert.isVisible} type={alert.type}>
    <AlertHeader type={alert.type}>
      {alert.type &&
        (french
          ? translateAlert(alert.type, french)
          : `${alert.type.charAt(0).toUpperCase()}${alert.type.slice(1)}`)}
    </AlertHeader>
    <AlertMsg type={alert.type}>{translateAlert(alert.msg, french)}</AlertMsg>
  </StyledAlert>
);

export default connect((state) => ({
  alert: state.Alert,
  french: state.Storage.language === 'fr',
}))(Alert);

Alert.propTypes = {
  alert: PropTypes.object.isRequired,
  french: PropTypes.bool,
};

Alert.defaultProps = {
  french: false,
};

const StyledAlert = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
  position: fixed;
  bottom: 0.6rem;
  right: 1rem;
  z-index: 9999;
  border-radius: 6px;
  padding: 1rem 3rem;
  text-align: center;
  background-color: ${(props) => background[props.type]};
  box-shadow: 0 0 0 1px ${(props) => colors[props.type]} inset,
    0 0 0 0 transparent;
`;

const AlertHeader = styled.div`
  color: ${(props) => colors[props.type]};
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`;

const AlertMsg = styled.div`
  opacity: 0.85;
  color: ${(props) => colors[props.type]};
  max-width: 20rem;
`;

const colors = {
  success: '#2c662d',
  warning: '#0e566c',
  error: '#912d2b',
};

const background = {
  success: '#fcfff5',
  warning: '#f8ffff',
  error: '#fff6f6',
};
