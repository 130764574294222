import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  Icon,
  Popup,
  Dropdown,
  Header,
  Form,
  Radio,
  Button as SemanticButton,
} from 'semantic-ui-react';
import cn from 'classnames';

import { AccountsDropdown, Button, Segment } from 'components';
import { Accounts, Planning, Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import { OasModal, CppModal } from './components';
import Result from './Result';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  wealthicaUser: state.Planning.wealthicaUser,
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  partnerName: state.Storage['partner-name'],
  cppEstimator: state.Planning.cppEstimator,
}))
export default class Drawdown extends Component {
  state = {
    open: true,
    isAssumptionsOpen: false,
    isOasModalOpen: false,
    isCppModalOpen: false,
    displayResult: true,
    scenario: null,
    displayScenario: null,

    warning: {},
  };

  componentWillMount = () => {
    const { dispatch, match, portfolios, drawdown } = this.props;
    const { inputs } = drawdown;
    const { id } = match.params;

    dispatch(Planning.getCppOasDataset());
    dispatch(Accounts.fetchAll());

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    if (!inputs.isRiskFree && inputs.scenario) {
      this.setState({
        scenario: inputs.scenario,
        displayScenario: inputs.scenario,
      });
    }

    if (Number(id)) {
      return dispatch(Planning.fetchDrawdownPlanId(id));
    }

    return false;
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { drawdown } = this.props;
      const { displayScenario } = this.state;

      if (drawdown) {
        const { inputs, modified } = drawdown;
        const { scenario } = inputs;

        if (scenario) {
          this.setState({ scenario });

          if (!modified && !displayScenario) {
            this.setState({ displayScenario: scenario });
          }
        }
      }
    }
  };

  getDropOptions = () => {
    const { portfolios } = this.props;
    const { inputs } = this.props.drawdown;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return portfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      selected:
        inputs.portfolio !== undefined && portf.id === Number(inputs.portfolio),
      text: portf.name,
    }));
  };

  getPortfolio = (portfolios, portfolioId) =>
    portfolios.find((port) => port.id === Number(portfolioId));

  portfolioIsDeleted = (inputs) =>
    !inputs.isRiskFree && inputs.portfolio === null;

  toggleOasModal = (val) => this.setState({ isOasModalOpen: val });

  toggleCppModal = (val) => this.setState({ isCppModalOpen: val });

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;

    if (field === 'isRiskFree') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [field]: value,
            pctCapGain: 0,
            divYield: 0,
            pctEligibleDiv: 0,
            ...(!value && { portfolio: drawdown.inputs.portfolio }),
          },
          errors: {
            pctCapGain: false,
            divYield: false,
            pctEligibleDiv: false,
            portfolio: false,
            constantRets: false,
          },
          modified: true,
        })
      );
    }

    if (field === 'cppStarted') {
      if (drawdown.inputs.cppStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, cppAge: '', cpp: '' },
            errors: { cppAge: false, cpp: false },
            modified: true,
          })
        );

        return this.setState({ warning: { cpp: '' } });
      }
    }

    if (field === 'oasStarted') {
      if (drawdown.inputs.oasStarted !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [field]: value, oasAge: '', oas: '' },
            errors: { oasAge: false, oas: false },
            modified: true,
          })
        );

        return this.setState({ warning: { oas: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  handlePortfolioChange = (e, { value }) => {
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: {
          portfolio: value,
          constantRets: null,
          divYield: this.prepopDividendYield(value),
        },
        errors: { portfolio: false, divYield: false },
        modified: true,
      })
    );
  };

  handleProvinceChange = (e, { value }) => {
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { prov: value },
        errors: { prov: false },
        modified: true,
      })
    );
  };

  prepopDividendYield = (portfolioId) => {
    const { portfolios } = this.props;

    if (this.getPortfolio(portfolios, portfolioId)) {
      if (this.getPortfolio(portfolios, portfolioId).dividend_yield !== 0) {
        return this.getPortfolio(portfolios, portfolioId).dividend_yield;
      }

      return '0';
    }

    return '';
  };

  validateSection = (section) => {
    const { inputs, errors } = this.props.drawdown;
    const { isRiskFree } = inputs;
    const sections = {
      general: ['age', 'horizon', 'prov'],
      retirement: ['cppAge', 'cpp', 'oasAge', 'oas', 'other'],
      portfolio: [
        'initRegular',
        'pctCapGain',
        'divYield',
        'pctEligibleDiv',
        'initRRIF',
        'initTFSA',
      ],
      preferences: [!isRiskFree ? 'portfolio' : 'constantRets'],
      scenario: ['scenario'],
    };

    return !!Object.keys(errors).find(
      (key) => sections[section].indexOf(key) !== -1 && errors[key]
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown, cppDataset, oasDataset, cppEstimator } =
      this.props;
    const { inputs } = drawdown;
    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
      ];

      if (field === 'horizon') {
        const maxHorizon = 80;
        if (Number(val) >= maxHorizon) {
          // Display an error message for horizon exceeding the maximum
          this.setState({
            warning: {
              ...this.state.warning,
              horizon:
                'Please input the number of years you are expected to be retired for, rather than the age you expect to live until.',
            },
          });
          return maxHorizon;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) {
        return 70;
      }

      if (isOas) {
        if (age < 65) {
          return 65;
        }
      } else if (age < 60) {
        return 60;
      }

      if (age > 70) {
        return 70;
      }

      return age;
    };

    if (name === 'age') {
      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), true))
      ).amount;

      if (Number(value) < 60) {
        this.changeSelection('cppStarted', false);
        this.changeSelection('oasStarted', false);
      } else if (Number(value) < 65) {
        this.changeSelection('oasStarted', false);
        this.changeSelection('cppStarted', true);
      } else {
        this.changeSelection('oasStarted', true);
        this.changeSelection('cppStarted', true);
      }

      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            age: parsedValue(name, value),
            ...(Number(value) > 60 && { cppAge: parsedValue(name, value) }),
            ...(Number(value) > 65 && { oasAge: parsedValue(name, value) }),
          },
          errors: {
            age: false,
            ...(Number(value) > 60 && inputs.cppAge && { cppAge: false }),
            ...(Number(value) > 65 && inputs.oasAge && { oasAge: false }),
            // ...(inputs.cppStarted &&
            // inputs.cpp > cppMax && {
            //   cpp: 'The benefit amount you entered exceeded the maximum for your current age.',
            // }),
            ...(inputs.oasStarted &&
              inputs.oas > oasMax && {
                oas: 'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs.cppStarted && inputs.cpp <= cppMax && { cpp: null }),
            ...(inputs.oasStarted && inputs.oas <= oasMax && { oas: null }),
            ...(!inputs.cppStarted && { cppAge: null }),
            ...(!inputs.oasStarted && { oasAge: null }),
          },
          modified: true,
        })
      );
    }

    if (name === 'cppAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { cppAge: parsedValue(name, value), cpp: '' },
          errors: { cppAge: false, cpp: false },
          modified: true,
        })
      );
    }

    if (name === 'oasAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oasAge: parsedValue(name, value), oas: '' },
          errors: { oasAge: false, oas: false },
          modified: true,
        })
      );
    }

    if (name === 'cpp') {
      if (!inputs.cppAge && inputs.cppStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();
      const body = {
        retirement_year:
          Number(currentYear) + Number(inputs.cppAge) - Number(inputs.age),
        start_cpp_age: inputs.cppAge,
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs.cppAge !== '' && inputs.cppAge >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, cpp: overMaxPrompt },
        });
      }

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { cpp: money },
          errors: { cpp: false },
          modified: true,
        })
      );
    }

    if (name === 'oas') {
      if (!inputs.oasAge && inputs.oasStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      if (!inputs.oasAge || inputs.oasAge > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs.oasAge < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs.oasAge)
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs.oasAge,
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, oas: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oas: money },
          errors: { oas: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  handleRadioChange = (e, { value, name }) => {
    this.setState({ [name]: value });
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );

    switch (name) {
      case 'initRegularFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initRegular: null, initRegAccList: [] },
            modified: true,
          })
        );
        break;
      case 'initRRIFFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initRRIF: null, initRrifAccList: [] },
            modified: true,
          })
        );
        break;
      case 'totalTfsaFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initTFSA: null, initTfsaAccList: [] },
            modified: true,
          })
        );
        break;
      default:
    }
  };

  updateCppInfo = (cppAge, cpp) => {
    const { dispatch } = this.props;

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { cppAge, cpp },
        errors: { cppAge: false, cpp: false },
        modified: true,
      })
    );
  };

  updateOasInfo = (oasAge, oas) => {
    const { dispatch } = this.props;

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { oasAge, oas },
        errors: { oasAge: false, oas: false },
        modified: true,
      })
    );
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  submit = () => {
    const { dispatch, drawdown, portfolios, is_wealthica_trial } = this.props;
    const { inputs } = drawdown;
    const { errors } = drawdown;
    const validated = Object.keys(inputs).reduce(
      (acc, val) => {
        if (val === 'constantRets' && !inputs.isRiskFree) {
          return acc;
        } else if (val === 'constantRets' && inputs.isRiskFree) {
          if (Number(inputs[val]) <= 0) {
            return acc;
          } else if (inputs[val].toString().split('.')[1]) {
            acc[val] = Number.parseFloat(inputs[val]).toFixed(2);

            return acc;
          }
        }

        if (inputs[val] !== '') {
          acc[val] = inputs[val];
        }

        return acc;
      },
      {
        rrifAge: inputs.cppAge || 0,
      }
    );
    const required = [
      'age',
      'horizon',
      'endValue',
      'prov',
      'cppAge',
      'oasAge',
      'cpp',
      'oas',
      'initRegular',
      !inputs.isRiskFree ? 'pctCapGain' : '',
      !inputs.isRiskFree ? 'divYield' : '',
      !inputs.isRiskFree ? 'pctEligibleDiv' : '',
      'initRRIF',
      'initTFSA',
      !inputs.isRiskFree ? 'portfolio' : 'constantRets',
      !!Number(inputs.other) && 'otherHorizon',
      !inputs.isRiskFree ? 'scenario' : null,
    ];

    const group = {
      generalInformation: ['age', 'horizon', 'prov'],
      retirementIncome: ['cppAge', 'oasAge', 'cpp', 'oas', 'otherHorizon'],
      investmentPortfolio: ['constantRets', 'portfolio'],
      portfolioValues: [
        'initRegular',
        'pctCapGain',
        'divYield',
        'pctEligibleDiv',
        'initRRIF',
        'initTFSA',
        'endValue',
      ],
      investmentSecnarios: ['scenario'],
    };

    const groupOrder = [
      'generalInformation',
      'retirementIncome',
      'investmentPortfolio',
      'portfolioValues',
      'investmentSecnarios',
    ];

    const errorObject = required.reduce((acc, val) => {
      if (val && !validated[val]) {
        // Allow account values to be 0
        if (
          (val === 'endValue' &&
            validated[val] !== 0 &&
            validated[val] !== undefined &&
            validated[val] !== null &&
            validated[val] !== '') ||
          val === 'initRegular' ||
          val === 'initRRIF' ||
          (val === 'initTFSA' && validated[val] === 0)
        ) {
          return acc;
        }
        acc[val] = true;
      }

      if (val === 'age' && Number(validated[val]) < 60) {
        acc[val] = '(cannot be less than 60)';
      }

      if (val === 'cpp') {
        if (typeof errors.cpp === 'string') {
          acc[val] = errors.cpp;
        }
      }

      if (val === 'oas') {
        if (typeof errors.oas === 'string') {
          acc[val] = errors.oas;
        }
      }

      if (val === 'cppAge') {
        if (Number(validated[val]) < Number(validated.age)) {
          acc[val] = '(cannot be less than current age)';
        }

        if (inputs.cppStarted) {
          if (Number(validated[val]) < 60) {
            acc[val] =
              'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
          }
        } else if (Number(validated[val] > 70 || Number(validated[val]) < 60)) {
          acc[val] =
            'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
        }
      }

      if (val === 'oasAge') {
        if (Number(validated[val]) < Number(validated.age)) {
          acc[val] = '(cannot be less than current age)';
        }

        if (inputs.oasStarted) {
          if (Number(validated[val]) < 65) {
            acc[val] =
              'You can only start collecting OAS benefits between age 65 and age 70.';
          }
        } else if (Number(validated[val] > 70 || Number(validated[val]) < 65)) {
          acc[val] =
            'You can only start collecting OAS benefits between age 65 and age 70.';
        }
      }

      if (
        val === 'otherHorizon' &&
        Number(validated[val]) > Number(validated.horizon || 0)
      ) {
        acc[val] = '(cannot be longer than your planning horizon)';
      }

      return acc;
    }, {});

    const hasError = Object.values(errorObject).find((val) => val);

    if (hasError) {
      const base = this.state.isAssumptionsOpen ? 1230 : 350;
      const blocksTop = [0, 230, 750, 930, 1050];
      let i = 0;
      for (; i < groupOrder.length; i += 1) {
        let leaveLoop = false;
        for (let j = 0; j < group[groupOrder[i]].length; j += 1) {
          if (group[groupOrder[i]][j] in errorObject) {
            leaveLoop = true;
            break;
          }
        }

        if (leaveLoop) {
          break;
        }
      }

      this.scrollHandler(base + blocksTop[i], 600);
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {},
          errors: errorObject,
          modified: true,
        })
      );
    }

    if (inputs.cppStarted) {
      if (!inputs.cppAge) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age },
            modified: true,
          })
        );
      }
    }

    if (inputs.oasStarted) {
      if (!inputs.oasAge) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age },
            modified: true,
          })
        );
      }
    }

    const selectedPortfolio = this.getPortfolio(portfolios, inputs.portfolio);

    const portfolio = !inputs.isRiskFree
      ? {
          isRiskFree: false,
          portId: selectedPortfolio.id,
          symbols: selectedPortfolio.symbols,
          weights: selectedPortfolio.weights,
          gic_info: selectedPortfolio.gic_info,
        }
      : {
          constantRets: Number(validated.constantRets) / 100,
          isRiskFree: true,
        };

    dispatch(
      Planning.drawdownUpdateInput({
        inputs: validated,
        errors: {},
        modified: false,
      })
    );
    dispatch(Planning.updateJustsaveOrFetch(false));

    return dispatch(
      Planning.calcDrawdown({
        ...validated,
        ...portfolio,
        init_date: portfolio.init_date,
        otherHorizon: Number(validated.otherHorizon) || 0,
        divYield: inputs.isRiskFree ? 0 : Number(validated.divYield) / 100,
        pctCapGain: inputs.isRiskFree ? 0 : Number(validated.pctCapGain) / 100,
        pctEligibleDiv: inputs.isRiskFree
          ? 0
          : Number(validated.pctEligibleDiv) / 100,
        pensionInf: validated.pensionInf,
        scenario: validated.scenario,
      })
    ).then(() => {
      const offsetY = this.state.isAssumptionsOpen
        ? !inputs.isRiskFree
          ? 2900
          : 2700
        : !inputs.isRiskFree
        ? 2100
        : 1900;
      this.scrollHandler(offsetY, 600);

      if (is_wealthica_trial) dispatch(Planning.fetchWealthicaUser());

      return this.setState({
        displayResult: true,
        open: false,
        displayScenario: !inputs.isRiskFree ? validated.scenario : '',
      });
    });
  };

  render() {
    const {
      isOasModalOpen,
      isCppModalOpen,
      isAssumptionsOpen,
      open,
      displayResult,
      displayScenario,
      warning,
    } = this.state;
    const { inputs, errors, isFetching } = this.props.drawdown;
    const {
      portfolios,
      cppDataset,
      accountList,
      wealthicaUser,
      is_wealthica_trial,
      partnerName,
    } = this.props;
    const { planning_restrictions } = wealthicaUser;
    const wealthicaTrialAllowed = is_wealthica_trial
      ? planning_restrictions && planning_restrictions.drawdown_allowed
      : true;

    return (
      <div id="drawdown-start">
        <Segment margin="1rem" textAlign="center">
          <Header
            size="huge"
            content="Optimal Drawdown"
            subheader={
              <div
                className="sub header"
                style={{ maxWidth: '47rem', margin: '0 auto' }}
              >
                With the retirement income and savings you have, let's work out
                how much you can spend each year, and where that money should
                come from to optimize taxes and maintain a smooth consumption
                path.
              </div>
            }
          />

          <P>
            One of the first questions many retirees ask is, how long should I
            be planning for? In Canada, a 65-year-old male is expected to live
            for another 19 years, and a 65-year-old female is expected to live
            for another 22 years. But these are just averages. For a more
            customized estimate, you may wish to take this questionnaire from
            Canadian scientists at{' '}
            <a
              href="https://www.projectbiglife.ca/life-expectancy-home"
              target="_blank"
              rel="noopener noreferrer"
            >
              Project Big Life
            </a>
            .
          </P>

          <CollapseWrapper open={isAssumptionsOpen}>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: isAssumptionsOpen ? '-11px' : '3px',
                left: '.5rem',
                background: 'white',
                transition: 'all 300ms ease',
                padding: '0 .75rem 0 .5rem',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledButton
                  icon
                  onClick={() =>
                    this.setState({ isAssumptionsOpen: !isAssumptionsOpen })
                  }
                  isOpen={isAssumptionsOpen}
                >
                  {isAssumptionsOpen ? '-' : '+'}
                </StyledButton>
                Model Features & Assumptions
              </div>
            </Header>

            <Assumptions>
              <Item>
                <Header
                  size="small"
                  content="Optimality means allocating withdrawals between taxable and RRIF accounts such that:"
                />
                <ul>
                  <li>The after-tax income path is smooth</li>
                  <li>The present value of after-tax income is maximized</li>
                  <li>
                    The bequest amount (client-specific value, adjusted to
                    inflation) is satisfied
                  </li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Taxable accounts" />
                <ul>
                  <li>Dividend tax, tax credit for eligible dividends</li>
                  <li>Capital gains tax</li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Portfolio options" />
                <ul>
                  <li>Flat rate of return (fixed income without risk), or</li>
                  <li>10,000 simulations using historical monthly returns</li>
                  <li>Sequence-of-returns risk option</li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Public pensions" />
                <ul>
                  <li>
                    Accommodates standard, deferral or early withdrawal of
                    CPP/QPP/OAS
                  </li>
                  <li>OAS clawback</li>
                  <li>CPP/QPP/OAS estimators are provided</li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Taxes" />
                <ul>
                  <li>
                    Federal and provincial tax brackets, indexed to inflation
                    (except for AB, NS, PEI)
                  </li>
                  <li>
                    Provincial surtax (Ontario and PEI) and the surtax brackets
                    indexed to inflation in ON
                  </li>
                  <li>OHIP premium (Ontario)</li>
                  <li>OAS clawback (recovery tax)</li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Tax credits" />
                <ul>
                  <li>
                    Basic Personal amount
                    <ul>
                      <li>Federal enhanced basic personal amount</li>
                      <li>Indexed to inflation except for AB, NS, PEI</li>
                      <li>
                        Additional basic personal amount in NS is not included
                      </li>
                    </ul>
                  </li>
                  <li>
                    Age amount
                    <ul>
                      <li>Indexed to inflation except for AB, NS, PEI, MB</li>
                      <li>Additional age amount in SK, NS is not included</li>
                      <li>Not included in QC</li>
                    </ul>
                  </li>
                  <li>
                    Pension income amount
                    <ul>
                      <li>Indexed to inflation only in ON</li>
                      <li>Not included in QC</li>
                    </ul>
                  </li>
                  <li>Dividend credit</li>
                </ul>
              </Item>

              <Item>
                <Header
                  size="small"
                  content="RRSP has been converted into a RRIF"
                />
                <ul>
                  <li>Calculates minimum RRIF</li>
                  <li>And additional withdrawal (if required) from RRIF</li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Company pension" />
                <ul>
                  <li>
                    Client specifies value and whether it is adjusted for
                    inflation
                  </li>
                </ul>
              </Item>

              <Item>
                <Header
                  size="small"
                  content="Rental / part-time income / etc..."
                />
                <ul>
                  <li>
                    Client specifies how long they last and whether this income
                    is adjusted for inflation
                  </li>
                </ul>
              </Item>

              <Item>
                <Header size="small" content="Inflation" />
                <ul>
                  <li>2%</li>
                </ul>
              </Item>
            </Assumptions>
          </CollapseWrapper>

          <CollapseWrapper open={open}>
            <Header
              size="medium"
              style={{
                position: 'absolute',
                top: open ? '-10px' : '3px',
                left: '.5rem',
                background: 'white',
                padding: '0 .75rem 0 .5rem',
                transition: 'all 300ms ease',
              }}
            >
              <StyledButton
                icon
                onClick={() => this.setState({ open: !open })}
                isOpen={open}
              >
                {open ? '-' : '+'}
              </StyledButton>
              Drawdown Plan Inputs
            </Header>

            <P pad>
              Please fill out the following info to generate your optimal
              drawdown plan.
            </P>

            <Section
              single
              className={cn({ invalid: this.validateSection('general') })}
            >
              <div className="heading">General Information</div>
              <div>
                <div className="title">
                  Current age
                  <Error visible={typeof errors.age === 'string'}>
                    {errors.age}
                  </Error>
                </div>
                <Input
                  className={cn('large', { invalid: errors.age })}
                  name="age"
                  type="number"
                  placeholder="years"
                  value={inputs.age || ''}
                  onChange={this.handleChange}
                />
              </div>

              <div>
                <div className="title">
                  Planning for how many years?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="e.g. if you are 65 and expect you will live until 85, then input 20 years."
                  />
                  (
                  <a
                    href="https://www.projectbiglife.ca/life-expectancy-home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Project Big Life
                  </a>
                  )
                  <p
                    style={{
                      margin: 0,
                      transform: 'translateY(-1px)',
                      display: 'block',
                      fontSize: '1rem',
                      fontStyle: 'normal',
                      color: `${colors.red}`,
                    }}
                    visible={
                      typeof warning.horizon === 'string' ||
                      typeof errors.horizon === 'string'
                    }
                  >
                    {warning.horizon || errors.horizon ? (
                      <React.Fragment>
                        Please input the number of years you are expected to be
                        retired for,
                        <br />
                        rather than the age you expect to live until.
                      </React.Fragment>
                    ) : null}
                  </p>
                </div>
                <Input
                  className={cn('large', { invalid: errors.horizon })}
                  name="horizon"
                  type="number"
                  placeholder="years"
                  value={inputs.horizon || ''}
                  onChange={this.handleChange}
                  onFocus={() =>
                    this.setState({
                      warning: { ...this.state.warning, horizon: null },
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      warning: { ...this.state.warning, horizon: null },
                    })
                  }
                  onClick={() =>
                    this.setState({
                      warning: { ...this.state.warning, horizon: null },
                    })
                  }
                />
              </div>

              <div>
                <div className="title">Province of residence</div>
                <StyledDropdown
                  className={cn('large', { invalid: errors.prov })}
                  placeholder="Province"
                  selection
                  options={[
                    { key: 'AB', value: 'AB', text: 'Alberta' },
                    { key: 'BC', value: 'BC', text: 'British Columbia' },
                    { key: 'MB', value: 'MB', text: 'Manitoba' },
                    { key: 'NB', value: 'NB', text: 'New Brunswick' },
                    {
                      key: 'NL',
                      value: 'NL',
                      text: 'Newfoundland & Labrador',
                    },
                    { key: 'NS', value: 'NS', text: 'Nova Scotia' },
                    { key: 'ON', value: 'ON', text: 'Ontario' },
                    { key: 'PE', value: 'PE', text: 'Prince Edward Island' },
                    { key: 'QC', value: 'QC', text: 'Quebec' },
                    { key: 'SK', value: 'SK', text: 'Saskatchewan' },
                  ]}
                  value={inputs.prov}
                  onChange={this.handleProvinceChange}
                />
              </div>
            </Section>

            <Section
              single
              className={cn({ invalid: this.validateSection('retirement') })}
            >
              <div className="heading">Retirement Income</div>

              <div className="title">CPP/QPP</div>
              <ul>
                <li className="visible">
                  <label>Have you started collecting CPP/QPP?</label>
                  {inputs.cppStarted && (
                    <Error
                      style={{
                        margin: 0,
                        paddingLeft: '1rem',
                        display: 'block',
                      }}
                      visible={typeof errors.cppAge === 'string'}
                    >
                      {errors.cppAge}
                    </Error>
                  )}
                  <OptionsWrapper>
                    <div
                      className={cn('select', { active: inputs.cppStarted })}
                      onClick={() => this.changeSelection('cppStarted', true)}
                      style={{
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                    >
                      Yes
                    </div>
                    <div
                      className={cn('select', { active: !inputs.cppStarted })}
                      onClick={() => this.changeSelection('cppStarted', false)}
                      style={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    >
                      No
                    </div>
                  </OptionsWrapper>
                </li>

                <li className={cn({ visible: inputs.cppStarted })}>
                  <label>
                    Annual CPP/QPP benefits
                    <Error
                      style={{
                        margin: 0,
                        display: 'block',
                        transform: 'translateY(-1px)',
                      }}
                      visible={
                        typeof warning.cpp === 'string' ||
                        typeof errors.cpp === 'string'
                      }
                    >
                      {warning.cpp || errors.cpp}
                    </Error>
                  </label>
                  <Input
                    className={cn({ invalid: errors.cpp })}
                    name="cpp"
                    type="number"
                    placeholder="$"
                    value={inputs.cpp || ''}
                    onChange={this.handleChange}
                    onFocus={() =>
                      this.setState({
                        warning: { ...this.state.warning, cpp: null },
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        warning: { ...this.state.warning, cpp: null },
                      })
                    }
                    onClick={() =>
                      this.setState({
                        warning: { ...this.state.warning, cpp: null },
                      })
                    }
                  />
                </li>

                <li className={cn({ visible: !inputs.cppStarted })}>
                  <label>
                    At what age do you plan to start?
                    <Popup
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content={
                        <div>
                          Read our article{' '}
                          <a
                            href="https://wealthscope.ca/cpp-benefits"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            CPP/QPP Benefits: To Defer or Not to Defer
                          </a>
                        </div>
                      }
                      hoverable
                      wide
                    />
                    <Error
                      style={{
                        margin: 0,
                        display: 'block',
                        transform: 'translateY(-1px)',
                      }}
                      visible={typeof errors.cppAge === 'string'}
                    >
                      {errors.cppAge}
                    </Error>
                  </label>
                  <Input
                    className={cn('absolute', { invalid: errors.cppAge })}
                    name="cppAge"
                    type="number"
                    placeholder="years"
                    value={inputs.cppAge || ''}
                    onChange={this.handleChange}
                  />
                </li>

                <li className={cn({ visible: !inputs.cppStarted })}>
                  <label>
                    Expected CPP/QPP annual benefits in today's dollars (
                    <Span
                      style={{ color: '#4183C3' }}
                      onClick={() => this.toggleCppModal(true)}
                    >
                      <b>CPP/QPP Estimator</b>
                    </Span>
                    )
                    <Error
                      style={{
                        margin: 0,
                        display: 'block',
                        transform: 'translateY(-1px)',
                      }}
                      visible={typeof warning.cpp === 'string'}
                    >
                      {warning.cpp}
                    </Error>
                  </label>
                  {!inputs.cppAge ? (
                    <Popup
                      trigger={
                        <Input
                          name="cpp"
                          type="number"
                          placeholder="$"
                          value={inputs.cpp || ''}
                          onChange={this.handleChange}
                          style={{
                            backgroundColor: colors.lightGrey,
                            opacity: '.8',
                          }}
                          disabled
                        />
                      }
                      position="top center"
                      content="Please enter CPP/QPP start age."
                    />
                  ) : (
                    <Input
                      className={cn({ invalid: errors.cpp })}
                      name="cpp"
                      type="number"
                      placeholder="$"
                      value={inputs.cpp || ''}
                      onChange={this.handleChange}
                      onFocus={() =>
                        this.setState({
                          warning: { ...this.state.warning, cpp: null },
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          warning: { ...this.state.warning, cpp: null },
                        })
                      }
                      onClick={() =>
                        this.setState({
                          warning: { ...this.state.warning, cpp: null },
                        })
                      }
                    />
                  )}
                </li>
              </ul>

              <div className="title">OAS</div>
              <ul>
                <li className="visible">
                  <label>Have you started collecting OAS?</label>
                  {inputs.oasStarted && (
                    <Error
                      style={{
                        margin: 0,
                        paddingLeft: '1rem',
                        display: 'block',
                      }}
                      visible={typeof errors.oasAge === 'string'}
                    >
                      {errors.oasAge}
                    </Error>
                  )}
                  <OptionsWrapper>
                    <div
                      className={cn('select', { active: inputs.oasStarted })}
                      onClick={() => this.changeSelection('oasStarted', true)}
                      style={{
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                    >
                      Yes
                    </div>
                    <div
                      className={cn('select', { active: !inputs.oasStarted })}
                      onClick={() => this.changeSelection('oasStarted', false)}
                      style={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    >
                      No
                    </div>
                  </OptionsWrapper>
                </li>

                <li className={cn({ visible: inputs.oasStarted })}>
                  <label>
                    Annual OAS benefits
                    <Error
                      style={{
                        margin: 0,
                        display: 'block',
                        transform: 'translateY(-1px)',
                      }}
                      visible={
                        typeof warning.oas === 'string' ||
                        typeof errors.oas === 'string'
                      }
                    >
                      {warning.oas || errors.oas}
                    </Error>
                  </label>
                  <Input
                    className={cn({ invalid: errors.oas })}
                    name="oas"
                    type="number"
                    placeholder="$"
                    value={inputs.oas || ''}
                    onChange={this.handleChange}
                    onFocus={() =>
                      this.setState({
                        warning: { ...this.state.warning, oas: null },
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        warning: { ...this.state.warning, oas: null },
                      })
                    }
                    onClick={() =>
                      this.setState({
                        warning: { ...this.state.warning, oas: null },
                      })
                    }
                  />
                </li>

                <li className={cn({ visible: !inputs.oasStarted })}>
                  <label>
                    At what age do you plan to start?
                    <Error
                      style={{
                        margin: 0,
                        display: 'block',
                        transform: 'translateY(-1px)',
                      }}
                      visible={typeof errors.oasAge === 'string'}
                    >
                      {errors.oasAge}
                    </Error>
                  </label>
                  <Input
                    className={cn('absolute', { invalid: errors.oasAge })}
                    name="oasAge"
                    type="number"
                    placeholder="years"
                    value={inputs.oasAge || ''}
                    onChange={this.handleChange}
                  />
                </li>

                <li className={cn({ visible: !inputs.oasStarted })}>
                  <label>
                    Expected OAS annual benefits in today's dollars (
                    <Span
                      style={{ color: '#4183C3' }}
                      onClick={() => this.toggleOasModal(true)}
                    >
                      <b>OAS Estimator</b>
                    </Span>
                    )
                    <Error
                      style={{
                        margin: 0,
                        display: 'block',
                        transform: 'translateY(-1px)',
                      }}
                      visible={typeof warning.oas === 'string'}
                    >
                      {warning.oas}
                    </Error>
                  </label>
                  {!inputs.oasAge ? (
                    <Popup
                      trigger={
                        <Input
                          name="oas"
                          type="number"
                          placeholder="$"
                          value={inputs.oas || ''}
                          onChange={this.handleChange}
                          style={{
                            backgroundColor: colors.lightGrey,
                            opacity: '.8',
                          }}
                          disabled
                        />
                      }
                      position="top center"
                      content="Please enter OAS start age."
                    />
                  ) : (
                    <Input
                      className={cn({ invalid: errors.oas })}
                      name="oas"
                      type="number"
                      placeholder="$"
                      value={inputs.oas || ''}
                      onChange={this.handleChange}
                      onFocus={() =>
                        this.setState({
                          warning: { ...this.state.warning, oas: null },
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          warning: { ...this.state.warning, oas: null },
                        })
                      }
                      onClick={() =>
                        this.setState({
                          warning: { ...this.state.warning, oas: null },
                        })
                      }
                    />
                  )}
                </li>
              </ul>

              <div>
                <div className="title">
                  Annual employer DB pension
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Defined Benefits pension plan"
                    wide
                  />
                </div>
                <Input
                  className={cn('large', { invalid: errors.pension })}
                  name="pension"
                  type="number"
                  placeholder="$"
                  value={inputs.pension}
                  onChange={this.handleChange}
                />

                <ul>
                  <li className="visible">
                    <label>Is this pension indexed to inflation?</label>
                    <OptionsWrapper>
                      <div
                        className={cn('select', {
                          active: inputs.pensionInf,
                        })}
                        onClick={() => this.changeSelection('pensionInf', true)}
                        style={{
                          borderTopLeftRadius: 5,
                          borderBottomLeftRadius: 5,
                        }}
                      >
                        Yes
                      </div>
                      <div
                        className={cn('select', {
                          active: !inputs.pensionInf,
                        })}
                        onClick={() =>
                          this.changeSelection('pensionInf', false)
                        }
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        No
                      </div>
                    </OptionsWrapper>
                  </li>
                </ul>
              </div>

              <div>
                <div className="title">
                  Annual other taxable income
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="This includes annuity and rental income. Dividends and interest income from non-registered accounts will be calculated below."
                    wide
                  />
                </div>
                <Input
                  className={cn('large', { invalid: errors.other })}
                  name="other"
                  type="number"
                  placeholder="$"
                  value={inputs.other}
                  onChange={this.handleChange}
                />

                <ul>
                  <li className="visible">
                    <label>Is it indexed to inflation?</label>
                    <OptionsWrapper>
                      <div
                        className={cn('select', { active: inputs.otherInf })}
                        onClick={() => this.changeSelection('otherInf', true)}
                        style={{
                          borderTopLeftRadius: 5,
                          borderBottomLeftRadius: 5,
                        }}
                      >
                        Yes
                      </div>
                      <div
                        className={cn('select', { active: !inputs.otherInf })}
                        onClick={() => this.changeSelection('otherInf', false)}
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      >
                        No
                      </div>
                    </OptionsWrapper>
                  </li>
                  <li className="visible">
                    <label>How long will it last?</label>
                    <Input
                      className={cn({ invalid: errors.otherHorizon })}
                      name="otherHorizon"
                      type="number"
                      placeholder="years"
                      value={inputs.otherHorizon || ''}
                      onChange={this.handleChange}
                    />
                    <Error
                      long
                      visible={typeof errors.otherHorizon === 'string'}
                    >
                      {errors.otherHorizon}
                    </Error>
                  </li>
                </ul>
              </div>
            </Section>

            <Section
              single
              className={cn({ invalid: this.validateSection('preferences') })}
              style={{ marginBottom: 0 }}
            >
              <div className="heading">Investment Portfolio</div>

              <div>
                <div
                  className="title"
                  style={{ position: 'relative', width: '100%' }}
                >
                  <div style={{ width: '80%' }}>
                    Are you putting your retirement savings in a risk-free
                    investment?
                  </div>
                  <OptionsWrapper style={{ top: 0 }}>
                    <div
                      className={cn('select', { active: inputs.isRiskFree })}
                      onClick={() => {
                        this.setState({ scenario: null });
                        this.changeSelection('isRiskFree', true);
                        this.changeSelection('scenario', '');
                      }}
                      style={{
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                    >
                      Yes
                    </div>
                    <div
                      className={cn('select', { active: !inputs.isRiskFree })}
                      onClick={() => {
                        if (!this.state.scenario) {
                          this.setState({ scenario: null });
                        }
                        this.changeSelection('isRiskFree', false);
                        this.changeSelection('constantRets', null);
                      }}
                      style={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    >
                      No
                    </div>
                  </OptionsWrapper>
                </div>

                <div style={{ display: !inputs.isRiskFree ? 'block' : 'none' }}>
                  <div className="description">
                    Use a portfolio close to your desired asset allocation. Pick
                    a Wealthscope model portfolio, or save a portfolio in the
                    "Portfolios" segment. You will see them in the drop down on
                    the right.
                  </div>
                  <StyledDropdown
                    style={{ width: '80% !important' }}
                    className={cn({
                      invalid:
                        errors.portfolio ||
                        this.portfolioIsDeleted(inputs, portfolios),
                    })}
                    placeholder="Select a portfolio"
                    selection
                    disabled={portfolios.length === 0}
                    options={this.getDropOptions()}
                    long
                    value={inputs.portfolio}
                    text={
                      inputs.portfolio !== undefined &&
                      portfolios.length !== 0 &&
                      !inputs.isRiskFree &&
                      this.getPortfolio(portfolios, inputs.portfolio) &&
                      this.getPortfolio(portfolios, inputs.portfolio).name
                    }
                    selectOnBlur={false}
                    onChange={this.handlePortfolioChange}
                  />
                </div>

                <div style={{ display: inputs.isRiskFree ? 'block' : 'none' }}>
                  <div className="description">
                    What is the expected rate of return?
                  </div>
                  <Input
                    className={cn({ invalid: errors.constantRets })}
                    name="constantRets"
                    type="number"
                    placeholder="%"
                    value={inputs.constantRets || ''}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              {this.portfolioIsDeleted(inputs, portfolios) && (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    color: 'red',
                  }}
                >
                  Your portfolio has been removed. Please select a new
                  portfolio.
                </div>
              )}
            </Section>

            <Section
              single
              className={cn({ invalid: this.validateSection('portfolio') })}
            >
              <div className="heading">Account Values</div>

              <div>
                <div className="title">Total non-registered (taxable)</div>
                {inputs.initRegularFromAcc ? (
                  <AccountsDropdown
                    inputs={inputs}
                    error={errors.initRegular}
                    accountList={accountList}
                    selectedAccList={inputs.initRegAccList}
                    savedAccountsValue={inputs.initRegular || 0}
                    assignAccountsValue={(selectedAccounts, accountsValue) => {
                      this.props.dispatch(
                        Planning.drawdownUpdateInput({
                          inputs: {
                            initRegAccList: selectedAccounts,
                            initRegular: accountsValue,
                          },

                          errors: { initRegular: false },
                          modified: true,
                        })
                      );
                    }}
                    float
                  />
                ) : (
                  <Input
                    className={cn('large', { invalid: errors.initRegular })}
                    name="initRegular"
                    type="number"
                    placeholder="$"
                    value={inputs.initRegular}
                    onChange={this.handleChange}
                  />
                )}
                {isGlobeInvestor(partnerName) ? null : (
                  <div style={{ margin: '0 0 0.5rem 1rem' }}>
                    <Form>
                      <Form.Field>
                        <Radio
                          name="initRegularFromAcc"
                          value={false}
                          checked={!inputs.initRegularFromAcc}
                          onChange={this.handleRadioChange}
                          label="Input Value"
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          name="initRegularFromAcc"
                          value
                          checked={inputs.initRegularFromAcc}
                          onChange={this.handleRadioChange}
                          label="Select From Accounts"
                        />
                      </Form.Field>
                    </Form>
                  </div>
                )}
              </div>

              {!inputs.isRiskFree ? (
                <ul>
                  <li className="visible">
                    <label>
                      Roughly what % of this value is from capital gains?
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="top center"
                        content="To calculate this percentage, subtract total book costs from total market value (giving you the amount of capital gains) and divide by the total market value. We require this information as capital gains are taxed differently from dividends."
                        wide
                      />
                    </label>
                    <Input
                      className={cn({ invalid: errors.pctCapGain })}
                      name="pctCapGain"
                      type="number"
                      placeholder="%"
                      value={inputs.pctCapGain || ''}
                      onChange={this.handleChange}
                    />
                  </li>

                  <li className="visible">
                    <label>
                      What is the dividend yield?
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="top center"
                        content="We assume that all dividends are withdrawn from taxable accounts for spending. The dividend yield is pre-populated given the portfolio you selected. This figure is used to calculate the dividend tax."
                        wide
                      />
                    </label>
                    <Input
                      className={cn({ invalid: errors.divYield })}
                      name="divYield"
                      type="number"
                      placeholder="%"
                      value={inputs.divYield || ''}
                      onChange={this.handleChange}
                    />
                  </li>

                  <li className="visible">
                    <label>
                      Percentage of dividends from Canadian stocks?
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="top center"
                        content="Canadian dividends are eligible for a tax credit."
                        wide
                      />
                    </label>
                    <Input
                      className={cn({ invalid: errors.pctEligibleDiv })}
                      name="pctEligibleDiv"
                      type="number"
                      placeholder="%"
                      value={inputs.pctEligibleDiv || ''}
                      onChange={this.handleChange}
                    />
                  </li>
                </ul>
              ) : null}
              <div>
                <div>
                  <div className="title">
                    Total Registered (RRIF/LIF)
                    <Popup
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content="Your RRSP/Defined Contribution (DC) Plan turn into an income fund (RRIF/LIF) at retirement."
                      wide
                    />
                  </div>
                  {inputs.initRRIFFromAcc ? (
                    <AccountsDropdown
                      inputs={inputs}
                      error={errors.initRRIF}
                      accountList={accountList}
                      selectedAccList={inputs.initRrifAccList}
                      savedAccountsValue={inputs.initRRIF || 0}
                      assignAccountsValue={(
                        selectedAccounts,
                        accountsValue
                      ) => {
                        this.props.dispatch(
                          Planning.drawdownUpdateInput({
                            inputs: {
                              initRrifAccList: selectedAccounts,
                              initRRIF: accountsValue,
                            },
                            errors: { initRRIF: false },
                            modified: true,
                          })
                        );
                      }}
                      float
                    />
                  ) : (
                    <Input
                      className={cn('large', { invalid: errors.initRRIF })}
                      name="initRRIF"
                      type="number"
                      placeholder="$"
                      value={inputs.initRRIF}
                      onChange={this.handleChange}
                    />
                  )}
                </div>

                <div style={{ marginLeft: '1rem' }}>
                  <Form>
                    <Form.Field>
                      <Radio
                        name="initRRIFFromAcc"
                        value={false}
                        checked={!inputs.initRRIFFromAcc}
                        onChange={this.handleRadioChange}
                        label="Input Value"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        name="initRRIFFromAcc"
                        value
                        checked={inputs.initRRIFFromAcc}
                        onChange={this.handleRadioChange}
                        label="Select From Accounts"
                      />
                    </Form.Field>
                  </Form>
                </div>
              </div>

              <div>
                <div>
                  <div className="title">Total TFSA</div>
                  {inputs.totalTfsaFromAcc ? (
                    <AccountsDropdown
                      inputs={inputs}
                      error={errors.initTFSA}
                      accountList={accountList}
                      selectedAccList={inputs.initTfsaAccList}
                      savedAccountsValue={inputs.initTFSA || 0}
                      assignAccountsValue={(
                        selectedAccounts,
                        accountsValue
                      ) => {
                        this.props.dispatch(
                          Planning.drawdownUpdateInput({
                            inputs: {
                              initTfsaAccList: selectedAccounts,
                              initTFSA: accountsValue,
                            },
                            errors: { initTFSA: false },
                            modified: true,
                          })
                        );
                      }}
                      float
                    />
                  ) : (
                    <Input
                      className={cn('large', { invalid: errors.initTFSA })}
                      name="initTFSA"
                      type="number"
                      placeholder="$"
                      value={inputs.initTFSA}
                      onChange={this.handleChange}
                    />
                  )}
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  <Form>
                    <Form.Field>
                      <Radio
                        name="totalTfsaFromAcc"
                        value={false}
                        checked={!inputs.totalTfsaFromAcc}
                        onChange={this.handleRadioChange}
                        label="Input Value"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        name="totalTfsaFromAcc"
                        value
                        checked={inputs.totalTfsaFromAcc}
                        onChange={this.handleRadioChange}
                        label="Select From Accounts"
                      />
                    </Form.Field>
                  </Form>
                </div>
              </div>

              <div className="title">
                Bequest
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content="This is your legacy, for your family and charitable donations."
                  wide
                />
              </div>

              <ul>
                <li className="visible">
                  <label>
                    How much would you like to leave (in today's dollars)?
                  </label>
                  <Input
                    className={cn({ invalid: errors.endValue })}
                    name="endValue"
                    type="number"
                    placeholder="$"
                    value={inputs.endValue}
                    onChange={this.handleChange}
                  />
                </li>
              </ul>
            </Section>

            {!inputs.isRiskFree ? (
              <Section
                single
                className={cn({ invalid: this.validateSection('scenario') })}
              >
                <div className="heading">Investment Scenarios</div>
                <div className="title">
                  We will run 10,000 simulations using your investment
                  portfolio's historical returns.
                </div>
                <div style={{ marginLeft: '1rem' }}>
                  <Form>
                    <Form.Field>
                      <Radio
                        name="scenario"
                        value="median"
                        checked={this.state.scenario === 'median'}
                        onChange={this.handleRadioChange}
                        label="Show me the median outcome."
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        name="scenario"
                        value="worst3"
                        checked={this.state.scenario === 'worst3'}
                        onChange={this.handleRadioChange}
                        label="Show me a poor outcome with sequence of returns risk."
                      />
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="top center"
                        wide
                        content="This scenario assumes that in the first three years of the planning horizon, the portfolio experiences the three worst annualized returns (beginning with the lowest in year 1) based on 10,000 simulations of historical monthly return paths."
                      />
                    </Form.Field>
                  </Form>
                </div>
              </Section>
            ) : null}

            <Button
              className={cn('left', { loading: isFetching })}
              disabled={this.portfolioIsDeleted(inputs, portfolios)}
              color={colors.blue}
              icon="calculator"
              onClick={this.submit}
              style={{
                margin: '1rem 0',
              }}
              popup={
                !wealthicaTrialAllowed
                  ? {
                      position: 'top center',
                      content: 'Trial Period Maximum of 2 Reached.',
                      wide: true,
                    }
                  : null
              }
            >
              Calculate
            </Button>
          </CollapseWrapper>
        </Segment>

        <OasModal
          isOpen={isOasModalOpen}
          toggle={this.toggleOasModal}
          updateOasInfo={this.updateOasInfo}
        />

        <CppModal
          isOpen={isCppModalOpen}
          toggle={this.toggleCppModal}
          updateCppInfo={this.updateCppInfo}
          cppDataset={cppDataset}
        />
        {displayResult ? (
          <div id="drawdown-result">
            <Result displayScenario={displayScenario} />
          </div>
        ) : null}
      </div>
    );
  }
}

Drawdown.propTypes = {
  dispatch: PropTypes.func,
  portfolios: PropTypes.array,
  drawdown: PropTypes.object,
  match: PropTypes.object,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  accountList: PropTypes.array,
  wealthicaUser: PropTypes.object,
  is_wealthica_trial: PropTypes.bool,
  partnerName: PropTypes.string,
  cppEstimator: PropTypes.object,
};

Drawdown.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  drawdown: {},
  match: {},
  cppDataset: [],
  oasDataset: [],
  accountList: [],
  wealthicaUser: {},
  is_wealthica_trial: false,
  partnerName: '',
  cppEstimator: {},
};

const Section = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 2rem auto;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
      padding-right: 3rem;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: 0 auto;
  padding: ${(props) => (props.pad ? '1.5rem 0 0 0' : '0')};
  text-align: ${(props) => (props.pad ? 'left' : 'center')};
  max-width: ${(props) => (props.pad ? '100%' : '47rem')};
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: unset !important;
  min-height: unset !important;
  padding: 0.5rem !important;
  min-width: unset !important;
  width: 35% !important;
  float: right;

  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }

  i {
    padding: 0.5rem !important;
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }
`;

const Error = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 1rem;
  font-style: normal;
  color: ${colors.red};

  ${(props) =>
    props.long &&
    css`
      position: absolute;
      bottom: 0;
      left: 4px;
    `}
`;

const Span = styled.span`
  color: #4183c4;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
  }
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 55rem;
  padding: 0 1rem;
  padding-top: ${(props) => (props.open ? '0rem' : '2rem')};
  margin: 3rem auto 0 auto;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '3000px' : '28px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const Assumptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  text-align: left;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);

  .header {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Item = styled.div`
  display: block;
  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 49.5%;
  margin: 0.5% 0;
  padding: 5px;
  border-radius: 4px;
`;

const StyledButton = styled(SemanticButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;
