import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PortfolioHistoryChart, Table } from 'components';
import { Header, Divider } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import styled from 'styled-components';
import { decideCurrency } from 'utils/helpers';
import { translation } from '../localization';
import {
  translateBenchmarkHeading,
  translateBenchmarkDescription,
} from '../../../../utils/helpers';

@connect((state) => ({
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))
class PortfolioHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compare: this.props.analytics.portfolioHistoryCompareto,
    };
  }

  getSubHeader = (date) => {
    const { analytics, user_region, french } = this.props;
    const language = french ? 'fr' : 'en';
    const init = date.init_date.split('-');
    const end = date.end_date.split('-');
    const parsedDate = `de ${translation[language].MONTH[[init[1]]]} ${
      init[0]
    } ${french ? 'à' : 'to'} ${translation[language].MONTH[[end[1]]]} ${
      end[0]
    }`;

    return french
      ? `${parsedDate} (inclusivement) en ${decideCurrency(
          analytics.data,
          user_region || 'CA'
        )}`
      : `${parsedDate} (inclusive) in ${decideCurrency(
          analytics.data,
          user_region || 'CA'
        )}`;
  };

  getChartData = (data) => {
    const { compare } = this.state;
    const { analytics } = this.props;
    const { benchmark } = analytics;

    return {
      labels: Object.keys(data.cumrets.portfolio),
      datasets: [
        {
          data: Object.values(data.cumrets.portfolio).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 0,
          label: 'Portfolio',
          lineTension: 0,
          fill: false,
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          pointBorderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointRadius: 0,
          pointHitRadius: 5,
        },
        {
          data: Object.values(data.cumrets[compare][benchmark]).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 0,
          label: compare,
          lineTension: 0,
          fill: false,
          backgroundColor: colors.red,
          borderColor: colors.red,
          pointBorderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointRadius: 0,
          pointHitRadius: 5,
        },
      ],
    };
  };

  capitalize = (str) => str.substring(0, 1).toUpperCase() + str.slice(1);

  shouldDrawBar = () => {
    const { data } = this.props.analytics;

    return (
      data &&
      data.crisisRets &&
      Object.values(data.crisisRets).reduce((result, event) => {
        result = Object.keys(event.rets).length ? result + 1 : result;

        return result;
      }, 0)
    );
  };

  renderCrisisTable = () => {
    const crisisAmount = this.shouldDrawBar();
    const language = this.props.french ? 'fr' : 'en';

    if (crisisAmount) {
      const { benchmark, data, portfolioHistoryCompareto } =
        this.props.analytics;
      const dummy = crisisAmount === 1 ? 9 : crisisAmount === 2 ? 6 : 5;

      return (
        <div style={{ marginTop: '2.5rem' }}>
          <Header>
            <Header.Content>
              {translation[language].CRISIS_PERIOD_RETURNS}
            </Header.Content>
          </Header>

          <Divider />

          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3} />
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.HeaderCell>
                    {translation[language].FINANCIAL_CRISIS}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2007-12 to 2009-05)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.HeaderCell>
                    {translation[language].OIL_PRICE_SHOCK}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2014-06 to 2016-01)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.HeaderCell>
                    {translation[language].COVID_19_PANDEMIC}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2020-02 to 2021-06)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.HeaderCell>
                    {translation[language].UKRAINE_WAR_ONSET}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2022-03 to 2022-08)
                    </span>
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell width={dummy} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={3}>
                  {translation[language].MY_PORTFOLIO}
                </Table.Cell>
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Financial Crisis'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Oil Price Shock'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Covid-19 Pandemic'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Ukraine War'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                <Table.Cell width={dummy} />
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  {translateBenchmarkHeading(
                    portfolioHistoryCompareto[0].toUpperCase() +
                      portfolioHistoryCompareto.slice(1),
                    this.props.french
                  )}
                </Table.Cell>
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Financial Crisis'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Oil Price Shock'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Covid-19 Pandemic'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Ukraine War'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                <Table.Cell width={dummy} />
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      );
    }

    return null;
  };

  render() {
    const { analytics, french } = this.props;
    const { benchmark, data } = analytics;
    const { compare } = this.state;
    const language = french ? 'fr' : 'en';

    return (
      <div style={{ clear: 'both', paddingTop: '5px' }}>
        <Header dividing size="medium" style={{ textAlign: 'left' }}>
          {translation[language].HYPOTHETICAL_PORTFOLIO_HISTORY}
          <Header.Subheader>
            {translation[language].BASE_CURRENT_PORTFOLIO}
          </Header.Subheader>
          <Header.Subheader>
            {this.getSubHeader(data.portfolio)}
          </Header.Subheader>
        </Header>
        <Header>
          {`${translation[language].COMPARE_TO}: ${translateBenchmarkHeading(
            this.capitalize(compare),
            french
          )}`}
          <Header.Subheader>
            {compare === 'market'
              ? translateBenchmarkDescription(
                  data.cumrets.market[`${benchmark}Desc`],
                  french
                )
              : translateBenchmarkDescription(
                  data.cumrets.benchmark[`${benchmark}Desc`],
                  french
                )}
          </Header.Subheader>
        </Header>
        <div style={{ textAlign: 'center', margin: '1rem 0 0 0' }}>
          <Note>{translation[language].RETURNS_INCLUDING_DISTRIBUTIONS}</Note>
          <Legend color={colors.blue}>{translation[language].PORTFOLIO}</Legend>
          <Legend color={colors.red}>
            {translateBenchmarkHeading(this.capitalize(compare), french)}
          </Legend>
        </div>
        <PortfolioHistoryChart
          portfolioData={data}
          compare={compare}
          benchmark={benchmark}
        />
        {this.renderCrisisTable()}
      </div>
    );
  }
}

PortfolioHistory.propTypes = {
  analytics: PropTypes.object,
  user_region: PropTypes.string,
  french: PropTypes.bool,
};

PortfolioHistory.defaultProps = {
  analytics: {},
  user_region: 'CA',
  french: false,
};

export default PortfolioHistory;

const Legend = styled.span`
  position: relative;
  color: black;
  font-size: 0.9rem;
  cursor: default;
  width: 100px;
  margin: 0 0 0 4rem;
  vertical-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -3.2rem;
    height: 0.8rem;
    width: 2.8rem;
    background: ${(props) => props.color || 'grey'};
    vertical-align: middle;
  }
`;

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.5rem auto;
`;
