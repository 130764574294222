import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Flag, Icon, Popup, Header, Dropdown } from 'semantic-ui-react';
import cn from 'classnames';

import { Accounts, CustomAccount } from 'actions';
import { colors } from 'utils/colors';
import { notWealthica, getTickerSearchResult } from 'utils/helpers';
import {
  Modal,
  Message,
  Table,
  Button,
  Segment,
  Selection,
  SearchTicker,
} from '../../../components';
import { Quantity, CashAmount, ConfirmModal } from './components';
import {
  translateAssetClass,
  translateTableHeadings,
  translateText,
} from '../../../utils/helpers';

@withRouter
@connect((state) => ({
  customAcc: state.CustomAccount,
  account: state.CustomAccount.account,
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
  user_region: state.Storage.user_region,
  toggle_region: state.Storage.toggle_region || 'CA',
}))
export default class BuildCustomAccount extends Component {
  state = {
    includeCash:
      typeof this.props.customAcc.cash === 'number' &&
      this.props.customAcc.cash !== 0,
    isOpen: false,
    conflictAccounts: null,
    resolveGicConflict: false,
    gicError: false,
  };

  componentWillMount = () => {
    const { dispatch, match, customAcc } = this.props;
    const { id } = match.params;

    if (
      Number(id) &&
      !Object.keys(customAcc.holdings).length &&
      !customAcc.gic_quantity &&
      !customAcc.gic_rate
    ) {
      return dispatch(CustomAccount.fetchId(id));
    }

    return false;
  };

  componentWillReceiveProps = (nextProps) => {
    const { history } = nextProps;
    const { match } = this.props;

    if (
      match.params.id === 'new' &&
      nextProps.account &&
      Number(nextProps.account.id)
    ) {
      return history.push(`/accounts/custom/${nextProps.account.id}`);
    }

    return false;
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.customAcc && this.props.customAcc) {
      const { customAcc } = this.props;

      if (
        prevProps.customAcc.cash &&
        (customAcc.cash === 0 || customAcc.cash === undefined)
      ) {
        this.setState({ includeCash: false });
      } else if (
        (prevProps.customAcc.cash === 0 ||
          prevProps.customAcc.cash === undefined) &&
        customAcc.cash &&
        customAcc.cash !== 0
      ) {
        this.setState({ includeCash: true });
      }
    }
  };

  getPriceFlag = (holding) => {
    const currency = {
      CAD: 'ca',
      USD: 'us',
    };

    // if security_detail does not exist, use yodlee currency for flag
    return holding.security_detail
      ? currency[holding.security_detail.default_currency]
      : currency[holding.default_currency];
  };

  getRegion = () => {
    const { account } = this.props;
    return account.currency === 'USD' ? 'US' : 'CA';
  };

  setResolveGicConflict = () =>
    this.setState({ resolveGicConflict: true }, this.saveBulk);

  round = (num) => Math.round(num * 100) / 100;

  createAccount = () => {
    const { name, institution, currency } = this.state;
    const { dispatch } = this.props;

    return dispatch(
      CustomAccount.createAccount({
        name,
        institution,
        currency,
        label: name,
        user_institution: institution,
      })
    );
  };

  handleResultSelect = (title) => {
    const { dispatch } = this.props;

    return dispatch(CustomAccount.add(title));
  };

  handleSearchChange = (value) => {
    const { dispatch } = this.props;

    return (
      !!value.trim() &&
      dispatch(
        CustomAccount.search(window.encodeURIComponent(value), this.getRegion())
      )
    );
  };

  saveBulk = () => {
    const { dispatch, customAcc, account, history } = this.props;
    const { gic_quantity, gic_rate, gic_ticker, gic_name } = customAcc;
    const { resolveGicConflict } = this.state;
    const gicFormat = /^GIC\d*:MKT$/;
    const getParams = () =>
      Object.keys(customAcc.holdings).reduce(
        (acc, key) => {
          if (!gicFormat.test(key)) {
            const { quantity, book_value } = customAcc.holdings[key];

            if (
              customAcc.holdings[key].security_detail.unadjusted_closing_price
                .CAD
            ) {
              acc.tickers += !acc.tickers.length ? `${key}` : `,${key}`;
              acc.quantities += !acc.quantities.length
                ? `${quantity}`
                : `,${quantity}`;
              acc.book_values += !acc.book_values.length
                ? `${book_value || 0}`
                : `,${book_value || 0}`;
            }
          }

          return acc;
        },
        { tickers: '', quantities: '', book_values: '' }
      );
    const params = {
      account_id: account.id,
      cash_amount: this.state.includeCash ? customAcc.cash : 0,
      ...(gic_quantity &&
        gic_rate &&
        gic_ticker && {
          gics: [
            {
              gic_quantity: gic_quantity.toString(),
              gic_rate: parseFloat((gic_rate / 100).toFixed(4)),
              gic_ticker,
              gic_name,
            },
          ],
        }),
      ...(resolveGicConflict && { resolve_gic_conflict: resolveGicConflict }),
      ...getParams(),
    };

    return dispatch(CustomAccount.saveBulk(params)).then((response) => {
      this.setState({ resolveGicConflict: false });

      if (response && response.type === 'CUSTOMACCOUNT_HOLDING_FAIL') {
        if (response.msg.split(' ')[0] === 'gic_rate') {
          const conflictAccounts = response.msg
            .substring(0, response.msg.length - 1)
            .split('[')[1]
            .split(', ');

          return this.setState({ isOpen: 'confirm', conflictAccounts });
        } else if (response.msg.split(' ')[0] === 'currency') {
          return this.setState({ gicError: 'Ticker' });
        } else if (response.msg.split(' ')[0] === 'Unique') {
          return this.setState({ gicError: 'Unique' });
        }
      }

      return dispatch(Accounts.fetchAll()).then(() =>
        history.push(`/accounts/${account.id}`)
      );
    });
  };

  saveOnlyCash = () => {
    const { dispatch, customAcc, account, history } = this.props;

    dispatch(
      CustomAccount.saveBulk({
        account_id: account.id,
        cash_amount: customAcc.cash,
        quantities: '',
        tickers: '',
      })
    );

    return dispatch(
      Accounts.edit(account.id, {
        cash: customAcc.cash,
        balance: customAcc.cash,
      })
    ).then(() =>
      dispatch(Accounts.fetchAll()).then(() =>
        history.push(`/accounts/${account.id}`)
      )
    );
  };

  remove = (ticker) => {
    const { dispatch } = this.props;

    return dispatch(CustomAccount.remove(ticker));
  };

  removeAll = () => this.props.dispatch(CustomAccount.removeAll());

  isDisabled = () => {
    const { customAcc } = this.props;
    const { gic_ticker, gic_quantity, gic_rate } = customAcc;
    const { includeCash } = this.state;
    const gicFormat = /^GIC\d*:MKT$/;
    const hasGic = gic_ticker || gic_quantity || gic_rate;
    const hasHolding = Object.keys(customAcc.holdings).find(
      (ticker) => !gicFormat.test(ticker)
    );
    const holdingHasQuantity = Object.values(customAcc.holdings).reduce(
      (acc, val) => {
        if (val.security_detail.unadjusted_closing_price.CAD && !val.quantity) {
          acc = true;
        }
        return acc;
      },
      false
    );

    if (hasHolding && hasGic && includeCash) {
      return (
        !(gic_ticker && gic_quantity && gic_rate) ||
        holdingHasQuantity ||
        !(customAcc.cash > 0)
      );
    } else if (hasHolding && hasGic) {
      return !(gic_ticker && gic_quantity && gic_rate) || holdingHasQuantity;
    } else if (hasHolding && includeCash) {
      return !(customAcc.cash > 0) || holdingHasQuantity;
    } else if (hasGic && includeCash) {
      return !(gic_ticker && gic_quantity && gic_rate) || !(customAcc.cash > 0);
    } else if (hasHolding) {
      return holdingHasQuantity;
    } else if (hasGic) {
      return !(gic_ticker && gic_quantity && gic_rate);
    } else if (includeCash) {
      return !(customAcc.cash > 0);
    }

    return true;
  };

  closeModal = () =>
    this.setState({
      isOpen: false,
    });

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value });
  };

  handleGicChange = (e) => {
    const { name, value } = e.target;
    const { dispatch } = this.props;
    const num = Number(value);
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);

    if (name === 'gic_ticker') {
      this.setState({ isOpen: false, gicError: false });
    }

    if (name === 'gic_rate') {
      this.setState({ isOpen: false });
    }

    if (negative) {
      if (name === 'gic_quantity' || name === 'gic_rate') {
        return dispatch(CustomAccount.updateGic(name, null));
      }
    }

    if (name === 'gic_quantity' || name === 'gic_rate') {
      if (name === 'gic_rate' && num > 100) {
        return dispatch(CustomAccount.updateGic(name, 100));
      } else if (!negative) {
        const rate =
          value === ''
            ? ''
            : Number.isInteger(num)
            ? num
            : value.split('.')[1].length >= 2
            ? Number(
                `${value.split('.')[0]}.${value.split('.')[1].substring(0, 2)}`
              )
            : num;

        return dispatch(CustomAccount.updateGic(name, rate));
      }
    } else if (name === 'gic_name' || name === 'gic_ticker') {
      return dispatch(CustomAccount.updateGic(name, value));
    }

    return false;
  };

  renderModal = (history, partnerName) => (
    <Modal basicTop open vb={notWealthica(partnerName)}>
      <Modal.Header>
        <Header icon="book" content="Name New Account" color="green" />
      </Modal.Header>
      <Modal.Content>
        <Field vb={notWealthica(partnerName)}>
          Institution:
          <input
            placeholder="(required)"
            name="institution"
            value={this.state.institution || ''}
            onChange={this.handleChange}
            vb={notWealthica(partnerName)}
          />
        </Field>
        <Field vb={notWealthica(partnerName)}>
          Account type:
          <input
            placeholder="(required)"
            name="name"
            value={this.state.name || ''}
            onChange={this.handleChange}
            vb={notWealthica(partnerName)}
          />
        </Field>
        <Field>
          Currency:
          <div style={{ width: '80%', float: 'right' }}>
            <StyledDropdown
              className={cn({ invalid: null })}
              placeholder="Currency"
              name="currency"
              selection
              options={[
                {
                  key: 'cad',
                  text: 'CAD',
                  value: 'CAD',
                  icon: <Flag name="ca" />,
                },
                {
                  key: 'usd',
                  text: 'USD',
                  value: 'USD',
                  icon: <Flag name="us" />,
                },
              ]}
              value={this.state.currency || ''}
              onChange={(e, data) => this.setState({ currency: data.value })}
              style={{ width: '95px' }}
            />
          </div>
        </Field>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className={notWealthica(partnerName) ? 'left' : 'inverted left'}
          color={colors.red}
          icon="cancel"
          margin="0 0.5rem 0 0"
          onClick={() => history.push('/accounts/user')}
        >
          Cancel
        </Button>
        <Button
          className={cn(
            `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
            {
              loading: this.state.isLoading,
              disabled:
                !this.state.institution ||
                !this.state.name ||
                !this.state.currency,
            }
          )}
          color={colors.green}
          icon="checkmark"
          onClick={this.createAccount}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );

  render() {
    const {
      history,
      match,
      customAcc,
      account,
      partnerName,
      french,
      user_region,
      toggle_region,
    } = this.props;
    const { search } = customAcc;
    const { gic_ticker, gic_name, gic_quantity, gic_rate } = customAcc;
    const { includeCash, isOpen, conflictAccounts, gicError } = this.state;
    const displayGicRate = gic_rate ? Number(gic_rate.toFixed(2)) : null;
    const gicFormat = /^GIC\d*:MKT$/;
    const hasGic = gic_ticker || gic_quantity || gic_rate;
    const excludeGicHoldings = Object.keys(customAcc.holdings).filter(
      (holding) => !gicFormat.test(holding)
    );
    const isWealthica = partnerName === 'wealthica';

    if (match.params.id === 'new') {
      return this.renderModal(history, partnerName);
    }

    return (
      <Segment style={{ minHeight: '100%', textAlign: 'center' }}>
        <Header dividing size="large">
          {' '}
          {account.user_institution}{' '}
          <Flag name={account.currency === 'USD' ? 'us' : 'ca'} />
          <Header.Subheader>{account.label}</Header.Subheader>
        </Header>
        <Header size="medium" style={{ margin: '0', marginBottom: '0.5rem' }}>
          {french ? 'Ajouter des titres' : 'Add Holdings'}{' '}
        </Header>

        <SearchTicker
          loading={customAcc.isFetching}
          results={getTickerSearchResult(search)}
          featureAction={CustomAccount}
          region={this.getRegion()}
          french={french}
        />

        <Note style={{ marginTop: '1rem' }}>
          {account.currency === 'CAD'
            ? french
              ? 'Pour les comptes en CAD, vous pouvez rechercher des actions canadiennes et américaines, des FNB canadiens et américains, ainsi que des fonds communs de placement et fonds distincts canadiens.'
              : 'For CAD accounts, you can search for Canadian and US stocks, Canadian and US ETFs, and Canadian mutual and segregated funds.'
            : french
            ? 'Pour les comptes en USD, vous ne pouvez rechercher que des actions et des FNB américains.'
            : 'For USD accounts, you can search only for US stocks and ETFS.'}
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{ fontSize: '15px', verticalAlign: 'initial' }}
              />
            }
            position="top center"
          >
            <Popup.Content>
              {french
                ? `Pour les fonds communs de placement, utilisez leur code de fonds (p. ex. : ${
                    account.currency === 'CAD' ? 'TDB339' : 'VTSMX'
                  }) si vous les avez, afin de vous assurer que vous avez la bonne catégorie d’actions. Si vous effectuez une recherche par nom, ne mettez pas de points (p. ex. : mettez "US" au lieu de "U.S.").`
                : `For mutual funds, use their fund code (e.g. 
              ${account.currency === 'CAD' ? 'TDB339' : 'VTSMX'}) if you have
              them to make sure you have the correct share class. If searching
              by name, do not include periods (e.g., put "US" instead of
              "U.S.")`}
            </Popup.Content>
          </Popup>
        </Note>
        {notWealthica(partnerName) ? null : (
          <div>
            <Note style={{ margin: '1rem' }}>
              Note: Use this <Link to="/tools/new-account-setup"> tool </Link>{' '}
              to calculate the number of shares to purchase if you are setting
              up a new account.
            </Note>
          </div>
        )}

        <Segment className="basic compact" style={{ textAlign: 'left' }}>
          <div
            style={{
              width: french ? '27rem' : '22rem',
              display: 'inline-block',
              textAlign: 'left',
            }}
          >
            <Selection
              style={{ marginTop: '2rem' }}
              text={
                french
                  ? 'Ce compte contient-il de l’argent liquide ?'
                  : 'Does this account have cash?'
              }
              selected={includeCash}
              changeSelection={() => {
                this.props.dispatch(CustomAccount.updateCash(0));
                this.setState({ includeCash: !includeCash });
              }}
              french={french}
            />
          </div>
          {includeCash && (
            <Table
              textAlign="center"
              style={{ width: '25rem', marginBottom: '1rem', float: 'right' }}
            >
              <Table.Body>
                <Table.Cell
                  width={1}
                  style={{
                    background: 'rgba(245,93,90,0.2)',
                    fontWeight: '700',
                  }}
                >
                  {french ? 'Montant' : 'Cash Amount'}
                </Table.Cell>
                <Table.Cell width={3} style={{ position: 'relative' }}>
                  <div>
                    <CashAmount
                      value={customAcc.cash === undefined ? 0 : customAcc.cash}
                    />
                    <Flag
                      name={account.currency === 'USD' ? 'us' : 'ca'}
                      style={{ marginLeft: '0.3rem' }}
                    />
                  </div>
                </Table.Cell>
              </Table.Body>
            </Table>
          )}
        </Segment>

        {!!excludeGicHoldings.length && (
          <Segment className="basic compact">
            <RemoveAll onClick={this.removeAll}>
              {french ? 'effacer' : 'clear'}
            </RemoveAll>
            <Table textAlign="center" style={{ marginBottom: '2rem' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    colSpan="2"
                    style={{ background: 'rgba(245,93,90,0.2)' }}
                  >
                    {translateTableHeadings('Asset', french)}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(245,93,90,0.2)' }}
                  >
                    {translateTableHeadings('Exchange', french)}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(245,93,90,0.2)' }}
                  >
                    {translateTableHeadings('Class', french)}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(245,93,90,0.2)' }}
                  >
                    {translateTableHeadings('Price', french)}
                  </Table.HeaderCell>
                  {isWealthica ? (
                    <Table.HeaderCell
                      rowSpan="2"
                      style={{ background: 'rgba(245,93,90,0.2)' }}
                    >
                      Total Book Value
                      <br />
                      <span style={{ color: 'rgba(0,0,0,0.4)' }}>
                        (Optional)
                      </span>
                    </Table.HeaderCell>
                  ) : null}
                  <Table.HeaderCell
                    style={{ background: 'rgba(245,93,90,0.2)' }}
                  >
                    {translateTableHeadings('Quantity', french)}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.values(customAcc.holdings).map(
                  (holding) =>
                    !holding.gic_ticker && (
                      <StyledRow
                        negative={
                          !holding.security_detail.unadjusted_closing_price.CAD
                        }
                        key={holding.security_detail.ticker}
                        style={{ height: 52 }}
                      >
                        <i
                          className="close"
                          onClick={() =>
                            this.remove(holding.security_detail.ticker)
                          }
                        >
                          +
                        </i>
                        <Table.Cell style={{ position: 'relative' }} width={5}>
                          {holding.security_detail.ticker || 'N/A'}
                          <SecurityName>
                            {holding.security_detail.long_name || 'N/A'}
                          </SecurityName>
                          {!holding.security_detail.unadjusted_closing_price
                            .CAD && (
                            <Error>
                              <Popup
                                trigger={
                                  <Icon
                                    style={{
                                      transform: 'translate(-20px, -4px)',
                                      position: 'absolute',
                                      display: 'block',
                                      marginTop: '0',
                                    }}
                                    name="exclamation circle"
                                  />
                                }
                                position="left center"
                                content="Unfortunately, this security cannot be added as its price is $0"
                              />
                            </Error>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {holding.security_detail.exchange_symbol || 'N/A'}
                        </Table.Cell>
                        <Table.Cell>
                          {translateAssetClass(
                            french,
                            holding.security_detail.global_asset_class
                          ) || 'N/A'}
                        </Table.Cell>
                        <Table.Cell>
                          $
                          {this.round(
                            holding.security_detail.unadjusted_closing_price[
                              holding.security_detail.default_currency
                            ] || 0
                          )}
                          <Flag
                            name={this.getPriceFlag(holding)}
                            style={{ marginLeft: '0.3rem' }}
                          />
                        </Table.Cell>
                        {isWealthica ? (
                          <Table.Cell style={{ position: 'relative' }}>
                            <Quantity
                              field="book_value"
                              isMarketValue
                              ticker={holding.security_detail.ticker}
                              value={holding.book_value}
                            />
                          </Table.Cell>
                        ) : null}
                        <Table.Cell width={2} style={{ position: 'relative' }}>
                          {!!holding.security_detail.unadjusted_closing_price
                            .CAD ? (
                            <Quantity
                              field="quantity"
                              ticker={holding.security_detail.ticker}
                              value={holding.quantity}
                            />
                          ) : (
                            'N/A'
                          )}
                        </Table.Cell>
                      </StyledRow>
                    )
                )}
              </Table.Body>
            </Table>
          </Segment>
        )}
        <div style={{ padding: '0 1rem', marginBottom: '1.5rem' }}>
          <div style={{ textAlign: 'left' }}>
            <Header style={{ fontSize: '14.72px', margin: '0' }}>
              {user_region === 'CA' || toggle_region === 'CA'
                ? french
                  ? 'Ajouter CPG/CEIE'
                  : 'Add GIC/HISA'
                : french
                ? 'Ajouter un CD'
                : 'Add CD'}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
              >
                <Popup.Content>
                  {user_region === 'CA' || toggle_region === 'CA'
                    ? french
                      ? "Cette catégorie est destinée aux titres à revenu fixe tels que les certificats de placement garanti (CPG) ou les comptes d'épargne à intérêt élevé (CEIE)."
                      : 'This category is for fixed interest securities such as Guaranteed Investment Certificates (GICs) or High Interest Savings Accounts (HISAs).'
                    : french
                    ? 'Cette catégorie est destinée aux titres à revenu fixe tels que les certificats de dépôt (CD) et les dépôts à terme (TD).'
                    : 'This category is for fixed interest securities such as Certificates of Deposits (CDs) and term deposits (TDs).'}
                </Popup.Content>
              </Popup>
            </Header>
            <Header.Subheader
              style={{ color: 'rgba(0,0,0,0.5)', fontSize: '0.9rem' }}
            >
              ({translateText('Optional', french)})
            </Header.Subheader>
          </div>
          <GicField>
            <label>{translateText('Value', french)}</label>
            <input
              className={cn({ invalid: null })}
              name="gic_quantity"
              type="number"
              placeholder="$"
              value={gic_quantity || ''}
              onChange={this.handleGicChange}
            />
            {(gic_rate || gic_ticker) && !gic_quantity ? (
              <ErrorLabel>{translateText('Update value', french)}</ErrorLabel>
            ) : null}
          </GicField>
          <GicField>
            <label style={{ textAlign: 'left' }}>
              {translateText('Interest Rate', french)}
              <br />
              <span style={{ color: 'rgba(0,0,0,0.5)', fontSize: '1rem' }}>
                {french ? '(p. ex., 0.75%)' : '(e.g., 0.75%)'}
              </span>
            </label>
            <input
              className={cn({ invalid: null })}
              name="gic_rate"
              type="number"
              placeholder="%"
              value={displayGicRate || ''}
              onChange={this.handleGicChange}
            />
            {(gic_quantity || gic_ticker) && !gic_rate ? (
              <ErrorLabel>
                {translateText('Update interest rate', french)}
              </ErrorLabel>
            ) : null}
          </GicField>
          <GicField center>
            <label>
              {translateText('Ticker', french)}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
              >
                <Popup.Content>
                  {translateText(
                    'For display in the Portfolio Scorecard.',
                    french
                  )}
                </Popup.Content>
              </Popup>
            </label>
            <input
              className={cn({ invalid: null })}
              name="gic_ticker"
              placeholder=""
              value={gic_ticker || ''}
              onChange={this.handleGicChange}
            />
            {(gic_quantity || gic_rate) && !gic_ticker ? (
              <ErrorLabel>{translateText('Update ticker', french)}</ErrorLabel>
            ) : null}
          </GicField>
          <GicField center>
            <label>
              {translateText('Name', french)}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
              >
                <Popup.Content>
                  {translateText(
                    'For display in the Portfolio Scorecard.',
                    french
                  )}
                </Popup.Content>
              </Popup>
            </label>
            <input
              className={cn({ invalid: null })}
              name="gic_name"
              placeholder={`(${translateText('Optional', french)})`}
              value={gic_name || ''}
              onChange={this.handleGicChange}
            />
          </GicField>
        </div>
        {gicError === 'Ticker' && (
          <Message visible error style={{ margin: '0 auto', width: '400px' }}>
            <Icon
              name="exclamation circle"
              style={{ fontSize: '16px', transform: 'translateY(1px)' }}
            />
            {french
              ? `Ce symbole a déjà été utilisé dans un compte ${
                  account.currency === 'USD' ? 'CAD' : 'USD'
                }. Veuillez choisir un nouveau symbole. `
              : `This ticker has already been used in a ${
                  account.currency === 'USD' ? 'CAD' : 'USD'
                } account. Please enter a
            different ticker.`}
          </Message>
        )}
        {gicError === 'Unique' && (
          <Message visible error style={{ margin: '0 auto', width: '240px' }}>
            <Icon
              name="exclamation circle"
              style={{ fontSize: '16px', transform: 'translateY(1px)' }}
            />
            {translateText('The limit is 10 GICs/HISAs.', french)}
          </Message>
        )}
        <Button
          style={{ marginTop: '2rem' }}
          className={cn('left centered grey-focus2', {
            disabled: this.isDisabled() || gicError,
            loading: customAcc.isFetching,
          })}
          color={colors.green}
          icon="line chart"
          onClick={
            Object.keys(customAcc.holdings).length || hasGic
              ? this.saveBulk
              : this.saveOnlyCash
          }
        >
          {translateText('Save', french)}
        </Button>
        <ConfirmModal
          account={account}
          accountList={this.props.accountList}
          close={this.closeModal}
          conflictAccounts={conflictAccounts}
          open={isOpen === 'confirm'}
          setResolveGicConflict={this.setResolveGicConflict}
        />
      </Segment>
    );
  }
}

BuildCustomAccount.propTypes = {
  dispatch: PropTypes.func,
  customAcc: PropTypes.object,
  account: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  accountList: PropTypes.array,
  french: PropTypes.bool,
  user_region: PropTypes.string,
  toggle_region: PropTypes.string,
};

BuildCustomAccount.defaultProps = {
  dispatch: () => null,
  customAcc: {},
  account: {},
  partnerName: '',
  accountList: [],
  french: false,
  user_region: 'CA',
  toggle_region: 'CA',
};

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative;

  .close {
    position: absolute;
    display: block;
    margin: -10px;
    margin-top: 20px;
    z-index: 100;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const RemoveAll = styled.div`
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  width: 50px;
  font-size: 0.9rem;
  border-radius: 10px;
  line-height: 20px;
  border: 1px solid #dededf;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 1px solid #db2828;
    background: #fff6f6;
  }
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;
  line-height: 1.3em;
  border-bottom: 1px solid grey;
  padding: 0 0 1rem 0;
  &:last-child {
    padding: 1rem 0 0 0;
    border-bottom: unset;
  }

  &:nth-child(2) {
    padding: 1rem 0;
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 25px !important;
  min-height: unset !important;
  padding: 0.3rem !important;
  min-width: unset !important;
  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }
  i {
    padding: 0.5rem !important;
  }
`;

const GicField = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 32px;
  label {
    display: block;
    float: left;
  }
  input {
    position: absolute;
    left: calc(50% - 47px);
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 93px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;
    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
      text-align: ${(props) => (props.center ? 'center' : 'right')};
    }
    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const ErrorLabel = styled.div`
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  left: calc(50% + 58px);
  text-transform: initial;

  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(3%, 0) rotate(45deg);
    background: inherit;
    left: -5px;
    margin-top: -4px;
    top: 50%;
    border-width: 0 0 1px 1px;
    border-style: solid;
  }
`;
