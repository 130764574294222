import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, NavLink, Route, Switch } from 'react-router-dom';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Storage } from 'actions';

import Execute from '../Execute';
import Overview from '../Overview';
import Performance from '../Performance';
import Downside from '../Downside';
import Fees from '../Fees';
import Income from '../Income';
import Diversification from '../Diversification';
import AssetClass from '../Diversification/AssetClass';
import IndividualAsset from '../Diversification/IndividualAsset';
import Region from '../Diversification/Region';
import GlobalSector from '../Diversification/GlobalSector';
import MacroFactor from '../Diversification/MacroFactor';
import EquityStyle from '../Diversification/EquityStyle';

import GlobePDF from '../PDF';

@withRouter
@connect((state) => ({
  analytics: state.Analytics,
  check_history:
    state.Storage['pwpa-check-history'] === undefined
      ? true
      : state.Storage['pwpa-check-history'],
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
  pwpaHasExcludeHolding: state.Storage['pwpa-has-exclude-holding'],
}))
export default class Menu extends Component {
  state = {
    openDiversification: false,
    ableHover: false,
    initHasExcludedHolding: null,
  };

  componentDidMount = () => {
    const { openDiversification } = this.state;
    const { analytics, pwpaHasExcludeHolding, dispatch } = this.props;
    const hasExcludedHolding =
      analytics && analytics.data
        ? analytics.data.tickersOriginal.find((holding) => holding.isExcluded)
        : null;

    if (window.location.pathname.split('/').indexOf('diversification') !== -1) {
      if (!openDiversification) {
        this.setState({ openDiversification: true });
      }
    } else if (openDiversification) {
      this.setState({ openDiversification: false });
    }

    if (pwpaHasExcludeHolding === undefined) {
      dispatch(
        Storage.setItem('pwpa-has-exclude-holding', !!hasExcludedHolding)
      );
      this.setState({ initHasExcludedHolding: !!hasExcludedHolding });
    } else {
      this.setState({ initHasExcludedHolding: pwpaHasExcludeHolding });
    }
  };

  componentDidUpdate = () => {
    const { ableHover } = this.state;

    if (window.location.pathname.split('/').indexOf('diversification') !== -1) {
      if (!ableHover) {
        this.setState({ ableHover: true });
      }
    } else if (ableHover) {
      this.setState({ ableHover: false });
    }
  };

  render() {
    const { analytics, dispatch, history, check_history, pwpaPortfolio } =
      this.props;
    const { openDiversification, ableHover, initHasExcludedHolding } =
      this.state;

    return (
      <Container
        noMargin={history.location.pathname.includes('pdf')}
        onClick={() =>
          openDiversification
            ? this.setState({ openDiversification: false })
            : null
        }
      >
        <div
          style={{
            position: 'relative',
            display: `${
              history.location.pathname.includes('pdf') ? 'none' : 'block'
            }`,
          }}
        >
          <Tab to="/globe/overview">Overview</Tab>
          <Tab to="/globe/performance">Performance</Tab>
          <Tab to="/globe/downside">Downside</Tab>
          <Tab to="/globe/fees">Fees</Tab>
          <Tab to="/globe/income">Income</Tab>
          {
            /* eslint-disable */
            <SubMenu open={openDiversification} ableHover={ableHover}>
              <li
                className={cn({
                  active:
                    window.location.pathname
                      .split('/')
                      .indexOf('diversification') !== -1,
                })}
                onClick={() =>
                  openDiversification
                    ? null
                    : this.setState({ openDiversification: true })
                }
              >
                <NavLink to="/globe/diversification">Diversification</NavLink>
              </li>
              <li>
                <NavLink to="/globe/diversification/asset_class">
                  Asset Class
                </NavLink>
              </li>
              <li>
                <NavLink to="/globe/diversification/individual_asset">
                  Individual
                </NavLink>
              </li>
              <li>
                <NavLink to="/globe/diversification/region">Region</NavLink>
              </li>
              <li>
                <NavLink to="/globe/diversification/global_sector">
                  Global Stock Sector
                </NavLink>
              </li>
              <li>
                <NavLink to="/globe/diversification/macro_factor">
                  Macro Factor
                </NavLink>
              </li>
              <li>
                <NavLink to="/globe/diversification/equity_style">
                  Equity Style
                </NavLink>
              </li>
            </SubMenu>
            /* eslint-enable */
          }
        </div>

        <Switch>
          <Route
            path="/globe/overview"
            render={(props) => (
              <Overview
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                income={analytics.income}
                objective={analytics.objective}
                initHasExcludedHolding={initHasExcludedHolding}
                check_history={check_history}
                pwpaPortfolio={pwpaPortfolio}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/performance"
            render={(props) => (
              <Performance
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                user_region="CA"
                {...props}
              />
            )}
          />
          <Route
            path="/globe/downside"
            render={(props) => (
              <Downside
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/fees"
            render={(props) => (
              <Fees
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/income"
            render={(props) => (
              <Income
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/globe/diversification"
            render={(props) => (
              <Diversification
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/diversification/asset_class"
            render={(props) => (
              <AssetClass
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/diversification/individual_asset"
            render={(props) => (
              <IndividualAsset
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/diversification/region"
            render={(props) => (
              <Region
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/diversification/global_sector"
            render={(props) => (
              <GlobalSector
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/diversification/macro_factor"
            render={(props) => (
              <MacroFactor
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/diversification/equity_style"
            render={(props) => (
              <EquityStyle
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route
            path="/globe/execute"
            render={(props) => (
              <Execute
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                {...props}
              />
            )}
          />
          <Route path="/globe/pdf" component={GlobePDF} />
        </Switch>
      </Container>
    );
  }
}

Menu.propTypes = {
  analytics: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  pwpaHasExcludeHolding: PropTypes.bool,
  check_history: PropTypes.bool,
  pwpaPortfolio: PropTypes.object,
};

Menu.defaultProps = {
  analytics: {},
  dispatch: () => false,
  history: {},
  pwpaHasExcludeHolding: false,
  check_history: true,
  pwpaPortfolio: {},
};

const Tab = styled(({ className, children, ...rest }) => (
  <NavLink className={className} {...rest}>
    {children}
  </NavLink>
))`
  display: inline-block;
  padding: 1rem 0;
  width: calc(100% / 6);
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  border-left: 1px solid rgb(236, 236, 240);
  border-bottom: 1px solid rgb(236, 236, 240);

  &:first-child {
    border-left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
  }

  &.active {
    font-weight: 700;
    background: rgb(246, 246, 250);

    &:after {
      background: ${colors.red};
    }
  }

  &:hover {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const SubMenu = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: calc(100% / 6);
  height: ${(props) => (props.open ? 'auto' : '48px')};
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 2;
  border-top-right-radius: 8px;

  &:hover {
    height: ${(props) => (props.ableHover ? 'auto' : '48px')};

    li:first-child {
      border: ${(props) =>
        props.ableHover
          ? '1px solid black !important'
          : '1px solid rgb(236,236,240)'};
      border-bottom: ${(props) =>
        props.ableHover
          ? '1px solid rgb(236,236,240) !important'
          : '1px solid rgb(236,236,240)'};
      border-top-right-radius: ${(props) =>
        props.ableHover ? '8px !important' : 'unset'};
      border-top: ${(props) =>
        props.ableHover ? 'transparent !important' : 'unset'};
    }
  }

  li:first-child {
    border: ${(props) =>
      props.open ? '1px solid black !important' : 'inherit'};
    border-bottom: ${(props) =>
      props.open
        ? '1px solid rgb(236,236,240) !important'
        : '1px solid rgb(236,236,240)'};
    border-top-right-radius: ${(props) =>
      props.open ? '8px !important' : 'unset'};
    border-top: ${(props) => (props.open ? 'transparent !important' : 'unset')};
  }

  li {
    padding: 1rem 0;
    width: 100%;
    height: 48px;
    font-size: 1rem;
    position: relative;
    text-align: center;
    border-left: 1px solid black;
    border-bottom: 1px solid rgb(236, 236, 240);
    border-right: 1px solid black;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
    }

    &.active:after {
      background: ${colors.red};
    }

    &:first-child {
      border-left: 1px solid rgb(236, 236, 240);
      border-right: 1px solid white;
    }

    &:last-child {
      border-bottom: 1px solid black;
    }

    &:hover {
      font-weight: 700;
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: rgba(0, 0, 0, 0.8) !important;
      line-height: 48px;

      &.active {
        font-weight: 700;
        background: rgb(246, 246, 250);
      }
    }
  }
`;

const Container = styled.div`
  min-height: 100%;
  border-radius: 8px;
  background: white;
  margin: ${(props) => (props.noMargin ? '0' : '1rem')};
`;
