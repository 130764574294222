import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

import { graph } from 'utils/colors';

export default class BeforeTaxPie extends Component {
  getChartData = (data) => ({
    labels: [
      'CPP',
      'OAS',
      'GIS',
      'Employer Pension',
      'Other',
      'Dividends',
      'RRIF',
      'Non-registered',
      'TFSA',
    ],
    datasets: [
      {
        data: [
          data.incCPP,
          data.incOAS,
          data.incGIS,
          data.incPension,
          data.incOther,
          data.incDividend,
          data.wdReqMin + data.wdRRIF,
          data.wdRegular,
          data.wdTFSA,
        ],
        backgroundColor: Object.values(graph).slice(0, 9),
      },
    ],
  });

  render() {
    const { data } = this.props;

    return (
      <Pie
        style={{ marginTop: '2rem' }}
        data={this.getChartData(data)}
        options={pieChartOptions}
      />
    );
  }
}

BeforeTaxPie.propTypes = {
  data: PropTypes.object.isRequired,
};

const pieChartOptions = {
  showAllTooltips: true,
  maintainAspectRatio: false,
  cutoutPercentage: 65,
  responsive: true,
  legend: {
    position: 'bottom',
  },
  layout: {
    padding: 0,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, dataItem) => {
        const { index, datasetIndex } = tooltipItems;
        return `${dataItem.labels[index]}: $${dataItem.datasets[
          datasetIndex
        ].data[index].toLocaleString()}`;
      },
    },
    displayColors: false,
  },
};
