import api from 'utils/api';

/**
 * Action constants
 */
export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';

/**
 * Action creators
 */
export function request(method, path, options = {}) {
  return (dispatch, getState) => {
    dispatch({ type: API_REQUEST });

    const { Storage } = getState();
    const pwpaToken = Storage ? Storage['pwpa-token'] : null;

    const actionOptions = { dispatch, token: pwpaToken };

    return api
      .request(method, path, { ...options, ...actionOptions })
      .then((data) => {
        dispatch({ type: API_SUCCESS });
        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch({ type: API_FAILURE });
        return Promise.reject(error);
      });
  };
}
