import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { RebalanceAccount } from 'actions';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
class Weight extends Component {
  state = {
    focused: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = () => {
    const { dispatch, ticker, value } = this.props;

    if (value === '') {
      dispatch(RebalanceAccount.updateWeight(ticker, 0));
    }

    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;

    e.target.value = '';

    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    if (Number(value) < -100) {
      return dispatch(RebalanceAccount.updateWeight(name, -100));
    }

    if (Number(value) > 200) {
      return dispatch(RebalanceAccount.updateWeight(name, 200));
    }

    return dispatch(RebalanceAccount.updateWeight(name, value));
  };

  focus = () => {
    const { value, ticker, dispatch } = this.props;

    if (!value) {
      dispatch(RebalanceAccount.updateWeight(ticker, ''));
    }

    return this.setState({ focused: true });
  };

  render() {
    const { ticker, value, isEditing, french } = this.props;
    const { focused } = this.state;

    return !focused ? (
      <Value
        className={`${isEditing ? 'edit' : ''}`}
        onClick={isEditing ? this.focus : () => false}
      >
        {value || 0} %{' '}
        {isEditing && !value && (
          <ErrorLabel>{french ? 'Ajoutez' : 'Update'}</ErrorLabel>
        )}
      </Value>
    ) : (
      <Input
        name={ticker}
        size="mini"
        type="number"
        value={value}
        onChange={this.handleChange}
        style={{ maxWidth: 80 }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    );
  }
}

Weight.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticker: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  french: PropTypes.bool,
};

Weight.defaultProps = {
  french: false,
};

export default Weight;

const Value = styled.span`
  position: relative;
  cursor: default;
  color: black;
  padding: 11px 5px;
  display: flex;
  justify-content: center;

  &.edit {
    cursor: pointer;
    color: #2185d0;

    &:hover {
      color: #1e70bf;
      text-decoration: underline;
    }
  }
`;

const ErrorLabel = styled.div`
  position: absolute;
  white-space: nowrap;
  transform: translateY(-25%) translateX(-90%); // Adjust for positioning to the left
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  text-transform: initial;
  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -40%) rotate(45deg); // Adjust arrow position
    background: inherit;
    right: -9px;
    top: 50%;
    border-width: 1px 1px 0 0; // Adjust border for the arrow
    border-style: solid;
  }
`;
