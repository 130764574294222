import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';

import { Accounts } from 'actions';
import Message from './Message';

class YodleeMsg extends Component {
  componentWillMount = () => {
    const { dispatch, unsupported } = this.props;

    if (!unsupported.length && !unsupported.closed) {
      return dispatch(Accounts.showUnsupportedMsg());
    }

    return false;
  };

  closeMessage = () => this.props.dispatch(Accounts.hideUnsupportedMsg());

  render() {
    const { unsupported } = this.props;

    return !unsupported.institutions.length ? null : (
      <Message visible={!unsupported.closed} closeable>
        <Message.Header>
          <Icon name="warning sign" />
          Important
        </Message.Header>
        <Message.Content>
          Our aggregation provider, Yodlee, has informed us that the following
          institutions are temporarily unavailable:
          <Popup
            trigger={<Icon name="question circle outline" />}
            position="top center"
            wide
            content="A financial institution may be unavailable for account linking for a variety of reasons. Examples are: the institution is disallowing aggregation services from accessing its site; the institution's site is down; or the aggregation service provider is experiencing technical problems."
          />
          <div style={{ marginTop: '0.5rem' }}>
            {unsupported.institutions.map((item) => (
              <span key={item} className="institution">
                {item}
              </span>
            ))}
          </div>
        </Message.Content>
        <div className="close-icon" onClick={this.closeMessage}>
          +
        </div>
      </Message>
    );
  }
}

YodleeMsg.propTypes = {
  dispatch: PropTypes.func.isRequired,
  unsupported: PropTypes.object,
};

YodleeMsg.defaultProps = {
  unsupported: {
    institutions: [],
    closed: false,
  },
};

export default connect((state) => ({
  unsupported: state.Accounts.unsupported,
}))(YodleeMsg);
