import { request } from './api';
import * as Alert from './alert';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_ANSWER = 'RECEIVE_ANSWER';
export const CLEAR = 'CLEAR';

export const clear = () => ({ type: CLEAR });

export function runChat(question) {
  return (dispatch) => {
    // Modify the payload to match the desired structure

    dispatch({ type: SEND_MESSAGE, question });

    return dispatch(
      request('post', '/aitools/qa/', {
        body: { question },
      })
    ).then(
      (data) => dispatch({ type: RECEIVE_ANSWER, answer: data.result }),
      (error) =>
        dispatch(Alert.show({ type: 'error', msg: 'Error with request' }))
    );
  };
}
