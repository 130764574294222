import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Icon, Header, Label } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';
import _ from 'lodash';
import { Security, CorrelationMatrix as CorrelationMatrixTool } from 'actions';
import { Segment, SearchTicker } from 'components';
import { correlationMatrixToolColors, colors } from 'utils/colors';
import Diversification from './Diversification';

class CorrelationMatrix extends Component {
  state = {
    warningMessage: '',
  };
  componentDidMount = () => {
    const { inputs } = this.props;
    const { securityList } = inputs;

    if (securityList && securityList.length) {
      this.drawChart();
    }
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.inputs, this.props.inputs)) {
      this.drawChart();
    }
  }

  getResults = () => {
    const { search } = this.props.security;

    return search.reduce((total, security) => {
      if (security) {
        if (!total[security.asset_type]) {
          total[security.asset_type] = {
            name: security.asset_type,
            results: [
              {
                title: security.ticker,
                description: security.long_name,
                price: security.exchange_name,
              },
            ],
          };
        } else {
          total[security.asset_type].results.push({
            title: security.ticker,
            description: security.long_name,
            price: security.exchange_name,
          });
        }
      }
      return total;
    }, {});
  };

  handleSearchChange = (value) => {
    const { dispatch } = this.props;
    const { warningMessage } = this.state;

    if (
      warningMessage !==
      'Maximum number of securities reached. Please remove one before adding.'
    ) {
      this.setState({ warningMessage: '' });
    }

    return (
      !!value.trim() &&
      dispatch(Security.searchAll(window.encodeURIComponent(value)))
    );
  };

  handleDeletion = (item) => {
    const { dispatch, inputs } = this.props;
    const { securityList } = inputs;

    let newSecurityList = securityList;

    newSecurityList = securityList.filter((val) => val !== item);

    dispatch(
      CorrelationMatrixTool.updateInputs({
        securityList: newSecurityList,
      })
    );
    this.setState({ warningMessage: '' });
  };

  handleFiveLimit = () => {
    this.setState({
      warningMessage:
        'Maximum number of securities reached. Please remove one before adding.',
    });
  };

  handleSearchResultSelect = (title) => {
    const { dispatch, inputs } = this.props;
    const { securityList } = inputs;
    const { warningMessage } = this.state;

    const hasReachedLimit = securityList.length >= 5;
    const isRepeatedSecurity = securityList.includes(title);

    if (!hasReachedLimit && !isRepeatedSecurity) {
      dispatch(
        CorrelationMatrixTool.updateInputs({
          securityList: securityList.concat(title),
        })
      );
      // Clear the warning message when a security is added successfully
      if (
        warningMessage !==
        'Maximum number of securities reached. Please remove one before adding.'
      ) {
        this.setState({ warningMessage: '' });
      }
    } else if (isRepeatedSecurity) {
      // Set the repeated security warning message
      this.setState({
        warningMessage: 'This security is already in the list.',
      });
    }
  };

  drawChart = () => {
    const { dispatch, inputs } = this.props;
    const { securityList } = inputs;

    if (securityList.length) {
      dispatch(
        CorrelationMatrixTool.fetchCorrelationParams(securityList.join(','))
      );
    }
  };

  render() {
    const { warningMessage } = this.state;
    const hasErrorMessage = Boolean(warningMessage);
    const { security, user_region, inputs, analytics, dispatch, data } =
      this.props;
    const { securityList } = inputs;
    const showcorrelationmatrix =
      data &&
      !!securityList.length &&
      data.corr &&
      Object.keys(data.corr).length !== 0;

    let excludedSecuritiesWarning = '';
    if (data && data.excluded && Object.keys(data.excluded).length > 0) {
      const excludedSecurities = Object.keys(data.excluded).join(', ');
      excludedSecuritiesWarning = `${excludedSecurities} is excluded in the correlation matrix because it has less than 12 months of history.`;
    }
    const hasReachedLimit = securityList.length >= 5;

    return (
      <Segment
        padding="0rem"
        textAlign="center"
        style={{
          position: 'relative',
          minHeight: '700px',
          overflowX: 'hidden',
        }}
      >
        <HeaderContainer
          showcorrelationmatrix={showcorrelationmatrix}
          scrollypos={window.scrollY}
        >
          <Header size="large" textAlign="center">
            <Header.Content>Correlation Matrix</Header.Content>
            <Header.Subheader>
              In the context of portfolio analysis, a correlation coefficient
              measures how two assets' returns move in relation to one another
              over time. The coefficient takes on a value between -1 and +1. The
              closer it is to +1, the less benefit there is to diversification.
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
              >
                This means that including both assets in the portfolio is not
                effective in lowering portfolio risk.
              </Popup>
              <br />
              <br />
              <segment style={{ color: 'black' }}>
                Add up to <strong>five</strong> securities; minimum 12 months of
                history.
              </segment>
            </Header.Subheader>
          </Header>
          <Note>
            {user_region === 'CA'
              ? 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'
              : 'Note: Currently we only have data for US-listed stocks and ETFs, and US mutual funds.'}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
            />
            Always check the fund code or ticker symbol to ensure you have the
            correct share class. Use US instead of U.S..
          </Note>

          <div>
            <WarningContainer>
              {excludedSecuritiesWarning && (
                <WarningMessage>
                  <Icon name="warning sign" /> {excludedSecuritiesWarning}
                </WarningMessage>
              )}
              {warningMessage && (
                <WarningMessage>
                  <Icon name="warning sign" /> {warningMessage}
                </WarningMessage>
              )}
            </WarningContainer>
          </div>
          <div>
            <SearchTicker
              loading={security.isFetching}
              results={this.getResults()}
              customResultSelect={this.handleSearchResultSelect}
              customSearchChange={this.handleSearchChange}
              correlationData={data}
              handleDeletion={this.handleDeletion}
              hasReachedLimit={hasReachedLimit}
              handleFiveLimit={this.handleFiveLimit}
              setMargin
              style={{
                display: 'inline-block',
                margin: '0.7rem',
                textAlign: 'left',
              }}
            />
            {showcorrelationmatrix && (
              <Popup
                trigger={
                  <Icon
                    name="undo"
                    style={{ fontSize: '1.1rem', verticalAlign: 'initial' }}
                    onClick={() => {
                      this.setState({ warningMessage: '' });
                      dispatch(CorrelationMatrixTool.clearCorrelationData());
                    }}
                  />
                }
                position="right center"
                wide
                content="Restart"
              />
            )}
          </div>

          <Labels>
            {securityList.map((item, index) => (
              <Label
                style={{
                  backgroundColor: Object.values(correlationMatrixToolColors)[
                    index
                  ],
                  color: 'white',
                }}
                key={index}
              >
                {item}{' '}
                <Icon
                  name="delete"
                  link
                  onClick={() => this.handleDeletion(item)}
                />
              </Label>
            ))}
          </Labels>
        </HeaderContainer>

        {showcorrelationmatrix && (
          <Fade duration={3500}>
            <ContentContainer
              className="content-container"
              hasErrorMessage={hasErrorMessage || excludedSecuritiesWarning}
            >
              <Diversification
                className="diversification"
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                correlationData={data}
                handleDeletion={this.handleDeletion}
                handleFiveLimit={this.handleFiveLimit}
                hasReachedLimit={this.hasReachedLimit}
              />
            </ContentContainer>
          </Fade>
        )}
      </Segment>
    );
  }
}

const Labels = styled.div`
  height: 1rem;
`;

const Note = styled.div`
  max-width: 55rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 1rem auto 0rem auto;
`;

CorrelationMatrix.propTypes = {
  dispatch: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  inputs: PropTypes.object,
  analytics: PropTypes.object,
  data: PropTypes.object,
};
CorrelationMatrix.defaultProps = {
  user_region: 'CA',
  inputs: {},
  analytics: {},
  data: {},
};

export default connect((state) => ({
  security: state.Security,
  user_region: state.Storage.user_region || 'CA',
  inputs: state.CorrelationMatrix.inputs,
  data: state.CorrelationMatrix.data,
  analytics: state.Analytics,
  user: state.Auth.user,
}))(CorrelationMatrix);

const HeaderContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  height: 100px;
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: ${(props) =>
    !props.showcorrelationmatrix
      ? 'translate(-50%, 180%)'
      : 'translate(-50%, 0)'};
  transition: transform 1s;
  background-color: white;
  z-index: 2;
  width: 100%;
  padding-bottom: 30px;
`;

const ContentContainer = styled(
  ({ className, children, hasErrorMessage, ...rest }) => (
    <div className={className} {...rest}>
      {children}
    </div>
  )
)`
  margin-top: 300px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const WarningMessage = styled.div`
  color: ${colors.red};
  font-weight: bold;
  margin: 0.7rem 0 0 0;
`;

const WarningContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
