import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Header } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { Accounts, Planning, Portfolio } from 'actions';
import { LogoSpinner, Segment } from 'components';
import cn from 'classnames';

import Introduction from './introduction';
import GeneralInformation from './GeneralInformation';
import RetirementIncome from './RetirementIncome';
import InvestmentPortfolio from './InvestmentPortfolio';
import AccountValues from './AccountValues';
import InvestmentScenarios from './InvestmentScenarios';
import Result from './CoupleResult';
import Container from './Container';
import ErrorResult from './ErrorResult';
import { OasModal, CppModal, NavigationButtons } from './components';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
  isCppModalOpen: state.Planning.isCppModalOpen,
  isOasModalOpen: state.Planning.isOasModalOpen,
  coupleDrawdown: state.Planning.drawdown.coupleDrawdown,
}))
export default class Drawdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scenario: null,
    };
  }
  componentWillMount = () => {
    const { dispatch, match, portfolios, drawdown } = this.props;
    const { inputs } = drawdown;
    const { id } = match.params;

    dispatch(Planning.getCppOasDataset());
    dispatch(Accounts.fetchAll());

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    if (!inputs.isRiskFree && inputs.scenario) {
      this.setState({
        scenario: inputs.scenario,
        displayScenario: inputs.scenario,
      });
    }

    if (Number(id) && !drawdown.id) {
      dispatch(Planning.fetchCoupleDrawdownPlanId(id));
      dispatch(Planning.navigateDrawdown(6));
    }

    return false;
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const { drawdown } = this.props;
      const { displayScenario } = this.state;

      if (drawdown) {
        const { inputs, modified } = drawdown;
        const { scenario } = inputs;

        if (scenario) {
          this.setState({ scenario });

          if (!modified && !displayScenario) {
            this.setState({ displayScenario: scenario });
          }
        }
      }
    }
  };
  tabHasErrors = (tab) => {
    const { drawdown } = this.props;
    const { errors } = drawdown;
    const requiredInputs = {
      Introduction: [],
      'Personal Information': ['age', 'horizon', 'prov'],
      'Retirement Income': [
        'cppAge',
        'cppAge2',
        'oasAge',
        'oasAge2',
        'cpp',
        'cpp2',
        'oas',
        'oas2',
        'myCpp',
        'myOas',
      ],
      'Investment Portfolio': ['portfolio'],
      'Account Values': [
        'initRegular',
        'initRegular2',
        'initRRIF',
        'initRRIF2',
        'initTFSA',
        'initTFSA2',
        'pctCapGain',
        'pctCapGain2',
        'divYield',
        'divYield2',
        'constantRets',
        'constantRets2',
        'pctEligibleDiv',
        'pctEligibleDiv2',
        'endValue',
        'endValue2',
      ],
      'Investment Scenario': ['scenario'],
    };

    const tabRequiredInputs = requiredInputs[tab] || [];

    // Check if any of the required inputs for this tab have errors
    return tabRequiredInputs.some((input) => errors[input]);
  };

  handleMenuItemClick = (page) => {
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown;

    if (page > 1) {
      const errors = { ...drawdown.errors };
      let hasErrors = false;

      if (!inputs.age || Number(inputs.age) === 0) {
        errors.age = 'Age is required';
        hasErrors = true;
      }
      if (!inputs.horizon || Number(inputs.horizon) === 0) {
        errors.horizon = 'Horizon is required';
        hasErrors = true;
      }
      if (!inputs.prov) {
        errors.prov = 'Province is required';
        hasErrors = true;
      }

      if (hasErrors) {
        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { ...inputs },
            errors,
          })
        );
      }
    }

    dispatch(Planning.navigateDrawdown(page));
  };

  handleNextPage = () => {
    const { dispatch, drawdown } = this.props;
    const { activePage, inputs } = drawdown;
    const spouseSpecificPages = [1, 2, 3, 4];

    if (spouseSpecificPages.includes(activePage)) {
      if (inputs.activeSpouse === 'spouse') {
        this.handleSpouseChange('spouse2');
      } else {
        this.handleSpouseChange('spouse');
        dispatch(Planning.navigateDrawdown(activePage + 1));
      }
    } else {
      dispatch(Planning.navigateDrawdown(activePage + 1));
    }
  };

  handlePreviousPage = () => {
    const { dispatch, drawdown } = this.props;
    const { activePage, inputs } = drawdown;
    const spouseSpecificPages = [1, 2, 3, 4];

    if (spouseSpecificPages.includes(activePage)) {
      if (inputs.activeSpouse === 'spouse2') {
        this.handleSpouseChange('spouse');
      } else {
        dispatch(Planning.navigateDrawdown(activePage - 1));
      }
    } else {
      dispatch(Planning.navigateDrawdown(activePage - 1));
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown, cppDataset, oasDataset, cppEstimator } =
      this.props;
    const { inputs } = drawdown;
    const { activeSpouse } = inputs; // Get the active spouse

    // Determine the correct field names based on the active spouse
    const ageField = activeSpouse === 'spouse' ? 'age' : 'age2';
    const horizonField = activeSpouse === 'spouse' ? 'horizon' : 'horizon2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';
    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';

    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;
    if (
      (name === ageField || name === horizonField) &&
      (value.includes('.') || value.includes('-'))
    ) {
      return;
    }

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'cpp2',
        'oas',
        'oas2',
        'pension',
        'pension2',
        'other',
        'other2',
        'initRegular',
        'initRegular2',
        'initRRIF',
        'initRRIF2',
        'initTFSA',
        'initTFSA2',
        'endValue',
        'endValue2',
      ];

      if (field === horizonField) {
        const maxHorizon = 110;
        const sum = Number(val) + Number(inputs[ageField]);
        if (Number(val) < 1) {
          this.setState({
            warning: {
              ...this.state.warning,
              [horizonField]: 'Please enter a number greater than 0.',
            },
          });
          return val;
        }

        if (sum > maxHorizon) {
          this.setState({
            warning: {
              ...this.state.warning,
              [horizonField]:
                'We restrict the sum of your age and planning horizon to be no more than 110 years.',
            },
          });
          return val;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) return 70;
      if (isOas && Number(age) < 65) return 65;
      if (Number(age) < 60) return 60;
      if (Number(age) > 70) return 70;
      return age;
    };

    // Detect age change and set error message if needed
    if (name === ageField) {
      const { cpp, oas, cpp2, oas2 } = inputs;
      const cppOrOasSet = cpp || oas || cpp2 || oas2;
      const newAge = Number(parsedValue(name, value));
      if (value < 49) {
        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { ...drawdown.inputs, [ageField]: value },
            errors: {
              ...drawdown.errors,
              [ageField]: 'Minimum age is 49',
            },
          })
        );
      }
      this.setState({ previousAge: newAge });

      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(newAge, false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(newAge, true))
      ).amount;
      if (newAge < 60) {
        if (cppOrOasSet) {
          this.setState({
            error: {
              [ageField]:
                'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                [cppField]: true,
                [oasField]: true,
              },
            })
          );
        }
        this.changeSelection(cppStartedField, false);
        this.changeSelection(oasStartedField, false);
      } else if (newAge < 65) {
        if (cppOrOasSet) {
          this.setState({
            error: {
              [ageField]:
                'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                [cppField]: true,
                [oasField]: true,
              },
            })
          );
        }
        this.changeSelection(oasStartedField, false);
        this.changeSelection(cppStartedField, true);
      } else {
        if (cppOrOasSet) {
          this.setState({
            error: {
              [ageField]:
                'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                [cppField]: true,
                [oasField]: true,
              },
            })
          );
        }
        this.changeSelection(oasStartedField, true);
        this.changeSelection(cppStartedField, true);
      }

      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [ageField]: newAge,
            ...(newAge > 60 && { [cppAgeField]: newAge }),
            ...(newAge > 65 && { [oasAgeField]: newAge }),
          },
          errors: {
            ...drawdown.errors,
            [ageField]: false,
            ...(newAge > 60 && inputs[cppAgeField] && { [cppAgeField]: false }),
            ...(newAge > 65 && inputs[oasAgeField] && { [oasAgeField]: false }),
            ...(inputs[oasStartedField] &&
              inputs[oasField] > oasMax && {
                [oasField]:
                  'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs[cppStartedField] &&
              inputs[cppField] <= cppMax && { [cppField]: null }),
            ...(inputs[oasStartedField] &&
              inputs[oasField] <= oasMax && { [oasField]: null }),
            ...(!inputs[cppStartedField] && { [cppAgeField]: null }),
            ...(!inputs[oasStartedField] && { [oasAgeField]: null }),
          },
          modified: true,
        })
      );
    }

    if (name === cppAgeField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [cppAgeField]: parsedValue(name, value), [cppField]: '' },
          errors: { [cppAgeField]: false, [cppField]: false },
          modified: true,
        })
      );
    }

    if (name === oasAgeField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [oasAgeField]: parsedValue(name, value), [oasField]: '' },
          errors: { [oasAgeField]: false, [oasField]: false },
          modified: true,
        })
      );
    }

    if (name === cppField) {
      if (!inputs[cppAgeField] && inputs[cppStartedField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [cppAgeField]: inputs[ageField] || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();
      const body = {
        retirement_year:
          Number(currentYear) +
          Number(inputs[cppAgeField]) -
          Number(inputs[ageField]),
        start_cpp_age: inputs[cppAgeField],
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs[cppAgeField] !== '' && inputs[cppAgeField] >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, [cppField]: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cpp: money },
            errors: { cpp: false },
            modified: true,
          })
        );
      }
      return this.setState({
        warning: {
          ...this.state.warning,
          [cppField]:
            'To start receiving CPP/QPP, you must be at least 60 years old. Please enter a valid age.',
        },
      });
    }

    if (name === oasField) {
      if (!inputs[oasAgeField] && inputs[oasStartedField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [oasAgeField]: inputs[ageField] || 0 },
            modified: true,
          })
        );
      }

      if (inputs[oasAgeField] !== '' && inputs[oasAgeField] >= 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs[oasAgeField])
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs[oasAgeField],
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, [oasField]: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [oasField]: money },
          errors: { [oasField]: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;
    const { activeSpouse } = drawdown.inputs; // Get the active spouse

    // Determine the correct field names based on the active spouse
    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';

    if (field === cppStartedField) {
      if (drawdown.inputs[cppStartedField] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [cppStartedField]: value,
              [cppAgeField]: '',
              [cppField]: '',
            },
            errors: { [cppAgeField]: false, [cppField]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { [cppField]: '' } });
      }
    }

    if (field === oasStartedField) {
      if (drawdown.inputs[oasStartedField] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [oasStartedField]: value,
              [oasAgeField]: '',
              [oasField]: '',
            },
            errors: { [oasAgeField]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { [oasField]: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  toggleOasModal = (val) => {
    const { dispatch } = this.props;
    dispatch(Planning.setOasModalOpen(val));
  };

  toggleCppModal = (val) => {
    const { dispatch } = this.props;
    dispatch(Planning.setCppModalOpen(val));
  };

  updateCppInfo = (cppAge, cpp) => {
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown;
    const cppAgeInput = inputs.activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const cppInputName = inputs.activeSpouse === 'spouse' ? 'cpp' : 'cpp2';

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [cppAgeInput]: cppAge, [cppInputName]: cpp },
        errors: { [cppAgeInput]: false, [cppInputName]: false },
        modified: true,
      })
    );
  };

  updateOasInfo = (oasAge, oas) => {
    const { dispatch, drawdown } = this.props;
    const { inputs } = drawdown;
    const oasAgeInput = inputs.activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const oasInputName = inputs.activeSpouse === 'spouse' ? 'oas' : 'oas2';

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [oasAgeInput]: oasAge, [oasInputName]: oas },
        errors: { [oasAgeInput]: false, [oasInputName]: false },
        modified: true,
      })
    );
  };
  handleSpouseChange = (spouse) => {
    const { dispatch } = this.props;
    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { activeSpouse: spouse },
        errors: { activeSpouse: false },
        modified: true,
      })
    );
  };

  render() {
    const {
      drawdown,
      portfolios,
      dispatch,
      cppDataset,
      match,
      isCppModalOpen,
      isOasModalOpen,
      coupleDrawdown,
    } = this.props;
    const { activePage, result, isFetching } = drawdown;
    const { scenario } = this.state;

    const components = [
      <Introduction activePage={activePage} />,
      <GeneralInformation
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        handleChange={this.handleChange}
        changeSelection={this.changeSelection}
      />,
      <RetirementIncome
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        toggleOasModal={this.toggleOasModal}
        toggleCppModal={this.toggleCppModal}
        handleChange={this.handleChange}
        changeSelection={this.changeSelection}
      />,
      <InvestmentPortfolio
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        handleChange={this.handleChange}
        changeSelection={this.changeSelection}
      />,
      <AccountValues
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        handleChange={this.handleChange}
        changeSelection={this.changeSelection}
      />,

      <InvestmentScenarios
        scenario={scenario}
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        match={match}
        handleChange={this.handleChange}
        changeSelection={this.changeSelection}
      />,

      <Result
        dispatch={dispatch}
        drawdown={drawdown}
        inputs={drawdown.inputs}
        errors={drawdown.errors}
        isFetching={drawdown.isFetching}
        portfolios={portfolios}
        displayScenario={drawdown.displayScenario}
      />,
      <ErrorResult />,
    ];

    if (
      isFetching ||
      coupleDrawdown.isFetching ||
      (activePage === 6 &&
        coupleDrawdown.result === null &&
        coupleDrawdown.result !== 'error')
    ) {
      return (
        <Segment margin="1rem" textAlign="center" className="segment" expand>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '6rem',
              opacity: '0.6',
            }}
          >
            <LogoSpinner active />
          </div>
        </Segment>
      );
    }
    const { inputs } = drawdown;
    const showNextButton =
      activePage !== 5 &&
      (activePage !== 1 ||
        (inputs.age !== undefined &&
          Number(inputs.age) !== 0 &&
          inputs.horizon !== undefined &&
          Number(inputs.horizon) !== 0 &&
          inputs.prov !== undefined)) &&
      (activePage !== 0 ||
        (inputs.spouse !== undefined &&
          inputs.spouse !== '' &&
          inputs.spouse2 !== undefined &&
          inputs.spouse2 !== '' &&
          inputs.prov !== undefined &&
          inputs.prov !== ''));

    return (
      <Container margin="1rem" textAlign="center" activePage={activePage}>
        <OasModal
          isOpen={isOasModalOpen}
          toggle={this.toggleOasModal}
          updateOasInfo={this.updateOasInfo}
          drawdown={drawdown}
        />
        <CppModal
          isOpen={isCppModalOpen}
          toggle={this.toggleCppModal}
          updateCppInfo={this.updateCppInfo}
          cppDataset={cppDataset}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom:
              (activePage === 1 || activePage === 3 || activePage === 5) &&
              '50px',
          }}
        >
          {activePage !== 6 && (
            <Header
              textAlign="center"
              size="large"
              style={{ marginBottom: activePage !== 0 && '2rem' }}
            >
              Optimal Drawdown for Couples
              {activePage === 0 && (
                <Header.Subheader>
                  See the tax savings by filing returns as a couple.
                </Header.Subheader>
              )}
            </Header>
          )}{' '}
          {activePage !== 6 && activePage !== 0 && (
            <div
              style={{
                color: '#4183C3',
                fontWeight: 'bold',
                transform: 'translateY(-20px)',
                cursor: 'pointer',
              }}
              onClick={() => dispatch(Planning.navigateDrawdown(0))}
            >
              Model Features and Assumptions
            </div>
          )}
          {activePage > 0 && activePage !== 6 && inputs.spouse && (
            <div
              style={{
                margin: '1rem auto 2rem auto',
                fontSize: '1.2rem',
                display: 'flex',
                width: '95%',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: '64rem',
              }}
            >
              {activePage !== 5 && (
                <React.Fragment>
                  <div style={{ fontSize: '1.2rem', alignItems: 'center' }}>
                    Please fill out the information for{' '}
                    <span
                      style={{
                        color: 'rgb(97, 124, 246)',
                        fontWeight: 'bold',
                      }}
                    >
                      {inputs[inputs.activeSpouse]}
                    </span>
                  </div>

                  <OptionsWrapper>
                    <div
                      className={cn('select', {
                        active: inputs.activeSpouse === 'spouse',
                      })}
                      onClick={() => this.handleSpouseChange('spouse')}
                      style={{
                        fontSize: '1rem',
                        padding: '6px 10px',
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                      }}
                    >
                      {inputs.spouse}
                    </div>
                    <div
                      className={cn('select', {
                        active: inputs.activeSpouse === 'spouse2',
                      })}
                      onClick={() => this.handleSpouseChange('spouse2')}
                      style={{
                        fontSize: '1rem',
                        padding: '6px 10px',
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    >
                      {inputs.spouse2}
                    </div>
                  </OptionsWrapper>
                </React.Fragment>
              )}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {activePage !== 6 && activePage !== 0 && (
              <MenuWrapper>
                {menuItemIcons.map((icon, index) => {
                  // Skip Result component when there's no result, when fetching data, or if it's risk-free with no scenario
                  if (
                    (index === 6 && (drawdown.isFetching || result === null)) ||
                    index === 0
                  )
                    return null;

                  return (
                    <MenuItem
                      key={index}
                      active={activePage === index}
                      onClick={() => this.handleMenuItemClick(index)}
                      hasErrors={this.tabHasErrors(menuItemNames[index])}
                    >
                      <div className="icon-wrapper">
                        <Icon name={icon} />
                      </div>
                      <MenuText>{menuItemNames[index]}</MenuText>
                      {this.tabHasErrors(menuItemNames[index]) && (
                        <ErrorIcon
                          style={{ fontSize: '1.3em' }}
                          name="exclamation"
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </MenuWrapper>
            )}
            <div
              style={{
                width: activePage !== 6 ? '700px' : '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {activePage !== 0 && <Introduction activePage={activePage} />}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  padding: '0 0 0 20px',
                }}
              >
                {components
                  .map((_, index) => ({ index, component: components[index] }))
                  .filter(
                    ({ index }) =>
                      (index !== 1 && index !== 7) ||
                      (index === 1 && activePage === 1) ||
                      drawdown.displayScenario
                  ) // Filter out the Result component if there is no result and only display General Information when activePage is 1
                  .map(({ index, component }) => {
                    if (index === activePage) {
                      return <div key={index}>{component}</div>;
                    }
                    return null;
                  })}
                {activePage !== 6 && (
                  <NavigationButtons
                    style={{ alignSelf: 'end', margin: 0 }}
                    long={
                      activePage === 1 || activePage === 3 || activePage === 5
                    }
                    handlePreviousPage={this.handlePreviousPage}
                    handleNextPage={this.handleNextPage}
                    showPreviousButton={activePage !== 0}
                    showNextButton={showNextButton}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

Drawdown.propTypes = {
  dispatch: PropTypes.func,
  portfolios: PropTypes.array,
  drawdown: PropTypes.object,
  match: PropTypes.object,
  cppDataset: PropTypes.array,
  isCppModalOpen: PropTypes.bool,
  isOasModalOpen: PropTypes.bool,
  coupleDrawdown: PropTypes.object.isRequired,
};

Drawdown.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  drawdown: {},
  match: {},
  cppDataset: [],
  isCppModalOpen: false,
  isOasModalOpen: false,
};

const MenuWrapper = styled.div`
  width: 200px;
  color: black;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: ${(props) => (props.active ? '#b7c1fe' : '#f0f0f0')};
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: ${(props) => (props.active ? '#b7c1fe' : '#ddd')};
  }

  .icon-wrapper {
    margin-right: 10px;
  }
`;

const ErrorIcon = styled(Icon)`
  left: 45px;
  color: ${colors.red};
`;

const MenuText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const menuItemIcons = [
  'info circle',
  'user',
  'money bill alternate outline',
  'chart line',
  'dollar',
  'sliders horizontal',
];

const menuItemNames = [
  'Introduction',
  'Personal Information',
  'Retirement Income',
  'Investment Portfolio',
  'Account Values',
  'Investment Scenario',
];

const OptionsWrapper = styled.div`
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }

  .custom {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 5px 10px;
    line-height: 1.5;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      border-color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      background-color: #b7c1fe;
      border-color: #b7c1fe;
    }
  }
`;
