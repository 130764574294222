import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon, List, Header } from 'semantic-ui-react';
import { formatDollarAmount } from 'utils/helpers';
import { Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { Table, Segment, Message } from 'components';

class Result extends Component {
  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount = () => {
    const { dispatch, portfolio } = this.props;

    // resize charts on print
    this.state.mql.addListener(this.printListener);

    if (!portfolio.user.length) {
      dispatch(Portfolio.fetchUser());
    }

    if (!portfolio.model.length) {
      dispatch(Portfolio.fetchModel());
    }

    return false;
  };

  componentDidMount = () => {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  };

  componentWillUnmount = () => {
    window.clearTimeout(this.timeoutId);
    this.state.mql.removeListener(this.printListener);
  };

  getPortfolio = (portfolioId, modelType) => {
    const { portfolio, data } = this.props;
    const portfolioLabels = {
      'WS All-In': '100% Equities / 0% Bonds',
      'WS Adventurous': '80% Equities / 20% Bonds',
      'WS Classic': '60% Equities / 40% Bonds',
      'WS Cautious': '40% Equities / 60% Bonds',
      'WS Playing-It-Safe': '20% Equities / 80% Bonds',
    };

    if (!portfolio.model) {
      return null;
    }

    if (modelType === 'annuityRate') {
      return (
        <span style={{ fontSize: '1.2rem' }}>
          Annuity: {data.targetWealth.annuityRate}%
        </span>
      );
    }

    let filteredPortfolio = portfolio.model.filter(
      (port) => Number(port.id) === Number(portfolioId)
    );
    if (filteredPortfolio.length) {
      return (
        <span>
          <span style={{ fontSize: '1.2rem' }}>
            {filteredPortfolio[0].name}:{' '}
          </span>
          <span style={{ fontSize: '1rem' }}>
            {portfolioLabels[filteredPortfolio[0].name]}
          </span>
        </span>
      );
    }

    filteredPortfolio = portfolio.user.filter(
      (port) => Number(port.id) === Number(portfolioId)
    );
    if (filteredPortfolio.length) {
      return (
        <span style={{ fontSize: '1.2rem' }}>
          {Object.keys(filteredPortfolio[0]).length &&
            filteredPortfolio[0].name}
        </span>
      );
    }

    return <span style={{ fontSize: '1.2rem', color: 'red' }}>Unmatched</span>;
  };

  getDate = () => {
    const { data } = this.props;
    let year = null;
    let month = null;
    let day = null;

    if (data.updated_on) {
      year = data.updated_on.split('-')[0];
      month = data.updated_on.split('-')[1];
      day = data.updated_on.split('-')[2].split('');

      if (day[0] === '0') {
        day.splice(0, 1);
      }

      day = day.join('');
    } else {
      const d = new Date();
      year = d.getFullYear();
      month =
        d.getMonth() + 1 < 10
          ? `0${(d.getMonth() + 1).toString()}`
          : d.getMonth() + 1;
      day = d.getDate();
    }

    const monthName = {
      '01': 'Jan.',
      '02': 'Feb.',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'Aug.',
      '09': 'Sept.',
      10: 'Oct.',
      11: 'Nov.',
      12: 'Dec.',
    };

    return `${monthName[month]} ${day}, ${year}`;
  };

  portfolioIsDeleted = (portfolioId, modelType) => {
    const { portfolio } = this.props;

    return modelType === 'annuityRate'
      ? false
      : !portfolio.model
          .concat(portfolio.user)
          .find((port) => port.id === portfolioId);
  };

  timeoutId = null;

  printListener = (e) => {
    const { history, match } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else {
      history.push(`/planning/blueprint/${match.params.id}/summary`);
    }
  };

  formatNum = (num) =>
    !num ? '$0' : `$${(Math.round(num * 100) / 100).toLocaleString()}`;

  isDisabled = () => {
    const { targetWealth, targetSaving, wealthResult, savingResult } =
      this.props.data;

    if (!targetWealth || !targetSaving || !wealthResult || !savingResult) {
      return true;
    }

    if (
      !targetWealth.targ ||
      (!targetSaving.monthlySaving && targetSaving.monthlySaving !== 0)
    ) {
      return true;
    }

    return false;
  };

  calcShortfall = () => {
    const { total, emp, emp2, cpp, cpp2, oas, oas2, other, other2 } =
      this.props.data.general;

    return (
      total -
      cpp -
      (cpp2 || 0) -
      oas -
      oas2 -
      (emp || 0) -
      (emp2 || 0) -
      (other || 0) -
      (other2 || 0)
    );
  };

  renderContent = (name) => (
    <p>
      You need to choose a <strong>{name}</strong> amount in order to save this
      blueprint. <br />
      Please select an amount by clicking the ribbon on the right.
    </p>
  );

  renderSuccess = (mode, target) => {
    const { data } = this.props;
    const targ = +Object.keys(data[`${mode}Result`]).find(
      (key) => data[`${mode}Result`][key] === target
    );

    if (!target) {
      return `${Math.trunc(data[`${mode}Result`].prob_without_savings * 100)}%`;
    }

    return `${targ * 100}%`;
  };

  renderSingle = () => {
    const { personalDetails } = this.props.data;
    const {
      age,
      gender,
      province,
      retAge,
      spending_amount,
      cppAge,
      cpp,
      oasAge,
      oas,
      pension,
      other,
      net_spending,
    } = personalDetails || {};

    return (
      <List divided relaxed size="huge">
        <ListItem noBorder>
          <List.Content>
            <List.Header>Sex</List.Header>
            <div className="value">{gender}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Current age</List.Header>
            <div className="value">{age}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Retirement age</List.Header>
            <div className="value">{retAge}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Province of residence</List.Header>
            <div className="value">{province}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>Annual spending needs</List.Header>
            <div className="value">{this.formatNum(spending_amount)}</div>
          </List.Content>
        </ListItem>
        <ListItem style={{ padding: '.5rem 0 0 1.5rem' }}>
          <List.Content>
            <List.Header>Annual retirement income:</List.Header>
          </List.Content>
        </ListItem>
        <ListItem small noBorder style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">
              {province === 'Quebec' ? 'QPP' : 'CPP'}, starting at age {cppAge}
            </List.Header>
            <div className="value small">{this.formatNum(cpp)}</div>
          </List.Content>
        </ListItem>
        <ListItem small style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">
              OAS, starting at age {oasAge}
            </List.Header>
            <div className="value small">{this.formatNum(oas)}</div>
          </List.Content>
        </ListItem>
        <ListItem small style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">Employment pension</List.Header>
            <div className="value small">{this.formatNum(pension)}</div>
          </List.Content>
        </ListItem>
        <ListItem small style={{ marginLeft: '3rem' }}>
          <List.Content>
            <List.Header className="small">Other income</List.Header>
            <div className="value small">{this.formatNum(other)}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>
              Annual spending needs net of retirement income
            </List.Header>
            <div className="value">{this.formatNum(net_spending)}</div>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Content>
            <List.Header>
              % of spending needs covered by retirement income
            </List.Header>
            <div className="value">{`${(
              ((spending_amount - net_spending) / spending_amount) *
              100
            ).toFixed(1)}%`}</div>
          </List.Content>
        </ListItem>
      </List>
    );
  };

  renderCouple = () => {
    const { personalDetails } = this.props.data;
    const {
      age,
      age2,
      gender,
      gender2,
      province,
      retAge,
      retAge2,
      spending_amount,
      cpp,
      cpp2,
      oas,
      oas2,
      pension,
      pension2,
      other,
      other2,
      net_spending,
    } = personalDetails || {};

    return (
      <Table basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={12} />
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            >
              You
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            >
              Spouse
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Sex</Cell>
            <Cell>{gender}</Cell>
            <Cell>{gender2}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Current age</Cell>
            <Cell>{age}</Cell>
            <Cell>{age2}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Retirement age</Cell>
            <Cell>{retAge}</Cell>
            <Cell>{retAge2}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Province of residence</Cell>
            <Cell colSpan="2">{province}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Annual spending needs</Cell>
            <Cell colSpan="2">{this.formatNum(spending_amount)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">Annual retirement income</Cell>
            <Cell />
            <Cell />
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" border padded small>
              {`${province === 'Quebec' ? 'Quebec' : 'Canadian'} Pension Plan`}
            </Cell>
            <Cell border small>
              {this.formatNum(cpp)}
            </Cell>
            <Cell border small>
              {this.formatNum(cpp2)}
            </Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" padded small>
              Old Age Security
            </Cell>
            <Cell small>{this.formatNum(oas)}</Cell>
            <Cell small>{this.formatNum(oas2)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" padded small>
              Employment Pension Plan
            </Cell>
            <Cell small>{this.formatNum(pension)}</Cell>
            <Cell small>{this.formatNum(pension2)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left" padded small>
              Other income
            </Cell>
            <Cell small>{this.formatNum(other)}</Cell>
            <Cell small>{this.formatNum(other2)}</Cell>
          </Table.Row>

          <Table.Row textAlign="center">
            <Cell textAlign="left">
              Combined annual spending needs net of retirement income
            </Cell>
            <Cell colSpan="2">
              {net_spending < 0
                ? `-${this.formatNum(Math.abs(net_spending))}`
                : this.formatNum(net_spending)}
            </Cell>
          </Table.Row>

          {net_spending > 0 && (
            <Table.Row textAlign="center">
              <Cell textAlign="left">
                % of spending needs covered by retirement income
              </Cell>
              <Cell colSpan="2">
                {(
                  (personalDetails.spending_amount - net_spending) /
                  personalDetails.spending_amount /
                  0.01
                ).toFixed(1)}
                %
              </Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  renderCoupleTax = () => {
    const { data } = this.props;
    const { targetWealth, targetSaving, savingResult } = data;
    const { modelType } = targetWealth || {};
    const { monthlySavingChosen, savings_portfolio } = targetSaving || {};
    return (
      <Table basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={10} />
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            />
            <Table.HeaderCell
              width={2}
              style={{
                paddingLeft: 0,
                color: colors.blue,
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Current savings</Cell>
            <Cell colSpan="2">{formatDollarAmount(targetSaving.init)}</Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Pre-retirement portfolio</Cell>
            <Cell colSpan="2">{this.getPortfolio(savings_portfolio)}</Cell>
          </Table.Row>
          {this.portfolioIsDeleted(savings_portfolio, modelType) && (
            <div
              style={{
                color: 'red',
                width: '125%',
                marginBottom: '1rem',
              }}
            >
              Your pre-retirement portfolio has been removed. Please select a
              new portfolio in target saving.
            </div>
          )}
          <Table.Row textAlign="center">
            <Cell textAlign="left">Additional savings required per month</Cell>
            <Cell colSpan="2">
              {formatDollarAmount(savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell small padded textAlign="left">
              Likelihood of success
            </Cell>
            <Cell small colSpan="2">
              {this.renderSuccess('saving', savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Tax implications per year: Year 1</Cell>
            {savingResult.tax_implications[monthlySavingChosen]
              .expected_taxes !== null ? (
              <React.Fragment>
                <Cell>
                  {formatDollarAmount(
                    savingResult.tax_implications[monthlySavingChosen]
                      .expected_taxes
                  )}
                </Cell>
                <Cell>
                  {formatDollarAmount(
                    savingResult.tax_implications[monthlySavingChosen]
                      .expected_taxes2
                  )}
                </Cell>
              </React.Fragment>
            ) : (
              <Cell colSpan="2">
                <b style={{ color: `${colors.red}` }}>
                  This is a new feature. Complete two additional questions in
                  Target Saving.
                </b>
              </Cell>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };
  renderSingleTax = () => {
    const { data } = this.props;
    const { targetWealth, targetSaving, savingResult } = data;
    const { modelType } = targetWealth || {};
    const { monthlySavingChosen, savings_portfolio } = targetSaving || {};
    return (
      <Table basic>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={10} />
            <Table.HeaderCell width={6} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Current savings</Cell>
            <Cell textAlign="right">{this.formatNum(targetSaving.init)}</Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Pre-retirement portfolio</Cell>
            <Cell textAlign="right">
              {this.getPortfolio(savings_portfolio)}
            </Cell>
          </Table.Row>
          {this.portfolioIsDeleted(savings_portfolio, modelType) && (
            <div
              style={{
                color: 'red',
                width: '125%',
                marginBottom: '1rem',
              }}
            >
              Your pre-retirement portfolio has been removed. Please select a
              new portfolio in target saving.
            </div>
          )}
          <Table.Row textAlign="center">
            <Cell textAlign="left">Additional savings required per month</Cell>
            <Cell textAlign="right">
              {this.formatNum(savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell padded small textAlign="left">
              Likelihood of success
            </Cell>
            <Cell small textAlign="right">
              {this.renderSuccess('saving', savingResult[monthlySavingChosen])}
            </Cell>
          </Table.Row>
          <Table.Row textAlign="center">
            <Cell textAlign="left">Tax implications per year: Year 1</Cell>
            {savingResult.tax_implications &&
            savingResult.tax_implications[monthlySavingChosen]
              .expected_taxes !== null ? (
              <Cell textAlign="right">
                {formatDollarAmount(
                  savingResult.tax_implications[monthlySavingChosen]
                    .expected_taxes
                )}
              </Cell>
            ) : (
              <b style={{ color: `${colors.red}` }}>
                This is a new feature. Complete two additional questions in
                Target Saving.
              </b>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { data } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const isCouplePlan = is_couple_plan === 'true';
    const { targetWealth, targetSaving, wealthResult, savingResult } =
      data || {};
    const { targ, wealth_portfolio, modelType } = targetWealth || {};
    const { monthlySavingChosen, savings_portfolio } = targetSaving || {};
    const { personalDetails } = this.props.data;
    const { retAge } = personalDetails || {};

    return (
      <PDF>
        <Segment style={{ margin: '0', padding: '0' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Header
              size="large"
              content="Retirement Blueprint"
              style={{ textAlign: 'left', margin: '0' }}
            />
            <div
              style={{
                textAlign: 'right',
                margin: '0',
                fontSize: '1.1rem',
                color: 'rgba(0,0,0,0.6)',
              }}
            >
              <div>{data.name}</div>
              <div>{data.description}</div>
              <div>{this.getDate()}</div>
            </div>
          </div>

          <div
            style={{
              margin: '2rem auto',
              padding: '0 2rem 2rem',
              width: '100%',
              border: `1px solid ${colors.blue}`,
              borderRadius: '8px',
            }}
          >
            <Header
              size="medium"
              content="Blueprint Summary"
              style={{
                position: 'relative',
                top: '-11px',
                left: '-.5rem',
                width: 'fit-content',
                background: 'white',
                padding: '0 .5rem',
              }}
            />

            <Header size="small" content="Personal Details" />

            {is_couple_plan === 'true'
              ? this.renderCouple()
              : this.renderSingle()}

            <Header
              size="small"
              content="Target Wealth at Retirement"
              style={{ margin: '20px, 0, 0, 0' }}
            />

            {targetWealth && targ && wealthResult ? (
              <Table basic>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell width={10} />
                    <Table.HeaderCell width={4} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row textAlign="center">
                    <Cell textAlign="left">Post retirement portfolio</Cell>
                    <Cell
                      textAlign={!isCouplePlan && 'right'}
                      colSpan={isCouplePlan && 2}
                    >
                      {this.getPortfolio(
                        targetWealth.wealth_portfolio || 'annuityRate',
                        modelType
                      )}
                    </Cell>
                  </Table.Row>

                  <Table.Row textAlign="center">
                    <Cell textAlign="left">
                      Target Wealth required to cover spending
                    </Cell>
                    <Cell
                      textAlign={!isCouplePlan && 'right'}
                      colSpan={isCouplePlan && 2}
                    >
                      {this.formatNum(targ)}
                    </Cell>
                  </Table.Row>
                  {wealth_portfolio !== null && (
                    <Table.Row textAlign="center">
                      <Cell padded small textAlign="left">
                        Likelihood of success
                      </Cell>
                      <Cell small textAlign="right">
                        {this.renderSuccess('wealth', targ)}
                      </Cell>
                    </Table.Row>
                  )}
                  {!isCouplePlan &&
                  savingResult.tax_implications[monthlySavingChosen]
                    .expected_taxes !== null ? (
                    <Table.Row textAlign="center">
                      <Cell textAlign="left">
                        Tax implications per year: Year 1
                      </Cell>
                      <Cell
                        textAlign={!isCouplePlan && 'right'}
                        colSpan={isCouplePlan && 2}
                      >
                        {formatDollarAmount(
                          savingResult.tax_implications[monthlySavingChosen]
                            .expected_taxes
                        )}
                      </Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
              </Table>
            ) : (
              <Message icon error visible>
                <Message.Content>
                  {this.renderContent('target wealth')}
                </Message.Content>
              </Message>
            )}
            <Header
              size="small"
              content="Target Monthly Savings Starting Today"
              style={{ margin: '20px, 0, 0, 0' }}
            />

            {targetSaving &&
            (monthlySavingChosen || monthlySavingChosen === 0) &&
            savingResult ? (
              isCouplePlan ? (
                this.renderCoupleTax()
              ) : (
                this.renderSingleTax()
              )
            ) : (
              <Message icon error visible>
                <Icon name="warning sign" />
                <Message.Content>
                  {this.renderContent('monthly savings')}
                </Message.Content>
              </Message>
            )}
            {this.portfolioIsDeleted(savings_portfolio, modelType) && (
              <div style={{ color: 'red', transform: 'translateY(-1.5rem)' }}>
                Your pre-retirement portfolio has been removed. Please select a
                new portfolio in target saving.
              </div>
            )}
          </div>
        </Segment>
        <div style={{ pageBreakAfter: 'always' }} />
        <CollapseWrapper style={{ marginBottom: '3rem' }}>
          <Header
            size="medium"
            content="Insights from your Retirement Blueprint"
            style={{
              position: 'relative',
              top: '-11px',
              left: '-.5rem',
              width: 'fit-content',
              background: 'white',
              padding: '0 .5rem',
            }}
          />

          <Assumptions>
            {savingResult[monthlySavingChosen] > 1 ? (
              <Item
                className="savings-dialogue"
                style={{
                  background: '#cbe8ee',
                  position: 'relative',
                  top: '-11px',
                }}
              >
                <Header size="small" content="Are You On Track?" />
                <ul>
                  <li>
                    By saving{' '}
                    <strong>
                      {this.formatNum(savingResult[monthlySavingChosen])}
                    </strong>{' '}
                    a month starting today, you are expected to reach your
                    retirement goal of <strong>{this.formatNum(targ)}</strong>{' '}
                    (in future dollars) by age <strong>{retAge}</strong> with a{' '}
                    <strong>
                      {this.renderSuccess(
                        'saving',
                        savingResult[monthlySavingChosen]
                      )}
                    </strong>{' '}
                    success rate. You are on track if you are contributing at
                    least this amount per month to the portfolio you selected in
                    the plan.
                  </li>
                </ul>
              </Item>
            ) : (
              <Item
                className="savings-dialogue"
                style={{
                  background: `${colors.lightBlue}`,
                  position: 'relative',
                  top: '-11px',
                }}
              >
                <Header size="small" content="Congratulations!" />
                <ul>
                  <li>
                    Given your inputs and the options you chose in the current
                    plan, it seems that you are on track! With no additional
                    savings, you are expected to reach your retirement goal of{' '}
                    <strong>{this.formatNum(targ)}</strong> (in future dollars)
                    by age <strong>{retAge}</strong> with a{' '}
                    <strong>
                      {' '}
                      {this.renderSuccess(
                        'saving',
                        savingResult[monthlySavingChosen]
                      )}
                    </strong>{' '}
                    success rate.
                  </li>
                </ul>
              </Item>
            )}

            <Item
              className="conclusion"
              style={{
                position: 'relative',
                top: '-11px',
                background: '#cce2ff',
              }}
            >
              <Header size="small" content="What's Next?" />
              <ul>
                <li>
                  The additional saving of{' '}
                  <strong>
                    {this.formatNum(savingResult[monthlySavingChosen])}
                  </strong>{' '}
                  is the amount you should add to your retirement investment
                  portfolio every month.
                </li>
                <li>
                  {' '}
                  Use up your contribution room in your tax-sheltered,
                  registered accounts first. Else, if you are contributing all
                  or part of the amount to a taxable account, make sure you set
                  aside money in your savings account each year for tax
                  purposes.
                </li>
              </ul>
            </Item>
          </Assumptions>
        </CollapseWrapper>
        <CollapseWrapper>
          <Header
            size="medium"
            content="Model Features & Assumptions"
            style={{
              position: 'absolute',
              top: '-11px',
              left: '.5rem',
              background: 'white',
              padding: '0 .5rem',
            }}
          />
          <Assumptions>
            <Item>
              <Header size="small" content="Target Audience" className="item" />
              <ul>
                <li>
                  The Blueprint is best suited for individuals that have RRSP
                  and TFSA contribution room. In 2024, the maximum combined
                  contribution room is $31,560 + $7,000 = $38,560 for the
                  calendar year. This is equivalent to saving $3,213 per month.
                  If the contribution room is exhausted and part of the savings
                  are held in a non-registered investment account, the Blueprint
                  will calculate the expected taxes owing for the coming year.
                </li>
              </ul>
            </Item>
            <Item>
              <Header size="small" content="Key Features" className="item" />
              <ul>
                <li>
                  <b>Investment returns: </b>Actual portfolios are used to
                  estimate returns; users are not asked to provide an expected
                  rate of return.
                </li>
                <li>
                  <b>Investment risk: </b>Since portfolios have uncertain
                  outcomes, three figures are presented for Target Wealth and
                  for Target Saving. They correspond to different likelihoods of
                  success (50%, 75% and 90%), based on 10,000 simulations using
                  historical monthly returns.
                </li>
                <li>
                  <b>Spending needs in retirement: </b>Users can choose
                  different methods to calculate this amount, including one
                  using Statistics Canada’s Survey of Household Spending.
                </li>
                <li>
                  <b>Longevity risk: </b>Conditional mortality rates from
                  Statistics Canada based on current age and sex are used. The
                  probability of survival does not reach zero until age 110.
                </li>
                <li>
                  <b>CPP/QPP and OAS: </b>Calculators are provided to estimate
                  future benefits.
                </li>
              </ul>
            </Item>
            <Item>
              <Header size="small" content="Target Wealth" className="item" />
              <ul>
                <li>
                  Defined as the present value of future spending needs in
                  retirement, taking into account longevity and investment
                  risks.
                </li>
                <li>
                  Invest post-retirement savings in a Wealthscope model
                  portfolio, a fixed-rate annuity, or any portfolio saved using
                  our Portfolio Builders.
                </li>
                <li>
                  Spending needs in retirement stay constant in real terms
                  (i.e., increasing only by the rate of inflation). The default
                  rate of inflation is 2%.
                </li>
              </ul>
            </Item>
            <Item>
              <Header size="small" content="Target Saving" className="item" />
              <ul>
                <li>
                  Defined as the amount of savings required per month (to be
                  added to a user’s pre-retirement portfolio) to reach the
                  target wealth at retirement.
                </li>
                <li>
                  Invest savings in a Wealthscope model portfolio or any
                  portfolio saved using our Portfolio Builders. This is the
                  portfolio a user will be investing in from now until
                  retirement.
                </li>
              </ul>
            </Item>
            <Item>
              <Header size="small" content="Important Note" className="item" />
              <ul>
                <li>
                  Users may wish to revisit the Blueprint at a later date as
                  their personal details change, and/or as their portfolio’s
                  return history lengthens.
                </li>
              </ul>
            </Item>
          </Assumptions>
        </CollapseWrapper>
      </PDF>
    );
  }
}

const ListItem = styled(({ className, children, noBorder, small, ...rest }) => (
  <List.Item className={className} {...rest}>
    {children}
  </List.Item>
))`
  position: relative;
  margin: ${(props) => (props.small ? '0 0 0 2.5rem' : '0')};
  padding: ${(props) => (props.small ? '4px 0' : '0')};
  border: ${(props) => (props.noBorder ? '0 !important' : 'initial')};

  .header {
    font-size: 1.2rem !important;
    font-weight: 400 !important;

    &.small {
      font-size: 1rem !important;
    }
  }

  .value {
    position: absolute;
    top: 8px;
    right: 0;
    font-size: 1.4rem;

    &.small {
      font-size: 1.1rem;
    }
  }
`;

const PDF = styled.div`
  position: relative;
  background: white;
  padding: 1rem 4rem 1rem;
  border-radius: 8px;
  width: 1160px;
  margin: -55px auto 5px auto;

  @media only print {
    print-color-adjust: exact;

    @page {
      size: auto;
      margin: 0.5cm;
    }
  }
`;

const Cell = styled(Table.Cell)`
  font-size: ${(props) => (props.small ? '1rem' : '1.2rem')};
  padding-left: ${(props) => (props.padded ? '2.5rem !important' : '0')};
  border: ${(props) => (props.border ? '0 !important' : 'inherit')};
  text-align: ${(props) => props.textAlign || 'center'};
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0rem 1rem 0;
  margin: 3rem auto 0;
  border: 1px solid ${colors.blue};
  border-radius: 8px;
  overflow: visible;
`;

const Assumptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  text-align: left;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);

  .header {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Item = styled.div`
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0.5% 0;
  padding: 5px;
  border-radius: 4px;
  .item {
    background: #f7f7f7;
  }
  .savings-dialogue {
    background: #cbe8ee !important;
  }
`;

Result.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withRouter(
  connect((state) => ({
    portfolio: state.Portfolio,
  }))(Result)
);
