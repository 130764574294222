import { request } from './api';
import * as Alert from './alert';

/**
 * Action constants
 */
export const COMPAREPORTFOLIO_REQUEST = 'COMPAREPORTFOLIO_REQUEST';
export const COMPAREPORTFOLIO_GIC_REQUEST = 'COMPAREPORTFOLIO_GIC_REQUST';

export const COMPAREPORTFOLIO_SEARCH_SUCCESS =
  'COMPAREPORTFOLIO_SEARCH_SUCCESS';
export const COMPAREPORTFOLIO_ADD_SUCCESS = 'COMPAREPORTFOLIO_ADD_SUCCESS';
export const COMPAREPORTFOLIO_UPDATE_WEIGHT = 'COMPAREPORTFOLIO_UPDATE_WEIGHT';
export const COMPAREPORTFOLIO_UPDATE_GIC = 'COMPAREPORTFOLIO_UPDATE_GIC';
export const COMPAREPORTFOLIO_UPDATE_TOGGLE = 'COMPAREPORTFOLIO_UPDATE_TOGGLE';
export const COMPAREPORTFOLIO_UPDATE_ADVISOR_FEE =
  'COMPAREPORTFOLIO_UPDATE_ADVISOR_FEE';
export const COMPAREPORTFOLIO_REMOVE_SECURITY =
  'COMPAREPORTFOLIO_REMOVE_SECURITY';
export const COMPAREPORTFOLIO_REMOVE_ALL_HOLDINGS =
  'COMPAREPORTFOLIO_REMOVE_ALL_HOLDINGS';
export const COMPAREPORTFOLIO_REMOVE_ALL_GICS =
  'COMPAREPORTFOLIO_REMOVE_ALL_GICS';
export const COMPAREPORTFOLIO_LOAD_PORTFOLIO =
  'COMPAREPORTFOLIO_LOAD_PORTFOLIO';

export const COMPAREPORTFOLIO_COMPARE_SUCCESS =
  'COMPAREPORTFOLIO_COMPARE_SUCCESS';
export const COMPAREPORTFOLIO_CLEAR = 'COMPAREPORTFOLIO_CLEAR';
export const COMPAREPORTFOLIO_UPDATE_INTEREST =
  'COMPAREPORTFOLIO_UPDATE_INTEREST';
export const COMPAREPORTFOLIO_SEARCH_REQUEST =
  'COMPAREPORTFOLIO_SEARCH_REQUEST';
export const COMPAREPORTFOLIO_ROUND_WEIGHTS_TO_WHOLE_NUMBERS =
  'COMPAREPORTFOLIO_ROUND_WEIGHTS_TO_WHOLE_NUMBERS';
export const COMPAREPORTFOLIO_SETBARCOMPARE = 'COMPAREPORTFOLIO_SETBARCOMPARE';
export const COMPAREPORTFOLIOUPDATECHECK = 'COMPAREPORTFOLIOUPDATECHECK';
export const COMPAREPORTFOLIO_UNDO_BARCOMPARE =
  'COMPAREPORTFOLIO_UNDO_BARCOMPARE';
export const COMPAREPORTFOLIO_RESET_CHECKWEIGHT =
  'COMPAREPORTFOLIO_RESET_CHECKWEIGHT';

export const COMPAREPORTFOLIO_TAKE_INITIAL_ANALYTICS =
  'COMPAREPORTFOLIO_TAKE_INITIAL_ANALYTICS';
export const COMPAREPORTFOLIO_ANALYTICS_INITIAL_SNAPSHOT =
  'COMPAREPORTFOLIO_ANALYTICS_INITIAL_SNAPSHOT';
export const COMPAREPORTFOLIO_PORTFOLIO_INITIAL_SNAPSHOT =
  'COMPAREPORTFOLIO_PORTFOLIO_INITIAL_SNAPSHOT';
export const COMPAREPORTFOLIO_UNDO_INITIAL_PORTFOLIO =
  'COMPAREPORTFOLIO_UNDO_INITIAL_PORTFOLIO';
export const COMPAREPORTFOLIO_TAKE_SNAPSHOT_AFTER_REPLACE =
  'COMPAREPORTFOLIO_TAKE_SNAPSHOT_AFTER_REPLACE';
export const COMPAREPORTFOLIO_SET_SNAPSHOT_LOAD =
  'COMPAREPORTFOLIO_SET_SNAPSHOT_LOAD';
export const COMPAREPORTFOLIO_LOAD_SNAPSHOT_AFTER_REPLACE =
  'COMPAREPORTFOLIO_LOAD_SNAPSHOT_AFTER_REPLACE';
export const COMPAREPORTFOLIO_RESCALE = 'COMPAREPORTFOLIO_RESCALE';
/**
 * Action creators
 */

// converts and stores the 'portfolio' object (in addition to some modifcations found in paramsAnalyzed)
//  found in the Analytics reducer to the format used
// found in ComparePortfolio. This, in addition to avoiding having to send another back-end request  to obtain the
// portfolio, we are able to see all the modifcations to the portfolio (ex. toggling check_history may add/remove
// symbols and/or reducing/increasing some weights)
export function setSnapshotLoad(value) {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_SET_SNAPSHOT_LOAD });
    dispatch(updateToggle('snapshotLoad', value));
  };
}
export function loadSnapshotAfterReplace() {
  return { type: 'COMPAREPORTFOLIO_LOAD_SNAPSHOT_AFTER_REPLACE' };
}
export function takeSnapshotAfterReplace(portfolio) {
  return { type: 'COMPAREPORTFOLIO_TAKE_SNAPSHOT_AFTER_REPLACE', portfolio };
}
export function undoToInitialPortfolio() {
  return { type: COMPAREPORTFOLIO_UNDO_INITIAL_PORTFOLIO };
}
export function takeInitialPortfolio(portfolio) {
  return { type: COMPAREPORTFOLIO_PORTFOLIO_INITIAL_SNAPSHOT, portfolio };
}
export function takeInitialAnalytics(analytics) {
  return {
    type: COMPAREPORTFOLIO_TAKE_INITIAL_ANALYTICS,
    analytics,
  };
}
export function analyticsInitialSnapshot(value) {
  return { type: COMPAREPORTFOLIO_ANALYTICS_INITIAL_SNAPSHOT, value };
}

export function undoBarCompare() {
  return { type: COMPAREPORTFOLIO_UNDO_BARCOMPARE };
}
export function resetCheckWeight() {
  return { type: COMPAREPORTFOLIO_RESET_CHECKWEIGHT };
}
export function setBarCompare(value) {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_SETBARCOMPARE });
    dispatch(updateToggle('barCompare', value));
  };
}
export function updateToggleCheck(ticker, weight, analytics) {
  return {
    type: COMPAREPORTFOLIOUPDATECHECK,
    ticker,
    weight,
    analytics,
  };
}
export function convertAnalyticsPortfolioObject(
  portfolio,
  paramsAnalyzed,
  overwriteOriginal = true
) {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_REQUEST });

    const { symbols, weights } = paramsAnalyzed;

    // gics in the symbols array use their corresponding gic_ticker instead of the GIC#:MKT ticker format,
    // so here we rename all of these gic tickers back to the GIC#:MKT ticker format
    const symbolIndexMapping = symbols.reduce((prev, curr, index) => {
      prev[curr] = index;
      return prev;
    }, {});

    const gic_info = JSON.parse(JSON.stringify(portfolio.gic_info || {}));
    let index;

    Object.keys(gic_info).forEach((key) => {
      index = symbolIndexMapping[gic_info[key].gic_ticker];
      gic_info[key].weight = weights[index];
      symbols[index] = key;
    });

    // figuring out what generic ticker  (ie. GIC#:MKT) to use when a user
    // wants to add a new GIC/HISA to their what-if portfolio
    let gic_new_generic_ticker = 'GIC1:MKT';
    // if at least one gic already exists in the what-if portfolio
    if (Object.keys(gic_info).length > 0) {
      const gicSample = [
        'GIC1:MKT',
        'GIC2:MKT',
        'GIC3:MKT',
        'GIC4:MKT',
        'GIC5:MKT',
        'GIC6:MKT',
        'GIC7:MKT',
        'GIC8:MKT',
        'GIC9:MKT',
        'GIC10:MKT',
      ];
      // getting all the unused generic gic tickers
      const possible_generic_tickers = gicSample.filter(
        (key) => !Object.keys(gic_info).includes(key)
      );

      gic_new_generic_ticker =
        possible_generic_tickers.length > 0
          ? possible_generic_tickers[0]
          : null;
    }

    // Reset the toggles
    dispatch(updateToggle('addBtcToggle', false));
    dispatch(updateToggle('addGicToggle', false));
    dispatch(updateAdvisorFee(null));

    if (symbols.includes('~BTCUSDT') || symbols.includes('~ETHUSDT')) {
      dispatch(updateToggle('addBtcToggle', true));
    }

    if (portfolio.advisor_fee) {
      dispatch(updateToggle('addAdvisorFeeToggle', true));
      dispatch(updateAdvisorFee(portfolio.advisor_fee));
    }

    const parsed = symbols.reduce((acc, val, idx) => {
      acc[val] = Math.round(Number(weights[idx]) * 10000) / 100;

      return acc;
    }, {});
    const max = symbols.length > 20 ? `&max=${symbols.length}` : '';

    return dispatch(
      request('get', `/security/`, {
        query: `?symbols=${symbols}${max}`,
      })
    ).then(
      (data) => {
        const parseData = data.reduce((acc, val) => {
          acc[val.ticker] = {
            ...val,
            weight: parsed[val.ticker],
          };

          return acc;
        }, {});

        return dispatch({
          type: COMPAREPORTFOLIO_LOAD_PORTFOLIO,
          portfolio: parseData,
          originalName: portfolio.name,
          name: portfolio.name,
          gic_info,
          overwriteOriginal,
          gic_new_generic_ticker,
        });
      },
      (error) => {
        dispatch({ type: COMPAREPORTFOLIO_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function convertAnalyticsPortfolioObjectChange(
  portfolio,
  paramsPassed,
  tickersOriginal,
  overwriteOriginal = true,
  initialSnapshot
) {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_REQUEST });
    const { symbols, weights } = paramsPassed;
    const symbolArray = symbols.split(',');
    const weightsArray = weights.split(',');
    // gics in the symbols array use their corresponding gic_ticker instead of the GIC#:MKT ticker format,
    // so here we rename all of these gic tickers back to the GIC#:MKT ticker format
    const symbolIndexMapping = symbolArray.reduce((prev, curr, index) => {
      prev[curr] = index;
      return prev;
    }, {});
    const gic_info = JSON.parse(JSON.stringify(portfolio.gic_info || {}));
    let index;
    Object.keys(gic_info).forEach((key) => {
      index = symbolIndexMapping[gic_info[key].gic_ticker];
      gic_info[key].weight = weightsArray[index];
      symbolArray[index] = key;
    });
    // figuring out what generic ticker  (ie. GIC#:MKT) to use when a user
    // wants to add a new GIC/HISA to their what-if portfolio
    let gic_new_generic_ticker = 'GIC1:MKT';
    // if at least one gic already exists in the what-if portfolio
    if (Object.keys(gic_info).length > 0) {
      const gicSample = [
        'GIC1:MKT',
        'GIC2:MKT',
        'GIC3:MKT',
        'GIC4:MKT',
        'GIC5:MKT',
        'GIC6:MKT',
        'GIC7:MKT',
        'GIC8:MKT',
        'GIC9:MKT',
        'GIC10:MKT',
      ];
      // getting all the unused generic gic tickers
      const possible_generic_tickers = gicSample.filter(
        (key) => !Object.keys(gic_info).includes(key)
      );
      gic_new_generic_ticker =
        possible_generic_tickers.length > 0
          ? possible_generic_tickers[0]
          : null;
    }
    // Reset the toggles
    dispatch(updateToggle('addBtcToggle', false));
    dispatch(updateToggle('addGicToggle', false));
    dispatch(updateAdvisorFee(null));
    if (symbolArray.includes('~BTCUSDT') || symbolArray.includes('~ETHUSDT')) {
      dispatch(updateToggle('addBtcToggle', true));
    }
    if (portfolio.advisor_fee) {
      dispatch(updateToggle('addAdvisorFeeToggle', true));
      dispatch(updateAdvisorFee(portfolio.advisor_fee));
    }
    const parsed = symbolArray.reduce((acc, val, idx) => {
      acc[val] = Math.round(Number(weightsArray[idx]) * 10000) / 100;
      return acc;
    }, {});
    const max = symbolArray.length > 20 ? `&max=${symbolArray.length}` : '';

    return dispatch(
      request('get', `/security/`, {
        query: `?symbols=${symbolArray}${max}`,
      })
    ).then(
      (data) => {
        let parseData = data.reduce((acc, val) => {
          acc[val.ticker] = {
            ...val,
            weight: parsed[val.ticker],
            isToggled: false,
          };
          return acc;
        }, {});
        parseData = Object.values(parseData).filter(
          (item) => item.weight !== 0
        );
        const output = Object.values(parseData).reduce((prev, curr) => {
          prev[curr.ticker] = {
            ...curr,
            isExcluded: curr.ticker.match(':MKT$')
              ? false
              : tickersOriginal.find((arrObj) => arrObj.ticker === curr.ticker)
                  .isExcluded || false,
          };
          return prev;
        }, {});
        return dispatch({
          type: COMPAREPORTFOLIO_LOAD_PORTFOLIO,
          portfolio: output,
          initialPortfolio: output,
          initialSnapshot,
          originalName: portfolio.name,
          name: portfolio.name,
          gic_info,
          overwriteOriginal,
          gic_new_generic_ticker,
        });
      },
      (error) => {
        dispatch({ type: COMPAREPORTFOLIO_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

// only used to fetch the data for the existing portfolio that the user is comparing to
export function fetchId(id, overwriteOriginal = true) {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_REQUEST });

    return dispatch(request('get', `/portfolio/${id}/`)).then(
      (portfolio) => {
        const weights = portfolio.weights.split(',');
        const symbols = portfolio.symbols.split(',');
        const gic_info = JSON.parse(JSON.stringify(portfolio.gic_info || {}));

        // figuring out what generic ticker  (ie. GIC#:MKT) to use when a user
        // wants to add a new GIC/HISA to their what-if portfolio
        let gic_new_generic_ticker = 'GIC1:MKT';
        if (Object.keys(gic_info).length > 0) {
          const gicSample = [
            'GIC1:MKT',
            'GIC2:MKT',
            'GIC3:MKT',
            'GIC4:MKT',
            'GIC5:MKT',
            'GIC6:MKT',
            'GIC7:MKT',
            'GIC8:MKT',
            'GIC9:MKT',
            'GIC10:MKT',
          ];
          const possible_generic_tickers = gicSample.filter(
            (key) => !Object.keys(gic_info).includes(key)
          );

          gic_new_generic_ticker =
            possible_generic_tickers.length > 0
              ? possible_generic_tickers[0]
              : null;
        }

        // Reset the toggles
        dispatch(updateToggle('addBtcToggle', false));
        dispatch(updateAdvisorFee(null));

        if (symbols.includes('~BTCUSDT') || symbols.includes('~ETHUSDT')) {
          dispatch(updateToggle('addBtcToggle', true));
        }

        if (portfolio.advisor_fee) {
          dispatch(updateToggle('addAdvisorFeeToggle', true));
          dispatch(updateAdvisorFee(portfolio.advisor_fee));
        }

        const parsed = symbols.reduce((acc, val, idx) => {
          acc[val] = Math.round(Number(weights[idx]) * 10000) / 100;

          return acc;
        }, {});
        const max = symbols.length > 20 ? `&max=${symbols.length}` : '';

        return dispatch(
          request('get', `/security/`, {
            query: `?symbols=${symbols}${max}`,
          })
        ).then(
          (data) => {
            const parseData = data.reduce((acc, val) => {
              acc[val.ticker] = {
                ...val,
                weight: parsed[val.ticker],
              };

              return acc;
            }, {});

            return dispatch({
              type: COMPAREPORTFOLIO_LOAD_PORTFOLIO,
              portfolio: parseData,
              originalName: portfolio.name,
              name: portfolio.name,
              gic_info,
              overwriteOriginal,
              gic_new_generic_ticker,
            });
          },
          (error) => {
            dispatch({ type: COMPAREPORTFOLIO_ADD_SUCCESS, security: null });

            return dispatch(
              Alert.show({ type: 'error', msg: parseErr(error) })
            );
          }
        );
      },
      (error) => {
        dispatch({ type: COMPAREPORTFOLIO_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function fetchCompare(
  port1,
  port2,
  gic_info_1,
  gic_info_2,
  region,
  custom_bm, // the custom benchmark ex. {symbols: "XBB:CA", weights: "1"}
  check_history,
  rebalanceInterval = 1
) {
  // making copies of all the objects to avoid changing the data in the store
  port1 = { ...port1 };
  port2 = { ...port2 };
  gic_info_1 = { ...gic_info_1 };
  gic_info_2 = { ...gic_info_2 };

  return (dispatch) => {
    const gic_info_1_keys = Object.keys(gic_info_1).filter(
      (key) => !!port1[key]
    ); // filter out any keys in gic_info that do no exist in the portfolio
    // (this can happen when a gic has a value of 0)
    const gic_info_2_keys = Object.keys(gic_info_2).filter(
      (key) => !!port2[key]
    );

    // For example say you have gic_info_1={GIC1:MKT: {...}, GIC2:MKT: {...}}
    // and gic_info_2={GIC1:MKT: {...}, GIC2:MKT: {...}}
    // after getting through the if block below, gic_info_1={GIC1:MKT: {...}, GIC2:MKT: {...}}
    // and gic_info_2={GIC10:MKT: {...}, GIC9:MKT: {...}} (these changes will also apply to port1 & port2)
    if (gic_info_1_keys.length && gic_info_2_keys.length) {
      // the back-end can only accept at most 10 GICs so these are the only options for tickers
      let gic_names = [
        'GIC1:MKT',
        'GIC2:MKT',
        'GIC3:MKT',
        'GIC4:MKT',
        'GIC5:MKT',
        'GIC6:MKT',
        'GIC7:MKT',
        'GIC8:MKT',
        'GIC9:MKT',
        'GIC10:MKT',
      ];
      // getting all available unused gic_names that we can use to rename the intersection later
      gic_names = gic_names.filter(
        (name) =>
          !gic_info_1_keys.includes(name) && !gic_info_2_keys.includes(name)
      );

      const gic_intersection = gic_info_1_keys.filter((key) =>
        gic_info_2_keys.includes(key)
      );

      if (gic_names.length < gic_intersection.length) {
        return dispatch(
          Alert.show({
            type: 'error',
            msg: 'You are limited to a combined total of 10 GICs/HISAs across both portfolios in this comparison. ',
          })
        );
      }

      // rename every key in the what-if portfolio that also appears in gic_intersection
      gic_intersection.forEach((key) => {
        const newName = gic_names.pop();
        gic_info_2[newName] = { ...gic_info_2[key] };
        port2[newName] = { ...port2[key] };
        delete port2[key];
        delete gic_info_2[key];
      });
    }

    const all_gic_info = { ...gic_info_1, ...gic_info_2 };

    const symbols1 = Object.keys(port1)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const weights1 = Object.keys(port1)
      .map((ticker) => (port1[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const symbols2 = Object.keys(port2)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const weights2 = Object.keys(port2)
      .map((ticker) => (port2[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);

    dispatch({ type: COMPAREPORTFOLIO_REQUEST });

    return dispatch(
      request('post', `/portfolio/analytics/compare/`, {
        body: {
          symbols1,
          weights1,
          symbols2,
          weights2,
          gic_info: all_gic_info,
          region: region || 'CA',
          custom_bm:
            !custom_bm || !Object.keys(custom_bm).length
              ? undefined
              : custom_bm,
          check_history,
          rebalance_interval: rebalanceInterval,
        },
      })
    ).then(
      ({ portfolio1, portfolio2 }) =>
        dispatch({
          type: COMPAREPORTFOLIO_COMPARE_SUCCESS,
          analysis1: {
            ...portfolio1,
            // back-end doesnt return portfolio name, id, etc...
            ...(portfolio1 &&
              Object.keys(portfolio1).length && { portfolio: port1 }),
            check_history,
          },
          analysis2: {
            ...portfolio2,
            // back-end doesnt return portfolio name, id, etc...
            ...(portfolio2 &&
              Object.keys(portfolio2).length && { portfolio: port2 }),
            check_history,
          },
        }),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({
          type: COMPAREPORTFOLIO_COMPARE_SUCCESS,
          analysis1: null,
          analysis2: null,
        });

        return dispatch(
          Alert.show({
            type: 'error',
            msg: parsedError
              .replace('portfolio1', 'current portfolio')
              .replace('portfolio2', 'what-if portfolio'),
          })
        );
      }
    );
  };
}

export function roundWeightsToWholeNumbers() {
  return { type: COMPAREPORTFOLIO_ROUND_WEIGHTS_TO_WHOLE_NUMBERS };
}

export function rescaleWeights(weight) {
  return { type: COMPAREPORTFOLIO_RESCALE, weight };
}

export function add(id) {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) =>
        dispatch({ type: COMPAREPORTFOLIO_ADD_SUCCESS, security: data }),
      (error) => {
        dispatch({ type: COMPAREPORTFOLIO_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function addGicBtcEth(ticker) {
  return {
    type: COMPAREPORTFOLIO_ADD_SUCCESS,
    security: { ticker },
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: COMPAREPORTFOLIO_SEARCH_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: COMPAREPORTFOLIO_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: COMPAREPORTFOLIO_SEARCH_SUCCESS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function updateWeight(ticker, value) {
  return {
    type: COMPAREPORTFOLIO_UPDATE_WEIGHT,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}
export function updateInterestRate(ticker, value) {
  return {
    type: COMPAREPORTFOLIO_UPDATE_INTEREST,
    ticker,
    interest_rate: value === '' ? '' : Number(value),
  };
}

export function updateGicInfo(field, value) {
  return {
    type: COMPAREPORTFOLIO_UPDATE_GIC,
    field,
    value,
  };
}

export function updateToggle(field, value) {
  return {
    type: COMPAREPORTFOLIO_UPDATE_TOGGLE,
    field,
    value,
  };
}

export function updateAdvisorFee(fee) {
  return {
    type: COMPAREPORTFOLIO_UPDATE_ADVISOR_FEE,
    fee,
  };
}

export function remove(ticker) {
  return {
    type: COMPAREPORTFOLIO_REMOVE_SECURITY,
    ticker,
  };
}

export function removeAllHoldings() {
  return {
    type: COMPAREPORTFOLIO_REMOVE_ALL_HOLDINGS,
  };
}
export function removeAllGics() {
  return {
    type: COMPAREPORTFOLIO_REMOVE_ALL_GICS,
  };
}

export function clearComparison() {
  return { type: COMPAREPORTFOLIO_CLEAR };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
