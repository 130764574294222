import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, Header, Icon, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Score, Table } from 'components';
import { decideCurrency } from 'utils/helpers';

@connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))
export default class Downside extends Component {
  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '-');
  getDownside = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0%');

  parseDate = (portfolio) => {
    const { french } = this.props;
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = french
      ? {
          '01': 'Janvier',
          '02': 'Février',
          '03': 'Mars',
          '04': 'Avril',
          '05': 'Mai',
          '06': 'Juin',
          '07': 'Juillet',
          '08': 'Août',
          '09': 'Septembre',
          10: 'Octobre',
          11: 'Novembre',
          12: 'Décembre',
        }
      : {
          '01': 'January',
          '02': 'February',
          '03': 'March',
          '04': 'April',
          '05': 'May',
          '06': 'June',
          '07': 'July',
          '08': 'August',
          '09': 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };

    return `${french ? 'de ' : ''}${months[init[1]]} ${init[0]} ${
      french ? 'à' : 'to'
    } ${months[end[1]]} ${end[0]}`;
  };

  render() {
    const { benchmark, data, user_region, french } = this.props;

    return (
      <Container>
        <Score
          name={french ? 'Risque de pertes ' : 'Downside '}
          score={`${data.score.Volatility[benchmark][0]}%`}
          grade={data.score.Volatility[benchmark][1]}
          description={
            french
              ? 'Noté sur la base de trois mesures de risque de pertes: la baisse maximale, le risque de baisse et la capture à la baisse (par rapport à celles de l’indice de référence).<br /> Si votre portefeuille ne fait qu’égaler, sans dépasser, l’indice de référence de base, il obtiendra une note de passage, soit 65 %.'
              : 'Scored on three downside measures of risk: maximum drawdown, downside risk, and downside capture (relative to those of the benchmark).<br />If your portfolio is just matching (rather than outperforming) the simple benchmark, it will get a pass, at 65%.'
          }
          portfolio={data.portfolio}
        />

        <p>
          {french
            ? 'La protection contre les baisses découle de la capacité de votre portefeuille à limiter les pertes en cas de baisse du cours du marché.'
            : "Downside protection speaks to your portfolio's ability to limit losses when there is a market downturn."}
        </p>
        <p>
          {french
            ? 'En gestion de portefeuille, la mesure traditionnelle du risque (ou de la volatilité) est l’écart-type des rendements historiques d’un portefeuille. Il s’agit d’une mesure du risque '
            : "In portfolio management, the traditional measure of risk (or volatility) is the standard deviation of a portfolio’s historical returns. It is a measure of the portfolio's "}
          <strong>total</strong>{' '}
          {french
            ? 'du portefeuille. Il est présenté sous forme de pourcentage annualisé pour faciliter la comparaison, peu importe le portefeuille ou la période.'
            : 'risk. It is reported as an annualized percentage for easy comparison across portfolios and time periods.'}
        </p>
        <p>
          {french
            ? 'Il est important de noter que le risque total comprend la possibilité de gains et de'
            : 'It is important to note that total risk has an upside and a'}{' '}
          <strong>{french ? 'pertes' : 'downside'}</strong>
          {french
            ? '. Au fil du temps, les rendements du portefeuille varieront au-dessus de la moyenne (ou de votre objectif de rendement), mais aussi '
            : '. Over time, portfolio returns will fluctuate above the average (or your target return), as well as '}
          <strong>{french ? 'en dessous' : 'below'}</strong>
          {french
            ? '. Par instinct, et aussi car c’est généralement un comportement rationnel, nous avons tendance à nous préoccuper davantage du risque de pertes que du risque de gains.'
            : '. It is human nature and often rational to be more concerned about downside risk than upside risk.'}
        </p>

        <Header>
          <Header.Content>
            {french ? 'Statistiques clés' : 'Key Statistics'}
          </Header.Content>
          <Header.Subheader>
            {french
              ? 'Selon la composition actuelle de votre portefeuille'
              : 'Based on the current composition of your portfolio'}
            &nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              content={
                french
                  ? 'En utilisant les mêmes avoirs et les mêmes pondérations.'
                  : 'Using the same holdings and the same weights.'
              }
              wide
            />
          </Header.Subheader>
          <Header.Subheader>
            {`${this.parseDate(data.portfolio)} ${
              french ? '(inclusivement) en' : '(inclusive) in'
            } ${decideCurrency(data, user_region || 'CA')}`}
          </Header.Subheader>
        </Header>
        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                {french ? 'Risque total' : 'Total Risk'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={
                    french
                      ? 'Il s’agit d’une mesure des fluctuations des rendements de votre portefeuille dans le temps, mesurée par l’écart-type. Il comprend les fluctuations haussières et baissières, d’où le nom de risque total.'
                      : "This is the variability of your portfolio's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk."
                  }
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french ? 'Risque de perte' : 'Downside Risk'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={
                    french
                      ? 'Il s’agit d’une mesure des fluctuations des rendements de votre portefeuille en dessous d’un objectif. L’objectif visé ici est la préservation du capital, c’est-à-dire un rendement qui n’est pas négatif.'
                      : "This is the variability of your portfolio's returns below a target. The target used here is capital preservation; that is, a non-negative return."
                  }
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french ? 'Baisse maximale' : 'Maximum Drawdown'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={
                    french
                      ? 'Il s’agit de la perte maximale de crête à creux observée pendant la période analysée. Elle donne une perspective différente sur le risque de pertes d’un portefeuille.'
                      : "This is the maximum peak to trough loss observed during the period in question. It gives a different perspective of a portfolio's downside risk."
                  }
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french ? 'Capture à la baisse' : 'Downside Capture'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="left center"
                  content={
                    french
                      ? 'Quantifie la perte relative de votre portefeuille par rapport au marché boursier (tel qu’il a été indiqué) durant une baisse du marché. Une capture à la baisse inférieure à 100 % indique que votre portefeuille a perdu moins que le marché au cours de ces périodes. Une valeur de capture à la baisse négative indique que votre portefeuille a plutôt connu un rendement positif lorsque le marché boursier était dans le rouge.'
                      : 'This measures whether your portfolio lost less than the stock market (as selected) when there was a market downturn. A downside capture of less than 100% indicates that your portfolio lost less than the stock market during those periods. A downside capture that is negative indicates that your portfolio actually generated positive returns when the stock market was in the red.'
                  }
                  wide
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell>
                {french ? 'Mon portefeuille' : 'My Portfolio'}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.stdDev)}
              </Table.Cell>
              <Table.Cell>
                {this.getDownside(data.fratios.portfolio.dsr)}
              </Table.Cell>
              <Table.Cell>
                {this.getDownside(data.fratios.portfolio.maxDd)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.dscapture[benchmark])}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {data.fratios[benchmark].name}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={data.fratios[benchmark].description}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].stdDev)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].dsr)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].maxDd)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].dscapture)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

Downside.propTypes = {
  data: PropTypes.object.isRequired,
  benchmark: PropTypes.string.isRequired,
  user_region: PropTypes.string,
};

Downside.defaultProps = {
  user_region: 'CA',
};

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
