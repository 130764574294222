import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Divider } from 'semantic-ui-react';

@connect()
export default class Unauthorized extends Component {
  render() {
    const { token } = qs.parse(window.location.search);
    let tokenInvalid = false;
    if (token === 'invalid') {
      tokenInvalid = true;
    }

    return (
      <div className="unauthorized">
        <Container>
          {tokenInvalid ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '50px',
              }}
            >
              <Header>
                Service Unavailable
                <SubHeader>
                  We are working to resolve this as quickly as possible. Please
                  try again later. Thank you for your patience!
                </SubHeader>
              </Header>
              <Divider />
              <Header>
                Service indisponible
                <SubHeader>
                  Nous travaillons à résoudre ce problème le plus rapidement
                  possible. Veuillez réessayer plus tard. Merci de votre
                  patience!
                </SubHeader>
              </Header>
            </div>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

Unauthorized.defaultProps = {
  dispatch: () => false,
  history: {},
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 625px;
  height: 100%;
  text-align: center;
`;

const Spinner = styled.div`
  display: inline-block;
  width: 70px;
  height: 70px;
  border: 4px solid #c7c7c7;
  border-radius: 50%;
  border-bottom-color: rgba(0, 0, 0, 0.7);
  animation: spin 900ms linear infinite;
`;

const Header = styled.div`
  font-size: 2rem;
  line-height: 3rem;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubHeader = styled.div`
  font-size: 1.2rem;
`;
