import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Flag, Icon, Popup, Header } from 'semantic-ui-react';
import cn from 'classnames';

import {
  BuildML as BuildMlAction,
  CacheInputs,
  FundAllocator,
  Storage,
} from 'actions';
import { colors } from 'utils/colors';
import { getTickerSearchResult } from 'utils/helpers';
import { Table, Button, Segment, SearchTicker } from 'components';
import Target from './Target';

@withRouter
class BuildML extends Component {
  state = {
    findWeightsEnable: false,
    analyzeEnable: false,
  };

  componentDidMount = () => {
    const { user_region, dispatch } = this.props;
    dispatch(Storage.setItem('toggle_region', user_region));
    this.updateButtonStatus();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.fundallocator && this.props.fundallocator) {
      const { fundallocator } = this.props || {};
      const { output } = fundallocator;

      if (output && prevProps.fundallocator.output !== output) {
        const notes = output._notes;
        if (notes.new_fund_allocation_failed) {
          return this.props.dispatch(
            CacheInputs.updateField('portfolios', 'fund_allocator', {
              error: { investment: true },
            })
          );
        }
        this.props.dispatch(
          CacheInputs.updateField('portfolios', 'fund_allocator', {
            cacheOutput: Object.keys(output)
              .filter((key) => key !== '_notes')
              .map((key) => ({
                [key]: output[key],
              })),
          })
        );
      }
    }

    if (
      prevProps.buildml &&
      prevProps.buildml.portfolio &&
      prevProps.buildml.portfolio !== this.props.buildml.portfolio
    ) {
      this.props.dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', {
          cacheOutput: [],
        })
      );

      this.updateButtonStatus();
    }

    if (
      prevProps.buildml &&
      prevProps.buildml.assetAllocation &&
      prevProps.buildml.assetAllocation !== this.props.buildml.assetAllocation
    ) {
      this.updateButtonStatus();
    }

    if (
      prevProps.buildml &&
      prevProps.buildml.fixedIncome !== this.props.buildml.fixedIncome
    ) {
      this.props.dispatch(BuildMlAction.clearPrevSavedAssetAllocation());
      return this.setState({ findWeightsEnable: true, analyzeEnable: false });
    }

    return false;
  };

  componentWillUnmount = () => {
    window.scrollTo(0, 0);
  };

  isListEqual = (list1, list2) => {
    if (list1.length !== list2.length) return false;

    list1 = list1.sort((a, b) => a.localeCompare(b));
    list2 = list2.sort((a, b) => a.localeCompare(b));

    for (let i = 0; i < list1.length; i += 1) {
      if (list1[i] !== list2[i]) return false;
    }

    return true;
  };

  updateButtonStatus = () => {
    const { assetAllocation, portfolio, prevSavedAssetAllocation } =
      this.props.buildml;
    const { excluded_tickers } = assetAllocation;
    const curValidList = Object.keys(assetAllocation).length
      ? Object.keys(assetAllocation.hrp_weights)
      : [];
    const prevValidList = prevSavedAssetAllocation.hrp_weights
      ? Object.keys(prevSavedAssetAllocation.hrp_weights)
      : [];
    const curInput = Object.keys(portfolio);

    // init status
    if (!Object.keys(portfolio).length) {
      return this.setState({ findWeightsEnable: false, analyzeEnable: false });
    }

    // at least find weight once
    if (Object.keys(prevSavedAssetAllocation).length) {
      if (!curValidList.length) {
        // no valid holdings can analyze
        return this.setState({ findWeightsEnable: true, analyzeEnable: false });
      }

      const curValidInput = curInput.filter(
        (h) => excluded_tickers.indexOf(h) === -1
      );

      // input is changing
      if (!this.isListEqual(curValidInput, prevValidList)) {
        this.props.dispatch(BuildMlAction.clearPrevSavedAssetAllocation());
        return this.setState({ findWeightsEnable: true, analyzeEnable: false });
      }

      return this.setState({ findWeightsEnable: false, analyzeEnable: true });
    }

    // only have input, but hasn't done find weight yet
    return this.setState({ findWeightsEnable: true, analyzeEnable: false });
  };

  round = (num) => Math.round(num * 100) / 100;

  filterPortfolioCash = (portfolio) =>
    Object.keys(portfolio)
      .filter((key) => key !== 'CASH:MKT')
      .reduce((obj, key) => {
        obj[key] = portfolio[key];
        return obj;
      }, {});

  isDisable = () => {
    const { fundAllocatorInputs } = this.props;
    const { investment, commission, commissionfree } = fundAllocatorInputs;

    if (!investment || commission === null) {
      return true;
    }

    if (
      commissionfree &&
      (!Number.isInteger(Number(commissionfree)) || commissionfree < 0)
    ) {
      return true;
    }

    return false;
  };

  handleChange = (e) => {
    const { dispatch, fundAllocatorInputs } = this.props;
    const { error } = fundAllocatorInputs;
    const { name, value } = e.target;
    const num = Number(value);
    const limitRegex =
      name === 'commission' || name === 'fixedIncome'
        ? /^\d*\.?\d*$/
        : /^[0-9]{1,9}$/;
    const negative = !limitRegex.test(num);

    if (value === '') {
      return dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', {
          [name]: null,
        })
      );
    }

    if (name === 'investment') {
      dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', {
          [name]: value,
          error: { ...error, [name]: null },
        })
      );
    }

    if (name === 'commissionfree') {
      dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', {
          [name]: value,
          error: { ...error, [name]: null },
        })
      );
    }

    if (!negative) {
      dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', { [name]: num })
      );

      return dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', {
          error: { ...error, [name]: null },
        })
      );
    }

    return false;
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  submit = (e) => {
    e.preventDefault();
    const { fundAllocatorInputs, dispatch, buildml, user_region } = this.props;
    const { investment, commission, commissionfree } = fundAllocatorInputs;
    const { portfolio, assetAllocation } = buildml;
    const portfolioInsertWeight = Object.keys(portfolio).reduce(
      (result, key) => {
        if (assetAllocation.hrp_weights[key]) {
          const weight = assetAllocation.hrp_weights[key];
          result[key] = { ...portfolio[key], weight };
        }

        return result;
      },
      {}
    );

    if (commissionfree > Object.keys(portfolioInsertWeight).length) {
      return dispatch(
        CacheInputs.updateField('portfolios', 'fund_allocator', {
          error: { commissionfree: true },
        })
      );
    }

    const tickers = Object.keys(portfolioInsertWeight).join(',');
    const quantities = Object.keys(portfolioInsertWeight)
      .reduce((result) => {
        result.push('0');

        return result;
      }, [])
      .join(',');
    const targetWeights = Object.values(portfolioInsertWeight)
      .reduce((result, value) => {
        if (value && value.weight) {
          result.push(value.weight.toFixed(2));
        }

        return result;
      }, [])
      .join(',');

    const body = {
      tickers,
      quantities,
      target_weights: targetWeights,
      cash_currency: user_region === 'US' ? 'USD' : 'CAD',
      cash_amount: investment,
      fee_per_trade: commission,
    };

    dispatch(
      CacheInputs.updateField('portfolios', 'fund_allocator', {
        cachePortfolio: { ...portfolioInsertWeight },
        cacheOutput: [],
      })
    );

    return dispatch(FundAllocator.calculate(body)).then(() => {
      this.scrollHandler(1000, 600);
    });
  };

  findWeights = () => {
    const { dispatch, buildml, user_region } = this.props;
    const { portfolio, fixedIncome, assetAllocation } = buildml;
    const { excluded_tickers = [] } = assetAllocation;
    const mlList = Object.values(portfolio).filter(
      (p) => !excluded_tickers.includes(p.ticker)
    );
    const params = {
      symbols: Object.keys(portfolio).join(','),
      region: user_region,
      ...(this.hasFixedIncome(mlList) && {
        fixed_income_weight: (fixedIncome / 100).toFixed(2),
      }),
    };

    return dispatch(BuildMlAction.fetchAssetAllocation(params));
  };

  analyze = (assetAllocation) => {
    const { history, dispatch } = this.props;
    const { hrp_weights } = assetAllocation;
    const params = Object.keys(hrp_weights).reduce(
      (total, key) => {
        total.symbols.push(key);
        total.weights.push(hrp_weights[key]);

        return total;
      },
      { symbols: [], weights: [] }
    );

    dispatch(
      Storage.setItem('pwpa-portfolio', {
        portfolio_type: 'custom',
        portfolio_sub_type: 'ml_asset_allocation',
        region: 'CA',
        symbols: params.symbols.join(','),
        weights: params.weights.join(','),
        gic_info: {},
      })
    );

    return history.push('/portfolio/unsaved/overview');
  };

  hasFixedIncome = (portfolio) =>
    portfolio.filter((p) => p.global_asset_class === 'Fixed Income').length;

  validateSection = (section) => {
    const { fundAllocatorInputs } = this.props;
    const { error } = fundAllocatorInputs;
    const sections = {
      commissionSection: ['commissionfree'],
      investment: ['investment'],
    };

    return !!Object.keys(error).find(
      (key) => sections[section].indexOf(key) !== -1 && error[key]
    );
  };

  remove = (ticker) => {
    const { dispatch } = this.props;

    return dispatch(BuildMlAction.remove(ticker));
  };

  removeAll = () => this.props.dispatch(BuildMlAction.removeAll());

  renderTable = (args) => {
    const { portfolio, assetAllocation, excluded } = args;
    const { analyzeEnable } = this.state;
    const hasAssetAllocation = Object.keys(assetAllocation).length;
    const { hrp_weights } = assetAllocation;
    /* const showCorrDetail = (obj) => {
      const corrText = Object.keys(obj).map(
        (key) => `${key}: ${obj[key].toFixed(2)}`
      );

      return (
        <Popup
          trigger={
            <div
              style={{
                cursor: 'pointer',
              }}
            >
              Show Detail
            </div>
          }
          position="bottom center"
        >
          {corrText.map((corr) => (
            <div>
              {corr}
              <br />
            </div>
          ))}
        </Popup>
      );
    }; */

    return (
      <Segment className="basic compact">
        {excluded ? null : (
          <RemoveAll onClick={this.removeAll}>clear</RemoveAll>
        )}
        <Table
          textAlign="center"
          style={{
            marginBottom: '0rem',
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                colSpan="2"
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Asset
              </Table.HeaderCell>
              <ConditionalHeaderCell
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Exchange
              </ConditionalHeaderCell>
              <ConditionalHeaderCell
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Class
              </ConditionalHeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Price
              </Table.HeaderCell>
              <ConditionalHeaderCell
                // colSpan={3}
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Start Date{' '}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '1rem',
                        verticalAlign: 'top',
                      }}
                    />
                  }
                  position="right center"
                  content="The start date is the month after the inception/IPO date of your holding or April 2007, whichever comes later."
                />
              </ConditionalHeaderCell>
              {!excluded ? (
                <Table.HeaderCell
                  style={{ background: 'rgba(203,232,238,0.3)' }}
                >
                  Weights
                </Table.HeaderCell>
              ) : null}

              {/* {hasAssetAllocation && !excluded ? (
                <Table.HeaderCell
                  style={{ background: 'rgba(203,232,238,0.3)' }}
                >
                  Dates
                </Table.HeaderCell>
              ) : null}
              {hasAssetAllocation && !excluded ? (
                <Table.HeaderCell
                  style={{ background: 'rgba(203,232,238,0.3)' }}
                >
                  Original Corr
                </Table.HeaderCell>
              ) : null}
              {hasAssetAllocation && !excluded ? (
                <Table.HeaderCell
                  style={{ background: 'rgba(203,232,238,0.3)' }}
                >
                  Reordered Corr
                </Table.HeaderCell>
              ) : null} */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {portfolio.map((security) => (
              <StyledRow
                negative={!security.unadjusted_closing_price.CAD}
                key={security.ticker}
                style={{ height: 52, position: 'relative' }}
              >
                <i
                  className="close"
                  onClick={() => this.remove(security.ticker)}
                >
                  +
                </i>
                <Table.Cell width={4} style={{ position: 'relative' }}>
                  {security.ticker || 'N/A'}
                  <SecurityName>{security.long_name || 'N/A'}</SecurityName>
                  {!security.unadjusted_closing_price.CAD && (
                    <Error>
                      <Popup
                        trigger={
                          <Icon
                            style={{
                              transform: 'translate(-20px, -4px)',
                              position: 'absolute',
                              display: 'block',
                              marginTop: '0',
                            }}
                            name="exclamation circle"
                          />
                        }
                        position="left center"
                        content="Unfortunately, this security cannot be added as its price is $0"
                      />
                    </Error>
                  )}
                </Table.Cell>
                <ConditionalCell>
                  {security.exchange_symbol || 'N/A'}
                </ConditionalCell>
                <ConditionalCell>
                  {security.global_asset_class || 'N/A'}
                </ConditionalCell>
                <Table.Cell>
                  $
                  {this.round(
                    security.unadjusted_closing_price[
                      security.default_currency
                    ] || 0
                  )}
                  <Flag
                    name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                    style={{ marginLeft: '0.3rem' }}
                  />
                </Table.Cell>
                <ConditionalCell>
                  {security.start_date || 'N/A'}
                </ConditionalCell>
                {excluded ? null : hasAssetAllocation && !excluded ? (
                  <Table.Cell
                    style={{
                      background: analyzeEnable ? colors.lightGrey : 'unset',
                    }}
                  >
                    {analyzeEnable ? (
                      <b>
                        {hrp_weights[security.ticker]
                          ? (hrp_weights[security.ticker] * 100).toFixed(2)
                          : '0'}
                        %
                      </b>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                ) : (
                  <Table.Cell>-</Table.Cell>
                )}
                {/* {hasAssetAllocation && !excluded ? (
                  <Table.Cell width={3}>
                    {init_date}
                    <br />
                    &#10072;
                    <br />
                    {end_date}
                  </Table.Cell>
                ) : null}
                {hasAssetAllocation && !excluded ? (
                  <Table.Cell width={2}>
                    {original_corr[security.ticker]
                      ? showCorrDetail(original_corr[security.ticker])
                      : null}
                  </Table.Cell>
                ) : null}
                {hasAssetAllocation && !excluded ? (
                  <Table.Cell width={2}>
                    {reordered_corr[security.ticker]
                      ? showCorrDetail(reordered_corr[security.ticker])
                      : null}
                  </Table.Cell>
                ) : null} */}
              </StyledRow>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  };

  renderPurchaseOutput = () => {
    const { fundAllocatorInputs, pwpaExchangeRate } = this.props;
    const { cachePortfolio, cacheOutput } = fundAllocatorInputs;
    const totalValue = cacheOutput.length
      ? cacheOutput.reduce((result, security) => {
          const price = Object.values(security)[0].security_price;
          const exchangeRate = Number(pwpaExchangeRate);
          const quanties = Object.values(security)[0].rebalancing_buy;
          const currency = Object.values(security)[0].security_currency;

          return (result +=
            currency === 'USD'
              ? price * quanties * exchangeRate
              : price * quanties);
        }, 0)
      : null;

    return cacheOutput.length ? (
      <Segment style={{ paddingBottom: '2rem' }}>
        <div
          style={{ fontSize: '1.28em', fontWeight: '700', textAlign: 'center' }}
        >
          Quantity of Shares to Purchase
        </div>
        <Table textAlign="center" style={{ marginBottom: '0rem' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                colSpan="2"
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Asset
              </Table.HeaderCell>
              <ConditionalHeaderCell
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Exchange
              </ConditionalHeaderCell>
              <ConditionalHeaderCell
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                Class
              </ConditionalHeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Price
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Weight
                <Popup
                  wide
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ fontSize: '15px', verticalAlign: 'initial' }}
                    />
                  }
                  position="top left"
                >
                  <Popup.Content>
                    There may be a discrepancy between your target weights and
                    the calculated weights as the number of shares are rounded
                    to the nearest whole number.
                  </Popup.Content>
                </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                Quantity
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(this.filterPortfolioCash(cachePortfolio)).map(
              (security, index) => (
                <StyledRow
                  negative={!security.unadjusted_closing_price.CAD}
                  key={security.ticker}
                  style={{ height: 52, position: 'relative' }}
                >
                  <div>&nbsp;</div>
                  <Table.Cell width={5} style={{ position: 'relative' }}>
                    {security.ticker || 'N/A'}
                    <SecurityName>{security.long_name || 'N/A'}</SecurityName>
                    {!security.unadjusted_closing_price.CAD && (
                      <Error>
                        <Popup
                          trigger={
                            <Icon
                              style={{
                                transform: 'translate(-20px, -4px)',
                                position: 'absolute',
                                display: 'block',
                                marginTop: '0',
                              }}
                              name="exclamation circle"
                            />
                          }
                          position="left center"
                          content="Unfortunately, this security cannot be added as its price is $0"
                        />
                      </Error>
                    )}
                  </Table.Cell>
                  <ConditionalCell>
                    {security.exchange_symbol || 'N/A'}
                  </ConditionalCell>
                  <ConditionalCell>
                    {security.global_asset_class || 'N/A'}
                  </ConditionalCell>
                  <Table.Cell>
                    $
                    {this.round(
                      security.unadjusted_closing_price[
                        security.default_currency
                      ] || 0
                    )}
                    <Flag
                      name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                      style={{ marginLeft: '0.3rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {(() => {
                      const value =
                        cacheOutput[index][security.ticker]
                          .security_currency === 'USD'
                          ? cacheOutput[index][security.ticker]
                              .rebalancing_buy *
                            cacheOutput[index][security.ticker].security_price *
                            Number(pwpaExchangeRate)
                          : cacheOutput[index][security.ticker]
                              .rebalancing_buy *
                            cacheOutput[index][security.ticker].security_price;

                      return value / totalValue
                        ? `${((value / totalValue) * 100).toFixed(2)}%`
                        : '0';
                    })()}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <div style={{ color: colors.blue }}>
                      {cacheOutput[index][security.ticker].rebalancing_buy}
                    </div>
                  </Table.Cell>
                </StyledRow>
              )
            )}
          </Table.Body>
        </Table>
      </Segment>
    ) : null;
  };

  render() {
    const { buildml, dispatch, fundAllocatorInputs, user_region } = this.props;
    const {
      search,
      portfolio,
      assetAllocation,
      allocationToggle,
      fixedIncome,
    } = buildml;
    const { findWeightsEnable, analyzeEnable } = this.state;
    const { excluded_tickers = [] } = assetAllocation;
    const mlList = Object.values(portfolio).filter(
      (p) => !excluded_tickers.includes(p.ticker)
    );
    const excludedList = Object.values(portfolio).filter((p) =>
      excluded_tickers.includes(p.ticker)
    );
    const { investment, commission, error } = fundAllocatorInputs;

    return (
      <div style={{ height: '100%' }}>
        <Segment
          style={{
            minHeight: '750px',
            textAlign: 'center',
            padding: '2rem 8rem',
          }}
        >
          <Header
            textAlign="center"
            size="medium"
            style={{ padding: '1rem 0 2rem 0' }}
          >
            Asset Allocation Using Machine Learning
            <Header.Subheader>
              This tool uses a technique called Hierarchical Risk Parity (HRP)
              to determine the portfolio weights, given a list of holdings.
              Compared to the classic Markowitz efficient frontier, the
              assumptions of HRP are less restrictive and it produces more
              stable results.
            </Header.Subheader>
            <br />
            <Header.Subheader>
              For more information, please read this short{' '}
              <a
                href={
                  user_region === 'US'
                    ? 'https://www.wealthscope.ca/assetallocationus'
                    : 'https://www.wealthscope.ca/assetallocation'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>asset allocation</strong>
              </a>{' '}
              article.
            </Header.Subheader>
          </Header>
          <Note>Note: Holdings must have at least 3 years of history.</Note>

          <div style={{ textAlign: 'left', marginTop: '3rem' }}>
            <Header
              style={{ fontSize: '15.5px', marginBottom: '.3rem' }}
              content="Add Holdings"
            />
            <Note>
              {user_region === 'CA'
                ? 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'
                : 'Note: Currently we only have data for US-listed stocks and ETFs, and US mutual funds.'}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
              />
              Always check the fund code or ticker symbol to ensure you have the
              correct share class. Use US instead of U.S..
            </Note>
          </div>

          <SearchTicker
            loading={buildml.isFetching}
            results={getTickerSearchResult(search)}
            featureAction={BuildMlAction}
            region={user_region}
          />

          {this.hasFixedIncome(mlList) ? (
            <Field2>
              <Header
                style={{
                  fontSize: '15.5px',
                  margin: '0',
                  display: 'inline-block',
                }}
              >
                What is your total target allocation to fixed income?
              </Header>
              <Target fixedIncome={fixedIncome} />
            </Field2>
          ) : null}

          {Object.keys(assetAllocation).length && Object.keys(portfolio).length
            ? this.renderTable({
                portfolio: mlList,
                assetAllocation,
              })
            : Object.keys(portfolio).length
            ? this.renderTable({
                portfolio: Object.values(portfolio),
                assetAllocation,
              })
            : null}

          {excludedList.length ? (
            <div style={{ textAlign: 'left', marginTop: '2rem' }}>
              <Header
                style={{ fontSize: '15.5px', marginBottom: '.3rem' }}
                content="Excluded due to less than 3 years of history"
              />
              {this.renderTable({
                portfolio: excludedList,
                assetAllocation,
                excluded: true,
              })}
            </div>
          ) : null}

          {!Object.keys(portfolio).length ? (
            <div style={{ margin: '2rem auto 3rem' }}>
              <Button
                className={cn('left centered grey-focus2', {
                  disabled: true,
                })}
                color={colors.darkBlue}
                icon="chart pie"
                onClick={this.findWeights}
                style={{ display: 'inline-block', marginRight: '0.5rem' }}
              >
                Find Weights
              </Button>
            </div>
          ) : (
            <div style={{ margin: '2rem auto 3rem' }}>
              {analyzeEnable ? (
                <Button
                  className={cn('left centered grey-focus2')}
                  color={colors.darkBlue}
                  icon="line chart"
                  onClick={() => this.analyze(assetAllocation)}
                  style={{ display: 'inline-block', marginLeft: '0.5rem' }}
                >
                  Analyze
                </Button>
              ) : (
                <Button
                  className={cn('left centered grey-focus2', {
                    disabled:
                      !Object.keys(portfolio).length || !findWeightsEnable,
                  })}
                  color={colors.darkBlue}
                  icon="chart pie"
                  onClick={this.findWeights}
                  style={{ display: 'inline-block', marginRight: '0.5rem' }}
                >
                  Find Weights
                </Button>
              )}
            </div>
          )}

          <div
            style={{
              minHeight: Object.keys(assetAllocation).length ? '100px' : '0',
            }}
          >
            {analyzeEnable ? (
              <div style={{ textAlign: 'left', marginTop: '2rem' }}>
                <div>
                  <div style={{ display: 'inline-block' }}>
                    <Header
                      style={{
                        fontSize: '15.5px',
                        margin: '0',
                      }}
                    >
                      Would you like to calculate how many shares you need to
                      purchase for each holding?
                    </Header>
                  </div>
                  <OptionsWrapper>
                    <div
                      className={cn('select left', {
                        active: allocationToggle,
                      })}
                      onClick={() =>
                        dispatch(
                          BuildMlAction.updateToggle('allocationToggle', true)
                        )
                      }
                    >
                      Yes
                    </div>
                    <div
                      className={cn('select right', {
                        active: !allocationToggle,
                      })}
                      onClick={() => {
                        dispatch(
                          BuildMlAction.updateToggle('allocationToggle', false)
                        );
                      }}
                    >
                      No
                    </div>
                  </OptionsWrapper>
                </div>
              </div>
            ) : null}

            {analyzeEnable && allocationToggle ? (
              <Section
                single
                className={cn({
                  invalid: this.validateSection('investment'),
                })}
              >
                <div className="heading" style={{ fontSize: '15.5px' }}>
                  Calculate how many shares to purchase
                </div>

                <Field>
                  <label>How much are you allocating to this portfolio?</label>
                  {error.investment ? (
                    <label style={{ color: colors.red }}>
                      (Insufficient capital allocated to fulfill target weights.
                      Please adjust your inputs.)
                    </label>
                  ) : null}
                  <input
                    className={cn({ invalid: error.investment })}
                    name="investment"
                    type="number"
                    placeholder="$"
                    min="1"
                    value={investment || ''}
                    onChange={this.handleChange}
                    onClick={() =>
                      dispatch(
                        CacheInputs.updateField(
                          'portfolios',
                          'fund_allocator',
                          {
                            error: { ...error, investment: null },
                          }
                        )
                      )
                    }
                  />
                </Field>
                <Field>
                  <label>
                    What is the commission per trade?
                    <Popup
                      hoverable
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content="If your broker charges by the share, enter the maximum commission."
                      style={{ textAlign: 'justify' }}
                    />
                  </label>
                  <input
                    className={cn({ invalid: error.commission })}
                    name="commission"
                    type="number"
                    placeholder="$"
                    value={commission === 0 ? '0' : commission || ''}
                    min="0"
                    onChange={this.handleChange}
                  />
                </Field>
                {/* <Field>
                  <label>
                    How many of the securities will be commission-free?
                    <Popup
                      wide
                      hoverable
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content="Some brokerages waive the commission for certain ETFs. Please check with your broker."
                      style={{ textAlign: 'justify' }}
                    />
                  </label>
                  {error.commissionfree ? (
                    <label style={{ color: colors.red }}>
                      (The number of securities commission-free needs to be less
                      than total securities.)
                    </label>
                  ) : null}
                  <input
                    className={cn({ invalid: error.commissionfree })}
                    name="commissionfree"
                    type="number"
                    min="0"
                    value={commissionfree === 0 ? '0' : commissionfree || ''}
                    onChange={this.handleChange}
                    onClick={() =>
                      dispatch(
                        CacheInputs.updateField(
                          'portfolios',
                          'fund_allocator',
                          {
                            error: { ...error, commissionfree: null },
                          }
                        )
                      )
                    }
                  />
                </Field> */}
                <Button
                  className={cn('left grey-focus2', {
                    disabled: this.isDisable() || !analyzeEnable,
                  })}
                  color={colors.darkBlue}
                  icon="calculator"
                  style={{ display: 'block', margin: '2rem auto 0' }}
                  onClick={this.submit}
                >
                  Calculate
                </Button>
              </Section>
            ) : null}
          </div>
        </Segment>
        {this.renderPurchaseOutput()}
      </div>
    );
  }
}

BuildML.propTypes = {
  buildml: PropTypes.object,
  dispatch: PropTypes.func,
  fundallocator: PropTypes.object,
  fundAllocatorInputs: PropTypes.object,
  history: PropTypes.object.isRequired,
  pwpaExchangeRate: PropTypes.number,
  user_region: PropTypes.string,
};

BuildML.defaultProps = {
  dispatch: () => false,
  buildml: {},
  fundallocator: {},
  fundAllocatorInputs: {},
  user_region: 'CA',
  pwpaExchangeRate: 0,
};

export default connect((state) => ({
  buildml: state.BuildML,
  fundallocator: state.FundAllocator,
  fundAllocatorInputs: state.CacheInputs.portfolios.fund_allocator,
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region || 'CA',
}))(BuildML);

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative !important;

  .close {
    display: block;
    position: absolute;
    margin: 20px 0 0 -10px;
    z-index: 100;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const RemoveAll = styled.div`
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  width: 50px;
  font-size: 0.9rem;
  border-radius: 10px;
  line-height: 20px;
  border: 1px solid #dededf;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 1px solid #db2828;
    background: #fff6f6;
  }
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 1rem 0;
`;

const Section = styled.section`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 2rem auto;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Field = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 32px;

  label {
    display: block;
    width: 100%;
    max-width: 80%;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 73px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const OptionsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 0.75rem;

  .select {
    display: inline-block;
    padding: 0.1rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.blue};
      color: white;
    }

    &.left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const Field2 = styled.fieldset`
  margin: 2rem 0 1rem;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: left;

  input {
    padding: 0.4rem;
    margin-left: 1rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 73px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const ConditionalCell = styled(Table.Cell)`
  @media (max-width: 700px) {
    display: none;
  }
`;

const ConditionalHeaderCell = styled(Table.HeaderCell)`
  background: rgba(203, 232, 238, 0.3);
  @media (max-width: 700px) {
    display: none;
  }
`;
