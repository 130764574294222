/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Header, Popup, Icon } from 'semantic-ui-react';

import { Accounts } from 'actions';
import assignColors from 'utils/assignColors';

class Charts extends Component {
  state = {
    chartData: null,
  };

  componentDidMount = () => {
    const { dispatch } = this.props;

    return dispatch(Accounts.fetchAll());
  };

  componentDidUpdate = (prevProps) => {
    const { dispatch, accountList } = this.props;
    if (accountList !== prevProps.accountList) {
      return this.getChartData(accountList);
    }
  };

  getChartData = (accounts) => {
    let noHolding = 0;
    const accountHoldings = accounts.reduce(
      (result, account) => {
        const { asset_allocation, holdings } = account;

        result.Alternative += asset_allocation.Alternative;
        result.Equity += asset_allocation.Equity;
        result['Fixed Income'] += asset_allocation['Fixed Income'];

        if (!holdings.length) {
          noHolding += 1;
        }

        return result;
      },
      {
        Equity: 0,
        'Fixed Income': 0,
        Alternative: 0,
      }
    );

    // eslint-disable-next-line guard-for-in
    for (const holding in accountHoldings) {
      accountHoldings[holding] /= accounts.length - noHolding;
    }

    this.setState({
      chartData: {
        labels: Object.keys(accountHoldings),
        datasets: [
          {
            data: Object.values(accountHoldings),
            backgroundColor: assignColors(Object.keys(accountHoldings)),
          },
        ],
      },
    });
  };

  render() {
    const { chartData } = this.state;

    const config = {
      showAllTooltips: true,
      maintainAspectRatio: true,
      cutoutPercentage: 65,
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        },
      },
      legend: {
        position: 'left',
        labels: {
          padding: 4,
          boxWidth: 25,
          fontSize: 12,
        },
      },
      tooltips: {
        callbacks: {
          title: (tooltipItems) => chartData.labels[tooltipItems[0].index],
          label: (tooltipItems) =>
            `${Math.round(
              chartData.datasets[tooltipItems.datasetIndex].data[
                tooltipItems.index
              ] * 100
            )}%`,
        },
      },
      hover: {
        onHover: (e) => {
          e.target.style.cursor = 'pointer';
        },
      },
    };

    return (
      chartData && (
        <div>
          <Header
            size="small"
            textAlign="center"
            style={{ margin: '0.4rem 0rem 1.2rem 8rem' }}
          >
            Holdings by Asset Class&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              content="This is your net asset allocation, reflecting any balanced funds/asset allocation funds that you may have."
              wide
            />
          </Header>
          {
            // eslint-disable-next-line no-restricted-globals
            !isNaN(chartData.datasets[0].data[0]) ? (
              <div>
                <Pie data={chartData} options={config} />
              </div>
            ) : (
              <div
                style={{
                  margin: '5rem 0rem 1.2rem 8rem',
                  textAlign: 'center',
                  opacity: '0.8',
                }}
              >
                There are no holdings for this client yet.
              </div>
            )
          }
        </div>
      )
    );
  }
}

Charts.propTypes = {
  dispatch: PropTypes.func,
  accountList: PropTypes.array,
};

Charts.defaultProps = {
  dispatch: () => {},
  accountList: [],
};

export default withRouter(
  connect((state) => ({
    accountList: state.Accounts.accountList,
  }))(Charts)
);
