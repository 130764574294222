import { CorrelationMatrix } from 'actions';

const initialState = {
  isFetching: false,
  data: null,
  inputs: {
    securityList: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CorrelationMatrix.CORRELATION_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CorrelationMatrix.CORRELATION_DATA_FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        errorEntity: '',
      };
    case CorrelationMatrix.CORRELATION_UPDATE_INPUTS:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          ...action.inputs,
        },
      };
    case CorrelationMatrix.CORRELATION_PARTIAL_FAIL:
      return {
        ...state,
        isFetching: false,
        errorEntity: action.data.error_entity,
      };
    case CorrelationMatrix.CORRELATION_DATA_CLEAR:
      return initialState;
    case CorrelationMatrix.CORRELATION_FAIL:
      return initialState;
    default:
      return state;
  }
}
