import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CacheInputs } from 'actions';
import { NotFound } from 'components';
import { isGlobeInvestor } from 'utils/helpers';
import Drawdown from './Drawdown';
import Drawdown2 from './Drawdown2';
import DrawdownPDF from './Drawdown2/PDF';
import NewDrawdown from './NewDrawdown2';
import NewDrawdownPDF from './NewDrawdown2/PDF';
import DrawdownCouples from './DrawdownCouples';
import DrawdownCouplesPDF from './DrawdownCouples/PDF';
import SavedDrawdownPlans from './Drawdown/SavedDrawdownPlans';
import Longevity from './Longevity';
import Saving from './Saving';
import Wealth from './Wealth';
import Blueprint from './Blueprint';
import Landing from './Landing';
import Accumulation from './Accumulation';
import Decumulation from './Decumulation';
import SavedBlueprints from './Blueprint/SavedBlueprints';
import RuinProbability from './RuinProbability';

class Planning extends Component {
  componentWillUnmount = () => {
    const { dispatch, location } = this.props;

    return dispatch(CacheInputs.captureUrl('planning', location.pathname));
  };

  render() {
    const { partnerName } = this.props;
    return (
      <div style={{ height: '90%', position: 'relative' }}>
        <Switch>
          <Route exact path="/planning/landing" component={Landing} />
          <Route exact path="/planning/accumulation" component={Accumulation} />
          <Route exact path="/planning/decumulation" component={Decumulation} />
          <Route exact path="/planning/saving" component={Saving} />
          <Route exact path="/planning/wealth" component={Wealth} />
          <Route exact path="/planning/longevity" component={Longevity} />
          <Route
            exact
            path="/planning/ruin-probability"
            component={RuinProbability}
          />
          <Route
            exact
            path="/planning/saved-blueprints"
            component={SavedBlueprints}
          />
          <Route path="/planning/blueprint/:id" component={Blueprint} />
          <Route
            exact
            path="/planning/drawdown/:id"
            component={isGlobeInvestor(partnerName) ? Drawdown : Drawdown2}
          />
          <Route
            exact
            path="/planning/drawdown-couples/:id"
            component={DrawdownCouples}
          />
          <Route
            exact
            path="/planning/drawdown/:id/pdf"
            component={DrawdownPDF}
          />
          <Route
            exact
            path="/planning/drawdown-couples/:id/pdf"
            component={DrawdownCouplesPDF}
          />
          <Route
            exact
            path="/planning/drawdown-target-spending/:id"
            component={NewDrawdown}
          />
          <Route
            exact
            path="/planning/drawdown-target-spending/:id/pdf"
            component={NewDrawdownPDF}
          />
          <Route
            path="/planning/drawdown-plans"
            component={SavedDrawdownPlans}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

Planning.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  partnerName: PropTypes.string.isRequired,
};

export default connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))(Planning);
