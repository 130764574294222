import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { Table, TableWrapper } from './StyledComponents.js';

const parseNum = (num) =>
  !num || num < 0
    ? '-'
    : `$${num.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`;

const TaxTable = ({ data, t, spouse, spouse2 }) => {
  const currentResult = data.result.combined_results[t];
  if (!currentResult) {
    return <div>No data available for the selected year.</div>;
  }

  const totalTaxBill = parseNum(currentResult.total_taxes_before);

  const pensionIncomePerson1 = parseNum(
    currentResult.person1.incomes_before.pension_income
  );
  const pensionIncomePerson2 = parseNum(
    currentResult.person2.incomes_before.pension_income
  );

  const totalTaxBillAfter = parseNum(currentResult.total_taxes_after);

  const pensionIncomeTransferOut = parseNum(
    currentResult.person1.transfers.transfer
  );
  const pensionIncomeTransferIn = parseNum(
    currentResult.person2.transfers.transfer
  );

  const spousalCreditPerson1 = parseNum(
    currentResult.person1.transfers.fed_spouse_credit +
      currentResult.person1.transfers.prov_spouse_credit
  );
  const spousalCreditPerson2 = parseNum(
    currentResult.person2.transfers.fed_spouse_credit +
      currentResult.person2.transfers.prov_spouse_credit
  );

  const transferCreditPerson1 = parseNum(
    currentResult.person1.transfers.fed_transferred_credit +
      currentResult.person1.transfers.prov_transferred_credit
  );
  const transferCreditPerson2 = parseNum(
    currentResult.person2.transfers.fed_transferred_credit +
      currentResult.person2.transfers.prov_transferred_credit
  );

  function getAfterTaxSpending() {
    const yearsPeter = Object.keys(data.spouse.yearlyDetails)
      .map((year) => parseInt(year))
      .sort((a, b) => a - b);
    const yearsMary = Object.keys(data.spouse2.yearlyDetails)
      .map((year) => parseInt(year))
      .sort((a, b) => a - b);

    // Calculate the actual year based on the offset
    const actualYearPeter = yearsPeter[t];
    const actualYearMary = yearsMary[t];

    // Access the wdRegular for the actual year for both Peter and Mary
    const afterTaxSpendingPeter = parseNum(
      data.spouse.yearlyDetails[actualYearPeter].incNet
    );
    const afterTaxSpendingMary = parseNum(
      data.spouse2.yearlyDetails[actualYearMary].incNet
    );

    return {
      afterTaxSpendingPeter,
      afterTaxSpendingMary,
    };
  }

  const { afterTaxSpendingPeter, afterTaxSpendingMary } =
    getAfterTaxSpending(t);

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <th />
            <th className="peter">{spouse}</th>
            <th className="mary">{spouse2}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="filing-together">
            <td className="section-header">Filing Separately</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total tax bill</td>
            <td colSpan="2">{totalTaxBill}</td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After-tax spending</td>
            <td>{`${afterTaxSpendingPeter}`}</td>
            <td>{`${afterTaxSpendingMary}`}</td>
          </tr>
          <tr>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eligible pension income{' '}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      color: 'dimgrey',
                    }}
                  />
                }
                content="RRIF and employer pension"
                position="center right"
              />
            </td>
            <td>{pensionIncomePerson1}</td>
            <td>{pensionIncomePerson2}</td>
          </tr>
          <tr className="filing-together">
            <td className="section-header filing-together">
              Filing as a Couple
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total tax bill</td>
            <td colSpan="2">{totalTaxBillAfter}</td>
          </tr>
          <tr>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Amount transfer from: </b>
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      color: 'dimgrey',
                    }}
                  />
                }
                content="A positive amount indicates which spouse/partner is making the transfer."
                position="center right"
              />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pension
              income{' '}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      color: 'dimgrey',
                    }}
                  />
                }
                position="center right"
              >
                <Popup.Content>
                  <b>T1032 Joint Election to Split Pension Income.</b> Complete
                  this form if you are electing to split your eligible pension
                  income with your spouse or common-law partner.
                </Popup.Content>
              </Popup>
            </td>
            <td>{pensionIncomeTransferOut}</td>
            <td>{pensionIncomeTransferIn}</td>
          </tr>
          <tr>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Spousal
              credit
            </td>
            <td>{spousalCreditPerson1}</td>
            <td>{spousalCreditPerson2}</td>
          </tr>
          <tr>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Transfer
              credit{' '}
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      color: 'dimgrey',
                    }}
                  />
                }
                content="Unused age and/or pension credits."
                position="center right"
              />
            </td>
            <td>{transferCreditPerson1}</td>
            <td>{transferCreditPerson2}</td>
          </tr>
        </tbody>
      </Table>
    </TableWrapper>
  );
};

TaxTable.propTypes = {
  data: PropTypes.shape({
    filingSeparately: PropTypes.shape({
      totalTaxBill: PropTypes.shape({
        Peter: PropTypes.string,
        Mary: PropTypes.string,
      }),
      afterTaxSpending: PropTypes.shape({
        Peter: PropTypes.string,
        Mary: PropTypes.string,
      }),
      pensionIncome: PropTypes.shape({
        Peter: PropTypes.string,
        Mary: PropTypes.string,
      }),
    }),
    filingTogether: PropTypes.shape({
      totalTaxBill: PropTypes.string,
      pensionIncomeTransfer: PropTypes.shape({
        Peter: PropTypes.string,
        Mary: PropTypes.string,
      }),
      spousalCredit: PropTypes.shape({
        Peter: PropTypes.string,
        Mary: PropTypes.string,
      }),
      transferCredit: PropTypes.shape({
        Peter: PropTypes.string,
        Mary: PropTypes.string,
      }),
    }),
  }),
};

TaxTable.defaultProps = {
  data: {
    filingSeparately: {
      totalTaxBill: { Peter: '$1272', Mary: '$300' },
      afterTaxSpending: { Peter: '$55903', Mary: '$38343' },
      pensionIncome: { Peter: '$7642', Mary: '$11010' },
    },
    filingTogether: {
      totalTaxBill: '$750',
      pensionIncomeTransfer: { Peter: '$4766', Mary: '($4766)' },
      spousalCredit: { Peter: '$0', Mary: '($0)' },
      transferCredit: { Peter: '($0)', Mary: '$0' },
    },
  },
};

export default TaxTable;
