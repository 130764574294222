import { Api } from 'actions';

const initialState = {
  isFetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Api.API_REQUEST:
      return { ...state, isFetching: true };
    case Api.API_SUCCESS:
    case Api.API_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
