import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Popup, Grid, Header } from 'semantic-ui-react';

import { Message } from 'components';
import { ExposureTable, ExposureChart } from '../components';

export default class Region extends Component {
  renderSubheader = (type) => (
    <div className="sub header">
      {type === 'domicile'
        ? 'By Region of Domicile'
        : 'To Regional Stock Markets'}
      &nbsp;
      <Popup
        trigger={
          <Icon
            name="question circle outline"
            style={{
              fontSize: '15px',
              verticalAlign: 'initial',
              color: 'dimgrey',
            }}
          />
        }
        position="top center"
        wide
        content={
          type === 'domicile'
            ? 'Including stocks and bonds in your portfolio'
            : 'Using statistical analysis, we estimate the exposure of your portfolio to fluctuations in different regional stock markets. If you have a predominantly bonds portfolio, the numbers may be very different from those under holdings breakdown.'
        }
      />
    </div>
  );

  render() {
    const { data } = this.props.analytics;

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content="Holdings and Risk Exposure Analysis"
          subheader="By Region"
        />

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content="Holdings Breakdown"
                subheader={this.renderSubheader('domicile')}
                style={{ margin: 0 }}
              />
              {typeof data.domicile === 'object' ? (
                <ExposureChart
                  title=""
                  labels={Object.keys(data.domicile)}
                  data={Object.values(data.domicile)}
                  frontierMarkets
                />
              ) : (
                <Message visible warning>
                  {data.domicile}
                </Message>
              )}
            </Grid.Column>
            <Grid.Column>
              <Header
                size="medium"
                content="Risk Exposure"
                subheader={this.renderSubheader('market')}
                style={{ margin: 0 }}
              />
              {typeof data.riskExp.C === 'object' ? (
                <ExposureChart
                  title=""
                  labels={Object.keys(data.riskExp.C)}
                  data={Object.values(data.riskExp.C)}
                  frontierMarkets
                />
              ) : (
                <Message visible warning>
                  {data.riskExp.C}
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {typeof data.riskExp.C === 'string' &&
        typeof data.domicile === 'string' ? null : (
          <ExposureTable
            headers={[
              'Region',
              <div>
                Holdings Breakdown
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content="Including stocks and bonds in your portfolio"
                />
              </div>,
              <div>
                Risk Exposure
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content="Using statistical analysis, we estimate the exposure of your portfolio to fluctuations in different regional stock markets. If you have a predominantly bonds portfolio, the numbers may be very different from those under holdings breakdown."
                />
              </div>,
            ]}
            totals={['Total', <div>100%</div>, <div>100%</div>]}
            data={[...Object.keys(data.domicile), 'Port. Specific Risk'].reduce(
              (total, key) => {
                if (key === 'Other') {
                  key = 'Frontier Markets';
                }
                total.push({
                  region: key,
                  holdingsBreakdown: data.domicile[key],
                  riskExposure: data.riskExp ? data.riskExp.C[key] : null,
                });

                return total;
              },
              []
            )}
          />
        )}
      </Container>
    );
  }
}

Region.propTypes = {
  analytics: PropTypes.object,
};

Region.defaultProps = {
  analytics: {},
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
