/**
 * Action constants
 */
export const CACHEINPUTS_CAPTURE_URL = 'CACHEINPUTS_CAPTURE_URL';
export const CACHEINPUTS_UPDATE_FIELD = 'CACHEINPUTS_UPDATE_FIELD';
export const CACHEINPUTS_CLEAR_FIELD = 'CACHEINPUTS_CLEAR_FIELD';
export const CACHEINPUTS_CLEAR_ALL = 'CACHEINPUTS_CLEAR_ALL';

export const CACHEINPUTS_PLANNING_TARGETSAVING =
  'CACHEINPUTS_PLANNING_TARGETSAVING';
export const CACHEINPUTS_CLEAR_PLANNING_TARGETSAVING =
  'CACHEINPUTS_CLEAR_PLANNING_TARGETSAVING';

/**
 * Action creators
 */
export function captureUrl(module, url) {
  return (dispatch) =>
    dispatch({
      type: CACHEINPUTS_CAPTURE_URL,
      module,
      url,
    });
}

export function updateField(module, feature, value) {
  return (dispatch) =>
    dispatch({
      type: CACHEINPUTS_UPDATE_FIELD,
      module,
      feature,
      value,
    });
}

export function clearField(module, feature) {
  return (dispatch) =>
    dispatch({
      type: CACHEINPUTS_CLEAR_FIELD,
      module,
      feature,
    });
}

export function clearAll() {
  return (dispatch) => dispatch({ type: CACHEINPUTS_CLEAR_ALL });
}
