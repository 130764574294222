import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Label, Header, Form } from 'semantic-ui-react';
import cn from 'classnames';

import { Storage, CacheInputs } from 'actions';
import { colors } from 'utils/colors';
import { ErrorLabel, Button, Segment } from 'components';

class ForeignMarkets extends Component {
  componentWillMount = () => {
    const { user_region, dispatch } = this.props;
    dispatch(Storage.setItem('toggle_region', user_region)); // Set toggle_region to user_region to avoid interference with Accounts
  };
  toggle = (e, { name }) => {
    const { updateSelection, foreign } = this.props;
    const { isForeign, markets } = foreign;

    if (name === 'foreign') {
      return updateSelection({
        foreign: {
          isForeign: !isForeign,
          markets: { us: false, dev: false, emer: false },
          error: null,
        },
      });
    }

    return updateSelection({
      foreign: {
        isForeign: false,
        markets: { ...markets, [name]: !markets[name] },
        error: null,
      },
    });
  };

  handleSubmit = () => {
    const { dispatch, history, pData, selection, smartRiskBuilder } =
      this.props;
    const filterWeights = (string) =>
      string
        .split(',')
        .map((item) => item / 100)
        .join(',');

    if (pData[this.handleMatch()].total === 0) {
      return dispatch(
        CacheInputs.updateField('portfolios', 'smart_risk_builder', {
          ...smartRiskBuilder,
          error: (
            <span>
              Sorry, we are not able to find a portfolio that matches your
              criteria. <br />
              Please change your selection.
            </span>
          ),
        })
      );
    }

    dispatch(
      Storage.setItem('pwpa-portfolio', {
        region: 'CA',
        symbols: pData[this.handleMatch()].portfolio.symbols,
        weights: filterWeights(pData[this.handleMatch()].portfolio.weights),
        // end-date is required to match specific portfolio risk
      })
    );
    dispatch(
      Storage.setItem('pwpa-objective', {
        ...selection,
        foreign: [this.handleMatch(), pData[this.handleMatch()].total],
      })
    );
    return history.push('/portfolio/unsaved/overview', {
      ...selection,
      foreign: [this.handleMatch(), pData[this.handleMatch()].total],
    });
  };

  handleMatch = () => {
    const { foreign } = this.props;
    const { isForeign, markets } = foreign;
    const keys = Object.keys(markets).filter((key) => markets[key]);

    if (keys.length === 1) {
      return keys[0];
    }
    if (keys.length === 3) {
      return 'usDevEmer';
    }
    if (keys.indexOf('dev') !== -1 && keys.indexOf('emer') !== -1) {
      return 'devEmer';
    }
    if (keys.indexOf('dev') !== -1 && keys.indexOf('us') !== -1) {
      return 'usDev';
    }
    if (keys.indexOf('us') !== -1 && keys.indexOf('emer') !== -1) {
      return 'usEmer';
    }
    if (isForeign) {
      return 'no';
    }

    return false;
  };

  render() {
    const { previousTab, pData, foreign } = this.props;
    const { isForeign, error, markets } = foreign;
    const { us, dev, emer } = markets;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Header
          size="medium"
          textAlign="center"
          content="Do you want exposure to foreign markets?"
        />
        <Segment basic compact textAlign="center">
          <Form.Group
            grouped
            style={{ display: 'inline-block', textAlign: 'left' }}
          >
            <Form.Checkbox
              toggle
              name="foreign"
              label="No (exposure to Canadian stock markets only)"
              onChange={this.toggle}
              checked={isForeign}
            />
          </Form.Group>
        </Segment>
        <Segment
          basic
          compact
          textAlign="center"
          style={{ marginBottom: '3rem' }}
        >
          <Header
            as={Header}
            size="medium"
            content="Or select all that apply: "
          />
          <Form.Group
            grouped
            className="attached"
            style={{ display: 'inline-block', textAlign: 'left' }}
          >
            <Form.Checkbox
              toggle
              name="us"
              label="U.S"
              onChange={this.toggle}
              checked={us}
            />
            <Form.Checkbox
              toggle
              name="dev"
              label="Developed Markets"
              onChange={this.toggle}
              checked={dev}
            />
            <Form.Checkbox
              toggle
              name="emer"
              label="Emerging Markets"
              onChange={this.toggle}
              checked={emer}
            />
          </Form.Group>
          <Label
            basic
            color="blue"
            size="large"
            attached="bottom"
            style={{
              width: '60%',
              left: '20%',
              bottom: '-6.4rem',
              border: 0,
              lineHeight: '1.4rem',
            }}
          >
            <div style={{ position: 'relative', fontSize: '1.5rem' }}>
              {this.handleMatch()
                ? pData[this.handleMatch()].total.toLocaleString()
                : pData.total.toLocaleString()}
              <ErrorLabel
                className="top"
                msg={error}
                style={{ top: '-55px' }}
              />
            </div>
            matching portfolios
          </Label>
        </Segment>
        <Button
          className="left"
          color={colors.orange}
          icon="chevron left"
          onClick={previousTab}
        >
          Back
        </Button>
        <Button
          className={cn('right', { disabled: !this.handleMatch() })}
          color={colors.orange}
          icon="chevron right"
          floated="right"
        >
          View Matched Portfolio
        </Button>
      </Form>
    );
  }
}

ForeignMarkets.propTypes = {
  history: PropTypes.object.isRequired,
  pData: PropTypes.object.isRequired,
  previousTab: PropTypes.func.isRequired,
  selection: PropTypes.object,
  smartRiskBuilder: PropTypes.object.isRequired,
  updateSelection: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  foreign: PropTypes.object,
  user_region: PropTypes.string,
};

ForeignMarkets.defaultProps = {
  selection: {},
  dispatch: () => false,
  foreign: {},
  user_region: 'CA',
};

export default withRouter(
  connect((state) => ({
    smartRiskBuilder: state.CacheInputs.portfolios.smart_risk_builder,
    user_region: state.Storage.user_region || 'CA',
  }))(ForeignMarkets)
);
