import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Header } from 'semantic-ui-react';

import { colors } from 'utils/colors';

export default class InvestmentIncomeBar extends Component {
  getChartData = (data) => {
    const validKeys = Object.keys(data).filter((key) => Number(key));

    return {
      labels: validKeys,
      datasets: [
        {
          label: 'Dividends',
          stack: 'Dataset 1',
          backgroundColor: colors.blue,
          data: validKeys.map((key) => data[key].incDividend),
        },
        {
          label: 'RRIF',
          stack: 'Dataset 1',
          backgroundColor: colors.orange,
          data: validKeys.map((key) => data[key].wdReqMin + data[key].wdRRIF),
        },
        {
          label: 'Non-registered',
          stack: 'Dataset 1',
          backgroundColor: colors.red,
          data: validKeys.map((key) => data[key].wdRegular),
        },
        {
          label: 'TFSA',
          stack: 'Dataset 1',
          backgroundColor: colors.darkBlue,
          data: validKeys.map((key) => data[key].wdTFSA),
        },
      ],
    };
  };

  render() {
    const { data, size } = this.props;

    return (
      <div style={{ maxWidth: size, width: size, margin: '2rem auto' }}>
        <Bar data={this.getChartData(data)} options={barChartOptions} />
      </div>
    );
  }
}

InvestmentIncomeBar.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
};

const barChartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItems, dataItem) => {
        const { index, datasetIndex } = tooltipItems;
        return `${dataItem.datasets[datasetIndex].label}: $${dataItem.datasets[
          datasetIndex
        ].data[index].toLocaleString()}`;
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: (value) => `$${value.toLocaleString()}`,
        },
      },
    ],
  },
};
