import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header, Form, Label } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { RiskModal, Button } from 'components';
import PortfolioList from './PortfolioList';

class RiskProfile extends Component {
  handleChange = (e, { value }) =>
    this.props.updateSelection({ risk: [value] });

  handleSubmit = () => {
    const { moveToNextSection, pData, risk } = this.props;

    return moveToNextSection({ risk: [risk, pData[risk].total] }, 1);
  };

  render() {
    const { pData, risk } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} style={{ textAlign: 'center' }}>
        <Header size="medium" textAlign="center">
          What would you consider your risk profile to be?
          <Header.Subheader>
            Not sure about your current risk profile? Try our{' '}
            <RiskModal target="Risk Profile Questionnaire" />.
          </Header.Subheader>
        </Header>
        <PortfolioList handlePortfolioChange={this.handleChange} risk={risk} />
        <Label
          basic
          color="blue"
          size="large"
          style={{
            width: '100%',
            border: 0,
            lineHeight: '1.4rem',
            position: 'relative',
          }}
        >
          <div style={{ fontSize: '1.5rem' }}>
            {risk ? pData[risk].total.toLocaleString() : '23,932,363'}
          </div>
          matching portfolios
        </Label>
        <Button
          className={cn('right', { disabled: !risk })}
          color={colors.orange}
          icon="chevron right"
          style={{ position: 'absolute', bottom: '5px', right: '0' }}
        >
          Next
        </Button>
      </Form>
    );
  }
}

RiskProfile.propTypes = {
  pData: PropTypes.object.isRequired,
  updateSelection: PropTypes.func.isRequired,
  moveToNextSection: PropTypes.func.isRequired,
  risk: PropTypes.string,
};

RiskProfile.defaultProps = {
  risk: null,
};

export default connect()(RiskProfile);
