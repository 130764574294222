import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, Dropdown, Icon, Button } from 'semantic-ui-react';
import { Radar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { Analytics, Portfolio, Storage, CacheInputs } from 'actions';
import { colors } from 'utils/colors';
import {
  getOverAllScore,
  getOverAllGrade,
  isGlobeInvestor,
} from 'utils/helpers';
import {
  CustomBenchmarkDashboard,
  Score,
  SelectionDashboard,
} from 'components';
import Holdings from './Holdings';
import PortfolioHistory from './PortfolioHistory';
import ObjectiveSummary from './ObjectiveSummary';
import Execute from '../Execute';

@connect((state) => ({
  toggle_region: state.Storage.toggle_region || 'CA',
  user_region: state.Storage.user_region || 'CA',
  checkHistory:
    state.Storage['pwpa-check-history'] === undefined
      ? true
      : state.Storage['pwpa-check-history'],
  partnerName: state.Storage['partner-name'],
  globe_destination: state.Storage.globe_destination,
  pwpaCheckHistory: state.Storage['pwpa-check-history'],
  smartRiskBuilder: state.CacheInputs.portfolios.smart_risk_builder,
  pwpaObjective: state.Storage['pwpa-objective'],
}))
export default class Overview extends Component {
  getRadarData = (score) => {
    const { analytics, esg } = this.props;
    const { benchmark, esgToggle, income } = analytics;
    const { portfolio } = esg;
    const hash = () => {
      if (income) {
        return {
          Diversification: 'Diversification',
          Fees: 'Low Fees',
          Income: 'Income',
          Performance: 'Performance',
        };
      }
      return {
        Diversification: 'Diversification',
        Fees: 'Low Fees',
        Performance: 'Performance',
      };
    };

    const data = Object.keys(hash()).reduce((total, key) => {
      total[hash()[key]] =
        key === ('Performance' || 'Volatility')
          ? score[key][benchmark][0]
          : score[key][0];

      return total;
    }, {});

    return {
      labels: esgToggle
        ? ['Downside Protection', ...Object.keys(data), 'ESG']
        : ['Downside Protection', ...Object.keys(data)],
      datasets: [
        {
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data:
            portfolio && portfolio.ws_grade[0] && esgToggle
              ? [
                  score.Volatility[benchmark][0],
                  ...Object.values(data),
                  portfolio.ws_grade[0],
                ]
              : [score.Volatility[benchmark][0], ...Object.values(data)],
        },
      ],
    };
  };

  getClass = (row) => {
    if (row.isExcluded) {
      return 'negative';
    }

    return Object.keys(row.replacedBy).length ? 'warning' : '';
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  changeIncome = (income) => {
    const { dispatch } = this.props;

    return dispatch(Analytics.updateParameter('income', income));
  };

  changeCheckHistory = (newSelcheckHistory) => {
    const {
      analytics,
      history,
      location,
      dispatch,
      match,
      check_history,
      pwpaPortfolio,
    } = this.props;
    const { data, selectedAssets, benchmark } = analytics;
    const { portfolio, paramsAnalyzed } = data;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );
    const customBenchmark = {
      symbols: Object.keys(selectedAssets).join(','),
      weights: benchmarkWeights.join(','),
    };

    if (newSelcheckHistory === !check_history) {
      return false;
    }

    // dispatch(ComparePortfolio.clearComparison());
    dispatch(Storage.setItem('pwpa-check-history', !newSelcheckHistory));
    const rebalanceInterval = paramsAnalyzed.rebalance_interval || 1;

    if (portfolio && portfolio.name) {
      dispatch(
        Portfolio.setEditing(
          portfolio.id,
          portfolio.name,
          portfolio.description
        )
      );
    }

    if (Number(match.params.id)) {
      if (benchmark === 'custom') {
        dispatch(
          Analytics.fetchId(
            match.params.id,
            check_history,
            customBenchmark,
            this.decideRegion(),
            rebalanceInterval
          )
        );
      } else {
        dispatch(
          Analytics.fetchId(
            match.params.id,
            check_history,
            null,
            this.decideRegion(),
            rebalanceInterval
          )
        );
      }
    }

    if (pwpaPortfolio) {
      const updatedPortfolio = {
        ...pwpaPortfolio,
        ...(benchmark === 'custom' && { custom_bm: customBenchmark }),
      };

      dispatch(
        Analytics.fetchParams(
          updatedPortfolio,
          location.state,
          check_history,
          this.decideRegion(),
          rebalanceInterval
        )
      );
    }

    return history.push('/portfolio/unsaved/overview');
  };

  changeRebalancingFrequency = (e, { value }) => {
    const { analytics, location, dispatch, pwpaCheckHistory, pwpaPortfolio } =
      this.props;
    const { data, selectedAssets, benchmark } = analytics;
    const { portfolio, paramsAnalyzed } = data;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );
    const checkHistory =
      pwpaCheckHistory === null || pwpaCheckHistory === undefined
        ? null
        : !pwpaCheckHistory;
    const customBenchmark = {
      symbols: Object.keys(selectedAssets).join(','),
      weights: benchmarkWeights.join(','),
    };
    const rebalancingFrequency = paramsAnalyzed.rebalance_interval || 1;
    if (Number(value) === Number(rebalancingFrequency)) return false;
    dispatch(Analytics.updateParameter('rebalance_interval', value));
    if (portfolio && portfolio.name) {
      dispatch(
        Portfolio.setEditing(
          portfolio.id,
          portfolio.name,
          portfolio.description
        )
      );
    }

    if (pwpaPortfolio) {
      const updatedPortfolio = {
        ...pwpaPortfolio,
        ...(benchmark === 'custom' && { custom_bm: customBenchmark }),
      };
      dispatch(
        Analytics.fetchParams(
          updatedPortfolio,
          location.state,
          checkHistory,
          this.decideRegion(),
          value
        )
      );
    }
  };

  decideRegion = () => {
    const { user_region, toggle_region } = this.props;
    if (user_region === 'US') {
      return 'US';
    }
    return toggle_region || 'CA';
  };
  replaceCountry = (data, userRegion) => {
    if (userRegion === 'US') return 'US';
    const { paramsPassed } = data;
    if (paramsPassed.region === 'US') return 'US';
    return 'Canada';
  };

  replaceCountry = (data, userRegion) => {
    if (userRegion === 'US') return 'US';

    const { paramsPassed } = data;

    if (paramsPassed.region === 'US') return 'US';

    return 'Canada';
  };

  changeEsgToggle = (esgToggle) => {
    const { analytics, dispatch, esg } = this.props;
    const { data } = analytics;

    if (!Object.keys(esg).length) {
      return dispatch(Analytics.fetchEsg(data.portfolio, true, esgToggle));
    } else if (esg.portfolio.esg_score === null) {
      return dispatch(Analytics.setEsgAlert());
    }

    return dispatch(Analytics.updateParameter('esgToggle', esgToggle));
  };

  handleReset = () => {
    const { dispatch } = this.props;
    return dispatch(CacheInputs.clearAll());
  };

  render() {
    const {
      analytics,
      history,
      match,
      dispatch,
      initHasExcludedHolding,
      check_history,
      esg,
      esgAlert,
      user_region,
      is_esg_enabled,
      checkHistory,
      partnerName,
      globe_destination,
      pwpaObjective,
    } = this.props;
    const { income, benchmark, esgToggle, data, portfolioHistoryCompareto } =
      analytics;
    const { paramsPassed, paramsAnalyzed, percentFI } = data;
    const sortedHoldings = data.tickersOriginal.sort(
      (a, b) => b.weight - a.weight
    );
    const hasExcludedHolding = sortedHoldings.find(
      (holding) => holding.isExcluded
    );
    const rebalancingFrequency = paramsAnalyzed.rebalance_interval || 1;
    return (
      <div>
        <Button
          onClick={() => this.handleReset()}
          style={{
            backgroundColor: `${colors.blue}`,
            color: 'white',
            position: 'absolute',
            top: '10rem',
            right: '6rem',
            cursor: 'pointer',
            zIndex: '9999',
          }}
        >
          Start Over
        </Button>

        {pwpaObjective && !!Object.keys(pwpaObjective).length && (
          <div style={{ display: 'flex' }}>
            <ObjectiveSummary
              data={pwpaObjective}
              ratios={data.fratios.portfolio}
            />
          </div>
        )}
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {initHasExcludedHolding ||
            (this.isLinked(data) && hasExcludedHolding) ||
            (this.isLinked(data) &&
              !initHasExcludedHolding &&
              checkHistory === false) ? (
              <StyledCard style={{ minWidth: '210px' }}>
                <SelectionDashboard
                  text="Include short-history holdings"
                  popuptext="Including short-history holdings in the analysis will shorten the evaluation period. Note that holdings with history less than a month will still be excluded."
                  selected={!checkHistory}
                  changeSelection={this.changeCheckHistory}
                  style={{ fontSize: '1.15rem' }}
                  popupBottom
                />
              </StyledCard>
            ) : null}
            <StyledCard>
              <SelectionDashboard
                text="Include income score"
                popuptext="The default includes the income score in the calculation of the overall score. If you are investing for the long term and/or do not need income (dividends and other distributions) from your investment portfolio, choose NO."
                selected={income}
                changeSelection={this.changeIncome}
                style={{ fontSize: '1.15rem' }}
              />
            </StyledCard>
            {is_esg_enabled ? (
              <StyledCard>
                <SelectionDashboard
                  text="Include ESG score"
                  popuptext="Check the ESG tab above to see if most of your holdings have ESG rating coverage first."
                  selected={esgToggle}
                  changeSelection={this.changeEsgToggle}
                  style={{ fontSize: '1.15rem' }}
                />
              </StyledCard>
            ) : null}{' '}
            {percentFI !== 1 && (
              <StyledCard style={{ minWidth: '190px' }}>
                <CustomBenchmarkDashboard
                  benchmark={benchmark}
                  feature="scorecard"
                  replaceDomestic={
                    (user_region || 'CA') === 'CA'
                      ? paramsPassed
                        ? paramsPassed.region
                        : null
                      : null
                  }
                />
              </StyledCard>
            )}
            <StyledCard style={{ minWidth: '180px' }}>
              <p
                style={{
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginBottom: '1em',
                }}
              >
                Rebalancing Frequency
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                        marginRight: '1rem',
                      }}
                    />
                  }
                  position="top left"
                  wide
                >
                  Rebalancing refers to the process of adjusting the holding
                  weights back to the desired allocation. As investments grow or
                  decline in value, their weights in the portfolio may deviate
                  from their targets. Rebalancing will bring the portfolio back
                  in line with its intended risk and return profile.
                </Popup>
              </p>
              <OptionsWrapper>
                <StyledDropdown
                  selection
                  placeholder="Monthly"
                  options={[
                    {
                      key: 'mon',
                      value: '1',
                      text: 'Monthly',
                    },
                    { key: 'qua', value: '3', text: 'Quarterly' },
                    { key: 'ann', value: '12', text: 'Annually' },
                  ]}
                  text={
                    rebalancingFrequency === 1
                      ? 'Monthly'
                      : rebalancingFrequency === 3
                      ? 'Quarterly'
                      : 'Annually'
                  }
                  onChange={this.changeRebalancingFrequency}
                />
              </OptionsWrapper>
            </StyledCard>
          </div>
        </div>
        <Container margin="2rem 3rem 2rem 19rem">
          <StyledChart>
            <Score
              score={getOverAllScore({
                income,
                benchmark,
                esgToggle,
                data,
                esg,
              })}
              grade={getOverAllGrade({
                income,
                benchmark,
                esgToggle,
                data,
                esg,
              })}
              description={[
                <div
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  {' '}
                  <p
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    Your overall portfolio score is the average of the component
                    scores below. Each component score is out of 100% for the
                    specific time period in question. A higher score always
                    means a more desireable characteristic. <br /> <br />{' '}
                    <a
                      href="https://www.wealthscope.ca/whyscores"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: '1rem',
                      }}
                    >
                      Learn more here.
                    </a>
                  </p>{' '}
                </div>,
              ]}
              portfolio={data.portfolio}
              boldDate={!check_history}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height:
                  hasExcludedHolding && sortedHoldings.length > 8
                    ? '430px'
                    : '350px',
              }}
            >
              <Radar
                id="radarChart"
                data={this.getRadarData(data.score)}
                options={chartOptions}
              />
            </div>

            <StyledAlert active={esgAlert}>
              <AlertHeader>Warning</AlertHeader>
              <AlertMsg>
                Sorry, ESG scores are not available for the holdings in this
                portfolio.
              </AlertMsg>
            </StyledAlert>
          </StyledChart>

          <Holdings
            portfolio={data.portfolio}
            holdings={sortedHoldings}
            hasExcludedHolding={!!hasExcludedHolding}
            history={history}
            match={match}
          />

          {!isGlobeInvestor(partnerName) && (
            <PortfolioHistory
              dispatch={dispatch}
              benchmark={benchmark}
              data={data}
              compare={portfolioHistoryCompareto}
            />
          )}
          {isGlobeInvestor(partnerName) &&
            (globe_destination === 'SmartRiskPortfolioBuilder' ||
              globe_destination === 'ETFPortfolioBuilder') && (
              <Execute
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                data={analytics.data}
                objective={analytics.objective}
                user_region={user_region}
              />
            )}
        </Container>
      </div>
    );
  }
}

Overview.propTypes = {
  analytics: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,

  location: PropTypes.object,
  check_history: PropTypes.bool,
  pwpaPortfolio: PropTypes.object,
  user_region: PropTypes.string.isRequired,
  esg: PropTypes.object,
  esgAlert: PropTypes.bool,
  initHasExcludedHolding: PropTypes.bool,
  toggle_region: PropTypes.string,
  is_esg_enabled: PropTypes.bool,
  checkHistory: PropTypes.bool,
  partnerName: PropTypes.string,
  globe_destination: PropTypes.string,
  pwpaCheckHistory: PropTypes.bool,
  pwpaObjective: PropTypes.object,
};

Overview.defaultProps = {
  history: {},
  match: {},
  location: {},
  check_history: true,
  pwpaPortfolio: {},
  esg: {},
  esgAlert: false,
  initHasExcludedHolding: false,
  toggle_region: 'CA',
  is_esg_enabled: false,
  checkHistory: null,
  partnerName: '',
  globe_destination: '',
  pwpaCheckHistory: null,
  pwpaObjective: {},
};

const chartOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 13 },
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
  layout: {
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
};

const StyledChart = styled.div`
  width: 48%;
  display: inline-block;
  float: right;
  position: relative;
`;

const Container = styled.div`
  min-height: 100%;
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
`;

const StyledAlert = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
  width: 500px;
  float: right;
  margin-top: 2rem;
  border-radius: 6px;
  padding: 1rem 3rem;
  text-align: center;
  background-color: #f8ffff;
  box-shadow: 0 0 0 1px #0e566c inset, 0 0 0 0 transparent;
`;

const AlertHeader = styled.div`
  color: #0e566c;
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`;

const AlertMsg = styled.div`
  opacity: 0.85;
  color: #0e566c;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-radius: 15px;
  background-color: #fff;
  ${
    '' /* box-shadow: 0 8px 15px 0 rgb(59 105 142 / 15%);
transition: box-shadow 0.2s ease-in-out; */
  }
  margin: 1rem 0.5rem 1rem 1rem;
`;
const StyledDropdown = styled(({ className, children, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 22px !important;
  min-width: 100px !important;
  min-height: 22px !important;
  padding: 1px 0 0 10px !important;
  font-size: 1rem;
  .icon {
    padding: 2px 0 0 0 !important;
  }
`;
const OptionsWrapper = styled.div`
  padding: 3px 0;
`;
