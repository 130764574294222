import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

import { graph } from 'utils/colors';

export default class AfterTaxPie extends Component {
  getChartData = (data) => ({
    labels: ['Spending', 'Taxes', 'OAS Clawback'],
    datasets: [
      {
        data: [data.incNet, data.taxBoth, data.taxOAS],
        backgroundColor: Object.values(graph).slice(0, 3),
      },
    ],
  });

  render() {
    const { data } = this.props;

    return <Pie data={this.getChartData(data)} options={pieChartOptions} />;
  }
}

AfterTaxPie.propTypes = {
  data: PropTypes.object.isRequired,
};

const pieChartOptions = {
  showAllTooltips: true,
  maintainAspectRatio: false,
  cutoutPercentage: 65,
  responsive: true,
  legend: {
    position: 'bottom',
  },
  layout: {
    padding: 0,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, dataItem) => {
        const { index, datasetIndex } = tooltipItems;
        return `${dataItem.labels[index]}: $${dataItem.datasets[
          datasetIndex
        ].data[index].toLocaleString()}`;
      },
    },
    displayColors: false,
  },
};
