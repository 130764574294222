import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';

import { Header, Segment, NeuCard } from 'components';
import { colors } from 'utils/colors';
import asset_3 from 'images/asset_3.png';
import jubilee from 'images/jubilee.png';
import favicon from 'images/favicon.png';
import twitternewlogo from 'images/twitternewlogo.svg';
import cn from 'classnames';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  wealthica_plan: state.Storage.wealthica_plan,
  french: state.Storage.language === 'fr',
}))
class Landing extends Component {
  socialMediaIcon = (name) => (
    <Icon className={cn(name)} style={{ color: colors.blue }} />
  );
  showLabel = (labelId) => {
    const label = document.getElementById(labelId);
    label.style.display = 'inline-block';
    setTimeout(() => {
      label.style.display = 'none';
    }, 3000);
  };

  render() {
    const { is_wealthica_trial, partnerName, wealthica_plan, french } =
      this.props;
    return (
      <Segment expand>
        <div>
          {this.props.partnerName === 'wealthica' ? (
            wealthica_plan === 'jubilee' ? (
              <a
                href="https://www.wealthscope.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={jubilee}
                  height="120"
                  width="120"
                  alt="Portfolio"
                  style={{ marginBottom: '1.5rem' }}
                />
              </a>
            ) : (
              <a
                href="https://www.wealthscope.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={favicon}
                  height="120"
                  width="120"
                  alt="Portfolio"
                  style={{ marginBottom: '1.5rem' }}
                />
              </a>
            )
          ) : (
            <img
              src={asset_3}
              height="120"
              width="120"
              alt="Portfolio"
              style={{ marginBottom: '1.5rem' }}
            />
          )}

          {this.props.partnerName === 'wealthica' ? (
            <div>
              <Header size="2rem" color="black" style={{ fontWeight: 'bold' }}>
                Welcome to Wealthscope
              </Header>
              <P>
                Congratulations! You now have access to a suite of powerful
                portfolio and planning tools at your fingertips.
              </P>
              <P>
                The book icon in the top right will take you to our Getting
                Started Guide. Explore different segments in the navigation bar,
                depending on your subscription level. To see your Account
                dashboard, click the tab below.
              </P>
            </div>
          ) : (
            <div>
              <Header size="2rem" color="black" style={{ fontWeight: 'bold' }}>
                {french
                  ? 'Bienvenue dans l’Analytique de Portefeuille'
                  : 'Welcome to Portfolio Analytics'}
              </Header>
              <P>
                {french
                  ? 'Vos comptes Banque Nationale Courtage direct sont automatiquement connectés à cette application. Vous pouvez ajouter manuellement vos comptes avec d’autres institutions financières afin de visualiser et d’analyser tous vos investissements de manière centralisée.'
                  : `Your ${
                      partnerName === 'nbdb_sandbox' || partnerName === 'nbdb'
                        ? 'National Bank Direct Brokerage'
                        : 'CI Direct Trading'
                    } accounts are linked automatically to this app. You can manually
                add your accounts from other financial institutions to view and
                analyze all your investments in one place.`}
              </P>

              <P>
                {french
                  ? 'Les comptes en USD seront convertis en CAD lorsque vous regroupez des comptes libellés en devises différentes.'
                  : 'USD accounts will be converted to CAD for the analysis when you group accounts in different currencies.'}
              </P>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <NeuCard
              iconName="list"
              title={french ? 'Comptes' : 'Accounts'}
              description={
                french
                  ? 'Analysez vos comptes de placement de Courtage direct de Banque Nationale, ainsi que tous les autres comptes saisis manuellement.'
                  : partnerName === 'wealthica'
                  ? 'Analyze your linked investment accounts from Wealthica and accounts you manually enter in the Wealthscope Power-Up.'
                  : `Analyze your ${
                      partnerName === 'nbdb_sandbox' || partnerName === 'nbdb'
                        ? 'National Bank Direct Brokerage'
                        : 'CI Direct Trading'
                    } investment accounts, as well as any manually entered ones`
              }
              color={colors.red}
              style={{ marginTop: '1.5rem' }}
              to="/accounts/user"
            />
          </div>

          {this.props.partnerName === 'wealthica' ? (
            <div>
              {is_wealthica_trial ? (
                <BottomP2>
                  For help with payments and refunds, please contact{' '}
                  <b>hello@wealthica.com</b>
                </BottomP2>
              ) : null}

              <SocialMediaList>
                <li>
                  <a
                    href="https://www.linkedin.com/company/wealthscope/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.socialMediaIcon('linkedin icon')}
                  </a>
                </li>
                <li>
                  <Label id="fb-label" pointing="below">
                    Link copied to clipboard!
                  </Label>
                  <a
                  // onClick={(event) => {
                  //   event.preventDefault();
                  //   navigator.clipboard.writeText(
                  //     'https://www.facebook.com/wealthscope/'
                  //   );
                  //   this.showLabel('fb-label');
                  // }}
                  >
                    {this.socialMediaIcon('facebook f')}
                  </a>
                </li>
                {/* <li>
                  <Label id="tw-label" pointing="below">
                    Link copied to clipboard!
                  </Label>
                  <a
                  // onClick={(event) => {
                  //   event.preventDefault();
                  //   navigator.clipboard.writeText(
                  //     'https://twitter.com/wealthscope'
                  //   );
                  //   this.showLabel('tw-label');
                  // }}
                  >
                    <ImageContainer>
                      <img
                        src={twitternewlogo}
                        alt="Twitter Logo"
                        width="27"
                        height="27"
                        style={{ transform: 'translateY(5px)' }}
                      />
                    </ImageContainer>
                  </a>
                </li> */}
              </SocialMediaList>
            </div>
          ) : null}
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  partnerName: PropTypes.string,
  is_wealthica_trial: PropTypes.bool,
  wealthica_plan: PropTypes.string,
  french: PropTypes.bool,
};

Landing.defaultProps = {
  partnerName: '',
  is_wealthica_trial: false,
  wealthica_plan: '',
  french: false,
};

const BottomP2 = styled.p`
  margin-top: 0 !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 1rem;
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 750px;

  &.strike {
    text-decoration: line-through;
  }
`;

const ImageContainer = styled.div`
  transition: transform 0.3s;
  transform-origin: center;
  :hover {
    transform: scale(1.2);
    transform-origin: center;
  }
`;

const SocialMediaList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  margin-bottom: 0;
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  transition: font-size 0.3s;

  li {
    display: inline-block;
    cursor: pointer;
    position: relative;

    :not(:last-child) {
      margin-right: 1rem;
    }

    a i.icon,
    a i.icons {
      font-size: 1.8rem !important;
      transition: transform 0.3s;
      z-index: 1;
    }

    a:hover i.icon,
    a:hover i.icons,
    a:hover img {
      opacity: 70%;
      transform: scale(1.2);
      transform-origin: center;
    }
  }

  .ui.label {
    position: absolute;
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: none;
  }
`;
