/* eslint-disable no-plusplus */
import { graph, nbGraph } from './colors';
import { isNB } from './helpers';

const assetLabels = [
  'fixed income',
  'equity',
  'alternative',
  'misc',
  'misc.',
  'other',
  'cash',
];

const isForAssetLabels = (labels) => {
  for (let i = 0; i < labels.length; i++) {
    const currentLabel =
      typeof labels[i] === 'string' ? labels[i].toLowerCase() : labels[i];
    if (!assetLabels.includes(currentLabel)) {
      return false;
    }
  }
  return true;
};

const assignColors = (labels, partner = '') => {
  if (isForAssetLabels(labels)) {
    // Hard code asset label colors
    const colors = labels.map((label) => {
      switch (label.toLowerCase()) {
        case 'fixed income':
          return isNB(partner) ? nbGraph.gray : graph.blue;
        case 'equity':
          return isNB(partner) ? nbGraph.dark : graph.red;
        case 'alternative':
          return isNB(partner) ? nbGraph.lighter : graph.pink;
        case 'misc':
        case 'misc.':
          return isNB(partner) ? nbGraph.darker : graph.teal;
        case 'cash':
          return isNB(partner) ? nbGraph.mediumDark : graph.orange;
        case 'other':
          return isNB(partner) ? nbGraph.mediumLight : graph.yellow;
        default:
          return isNB(partner) ? nbGraph.darkest : graph.grey; // Default color for labels not in the predefined list
      }
    });
    return colors;
  } else if (isNB(partner)) {
    const colorKeys = [
      'gray',
      'lightest',
      'lighter',
      'light',
      'mediumLight',
      'medium',
      'mediumDark',
      'dark',
      'darker',
      'darkest',
      'black',
    ];

    const numLabels = labels.length;

    // If there are 11 labels, just return all colors
    if (numLabels === 11) {
      return colorKeys.map((key) => nbGraph[key]);
    }

    // Calculate the step size to space colors evenly across the gradient
    const step = Math.floor(colorKeys.length / numLabels);

    // Create an array of colors spaced out as evenly as possible
    const selectedColors = [];
    for (let i = 0; i < numLabels; i++) {
      selectedColors.push(nbGraph[colorKeys[i * step]]);
    }

    return selectedColors;
  }
  return Object.values(graph).slice(0, labels.length);
};

export default assignColors;
