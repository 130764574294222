import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'components';

export default class Container extends PureComponent {
  render() {
    const { children, activePage } = this.props;
    return activePage !== 6 ? (
      <Segment
        margin="1rem"
        textAlign="center"
        className="segment"
      >
        {children}
      </Segment>
    ) : (
      <div
        style={{ margin: '1rem', textAlign: 'center' }}
        className="div"
      >
        {children}
      </div>
    );
  }
}

Container.propTypes = {
  activePage: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  activePage: 0,
};
