import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Form, Divider } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import cn from 'classnames';

import { colors } from 'utils/colors';
import Button from './Button';
import Message from './Message';
import Modal from './Modal';

export default class RiskModal extends Component {
  state = {
    error: false,
    result: false,
    isOpen: false,
  };

  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, result: false });

  handleSubmit = (e) => {
    const { t1, t2, t3, s1, s2, s3, r1, r2, r3, r4 } = this.state;
    const time = [t1, t2, t3].reduce((a, b) => a + b, 0);
    const savings = [s1, s2, s3].reduce((a, b) => a + b, 0);
    const risk = [r1, r2, r3, r4].reduce((a, b) => a + b, 0);
    const isInvalid = [time, savings, risk].filter((item) => !item).length;

    e.preventDefault();

    if (isInvalid) {
      this.setState({ error: true });
      return setTimeout(() => this.setState({ error: false }), 2000);
    }

    const result = time + savings + risk;
    let content1 = '';
    let content2 = '';

    if (result > 30) {
      content1 = 'All-in';
      content2 = 'Adventurous';
    } else if (result < 21) {
      content1 = 'Playing it Safe';
      content2 = 'Cautious';
    } else {
      content1 = 'Adventurous';
      content2 = 'Classic';
    }

    return this.setState({ result, content1, content2 });
  };

  closeModal = () => this.setState({ isOpen: false });

  resetState = () =>
    this.setState(
      Object.keys(this.state).reduce((newState, key) => {
        newState[key] = false;

        return newState;
      }, {})
    );

  render() {
    const {
      result,
      content1,
      content2,
      error,
      t1,
      t2,
      t3,
      s1,
      s2,
      s3,
      r1,
      r2,
      r3,
      r4,
      isOpen,
    } = this.state;
    const chartData = {
      labels: ['Portfolio 1', 'Portfolio 2', 'Portfolio 3', 'Portfolio 4'],
      datasets: [
        {
          label: 'Gains',
          stack: 'Dataset 1',
          backgroundColor: colors.blue,
          data: [10, 15, 25, 35],
        },
        {
          label: 'Losses',
          stack: 'Dataset 1',
          backgroundColor: colors.red,
          data: [-2, -5, -10, -15],
        },
      ],
    };
    const chartOptions = {
      tooltips: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.yLabel}%`,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: -20,
              max: 40,
              callback: (value) => `${value}%`,
            },
          },
        ],
      },
    };

    return (
      <span>
        <StyledLink onClick={() => this.setState({ isOpen: true })}>
          {' '}
          {this.props.target}
        </StyledLink>
        <Modal open={isOpen} dimmerClick={this.resetState}>
          <Modal.Header closeIcon close={this.closeModal}>
            <Header icon="briefcase" content="Risk Profile Questionnaire" />
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Header
                size="medium"
                content="1. Time Horizon"
                style={{ marginTop: 0 }}
              />
              <Form.Group grouped>
                <Header size="small" content="What is your current age?" />
                <Form.Radio
                  toggle
                  label="Under 45"
                  name="t1"
                  value={1}
                  checked={t1 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="45-51"
                  name="t1"
                  value={2}
                  checked={t1 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="52-56"
                  name="t1"
                  value={3}
                  checked={t1 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="Over 56"
                  name="t1"
                  value={4}
                  checked={t1 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="When do you anticipate that you will need this money?"
                />
                <Form.Radio
                  toggle
                  label="Within the next 2 years"
                  name="t2"
                  value={1}
                  checked={t2 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="In 3-5 years"
                  name="t2"
                  value={2}
                  checked={t2 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="In 5-10 years"
                  name="t2"
                  value={3}
                  checked={t2 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="Not before 10 years"
                  name="t2"
                  value={4}
                  checked={t2 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="When you need the money, over what period of time will you withdraw the funds?"
                />
                <Form.Radio
                  toggle
                  label="I will fully withdraw the funds within 2 years"
                  name="t3"
                  value={1}
                  checked={t3 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I will gradually withdraw the funds over 2-5 years"
                  name="t3"
                  value={2}
                  checked={t3 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I will gradually withdraw the funds over 6-9 years"
                  name="t3"
                  value={3}
                  checked={t3 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I will gradually withdraw the funds over a period of 10 years or more"
                  name="t3"
                  value={4}
                  checked={t3 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Divider />

              <Header size="medium" content="2. Savings Goals" />

              <Form.Group grouped>
                <Header
                  size="small"
                  content="What is your current annual income?"
                />
                <Form.Radio
                  toggle
                  label="Under $35,000"
                  name="s1"
                  value={1}
                  checked={s1 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="$36,000 - $75,000"
                  name="s1"
                  value={2}
                  checked={s1 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="$76,000 - $150,000"
                  name="s1"
                  value={3}
                  checked={s1 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="Over $150,000"
                  name="s1"
                  value={4}
                  checked={s1 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="What is your household net worth (total assets - total debt)"
                />
                <Form.Radio
                  toggle
                  label="Under $50,000"
                  name="s2"
                  value={1}
                  checked={s2 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="$51,000 - $125,000"
                  name="s2"
                  value={2}
                  checked={s2 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="$126,000 - $250,000"
                  name="s2"
                  value={3}
                  checked={s2 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="Over $250,000"
                  name="s2"
                  value={4}
                  checked={s2 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="Which of the following statements best describes your investment needs?"
                />
                <Form.Radio
                  toggle
                  label="I have no income from other sources and essentially rely on my savings"
                  name="s3"
                  value={1}
                  checked={s3 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I have a major source of income which I supplement with moderate income from my investments"
                  name="s3"
                  value={2}
                  checked={s3 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I have a moderate income from other sources, but primarily rely on my investments"
                  name="s3"
                  value={3}
                  checked={s3 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="My income is more than adequate and I don't have to rely on my investments."
                  name="s3"
                  value={4}
                  checked={s3 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Divider />

              <Header size="medium" content="3. Risk Tolerance" />

              <Form.Group grouped>
                <Header
                  size="small"
                  content="Which of the following situations upset you the most?"
                />
                <Form.Radio
                  toggle
                  label="You wake up one morning to learn that the value of your investment went down by 25% overnight"
                  name="r1"
                  value={1}
                  checked={r1 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="You have just realized that you have foregone a 25% rally in stocks because you were heavily invested in bonds"
                  name="r1"
                  value={2}
                  checked={r1 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="Both of the above"
                  name="r1"
                  value={3}
                  checked={r1 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="None of the above"
                  name="r1"
                  value={4}
                  checked={r1 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="Which of the following statements best describes your reaction to fluctuations in the value of your portfolio?"
                />
                <Form.Radio
                  toggle
                  label="I will immediately sell any holding in my portfolio if its value drops sharply or if I am losing money"
                  name="r2"
                  value={1}
                  checked={r2 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I would watch the market and wait at least a year before making a decision to sell"
                  name="r2"
                  value={2}
                  checked={r2 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I am uncomfortable with short-term fluctuations, but would wait at least 3 months before selling"
                  name="r2"
                  value={3}
                  checked={r2 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I am committed to a long-term strategy, and would not change my portfolio even if one of my holdings suffered a significant drop in value for over a year"
                  name="r2"
                  value={4}
                  checked={r2 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="Over time, the purchasing power of your investments will diminish due to inflation. Investments that have a higher potential to outpace inflation may also be more volatile. Which of the following statements is closest to your views on investing with respect to inflation?"
                />
                <Form.Radio
                  toggle
                  label="I prefer stable returns, even if this means that I may just keep up with or marginally beat inflation"
                  name="r3"
                  value={1}
                  checked={r3 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I prefer to exceed inflation substantially, but I am not comfortable with volatility"
                  name="r3"
                  value={2}
                  checked={r3 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I prefer stable returns, but it's more important that my gains exceed inflation"
                  name="r3"
                  value={3}
                  checked={r3 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  label="I want to maximize the return on my investments, and I accept that from time to time the value of my investments may decline"
                  name="r3"
                  value={4}
                  checked={r3 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group grouped>
                <Header
                  size="small"
                  content="The chart below illustrates the potential range of returns and losses of four different portfolios, over a 1-year period. Which portfolio would you be most comfortable with?"
                />
                <Form.Radio
                  toggle
                  name="r4"
                  label="Portfolio 1"
                  value={1}
                  checked={r4 === 1}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  name="r4"
                  label="Portfolio 2"
                  value={2}
                  checked={r4 === 2}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  name="r4"
                  label="Portfolio 3"
                  value={3}
                  checked={r4 === 3}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  toggle
                  name="r4"
                  label="Portfolio 4"
                  value={4}
                  checked={r4 === 4}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <div style={{ width: '70%', margin: '0 auto' }}>
                <Bar data={chartData} options={chartOptions} />
              </div>

              <Message
                visible={!!result}
                positive
                style={{
                  position: 'absolute',
                  width: '30rem',
                  textAlign: 'center',
                  margin: '0 auto',
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <Message.Content>
                  <div
                    style={{
                      padding: '4rem 0 0 0',
                      fontSize: '1.2rem',
                      lineHeight: '1.7rem',
                    }}
                  >
                    <Header
                      size="medium"
                      content="Based on your answers:"
                      style={{ margin: '0 0 1rem 0' }}
                    />
                    Your total score is <strong>{result}</strong>
                    <br />
                    Your risk profile falls in the range of{' '}
                    <strong>{content1}</strong> to <strong>{content2}</strong>
                  </div>
                  <div style={{ padding: '4rem 0' }}>
                    <Header size="medium" content="Scoring:" />
                    <strong>10 - 20</strong> is Cautious or Playing if Safe
                    <br />
                    <strong>21 - 30</strong> is Classic or Adventurous
                    <br />
                    <strong>31 - 40</strong> is Adventurous or All-in
                  </div>
                </Message.Content>
              </Message>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={cn('fluid', { negative: error })}
              color={colors.blue}
              onClick={this.handleSubmit}
            >
              {error ? 'Please answer each question' : 'Submit'}
            </Button>
          </Modal.Actions>
        </Modal>
      </span>
    );
  }
}

RiskModal.propTypes = {
  target: PropTypes.string.isRequired,
};

RiskModal.defaultProps = {};

const StyledLink = styled.span`
  color: #2185d0;

  &:hover {
    color: #1e70bf;
    cursor: pointer;
  }
`;
