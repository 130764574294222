import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Radar, Line } from 'react-chartjs-2';
import {
  Divider,
  Statistic,
  Header,
  Grid,
  Icon,
  Popup,
} from 'semantic-ui-react';
import cn from 'classnames';
import { Analytics, ComparePortfolio, Storage } from 'actions';
import { colors, nbColors, nbGraph } from 'utils/colors';
import {
  getOverAllScore,
  getOverAllGrade,
  decideCurrency,
} from 'utils/helpers';
import { displayLineChartDots, marginOfError } from 'utils/constants';
import { withRouter } from 'react-router-dom';
import { Table, Button } from 'components';
import SaveModal from '../Menu/SaveModal';
import {
  translateBenchmarkDescription,
  translateBenchmarkHeading,
  translateText,
} from '../../../../../utils/helpers';

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id: null,
      saveModalOpen: false,
    };
  }

  componentDidMount() {
    const { esgToggle, comparePortfolioState } = this.props;

    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    if (esgToggle) {
      const { analysis1, analysis2 } = comparePortfolioState.compareResult;

      this.fetchEsg(
        {
          symbols: analysis1.paramsAnalyzed.symbols.join(','),
          weights: analysis1.paramsAnalyzed.weights.join(','),
          gic_info: comparePortfolioState.original.gic_info,
        },
        {
          symbols: analysis2.paramsAnalyzed.symbols.join(','),
          weights: analysis2.paramsAnalyzed.weights.join(','),
          gic_info: comparePortfolioState.original.gic_info,
        }
      );
    }
  }

  componentWillUnmount() {
    const { dispatch, history } = this.props;
    const portfolioRegex = /^\/portfolio\/.*$/;

    if (!portfolioRegex.test(history.location.pathname)) {
      dispatch(ComparePortfolio.clearComparison());
      dispatch(Analytics.clearAnalytics());
    }
  }

  getPortfoliosAssociatedAccountName = () => {
    const { analytics } = this.props;
    if (analytics.data.paramsPassed.account)
      if (!analytics.data.paramsPassed.account.label)
        return `${analytics.data.paramsPassed.account.user_institution || ''}`;
      else
        return `${
          analytics.data.paramsPassed.account.user_institution || ''
        }: ${analytics.data.paramsPassed.account.label || ''}`;

    return null;
  };

  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0 %');

  getRadarData = (port1, port2, esg1, esg2) => {
    const { analytics, esgToggle, french } = this.props;
    const { benchmark } = analytics;
    const hash = {
      Diversification: french ? 'Diversification' : 'Diversification',
      Fees: french ? 'Frais faibles' : 'Low Fees',
      Income: french ? 'Revenu' : 'Income',
      Performance: french ? 'Performance' : 'Performance',
    };
    const portScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port1.score[key][benchmark][0]
          : port1.score[key][0];

      return total;
    }, {});
    const compareScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port2.score[key][benchmark][0]
          : port2.score[key][0];

      return total;
    }, {});

    return {
      labels: esgToggle
        ? [
            translateText('Downside Protection', french),
            ...Object.keys(portScores),
            'ESG',
          ]
        : [
            translateText('Downside Protection', french),
            ...Object.keys(portScores),
          ],
      datasets: [
        {
          label: french ? 'Portefeuille actuel' : 'Current Portfolio',
          backgroundColor: 'rgba(2,116,193,0.2)',
          borderColor: nbGraph.medium,
          pointBackgroundColor: nbGraph.medium,
          pointBorderColor: nbGraph.medium,
          lineTension: 0.3,
          data:
            esgToggle && esg1 && esg1.portfolio.esg_score
              ? [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                  esg1.portfolio.ws_grade[0] || 0,
                ]
              : [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                ],
        },
        {
          label: french ? 'Portefeuille comparé' : 'Comparison Portfolio',
          backgroundColor: 'rgba(255,184,182,0.5)',
          borderColor: nbColors.red,
          pointBackgroundColor: nbColors.red,
          pointBorderColor: nbColors.red,
          lineTension: 0.3,
          data:
            esgToggle && esg2 && esg2.portfolio.esg_score
              ? [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                  esg2.portfolio.ws_grade[0] || 0,
                ]
              : [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                ],
        },
      ],
    };
  };

  getChartData = (port1, port2) => {
    const { french } = this.props;
    return {
      labels: Object.keys(port1.cumrets.portfolio),
      datasets: [
        {
          data: Object.values(port1.cumrets.portfolio).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 0,
          label: french ? 'Portefeuille actuel' : 'Current Portfolio',
          lineTension: 0,
          fill: false,
          backgroundColor: nbColors.blue,
          borderColor: nbColors.blue,
          pointBackgroundColor: nbColors.blue,
          pointBorderColor: nbColors.blue,
          pointRadius:
            Object.keys(port1.cumrets.portfolio).length > displayLineChartDots
              ? 0
              : 2,
          pointHitRadius: 5,
        },
        {
          data: Object.values(port2.cumrets.portfolio).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 0,
          label: french ? 'Portefeuille comparé' : 'Comparison Portfolio',
          lineTension: 0,
          fill: false,
          backgroundColor: nbColors.red,
          borderColor: nbColors.red,
          pointBackgroundColor: nbColors.red,
          pointBorderColor: nbColors.red,
          pointRadius:
            Object.keys(port2.cumrets.portfolio).length > displayLineChartDots
              ? 0
              : 2,
          pointHitRadius: 5,
        },
      ],
    };
  };

  // Eslint marked as unused
  // getPortfolios = (portfolios) => {
  //   const { id } = this.state;

  //   return portfolios.reduce((total, portfolio) => {
  //     if (portfolio) {
  //       total.push({
  //         key: portfolio.id,
  //         value: portfolio.id,
  //         selected: portfolio.id === id,
  //         icon: portfolio.is_global ? (
  //           <Icon name="briefcase" style={{ color: colors.red }} />
  //         ) : (
  //           'briefcase'
  //         ),
  //         text: portfolio.name,
  //       });
  //     }

  //     return total;
  //   }, []);
  // };

  getNonTypicalWeight = (ticker) => {
    const { comparePortfolioState } = this.props;
    const nonTypicalTicker = Object.values(
      comparePortfolioState.portfolio
    ).find((port) => port.ticker === ticker);

    return nonTypicalTicker ? nonTypicalTicker.weight : null;
  };

  decideRegion = () => {
    const { user_region, toggle_region } = this.props;
    if (user_region === 'US') {
      return 'US';
    }
    if (toggle_region === 'US') {
      return 'US';
    }
    return 'CA';
  };

  analyzeWhatIf = () => {
    const { history, comparePortfolioState, dispatch, location, analytics } =
      this.props;
    const gicFormat = /^GIC\d*:MKT$/;
    Storage.removeItem('pwpa-has-exclude-holding');
    const params = Object.values(comparePortfolioState.portfolio).reduce(
      (total, item) => {
        if (
          (item.weight && gicFormat.test(item.ticker)) ||
          (item.weight && item.ticker === '~BTCUSDT') ||
          (item.weight && item.ticker === '~ETHUSDT') ||
          (item.weight &&
            item.unadjusted_closing_price &&
            item.unadjusted_closing_price.CAD)
        ) {
          const weight = item.weight / 100;
          total.symbols.push(item.ticker);
          total.weights.push(weight);
        }

        return total;
      },
      { symbols: [], weights: [] }
    );

    const rebalanceInterval = analytics.data.paramsPassed.rebalance_interval;
    const myPortfolio = {
      portfolio_type: 'custom',
      region: this.decideRegion(),
      symbols: params.symbols.join(','),
      weights: params.weights.join(','),
      ...(comparePortfolioState.gic_info &&
        Object.keys(comparePortfolioState.gic_info).length && {
          gic_info: comparePortfolioState.gic_info,
        }),
      is_linked: analytics.data.paramsPassed.is_linked,
    };

    dispatch(Storage.setItem('pwpa-portfolio', myPortfolio));

    dispatch(
      Analytics.fetchParams(
        myPortfolio,
        location.state,
        null,
        this.decideRegion(),
        rebalanceInterval
      )
    );

    return history.push('/portfolio/unsaved/overview');
  };

  isDisabled = () => {
    const { comparePortfolioState } = this.props;
    const { portfolio, gic_new_generic_ticker, gic_info } =
      comparePortfolioState;
    const { gic_rate, gic_ticker } = gic_new_generic_ticker
      ? gic_info[gic_new_generic_ticker] || {}
      : {};

    const totalWeight = Object.values(portfolio)
      .reduce((total, value) => (total += Number(value.weight)), 0)
      .toFixed(2);

    const gicWeight = this.getNonTypicalWeight(gic_new_generic_ticker);

    return (
      ((gicWeight || gic_rate || gic_ticker) &&
        !(gicWeight && gic_rate && gic_ticker)) ||
      totalWeight < 100 - marginOfError ||
      totalWeight > 100 + marginOfError
    );
  };

  closeModal = () => this.setState({ saveModalOpen: false });

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  // Eslint marked as unused
  // submit = () => {
  //   const { portfolios, dispatch, analytics, esgToggle, user_region } =
  //     this.props;
  //   const { data } = analytics;
  //   const portfolio = portfolios.find((port) => port.id === this.state.id);
  //   const params1 = {
  //     ...data.portfolio,
  //   };
  //   const params2 = {
  //     ...portfolio,
  //   };

  //   if (esgToggle) {
  //     this.fetchEsg(params1, params2);
  //   }

  //   return dispatch(Analytics.fetchCompare(params1, params2, user_region));
  // };

  fetchEsg = (port1, port2) => {
    const { dispatch } = this.props;

    return dispatch(Analytics.fetchCompareEsg(port1, port2));
  };

  parseDate = () => {
    const { comparePortfolioState, french } = this.props;
    const { analysis1 } = comparePortfolioState.compareResult;
    const { init_date, end_date } = analysis1.paramsAnalyzed;
    const init = init_date.split('-');
    const end = end_date.split('-');
    const months = {
      '01': french ? 'Janvier' : 'January',
      '02': french ? 'Février' : 'February',
      '03': french ? 'Mars' : 'March',
      '04': french ? 'Avril' : 'April',
      '05': french ? 'Mai' : 'May',
      '06': french ? 'Juin' : 'June',
      '07': french ? 'Juillet' : 'July',
      '08': french ? 'Août' : 'August',
      '09': french ? 'Septembre' : 'September',
      10: french ? 'Octobre' : 'October',
      11: french ? 'Novembre' : 'November',
      12: french ? 'Décembre' : 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  /* TODO: add translation to compare PDF. print button hidden in the meantime  */
  // generatePDF = () => {
  //   const { match, history } = this.props;

  //   return history.push(`/portfolio/${match.params.id}/compare/pdf`);
  // };

  displayEsg = (esg) => {
    if (!esg) return '-';

    const { portfolio } = esg;

    if (!portfolio.esg_score) return '-';

    return !portfolio.esg_score ? '-' : portfolio.esg_score;
  };

  esgCoverage = (esg) => {
    if (!esg) return '(0%)';

    const { portfolio } = esg;
    const weight = (portfolio.weight * 100).toFixed(2);

    if (!weight || !Number(weight)) return '(0%)';

    const convertWeight = weight.split('.');

    if (convertWeight[1][0] === '0' && convertWeight[1][1] === '0')
      return `(${convertWeight[0]}%)`;
    if (convertWeight[1][1] === '0')
      return `(${convertWeight[0]}.${convertWeight[1][0]}%)`;

    return `(${weight}%)`;
  };

  formatDataForSaving = () => {
    const { comparePortfolioState } = this.props;
    const { portfolio, gic_info } = comparePortfolioState;
    const { analysis2 } = comparePortfolioState.compareResult;
    const { paramsAnalyzed } = analysis2;

    const symbols = Object.keys(portfolio)
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);
    const weights = Object.keys(portfolio)
      .map((ticker) => (portfolio[ticker].weight / 100).toFixed(4))
      .reduce((prev, curr) => prev.concat(`${curr},`), '')
      .slice(0, -1);

    return {
      ...paramsAnalyzed,
      portfolio_type: 'custom',
      gic_info, // need to use this info from compareportfolio due to the renaming of the gic tickers at the time of comparison
      symbols,
      weights,
      check_history: !!analysis2.check_history, // use the check_history value that was used in the comparison
    };
  };

  renderSubheader = () => {
    const { french } = this.props;
    return (
      <div className="sub header">
        {french ? 'Période de comparaison' : 'Comparison Period'}:{' '}
        {this.parseDate()}
        <Popup
          trigger={
            <Icon
              name="info circle"
              style={{ fontSize: '15px', verticalAlign: 'initial' }}
            />
          }
          position="bottom center"
          wide
        >
          {french
            ? 'La période de comparaison est définie à la plus longue histoire commune des deux portefeuilles que vous comparez. Par conséquent, les scores du portefeuille actuel peuvent changer, car son histoire peut maintenant être plus courte.'
            : 'The comparison period is set to the longest common history of the two portfolios you are comparing. Therefore, the scores of the current portfolio may change, as its history may now be shorter.'}
        </Popup>
      </div>
    );
  };

  render() {
    const {
      analytics,
      user_region,
      comparePortfolioState,
      dispatch,
      setView,
      french,
    } = this.props;
    const { saveModalOpen } = this.state;
    const { benchmark, income, compareEsg, esgToggle } = analytics;
    const { analysis1, analysis2 } = comparePortfolioState.compareResult;
    // const { isWhatIf } = comparePortfolioState; NB currently does not have access to portfolio tools
    const { portfolio1esg, portfolio2esg } = compareEsg;
    let portOneName = comparePortfolioState.original.name;
    const portTwoName = comparePortfolioState.name;

    portOneName = portOneName || this.getPortfoliosAssociatedAccountName();
    const compareResultsUseSameBenchmark =
      JSON.stringify(analysis1.fratios[benchmark]) ===
      JSON.stringify(analysis2.fratios[benchmark]);

    return (
      <StyledDiv>
        {analysis1 && analysis2 && portTwoName === 'What-if' && (
          <Popup
            position="right center"
            trigger={
              <Button
                className="back left"
                color={colors.lightGrey}
                icon="chevron left"
                onClick={() => {
                  dispatch(ComparePortfolio.setSnapshotLoad(true));
                  setView('WhatIfCustomization');
                }}
              >
                {french ? 'Retour' : 'Back'}
              </Button>
            }
            content={
              french
                ? "Retour à l'éditeur de simulations"
                : 'Return to What-If Editor'
            }
          />
        )}
        {analysis1 && !analysis2 ? (
          <Error>
            {french
              ? "Désolé, toutes les valeurs mobilières de l'un de vos portefeuilles sont trop récentes pour être analysées."
              : 'Sorry, all securities in one of your portfolios are too new to be analyzed.'}
          </Error>
        ) : analysis1 && analysis2 ? (
          <Container centered>
            <Header
              id="result-vs-header"
              size="large"
              content={`${
                french ? 'Portefeuille actuel' : 'Current Portfolio'
              } ${portOneName ? `(${portOneName})` : ''} ${
                french ? 'vs.' : 'vs.'
              } ${french ? ' Portefeuille ' : ''}${translateText(
                portTwoName,
                french
              )}`}
              subheader={this.renderSubheader()}
              style={{ marginTop: 0, marginBottom: '3rem' }}
            />
            <Grid style={{ margin: '0 0 3rem 0' }}>
              <Grid.Row columns={2}>
                <Grid.Column
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                  }}
                  width={7}
                >
                  <Statistic
                    size="tiny"
                    style={{
                      height: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '30px',
                    }}
                  >
                    <Grade color={nbColors.blue}>
                      {getOverAllGrade({
                        income,
                        benchmark,
                        esgToggle,
                        esg: portfolio1esg,
                        data: analysis1,
                      })}
                    </Grade>
                    <ScoreHeader>
                      {french ? (
                        <div>
                          Score du portefeuille
                          <span style={{ color: nbColors.blue }}>
                            {' '}
                            actuel
                          </span>{' '}
                        </div>
                      ) : (
                        <div>
                          <span style={{ color: nbColors.blue }}>Current</span>{' '}
                          Portfolio Score
                        </div>
                      )}
                    </ScoreHeader>
                    <Statistic.Value>
                      {getOverAllScore({
                        income,
                        benchmark,
                        esgToggle,
                        esg: portfolio1esg,
                        data: analysis1,
                      })}
                    </Statistic.Value>
                  </Statistic>
                  <Statistic
                    size="tiny"
                    style={{
                      height: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 0 0 0',
                    }}
                  >
                    <Grade color={nbColors.red}>
                      {getOverAllGrade({
                        income,
                        benchmark,
                        esgToggle,
                        esg: portfolio2esg,
                        data: analysis2,
                      })}
                    </Grade>
                    <ScoreHeader>
                      {french ? (
                        <div>
                          Score du portefeuille
                          <span style={{ color: nbColors.red }}>
                            {' '}
                            comparé
                          </span>{' '}
                        </div>
                      ) : (
                        <div>
                          <span style={{ color: nbColors.red }}>
                            Comparison
                          </span>{' '}
                          Portfolio Score
                        </div>
                      )}
                    </ScoreHeader>
                    <Statistic.Value>
                      {getOverAllScore({
                        income,
                        benchmark,
                        esgToggle,
                        esg: portfolio2esg,
                        data: analysis2,
                      })}
                    </Statistic.Value>
                    {portTwoName === 'What-if' && (
                      <Popup
                        position="left center"
                        content="Analyze your What-If Portfolio"
                        trigger={
                          <Button
                            className={cn('left centered', {
                              disabled: this.isDisabled(),
                            })}
                            style={{
                              width: '8rem',
                            }}
                            color={colors.darkBlue}
                            icon="line chart"
                            onClick={this.analyzeWhatIf}
                          >
                            Analyze
                          </Button>
                        }
                      />
                    )}
                  </Statistic>
                </Grid.Column>
                <Grid.Column style={{ display: 'flex', alignItems: 'center' }}>
                  <Radar
                    id="radarChart"
                    data={this.getRadarData(
                      analysis1,
                      analysis2,
                      portfolio1esg,
                      portfolio2esg
                    )}
                    options={radarOptions}
                    height={160}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Header>
              <Header.Content>
                {french ? 'Statistiques clés' : 'Key Statistics'}
              </Header.Content>
              <Header.Subheader>
                {`${this.parseDate()} ${
                  french ? '(inclusif)' : '(inclusive)'
                } ${french ? 'en' : 'in'} ${decideCurrency(
                  analytics.data,
                  user_region || 'CA'
                )}`}
              </Header.Subheader>
            </Header>

            <Divider />

            <TableContainer>
              <Table basic textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4} />
                    <Table.HeaderCell width={3}>
                      {french ? 'Rendement annualisé' : 'Annualized Return'}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {french ? 'Ratio de Sharpe' : 'Sharpe Ratio'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={
                          french
                            ? 'Ce ratio mesure la "récompense" du portefeuille par unité de "risque", où la récompense est le rendement moyen du portefeuille au-delà du taux sans risque, et le risque est l\'écart type (ou volatilité) du portefeuille. Le taux sans risque que nous utilisons est le rendement des bons du Trésor à 12 mois.'
                            : "This ratio measures the 'reward' of the portfolio per unit of 'risk', where reward is the average return of the portfolio over and above the risk-free rate, and risk is the standard deviation (or volatility) of the portfolio. The risk-free rate we use is the 12-month Treasury bill yield."
                        }
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {french
                        ? 'Critère de Sécurité de Roy'
                        : "Roy's Safety First Criterion"}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                      >
                        {french
                          ? "Comme le ratio de Sharpe, ce ratio mesure la récompense du portefeuille par unité de risque. Cependant, la récompense est définie comme le rendement moyen du portefeuille au-dessus de votre rendement cible (ou taux minimum acceptable), au lieu du taux sans risque. Actuellement, le rendement cible est fixé à zéro, c'est-à-dire la préservation de l'argent (ne pas perdre d'argent en termes nominaux)."
                          : 'Like the Sharpe Ratio, this ratio measures the portfolio’s reward per unit of risk. However, the reward is defined as the portfolio’s average return over your target return (or minimum acceptable rate), instead of the risk-free rate. Currently, the target return is set to zero, i.e., money preservation (not losing money in nominal terms).'}
                      </Popup>
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {french ? 'Ratio de Sortino' : 'Sortino Ratio'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="left center"
                        wide
                      >
                        {french
                          ? "Ce ratio mesure la récompense du portefeuille par unité de risque à la baisse. La récompense est le rendement moyen du portefeuille au-dessus de votre rendement cible (ou taux minimum acceptable). Le risque à la baisse est la volatilité des rendements du portefeuille en dessous de votre objectif. Actuellement, le rendement cible est fixé à zéro, c'est-à-dire la préservation de l'argent (ne pas perdre d'argent en termes nominaux)."
                          : 'This ratio measures the portfolio’s reward per unit of downside risk. The reward is the portfolio’s average return over your target return (or minimum acceptable rate). Downside risk is the volatility of the portfolio returns below your target. Currently, the target return is set to zero, i.e., money preservation (not losing money in nominal terms).'}
                      </Popup>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell width={4}>
                      {french ? 'Portefeuille actuel' : 'Current Portfolio'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis1.fratios.portfolio.annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios.portfolio.sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios.portfolio.roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios.portfolio.sortino)}
                    </Table.Cell>
                  </Table.Row>
                  {!compareResultsUseSameBenchmark && (
                    <Table.Row>
                      <Table.Cell width={4}>
                        {translateBenchmarkHeading(
                          analysis1.fratios[benchmark].name,
                          french
                        )}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content={translateBenchmarkDescription(
                            analysis1.fratios[benchmark].description,
                            french
                          )}
                          wide
                        />
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(
                          analysis1.fratios[benchmark].annualizedRet
                        )}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.formatNum(analysis1.fratios[benchmark].sharpe)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.formatNum(analysis1.fratios[benchmark].roys)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.formatNum(analysis1.fratios[benchmark].sortino)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row positive>
                    <Table.Cell width={4}>
                      {french ? 'Portefeuille comparé' : 'Comparison Portfolio'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis2.fratios.portfolio.annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios.portfolio.sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios.portfolio.roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios.portfolio.sortino)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {translateBenchmarkHeading(
                        analysis2.fratios[benchmark].name,
                        french
                      )}
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={translateBenchmarkDescription(
                          analysis2.fratios[benchmark].description,
                          french
                        )}
                        wide
                      />
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis2.fratios[benchmark].annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios[benchmark].sharpe)}
                    </Table.Cell>

                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios[benchmark].roys)}
                    </Table.Cell>

                    <Table.Cell width={3}>
                      {this.formatNum(analysis2.fratios[benchmark].sortino)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table basic textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell width={3}>
                      {french ? 'Risque total' : 'Total Risk'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={
                          french
                            ? "C'est la variabilité des rendements de votre portefeuille au fil du temps, mesurée par l'écart type. Elle inclut la variabilité à la hausse ainsi que la variabilité à la baisse, d'où le nom de risque total."
                            : "This is the variability of your portfolio's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk."
                        }
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {french ? 'Risque à la baisse' : 'Downside Risk'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={
                          french
                            ? "C'est la variabilité des rendements de votre portefeuille en dessous d'un objectif. L'objectif utilisé ici est la préservation du capital; c'est-à-dire un rendement non négatif."
                            : "This is the variability of your portfolio's returns below a target. The target used here is capital preservation; that is, a non-negative return."
                        }
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {french ? 'Perte maximale' : 'Maximum Drawdown'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={
                          french
                            ? "C'est la perte maximale du pic au creux observée pendant la période en question. Elle donne une perspective différente du risque à la baisse d'un portefeuille."
                            : "This is the maximum peak to trough loss observed during the period in question. It gives a different perspective of a portfolio's downside risk."
                        }
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {french ? 'Capture à la baisse' : 'Downside Capture'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="left center"
                        content={
                          french
                            ? "Cela mesure si votre portefeuille a perdu moins que le marché boursier (tel que sélectionné) lors d'un repli du marché. Une capture à la baisse inférieure à 100 % indique que votre portefeuille a perdu moins que le marché boursier pendant ces périodes. Une capture à la baisse qui est négative indique que votre portefeuille a généré des rendements positifs lorsque le marché boursier était en baisse."
                            : 'This measures whether your portfolio lost less than the stock market (as selected) when there was a market downturn. A downside capture of less than 100% indicates that your portfolio lost less than the stock market during those periods. A downside capture that is negative indicates that your portfolio actually generated positive returns when the stock market was in the red.'
                        }
                        wide
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell width={4}>
                      {french ? 'Portefeuille actuel' : 'Current Portfolio'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.fratios.portfolio.stdDev)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.fratios.portfolio.dsr)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.fratios.portfolio.maxDd)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis1.fratios.portfolio.dscapture[benchmark]
                      )}
                    </Table.Cell>
                  </Table.Row>
                  {!compareResultsUseSameBenchmark && (
                    <Table.Row>
                      <Table.Cell width={4}>
                        {translateBenchmarkHeading(
                          analysis1.fratios[benchmark].name,
                          french
                        )}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                              }}
                            />
                          }
                          position="top center"
                          content={translateBenchmarkDescription(
                            analysis1.fratios[benchmark].description,
                            french
                          )}
                          wide
                        />
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(analysis1.fratios[benchmark].stdDev)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(analysis1.fratios[benchmark].dsr)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(analysis1.fratios[benchmark].maxDd)}
                      </Table.Cell>
                      <Table.Cell width={3}>
                        {this.getPercent(
                          analysis1.fratios[benchmark].dscapture
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row positive>
                    <Table.Cell width={4}>
                      {french ? 'Portefeuille comparé' : 'Comparison Portfolio'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios.portfolio.stdDev)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios.portfolio.dsr)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios.portfolio.maxDd)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis2.fratios.portfolio.dscapture[benchmark]
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {translateBenchmarkHeading(
                        analysis2.fratios[benchmark].name,
                        french
                      )}
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                            }}
                          />
                        }
                        position="top center"
                        content={translateBenchmarkDescription(
                          analysis2.fratios[benchmark].description,
                          french
                        )}
                        wide
                      />
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].stdDev)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].dsr)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].maxDd)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.fratios[benchmark].dscapture)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table basic textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell width={3}>
                      {french ? 'Frais' : 'Fees'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={
                          french
                            ? 'Frais moyens pondérés des produits (RFG)'
                            : 'Weighted average product fees (MERs)'
                        }
                        wide
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {french ? 'Revenu' : 'Income'}
                      &nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content={
                          french
                            ? 'Moyenne pondérée des distributions'
                            : 'Weighted average distributions'
                        }
                        wide
                      />
                    </Table.HeaderCell>
                    {esgToggle ? (
                      <Table.HeaderCell>
                        {french ? 'Couverture ESG' : 'ESG Coverage'}
                        &nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content={
                            french
                              ? 'Score ESG moyen du portefeuille et pourcentage de titres ayant des notes ESG (entre parenthèses).'
                              : 'Portfolio average ESG score and percentage of holdings that have ESG ratings (in brackets).'
                          }
                          wide
                        />
                      </Table.HeaderCell>
                    ) : (
                      <Table.HeaderCell />
                    )}
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell width={4}>
                      {french ? 'Portefeuille actuel' : 'Current Portfolio'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.portMER)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis1.portYield)}
                    </Table.Cell>

                    {esgToggle ? (
                      <Table.Cell width={3}>
                        {this.displayEsg(portfolio1esg)}&nbsp;
                        {this.esgCoverage(portfolio1esg)}
                      </Table.Cell>
                    ) : (
                      <Table.Cell width={3} />
                    )}
                    <Table.Cell width={3} />
                  </Table.Row>
                  <Table.Row positive>
                    <Table.Cell width={4}>
                      {french ? 'Portefeuille comparé' : 'Comparison Portfolio'}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.portMER)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(analysis2.portYield)}
                    </Table.Cell>

                    {esgToggle ? (
                      <Table.Cell width={3}>
                        {this.displayEsg(portfolio2esg)}&nbsp;
                        {this.esgCoverage(portfolio2esg)}
                      </Table.Cell>
                    ) : (
                      <Table.Cell width={3} />
                    )}
                    <Table.Cell width={3} />
                  </Table.Row>
                </Table.Body>
              </Table>
            </TableContainer>

            <Divider />

            <Header>
              <Header.Content>
                {french
                  ? 'Historique hypothetique du portefeuille'
                  : 'Hypothetical Portfolio History'}
              </Header.Content>
              <Header.Subheader>
                {french
                  ? 'Basé sur la composition actuelle de votre portefeuille'
                  : 'Based on the current composition of your portfolio'}
                &nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={
                    french
                      ? 'En utilisant les mêmes avoirs et les mêmes pondérations.'
                      : 'Using the same holdings and the same weights.'
                  }
                  wide
                />
              </Header.Subheader>
              <Header.Subheader>
                {`${this.parseDate()} ${
                  french ? '(inclusif)' : '(inclusive)'
                } ${french ? 'en' : 'in'} ${decideCurrency(
                  analytics.data,
                  user_region || 'CA'
                )}`}
              </Header.Subheader>
            </Header>
            <Note>
              {french
                ? 'Rendements totaux incluant les distributions'
                : 'Total Returns including Distributions'}
            </Note>
            <Line
              data={this.getChartData(analysis1, analysis2)}
              options={chartOptions}
            />
            <div
              style={{
                display: 'flex',
                margin: 'auto',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              {/* TODO: add translation to compare PDF. print button hidden in the meantime  */}
              {/* <Button
                className={cn('left')}
                icon="file text"
                color={colors.darkGrey}
                onClick={this.generatePDF}
                style={{ marginTop: '30px' }}
              >
                {french ? 'Imprimer/PDF' : 'Print/PDF'}
              </Button> */}

              {/* Hidden since NB does not have access to portfolio tools  */}
              {/* {isWhatIf && (
                <Button
                  className={cn('left')}
                  icon="save outline"
                  color={colors.red}
                  onClick={() => this.setState({ saveModalOpen: true })}
                  style={{ marginTop: '30px' }}
                >
                  {french
                    ? 'Enregistrer le scénario comme nouveau portefeuille'
                    : 'Save What-if As a New Portfolio'}
                </Button>
              )} */}
            </div>
          </Container>
        ) : null}
        <SaveModal
          closeModal={this.closeModal}
          open={saveModalOpen}
          portfolio={this.formatDataForSaving()}
          redirectToSavedPortfolioOnSuccess={false}
          forceSaveAsNewPortfolio
        />
      </StyledDiv>
    );
  }
}

Result.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // portfolios: PropTypes.array,
  history: PropTypes.object.isRequired,
  comparePortfolioState: PropTypes.object.isRequired,
  // match: PropTypes.object.isRequired,
  esgToggle: PropTypes.bool,
  analytics: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  setView: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  toggle_region: PropTypes.string,
  french: PropTypes.bool,
};

Result.defaultProps = {
  // portfolios: [],
  esgToggle: false,
  user_region: 'CA',
  toggle_region: 'CA',
  french: false,
};

export default withRouter(
  connect((state) => ({
    portfolios: state.Portfolio.user,
    comparePortfolioState: state.ComparePortfolio,
    analytics: state.Analytics,
    esgToggle: state.Analytics.esgToggle,
    user_region: state.Storage.user_region,
    toggle_region: state.Storage.toggle_region || 'CA',
    french: state.Storage.language === 'fr',
  }))(Result)
);

const StyledDiv = styled.div`
  min-height: 75vh;
  @media only screen and (max-width: 968px) {
    #result-vs-header {
      width: 30rem !important;
      margin: auto !important;
    }
  }
  button.back {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;
  }
`;

const Grade = styled.div`
  font-family: 'Kalam', cursive;
  color: ${(props) => props.color};
  font-size: 4rem;
  padding: 1.5rem 0;
`;

const Container = styled.div`
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const radarOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: false,
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
  hover: {
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const chartOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (label) => `$${label}`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${data.datasets[tooltipItems.datasetIndex].label}: $${
          tooltipItems.yLabel
        }`,
    },
  },
  hover: {
    animationDuration: 0,
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const Error = styled.div`
  width: 400px;
  background: white;
  margin: 0 auto;
  text-align: center;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 8px;
  font-size: 1.4rem;
  box-shadow: 0 0 3px dimgrey;
`;

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.5rem auto;
`;

const ScoreHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 6rem;
`;
