import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';

import { Message } from 'components';
import { arrangeSector } from 'utils/helpers';
import { ExposureChart, ExposureTable } from '../components';

export default class MacroFactor extends Component {
  render() {
    const { data } = this.props;

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content="Risk Exposure Analysis"
          subheader="By Macroeconomic Factor"
        />
        {typeof data.riskExp.F === 'string' ? (
          <Message visible warning>
            {data.riskExp.F}
          </Message>
        ) : (
          <div>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <p>
                    This section shows your portfolio’s risk attribution to
                    seven macroeconomics factors.
                  </p>
                  <Header
                    size="medium"
                    content="Macroeconomic Risk Exposure"
                    style={{ margin: 0 }}
                  />
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.riskExp.F))}
                    data={Object.values(arrangeSector(data.riskExp.F))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      'Asset',
                      <div>
                        Macroeconomic Risk Exposure&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                          style={{ height: 'initial' }}
                        >
                          A negative number means that your portfolio is hedged
                          against that factor. The risk of your portfolio is
                          negatively related to movements in that factor.
                          <br />
                          <br />
                          Factors with {'"-"'} risk exposure are statistically
                          insignificant.
                        </Popup>
                      </div>,
                    ]}
                    totals={['Total', <div>100%</div>]}
                    data={Object.keys(data.riskExp.F).reduce((total, key) => {
                      total.push({
                        ticker: key,
                        macroRiskExp: data.riskExp.F[key],
                      });

                      return total;
                    }, [])}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Container>
    );
  }
}

MacroFactor.propTypes = {
  data: PropTypes.object.isRequired,
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
