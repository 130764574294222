import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Flag, Icon, Popup, Header, Divider } from 'semantic-ui-react';
import cn from 'classnames';
import { CompareAccount, Storage } from 'actions';
import { colors } from 'utils/colors';
import { Table, Button, Segment, SearchTicker, Loading } from 'components';
import { getTickerSearchResult } from 'utils/helpers';
import { marginOfError } from 'utils/constants';
import Fade from 'react-reveal/Fade';
import RoundDecimalImage from 'images/roundDecimal.png';
import InterestRate from './InterestRate';
import Weight from './Weight';

@connect((state) => ({
  compareAccState: state.CompareAccount,
  selectedAccountList: state.Accounts.selectedAccountList,
  selectedAccountOverview: state.Accounts.selectedAccountOverview,
  overview: state.Accounts.overview,
}))
export default class CompareAccountView extends Component {
  componentWillMount = () => {
    const {
      dispatch,
      match,
      compareAccState,
      type,
      selectedAccountOverview,
      overview,
    } = this.props;
    const { id } = match.params;

    if (!Object.keys(compareAccState.holdings).length) {
      this.resetDigCurrency();

      if (Number(id)) {
        return dispatch(CompareAccount.fetchId(id));
      } else if (type === 'multiple') {
        return dispatch(CompareAccount.fetchId(id, selectedAccountOverview));
      } else if (type === 'overview') {
        return dispatch(CompareAccount.fetchId(id, overview));
      }
    }

    return false;
  };

  componentDidUpdate = (prevProps) => {
    const { compareAccState } = this.props;

    if (
      prevProps.compareAccState.compareResult !== compareAccState.compareResult
    ) {
      const { history, match } = this.props;

      if (window.parentIFrame) {
        window.parentIFrame.scrollTo(0, 0);
      }

      if (
        compareAccState.compareResult.analysis1 &&
        compareAccState.compareResult.analysis2
      ) {
        history.push(`/accounts/${match.params.id}/compare/result`);
      }
    }
  };

  componentWillUnmount = () => {
    const { dispatch, history } = this.props;

    if (history.action === 'POP') {
      if (!history.location.pathname.includes('compare')) {
        dispatch(CompareAccount.clearResult());
        dispatch(CompareAccount.removeAll());
      }
    }
  };

  getPriceFlag = (holding) => {
    const currency = {
      CAD: 'ca',
      USD: 'us',
    };

    // if security_detail does not exist, use yodlee currency for flag
    return holding.security_detail
      ? currency[holding.security_detail.default_currency]
      : currency[holding.default_currency];
  };

  getStatusMsg = (status) => {
    if (status === 'fail') {
      return <Icon name="warning" color="red" style={{ fontSize: '1.3rem' }} />;
    } else if (status === 'success') {
      return (
        <Icon name="checkmark" color="green" style={{ fontSize: '1.3rem' }} />
      );
    }

    return <Spinner />;
  };

  getGICHoldings = () => {
    const { compareAccState } = this.props;
    // eslint-disable-next-line no-useless-escape
    const gicFormat = /^GIC\d*\:MKT$/;

    const res = Object.keys(compareAccState.holdings)
      .filter((key) => gicFormat.test(key))
      .reduce((obj, key) => {
        obj[key] = compareAccState.holdings[key];
        return obj;
      }, {});

    return res;
  };

  getNonTypicalWeight = (ticker) => {
    const { compareAccState } = this.props;
    const nonTypicalTicker = Object.values(compareAccState.holdings).find(
      (port) => port.ticker === ticker
    );

    return nonTypicalTicker ? nonTypicalTicker.weight : null;
  };

  resetDigCurrency = () => {
    const { dispatch } = this.props;

    dispatch(CompareAccount.removeDigitalCurrency('~BTCUSDT'));
    dispatch(CompareAccount.removeDigitalCurrency('~ETHUSDT'));
    dispatch(CompareAccount.updateToggle('addBtcToggle', false));

    return false;
  };

  filterOutGICAndDigitalCurrency = () => {
    const { compareAccState } = this.props;
    // eslint-disable-next-line no-useless-escape
    const gicFormat = /^GIC\d*\:MKT$/;

    return Object.keys(compareAccState.holdings)
      .filter((key) => !gicFormat.test(key))
      .filter((key) => key !== '~BTCUSDT' && key !== '~ETHUSDT')
      .reduce((obj, key) => {
        obj[key] = compareAccState.holdings[key];
        return obj;
      }, {});
  };

  showAddGICToggle = () => {
    const { compareAccState } = this.props;
    const { original, holdings, gic_info, addGicToggle } = compareAccState;

    const all_gics = Object.keys(gic_info);

    return (
      addGicToggle ||
      Object.values(holdings).reduce(
        (prev, curr) => (prev += all_gics.includes(curr.ticker) ? 1 : 0),
        0
      ) === 0 ||
      Object.values(original.holdings).reduce(
        (prev, curr) => (prev += all_gics.includes(curr.ticker) ? 1 : 0),
        0
      ) === 0
    );
  };

  didUserAddNewHoldingsToWhatIf = () => {
    const { compareAccState } = this.props;
    // eslint-disable-next-line prefer-const
    const { holdings, original } = compareAccState;

    // removing all holdings that have a weight of 0%
    const portfolioTickers = Object.keys(holdings).filter(
      (ticker) => holdings[ticker].weight > 0
    );
    const originalPortfolioTickers = Object.keys(original.holdings);

    const sortedPortfolioTickers = [...portfolioTickers].sort();
    const sortedOriginalPortfolioTickers = [...originalPortfolioTickers].sort();

    // using sets to decrease overall average time complexity
    const sortedOriginalPortfolioSet = new Set(sortedOriginalPortfolioTickers);

    // user must have added some new holdings
    if (sortedOriginalPortfolioTickers.length < sortedPortfolioTickers.length) {
      return true;
    } else if (
      sortedOriginalPortfolioTickers.length > sortedPortfolioTickers.length
    ) {
      // user could have deleted a bunch and added a new one
      for (let i = 0; i < sortedPortfolioTickers.length; i += 1) {
        if (!sortedOriginalPortfolioSet.has(sortedPortfolioTickers[i])) {
          return true;
        }
      }
    } else {
      // original and what-if have an equal number of holdings. user may have added new holdings (could have added one and deleted one)
      for (let i = 0; i < sortedPortfolioTickers.length; i += 1) {
        if (sortedPortfolioTickers[i] !== sortedOriginalPortfolioTickers[i]) {
          return true;
        }
      }
    }
    return false;
  };

  fetchCompare = () => {
    const { dispatch, compareAccState, user_region } = this.props;
    const { holdings, original, gic_info } = compareAccState;
    return dispatch(
      CompareAccount.fetchCompare(
        original.holdings,
        holdings,
        original.gic_info,
        gic_info,
        user_region,
        null,
        !this.didUserAddNewHoldingsToWhatIf()
      )
    );
  };

  convertToPortfolio = () => {
    const { holdings, digitalCurrency } = this.props.compareAccState;

    return {
      name: 'What-if Portfolio',
      region: 'CA',
      target: 0,
      symbols: Object.values(holdings)
        .map((h) => h.ticker)
        .concat(Object.keys(digitalCurrency))
        .join(','),
      weights: Object.values(holdings)
        .map((h) => Number(h.weight) / 100)
        .concat(Object.values(digitalCurrency).map((c) => c / 100))
        .join(','),
    };
  };

  labelData = () => {
    const { holdings, digitalCurrency } = this.props.compareAccState;
    const holdingsValue = Object.values(holdings).reduce(
      (total, value) => (total += Number(value.weight)),
      0
    );
    const digitalCurrencyValue = Object.values(digitalCurrency).reduce(
      (total, value) => (total += Number(value)),
      0
    );
    const totalValue = holdingsValue + digitalCurrencyValue;

    if (
      !totalValue ||
      (this.round(totalValue) >= 100 - marginOfError &&
        this.round(totalValue) <= 100 + marginOfError)
    ) {
      return false;
    }

    return {
      val: this.round(Math.abs(100 - totalValue)),
      msg: 100 - totalValue > 0 ? 'under' : 'over',
    };
  };

  round = (num) => Math.round(num * 100) / 100;

  handleDigCurrencyChange = (e) => {
    const { dispatch } = this.props;
    const { name, value } = e.target;
    const num = Number(value);
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);
    const ticker =
      name === 'btc_weight'
        ? '~BTCUSDT'
        : name === 'eth_weight'
        ? '~ETHUSDT'
        : '';

    if (Number(value) > 100) {
      return dispatch(CompareAccount.updateDigitalCurrency(ticker, 100));
    }

    return !negative
      ? dispatch(
          CompareAccount.updateDigitalCurrency(
            ticker,
            value === ''
              ? ''
              : Number.isInteger(num)
              ? num
              : value.split('.')[1].length >= 2
              ? Number(
                  `${value.split('.')[0]}.${value
                    .split('.')[1]
                    .substring(0, 2)}`
                )
              : num
          )
        )
      : false;
  };

  remove = (ticker) => {
    const { dispatch } = this.props;

    return dispatch(CompareAccount.remove(ticker));
  };

  removeAll = () => this.props.dispatch(CompareAccount.removeAll());

  removeAllHoldings = () =>
    this.props.dispatch(CompareAccount.removeAllHoldings());

  removeAllGics = () => this.props.dispatch(CompareAccount.removeAllGics());

  isDisabled = () => {
    // returns true if the compare button is disabled, false otherwise
    const { compareAccState } = this.props;
    const { holdings, gic_new_generic_ticker, gic_info } = compareAccState;

    const { gic_rate, gic_ticker } = gic_new_generic_ticker
      ? gic_info[gic_new_generic_ticker] || {}
      : {};

    const totalWeight = Object.values(holdings)
      .reduce((total, value) => (total += Number(value.weight)), 0)
      .toFixed(2);

    const gicWeight = this.getNonTypicalWeight(gic_new_generic_ticker);

    return (
      ((gicWeight || gic_rate || gic_ticker) &&
        !(gicWeight && gic_rate && gic_ticker)) ||
      totalWeight < 100 - marginOfError ||
      totalWeight > 100 + marginOfError
    );
  };

  handleChange = (e) => {
    const { dispatch, compareAccState } = this.props;
    const { gic_new_generic_ticker } = compareAccState;
    const { name, value } = e.target;
    const correctNum = value === '' ? '' : Number(value);
    const num =
      correctNum === ''
        ? ''
        : Number.isInteger(correctNum)
        ? correctNum
        : value.split('.')[1].length >= 2
        ? Number(
            `${value.split('.')[0]}.${value.split('.')[1].substring(0, 2)}`
          )
        : correctNum;
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);
    const ticker =
      name === 'btc_weight'
        ? '~BTCUSDT'
        : name === 'eth_weight'
        ? '~ETHUSDT'
        : gic_new_generic_ticker;

    if (
      name === 'gic_weight' ||
      name === 'btc_weight' ||
      name === 'eth_weight'
    ) {
      dispatch(CompareAccount.addGicBtcEth(ticker));

      if (!num && num !== 0) {
        dispatch(CompareAccount.remove(ticker));
      } else if (num > 100) {
        dispatch(CompareAccount.updateWeight(ticker, 100));
      } else if (!negative) {
        dispatch(CompareAccount.updateWeight(ticker, num));
      }
    } else if (name === 'gic_rate') {
      if (num > 100) {
        dispatch(CompareAccount.updateInterestRate(ticker, 1));
      } else if (!negative) {
        dispatch(
          CompareAccount.updateInterestRate(
            ticker,
            value === '' ? '' : (num / 100).toFixed(4)
          )
        );
      }
      return false;
    }
    return false;
  };

  analyzeWhatIf = () => {
    const { dispatch, history, compareAccState } = this.props;
    const gicFormat = /^GIC\d*:MKT$/;
    Storage.removeItem('pwpa-has-exclude-holding');
    const params = Object.values(compareAccState.holdings).reduce(
      (total, item) => {
        if (
          (item.weight && gicFormat.test(item.ticker)) ||
          (item.weight && item.ticker === '~BTCUSDT') ||
          (item.weight && item.ticker === '~ETHUSDT') ||
          (item.weight &&
            item.unadjusted_closing_price &&
            item.unadjusted_closing_price.CAD)
        ) {
          const weight = item.weight / 100;
          total.symbols.push(item.ticker);
          total.weights.push(weight);
        }

        return total;
      },
      { symbols: [], weights: [] }
    );

    if (
      compareAccState.gic_info &&
      Object.keys(compareAccState.gic_info).length
    ) {
      dispatch(
        Storage.setItem('pwpa-portfolio', {
          portfolio_type: 'custom',
          region: 'CA',
          symbols: params.symbols.join(','),
          weights: params.weights.join(','),
          gic_info: compareAccState.gic_info,
          is_linked: true,
        })
      );
    } else {
      dispatch(
        Storage.setItem('pwpa-portfolio', {
          portfolio_type: 'custom',
          region: 'CA',
          symbols: params.symbols.join(','),
          weights: params.weights.join(','),
          gic_info: {},
          is_linked: true,
        })
      );
    }

    return history.push('/portfolio/unsaved/overview');
  };

  renderHoldingsTable = () => {
    const { compareAccState, dispatch } = this.props;

    const numHoldings = Object.keys(
      this.filterOutGICAndDigitalCurrency()
    ).length;

    return (
      <Segment
        className="basic compact"
        style={{
          marginTop: '0.5rem',
          padding: '0rem',
          marginBottom: '0rem',
          opacity: compareAccState.isFetching ? 0.5 : 1,
          paddingBottom: '0',
          width: '700px',
        }}
      >
        {!!numHoldings && (
          <RemoveAll
            style={{ top: '3px', textAlign: 'center' }}
            onClick={this.removeAllHoldings}
          >
            clear
          </RemoveAll>
        )}

        <Table
          textAlign="center"
          style={{
            marginBottom: '0rem',
            marginTop: !numHoldings && '0.7rem',
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Asset
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Exchange
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Class
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Price
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  background: 'rgba(245,93,90,0.2)',
                  position: 'relative',
                  paddingRight: '3rem',
                }}
              >
                Weight{' '}
                <Popup
                  trigger={
                    <div
                      className="round-weights-button"
                      onClick={() =>
                        dispatch(CompareAccount.roundWeightsToWholeNumbers())
                      }
                    >
                      <img
                        src={RoundDecimalImage}
                        alt="round weights icon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  }
                  wide
                  position="top center"
                  content="Round all weights to whole numbers."
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(this.filterOutGICAndDigitalCurrency()).map(
              (security) => (
                <StyledRow
                  negative={!security.unadjusted_closing_price.CAD}
                  key={security.ticker}
                  style={{
                    height: '70px',
                    position: 'relative',
                  }}
                >
                  <Table.Cell
                    width={5}
                    style={{
                      position: 'relative',
                      zIndex: '0',
                      paddingLeft: '1.2rem',
                    }}
                  >
                    <i
                      className="close"
                      onClick={() =>
                        dispatch(CompareAccount.remove(security.ticker))
                      }
                      style={{
                        zIndex: '1',
                        position: 'absolute',
                        top: '50%',
                        left: '0',
                      }}
                    >
                      +
                    </i>

                    {security.ticker || 'N/A'}

                    <SecurityName>{security.long_name || 'N/A'}</SecurityName>
                    {!security.unadjusted_closing_price.CAD && (
                      <Error>
                        <Popup
                          trigger={
                            <Icon
                              style={{
                                transform: 'translate(-20px, -4px)',
                                position: 'absolute',
                                display: 'block',
                                marginTop: '0',
                              }}
                              name="exclamation circle"
                            />
                          }
                          position="left center"
                          content="Unfortunately, this security cannot be added as its price is $0"
                        />
                      </Error>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {!security.exchange_symbol ||
                    security.exchange_symbol === 'NULL'
                      ? 'N/A'
                      : security.exchange_symbol}
                  </Table.Cell>
                  <Table.Cell>
                    {security.global_asset_class || 'N/A'}
                  </Table.Cell>
                  <Table.Cell>
                    $
                    {this.round(
                      security.unadjusted_closing_price[
                        security.default_currency
                      ] || 0
                    )}
                    <Flag
                      name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                      style={{ marginLeft: '0.3rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {!!security.unadjusted_closing_price.CAD ? (
                      <Weight
                        ticker={security.ticker}
                        value={security.weight}
                      />
                    ) : (
                      'N/A'
                    )}
                  </Table.Cell>
                </StyledRow>
              )
            )}
          </Table.Body>
        </Table>

        {!numHoldings ? (
          <div style={{ marginTop: '0.5rem' }}>
            Use the search bar above to add holdings to this "what-if"
            portfolio.
          </div>
        ) : null}
      </Segment>
    );
  };

  renderGICTable = () => {
    const { compareAccState, dispatch } = this.props;
    const { gic_info } = compareAccState;

    return !this.showAddGICToggle() ? (
      <Segment
        className="basic compact"
        style={{
          marginTop: '2rem',
          padding: '0rem',
          marginBottom: '0rem',
          opacity: compareAccState.isFetching ? 0.5 : 1,
          paddingBottom: '0',
          width: '700px',
        }}
      >
        <Header style={{ textAlign: 'center' }}>GIC/HISA</Header>
        <RemoveAll
          style={{ top: '23px', textAlign: 'center' }}
          onClick={this.removeAllGics}
        >
          clear
        </RemoveAll>
        <Table
          textAlign="center"
          style={{
            marginBottom: '0rem',
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Ticker/Name
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Asset Class
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Value
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                Interest Rate
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  background: 'rgba(245,93,90,0.2)',
                  position: 'relative',
                  paddingRight: '3rem',
                }}
              >
                Weight{' '}
                <Popup
                  trigger={
                    <div
                      className="round-weights-button"
                      onClick={() =>
                        dispatch(CompareAccount.roundWeightsToWholeNumbers())
                      }
                    >
                      <img
                        src={RoundDecimalImage}
                        alt="round weights icon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  }
                  wide
                  position="top center"
                  content="Round all weights to whole numbers."
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(this.getGICHoldings()).map((security) => (
              <StyledRow
                negative={!security.unadjusted_closing_price.CAD}
                key={security.ticker}
                style={{
                  height: '70px',
                  position: 'relative',
                }}
              >
                <Table.Cell
                  width={4}
                  style={{
                    position: 'relative',
                    zIndex: '0',
                    paddingLeft: '1.2rem',
                  }}
                >
                  <i
                    className="close"
                    onClick={() =>
                      dispatch(CompareAccount.remove(security.ticker))
                    }
                    style={{
                      zIndex: '1',
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                    }}
                  >
                    +
                  </i>

                  {gic_info[security.ticker].gic_ticker ||
                    security.ticker ||
                    'N/A'}

                  <SecurityName>GIC or HISA</SecurityName>
                  {!security.unadjusted_closing_price.CAD && (
                    <Error>
                      <Popup
                        trigger={
                          <Icon
                            style={{
                              transform: 'translate(-20px, -4px)',
                              position: 'absolute',
                              display: 'block',
                              marginTop: '0',
                            }}
                            name="exclamation circle"
                          />
                        }
                        position="left center"
                        content="Unfortunately, this security cannot be added as its price is $0"
                      />
                    </Error>
                  )}
                </Table.Cell>
                <Table.Cell>Fixed Income</Table.Cell>

                <Table.Cell width={2}>
                  $
                  {this.round(
                    security.unadjusted_closing_price[
                      security.default_currency
                    ] || 0
                  )}
                  <Flag
                    name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                    style={{ marginLeft: '0.3rem' }}
                  />
                </Table.Cell>
                <Table.Cell with={3}>
                  <InterestRate
                    ticker={security.ticker}
                    value={
                      gic_info[security.ticker] &&
                      gic_info[security.ticker].gic_rate !== ''
                        ? Math.round(
                            gic_info[security.ticker].gic_rate * 10000
                          ) / 100
                        : ''
                    }
                  />
                </Table.Cell>
                <Table.Cell width={3}>
                  {!!security.unadjusted_closing_price.CAD ? (
                    <Weight ticker={security.ticker} value={security.weight} />
                  ) : (
                    'N/A'
                  )}
                </Table.Cell>
              </StyledRow>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    ) : null;
  };

  render() {
    const { compareAccState, account, dispatch, type, selectedAccountList } =
      this.props;
    const {
      search,
      addBtcToggle,
      isFetching,
      gic_new_generic_ticker,
      addGicToggle,
      gic_info,
      isFetchingSearchResults,
    } = compareAccState;

    const { gic_ticker, gic_name, gic_rate } = gic_new_generic_ticker
      ? gic_info[gic_new_generic_ticker] || {}
      : {};
    const gicWeight = this.getNonTypicalWeight(gic_new_generic_ticker); // the weight of the gic that's being edited in the gic toggle (NOT in the GIC table)
    const btcWeight = this.getNonTypicalWeight('~BTCUSDT');
    const ethWeight = this.getNonTypicalWeight('~ETHUSDT');

    return (
      <div style={{ height: '100%' }}>
        <Segment
          style={{
            minHeight: '80vh',
            margin: '1rem',
            padding: '2rem',
            textAlign: 'center',
          }}
        >
          {isFetching ? (
            <Loading
              style={{
                top: '-3rem',
                marginLeft: '-3rem',
                height: 'calc(100% + 3rem)',
              }}
            />
          ) : null}
          <StyledDiv>
            <Fade duration={1500}>
              <div>
                <Header size="large">
                  {type ? (
                    type === 'multiple' ? (
                      <div>
                        Grouped Accounts
                        <Header.Subheader>{`${selectedAccountList.length} accounts selected`}</Header.Subheader>
                      </div>
                    ) : (
                      <div>
                        Overview
                        <Header.Subheader>
                          Key account information
                        </Header.Subheader>
                      </div>
                    )
                  ) : (
                    <div>
                      {account.user_institution}
                      <Header.Subheader>{account.label}</Header.Subheader>
                    </div>
                  )}
                </Header>
                <Divider fitted />
                <Header
                  size="medium"
                  style={{ maxWidth: '48rem', margin: '0 auto' }}
                >
                  Want to tweak your account?
                  <Header.Subheader style={{ margin: '0 auto' }}>
                    Create a{' '}
                    <span style={{ fontStyle: 'italic' }}>What-if</span>{' '}
                    portfolio below and see how it stacks up to the current one.
                    You can also save it (with or without any modifications) as
                    a portfolio. Once saved, it will show up in
                    <b> Portfolios</b> &rarr; <b>Revisit a Saved Portfolio</b>.
                    Additionally, you will be able to use this saved portfolio
                    in other tools such as in the dropdown menu of{' '}
                    <b>Planning</b> &rarr; <b>Target Saving</b> and in{' '}
                    <b>Portfolio Scorecard</b> &rarr; <b>Compare</b> (the scale
                    icon on the left of the Scorecard).
                  </Header.Subheader>
                  <Header.Subheader
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    <Icon name="warning sign" />
                    The comparison period is set to the longest common history
                    of the two portfolios you are comparing. Therefore, the
                    scores of the current portfolio may change, as its history
                    may now be shorter.
                  </Header.Subheader>
                </Header>
                <Divider style={{ width: '90%' }} />
                <TableContainer
                  redBorder={this.isDisabled() && this.labelData()}
                >
                  <Header style={{ marginTop: '0' }}>Holdings</Header>
                  <SearchTicker
                    loading={isFetchingSearchResults}
                    results={getTickerSearchResult(search)}
                    featureAction={CompareAccount}
                  />

                  {this.renderHoldingsTable()}
                  {this.renderGICTable()}

                  {/* over/under weight message with popup */}
                  {!!this.labelData() && this.isDisabled() && (
                    <Segment className="over-under-message">
                      {true && (
                        <Popup
                          mouseEnterDelay={300}
                          on="hover"
                          trigger={
                            <Label>
                              {`${this.labelData().val}% ${
                                this.labelData().msg
                              }`}
                            </Label>
                          }
                          position="bottom center"
                          wide
                          content="All weights in this portfolio should add to 100% ± 0.1%."
                        />
                      )}
                    </Segment>
                  )}

                  <div
                    className="compare-whatif-toggles"
                    style={{ marginTop: '3rem' }}
                  >
                    <div style={{ textAlign: 'left' }}>
                      {gic_new_generic_ticker && this.showAddGICToggle() && (
                        <div style={{ marginTop: '-1rem' }}>
                          <div
                            style={{ display: 'inline-block', width: '220px' }}
                          >
                            <Header
                              style={{
                                fontSize: '14.72px',
                                margin: '0',
                              }}
                            >
                              Add GIC/HISA
                              <Popup
                                trigger={
                                  <Icon
                                    name="question circle outline"
                                    style={{
                                      fontSize: '15px',
                                      verticalAlign: 'initial',
                                      color: 'dimgrey',
                                    }}
                                  />
                                }
                                position="top center"
                                wide
                              >
                                <Popup.Content>
                                  This category is for fixed interest securities
                                  such as Guaranteed Investment Certificates
                                  (GICs) or High Interest Savings Accounts
                                  (HISAs).
                                </Popup.Content>
                              </Popup>
                            </Header>

                            <Header.Subheader
                              style={{
                                color: 'rgba(0,0,0,0.5)',
                                fontSize: '0.9rem',
                              }}
                            >
                              (Optional)
                            </Header.Subheader>
                          </div>
                          <OptionsWrapper>
                            <div
                              className={cn('select left', {
                                active: addGicToggle,
                              })}
                              onClick={() =>
                                dispatch(
                                  CompareAccount.updateToggle(
                                    'addGicToggle',
                                    true
                                  )
                                )
                              }
                            >
                              Yes
                            </div>
                            <div
                              className={cn('select right', {
                                active: !addGicToggle,
                              })}
                              onClick={() => {
                                dispatch(
                                  CompareAccount.updateToggle(
                                    'addGicToggle',
                                    false
                                  )
                                );
                                dispatch(
                                  CompareAccount.remove(gic_new_generic_ticker)
                                );
                              }}
                            >
                              No
                            </div>
                          </OptionsWrapper>
                          {addGicToggle ? (
                            <div>
                              <Field>
                                <label>Weight</label>
                                <input
                                  name="gic_weight"
                                  type="number"
                                  placeholder="%"
                                  value={gicWeight}
                                  onChange={this.handleChange}
                                />
                                {gic_rate && !gicWeight ? (
                                  <ErrorLabel>Update weight</ErrorLabel>
                                ) : null}
                              </Field>
                              <Field>
                                <label style={{ textAlign: 'left' }}>
                                  Interest Rate
                                  <br />
                                  <span
                                    style={{
                                      color: 'rgba(0,0,0,0.5)',
                                      fontSize: '0.9rem',
                                    }}
                                  >
                                    (e.g., 0.7%)
                                  </span>
                                </label>
                                <input
                                  name="gic_rate"
                                  type="number"
                                  placeholder="%"
                                  value={
                                    gic_info[gic_new_generic_ticker] &&
                                    gic_info[gic_new_generic_ticker]
                                      .gic_rate !== ''
                                      ? Math.round(
                                          gic_info[gic_new_generic_ticker]
                                            .gic_rate * 10000
                                        ) / 100
                                      : ''
                                  }
                                  onChange={this.handleChange}
                                />
                                {gicWeight && !gic_rate ? (
                                  <ErrorLabel>Update interest rate</ErrorLabel>
                                ) : null}
                              </Field>
                              <Field center>
                                <label>
                                  Ticker
                                  <Popup
                                    trigger={
                                      <Icon
                                        name="question circle outline"
                                        style={{
                                          fontSize: '15px',
                                          verticalAlign: 'initial',
                                          color: 'dimgrey',
                                        }}
                                      />
                                    }
                                    position="top center"
                                    wide
                                  >
                                    <Popup.Content>
                                      For display in the Portfolio Scorecard.
                                    </Popup.Content>
                                  </Popup>
                                </label>
                                <input
                                  name="gic_ticker"
                                  value={gic_ticker || ''}
                                  onChange={(e) =>
                                    dispatch(
                                      CompareAccount.updateGicInfo(
                                        e.target.name,
                                        e.target.value
                                      )
                                    )
                                  }
                                />
                                {(gicWeight || gic_rate) && !gic_ticker ? (
                                  <ErrorLabel>Update ticker</ErrorLabel>
                                ) : null}
                              </Field>
                              <Field center>
                                <label>
                                  Name
                                  <Popup
                                    trigger={
                                      <Icon
                                        name="question circle outline"
                                        style={{
                                          fontSize: '15px',
                                          verticalAlign: 'initial',
                                          color: 'dimgrey',
                                        }}
                                      />
                                    }
                                    position="top center"
                                    wide
                                  >
                                    <Popup.Content>
                                      For display in the Portfolio Scorecard.
                                    </Popup.Content>
                                  </Popup>
                                </label>
                                <input
                                  name="gic_name"
                                  placeholder="(Optional)"
                                  value={gic_name || ''}
                                  onChange={(e) =>
                                    dispatch(
                                      CompareAccount.updateGicInfo(
                                        e.target.name,
                                        e.target.value
                                      )
                                    )
                                  }
                                />
                              </Field>
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div
                        style={
                          this.showAddGICToggle() ? { marginTop: '1rem' } : {}
                        }
                      >
                        <div
                          style={{ display: 'inline-block', width: '220px' }}
                        >
                          <Header
                            style={{
                              fontSize: '14.72px',
                              margin: '0',
                            }}
                          >
                            Add Bitcoin and/or Ethereum
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{
                                    fontSize: '15px',
                                    verticalAlign: 'initial',
                                    color: 'dimgrey',
                                  }}
                                />
                              }
                              position="top center"
                              wide
                            >
                              <Popup.Content>
                                Bitcoin and Ethereum can be added to test
                                hypothetical portfolio ideas. Please note that
                                our analysis is based on historical prices in
                                the coin market, as cryptocurrency ETFs are too
                                new. Our data goes back to October 2014 for
                                Bitcoin and to September 2015 for Ethereum.
                              </Popup.Content>
                            </Popup>
                          </Header>

                          <Header.Subheader
                            style={{
                              color: 'rgba(0,0,0,0.5)',
                              fontSize: '0.9rem',
                            }}
                          >
                            (Optional)
                          </Header.Subheader>
                        </div>

                        <OptionsWrapper btc>
                          <div
                            className={cn('select left', {
                              active: addBtcToggle,
                            })}
                            onClick={() =>
                              dispatch(
                                CompareAccount.updateToggle(
                                  'addBtcToggle',
                                  true
                                )
                              )
                            }
                          >
                            Yes
                          </div>
                          <div
                            className={cn('select right', {
                              active: !addBtcToggle,
                            })}
                            onClick={() => {
                              dispatch(CompareAccount.remove('~BTCUSDT'));
                              dispatch(CompareAccount.remove('~ETHUSDT'));
                              dispatch(
                                CompareAccount.updateToggle(
                                  'addBtcToggle',
                                  false
                                )
                              );
                            }}
                          >
                            No
                          </div>
                        </OptionsWrapper>
                        {addBtcToggle ? (
                          <div>
                            <Field>
                              <label>Bitcoin Weight</label>
                              <input
                                name="btc_weight"
                                type="number"
                                placeholder="%"
                                value={btcWeight}
                                onChange={this.handleChange}
                              />
                            </Field>
                            <Field>
                              <label>Ethereum Weight</label>
                              <input
                                name="eth_weight"
                                type="number"
                                placeholder="%"
                                value={ethWeight}
                                onChange={this.handleChange}
                              />
                            </Field>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </TableContainer>
                <div
                  style={{
                    marginTop: `${addBtcToggle ? '2rem' : '1rem'}`,
                  }}
                >
                  <Button
                    className={cn('left centered', {
                      disabled: this.isDisabled(),
                    })}
                    style={{
                      marginTop: '3rem',
                      position: 'relative',
                      display: 'inline-block',
                    }}
                    color={colors.red}
                    icon="law"
                    onClick={this.fetchCompare}
                  >
                    Compare
                  </Button>
                  <Button
                    className={cn('left centered', {
                      disabled: this.isDisabled(),
                    })}
                    style={{
                      margin: '3rem 0 0 1rem',
                      position: 'relative',
                      display: 'inline-block',
                    }}
                    color={colors.darkBlue}
                    icon="line chart"
                    onClick={this.analyzeWhatIf}
                  >
                    Analyze
                  </Button>
                </div>
              </div>
            </Fade>
          </StyledDiv>
        </Segment>
      </div>
    );
  }
}

CompareAccountView.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object.isRequired,
  compareAccState: PropTypes.object,
  match: PropTypes.object.isRequired,
  account: PropTypes.object,
  type: PropTypes.string,
  selectedAccountOverview: PropTypes.object,
  selectedAccountList: PropTypes.array,
  overview: PropTypes.object,
  user_region: PropTypes.string,
};

CompareAccountView.defaultProps = {
  dispatch: () => null,
  compareAccState: {},
  account: {},
  user_region: 'CA',
  type: '',
  selectedAccountOverview: {},
  selectedAccountList: [],
  overview: {},
};

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;

  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const TableContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  & {
    border: 2px solid ${(props) => (props.redborder ? 'red' : 'transparent')};

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-radius: 8px;
    position: relative;
    .over-under-message {
      padding: 0;
      position: absolute;

      bottom: 0;
      margin: 0;
      div {
        min-width: 120px;
        bottom: -11px;
      }
    }
  }
`;

const StyledDiv = styled.div`
  & {
    // border: 2px solid ${colors.blue};
    // border-radius: 4px;

    width: 100%;
    height: 100%;
    display: flex;
    padding: 3rem 0;
    flex-direction: column;
    justify-content: flex-start;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      div.search {
        .results {
          .category {
            align-items: flex-start;
          }
        }
      }
    }
    div.compare-whatif-toggles {
      width: 50%;
      display: block;
    }
  }
  .round-weights-button {
    display: inline-block;
    padding: 0 0.5rem;
    position: absolute;
    right: 0.5rem;
    bottom: 11px;
    background-color: white;
    border-radius: 0.28571429rem;

    border: 2px solid ${colors.red};
    color: black;
    height: 22px;
    -webkit-box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.3);
  }

  .round-weights-button:active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .round-weights-button:hover {
    cursor: pointer;
  }
`;

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative;

  .close {
    display: block;
    margin: -10px;
    z-index: 100;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const RemoveAll = styled.div`
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  width: 50px;
  font-size: 0.9rem;
  border-radius: 10px;
  line-height: 20px;
  border: 1px solid #dededf;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 1px solid #db2828;
    background: #fff6f6;
  }
`;

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #e2e2e2;
  border-radius: 50%;
  border-bottom-color: #909090;
  animation: spin 700ms linear infinite;
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;

const Label = styled.div`
  display: inline-block;
  position: absolute;
  bottom: ${(props) => (props.openDigCurrency ? '224px' : '146px')};
  left: 50%;
  color: white;
  transform: translateX(-50%);
  padding: 1px 20px;
  font-size: 0.9rem;
  background: ${colors.red};
  text-align: center;
  border-radius: 12px;
`;

const Field = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 32px;
  label {
    display: block;
    float: left;
  }
  input {
    position: absolute;
    left: calc(50% - 47px);
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 93px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;
    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
      text-align: ${(props) => (props.center ? 'center' : 'right')};
    }
    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const OptionsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 0.25rem;
  .select {
    display: inline-block;
    padding: 0.1rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }
    &.active {
      box-shadow: none;
      background-color: ${colors.blue};
      color: white;
    }
    &.left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const ErrorLabel = styled.div`
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  left: calc(50% + 58px);
  text-transform: initial;

  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(3%, 0) rotate(45deg);
    background: inherit;
    left: -5px;
    margin-top: -4px;
    top: 50%;
    border-width: 0 0 1px 1px;
    border-style: solid;
  }
`;
