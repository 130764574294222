import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pie, HorizontalBar } from 'react-chartjs-2';
import { Message } from 'components';
import assignColors from 'utils/assignColors';
import { translateText } from '../../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
  partnerName: state.Storage['partner-name'],
}))
export default class ExposureChart extends Component {
  hasNegative = (data) => data.filter((item) => item < 0).length !== 0;

  chartHasNullValues = (data) =>
    data.filter((item) => item == null).length !== 0;

  render() {
    const {
      title,
      labels,
      data,
      graphHeight,
      position,
      french,
      frontierMarkets,
      partnerName,
    } = this.props;
    const modifiedLabels =
      frontierMarkets && labels.includes('Other')
        ? labels.map((label) =>
            label === 'Other' ? 'Frontier Markets' : label
          )
        : labels;
    const translatedLabels = modifiedLabels.map((label) =>
      translateText(label, french)
    );

    const chartData = {
      labels: translatedLabels,
      datasets: [
        {
          data,
          backgroundColor: assignColors(labels, partnerName),
        },
      ],
    };

    const chartOptions = {
      showAllTooltips: !this.hasNegative(data),
      maintainAspectRatio: false,
      cutoutPercentage: this.hasNegative(data) ? 50 : 65,
      responsive: true,
      legend: {
        display: !this.hasNegative(data),
        position: position || 'right',
        labels: this.hasNegative(data) ? { display: false } : { display: true },
      },
      title: {
        display: true,
        fontSize: 20,
        text: title,
      },
      layout: {
        padding: 0,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, dataItem) =>
            `${
              Math.round(
                dataItem.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ] * 10000
              ) / 100
            }%`,
        },
        displayColors: false,
      },
    };

    return this.chartHasNullValues(data) ? (
      <Message visible warning>
        Chart data is unavailable
      </Message>
    ) : (
      <div
        style={{ height: graphHeight, minHeight: 350, marginBottom: '2rem' }}
      >
        {this.hasNegative(data) ? (
          <HorizontalBar data={chartData} options={chartOptions} />
        ) : (
          <Pie id="chart" data={chartData} options={chartOptions} />
        )}
      </div>
    );
  }
}

ExposureChart.propTypes = {
  graphHeight: PropTypes.number,
  title: PropTypes.string,
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  position: PropTypes.string,
  french: PropTypes.bool,
  frontierMarkets: PropTypes.bool,
  partnerName: PropTypes.string,
};

ExposureChart.defaultProps = {
  graphHeight: 300,
  title: 'Values Chart',
  position: '',
  french: false,
  frontierMarkets: false,
  partnerName: '',
};
