import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading = ({ active, fixed, french }) => (
  <Dimmer
    active={active}
    inverted
    style={{ zIndex: 100, position: fixed ? 'fixed' : 'absolute' }}
  >
    <Loader>{french ? '' : 'Loading'}</Loader>
  </Dimmer>
);

Loading.propTypes = {
  active: PropTypes.bool,
  fixed: PropTypes.bool,
  french: PropTypes.bool,
};

Loading.defaultProps = {
  active: false,
  fixed: false,
  french: false,
};

export default Loading;
