import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Divider } from 'semantic-ui-react';

import { Message } from 'components';

const Result = ({ result, isFetching }) =>
  !result ? null : (
    <div id="longevity-ref" style={{ opacity: isFetching ? 0.3 : 1 }}>
      <Divider
        style={{
          margin: '2rem 0 0.5rem -3rem',
          width: 'calc(100% + 6rem)',
          color: '#ececf0',
        }}
      />
      <div style={{ width: '80%', margin: '0 auto' }}>
        <Card>
          Based on the information you provided, the probability that you will
          outlive your money is: <br />
          <div className="result">
            {!result.ruinProbability
              ? 0
              : Math.round(result.ruinProbability * 100)}
            %
          </div>
          <div className="info">*based on 10,000 simulations</div>
        </Card>
        {result.ruinProbability > 0.05 && (
          <Conclusion>
            <Message icon visible positive>
              <Icon name="comments" style={{ fontSize: '2rem' }} />
              <Message.Content>
                As a comparison, if you want to target a small, say 5%,
                probability of ruin, you should withdraw no more than
                <span> ${result.maxSpendingNoRuin.toLocaleString()}</span> per
                year
              </Message.Content>
            </Message>
          </Conclusion>
        )}
      </div>
    </div>
  );

Result.propTypes = {
  result: PropTypes.object,
  isFetching: PropTypes.bool,
};

Result.defaultProps = {
  result: null,
  isFetching: false,
};

export default Result;

const Card = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1.1rem;
  max-width: 50%;
  float: left;

  .result {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0.5rem 0 0 0;
  }

  .info {
    font-size: 0.9rem;
    font-style: italic;
    color: #afafaf;
  }
`;

const Conclusion = styled.div`
  font-size: 1.3rem;
  clear: both;
  padding: 2rem 0 0 0;
  text-align: center;

  .visible {
    font-size: 1.1rem;
    margin: 1rem auto 0 auto;
    max-width: 40rem;
    text-align: left;
  }

  span {
    font-weight: 700;
    font-size: 1.3rem;
  }
`;
