import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Header, Icon, Divider } from 'semantic-ui-react';
import { DateLabel, Score, Table } from 'components';
import { translation } from '../localization';
import { translateBenchmarkHeading } from '../../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class Downside extends Component {
  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '-');

  getDownside = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0%');

  parseDate = (portfolio) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const { french } = this.props;
    const language = french ? 'fr' : 'en';

    return `${translation[language].MONTH[[init[1]]]} ${init[0]} ${
      french ? 'à' : 'to'
    } ${translation[language].MONTH[[end[1]]]} ${end[0]}`;
  };

  render() {
    const { analytics } = this.props;
    const { benchmark, data } = analytics;
    const { french } = this.props;
    const language = french ? 'fr' : 'en';

    return (
      <Container>
        <Score
          name={`${translation[language].DOWNSIDE} `}
          score={`${data.score.Volatility[benchmark][0]}%`}
          grade={data.score.Volatility[benchmark][1]}
          description="Scored on three downside measures of risk: maximum drawdown, downside risk, and downside capture (relative to those of the benchmark).<br />If your portfolio is just matching (rather than outperforming) the simple benchmark, it will get a pass, at 65%."
          portfolio={data.portfolio}
          pdf
        />
        {french ? (
          <div>
            <p>
              La protection contre les baisses découle de la capacité de votre
              portefeuille à limiter les pertes en cas de baisse du cours du
              marché.
            </p>
            <p>
              En gestion de portefeuille, la mesure traditionnelle du risque (ou
              de la volatilité) est l’écart-type des rendements historiques d’un
              portefeuille. Il s’agit d’une mesure du risque total du
              portefeuille. Il est présenté sous forme de pourcentage annualisé
              pour faciliter la comparaison, peu importe le portefeuille ou la
              période.
            </p>
            <p>
              Il est important de noter que le risque total comprend la
              possibilité de gains et de pertes. Au fil du temps, les rendements
              du portefeuille varieront au-dessus de la moyenne (ou de votre
              objectif de rendement), mais aussi en dessous. Par instinct, et
              aussi car c’est généralement un comportement rationnel, nous avons
              tendance à nous préoccuper davantage du risque de pertes que du
              risque de gains
            </p>
          </div>
        ) : (
          <div>
            <p>
              Downside protection speaks to your portfolio's ability to limit
              losses when there is a market downturn.
            </p>
            <p>
              In portfolio management, the traditional measure of risk (or
              volatility) is the standard deviation of a portfolio’s historical
              returns. It is a measure of the portfolio's <strong>total</strong>{' '}
              risk. It is reported as an annualized percentage for easy
              comparison across portfolios and time periods.
            </p>
            <p>
              It is important to note that total risk has an upside and a{' '}
              <strong>downside</strong>. Over time, portfolio returns will
              fluctuate above the average (or your target return), as well as{' '}
              <strong>below</strong>. It is human nature and often rational to
              be more concerned about downside risk than upside risk.
            </p>
          </div>
        )}

        <Header>
          <Header.Content>
            {translation[language].KEY_STATISTICS}
          </Header.Content>
          <Header.Subheader>
            <DateLabel
              str={`${this.parseDate(data.portfolio)} (${
                translation[language].INCLUSIVE
              })`}
            />
          </Header.Subheader>
        </Header>

        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                {translation[language].TOTAL_RISK}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This is the variability of your portfolio's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].DOWNSIDE_RISK}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This is the variability of your portfolio's returns below a target. The target used here is capital preservation; that is, a non-negative return."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].MAXIMUM_DRAWDOWN}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This is the maximum peak to trough loss observed during the period in question. It gives a different perspective of a portfolio's downside risk."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].DOWNSIDE_CAPTURE}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This measures whether your portfolio lost less than the stock market (as selected) when there was a market downturn. A downside capture of less than 100% indicates that your portfolio lost less than the stock market during those periods. A downside capture that is negative indicates that your portfolio actually generated positive returns when the stock market was in the red."
                  wide
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell>{translation[language].MY_PORTFOLIO}</Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.stdDev)}
              </Table.Cell>
              <Table.Cell>
                {this.getDownside(data.fratios.portfolio.dsr)}
              </Table.Cell>
              <Table.Cell>
                {this.getDownside(data.fratios.portfolio.maxDd)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.dscapture[benchmark])}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {translateBenchmarkHeading(
                  data.fratios[benchmark].name,
                  french
                )}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={data.fratios[benchmark].description}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].stdDev)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].dsr)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].maxDd)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].dscapture)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

Downside.propTypes = {
  analytics: PropTypes.object.isRequired,
  french: PropTypes.bool.isRequired,
};

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
