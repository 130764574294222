import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BuildCustom, BuildETF, CacheInputs } from 'actions';
import { Header, Segment, Feature, NeuCard } from 'components';
import { colors } from 'utils/colors';
import { PORTFOLIOS_CREATE_NEW, PORTFOLIOS_VIEW_MODEL } from 'utils/features';
import asset7 from 'images/asset_7.png';

@withRouter
@connect((state) => ({
  account: state.CustomAccount.account,
}))
class Landing extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(BuildCustom.removeAll());
    dispatch(BuildCustom.updateToggle('addGicToggle', false));
    dispatch(BuildCustom.updateToggle('addBtcToggle', false));
    dispatch(BuildETF.clearBuildETF());

    return dispatch(CacheInputs.clearField('portfolios', 'smart_risk_builder'));
  };

  render() {
    return (
      <Segment expand>
        <div>
          <img src={asset7} height="80" width="120" alt="Portfolio" />
          <Header size="1.5rem" color="black">
            Welcome to Portfolios
          </Header>
          <P>
            Let us help you build and analyze a brand new portfolio. Once
            created, you will see the Wealthscope portfolio scorecard and all
            the relevant analytics. You can also revisit a previously saved
            portfolio here, or see the Wealthscope model portfolios.
          </P>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Feature name={PORTFOLIOS_CREATE_NEW}>
              <NeuCard
                to="/portfolio/create"
                iconName="file"
                title="Create or Revisit a portfolio"
                description="Build, analyze a new portfolio or revisit a saved portfolio."
                color={colors.teal}
              />
            </Feature>
            <Feature name={PORTFOLIOS_VIEW_MODEL}>
              <NeuCard
                to="/portfolio/model"
                iconName="flag"
                title="View our Model Portfolios"
                description="See five simple ETF portfolios that have been optimized for different investor risk profiles."
                color={colors.teal}
              />
            </Feature>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 650px;
  margin: 0 auto 3rem auto;

  &.strike {
    text-decoration: line-through;
  }
`;
