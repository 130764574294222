import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Form, Radio } from 'semantic-ui-react';
import cn from 'classnames';

import { AccountsDropdown } from 'components';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class AccountValues extends Component {
  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown, cppDataset, oasDataset, cppEstimator } =
      this.props;
    const { inputs } = drawdown;
    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
      ];

      if (field === 'horizon') {
        const maxHorizon = 80;
        if (Number(val) >= maxHorizon) {
          // Display an error message for horizon exceeding the maximum
          this.setState({
            warning: {
              ...this.state.warning,
              horizon:
                'Please input the number of years you are expected to be retired for, rather than the age you expect to live until.',
            },
          });
          return maxHorizon;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) {
        return 70;
      }

      if (isOas && Number(age) < 65) {
        return 65;
      }

      if (Number(age) < 60) {
        return 60;
      } else if (Number(age) > 70) {
        return 70;
      }

      return age;
    };

    if (name === 'age') {
      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), true))
      ).amount;
      if (Number(value) < 60) {
        this.changeSelection('cppStarted', false);
        this.changeSelection('oasStarted', false);
      } else if (Number(value) < 65) {
        this.changeSelection('oasStarted', false);
        this.changeSelection('cppStarted', true);
      } else {
        this.changeSelection('oasStarted', true);
        this.changeSelection('cppStarted', true);
      }
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            age: parsedValue(name, value),
            ...(Number(value) > 60 && { cppAge: parsedValue(name, value) }),
            ...(Number(value) > 65 && { oasAge: parsedValue(name, value) }),
          },
          errors: {
            age: false,
            ...(Number(value) > 60 && inputs.cppAge && { cppAge: false }),
            ...(Number(value) > 65 && inputs.oasAge && { oasAge: false }),
            // ...(inputs.cppStarted &&
            //   inputs.cpp > cppMax && {
            //     cpp:
            //       'The benefit amount you entered exceeded the maximum for your current age.',
            //   }),
            ...(inputs.oasStarted &&
              inputs.oas > oasMax && {
                oas: 'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs.cppStarted && inputs.cpp <= cppMax && { cpp: null }),
            ...(inputs.oasStarted && inputs.oas <= oasMax && { oas: null }),
            ...(!inputs.cppStarted && { cppAge: null }),
            ...(!inputs.oasStarted && { oasAge: null }),
          },
          modified: true,
        })
      );
    }

    if (name === 'cppAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { cppAge: parsedValue(name, value), cpp: '' },
          errors: { cppAge: false, cpp: false },
          modified: true,
        })
      );
    }

    if (name === 'oasAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oasAge: parsedValue(name, value), oas: '' },
          errors: { oasAge: false, oas: false },
          modified: true,
        })
      );
    }

    if (name === 'cpp') {
      if (!inputs.cppAge && inputs.cppStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();

      const body = {
        retirement_year:
          Number(currentYear) + Number(inputs.cppAge) - Number(inputs.age),
        start_cpp_age: inputs.cppAge,
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs.cppAge !== '' && inputs.cppAge >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, cpp: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cpp: money },
            errors: { cpp: false },
            modified: true,
          })
        );
      }
    }

    if (name === 'oas') {
      if (!inputs.oasAge && inputs.oasStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      if (!inputs.oasAge || inputs.oasAge > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs.oasAge < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs.oasAge)
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs.oasAge,
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, oas: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oas: money },
          errors: { oas: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (inputs, portfolios) => {
    if (inputs.isRiskFree === undefined) return false;
    if (inputs.isRiskFree) return false;
    if (inputs.portfolio === undefined) return false;

    return !portfolios.find((p) => p.id === inputs.portfolio);
  };

  handleRadioChange = (e, { value, name }) => {
    this.setState({ [name]: value });
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );

    switch (name) {
      case 'initRegularFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initRegular: null, initRegAccList: [] },
            modified: true,
          })
        );
        break;
      case 'initRRIFFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initRRIF: null, initRRIFAccList: [] },
            modified: true,
          })
        );
        break;
      case 'totalTfsaFromAcc':
        this.props.dispatch(
          Planning.drawdownUpdateInput({
            inputs: { initTFSA: null, initTFSAAccList: [] },
            modified: true,
          })
        );
        break;
      default:
    }
  };

  submit = () => {
    const { dispatch, drawdown, portfolios } = this.props;
    const { inputs, errors } = drawdown;
    const validated = Object.keys(inputs).reduce(
      (acc, val) => {
        if (val === 'constantRets' && !inputs.isRiskFree) {
          return acc;
        } else if (val === 'constantRets' && inputs.isRiskFree) {
          if (Number(inputs[val]) <= 0) {
            return acc;
          } else if (inputs[val].toString().split('.')[1]) {
            acc[val] = Number.parseFloat(inputs[val]).toFixed(2);

            return acc;
          }
        }

        if (inputs[val] !== '') {
          acc[val] = inputs[val];
        }

        return acc;
      },
      {
        rrifAge: inputs.cppAge || 0,
      }
    );
    const required = [
      'age',
      'horizon',
      'endValue',
      'prov',
      'cppAge',
      'oasAge',
      'cpp',
      'oas',
      'initRegular',
      !inputs.isRiskFree ? 'pctCapGain' : '',
      !inputs.isRiskFree ? 'divYield' : '',
      !inputs.isRiskFree ? 'pctEligibleDiv' : '',
      'initRRIF',
      'initTFSA',
      !inputs.isRiskFree ? 'portfolio' : 'constantRets',
      !!Number(inputs.other) && 'otherHorizon',
      !inputs.isRiskFree ? 'scenario' : null,
    ];
    const group = {
      generalInformation: ['age', 'horizon', 'prov'],
      retirementIncome: ['cppAge', 'oasAge', 'cpp', 'oas', 'otherHorizon'],
      investmentPortfolio: ['constantRets', 'portfolio'],
      portfolioValues: [
        'initRegular',
        'pctCapGain',
        'divYield',
        'pctEligibleDiv',
        'initRRIF',
        'initTFSA',
        'endValue',
      ],
      investmentSecnarios: ['scenario'],
    };
    const groupOrder = [
      'generalInformation',
      'retirementIncome',
      'investmentPortfolio',
      'portfolioValues',
      'investmentSecnarios',
    ];

    const errorObject = required.reduce((acc, val) => {
      if (val && !validated[val]) {
        // Allow account values to be 0

        if (
          val === 'endValue' &&
          validated[val] !== 0 &&
          validated[val] !== undefined &&
          validated[val] !== null &&
          validated[val] !== ''
        ) {
          return acc;
        }
        acc[val] = true;
      }

      if (val === 'age' && Number(validated[val]) < 60) {
        acc[val] = '(cannot be less than 60)';
      }

      if (val === 'cpp') {
        if (typeof errors.cpp === 'string') {
          acc[val] = errors.cpp;
        }
      }

      if (val === 'oas') {
        if (typeof errors.oas === 'string') {
          acc[val] = errors.oas;
        }
      }

      if (val === 'cppAge') {
        if (inputs.cppStarted) {
          if (Number(validated[val]) < 60) {
            acc[val] =
              'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
          }
        } else if (!inputs.cppStarted) {
          if (Number(validated[val] > 70 || Number(validated[val]) < 60)) {
            acc[val] =
              'You can only start collecting CPP/QPP benefits between age 60 and age 70.';
          }
        }
      }

      if (val === 'oasAge') {
        if (inputs.oasStarted) {
          if (Number(validated[val]) < 65) {
            acc[val] =
              'You can only start collecting OAS benefits between age 65 and age 70.';
          }
        } else if (!inputs.oasStarted) {
          if (Number(validated[val] > 70 || Number(validated[val]) < 65)) {
            acc[val] =
              'You can only start collecting OAS benefits between age 65 and age 70.';
          }
        }
      }

      if (
        val === 'otherHorizon' &&
        Number(validated[val]) > Number(validated.horizon || 0)
      ) {
        acc[val] = '(cannot be longer than your planning horizon)';
      }

      return acc;
    }, {});

    const hasError = Object.values(errorObject).find((val) => val);

    if (hasError) {
      const base = this.state.isAssumptionsOpen ? 1230 : 350;
      const blocksTop = [0, 230, 750, 930, 1050];
      let i = 0;
      for (; i < groupOrder.length; i += 1) {
        let leaveLoop = false;
        for (let j = 0; j < group[groupOrder[i]].length; j += 1) {
          if (group[groupOrder[i]][j] in errorObject) {
            leaveLoop = true;
            break;
          }
        }

        if (leaveLoop) {
          break;
        }
      }

      this.scrollHandler(base + blocksTop[i], 600);
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {},
          errors: errorObject,
          modified: true,
        })
      );
    }

    if (inputs.cppStarted) {
      if (!inputs.cppAge) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age },
            modified: true,
          })
        );
      }
    }

    if (inputs.oasStarted) {
      if (!inputs.oasAge) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age },
            modified: true,
          })
        );
      }
    }

    const selectedPortfolio = this.getPortfolio(portfolios, inputs.portfolio);

    const portfolio = !inputs.isRiskFree
      ? {
          isRiskFree: false,
          portId: selectedPortfolio.id,
          symbols: selectedPortfolio.symbols,
          weights: selectedPortfolio.weights,
          gic_info: selectedPortfolio.gic_info,
        }
      : {
          constantRets: Number(validated.constantRets) / 100,
          isRiskFree: true,
        };

    dispatch(
      Planning.drawdownUpdateInput({
        inputs: validated,
        errors: {},
        modified: false,
      })
    );
    dispatch(Planning.updateJustsaveOrFetch(false));

    return dispatch(
      Planning.calcDrawdown({
        ...validated,
        ...portfolio,
        init_date: portfolio.init_date,
        otherHorizon: Number(validated.otherHorizon) || 0,
        divYield: inputs.isRiskFree ? 0 : Number(validated.divYield) / 100,
        pctCapGain: inputs.isRiskFree ? 0 : Number(validated.pctCapGain) / 100,
        pctEligibleDiv: inputs.isRiskFree
          ? 0
          : Number(validated.pctEligibleDiv) / 100,
        pensionInf: validated.pensionInf,
        scenario: validated.scenario,
      })
    ).then(() => {
      const offsetY = this.state.isAssumptionsOpen
        ? !inputs.isRiskFree
          ? 2900
          : 2700
        : !inputs.isRiskFree
        ? 2100
        : 1900;
      this.scrollHandler(offsetY, 600);
    });
  };

  render() {
    const { inputs, errors } = this.props.drawdown;
    const { accountList } = this.props;

    return (
      <div>
        <Section single>
          {/* <div className="heading">Account Values</div> */}

          <div>
            <div className="title">Total non-registered (taxable)</div>
            {inputs.initRegularFromAcc ? (
              <AccountsDropdown
                inputs={inputs}
                error={errors.initRegular}
                accountList={accountList}
                selectedAccList={inputs.initRegAccList}
                excludedList={inputs.initRRIFAccList.concat(
                  inputs.initTFSAAccList
                )}
                savedAccountsValue={inputs.initRegular || 0}
                assignAccountsValue={(selectedAccounts, accountsValue) => {
                  this.props.dispatch(
                    Planning.drawdownUpdateInput({
                      inputs: {
                        initRegAccList: selectedAccounts,
                        initRegular: accountsValue,
                      },

                      errors: { initRegular: false },
                      modified: true,
                    })
                  );
                }}
                float
              />
            ) : (
              <Input
                className={cn('large', {
                  invalid: errors.initRegular ? 'true' : undefined,
                })}
                name="initRegular"
                type="number"
                placeholder="$"
                value={inputs.initRegular}
                onChange={this.handleChange}
              />
            )}
            <div style={{ margin: '0 0 0.5rem 1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name="initRegularFromAcc"
                    value={false}
                    checked={!inputs.initRegularFromAcc}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="initRegularFromAcc"
                    value
                    checked={inputs.initRegularFromAcc}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          {!inputs.isRiskFree ? (
            <ul>
              <li className="visible">
                <label>
                  Roughly what % of this value is from capital gains?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="To calculate this percentage, subtract total book costs from total market value (giving you the amount of capital gains) and divide by the total market value. We require this information as capital gains are taxed differently from dividends."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors.pctCapGain ? 'true' : undefined,
                  })}
                  name="pctCapGain"
                  type="number"
                  placeholder="%"
                  value={inputs.pctCapGain || ''}
                  onChange={this.handleChange}
                />
              </li>

              <li className="visible">
                <label>
                  What is the dividend yield?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="We assume that all dividends are withdrawn from taxable accounts for spending. The dividend yield is pre-populated given the portfolio you selected. This figure is used to calculate the dividend tax."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors.divYield ? 'true' : undefined,
                  })}
                  name="divYield"
                  type="number"
                  placeholder="%"
                  value={inputs.divYield || ''}
                  onChange={this.handleChange}
                />
              </li>

              <li className="visible">
                <label>
                  Percentage of dividends from Canadian stocks?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Canadian dividends are eligible for a tax credit."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors.pctEligibleDiv ? 'true' : undefined,
                  })}
                  name="pctEligibleDiv"
                  type="number"
                  placeholder="%"
                  value={inputs.pctEligibleDiv || ''}
                  onChange={this.handleChange}
                />
              </li>
            </ul>
          ) : null}

          <div>
            <div>
              <div className="title">
                Total Registered (RRIF/LIF)
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content="Your RRSP/Defined Contribution (DC) Plan turn into an income fund (RRIF/LIF) at retirement."
                  wide
                />
              </div>
              {inputs.initRRIFFromAcc ? (
                <AccountsDropdown
                  inputs={inputs}
                  error={errors.initRRIF}
                  accountList={accountList}
                  excludedList={inputs.initRegAccList.concat(
                    inputs.initTFSAAccList
                  )}
                  selectedAccList={inputs.initRRIFAccList}
                  savedAccountsValue={inputs.initRRIF || 0}
                  assignAccountsValue={(selectedAccounts, accountsValue) => {
                    this.props.dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          initRRIFAccList: selectedAccounts,
                          initRRIF: accountsValue,
                        },
                        errors: { initRRIF: false },
                        modified: true,
                      })
                    );
                  }}
                  float
                />
              ) : (
                <Input
                  className={cn('large', {
                    invalid: errors.initRRIF ? 'true' : undefined,
                  })}
                  name="initRRIF"
                  type="number"
                  placeholder="$"
                  value={inputs.initRRIF}
                  onChange={this.handleChange}
                />
              )}
            </div>

            <div style={{ marginLeft: '1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name="initRRIFFromAcc"
                    value={false}
                    checked={!inputs.initRRIFFromAcc}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="initRRIFFromAcc"
                    value
                    checked={inputs.initRRIFFromAcc}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          <div>
            <div>
              <div className="title">Total TFSA</div>
              {inputs.totalTfsaFromAcc ? (
                <AccountsDropdown
                  inputs={inputs}
                  error={errors.initTFSA}
                  accountList={accountList}
                  excludedList={inputs.initRegAccList.concat(
                    inputs.initRRIFAccList
                  )}
                  selectedAccList={inputs.initTFSAAccList}
                  savedAccountsValue={inputs.initTFSA || 0}
                  assignAccountsValue={(selectedAccounts, accountsValue) => {
                    this.props.dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          initTFSAAccList: selectedAccounts,
                          initTFSA: accountsValue,
                        },
                        errors: { initTFSA: false },
                        modified: true,
                      })
                    );
                  }}
                  float
                />
              ) : (
                <Input
                  className={cn('large', {
                    invalid: errors.initTFSA ? 'true' : undefined,
                  })}
                  name="initTFSA"
                  type="number"
                  placeholder="$"
                  value={inputs.initTFSA}
                  onChange={this.handleChange}
                />
              )}
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name="totalTfsaFromAcc"
                    value={false}
                    checked={!inputs.totalTfsaFromAcc}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name="totalTfsaFromAcc"
                    value
                    checked={inputs.totalTfsaFromAcc}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          <div className="title">
            Bequest
            <Popup
              trigger={<Icon name="question circle outline" />}
              position="top center"
              content="This is your legacy, for your family and charitable donations."
              wide
            />
          </div>

          <ul>
            <li className="visible">
              <label>
                How much would you like to leave (in today's dollars)?
              </label>
              <Input
                className={cn({
                  invalid: errors.endValue ? 'true' : undefined,
                })}
                name="endValue"
                type="number"
                placeholder="$"
                value={inputs.endValue}
                onChange={this.handleChange}
              />
            </li>
          </ul>
        </Section>
      </div>
    );
  }
}

AccountValues.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  accountList: PropTypes.array,
};

AccountValues.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  accountList: [],
};

const Section = styled.section`
  padding-left: 2rem;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
