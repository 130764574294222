import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Pie, HorizontalBar } from 'react-chartjs-2';
import { Message } from 'components';
import assignColors from 'utils/assignColors';

export default class ExposureChart extends Component {
  hasNegative = (data) => data.filter((item) => item < 0).length !== 0;

  chartHasNullValues = (data) =>
    data.filter((item) => item == null).length !== 0;

  render() {
    const { title, labels, data, graphHeight, position, frontierMarkets } =
      this.props;
    const modifiedLabels =
      frontierMarkets && labels.includes('Other')
        ? labels.map((label) =>
            label === 'Other' ? 'Frontier Markets' : label
          )
        : labels;
    const chartData = {
      labels: modifiedLabels,
      datasets: [
        {
          data,
          backgroundColor: assignColors(labels),
        },
      ],
    };

    const chartOptions = {
      showAllTooltips: !this.hasNegative(data),
      maintainAspectRatio: false,
      cutoutPercentage: this.hasNegative(data) ? 50 : 65,
      responsive: true,
      legend: {
        display: !this.hasNegative(data),
        position: position || 'right',
        labels: this.hasNegative(data) ? { display: false } : { display: true },
      },
      title: {
        display: true,
        fontSize: 20,
        text: title,
      },
      layout: {
        padding: 0,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, dataItem) =>
            `${
              Math.round(
                dataItem.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ] * 10000
              ) / 100
            }%`,
        },
        displayColors: false,
      },
    };

    return this.chartHasNullValues(data) ? (
      <Message visible warning>
        Chart data is unavailable
      </Message>
    ) : (
      <div
        style={{ height: graphHeight, minHeight: 350, marginBottom: '2rem' }}
      >
        {this.hasNegative(data) ? (
          <HorizontalBar data={chartData} options={chartOptions} />
        ) : (
          <Pie id="chart" data={chartData} options={chartOptions} />
        )}
      </div>
    );
  }
}

ExposureChart.propTypes = {
  graphHeight: PropTypes.number,
  title: PropTypes.string,
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  position: PropTypes.string,
  frontierMarkets: PropTypes.bool,
};

ExposureChart.defaultProps = {
  graphHeight: 300,
  title: 'Values Chart',
  position: '',
  frontierMarkets: false,
};
