import * as Alert from './alert';
import { request } from './api';

/**
 * Action constants
 */
export const FUNDALLOCATOR_REQUEST = 'FUNDALLOCATOR_REQUEST';

export const FUNDALLOCATOR_SEARCH_SUCCESS = 'FUNDALLOCATOR_SEARCH_SUCCESS';
export const FUNDALLOCATOR_ADD_SUCCESS = 'FUNDALLOCATOR_ADD_SUCCESS';
export const FUNDALLOCATOR_UPDATE_WEIGHT = 'FUNDALLOCATOR_UPDATE_WEIGHT';
export const FUNDALLOCATOR_REMOVE_SECURITY = 'FUNDALLOCATOR_REMOVE_SECURITY';
export const FUNDALLOCATOR_REMOVE_ALL = 'FUNDALLOCATOR_REMOVE_ALL';
export const FUNDALLOCATOR_LOAD_PORTFOLIO = 'FUNDALLOCATOR_LOAD_PORTFOLIO';
export const FUNDALLOCATOR_CALCULATE_REQUEST =
  'FUNDALLOCATOR_CALCULATE_REQUEST';
export const FUNDALLOCATOR_CALCULATED = 'FUNDALLOCATOR_CALCULATED';

export const FUNDALLOCATOR_CLEAR = 'FUNDALLOCATOR_CLEAR';

/**
 * Action creators
 */
export function add(id, weight = 0) {
  return (dispatch) => {
    dispatch({ type: FUNDALLOCATOR_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) =>
        dispatch({ type: FUNDALLOCATOR_ADD_SUCCESS, security: data, weight }),
      (error) => {
        dispatch({ type: FUNDALLOCATOR_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function calculate(body) {
  return (dispatch) => {
    dispatch({ type: FUNDALLOCATOR_CALCULATE_REQUEST });

    return dispatch(request('post', '/accounts/rebalancing/', { body })).then(
      (data) => {
        dispatch({ type: FUNDALLOCATOR_CALCULATED, data });
      },
      (error) => {
        dispatch({ type: FUNDALLOCATOR_CALCULATED });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: FUNDALLOCATOR_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: FUNDALLOCATOR_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: FUNDALLOCATOR_SEARCH_SUCCESS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function updateWeight(ticker, value) {
  return {
    type: FUNDALLOCATOR_UPDATE_WEIGHT,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function remove(ticker) {
  return {
    type: FUNDALLOCATOR_REMOVE_SECURITY,
    ticker,
  };
}

export function removeAll() {
  return {
    type: FUNDALLOCATOR_REMOVE_ALL,
  };
}

// Clears all local fundAllocator data when leaving client mode
export function clearFundAllocator() {
  return { type: FUNDALLOCATOR_CLEAR };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
