import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Divider } from 'semantic-ui-react';

import { Message } from 'components';
import { isGlobeInvestor } from 'utils/helpers';

const Result = ({ result, isFetching, partnerName }) =>
  !result ? null : (
    <div id="longevity-ref" style={{ opacity: isFetching ? 0.3 : 1 }}>
      {!isGlobeInvestor(partnerName) && (
        <Divider
          style={{
            margin: '2rem 0 0.5rem -3rem',
            width: 'calc(100% + 6rem)',
            color: '#ececf0',
          }}
        />
      )}
      <div style={{ width: '80%', margin: '0 auto' }}>
        <Card>
          This portfolio is expected to last you: <br />
          <div className="result">{result.portLongevity} years</div>
          <div className="info">*based on 10,000 simulations</div>
        </Card>

        <Card style={{ float: 'right' }}>
          You are expected to live for another: <br />
          <div className="result">{result.lifeExpectancy} years</div>
          <div className="info">*based on your age & sex</div>
        </Card>

        {result.portLongevity >= result.lifeExpectancy ? (
          <Conclusion>
            Congratulations! You are not expected to outlive your savings.{' '}
            <br />
            <Message icon visible positive>
              <Icon name="comments" style={{ fontSize: '2rem' }} />
              <Message.Content>
                With your current savings, chosen portfolio, and life
                expectancy, you could draw up to
                <span> ${result.maxSpending.toLocaleString()}</span> per year
                from your savings and equate your expected portfolio longevity
                to your expected human longevity.
              </Message.Content>
            </Message>
          </Conclusion>
        ) : (
          <Conclusion>
            Oops! You may want to revisit your spending habits and/or investment
            portfolio.
            <br />
            <Message icon visible warning>
              <Icon name="comments" style={{ fontSize: '2rem' }} />
              <Message.Content>
                With your current savings, chosen portfolio, and life
                expectancy, you should not draw more than
                <span> ${result.maxSpending.toLocaleString()}</span> per year
                from your savings in order to equate your expected portfolio
                longevity to your expected human longevity.
              </Message.Content>
            </Message>
          </Conclusion>
        )}
      </div>
    </div>
  );

Result.propTypes = {
  result: PropTypes.object,
  isFetching: PropTypes.bool,
  partnerName: PropTypes.string,
};

Result.defaultProps = {
  result: null,
  isFetching: false,
  partnerName: '',
};

export default Result;

const Card = styled.div`
  padding: 1rem;
  text-align: center;
  font-size: 1.1rem;
  max-width: 50%;
  float: left;

  .result {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0.5rem 0 0 0;
  }

  .info {
    font-size: 0.9rem;
    font-style: italic;
    color: #afafaf;
  }
`;

const Conclusion = styled.div`
  font-size: 1.3rem;
  clear: both;
  padding: 2rem 0 0 0;
  text-align: center;

  .visible {
    font-size: 1.1rem;
    margin: 1rem auto 0 auto;
    max-width: 40rem;
    text-align: left;
  }

  span {
    font-weight: 700;
    font-size: 1.3rem;
  }
`;
