import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FindETF from './FindETF';
import BuildETF from './BuildETF';
import BuildCustom from './BuildCustom';
import BuildML from './BuildML';
import Analytics from './Analytics';
import OpenUser from './OpenUser';
import OpenModel from './OpenModel';
import Create from './Create';
import Landing from './Landing';
import PortfolioPDF from './PDF';

const NBPortfolio = () => (
  <div style={{ height: '90%' }}>
    <Switch>
      <Route exact path="/portfolio/landing" component={Landing} />
      <Route exact path="/portfolio/user" component={OpenUser} />
      <Route exact path="/portfolio/model" component={OpenModel} />
      <Route exact path="/portfolio/create" component={Create} />
      <Route exact path="/portfolio/find" component={FindETF} />
      <Route exact path="/portfolio/:id/pdf" component={PortfolioPDF} />
      <Route exact path="/portfolio/build-custom/:id" component={BuildCustom} />
      <Route path="/portfolio/build-etf/:id" component={BuildETF} />
      <Route exact path="/portfolio/build-ml/:id" component={BuildML} />
      <Route path="/portfolio/:id" component={Analytics} />
    </Switch>
  </div>
);

export default NBPortfolio;
