import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Planning, Storage } from 'actions';
import { Header, Segment, Feature, NeuCard } from 'components';
import { colors } from 'utils/colors';
import { PLANNING_ACCUMULATION, PLANNING_DECUMULATION } from 'utils/features';
import asset4 from 'images/asset_4.png';

@withRouter
@connect((state) => ({
  account: state.CustomAccount.account,
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  wealthicaUser: state.Planning.wealthicaUser,
}))
class Landing extends Component {
  componentDidMount = () => {
    const { dispatch, is_wealthica_trial } = this.props;

    dispatch(Planning.clearPlanning());
    dispatch(Planning.clearStore());
    dispatch(Storage.removeItem('cache-pwpa-blueprint'));
    dispatch(Storage.removeItem('cachePB'));
    dispatch(Storage.removeItem('cachePB'));
    dispatch(Storage.removeItem('cacheTW'));
    dispatch(Storage.removeItem('cacheWR'));
    dispatch(Storage.removeItem('cacheTS'));
    dispatch(Storage.removeItem('cacheSR'));

    if (is_wealthica_trial) dispatch(Planning.fetchWealthicaUser());
  };

  render() {
    const { is_wealthica_trial } = this.props;

    return (
      <Segment expand>
        <div>
          <img src={asset4} height="80" width="120" alt="Portfolio" />
          <Header size="1.5rem" color="black">
            Welcome to Planning
          </Header>
          <P>
            We have crafted tools for the accumulation phase of retirement
            planning, such as calculating target wealth at retirement, and
            monthly target savings.
          </P>
          {is_wealthica_trial ? (
            <P>
              Note: During the 7-day money-back period, you may calculate a
              maximum of 2 Retirement Blueprints, Optimal Drawdown Plans, and
              Optimal Drawdowns with Target Spending each.
              <br />
            </P>
          ) : null}{' '}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Feature name={PLANNING_ACCUMULATION}>
              <NeuCard
                to="/planning/accumulation"
                iconName="double angle up"
                title="Accumulation"
                description="Tools to help you make better saving and investment decisions as you build wealth during your working years."
                color={colors.orange}
              />
            </Feature>
            <Feature name={PLANNING_DECUMULATION}>
              <NeuCard
                to="/planning/decumulation"
                iconName="double angle down"
                title="Decumulation"
                description="Tools for retirees who are drawing down their savings for their retirement spending needs."
                color={colors.orange}
              />
            </Feature>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
  is_wealthica_trial: PropTypes.bool,
  wealthicaUser: PropTypes.object,
};

Landing.defaultProps = {
  is_wealthica_trial: false,
  wealthicaUser: {},
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 650px;

  &.strike {
    text-decoration: line-through;
  }
`;
