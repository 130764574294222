import React, { Component } from 'react';
import { Icon, Popup, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Table } from 'components';

export default class History extends Component {
  formatNum = (num) => (num ? num.toFixed(2) : '--');

  renderTable = (annual, peer, data) => (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>YTD</Table.HeaderCell>
          <Table.HeaderCell>1-Year</Table.HeaderCell>
          <Table.HeaderCell>3-Year</Table.HeaderCell>
          <Table.HeaderCell>5-Year</Table.HeaderCell>
          <Table.HeaderCell>10-Year</Table.HeaderCell>
          <Table.HeaderCell>Since Inception</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell style={{ textAlign: 'left' }}>Fund Return (%)</Table.Cell>
          <Table.Cell>{this.formatNum(annual.year_to_date)}</Table.Cell>
          <Table.Cell>{this.formatNum(annual.one_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(annual.three_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(annual.five_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(annual.ten_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(annual.since_inception)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell style={{ textAlign: 'left' }}>
            Peer Group Return
            <Popup
              trigger={<Icon name="question circle outline" />}
              position="right center"
              wide
              content={`${data.global_category} peer group returns.`}
            />
            (%)
          </Table.Cell>
          <Table.Cell>{this.formatNum(peer.year_to_date)}</Table.Cell>
          <Table.Cell>{this.formatNum(peer.one_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(peer.three_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(peer.five_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(peer.ten_year)}</Table.Cell>
          <Table.Cell>{this.formatNum(peer.since_inception)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );

  render() {
    const { data, user_region } = this.props;
    const split = data.as_of_date ? data.as_of_date.split('-') : null;
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };
    const date = split
      ? `As of ${months[split[1]]} ${split[2]}, ${split[0]}`
      : 'N/A';

    return (
      <div>
        <Header size="medium" style={{ margin: '2rem 0', textAlign: 'left' }}>
          <Header.Content style={{ textAlign: 'left' }}>
            Annual Returns&nbsp;
          </Header.Content>
          <Header.Subheader>
            {date}{' '}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="right center"
              content="This data is updated once a month on the 15th."
              wide
            />
          </Header.Subheader>
          {user_region === 'CA' ? (
            <Header.Subheader>
              US funds in USD; Canadian funds in CAD
            </Header.Subheader>
          ) : null}
        </Header>
        {this.renderTable(data.annual_returns, data.peer_annual_returns, data)}
      </div>
    );
  }
}

History.propTypes = {
  data: PropTypes.object.isRequired,
  user_region: PropTypes.string.isRequired,
};
