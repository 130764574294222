import { setItem, removeItem } from './storage';
import { request } from './api';
import { isGlobeInvestor } from '../utils/helpers';

/**
 * Action constants
 */
export const AUTH_START = 'AUTH_START';
export const AUTH_END = 'AUTH_END';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';

/**
 * Action creators
 */
export function login(jwtData, history = null) {
  return (dispatch) => {
    dispatch({ type: AUTH_START });

    return dispatch(
      request('post', '/auth/authenticate/', { body: { token: jwtData } })
    ).then(
      ({
        token,
        feature_restrictions,
        full_feature_list,
        is_wealthica_trial,
        wealthica_plan,
        partner_name,
        globe_destination,
        language,
      }) => {
        dispatch(setItem('pwpa-token', token));
        dispatch(setItem('feature-restrictions', feature_restrictions));
        dispatch(setItem('full-feature-list', full_feature_list));
        dispatch(
          setItem(
            'is_esg_enabled',
            decideEnableEsg(partner_name, wealthica_plan)
          )
        );
        dispatch(setItem('language', language));
        dispatch(setItem('is_wealthica_trial', is_wealthica_trial));
        dispatch(setItem('wealthica_plan', wealthica_plan));
        dispatch(setItem('globe_destination', globe_destination));
        return history.push(`/auth/?token=${token}`);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log('ERROR REQUESTING TOKEN: ', err);

        dispatch(removeItem('pwpa-token'));
        dispatch(removeItem('feature-restrictions'));
        dispatch(removeItem('full-feature-list'));

        return history.push('/unauthorized/?token=invalid');
      }
    );
  };
}

export function logout(history = null) {
  return (dispatch) => {
    dispatch({ type: AUTH_END });
    dispatch(removeItem('pwpa-token'));
    dispatch(removeItem('blueprintDeleteWarningSuppress'));
    return history.push('/');
  };
}

export function validateToken(token) {
  return (dispatch) => {
    dispatch({ type: AUTH_START });

    return dispatch(
      request('post', '/auth/tokenrefresh/', { body: { token } })
    ).then(
      (data) => {
        dispatch(setItem('pwpa-token', data.token));

        return dispatch({
          type: VALIDATE_SUCCESS,
          token: data.token,
        });
      },
      () => {
        dispatch(removeItem('pwpa-token'));

        return dispatch({ type: VALIDATE_SUCCESS, token: 'invalid' });
      }
    );
  };
}

function decideEnableEsg(partnerName, wealthicaPlan) {
  if (isGlobeInvestor(partnerName)) {
    return false;
  }
  if (partnerName === 'wealthica') {
    return wealthicaPlan !== 'monthly';
  }

  return true;
}
