import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Security, FundComparison as FundComparisonActions } from 'actions';
import { maxNumberOfFundsToCompare } from 'utils/constants';
import { Modal, SearchTicker } from 'components';

class SearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySearchResult: false,
    };
  }

  getResults = () => {
    const { securityTickerList } = this.props;
    const { search } = this.props.security;

    return search.reduce((total, security) => {
      if (security) {
        if (!total[security.asset_type]) {
          total[security.asset_type] = {
            name: security.asset_type,
            results: [
              {
                title: security.ticker,
                description: security.long_name,
                price: security.exchange_name,
              },
            ],
          };
        } else {
          total[security.asset_type].results.push({
            title: security.ticker,
            description: security.long_name,
            price: security.exchange_name,
          });
        }
      }

      if (total.ETF !== undefined) {
        const etfArray = total.ETF.results;
        const filteredETFs = etfArray.filter(
          (securityItem) => !securityTickerList.includes(securityItem.title)
        );
        total.ETF.results = filteredETFs;
      }

      if (total.Stock !== undefined) {
        const stockArray = total.Stock.results;

        const filteredStocks = stockArray.filter(
          (securityItem) => !securityTickerList.includes(securityItem.title)
        );
        total.Stock.results = filteredStocks;
      }
      return total;
    }, {});
  };

  updateDisplaySearchResult = (newValue) => {
    this.setState({ displaySearchResult: newValue });
  };

  handleSearchChange = (value) => {
    const { dispatch, user_region } = this.props;

    return (
      !!value.trim() &&
      dispatch(Security.search(window.encodeURIComponent(value), user_region))
    );
  };
  handleSearchResultSelect = (ticker) => {
    const { dispatch, securityTickerList, toggle } = this.props;
    if (securityTickerList.length < maxNumberOfFundsToCompare) {
      dispatch(FundComparisonActions.addFund(ticker));
    }
    toggle(false);
  };

  render() {
    const { isOpen, toggle, security } = this.props;
    const hasSecurityResults = () => {
      const results = this.getResults();

      // Check if there are results in any asset type (e.g., ETF, Stock)
      for (const assetType in results) {
        if (results[assetType].results.length > 0) {
          return true;
        }
      }

      // Check if the security search is in progress
      if (security.isFetching) {
        return false;
      }

      // If no results and not fetching, return false
      return false;
    };

    return (
      <Modal open={isOpen}>
        <Modal.Content
          style={{
            position: 'relative',
            padding: '2rem',
            minHeight: this.state.displaySearchResult ? '390px' : 'auto',
          }}
        >
          <CloseIcon onClick={() => toggle(false)}>+</CloseIcon>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SearchTicker
              loading={security.isFetching}
              results={this.getResults()}
              customResultSelect={this.handleSearchResultSelect}
              customSearchChange={this.handleSearchChange}
              updateDisplaySearchResult={this.updateDisplaySearchResult}
              setMargin
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

SearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  securityTickerList: PropTypes.array.isRequired,
};

SearchModal.defaultProps = {
  user_region: 'CA',
};

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

export default connect((state) => ({
  security: state.Security,
  user_region: state.Storage.user_region || 'CA',
  user: state.Auth.user,
  securityTickerList: Object.keys(state.FundComparison.securityData || {}),
}))(SearchModal);
