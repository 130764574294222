import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import cn from 'classnames';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
  isCppModalOpen: state.Planning.isCppModalOpen,
  isOasModalOpen: state.Planning.isOasModalOpen,
}))
export default class RetirementIncome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {},
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const {
      dispatch,
      drawdown,
      cppDataset,
      oasDataset,
      cppEstimator,
    } = this.props;
    const { inputs } = drawdown;

    const { activeSpouse } = inputs; // Get the active spouse

    // Determine the correct field names based on the active spouse
    const ageField = activeSpouse === 'spouse' ? 'age' : 'age2';
    const horizonField = activeSpouse === 'spouse' ? 'horizon' : 'horizon2';
    const otherHorizonField =
      activeSpouse === 'spouse' ? 'otherHorizon' : 'otherHorizon2';
    const otherField = activeSpouse === 'spouse' ? 'other' : 'other2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';
    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';

    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;

    if (
      (name === ageField || name === horizonField) &&
      (value.includes('.') || value.includes('-'))
    ) {
      return;
    }

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'cpp2',
        'oas',
        'oas2',
        'pension',
        'pension2',
        'other',
        'other2',
        'initRegular',
        'initRegular2',
        'initRRIF',
        'initRRIF2',
        'initTFSA',
        'initTFSA2',
        'endValue',
        'endValue2',
      ];

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) {
        return 70;
      }

      if (isOas && Number(age) < 65) {
        return 65;
      }

      if (Number(age) < 60) {
        return 60;
      } else if (Number(age) > 70) {
        return 70;
      }

      return age;
    };

    if (name === ageField) {
      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), true))
      ).amount;
      if (Number(value) < 60) {
        this.changeSelection(cppStartedField, false);
        this.changeSelection('oasStarted', false);
      } else if (Number(value) < 65) {
        this.changeSelection(oasStartedField, false);
        this.changeSelection(cppStartedField, true);
      } else {
        this.changeSelection(oasStartedField, true);
        this.changeSelection(cppStartedField, true);
      }
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            ageField: parsedValue(name, value),
            ...(Number(value) > 60 && {
              cppAgeField: Number(value) >= 70 ? 70 : parsedValue(name, value),
            }),
            ...(Number(value) > 65 && {
              oasAgeField: Number(value) >= 70 ? 70 : parsedValue(name, value),
            }),
          },
          errors: {
            ageField: false,
            ...(Number(value) > 60 &&
              inputs[cppAgeField] && { [cppAgeField]: false }),
            ...(Number(value) > 65 &&
              inputs[oasAgeField] && { [oasAgeField]: false }),
            ...(inputs[oasStartedField] &&
              inputs[oasField] > oasMax && {
                [oasField]:
                  'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs[cppStartedField] &&
              inputs[cppField] <= cppMax && { [cppField]: null }),
            ...(inputs[oasStartedField] &&
              inputs[oasField] <= oasMax && { [oasField]: null }),
            ...(!inputs[cppStartedField] && { [cppAgeField]: null }),
            ...(!inputs[oasStartedField] && { [oasAgeField]: null }),
          },
          modified: true,
        })
      );
    }

    if (name === cppAgeField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [cppAgeField]: parsedValue(name, value), [cppField]: '' },
          errors: {
            [cppAgeField]:
              value < 60 || value > 70
                ? 'You can only start collecting CPP benefits between age 60 and age 70'
                : false,
            [cppField]: value < 60,
          },
          modified: true,
        })
      );
    }

    if (name === oasAgeField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [oasAgeField]: parsedValue(name, value), [oasField]: '' },
          errors: {
            [oasAgeField]:
              value < 65 || value > 70
                ? 'You can only start collecting OAS benefits between age 65 and age 70'
                : false,
            [oasField]: value < 65,
          },
          modified: true,
        })
      );
    }

    if (name === cppField) {
      dispatch(
        Planning.drawdownUpdateInput({
          errors: {
            [`my${activeSpouse === 'spouse' ? 'Cpp' : 'Cpp2'}`]: false,
          },
        })
      );
      if (inputs[oasField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            errors: {
              [`my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`]: false,
            },
          })
        );
      }
      if (!inputs[cppAgeField] && inputs[cppStartedField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs[ageField] || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();

      const body = {
        retirement_year:
          Number(currentYear) +
          Number(inputs[cppAgeField]) -
          Number(inputs[ageField]),
        start_cpp_age: inputs[ageField] > 70 ? 70 : inputs[cppAgeField],
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs[cppAgeField] !== '' && inputs[cppAgeField] >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, [cppField]: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [cppField]: money },
            errors: { ...inputs.errors, [cppField]: false },
            modified: true,
          })
        );
      }
    }

    if (name === oasField) {
      dispatch(
        Planning.drawdownUpdateInput({
          errors: {
            [`my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`]: false,
          },
        })
      );
      if (!inputs[oasAgeField] && inputs[oasStartedField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [oasAgeField]: inputs[ageField] > 70 ? 70 : inputs[ageField] || 0,
            },
            modified: true,
          })
        );
      }

      if (!inputs[oasAgeField] || inputs[oasAgeField] > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs[oasAgeField] < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs[oasAgeField])
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs[oasAgeField],
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, [oasField]: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [oasField]: money },
          errors: { ...inputs.errors, [oasField]: false },
          modified: true,
        })
      );
    }

    if (name === otherField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [otherField]: parsedValue(name, value) },
          errors: { ...inputs.errors, [otherHorizonField]: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { ...inputs.errors, [name]: false },
        modified: true,
      })
    );
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;
    const { activeSpouse } = drawdown.inputs;

    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';

    if (field === cppStartedField) {
      if (drawdown.inputs[cppStartedField] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [cppStartedField]: value,
              [cppAgeField]: '',
              [cppField]: '',
            },
            errors: { [cppAgeField]: false, [cppField]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { [cppField]: '' } });
      }
    }

    if (field === oasStartedField) {
      if (drawdown.inputs[oasStartedField] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [oasStartedField]: value,
              [oasAgeField]: '',
              [oasField]: '',
            },
            errors: { [oasAgeField]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { [oasField]: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (portfolioId, modelType) => {
    const { portfolio } = this.props;

    return modelType === 'annuityRate'
      ? false
      : !portfolio.model
          .concat(portfolio.user)
          .find((port) => port.id === portfolioId);
  };

  render() {
    const { warning } = this.state;
    const { inputs, errors, dispatch } = this.props;
    const { activeSpouse } = inputs;
    const ageField = activeSpouse === 'spouse' ? 'age' : 'age2';
    const cppAgeInputName = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const cppInputName = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasInputName = activeSpouse === 'spouse' ? 'oas' : 'oas2';
    const oasAgeInputName = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const pensionInputName = activeSpouse === 'spouse' ? 'pension' : 'pension2';
    const pensionInfName =
      activeSpouse === 'spouse' ? 'pensionInf' : 'pensionInf2';
    const otherInputName = activeSpouse === 'spouse' ? 'other' : 'other2';
    const otherInfName = activeSpouse === 'spouse' ? 'otherInf' : 'otherInf2';
    const otherHorizonName =
      activeSpouse === 'spouse' ? 'otherHorizon' : 'otherHorizon2';
    const cppAgeError = errors[cppAgeInputName];
    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';

    return (
      <div>
        <Section single>
          <div className="title">CPP/QPP</div>
          <ul>
            <li className="visible">
              <label>Have you started collecting CPP/QPP?</label>
              {cppStartedField && (
                <Error
                  style={{
                    margin: 0,
                    paddingLeft: '1rem',
                    display: 'block',
                  }}
                  visible={typeof cppAgeError === 'string'}
                >
                  {cppAgeError}
                </Error>
              )}
              <OptionsWrapper>
                <div
                  className={cn('select', {
                    active: inputs[cppStartedField],
                  })}
                  onClick={() => {
                    if (inputs[ageField] >= 60) {
                      this.changeSelection([cppStartedField], true);
                      dispatch(
                        Planning.drawdownUpdateInput({
                          inputs: {
                            [cppAgeField]: inputs[ageField],
                            [cppField]: '',
                          },
                          modified: true,
                        })
                      );
                    }
                  }}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', {
                    active: !inputs[cppStartedField],
                  })}
                  onClick={() => {
                    this.changeSelection([cppStartedField], false);
                    dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: { [cppAgeField]: '', [cppField]: '' },
                        modified: true,
                      })
                    );
                  }}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </li>

            <li
              className={cn({
                visible: inputs[cppStartedField],
              })}
            >
              <label>
                Annual CPP/QPP benefits
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={
                    typeof warning[cppInputName] === 'string' ||
                    typeof errors[cppInputName] === 'string' ||
                    errors[`my${activeSpouse === 'spouse' ? 'Cpp' : 'Cpp2'}`]
                  }
                >
                  {warning[cppInputName] ||
                    errors[activeSpouse === 'spouse' ? cppInputName : 'cpp2']}
                </Error>
              </label>
              <Input
                className={cn({
                  invalid:
                    errors[cppInputName] ||
                    errors[`my${activeSpouse === 'spouse' ? 'Cpp' : 'Cpp2'}`]
                      ? 'true'
                      : undefined,
                })}
                name={cppInputName}
                type="number"
                placeholder="$"
                value={inputs[cppInputName] || ''}
                onChange={this.handleChange}
                onFocus={() =>
                  this.setState({
                    warning: { ...this.state.warning, [cppInputName]: null },
                  })
                }
                onBlur={() =>
                  this.setState({
                    warning: { ...this.state.warning, [cppInputName]: null },
                  })
                }
                onClick={() =>
                  this.setState({
                    warning: { ...this.state.warning, [cppInputName]: null },
                  })
                }
              />
            </li>

            <li className={cn({ visible: !inputs[cppStartedField] })}>
              <label>
                At what age do you plan to start?
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content={
                    <div>
                      Read our article{' '}
                      <a
                        href="https://wealthscope.ca/cpp-benefits"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CPP/QPP Benefits: To Defer or Not to Defer
                      </a>
                    </div>
                  }
                  hoverable
                  wide
                />
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof errors[cppAgeField] === 'string'}
                >
                  {errors[cppAgeField]}
                </Error>
              </label>
              <Input
                className={cn('absolute', {
                  invalid:
                    errors[cppAgeField] ||
                    (errors[
                      `my${activeSpouse === 'spouse' ? 'Cpp' : 'Cpp2'}`
                    ] &&
                      !inputs[cppAgeField])
                      ? 'true'
                      : undefined,
                })}
                name={cppAgeField}
                type="number"
                placeholder="years"
                value={inputs[cppAgeField] || ''}
                onChange={this.handleChange}
              />
            </li>

            <li
              className={cn({
                visible: !inputs[cppStartedField],
                invalid: errors[
                  `my${activeSpouse === 'spouse' ? 'Cpp' : 'Cpp2'}`
                ]
                  ? 'true'
                  : undefined,
              })}
            >
              <label>
                Expected CPP/QPP annual benefits in today's dollars (
                <Span
                  style={{ color: '#4183C3' }}
                  onClick={() => this.props.toggleCppModal(true)}
                >
                  <b>CPP/QPP Estimator</b>
                </Span>
                )
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof warning[cppField] === 'string'}
                >
                  {warning[cppField]}
                </Error>
              </label>
              {!inputs[cppAgeField] ? (
                <Popup
                  trigger={
                    <Input
                      name={cppAgeField}
                      type="number"
                      placeholder="$"
                      value={inputs[cppAgeField] || ''}
                      onChange={this.handleChange}
                      style={{
                        backgroundColor: colors.lightGrey,
                        opacity: '.8',
                      }}
                      disabled
                    />
                  }
                  position="top center"
                  content="Please enter CPP/QPP start age."
                />
              ) : (
                <Input
                  className={cn({
                    invalid: errors[cppField] ? 'true' : undefined,
                  })}
                  name={cppField}
                  type="number"
                  placeholder="$"
                  value={inputs[cppField] || ''}
                  onChange={this.handleChange}
                  onFocus={() =>
                    this.setState({
                      warning: { ...this.state.warning, [cppField]: null },
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      warning: { ...this.state.warning, [cppField]: null },
                    })
                  }
                  onClick={() =>
                    this.setState({
                      warning: { ...this.state.warning, [cppField]: null },
                    })
                  }
                />
              )}
            </li>
          </ul>

          <div className="title">OAS</div>
          <ul>
            <li className="visible">
              <label>Have you started collecting OAS?</label>
              {inputs[oasStartedField] && (
                <Error
                  style={{
                    margin: 0,
                    paddingLeft: '1rem',
                    display: 'block',
                  }}
                  visible={typeof errors[oasAgeField] === 'string'}
                >
                  {errors[oasAgeField]}
                </Error>
              )}
              <OptionsWrapper>
                <div
                  className={cn('select', { active: inputs[oasStartedField] })}
                  onClick={() => {
                    if (inputs[ageField] >= 65) {
                      this.changeSelection([oasStartedField], true);
                      dispatch(
                        Planning.drawdownUpdateInput({
                          inputs: {
                            [oasAgeField]: inputs[ageField],
                            [oasField]: '',
                          },
                          modified: true,
                        })
                      );
                    }
                  }}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', { active: !inputs[oasStartedField] })}
                  onClick={() => {
                    this.changeSelection([oasStartedField], false);
                    dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: { [oasAgeField]: '', [oasField]: '' },
                        modified: true,
                      })
                    );
                  }}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </li>

            <li className={cn({ visible: inputs[oasStartedField] })}>
              <label>
                Annual OAS benefits
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={
                    typeof warning[oasInputName] === 'string' ||
                    typeof errors[oasInputName] === 'string'
                  }
                >
                  {warning[oasInputName] || errors[oasInputName]}
                </Error>
              </label>
              <Input
                className={cn({
                  invalid:
                    errors[oasInputName] ||
                    errors[`my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`]
                      ? 'true'
                      : undefined,
                })}
                name={oasInputName}
                type="number"
                placeholder="$"
                value={inputs[oasInputName] || ''}
                onChange={this.handleChange}
                onFocus={() =>
                  this.setState({
                    warning: { ...this.state.warning, [oasInputName]: null },
                  })
                }
                onBlur={() =>
                  this.setState({
                    warning: { ...this.state.warning, [oasInputName]: null },
                  })
                }
                onClick={() =>
                  this.setState({
                    warning: { ...this.state.warning, [oasInputName]: null },
                  })
                }
              />
            </li>

            <li
              className={cn({
                visible: !inputs[oasStartedField],
                invalid: errors[
                  `my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`
                ]
                  ? 'true'
                  : undefined,
              })}
            >
              <label>
                At what age do you plan to start?
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof errors[oasAgeInputName] === 'string'}
                >
                  {errors[oasAgeInputName]}
                </Error>
              </label>
              <Input
                className={cn('absolute', {
                  invalid:
                    errors[oasAgeInputName] ||
                    errors[`my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`]
                      ? 'true'
                      : undefined,
                })}
                name={oasAgeInputName}
                type="number"
                placeholder="years"
                value={inputs[oasAgeInputName] || ''}
                onChange={this.handleChange}
              />
            </li>

            <li className={cn({ visible: !inputs[oasStartedField] })}>
              <label>
                Expected OAS annual benefits in today's dollars (
                <Span
                  style={{ color: '#4183C3' }}
                  onClick={() => this.props.toggleOasModal(true)}
                >
                  <b>OAS Estimator</b>
                </Span>
                )
                <Error
                  style={{
                    margin: 0,
                    display: 'block',
                    transform: 'translateY(-1px)',
                  }}
                  visible={typeof warning[oasField] === 'string'}
                >
                  {warning[oasField]}
                </Error>
              </label>
              {!inputs[oasAgeField] ? (
                <Popup
                  trigger={
                    <Input
                      name={oasField}
                      type="number"
                      placeholder="$"
                      value={inputs[oasField] || ''}
                      onChange={this.handleChange}
                      style={{
                        backgroundColor: colors.lightGrey,
                        opacity: '.8',
                      }}
                      disabled
                      className={cn({
                        invalid: errors[
                          `my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`
                        ]
                          ? 'true'
                          : undefined,
                      })}
                    />
                  }
                  position="top center"
                  content="Please enter OAS start age."
                />
              ) : (
                <Input
                  className={cn({
                    invalid:
                      errors[oasField] ||
                      errors[`my${activeSpouse === 'spouse' ? 'Oas' : 'Oas2'}`]
                        ? 'true'
                        : undefined,
                  })}
                  name={oasField}
                  type="number"
                  placeholder="$"
                  value={inputs[oasField] || ''}
                  onChange={this.handleChange}
                  onFocus={() =>
                    this.setState({
                      warning: { ...this.state.warning, [oasField]: null },
                    })
                  }
                  onBlur={() =>
                    this.setState({
                      warning: { ...this.state.warning, [oasField]: null },
                    })
                  }
                  onClick={() =>
                    this.setState({
                      warning: { ...this.state.warning, [oasField]: null },
                    })
                  }
                />
              )}
            </li>
          </ul>

          <div>
            <div className="title">
              Annual employer DB pension (optional)
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="top center"
                content="Defined Benefits pension plan"
                wide
              />
            </div>
            <Input
              className={cn('large', {
                invalid: errors[pensionInputName] ? 'true' : undefined,
              })}
              name={pensionInputName}
              type="number"
              placeholder="$"
              value={inputs[pensionInputName] || ''}
              onChange={this.handleChange}
            />

            <ul>
              <li className="visible">
                <label>Is this pension indexed to inflation?</label>
                <OptionsWrapper>
                  <div
                    className={cn('select', { active: inputs[pensionInfName] })}
                    onClick={() => this.changeSelection([pensionInfName], true)}
                    style={{
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className={cn('select', {
                      active: !inputs[pensionInfName],
                    })}
                    onClick={() =>
                      this.changeSelection([pensionInfName], false)
                    }
                    style={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    No
                  </div>
                </OptionsWrapper>
              </li>
            </ul>
          </div>

          <div>
            <div className="title">
              Annual other taxable income (optional)
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="top center"
                content="This includes annuity and rental income. Dividends and interest income from non-registered accounts will be calculated below."
                wide
              />
            </div>
            <Input
              className={cn('large', {
                invalid: errors[otherInputName] ? 'true' : undefined,
              })}
              name={otherInputName}
              type="number"
              placeholder="$"
              value={inputs[otherInputName] || ''}
              onChange={this.handleChange}
            />

            <ul>
              <li className="visible">
                <label>Is it indexed to inflation?</label>
                <OptionsWrapper>
                  <div
                    className={cn('select', { active: inputs[otherInfName] })}
                    onClick={() => this.changeSelection([otherInfName], true)}
                    style={{
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className={cn('select', { active: !inputs[otherInfName] })}
                    onClick={() => this.changeSelection([otherInfName], false)}
                    style={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    No
                  </div>
                </OptionsWrapper>
              </li>
              <li className="visible">
                <label>How long will it last?</label>
                <Input
                  className={cn({
                    invalid: errors[otherHorizonName] ? 'true' : undefined,
                  })}
                  name={otherHorizonName}
                  type="number"
                  placeholder="years"
                  value={inputs[otherHorizonName] || ''}
                  onChange={this.handleChange}
                />
                <Error
                  long
                  visible={typeof errors[otherHorizonName] === 'string'}
                >
                  {errors[otherHorizonName]}
                </Error>
              </li>
            </ul>
          </div>
        </Section>
      </div>
    );
  }
}

RetirementIncome.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  cppEstimator: PropTypes.object,
  toggleOasModal: PropTypes.func,
  toggleCppModal: PropTypes.func,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  portfolio: PropTypes.object,
};

RetirementIncome.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  cppDataset: [],
  oasDataset: [],
  cppEstimator: {},
  toggleOasModal: () => false,
  toggleCppModal: () => false,
  inputs: {},
  errors: {},
  portfolio: {},
};

const Section = styled.section`
  padding-left: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 0 auto 2rem auto;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    top: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }
  ol {
    position: relative;
    top: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Error = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 1rem;
  font-style: normal;
  color: ${colors.red};

  ${(props) =>
    props.long &&
    css`
      position: absolute;
      bottom: 0;
      left: 4px;
    `}
`;

const Span = styled.span`
  color: #4183c4;
  cursor: pointer;

  &:hover {
    color: #1e70bf;
  }
`;
