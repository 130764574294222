import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';

import { colors } from 'utils/colors';

const convertPropToClassName = (prop, className) => prop && className;

class Message extends Component {
  static Header = ({ children, className, ...rest }) => (
    <div className={cn('header', className)} {...rest}>
      {children}
    </div>
  );
  static Content = ({ children, className, ...rest }) => (
    <div className={cn('content', className)} {...rest}>
      {children}
    </div>
  );
  static Actions = ({ children, className, ...rest }) => (
    <div className={cn('actions', className)} {...rest}>
      {children}
    </div>
  );

  render() {
    const {
      children,
      className,
      color,
      size,
      visible,
      warning,
      error,
      positive,
      negative,
      icon,
      compact,
      inlineButton,
      basic,
      closeable,
      ...rest
    } = this.props;
    const classes = cn(
      className,
      color,
      size,
      convertPropToClassName(visible, 'visible'),
      convertPropToClassName(warning, 'warning'),
      convertPropToClassName(error, 'error'),
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(icon, 'icon'),
      convertPropToClassName(compact, 'compact'),
      convertPropToClassName(inlineButton, 'inlineButton'),
      convertPropToClassName(basic, 'basic'),
      convertPropToClassName(closeable, 'closeable')
    );

    return (
      <StyledMessage {...rest} className={classes}>
        {children}
      </StyledMessage>
    );
  }
}

Message.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  visible: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  icon: PropTypes.bool,
  compact: PropTypes.bool,
  inlineButton: PropTypes.bool,
  basic: PropTypes.bool,
  closeable: PropTypes.bool,
};

Message.defaultProps = {
  className: '',
  color: '',
  size: '',
  visible: false,
  warning: false,
  error: false,
  positive: false,
  negative: false,
  icon: false,
  compact: false,
  inlineButton: false,
  basic: false,
  closeable: false,
};

export default Message;

const StyledMessage = styled.div`
  display: none;
  font-size: 1em;
  position: relative;
  min-height: 1em;
  margin: 0.5em 0;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent;

  .header {
    font-size: 1.14285714em;
    font-weight: 700;
    margin: -0.14285714em 0 0 0;
  }

  &.icon {
    .icon {
      margin-right: 0.6em;
      line-height: 1;
      font-size: 3em;
      opacity: 0.8;
      display: inline-block;
      vertical-align: middle;
    }

    .content {
      display: inline-block;
      width: calc(100% - 75px);
      vertical-align: middle;
    }

    .actions {
      vertical-align: middle;
    }
  }

  &.inlineButton {
    .content {
      width: calc(100% - 245px);
    }

    .actions {
      display: inline-block !important;
      margin-left: 2rem;
    }
  }

  &.positive {
    background-color: #fcfff5;
    color: #2c662d;
    box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;

    .header {
      color: #1a531b;
    }
  }

  &.error {
    background-color: #fffafa;
    color: #db2828;
    font-weight: 400;
    box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
  }

  &.warning {
    background-color: #fffaf3;
    color: #573a08;
    box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  }

  &.compact {
    padding: 7px;
    margin: 0;
  }

  &.red {
    background-color: #ffe8e6;
    color: #db2828;
    box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
  }

  &.orange {
    background-color: #ffedde;
    color: #f2711c;
    box-shadow: 0 0 0 1px #f2711c inset, 0 0 0 0 transparent;
  }

  &.teal {
    background-color: #e1f7f7;
    color: #10a3a3;
    box-shadow: 0 0 0 1px #10a3a3 inset, 0 0 0 0 transparent;
  }

  &.blue {
    background-color: #dff0ff;
    color: ${colors.blue};
    box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent;
  }

  &.basic {
    background-color: transparent;
    box-shadow: initial;
    margin: 4rem auto;
    max-width: 400px;

    .header {
      font-size: 2rem;
    }

    .content {
      margin: 2rem 0;
      font-size: 1.1rem;
    }
  }

  &.closeable {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    max-width: 412px;
    border-left: 4px solid #ab4a48;
    border-radius: 0;
    padding: 0.5rem 1rem;
    background-color: #fff6f6;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 0 1px #ab4a48 inset, 0 0 0 0 transparent;
    font-size: 1.1rem;
    z-index: 11;
    text-align: left;
    margin: 0;

    .header {
      color: #ab4a48;
      font-size: 1.5rem;
      margin: 0.2rem 0 0.7rem 0;
    }

    .msg {
      margin-bottom: 0.5rem;
    }

    .close-icon {
      position: absolute;
      top: 4px;
      right: 4px;
      height: 20px;
      width: 20px;
      text-align: center;
      font-size: 1.7rem;
      border-radius: 50%;
      line-height: 19px;
      transform: rotate(45deg);
      transition: all 0.1s ease;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.8);
        color: #fff6f6;
      }
    }

    .institution {
      width: 45%;
      float: left;
      line-height: 1.1rem;
      margin: 0.2rem 1rem 0.4rem 0rem;
      vertical-align: middle;
    }
  }

  &.visible {
    display: block;
  }
`;
