import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label, Header, Form } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Segment, Button } from 'components';

class Reit extends Component {
  handleChange = (e, { name, value }) =>
    this.props.updateSelection({ [name]: [value] });

  handleSubmit = () => {
    const { moveToNextSection, pData, reit } = this.props;

    return moveToNextSection({ reit: [reit, pData[reit].total] }, 5);
  };

  render() {
    const { previousTab, pData, reit } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Header size="medium" textAlign="center">
            Do you want to exclude real estate investment trusts (REITs) in the
            selection process?
            <Header.Subheader style={{ fontSize: '1rem' }}>
              Performance of REITs is highly associated with interest rate risk.
            </Header.Subheader>
          </Header>
          <Segment basic compact textAlign="center">
            <Form.Group
              grouped
              style={{ display: 'inline-block', textAlign: 'left' }}
            >
              <Form.Radio
                toggle
                name="reit"
                label="No, just find me the best portfolio according to my other  criteria"
                value="no"
                checked={reit === 'no'}
                onChange={this.handleChange}
              />
              <Form.Radio
                toggle
                name="reit"
                label="Yes"
                value="yes"
                checked={reit === 'yes'}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Label
              basic
              color="blue"
              size="large"
              attached="bottom"
              style={{
                width: '60%',
                left: '20%',
                bottom: '-4.4rem',
                border: 0,
                lineHeight: '1.4rem',
              }}
            >
              <div style={{ fontSize: '1.5rem' }}>
                {reit && pData[reit]
                  ? pData[reit].total.toLocaleString()
                  : pData.total.toLocaleString()}
              </div>
              matching portfolios
            </Label>
          </Segment>
          <Button
            className="left"
            color={colors.orange}
            icon="chevron left"
            onClick={previousTab}
          >
            Back
          </Button>
          <Button
            className={cn('right', { disabled: !reit })}
            color={colors.orange}
            icon="chevron right"
            floated="right"
          >
            Next
          </Button>
        </Form>
      </div>
    );
  }
}

Reit.propTypes = {
  pData: PropTypes.object.isRequired,
  updateSelection: PropTypes.func.isRequired,
  moveToNextSection: PropTypes.func.isRequired,
  previousTab: PropTypes.func.isRequired,
  reit: PropTypes.string,
};

Reit.defaultProps = {
  reit: '',
};

export default connect()(Reit);
