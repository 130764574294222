import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header, Popup, Icon } from 'semantic-ui-react';
import cn from 'classnames';

import { Planning } from 'actions';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Modal, Button } from 'components';

class SaveModal extends Component {
  constructor(props) {
    super(props);
    const { name, description } = this.props;
    this.state = {
      saveModalIsOpen: false,
      loading: false,
      name: name || '',
      description: description || '',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.name || '',
      description: nextProps.description || '',
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value });
  };

  handleSave = () => {
    const { dispatch, drawdown, match, history } = this.props;
    const { name, description } = this.state;
    const {
      isRiskFree,
      constantRets,
      portfolio,
      initRegAccList,
      initRrifAccList,
      initTfsaAccList,
    } = drawdown.inputs;
    const body = {
      ...drawdown.inputs,
      name,
      description,
      outputData: drawdown.result,
      selectedRegular:
        initRegAccList && initRegAccList.length ? initRegAccList.join(',') : '',
      selectedRRIF:
        initRrifAccList && initRrifAccList.length
          ? initRrifAccList.join(',')
          : '',
      selectedTFSA:
        initTfsaAccList && initTfsaAccList.length
          ? initTfsaAccList.join(',')
          : '',
      ...(isRiskFree
        ? {
            isRiskFree: true,
            constantRets: Number(constantRets) / 100,
            portfolio: undefined,
          }
        : {
            isRiskFree: false,
            portfolio,
          }),
    };

    if (Number(match.params.id)) {
      dispatch(Planning.updateDrawdownPlan(match.params.id, body));
    } else {
      dispatch(Planning.saveDrawdownPlan(body, history));
    }
    dispatch(Planning.updateJustsaveOrFetch(true));

    return this.setState({ saveModalIsOpen: false, loading: false });
  };

  handleClick = () => this.setState({ saveModalIsOpen: true });

  handleCancel = () => {
    const { name, description } = this.props;

    return this.setState({
      saveModalIsOpen: false,
      name: name || '',
      description: description || '',
    });
  };

  generatePDF = () => {
    const { match, history } = this.props;

    return history.push(`/planning/drawdown/${match.params.id}/pdf`);
  };

  render() {
    const { match, partnerName, drawdown } = this.props;
    const { saveModalIsOpen, name, description, loading } = this.state;

    return (
      <div>
        <div>
          {drawdown.modified ? (
            <Popup
              wide
              trigger={
                <DisableButton color={colors.blue}>
                  <StyledIcon name="file text" />
                  Save
                </DisableButton>
              }
              position="top center"
              content={<div>Please re-calculate to save new changes.</div>}
            />
          ) : (
            <Button
              className={cn('left', {
                disabled: drawdown.modified || drawdown.justSaveOrFetch,
              })}
              color={colors.blue}
              icon="save"
              onClick={
                match.params.id === 'new' ? this.handleClick : this.handleSave
              }
            >
              Save
            </Button>
          )}

          {drawdown.modified ? (
            <Popup
              wide
              trigger={
                <DisableButton color={colors.blue}>
                  <StyledIcon name="file text" />
                  Print/PDF
                </DisableButton>
              }
              position="top center"
              content={<div>Please re-calculate to print new changes.</div>}
            />
          ) : (
            <Button
              className={cn('left', { disabled: drawdown.modified })}
              icon="file text"
              color={colors.blue}
              onClick={this.generatePDF}
              style={{ marginLeft: '1rem' }}
            >
              Print/PDF
            </Button>
          )}
        </div>

        <Modal basic open={saveModalIsOpen} vb={notWealthica(partnerName)}>
          <Modal.Header>
            <Header
              icon="briefcase"
              content={
                match.params.id === 'new'
                  ? 'Save Drawdown Plan'
                  : 'Update Drawdown Plan'
              }
              color="green"
            />
          </Modal.Header>
          <Modal.Content>
            <Field vb={notWealthica(partnerName)}>
              Plan name:
              <input
                placeholder="(required)"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Field>
            <Field vb={notWealthica(partnerName)}>
              Notes:
              <input
                placeholder="(optional)"
                name="description"
                value={description}
                onChange={this.handleChange}
              />
            </Field>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              icon="cancel"
              color={colors.red}
              margin="0 0.5rem 0 0"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                { loading, disabled: !name || !name.trim() }
              )}
              icon="checkmark"
              color={colors.green}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

SaveModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  drawdown: PropTypes.object,
  partnerName: PropTypes.string,
};

SaveModal.defaultProps = {
  name: '',
  description: '',
  drawdown: {},
  partnerName: '',
};

export default withRouter(
  connect((state) => ({
    partnerName: state.Storage['partner-name'],
  }))(SaveModal)
);

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 75%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;

const StyledIcon = styled(({ className, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon className={className} {...rest} />
))`
  font-size: 1rem !important;
  color: white !important;
  position: absolute;
  top: 0;
  left: 0;
`;

const DisableButton = styled.div`
  margin-left: 1rem;
  cursor: default;
  opacity: 0.45;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
  font-size: 0.9rem;
  display: inline-block;
  min-height: 1em;
  min-width: 95px;
  height: 36px;
  border: none;
  text-align: center;
  outline: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.6);
  background-color: transparent;
  padding: 0.65em 1.5em 0.65em 4em;
  vertical-align: top;
  line-height: 1.4em !important;
  user-select: none !important;
  .icon {
    margin: 0;
    height: 100%;
    position: absolute;
    color: ${(props) => props.color};
    top: 0;
    width: 36px;
    background-color: ${(props) => props.color};
    &:before {
      position: absolute;
      line-height: 1;
      font-size: 1em;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
