import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header, Popup, Icon } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Modal, Message, Button } from 'components';
import { Portfolio } from 'actions';

class SaveModal extends Component {
  state = {
    name: '',
    description: '',
    existedPortfolio: null,
  };

  componentWillMount = () => {
    const { dispatch, modelPortfolios, userPortfolios } = this.props;

    if (!userPortfolios.length) {
      dispatch(Portfolio.fetchUser());
    }

    if (!modelPortfolios.length) {
      dispatch(Portfolio.fetchModel());
    }
  };

  componentDidMount = () => {
    const { editing, forceSaveAsNewPortfolio } = this.props;

    if (!forceSaveAsNewPortfolio)
      this.setState({
        name: editing.name,
        description: editing.description,
      });
    else {
      this.setState({
        name: '',
        description: '',
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value, existedPortfolio: null });
  };

  save = () => {
    const {
      closeModal,
      dispatch,
      history,
      portfolio,
      userPortfolios,
      modelPortfolios,
      redirectToSavedPortfolioOnSuccess,
      forceSaveAsNewPortfolio,
    } = this.props;
    const allPortfolios = modelPortfolios.concat(userPortfolios);
    const { name, description } = this.state;
    const body = {
      ...portfolio,
      ...(portfolio.account && { account: portfolio.account.id }),
      name,
      description,
      check_history: portfolio.check_history || false,
      portfolio_type: portfolio.portfolio_type,
      is_linked:
        portfolio.is_linked === undefined ? false : portfolio.is_linked,
      is_mp: false,
    };

    const existedPortfolio = allPortfolios.find((port) => port.name === name);

    if (existedPortfolio) {
      return this.setState({ existedPortfolio });
    }
    if (forceSaveAsNewPortfolio) {
      this.setState({
        name: '',
        description: '',
        existedPortfolio: null,
      });
    }

    dispatch(Portfolio.save(body, history, redirectToSavedPortfolioOnSuccess));

    return closeModal();
  };

  update = (id) => {
    const { closeModal, dispatch, portfolio, history } = this.props;
    const body = {
      name: this.state.name,
      description: this.state.description,
      region: portfolio.region,
      symbols: portfolio.symbols,
      weights: portfolio.weights,
      gic_info: portfolio.gic_info,
      gic_rate: portfolio.gic_rate,
      init_date: portfolio.init_date,
      check_history: portfolio.check_history,
    };

    dispatch(Portfolio.update(id, body));
    dispatch(Portfolio.setEditing(id, this.state.name, this.state.description));

    history.push(`/portfolio/${id}/overview`);

    return closeModal();
  };

  render() {
    const {
      open,
      closeModal,
      editing,
      portfolio,
      partnerName,
      forceSaveAsNewPortfolio,
    } = this.props;
    const { existedPortfolio } = this.state;

    return (
      <Modal
        basicTop
        open={open}
        dimmerClick={() => {
          this.setState({
            name: '',
            description: '',
            existedPortfolio: null,
          });

          return closeModal();
        }}
        vb={notWealthica(partnerName)}
      >
        <Modal.Header>
          <Header
            icon="briefcase"
            content={
              forceSaveAsNewPortfolio || editing.name === '' ? (
                portfolio && portfolio.is_linked ? (
                  <div>
                    Save this portfolio on Wealthscope
                    <Popup
                      wide
                      hoverable
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content={
                        <div>
                          Once saved, it can be used in the Planning Tools and
                          for the Compare function within the Portfolio
                          Scorecard (the Scale icon on the left). If your
                          portfolio has changed (holdings/weights), please save
                          it again when you return.
                        </div>
                      }
                    />
                  </div>
                ) : (
                  'Save Portfolio'
                )
              ) : (
                `Save changes for ${editing.name}`
              )
            }
            color="green"
          />
        </Modal.Header>
        <Modal.Content>
          <Field vb={notWealthica(partnerName)}>
            Name:
            <input
              placeholder="(required)"
              name="name"
              value={this.state.name || ''}
              onChange={this.handleChange}
            />
          </Field>
          <Field vb={notWealthica(partnerName)}>
            Description:
            <input
              placeholder="(optional)"
              name="description"
              value={this.state.description || ''}
              onChange={this.handleChange}
            />
          </Field>
        </Modal.Content>
        <Modal.Actions>
          {existedPortfolio && (
            <div
              style={{
                textAlign: 'center',
                overflow: 'auto',
                float: 'right',
                margin: '0 0 1rem 0',
              }}
            >
              <Message visible error style={{ margin: '0' }}>
                <Icon
                  name="exclamation circle"
                  style={{ fontSize: '16px', transform: 'translateY(1px)' }}
                />
                This portfolio name already exists, would you like to replace
                the old one?
              </Message>
            </div>
          )}
          <div style={{ clear: 'both' }}>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              color={colors.red}
              icon="cancel"
              margin="0 0.5rem 0 0"
              onClick={() => {
                if (existedPortfolio) {
                  return this.setState({
                    name: '',
                    description: '',
                    existedPortfolio: null,
                  });
                }

                return closeModal();
              }}
            >
              {existedPortfolio ? 'No' : 'Cancel'}
            </Button>
            <Button
              className={cn(
                `${
                  notWealthica(partnerName)
                    ? 'left'
                    : 'inverted left grey-focus'
                }`,
                { loading: this.state.loading, disabled: !this.state.name }
              )}
              color={colors.green}
              icon="checkmark"
              onClick={
                forceSaveAsNewPortfolio
                  ? this.save
                  : editing.id
                  ? () => this.update(editing.id)
                  : existedPortfolio
                  ? () => this.update(existedPortfolio.id)
                  : this.save
              }
            >
              {existedPortfolio ? 'Yes' : 'Save'}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

SaveModal.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  portfolio: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  editing: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  userPortfolios: PropTypes.array,
  modelPortfolios: PropTypes.array,
  redirectToSavedPortfolioOnSuccess: PropTypes.bool,
  forceSaveAsNewPortfolio: PropTypes.bool,
};

SaveModal.defaultProps = {
  history: {},
  portfolio: {},
  dispatch: () => false,
  partnerName: '',
  userPortfolios: null,
  modelPortfolios: null,
  redirectToSavedPortfolioOnSuccess: false,
  forceSaveAsNewPortfolio: false,
};

export default withRouter(
  connect((state) => ({
    portfolios: state.Portfolio.user,
    editing: state.Portfolio.editing,
    partnerName: state.Storage['partner-name'],
    userPortfolios: state.Portfolio.user,
    modelPortfolios: state.Portfolio.model,
  }))(SaveModal)
);

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;
  line-height: 1.3em;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;
