import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default class NonCaFootNote extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { hasEsg } = this.props;

    return (
      <div>
        <P>
          1. Wealthscope Portfolio Scorecard guidelines:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To protect the integrity of the
          statistical analysis, Wealthscope implements the following rules when
          analyzing your portfolio:
          <br />
          <ul style={{ listStyleType: 'disc', transform: 'translateX(12px)' }}>
            <li>
              If all of the securities in the portfolio have more than or equal
              to 48 months of history, all of the analysis will be performed.
              Otherwise, we exclude the following securities:
              <ul style={{ listStyleType: 'circle' }}>
                <li>Securities that have less than 36 months of history;</li>
                <li>
                  Securities that have between 36 and 47 months of history and
                  collectively weigh less than 10% of the portfolio.
                </li>
              </ul>
            </li>
            <li>
              For securities included in the analysis, the period of analysis
              will be the longest common history.
            </li>
            <li>
              If all of the securities in the portfolio have less than 36 months
              of history, we provide only basic summary information for the
              portfolio using the longest common history.
            </li>
            <li>
              If a passive ETF has insufficient history but there is a close
              substitute, the latter will be used in the analysis. For some
              passive ETFs with short history, we may use the underlying index
              returns (minus the MER) prior to inception.
            </li>
          </ul>
        </P>
        <P>
          2. The Overall Score is the average score of the individual
          dimensions. Each individual score is out of 100 for the specific time
          period in question. A higher score always means a more desirable
          characteristic.
        </P>
        <P>
          3. The benchmark for your portfolio is automatically selected
          depending on your asset allocation. It represents a simple, low-cost
          two-ETF (one broad equity and one broad fixed income) alternative for
          your portfolio.
        </P>
        <P>
          4. The following are risk-adjusted return ratios, and a higher ratio
          is better: The Sharpe Ratio measures the portfolio’s reward (excess
          returns over the risk-free rate) per unit of risk. The Roy’s Safety
          First Criterion measures the portfolio’s reward (excess returns over
          the target rate) per unit of risk. The Sortino Ratio measures the
          portfolio’s reward (excess returns over the target rate) per unit of
          downside risk.
        </P>
        <P>
          5. The following are portfolio risk measures: Total risk represents
          the volatility of the portfolio’s returns as measured by its standard
          deviation. Downside risk is the volatility of the portfolio’s return
          below a target rate. Maximum drawdown is the maximum peak to trough
          loss during a given period. Downside capture measures whether the
          portfolio lost less than the market when there was a market downturn.
        </P>
        <P>
          6. The default target rate is set to zero, representing nominal
          capital preservation.
        </P>
        <P>
          7. Fees capture product fees if you have mutual funds/ETFs. It can
          include a trailing commission for your advisor, depending on the share
          class you have (e.g., Class A). A weight fee of 3% will receive a
          score of 0%.
        </P>
        <P>
          8. A weighted income (dividends and other distributions) yield of 5%
          or higher will receive a score of 100%.
        </P>
        <P>
          9. Diversification is scored using your portfolio’s average
          correlation and four other dimensions across i) asset class, ii)
          geographical region, iii) business sectors, and iv) macroeconomic
          factors.
        </P>
        <P>
          10. For regional stock market, global stock sector, macroeconomic, and
          equity style diversification, we show you your portfolio’s risk
          exposures. The latter measures how much the volatility of your
          portfolio can be attributed to each factor. Portfolio-specific risk is
          the portion of your portfolio’s volatility that cannot be explained by
          the factors in question.
        </P>
        {hasEsg ? (
          <P>
            11. The Owl Analytics ESG scores are on a 0-100 scale, with a higher
            score being more desirable. The Wealthscope grading scheme differs
            from the ESG scores as the grading scheme reflects the distribution
            of the scores, which is tight around the middle for funds (see the
            distribution chart).
          </P>
        ) : null}
      </div>
    );
  }
}

NonCaFootNote.propTypes = { hasEsg: PropTypes.bool.isRequired };

NonCaFootNote.defaultProps = {};

const P = styled.p`
  text-align: left;
  max-width: 100%;
`;
