import { Accounts } from 'actions';

const initialState = {
  isFetching: false,
  accountList: null,
  overview: null,
  unsupported: {
    institutions: [],
    closed: false,
  },
  selectedAccountList: [],
  selectedAccountOverview: null,
  selectedSingleAccInstution: null,
  selectedSingleAccLabel: null,
  notEnoughHoldings: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Accounts.ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Accounts.ACCOUNTS_TOGGLE_MULTIPLE_MODE:
      return {
        ...state,
        selectedAccountList: [],
      };
    case Accounts.ACCOUNTS_SELECT_MULTIPLE_ACCOUNT:
      return {
        ...state,
        selectedAccountList: action.selectedAccountList,
        selectedAccountOverview: action.selectedAccountOverview,
      };
    case Accounts.ACCOUNTS_DESELECT_MULTIPLE_ACCOUNT:
      return {
        ...state,
        selectedAccountList: action.selectedAccountList,
        selectedAccountOverview: action.selectedAccountOverview,
      };
    case Accounts.ACCOUNTS_FETCHALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountList: action.payload,
        overview: action.overview,
      };
    case Accounts.ACCOUNTS_FETCHACCOUNT_SUCCESS: {
      const newAccountList = state.accountList.reduce(
        (acc, val) =>
          val.id === action.account.id
            ? acc.concat(action.account)
            : acc.concat(val),
        []
      );

      return {
        ...state,
        isFetching: false,
        accountList: newAccountList,
      };
    }
    case Accounts.ACCOUNTS_EDIT_SUCCESS: {
      const id = action.payload ? action.payload.id : null;

      return {
        ...state,
        isFetching: false,
        accountList: state.accountList.map((account) =>
          account.id === id ? action.payload : account
        ),
      };
    }
    case Accounts.ACCOUNTS_REMOVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountList: state.accountList.filter(
          (account) => account.id !== action.id
        ),
      };
    case Accounts.ACCOUNTS_ANALYZE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case Accounts.ACCOUNTS_SHOW_MESSAGE:
      return {
        ...state,
        unsupported: { institutions: action.institutions, closed: false },
      };
    case Accounts.ACCOUNTS_HIDE_MESSAGE:
      return {
        ...state,
        unsupported: { ...state.unsupported, closed: true },
      };
    case Accounts.ACCOUNTS_REMOVE_HOLDING: {
      const newAccountList = state.accountList.map((account, i) => {
        const newAccount =
          account.id !== Number(action.id)
            ? account
            : {
                ...account,
                holdings: state.accountList[i].holdings.reduce((acc, val) => {
                  if (
                    (val.ticker || val.security_detail.ticker) !== action.ticker
                  ) {
                    return acc.concat(val);
                  }

                  return acc;
                }, []),
              };

        return newAccount;
      });

      return {
        ...state,
        accountList: newAccountList,
      };
    }
    case Accounts.ACCOUNTS_SAVE_HOLDING: {
      const newAccountList = state.accountList.map((account, i) => {
        const newAccount =
          account.id !== Number(action.id)
            ? account
            : {
                ...account,
                holdings: state.accountList[i].holdings.concat(action.holding),
              };

        return newAccount;
      });

      return {
        ...state,
        accountList: newAccountList,
      };
    }
    case Accounts.ACCOUNTS_UPDATE_HOLDING: {
      const newAccountList = state.accountList.map((account, i) => {
        const newAccount =
          account.id !== Number(action.id)
            ? account
            : {
                ...account,
                holdings: state.accountList[i].holdings.reduce((acc, val) => {
                  if (
                    (val.ticker || val.security_detail.ticker) !== action.ticker
                  ) {
                    return acc.concat(val);
                  }

                  return acc.concat(action.holding);
                }, []),
              };

        return newAccount;
      });

      return {
        ...state,
        accountList: newAccountList,
      };
    }
    case Accounts.ACCOUNTS_UPDATE_DATA: {
      const newAccountList = state.accountList.map((account) => {
        const newAccount =
          account.id !== Number(action.id)
            ? account
            : {
                ...account,
                ...action.data,
              };

        return newAccount;
      });

      return {
        ...state,
        accountList: newAccountList,
      };
    }
    case Accounts.ACCOUNTS_SAVE_NAME:
      return {
        ...state,
        selectedSingleAccInstution: action.institution,
        selectedSingleAccLabel: action.label,
      };
    case Accounts.ACCOUNTS_NOT_ENOUGHT_HOLDINGS:
      return {
        ...state,
        isFetching: false,
        notEnoughHoldings: true,
      };
    default:
      return state;
  }
}
