import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Statistic, Icon, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import ReactExport from 'react-data-export';
import { Message, Segment } from 'components';
import { colors } from 'utils/colors';
import FullScreenModal from './FullScreenModal';
import BeforeTaxPie from './BeforeTaxPie';
import AfterTaxPie from './AfterTaxPie';
import InvestmentAccountLine from './InvestmentAccountLine';
import PensionIncomeBar from './PensionIncomeBar';
import InvestmentIncomeBar from './InvestmentIncomeBar';
import TaxesBar from './TaxesBar';
import SaveModal from './SaveModal';

const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

@withRouter
@connect((state) => ({
  drawdown: state.Planning.drawdown,
}))
export default class Result extends Component {
  state = {
    fullscreen: false,
    activeTab: 1,
  };

  formatNum = (num) => {
    if (!num) {
      return '$0';
    }
    const options = {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    const format = new Intl.NumberFormat('en-US', options);

    return `$${format.format(num)}`;
  };

  handleTabChange = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
  };

  handleNextTab = () => {
    const { activeTab } = this.state;
    const nextTab = activeTab === 6 ? 1 : activeTab + 1;
    this.setState({ activeTab: nextTab });
  };

  handlePrevTab = () => {
    const { activeTab } = this.state;
    const prevTab = activeTab === 1 ? 6 : activeTab - 1;
    this.setState({ activeTab: prevTab });
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  scrollToBottom = () => this.scrollHandler(800, 600);

  render() {
    const { fullscreen, activeTab } = this.state;
    const { drawdown, match, displayScenario } = this.props;

    const { result, name, description } = drawdown;

    if (!result || !result.yearlyDetails) {
      return (
        <Segment margin="2rem 3rem 2rem 3rem">
          <div margin="2rem 3rem 2rem 31rem" textAlign="center">
            <Message icon visible error>
              <Icon name="exclamation triangle" style={{ fontSize: '2rem' }} />
              <Message.Content>
                Sorry, no optimal solution was found for the parameters you
                entered. The issue is likely due to <br />
                i) abnormal portfolio values for your RRIF and taxable accounts,
                or <br />
                ii) your "Annual Other Taxable Income" being too large and
                having too short a duration.
              </Message.Content>
            </Message>
          </div>
        </Segment>
      );
    }

    const ages = Object.entries(result.yearlyDetails).map(([age, details]) => ({
      age: Number(age),
      ...details,
    }));

    const prefiltered = ages.map((entry) => entry.age);
    const filtered = [
      ...prefiltered.slice(0, 5),
      ...prefiltered.slice(5).filter((age, index) => index % 5 === 0),
    ];

    if (typeof result.error === 'string') {
      // Correct error handling
      return (
        <div margin="2rem 3rem 2rem 31rem" textAlign="center">
          <Message icon visible error>
            <Icon name="exclamation triangle" style={{ fontSize: '2rem' }} />
            <Message.Content>
              Sorry, no optimal solution was found for the parameters you
              entered. The issue is likely due to <br />
              i) abnormal portfolio values for your RRIF and taxable accounts,
              or <br />
              ii) your "Annual Other Taxable Income" being too large and having
              too short a duration.
            </Message.Content>
          </Message>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div textAlign="center">
          <Segment margin="2rem 3rem 2rem 3rem">
            <Header
              textAlign="center"
              size="large"
              style={{ margin: '1rem auto 2rem auto' }}
            >
              Optimal Drawdown with Target Spending{' '}
            </Header>
            <div>
              <SaveModal
                name={name}
                description={description}
                id={match.params.id}
                drawdown={drawdown}
              />
              <FullScreenModal
                result={result}
                data={result.yearlyDetails}
                filtered={filtered}
                open={fullscreen}
                closeFullscreen={() => this.setState({ fullscreen: false })}
              />
            </div>
            {displayScenario ? (
              displayScenario === 'median' ? (
                <P style={{ color: colors.blue, marginBottom: '1rem' }}>
                  Median Outcome
                </P>
              ) : (
                <P style={{ color: colors.blue, marginBottom: '1rem' }}>
                  Sequence Of Returns Risk Outcome
                </P>
              )
            ) : null}
            <P margin>
              In addition to your retirement income, how much should you
              withdraw from your investment accounts each year to meet your
              spending needs? In the results below, we show the plan that will
              minimize your tax burden, while keeping your desired spending
              fixed in real terms.
            </P>
            <Flex style={{ marginBottom: '2rem' }}>
              <div
                style={{ width: '100%', maxWidth: 320, margin: '2rem auto' }}
              >
                <Header
                  size="small"
                  content="Your desired spending per year"
                  subheader="After tax, in today's dollars"
                />
                <Flex className="circle blue">
                  {this.formatNum(
                    result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                      .incPlot
                  )}
                </Flex>
              </div>
              <div
                style={{ width: '100%', maxWidth: 320, margin: '2rem auto' }}
              >
                <Header
                  size="small"
                  content="Tax implications over the planning horizon"
                  subheader="Sum in today’s dollars"
                />
                <Flex className="circle orange">
                  {this.formatNum(result.taxPV)}
                </Flex>
              </div>
            </Flex>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onClick={this.scrollToBottom}
            >
              <span
                style={{
                  color: `${colors.darkBlue}`,
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  marginBottom: '1rem',
                }}
              >
                Click to see Plan Breakdown
              </span>
              <CircleButton>chevron down</CircleButton>
            </div>
          </Segment>
          <Segment
            margin="2rem 3rem 2rem 3rem"
            style={{ paddingBottom: '2rem' }}
          >
            <Header size="large" style={{ marginTop: '2rem' }}>
              Plan Breakdown
            </Header>
            <TabBar>
              {[1, 2, 3, 4, 5, 6].map((tabNum) => (
                <TabButton
                  key={tabNum}
                  active={activeTab === tabNum}
                  onClick={() => this.handleTabChange(tabNum)}
                >
                  {tabNum === 1 && '1st Yr Income Sources'}
                  {tabNum === 2 && 'Pension Income Breakdown'}
                  {tabNum === 3 && 'Investment Income Breakdown'}
                  {tabNum === 4 && 'Income Tax Breakdown'}
                  {tabNum === 5 && 'Investment Account Projections'}
                  {tabNum === 6 && 'Year-by-Year Details'}
                </TabButton>
              ))}
            </TabBar>
            {activeTab !== 1 && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '20px',
                  transform: 'translateY(-50%)',
                }}
              >
                <CircleButton onClick={this.handlePrevTab}>
                  chevron left
                </CircleButton>
              </div>
            )}

            {activeTab !== 6 && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  transform: 'translateY(-50%)',
                }}
              >
                <CircleButton onClick={this.handleNextTab}>
                  chevron right
                </CircleButton>
              </div>
            )}
            {activeTab === 1 && (
              <div style={{ margin: '3rem auto 0 3rem' }}>
                {displayScenario ? (
                  displayScenario === 'median' ? (
                    <P style={{ color: colors.blue, marginBottom: '1rem' }}>
                      Median Outcome
                    </P>
                  ) : (
                    <P style={{ color: colors.blue, marginBottom: '1rem' }}>
                      Sequence Of Returns Risk Outcome
                    </P>
                  )
                ) : null}{' '}
                <Header size="small" content="1st Year Income Sources" />
                <P style={{ margin: '0 auto 2.5rem auto' }}>
                  Below, you’ll find a breakdown of the amounts to withdraw from
                  your various income sources in the{' '}
                  <span style={{ fontWeight: 'bold' }}>1st year</span> of your
                  plan to optimize your after-tax retirement income. Remember,
                  optimality means that your after-tax income is maximized, on a
                  smooth path, while satisfying the bequest amount you
                  indicated, if any.
                </P>
                <Flex>
                  <div
                    className="flex-item"
                    style={{ height: 370, maxWidth: 320, margin: '2rem auto' }}
                  >
                    <Header
                      size="small"
                      content={`Income sources at age ${filtered[0]}`}
                      subheader="before-tax"
                      style={{ marginTop: 0 }}
                    />
                    <BeforeTaxPie
                      data={
                        result.yearlyDetails[
                          Object.keys(result.yearlyDetails)[0]
                        ]
                      }
                    />
                  </div>
                  <div
                    className="flex-item"
                    style={{ height: 326, maxWidth: 320, margin: '2rem auto' }}
                  >
                    <Header
                      size="small"
                      content={`Spending and taxes at age ${filtered[0]}`}
                      subheader="after-tax"
                      style={{ marginTop: 0 }}
                    />
                    <AfterTaxPie
                      data={
                        result.yearlyDetails[
                          Object.keys(result.yearlyDetails)[0]
                        ]
                      }
                    />
                  </div>
                </Flex>
              </div>
            )}
            {activeTab === 2 && (
              <div style={{ margin: '3rem auto', maxWidth: '85%' }}>
                <Header
                  size="small"
                  content="Pension income over the planning horizon"
                  subheader="before-tax"
                />
                <P style={{ margin: '0 auto 2.5rem auto' }}>
                  See below for a breakdown of your pension income from various
                  sources, based on your inputs. The Pension Income chart helps
                  you understand how government benefits and other pension
                  income will contribute to your annual income throughout your
                  retirement.
                  <br />
                  CPP & OAS are automatically indexed to inflation.
                </P>
                <PensionIncomeBar data={result.yearlyDetails} />
              </div>
            )}
            {activeTab === 3 && (
              <div style={{ margin: '3rem auto', maxWidth: '85%' }}>
                <Header
                  size="small"
                  content="Investment income over the planning horizon"
                  subheader="before-tax"
                />
                <P style={{ margin: '0 auto 2.5rem auto' }}>
                  We’ve analyzed the optimal withdrawal schedule from your
                  various investment accounts to minimize your tax burden. Based
                  on the information you provided and simulated returns data for
                  your planning horizon, the following chart represents when and
                  how much you should withdraw from each of your accounts
                  annually.
                </P>
                <InvestmentIncomeBar data={result.yearlyDetails} />
              </div>
            )}
            {activeTab === 4 && (
              <div style={{ margin: '3rem auto', maxWidth: '85%' }}>
                <Header
                  size="small"
                  content="Taxes over the planning horizon"
                />
                <P style={{ margin: '0 auto 2.5rem auto' }}>
                  The following chart represents the minimized amount of income
                  lost to taxes in each year of your drawdown plan. See Model
                  Features & Assumptions for more information on our strategy.
                </P>
                <TaxesBar data={result.yearlyDetails} />
              </div>
            )}
            {activeTab === 5 && (
              <div style={{ margin: '3rem auto', maxWidth: '85%' }}>
                <Header
                  size="small"
                  content="Projection of investment account balances"
                />
                <P style={{ margin: '0 auto 2.5rem auto' }}>
                  Here are the projections of your investment accounts
                  throughout your planning horizon. Based on your investment
                  income schedule and the simulated returns of the holdings in
                  your portfolio, your account balances over the years are
                  broken down as follows.
                </P>
                <InvestmentAccountLine data={result.yearlyDetails} />
              </div>
            )}
            {activeTab === 6 && (
              <React.Fragment>
                <Flex
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 0 0 1rem',
                  }}
                >
                  <Header
                    size="small"
                    content="Year-by-Year Details"
                    style={{ marginTop: '2.5rem' }}
                  />
                  <P style={{ margin: '0 auto 2.5rem auto' }}>
                    The table below displays data annually for the first 5
                    years, then displays in 5-year intervals. All figures in the
                    table are nominal, reflecting future values, except{' '}
                    <strong>real spending</strong>.
                    <br />
                    Click{' '}
                    <FSIcon onClick={() => this.setState({ fullscreen: true })}>
                      <strong>here</strong>
                    </FSIcon>{' '}
                    to view table in full screen.
                  </P>
                  {activeTab === 6 && (
                    <div>
                      {drawdown ? (
                        <ExcelFile
                          element={
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'top',
                              }}
                            >
                              <p>To dig deeper, and/or save your data </p>
                              <ExcelDownload>Download as Excel</ExcelDownload>
                            </div>
                          }
                          filename="Optimal Drawdown Details"
                        >
                          <ExcelSheet
                            data={ages}
                            name="Optimal Drawdown Details"
                          >
                            <ExcelColumn label="Age" value="age" />
                            <ExcelColumn
                              label="Gross Spending"
                              value="incTotal"
                            />
                            <ExcelColumn label="Net Spending" value="incNet" />
                            <ExcelColumn
                              label="Real Net Spending"
                              value="incPlot"
                            />
                            <ExcelColumn
                              label="CPP/QPP Income"
                              value="incCPP"
                            />
                            <ExcelColumn label="OAS Income" value="incOAS" />
                            <ExcelColumn label="GIS Income" value="incGIS" />
                            <ExcelColumn
                              label="Employment Pension Income"
                              value="incPension"
                            />
                            <ExcelColumn
                              label="Other Income"
                              value="incOther"
                            />
                            <ExcelColumn
                              label="Minimum RRIF Withdrawals"
                              value="wdReqMin"
                            />
                            <ExcelColumn
                              label="Non-registered Withdrawals"
                              value="wdRegular"
                            />
                            <ExcelColumn
                              label="RRIF Withdrawals"
                              value="wdRRIF"
                            />
                            <ExcelColumn
                              label="TFSA Withdrawals"
                              value="wdTFSA"
                            />
                            <ExcelColumn
                              label="Dividend Withdrawals"
                              value="incDividend"
                            />
                            <ExcelColumn label="Taxes" value="taxBoth" />
                            <ExcelColumn label="OAS Clawback" value="taxOAS" />
                          </ExcelSheet>
                        </ExcelFile>
                      ) : null}
                    </div>
                  )}

                  <div style={{}}>
                    <TableWrapper>
                      <Table>
                        <thead>
                          <tr>
                            <th />
                            {filtered.map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                          </tr>
                        </thead>

                        <Header
                          size="medium"
                          content="Spending"
                          textAlign="left"
                        />
                        <tbody>
                          <tr>
                            <td className="title">Gross spending</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  result.yearlyDetails[key].incTotal !==
                                    undefined &&
                                  this.formatNum(
                                    result.yearlyDetails[key].incTotal
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">Net spending</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  result.yearlyDetails[key].incNet !==
                                    undefined &&
                                  this.formatNum(
                                    result.yearlyDetails[key].incNet
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">Real net spending</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  result.yearlyDetails[key].incPlot !==
                                    undefined &&
                                  this.formatNum(
                                    result.yearlyDetails[key].incPlot
                                  )}
                              </td>
                            ))}
                          </tr>
                        </tbody>

                        <Header
                          size="medium"
                          content="Retirement income"
                          textAlign="left"
                          style={{ margin: '25px 0 14px 0' }}
                        />
                        <tbody>
                          <tr>
                            <td className="title">CPP/QPP</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                result.yearlyDetails[key].incCPP !== undefined
                                  ? this.formatNum(
                                      result.yearlyDetails[key].incCPP
                                    )
                                  : 'N/A'}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">OAS</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                result.yearlyDetails[key].incOAS !== undefined
                                  ? this.formatNum(
                                      result.yearlyDetails[key].incOAS
                                    )
                                  : 'N/A'}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">GIS</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                result.yearlyDetails[key].incGIS !== undefined
                                  ? this.formatNum(
                                      result.yearlyDetails[key].incGIS
                                    )
                                  : 'N/A'}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">Employment pension</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                result.yearlyDetails[key].incPension !==
                                  undefined
                                  ? this.formatNum(
                                      result.yearlyDetails[key].incPension
                                    )
                                  : 'N/A'}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">Other</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                result.yearlyDetails[key].incOther !== undefined
                                  ? this.formatNum(
                                      result.yearlyDetails[key].incOther
                                    )
                                  : 'N/A'}
                              </td>
                            ))}
                          </tr>
                        </tbody>

                        <Header
                          size="medium"
                          content="Investments"
                          textAlign="left"
                          style={{ margin: '25px 0 14px 0' }}
                        />
                        <tbody>
                          <tr>
                            <td className="title">Minimum RRIF</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  this.formatNum(
                                    result.yearlyDetails[key].wdReqMin
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">Non-registered</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  this.formatNum(
                                    result.yearlyDetails[key].wdRegular
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">RRIF</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  this.formatNum(
                                    result.yearlyDetails[key].wdRRIF
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">TFSA</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  this.formatNum(
                                    result.yearlyDetails[key].wdTFSA
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">
                              Dividends
                              <Popup
                                trigger={
                                  <Icon name="question circle outline" />
                                }
                                position="top center"
                                content="From non-registered accounts."
                                wide
                              />
                            </td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  this.formatNum(
                                    result.yearlyDetails[key].incDividend
                                  )}
                              </td>
                            ))}
                          </tr>
                        </tbody>

                        <Header
                          size="medium"
                          content="Taxes"
                          textAlign="left"
                          style={{ margin: '25px 0 14px 0' }}
                        />
                        <tbody>
                          <tr>
                            <td className="title">Taxes</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  this.formatNum(
                                    result.yearlyDetails[key].taxBoth
                                  )}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="title">OAS clawback</td>
                            {filtered.map((key) => (
                              <td key={key}>
                                {result.yearlyDetails[key] &&
                                  result.yearlyDetails[key].taxOAS &&
                                  this.formatNum(
                                    result.yearlyDetails[key].taxOAS
                                  )}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </TableWrapper>
                  </div>

                  <Header
                    size="medium"
                    content="Ending Values"
                    style={{ textAlign: 'center' }}
                    // subheader="After tax, in today's dollars"
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      margin: '1rem',
                      // transform: 'translateX(-40px)',
                    }}
                  >
                    <Statistic
                      size="tiny"
                      style={{ marginRight: '4rem', marginLeft: '4rem' }}
                    >
                      <Statistic.Label>
                        Non-registered
                        {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
                      </Statistic.Label>
                      <Statistic.Value>
                        {this.formatNum(
                          result.yearlyDetails[
                            Object.keys(result.yearlyDetails)[
                              Object.keys(result.yearlyDetails).length - 1
                            ]
                          ].endingRegular
                        )}
                      </Statistic.Value>
                    </Statistic>
                    <Statistic
                      size="tiny"
                      style={{ marginRight: '4rem', marginLeft: '4rem' }}
                    >
                      <Statistic.Label>
                        RRIF
                        {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
                      </Statistic.Label>
                      <Statistic.Value>
                        {this.formatNum(
                          result.yearlyDetails[
                            Object.keys(result.yearlyDetails)[
                              Object.keys(result.yearlyDetails).length - 1
                            ]
                          ].endingRRIF
                        )}
                      </Statistic.Value>
                    </Statistic>
                    <Statistic
                      size="tiny"
                      style={{ marginRight: '4rem', marginLeft: '4rem' }}
                    >
                      <Statistic.Label>
                        TFSA
                        {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
                      </Statistic.Label>
                      <Statistic.Value>
                        {this.formatNum(
                          result.yearlyDetails[
                            Object.keys(result.yearlyDetails)[
                              Object.keys(result.yearlyDetails).length - 1
                            ]
                          ].endingTFSA
                        )}
                      </Statistic.Value>
                    </Statistic>
                  </div>
                  <div style={{ textAlign: 'center', margin: '1rem 0' }}>
                    <Statistic size="tiny">
                      <Statistic.Label>
                        Total
                        {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
                      </Statistic.Label>
                      <Statistic.Value>
                        {this.formatNum(result.sumEndingValues)}
                      </Statistic.Value>
                    </Statistic>
                  </div>
                </Flex>
              </React.Fragment>
            )}
          </Segment>
        </div>
      </React.Fragment>
    );
  }
}

Result.propTypes = {
  drawdown: PropTypes.object,
  match: PropTypes.object,
  displayScenario: PropTypes.string,
};

Result.defaultProps = {
  drawdown: {},
  match: {},
  displayScenario: '',
};

const Table = styled.table`
  padding: 0;
  width: 100%;
  overflow-x: auto;

  tr {
    width: 100%;
  }

  td,
  th {
    border: 1px solid #eaecef;
    padding: 6px 12px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      color: rgba(0, 0, 0, 0.8);
      width: 168px;
    }

    &:last-child {
      border-right: 0;
    }

    &.title {
      font-weight: 700;
      min-width: 12rem;
      text-align: left;
    }

    &.short {
      width: 15%;
      max-width: 11rem;
      min-width: 7rem;
    }

    &.text {
      font-size: 0.9rem;
    }
  }

  th {
    background: #fafbfc;

    &:first-child {
      background: white;
      border-top: 0;
    }
  }

  &.fixed {
    display: block;
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 168px;
    background: white;
    padding-top: 79px;

    td {
      border-right: 1px solid #eaecef;
    }
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;

  @media (min-width: 768px) {
    max-width: 500px;
  }

  @media (min-width: 992px) {
    max-width: 600px;
  }

  @media (min-width: 1200px) {
    max-width: 650px;
  }
  @media (min-width: 1400px) {
    max-width: 750px;
  }
  @media (min-width: 1600px) {
    max-width: 800px;
  }
  @media (min-width: 1700px) {
    max-width: 1100px;
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: ${(props) => (props.margin ? '1rem auto' : '0 auto')};
`;

const FSIcon = styled.span`
  cursor: pointer;
  color: #2185d0;
`;

const Flex = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-around;
  text-align: center;
  margin: 2rem auto 0 auto;
  flex-wrap: wrap;

  &.margin {
    background: lightgreen;
  }

  &.circle {
    align-items: center;
    justify-content: space-around;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 2rem;

    &.blue {
      background: ${colors.blue};
    }

    &.orange {
      background: ${colors.orange};
    }
  }

  .flex-item {
    width: 45%;
    min-width: 300px;
    min-height: 250px;
    padding-bottom: 5rem;
  }
`;

const ExcelDownload = styled.div`
  background: ${colors.teal};
  color: black;
  margin-left: 1rem;
  padding: 1px 10px 3px 10px;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 11px;
  max-width: 12rem;
  max-height: 1.5rem;
  font-weight: bold;
`;

const TabBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const TabButton = styled.button`
  background-color: ${(props) => (props.active ? '#b7c1fe' : 'transparent')};
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? '#b7c1fe' : '#ddd')};
  }
`;
const CircleButton = ({ children, onClick }) => {
  const translateX = children === 'chevron left' ? '-2px' : '-20px';

  return (
    <button
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: 'none',
        background: `transparent`,
        color: `${colors.blue}`,
        fontSize: '3rem',
        cursor: 'pointer',
        zIndex: '6',
      }}
      onClick={onClick}
    >
      <Icon
        name={children}
        style={{ transform: `translateX(${translateX})` }}
      />
    </button>
  );
};
