import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Accounts, Portfolio, Storage } from 'actions';
import { Header, Segment, Button } from 'components';
import { colors } from 'utils/colors';

class Cash extends Component {
  /*
    Creates a "Cash holding" in order to account for the amount of cash in the analysis
    The security_detail parameter is required for the "convertToTickersAndWeights" function in the utils/helpers.js file
  */
  getCash = (account) => {
    const cash = {
      account: account.id,
      account_id: account.id,
      currency: account.currency,
      market_value: account.cash,
      name: 'Cash',
      security: 'CASH:MKT',
      ticker: 'CASH:MKT',
      security_detail: {
        exchange_symbol: 'CASH:MKT',
        ticker: 'CASH:MKT',
        long_name: 'Cash',
        unadjusted_closing_price: {
          CAD: 1,
          USD: 1,
        },
      },
      unadjusted_closing_price: {},
    };
    const holdingsCash = account.holdings.concat(cash);
    return holdingsCash;
  };

  analyze = (holdings, region) => {
    const { account, dispatch, history } = this.props;
    const gicHoldings = holdings.filter((holding) => holding.gic_ticker);
    Storage.removeItem('pwpa-has-exclude-holding');

    return dispatch(
      Accounts.analyze(holdings, history, account, gicHoldings, region)
    );
  };

  render() {
    const { account, dispatch, user_region } = this.props;
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <Segment margin="2rem 3rem 2rem 3rem" expand>
          <div style={{ position: 'relative' }}>
            <Header size="1.5rem" color="black">
              Include cash in your account for portfolio analysis
            </Header>
            <P>
              The main “Analyze” button assumes that the cash in your account
              balance (0% interest) is temporary, and excludes it from the
              analysis. Here you can include cash in the analysis and see the
              impact of the so-called “cash drag” on your account’s scores.
            </P>
            <P>
              What is a cash drag? Since cash has no market exposure, it will
              likely drag down returns over time relative to the benchmark,
              which has no cash. (Your account’s benchmark portfolio is
              automatically picked for you based on your asset allocation).
            </P>
            <Button
              className="left"
              icon="line chart"
              color={colors.darkBlue}
              style={{ top: '20px' }}
              onClick={() => {
                this.analyze(this.getCash(account), user_region);
                dispatch(Portfolio.notSaveUrlSc());
              }}
            >
              Analyze
            </Button>
          </div>
        </Segment>
      </div>
    );
  }
}

Cash.propTypes = {
  account: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  user_region: PropTypes.string,
};

Cash.defaultProps = {
  account: {},
  dispatch: () => {},
  history: {},
  user_region: 'CA',
};

export default connect((state) => ({
  user_region: state.Storage.user_region,
}))(Cash);

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;

  &.strike {
    text-decoration: line-through;
  }
`;
