import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

class DirectNavLink extends Component {
  hasOneChild() {
    const { menuName, featureRestrictions, fullFeatureList } = this.props;

    const subMenu = fullFeatureList.filter(
      (feature) =>
        feature.toLowerCase().includes(menuName.toLowerCase()) &&
        feature.toLowerCase() !== menuName.toLowerCase() &&
        (feature.match(/_/g) || []).length === 1
    );

    const remainMenu = subMenu.filter(
      (child) => !featureRestrictions.includes(child)
    );

    if (remainMenu.length === 1) {
      return remainMenu[0].split('_')[1].toLowerCase();
    }
    return '';
  }

  render() {
    const { menuName } = this.props;

    const landingPage = `/${menuName}/landing`;

    if (this.hasOneChild() !== '') {
      const childName = this.hasOneChild();
      const childPage = `/${menuName}/${childName}`;

      return <NavLink to={childPage}>{menuName}</NavLink>;
    }

    return <NavLink to={landingPage}>{menuName}</NavLink>;
  }
}

DirectNavLink.propTypes = {
  menuName: PropTypes.string.isRequired,
  featureRestrictions: PropTypes.array,
  fullFeatureList: PropTypes.array,
};

DirectNavLink.defaultProps = {
  featureRestrictions: [],
  fullFeatureList: [],
};

export default connect((state) => ({
  featureRestrictions: state.Storage['feature-restrictions'],
  fullFeatureList: state.Storage['full-feature-list'],
}))(DirectNavLink);
