import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Icon, Header, Flag } from 'semantic-ui-react';
import { Segment, Table } from 'components';
import { colors } from 'utils/colors';
import { exchange } from 'utils/constants';
import { isGlobeInvestor } from 'utils/helpers';

class HoldingTable extends Component {
  getPriceFlag = (holding) => {
    const currency = {
      CAD: 'ca',
      USD: 'us',
    };

    // if security_detail does not exist, use yodlee currency for flag
    return holding.security_detail
      ? currency[holding.security_detail.default_currency]
      : currency[holding.default_currency];
  };

  getName = (holding) => {
    const { name, security_detail } = holding;

    if (name) {
      return name;
    }

    return security_detail ? security_detail.long_name : 'N/A';
  };

  getSecLink = (holding) => {
    const cik = holding.item.security_detail
      ? holding.item.security_detail.cik
        ? holding.item.security_detail.cik
        : false
      : false;

    // Check if cik is available and truthy, then construct the URL using template literals
    if (cik) {
      return `https://www.sec.gov/edgar/browse/?CIK=${cik}`;
    }
    return false;
  };

  getSector(holding) {
    const { security_detail } = holding;
    if (!security_detail) {
      return '-';
    }
    const { sector } = security_detail;
    if (!sector) {
      return '-';
    }
    if (sector === 'info_tech') {
      return 'Info Tech';
    }
    const words = sector.split('_');
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const formattedSector = formattedWords.join('<br />');

    return formattedSector;
  }

  getMarketValue = (holding) => {
    const { account, individualAccount, user_region } = this.props;

    if (!holding.market_value && !holding.quantity) {
      return 'N/A';
    }

    // use our generated price if exists, else use yodlee price
    const price = holding.security_detail
      ? holding.security_detail.unadjusted_closing_price.CAD
      : holding.unadjusted_closing_price.CAD || 0;

    // use yodlee market_value if exists, else use our market_value
    let mktValue = holding.market_value || price * holding.quantity;

    if (
      individualAccount &&
      account &&
      account.currency === 'USD' &&
      user_region === 'CA'
    ) {
      mktValue *= account.usd2cad;
    }

    return this.formatNum(mktValue, '$');
  };

  getMarketPercentage = (holding) => {
    const { totalMarketValue, account, individualAccount, user_region } = this.props;
  
    // Return null if total market value or holding quantity/market value is missing
    if (!totalMarketValue || (!holding.market_value && !holding.quantity)) {
      return null;
    }
  
    // Use market_value if available, else calculate it using unadjusted_closing_price
    let mktValue = holding.market_value;
  
    if (!mktValue) {
      const price = holding.security_detail
        ? holding.security_detail.unadjusted_closing_price.CAD
        : holding.unadjusted_closing_price.CAD || 0;
      mktValue = price * holding.quantity;
    }
  
    // Adjust for currency conversion if needed
    if (
      individualAccount &&
      account &&
      account.currency === 'USD' &&
      user_region === 'CA'
    ) {
      mktValue *= account.usd2cad;
    }
  
    // Calculate the percentage of total market value
    return `${parseFloat(
      (100 * (mktValue / totalMarketValue)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    )}%`;
  };

  getPrice = (holding) =>
    holding.security_detail
      ? this.formatNum(
          holding.security_detail.unadjusted_closing_price[
            holding.default_currency
          ],
          '$'
        )
      : this.formatNum(
          holding.unadjusted_closing_price[holding.default_currency],
          '$'
        );

  getDailyChange = (holding) => {
    const daily = holding.security_detail
      ? holding.security_detail.daily_change
      : holding.daily_change;

    return this.formatNum(daily, '%');
  };

  round = (num) => Math.round(num * 10000) / 10000;

  formatNum = (num, sign) => {
    if (!num && num !== 0) {
      return 'N/A';
    }

    const formatted = Number(num.toFixed(2)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return sign === '$' ? `$${formatted}` : `${formatted}%`;
  };

  isUnmatched = (holding) => !holding.security_detail;

  isNoValue = (holding) => {
    // If no security_detail, catch by "isUnmatched"
    if (!holding.security_detail) {
      return false;
    }

    // use yodlee price if exists, else use our generated price
    const price = holding.unadjusted_closing_price[holding.default_currency]
      ? holding.unadjusted_closing_price[holding.default_currency]
      : holding.security_detail
      ? holding.security_detail.unadjusted_closing_price[
          holding.default_currency
        ]
      : 0;

    return !(holding.market_value || price * holding.quantity);
  };

  hasUsdAccount = (accountList) =>
    !!accountList.find((acc) => acc.currency === 'USD');

  render() {
    const {
      holdings,
      title,
      account,
      accountList = [],
      user_region,
      partnerName,
    } = this.props;
    const filtered = holdings.reduce(
      (sorted, item, index) => {
        if (this.isUnmatched(item) || this.isNoValue(item)) {
          sorted.invalid.push({ key: index, item });
        } else {
          sorted.valid.push({ key: index, item });
        }

        return sorted;
      },
      {
        valid: [],
        invalid: [],
      }
    );
    const sortedHoldings = [...filtered.valid, ...filtered.invalid];

    return (
      <Segment basic compact textAlign="center">
        <Header size="medium" content={title} />
        {!holdings.length ? (
          <Header
            disabled
            size="small"
            content={
              account && account.provider === 'ydl'
                ? 'We are unable to retrieve holdings for this account'
                : 'This account does not have any holdings'
            }
          />
        ) : (
          <div style={{ position: 'relative' }}>
            <Table textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Ticker/Name</Table.HeaderCell>
                  <Table.HeaderCell>Asset Class</Table.HeaderCell>
                  <Table.HeaderCell>
                    Sector{' '}
                    <Popup
                      trigger={<Icon name="question circle outline" />}
                      position="top center"
                      content="Applicable for Individual Stocks"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Market Value
                    {((account && account.currency === 'USD') ||
                      this.hasUsdAccount(accountList)) &&
                      user_region === 'CA' && (
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          wide
                          content="Converted to CAD for the purpose of aggregating accounts in different currencies. Note that this does not affect USD account(s)-only portfolio analysis as the weights of your holdings are preserved."
                        />
                      )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Traded in</Table.HeaderCell>
                  <Table.HeaderCell>Previous Close</Table.HeaderCell>
                  <Table.HeaderCell>Daily Gain/Loss</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sortedHoldings.map((holding) => (
                  <Table.Row
                    key={holding.key}
                    negative={
                      this.isUnmatched(holding.item) ||
                      this.isNoValue(holding.item)
                    }
                  >
                    <Table.Cell style={{ position: 'relative' }}>
                      {this.getSecLink(holding) &&
                      !isGlobeInvestor(partnerName) ? (
                        <Popup
                          trigger={
                            <a
                              href={this.getSecLink(holding)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {!!holding.item.security
                                ? holding.item.security
                                : holding.item.ticker || 'N/A'}
                            </a>
                          }
                          content="Click for SEC Filings"
                          position="right center"
                        />
                      ) : !!holding.item.security ? (
                        holding.item.security
                      ) : (
                        holding.item.ticker || 'N/A'
                      )}
                      <SecurityName negative={!holding.item.security_detail}>
                        {this.getName(holding.item)}
                      </SecurityName>
                      {this.isUnmatched(holding.item) && (
                        <Error>
                          <Popup
                            trigger={<Icon name="exclamation circle" />}
                            position="left center"
                            content="Unfortunately, we could not match this security to our database, thus it will not be used in analysis."
                          />
                        </Error>
                      )}
                      {this.isNoValue(holding.item) && (
                        <Error>
                          <Popup
                            trigger={<Icon name="exclamation circle" />}
                            position="left center"
                            content="No quantity or market value was retrieved for this holding, thus it will not be included in the analysis."
                          />
                        </Error>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {holding.item.security_detail
                        ? holding.item.security_detail.global_asset_class[0].toUpperCase() +
                          holding.item.security_detail.global_asset_class.slice(
                            1
                          )
                        : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {holding.item.security_detail ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.getSector(holding.item),
                          }}
                        />
                      ) : (
                        'N/A'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {/* 
                        Region CA: CAD account -> CAD
                                   USD account -> Converted to CAD for the purpose of aggregating accounts in different currencies.

                        Region US: Numbers are in USD. Divide exchange rate when getMarketValue for Canadian holdings
                      */}
                      {this.getMarketValue(holding.item) === 'N/A' ? (
                        'N/A'
                      ) : (
                        <div>
                          {this.getMarketValue(holding.item)}
                          &nbsp;
                          {user_region === 'CA' ? (
                            <Flag name="ca" />
                          ) : (
                            <Flag name="us" />
                          )}
                          <ValuePercentage
                            negative={!holding.item.security_detail}
                          >
                            {this.getMarketPercentage(holding.item)
                              ? `(${this.getMarketPercentage(holding.item)})`
                              : null}
                          </ValuePercentage>
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {holding.item.security_detail
                        ? exchange[
                            holding.item.security_detail.exchange_symbol
                          ] || holding.item.security_detail.exchange_symbol
                        : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getPrice(holding.item) === 'N/A' ? (
                        'N/A'
                      ) : (
                        <div>
                          {this.getPrice(holding.item)}{' '}
                          <Flag name={this.getPriceFlag(holding.item)} />
                        </div>
                      )}
                    </Table.Cell>
                    <Table.Cell>{this.getDailyChange(holding.item)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </Segment>
    );
  }
}

HoldingTable.propTypes = {
  holdings: PropTypes.array,
  account: PropTypes.object,
  title: PropTypes.string,
  totalMarketValue: PropTypes.number,
  accountList: PropTypes.array,
  individualAccount: PropTypes.bool,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

HoldingTable.defaultProps = {
  holdings: [],
  account: null,
  title: '',
  totalMarketValue: 0,
  accountList: [],
  individualAccount: false,
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))(HoldingTable);

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const ValuePercentage = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;
