import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { Accounts as AccountsActions, CacheInputs } from 'actions';
// import { Loader } from 'components';

import MainDash from './MainDash';

class Dashboard extends Component {
  componentWillMount = () => {
    const { dispatch, accountList } = this.props;

    if (!accountList) {
      return dispatch(AccountsActions.fetchAll());
    }

    return false;
  };

  componentWillUnmount = () => {
    const { dispatch, location } = this.props;

    return dispatch(CacheInputs.captureUrl('accounts', location.pathname));
  };

  render() {
    const { accountList } = this.props;

    return !accountList ? null : ( //<Loader />
      <div style={{ height: 'calc(100% - 2rem)' }}>
        <Switch>
          <Route exact path="/dashboard" component={MainDash} />
        </Switch>
      </div>
    );
  }
}

Dashboard.propTypes = {
  accountList: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

Dashboard.defaultProps = {
  accountList: null,
};

export default connect((state) => ({
  accountList: state.Accounts.accountList,
}))(Dashboard);
