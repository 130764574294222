import { request } from './api';

import * as Alert from './alert';

/**
 * Action constants
 */
export const FUNDCOMPARISON_REQUEST = 'FUNDCOMPARISON_REQUEST';
export const FUNDCOMPARISON_ADD_FUND_DATA = 'FUNDCOMPARISON_ADD_FUND_DATA';
export const FUNDCOMPARISON_DELETE_FUND_DATA =
  'FUNDCOMPARISON_DELETE_FUND_DATA';
export const FUNDCOMPARISON_UPDATE_GRAPH_DATES =
  'FUNDCOMPARISON_UPDATE_GRAPH_DATES';
export const FUNDCOMPARISON_ADD_CHARTERROR_LIST =
  'FUNDCOMPARISON_ADD_CHARTERROR_LIST';

/**
 * Action creators
 */

export function addFund(ticker) {
  return (dispatch) => {
    dispatch({ type: FUNDCOMPARISON_REQUEST });

    return dispatch(request('get', `/security/${ticker}/`)).then(
      (data) =>
        dispatch({
          type: FUNDCOMPARISON_ADD_FUND_DATA,
          ticker,
          securityData: data,
        }),
      (error) => {
        dispatch({
          type: FUNDCOMPARISON_ADD_FUND_DATA,
          securityData: undefined,
        });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}
export function deleteFund(ticker) {
  return { type: FUNDCOMPARISON_DELETE_FUND_DATA, ticker };
}

export function updateGraphDates(dates) {
  // an example of a valid 'dates' argument is {startDate: '2007-03', endDate: '2021-04'}
  // if any of the two keys are null or undefined, the corresponding property will not be updated in the store
  return { type: FUNDCOMPARISON_UPDATE_GRAPH_DATES, dates };
}

export function addChartErrorList(ticker) {
  return { type: FUNDCOMPARISON_ADD_CHARTERROR_LIST, ticker };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
