import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Dropdown } from 'semantic-ui-react';
import { Planning, Portfolio, Accounts } from 'actions';
import cn from 'classnames';

@connect((state) => ({
  portfolios: state.Portfolio.user.concat(state.Portfolio.model),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class InvestmentPortfolio extends Component {
  state = {
    scenario: null,
  };

  componentWillMount = () => {
    const { dispatch, portfolios, drawdown } = this.props;
    const { inputs } = drawdown;
    const { activeSpouse } = inputs; // Updated this line

    dispatch(Planning.getCppOasDataset());
    dispatch(Accounts.fetchAll());

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }
    const isRiskFreeField =
      activeSpouse === 'spouse' ? 'isRiskFree' : 'isRiskFree2';
    const scenarioField = activeSpouse === 'spouse' ? 'scenario' : 'scenario2';

    if (!inputs[isRiskFreeField] && inputs[scenarioField]) {
      this.setState({
        [scenarioField]: inputs[scenarioField],
      });
    }

    return false;
  };

  getDropOptions = () => {
    const { portfolios } = this.props;
    const { inputs } = this.props.drawdown;
    const { activeSpouse } = inputs; // Updated this line
    const portfolioField =
      activeSpouse === 'spouse' ? 'portfolio' : 'portfolio2';

    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return portfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      selected:
        inputs[portfolioField] !== undefined &&
        portf.id === Number(inputs[portfolioField]),
      text: portf.name,
    }));
  };

  getPortfolio = (portfolios, portfolioId) =>
    portfolios.find((port) => port.id === Number(portfolioId));

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;
    const { activeSpouse } = drawdown.inputs; // Get the active spouse

    // Determine the correct field name based on the active spouse
    const isRiskFreeField =
      activeSpouse === 'spouse' ? 'isRiskFree' : 'isRiskFree2';
    const portfolioField =
      activeSpouse === 'spouse' ? 'portfolio' : 'portfolio2';
    const constantRetsField =
      activeSpouse === 'spouse' ? 'constantRets' : 'constantRets2';
    const pctCapGainField =
      activeSpouse === 'spouse' ? 'pctCapGain' : 'pctCapGain2';
    const divYieldField = activeSpouse === 'spouse' ? 'divYield' : 'divYield2';
    const pctEligibleDivField =
      activeSpouse === 'spouse' ? 'pctEligibleDiv' : 'pctEligibleDiv2';

    if (field === isRiskFreeField && value === true) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [field]: value,
            [pctCapGainField]: null,
            [divYieldField]: null,
            [pctEligibleDivField]: null,
            ...(!value && {
              [portfolioField]: drawdown.inputs[portfolioField],
            }),
          },
          errors: {
            [pctCapGainField]: false,
            [divYieldField]: false,
            [pctEligibleDivField]: false,
            [portfolioField]: false,
            [constantRetsField]: false,
          },
          modified: true,
        })
      );
    } else if (field === isRiskFreeField && value === false) {
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [field]: value,
            [pctCapGainField]: null,
            [divYieldField]: null,
            [pctEligibleDivField]: '',
            ...(!value && {
              [portfolioField]: drawdown.inputs[portfolioField],
            }),
          },
          errors: {
            [pctCapGainField]: false,
            [divYieldField]: false,
            [pctEligibleDivField]: false,
            [portfolioField]: false,
            [constantRetsField]: false,
          },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch } = this.props;

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  handlePortfolioChange = (e, { value }) => {
    const { drawdown } = this.props;
    const { activeSpouse } = drawdown.inputs; // Get the active spouse

    // Determine the correct field name based on the active spouse
    const portfolioField =
      activeSpouse === 'spouse' ? 'portfolio' : 'portfolio2';
    const constantRetsField =
      activeSpouse === 'spouse' ? 'constantRets' : 'constantRets2';
    const divYieldField = activeSpouse === 'spouse' ? 'divYield' : 'divYield2';

    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: {
          [portfolioField]: value,
          [constantRetsField]: null,
          [divYieldField]: this.prepopDividendYield(value),
        },
        errors: { [portfolioField]: false, [divYieldField]: false },
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (inputs, portfolios) => {
    const { isRiskFree, isRiskFree2, portfolio, portfolio2 } = inputs;
    if (isRiskFree === undefined && isRiskFree2 === undefined) return false;
    if (isRiskFree || isRiskFree2) return false;
    if (portfolio === undefined && portfolio2 === undefined) return false;

    return (
      !portfolios.find((p) => p.id === portfolio) &&
      !portfolios.find((p) => p.id === portfolio2)
    );
  };

  prepopDividendYield = (portfolioId) => {
    const { portfolios } = this.props;

    if (this.getPortfolio(portfolios, portfolioId)) {
      if (this.getPortfolio(portfolios, portfolioId).dividend_yield !== 0) {
        return this.getPortfolio(portfolios, portfolioId).dividend_yield;
      }

      return '0';
    }

    return '';
  };

  render() {
    const { inputs, errors } = this.props.drawdown;
    const { portfolios } = this.props;
    const { activeSpouse } = inputs; // Get the active spouse

    // Determine the correct field names based on the active spouse
    const isRiskFreeField =
      activeSpouse === 'spouse' ? 'isRiskFree' : 'isRiskFree2';
    const portfolioField =
      activeSpouse === 'spouse' ? 'portfolio' : 'portfolio2';
    const constantRetsField =
      activeSpouse === 'spouse' ? 'constantRets' : 'constantRets2';
    const scenarioField = activeSpouse === 'spouse' ? 'scenario' : 'scenario2';
    const pctCapGainField =
      activeSpouse === 'spouse' ? 'pctCapGain' : 'pctCapGain2';
    const divYieldField = activeSpouse === 'spouse' ? 'divYield' : 'divYield2';
    const pctEligibleDivField =
      activeSpouse === 'spouse' ? 'pctEligibleDiv' : 'pctEligibleDiv2';

    return (
      <div>
        <Section single style={{ marginBottom: 0 }}>
          <div>
            <div
              className="title"
              style={{ position: 'relative', width: '100%' }}
            >
              <div style={{ width: '80%' }}>
                Are you putting your retirement savings in a risk-free
                investment?
              </div>
              <OptionsWrapper style={{ top: 0 }}>
                <div
                  className={cn('select', { active: inputs[isRiskFreeField] })}
                  onClick={() => {
                    this.setState({ scenario: null });
                    this.changeSelection([isRiskFreeField], true);
                    this.changeSelection([scenarioField], '');
                  }}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', { active: !inputs[isRiskFreeField] })}
                  onClick={() => {
                    if (!this.state.scenario) {
                      this.setState({ scenario: null });
                    }
                    this.changeSelection([isRiskFreeField], false);
                    this.changeSelection([constantRetsField], null);
                  }}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </div>

            <div
              style={{
                display: !inputs[isRiskFreeField] ? 'flex' : 'none',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="description">
                Use a portfolio close to your desired asset allocation. Pick a
                model portfolio, or save a portfolio in the "Portfolios"
                segment. You will see them in the drop down on the right.
              </div>
              <StyledDropdown
                style={{}}
                className={cn({
                  invalid:
                    errors[portfolioField] ||
                    this.portfolioIsDeleted(inputs, portfolios)
                      ? 'true'
                      : undefined,
                })}
                placeholder="Select a portfolio"
                selection
                disabled={portfolios.length === 0}
                options={this.getDropOptions()}
                long
                value={inputs[portfolioField] || ''}
                text={
                  inputs[portfolioField] !== undefined &&
                  portfolios.length !== 0 &&
                  !inputs[isRiskFreeField] &&
                  this.getPortfolio(portfolios, inputs[portfolioField]) &&
                  this.getPortfolio(portfolios, inputs[portfolioField]).name
                }
                selectOnBlur={false}
                onChange={this.handlePortfolioChange}
              />
            </div>

            <div
              style={{ display: inputs[isRiskFreeField] ? 'block' : 'none' }}
            >
              <div className="description">
                What is the expected rate of return?
              </div>
              <Input
                className={cn({
                  invalid: errors[constantRetsField] ? 'true' : undefined,
                })}
                name={constantRetsField}
                type="number"
                placeholder="%"
                value={inputs[constantRetsField] || ''}
                onChange={this.handleChange}
              />
            </div>
          </div>
          {this.portfolioIsDeleted(inputs, portfolios) && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '10px',
                color: 'red',
              }}
            >
              Your portfolio has been removed. Please select a new portfolio.
            </div>
          )}
        </Section>
      </div>
    );
  }
}

InvestmentPortfolio.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  portfolios: PropTypes.array,
};

InvestmentPortfolio.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  portfolios: [],
};

const Section = styled.section`
  display: flex; /* Add this line */
  flex-direction: column;
  padding-left: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 0 auto 2rem auto;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }
  &.large {
    margin: 0.7rem 0;
    clear: both;
  }
`;

const Input = styled.input`
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
