import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Popup,
  Icon,
  Dropdown,
  Header,
  Divider,
  Form,
  Checkbox,
} from 'semantic-ui-react';
import cn from 'classnames';

import { Analytics, CompareAccount } from 'actions';
import { colors } from 'utils/colors';
import { nestData } from 'utils/helpers';
import { request } from 'actions/api';
import AllocateWeights from './AllocateWeights';
import Modal from './Modal';
import Segment from './Segment';
import Button from './Button';

@connect((state) => ({
  analytics: state.Analytics,
  partnerName: state.Storage['partner-name'],
  user_region: state.Storage.user_region || 'CA',
  toggle_region: state.Storage.toggle_region || 'CA',
  french: state.Storage.language === 'fr',
}))
export default class CustomBenchmark extends Component {
  state = {
    isOpen: false,
    data: null,
    selectAssets: true,
  };

  componentWillMount = () => {
    const { dispatch } = this.props;
    const decideRegion = () => {
      if (this.props.user_region === 'US') {
        return 'US';
      } else if (this.props.toggle_region === 'US') {
        return 'US';
      }
      return 'CA';
    };

    return dispatch(
      request(
        'get',
        `/portfolio/byoetf/?region=${decideRegion()}&benchmark=true`
      )
    ).then((data) => {
      this.setState({ data: nestData(data) });
    });
  };

  getDropOptions = () => {
    const { replaceDomestic, partnerName, french } = this.props;
    if (replaceDomestic === 'US' && partnerName !== 'quotemedia_pro') {
      return [
        {
          key: 'dom',
          value: 'domestic',
          text: replaceDomestic || (french ? 'National' : 'Domestic'),
        },
        { key: 'glb', value: 'global', text: french ? 'Global' : 'Global' },
      ];
    }
    return [
      {
        key: 'dom',
        value: 'domestic',
        text: replaceDomestic || (french ? 'National' : 'Domestic'),
      },
      { key: 'glb', value: 'global', text: french ? 'Global' : 'Global' },
      {
        key: 'cus',
        value: 'custom',
        text:
          partnerName === 'quotemedia_pro'
            ? french
              ? 'Personnalisé'
              : 'Custom'
            : 'US',
      },
    ];
  };

  getPortfolioRtio = () => {
    const { analytics, french } = this.props;

    if (!analytics || !analytics.data || !analytics.data.fratios) {
      return '';
    }

    const ratioStr = analytics.data.fratios.domestic.name.split(' ');
    /* All possibilities:
      Stock Market
      Bond Market
      90/10 Portfolio
      80/20 Portfolio ...
      10/90 Portfolio
    */

    if (ratioStr[1] === 'Market') {
      if (ratioStr[0] === 'Bond') {
        return french
          ? "La répartition d'actifs de votre portefeuille est la plus proche de 100 % d'obligations."
          : "Your portfolio's asset allocation is closest to 100% Bonds.";
      }

      return french
        ? "La répartition d'actifs de votre portefeuille est la plus proche de 100 % d'actions."
        : "Your portfolio's asset allocation is closest to 100% Equity.";
    } else if (ratioStr[1] === 'Portfolio') {
      const ratio = ratioStr[0].split('/');
      return french
        ? `La répartition d'actifs de votre portefeuille est la plus proche de ${ratio[0]} % d'actions et de ${ratio[1]} % d'obligations`
        : `Your portfolio's asset allocation is closest to ${ratio[0]}% Equity and ${ratio[1]}% Bonds`;
    }

    return '';
  };

  decideRegion = () => {
    if (this.props.user_region === 'US') {
      return 'US';
    }
    return this.props.toggle_region || 'CA';
  };

  close = () => this.setState({ isOpen: false });

  changeBenchmark = (e, { value }) => {
    const { dispatch, feature } = this.props;

    if (value === 'custom' && this.isAbleToUseCustomBenchmark()) {
      return this.setState({ isOpen: true });
    }

    return feature === 'compareAccounts'
      ? dispatch(CompareAccount.updateBenchmark(value))
      : dispatch(Analytics.updateParameter('benchmark', value));
  };

  decideCountry = (data, userRegion) => {
    const { french } = this.props;
    if (userRegion === 'US')
      return french
        ? "L'indice par défaut est un FNB S&P 500. L'indice de référence est utilisé pour évaluer la performance et la protection contre les baisses de votre portefeuille. Les poids de l'indice de référence par défaut sont automatiquement attribués."
        : 'The default is an S&P 500 ETF. The benchmark is used to gauge the performance and downside protection of your portfolio. The weights of the default benchmark are automatically assigned.';

    const { paramsPassed } = data;

    if (paramsPassed.region === 'US')
      return french
        ? "L'indice par défaut est un FNB S&P 500. L'indice de référence est utilisé pour évaluer la performance et la protection contre les baisses de votre portefeuille. Les poids de l'indice de référence par défaut sont automatiquement attribués."
        : 'The default is an S&P 500 ETF. The benchmark is used to gauge the performance and downside protection of your portfolio. The weights of the default benchmark are automatically assigned.';

    return french
      ? "L'indice par défaut est un FNB TSX Capped Composite. L'indice de référence est utilisé pour évaluer la performance et la protection contre les baisses de votre portefeuille. Les poids de l'indice de référence par défaut sont automatiquement attribués."
      : 'The default is a TSX Capped Composite ETF. The benchmark is used to gauge the performance and downside protection of your portfolio. The weights of the default benchmark are automatically assigned.';
  };

  toggleClass = (e, { name }) => {
    const { dispatch, analytics } = this.props;
    const { selectedAssets, selectedClass } = analytics;

    dispatch(
      Analytics.updateParameter('selectedClass', {
        ...selectedClass,
        [name]: !selectedClass[name],
      })
    );

    dispatch(
      Analytics.updateParameter(
        'selectedAssets',
        Object.values(selectedAssets).reduce((newAssets, asset) => {
          if (asset.assetclass !== name) {
            newAssets[asset.symbol] = asset;
          }

          return newAssets;
        }, {})
      )
    );
  };

  toggleAsset = (e, { value }) => {
    const { dispatch, analytics } = this.props;
    const { selectedAssets, selectedClass } = analytics;

    if (!selectedClass[value.assetclass]) {
      return false;
    }

    return dispatch(
      Analytics.updateParameter(
        'selectedAssets',
        !selectedAssets[value.symbol]
          ? { ...selectedAssets, [value.symbol]: { ...value, weight: 0 } }
          : {
              ...Object.values(selectedAssets).reduce((result, item) => {
                if (item.symbol !== value.symbol) {
                  result[item.symbol] = item;
                }

                return result;
              }, {}),
            }
      )
    );
  };

  updateWeight = (name, value) => {
    const { dispatch, analytics } = this.props;
    const { selectedAssets } = analytics;

    dispatch(
      Analytics.updateParameter('selectedAssets', {
        ...selectedAssets,
        [name]: {
          ...selectedAssets[name],
          weight: Number(value) > 100 ? 100 : Math.round(value * 100) / 100,
        },
      })
    );
  };

  isAbleToUseCustomBenchmark = () => {
    const { partnerName } = this.props;

    if (partnerName === 'quotemedia_pro') {
      return true;
    }

    return false;
  };

  renderCheckboxes = (arr) => {
    const { analytics } = this.props;
    const { selectedAssets } = analytics;

    return arr
      .map((elem) =>
        elem.is_benchmark ? (
          <Checkbox
            key={elem.symbol}
            name={elem.symbol}
            label={elem.name}
            value={elem}
            checked={!!selectedAssets[elem.symbol]}
            onChange={this.toggleAsset}
            style={{ margin: '2px 0', display: 'block' }}
          />
        ) : null
      )
      .filter((checkBox) => checkBox !== null);
  };

  renderSubClass = (data, selectedClass, assetClass) => {
    const renderSubSubClass = (subClass, subSubClass) =>
      Object.keys(subSubClass).map((subSubClassName) => {
        const result = this.renderCheckboxes(data[subClass][subSubClassName]);

        return result.length ? <div>{result}</div> : null;
      });

    return Object.keys(data).map((subClass) => {
      const subSubClassList = renderSubSubClass(
        subClass,
        data[subClass]
      ).filter((subSubClass) => subSubClass !== null);

      return subSubClassList.length ? (
        <Segment basic compact disabled={!selectedClass[assetClass]}>
          <Header size="small">
            {subClass.toUpperCase() === 'US'
              ? this.props.french
                ? 'États-Unis'
                : 'U.S.'
              : subClass.toUpperCase()}
          </Header>
          <Divider fitted style={{ marginBottom: '0.75rem' }} />
          {subSubClassList}
        </Segment>
      ) : null;
    });
  };

  render() {
    const { benchmark, feature, analytics, replaceDomestic } = this.props;
    const { selectedClass, selectedAssets } = analytics;
    const { isOpen, data, selectAssets, french } = this.state;
    const ableToUseCustomBenchmark = this.isAbleToUseCustomBenchmark();

    return !data ? null : (
      <div>
        <Benchmark feature={feature}>
          <p>
            {french
              ? "Utiliser un autre FNB d'indice pour la partie actions de l'indice de référence de mon portefeuille"
              : "Use another index ETF for the equity portion of my portfolio's benchmark"}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="bottom center"
              wide
              content={
                this.decideRegion() === 'US'
                  ? french
                    ? "L'indice par défaut est un FNB S&P 500. L'indice de référence est utilisé pour évaluer la performance et la protection contre les baisses de votre portefeuille. Les poids de l'indice de référence par défaut sont automatiquement attribués."
                    : 'The default is an S&P 500 ETF. The benchmark is used to gauge the performance and downside protection of your portfolio. The weights of the default benchmark are automatically assigned.'
                  : french
                  ? "L'indice par défaut est un FNB TSX Capped Composite. L'indice de référence est utilisé pour évaluer la performance et la protection contre les baisses de votre portefeuille. Les poids de l'indice de référence par défaut sont automatiquement attribués."
                  : 'The default is a TSX Capped Composite ETF. The benchmark is used to gauge the performance and downside protection of your portfolio. The weights of the default benchmark are automatically assigned.'
              }
            />
          </p>
          <OptionsWrapper>
            <StyledDropdown
              selection
              placeholder={french ? 'Indice de référence' : 'Benchmark'}
              options={this.getDropOptions()}
              text={
                isOpen
                  ? french
                    ? 'Personnalisé'
                    : 'Custom'
                  : benchmark === 'domestic'
                  ? replaceDomestic || (french ? 'National' : 'Domestic')
                  : benchmark === 'global'
                  ? 'Global'
                  : ableToUseCustomBenchmark
                  ? french
                    ? 'Personnalisé'
                    : 'Custom'
                  : 'US'
              }
              onChange={this.changeBenchmark}
            />
          </OptionsWrapper>
        </Benchmark>

        <Modal
          open={isOpen}
          dimmerClick={() => this.close()}
          style={{ padding: '2rem', backgroundColor: '#ffffff' }}
        >
          <CloseIcon onClick={() => this.close()}>+</CloseIcon>
          <Header
            size="large"
            textAlign="center"
            style={{ marginBottom: '2rem' }}
          >
            {french
              ? 'Définir un indice de référence personnalisé'
              : 'Define a Custom Benchmark'}
            <Header.Subheader>{this.getPortfolioRtio()}</Header.Subheader>
          </Header>
          <Form>
            {selectAssets ? (
              <div>
                <Header
                  textAlign="center"
                  size="medium"
                  content={french ? 'Sélectionner des actifs' : 'Select Assets'}
                  style={{ padding: '0 0 2rem 0' }}
                />
                <StyledDiv>
                  <Header size="medium" textAlign="center">
                    {french ? 'Obligations' : 'Bonds'}
                    <Form.Checkbox
                      inline
                      name="Fixed Income"
                      checked={selectedClass['Fixed Income']}
                      onChange={this.toggleClass}
                      toggle
                    />
                  </Header>
                  {this.renderSubClass(
                    data['Fixed Income'],
                    selectedClass,
                    'Fixed Income'
                  )}
                </StyledDiv>

                <StyledDiv>
                  <Header size="medium" textAlign="center">
                    {french ? 'Actions' : 'Stocks'}
                    <Form.Checkbox
                      inline
                      name="Equity"
                      checked={selectedClass.Equity}
                      onChange={this.toggleClass}
                      toggle
                    />
                  </Header>
                  {this.renderSubClass(data.Equity, selectedClass, 'Equity')}
                </StyledDiv>

                <StyledDiv>
                  <Header size="medium" textAlign="center">
                    {french ? 'Alternative' : 'Alternative'}
                    <Form.Checkbox
                      inline
                      name="Alternative"
                      checked={selectedClass.Alternative}
                      onChange={this.toggleClass}
                      toggle
                    />
                  </Header>
                  {this.renderSubClass(
                    data.Alternative,
                    selectedClass,
                    'Alternative'
                  )}
                </StyledDiv>

                <ButtonWrapper>
                  <Button
                    className={cn('right', {
                      disabled: !Object.keys(selectedAssets).length,
                    })}
                    color={colors.orange}
                    icon="chevron right"
                    floated="right"
                    onClick={() => this.setState({ selectAssets: false })}
                  >
                    {french ? 'Suivant' : 'Next'}
                  </Button>
                </ButtonWrapper>

                <div style={{ clear: 'both' }} />
              </div>
            ) : (
              <AllocateWeights
                selectedAssets={selectedAssets}
                previousTab={() => this.setState({ selectAssets: true })}
                updateWeight={this.updateWeight}
                close={this.close}
                feature={feature}
              />
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}

CustomBenchmark.propTypes = {
  benchmark: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  analytics: PropTypes.object,
  feature: PropTypes.string,
  partnerName: PropTypes.string,
  user_region: PropTypes.string,
  toggle_region: PropTypes.string,
  replaceDomestic: PropTypes.string,
  french: PropTypes.bool,
};

CustomBenchmark.defaultProps = {
  dispatch: () => false,
  analytics: {},
  feature: '',
  partnerName: '',
  user_region: 'CA',
  toggle_region: 'CA',
  replaceDomestic: '',
  french: false,
};

const Benchmark = styled.div`
  font-size: 1.15rem;
  margin-bottom: 1.5rem;
  margin: 0.5rem 0;
  position: relative;
  width: 100%;

  p {
    font-size: ${(props) =>
      props.feature === 'compareAccounts' ? '1rem' : '1.15rem'};
    width: ${(props) =>
      props.feature === 'compareAccounts'
        ? 'calc(100% - 112px)'
        : 'calc(100% - 180px)'};
    text-align: ${(props) =>
      props.feature === 'compareAccounts' ? 'right' : 'inherit'};
    max-width: ${(props) =>
      props.feature === 'compareAccounts' ? 'none' : 'inherit'};
  }
`;

const StyledDropdown = styled(({ className, children, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 22px !important;
  min-width: 100px !important;
  min-height: 22px !important;
  padding: 1px 0 0 10px !important;

  .icon {
    padding: 2px 0 0 0 !important;
  }
`;

const OptionsWrapper = styled.div`
  padding: 3px 0;
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledDiv = styled.div`
  float: left;
  width: 33.3%;
`;

const ButtonWrapper = styled.div`
  position: relative;
  float: right;
  clear: both;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;
