import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import Message from 'components/Message';

const Result = ({ cpp, ...props }) =>
  !cpp ? null : (
    <Message visible icon positive {...props}>
      <Icon name="info circle" style={{ fontSize: '2rem' }} />
      <Message.Content>
        Your CPP/QPP pension is estimated to be $
        {cpp.toLocaleString(undefined, { maxFractionDigits: 2 })} per year in
        today’s dollars.{' '}
      </Message.Content>
    </Message>
  );

Result.propTypes = {
  cpp: PropTypes.number.isRequired,
};

export default Result;
