import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popup, Icon, Header } from 'semantic-ui-react';
import cn from 'classnames';
import styled from 'styled-components';

import { Button, Segment, SearchTicker } from 'components';
import { CacheInputs, Security, Storage } from 'actions';
import { getTickerSearchResult } from 'utils/helpers';
import { colors } from 'utils/colors';
import Overview from './Overview';

class FundLookup extends Component {
  state = { headerFixed: false };

  componentWillUnmount = () => {
    const { dispatch, location } = this.props;

    return dispatch(CacheInputs.captureUrl('tools', location.pathname));
  };

  generatePDF = () => {
    const { history } = this.props;

    return history.push('/tools/fund-lookup/pdf');
  };

  handleResultSelect = (title) => {
    const { dispatch } = this.props;

    dispatch(Security.fetch(title));
    return dispatch(Storage.setItem('fundLookupSecurity', title));
  };

  handleSearchChange = (value) => {
    const { dispatch } = this.props;

    return !!value.trim() && dispatch(Security.search(value));
  };

  handleScroll = () => {
    const { security, dispatch } = this.props;

    if (!Object.keys(security).length) return;
    dispatch(Storage.setItem('fundLookupSecurity', security));
    if (window.scrollY >= 100) {
      this.setState({ headerFixed: true });
    } else {
      this.setState({ headerFixed: false });
    }
  };

  render() {
    const { security, fundLookupSecurity } = this.props;
    const { search } = security;
    const showSecurityContent = security && security.security;

    return (
      <Segment
        textAlign="center"
        style={{
          position: 'relative',
          minHeight: '700px',
        }}
      >
        <HeaderContainer
          showSecurityContent={showSecurityContent}
          scrollYPos={window.scrollY}
          headerFixed={this.state.headerFixed}
        >
          <Header size="large">
            ETF / Mutual Fund Lookup
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ fontSize: '1.4rem', verticalAlign: 'initial' }}
                />
              }
              position="top center"
              wide
            >
              <Popup.Content>
                For mutual funds, use their fund code (e.g. TDB339) if you have
                them to make sure you have the correct share class. If searching
                by name, do not include periods (e.g., put "US" instead of
                "U.S.").
              </Popup.Content>
            </Popup>
          </Header>
          <div style={{ position: 'relative' }}>
            <SearchTicker
              loading={security.isFetching}
              placeholder="Enter fund code or name"
              results={getTickerSearchResult(search)}
              customResultSelect={this.handleResultSelect}
              customSearchChange={this.handleSearchChange}
            />
          </div>
        </HeaderContainer>
        {fundLookupSecurity && security && (
          <ContentContainer>
            <Overview data={security.security} />
          </ContentContainer>
        )}

        {security && security.security ? (
          <Button
            className={cn('left')}
            icon="file text"
            color={colors.darkGrey}
            onClick={this.generatePDF}
            style={{ display: 'flex', margin: '0 auto' }}
          >
            Print/PDF
          </Button>
        ) : null}
      </Segment>
    );
  }
}

FundLookup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fundLookupSecurity: PropTypes.string,
};

FundLookup.defaultProps = {
  fundLookupSecurity: '',
};

export default connect((state) => ({
  security: state.Security,
  fundLookupSecurity: state.Storage.fundLookupSecurity,
}))(FundLookup);

const ContentContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  margin-top: 120px;
`;

const HeaderContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  height: 100px;
  position: ${(props) => (props.headerFixed ? 'fixed' : 'absolute')};
  left: ${(props) => (props.headerFixed ? 'calc(50% + 13rem)' : '50%')};
  top: ${(props) => (props.headerFixed ? '4rem' : '2rem')};
  transform: ${(props) =>
    !props.showSecurityContent
      ? 'translate(-50%, 180%)'
      : 'translate(-50%, 0)'};
  transition: transform 1s;
  background-color: white;
  z-index: 2;
  width: ${(props) => (props.headerFixed ? 'calc(100% - 32rem)' : '100%')};
`;
