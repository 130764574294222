import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Icon,
  Popup,
  Header,
  Button as SemanticButton,
} from 'semantic-ui-react';
import cn from 'classnames';
import ReactExport from 'react-data-export';

import { colors } from 'utils/colors';
import { convertToTickersAndWeights } from 'utils/helpers';
import { RebalanceAccount } from 'actions';
import { Segment, Button, Table, Modal } from 'components';

const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

@connect((state) => ({
  rebalanceAcc: state.RebalanceAccount,
}))
export default class RebalanceModal extends Component {
  state = {
    cashAmount: 0,
    isDeposit: true,
    useCash: false,
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.open && !nextProps.open) {
      this.setState({
        cashAmount: 0,
        isDeposit: true,
        useCash: false,
      });
      this.props.dispatch(RebalanceAccount.clearRebalanceData());
    }
  };

  componentWillUnmount = () => {
    this.props.dispatch(RebalanceAccount.clearRebalanceData());
  };

  getTicker = (ticker) => {
    const { holdings } = this.props;
    const holding = holdings.find((h) => h.ticker === ticker);

    return holding.gic_ticker ? holding.gic_ticker : holding.ticker;
  };

  getOriginalWeight = (ticker) => {
    const { weights, tickers } = this.props.rebalanceAcc.original;
    const originalWeights = weights.split(',');
    const originalTickers = tickers.split(',');
    const idx = originalTickers.findIndex((val) => val === ticker);

    return (Math.round(originalWeights[idx] * 100 * 100) / 100).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  };

  generateDataSet = (data) => {
    const { holdings, rebalanceData } = data;
    const { tickers, quantities } = convertToTickersAndWeights(
      Object.values(holdings).filter((val) => !!val.security_detail)
    );
    const tickersArr = tickers ? tickers.split(',') : [];
    const quantitiesArr = quantities ? quantities.split(',') : [];
    const pairTickerQuantity = tickersArr.reduce((result, t, index) => {
      result[t] = quantitiesArr[index];
      return result;
    }, {});

    if (rebalanceData) {
      return rebalanceData.map((row) => {
        const { ticker, security_price } = row;
        const holding = holdings[ticker];
        const securityDetail = holding.security_detail;
        const reformatRebalData = rebalanceData.reduce((result, rebal) => {
          result[rebal.ticker] = rebal;
          return result;
        }, {});

        const template = {
          ticker: holding
            ? `${
                (holding.gic_ticker
                  ? holding.gic_ticker
                  : holding.security_detail.ticker) || 'N/A'
              } / ${
                (holding.gic_ticker
                  ? holding.gic_name
                  : holding.security_detail.long_name) || 'N/A'
              }`
            : 'N/A',
          exchange: securityDetail
            ? securityDetail.exchange_symbol || 'N/A'
            : 'N/A',
          class: securityDetail
            ? securityDetail.global_asset_class || 'N/A'
            : 'N/A',
          currentWeight: securityDetail
            ? `${this.getOriginalWeight(securityDetail.ticker, holdings)}%`
            : 'N/A',
          modelWeight: holding ? `${holding.weight}%` : 'N/A',
          calculatedTrades:
            reformatRebalData && reformatRebalData[ticker]
              ? `${
                  reformatRebalData[ticker].rebalancing_buy > 0 ? 'Buy' : 'Sell'
                } ${Math.abs(reformatRebalData[ticker].rebalancing_buy)} shares`
              : 'N/A',
          lastClose: this.formatNum(security_price, '$'),
          currentQuantity: pairTickerQuantity
            ? pairTickerQuantity[ticker]
            : 'N/A',
        };

        return template;
      });
    }

    return [];
  };

  rebalanceAccount = () => {
    const { isDeposit, cashAmount } = this.state;
    const { dispatch, rebalanceAcc } = this.props;
    const holdings = Object.values(rebalanceAcc.holdings).filter(
      (val) => !!val.security_detail
    );
    const { tickers, quantities } = convertToTickersAndWeights(holdings);
    const weights = tickers.split(',').reduce((acc, ticker) => {
      const w = rebalanceAcc.holdings[ticker].weight / 100;

      acc += acc.length ? `,${w}` : `${w}`;

      return acc;
    }, '');

    const body = {
      tickers,
      quantities,
      target_weights: weights,
      cash_currency: 'CAD',
      cash_amount: isDeposit ? Math.abs(cashAmount) : 0 - Math.abs(cashAmount),
    };

    dispatch(RebalanceAccount.rebalance(body));
  };

  handleChange = (e) => this.setState({ cashAmount: e.target.value });

  formatNum = (num, sign) => {
    if (!num && num !== 0) {
      return 'N/A';
    }

    const formatNumToStr = (n) =>
      Number((Math.round(n * 100) / 100).toFixed(2)).toLocaleString();

    return sign === '$'
      ? num < 0
        ? `-$${formatNumToStr(Math.abs(num))}`
        : `$${formatNumToStr(num)}`
      : `${formatNumToStr(num)}%`;
  };

  renderMessage() {
    const { isDeposit, useCash, cashAmount } = this.state;
    const { rebalanceAcc } = this.props;

    return (
      <div>
        If you have cash to deploy to or withdraw from the account, we can
        include it in the calculation of the rebalancing trades.
        <Popup
          trigger={<Icon name="question circle outline" />}
          position="bottom center"
          content="Using closing prices."
        />
        <CollapseWrapper open={useCash}>
          <Header
            size="medium"
            content="Deploy / Withdraw Cash?"
            style={{
              position: 'absolute',
              top: useCash ? '-10px' : '5px',
              left: '10px',
              background: 'white',
              padding: '0 5px',
              transition: 'all 300ms ease',
              textAlign: 'left',
              marginBottom: '16px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledButton
                icon
                onClick={() => this.setState({ useCash: !useCash })}
                isOpen={!!useCash}
              >
                {useCash ? '-' : '+'}
              </StyledButton>
              Deploy / Withdraw Cash?
            </div>
            <Header.Subheader style={{ paddingLeft: '2.8rem' }}>
              Calculate the trades required if you want to add money to or take
              money out from this account.
            </Header.Subheader>
          </Header>

          <div>
            <Segment
              basic
              compact
              style={{ textAlign: 'left', position: 'relative' }}
            >
              How much?
              <Input
                name="cashAmount"
                type="number"
                placeholder="$"
                value={cashAmount || ''}
                onChange={this.handleChange}
              />
              <OptionsWrapper style={{ top: 17 }}>
                <div
                  className={cn('select', { active: isDeposit })}
                  onClick={() => this.setState({ isDeposit: true })}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Deploy
                </div>
                <div
                  className={cn('select', { active: !isDeposit })}
                  onClick={() => this.setState({ isDeposit: false })}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  Withdraw
                </div>
              </OptionsWrapper>
            </Segment>
          </div>
        </CollapseWrapper>
        <Button
          className={cn('left centered', { loading: rebalanceAcc.isFetching })}
          color={colors.red}
          icon="chart bar"
          onClick={this.rebalanceAccount}
        >
          Show trades
        </Button>
      </div>
    );
  }

  renderTable() {
    const { rebalanceAcc, holdings } = this.props;

    return (
      <Table textAlign="center" style={{ borderColor: colors.red }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
              Asset
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
              Buy/Sell
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
              Quantity
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rebalanceAcc.rebalanceData.map((holding) => (
            <StyledRow key={holding.ticker}>
              <Table.Cell>
                {this.getTicker(holding.ticker) || 'N/A'}
                <SecurityName>
                  {holdings.find((h) => h.security === holding.ticker).name}
                </SecurityName>
              </Table.Cell>
              <Table.Cell>
                {holding.rebalancing_buy > 0 ? 'Buy' : 'Sell'}
              </Table.Cell>
              <Table.Cell>
                {Math.abs(holding.rebalancing_buy)} shares
              </Table.Cell>
            </StyledRow>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { account, rebalanceAcc, open, closeModal } = this.props;

    return !open ? null : (
      <Modal rebalance open={open} dimmerClick={closeModal}>
        <Modal.Header>
          {!rebalanceAcc.rebalanceData ? (
            <CloseIcon onClick={() => closeModal()}>+</CloseIcon>
          ) : null}
          <Header
            content="Trades required to rebalance to model weights"
            subheader={
              rebalanceAcc.rebalanceData
                ? 'These are trades calculated using the last market closing prices based on the parameters you set. In no way should they be viewed as recommendations or instructions for trading.'
                : ''
            }
          />
        </Modal.Header>
        <Modal.Content style={{ color: '#000' }}>
          {!rebalanceAcc.rebalanceData
            ? this.renderMessage()
            : this.renderTable()}
        </Modal.Content>
        {rebalanceAcc.rebalanceData ? (
          <ExcelFile
            element={
              <Button
                className={cn('left grey-focus2')}
                icon="download"
                color={colors.red}
                style={{ marginRight: '1rem' }}
              >
                Download CSV
              </Button>
            }
            filename={
              account
                ? `Rebalanced ${account.user_institution} ${account.label}`
                : 'Rebalanced'
            }
          >
            <ExcelSheet
              data={this.generateDataSet(rebalanceAcc)}
              name="Rebalance"
            >
              <ExcelColumn label="Ticker Symbol" value="ticker" />
              <ExcelColumn label="Exchange" value="exchange" />
              <ExcelColumn label="Class" value="class" />
              <ExcelColumn label="Last Close" value="lastClose" />
              <ExcelColumn label="Current Quantity" value="currentQuantity" />
              <ExcelColumn label="Current Weight" value="currentWeight" />
              <ExcelColumn label="Model Weight" value="modelWeight" />
              <ExcelColumn label="Calculated Trades" value="calculatedTrades" />
            </ExcelSheet>
          </ExcelFile>
        ) : null}
        {rebalanceAcc.rebalanceData ? (
          <Button
            className={cn('left grey-focus2')}
            icon="close"
            color={colors.red}
            onClick={() => this.props.closeModal()}
            style={{ marginBottom: '1rem' }}
          >
            Close
          </Button>
        ) : null}
      </Modal>
    );
  }
}

RebalanceModal.propTypes = {
  dispatch: PropTypes.func,
  holdings: PropTypes.array.isRequired,
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  rebalanceAcc: PropTypes.object,
  account: PropTypes.object,
};

RebalanceModal.defaultProps = {
  dispatch: () => false,
  rebalanceAcc: {},
  open: null,
  account: {},
};

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative !important;

  .close {
    display: block;
    margin: -10px;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 1rem 0.2rem;
  padding-top: ${(props) => (props.open ? '2rem' : '4rem')};
  margin: 3rem 0 0 0;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '1700px' : '57px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 11px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.red};
      color: white;
    }
  }
`;

const Input = styled.input`
  margin-left: 1rem;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;
  z-index: 1001;
  color: rgb(0, 0, 0);

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

const StyledButton = styled(SemanticButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem !important;
  width: 2rem !important;
  min-width: 2rem !important; // Ensure the button has a fixed width
  padding: 0 !important; // Remove any default padding
  font-size: 1rem; // Adjust the font size if needed
  line-height: 1;
`;
