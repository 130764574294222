import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Header, Card, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { colors } from 'utils/colors';
import { Segment } from 'components';
import { exchange } from 'utils/constants';

import Breakdown from '../Overview/Breakdown';
import History from '../Overview/History';
import Credit from '../Overview/Credit';
import Holdings from '../Overview/Holdings';

@withRouter
class Result extends Component {
  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount = () => {
    window.scrollTo(0, 0);

    // resize charts on print
    this.state.mql.addListener(this.printListener);

    return false;
  };

  componentDidMount = () => {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  };

  componentWillUnmount = () => {
    window.clearTimeout(this.timeoutId);
    this.state.mql.removeListener(this.printListener);
  };

  printListener = (e) => {
    const { history } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else {
      history.goBack();
    }
  };

  hasValidData = (arr) => !!arr.filter((item) => item).length;

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  render() {
    const { security, user_region } = this.props;
    const data = security.security;

    return (
      <PDF>
        <Container centered>
          <Header size="large">ETF / Mutual Fund Lookup</Header>
          <Segment basic compact textAlign="left">
            <Container>
              <Card fluid style={{ width: '90%', margin: '0 auto' }}>
                <Card.Content>
                  <Card.Header style={{ textAlign: 'center' }}>
                    {data.long_name}
                  </Card.Header>
                  <Card.Description>
                    <Grid>
                      <Grid.Row divided columns={2}>
                        <Grid.Column>
                          <strong>Ticker: </strong>
                          <Value>{data.ticker}</Value>
                          <br />
                          <strong>Exchange: </strong>
                          <Value>{exchange[data.exchange_symbol]}</Value>
                          <br />
                          <strong>Net Assets: </strong>
                          <Value>
                            {data.fund_net_assets
                              ? `$${Math.round(
                                  data.fund_net_assets
                                ).toLocaleString()}`
                              : 'N/A'}
                          </Value>
                          <br />
                          <strong>Fund Benchmark: </strong>
                          <Value
                            className={
                              data.fund_benchmark &&
                              data.fund_benchmark.length > 25
                                ? 'long'
                                : ''
                            }
                          >
                            {data.fund_benchmark || 'N/A'}
                          </Value>
                          <br />
                          <div>
                            <strong>Management Expense Ratio (MER):</strong>

                            <Value>{data.mer ? `${data.mer}%` : '-'}</Value>
                            <br />
                          </div>
                          {data.avg_duration && (
                            <div>
                              <strong>Average duration: </strong>
                              <Value>
                                {this.parseNum(data.avg_duration)} years
                              </Value>
                            </div>
                          )}
                        </Grid.Column>
                        <Grid.Column>
                          <strong>Inception Date: </strong>
                          <Value>{data.effective_date}</Value>
                          <br />
                          <div>
                            <strong>Distribution Yield:</strong>

                            <Value>
                              {data._yield
                                ? `${this.parseNum(data._yield)}%`
                                : 'N/A'}
                            </Value>
                            <br />
                          </div>
                          <strong>Distribution Frequency: </strong>
                          <Value>{data.dividend_freq}</Value>
                          <br />
                          <strong>Total Number of Holdings: </strong>
                          <Value>{data.total_holdings || 'N/A'}</Value>
                          <br />
                          <strong>P/E Ratio: </strong>
                          <Value>
                            {data.price_to_earnings
                              ? this.parseNum(data.price_to_earnings)
                              : '-'}
                          </Value>
                          <br />
                          {data.avg_maturity && (
                            <div>
                              <strong>Average maturity: </strong>
                              <Value>
                                {this.parseNum(data.avg_maturity)} years
                              </Value>
                            </div>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Container>
          </Segment>
          <StyledHeader size="large">Historical Performance</StyledHeader>
          <History data={data} user_region={user_region} />
          {this.hasValidData([
            ...Object.values(data.regional_exposure),
            ...Object.values(data.asset_allocation),
            ...Object.values(data.sector_allocation),
          ]) && (
            <div>
              <StyledHeader size="large">Fund Breakdown</StyledHeader>
              <Breakdown data={data} />
              <div style={{ pageBreakAfter: 'always' }} />
            </div>
          )}

          {this.hasValidData(Object.values(data.credit_quality)) && (
            <div>
              <StyledHeader size="large">Credit Quality</StyledHeader>
              <Credit data={data} />
            </div>
          )}

          {this.hasValidData(Object.values(data.top_holdings)) && (
            <div>
              <StyledHeader size="large">Top Holdings</StyledHeader>
              <Holdings data={data} />
            </div>
          )}
        </Container>
      </PDF>
    );
  }
}

Result.propTypes = {
  history: PropTypes.object,
  security: PropTypes.object,
  user_region: PropTypes.string,
};

Result.defaultProps = {
  history: {},
  security: {},
  user_region: 'CA',
};

export default connect((state) => ({
  security: state.Security,
  user_region: state.Storage.user_region,
}))(Result);

const PDF = styled.div`
  position: relative;
  background: white;
  text-align: center;
  padding: 2.5rem;
  border-radius: 8px;
  width: 1160px;
  margin: -55px auto 5px auto;

  @media only print {
    print-color-adjust: exact;

    @page {
      size: auto;
      margin: 0.5cm;
    }
  }
`;

const Container = styled.div`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const StyledHeader = styled(({ className, children, ...rest }) => (
  <Header className={className} {...rest}>
    {children}
  </Header>
))`
  text-align: left;
  margin-top: 40px !important;
`;

const Value = styled.span`
  position: absolute;
  right: 1rem;
  text-overflow: ellipsis;
  text-align: right;
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  transition: background 0.1s ease;

  &.long:hover {
    background: ${colors.orange};
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    width: auto;
    max-width: unset;
    white-space: normal;
    overflow: unset;
  }
`;
