import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import cn from 'classnames';

import { Planning } from 'actions';
import { notWealthica } from 'utils/helpers';
import { colors } from 'utils/colors';
import { Modal, Button } from 'components';
import { saveModalCharLimits } from 'utils/constants';

@withRouter
class SaveModal extends Component {
  state = {
    modalIsOpen: false,
    loading: false,
    name: this.props.name || '',
    description: this.props.description || '',
    errors: {},
  };

  getDate = () => {
    const d = new Date();
    const year = d.getFullYear();
    const month =
      d.getMonth() + 1 < 10
        ? `0${(d.getMonth() + 1).toString()}`
        : d.getMonth() + 1;
    const day = d.getDate();

    return `${year}-${month}-${day}`;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' || name === 'description') {
      if (value.length > saveModalCharLimits[name])
        this.setState({
          errors: {
            ...this.state.errors,
            [name]: `Maximum ${saveModalCharLimits[name]} characters`,
          },
        });
      else {
        this.setState({
          errors: {
            ...this.state.errors,
            [name]: null,
          },
        });
      }
    }

    return this.setState({ [name]: value });
  };

  saveButtonDisabled = () =>
    !this.state.name ||
    !this.state.name.trim() ||
    this.state.errors.name ||
    this.state.errors.description;

  handleSave = () => {
    if (this.saveButtonDisabled()) return false;
    const { data, dispatch, history, match } = this.props;
    const {
      retirementBlueprint,
      personalDetails,
      targetWealth,
      targetSaving,
      savingResult,
      wealthResult,
    } = data;
    const { name, description } = this.state;
    const params = {
      // general
      age: personalDetails.age,
      cpp: personalDetails.cpp,
      cppAge: personalDetails.cppAge,
      gender: personalDetails.gender,
      oas: personalDetails.oas,
      oasAge: personalDetails.oasAge,
      other: personalDetails.other || 0,
      retAge: personalDetails.retAge,
      net_spending: personalDetails.net_spending,
      province: personalDetails.province,
      pension: personalDetails.pension || 0,
      spending_amount: personalDetails.spending_amount,
      spending_tool_data: personalDetails.spendingToolData,
      selected_spending_tool: personalDetails.selectedSpendingTool,

      years_contrib: personalDetails.years_contrib,
      primary_caregiver: personalDetails.primary_caregiver,
      years_primary_caregiver: personalDetails.years_primary_caregiver,
      is_earning_over_average:
        personalDetails.is_earning_over_average === null
          ? true
          : personalDetails.is_earning_over_average,
      perc_under_average: personalDetails.perc_under_average,
      ...(personalDetails.resident_years && {
        resident_years: personalDetails.resident_years,
      }),
      ...(!!personalDetails.declinePattern && {
        declinePattern: {
          percent: personalDetails.declinePattern.percent,
          duration: personalDetails.declinePattern.duration,
        },
      }),
      ...(!personalDetails.declinePattern && { declinePattern: {} }),
      // wealth
      annuityRate: targetWealth.annuityRate,
      horz: targetWealth.horz,
      targ: targetWealth.targ,
      wealth_portfolio: targetWealth.wealth_portfolio,
      modelType: targetWealth.modelType,
      targetWealth50: wealthResult['0.5'],
      targetWealth75: wealthResult['0.75'],
      targetWealth90: wealthResult['0.9'],
      targetWealth95: wealthResult['0.95'],
      // saving
      init: targetSaving.init,
      annual_income: targetSaving.annual_income,
      annual_income2: targetSaving.annual_income2,
      rrsp_room: targetSaving.rrsp_room,
      rrsp_room2: targetSaving.rrsp_room2,
      tfsa_room: targetSaving.tfsa_room,
      tfsa_room2: targetSaving.tfsa_room2,
      expected_taxes50: savingResult.tax_implications[0.5].expected_taxes,
      expected_taxes50b: savingResult.tax_implications[0.5].expected_taxes2,
      expected_taxes75: savingResult.tax_implications[0.75].expected_taxes,
      expected_taxes75b: savingResult.tax_implications[0.75].expected_taxes2,
      expected_taxes90: savingResult.tax_implications[0.9].expected_taxes,
      expected_taxes90b: savingResult.tax_implications[0.9].expected_taxes2,
      prob_without_savings: savingResult.prob_without_savings,
      monthlySavingChosen: targetSaving.monthlySavingChosen,
      monthlySaving50: savingResult['0.5'],
      monthlySaving75: savingResult['0.75'],
      monthlySaving90: savingResult['0.9'],
      savingPaths: savingResult.paths,
      savings_portfolio: targetSaving.savings_portfolio,
      saving_portfolio_symbols: targetSaving.saving_portfolio_symbols,
      saving_portfolio_weights: targetSaving.saving_portfolio_weights,
      saving_portfolio_region: targetSaving.saving_portfolio_region,
      ...(targetSaving.initAccList &&
        targetSaving.initAccList.length && {
          selectedAccounts: targetSaving.initAccList.join(','),
        }),
      // spouse
      is_couple_plan: retirementBlueprint.is_couple_plan,
      age2: personalDetails.age2,
      cppAge2: personalDetails.cppAge2,
      cpp2: personalDetails.cpp2,
      pension2: personalDetails.pension2 || 0,
      oas2: personalDetails.oas2,
      oasAge2: personalDetails.oasAge2,
      gender2: personalDetails.gender2,
      other2: personalDetails.other2 || 0,
      retAge2: personalDetails.retAge2,

      ...(personalDetails.resident_years2 && {
        resident_years2: personalDetails.resident_years2,
      }),
      ...(retirementBlueprint.is_couple_plan === 'true' &&
        !!personalDetails.declinePattern && {
          declinePattern2: {
            percent: personalDetails.declinePattern.percent,
            duration: personalDetails.declinePattern.duration,
          },
        }),
      ...(!personalDetails.declinePattern && { declinePattern2: {} }),

      years_contrib2: personalDetails.years_contrib2,
      primary_caregiver2: personalDetails.primary_caregiver2,
      years_primary_caregiver2: personalDetails.years_primary_caregiver2,
      is_earning_over_average2:
        personalDetails.is_earning_over_average2 === null
          ? true
          : personalDetails.is_earning_over_average2,
      perc_under_average2: personalDetails.perc_under_average2,
    };

    this.setState({ loading: true });

    if (Number(match.params.id)) {
      dispatch(
        Planning.update(match.params.id, {
          name,
          description,
          updated_on: this.getDate(),
          ...params,
        })
      );
    } else {
      dispatch(
        Planning.save(
          { name, description, updated_on: this.getDate(), ...params },
          history
        )
      );
    }

    return this.setState({ modalIsOpen: false, loading: false });
  };

  handleNewSave = () => {
    if (this.saveButtonDisabled()) return false;
    const { data, dispatch, history } = this.props;
    const {
      retirementBlueprint,
      personalDetails,
      targetWealth,
      targetSaving,
      savingResult,
      wealthResult,
    } = data;
    const { name, description } = this.state;
    const params = {
      // general
      age: personalDetails.age,
      cpp: personalDetails.cpp,
      cppAge: personalDetails.cppAge,
      gender: personalDetails.gender,
      pension: personalDetails.pension || 0,
      oas: personalDetails.oas,
      oasAge: personalDetails.oasAge,
      other: personalDetails.other || 0,
      retAge: personalDetails.retAge,
      net_spending: personalDetails.net_spending,
      province: personalDetails.province,
      spending_amount: personalDetails.spending_amount,
      spending_tool_data: personalDetails.spendingToolData,
      selected_spending_tool: personalDetails.selectedSpendingTool,

      years_contrib: personalDetails.years_contrib,
      primary_caregiver: personalDetails.primary_caregiver,
      years_primary_caregiver: personalDetails.years_primary_caregiver,
      is_earning_over_average:
        personalDetails.is_earning_over_average === null
          ? true
          : personalDetails.is_earning_over_average,
      perc_under_average: personalDetails.perc_under_average,
      ...(personalDetails.resident_years && {
        resident_years: personalDetails.resident_years,
      }),
      ...(!!personalDetails.declinePattern && {
        declinePattern: {
          percent: personalDetails.declinePattern.percent,
          duration: personalDetails.declinePattern.duration,
        },
      }),
      ...(!personalDetails.declinePattern && { declinePattern: {} }),

      // wealth
      annuityRate: targetWealth.annuityRate,
      horz: targetWealth.horz,
      targ: targetWealth.targ,
      wealth_portfolio: targetWealth.wealth_portfolio,
      modelType: targetWealth.modelType,
      targetWealth50: wealthResult['0.5'],
      targetWealth75: wealthResult['0.75'],
      targetWealth90: wealthResult['0.9'],
      targetWealth95: wealthResult['0.95'],
      // saving
      init: targetSaving.init,
      annual_income: targetSaving.annual_income,
      annual_income2: targetSaving.annual_income2,
      rrsp_room: targetSaving.rrsp_room,
      rrsp_room2: targetSaving.rrsp_room2,
      tfsa_room: targetSaving.tfsa_room,
      tfsa_room2: targetSaving.tfsa_room2,
      expected_taxes50: savingResult.tax_implications[0.5].expected_taxes,
      expected_taxes50b: savingResult.tax_implications[0.5].expected_taxes2,
      expected_taxes75: savingResult.tax_implications[0.75].expected_taxes,
      expected_taxes75b: savingResult.tax_implications[0.75].expected_taxes2,
      expected_taxes90: savingResult.tax_implications[0.9].expected_taxes,
      expected_taxes90b: savingResult.tax_implications[0.9].expected_taxes2,
      prob_without_savings: savingResult.prob_without_savings,
      monthlySavingChosen: targetSaving.monthlySavingChosen || 0,
      monthlySaving50: savingResult['0.5'],
      monthlySaving75: savingResult['0.75'],
      monthlySaving90: savingResult['0.9'],
      savingPaths: savingResult.paths,
      savings_portfolio: targetSaving.savings_portfolio,
      saving_portfolio_symbols: targetSaving.saving_portfolio_symbols,
      saving_portfolio_weights: targetSaving.saving_portfolio_weights,
      saving_portfolio_region: targetSaving.saving_portfolio_region,
      ...(targetSaving.initAccList &&
        targetSaving.initAccList.length && {
          selectedAccounts: targetSaving.initAccList.join(','),
        }),

      // spouse
      is_couple_plan: retirementBlueprint.is_couple_plan,
      age2: personalDetails.age2,
      cppAge2: personalDetails.cppAge2,
      cpp2: personalDetails.cpp2,
      pension2: personalDetails.pension2 || 0,
      oas2: personalDetails.oas2,
      oasAge2: personalDetails.oasAge2,
      gender2: personalDetails.gender2,
      other2: personalDetails.other2 || 0,
      retAge2: personalDetails.retAge2,
      ...(personalDetails.resident_years2 && {
        resident_years2: personalDetails.resident_years2,
      }),
      ...(!!retirementBlueprint.is_couple_plan &&
        !!personalDetails.declinePattern && {
          declinePattern2: {
            percent: personalDetails.declinePattern.percent,
            duration: personalDetails.declinePattern.duration,
          },
        }),
      ...(!personalDetails.declinePattern && { declinePattern2: {} }),

      years_contrib2: personalDetails.years_contrib2,
      primary_caregiver2: personalDetails.primary_caregiver2,
      years_primary_caregiver2: personalDetails.years_primary_caregiver2,
      is_earning_over_average2:
        personalDetails.is_earning_over_average2 === null
          ? true
          : personalDetails.is_earning_over_average2,
      perc_under_average2: personalDetails.perc_under_average2,
    };

    this.setState({ loading: true });
    dispatch(
      Planning.save(
        { name, description, updated_on: this.getDate(), ...params },
        history
      )
    );

    return this.setState({ modalIsOpen: false, loading: false });
  };

  handleClick = () => this.setState({ modalIsOpen: true });

  handleCancel = () => {
    const { name, description } = this.state;

    return this.setState({
      modalIsOpen: false,
      name: name || '',
      description: description || '',
    });
  };

  generatePDF = () => {
    const { history, match } = this.props;

    return history.push(`/planning/blueprint/${match.params.id}/pdf`);
  };

  render() {
    const { match, isDisabled, partnerName, blueprints } = this.props;
    const { modalIsOpen, name, description, loading } = this.state;

    return (
      <div>
        <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
          <Button
            className={cn('left', { disabled: isDisabled })}
            color={colors.teal}
            icon="save"
            onClick={this.handleClick}
            disabled={isDisabled}
          >
            Save
          </Button>

          <Button
            className="left"
            icon="file text"
            color={colors.teal}
            onClick={this.generatePDF}
            style={{ marginLeft: '1rem' }}
          >
            Print/PDF
          </Button>
        </div>

        <Modal
          basic
          open={modalIsOpen}
          vb={notWealthica(partnerName)}
          bottom={notWealthica(partnerName) ? '125' : '200'}
        >
          <Modal.Header>
            <Header icon="briefcase" content="Save Blueprint" color="green" />
          </Modal.Header>
          <Modal.Content>
            <Field vb={notWealthica(partnerName)}>
              Name:
              <input
                placeholder="(required)"
                name="name"
                value={name}
                onChange={this.handleChange}
                vb={notWealthica(partnerName)}
              />
            </Field>
            <Field vb={notWealthica(partnerName)}>
              Description:
              <input
                placeholder="(optional)"
                name="description"
                value={description}
                onChange={this.handleChange}
                vb={notWealthica(partnerName)}
              />
            </Field>
            {blueprints.find(
              (blueprint) =>
                blueprint.name === this.state.name &&
                blueprint.description === this.state.description
            ) ? (
              <Field>
                <Icon
                  name="exclamation triangle"
                  style={{ color: 'white', fontSize: '15px' }}
                />
                You have a blueprint with the same name and description.
              </Field>
            ) : null}
          </Modal.Content>
          <Modal.Actions>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              icon="cancel"
              color={colors.red}
              margin="0 0.5rem 0 0"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                { loading, disabled: !name || !name.trim() }
              )}
              icon="checkmark"
              margin="0 0.5rem 0 0"
              color={colors.green}
              onClick={this.handleSave}
            >
              Save
            </Button>
            {Number(match.params.id) ? (
              <Button
                className={cn(
                  `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
                  { loading, disabled: this.saveButtonDisabled() }
                )}
                icon="save"
                color={colors.blue}
                onClick={this.handleNewSave}
              >
                Save as a New Blueprint
              </Button>
            ) : null}
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

SaveModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  description: PropTypes.string,
  name: PropTypes.string,
  partnerName: PropTypes.string,
  blueprints: PropTypes.array.isRequired,
};

SaveModal.defaultProps = {
  isDisabled: false,
  match: {},
  history: {},
  description: '',
  name: '',
  partnerName: '',
};

export default connect((state) => ({
  blueprint: state.Planning.blueprint,
  partnerName: state.Storage['partner-name'],
  blueprints: state.Planning.savedBlueprints,
}))(SaveModal);

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};

    &:focus {
      outline: 0;
    }
  }
`;
