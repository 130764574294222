// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Feature extends Component {
  hasAccess() {
    const { name, featureRestrictions, partnerName, user_region } = this.props;
    const updateFeatureRestrictions =
      (partnerName === 'quotemedia_sandbox' ||
        partnerName === 'quotemedia_retail' ||
        partnerName === 'quotemedia_pro') &&
      user_region === 'US'
        ? [...featureRestrictions, 'PORTFOLIOS_VIEW_MODEL']
        : featureRestrictions;

    return updateFeatureRestrictions
      ? !updateFeatureRestrictions.find((restriction) => restriction === name)
      : true;
  }

  render() {
    if (!this.hasAccess()) {
      return null;
    }

    return this.props.children;
  }
}

Feature.propTypes = {
  name: PropTypes.string.isRequired,
  featureRestrictions: PropTypes.array,
  children: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

Feature.defaultProps = {
  featureRestrictions: [],
  user_region: 'CA',
  partnerName: '',
};

export default connect((state) => ({
  featureRestrictions: state.Storage['feature-restrictions'],
  partnerName: state.Storage['partner-name'],
  user_region: state.Storage.user_region || 'CA',
}))(Feature);
