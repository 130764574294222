import { BuildML } from 'actions';

const initialState = {
  isFetching: false,
  search: [],
  portfolio: {},
  assetAllocation: {},
  prevSavedAssetAllocation: {},
  allocationToggle: false,
  fixedIncome: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BuildML.BUILDML_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case BuildML.BUILDML_ADD_SUCCESS:
      return !action.security
        ? { ...state, isFetching: false }
        : {
            ...state,
            isFetching: false,
            portfolio: {
              ...state.portfolio,
              [action.security.ticker]: {
                ...action.security,
              },
            },
          };
    case BuildML.BUILDML_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        search: action.data,
      };
    case BuildML.BUILDML_REMOVE_SECURITY:
      return {
        ...state,
        isFetching: false,
        portfolio: Object.values(state.portfolio).reduce(
          (newPortfolio, item) => {
            if (item.ticker !== action.ticker) {
              newPortfolio[item.ticker] = item;
            }

            return newPortfolio;
          },
          {}
        ),
        gic_info: action.ticker === 'GIC1:MKT' ? {} : state.gic_info,
      };
    case BuildML.BUILDML_REMOVE_ALL:
      return {
        ...state,
        isFetching: false,
        portfolio: {},
      };
    case BuildML.BUILDML_UPDATE_TOGGLE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case BuildML.BUILDML_UPDATE_FIXEDINCOME:
      return {
        ...state,
        fixedIncome: action.value,
      };
    case BuildML.BUILDML_BUILDML_FETCH_ASSETALLOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        assetAllocation: action.data,
        prevSavedAssetAllocation: action.data,
      };
    case BuildML.BUILDML_CLEAR_PREV_ASSET_ALLOCATION:
      return {
        ...state,
        prevSavedAssetAllocation: {},
      };
    case BuildML.BUILDML_CLEAR:
      return initialState;
    default:
      return state;
  }
}
