import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Icon, Form, Input, Header, Dropdown } from 'semantic-ui-react';
import cn from 'classnames';

import { CacheInputs, Planning, Portfolio } from 'actions';
import { colors } from 'utils/colors';
import { Message, ErrorLabel, Button, Segment } from 'components';
import Result from './Result';

@connect((state) => ({
  isFetching: state.Planning.isFetching,
  longevity: state.Planning.longevity,
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  portLongevityInputs: state.CacheInputs.planning.portfolio_longevity,
}))
export default class Longevity extends Component {
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    return false;
  };

  getPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    const date = !port ? [0] : port.init_date.split('-');

    if (Number(date[0]) < 2008) {
      return false;
    }

    return `${date[1]}/${date[0]}`;
  };

  getNewPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    if (!port || !port.init_date) {
      return false;
    }
    const date = new Date(port.init_date);
    const today = new Date();
    const monthDifference =
      today.getMonth() -
      date.getMonth() +
      12 * (today.getFullYear() - date.getFullYear());
    if (monthDifference > 60) {
      return false;
    }

    const init = date.toDateString().toString().split(/\s+/);

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sept: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    return `${months[init[1]]} ${init[3]}`;
  };

  handleKeyPress = (e) => e.key === '.' && e.preventDefault();

  handlePortfolioChange = (e, { value }) =>
    this.props.dispatch(
      CacheInputs.updateField('planning', 'portfolio_longevity', {
        portfolio: value,
        errors: { portfolio: null },
      })
    );

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    if (name === 'gender') {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          gender: value,
          errors: { gender: null },
        })
      );
    }

    if (
      (name === 'retAge' || name === 'annuityRate' || name === 'lifeExp') &&
      Number(value) > 100
    ) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: 100,
          errors: { [name]: null },
        })
      );
    }
    if (
      (name === 'lifeExp' || name === 'cf' || name === 'retAge') &&
      Number(value) < 0
    ) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: 1,
          errors: { [name]: null },
        })
      );
    }

    return (
      value.length < 10 &&
      dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          [name]: value,
          errors: { [name]: null },
        })
      )
    );
  };

  scrollHandler = (element, to, duration) => {
    if (duration <= 0) return false;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      element.scrollTop += perTick;
      if (element.scrollTop === to) return false;
      return this.scrollHandler(element, to, duration - 10);
    }, 10);
  };

  hasEmptyValue = (values) =>
    values.some((field) => {
      const { dispatch, portLongevityInputs } = this.props;

      if (!portLongevityInputs[field]) {
        return dispatch(
          CacheInputs.updateField('planning', 'portfolio_longevity', {
            errors: { [field]: 'This field is required' },
          })
        );
      }

      return false;
    });

  handleSubmit = () => {
    const { dispatch, portfolios, portLongevityInputs } = this.props;
    const {
      useDefault,
      lifeExp,
      portfolio,
      annuityRate,
      retAge,
      init,
      gender,
      cf,
      riskFree,
    } = portLongevityInputs;
    const params = {
      retAge,
      init,
      gender,
      cf,
      init_date: portfolio
        ? portfolios.find((p) => p.id === portfolio).init_date
        : null,
      region: 'CA',
    };

    if (this.hasEmptyValue(Object.keys({ retAge, init, gender, cf }))) {
      return false;
    }

    if (!useDefault && !lifeExp) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { lifeExp: 'This field is required' },
        })
      );
    }

    if (riskFree && !annuityRate) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { annuityRate: 'This field is required' },
        })
      );
    }

    if (!riskFree && !portfolio) {
      return dispatch(
        CacheInputs.updateField('planning', 'portfolio_longevity', {
          errors: { portfolio: 'This field is required' },
        })
      );
    }

    if (annuityRate) {
      params.annuityRate = annuityRate / 100;
    }

    if (lifeExp) {
      params.lifeExp = lifeExp;
    }

    if (portfolio) {
      const idx = portfolios.reduce((result, item, index) => {
        if (item.id === portfolio && !result) {
          result = index;
        }

        return result;
      }, false);

      params.symbols = portfolios[idx].symbols;
      params.weights = portfolios[idx].weights;
      params.gic_info = portfolios[idx].gic_info;
    }

    return dispatch(Planning.analyzeLongevity(params)).then((action) => {
      const element = document.documentElement;
      const target = document.getElementById('longevity-ref').offsetHeight;

      return (
        action && !!action.result && this.scrollHandler(element, target, 300)
      );
    });
  };

  render() {
    const { dispatch, isFetching, longevity, portfolios, portLongevityInputs } =
      this.props;
    const {
      lifeExp,
      useDefault,
      retAge,
      init,
      cf,
      gender,
      portfolio,
      riskFree,
      annuityRate,
      errors,
    } = portLongevityInputs;
    const portDate = this.getNewPortDate(
      portfolios.find((port) => Number(port.id) === portfolio)
    );

    return (
      <Segment>
        <Header
          size="large"
          content="Portfolio Longevity"
          textAlign="center"
          subheader={
            <div className="sub header">
              You are retired. How long will your savings last? <br />
              Find out below if your savings are expected to outlive you!
            </div>
          }
        />

        <Form
          onSubmit={this.handleSubmit}
          style={{ width: '37rem', margin: '2rem auto 0 auto' }}
        >
          <Form.Group widths="equal">
            <Form.Field style={{ position: 'relative' }}>
              <label>Age:</label>
              <Input
                name="retAge"
                type="number"
                fluid
                placeholder="years"
                value={retAge || ''}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              <ErrorLabel className="top" msg={errors.retAge} />
            </Form.Field>

            <Form.Field style={{ position: 'relative' }}>
              <label>
                Sex:&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                  content="We are using mortality rates from Statistics Canada in the calculations and the data is available for males and females only."
                />
              </label>
              <Dropdown
                name="gender"
                compact
                placeholder="Sex"
                selection
                options={[
                  { key: 'm', value: 'M', text: 'Male' },
                  { key: 'f', value: 'F', text: 'Female' },
                ]}
                value={gender}
                onChange={this.handleChange}
              />
              <ErrorLabel className="top" msg={errors.gender} />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field style={{ position: 'relative' }}>
              <label>
                Retirement Savings:
                <SubLabel>How much you currently have.</SubLabel>
              </label>
              <Input
                name="init"
                type="number"
                fluid
                value={init || ''}
                icon="dollar"
                iconPosition="left"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              <ErrorLabel className="top" msg={errors.init} />
            </Form.Field>

            <Form.Field style={{ position: 'relative' }}>
              <label>
                Withdrawal per year:
                <SubLabel>
                  How much you withdraw from your savings.
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ fontSize: '13px', verticalAlign: 'initial' }}
                      />
                    }
                    position="right center"
                    content="This amount is what you spend per year over and above any retirement income you may receive, i.e., spending that has to be financed by your savings. We assume that this amount grows with inflation at 2% over time."
                  />
                </SubLabel>
              </label>
              <Input
                name="cf"
                type="number"
                fluid
                value={cf || ''}
                icon="dollar"
                iconPosition="left"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
              <ErrorLabel className="top" msg={errors.cf} />
            </Form.Field>
          </Form.Group>

          <Header
            size="tiny"
            content="Life expectancy"
            subheader="Use Statistics Canada estimate based on my age and sex"
            textAlign="center"
            style={{ margin: '1rem 0 0.5rem 0', fontSize: '0.92857143em' }}
          />

          <div style={{ textAlign: 'center', margin: '0 0 1.5rem 0' }}>
            <StyledSpan
              active={!!useDefault}
              onClick={() =>
                dispatch(
                  CacheInputs.updateField('planning', 'portfolio_longevity', {
                    useDefault: true,
                  })
                )
              }
            >
              Yes
            </StyledSpan>
            <StyledSpan
              active={!useDefault}
              onClick={() =>
                dispatch(
                  CacheInputs.updateField('planning', 'portfolio_longevity', {
                    useDefault: false,
                  })
                )
              }
            >
              No
            </StyledSpan>
          </div>

          {!useDefault && (
            <Form.Field
              style={{ position: 'relative', width: '17rem', margin: '0 auto' }}
            >
              <label style={{ textAlign: 'center' }}>
                I expect to live for another
              </label>
              <Input
                size="mini"
                type="number"
                name="lifeExp"
                label={{ basic: true, content: 'years' }}
                labelPosition="right"
                value={lifeExp || ''}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                style={{ maxWidth: '5rem', margin: '0 0 0 4.3rem' }}
              />
              <ErrorLabel msg={errors.lifeExp} />
            </Form.Field>
          )}

          <Header
            size="tiny"
            content="Are you putting money in risk-free investment?"
            textAlign="center"
            style={{ margin: '2rem 0 0.5rem 0', fontSize: '0.92857143em' }}
          />

          <div style={{ textAlign: 'center', margin: '0 0 1.5rem 0' }}>
            <StyledSpan
              active={!!riskFree}
              onClick={() =>
                dispatch(
                  CacheInputs.updateField('planning', 'portfolio_longevity', {
                    riskFree: true,
                    portfolio: '',
                  })
                )
              }
            >
              Yes
            </StyledSpan>
            <StyledSpan
              active={!riskFree}
              onClick={() =>
                dispatch(
                  CacheInputs.updateField('planning', 'portfolio_longevity', {
                    riskFree: false,
                    annuityRate: '',
                  })
                )
              }
            >
              No
            </StyledSpan>
          </div>

          {!riskFree && (
            <Form.Field
              style={{
                position: 'relative',
                width: '100%',
                margin: '0 auto',
                maxWidth: '17rem',
              }}
            >
              <label>
                Choose the portfolio for your savings:
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ fontSize: '13px', verticalAlign: 'initial' }}
                    />
                  }
                  position="right center"
                  wide
                  content="You can choose a WS model portfolio or any of your saved portfolios here. See the “Portfolios” segment."
                />
              </label>
              <Dropdown
                name="portfolio"
                compact
                placeholder="Port"
                selection
                options={portfolios.reduce((total, item) => {
                  total.push({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                  });

                  return total;
                }, [])}
                value={portfolio}
                onChange={this.handlePortfolioChange}
              />
              <ErrorLabel msg={errors.portfolio} />
            </Form.Field>
          )}

          <Message
            icon
            visible={!!portDate}
            warning
            style={{
              margin: '1rem auto 0 auto',
              maxWidth: '45rem',
              textAlign: 'left',
            }}
          >
            <Icon name="exclamation" />
            <Message.Content>
              This portfolio has a start date of <strong>{portDate}</strong>.
              You may wish to use a portfolio with a longer history. You can use
              the ETF Portfolio Builder in the "Portfolios" segment. Once saved,
              the portfolio will appear in the dropdown list here.
            </Message.Content>
          </Message>

          {riskFree && (
            <Form.Field
              style={{ position: 'relative', width: '10rem', margin: '0 auto' }}
            >
              <label>Enter the annual return:</label>
              <Input
                type="number"
                step="0.01"
                name="annuityRate"
                icon="percent"
                value={annuityRate || ''}
                onChange={this.handleChange}
              />
              <ErrorLabel msg={errors.annuityRate} />
            </Form.Field>
          )}

          <Button
            className={cn('centered right', { loading: isFetching })}
            color={colors.orange}
            icon="calculator"
          >
            Calculate
          </Button>
        </Form>

        <Result isFetching={isFetching} result={longevity} />
      </Segment>
    );
  }
}

Longevity.propTypes = {
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  portfolios: PropTypes.array,
  longevity: PropTypes.object,
  portLongevityInputs: PropTypes.object,
};

Longevity.defaultProps = {
  dispatch: () => false,
  portfolios: [],
  longevity: null,
  isFetching: false,
  portLongevityInputs: {},
};

const StyledSpan = styled.span`
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: ${(props) => (props.active ? colors.orange : 'white')};
  border: ${(props) =>
    props.active ? '1px solid transparent' : '1px solid #dcdcdc'};
  user-select: none;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    border: 1px solid ${colors.orange};
  }
`;

const SubLabel = styled.div`
  color: rgba(0, 0, 0, 0.5);
  line-height: 0.8rem;
  font-style: italic;
  font-weight: 100;
`;
