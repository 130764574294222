import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { Accounts } from 'actions';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Button, Modal } from 'components';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))
export default class DeleteModal extends Component {
  state = {
    loading: false,
    error: null,
  };

  deleteAccount = () => {
    const { account, dispatch, history } = this.props;

    this.setState({ loading: true });

    return dispatch(Accounts.remove(account.id, history)).then((res) => {
      if (res && res.err) {
        return this.setState({
          error: 'Failed to delete account',
          loading: false,
        });
      }

      return false;
    });
  };

  closeModal = () => {
    const { close } = this.props;

    this.setState({ loading: false, error: null });

    return close();
  };

  render() {
    const { account, open, partnerName, french } = this.props;
    const { error, loading } = this.state;

    return (
      <Modal
        dimmerClick={this.closeModal}
        basic
        open={open}
        vb={notWealthica(partnerName)}
      >
        <Modal.Header>
          <Header
            icon="warning sign"
            content={french ? 'Supprimé votre compte' : 'Delete Account'}
            style={{ color: colors.red }}
          />
        </Modal.Header>
        <Modal.Content>
          {french ? (
            <p>
              Êtes-vous sûr de vouloir supprimer{' '}
              <span style={{ color: colors.red }}>en permanence</span> le compte{' '}
              <span style={{ color: colors.red }}>
                {account.user_institution}
              </span>{' '}
              de Wealthscope ?
            </p>
          ) : (
            <p>
              Are you sure you want to{' '}
              <span style={{ color: colors.red }}>permanently</span> delete
              account{' '}
              <span style={{ color: colors.red }}>
                {account.user_institution}
              </span>{' '}
              from Wealthscope?
            </p>
          )}
          {error && <Error>{error}</Error>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: loading }
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={this.deleteAccount}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  history: PropTypes.object,
  account: PropTypes.object,
  dispatch: PropTypes.func,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

DeleteModal.defaultProps = {
  history: {},
  account: {},
  dispatch: () => false,
  partnerName: '',
  french: false,
};

const Error = styled.div`
  color: ${colors.red};
  font-weight: 700;
  font-size: 1.14rem;
  padding: 10px 0;
`;
