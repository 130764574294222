import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { BuildML } from 'actions';
import { ErrorLabel } from 'components';

class Target extends Component {
  state = {
    focused: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = (e) => {
    const { dispatch, fixedIncome } = this.props;

    // Firefox issue
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return false;
    }

    if (fixedIncome === '') {
      dispatch(BuildML.updateFixedIncome(0));
    }

    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;

    e.target.value = '';

    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  handleChange = (e, { value }) => {
    const { dispatch } = this.props;
    const num = Number(value);
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);

    if (num > 100) {
      return dispatch(BuildML.updateFixedIncome(100));
    }

    if (!negative) {
      return dispatch(BuildML.updateFixedIncome(num));
    }

    return null;
  };

  focus = () => {
    const { fixedIncome, dispatch } = this.props;

    if (!fixedIncome) {
      dispatch(BuildML.updateFixedIncome(0));
    }

    return this.setState({ focused: true });
  };

  render() {
    const { ticker, fixedIncome } = this.props;
    const { focused } = this.state;

    return !focused ? (
      <Value onClick={this.focus}>
        {fixedIncome}
        <ErrorLabel msg={!fixedIncome ? 'Update weight' : null} /> %
      </Value>
    ) : (
      <Input
        name={ticker}
        size="mini"
        type="number"
        value={fixedIncome || ''}
        onChange={this.handleChange}
        style={{ maxWidth: 80, marginLeft: '0.5rem' }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    );
  }
}

Target.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticker: PropTypes.string.isRequired,
  fixedIncome: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default connect()(Target);

const Value = styled.span`
  position: relative;
  cursor: pointer;
  color: #2185d0;
  padding: 11px 5px;
  margin-left: 0.5rem;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;
