// TODO: write unit tests for helper functions
import * as Alert from './alert';
import { request } from './api';
import { setItem, removeItem } from './storage';

/**
 * Action constants
 */
export const ACCOUNTS_REQUEST = 'ACCOUNTS_REQUEST';

export const ACCOUNTS_TOGGLE_MULTIPLE_MODE = 'ACCOUNTS_TOGGLE_MULTIPLE_MODE';
export const ACCOUNTS_SELECT_MULTIPLE_ACCOUNT =
  'ACCOUNTS_SELECT_MULTIPLE_ACCOUNT';
export const ACCOUNTS_DESELECT_MULTIPLE_ACCOUNT =
  'ACCOUNTS_DESELECT_MULTIPLE_ACCOUNT';

export const ACCOUNTS_FETCHALL_SUCCESS = 'ACCOUNTS_FETCHALL_SUCCESS';
export const ACCOUNTS_FETCHACCOUNT_SUCCESS = 'ACCOUNTS_FETCHACCOUNT_SUCCESS';
export const ACCOUNTS_REMOVE_SUCCESS = 'ACCOUNTS_REMOVE_SUCCESS';
export const ACCOUNTS_EDIT_SUCCESS = 'ACCOUNTS_EDIT_SUCCESS';
export const ACCOUNTS_ANALYZE_SUCCESS = 'ACCOUNTS_ANALYZE_SUCCESS';
export const ACCOUNTS_NOT_ENOUGHT_HOLDINGS = 'ACCOUNTS_NOT_ENOUGHT_HOLDINGS';

export const ACCOUNTS_REMOVE_HOLDING = 'ACCOUNTS_REMOVE_HOLDING';
export const ACCOUNTS_SAVE_HOLDING = 'ACCOUNTS_SAVE_HOLDING';
export const ACCOUNTS_SAVE_NAME = 'ACCOUNTS_SAVE_NAME';
export const ACCOUNTS_UPDATE_HOLDING = 'ACCOUNTS_UPDATE_HOLDING';

export const ACCOUNTS_SHOW_MESSAGE = 'ACCOUNTS_SHOW_MESSAGE';
export const ACCOUNTS_HIDE_MESSAGE = 'ACCOUNTS_HIDE_MESSAGE';
export const ACCOUNTS_UPDATE_DATA = 'ACCOUNTS_UPDATE_DATA';
export const ACCOUNTS_UI_CHECKED = 'ACCOUNTS_UI_CHECKED';
export const ACCOUNTS_UI_EXPOSURE = 'ACCOUNTS_UI_EXPOSURE';
/**
 * Action creators
 */
export function fetchId(id, exchangeRate = 1) {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_REQUEST });

    return dispatch(request('get', `/accounts/${id}/`))
      .then((data) => {
        const change = getDailyChange(data.holdings, exchangeRate);
        const balance = data.balance
          ? data.balance
          : data.holdings.reduce(
              (acc, val) =>
                (acc += getMktValue(
                  val,
                  getOverviewCurrency(data, exchangeRate),
                  exchangeRate
                )),
              0
            );

        return dispatch({
          type: ACCOUNTS_FETCHACCOUNT_SUCCESS,
          account: {
            ...data,
            change: !balance ? 0 : round(change / balance),
            balance,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: ACCOUNTS_FETCHACCOUNT_SUCCESS, account: null });

        return dispatch(
          Alert.show({ type: 'error', msg: JSON.stringify(err) })
        );
      });
  };
}

export function checkUiCompare() {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_UI_CHECKED });

    return dispatch(
      request('put', `/auth/user/`, {
        body: {
          ui_compare_checked: true,
        },
      })
    );
  };
}

export function checkUiExposure() {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_UI_EXPOSURE });

    return dispatch(
      request('put', `/auth/user/`, {
        body: {
          ui_exposure_checked: true,
        },
      })
    );
  };
}

export function checkUiBot() {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_UI_CHECKED });

    return dispatch(
      request('put', `/auth/user/`, {
        body: {
          ui_chatbot_checked: true,
        },
      })
    );
  };
}

export function checkUiAgree() {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_UI_CHECKED });

    return dispatch(
      request('put', `/auth/user/`, {
        body: {
          ui_agree_checked: true,
        },
      })
    );
  };
}

export function fetchAll() {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_REQUEST });

    return dispatch(request('get', '/accounts/'))
      .then((data) =>
        dispatch(request('get', '/auth/user/'))
          .then((userInfo) => {
            dispatch(
              setItem('ui_compare_checked', userInfo.ui_compare_checked)
            );
            dispatch(
              setItem('ui_exposure_checked', userInfo.ui_exposure_checked)
            );
            dispatch(
              setItem('ui_chatbot_checked', userInfo.ui_chatbot_checked)
            );
            dispatch(setItem('ui_agree_checked', userInfo.ui_agree_checked));

            dispatch(setItem('pwpa-exchange-rate', userInfo.exchange_rate));
            dispatch({
              type: ACCOUNTS_FETCHALL_SUCCESS,
              payload: parseAccountData(data, userInfo.exchange_rate),
              overview: getAccountOverview(
                data,
                userInfo.region,
                userInfo.exchange_rate
              ),
            });
          })
          .catch((err) => {
            dispatch({
              type: ACCOUNTS_FETCHALL_SUCCESS,
              payload: [],
              overview: null,
            });

            return dispatch(
              Alert.show({ type: 'error', msg: JSON.stringify(err) })
            );
          })
      )
      .catch((err) => {
        dispatch({
          type: ACCOUNTS_FETCHALL_SUCCESS,
          payload: [],
          overview: null,
        });

        return dispatch(
          Alert.show({ type: 'error', msg: JSON.stringify(err) })
        );
      });
  };
}

export function edit(id, params) {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_REQUEST });

    return dispatch(request('put', `/accounts/${id}/`, { body: params }))
      .then((data) => {
        dispatch({ type: ACCOUNTS_EDIT_SUCCESS, payload: data });

        return dispatch(
          Alert.show({ type: 'success', msg: 'Account Information Updated' })
        );
      })
      .catch((err) => {
        dispatch({ type: ACCOUNTS_EDIT_SUCCESS, payload: null });

        return dispatch(
          Alert.show({ type: 'error', msg: err.detail || 'Server Offline' })
        );
      });
  };
}

export function remove(id, history) {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_REQUEST });

    return dispatch(request('delete', `/accounts/${id}/`)).then(
      () => {
        dispatch({ type: ACCOUNTS_REMOVE_SUCCESS, id: Number(id) });
        dispatch(fetchAll());
        dispatch(Alert.show({ type: 'success', msg: 'Account deleted' }));

        return history.push('/accounts/overview');
      },
      (err) => {
        dispatch({ type: ACCOUNTS_REMOVE_SUCCESS, id: null });

        return { err: err.detail || 'Server Offline' };
      }
    );
  };
}

export function analyze(
  holdings,
  history,
  account,
  gicHoldings = [],
  region = 'CA'
) {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_REQUEST });
    dispatch(removeItem('pwpa-has-exclude-holding'));

    const currency = region === 'CA' ? 'CAD' : 'USD';

    const totalValue = holdings.reduce(
      (total, holding) =>
        holding.security_detail && getMktValue(holding, currency)
          ? total + getMktValue(holding, 'CAD')
          : total,
      0
    );

    const parsedHoldings = holdings.reduce(
      (parsed, holding) => {
        if (!holding.security_detail || !totalValue) {
          return parsed;
        }

        const { ticker } = holding.security_detail;
        const weight = getMktValue(holding, currency) / totalValue;

        if (!weight || isNaN(weight)) {
          return parsed;
        }

        parsed.tickers += parsed.tickers.length ? `,${ticker}` : `${ticker}`;
        parsed.weights += parsed.weights.length ? `,${weight}` : `${weight}`;
        return parsed;
      },
      { tickers: '', weights: '' }
    );

    if (!parsedHoldings.weights.length) {
      dispatch({ type: ACCOUNTS_ANALYZE_SUCCESS });
      return dispatch(
        Alert.show({
          type: 'warning',
          msg: 'Not enough matched holdings to analyze this account',
        })
      );
    }

    const gicInfo = gicHoldings.reduce((result, holding) => {
      if (result[holding.security]) {
        result[holding.security] = {
          ...result[holding.security],
          gic_quantity:
            holding.quantity + result[holding.security].gic_quantity,
          gic_rate: holding.gic_rate,
          gic_ticker: holding.gic_ticker,
        };
      } else {
        result[holding.security] = {
          gic_quantity: holding.quantity,
          gic_rate: holding.gic_rate,
          gic_ticker: holding.gic_ticker,
          gic_name: holding.gic_name,
        };
      }

      return result;
    }, {});

    dispatch(
      setItem('pwpa-portfolio', {
        account,
        is_linked: true,
        region,
        symbols: parsedHoldings.tickers,
        weights: parsedHoldings.weights,
        ...(gicInfo && { gic_info: gicInfo }),
      })
    );

    dispatch({ type: ACCOUNTS_ANALYZE_SUCCESS });

    return history.push('/portfolio/unsaved/overview');
  };
}

export function analyzeGlobe(holdings, history) {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_REQUEST });

    const totalValue = holdings.reduce(
      (total, holding) =>
        holding.security_detail
          ? (total += getMktValue(holding, 'CAD'))
          : total,
      0
    );

    const parsedHoldings = holdings.reduce(
      (parsed, holding) => {
        if (!holding.security_detail || !totalValue) {
          return parsed;
        }

        const { ticker } = holding.security_detail;
        const weight = getMktValue(holding, 'CAD') / totalValue;

        if (!weight) {
          return parsed;
        }

        parsed.tickers += parsed.tickers.length ? `,${ticker}` : `${ticker}`;
        parsed.weights += parsed.weights.length ? `,${weight}` : `${weight}`;
        return parsed;
      },
      { tickers: '', weights: '' }
    );

    if (!parsedHoldings.weights.length) {
      return dispatch({ type: ACCOUNTS_NOT_ENOUGHT_HOLDINGS });
    }

    dispatch(
      setItem('pwpa-portfolio', {
        is_linked: true,
        region: 'CA',
        symbols: parsedHoldings.tickers,
        weights: parsedHoldings.weights,
      })
    );

    dispatch({ type: ACCOUNTS_ANALYZE_SUCCESS });

    return history.push('/globe/overview');
  };
}

export function showUnsupportedMsg() {
  // pre-analysis URL is temporary
  return (dispatch) =>
    dispatch(request('get', '/portfolio/pre-analysis/')).then(
      (data) => dispatch({ type: ACCOUNTS_SHOW_MESSAGE, institutions: data }),
      () => dispatch({ type: ACCOUNTS_HIDE_MESSAGE })
    );
}

export function hideUnsupportedMsg() {
  return { type: ACCOUNTS_HIDE_MESSAGE };
}

export function saveHolding(id, holding) {
  return {
    type: ACCOUNTS_SAVE_HOLDING,
    id,
    holding,
  };
}

export function updateHolding(id, holding) {
  return {
    type: ACCOUNTS_UPDATE_HOLDING,
    id,
    holding,
  };
}

export function removeHolding(id, ticker) {
  return {
    type: ACCOUNTS_REMOVE_HOLDING,
    id,
    ticker,
  };
}

export function updateData(id, data) {
  return {
    type: ACCOUNTS_UPDATE_DATA,
    id,
    data,
  };
}

export function toggleMultipleMode() {
  return (dispatch) => {
    dispatch({ type: ACCOUNTS_TOGGLE_MULTIPLE_MODE });
  };
}

export function selectMultipleAccount(
  account,
  accountList,
  region = 'CA',
  exchangeRate
) {
  return (dispatch) => {
    const newAccountList = accountList.concat(account);
    const overview = getAccountOverview(newAccountList, region, exchangeRate);

    dispatch({
      type: ACCOUNTS_SELECT_MULTIPLE_ACCOUNT,
      selectedAccountList: newAccountList,
      selectedAccountOverview: overview,
    });
  };
}

export function deselectMultipleAccount(
  account,
  accountList,
  region = 'CA',
  exchangeRate
) {
  return (dispatch) => {
    if (accountList.length !== 0) {
      const newAccountList = accountList.filter(
        (selectedAccount) => account.id !== selectedAccount.id
      );

      const overview = getAccountOverview(newAccountList, region, exchangeRate);

      dispatch({
        type: ACCOUNTS_DESELECT_MULTIPLE_ACCOUNT,
        selectedAccountList: newAccountList,
        selectedAccountOverview: overview,
      });
    }
  };
}

export function saveSingleAccInfo(accountId, institution, label) {
  return {
    type: ACCOUNTS_SAVE_NAME,
    accountId,
    institution,
    label,
  };
}

/**
 * Helper functions
 */
function parseAccountData(data) {
  return data.reduce((total, account) => {
    const change = getDailyChange(account.holdings);
    const balance = account.balance
      ? account.balance
      : account.holdings.reduce(
          (acc, val) => (acc += getMktValue(val, account.currency)),
          0
        );

    total.push({
      ...account,
      change: !balance ? 0 : round(change / balance),
      balance,
    });

    return total;
  }, []);
}

function getAccountOverview(data, userRegion = 'CA', exchangeRate = 1) {
  const newList = deepCopy(data);
  const currency = getOverviewCurrency(userRegion, data); // Determine overview currency
  const totalBalance = calculateTotalBalance(data, currency, exchangeRate);
  const totalCash = calculateTotalCash(data, currency, exchangeRate);
  const groupedHoldings = groupHoldingsByTicker(
    newList,
    userRegion,
    exchangeRate
  );

  const sortedHoldings = sortHoldings(groupedHoldings, currency, exchangeRate);
  const totalChange = calculateTotalChange(sortedHoldings, totalBalance);
  return {
    holdings: sortedHoldings,
    balance: totalBalance,
    cash: totalCash,
    change: totalChange,
    currency,
  };
}

function getOverviewCurrency(userRegion, data) {
  const hasCAD = anyAccountInCurrency(data, 'CAD');

  // If any account is in CAD, return CAD
  if (hasCAD && userRegion !== 'US') {
    return 'CAD';
  }
  return 'USD';
}

function groupHoldingsByTicker(data, userRegion, exchangeRate = 1) {
  const convertCurrencyToCad = (num) =>
    num * (userRegion === 'CA' ? exchangeRate : 1);

  return data.reduce((total, account) => {
    account.holdings.forEach((holding) => {
      const ticker = holding.security || holding.ticker;
      if (!total[ticker]) {
        total[ticker] = { ...holding };
        total[ticker].miss_book_value = !Boolean(holding.book_value);

        if (holding.currency === 'USD' && userRegion === 'CA') {
          convertHoldingCurrency(total[ticker], convertCurrencyToCad);
        }
      } else {
        updateExistingHolding(
          total[ticker],
          holding,
          convertCurrencyToCad,
          userRegion
        );
      }
    });
    return total;
  }, {});
}

function calculateTotalCash(data, currency) {
  return data.reduce((total, account) => {
    // Ensure cash is converted to the overview currency
    const cashInOverviewCurrency =
      currency === account.currency
        ? account.cash
        : account.cash * account.usd2cad;
    return total + cashInOverviewCurrency;
  }, 0);
}

function calculateTotalChange(sortedHoldings, totalBalance) {
  if (!totalBalance) return 0;
  return round(getDailyChange(sortedHoldings) / totalBalance);
}

function convertHoldingCurrency(holding, convertCurrencyToCad) {
  holding.market_value = convertCurrencyToCad(holding.market_value);
  holding.book_value = convertCurrencyToCad(holding.book_value);
  holding.unrealized_gain_amount = convertCurrencyToCad(
    holding.unrealized_gain_amount
  );
  holding.currency = 'CAD'; // Ensure the currency is updated to CAD
}

function anyAccountInCurrency(data, currency) {
  return data.some((account) => account.currency === currency);
}

function deepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

function updateExistingHolding(
  existingHolding,
  holding,
  convertCurrencyToCad,
  userRegion
) {
  existingHolding.market_value +=
    holding.currency === 'USD' && userRegion === 'CA'
      ? convertCurrencyToCad(holding.market_value)
      : holding.market_value;

  existingHolding.quantity += holding.quantity;

  const missBookValue =
    !Boolean(holding.book_value) || existingHolding.miss_book_value;

  existingHolding.miss_book_value = missBookValue;

  existingHolding.book_value = missBookValue
    ? 0
    : holding.currency === 'USD' && userRegion === 'CA'
    ? convertCurrencyToCad(existingHolding.book_value + holding.book_value)
    : existingHolding.book_value + holding.book_value;

  existingHolding.unrealized_gain_amount = missBookValue
    ? 0
    : holding.currency === 'USD' && userRegion === 'CA'
    ? convertCurrencyToCad(
        existingHolding.unrealized_gain_amount + holding.unrealized_gain_amount
      )
    : existingHolding.unrealized_gain_amount + holding.unrealized_gain_amount;
}

function sortHoldings(groupedHoldings, targetCurrency, exchangeRate) {
  return Object.values(groupedHoldings).sort(
    (a, b) =>
      getMktValue(b, targetCurrency, exchangeRate) -
      getMktValue(a, targetCurrency, exchangeRate)
  );
}

function calculateTotalBalance(data, outputCurrency, exchangeRate) {
  return data.reduce((sum, account) => {
    let balance = 0;

    if (account.currency === 'USD' && outputCurrency === 'CAD') {
      // Convert USD balance to CAD using exchange rate
      balance += account.balance * exchangeRate;
    } else {
      // Otherwise, directly use account balance
      balance += account.balance;
    }

    return sum + balance;
  }, 0);
}

export default getAccountOverview;

function getDailyChange(holdings) {
  return holdings.reduce((sum, holding) => {
    if (!holding.security_detail) {
      return (sum += 0);
    }
    // use our daily_change if exists, else use yodlee daily_change
    const change = holding.security_detail
      ? holding.security_detail.daily_change
      : holding.daily_change || 0;

    return (sum += getMktValue(holding) * change);
  }, 0);
}

function round(num) {
  return num ? Math.round(num * 100) / 100 : 0;
}

// function convertAccountCurrency(
//   balance,
//   currency,
//   exchangeRate,
//   region = 'CA'
// ) {
//   let accountValue = balance;

//   if (currency === null) {
//     currency = 'CAD';
//   }
//   if (currency === 'USD' && region === 'CA') {
//     const exchange = exchangeRate;
//     accountValue *= exchange;
//   }

//   return accountValue;
// }

// function getMktValue(holding, targetCurrency = 'CAD', exchangeRate = 1) {
//   // Use market_value if available
//   let mktValue = holding.market_value;

//   // If market_value is not available, calculate it using unadjusted_closing_price
//   if (!mktValue) {
//     let currency = targetCurrency; // Default to targetCurrency

//     // Check if unadjusted_closing_price exists and is not empty
//     let price = 0;
//     if (
//       holding.unadjusted_closing_price &&
//       Object.keys(holding.unadjusted_closing_price).length > 0
//     ) {
//       price = holding.unadjusted_closing_price[currency] || 0;
//     } else if (
//       holding.security_detail &&
//       holding.security_detail.unadjusted_closing_price &&
//       Object.keys(holding.security_detail.unadjusted_closing_price).length > 0
//     ) {
//       price = holding.security_detail.unadjusted_closing_price[currency] || 0;
//     }

//     mktValue = price * holding.quantity;
//   }

//   // Convert to target currency if necessary
//   if (holding.price_currency !== targetCurrency) {
//     // Apply exchange rate conversion if needed
//     mktValue *= exchangeRate;
//   }

//   return mktValue;
// }

export function getMktValue(holding, outputCurrency = 'CAD', exchangeRate = 1) {
  // Ensure market_value is a valid number, defaulting to 0 if it's not
  let mktVal =
    typeof holding.market_value === 'number' && !isNaN(holding.market_value)
      ? holding.market_value
      : 0;

  // Ensure price is calculated correctly using unadjusted_closing_price
  const price =
    holding.unadjusted_closing_price &&
    holding.unadjusted_closing_price.CAD &&
    typeof holding.unadjusted_closing_price.CAD === 'number'
      ? holding.unadjusted_closing_price.CAD
      : holding.security_detail &&
        holding.security_detail.unadjusted_closing_price &&
        holding.security_detail.unadjusted_closing_price.CAD &&
        typeof holding.security_detail.unadjusted_closing_price.CAD === 'number'
      ? holding.security_detail.unadjusted_closing_price.CAD
      : 0;

  // Ensure quantity is a valid number, default to 0 if invalid
  const quantity =
    typeof holding.quantity === 'number' && !isNaN(holding.quantity)
      ? holding.quantity
      : 0;

  const value = price * quantity;

  // Convert to target currency if market value exists & currency is USD
  if (holding.currency === 'USD' && outputCurrency !== 'USD') {
    mktVal *=
      typeof exchangeRate === 'number' && exchangeRate > 0 ? exchangeRate : 1;
  }

  // Return the market value, or fall back to calculated value
  return mktVal || value;
}
