import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flag, Button, Icon, Header } from 'semantic-ui-react';
import { LogoSpinner } from 'components';
import { colors } from 'utils/colors';
import cn from 'classnames';

class Showcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      togglePct: true,
      directCollapsed: true,
      indirectCollapsed: true,
      isDisclaimerOpen: false,
    };
  }

  handleToggleCollapse = (item) => {
    this.setState((prevState) => ({ [item]: !prevState[item] }));
  };

  handleTogglePct = (event, togglePct) => {
    event.stopPropagation(); // Stop event propagation
    this.setState({ togglePct });
  };

  parseNum = (num) =>
    num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  round = (num) => (!num ? 0 : Math.round(num * 10000) / 10000);

  render() {
    const { stockExposure, user_region, exchangeRate } = this.props;
    const { security, prevSearch, isFetching } = stockExposure;
    const {
      num_accounts_exposed,
      total_direct_exposures,
      total_indirect_exposures,
      account_exposures,
    } = security || {};
    const { directCollapsed, indirectCollapsed, togglePct, isDisclaimerOpen } =
      this.state;
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    const showSecurityContent =
      stockExposure &&
      security &&
      Object.keys(total_direct_exposures || {}).length !== 0 &&
      total_direct_exposures !== null;

    return !prevSearch ? null : isFetching || !showSecurityContent ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '6rem',
          opacity: '0.6',
        }}
      >
        <LogoSpinner />
      </div>
    ) : (
      <div>
        <div
          className="showcaseComponent"
          style={{ marginTop: '1rem', marginBottom: '3rem' }}
        >
          <CollapseWrapper open={isDisclaimerOpen}>
            <Header
              size="medium"
              content="Disclaimer"
              style={{
                position: 'absolute',
                top: isDisclaimerOpen ? '-11px' : '3px',
                left: '.5rem',
                background: 'white',
                transition: 'all 300ms ease',
                padding: '0 0.75rem 0 .5rem',
              }}
            >
              <StyledDisclaimerButton
                icon
                onClick={() =>
                  this.setState({ isDisclaimerOpen: !isDisclaimerOpen })
                }
                isOpen={isDisclaimerOpen}
              >
                {isDisclaimerOpen ? '-' : '+'}
              </StyledDisclaimerButton>{' '}
              Disclaimer
            </Header>
            <div>
              <div style={{ margin: '2rem 2rem 2rem auto' }}>
                <ul>
                  <li>
                    If you have mutual funds and/or exchange-traded funds, the
                    information provided here is an approximation only, because
                    indirect ownership of a stock (see below) is based on a
                    fund's top 25 holdings, and fund holdings and their weights
                    may have changed. Please consult the most recent fund fact
                    sheets from your fund providers and perform further due
                    diligence.
                  </li>
                  <li>
                    Any mutual funds for which we have no data (e.g., private
                    pooled funds), they will be marked as "unmatched" and
                    highlighted in yellow in the Accounts segment. These funds
                    are excluded in the analysis.
                  </li>
                </ul>
              </div>
            </div>
          </CollapseWrapper>
          <StyledHeader>
            <span style={{ color: `${colors.blue}` }}>{prevSearch}</span>{' '}
            Exposure Details
          </StyledHeader>
          <Note>
            {`Note: All Account values are displayed in ${
              user_region === 'CA' ? 'CAD' : 'USD'
            }${
              user_region === 'CA'
                ? `. USD/CAD exchange rate used is ${this.round(
                    Number(exchangeRate)
                  )}`
                : '.'
            }`}
          </Note>
        </div>

        <div>
          <section>
            <CardContainer style={{ display: 'flex', minWidth: '100%' }}>
              <InfoCard>
                <div>{prevSearch} accounts for</div>
                <div
                  className="inner-statistic"
                  style={{ color: `${colors.blue}` }}
                >
                  {(total_indirect_exposures.pct + total_direct_exposures.pct) *
                    100 <
                    0.005 &&
                  (total_indirect_exposures.pct + total_direct_exposures.pct) *
                    100 !==
                    0
                    ? `~ ${this.parseNum(
                        (total_indirect_exposures.pct +
                          total_direct_exposures.pct) *
                          100
                      )}`
                    : `${this.parseNum(
                        (total_indirect_exposures.pct +
                          total_direct_exposures.pct) *
                          100
                      )}`}
                  %
                </div>
                <div>of your total holdings</div>
              </InfoCard>
              <InfoCard>
                <div>As of {formattedDate}, you own</div>
                <div
                  className="inner-statistic"
                  style={{ color: `${colors.darkBlue}` }}
                >
                  $
                  {user_region === 'CA'
                    ? this.parseNum(
                        total_indirect_exposures.cad +
                          total_direct_exposures.cad
                      )
                    : this.parseNum(
                        total_indirect_exposures.usd +
                          total_direct_exposures.usd
                      )}
                </div>
                <div>of {prevSearch}</div>
              </InfoCard>
              <InfoCard>
                <div>{prevSearch} is located in</div>
                <div
                  className="inner-statistic"
                  style={{ color: `${colors.red}` }}
                >
                  {num_accounts_exposed}
                </div>
                <div>of your accounts</div>
              </InfoCard>
            </CardContainer>
          </section>

          <section style={{ minWidth: '65vw' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2rem',
              }}
            >
              <TitleBar
                onClick={() => this.handleToggleCollapse('directCollapsed')}
              >
                <div>Accounts Holding {prevSearch} Directly</div>
                <div style={{ display: 'flex' }}>
                  <Selection>
                    <span
                      className={cn({ active: togglePct })}
                      onClick={(event) => this.handleTogglePct(event, true)}
                    >
                      %
                    </span>
                    <span
                      className={cn({ active: !togglePct })}
                      onClick={(event) => this.handleTogglePct(event, false)}
                    >
                      $
                    </span>
                  </Selection>
                  <StyledButton icon>
                    <Icon
                      name="chevron down"
                      style={{
                        transform: directCollapsed
                          ? 'rotate(0deg)'
                          : 'rotate(180deg)',
                      }}
                    />
                  </StyledButton>
                </div>
              </TitleBar>

              <OptionWrapper collapsed={directCollapsed}>
                <Bar>
                  <div>Total Direct Exposure</div>
                  <div className="main-statistic">
                    {!togglePct
                      ? `$${
                          user_region === 'CA'
                            ? this.parseNum(total_direct_exposures.cad)
                            : this.parseNum(total_direct_exposures.usd)
                        }`
                      : total_direct_exposures.pct * 100 < 0.005 &&
                        total_direct_exposures.pct * 100 !== 0
                      ? `~ ${this.parseNum(total_direct_exposures.pct * 100)}%`
                      : `${this.parseNum(total_direct_exposures.pct * 100)}%`}
                  </div>
                </Bar>
                {account_exposures.direct.map((account, index) => (
                  <Bar key={index}>
                    <AccountInfo>
                      <div>
                        <div>
                          <div>{account.account_institution}</div>
                          <AccountDescription>
                            {account.account_name}
                          </AccountDescription>
                        </div>
                        <Flag
                          name={
                            account.account_currency === 'CAD' ? 'ca' : 'us'
                          }
                          className="larger-flag"
                        />
                      </div>
                    </AccountInfo>
                    <div>
                      {!togglePct
                        ? `$${
                            user_region === 'CA'
                              ? this.parseNum(account.exposure.cad)
                              : this.parseNum(account.exposure.usd)
                          }`
                        : account.exposure.pct * 100 < 0.005 &&
                          account.exposure.pct * 100 !== 0
                        ? `~ ${this.parseNum(account.exposure.pct * 100)}%`
                        : `${this.parseNum(account.exposure.pct * 100)}%`}
                    </div>
                  </Bar>
                ))}
              </OptionWrapper>
            </div>
          </section>

          <section style={{ minWidth: '60vw' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
              }}
            >
              <TitleBar
                onClick={() => this.handleToggleCollapse('indirectCollapsed')}
              >
                <div>
                  Accounts Holding {stockExposure.prevSearch} Indirectly
                </div>
                <div style={{ display: 'flex' }}>
                  <Selection>
                    <span
                      className={cn({ active: togglePct })}
                      onClick={(event) => this.handleTogglePct(event, true)}
                    >
                      %
                    </span>
                    <span
                      className={cn({ active: !togglePct })}
                      onClick={(event) => this.handleTogglePct(event, false)}
                    >
                      $
                    </span>
                  </Selection>
                  <StyledButton icon>
                    <Icon
                      name="chevron down"
                      style={{
                        transform: indirectCollapsed
                          ? 'rotate(0deg)'
                          : 'rotate(180deg)',
                      }}
                    />
                  </StyledButton>
                </div>
              </TitleBar>
              <OptionWrapper collapsed={indirectCollapsed}>
                <Bar>
                  <div>
                    <div>Total Indirect Exposure</div>
                    <AccountDescription>
                      Including fund of funds
                    </AccountDescription>
                  </div>

                  <div className="main-statistic">
                    {!togglePct
                      ? `$${
                          user_region === 'CA'
                            ? this.parseNum(total_indirect_exposures.cad)
                            : this.parseNum(total_indirect_exposures.usd)
                        }`
                      : total_indirect_exposures.pct * 100 < 0.005 &&
                        total_indirect_exposures.pct * 100 !== 0
                      ? `~ ${this.parseNum(
                          total_indirect_exposures.pct * 100
                        )}%`
                      : `${this.parseNum(total_indirect_exposures.pct * 100)}%`}
                  </div>
                </Bar>
                {account_exposures.indirect.map((account, index) => (
                  <IndirectItem>
                    <AccountContainer key={index} style={{ display: 'flex' }}>
                      <AccountInfo>
                        <div>
                          <div>
                            <div>{account.account_institution}</div>
                            <AccountDescription>
                              {account.account_name}
                            </AccountDescription>
                          </div>
                          <Flag
                            name={
                              account.account_currency === 'CAD' ? 'ca' : 'us'
                            }
                            className="larger-flag"
                          />
                        </div>
                      </AccountInfo>
                      <div>
                        {!togglePct
                          ? `$${
                              user_region === 'CA'
                                ? this.parseNum(account.exposure.cad)
                                : this.parseNum(account.exposure.usd)
                            }`
                          : account.exposure.pct * 100 < 0.005 &&
                            account.exposure.pct * 100 !== 0
                          ? `~ ${this.parseNum(account.exposure.pct * 100)}%`
                          : `${this.parseNum(account.exposure.pct * 100)}%`}
                      </div>
                    </AccountContainer>

                    {account.funds &&
                      account.funds.map((fund, fundIndex) => (
                        <FundContainer key={fundIndex}>
                          <div>
                            {fund.name} ({fund.ticker})
                          </div>
                          <div className="exposure">
                            {!togglePct
                              ? `$${
                                  user_region === 'CA'
                                    ? this.parseNum(fund.exposure.cad)
                                    : this.parseNum(fund.exposure.usd)
                                }`
                              : this.parseNum(fund.exposure.pct * 100) <
                                  0.005 && fund.exposure.pct * 100 !== 0
                              ? `~ ${this.parseNum(fund.exposure.pct * 100)}%`
                              : `${this.parseNum(fund.exposure.pct * 100)}%`}
                          </div>
                        </FundContainer>
                      ))}
                  </IndirectItem>
                ))}
              </OptionWrapper>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Showcase.propTypes = {
  stockExposure: PropTypes.object,
  user_region: PropTypes.string,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Showcase.defaultProps = {
  stockExposure: {},
  user_region: 'CA',
  exchangeRate: 0,
};

export default connect((state) => ({
  stockExposure: state.StockExposure,
  user_region: state.Storage.user_region,
  exchangeRate: state.Storage['pwpa-exchange-rate'],
}))(Showcase);

const IndirectItem = styled.div`
  background: #f3f3f3;
  border-radius: 10px;
  margin: 0.5rem;
  padding: 1rem;
`;

const AccountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: 600;
`;

const FundContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-left: 3.5rem !important;
`;

const StyledHeader = styled.h2`
  font-size: 1.6rem !important;
  margin-top: 2rem;
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 0rem 0;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const InfoCard = styled.div`
  display: flex;
  height: 11rem;
  width: 16rem;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  background: #f3f3f3;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 20px;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  .inner-statistic {
    font-size: 1.6rem;
    font-weight: 700;
  }
`;

const OptionWrapper = styled.div`
  transition: max-height 0.8s ease-in-out;
  overflow: hidden;
  max-height: ${(props) => (props.collapsed ? '0' : '1000px')};
`;

const StyledButton = styled(Button)`
  background-color: transparent !important;
  color: #000 !important;
  svg {
    transition: transform 0.8s ease-in-out;
  }
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.teal};
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0 1.3rem 0 1.3rem;
  margin: 0.5rem;
  cursor: pointer;
`;

const AccountDescription = styled.div`
  font-size: 0.9rem;
  text-align: left;
`;

const Bar = styled.div`
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0 1.3rem 0 1.3rem;
  margin: 0.5rem;
  & .main-statistic {
    color: ${colors.blue};
    font-size: 1.5rem;
  }
`;

const AccountInfo = styled.div`
  display: flex;
  margin-left: 2.5rem;
  text-align: left;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
`;

const Selection = styled.div`
  text-align: left;
  margin: 1rem 0;

  span {
    padding: 0.3rem 0.7rem;
    cursor: pointer;
    font-size: 0.9rem;
    background-color: white;
    border: 1px solid #dcdcdc;
    user-select: none;
    color: black;

    &.active {
      background-color: ${colors.blue};
      border: 1px solid transparent;
      color: white;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      border: 1px solid ${colors.blue};
    }
  }
`;

const StyledDisclaimerButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 60vw;
  padding: ${(props) => (props.open ? '0rem 1rem 0' : '2rem 1rem 0')};
  margin: 1.5rem auto 0;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '2000px' : '28px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
  text-align: justify;
`;
