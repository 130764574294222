import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Popup,
  Form,
  Input,
  Icon,
  Header,
  Dropdown,
  Divider,
} from 'semantic-ui-react';
import cn from 'classnames';

import { CacheInputs, Portfolio, Planning } from 'actions';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import {
  Loading,
  Segment,
  YouTubePlayer,
  ErrorLabel,
  Button,
} from 'components';
import PortfoliosList from '../components/PortfoliosList';
import Result from './Result';

class TargetWealth extends Component {
  state = {
    isChanged: false,
  };
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    return !portfolios.length ? dispatch(Portfolio.fetchModel()) : false;
  };

  toggleVideo = (target) =>
    this.props.dispatch(
      CacheInputs.updateField('planning', 'target_wealth', {
        video: target === this.props.targetWealthInputs.video ? false : target,
      })
    );

  handleChange = (e, { name, value }) => {
    const { dispatch, targetWealthInputs } = this.props;
    const { errors } = targetWealthInputs;

    if (name !== 'gender') {
      const limitRegex = /^[0-9]{1,9}$/;
      if (value !== '' && !limitRegex.test(value)) return;
      this.setState({ isChanged: true });
      if (name === 'age' || name === 'retAge') {
        const num = value > 100 ? 100 : Number(value);

        // eslint-disable-next-line consistent-return
        return dispatch(
          CacheInputs.updateField('planning', 'target_wealth', {
            [name]: num,
            errors: { ...errors, [name]: null },
          })
        );
      }
      dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          [name]: value,
          errors: { ...errors, [name]: null },
        })
      );
    } else {
      dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          [name]: value,
          errors: { ...errors, [name]: null },
        })
      );
    }
  };

  handlePortfolioChange = (e, { name, value }) => {
    const { dispatch, targetWealthInputs } = this.props;
    const { errors } = targetWealthInputs;

    dispatch(Planning.clearSectionData('wealthResult'));
    this.setState({ isChanged: true });
    const limitRegex = /^[0-9]{1,9}$/;
    if (name === 'annuityRate') {
      if (value === '' || !limitRegex.test(value)) {
        dispatch(
          CacheInputs.updateField('planning', 'target_wealth', {
            ...this.props.targetWealthInputs,
            wealth_portfolio: null,
            modelType: 'annuityRate',
            targ: null,
            annuityRate: null,
          })
        );
        return;
      }

      const num = Number(value) > 100 ? 100 : Number(value);

      dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          ...this.props.targetWealthInputs,
          wealth_portfolio: null,
          modelType: 'annuityRate',
          annuityRate: num,
          targ: null,
        })
      );
    } else {
      dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          ...this.props.targetWealthInputs,
          wealth_portfolio: value,
          modelType: name,
          annuityRate: null,
          targ: null,
        })
      );
    }
    dispatch(
      CacheInputs.updateField('planning', 'target_wealth', {
        errors: {
          ...errors,
          portfolioID: null,
        },
      })
    );
  };

  handleRadioChange = (e, { value }) => {
    const { dispatch, targetWealthInputs } = this.props;
    const { errors } = targetWealthInputs;
    dispatch(Planning.clearSectionData('wealthResult'));

    dispatch(
      CacheInputs.updateField('planning', 'target_wealth', {
        modelType: value,
        annuityRate: null,
        errors: { ...errors, portfolioID: null },
      })
    );
  };

  hasEmptyValue = (values) =>
    values.some((field) => {
      const { dispatch, targetWealthInputs } = this.props;

      if (!targetWealthInputs[field]) {
        this.scrollHandler(document.documentElement, -20000, 600);
        return dispatch(
          CacheInputs.updateField('planning', 'target_wealth', {
            errors: { [field]: 'This field is required' },
          })
        );
      }

      return false;
    });

  handleDeclinePatternChange = (e) => {
    const { dispatch, targetWealthInputs } = this.props;
    const { errors } = targetWealthInputs;
    const { name, value } = e.target;
    const num = value > 100 ? 100 : Number(value);

    return dispatch(
      CacheInputs.updateField('planning', 'target_wealth', {
        [name]: num,
        errors: { ...errors, [name]: null },
      })
    );
  };

  handleSubmit = () => {
    const { portfolios, dispatch, targetWealthInputs, user_region } =
      this.props;
    const {
      annuityRate,
      age,
      retAge,
      gender,
      cf3,
      total,
      cpp = 0,
      oas = 0,
      declinePattern,
      declinePatternPercent,
      declinePatternDuration,
      modelType,
      wealth_portfolio,
    } = targetWealthInputs;
    const cppAge = retAge < 60 ? 60 : retAge;
    const oasAge = retAge < 65 ? 65 : retAge;
    const params = {
      gender,
      exp: total || cf3,
      cpp,
      cppAge,
      oas,
      oasAge,
      age,
      retAge,
      ...(declinePattern && {
        declinePattern: {
          percent: declinePatternPercent,
          duration: declinePatternDuration,
        },
      }),
    };
    const userInputs = {
      age,
      retAge,
      gender,
      cf3,
      ...(declinePattern && {
        declinePatternPercent,
        declinePatternDuration,
      }),
    };

    if (this.hasEmptyValue(Object.keys({ ...userInputs }))) return false;
    this.setState({ isChanged: false });

    if (retAge < age) {
      this.scrollHandler(document.documentElement, -20000, 600);
      return dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          errors: { retAge: 'Retirement age can not be less than current age' },
        })
      );
    }

    if (
      (wealth_portfolio === null || wealth_portfolio === undefined) &&
      modelType !== 'annuityRate'
    ) {
      return dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          errors: { portfolioID: 'Please select a portfolio.' },
        })
      );
    }

    if (!annuityRate && annuityRate !== 0 && modelType === 'annuityRate') {
      return dispatch(
        CacheInputs.updateField('planning', 'target_wealth', {
          errors: { portfolioID: 'Please enter a risk-free rate.' },
        })
      );
    }

    if (annuityRate || annuityRate === 0) {
      return dispatch(
        Planning.analyzeWealth({
          ...params,
          region: user_region,
          annuityRate: annuityRate / 100,
        })
      ).then(() =>
        this.scrollHandler(
          document.documentElement,
          document.getElementById('ref').offsetHeight,
          600
        )
      );
    }
    const selectedPortfolio = portfolios.find(
      (port) => port.id === Number(wealth_portfolio)
    );

    return dispatch(
      Planning.analyzeWealth({
        symbols: selectedPortfolio.symbols,
        weights: selectedPortfolio.weights,
        region: selectedPortfolio.region,
        gic_info: selectedPortfolio.gic_info,
        ...params,
      })
    ).then(() =>
      this.scrollHandler(
        document.documentElement,
        document.getElementById('ref').offsetHeight,
        600
      )
    );
  };

  scrollHandler = (element, to, duration) => {
    if (duration <= 0) return false;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      element.scrollTop += perTick;
      // for safari compatibility
      if (element.scrollTop === 0) {
        document.body.scrollTop += perTick;
      }
      if ((element.scrollTop || document.body.scrollTop) >= to) return false;
      return this.scrollHandler(element, to, duration - 10);
    }, 10);
  };

  renderSubHeaderWithVideo = () => (
    <div className="sub header">
      In this segment, you can calculate the level of wealth you need at
      retirement in order to sustain your post-retirement spending needs.
      <Icon
        name="youtube play"
        onClick={() => this.toggleVideo('3QM5prvkO0M')}
        link
        style={{ color: 'rgb(255,2,2)', marginLeft: '3px' }}
      />
      <YouTubePlayer
        isOpen={this.props.targetWealthInputs.video === '3QM5prvkO0M'}
        id="3QM5prvkO0M"
        style={{ margin: '10px 0' }}
      />
    </div>
  );

  render() {
    const {
      portfolios,
      isFetching,
      dispatch,
      targetWealthInputs,
      user_region,
      partnerName,
      result,
    } = this.props;
    const {
      portfolioID,
      annuityRate,
      age,
      retAge,
      gender,
      cf3,
      declinePattern,
      declinePatternPercent,
      declinePatternDuration,
      errors,
      modelType,
    } = targetWealthInputs;

    return (
      <div
        style={{
          border: isGlobeInvestor(partnerName)
            ? '1px solid rgba(0, 0, 0, 0.2)'
            : 'none',
        }}
      >
        <Segment>
          <div id="ref">
            <Loading active={!portfolios.length} />

            {!isGlobeInvestor(partnerName) && (
              <Header
                textAlign="center"
                size="large"
                content="Target Wealth"
                subheader={this.renderSubHeaderWithVideo()}
                style={{ marginBottom: '1rem' }}
              />
            )}

            <Form>
              {!isGlobeInvestor(partnerName) && (
                <Header
                  size="medium"
                  content="Enter Your Inputs"
                  textAlign="center"
                />
              )}
              <Section globe={isGlobeInvestor(partnerName)}>
                {isGlobeInvestor(partnerName) && (
                  <div className="heading">Inputs</div>
                )}
                <StyledFormGroup grouped>
                  <Form.Field style={{ position: 'relative' }}>
                    <StyledLabel>Current age:</StyledLabel>
                    <Input
                      name="age"
                      value={age || ''}
                      placeholder="Enter value..."
                      label={{ basic: true, content: 'years' }}
                      labelPosition="right"
                      onChange={this.handleChange}
                    />
                    <ErrorLabel className="top" msg={errors.age} />
                  </Form.Field>
                  <Form.Field style={{ position: 'relative' }}>
                    <StyledLabel>Retirement age:</StyledLabel>
                    <Input
                      name="retAge"
                      value={retAge || ''}
                      placeholder="Enter value..."
                      label={{ basic: true, content: 'years' }}
                      labelPosition="right"
                      onChange={this.handleChange}
                    />
                    <ErrorLabel className="bottom" msg={errors.retAge} />
                  </Form.Field>
                  <Form.Field style={{ position: 'relative' }}>
                    <StyledLabel>
                      Sex:&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                        content={
                          user_region === 'CA'
                            ? 'We are using mortality rates from Statistics Canada in the calculations and the data is available for males and females only.'
                            : 'We are using mortality rates from the National Center for Health Statistics in the calculations and the data is available for males and females only.'
                        }
                      />
                    </StyledLabel>
                    <Dropdown
                      selection
                      options={[
                        { key: 'm', value: 'M', text: 'Male' },
                        { key: 'f', value: 'F', text: 'Female' },
                      ]}
                      name="gender"
                      value={gender}
                      placeholder="Select ..."
                      onChange={this.handleChange}
                      style={{ minWidth: 100 }}
                    />
                    <ErrorLabel className="top" msg={errors.gender} />
                  </Form.Field>
                </StyledFormGroup>

                <StyledFormGroup grouped>
                  <Form.Field style={{ position: 'relative' }}>
                    <StyledLabel>
                      Annual spending amount NET OF expected retirement income:{' '}
                    </StyledLabel>
                    <Input
                      name="cf3"
                      value={!cf3 && cf3 !== 0 ? '' : cf3}
                      placeholder="Enter value..."
                      label={{ basic: true, content: '$' }}
                      onChange={this.handleChange}
                    />
                    <ErrorLabel className="bottom" msg={errors.cf3} />
                  </Form.Field>

                  {!isGlobeInvestor(partnerName) && (
                    <Form.Field style={{ position: 'relative' }}>
                      <StyledLabel style={{ letterSpacing: '-0.1px' }}>
                        This amount will stay constant in real terms. However,
                        you may have heard of references to the "go-go",
                        "slow-go", and "no-go" phases of retirement, i.e., the
                        idea that your spending needs may drop as you age, when
                        activities are gradually reduced.
                      </StyledLabel>
                    </Form.Field>
                  )}

                  <Form.Field style={{ position: 'relative' }}>
                    <StyledLabel style={{ width: '105%' }}>
                      {isGlobeInvestor(partnerName)
                        ? 'Do you expect your spending to stay constant throughout your retirement?'
                        : 'Do you want to incorporate this consideration into the simulations?'}{' '}
                      <Popup
                        trigger={
                          <Icon
                            name="info circle"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="bottom center"
                        wide
                      >
                        This amount will stay constant in real terms. However,
                        you may have heard of references to the "go-go",
                        "slow-go", and "no-go" phases of retirement, i.e., the
                        idea that your spending needs may drop as you age, when
                        activities are gradually reduced.
                      </Popup>
                    </StyledLabel>
                    {!isGlobeInvestor(partnerName) && (
                      <div>
                        <StyledSpan
                          active={declinePattern}
                          onClick={() => {
                            if (!declinePattern) {
                              dispatch(
                                CacheInputs.updateField(
                                  'planning',
                                  'target_wealth',
                                  {
                                    declinePattern: true,
                                    declinePatternPercent: null,
                                    declinePatternDuration: null,
                                  }
                                )
                              );
                            }
                          }}
                        >
                          Yes
                        </StyledSpan>
                        <StyledSpan
                          active={!declinePattern}
                          onClick={() => {
                            if (declinePattern) {
                              dispatch(
                                CacheInputs.updateField(
                                  'planning',
                                  'target_wealth',
                                  {
                                    declinePattern: false,
                                    declinePatternPercent: null,
                                    declinePatternDuration: null,
                                  }
                                )
                              );
                            }
                          }}
                        >
                          No
                        </StyledSpan>
                      </div>
                    )}

                    {isGlobeInvestor(partnerName) && (
                      <Dropdown
                        selection
                        options={[
                          {
                            key: 'yes',
                            value: 'yes',
                            text: 'Yes',
                            onClick: () => {
                              if (declinePattern) {
                                dispatch(
                                  CacheInputs.updateField(
                                    'planning',
                                    'target_wealth',
                                    {
                                      declinePattern: false,
                                      declinePatternPercent: null,
                                      declinePatternDuration: null,
                                    }
                                  )
                                );
                              }
                            },
                          },
                          {
                            key: 'no',
                            value: 'no',
                            text: 'No',
                            onClick: () => {
                              if (!declinePattern) {
                                dispatch(
                                  CacheInputs.updateField(
                                    'planning',
                                    'target_wealth',
                                    {
                                      declinePattern: true,
                                      declinePatternPercent: null,
                                      declinePatternDuration: null,
                                    }
                                  )
                                );
                              }
                            },
                          },
                        ]}
                        name="declinePattern"
                        value={declinePattern ? 'no' : 'yes'}
                        placeholder="Select..."
                        onChange={this.handleChange}
                        style={{ minWidth: 100 }}
                      />
                    )}
                    <ErrorLabel className="top" msg={errors.gender} />
                  </Form.Field>
                  {declinePattern ? (
                    <Form.Field style={{ position: 'relative' }}>
                      <StyledLabel>
                        Assume that your spending needs will drop by
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                                transform: 'translateY(1px)',
                              }}
                            />
                          }
                          position="top center"
                          wide
                          content="For example, drop by 10% every 10 years."
                        />
                      </StyledLabel>
                      <DeclinePatternInput
                        name="declinePatternPercent"
                        placeholder="Enter value..."
                        label={{ basic: true, content: '%' }}
                        labelPosition="right"
                        value={declinePatternPercent || ''}
                        onChange={this.handleDeclinePatternChange}
                        style={{
                          width: '125px !important',
                          marginBottom: isGlobeInvestor(partnerName) && '1rem',
                        }}
                        setLabelWidth
                      />
                      <ErrorLabel
                        className="bottom"
                        msg={errors.declinePatternPercent}
                        style={{ left: '10%' }}
                      />
                      <div
                        style={{
                          display: 'inline-block',
                          margin: '0 7px 0 42px',
                        }}
                      >
                        every
                      </div>
                      <DeclinePatternInput
                        name="declinePatternDuration"
                        placeholder="Enter value..."
                        label={{ basic: true, content: 'years' }}
                        labelPosition="right"
                        value={declinePatternDuration || ''}
                        onChange={this.handleDeclinePatternChange}
                      />
                      <ErrorLabel
                        className="bottom"
                        msg={errors.declinePatternDuration}
                        style={{ left: '40%' }}
                      />
                    </Form.Field>
                  ) : null}
                </StyledFormGroup>
              </Section>
            </Form>
            <Section globe={isGlobeInvestor(partnerName)}>
              <div className="heading">Select a Model Portfolio</div>
              <div
                style={{
                  textAlign: 'center',
                  maxWidth: '45rem',
                  margin: '0 auto',
                  color: 'rgba(0,0,0,0.6)',
                }}
              >
                {isGlobeInvestor(partnerName)
                  ? 'This is your post-retirement asset allocation. We assume that you withdraw funds at the beginning of each year for spending, and the rest stays invested in the portfolio. Equity is half Canadian and half U.S. large-cap equity; bonds are Canadian universe bonds.'
                  : user_region === 'CA'
                  ? 'This is the portfolio for your post-retirement savings. We assume that you withdraw funds at the beginning of each year for spending, and the rest stays invested in this portfolio. Details of the Wealthscope (WS) model portfolios can be found in the "Portfolios" section.'
                  : 'This is your post-retirement asset allocation. We assume that you withdraw funds at the beginning of each year for spending, and the rest stays invested in the portfolio. There are five simple models below, using a combination of S&P 500 and U.S. Aggregate Bonds ETFs.'}
              </div>
              <Divider />
              <div style={{ position: 'relative' }}>
                <PortfoliosList
                  portfolios={portfolios}
                  portfolioID={portfolioID ? portfolioID.toString() : null}
                  annuityRate={annuityRate}
                  handlePortfolioChange={this.handlePortfolioChange}
                  handleRadioChange={this.handleRadioChange}
                  modelType={modelType || ''}
                  style={{ margin: 'initial' }}
                  user_region={user_region}
                  partnerName={partnerName}
                />
                <ErrorLabel
                  className="bottom"
                  msg={errors.portfolioID}
                  style={{ top: '130%' }}
                />
              </div>

              <Divider />

              <Button
                className={cn('right centered', {
                  loading: isFetching,
                  disabled: result && !this.state.isChanged,
                })}
                color={colors.orange}
                icon="calculator"
                onClick={this.handleSubmit}
              >
                Calculate
              </Button>
            </Section>
          </div>
        </Segment>
        <Result globe={isGlobeInvestor(partnerName)} />
      </div>
    );
  }
}

TargetWealth.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  targetWealthInputs: PropTypes.object,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  result: PropTypes.object,
};

TargetWealth.defaultProps = {
  portfolios: [],
  targetWealthInputs: {},
  user_region: 'CA',
  partnerName: '',
  result: null,
};

export default withRouter(
  connect((state) => ({
    isFetching: state.Planning.isFetching,
    portfolios: state.Portfolio.model,
    targetWealthInputs: state.CacheInputs.planning.target_wealth,
    user_region: state.Storage.user_region || 'CA',
    partnerName: state.Storage['partner-name'],
    result: state.Planning.wealthResult,
  }))(TargetWealth)
);

const StyledFormGroup = styled(Form.Group)`
  width: 50%;
  display: inline-block !important;
  padding: 0 3rem;
  vertical-align: top;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  text-align: left;
`;

const StyledSpan = styled.span`
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: ${(props) => (props.active ? colors.teal : 'white')};
  border: ${(props) =>
    props.active ? '1px solid transparent' : '1px solid #dcdcdc'};
  user-select: none;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    border: 1px solid ${colors.teal};
  }
`;

const DeclinePatternInput = styled(({ children, className, ...rest }) => (
  <Input className={className} {...rest}>
    {children}
  </Input>
))`
  height: 25px !important;
  width: 120px !important;

  .label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 25px;
    width: ${(props) => (props.setLabelWidth ? '35px' : 'auto')};
  }
`;

const Section = styled.section`
  border: ${(props) => (props.globe ? '1px solid rgba(0, 0, 0, 0.2)' : 'none')};
  padding: ${(props) => (props.globe ? '2rem' : '0')};
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: ${(props) => (props.globe ? '2rem auto' : '0')};
  line-height: 1.5rem;
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
      padding-right: 3rem;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
