import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'components';

export default class Holdings extends Component {
  render() {
    const { data } = this.props;
    const filtered = Object.keys(data.top_holdings)
      .sort((a, b) => data.top_holdings[b] - data.top_holdings[a])
      .reduce((total, key) => {
        total[key] = data.top_holdings[key];

        return total;
      }, {});

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <p
            style={{
              fontSize: '1rem',
              color: 'rgba(0, 0, 0, 0.6)',
              marginTop: '3rem',
              textAlign: 'center',
            }}
          >
            For funds with a fund-of-funds (FOF) structure, we may show the top
            10 holdings of the underlying funds.
          </p>
        </div>
        <Table textAlign="center" style={{ margin: '1rem 0 0 0' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Security name</Table.HeaderCell>
              <Table.HeaderCell>Weight</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(filtered).map((key) => (
              <Table.Row key={key}>
                <Table.Cell>{key}</Table.Cell>
                <Table.Cell>{data.top_holdings[key]}%</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

Holdings.propTypes = {
  data: PropTypes.object.isRequired,
};
