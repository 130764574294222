import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup, Icon, Header } from 'semantic-ui-react';
import { Portfolio, Storage } from 'actions';
import { RiskModal, Segment, Card, Loading } from 'components';
import { colors, darker, dark } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';

class OpenModel extends Component {
  componentWillMount = () => {
    const { dispatch, portfolios, user_region } = this.props;
    dispatch(Storage.setItem('toggle_region', user_region)); // Set toggle_region to user_region to avoid interference with Accounts
    if (!portfolios.length) {
      return dispatch(Portfolio.fetchModel());
    }

    return false;
  };

  getLastUpdateDate = (portfolios) => {
    if (!portfolios || !portfolios.length) return '';

    const lastDateTimestamp = portfolios.reduce((result, portfolio) => {
      const time = new Date(portfolio.updated_on).getTime();

      return time > result ? time : result;
    }, Number.MIN_SAFE_INTEGER);
    const monthName = {
      1: 'Jan.',
      2: 'Feb.',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'Aug.',
      9: 'Sept.',
      '01': 'Jan.',
      '02': 'Feb.',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'Aug.',
      '09': 'Sept.',
      10: 'Oct.',
      11: 'Nov.',
      12: 'Dec.',
    };
    const lastDate = new Date(lastDateTimestamp);

    return `Last update: ${
      monthName[lastDate.getMonth() + 1]
    } ${lastDate.getFullYear()}.`;
  };

  handleOnClick = (portfolio) => {
    const { dispatch } = this.props;

    return dispatch(
      Storage.setItem(
        'pwpa-portfolio',
        JSON.stringify({
          name: portfolio.name,
          description: portfolio.description,
          portfolio_type:
            portfolio.portfolio_type === 'custom' ? 'custom' : 'unknown',
          region: 'CA',
          symbols: portfolio.symbols,
          weights: portfolio.weights,
          ...(portfolio.is_linked && { is_linked: portfolio.is_linked }),
        })
      )
    );
  };

  render() {
    const { dispatch, portfolios, user_region, partnerName } = this.props;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };
    const removeWsForUsVersion = (name) => {
      if (user_region === 'US' || isGlobeInvestor(partnerName)) {
        const removeWs = name.split(' ');
        removeWs.shift();
        return removeWs.join(' ');
      }
      return name;
    };

    return (
      <Segment>
        <Header size="1.5rem" color="black">
          {user_region === 'US'
            ? 'Benchmark Portfolios'
            : 'Wealthscope model portfolios'}
          <Header.Subheader style={{ color: dark.grey }}>
            <div style={{ maxWidth: '45rem' }}>
              {user_region === 'US'
                ? 'These are low-cost, well-diversified ETF portfolios that track simple benchmarks for five different risk profiles, for use across the platform for comparing portfolios and for simulations in the Planning segment.'
                : 'Using our proprietary algorithms, we have created five low cost, well-diversified, and downside-risk-optimized ETF portfolios.'}
              {user_region === 'CA' ? (
                <React.Fragment>
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="right center"
                    wide
                    content="These model portfolios are categorized into traditional investor risk profiles and have been designed to accommodate a variety of investment objectives ranging from the desire for maximum growth to the need for relative safety."
                  />
                  The model portfolios are updated twice a year. Please note
                  that Wealthscope is not recommending any of these portfolios
                  for investment. They are provided solely for analysis on the
                  Wealthscope platform.
                </React.Fragment>
              ) : null}
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>
                {this.getLastUpdateDate(portfolios)}
              </span>
            </div>
          </Header.Subheader>
        </Header>
        {!portfolios.length ? (
          <Loading active />
        ) : (
          portfolios.map((portfolio) => (
            <Card
              to={`/portfolio/${portfolio.id}/overview`}
              color={colors.teal}
              hoverside={darker.teal}
              key={portfolio.id}
              width="50%"
              header={removeWsForUsVersion(portfolio.name)}
              content={
                portfolio.description ||
                'This portfolio does not have a description.'
              }
              icon={icons[portfolio.name]}
              model
              onClick={() => {
                this.handleOnClick(portfolio);
                dispatch(Portfolio.notSaveUrlSc());
              }}
            />
          ))
        )}

        <RiskProfile>
          <div className="wrapper">
            <Icon name="comments" />
            Not sure about your current risk profile? <br />
            Try our <RiskModal target="Risk Profile Questionnaire" />.
          </div>
        </RiskProfile>
      </Segment>
    );
  }
}

OpenModel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array.isRequired,
  user_region: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
};

export default connect((state) => ({
  portfolios: state.Portfolio.model,
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
}))(OpenModel);

const RiskProfile = styled.div`
  display: inline-block;
  text-align: center;
  width: 45%;
  height: 10rem;
  position: relative;

  .wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -20px;
  }

  .icon {
    font-size: 1.3rem;
    margin: 0 1rem 0 0;
  }

  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`;
