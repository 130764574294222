import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Statistic, Icon, Header, Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import ReactExport from 'react-data-export';
import { Message, Segment } from 'components';
import { colors } from 'utils/colors';
import FullScreenModal from './FullScreenModal';
import BeforeTaxPie from './BeforeTaxPie';
import AfterTaxPie from './AfterTaxPie';
import InvestmentAccountLine from './InvestmentAccountLine';
import PensionIncomeBar from './PensionIncomeBar';
import InvestmentIncomeBar from './InvestmentIncomeBar';
import TaxesBar from './TaxesBar';
import SaveModal from './SaveModal';

const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

@withRouter
@connect((state) => ({
  drawdown: state.Planning.drawdown,
}))
export default class Result extends Component {
  state = {
    isDetailsOpen: false,
    isTableOpen: false,
    fullscreen: false,
  };

  formatNum = (num) => {
    if (!num) {
      return '$0';
    }
    const options = {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    const format = new Intl.NumberFormat('en-US', options);

    return `$${format.format(num)}`;
  };

  render() {
    const { isDetailsOpen, isTableOpen, fullscreen } = this.state;
    const { drawdown, match, displayScenario } = this.props;
    const { result, name, description, isFetching } = drawdown;

    if (!result || !result.yearlyDetails) {
      return null;
    }

    const ages = Object.entries(result.yearlyDetails).map(([age, details]) => ({
      age: Number(age),
      ...details,
    }));

    const prefiltered = ages.map((entry) => entry.age);
    const filtered = [
      ...prefiltered.slice(0, 5),
      ...prefiltered.slice(5).filter((age, index) => index % 5 === 0),
    ];

    if (typeof result.error === 'string') {
      return (
        <Segment margin="1rem" textAlign="center">
          <Message icon visible error>
            <Icon name="exclamation triangle" style={{ fontSize: '2rem' }} />
            <Message.Content>
              Sorry, no optimal solution was found for the parameters you
              entered. The issue is likely due to <br />
              i) abnormal portfolio values for your RRIF and taxable accounts,
              or <br />
              ii) your "Annual Other Taxable Income" being too large and having
              too short a duration.
            </Message.Content>
          </Message>
        </Segment>
      );
    }

    return (
      <Segment
        margin="1rem"
        textAlign="center"
        style={{ opacity: isFetching ? 0.2 : 1 }}
      >
        <Header
          size="large"
          content={name || 'Your Drawdown Plan'}
          subheader={description || ''}
        />
        {displayScenario ? (
          displayScenario === 'median' ? (
            <P style={{ color: colors.blue, marginBottom: '1rem' }}>
              Median Outcome
            </P>
          ) : (
            <P style={{ color: colors.blue, marginBottom: '1rem' }}>
              Sequence Of Returns Risk Outcome
            </P>
          )
        ) : null}
        <P>How much can I afford to spend each year?</P>
        <P margin>
          Knowing how much retirement income you will receive is just a start.
          You need to know how much you should withdraw from your savings each
          year and understand the tax consequences of your withdrawal decision.
          In the results below, we show the plan that will maximize your
          after-tax income, while keeping a smooth consumption path in real
          terms.
        </P>

        <Flex>
          <div style={{ width: '100%', maxWidth: 320, marginTop: '2rem' }}>
            <Header
              size="small"
              content="What you can afford to spend per year"
              subheader="After tax, in today's dollars"
            />
            <Flex className="circle blue">
            {this.formatNum(
                result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                  .incPlot
              )}
            </Flex>
          </div>
          <div style={{ width: '100%', maxWidth: 320, marginTop: '2rem' }}>
            <Header
              size="small"
              content="Total tax implications over the planning horizon"
              subheader="In today's dollars"
            />
            <Flex className="circle orange">
              {this.formatNum(result.taxPV)}
            </Flex>
          </div>
        </Flex>

        <Section
          open={isDetailsOpen}
          height={5000}
          color="blue"
          center
          style={{ marginBottom: '3rem' }}
        >
          <Header
            size="medium"
            content="Plan details"
            style={{
              position: 'absolute',
              top: isDetailsOpen ? '-12px' : '0',
              left: 15,
              background: 'white',
              padding: '0 5px',
              margin: 0,
              transition: 'all 300ms ease',
            }}
          >
            <StyledButton
              icon
              onClick={() => this.setState({ isDetailsOpen: !isDetailsOpen })}
              isOpen={isDetailsOpen}
            >
              {isDetailsOpen ? '-' : '+'}
            </StyledButton>
            Plan Details
          </Header>

          <div
            style={{
              opacity: isDetailsOpen ? 1 : 0,
              visibility: isDetailsOpen ? 'visible' : 'hidden',
              transition: `all 300ms ease ${isDetailsOpen ? '200ms' : '0ms'}`,
            }}
          >
            <Flex>
              <div className="flex-item" style={{ height: 370 }}>
                <Header
                  size="small"
                  content={`Income sources at age ${filtered[0]}`}
                  subheader="before-tax"
                  style={{ marginTop: 0 }}
                />
                <BeforeTaxPie
                  data={
                    result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                  }
                />
              </div>
              <div className="flex-item" style={{ height: 326 }}>
                <Header
                  size="small"
                  content={`Income and taxes at age ${filtered[0]}`}
                  subheader="after-tax"
                  style={{ marginTop: 0 }}
                />
                <AfterTaxPie
                  data={
                    result.yearlyDetails[Object.keys(result.yearlyDetails)[0]]
                  }
                />
              </div>
            </Flex>

            <Header
              size="small"
              content="Pension income over the planning horizon"
              subheader="before-tax"
            />
            <PensionIncomeBar data={result.yearlyDetails} />

            <Header
              size="small"
              content="Investment income over the planning horizon"
              subheader="before-tax"
            />
            <InvestmentIncomeBar data={result.yearlyDetails} />

            <Header size="small" content="Taxes over the planning horizon" />
            <TaxesBar data={result.yearlyDetails} />

            <Header
              size="small"
              content="Projection of investment account balances"
            />
            <InvestmentAccountLine data={result.yearlyDetails} />
          </div>
        </Section>

        <Section color="blue" open={isTableOpen} height={1500}>
          <Header
            size="medium"
            style={{
              position: 'absolute',
              top: isTableOpen ? '-12px' : '0',
              left: 15,
              background: 'white',
              padding: '0 5px',
              margin: 0,
              transition: 'all 300ms ease',
            }}
          >
            <StyledButton
              icon
              onClick={() => this.setState({ isTableOpen: !isTableOpen })}
              isOpen={isTableOpen}
            >
              {isTableOpen ? '-' : '+'}
            </StyledButton>
            Year by year details
          </Header>
          { drawdown ? (
            <ExcelFile
              element={
                <CSVDownload
                  open={this.state.isDetailsOpen}
                  style={{ top: isTableOpen ? '-12px' : '3px' }}
                >
                  Download CSV
                </CSVDownload>
              }
              filename="Optimal Drawdown with Target Spending Details"
            >
              <ExcelSheet data={ages} name="Optimal Drawdown Details">
                <ExcelColumn label="Age" value="age" />
                <ExcelColumn label="Gross Spending" value="incTotal" />
                <ExcelColumn label="Net Spending" value="incNet" />
                <ExcelColumn label="Real Net Spending" value="incPlot" />
                <ExcelColumn label="CPP/QPP Income" value="incCPP" />
                <ExcelColumn label="OAS Income" value="incOAS" />
                <ExcelColumn label="GIS Income" value="incGIS" />
                <ExcelColumn
                  label="Employment Pension Income"
                  value="incPension"
                />
                <ExcelColumn label="Other Income" value="incOther" />
                <ExcelColumn
                  label="Minimum RRIF Withdrawals"
                  value="wdReqMin"
                />
                <ExcelColumn
                  label="Non-registered Withdrawals"
                  value="wdRegular"
                />
                <ExcelColumn label="RRIF Withdrawals" value="wdRRIF" />
                <ExcelColumn label="TFSA Withdrawals" value="wdTFSA" />
                <ExcelColumn label="Dividend Withdrawals" value="incDividend" />
                <ExcelColumn label="Taxes" value="taxBoth" />
                <ExcelColumn label="OAS Clawback" value="taxOAS" />
              </ExcelSheet>
            </ExcelFile>
          ) : null}

          <div
            style={{
              opacity: isTableOpen ? 1 : 0,
              visibility: isTableOpen ? 'visible' : 'hidden',
              transition: `all 300ms ease ${isTableOpen ? '200ms' : '0ms'}`,
            }}
          >
            <P style={{ margin: '0 0 2.5rem 0' }}>
              All figures in the table are nominal, reflecting future values,
              except <strong>real spending</strong>.
              <br />
              Click{' '}
              <FSIcon onClick={() => this.setState({ fullscreen: true })}>
                here
              </FSIcon>{' '}
              to view table in full screen.
            </P>
            <div style={{ position: 'relative' }}>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <th />
                      {filtered.map((key) => (
                        <th key={key}>{key}</th>
                      ))}
                    </tr>
                  </thead>

                  <Header size="medium" content="Spending" textAlign="left" />
                  <tbody>
                    <tr>
                      <td className="title">Gross spending</td>
                      {filtered.map((key) => (
                        <td key={key}>
                        {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].incTotal !== undefined &&
                            this.formatNum(result.yearlyDetails[key].incTotal)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Net spending</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].incNet !== undefined &&
                            this.formatNum(result.yearlyDetails[key].incNet)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Real net spending</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].incPlot !== undefined &&
                            this.formatNum(result.yearlyDetails[key].incPlot)}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Retirement income"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">CPP/QPP</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incCPP !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incCPP)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">OAS</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incOAS !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incOAS)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">GIS</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incGIS !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incGIS)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Employment pension</td>
                      {filtered.map((key) => (
                        <td key={key}>
                        {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incPension !== undefined
                            ? this.formatNum(
                                result.yearlyDetails[key].incPension
                              )
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Other</td>
                      {filtered.map((key) => (
                        <td key={key}>
                        {result.yearlyDetails[key] &&
                          result.yearlyDetails[key].incOther !== undefined
                            ? this.formatNum(result.yearlyDetails[key].incOther)
                            : 'N/A'}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Investments"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">Minimum RRIF</td>
                      {filtered.map((key) => (
                        <td key={key}>
                        {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdReqMin)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">Non-registered</td>
                      {filtered.map((key) => (
                        <td key={key}>
                        {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdRegular)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">RRIF</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdRRIF)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">TFSA</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].wdTFSA)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">
                        Dividends
                        <Popup
                          trigger={<Icon name="question circle outline" />}
                          position="top center"
                          content="From non-registered accounts."
                          wide
                        />
                      </td>
                      {filtered.map((key) => (
                        <td key={key}>
                        {result.yearlyDetails[key] &&
                            this.formatNum(
                              result.yearlyDetails[key].incDividend
                            )}
                        </td>
                      ))}
                    </tr>
                  </tbody>

                  <Header
                    size="medium"
                    content="Taxes"
                    textAlign="left"
                    style={{ margin: '25px 0 14px 0' }}
                  />
                  <tbody>
                    <tr>
                      <td className="title">Taxes</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            this.formatNum(result.yearlyDetails[key].taxBoth)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="title">OAS clawback</td>
                      {filtered.map((key) => (
                        <td key={key}>
                          {result.yearlyDetails[key] &&
                            result.yearlyDetails[key].taxOAS &&
                            this.formatNum(result.yearlyDetails[key].taxOAS)}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </TableWrapper>
            </div>

            <div style={{ textAlign: 'center', margin: '1rem 0' }}>
              <Statistic size="tiny">
                <Statistic.Label>
                  Future value of bequest
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  />
                </Statistic.Label>
                <Statistic.Value>
                  {this.formatNum(result.terminalValue)}
                </Statistic.Value>
              </Statistic>
            </div>
          </div>
        </Section>

        <SaveModal
          name={name}
          description={description}
          id={match.params.id}
          drawdown={drawdown}
        />

        <FullScreenModal
          data={result.yearlyDetails}
          filtered={filtered}
          open={fullscreen}
          closeFullscreen={() => this.setState({ fullscreen: false })}
        />
      </Segment>
    );
  }
}

Result.propTypes = {
  drawdown: PropTypes.object,
  match: PropTypes.object,
  displayScenario: PropTypes.string,
};

Result.defaultProps = {
  drawdown: {},
  match: {},
  displayScenario: '',
};

const Section = styled.div`
  position: relative;
  max-width: ${(props) => (props.single ? '45rem' : '100%')};
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  padding: 0 1rem;
  padding-top: ${(props) => (props.open ? '2rem' : '2rem')};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin-bottom: 2rem;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? `${props.height}px` : '31px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const Table = styled.table`
  text-align: center;
  padding: 0;
  width: 100%;

  tr {
    width: 100%;
  }

  td,
  th {
    border: 1px solid #eaecef;
    padding: 6px 12px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      color: rgba(0, 0, 0, 0.8);
      width: 168px;
    }

    &:last-child {
      border-right: 0;
    }

    &.title {
      font-weight: 700;
      min-width: 12rem;
      text-align: left;
    }

    &.short {
      width: 15%;
      max-width: 11rem;
      min-width: 7rem;
    }

    &.text {
      font-size: 0.9rem;
    }
  }

  th {
    background: #fafbfc;

    &:first-child {
      background: white;
      border-top: 0;
    }
  }

  &.fixed {
    display: block;
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 168px;
    background: white;
    padding-top: 79px;

    td {
      border-right: 1px solid #eaecef;
    }
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: scroll;
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: ${(props) => (props.margin ? '1rem auto' : '0 auto')};
`;

const FSIcon = styled.span`
  cursor: pointer;
  color: #2185d0;
  font-weight: bold;
`;

const Flex = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-around;
  text-align: center;
  margin: 2rem 0;
  flex-wrap: wrap;

  &.margin {
    background: lightgreen;
  }

  &.circle {
    align-items: center;
    justify-content: space-around;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 2rem;

    &.blue {
      background: ${colors.blue};
    }

    &.orange {
      background: ${colors.orange};
    }
  }

  .flex-item {
    width: 45%;
    min-width: 300px;
    min-height: 250px;
    padding-bottom: 5rem;
  }
`;

const CSVDownload = styled.div`
  position: absolute;
  right: 10px;
  background: #4f4f4f;
  color: white;
  padding: 1px 10px 3px 10px;
  cursor: pointer;
  transition: all 300ms ease;
  border-radius: 11px;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;
