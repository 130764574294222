import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';

import {
  Accounts,
  CompareAccount,
  CustomAccount,
  Portfolio,
  RebalanceAccount,
  Storage,
} from 'actions';
import { notWealthica } from 'utils/helpers';
import { Radio, Label, Popup, Icon } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { TOOLS_STOCK_EXPOSURE } from 'utils/features';
import { Button, Card, Segment, Loading, Feature } from '../../components';

@connect((state) => ({
  accounts: state.Accounts.accountList,
  isFetching: state.Accounts.isFetching,
  selectedAccountList: state.Accounts.selectedAccountList,
  partnerName: state.Storage['partner-name'],
  overview: state.Accounts.overview,
  exchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region,
  ui_exposure_checked: state.Storage.ui_exposure_checked,
  french: state.Storage.language === 'fr',
}))
export default class AccountsList extends Component {
  state = {
    multipleSelect: !!this.props.selectedAccountList.length,
    invalidSubmission: false,
    reminderIsOpen: false,
  };

  componentWillMount = () => {
    const { accounts, dispatch, ui_exposure_checked } = this.props;

    dispatch(Portfolio.setEditing('', '', ''));
    dispatch(CompareAccount.clearResult());
    dispatch(CompareAccount.removeAll());
    dispatch(RebalanceAccount.removeAll());
    dispatch(CustomAccount.clearCustomAccount());
    if (!ui_exposure_checked) {
      this.setReminderVisibility(true);
    }
    if (!accounts.length) {
      dispatch(Accounts.fetchAll());
    }

    return false;
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (!history.location.pathname.includes('/accounts/')) {
      dispatch(Accounts.toggleMultipleMode());
    }
  }

  setReminderVisibility = (isOpen) => this.setState({ reminderIsOpen: isOpen });

  canCreateAccount = (match, accountList) => {
    const { id } = match.params;
    const { partnerName } = this.props;
    const customAccounts = accountList.filter(
      ({ provider }) => provider === 'wsc'
    );

    // Only check accounts if there isn't a target account.
    // Otherwise, the second step to creating an account could be blocked.
    if (
      (!Number(id) && customAccounts.length >= 10) ||
      partnerName === 'nbdb_sandbox' ||
      partnerName === 'nbdb'
    ) {
      return false;
    }

    return true;
  };

  canUseStockExposure = (accountList) => accountList.length > 0;

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  handleReminderClick = () => {
    const { dispatch } = this.props;
    dispatch(Storage.setItem('ui_exposure_checked', true));
    dispatch(Accounts.checkUiExposure());
    this.setState({ reminderIsOpen: false });
  };

  render() {
    const {
      accounts,
      isFetching,
      history,
      dispatch,
      selectedAccountList,
      match,
      partnerName,
      overview,
      exchangeRate,
      user_region,
      french,
    } = this.props;
    const { multipleSelect, invalidSubmission, reminderIsOpen } = this.state;

    return (
      <Segment style={{ height: '100%', overflow: 'auto' }}>
        <Loading active={isFetching} french={french} />

        <StyledHeader size="1.5rem" color="black">
          {french ? 'Mes comptes' : 'My Accounts'}
          <div>
            {french ? (
              <SubHeader long>
                Notre analyse vous montre les valeurs nutritives de votre ou vos
                portefeuilles{' '}
                <strong style={{ color: 'rgb(110, 110, 110' }}>actuels</strong>.
                Depuis un compte, sélectionnez le bouton Analyser en haut à
                droite. <br /> <br />
                <strong>Notes:</strong> Actuellement, nous disposons de données
                sur les actions/FNB canadiens et américains, les fonds communs
                de placement et les fonds distincts canadiens, ainsi que les
                fonds de CÉIÉ en CAD/USD. Les comptes en USD seront convertis en
                CAD lorsque vous regroupez des comptes libellés en devises
                différentes.
              </SubHeader>
            ) : !notWealthica(partnerName) ? (
              <SubHeader long>
                Our analysis shows you the nutrition facts of your{' '}
                <strong style={{ color: 'rgb(110, 110, 110' }}>current </strong>
                portfolio(s). In an account, click the Analyze button in the top
                right. <br /> <br />
                <strong>Notes:</strong>Currently, we have data for Canadian and
                US stocks/ETFs, Canadian mutual and segregated funds, and
                CAD/USD HISA funds.
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  content={
                    french
                      ? 'Les fonds de CÉIÉ en CAD/USD sont inclus dans l’analyse en utilisant le rendement historique d’un fonds de CÉIÉ en CAD/USD représentatif détenu par une banque.'
                      : 'CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund.'
                  }
                />
                Any other securities will be excluded from the analysis. USD
                accounts will be converted to CAD when you group accounts in
                different currencies.
              </SubHeader>
            ) : (
              <SubHeader long>
                Our analysis shows you the nutrition facts of your{' '}
                <strong style={{ color: 'rgb(110, 110, 110' }}>current </strong>
                portfolio(s). In an account, click the Analyze button in the top
                right. <br /> <br />
                <strong>Notes:</strong> Currently, we have data for Canadian and
                US stocks/ETFs, Canadian mutual and segregated funds, and
                CAD/USD HISA funds.
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
                />
                Any other securities will be excluded from the analysis. USD
                accounts will be converted to CAD when you group accounts in
                different currencies.
              </SubHeader>
            )}
          </div>
        </StyledHeader>
        <Button
          className={cn('left', {
            disabled: !this.canCreateAccount(match, accounts),
          })}
          icon="line chart"
          onClick={() => history.push('/accounts/custom/new')}
          color={colors.red}
          style={{
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            width: '15rem',
          }}
          popup={{
            position: 'left center',
            content: french
              ? 'Enregistre jusqu’à 10 comptes saisis manuellement'
              : 'There is a custom account limit of 10.',
            wide: true,
          }}
          enabledPopup={{
            position: 'left center',
            content: french
              ? 'Enregistre jusqu’à 10 comptes saisis manuellement'
              : 'Save up to 10 manually entered accounts',
            wide: true,
          }}
        >
          {french ? 'Saisie manuelle' : 'Manually Enter'}
        </Button>
        {!notWealthica(partnerName) &&
        partnerName !== 'nbdb_sandbox' &&
        partnerName !== 'nbdb' ? (
          <Feature name={TOOLS_STOCK_EXPOSURE}>
            <Popup
              className="stockPopup"
              onClick={this.handleReminderClick}
              style={{ position: 'static' }}
              trigger={
                <div
                  style={{
                    position: 'absolute',
                    top: '5.5rem',
                    right: '2rem',
                  }}
                >
                  <Button
                    className={cn('left', {
                      disabled: !this.canUseStockExposure(accounts),
                    })}
                    icon="line chart"
                    onClick={() => {
                      this.handleReminderClick();
                      history.push('/accounts/stock-exposure');
                    }}
                    color={colors.blue}
                    popup={{
                      position: 'left center',
                      content:
                        'This tool requires you to have saved accounts in Wealthscope. Please Manually Enter or Link your Accounts to get started.',
                      wide: true,
                    }}
                    style={{ width: '15rem' }}
                  >
                    Stock Exposure Lookup
                  </Button>
                  <span
                    className="notification heartbeat"
                    style={{
                      backgroundColor: `${colors.orange}`,
                      display: `${reminderIsOpen ? 'flex' : 'none'}`,
                    }}
                  >
                    !
                    {/* You can put the notification count or any other content here */}
                  </span>
                </div>
              }
              position="bottom center"
              open={reminderIsOpen}
            >
              <Popup.Header>New Tool!</Popup.Header>
              <Popup.Content>
                <br />
                Use it to discover your exposure to any stock across all of your
                accounts. Be sure to go deeper and unveil the breakdown of your
                direct and indirect (within a fund) holdings.
                <br />
                <br />
                <span
                  style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Got it.
                </span>
              </Popup.Content>
            </Popup>
          </Feature>
        ) : null}

        <div>
          <Paragraph
            style={{ paddingRight: '0.9rem', paddingTop: '0.8rem' }}
            disabled={accounts.length < 2}
          >
            {french
              ? 'Regrouper les comptes aux fins de l’analyse?'
              : 'Group accounts for analysis?'}
          </Paragraph>
          <ToggleWrapper
            onClick={() => {
              if (accounts.length > 1) {
                this.setState({
                  multipleSelect: !multipleSelect,
                  invalidSubmission: false,
                });
                dispatch(Accounts.toggleMultipleMode());
              }
            }}
            groupDisabled={accounts.length < 2}
          >
            {accounts.length < 2 ? (
              <Popup
                hoverable
                trigger={
                  <CustomRadio
                    toggle
                    checked={multipleSelect}
                    onClick={() => {
                      dispatch(Accounts.saveSingleAccInfo(''));
                    }}
                    disabled
                  />
                }
                position="top center"
                content={
                  french
                    ? 'Nous pouvez utiliser cette fonction lorsque vous avez 2 comptes ou plus.'
                    : 'You can use this function when you have 2 or more accounts.'
                }
              />
            ) : (
              <CustomRadio
                toggle
                checked={multipleSelect}
                onClick={() => {
                  dispatch(Accounts.saveSingleAccInfo(''));
                }}
              />
            )}
          </ToggleWrapper>
          <Button
            className="left"
            icon="book"
            color={colors.blue}
            onClick={() => {
              if (selectedAccountList.length >= 2) {
                return history.push('/accounts/multiple');
              }
              return this.setState({ invalidSubmission: true });
            }}
            style={{
              display: `${multipleSelect ? 'inline-block' : 'none'}`,
              transform: 'translate(15px, 3px)',
            }}
          >
            {french ? 'Voir groupe' : 'View Group'}
          </Button>
          <Label
            basic
            color="red"
            pointing="left"
            content={
              french
                ? 'Veuillez sélectionner au moins deux comptes.'
                : 'Please select at least two accounts.'
            }
            style={{
              display: `${invalidSubmission ? 'inline-block' : 'none'}`,
              transform: 'translate(18px, -3px)',
            }}
          />
        </div>

        {!accounts.length ? null : (
          <Card
            to="/accounts/overview"
            color="red"
            hoverside="orange"
            header={french ? "Vue d'ensemble" : 'Overview'}
            content={
              french ? "Vue d'ensemble des comptes" : 'Accounts overview'
            }
            currency={user_region === 'US' ? 'USD' : 'CAD'}
            style={{
              opacity: `${multipleSelect ? '0.4' : '1'}`,
              pointerEvents: `${multipleSelect ? 'none' : 'auto'}`,
            }}
            onClick={() => {
              dispatch(Accounts.saveSingleAccInfo('overview'));
            }}
            balance={this.parseNum(overview.balance)}
          />
        )}
        {!accounts.length ? (
          <div>
            {french
              ? "Vous n'avez aucun compte enregistré."
              : "You don't have any saved accounts"}
          </div>
        ) : (
          accounts.map((acc) => (
            <Card
              to={multipleSelect ? '/accounts/user' : `/accounts/${acc.id}`}
              accoundId={acc.id}
              selectable={multipleSelect}
              selected={selectedAccountList.includes(acc)}
              key={acc.id}
              icon="chart line"
              color="red"
              hoverside="orange"
              header={acc.user_institution}
              content={acc.label}
              warning={acc.rebal_alert || acc.rebal_update}
              rebal={acc.rebal_set}
              balance={this.parseNum(acc.balance)}
              currency={user_region === 'US' ? 'USD' : acc.currency}
              linked={acc.provider !== 'wsc' && !notWealthica(partnerName)}
              isActive={() => {
                if (multipleSelect) {
                  return selectedAccountList.includes(acc);
                }

                return false;
              }}
              onClick={() => {
                dispatch(
                  Accounts.saveSingleAccInfo(
                    acc.id.toString(),
                    acc.user_institution,
                    acc.label
                  )
                );

                if (multipleSelect) {
                  if (selectedAccountList.includes(acc)) {
                    return dispatch(
                      Accounts.deselectMultipleAccount(
                        acc,
                        selectedAccountList,
                        user_region,
                        exchangeRate
                      )
                    );
                  }

                  this.setState({ invalidSubmission: false });
                  return dispatch(
                    Accounts.selectMultipleAccount(
                      acc,
                      selectedAccountList,
                      user_region,
                      exchangeRate
                    )
                  );
                }

                return false;
              }}
            />
          ))
        )}
      </Segment>
    );
  }
}

AccountsList.propTypes = {
  accounts: PropTypes.array,
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  history: PropTypes.object,
  selectedAccountList: PropTypes.array,
  match: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  overview: PropTypes.object,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user_region: PropTypes.string,
  ui_exposure_checked: PropTypes.bool,
  french: PropTypes.bool,
};

AccountsList.defaultProps = {
  accounts: [],
  dispatch: () => false,
  isFetching: false,
  history: {},
  selectedAccountList: [],
  partnerName: '',
  overview: {},
  exchangeRate: 1,
  user_region: 'CA',
  ui_exposure_checked: false,
  french: false,
};

const Paragraph = styled.p`
  color: black;
  opacity: 0.9;
  font-size: 1.14rem;
  max-width: 16rem;
  font-weight: 600;
  display: inline-block;
`;

const ToggleWrapper = styled.div`
  display: inline-block;
  transform: translateY(5px);

  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background: ${(props) =>
      props.groupDisabled ? 'rgba(0, 0, 0, 0.25) !important' : ''};
  }

  .ui.toggle.checkbox input:focus:checked~.box:before,
  .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: ${colors.blue} !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: ${colors.blue} !important;
`;

const CustomRadio = styled(({ className, children, ...rest }) => (
  <Radio className={className} {...rest}>
    {children}
  </Radio>
))`
  label:before {
    background: rgba(0, 0, 0, 0.25) !important;
  }

  label:hover:before {
    background: rgba(0, 0, 0, 0.15) !important;
  }import { isQuoteMedia } from '../../utils/helpers';

`;

const StyledHeader = styled.div`
  margin: 0 0 2rem 0;
  color: ${(props) => props.color || 'inherit'};
  font-size: ${(props) => props.size || '1rem'};
`;

const SubHeader = styled.div`
  color: rgb(149, 149, 149);
  margin-top: 8px;
  font-size: 1.14rem;
  max-width: ${(props) => (props.long ? '47rem' : '39rem')};
`;
