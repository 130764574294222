/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, NavLink, Route, Switch } from 'react-router-dom';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Analytics, Storage, ComparePortfolio } from 'actions';
import SideBar from './SideBar';
import Compare from '../Compare/index';
import ComparePDF from '../Compare/ComparePDF';
import BarCompareWhatIf from '../Compare/BarCompareWhatIf';
import Execute from '../Execute';
import Overview from '../Overview';
import Performance from '../Performance';
import Downside from '../Downside';
import Fees from '../Fees';
import Income from '../Income';
import ESG from '../ESG';
import Diversification from '../Diversification';
import AssetClass from '../Diversification/AssetClass';
import IndividualAsset from '../Diversification/IndividualAsset';
import Region from '../Diversification/Region';
import GlobalSector from '../Diversification/GlobalSector';
import MacroFactor from '../Diversification/MacroFactor';
import EquityStyle from '../Diversification/EquityStyle';

@withRouter
@connect((state) => ({
  analytics: state.Analytics,
  check_history:
    state.Storage['pwpa-check-history'] === undefined
      ? true
      : state.Storage['pwpa-check-history'],
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
  pwpaHasExcludeHolding: state.Storage['pwpa-has-exclude-holding'],
  esg: state.Analytics.esg,
  esgAlert: state.Analytics.esgAlert,
  is_esg_enabled: state.Storage.is_esg_enabled,
  user_region: state.Storage.user_region || 'CA',
  toggle_region: state.Storage.toggle_region || 'CA',
  french: state.Storage.language === 'fr',
  partnerName: state.Storage['partner-name'],
}))
export default class Menu extends Component {
  state = {
    openDiversification: false,
    ableHover: false,
  };

  componentDidMount = () => {
    const { openDiversification } = this.state;
    const { analytics, pwpaHasExcludeHolding, dispatch } = this.props;
    const hasExcludedHolding =
      analytics && analytics.data
        ? analytics.data.tickersOriginal.find((holding) => holding.isExcluded)
        : null;

    if (window.location.pathname.split('/').indexOf('diversification') !== -1) {
      if (!openDiversification) {
        this.setState({ openDiversification: true });
      }
    } else if (openDiversification) {
      this.setState({ openDiversification: false });
    }

    if (pwpaHasExcludeHolding === undefined) {
      dispatch(
        Storage.setItem('pwpa-has-exclude-holding', !!hasExcludedHolding)
      );
      this.setState({ initHasExcludedHolding: !!hasExcludedHolding });
    } else {
      this.setState({ initHasExcludedHolding: pwpaHasExcludeHolding });
    }
  };

  componentDidUpdate = () => {
    const { ableHover } = this.state;

    if (window.location.pathname.split('/').indexOf('diversification') !== -1) {
      if (!ableHover) {
        this.setState({ ableHover: true });
      }
    } else if (ableHover) {
      this.setState({ ableHover: false });
    }
  };

  componentWillUnmount = () => {
    const { history, dispatch } = this.props;
    const portfolioRegex = /^\/portfolio\/.*$/;
    const portLandingRegex = /^\/portfolio\/landing$/;

    if (
      !portfolioRegex.test(history.location.pathname) ||
      portLandingRegex.test(history.location.pathname)
    ) {
      dispatch(Analytics.clearAnalytics());
      dispatch(ComparePortfolio.clearComparison());
      dispatch(Storage.removeItem('pwpa-check-history'));
      dispatch(Storage.removeItem('pwpa-has-exclude-holding'));
    }
  };

  render() {
    const {
      analytics,
      dispatch,
      esg,
      esgAlert,
      id,
      history,
      check_history,
      pwpaPortfolio,
      is_esg_enabled,
      user_region,
      toggle_region,
      french,
      partnerName,
    } = this.props;
    const { openDiversification, ableHover, initHasExcludedHolding } =
      this.state;
    const isEsgEnabled =
      is_esg_enabled &&
      partnerName !== 'nbdb_sandbox' &&
      partnerName !== 'nbdb';

    return (
      <div>
        <Container
          noMargin={history.location.pathname.includes('pdf')}
          onClick={() =>
            openDiversification
              ? this.setState({ openDiversification: false })
              : null
          }
        >
          <SideBar
            portfolio={analytics.data.portfolio}
            history={history}
            id={id}
            hidden={history.location.pathname.includes('pdf')}
          />

          <div
            style={{
              position: 'relative',
              display: `${
                history.location.pathname.includes('pdf') ? 'none' : 'block'
              }`,
            }}
          >
            <Tab is_esg_enabled={isEsgEnabled} to={`/portfolio/${id}/overview`}>
              {french ? 'Aperçu' : 'Overview'}
            </Tab>
            <Tab
              is_esg_enabled={isEsgEnabled}
              to={`/portfolio/${id}/performance`}
            >
              Performance
            </Tab>
            <Tab is_esg_enabled={isEsgEnabled} to={`/portfolio/${id}/downside`}>
              {french ? 'Risque de pertes' : 'Downside'}
            </Tab>
            <Tab is_esg_enabled={isEsgEnabled} to={`/portfolio/${id}/fees`}>
              {french ? 'Frais' : 'Fees'}
            </Tab>
            <Tab is_esg_enabled={isEsgEnabled} to={`/portfolio/${id}/income`}>
              {french ? 'Revenu' : 'Income'}
            </Tab>
            <SubMenu
              open={openDiversification}
              ableHover={ableHover}
              is_esg_enabled={isEsgEnabled}
            >
              <li
                className={cn({
                  active:
                    window.location.pathname
                      .split('/')
                      .indexOf('diversification') !== -1,
                })}
                onClick={() =>
                  openDiversification
                    ? null
                    : this.setState({ openDiversification: true })
                }
              >
                <NavLink to={`/portfolio/${id}/diversification`}>
                  Diversification
                </NavLink>
              </li>
              <li>
                <NavLink to={`/portfolio/${id}/diversification/asset_class`}>
                  {french ? 'Classe d’actifs' : 'Asset Class'}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/portfolio/${id}/diversification/individual_asset`}
                >
                  {french ? 'Individuelle' : 'Individual'}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/portfolio/${id}/diversification/region`}>
                  {french ? 'Région' : 'Region'}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/portfolio/${id}/diversification/global_sector`}>
                  {french ? 'Secteur mondial' : 'Global Stock Sector'}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/portfolio/${id}/diversification/macro_factor`}>
                  {french ? 'Facteur macro' : 'Macro Factor'}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/portfolio/${id}/diversification/equity_style`}>
                  {french ? "Style de l'équité" : 'Equity Style'}
                </NavLink>
              </li>
            </SubMenu>
            {isEsgEnabled && (
              <Tab is_esg_enabled={isEsgEnabled} to={`/portfolio/${id}/esg`}>
                ESG
              </Tab>
            )}
          </div>

          <Switch>
            <Route
              path="/portfolio/:id/overview"
              render={(props) => (
                <Overview
                  dispatch={dispatch}
                  analytics={analytics}
                  benchmark={analytics.benchmark}
                  esg={esg}
                  esgAlert={esgAlert}
                  initHasExcludedHolding={initHasExcludedHolding}
                  id={id}
                  check_history={check_history}
                  pwpaPortfolio={pwpaPortfolio}
                  is_esg_enabled={isEsgEnabled}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/performance"
              render={(props) => (
                <Performance
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  user_region={user_region}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/downside"
              render={(props) => (
                <Downside
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/fees"
              render={(props) => (
                <Fees
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/income"
              render={(props) => (
                <Income
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/portfolio/:id/diversification"
              render={(props) => (
                <Diversification
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/diversification/asset_class"
              render={(props) => (
                <AssetClass
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/diversification/individual_asset"
              render={(props) => (
                <IndividualAsset
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/diversification/region"
              render={(props) => (
                <Region
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/diversification/global_sector"
              render={(props) => (
                <GlobalSector
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  user_region={user_region || 'CA'}
                  toggle_region={toggle_region || 'CA'}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/diversification/macro_factor"
              render={(props) => (
                <MacroFactor
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/diversification/equity_style"
              render={(props) => (
                <EquityStyle
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/esg"
              render={(props) => <ESG {...props} />}
            />
            <Route
              exact
              path="/portfolio/:id/compare"
              render={(props) => (
                <Compare
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  income={analytics.income}
                  data={analytics.data}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/portfolio/:id/compare-replace"
              render={(props) => (
                <BarCompareWhatIf
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  income={analytics.income}
                  data={analytics.data}
                  {...props}
                />
              )}
            />
            <Route
              path="/portfolio/:id/execute"
              render={(props) => (
                <Execute
                  dispatch={dispatch}
                  benchmark={analytics.benchmark}
                  data={analytics.data}
                  objective={analytics.objective}
                  user_region={user_region}
                  {...props}
                />
              )}
            />
          </Switch>
        </Container>
        <Switch>
          <Route
            exact
            path="/portfolio/:id/compare/pdf"
            render={(props) => (
              <ComparePDF
                dispatch={dispatch}
                benchmark={analytics.benchmark}
                income={analytics.income}
                data={analytics.data}
                {...props}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  analytics: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  pwpaHasExcludeHolding: PropTypes.bool,
  check_history: PropTypes.bool,
  pwpaPortfolio: PropTypes.object,
  esg: PropTypes.object,
  esgAlert: PropTypes.bool,
  is_esg_enabled: PropTypes.bool,
  user_region: PropTypes.string,
  toggle_region: PropTypes.string,
  french: PropTypes.string,
  partnerName: PropTypes.string,
};

Menu.defaultProps = {
  analytics: {},
  dispatch: () => false,
  history: {},
  check_history: true,
  pwpaPortfolio: {},
  esg: {},
  esgAlert: false,
  is_esg_enabled: false,
  pwpaHasExcludeHolding: false,
  user_region: 'CA',
  toggle_region: 'CA',
  french: false,
  partnerName: '',
};

const Tab = styled(({ className, children, is_esg_enabled, ...rest }) => (
  <NavLink is_esg_enabled={is_esg_enabled} className={className} {...rest}>
    {children}
  </NavLink>
))`
  display: inline-block;
  padding: 1rem 0;
  width: ${(props) =>
    props.is_esg_enabled ? 'calc(100% / 7)' : 'calc(100% / 6)'};
  font-size: 1rem;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  border-left: 1px solid rgb(236, 236, 240);
  border-bottom: 1px solid rgb(236, 236, 240);

  &:first-child {
    border-left: 0;
    border-top-left-radius: 8px;
    &:after {
      border-top-left-radius: 10px;
    }
  }

  &:last-child {
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 8px;

    &:after {
      border-top-right-radius: 10px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
  }

  &.active {
    font-weight: 700;
    background: rgb(246, 246, 250);

    &:after {
      background: ${colors.red};
    }
  }

  &:hover {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const SubMenu = styled.ul`
  position: absolute;
  top: 0;
  left: ${(props) => (props.is_esg_enabled ? '71.4285%' : 'unset')};
  right: ${(props) => (props.is_esg_enabled ? 'unset' : '0')};
  background: white;
  border-top-right-radius: ${(props) =>
    props.is_esg_enabled ? 'unset' : '8px'};
  width: ${(props) =>
    props.is_esg_enabled ? 'calc(100% / 7)' : 'calc(100% / 6)'};
  height: ${(props) => (props.open ? 'auto' : '48px')};
  overflow: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 2;

  &:hover {
    height: ${(props) => (props.ableHover ? 'auto' : '48px')};

    li:first-child {
      border: ${(props) =>
        props.ableHover
          ? '1px solid black !important'
          : '1px solid rgb(236,236,240)'};
      border-bottom: ${(props) =>
        props.ableHover
          ? '1px solid rgb(236,236,240) !important'
          : '1px solid rgb(236,236,240)'};
      border-top-right-radius: ${(props) =>
        props.ableHover ? '8px !important' : 'unset'};
      border-top: ${(props) =>
        props.ableHover ? 'transparent !important' : 'unset'};
      border-right: 1px solid white;
    }
  }

  li:first-child {
    border: ${(props) =>
      props.open ? '1px solid black !important' : 'inherit'};
    border-bottom: ${(props) =>
      props.open
        ? '1px solid rgb(236,236,240) !important'
        : '1px solid rgb(236,236,240)'};
    border-top-right-radius: ${(props) =>
      props.open ? '8px !important' : 'unset'};
    border-top: ${(props) => (props.open ? 'transparent !important' : 'unset')};
  }

  li {
    padding: 1rem 0;
    width: 100%;
    height: 48px;
    font-size: 1rem;
    position: relative;
    text-align: center;
    border-left: 1px solid black;
    border-bottom: 1px solid rgb(236, 236, 240);
    border-right: 1px solid black;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
    }

    &.active:after {
      background: ${colors.red};
    }

    &:first-child {
      border-left: 1px solid rgb(236, 236, 240);
    }

    &:last-child {
      border-bottom: 1px solid black;
    }

    &:hover {
      font-weight: 700;
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: rgba(0, 0, 0, 0.8) !important;
      line-height: 48px;

      &.active {
        font-weight: 700;
        background: rgb(246, 246, 250);
      }
    }
  }
`;

const Container = styled.div`
  height: 100%;
  min-width: 670px;
  border-radius: 8px;
  background: white;
  margin: ${(props) => (props.noMargin ? '0' : '1rem 1rem 1rem 6rem')};
  overflow-y: hidden;
`;
