import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { List, Radio, Header, Progress, Popup } from 'semantic-ui-react';

import { Loading } from 'components';
import { Portfolio } from 'actions';
import { colors } from 'utils/colors';

class PortfolioList extends Component {
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    if (!portfolios.length) {
      dispatch(Portfolio.fetchModel());
    }

    return false;
  };

  selectPortfolio = (portfolio) => {
    const map = {
      'WS All-In': {
        percent: 100,
        label: '100% Equities / 0% Bonds',
        icon: 'lightning',
        value: 'allin',
        percentEquity: 100,
        message: () => (
          <div>
            Your portfolio is <b>all-in</b> because it is invested 100% in
            stocks and alternative investments. Offering the possibility of
            higher returns, but with a higher amount of risk.
          </div>
        ),
      },
      'WS Adventurous': {
        percent: 80,
        label: '80% Equities / 20% Bonds',
        icon: 'map signs',
        value: 'adventurous',
        message: () => (
          <div>
            Your portfolio is <b>adventurous</b> because it is invested 80% in
            higher risk stocks and alternative investments and 20% in less risky
            bonds.
          </div>
        ),
      },
      'WS Classic': {
        percent: 60,
        label: '60% Equities / 40% Bonds',
        icon: 'leaf',
        value: 'classic',
        message: () => (
          <div>
            Your portfolio is <b>classic</b> because it balances the risk of
            being 60% invested in riskier stocks and alternative investments,
            with 40% in less risky bonds.
          </div>
        ),
      },
      'WS Cautious': {
        percent: 40,
        label: '40% Equities / 60% Bonds',
        icon: 'cloud',
        value: 'cautious',
        message: () => (
          <div>
            Your portfolio is <b>cautious</b> because it is invested 60% in
            lower risk bonds and 40% in riskier stocks and alternative
            investments.
          </div>
        ),
      },
      'WS Playing-It-Safe': {
        percent: 20,
        label: '20% Equities / 80% Bonds',
        icon: 'anchor',
        value: 'safe',
        message: () => (
          <div>
            Your portfolio is <b>playing it safe</b> because it is invested 80%
            in lower risk bonds, with 20% in riskier stocks and alternative
            investments.
          </div>
        ),
      },
    };

    return map[portfolio];
  };

  render() {
    const { portfolios, handlePortfolioChange, risk } = this.props;
    return !portfolios.length ? (
      <Loading active />
    ) : (
      <StyledList celled divided>
        {portfolios.map((port) => (
          <Popup
            trigger={
              <List.Item
                style={{
                  background:
                    risk === this.selectPortfolio(port.name).value
                      ? 'rgb(252,255,245)'
                      : 'transparent',
                }}
                onClick={(e) =>
                  handlePortfolioChange(e, {
                    value: this.selectPortfolio(port.name).value,
                  })
                }
              >
                <Radio
                  name="risk"
                  checked={risk === this.selectPortfolio(port.name).value}
                />
                <List.Content>
                  <Header
                    as="h5"
                    icon={this.selectPortfolio(port.name).icon}
                    content={port.name.substr(3)}
                  />
                  <StyledProgress
                    percent={this.selectPortfolio(port.name).percent}
                    progress
                    color="blue"
                    inverted
                  />
                </List.Content>
              </List.Item>
            }
            position="top center"
            key={port.id}
            content={this.selectPortfolio(port.name).message()}
          />
        ))}
      </StyledList>
    );
  }
}

export default connect((state) => ({
  portfolios: state.Portfolio.model,
}))(PortfolioList);

PortfolioList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  handlePortfolioChange: PropTypes.func.isRequired,
  risk: PropTypes.string,
};

PortfolioList.defaultProps = {
  portfolios: [],
  risk: '',
};

const StyledList = styled(({ className, children, ...rest }) => (
  <List className={className} {...rest}>
    {children}
  </List>
))`
  display: block;
  width: 70%;
  max-width: 540px;
  margin: 1.5rem auto !important;

  .item {
    position: relative;
    text-align: center;
    cursor: pointer;

    .radio {
      label::before {
        border-color: rgba(0, 0, 0, 0.4);
      }
    }

    .progress {
      margin-top: 7px;

      &:last-child {
        margin: 1em 0 2.5em;
      }
    }

    .checkbox {
      position: absolute;
      top: 58%;
      transform: translateY(-50%);
      left: 0;
    }

    .header {
      text-align: center;

      .icon,
      .content {
        display: initial;
      }
    }

    .content {
      width: 100%;
      margin: -5px 0 0 15px;
      padding-right: 8px !important;

      .header {
        padding-top: 1rem;
      }
    }

    .label {
      margin-top: 8px !important;
      color: rgba(0, 0, 0, 0.4);
    }

    &:last-child {
      border-bottom: 0 !important;
    }
  }
`;

const StyledProgress = styled(({ className, children, ...rest }) => (
  <Progress className={className} {...rest}>
    {children}
  </Progress>
))`
  margin-bottom: 25px !important;
  background-color: red;
  background-color: ${colors.periwinkle} !important;
  height: 17px;

  color: white !important;
  border-radius: 50px !important;
  .bar {
    background-color: ${colors.navyBlue} !important;
    height: 17px !important;

    border-radius: 50px !important;
    border-top-right-radius: ${(props) =>
      props.percent >= 100 ? '50px' : '0'} !important;
    border-bottom-right-radius: ${(props) =>
      props.percent >= 100 ? '50px' : '0'} !important;
    .progress {
      right: 2.3em !important;

      padding-right: ${(props) => (props.percent >= 100 ? '50px' : '42px')};
    }

    .progress::after {
      content: '${(props) =>
        props.percent >= 100
          ? ` ${props.percent}% Equity`
          : ` ${props.percent}% Equity     ${100 - props.percent}% Bonds`}';
      position: absolute;
      white-space: pre;
      top: -19px;
    }
  }
`;
