import { combineReducers } from 'redux';

import {
  Accounts,
  Auth,
  Analytics,
  Alert,
  Api,
  BuildCustom,
  BuildML,
  CacheInputs,
  ChartTool,
  CompareAccount,
  ComparePortfolio,
  CustomAccount,
  BuildETF,
  FundAllocator,
  Portfolio,
  Planning,
  RebalanceAccount,
  Security,
  Storage,
  FundComparison,
  CorrelationMatrix,
  StockExposure,
  Chatbot,
} from './';

const appReducer = combineReducers({
  Accounts,
  Auth,
  Analytics,
  Alert,
  Api,
  BuildETF,
  BuildCustom,
  BuildML,
  CacheInputs,
  ChartTool,
  FundAllocator,
  Portfolio,
  Planning,
  Security,
  CustomAccount,
  ComparePortfolio,
  CompareAccount,
  Storage,
  RebalanceAccount,
  FundComparison,
  CorrelationMatrix,
  StockExposure,
  Chatbot,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
