import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Header,
  Divider,
  Input,
  Statistic,
  Icon,
  Popup,
  Grid,
  Form,
  Button,
} from 'semantic-ui-react';

import { Alert, Api, Planning, Storage } from 'actions';
import { ErrorLabel } from 'components';
import { colors } from 'utils/colors';

class SpendingTools extends Component {
  state = {
    provData: null,
    error: null,
  };

  componentDidMount = () => {
    const { dispatch, isCouplePlan, spendingToolData } = this.props;

    const titleTransfer = {
      'Clothing and accessories': 'Clothing and Accessories',
      Education: 'Education',
      'Food expenditures': 'Food Expenditures',
      'Games of chance': 'Games of Chance',
      'Gifts of money and charitable contributions': 'Gifts and Charity',
      'Health care': 'Health Care',
      'Household furnishings and equipment': 'Household Furnishings',
      'Household operations': 'Household Operations',
      'Income taxes': 'Income Taxes',
      'Insurance payments and pension contributions': 'Insurance and Pension',
      'Miscellaneous expenditures': 'Miscellaneous Expenditures',
      'Personal care': 'Personal Care',
      'Reading materials and other printed matter': 'Reading Materials',
      Recreation: 'Recreation',
      Shelter: 'Shelter',
      'Tobacco products and alcoholic beverages': 'Tobacco and Alcohol',
      Transportation: 'Transportation',
    };

    return dispatch(Api.request('get', '/planning/expenditure/')).then(
      (data) => {
        const { province } = this.props;
        const provData = data.reduce((result, item) => {
          const tempProvince =
            item.province === 'Newfoundland and Labrador'
              ? 'Newfoundland & Labrador'
              : item.province;

          if (item.category_name === 'Total expenditure') {
            return result;
          }

          if (!result[item.type]) {
            result[item.type] = {};
          }

          if (!result[item.type][tempProvince]) {
            result[item.type][tempProvince] = {};
          }

          result[item.type][tempProvince][titleTransfer[item.category_name]] =
            item.value;

          return result;
        }, {});

        this.setState(
          {
            provData,
          },
          () => {
            if (
              !spendingToolData ||
              Object.keys(spendingToolData).length === 0
            ) {
              dispatch(
                Planning.updateBlueprintData('personalDetails', {
                  spendingToolData: {
                    retVal: null,
                    wagePct: null,
                    retVal2: null,
                    wagePct2: null,
                    blunter: this.calcAverage(),
                    manual: null,
                    ...(isCouplePlan && provData && provData.couple[province]),
                    ...(!isCouplePlan && provData && provData.single[province]),
                  },
                })
              );
            }
          }
        );
      },
      (err) =>
        dispatch(
          Alert.show({
            type: 'error',
            msg: err.non_field_errors
              ? err.non_field_errors[0]
              : err.msg || err.detail || 'Server Offline',
          })
        )
    );
  };

  componentDidUpdate = (prevProps) => {
    const prevSpendingToolData = prevProps.spendingToolData;
    const { spendingToolData } = this.props.data.personalDetails;
    const { selectedSpendingTool } = this.props.data.personalDetails;
    if (!prevSpendingToolData || Object.keys(prevSpendingToolData).length === 0)
      return;

    if (
      JSON.stringify(
        prevSpendingToolData,
        Object.keys(spendingToolData).sort()
      ) !==
      JSON.stringify(spendingToolData, Object.keys(spendingToolData).sort())
    ) {
      this.handleSpendingAmount(this.calcSpendingAmount(selectedSpendingTool));
    }
  };

  handlePercentageChange = (e) => {
    const { dispatch, data } = this.props;
    const { spendingToolData, selectedSpendingTool } =
      data.personalDetails || {};

    const { name, value } = e.target;
    const limitRegex = /^[0-9]{1,9}$/;
    if (value !== '' && !limitRegex.test(value)) return;
    if (value === '0' && selectedSpendingTool !== 'personalized') return;
    this.props.clearError(name);

    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        spendingToolData: {
          ...spendingToolData,
          [name]: !value ? null : Number(value) > 100 ? 100 : Number(value),
        },
      })
    );
  };

  scrollHandler = (to, duration) => {
    if (duration <= 0) return false;
    const el = document.scrollingElement || document.documentElement;
    const difference = to - el.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      el.scrollTop += perTick;
      if (el.scrollTop === to) {
        return false;
      }
      return this.scrollHandler(to, duration - 10);
    }, 10);
  };

  scrollToBottom = () => this.scrollHandler(window.screen.height - 230, 600);

  handleSpendingToolChange = (tool) => {
    const { dispatch, cacheInputChanged } = this.props;

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }

    return dispatch(
      Planning.updateBlueprintData('personalDetails', {
        selectedSpendingTool: tool,
      })
    );
  };

  handleSpendingAmount = (spending) => {
    const { dispatch, cacheInputChanged } = this.props;
    this.props.dispatch(
      Planning.updateBlueprintData('personalDetails', {
        spending_amount: Math.round(spending),
      })
    );

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }

    this.setState({
      errors: { ...this.state.errors, spending_amount: null },
    });
  };

  handleMoneyChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const { dispatch, data, cacheInputChanged } = this.props;

    const { spendingToolData, selectedSpendingTool } = data.personalDetails;

    const limitRegex = /^[0-9]{1,9}$/;
    if (value !== '' && !limitRegex.test(value)) return;
    if (value === '0' && selectedSpendingTool !== 'personalized') return;

    this.props.clearError(name);

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }

    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        spendingToolData: {
          ...spendingToolData,
          [name]: !value ? null : Number(value),
        },
      })
    );
  };

  handleKeyPress = (e) => e.key === 'Enter' && e.preventDefault();

  calcSpendingAmount = (spendingTool) => {
    const { isCouplePlan, data } = this.props;
    const { spendingToolData } = data.personalDetails;
    const { retVal, wagePct, retVal2, wagePct2, blunter, manual } =
      spendingToolData;

    if (spendingTool === 'manual') return manual;

    const standardResult = isCouplePlan
      ? Math.round((retVal * wagePct + retVal2 * wagePct2) * 0.01)
      : Math.round(retVal * wagePct * 0.01);

    const average = this.calcAverage();

    const personalizedResult = this.calcPersonalizedResult();

    const bigPictureResult = blunter || blunter === 0 ? blunter : average || 0;

    const to_return =
      spendingTool === 'industryStandard'
        ? standardResult
        : spendingTool === 'bigPicture'
        ? bigPictureResult
        : personalizedResult;

    return to_return;
  };

  handleRadioSectionClick = (toolName) => {
    const { dispatch, cacheInputChanged } = this.props;
    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
      dispatch(Storage.setItem('cachePDChanged', true));
    }
    this.clearInput();
    this.handleSpendingToolChange(toolName);
    this.scrollToBottom();
    this.handleSpendingAmount(this.calcSpendingAmount(toolName));
  };

  clearInput = () => {
    this.setState({
      error: null,
    });
  };

  didUserModifyStartingValues = () => {
    const { data, isCouplePlan, province } = this.props;
    const { spendingToolData } = data.personalDetails;
    const { provData } = this.state;
    if (!provData || !spendingToolData) return false;
    return isCouplePlan
      ? JSON.stringify(
          spendingToolData,
          Object.keys(provData.couple[province]).sort()
        ) !==
          JSON.stringify(
            provData.couple[province],
            Object.keys(provData.couple[province]).sort()
          )
      : JSON.stringify(
          spendingToolData,
          Object.keys(provData.single[province]).sort()
        ) !==
          JSON.stringify(
            provData.single[province],
            Object.keys(provData.single[province]).sort()
          );
  };

  useEstimatesInSpendingTools = () => {
    const { dispatch, data, isCouplePlan, province } = this.props;
    const { provData } = this.state;
    const { spendingToolData } = data.personalDetails;

    if (!this.state.provData) return;

    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        spendingToolData: {
          ...spendingToolData,
          ...(isCouplePlan && provData && provData.couple[province]),
          ...(!isCouplePlan && provData && provData.single[province]),
        },
      })
    );
  };

  calcAverage = () => {
    const { province, isCouplePlan } = this.props;
    const { provData } = this.state;
    return provData
      ? province
        ? isCouplePlan
          ? Object.values(provData.couple[province]).reduce(
              (total, value) => (total += value),
              0
            )
          : Object.values(provData.single[province]).reduce(
              (total, value) => (total += value),
              0
            )
        : null
      : null;
  };

  calcPersonalizedResult = () => {
    const { province, isCouplePlan, data } = this.props;
    const { provData } = this.state;
    let { spendingToolData } = data.personalDetails;
    spendingToolData = spendingToolData || {};
    return provData
      ? province
        ? isCouplePlan
          ? Object.keys(provData.couple[province]).reduce(
              (total, item) => (total += Number(spendingToolData[item])),
              0
            )
          : Object.keys(provData.single[province]).reduce(
              (total, item) => (total += Number(spendingToolData[item])),
              0
            )
        : null
      : null;
  };

  render() {
    const { province, isCouplePlan, spendingTool, data } = this.props;
    const { error } = this.state;
    let { spendingToolData } = data.personalDetails;
    spendingToolData = spendingToolData || {};
    const { retVal, wagePct, retVal2, wagePct2, blunter, manual } =
      spendingToolData;

    const standardResult = isCouplePlan
      ? Math.round((retVal * wagePct + retVal2 * wagePct2) * 0.01)
      : Math.round(retVal * wagePct * 0.01);

    const average = this.calcAverage();

    const personalizedResult = this.calcPersonalizedResult();

    const excluded = /(provData|error)/;

    const personalizedToolColumnValues = [
      [
        'Food Expenditures',
        'Shelter',
        'Household Operations',
        'Household Furnishings',
        'Clothing and Accessories',
        'Transportation',
        'Health Care',
        'Personal Care',
        'Recreation',
      ],
      [
        'Education',
        'Tobacco and Alcohol',
        'Games of Chance',
        'Miscellaneous Expenditures',
        'Income Taxes',
        'Insurance and Pension',
        'Gifts and Charity',
        'Reading Materials',
      ],
    ];

    return (
      <div>
        <RadioSection
          onClick={() => {
            this.handleRadioSectionClick('industryStandard');
          }}
        >
          <input
            type="radio"
            name="spendingTool"
            value="industryStandard"
            checked={spendingTool === 'industryStandard'}
          />
          {isCouplePlan ? (
            <Label>
              <b>Traditional:</b> Based on a percentage of you and your spouse’s
              pre-retirement income. This percentage is typically recommended to
              be 70% or below, depending on the circumstances of your household.
            </Label>
          ) : (
            <Label>
              <b>Traditional:</b> Based on a percentage of your pre-retirement
              income. This percentage is typically recommended to be 70% or
              below, depending on the circumstances of your household.
            </Label>
          )}
        </RadioSection>
        <RadioSection
          onClick={() => {
            this.handleRadioSectionClick('bigPicture');
          }}
        >
          <input
            type="radio"
            name="spendingTool"
            value="bigPicture"
            checked={spendingTool === 'bigPicture'}
          />
          {isCouplePlan ? (
            <Label>
              <b>Big Picture:</b> The big picture is important to us - we are
              looking for a general guide that fits our overall situation. Use
              the provincial average for total annual spending in retirement as
              a benchmark.
            </Label>
          ) : (
            <Label>
              <b>Big Picture:</b> The big picture is important to me - I am
              looking for a general guide that fits my overall situation. Use
              the provincial average for total annual spending in retirement as
              a benchmark.
            </Label>
          )}
        </RadioSection>
        <RadioSection
          onClick={() => {
            this.handleRadioSectionClick('personalized');
          }}
        >
          <input
            type="radio"
            name="spendingTool"
            value="personalized"
            checked={spendingTool === 'personalized'}
          />
          {isCouplePlan ? (
            <Label>
              <b>Personalized:</b> Fine details are important to us. We are
              willing to spend time and effort to look up relevant information.
              Provincial averages for different spending categories are provided
              as a reference point.
            </Label>
          ) : (
            <Label>
              <b>Personalized:</b> Fine details are important to me. I am
              willing to spend time and effort to look up relevant information.
              Provincial averages for different spending categories are provided
              as a reference point.
            </Label>
          )}
        </RadioSection>
        <div
          style={{
            marginTop: '2rem',
            'font-style': 'italic',
          }}
        >
          <strong>OR</strong>
        </div>
        <RadioSection
          onClick={() => {
            this.handleRadioSectionClick('manual');
          }}
        >
          <input
            type="radio"
            name="spendingTool"
            value="manual"
            checked={spendingTool === 'manual'}
          />
          {isCouplePlan ? (
            <Label>
              <b>Manual Entry:</b> We already know the amount and will enter it
              in the input below.
            </Label>
          ) : (
            <Label>
              <b>Manual Entry:</b> I already know the amount and I will enter it
              in the input below.
            </Label>
          )}
        </RadioSection>
        <Divider
          id="radio-tool-divider"
          style={{ margin: '-1rem auto 2rem' }}
        />
        {spendingTool ? (
          <div>
            <div style={{ textAlign: 'center' }}>
              {spendingTool === 'industryStandard' && (
                <div>
                  <Header
                    textAlign="center"
                    size="medium"
                    style={{ fontSize: '1.2rem' }}
                  >
                    On average, you may need approximately 50‑70% of your
                    pre-retirement income in retirement, to live the same
                    lifestyle you&apos;re living today.
                    <Header.Subheader
                      style={{ marginTop: '1rem', fontSize: '1.14rem' }}
                    >
                      The rule of thumb in the industry is 70%. However, if you
                      have significant expenses during your working years, such
                      as children and mortgages, but expect to be relieved of
                      these obligations by the time you retire, you should
                      consider a lower percentage (e.g., 50% or 60%). For
                      further discussion, see our
                      <a
                        href="https://www.wealthscope.ca/retirementtarget"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ padding: '4px' }}
                      >
                        <strong>Retirement Income Target</strong>
                      </a>
                      article.
                    </Header.Subheader>
                  </Header>
                  <div>
                    {isCouplePlan ? (
                      <div
                        style={{
                          margin: '2rem auto',
                          display: 'flex',
                          gap: '2rem',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            margin: '3rem 2rem 0 0',
                            verticalAlign: 'top',
                          }}
                        >
                          <label
                            style={{
                              display: 'block',
                              marginBottom: '2rem',
                              fontSize: '1.14rem',
                            }}
                          >
                            <b>Expected pre-retirement income &nbsp;&nbsp;</b>
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{ color: 'black', fontSize: '15px' }}
                                />
                              }
                              position="left center"
                              content="In today’s dollars"
                            />
                          </label>
                          <label
                            style={{ display: 'block', fontSize: '1.14rem' }}
                          >
                            <b>Retirement spending as a percentage</b>
                          </label>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '1.14rem',
                              color: colors.blue,
                              textAlign: 'center',
                              marginBottom: '1rem',
                              paddingRight: '4rem',
                            }}
                          >
                            You
                          </div>
                          <div
                            style={{
                              marginRight: '2rem',
                              paddingRight: '2rem',
                              borderRight: '1px solid rgb(0, 0, 0, .2)',
                            }}
                          >
                            <InputSection
                              style={{ display: 'block', marginBottom: '1rem' }}
                              className={
                                this.props.errors.retVal ? 'invalid' : ''
                              }
                            >
                              <Input
                                name="retVal"
                                size="small"
                                label={{ basic: true, content: '$' }}
                                value={retVal || ''}
                                onChange={this.handleMoneyChange}
                                onKeyPress={this.handleKeyPress}
                              />
                            </InputSection>
                            <InputSection
                              style={{ display: 'block' }}
                              className={
                                this.props.errors.wagePct ? 'invalid' : ''
                              }
                            >
                              <Input
                                name="wagePct"
                                size="small"
                                label={{ basic: true, content: '%' }}
                                value={wagePct || ''}
                                onChange={this.handlePercentageChange}
                                onKeyPress={this.handleKeyPress}
                              />
                            </InputSection>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '1.14rem',
                              color: colors.blue,
                              textAlign: 'center',
                              marginBottom: '1rem',
                            }}
                          >
                            Spouse
                          </div>
                          <InputSection
                            style={{ display: 'block', marginBottom: '1rem' }}
                            className={
                              this.props.errors.retVal2 ? 'invalid' : ''
                            }
                          >
                            <Input
                              name="retVal2"
                              size="small"
                              label={{ basic: true, content: '$' }}
                              value={retVal2 || ''}
                              onChange={this.handleMoneyChange}
                              onKeyPress={this.handleKeyPress}
                            />
                          </InputSection>
                          <InputSection
                            style={{ display: 'block' }}
                            className={
                              this.props.errors.wagePct2 ? 'invalid' : ''
                            }
                          >
                            <Input
                              name="wagePct2"
                              size="small"
                              label={{ basic: true, content: '%' }}
                              value={wagePct2 || ''}
                              onChange={this.handlePercentageChange}
                              onKeyPress={this.handleKeyPress}
                            />
                          </InputSection>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          margin: '2rem auto',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2rem',
                        }}
                      >
                        <InputSection
                          className={this.props.errors.retVal ? 'invalid' : ''}
                        >
                          <label style={{ fontSize: '1.14rem' }}>
                            <b>Expected pre-retirement income &nbsp;&nbsp;</b>
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{ color: 'black', fontSize: '15px' }}
                                />
                              }
                              position="left center"
                              content="In today’s dollars"
                            />
                          </label>
                          <Input
                            name="retVal"
                            size="small"
                            label={{ basic: true, content: '$' }}
                            width={8}
                            value={retVal || ''}
                            onChange={this.handleMoneyChange}
                            onKeyPress={this.handleKeyPress}
                          />
                        </InputSection>
                        <InputSection
                          className={this.props.errors.wagePct ? 'invalid' : ''}
                        >
                          <label>
                            <b>Retirement spending as a percentage</b>
                          </label>
                          <Input
                            name="wagePct"
                            size="small"
                            width={8}
                            label={{ basic: true, content: '%' }}
                            value={wagePct || ''}
                            onChange={this.handlePercentageChange}
                            onKeyPress={this.handleKeyPress}
                          />
                        </InputSection>
                      </div>
                    )}
                  </div>
                  <Statistic
                    size="small"
                    style={{ margin: 'auto', marginTop: '2rem' }}
                  >
                    <Statistic.Label>Total Expenditure</Statistic.Label>
                    <Statistic.Value
                      style={{ position: 'relative', marginTop: '0.5rem' }}
                    >
                      <Icon name="dollar" />
                      {standardResult && standardResult.toLocaleString()}
                      <ErrorLabel
                        msg={
                          error && !standardResult
                            ? 'Has to be greater than $0'
                            : null
                        }
                      />
                    </Statistic.Value>
                  </Statistic>
                </div>
              )}
              {spendingTool === 'bigPicture' && (
                <div>
                  <Header
                    size="medium"
                    textAlign="center"
                    style={{ fontSize: '1.2rem', maxWidth: '49rem' }}
                  >
                    <div>
                      The provincial average for{' '}
                      {isCouplePlan
                        ? 'a household of an individual aged 65+'
                        : 'an individual aged 65+ '}
                      in {province} is ${average && average.toLocaleString()}.
                      &nbsp;&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{ color: 'black', fontSize: '15px' }}
                          />
                        }
                        position="right center"
                        content="Based on the most recent Statistics Canada Survey of Household Spending"
                      />
                    </div>
                    <Header.Subheader style={{ marginTop: '.3rem' }}>
                      Use this value as a reference point and adjust it to your
                      lifestyle needs.
                    </Header.Subheader>
                  </Header>
                  <InputSection
                    style={{ marginTop: '2rem' }}
                    className={this.props.errors.blunter ? 'invalid' : ''}
                  >
                    <label style={{ fontSize: '1.14rem' }}>
                      <b>Retirement spending needs</b>
                    </label>
                    <Input
                      name="blunter"
                      size="small"
                      label={{ basic: true, content: '$' }}
                      value={blunter || ''}
                      onChange={this.handleMoneyChange}
                      onKeyPress={this.handleKeyPress}
                    />
                  </InputSection>
                </div>
              )}
              {spendingTool === 'personalized' && (
                <div>
                  <Header
                    size="medium"
                    textAlign="center"
                    style={{ fontSize: '1.2rem', maxWidth: '49rem' }}
                  >
                    <div>
                      {isCouplePlan
                        ? `The starting values are estimates for a household of an individual aged 65+ in ${province}.`
                        : `The starting values are estimates for an individual aged 65+ in ${province}.`}
                      &nbsp;&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{ color: 'black', fontSize: '15px' }}
                          />
                        }
                        position="right center"
                      >
                        Based on averages from{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          the most recent
                        </span>{' '}
                        Statistics Canada's Survey of Household Spending.
                      </Popup>
                    </div>
                    <Header.Subheader
                      style={{
                        marginTop: '.3rem',
                        fontSize: '1.14rem',
                      }}
                    >
                      Use these values as a reference point and adjust them to
                      your lifestyle needs.
                      <div style={{ marginTop: '0.9rem', height: '36px' }}>
                        {this.didUserModifyStartingValues() ? (
                          <Button
                            style={{
                              backgroundColor: colors.blue,
                              color: colors.white,
                            }}
                            onClick={this.useEstimatesInSpendingTools}
                          >
                            Use latest estimates
                          </Button>
                        ) : null}
                      </div>
                    </Header.Subheader>
                  </Header>
                  <Grid
                    style={{
                      marginTop: this.didUserModifyStartingValues()
                        ? '0'
                        : '-2.3rem',
                    }}
                  >
                    <Grid.Row
                      columns={2}
                      style={{
                        textAlign: 'left',
                        justifyContent: 'center',
                      }}
                    >
                      <Grid.Column style={{ flex: '0 0 350px' }}>
                        {personalizedToolColumnValues[0].map(
                          (key) =>
                            !excluded.test(key) && (
                              <Form.Field
                                inline
                                key={key}
                                style={{
                                  marginTop: '.5rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <StyledSemanticInput
                                  name={key}
                                  size="mini"
                                  label={{ basic: true, content: '$' }}
                                  value={
                                    spendingToolData[key] === 0
                                      ? '0'
                                      : spendingToolData[key] || ''
                                  }
                                  placeholder="0"
                                  onChange={this.handleMoneyChange}
                                  onKeyPress={this.handleKeyPress}
                                />
                                <label style={{ fontSize: '1.14rem' }}>
                                  {key}
                                </label>
                              </Form.Field>
                            )
                        )}
                      </Grid.Column>
                      <Grid.Column style={{ flex: '0 0 370px' }}>
                        {personalizedToolColumnValues[1].map(
                          (key) =>
                            !excluded.test(key) && (
                              <Form.Field
                                inline
                                key={key}
                                style={{
                                  marginTop: '.5rem',
                                }}
                              >
                                <StyledSemanticInput
                                  name={key}
                                  size="mini"
                                  label={{ basic: true, content: '$' }}
                                  value={
                                    spendingToolData[key] === 0
                                      ? '0'
                                      : spendingToolData[key] || ''
                                  }
                                  placeholder="0"
                                  onChange={this.handleMoneyChange}
                                  onKeyPress={this.handleKeyPress}
                                />
                                <label style={{ fontSize: '1.14rem' }}>
                                  {key}
                                </label>
                              </Form.Field>
                            )
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Statistic size="small" style={{ margin: '2rem 0 0' }}>
                    <Statistic.Label>Total Expenditure</Statistic.Label>
                    <Statistic.Value style={{ position: 'relative' }}>
                      <Icon name="dollar" />
                      {personalizedResult &&
                        personalizedResult.toLocaleString()}
                      <ErrorLabel
                        msg={
                          error && !personalizedResult
                            ? 'Has to be greater than $0'
                            : null
                        }
                      />
                    </Statistic.Value>
                  </Statistic>
                </div>
              )}
              {spendingTool === 'manual' && (
                <InputSection
                  style={{ display: 'block' }}
                  className={this.props.errors.manual ? 'invalid' : ''}
                >
                  <Input
                    name="manual"
                    size="small"
                    label={{ basic: true, content: '$' }}
                    value={manual || ''}
                    onChange={this.handleMoneyChange}
                    onKeyPress={this.handleKeyPress}
                  />
                </InputSection>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

SpendingTools.propTypes = {
  province: PropTypes.string,
  data: PropTypes.object.isRequired,
  isCouplePlan: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  spendingTool: PropTypes.string.isRequired,
  spendingToolData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
  cacheInputChanged: PropTypes.bool,
};

SpendingTools.defaultProps = {
  province: '',
  isCouplePlan: false,
  cacheInputChanged: false,
};

export default connect((state) => ({
  cacheInputChanged: state.Storage.cacheInputChanged,
}))(SpendingTools);

const RadioSection = styled.div`
  position: relative;
  min-height: 60px;
  margin-top: 2rem;
  margin-left: 2rem;
`;

const Label = styled.label`
  position: absolute;
  top: -1.5px;
  margin-left: 5px;
  font-size: 1.2rem;
  width: 47rem;
`;

const InputSection = styled.div`
  display: inline-block;

  label {
    display: block;
    width: 300px;
    text-align: left;
  }
  input {
    font-size: 14px !important;
  }
  &.invalid {
    .input {
      input {
        border: 1px solid ${colors.red} !important;
        background: rgb(255, 240, 240) !important;
      }
      .label {
        border: 1px solid ${colors.red} !important;
        background: rgb(255, 240, 240) !important;
      }
    }
  }
`;

const StyledSemanticInput = styled(({ children, className, ...rest }) => (
  <Input label={{ basic: true, content: '$' }} className={className} {...rest}>
    {children}
  </Input>
))`
  .label {
    font-size: 14px !important;
    padding-top: 0.7rem !important;
  }
  input {
    width: 100px;
    padding-top: 0.7rem !important;
    font-size: 0.9rem !important;
    margin-right: 0.5rem !important;
  }
  &.invalid {
    input {
      border: 1px solid ${colors.red} !important;
      background: rgb(255, 240, 240) !important;
    }
    .label {
      border: 1px solid ${colors.red} !important;
      background: rgb(255, 240, 240) !important;
    }
  }
`;
