/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header, Button, Popup, Icon } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import Fade from 'react-reveal/Fade';
import { Storage } from 'actions';

class Intro extends Component {
  componentDidMount() {
    const { dispatch, partnerName, setView } = this.props;
    dispatch(Storage.setItem('to_scorecard', true));
    if (isGlobeInvestor(partnerName)) {
      setView('WhatIfCustomization');
    }
  }

  isCompareToPortVisible = (args) => {
    const { user_region } = this.props;
    const { portfolio } = args.data;

    if (!portfolio) return false;
    if (portfolio.region !== user_region) return false;

    return true;
  };

  render() {
    const { setView, analytics, french } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledDiv>
          <Fade duration={1500}>
            <div>
              <Header>
                {french
                  ? 'À quoi aimeriez-vous comparer ce portefeuille? '
                  : 'What would you like to compare this portfolio to? '}
              </Header>
              <div>
                <Button onClick={() => setView('WhatIfCustomization')}>
                  {french ? 'Un portefeuille ' : 'A '}
                  <span style={{ fontStyle: 'italic' }}>
                    {french ? 'Et si' : 'What-if'}
                  </span>
                  {french ? '' : ' portfolio'}&nbsp;
                  <Popup
                    style={{ marginTop: '20px' }}
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'white',
                          margin: '0',
                        }}
                      />
                    }
                    position="bottom center"
                    wide
                    content={
                      french
                        ? 'Une copie de votre portefeuille actuel que vous pouvez modifier sans modifier votre portefeuille actuel.'
                        : 'A copy of your current portfolio that you can tweak/edit without modifying your current portfolio.'
                    }
                  />
                </Button>
                {this.isCompareToPortVisible(analytics) ? (
                  <Button onClick={() => setView('ExistingPortfolioSelection')}>
                    {french
                      ? 'Un portefeuille enregistré'
                      : 'A saved portfolio'}
                    &nbsp;
                    <Popup
                      style={{ marginTop: '20px' }}
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'white',
                            margin: '0',
                          }}
                        />
                      }
                      position="bottom center"
                      wide
                      content={
                        french
                          ? "Sélectionnez l'un de vos portefeuilles enregistrés ou l'un de nos portefeuilles modèles."
                          : 'Select one of your saved portfolios or one of our model portfolios.'
                      }
                    />
                  </Button>
                ) : null}
              </div>
            </div>
          </Fade>
        </StyledDiv>
        {!this.isCompareToPortVisible(analytics) && (
          <Note>
            <b>{french ? 'Remarque : ' : 'Note: '}</b>
            {french
              ? 'lorsque vous analysez votre compte USD en USD, il n\'y a pas de fonction "Comparer" (aux portefeuilles enregistrés). Utilisez plutôt la fonction "hypothétique" pour créer un portefeuille à des fins de comparaison.'
              : 'when you analyze your USD account in USD, there is no "Compare" (to saved portfolios) function. Use the "What-if" function instead to create a portfolio for comparison.'}
          </Note>
        )}
      </div>
    );
  }
}

Intro.propTypes = {
  setView: PropTypes.func.isRequired,
  analytics: PropTypes.object,
  user_region: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
  french: PropTypes.string,
};

Intro.defaultProps = {
  analytics: {},
  user_region: 'CA',
  partnerName: '',
  french: '',
};

export default connect((state) => ({
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))(Intro);

const StyledDiv = styled.div`
  & {
    // border: 2px solid ${colors.blue};
    // border-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;

    flex-direction: column;
    justify-content: center;
    div {
      margin-top: -4rem;
      div {
        margin-top: 1.5rem;
        display: flex;
        gap: 2rem;
        justify-content: center;
        button {
          background-color: ${colors.blue} !important;
          color: white !important;
        }
      }
    }
  }
`;

const Note = styled.div`
  position: absolute;
  font-size: 1.1rem;
  width: 50%;
  text-align: center;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  bottom: 30px;
`;
