import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { NotFound, Loading } from 'components';
import { Accounts, Analytics, Storage } from 'actions';
import Menu from './Menu';

const WS_UI_LOADED = 'WS_UI_LOADED';
const globeandmailScriptContent = `
  window.sophi = window.sophi || {};
  window.sophi.q = window.sophi.q || [];
  window.sophi.sendEvent = function (a) {
    window.sophi.q.push(a);
  };
  window.sophi.data = {
    page: {
      type: "tool",
      breadcrumb: "investing:markets:portfolio:wealthscape",
      sectionName: "investing",
    },
    environment: {
      environment: "prod",
    },
  };
  window.sophi.settings = window.sophi.settings || {
    linkedDomains: ["theglobeandmail.com", "legacy.com", "globeinvestor.com"],
    client: "globe_and_mail",
    trackerName: "sophiTracker",
    productionEndpoint: "sp.theglobeandmail.com",
    scriptDir: "latest",
    loadFrom: "https://cdn.sophi.io/latest/sophi.min.js",
    legacy: "https://cdn.sophi.io/latest/sophi.legacy.min.js",
  };

  try {
    eval('let id = Symbol("id"), a = [...new Set([0,1])].includes(0);');
  } catch (e) {
    window.sophi.settings.loadFrom = window.sophi.settings.legacy;
  }

  (function IIFE_sophiInit() {
    var n, g;
    if (!window[window.sophi.settings.trackerName]) {
      n = document.createElement("script");
      g = document.getElementsByTagName("script")[0];
      n.async = 1;
      n.src = window.sophi.settings.loadFrom;
      g.parentNode.insertBefore(n, g);
    }
  })();
  window.sophi.sendEvent({ type: "page_view" });
`;

class Globe extends Component {
  state = {
    noAccount: false,
  };

  componentWillMount = () => {
    const { dispatch, accountList } = this.props;

    if (!accountList) {
      return dispatch(Accounts.fetchAll());
    }

    return false;
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    const script = document.createElement('script');
    script.textContent = globeandmailScriptContent;
    script.type = 'text/javascript';
    document.body.appendChild(script);

    dispatch(Storage.removeItem('pwpa-has-exclude-holding'));
    dispatch(Storage.removeItem('pwpa-check-history'));
  };

  componentDidUpdate = (prevProps) => {
    const { dispatch, history, isFetching, notEnoughHoldings } = this.props;

    if (notEnoughHoldings) {
      window.parent.postMessage({ type: WS_UI_LOADED }, '*');
    }

    if (prevProps.isFetching && !isFetching) {
      const { accountList } = this.props;

      if (accountList.length && accountList[0]) {
        return dispatch(
          Accounts.analyzeGlobe(accountList[0].holdings, history)
        );
      }

      window.parent.postMessage({ type: WS_UI_LOADED }, '*');
      return this.setState({ noAccount: true });
    }

    if (prevProps.pwpaPortfolio !== this.props.pwpaPortfolio) {
      const { pwpaPortfolio } = this.props;

      return dispatch(Analytics.fetchParams(pwpaPortfolio));
    }

    if (prevProps.analytics !== this.props.analytics) {
      if (prevProps.analytics.isFetching && !this.props.analytics.isFetching) {
        window.parent.postMessage({ type: WS_UI_LOADED }, '*');
      }
    }

    return false;
  };

  renderError = (data) =>
    data.detail ? (
      <Error>Analysis failed. {data.detail}</Error>
    ) : (
      <Error>
        Sorry, all securities in your portfolio are too new to be analyzed.
      </Error>
    );

  render() {
    const { noAccount } = this.state;
    const { match, analytics, isFetching, notEnoughHoldings } = this.props;

    if (noAccount || notEnoughHoldings) {
      return <Error>Not enough matched holdings to analyze this account</Error>;
    }

    if (isFetching || !analytics.data || analytics.isFetching) {
      return <Loading active fixed />;
    }

    if (analytics.data.detail === 'Not found.') {
      return <NotFound />;
    }

    return (
      <div>
        <Loading active={analytics.isFetching} />
        {!analytics.data.score ? (
          this.renderError(analytics.data)
        ) : (
          <Menu id={match.params.id} />
        )}
      </div>
    );
  }
}

Globe.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  analytics: PropTypes.object,
  pwpaPortfolio: PropTypes.object,
  accountList: PropTypes.array,
  isFetching: PropTypes.bool,
  notEnoughHoldings: PropTypes.bool,
};

Globe.defaultProps = {
  accountList: [],
  analytics: {},
  pwpaPortfolio: null,
  isFetching: false,
  notEnoughHoldings: false,
};

export default connect((state) => ({
  accountList: state.Accounts.accountList,
  isFetching: state.Accounts.isFetching,
  notEnoughHoldings: state.Accounts.notEnoughHoldings,
  analytics: state.Analytics,
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
}))(Globe);

const Error = styled.div`
  position: relative;
  width: 400px;
  background: white;
  margin: 4rem auto;
  text-align: center;
  line-height: 2rem;
  padding: 2rem;
  border-radius: 8px;
  font-size: 1.4rem;
  box-shadow: 0 0 3px dimgrey;

  .close {
    position: absolute;
    color: black;
    top: 4px;
    right: 4px;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 1.7rem;
    border-radius: 50%;
    line-height: 1.4rem;
    transform: rotate(45deg);
    transition: all 0.1s ease;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
      color: #fff6f6;
    }
  }
`;
