import React from 'react';
import styled from 'styled-components';

export default () => (
  <Container>
    <Header>404</Header>
    <SubHeader>The page you requested does not exist</SubHeader>
  </Container>
);

const Container = styled.div`
  padding: 2rem 5rem;
`;

const Header = styled.div`
  font-size: 7rem;
  letter-spacing: 0.3rem;
  position: relative;
  margin-top: 10rem;
`;

const SubHeader = styled.div`
  font-size: 2rem;
  margin: 5rem 0;
`;
