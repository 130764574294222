import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';
import { chartingToolColors } from 'utils/colors';
import DateMenu from './DateMenu';

// unregister datalabels globally
// Chart.plugins.unregister(ChartDataLabels);

class Graph extends Component {
  getName = (name) => {
    if (Number.isInteger(name)) {
      for (
        let i = 0;
        i < Object.values(this.props.portfolioNames).length;
        i += 1
      ) {
        if (this.props.portfolioNames[i].id === name) {
          return this.props.portfolioNames[i].name;
        }
      }
    }
    return name;
  };

  getColorIndex = (name) => this.props.allNamesList.indexOf(name);

  formatDate = (yr, mnth) => {
    const valMnth = Number(mnth) < 10 ? `0${mnth}` : mnth;
    return `${yr}-${valMnth}`;
  };

  createChartConfig = (user_region) => ({
    // plugins: {
    //   datalabels: {
    //     align: (e) => {
    //       if (e.dataIndex >= (e.dataset.data.length * 0.80)) {
    //         return 160;
    //       } else if (e.dataIndex <= (e.dataset.data.length * 0.15)) {
    //         return 340;
    //       }
    //       return 'right';
    //     },
    //     clamp: 'true',
    //     clip: 'false',
    //     display: (context) => context.active ? 'auto' : false,
    //     backgroundColor: (context) => context.dataset.backgroundColor,
    //     borderRadius: 6,
    //     color: 'white',
    //     formatter: (value, context) => {
    //       const i = context.dataIndex;
    //       const prev = context.dataset.data[i - 1];
    //       let diff = prev !== undefined ? prev - value : 0;
    //       const change = prev !== undefined ? (Math.round(100 * (value - prev) / Math.abs(prev)) / 100) : 0;
    //       const glyph = change > 0 ? '\u25B2' : change < 0 ? '\u25BC' : '\u25C6';
    //       return `${context.chart.data.labels[context.dataIndex]} ${context.dataset.label} $${value}`;
    //     },
    //     offset: 8,
    //     textAlign: 'center',
    //   },
    // },
    legend: {
      display: false,
    },
    title: {
      display: true,
      fontStyle: {
        weight: 'normal',
      },
      text:
        user_region === 'CA'
          ? 'Investment Value ($𝗖𝗔𝗗)'
          : 'Investment Value ($𝗨𝗦𝗗)',
      position: 'left',
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      position: 'nearest',
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  });

  convertChartData = (data) => {
    if (data.length !== 0) {
      return {
        labels: Object.keys(data[0].data),
        datasets: data.map((val) => ({
          label: this.getName(val.id),
          data: Object.values(val.data).map(
            (value) => Math.round(value * 1000 * 100) / 100
          ),
          borderWidth: 3,
          lineTension: 0,
          fill: false,
          backgroundColor:
            Object.values(chartingToolColors)[
              this.getColorIndex(this.getName(val.id))
            ],
          borderColor:
            Object.values(chartingToolColors)[
              this.getColorIndex(this.getName(val.id))
            ],
          pointBorderColor:
            Object.values(chartingToolColors)[
              this.getColorIndex(this.getName(val.id))
            ],
          pointBackgroundColor:
            Object.values(chartingToolColors)[
              this.getColorIndex(this.getName(val.id))
            ],
          pointRadius: 0,
          pointHitRadius: 5,
        })),
      };
    }
    return {};
  };

  render() {
    const { chartData, updateStartEndDate, user_region } = this.props;

    return (
      <div>
        <Note>Total Returns including Distributions</Note>
        <DateMenu updateStartEndDate={updateStartEndDate} />
        {chartData.length !== 0 && (
          <Line
            data={this.convertChartData(chartData)}
            options={this.createChartConfig(user_region)}
            // plugins={[ChartDataLabels]}
          />
        )}
      </div>
    );
  }
}

Graph.propTypes = {
  chartData: PropTypes.array,
  updateStartEndDate: PropTypes.func.isRequired,
  portfolioNames: PropTypes.array,
  allNamesList: PropTypes.array,
  user_region: PropTypes.string,
};

Graph.defaultProps = {
  chartData: [],
  portfolioNames: [],
  allNamesList: [],
  user_region: 'CA',
};

export default connect((state) => ({
  chartData: state.ChartTool.securityChartData.concat(
    state.ChartTool.portfolioChartData
  ),
  user_region: state.Storage.user_region || 'CA',
}))(Graph);

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.1rem auto;
`;
