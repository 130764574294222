import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Message } from 'components';
import { ExposureTable } from '../components';

class EquityStyle extends Component {
  renderContent = (data) => {
    const filteredData = { ...data.partialCorr['G.EQ'] };
    delete filteredData.ignoredNegFac;

    const determineStyleTilt = (factor, value) => {
      if (value > 0) return tiltLabels[factor].plus;
      else if (value === 0) return tiltLabels[factor].neutral;
      return tiltLabels[factor].minus;
    };

    const tiltLabels = {
      Market: {
        plus: 'Market',
        neutral: 'Neutral',
        minus: 'Hedge',
      },
      Size: {
        plus: 'Small Cap',
        neutral: 'Neutral',
        minus: 'Large Cap',
      },
      Value: {
        plus: 'Value',
        neutral: 'Neutral',
        minus: 'Growth',
      },
      Momentum: {
        plus: 'High Momentum',
        neutral: 'Neutral',
        minus: 'Reversal',
      },
    };
    return (
      <div>
        <Header
          dividing
          size="large"
          content="Portfolio-Level Style Analysis"
          subheader="By Equity Style"
        />
        <p>
          This section shows your portfolio's equity style tilts using the
          Fama-French-Carhart equity style factors: market, size, value, and
          momentum. In the table, portfolio-level style tilts are assessed using
          the partial correlation between your equity portfolio returns and each
          factor. If it is positive (and statistically significant), then your
          equity portfolio has exposure to the market, small size, value, and
          momentum. If it is negative, then your equity portfolio is hedged to
          the market, and has exposure to large size, growth, and reversal.
          Further, since the partial correlation lies between -1 and 1, its
          magnitude shows the strength of the correlation. the one
        </p>
        {data.partialCorr && typeof data.partialCorr['G.EQ'] === 'string' ? (
          this.renderMessage(data.partialCorr['G.EQ'])
        ) : (
          <ExposureTable
            portfolioLevel
            headers={[
              'Style Factor',
              'Partial Correlation between Portfolio and Factor',
              'Style Tilt                   ',
            ]}
            data={Object.entries(filteredData).map(([factor, value]) => ({
              ticker: factor,
              macroRiskExp: value,
              styleTilt: determineStyleTilt(factor, value),
            }))}
          />
        )}
      </div>
    );
  };

  renderMessage = (message) => {
    if (typeof message !== 'string') {
      return null; // or some default message
    }

    return (
      <div>
        <Header
          dividing
          size="large"
          content="Portfolio-Level Style Analysis"
          subheader="By Equity Style"
        />
        <p>
          This section shows your portfolio's equity style tilts using the
          Fama-French-Carhart equity style factors: market, size, value, and
          momentum. In the table, portfolio-level style tilts are assessed using
          the partial correlation between your equity portfolio returns and each
          factor. If it is positive (and statistically significant), then your
          equity portfolio has exposure to the market, small size, value, and
          momentum. If it is negative, then your equity portfolio is hedged to
          the market, and has exposure to large size, growth, and reversal.
          Further, since the partial correlation lies between -1 and 1, its
          magnitude shows the strength of the correlation. the one
        </p>
        <Message visible warning>
          {message}
        </Message>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <Container>
        {data.partialCorr && typeof data.partialCorr['G.EQ'] === 'string'
          ? this.renderMessage(data.partialCorr['G.EQ'])
          : this.renderContent(data)}
      </Container>
    );
  }
}

EquityStyle.propTypes = {
  data: PropTypes.object,
};

EquityStyle.defaultProps = {
  data: {},
};

export default connect((state) => ({
  data: state.Analytics.data,
}))(EquityStyle);

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
