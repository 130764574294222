import { Planning } from 'actions';

const initialState = {
  isFetching: false,
  oasEstimator: {
    isFetching: false,
    eligible: true,
    years: { value: '', error: false },
    oasAge: { value: '', error: false },
    result: null,
  },
  cppEstimator: {
    isFetching: false,
    tableData: {},
    inputs: {
      caregiver: false,
      exceedIncome: true,
    },
    errors: {},
    result: null,
  },
  cppDataset: [],
  oasDataset: [],
  blueprint: {
    retirementBlueprint: {},
  },
  savedBlueprints: [],
  wealthResult: null,
  savingResult: null,
  planningUrl: null,
  longevity: null,
  drawdown: {
    activePage: 0,
    isFetching: false,
    id: null,
    name: '',
    description: '',
    inputs: {
      spouse: null,
      spouse2: null,
      activeSpouse: 'spouse',
      cppStarted: true,
      oasStarted: true,
      pensionInf: false,
      otherInf: false,
      port: null,
      initRegAccList: [],
      initRRIFAccList: [],
      initTFSAAccList: [],
    },
    errors: {},
    result: null,
    modified: false,
    justSaveOrFetch: false,
    coupleDrawdown: {
      isFetching: false,
      result: null,
    },
  },
  partialSuccess: null,
  isFullError: false,
  coupleDrawdown: {
    isFetching: false,
    result: null,
  },
  displayResult: false,
  savedDrawdownPlans: [],
  savedTargetDrawdownPlans: [],
  savedCoupleDrawdownPlans: [],
  wealthicaUser: {},
  tempSavedBlueprint: null,
  displayScenario: null,
  isOasModalOpen: false,
  isCppModalOpen: false,
};

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case Planning.PLANNING_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Planning.CLEAR_STORE:
      return {
        ...state,
        savingResult: null,
        wealthResult: null,
        blueprint: {
          retirementBlueprint: {},
        },
      };
    case Planning.DRAWDOWN_UPDATE_JUSTSAVEORFETCH:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          justSaveOrFetch: action.justSaveOrFetch,
        },
      };
    case Planning.CLEAR_SECTION_DATA:
      return {
        ...state,
        [action.section]: null,
      };
    case Planning.CLEAR_BLUEPRINT_DATA:
      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          [action.section]: null,
        },
      };
    case Planning.DELETE_BLUEPRINT_DATA:
      delete state.blueprint[action.section][action.input];

      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          [action.section]: {
            ...state.blueprint[action.section],
          },
        },
      };
    case Planning.CLEAR_BLUEPRINT_SECTION:
      delete state.blueprint[action.section];

      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          [action.section]: null,
        },
      };
    case Planning.UPDATE_BLUEPRINT_DATA:
      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          [action.section]: {
            ...state.blueprint[action.section],
            ...action.input,
          },
        },
      };
    case Planning.FETCH_BLUEPRINT:
      return {
        ...state,
        isFetching: false,
        blueprint: action.blueprintData,
      };
    case Planning.FETCH_ALL_BLUEPRINTS:
      return {
        ...state,
        isFetching: false,
        savedBlueprints: action.data,
      };
    case Planning.SAVE_BLUEPRINT:
      return {
        ...state,
        isFetching: false,
        blueprint: action.data,
      };
    case Planning.REMOVE_BLUEPRINT:
      return {
        ...state,
        isFetching: false,
        savedBlueprints: state.savedBlueprints.filter(
          (item) => item.id !== action.id
        ),
      };
    case Planning.ANALYZE_LONGEVITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        longevity: action.result,
      };
    case Planning.ANALYZE_WEALTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        wealthResult: action.result,
      };
    case Planning.ANALYZE_SAVING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        savingResult: action.data,
      };
    case Planning.ANALYZE_BLUEPRINT_WEALTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        blueprint: {
          ...state.blueprint,
          wealthResult: {
            ...action.result,
          },
        },
      };
    case Planning.ANALYZE_BLUEPRINT_SAVING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        blueprint: {
          ...state.blueprint,
          savingResult: {
            ...action.result,
          },
        },
      };
    case Planning.OAS_CALCULATE_REQUEST:
      return {
        ...state,
        oasEstimator: {
          ...state.oasEstimator,
          isFetching: true,
        },
      };
    case Planning.OAS_CALCULATE_SUCCESS:
      return {
        ...state,
        oasEstimator: {
          ...state.oasEstimator,
          isFetching: false,
          result: action.result,
        },
      };
    case Planning.OAS_CHANGE_ELIGIBLE:
      return {
        ...state,
        oasEstimator: {
          ...state.oasEstimator,
          eligible: action.value,
          result: null,
        },
      };
    case Planning.OAS_UPDATE_INPUT: {
      const { field, value, error } = action.params;
      const validate = (name, val) => {
        if ((name === 'years' || name === 'oasAge') && Number(val) > 100) {
          return 100;
        }

        return val;
      };

      return {
        ...state,
        oasEstimator: {
          ...state.oasEstimator,
          result: null,
          [field]: {
            ...state[field],
            value: validate(field, value),
            error: error || false,
          },
        },
      };
    }
    case Planning.OAS_CLEAR_ERRORS:
      return {
        ...state,
        oasEstimator: {
          eligible: state.oasEstimator.eligible,
          years: { value: state.oasEstimator.years.value, error: false },
          oasAge: { value: state.oasEstimator.oasAge.value, error: false },
          result: null,
        },
      };
    case Planning.OAS_CLEAR_INPUTS:
      return {
        ...state,
        oasEstimator: {
          isFetching: false,
          eligible: true,
          years: { value: '', error: false },
          oasAge: { value: '', error: false },
          result: null,
        },
      };
    case Planning.CPP_OAS_DATASET:
      return {
        ...state,
        cppDataset: action.cppDataset,
        oasDataset: action.oasDataset,
      };
    case Planning.CPP_CALCULATE_REQUEST:
      return {
        ...state,
        cppEstimator: {
          ...state.cppEstimator,
          isFetching: true,
        },
      };
    case Planning.CPP_CALCULATE_SUCCESS:
      return {
        ...state,
        cppEstimator: {
          ...state.cppEstimator,
          isFetching: false,
          result: action.result,
        },
      };
    case Planning.CPP_UPDATE_INPUT:
      return {
        ...state,
        cppEstimator: {
          ...state.cppEstimator,
          result: null,
          inputs: {
            ...state.cppEstimator.inputs,
            ...action.params.inputs,
          },
          errors: {
            ...state.cppEstimator.errors,
            ...action.params.errors,
          },
        },
      };
    case Planning.CPP_TABLEDATA:
      return {
        ...state,
        cppEstimator: {
          ...state.cppEstimator,
          tableData: action.tableData,
        },
      };
    case Planning.CPP_CLEAR_ERRORS:
      return {
        ...state,
        cppEstimator: {
          ...state.cppEstimator,
          result: null,
          inputs: {
            ...state.cppEstimator.inputs,
          },
          errors: {},
        },
      };
    case Planning.CPP_CLEAR_INPUTS:
      return {
        ...state,
        cppEstimator: {
          ...state.cppEstimator,
          result: null,
          inputs: {
            caregiver: false,
            exceedIncome: true,
          },
          errors: {},
        },
      };

    case Planning.COUPLE_PARTIAL_SUCCESS: {
      return {
        ...state,
        drawdown: {
          isFullError: false,
          ...state.drawdown,
          isFetching: false,
          coupleDrawdown: {
            ...state.coupleDrawdown,
            isFetching: false,
            result: action.result,
            spouse: action.spouseResult,
            spouse2: action.spouse2Result,
          },

          result: action.spouse === 'spouse' ? action.item : null,
          result2: action.spouse === 'spouse2' ? action.item : null,
          partialSuccess: action.spouse,
        },
      };
    }

    case Planning.COUPLE_NO_SUCCESS: {
      return {
        ...state,

        drawdown: {
          partialSuccess: null,
          isFullError: true,
          ...state.drawdown,
          activePage: 6,
          isFetching: false,
          coupleDrawdown: {
            ...state.coupleDrawdown,
            isFetching: false,
            result: 'error',
          },
        },

        result: null,
        result2: null,
      };
    }
    case Planning.DRAWDOWN_ANALYZE_REQUEST:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          isFetching: true,
        },
      };
    case Planning.DRAWDOWN_ANALYZE_TASK_ID_RECEIVED:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          isFetching: true,
          taskId: action.taskId,
        },
      };
    case Planning.COUPLE_DRAWDOWN_ANALYZE_REQUEST:
      return {
        ...state,

        drawdown: {
          ...state.drawdown,

          isFetching: true,

          partialSuccess: null,

          coupleDrawdown: {
            ...state.coupleDrawdown,

            isFetching: true,
          },
        },
      };

    case Planning.COUPLE_DRAWDOWN_ANALYZE_SUCCESS:
      return {
        ...state,

        drawdown: {
          ...state.drawdown,

          isFetching: false,

          coupleDrawdown: {
            ...state.coupleDrawdown,

            isFetching: false,

            result: action.result,

            spouse: action.spouseResult,

            spouse2: action.spouse2Result,
          },
        },
      };

    case Planning.COUPLE_DRAWDOWN_ANALYZE_FAILURE:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          isFetching: false,
          partialSuccess: null,
          coupleDrawdown: {
            ...state.coupleDrawdown,
            isFetching: false,
            result: null,
          },
        },
      };
    case Planning.DRAWDOWN_ANALYZE_SUCCESS:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          isFetching: !!action.isCouple,
          result: action.result,
        },
      };
    case Planning.DRAWDOWN_UPDATE_INPUT:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          inputs: {
            ...state.drawdown.inputs,
            ...action.params.inputs,
          },
          errors: {
            ...state.drawdown.errors,
            ...action.params.errors,
          },
          modified:
            action.params.modified !== undefined
              ? action.params.modified
              : state.drawdown.modified,
          justSaveOrFetch: false,
        },
      };
    case Planning.FETCH_DRAWDOWN_PLAN:
      return {
        ...state,
        isFetching: false,
        drawdown: {
          ...state.drawdown,
          isFetching: false,
          id: action.id,
          name: action.name,
          description: action.description,
          inputs: {
            ...action.inputs,
            spouse: action.inputs.spouse,
            spouse2: action.inputs.spouse2,
          },
          coupleDrawdown: {
            ...action.coupleDrawdown,
            spouse: action.result,
            spouse2: action.result2,
          },
          errors: {},
          result: action.result,
          result2: action.result2,
          justSaveOrFetch: true,
        },
      };
    case Planning.FETCH_COUPLE_DRAWDOWN_PLAN:
      return {
        ...state,
        isFetching: false,
        drawdown: {
          ...state.drawdown,
          isFetching: false,
          id: action.id,
          name: action.name,
          description: action.description,
          inputs: {
            ...action.inputs,
            spouse: action.inputs.personName1,
            spouse2: action.inputs.personName2,
          },
          coupleDrawdown: {
            ...action.coupleDrawdown,
            spouse: action.result,
            spouse2: action.result2,
          },
          errors: {},
          result: action.result,
          result2: action.result2,
          justSaveOrFetch: true,
        },
      };
    case Planning.FETCH_ALL_DRAWDOWN_PLANS:
      return {
        ...state,
        isFetching: false,
        drawdown: {
          ...state.drawdown,
          isFetching: false,
        },
        savedDrawdownPlans: action.data,
      };
    case Planning.FETCH_ALL_COUPLE_DRAWDOWN_PLANS:
      return {
        ...state,

        isFetching: false,

        drawdown: {
          ...state.drawdown,

          isFetching: false,
        },

        savedCoupleDrawdownPlans: action.data,
      };
    case Planning.FETCH_ALL_TARGET_DRAWDOWN_PLANS:
      return {
        ...state,
        isFetching: false,
        drawdown: {
          ...state.drawdown,
          isFetching: false,
        },
        savedTargetDrawdownPlans: action.data,
      };
    case Planning.SAVE_DRAWDOWN_PLAN:
      return {
        ...state,
        isFetching: false,
        drawdown: {
          ...state.drawdown,
          isFetching: false,
          errors: {},
          name: action.name,
          description: action.description,
          inputs: action.inputs,
          result: action.result,
          activePage: 6,
        },
      };
    case Planning.REMOVE_DRAWDOWN_PLAN:
      return {
        ...state,
        isFetching: false,
        savedDrawdownPlans: state.savedDrawdownPlans.filter(
          (item) => item.id !== action.id
        ),
      };
    case Planning.PLANNING_CLEAR:
      return initialState;
    case Planning.NEXT_SUB_PAGE:
      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          [action.section]: {
            ...state.blueprint[action.section],
            currentSubPage: state.blueprint[action.section].currentSubPage
              ? state.blueprint[action.section].currentSubPage + 1
              : 1,
          },
        },
      };
    case Planning.PREVIOUS_SUB_PAGE:
      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          [action.section]: {
            ...state.blueprint[action.section],
            currentSubPage:
              !state.blueprint[action.section].currentSubPage ||
              state.blueprint[action.section].currentSubPage <= 1
                ? 1
                : state.blueprint[action.section].currentSubPage - 1,
          },
        },
      };
    case Planning.UPDATE_COMPLETED_SECTION_PAGE_NUMBER:
      return {
        ...state,
        blueprint: {
          ...state.blueprint,
          ...action.data,
        },
      };
    case Planning.DRAWDOWN_NAVIGATE:
      return {
        ...state,

        drawdown: {
          ...state.drawdown,

          activePage: action.page,
        },
      };
    case Planning.FETCH_WEALTHICA_USER:
      return {
        ...state,
        isFetching: false,
        wealthicaUser: action.data,
      };
    case Planning.DRAWDOWN_SET_DISPLAY_SCENARIO:
      return {
        ...state,
        drawdown: {
          ...state.drawdown,
          displayScenario: action.scenario,
        },
      };
    case Planning.SET_OAS_MODAL_OPEN:
      return {
        ...state,
        isOasModalOpen: action.isOpen,
      };
    case Planning.SET_CPP_MODAL_OPEN:
      return {
        ...state,
        isCppModalOpen: action.isOpen,
      };
    default:
      return state;
  }
}
