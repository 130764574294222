import { request } from './api';
import * as Alert from './alert';
import * as Accounts from './accounts';

/**
 * Action constants
 */
export const REBALANCEACCOUNT_REQUEST = 'REBALANCEACCOUNT_REQUEST';
export const REBALANCEACCOUNT_FETCH_ID = 'REBALANCEACCOUNT_FETCH_ID';
export const REBALANCEACCOUNT_SAVE_SYSTEM = 'REBALANCEACCOUNT_SAVE_SYSTEM';
export const REBALANCEACCOUNT_REMOVE_SYSTEM = 'REBALANCEACCOUNT_REMOVE_SYSTEM';
export const REBALANCEACCOUNT_REBALANCE = 'REBALANCEACCOUNT_REBALANCE';

export const REBALANCEACCOUNT_UPDATE_WEIGHT = 'REBALANCEACCOUNT_UPDATE_WEIGHT';
export const REBALANCEACCOUNT_UPDATE_DEVIATION =
  'REBALANCEACCOUNT_UPDATE_DEVIATION';
export const REBALANCEACCOUNT_REMOVE_SECURITY =
  'REBALANCEACCOUNT_REMOVE_SECURITY';
export const REBALANCEACCOUNT_REMOVE_ALL = 'REBALANCEACCOUNT_REMOVE_ALL';
export const REBALANCEACCOUNT_CLEAR_DATA = 'REBALANCEACCOUNT_CLEAR_DATA';
export const REBALANCEACCOUNT_INIT_EDITING = 'REBALANCEACCOUNT_INIT_EDITING';

/**
 * Action creators
 */
export function fetchId(id) {
  return (dispatch) => {
    dispatch({ type: REBALANCEACCOUNT_REQUEST });

    return dispatch(request('get', `/accounts/${id}/`)).then(
      (data) => dispatch({ type: REBALANCEACCOUNT_FETCH_ID, account: data }),
      (error) => dispatch(Alert.show({ type: 'error', msg: parseErr(error) }))
    );
  };
}

export function saveSystem(body) {
  return (dispatch) => {
    dispatch({ type: REBALANCEACCOUNT_REQUEST });

    return dispatch(
      request('put', '/accounts/rebalancing-setup/', { body })
    ).then(
      () => {
        dispatch({ type: REBALANCEACCOUNT_SAVE_SYSTEM });
        dispatch(Accounts.fetchId(body.account_id));
        dispatch(
          Alert.show({ type: 'success', msg: 'Rebalancing system saved' })
        );
      },
      (error) => {
        dispatch({ type: REBALANCEACCOUNT_SAVE_SYSTEM, error });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function removeSystem(id) {
  return (dispatch) => {
    dispatch({ type: REBALANCEACCOUNT_REQUEST });

    return dispatch(
      request('put', '/accounts/rebalancing-setup/', {
        body: {
          account_id: id,
          rebal_targets: null,
          rebal_tolerances: null,
        },
      })
    ).then(
      () => {
        dispatch({ type: REBALANCEACCOUNT_REMOVE_SYSTEM });
        dispatch(
          Accounts.updateData(id, { rebal_set: false, rebal_alert: false })
        );
        dispatch(
          Alert.show({ type: 'success', msg: 'Rebalancing system deleted' })
        );

        return { success: true };
      },
      (error) => {
        dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));

        return { success: false };
      }
    );
  };
}

export function rebalance(body) {
  return (dispatch) => {
    dispatch({ type: REBALANCEACCOUNT_REQUEST });

    return dispatch(request('post', '/accounts/rebalancing/', { body })).then(
      (data) => {
        const convertedData = Object.keys(data).reduce((acc, key) => {
          if (data[key].rebalancing_buy)
            acc.push({ ...data[key], ticker: key });

          return acc;
        }, []);
        dispatch({ type: REBALANCEACCOUNT_REBALANCE, data: convertedData });
      },
      (error) => {
        dispatch({ type: REBALANCEACCOUNT_REBALANCE });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function updateWeight(ticker, value) {
  return {
    type: REBALANCEACCOUNT_UPDATE_WEIGHT,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function updateDeviation(ticker, value) {
  return {
    type: REBALANCEACCOUNT_UPDATE_DEVIATION,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function removeAll() {
  return {
    type: REBALANCEACCOUNT_REMOVE_ALL,
  };
}

export function clearRebalanceData() {
  return {
    type: REBALANCEACCOUNT_CLEAR_DATA,
  };
}

export function initEditing() {
  return {
    type: REBALANCEACCOUNT_INIT_EDITING,
  };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
