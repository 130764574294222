import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import cn from 'classnames';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class GeneralInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {},
      error: {},
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown, cppDataset, oasDataset, cppEstimator } =
      this.props;
    const { inputs } = drawdown;
    const { activeSpouse } = inputs; // Get the active spouse

    // Determine the correct field names based on the active spouse
    const ageField = activeSpouse === 'spouse' ? 'age' : 'age2';
    const horizonField = activeSpouse === 'spouse' ? 'horizon' : 'horizon2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';
    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';

    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;
    if (
      (name === ageField || name === horizonField) &&
      (value.includes('.') || value.includes('-'))
    ) {
      return;
    }

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'cpp2',
        'oas',
        'oas2',
        'pension',
        'pension2',
        'other',
        'other2',
        'initRegular',
        'initRegular2',
        'initRRIF',
        'initRRIF2',
        'initTFSA',
        'initTFSA2',
        'endValue',
        'endValue2',
      ];

      if (field === horizonField) {
        const maxHorizon = 110;
        const sum = Number(val) + Number(inputs[ageField]);
        if (Number(val) < 1) {
          this.setState({
            warning: {
              ...this.state.warning,
              [horizonField]: 'Please enter a number greater than 0.',
            },
          });
          return val;
        }

        if (sum > maxHorizon) {
          this.setState({
            warning: {
              ...this.state.warning,
              [horizonField]:
                'We restrict the sum of your age and planning horizon to be no more than 110 years.',
            },
          });
          return val;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) return 70;
      if (isOas && Number(age) < 65) return 65;
      if (Number(age) < 60) return 60;
      if (Number(age) > 70) return 70;
      return age;
    };

    // Detect age change and set error message if needed
    if (name === ageField) {
      const { cpp, oas, cpp2, oas2 } = inputs;
      const cppOrOasSet = cpp || oas || cpp2 || oas2;
      const newAge = Number(parsedValue(name, value));
      if (value < 49) {
        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { ...drawdown.inputs, [ageField]: value },
            errors: {
              ...drawdown.errors,
              [ageField]: 'Minimum age is 49',
            },
          })
        );
      }

      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(newAge, false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(newAge, true))
      ).amount;
      if (newAge < 60) {
        if (cppOrOasSet) {
          this.setState({
            error: {
              [ageField]:
                'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                [cppField]: true,
                [oasField]: true,
              },
            })
          );
        }
        this.changeSelection(cppStartedField, false);
        this.changeSelection(oasStartedField, false);
      } else if (newAge < 65) {
        if (cppOrOasSet) {
          this.setState({
            error: {
              [ageField]:
                'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                [cppField]: true,
                [oasField]: true,
              },
            })
          );
        }
        this.changeSelection(oasStartedField, false);
        this.changeSelection(cppStartedField, true);
      } else {
        if (cppOrOasSet) {
          this.setState({
            error: {
              [ageField]:
                'Your current age has changed. Please revisit the Retirement Income section to adjust your inputs.',
            },
          });
          dispatch(
            Planning.drawdownUpdateInput({
              inputs: { ...drawdown.inputs },
              errors: {
                ...drawdown.errors,
                [cppField]: true,
                [oasField]: true,
              },
            })
          );
        }
        this.changeSelection(oasStartedField, true);
        this.changeSelection(cppStartedField, true);
      }

      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [ageField]: newAge,
            ...(newAge > 60 && { [cppAgeField]: newAge }),
            ...(newAge > 65 && { [oasAgeField]: newAge }),
          },
          errors: {
            ...drawdown.errors,
            [ageField]: false,
            ...(newAge > 60 && inputs[cppAgeField] && { [cppAgeField]: false }),
            ...(newAge > 65 && inputs[oasAgeField] && { [oasAgeField]: false }),
            ...(inputs[oasStartedField] &&
              inputs[oasField] > oasMax && {
                [oasField]:
                  'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs[cppStartedField] &&
              inputs[cppField] <= cppMax && { [cppField]: null }),
            ...(inputs[oasStartedField] &&
              inputs[oasField] <= oasMax && { [oasField]: null }),
            ...(!inputs[cppStartedField] && { [cppAgeField]: null }),
            ...(!inputs[oasStartedField] && { [oasAgeField]: null }),
          },
          modified: true,
        })
      );
    }

    if (name === cppAgeField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [cppAgeField]: parsedValue(name, value), [cppField]: '' },
          errors: { [cppAgeField]: false, [cppField]: false },
          modified: true,
        })
      );
    }

    if (name === oasAgeField) {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [oasAgeField]: parsedValue(name, value), [oasField]: '' },
          errors: { [oasAgeField]: false, [oasField]: false },
          modified: true,
        })
      );
    }

    if (name === cppField) {
      if (!inputs[cppAgeField] && inputs[cppStartedField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [cppAgeField]: inputs[ageField] || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();
      const body = {
        retirement_year:
          Number(currentYear) +
          Number(inputs[cppAgeField]) -
          Number(inputs[ageField]),
        start_cpp_age: inputs[cppAgeField],
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs[cppAgeField] !== '' && inputs[cppAgeField] >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, [cppField]: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cpp: money },
            errors: { cpp: false },
            modified: true,
          })
        );
      }
      return this.setState({
        warning: {
          ...this.state.warning,
          [cppField]:
            'To start receiving CPP/QPP, you must be at least 60 years old. Please enter a valid age.',
        },
      });
    }

    if (name === oasField) {
      if (!inputs[oasAgeField] && inputs[oasStartedField]) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { [oasAgeField]: inputs[ageField] || 0 },
            modified: true,
          })
        );
      }

      if (inputs[oasAgeField] !== '' && inputs[oasAgeField] >= 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs[oasAgeField])
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs[oasAgeField],
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, [oasField]: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [oasField]: money },
          errors: { [oasField]: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;
    const { activeSpouse } = drawdown.inputs; // Get the active spouse

    // Determine the correct field names based on the active spouse
    const cppStartedField =
      activeSpouse === 'spouse' ? 'cppStarted' : 'cppStarted2';
    const oasStartedField =
      activeSpouse === 'spouse' ? 'oasStarted' : 'oasStarted2';
    const cppAgeField = activeSpouse === 'spouse' ? 'cppAge' : 'cppAge2';
    const oasAgeField = activeSpouse === 'spouse' ? 'oasAge' : 'oasAge2';
    const cppField = activeSpouse === 'spouse' ? 'cpp' : 'cpp2';
    const oasField = activeSpouse === 'spouse' ? 'oas' : 'oas2';

    if (field === cppStartedField) {
      if (drawdown.inputs[cppStartedField] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [cppStartedField]: value,
              [cppAgeField]: '',
              [cppField]: '',
            },
            errors: { [cppAgeField]: false, [cppField]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { [cppField]: '' } });
      }
    }

    if (field === oasStartedField) {
      if (drawdown.inputs[oasStartedField] !== value) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: {
              [oasStartedField]: value,
              [oasAgeField]: '',
              [oasField]: '',
            },
            errors: { [oasAgeField]: false },
            modified: true,
          })
        );

        return this.setState({ warning: { [oasField]: '' } });
      }
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  render() {
    const { warning } = this.state;
    const { inputs, errors } = this.props.drawdown;
    const { activeSpouse } = inputs;
    const { error } = this.state;

    const ageInputName = activeSpouse === 'spouse' ? 'age' : 'age2';
    const horizonInputName = activeSpouse === 'spouse' ? 'horizon' : 'horizon2';
    const ageValue = inputs[ageInputName] || '';
    const horizonValue = inputs[horizonInputName] || '';
    const ageError = errors[ageInputName];
    const horizonError = errors[horizonInputName];
    const horizonWarning = warning[horizonInputName];

    return (
      <div>
        <Section single>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div className="title">
              Current age
              <p
                style={{
                  margin: 0,
                  transform: 'translateY(-1px)',
                  display: 'block',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  color: `${colors.red}`,
                }}
                visible={typeof ageError === 'string' || error[ageInputName]}
              >
                {ageError || error[ageInputName]}
              </p>
            </div>
            <Input
              className={cn('large', {
                invalid: ageError ? 'true' : undefined,
              })}
              name={ageInputName}
              type="number"
              placeholder="years"
              value={ageValue}
              onChange={this.handleChange}
            />
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div className="title">
              Planning for how many years?
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="top center"
                content="e.g. if you are 65 and expect you will live until 85, then input 20 years."
              />
              (
              <a
                href="https://www.projectbiglife.ca/life-expectancy-home"
                target="_blank"
                rel="noopener noreferrer"
              >
                Project Big Life
              </a>
              )
              <p
                style={{
                  margin: 0,
                  transform: 'translateY(-1px)',
                  display: 'block',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  color: `${colors.red}`,
                }}
                visible={
                  typeof horizonWarning === 'string' ||
                  typeof horizonError === 'string'
                }
              >
                {horizonWarning || horizonError ? (
                  <React.Fragment>
                    {horizonWarning || horizonError}
                  </React.Fragment>
                ) : null}
              </p>
            </div>
            <Input
              className={cn('large', {
                invalid: horizonError || horizonWarning ? 'true' : undefined,
              })}
              name={horizonInputName}
              type="number"
              placeholder="years"
              value={horizonValue}
              onChange={this.handleChange}
              onFocus={() =>
                this.setState({
                  warning: { ...this.state.warning, [horizonInputName]: null },
                })
              }
              onBlur={() =>
                this.setState({
                  warning: { ...this.state.warning, [horizonInputName]: null },
                })
              }
              onClick={() =>
                this.setState({
                  warning: { ...this.state.warning, [horizonInputName]: null },
                })
              }
            />
          </div>
        </Section>
      </div>
    );
  }
}

GeneralInformation.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  cppDataset: PropTypes.array,
  oasDataset: PropTypes.array,
  cppEstimator: PropTypes.object,
};

GeneralInformation.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  cppDataset: [],
  oasDataset: [],
  cppEstimator: {},
};

const Section = styled.section`
  display: flex; /* Add this line */
  flex-direction: column;
  padding-left: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem !important;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Input = styled.input`
  max-height: 30px;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
