import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Header, Segment, NeuCard, Feature } from 'components';
import { CacheInputs, FundAllocator, Planning } from 'actions';
import { colors } from 'utils/colors';
import {
  TOOLS_FUND_LOOKUP,
  TOOLS_OAS_ESTIMATOR,
  TOOLS_CPP_ESTIMATOR,
  TOOLS_CHARTING_TOOL,
  TOOLS_ACCOUNTSETUP_TOOL,
  TOOLS_FUND_COMPARISON,
  TOOLS_CORRELATION_MATRIX,
  TOOLS_STOCK_EXPOSURE,
} from 'utils/features';
import asset5 from 'images/asset_5.png';

@withRouter
@connect((state) => ({
  account: state.CustomAccount.account,
}))
class Landing extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(CacheInputs.clearField('tools', 'fund_allocator'));
    dispatch(FundAllocator.clearFundAllocator());
    dispatch(Planning.getCppOasDataset());
  };

  render() {
    return (
      <Segment expand>
        <div>
          <img src={asset5} height="80" width="120" alt="Portfolio" />
          <Header size="1.5rem" color="black">
            Welcome to Tools
          </Header>
          <P>
            Useful tools at your fingertips to help you better understand your
            investments.
          </P>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            <Feature name={TOOLS_STOCK_EXPOSURE}>
              <NeuCard
                to="/tools/stock-exposure"
                iconName="chart line"
                title="Stock Exposure Lookup"
                description="Discover your stock exposure across your investment accounts."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_FUND_LOOKUP}>
              <NeuCard
                to="/tools/fund-lookup"
                iconName="search"
                title="Fund Lookup"
                description="Look up an ETF or a Mutual Fund and see pertinent fund information."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_FUND_COMPARISON}>
              <NeuCard
                to="/tools/fund-comparison"
                iconName="balance scale"
                title="Fund Comparison"
                description="Compare up to four ETFs and/or mutual funds."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_CORRELATION_MATRIX}>
              <NeuCard
                to="/tools/correlation-matrix"
                iconName="table"
                title="Correlation Matrix"
                description="Estimate the correlation amongst a set of securities to see how closely they move together over time."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_OAS_ESTIMATOR}>
              <NeuCard
                to="/tools/oas-estimator"
                iconName="dashboard"
                title="OAS Estimator"
                description="See how much Old Age Security pension you can expect."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_CPP_ESTIMATOR}>
              <NeuCard
                to="/tools/cpp-estimator"
                iconName="calculator"
                title="CPP/QPP Estimator"
                description="Obtain a quick estimate of the amount of Canada Pension Plan benefits you can expect."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_CHARTING_TOOL}>
              <NeuCard
                to="/tools/charting-tool"
                iconName="chart line"
                title="Charting Tool"
                description="A simple way to compare funds, saved portfolios and other securities."
                color={colors.blue}
              />
            </Feature>
            <Feature name={TOOLS_ACCOUNTSETUP_TOOL}>
              <NeuCard
                to="/tools/new-account-setup"
                iconName="chart pie"
                title="Fund Allocator"
                description="Calculate how many shares of each security in the portfolio you should purchase."
                color={colors.blue}
              />
            </Feature>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 650px;
  margin: 0 auto 3rem auto;

  &.strike {
    text-decoration: line-through;
  }
`;
