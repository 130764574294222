/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Flag, Header, Popup, Icon, Divider } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import Fade from 'react-reveal/Fade';
import { SearchTicker, Table, Segment, Button, Loading } from 'components';
import { getTickerSearchResult } from 'utils/helpers';

import { ComparePortfolio } from 'actions';
import cn from 'classnames';
import { marginOfError } from 'utils/constants';
import { withRouter } from 'react-router-dom';
import RoundDecimalImage from 'images/roundDecimal.png';
import Weight from './Weight';
import InterestRate from './InterestRate';
import { translateAssetClass } from '../../../../utils/helpers';

class WhatIfCustomization extends Component {
  componentDidMount = () => {
    const { dispatch, match, history, analytics, comparePortfolioState } =
      this.props;
    const { data } = analytics;
    const { snapshotLoad } = comparePortfolioState;
    if (snapshotLoad) {
      return;
    }
    dispatch(
      ComparePortfolio.convertAnalyticsPortfolioObject(
        data.portfolio,
        data.paramsAnalyzed,
        // the original portfolio symbols and weights can change depending on the check_history toggle,
        true
      )
    );
  };

  componentDidUpdate = () => {
    const { comparePortfolioState, setView } = this.props;
    if (
      comparePortfolioState.compareResult &&
      !comparePortfolioState.snapshotLoad
    )
      setView('Result');
  };

  getNonTypicalWeight = (ticker) => {
    const { comparePortfolioState } = this.props;
    const nonTypicalTicker = Object.values(
      comparePortfolioState.portfolio
    ).find((port) => port.ticker === ticker);

    return nonTypicalTicker ? nonTypicalTicker.weight : null;
  };

  getGICHoldings = () => {
    const { comparePortfolioState } = this.props;
    // eslint-disable-next-line no-useless-escape
    const gicFormat = /^GIC\d*\:MKT$/;

    const res = Object.keys(comparePortfolioState.portfolio)
      .filter((key) => gicFormat.test(key))
      .reduce((obj, key) => {
        obj[key] = comparePortfolioState.portfolio[key];
        return obj;
      }, {});

    return res;
  };

  getPortfoliosAssociatedAccountName = () => {
    const { analytics } = this.props;
    if (analytics.data.paramsPassed.account)
      if (!analytics.data.paramsPassed.account.label)
        return `${analytics.data.paramsPassed.account.user_institution || ''}`;
      else
        return `${
          analytics.data.paramsPassed.account.user_institution || ''
        }: ${analytics.data.paramsPassed.account.label || ''}`;

    return null;
  };

  filterOutGICAndDigitalCurrency = () => {
    const { comparePortfolioState } = this.props;
    // eslint-disable-next-line no-useless-escape
    const gicFormat = /^GIC\d*\:MKT$/;

    return Object.keys(comparePortfolioState.portfolio)
      .filter((key) => !gicFormat.test(key))
      .filter((key) => key !== '~BTCUSDT' && key !== '~ETHUSDT')
      .reduce((obj, key) => {
        obj[key] = comparePortfolioState.portfolio[key];
        return obj;
      }, {});
  };

  round = (num) => Math.round(num * 100) / 100;

  showAddGICToggle = () => {
    const { comparePortfolioState } = this.props;
    const { original, portfolio, gic_info, addGicToggle } =
      comparePortfolioState;

    const all_gics = Object.keys(gic_info);

    return (
      addGicToggle ||
      Object.values(portfolio).reduce(
        (prev, curr) => (prev += all_gics.includes(curr.ticker) ? 1 : 0),
        0
      ) === 0 ||
      Object.values(original.portfolio).reduce(
        (prev, curr) => (prev += all_gics.includes(curr.ticker) ? 1 : 0),
        0
      ) === 0
    );
  };

  didUserAddNewHoldingsToWhatIf = () => {
    const { comparePortfolioState } = this.props;
    // eslint-disable-next-line prefer-const
    const { portfolio, original } = comparePortfolioState;

    // removing all holdings that have a weight of 0%
    const portfolioTickers = Object.keys(portfolio).filter(
      (ticker) => portfolio[ticker].weight > 0
    );
    const originalPortfolioTickers = Object.keys(original.portfolio);

    const sortedPortfolioTickers = [...portfolioTickers].sort();
    const sortedOriginalPortfolioTickers = [...originalPortfolioTickers].sort();

    const sortedPortfolioSet = new Set(sortedPortfolioTickers); // using sets to decrease overall average time complexity
    const sortedOriginalPortfolioSet = new Set(sortedOriginalPortfolioTickers);

    // user must have added some new holdings
    if (sortedOriginalPortfolioTickers.length < sortedPortfolioTickers.length) {
      return true;
    } else if (
      sortedOriginalPortfolioTickers.length > sortedPortfolioTickers.length
    ) {
      // user could have deleted a bunch and added a new one
      for (let i = 0; i < sortedPortfolioTickers.length; i += 1) {
        if (!sortedOriginalPortfolioSet.has(sortedPortfolioTickers[i])) {
          return true;
        }
      }
    } else {
      // original and what-if have an equal number of holdings. user may have added new holdings (could have added one and deleted one)
      for (let i = 0; i < sortedPortfolioTickers.length; i += 1) {
        if (sortedPortfolioTickers[i] !== sortedOriginalPortfolioTickers[i]) {
          return true;
        }
      }
    }
    return false;
  };

  labelData = () => {
    const { comparePortfolioState } = this.props;
    const totalValue = Object.values(comparePortfolioState.portfolio).reduce(
      (total, value) => (total += Number(value.weight)),
      0
    );
    if (!totalValue)
      return {
        realVal: 100,
        val: 100,
        msg: 'under',
      };

    if (this.round(totalValue) === 100) {
      return false;
    }

    return {
      realVal: this.round(100 - totalValue),
      val: this.round(Math.abs(100 - totalValue)),
      msg: 100 - totalValue > 0 ? 'under' : 'over',
    };
  };
  compare = () => {
    const { dispatch, comparePortfolioState, user_region, analytics } =
      this.props;
    const { paramsPassed } = analytics.data;
    const { portfolio, original, gic_info } = comparePortfolioState;

    const customBenchmark =
      analytics && analytics.benchmark === 'custom'
        ? paramsPassed
          ? paramsPassed.custom_bm
          : null
        : null;

    const checkHistory = this.didUserAddNewHoldingsToWhatIf()
      ? false
      : analytics.data.paramsPassed.check_history || false;
    const rebalanceInterval =
      analytics.data.paramsPassed.rebalance_interval || 1;
    dispatch(ComparePortfolio.takeSnapshotAfterReplace());
    dispatch(ComparePortfolio.setSnapshotLoad(false));

    return dispatch(
      ComparePortfolio.fetchCompare(
        original.portfolio,
        portfolio,
        original.gic_info,
        gic_info,
        this.decideRegion(),
        customBenchmark,
        checkHistory,
        rebalanceInterval
      )
    );
  };
  removeAllHoldings = () =>
    this.props.dispatch(ComparePortfolio.removeAllHoldings());
  removeAllGics = () => this.props.dispatch(ComparePortfolio.removeAllGics());

  isDisabled = () => {
    const { comparePortfolioState } = this.props;
    const { portfolio, gic_new_generic_ticker, gic_info } =
      comparePortfolioState;
    const { gic_rate, gic_ticker } = gic_new_generic_ticker
      ? gic_info[gic_new_generic_ticker] || {}
      : {};

    const totalWeight = Object.values(portfolio)
      .reduce((total, value) => (total += Number(value.weight)), 0)
      .toFixed(2);

    const gicWeight = this.getNonTypicalWeight(gic_new_generic_ticker);

    return (
      ((gicWeight || gic_rate || gic_ticker) &&
        !(gicWeight && gic_rate && gic_ticker)) ||
      totalWeight < 100 - marginOfError ||
      totalWeight > 100 + marginOfError
    );
  };

  handleChange = (e) => {
    const { dispatch, comparePortfolioState } = this.props;
    const { gic_new_generic_ticker } = comparePortfolioState;
    const { name, value } = e.target;
    const correctNum = value === '' ? '' : Number(value);
    const num =
      correctNum === ''
        ? ''
        : Number.isInteger(correctNum)
        ? correctNum
        : value.split('.')[1].length >= 2
        ? Number(
            `${value.split('.')[0]}.${value.split('.')[1].substring(0, 2)}`
          )
        : correctNum;
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);
    const ticker =
      name === 'btc_weight'
        ? '~BTCUSDT'
        : name === 'eth_weight'
        ? '~ETHUSDT'
        : gic_new_generic_ticker;

    if (
      name === 'gic_weight' ||
      name === 'btc_weight' ||
      name === 'eth_weight'
    ) {
      dispatch(ComparePortfolio.addGicBtcEth(ticker));

      if (!num && num !== 0) {
        dispatch(ComparePortfolio.remove(ticker));
      } else if (num > 100) {
        dispatch(ComparePortfolio.updateWeight(ticker, 100));
      } else if (!negative) {
        dispatch(ComparePortfolio.updateWeight(ticker, num));
      }
    } else if (name === 'gic_rate') {
      if (num > 100) {
        dispatch(ComparePortfolio.updateInterestRate(ticker, 1));
      } else if (!negative) {
        dispatch(
          ComparePortfolio.updateInterestRate(
            ticker,
            value === '' ? '' : (num / 100).toFixed(4)
          )
        );
      }
      return false;
    }
    return false;
  };

  decideRegion = () => {
    if (this.props.user_region === 'US') {
      return 'US';
    } else if (this.props.toggle_region === 'US') {
      return 'US';
    }
    return 'CA';
  };

  showSecurityProxyName = (security) => {
    const { analytics } = this.props;
    const { data } = analytics;
    if (!security) return null;
    return (
      data.portfolio.portfolio_type !== 'custom' &&
      data.tickersValid[security.ticker] &&
      data.tickersValid[security.ticker].wsName
    );
  };

  renderHoldingsTable = () => {
    const { comparePortfolioState, dispatch, analytics, french } = this.props;

    const numHoldings = Object.keys(
      this.filterOutGICAndDigitalCurrency()
    ).length;

    return (
      <Segment
        className="basic compact"
        style={{
          marginTop: '0.5rem',
          padding: '0rem',
          marginBottom: '0rem',
          opacity: comparePortfolioState.isFetching ? 0.5 : 1,
          paddingBottom: '0',
          width: '800px',
        }}
      >
        {!!numHoldings && (
          <RemoveAll
            style={{ top: '3px', textAlign: 'center' }}
            onClick={this.removeAllHoldings}
          >
            {french ? 'effacer' : 'clear'}
          </RemoveAll>
        )}

        <Table
          textAlign="center"
          style={{
            marginBottom: '0rem',
            marginTop: !numHoldings && '0.7rem',
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? 'Actif' : 'Asset'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? 'Échange' : 'Exchange'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? 'Classe' : 'Class'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? 'Prix' : 'Price'}
              </Table.HeaderCell>
              <Table.HeaderCell
                colSpan={3}
                style={{ background: 'rgba(203,232,238,0.3)' }}
              >
                {french ? 'Date de début' : 'Start Date'}{' '}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{ fontSize: '1rem', verticalAlign: 'top' }}
                    />
                  }
                  position="right center"
                  content={
                    french
                      ? 'La date de début est le mois suivant la date de création/IPO de votre avoir ou avril 2007, selon la date la plus récente.'
                      : 'The start date is the month after the inception/IPO date of your holding or April 2007, whichever comes later.'
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  background: 'rgba(203,232,238,0.3)',
                  position: 'relative',
                  paddingRight: '3rem',
                }}
              >
                {french ? 'Poids' : 'Weight'}{' '}
                <Popup
                  trigger={
                    <div
                      className="round-weights-button"
                      onClick={() =>
                        dispatch(ComparePortfolio.roundWeightsToWholeNumbers())
                      }
                    >
                      <img
                        src={RoundDecimalImage}
                        alt="round weights icon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  }
                  wide
                  position="top center"
                  content={
                    french
                      ? 'Arrondir tous les poids aux nombres entiers.'
                      : 'Round all weights to whole numbers.'
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(this.filterOutGICAndDigitalCurrency()).map(
              (security) => (
                <StyledRow
                  negative={!security.unadjusted_closing_price.CAD}
                  key={security.ticker}
                  style={{
                    height: '70px',
                    position: 'relative',
                  }}
                >
                  <Table.Cell
                    width={5}
                    style={{
                      position: 'relative',
                      zIndex: '0',
                      paddingLeft: '1.2rem',
                    }}
                  >
                    <i
                      className="close"
                      onClick={() =>
                        dispatch(ComparePortfolio.remove(security.ticker))
                      }
                      style={{ zIndex: '1', left: '0' }}
                    >
                      +
                    </i>

                    {security.ticker || 'N/A'}
                    {this.showSecurityProxyName(security) &&
                      !analytics.data.paramsPassed.is_linked && (
                        <Popup
                          style={{ marginTop: '20px' }}
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                                margin: '0',
                              }}
                            />
                          }
                          position="bottom center"
                          wide
                          content={`${
                            french
                              ? `Ceci est l'ETF proxy pour ${this.showSecurityProxyName(
                                  security
                                )}`
                              : `This is the proxy ETF for ${this.showSecurityProxyName(
                                  security
                                )}`
                          }`}
                        />
                      )}
                    <SecurityName>{security.long_name || 'N/A'}</SecurityName>
                    {!security.unadjusted_closing_price.CAD && (
                      <Error>
                        <Popup
                          trigger={
                            <Icon
                              style={{
                                transform: 'translate(-20px, -4px)',
                                position: 'absolute',
                                display: 'block',
                                marginTop: '0',
                              }}
                              name="exclamation circle"
                            />
                          }
                          position="left center"
                          content={
                            french
                              ? 'Malheureusement, cette valeur mobilière ne peut pas être ajoutée car son prix est de 0 $.'
                              : 'Unfortunately, this security cannot be added as its price is $0.'
                          }
                        />
                      </Error>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {!security.exchange_symbol ||
                    security.exchange_symbol === 'NULL'
                      ? 'N/A'
                      : security.exchange_symbol}
                  </Table.Cell>
                  <Table.Cell>
                    {translateAssetClass(security.global_asset_class) || 'N/A'}
                  </Table.Cell>
                  <Table.Cell>
                    $
                    {this.round(
                      security.unadjusted_closing_price[
                        security.default_currency
                      ] || 0
                    )}
                    <Flag
                      name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                      style={{ marginLeft: '0.3rem' }}
                    />
                  </Table.Cell>
                  <Table.Cell colSpan={3}>
                    {security.start_date || 'N/A'}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {!!security.unadjusted_closing_price.CAD ? (
                      <Weight
                        ticker={security.ticker}
                        value={security.weight}
                      />
                    ) : (
                      'N/A'
                    )}
                  </Table.Cell>
                </StyledRow>
              )
            )}
          </Table.Body>
        </Table>

        {!numHoldings ? (
          <div style={{ marginTop: '0.5rem' }}>
            {french
              ? 'Utilisez la barre de recherche ci-dessus pour ajouter des avoirs à ce portefeuille "et si".'
              : 'Use the search bar above to add holdings to this "what-if" portfolio.'}
          </div>
        ) : null}
      </Segment>
    );
  };

  renderGICTable = () => {
    const { comparePortfolioState, dispatch, analytics, french } = this.props;
    const { gic_info } = comparePortfolioState;

    return !this.showAddGICToggle() ? (
      <Segment
        className="basic compact"
        style={{
          marginTop: '2rem',
          padding: '0rem',
          marginBottom: '0rem',
          opacity: comparePortfolioState.isFetching ? 0.5 : 1,
          paddingBottom: '0',
          width: '700px',
        }}
      >
        <Header style={{ textAlign: 'center' }}>GIC/HISA</Header>
        <RemoveAll
          style={{ top: '23px', textAlign: 'center' }}
          onClick={this.removeAllGics}
        >
          {french ? 'effacer' : 'clear'}
        </RemoveAll>
        <Table
          textAlign="center"
          style={{
            marginBottom: '0rem',
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? 'Symbole/nom' : 'Ticker/Name'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? "Classe d'actifs" : 'Asset Class'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? 'Valeur' : 'Value'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: 'rgba(203,232,238,0.3)' }}>
                {french ? "Taux d'intérêt" : 'Interest Rate'}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{
                  background: 'rgba(203,232,238,0.3)',
                  position: 'relative',
                  paddingRight: '3rem',
                }}
              >
                {french ? 'Poids' : 'Weight'}{' '}
                <Popup
                  trigger={
                    <div
                      className="round-weights-button"
                      onClick={() =>
                        dispatch(ComparePortfolio.roundWeightsToWholeNumbers())
                      }
                    >
                      <img
                        src={RoundDecimalImage}
                        alt="round weights icon"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  }
                  wide
                  position="top center"
                  content={
                    french
                      ? 'Arrondir tous les poids aux nombres entiers.'
                      : 'Round all weights to whole numbers.'
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.values(this.getGICHoldings()).map((security) => (
              <StyledRow
                negative={!security.unadjusted_closing_price.CAD}
                key={security.ticker}
                style={{
                  height: '70px',
                  position: 'relative',
                }}
              >
                <Table.Cell
                  width={4}
                  style={{
                    position: 'relative',
                    zIndex: '0',
                    paddingLeft: '1.2rem',
                  }}
                >
                  <i
                    className="close"
                    onClick={() =>
                      dispatch(ComparePortfolio.remove(security.ticker))
                    }
                    style={{ zIndex: '1', left: '0' }}
                  >
                    +
                  </i>

                  {gic_info[security.ticker].gic_ticker ||
                    security.ticker ||
                    'N/A'}
                  {this.showSecurityProxyName(security) &&
                    !analytics.data.paramsPassed.is_linked && (
                      <Popup
                        style={{ marginTop: '20px' }}
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                              margin: '0',
                            }}
                          />
                        }
                        position="bottom center"
                        wide
                        content={`${
                          french
                            ? `Ceci est l'ETF proxy pour ${this.showSecurityProxyName(
                                security
                              )}`
                            : `This is the proxy ETF for ${this.showSecurityProxyName(
                                security
                              )}`
                        }`}
                      />
                    )}
                  <SecurityName>
                    {french ? 'CPG ou CÉIÉ' : 'GIC or HISA'}
                  </SecurityName>
                  {!security.unadjusted_closing_price.CAD && (
                    <Error>
                      <Popup
                        trigger={
                          <Icon
                            style={{
                              transform: 'translate(-20px, -4px)',
                              position: 'absolute',
                              display: 'block',
                              marginTop: '0',
                            }}
                            name="exclamation circle"
                          />
                        }
                        position="left center"
                        content="Unfortunately, this security cannot be added as its price is $0"
                      />
                    </Error>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {french ? 'Revenu fixe' : 'Fixed Income'}
                </Table.Cell>

                <Table.Cell width={2}>
                  $
                  {this.round(
                    security.unadjusted_closing_price[
                      security.default_currency
                    ] || 0
                  )}
                  <Flag
                    name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                    style={{ marginLeft: '0.3rem' }}
                  />
                </Table.Cell>
                <Table.Cell with={3}>
                  <InterestRate
                    ticker={security.ticker}
                    value={
                      gic_info[security.ticker] &&
                      gic_info[security.ticker].gic_rate !== ''
                        ? Math.round(
                            gic_info[security.ticker].gic_rate * 10000
                          ) / 100
                        : ''
                    }
                  />
                </Table.Cell>
                <Table.Cell width={3}>
                  {!!security.unadjusted_closing_price.CAD ? (
                    <Weight ticker={security.ticker} value={security.weight} />
                  ) : (
                    'N/A'
                  )}
                </Table.Cell>
              </StyledRow>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    ) : null;
  };

  render() {
    const { comparePortfolioState, dispatch, analytics, user_region, french } =
      this.props;
    let { name } = comparePortfolioState.original;
    const { data } = analytics;
    const {
      search,
      advisorFee,
      gic_info,
      isFetchingSearchResults,
      addGicToggle,
      addBtcToggle,
      addAdvisorFeeToggle,
      isFetching,
      gic_new_generic_ticker,
    } = comparePortfolioState;
    const { gic_ticker, gic_name, gic_rate } = gic_new_generic_ticker
      ? gic_info[gic_new_generic_ticker] || {}
      : {};

    const gicWeight = this.getNonTypicalWeight(gic_new_generic_ticker); // the weight of the gic that's being edited in the gic toggle (NOT in the GIC table)
    const btcWeight = this.getNonTypicalWeight('~BTCUSDT');
    const ethWeight = this.getNonTypicalWeight('~ETHUSDT');
    const numHoldings = Object.keys(
      this.filterOutGICAndDigitalCurrency()
    ).length;

    // if name is null, check to see if this portfolio is an account that was analyzed
    // if it is, set the name to '<account.user_institution>: <account.label>'
    name = name || this.getPortfoliosAssociatedAccountName();

    return (
      <div>
        {isFetching ? <Loading style={{ top: '-3rem' }} /> : null}
        <StyledDiv>
          <Fade duration={1500}>
            <div>
              <Header style={{ textAlign: 'center', width: '100%' }}>
                <span style={{ marginBottom: '1rem' }}>
                  {french
                    ? 'Votre portefeuille hypothétique'
                    : 'Your What-if Portfolio'}
                </span>
                {Object.values(this.filterOutGICAndDigitalCurrency()).filter(
                  (security) => !!this.showSecurityProxyName(security)
                ).length > 0 ? (
                  <Header.Subheader
                    style={{
                      textAlign: 'justify',
                      maxWidth: '48rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    {french
                      ? `Créez un portefeuille "et si" ci-dessous en utilisant votre portefeuille actuel ${
                          name ? `(${name})` : ''
                        } comme point de départ. La période de comparaison est définie à la plus longue histoire commune des deux portefeuilles que vous comparez. Par conséquent, les scores du portefeuille actuel peuvent changer, car son historique peut maintenant être plus court. ${
                          !data.paramsPassed.is_linked
                            ? 'Notez que des ETF de substitution sont utilisés ci-dessous.'
                            : ''
                        }`
                      : `Create a "what-if" portfolio below using your current portfolio ${
                          name ? `(${name})` : ''
                        } as a starting point. The comparison period is set to the longest common history of the two portfolios you are comparing. Therefore, the scores of the current portfolio may change, as its history may now be shorter. ${
                          !data.paramsPassed.is_linked
                            ? 'Note that proxy ETFs are being used below.'
                            : ''
                        }`}
                  </Header.Subheader>
                ) : (
                  <Header.Subheader
                    style={{
                      textAlign: 'justify',
                      maxWidth: '48rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    {french
                      ? `Créez un portefeuille "et si" ci-dessous en utilisant votre portefeuille actuel ${
                          name ? `(${name})` : ''
                        } comme point de départ. La période de comparaison est définie à la plus longue histoire commune des deux portefeuilles que vous comparez. Par conséquent, les scores du portefeuille actuel peuvent changer, car son historique peut maintenant être plus court.`
                      : `Create a "what-if" portfolio below using your current portfolio ${
                          name ? `(${name})` : ''
                        } as a starting point. The comparison period is set to the longest common history of the two portfolios you are comparing. Therefore, the scores of the current portfolio may change, as its history may now be shorter.`}
                  </Header.Subheader>
                )}
                {analytics.data.paramsPassed.is_linked ? (
                  <Header.Subheader
                    style={{
                      marginTop: '1rem',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      textAlign: 'justify',
                      maxWidth: '48rem',
                    }}
                  >
                    {french
                      ? `Si vous voyez un bascule "Inclure tout" en haut à gauche de votre tableau de bord, cela signifie que vous avez une sécurité qui a une courte histoire. Décidez si vous souhaitez utiliser le bascule car cette décision est reportée à la comparaison ici. `
                      : `If you see an "Include All" toggle at the top left corner of your Scorecard, that means you have a security that has a short history. Decide whether you want to use the toggle as that decision is carried over to the comparison here. `}
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                          }}
                        />
                      }
                      position="right center"
                      content={
                        french
                          ? 'Notez que "Inclure tout" s\'applique automatiquement à votre portefeuille hypothétique "et si", même si vous ne l\'avez pas choisi pour votre portefeuille réel.'
                          : 'Note that "Include All" automatically applies to your hypothetical What-if portfolio, even if you did not choose it for your actual portfolio.'
                      }
                      wide
                    />{' '}
                  </Header.Subheader>
                ) : null}
              </Header>
              <Divider style={{ width: '90%' }} />
              <TableContainer redborder={this.isDisabled() && this.labelData()}>
                <Header style={{ marginTop: '0' }}>
                  {french ? 'Avoirs' : 'Holdings'}
                </Header>
                <SearchTicker
                  loading={comparePortfolioState.isFetchingSearchResults}
                  results={getTickerSearchResult(search)}
                  featureAction={ComparePortfolio}
                  region={this.decideRegion()}
                  french={french}
                />

                {this.renderHoldingsTable()}
                {this.renderGICTable()}

                {/* over/under weight message with popup */}
                {!!this.labelData() && this.isDisabled() && (
                  <Segment className="over-under-message">
                    {true && (
                      <div>
                        <Popup
                          // mouseEnterDelay={300}
                          on="hover"
                          trigger={
                            <Label>
                              {`${this.labelData().val}% ${
                                this.labelData().msg
                              }`}
                            </Label>
                          }
                          position="bottom center"
                          wide
                          content={
                            french
                              ? "Les poids doivent s'additionner à 100 % ± 0,1 %."
                              : 'The weights should add to 100% ± 0.1%.'
                          }
                        />
                        <Popup
                          // mouseEnterDelay={300}
                          on="hover"
                          trigger={
                            <RescaleLabel
                              onClick={() => {
                                dispatch(
                                  ComparePortfolio.rescaleWeights(
                                    this.labelData().realVal
                                  )
                                );
                              }}
                            >
                              {french
                                ? 'Cliquez pour rééchelonner'
                                : 'Click to Rescale'}
                            </RescaleLabel>
                          }
                          position="bottom center"
                          wide
                          content={
                            french
                              ? "Rééchelonner les poids proportionnellement pour ajouter jusqu'à 100%"
                              : 'Rescale weights proportionately to add up to 100%'
                          }
                        />
                      </div>
                    )}
                  </Segment>
                )}

                <div
                  className="compare-whatif-toggles"
                  style={{ marginTop: '3rem' }}
                >
                  <div style={{ textAlign: 'left' }}>
                    {gic_new_generic_ticker && this.showAddGICToggle() && (
                      <div style={{ marginTop: '-1rem' }}>
                        <div
                          style={{ display: 'inline-block', width: '220px' }}
                        >
                          <Header
                            style={{
                              fontSize: '14.72px',
                              margin: '0',
                            }}
                          >
                            {this.props.toggle_region === 'US' ||
                            user_region === 'US'
                              ? french
                                ? 'Ajouter un CD'
                                : 'Add CD'
                              : french
                              ? 'Ajouter CPG/CEIE'
                              : 'Add GIC/HISA'}
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{
                                    fontSize: '15px',
                                    verticalAlign: 'initial',
                                    color: 'dimgrey',
                                  }}
                                />
                              }
                              position="top center"
                              wide
                            >
                              <Popup.Content>
                                {this.props.toggle_region === 'US' ||
                                user_region === 'US'
                                  ? french
                                    ? 'Cette catégorie est destinée aux titres à revenu fixe tels que les certificats de dépôt (CD) et les dépôts à terme (TD).'
                                    : 'This category is for fixed interest securities such as Certificates of Deposits (CDs) and term deposits (TDs).'
                                  : french
                                  ? "Cette catégorie est destinée aux titres à revenu fixe tels que les certificats de placement garanti (CPG) ou les comptes d'épargne à intérêt élevé (CEIE)."
                                  : 'This category is for fixed interest securities such as Guaranteed Investment Certificates (GICs) or High Interest Savings Accounts (HISAs).'}
                              </Popup.Content>
                            </Popup>
                          </Header>

                          <Header.Subheader
                            style={{
                              color: 'rgba(0,0,0,0.5)',
                              fontSize: '0.9rem',
                            }}
                          >
                            {french ? '(Facultatif)' : '(Optional)'}
                          </Header.Subheader>
                        </div>
                        <OptionsWrapper>
                          <div
                            className={cn('select left', {
                              active: addGicToggle,
                            })}
                            onClick={() =>
                              dispatch(
                                ComparePortfolio.updateToggle(
                                  'addGicToggle',
                                  true
                                )
                              )
                            }
                          >
                            {french ? 'Oui' : 'Yes'}
                          </div>
                          <div
                            className={cn('select right', {
                              active: !addGicToggle,
                            })}
                            onClick={() => {
                              dispatch(
                                ComparePortfolio.updateToggle(
                                  'addGicToggle',
                                  false
                                )
                              );
                              dispatch(
                                ComparePortfolio.remove(gic_new_generic_ticker)
                              );
                            }}
                          >
                            {french ? 'Non' : 'No'}
                          </div>
                        </OptionsWrapper>
                        {addGicToggle ? (
                          <div>
                            <Field>
                              <label>{french ? 'Poids' : 'Weight'}</label>
                              <input
                                name="gic_weight"
                                type="number"
                                placeholder="%"
                                value={gicWeight}
                                onChange={this.handleChange}
                              />
                              {gic_rate && !gicWeight ? (
                                <ErrorLabel>
                                  {french
                                    ? 'Mettre à jour le poids'
                                    : 'Update weight'}
                                </ErrorLabel>
                              ) : null}
                            </Field>
                            <Field>
                              <label style={{ textAlign: 'left' }}>
                                {french ? "Taux d'intérêt" : 'Interest Rate'}
                                <br />
                                <span
                                  style={{
                                    color: 'rgba(0,0,0,0.5)',
                                    fontSize: '0.9rem',
                                  }}
                                >
                                  {french ? '(ex. 0.7%)' : '(e.g., 0.7%)'}
                                </span>
                              </label>
                              <input
                                name="gic_rate"
                                type="number"
                                placeholder="%"
                                value={
                                  gic_info[gic_new_generic_ticker] &&
                                  gic_info[gic_new_generic_ticker].gic_rate !==
                                    ''
                                    ? Math.round(
                                        gic_info[gic_new_generic_ticker]
                                          .gic_rate * 10000
                                      ) / 100
                                    : ''
                                }
                                onChange={this.handleChange}
                              />
                              {gicWeight && !gic_rate ? (
                                <ErrorLabel>
                                  {french
                                    ? "Mettre à jour le taux d'intérêt"
                                    : 'Update interest rate'}
                                </ErrorLabel>
                              ) : null}
                            </Field>
                            <Field center>
                              <label>
                                {french ? 'Symbole' : 'Ticker'}
                                <Popup
                                  trigger={
                                    <Icon
                                      name="question circle outline"
                                      style={{
                                        fontSize: '15px',
                                        verticalAlign: 'initial',
                                        color: 'dimgrey',
                                      }}
                                    />
                                  }
                                  position="top center"
                                  wide
                                >
                                  <Popup.Content>
                                    {french
                                      ? 'Pour affichage dans le tableau de bord du portefeuille.'
                                      : 'For display in the Portfolio Scorecard.'}
                                  </Popup.Content>
                                </Popup>
                              </label>
                              <input
                                name="gic_ticker"
                                value={gic_ticker || ''}
                                onChange={(e) =>
                                  dispatch(
                                    ComparePortfolio.updateGicInfo(
                                      e.target.name,
                                      e.target.value
                                    )
                                  )
                                }
                              />
                              {(gicWeight || gic_rate) && !gic_ticker ? (
                                <ErrorLabel>
                                  {french
                                    ? 'Mettre à jour le symbole'
                                    : 'Update ticker'}
                                </ErrorLabel>
                              ) : null}
                            </Field>
                            <Field center>
                              <label>
                                {french ? 'Nom' : 'Name'}
                                <Popup
                                  trigger={
                                    <Icon
                                      name="question circle outline"
                                      style={{
                                        fontSize: '15px',
                                        verticalAlign: 'initial',
                                        color: 'dimgrey',
                                      }}
                                    />
                                  }
                                  position="top center"
                                  wide
                                >
                                  <Popup.Content>
                                    {french
                                      ? 'Pour affichage dans le tableau de bord du portefeuille.'
                                      : 'For display in the Portfolio Scorecard.'}
                                  </Popup.Content>
                                </Popup>
                              </label>
                              <input
                                name="gic_name"
                                placeholder={
                                  french ? '(Facultatif)' : '(Optional)'
                                }
                                value={gic_name || ''}
                                onChange={(e) =>
                                  dispatch(
                                    ComparePortfolio.updateGicInfo(
                                      e.target.name,
                                      e.target.value
                                    )
                                  )
                                }
                              />
                            </Field>
                          </div>
                        ) : null}
                      </div>
                    )}
                    <div
                      style={
                        this.showAddGICToggle() ? { marginTop: '1rem' } : {}
                      }
                    >
                      <div style={{ display: 'inline-block', width: '220px' }}>
                        <Header
                          style={{
                            fontSize: '14.72px',
                            margin: '0',
                          }}
                        >
                          {french
                            ? 'Ajouter Bitcoin et/ou Ethereum'
                            : 'Add Bitcoin and/or Ethereum'}
                          <Popup
                            trigger={
                              <Icon
                                name="question circle outline"
                                style={{
                                  fontSize: '15px',
                                  verticalAlign: 'initial',
                                  color: 'dimgrey',
                                }}
                              />
                            }
                            position="top center"
                            wide
                          >
                            <Popup.Content>
                              {french
                                ? 'Bitcoin et Ethereum peuvent être ajoutés pour tester des idées de portefeuilles hypothétiques. Veuillez noter que notre analyse est basée sur les prix historiques sur le marché des cryptomonnaies, car les ETF de cryptomonnaie sont trop récents. Nos données remontent à octobre 2014 pour Bitcoin et à septembre 2015 pour Ethereum.'
                                : 'Bitcoin and Ethereum can be added to test hypothetical portfolio ideas. Please note that our analysis is based on historical prices in the coin market, as cryptocurrency ETFs are too new. Our data goes back to October 2014 for Bitcoin and to September 2015 for Ethereum.'}
                            </Popup.Content>
                          </Popup>
                        </Header>

                        <Header.Subheader
                          style={{
                            color: 'rgba(0,0,0,0.5)',
                            fontSize: '0.9rem',
                          }}
                        >
                          {french ? '(Facultatif)' : '(Optional)'}
                        </Header.Subheader>
                      </div>

                      <OptionsWrapper btc>
                        <div
                          className={cn('select left', {
                            active: addBtcToggle,
                          })}
                          onClick={() =>
                            dispatch(
                              ComparePortfolio.updateToggle(
                                'addBtcToggle',
                                true
                              )
                            )
                          }
                        >
                          {french ? 'Oui' : 'Yes'}
                        </div>
                        <div
                          className={cn('select right', {
                            active: !addBtcToggle,
                          })}
                          onClick={() => {
                            dispatch(ComparePortfolio.remove('~BTCUSDT'));
                            dispatch(ComparePortfolio.remove('~ETHUSDT'));
                            dispatch(
                              ComparePortfolio.updateToggle(
                                'addBtcToggle',
                                false
                              )
                            );
                          }}
                        >
                          {french ? 'Non' : 'No'}
                        </div>
                      </OptionsWrapper>
                      {addBtcToggle ? (
                        <div>
                          <Field>
                            <label>
                              {french ? 'Poids de Bitcoin' : 'Bitcoin Weight'}
                            </label>
                            <input
                              name="btc_weight"
                              type="number"
                              placeholder="%"
                              value={btcWeight}
                              onChange={this.handleChange}
                            />
                          </Field>
                          <Field>
                            <label>
                              {french ? 'Poids de Ethereum' : 'Ethereum Weight'}
                            </label>
                            <input
                              name="eth_weight"
                              type="number"
                              placeholder="%"
                              value={ethWeight}
                              onChange={this.handleChange}
                            />
                          </Field>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </TableContainer>
              <div>
                <Button
                  className={cn('left centered', {
                    disabled: this.isDisabled(),
                  })}
                  style={{
                    marginTop: '3rem',
                    position: 'relative',
                    display: 'inline-block',
                  }}
                  color={colors.red}
                  icon="law"
                  onClick={this.compare}
                >
                  {french ? 'Comparer' : 'Compare'}
                </Button>
              </div>
            </div>
          </Fade>
        </StyledDiv>
      </div>
    );
  }
}

WhatIfCustomization.propTypes = {
  setView: PropTypes.func.isRequired,
  analytics: PropTypes.object,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  comparePortfolioState: PropTypes.object,
  match: PropTypes.object.isRequired,
  account: PropTypes.object,
  history: PropTypes.object.isRequired,
  type: PropTypes.string,
  selectedAccountOverview: PropTypes.object,
  selectedAccountList: PropTypes.array,
  overview: PropTypes.object,
  user_region: PropTypes.string,
  toggle_region: PropTypes.string,
  french: PropTypes.bool,
};

WhatIfCustomization.defaultProps = {
  dispatch: () => null,
  account: {},
  comparePortfolioState: {},
  analytics: {},
  type: '',
  selectedAccountOverview: {},
  selectedAccountList: [],
  overview: {},
  user_region: 'CA',
  toggle_region: 'CA',
  french: false,
};

export default withRouter(
  connect((state) => ({
    comparePortfolioState: state.ComparePortfolio,
    analytics: state.Analytics,

    selectedAccountList: state.Accounts.selectedAccountList,
    selectedAccountOverview: state.Accounts.selectedAccountOverview,
    overview: state.Accounts.overview,
    user_region: state.Storage.user_region,
    toggle_region: state.Storage.toggle_region || 'CA',
    french: state.Storage.language === 'fr',
  }))(WhatIfCustomization)
);

const TableContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  & {
    border: 2px solid ${(props) => (props.redborder ? 'red' : 'transparent')};

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-radius: 8px;
    position: relative;
    .over-under-message {
      padding: 0;
      position: absolute;

      bottom: 0;
      margin: 0;
      div {
        bottom: -11px;
      }
    }
  }
`;

const StyledDiv = styled.div`
  & {
    // border: 2px solid ${colors.blue};
    // border-radius: 4px;

    width: 100%;
    height: 100%;
    display: flex;
    padding: 3rem 0;
    flex-direction: column;
    justify-content: flex-start;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      div.search {
        .results {
          .category {
            align-items: flex-start;
          }
        }
      }
    }
    div.compare-whatif-toggles {
      width: 50%;
      display: block;
    }
  }

  .round-weights-button {
    display: inline-block;
    padding: 0 0.5rem;
    position: absolute;
    right: 0.5rem;
    bottom: 11px;
    background-color: white;
    border-radius: 0.28571429rem;

    border: 2px solid ${colors.blue};
    color: black;
    height: 22px;
    -webkit-box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.3);
  }

  .round-weights-button:active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .round-weights-button:hover {
    cursor: pointer;
  }

  #table-container {
    text-align: center;
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative;

  .close {
    position: absolute;
    top: calc(10% - 2px);
    display: block;
    margin: -10px;
    margin-top: 20px;
    z-index: 100;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const RemoveAll = styled.div`
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  width: 50px;
  font-size: 0.9rem;
  border-radius: 10px;
  line-height: 20px;
  border: 1px solid #dededf;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 1px solid #db2828;
    background: #fff6f6;
  }
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;

const Label = styled.div`
  display: inline-block;
  position: absolute;
  bottom: -13px;
  left: 50%;
  color: white;
  transform: translateX(-100%);
  padding: 1px 20px;
  font-size: 0.9rem;
  background: ${colors.red};
  text-align: center;
  border-radius: 12px;
  min-width: 120px;
`;
const RescaleLabel = styled.div`
  display: inline-block;
  position: absolute;
  bottom: -13px;
  left: 50%;
  color: white;
  transform: translateX(10%);
  padding: 1px 20px;
  font-size: 0.9rem;
  background: ${colors.red};
  text-align: center;
  border-radius: 12px;
  min-width: 140px;
  cursor: pointer;
`;

const Field = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 32px;
  label {
    display: block;
    float: left;
  }
  input {
    position: absolute;
    left: calc(50% - 47px);
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 93px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;
    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
      text-align: ${(props) => (props.center ? 'center' : 'right')};
    }
    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const OptionsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 0.25rem;
  .select {
    display: inline-block;
    padding: 0.1rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }
    &.active {
      box-shadow: none;
      background-color: ${colors.blue};
      color: white;
    }
    &.left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const ErrorLabel = styled.div`
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  left: calc(50% + 58px);
  text-transform: initial;

  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(3%, 0) rotate(45deg);
    background: inherit;
    left: -5px;
    margin-top: -4px;
    top: 50%;
    border-width: 0 0 1px 1px;
    border-style: solid;
  }
`;
