import * as Alert from './alert';
import { request } from './api';

/**
 * Action constants
 */
export const BUILDCUSTOM_REQUEST = 'BUILDCUSTOM_REQUEST';
export const BUILDCUSTOM_GIC_REQUEST = 'BUILDCUSTOM_GIC_REQUST';

export const BUILDCUSTOM_SEARCH_SUCCESS = 'BUILDCUSTOM_SEARCH_SUCCESS';
export const BUILDCUSTOM_ADD_SUCCESS = 'BUILDCUSTOM_ADD_SUCCESS';
export const BUILDCUSTOM_UPDATE_WEIGHT = 'BUILDCUSTOM_UPDATE_WEIGHT';
export const BUILDCUSTOM_UPDATE_GIC = 'BUILDCUSTOM_UPDATE_GIC';
export const BUILDCUSTOM_UPDATE_TOGGLE = 'BUILDCUSTOM_UPDATE_TOGGLE';
export const BUILDCUSTOM_REMOVE_SECURITY = 'BUILDCUSTOM_REMOVE_SECURITY';
export const BUILDCUSTOM_REMOVE_ALL = 'BUILDCUSTOM_REMOVE_ALL';
export const BUILDCUSTOM_LOAD_PORTFOLIO = 'BUILDCUSTOM_LOAD_PORTFOLIO';

/**
 * Action creators
 */
export function fetchId(id) {
  return (dispatch) => {
    dispatch({ type: BUILDCUSTOM_REQUEST });

    return dispatch(request('get', `/portfolio/${id}/`)).then(
      (portfolio) => {
        const gicFormat = /^GIC\d*:MKT$/;
        const weights = portfolio.weights.split(',');
        const symbols = portfolio.symbols.split(',');
        const gicAssignedTicker = symbols.find((holding) =>
          gicFormat.test(holding)
        );

        // Reset the toggles
        dispatch(updateToggle('addGicToggle', false));
        dispatch(updateToggle('addBtcToggle', false));

        let gicSecurity = {};
        let gicInfo = {};
        if (gicAssignedTicker) {
          const gicIndex = symbols.indexOf(gicAssignedTicker);
          gicSecurity = {
            ticker: gicAssignedTicker,
            weight: weights[gicIndex] * 100,
          };
          symbols.splice(gicIndex, 1);
          weights.splice(gicIndex, 1);
          gicInfo = portfolio.gic_info[gicAssignedTicker];

          if (gicInfo.gic_rate) {
            const rate = (gicInfo.gic_rate * 10000) / 100;

            gicInfo.gic_rate = Number(rate.toFixed(2).split('.')[1])
              ? rate.toFixed(2)
              : rate.toFixed(0);
          }

          dispatch(updateToggle('addGicToggle', true));
        }

        if (symbols.includes('~BTCUSDT') || symbols.includes('~ETHUSDT')) {
          dispatch(updateToggle('addBtcToggle', true));
        }

        const parsed = symbols.reduce((acc, val, idx) => {
          acc[val] = Math.round(Number(weights[idx]) * 10000) / 100;

          return acc;
        }, {});

        const max = symbols.length > 20 ? `&max=${symbols.length}` : '';

        return dispatch(
          request('get', '/security/', { query: `?symbols=${symbols}${max}` })
        ).then(
          (data) => {
            const parseData = data.reduce((acc, val) => {
              acc[val.ticker] = {
                ...val,
                weight: parsed[val.ticker],
              };

              return acc;
            }, {});
            if (Object.keys(gicSecurity).length !== 0) {
              parseData[gicAssignedTicker] = gicSecurity;
            }

            return dispatch({
              type: BUILDCUSTOM_LOAD_PORTFOLIO,
              portfolio: parseData,
              gicInfo,
            });
          },
          (error) => {
            dispatch({ type: BUILDCUSTOM_ADD_SUCCESS, security: null });

            return dispatch(
              Alert.show({ type: 'error', msg: parseErr(error) })
            );
          }
        );
      },
      (error) => {
        dispatch({ type: BUILDCUSTOM_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function add(id) {
  return (dispatch) => {
    dispatch({ type: BUILDCUSTOM_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) => dispatch({ type: BUILDCUSTOM_ADD_SUCCESS, security: data }),
      (error) => {
        dispatch({ type: BUILDCUSTOM_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function addGicBtcEth(ticker) {
  return {
    type: BUILDCUSTOM_ADD_SUCCESS,
    security: { ticker },
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: BUILDCUSTOM_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: BUILDCUSTOM_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: BUILDCUSTOM_SEARCH_SUCCESS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function updateWeight(ticker, value) {
  return {
    type: BUILDCUSTOM_UPDATE_WEIGHT,
    ticker,
    value: value === '' ? '' : Number(value),
  };
}

export function updateGicInfo(field, value) {
  return {
    type: BUILDCUSTOM_UPDATE_GIC,
    field,
    value,
  };
}

export function updateToggle(field, value) {
  return {
    type: BUILDCUSTOM_UPDATE_TOGGLE,
    field,
    value,
  };
}

export function remove(ticker) {
  return {
    type: BUILDCUSTOM_REMOVE_SECURITY,
    ticker,
  };
}

export function removeAll() {
  return {
    type: BUILDCUSTOM_REMOVE_ALL,
  };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
