import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { translateText } from '../utils/helpers';

class SearchTicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      focusOnSearch: false,
      displaySearchResult: false,
      selectedIndex: -1,
      selectedCategory: 0,
    };
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('resize', this.handleWindowResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.displaySearchResult && this.state.displaySearchResult) {
      this.scrollToTop();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  scrollToTop = () => {
    this.setState({ selectedIndex: -1, selectedCategory: 0 });
    if (this.container) {
      this.container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  scrollContainerBy(distance) {
    this.container.scrollBy({ top: distance, behavior: 'smooth' });
  }
  handleKeyDown = (e) => {
    const { results } = this.props;
    const { selectedIndex, selectedCategory } = this.state;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.scrollContainerBy(-20);
      if (selectedIndex > 0) {
        this.setState({ selectedIndex: selectedIndex - 1 });
      } else if (selectedCategory > 0) {
        const previousCategory =
          results[Object.keys(results)[selectedCategory - 1]];
        this.setState({
          selectedIndex: previousCategory.results.length - 1,
          selectedCategory: selectedCategory - 1,
        });
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.scrollContainerBy(20);
      const currentCategory = results[Object.keys(results)[selectedCategory]];
      if (selectedIndex < currentCategory.results.length - 1) {
        this.setState({ selectedIndex: selectedIndex + 1 });
      } else if (selectedCategory < Object.keys(results).length - 1) {
        this.setState({
          selectedIndex: 0,
          selectedCategory: selectedCategory + 1,
        });
      }
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault();
      const selectedItem =
        results[Object.keys(results)[selectedCategory]].results[selectedIndex];
      if (this.props.featureAction) {
        this.handleResultSelect(selectedItem.title);
        this.handleBlur();
      } else {
        this.props.customResultSelect(selectedItem.title);
        this.handleBlur();
      }
    }
  };

  handleResultSelect = (title) => {
    const { dispatch, featureAction } = this.props;
    this.props.updateDisplaySearchResult(false);

    return dispatch(featureAction.add(title));
  };

  datasetKeyProvider = () => btoa(Math.random()).substring(0, 12);

  handleSearchChange = (value) => {
    const { dispatch, featureAction, region, user_region } = this.props;

    const getRegion = () => region || user_region || 'CA';

    return (
      !!value.trim() &&
      dispatch(
        featureAction.search(window.encodeURIComponent(value), getRegion())
      )
    );
  };

  handleBlur = () => {
    this.props.updateDisplaySearchResult(false);
    this.setState({
      focusOnSearch: false,
      displaySearchResult: false,
      selectedIndex: -1,
      selectedCategory: 0,
    });
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.props.updateDisplaySearchResult(true);

    this.setState({
      selectedIndex: -1,
      selectedCategory: 0,
      value,
      focusOnSearch: true,
      displaySearchResult: true,
    });

    return this.handleSearchChange(value);
  };

  handleCustomSearchChange = (e) => {
    const { customSearchChange } = this.props;
    const { value } = e.target;
    this.props.updateDisplaySearchResult(true);
    this.setState({
      selectedIndex: -1,
      selectedCategory: 0,
      focusOnSearch: true,
      displaySearchResult: true,
      value,
    });

    return customSearchChange(value);
  };

  resetSearch = () => {
    this.setState({
      value: '',
      focusOnSearch: true,
      displaySearchResult: true,
      selectedIndex: -1,
      selectedCategory: 0,
    });
  };

  resetSearch2 = () => {
    this.setState({
      focusOnSearch: true,
      displaySearchResult: true,
      selectedIndex: -1,
      selectedCategory: 0,
    });
  };

  renderSearchResult = (results) => {
    const { featureAction, customResultSelect, globe } = this.props;
    const { value, displaySearchResult } = this.state;
    const shortenDescription = (title, description, price) => {
      title = title || '';
      description = description || '';
      price = price || '';
      const totalLength = title.length + description.length + price.length;

      return totalLength > 76
        ? `${description.substring(0, 65)}...`
        : description;
    };
    const renderCategoryItems = (list, categoryIndex) =>
      list.map((item, index) => {
        const isSelected =
          this.state.selectedCategory === categoryIndex &&
          this.state.selectedIndex === index;
        const shortDescription = shortenDescription(
          item.title,
          item.description,
          item.price
        );

        return shortDescription === item.description ? (
          <div
            className={`result${isSelected ? ' selected' : ''}`}
            key={item.title}
            onMouseDown={() => {
              if (featureAction) {
                this.handleResultSelect(item.title);
              } else {
                this.props.updateDisplaySearchResult(false);
                customResultSelect(item.title);
              }
            }}
            onMouseEnter={() => {
              this.setState({
                selectedIndex: index,
                selectedCategory: categoryIndex,
              });
            }}
            style={
              isSelected
                ? selectedItemStyle
                : window.innerWidth >= 700
                ? { marginLeft: '130px' }
                : { marginLeft: '0' }
            }
          >
            <div className="content">
              <div className="price">{item.price}</div>
              <div className="title">{item.title}</div>
              <div className="description">{item.description}</div>
            </div>
          </div>
        ) : (
          <div
            className={`results ${globe ? 'globe' : ''}`}
            key={this.datasetKeyProvider()}
            ref={(node) => {
              this.container = node;
            }}
          >
            <Popup
              key={item.title}
              trigger={
                <div
                  className="result"
                  onMouseDown={() => {
                    if (featureAction) {
                      this.handleResultSelect(item.title);
                    } else {
                      customResultSelect(item.title);
                    }
                  }}
                  style={
                    isSelected
                      ? selectedItemStyle
                      : window.innerWidth >= 700
                      ? { marginLeft: '130px' }
                      : { marginLeft: '0' }
                  }
                >
                  <div className="content">
                    <div className="price">{item.price}</div>
                    <div className="title">{item.title}</div>
                    <div className="description">
                      {shortenDescription(
                        item.title,
                        item.description,
                        item.price
                      )}
                    </div>
                  </div>
                </div>
              }
              position="top center"
              wide
            >
              {item.description}
            </Popup>
          </div>
        );
      });

    return (
      <div
        className={`results transition ${
          value.length && displaySearchResult ? 'visible' : ''
        } ${globe ? 'globe' : ''}`}
        ref={(node) => {
          this.container = node;
        }}
      >
        {Object.keys(results).length ? (
          Object.keys(results).map((category, categoryIndex) => (
            <div
              className={window.innerWidth <= 700 ? '' : 'category'}
              key={category}
            >
              <div
                className="name"
                style={{
                  width: '130px',
                  padding: '0.85em 1em',
                  display: window.innerWidth <= 700 ? 'none' : 'block',
                }}
              >
                {category}
              </div>
              {renderCategoryItems(results[category].results, categoryIndex)}
            </div>
          ))
        ) : (
          <div className="message empty">
            <div className="header">No results found.</div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      loading,
      results,
      placeholder,
      featureAction,
      setMargin,
      correlationData,
      handleDeletion,
      hasReachedLimit,
      handleFiveLimit,
      globe,
      french,
    } = this.props;
    const { focusOnSearch } = this.state;

    return (
      <div
        className={`ui category search ${
          focusOnSearch && loading ? 'loading' : ''
        }`}
        style={{
          display: 'inline-block',
          textAlign: 'left',
          margin: setMargin ? '0.7rem' : '0',
        }}
      >
        <div className="ui icon input">
          <input
            style={{ borderRadius: globe && '0' }}
            type="text"
            name="ticker"
            placeholder={
              placeholder || translateText('Search for ticker or name', french)
            }
            value={this.state.value}
            tabIndex="0"
            className="prompt"
            autoComplete="off"
            onChange={
              featureAction ? this.handleChange : this.handleCustomSearchChange
            }
            onFocus={() => {
              this.resetSearch();
              if (hasReachedLimit) {
                handleFiveLimit();
              }
              if (
                correlationData &&
                correlationData.excluded &&
                !!Object.keys(correlationData.excluded).length > 0
              ) {
                handleDeletion(Object.keys(correlationData.excluded)[0]);
              }
            }}
            onBlur={() => {
              this.resetSearch();
              this.handleBlur();
            }}
          />
          <i aria-hidden="true" className="search icon" />
        </div>
        {this.renderSearchResult(results)}
      </div>
    );
  }
}

SearchTicker.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.array,
  dispatch: PropTypes.func,
  featureAction: PropTypes.object,
  placeholder: PropTypes.string,
  customSearchChange: PropTypes.func,
  customResultSelect: PropTypes.func,
  setMargin: PropTypes.bool,
  user_region: PropTypes.string,
  region: PropTypes.string,
  correlationData: PropTypes.object,
  handleDeletion: PropTypes.func,
  handleFiveLimit: PropTypes.func,
  hasReachedLimit: PropTypes.bool,
  globe: PropTypes.bool,
  updateDisplaySearchResult: PropTypes.func,
  french: PropTypes.bool,
};

SearchTicker.defaultProps = {
  loading: false,
  results: [],
  dispatch: () => false,
  customSearchChange: () => false,
  customResultSelect: () => false,
  featureAction: null,
  placeholder: '',
  setMargin: false,
  user_region: '',
  region: 'CA',
  correlationData: {},
  handleDeletion: () => false,
  handleFiveLimit: () => false,
  hasReachedLimit: false,
  globe: false,
  updateDisplaySearchResult: () => false,
  french: false,
};

const selectedItemStyle = {
  marginLeft: '130px',
  backgroundColor: '#f1f1f1',
};

export default connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
}))(SearchTicker);
