import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Icon,
  Popup,
  Header,
  Flag,
  Button as SemanticButton,
} from 'semantic-ui-react';
import cn from 'classnames';
import ReactExport from 'react-data-export';

import { colors } from 'utils/colors';
import { convertToTickersAndWeights } from 'utils/helpers';
import { RebalanceAccount } from 'actions';
import { Segment, Button, Table, Modal } from 'components';
import { translateTableHeadings } from '../../../utils/helpers';

const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

@connect((state) => ({
  rebalanceAcc: state.RebalanceAccount,
  partnerName: state.Storage['partner-name'],
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  french: state.Storage.language === 'fr',
}))
export default class RebalanceModal extends Component {
  state = {
    cashAmount: 0,
    isDeposit: true,
    useCash: false,
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.open && !nextProps.open) {
      this.setState({
        cashAmount: 0,
        isDeposit: true,
        useCash: false,
      });
      this.props.dispatch(RebalanceAccount.clearRebalanceData());
    }
  };

  componentWillUnmount = () => {
    this.props.dispatch(RebalanceAccount.clearRebalanceData());
  };

  getRebalanceData = () => {
    const { rebalanceAcc } = this.props;
    const { rebalanceData } = rebalanceAcc;

    return rebalanceData.reduce((acc, val) => {
      acc[val.ticker] = { ...val };
      return acc;
    }, {});
  };

  getTicker = (ticker) => {
    const gicSamples = [
      'GIC1:MKT',
      'GIC2:MKT',
      'GIC3:MKT',
      'GIC4:MKT',
      'GIC5:MKT',
      'GIC6:MKT',
      'GIC7:MKT',
      'GIC8:MKT',
      'GIC9:MKT',
      'GIC10:MKT',
    ];

    if (gicSamples.includes(ticker)) {
      const { holdings } = this.props;
      const holding = holdings.find((h) => h.ticker === ticker);

      return holding ? holding.gic_ticker : ticker;
    }

    return ticker || 'N/A';
  };

  getOriginalWeight = (ticker) => {
    const { weights, tickers } = this.props.rebalanceAcc.original;
    const originalWeights = weights.split(',');
    const originalTickers = tickers.split(',');
    const idx = originalTickers.findIndex((val) => val === ticker);

    return (Math.round(originalWeights[idx] * 100 * 100) / 100).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  };

  getGainAmountAndPercentage = (holding) => {
    const { pwpaExchangeRate } = this.props;

    if (!holding.unrealized_gain_amount || !holding.unrealized_gain_percent) {
      return 'N/A';
    }

    let unrealizedGainAmount = holding.unrealized_gain_amount;

    // Overview, Group accounts have converted currency at Account.getAccountOverview, so this is only for single linked/manually-entered account
    if (holding.currency === 'USD') {
      unrealizedGainAmount *= pwpaExchangeRate;
    }

    return (
      <div
        style={{
          color:
            holding.unrealized_gain_amount < 0
              ? colors.red
              : holding.unrealized_gain_amount > 0
              ? colors.darkGreen
              : 'inherit',
        }}
      >
        {this.formatNum(unrealizedGainAmount, '$')}
        &nbsp;
        <Flag name="ca" />
        <br />
        {`${Number(
          (holding.unrealized_gain_percent * 100).toFixed(2)
        ).toLocaleString()}%`}
        {holding.unrealized_gain_amount < 0 ? (
          <Icon name="long arrow alternate down" />
        ) : null}
        {holding.unrealized_gain_amount > 0 ? (
          <Icon name="long arrow alternate up" />
        ) : null}
      </div>
    );
  };

  generateDataSet = (data) => {
    const { holdings, rebalanceData } = data;
    const { pwpaExchangeRate } = this.props;
    const { tickers, quantities } = convertToTickersAndWeights(
      Object.values(holdings).filter((val) => !!val.security_detail)
    );
    const tickersArr = tickers ? tickers.split(',') : [];
    const quantitiesArr = quantities ? quantities.split(',') : [];
    const pairTickerQuantity = tickersArr.reduce((result, t, index) => {
      result[t] = quantitiesArr[index];
      return result;
    }, {});

    if (rebalanceData) {
      return rebalanceData.map((row) => {
        const { ticker, security_price } = row;
        const holding = holdings[ticker];
        const securityDetail = holding.security_detail;
        const reformatRebalData = rebalanceData.reduce((result, rebal) => {
          result[rebal.ticker] = rebal;
          return result;
        }, {});
        const gainAmount = (h) => {
          if (!h) return 'N/A';
          if (!h.unrealized_gain_amount) {
            return 'N/A';
          }

          let unrealizedGainAmount = holding.unrealized_gain_amount;

          // Overview, Group accounts have converted currency at Account.getAccountOverview, so this is only for single linked/manually-entered account
          if (holding.currency === 'USD') {
            unrealizedGainAmount *= pwpaExchangeRate;
          }

          return `${Number(
            unrealizedGainAmount.toFixed(2)
          ).toLocaleString()} CAD`;
        };

        const gainPercentage = (h) => {
          if (!h) return 'N/A';
          if (!h.unrealized_gain_percent) {
            return 'N/A';
          }

          return `${Number(
            (holding.unrealized_gain_percent * 100).toFixed(2)
          ).toLocaleString()}%`;
        };

        const template = {
          ticker: holding
            ? `${
                (holding.gic_ticker
                  ? holding.gic_ticker
                  : holding.security_detail.ticker) || 'N/A'
              } / ${
                (holding.gic_ticker
                  ? holding.gic_name
                  : holding.security_detail.long_name) || 'N/A'
              }`
            : 'N/A',
          exchange: securityDetail
            ? securityDetail.exchange_symbol || 'N/A'
            : 'N/A',
          class: securityDetail
            ? securityDetail.global_asset_class || 'N/A'
            : 'N/A',
          currentWeight: securityDetail
            ? `${this.getOriginalWeight(securityDetail.ticker, holdings)}%`
            : 'N/A',
          modelWeight: holding ? `${holding.weight}%` : 'N/A',
          ugl:
            gainAmount(holding) === 'N/A'
              ? 'N/A'
              : `${gainAmount(holding)} / ${gainPercentage(holding)}`,
          calculatedTrades:
            reformatRebalData && reformatRebalData[ticker]
              ? `${
                  reformatRebalData[ticker].rebalancing_buy > 0 ? 'Buy' : 'Sell'
                } ${Math.abs(reformatRebalData[ticker].rebalancing_buy)} shares`
              : 'N/A',
          lastClose: this.formatNum(security_price, '$'),
          currentQuantity: pairTickerQuantity
            ? pairTickerQuantity[ticker]
            : 'N/A',
        };

        return template;
      });
    }

    return [];
  };

  rebalanceAccount = () => {
    const { isDeposit, cashAmount } = this.state;
    const { dispatch, rebalanceAcc } = this.props;
    const holdings = Object.values(rebalanceAcc.holdings).filter(
      (val) => !!val.security_detail
    );
    const { tickers, quantities } = convertToTickersAndWeights(holdings);
    const weights = tickers.split(',').reduce((acc, ticker) => {
      const w = rebalanceAcc.holdings[ticker].weight / 100;

      acc += acc.length ? `,${w}` : `${w}`;

      return acc;
    }, '');

    const body = {
      tickers,
      quantities,
      target_weights: weights,
      cash_currency: 'CAD',
      cash_amount: isDeposit ? Math.abs(cashAmount) : 0 - Math.abs(cashAmount),
    };

    dispatch(RebalanceAccount.rebalance(body));
  };

  handleChange = (e) => this.setState({ cashAmount: e.target.value });

  formatNum = (num, sign) => {
    if (!num && num !== 0) {
      return 'N/A';
    }

    const formatNumToStr = (n) =>
      Number((Math.round(n * 100) / 100).toFixed(2)).toLocaleString();

    return sign === '$'
      ? num < 0
        ? `-$${formatNumToStr(Math.abs(num))}`
        : `$${formatNumToStr(num)}`
      : `${formatNumToStr(num)}%`;
  };

  renderMessage() {
    const { isDeposit, useCash, cashAmount } = this.state;
    const { rebalanceAcc } = this.props;
    const { french } = this.props; // Assuming 'french' is passed as a prop

    return (
      <div>
        {french
          ? 'Si vous avez des liquidités à déployer ou à retirer du compte, nous pouvons les inclure dans le calcul des opérations de rééquilibrage.'
          : 'If you have cash to deploy to or withdraw from the account, we can include it in the calculation of the rebalancing trades.'}
        <Popup
          trigger={<Icon name="question circle outline" />}
          position="bottom center"
          content={
            french
              ? 'Utilisation des prix de clôture.'
              : 'Using closing prices.'
          }
        />
        <CollapseWrapper open={useCash}>
          <Header
            size="medium"
            content={
              french
                ? 'Déployer / Retirer des liquidités?'
                : 'Deploy / Withdraw Cash?'
            }
            style={{
              position: 'absolute',
              top: useCash ? '-10px' : '5px',
              left: '10px',
              background: 'white',
              padding: '0 5px',
              transition: 'all 300ms ease',
              textAlign: 'left',
              marginBottom: '16px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledButton
                icon
                onClick={() => this.setState({ useCash: !useCash })}
                isOpen={!!useCash}
              >
                {useCash ? '-' : '+'}
              </StyledButton>
              {french
                ? 'Déployer / Retirer des liquidités?'
                : 'Deploy / Withdraw Cash?'}
            </div>
            <Header.Subheader style={{ paddingLeft: '2.8rem' }}>
              {french
                ? "Calculez les opérations nécessaires si vous souhaitez ajouter ou retirer de l'argent de ce compte."
                : 'Calculate the trades required if you want to add money to or take money out from this account.'}
            </Header.Subheader>
          </Header>

          <div>
            <Segment
              basic
              compact
              style={{ textAlign: 'left', position: 'relative' }}
            >
              {french ? 'Combien?' : 'How much?'}
              <Input
                name="cashAmount"
                type="number"
                placeholder="$"
                value={cashAmount || ''}
                onChange={this.handleChange}
              />
              <OptionsWrapper style={{ top: 17 }}>
                <div
                  className={cn('select', { active: isDeposit })}
                  onClick={() => this.setState({ isDeposit: true })}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  {french ? 'Déployer' : 'Deploy'}
                </div>
                <div
                  className={cn('select', { active: !isDeposit })}
                  onClick={() => this.setState({ isDeposit: false })}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  {french ? 'Retirer' : 'Withdraw'}
                </div>
              </OptionsWrapper>
            </Segment>
          </div>
        </CollapseWrapper>
        <Button
          className={cn('left centered', { loading: rebalanceAcc.isFetching })}
          color={colors.red}
          icon="chart bar"
          onClick={this.rebalanceAccount}
        >
          {french ? 'Afficher les opérations' : 'Show trades'}
        </Button>
      </div>
    );
  }

  renderTable() {
    const { rebalanceAcc, partnerName } = this.props;
    const { french } = this.props; // Assuming 'french' is passed as a prop
    const isWealthica = partnerName === 'wealthica';
    const rebalanceData = this.getRebalanceData();

    let { holdings } = rebalanceAcc;

    holdings = Object.values(holdings).filter((holding) =>
      holding.security_detail
        ? !!rebalanceData[holding.security_detail.ticker]
        : !!rebalanceData[holding.ticker]
    ); // filter out any holdings that don't have any rebalanceData

    return (
      <Table textAlign="center" style={{ borderColor: colors.red }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
              {translateTableHeadings('Asset', french)}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
              {translateTableHeadings('Buy/Sell', french)}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
              {translateTableHeadings('Quantity', french)}
            </Table.HeaderCell>
            {isWealthica ? (
              <Table.HeaderCell style={{ background: 'rgba(245,93,90,0.2)' }}>
                {french ? 'Gain/Perte non réalisé' : 'Unrealized Gain/Loss'}{' '}
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="top center"
                  content={
                    french
                      ? "Les avoirs sans valeur comptable verront cette valeur fixée à 'N/A'."
                      : 'Holdings that don’t have a book value will have this value set to ‘N/A’.'
                  }
                />
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {holdings.map((holding) => {
            const ticker = holding.security_detail
              ? holding.security_detail.ticker
              : holding.ticker;

            return (
              <StyledRow key={ticker}>
                <Table.Cell>
                  {ticker || 'N/A'}
                  <SecurityName>
                    {(holding.gic_ticker
                      ? holding.gic_name
                      : holding.security_detail.long_name) || 'N/A'}
                  </SecurityName>
                </Table.Cell>
                <Table.Cell>
                  {rebalanceData[ticker]
                    ? rebalanceData[ticker].rebalancing_buy > 0
                      ? french
                        ? 'Acheter'
                        : 'Buy'
                      : french
                      ? 'Vendre'
                      : 'Sell'
                    : 'N/A'}
                </Table.Cell>
                <Table.Cell>
                  {rebalanceData[ticker]
                    ? `${Math.abs(rebalanceData[ticker].rebalancing_buy)} ${
                        french ? 'actions' : 'shares'
                      }`
                    : 'N/A'}
                </Table.Cell>
                {isWealthica ? (
                  <Table.Cell>
                    <div>{this.getGainAmountAndPercentage(holding)}</div>
                  </Table.Cell>
                ) : null}
              </StyledRow>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { account, rebalanceAcc, open, closeModal, partnerName } = this.props;
    const { french } = this.props; // Assuming 'french' is passed as a prop
    const isWealthica = partnerName === 'wealthica';

    return !open ? null : (
      <Modal rebalance open={open} dimmerClick={closeModal}>
        <Modal.Header>
          {!rebalanceAcc.rebalanceData ? (
            <CloseIcon onClick={() => closeModal()}>+</CloseIcon>
          ) : null}
          <Header
            content={
              french
                ? 'Opérations nécessaires pour rééquilibrer aux pondérations du modèle'
                : 'Trades required to rebalance to model weights'
            }
            subheader={
              rebalanceAcc.rebalanceData
                ? french
                  ? 'Ce sont des opérations calculées en utilisant les derniers prix de clôture du marché en fonction des paramètres que vous avez définis. Ils ne doivent en aucun cas être considérés comme des recommandations ou des instructions de trading.'
                  : 'These are trades calculated using the last market closing prices based on the parameters you set. In no way should they be viewed as recommendations or instructions for trading.'
                : ''
            }
          />
        </Modal.Header>
        <Modal.Content style={{ color: '#000' }}>
          {!rebalanceAcc.rebalanceData
            ? this.renderMessage()
            : this.renderTable()}
        </Modal.Content>
        {rebalanceAcc.rebalanceData ? (
          <ExcelFile
            element={
              <Button
                className={cn('left grey-focus2')}
                icon="download"
                color={colors.red}
                style={{ marginRight: '1rem' }}
              >
                {french ? 'Télécharger CSV' : 'Download CSV'}
              </Button>
            }
            filename={
              account
                ? `${french ? 'Rééquilibré' : 'Rebalanced'} ${
                    account.user_institution
                  } ${account.label}`
                : french
                ? 'Rééquilibré'
                : 'Rebalanced'
            }
          >
            {isWealthica ? (
              <ExcelSheet
                data={this.generateDataSet(rebalanceAcc)}
                name={french ? 'Rééquilibrer' : 'Rebalance'}
              >
                <ExcelColumn
                  label={french ? 'Symbole de Ticker' : 'Ticker Symbol'}
                  value="ticker"
                />
                <ExcelColumn
                  label={french ? 'Bourse' : 'Exchange'}
                  value="exchange"
                />
                <ExcelColumn
                  label={french ? 'Classe' : 'Class'}
                  value="class"
                />
                <ExcelColumn
                  label={french ? 'Dernière Clôture' : 'Last Close'}
                  value="lastClose"
                />
                <ExcelColumn
                  label={french ? 'Quantité Actuelle' : 'Current Quantity'}
                  value="currentQuantity"
                />
                <ExcelColumn
                  label={french ? 'Pondération Actuelle' : 'Current Weight'}
                  value="currentWeight"
                />
                <ExcelColumn
                  label={french ? 'Pondération Modèle' : 'Model Weight'}
                  value="modelWeight"
                />
                <ExcelColumn
                  label={
                    french ? 'Gain/Perte Non Réalisé' : 'Unrealized Gain/Loss'
                  }
                  value="ugl"
                />
                <ExcelColumn
                  label={french ? 'Opérations Calculées' : 'Calculated Trades'}
                  value="calculatedTrades"
                />
              </ExcelSheet>
            ) : (
              <ExcelSheet
                data={this.generateDataSet(rebalanceAcc)}
                name={french ? 'Rééquilibrer' : 'Rebalance'}
              >
                <ExcelColumn
                  label={french ? 'Symbole de Ticker' : 'Ticker Symbol'}
                  value="ticker"
                />
                <ExcelColumn
                  label={french ? 'Bourse' : 'Exchange'}
                  value="exchange"
                />
                <ExcelColumn
                  label={french ? 'Classe' : 'Class'}
                  value="class"
                />
                <ExcelColumn
                  label={french ? 'Dernière Clôture' : 'Last Close'}
                  value="lastClose"
                />
                <ExcelColumn
                  label={french ? 'Quantité Actuelle' : 'Current Quantity'}
                  value="currentQuantity"
                />
                <ExcelColumn
                  label={french ? 'Pondération Actuelle' : 'Current Weight'}
                  value="currentWeight"
                />
                <ExcelColumn
                  label={french ? 'Pondération Modèle' : 'Model Weight'}
                  value="modelWeight"
                />
                <ExcelColumn
                  label={french ? 'Opérations Calculées' : 'Calculated Trades'}
                  value="calculatedTrades"
                />
              </ExcelSheet>
            )}
          </ExcelFile>
        ) : null}
        {rebalanceAcc.rebalanceData ? (
          <Button
            className={cn('left grey-focus2')}
            icon="close"
            color={colors.red}
            onClick={() => this.props.closeModal()}
            style={{ marginBottom: '1rem' }}
          >
            {french ? 'Fermer' : 'Close'}
          </Button>
        ) : null}
      </Modal>
    );
  }
}

RebalanceModal.propTypes = {
  dispatch: PropTypes.func,
  holdings: PropTypes.array.isRequired,
  open: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  rebalanceAcc: PropTypes.object,
  account: PropTypes.object,
  partnerName: PropTypes.string,
  pwpaExchangeRate: PropTypes.number,
  french: PropTypes.bool,
};

RebalanceModal.defaultProps = {
  dispatch: () => false,
  rebalanceAcc: {},
  open: null,
  account: {},
  partnerName: '',
  pwpaExchangeRate: 0,
  french: false,
};

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative !important;

  .close {
    display: block;
    margin: -10px;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 1rem 0.2rem;
  padding-top: ${(props) => (props.open ? '2rem' : '4rem')};
  margin: 3rem 0 0 0;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '1700px' : '57px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 11px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.red};
      color: white;
    }
  }
`;

const Input = styled.input`
  margin-left: 1rem;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;
  z-index: 1001;
  color: rgb(0, 0, 0);

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

const StyledButton = styled(SemanticButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem !important;
  width: 2rem !important;
  min-width: 2rem !important; // Ensure the button has a fixed width
  padding: 0 !important; // Remove any default padding
  font-size: 1rem; // Adjust the font size if needed
  line-height: 1;
`;
