import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Storage, Api } from 'actions';
import PropTypes from 'prop-types';

export default () => (DecoratedComponent) => {
  @connect((state) => ({
    pwpaToken: state.Storage['pwpa-token'],
  }))
  class TokenRefresh extends Component {
    constructor(props) {
      super(props);
      this.timerID = null; //
    }

    componentWillMount() {
      this.startTokenRefresh();
    }

    componentDidUpdate(prevProps) {
      const { pwpaToken } = this.props;

      if (pwpaToken !== prevProps.pwpaToken && pwpaToken !== null) {
        this.startTokenRefresh();
      }
    }

    componentWillUnmount() {
      if (this.timerID) {
        clearInterval(this.timerID);
      }
    }

    refreshToken = () => {
      const { dispatch, pwpaToken } = this.props;

      // Clear any existing interval to reset the timer
      if (this.timerID) {
        clearInterval(this.timerID);
      }

      if (pwpaToken) {
        dispatch(
          Api.request('post', '/auth/tokenrefresh/', {
            body: { token: pwpaToken },
          })
        )
          .then((data) => {
            // eslint-disable-next-line no-console
            // console.log('Token refreshed:', data.token);
            dispatch(Storage.setItem('pwpa-token', data.token)); // Update token in storage
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Error refreshing token:', err);
          });
      }
    };

    startTokenRefresh() {
      // refresh token after 15 minutes
      this.timerID = setInterval(this.refreshToken, 15 * 60 * 1000);
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <DecoratedComponent {...this.props} />;
    }
  }

  TokenRefresh.propTypes = {
    pwpaToken: PropTypes.string,
    dispatch: PropTypes.func,
  };

  TokenRefresh.defaultProps = {
    pwpaToken: null,
    dispatch: () => false,
  };

  return TokenRefresh;
};
