import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Message } from 'components';
import { arrangeSector } from 'utils/helpers';
import { ExposureTable, ExposureChart } from '../components';
import { translation } from '../localization';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class MacroFactor extends Component {
  render() {
    const { data } = this.props.analytics;
    const { french } = this.props;
    const language = french ? 'fr' : 'en';

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content={translation[language].RISK_EXPOSURE_ANALYSIS}
          subheader={translation[language].BY_MACRO_FACTOR}
        />
        {typeof data.riskExp.F === 'string' ? (
          <Message visible warning>
            {data.riskExp.F}
          </Message>
        ) : (
          <div>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {french ? (
                    <p>
                      Cette section montre la répartition du risque de votre
                      portefeuille à sept facteurs macroéconomiques.
                    </p>
                  ) : (
                    <p>
                      This section shows your portfolio’s risk attribution to
                      seven macroeconomics factors.
                    </p>
                  )}
                  <Header
                    size="medium"
                    content={translation[language].MACRO_RISK_EXPOSURE}
                    style={{ margin: 0 }}
                  />
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.riskExp.F))}
                    data={Object.values(arrangeSector(data.riskExp.F))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      translation[language].ASSET,
                      <div>
                        {translation[language].MACRO_RISK_EXPOSURE}&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                          style={{ height: 'initial' }}
                        >
                          A negative number means that your portfolio is hedged
                          against that factor. The risk of your portfolio is
                          negatively related to movements in that factor.
                          <br />
                          <br />
                          Factors with {'"-"'} risk exposure are statistically
                          insignificant.
                        </Popup>
                      </div>,
                    ]}
                    totals={['Total', <div>100%</div>]}
                    data={Object.keys(data.riskExp.F).reduce((total, key) => {
                      total.push({
                        ticker: key,
                        macroRiskExp: data.riskExp.F[key],
                      });

                      return total;
                    }, [])}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}
      </Container>
    );
  }
}

MacroFactor.propTypes = {
  analytics: PropTypes.object,
  french: PropTypes.bool.isRequired,
};

MacroFactor.defaultProps = {
  analytics: {},
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
