import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Radar, Line } from 'react-chartjs-2';
import { Statistic, Header, Divider, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Table } from 'components';
import {
  getOverAllScore,
  getOverAllGrade,
  decideCurrency,
} from 'utils/helpers';
import { colors } from 'utils/colors';

@withRouter
class ComparePDF extends Component {
  timeoutId = null;

  constructor(props) {
    super(props);
    const mql = window.matchMedia('print');

    this.state = {
      isPrintReady: false,
      mql,
    };
  }

  componentWillMount() {
    const { mql } = this.state;
    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }

    // resize charts on print
    mql.addListener(this.printListener);

    return false;
  }

  componentDidMount() {
    this.timeoutId = window.setTimeout(
      () => this.setState({ isPrintReady: true }),
      1000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPrintReady } = this.state;
    if (isPrintReady !== prevState.isPrintReady) {
      const originalTitle = document.title;
      document.title = `${originalTitle}.PDF`;
      window.print();
      document.title = originalTitle;
    }
  }

  componentWillUnmount() {
    const { mql } = this.state;
    window.clearTimeout(this.timeoutId);
    mql.removeListener(this.printListener);
  }

  getRadarData = (port1, port2, esg1, esg2) => {
    const { benchmark, esgToggle } = this.props;
    const hash = {
      Diversification: 'Diversification',
      Fees: 'Low Fees',
      Income: 'Income',
      Performance: 'Performance',
    };
    const portScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port1.score[key][benchmark][0]
          : port1.score[key][0];

      return total;
    }, {});
    const compareScores = Object.keys(hash).reduce((total, key) => {
      total[hash[key]] =
        key === ('Performance' || 'Volatility')
          ? port2.score[key][benchmark][0]
          : port2.score[key][0];

      return total;
    }, {});

    return {
      labels: esgToggle
        ? ['Downside Protection', ...Object.keys(portScores), 'ESG']
        : ['Downside Protection', ...Object.keys(portScores)],
      datasets: [
        {
          label: 'Current Portfolio',
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data:
            esgToggle && esg1 && esg1.portfolio.esg_score
              ? [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                  esg1.portfolio.ws_grade[0] || 0,
                ]
              : [
                  port1.score.Volatility[benchmark][0],
                  ...Object.values(portScores),
                ],
        },
        {
          label: 'Comparison Portfolio',
          backgroundColor: 'rgba(255,184,182,0.5)',
          borderColor: colors.red,
          pointBackgroundColor: colors.red,
          pointBorderColor: colors.red,
          lineTension: 0.3,
          data:
            esgToggle && esg2 && esg2.portfolio.esg_score
              ? [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                  esg2.portfolio.ws_grade[0] || 0,
                ]
              : [
                  port2.score.Volatility[benchmark][0],
                  ...Object.values(compareScores),
                ],
        },
      ],
    };
  };

  getChartData = (port1, port2) => ({
    labels: Object.keys(port1.cumrets.portfolio),
    datasets: [
      {
        data: Object.values(port1.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: 'Current Portfolio',
        lineTension: 0,
        fill: false,
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        pointBackgroundColor: colors.blue,
        pointBorderColor: colors.blue,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        data: Object.values(port2.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: 'Comparison Portfolio',
        lineTension: 0,
        fill: false,
        backgroundColor: colors.red,
        borderColor: colors.red,
        pointBackgroundColor: colors.red,
        pointBorderColor: colors.red,
        pointRadius: 0,
        pointHitRadius: 5,
      },
    ],
  });

  getPortfoliosAssociatedAccountName = () => {
    const { analytics } = this.props;
    if (analytics.data.paramsPassed.account)
      if (!analytics.data.paramsPassed.account.label)
        return `${analytics.data.paramsPassed.account.user_institution || ''}`;
      else
        return `${
          analytics.data.paramsPassed.account.user_institution || ''
        }: ${analytics.data.paramsPassed.account.label || ''}`;

    return null;
  };

  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0 %');

  printListener = (e) => {
    const { history, match } = this.props;
    // resize charts on print
    if (e.matches) {
      Object.keys(window.Chart.instances).forEach((key) => {
        window.Chart.instances[key].resize();
      });
    } else {
      history.push(`/portfolio/${match.params.id}/compare`);
    }
  };

  parseDate = () => {
    const { comparePortfolioState } = this.props;
    const { analysis1 } = comparePortfolioState.compareResult;
    const { init_date, end_date } = analysis1.paramsAnalyzed;
    const init = init_date.split('-');
    const end = end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  displayEsg = (esg) => {
    if (!esg) return '-';

    const { portfolio } = esg;

    if (!portfolio.esg_score) return '-';

    return !portfolio.esg_score ? '-' : portfolio.esg_score;
  };

  esgCoverage = (esg) => {
    if (!esg) return '(0%)';

    const { portfolio } = esg;
    const weight = (portfolio.weight * 100).toFixed(2);

    if (!weight || !Number(weight)) return '(0%)';

    const convertWeight = weight.split('.');

    if (convertWeight[1][0] === '0' && convertWeight[1][1] === '0')
      return `(${convertWeight[0]}%)`;
    if (convertWeight[1][1] === '0')
      return `(${convertWeight[0]}.${convertWeight[1][0]}%)`;

    return `(${weight}%)`;
  };

  renderSubheader = () => (
    <div className="sub header">Comparison Period: {this.parseDate()}</div>
  );

  render() {
    const {
      income,
      benchmark,
      compareEsg,
      esgToggle,
      comparePortfolioState,
      analytics,
      user_region,
    } = this.props;
    const { analysis1, analysis2 } = comparePortfolioState.compareResult;
    const { portfolio1esg, portfolio2esg } = compareEsg;

    let portOneName = comparePortfolioState.original.name;
    const portTwoName = comparePortfolioState.name;

    // if portOneName is null, check to see if this portfolio is an account that was analyzed
    // if it is, set the name to '<account.user_institution>: <account.label>'
    portOneName = portOneName || this.getPortfoliosAssociatedAccountName();
    const compareResultsUseSameBenchmark =
      JSON.stringify(analysis1.fratios[benchmark]) ===
      JSON.stringify(analysis2.fratios[benchmark]);

    return (
      <PDF>
        <Header
          size="large"
          content={`Current Portfolio ${
            portOneName ? `(${portOneName})` : ''
          } vs. ${portTwoName}`}
          subheader={this.renderSubheader()}
          style={{ marginTop: '0px' }}
        />
        <Grid style={{ margin: '4rem 0 6rem 0' }}>
          <Grid.Row columns={2}>
            <Grid.Column
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '20px',
              }}
              width={7}
            >
              <Statistic
                size="tiny"
                style={{
                  height: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '30px',
                }}
              >
                <Grade color={colors.blue}>
                  {getOverAllGrade({
                    income,
                    benchmark,
                    esgToggle,
                    esg: portfolio1esg,
                    data: analysis1,
                  })}
                </Grade>
                <ScoreHeader>
                  <span style={{ color: colors.blue }}>Current</span> Portfolio
                  Score
                </ScoreHeader>
                <Statistic.Value>
                  {getOverAllScore({
                    income,
                    benchmark,
                    esgToggle,
                    esg: portfolio1esg,
                    data: analysis1,
                  })}
                </Statistic.Value>
              </Statistic>
              <Statistic
                size="tiny"
                style={{
                  height: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 0 0 0',
                }}
              >
                <Grade color={colors.red}>
                  {getOverAllGrade({
                    income,
                    benchmark,
                    esgToggle,
                    esg: portfolio2esg,
                    data: analysis2,
                  })}
                </Grade>
                <ScoreHeader>
                  <span style={{ color: colors.red }}>Comparison</span>{' '}
                  Portfolio Score
                </ScoreHeader>
                <Statistic.Value>
                  {getOverAllScore({
                    income,
                    benchmark,
                    esgToggle,
                    esg: portfolio2esg,
                    data: analysis2,
                  })}
                </Statistic.Value>
              </Statistic>
            </Grid.Column>
            <Grid.Column>
              <Radar
                id="radarChart"
                data={this.getRadarData(
                  analysis1,
                  analysis2,
                  portfolio1esg,
                  portfolio2esg
                )}
                options={radarOptions}
                height={160}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Header>
          <Header.Content>Key Statistics</Header.Content>
          <Header.Subheader>
            {`${this.parseDate()} (inclusive) in ${decideCurrency(
              analytics.data,
              user_region || 'CA'
            )}`}
          </Header.Subheader>
        </Header>

        <Divider />

        <TableContainer>
          <TableWrapper>
            <Table basic textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={3}>
                    Annualized Return
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Sharpe Ratio&nbsp;
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Roy&#8217;s Safety First Criterion&nbsp;
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3}>
                    Sortino Ratio&nbsp;
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row positive>
                  <Table.Cell width={4}>Current Portfolio</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios.portfolio.annualizedRet)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios.portfolio.sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios.portfolio.roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis1.fratios.portfolio.sortino)}
                  </Table.Cell>
                </Table.Row>
                {!compareResultsUseSameBenchmark && (
                  <Table.Row>
                    <Table.Cell width={4}>
                      {analysis1.fratios[benchmark].name}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.getPercent(
                        analysis1.fratios[benchmark].annualizedRet
                      )}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios[benchmark].sharpe)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios[benchmark].roys)}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {this.formatNum(analysis1.fratios[benchmark].sortino)}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row positive>
                  <Table.Cell width={4}>Comparison Portfolio</Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis2.fratios.portfolio.annualizedRet)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios.portfolio.sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios.portfolio.roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios.portfolio.sortino)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={4}>
                    {analysis2.fratios[benchmark].name}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(
                      analysis2.fratios[benchmark].annualizedRet
                    )}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios[benchmark].sharpe)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios[benchmark].roys)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.formatNum(analysis2.fratios[benchmark].sortino)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </TableWrapper>
          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Total Risk&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Downside Risk&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Maximum Drawdown&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Downside Capture&nbsp;</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={4}>Current Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.fratios.portfolio.maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(
                    analysis1.fratios.portfolio.dscapture[benchmark]
                  )}
                </Table.Cell>
              </Table.Row>
              {!compareResultsUseSameBenchmark && (
                <Table.Row>
                  <Table.Cell width={4}>
                    {analysis1.fratios[benchmark].name}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].stdDev)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].dsr)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].maxDd)}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {this.getPercent(analysis1.fratios[benchmark].dscapture)}
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row positive>
                <Table.Cell width={4}>Comparison Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios.portfolio.maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(
                    analysis2.fratios.portfolio.dscapture[benchmark]
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  {analysis2.fratios[benchmark].name}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].stdDev)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].dsr)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].maxDd)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.fratios[benchmark].dscapture)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Fees&nbsp;</Table.HeaderCell>
                <Table.HeaderCell>Income&nbsp;</Table.HeaderCell>
                {esgToggle ? (
                  <Table.HeaderCell>ESG</Table.HeaderCell>
                ) : (
                  <Table.HeaderCell />
                )}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={4}>Current Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.portMER)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis1.portYield)}
                </Table.Cell>

                {esgToggle ? (
                  <Table.Cell width={3}>
                    {this.displayEsg(portfolio1esg)}&nbsp;
                    {this.esgCoverage(portfolio1esg)}
                  </Table.Cell>
                ) : (
                  <Table.Cell width={3} />
                )}
                <Table.Cell width={3} />
              </Table.Row>
              <Table.Row positive>
                <Table.Cell width={4}>Comparison Portfolio</Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.portMER)}
                </Table.Cell>
                <Table.Cell width={3}>
                  {this.getPercent(analysis2.portYield)}
                </Table.Cell>

                {esgToggle ? (
                  <Table.Cell width={3}>
                    {this.displayEsg(portfolio2esg)}&nbsp;
                    {this.esgCoverage(portfolio2esg)}
                  </Table.Cell>
                ) : (
                  <Table.Cell width={3} />
                )}
                <Table.Cell width={3} />
              </Table.Row>
            </Table.Body>
          </Table>
        </TableContainer>
        <p
          style={{
            fontSize: '1rem',
            textAlign: 'left',
          }}
        >
          Benchmark(s): <br />
          {analysis1.fratios[benchmark] &&
            `
            ${analysis1.fratios[benchmark].name} is${' '}
          ${analysis1.fratios[benchmark].description};`}
          <br />
          {analysis2.fratios[benchmark] &&
            analysis2.fratios[benchmark].name !==
              analysis1.fratios[benchmark].name &&
            `
            ${analysis2.fratios[benchmark].name} is${' '}
          ${analysis2.fratios[benchmark].description};`}
        </p>

        <div style={{ pageBreakAfter: 'always' }} />

        <Header size="medium">
          <Header.Content>Hypothetical Portfolio History</Header.Content>
          <Header.Subheader>
            Based on the current composition of your portfolio
          </Header.Subheader>
          <Header.Subheader>
            {`${this.parseDate()} (inclusive) in ${decideCurrency(
              analytics.data,
              user_region || 'CA'
            )}`}
          </Header.Subheader>
        </Header>
        <Note>Total Returns including Distributions</Note>
        <Line
          data={this.getChartData(analysis1, analysis2)}
          options={chartOptions}
        />

        <div style={{ pageBreakAfter: 'always' }} />
        <Header
          size="large"
          content="Footnotes"
          style={{ textAlign: 'left', marginBottom: '15px' }}
        />
        <P>
          1. The Wealthscope Performance and Downside Risk scores are determined
          relative to a benchmark. As such, you should ignore these scores and
          compare only the financial metrics if the two portfolios have a
          different asset allocation, corresponding to a different benchmark.
        </P>
        <P>
          2. The comparison period is set to the longest common history of the
          two portfolios. As such, the scores of the “Current Portfolio” may
          change when it is being compared because the history may now be
          shorter.
        </P>
        <P>
          3. All key statistics are reported in{' '}
          {`${decideCurrency(analytics.data, user_region || 'CA')}`}.
        </P>
        <P>
          4. The following are risk-adjusted return ratios, and a higher ratio
          is better. The Sharpe Ratio measures the portfolio’s reward (excess
          returns over the risk-free rate) per unit of risk. The Roy’s Safety
          First Criterion measures the portfolio’s reward (excess returns over
          the target rate) per unit of risk. The Sortino Ratio measures the
          portfolio’s reward (excess returns over the target rate) per unit of
          downside risk.
        </P>
        <P>
          5. The following are portfolio risk measures: Total risk represents
          the volatility of the portfolio’s returns as measured by its standard
          deviation. Downside risk is the volatility of the portfolio’s return
          below a target rate. Maximum drawdown is the maximum peak to trough
          loss during a given period. Downside capture measures whether the
          portfolio lost less than the market when there was a market downturn.
        </P>
        <P>
          6. The default target rate is set to zero, representing nominal
          capital preservation.
        </P>
        <P>
          7. Fees capture product fees if you have mutual funds/ETFs. It could
          include a trailing commission for your advisor, depending on the share
          class.
        </P>
        <P>
          8. Income includes dividends from stocks and distributions from mutual
          funds/ETFs.
        </P>
      </PDF>
    );
  }
}

ComparePDF.propTypes = {
  benchmark: PropTypes.string.isRequired,
  income: PropTypes.bool.isRequired,
  comparePortfolioState: PropTypes.object,
  compareEsg: PropTypes.object,
  history: PropTypes.func,
  match: PropTypes.object,
  esgToggle: PropTypes.bool,
  analytics: PropTypes.object.isRequired,
  user_region: PropTypes.string,
};

ComparePDF.defaultProps = {
  compareEsg: {},
  comparePortfolioState: {},
  history: () => false,
  match: {},
  esgToggle: false,
  user_region: 'CA',
};

export default connect((state) => ({
  comparePortfolioState: state.ComparePortfolio,
  compareEsg: state.Analytics.compareEsg,
  esgToggle: state.Analytics.esgToggle,
  analytics: state.Analytics,
  user_region: state.Storage.user_region || 'CA',
}))(ComparePDF);

const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
`;

const P = styled.p`
  text-align: left;
  max-width: 100%;
`;

const PDF = styled.div`
  position: relative;
  background: white;
  text-align: center;
  padding: 1.5rem;
  border-radius: 8px;
  width: 1160px;
  margin: -55px auto 5px auto;

  @media only print {
    print-color-adjust: exact;

    @page {
      size: auto;
      margin: 0.5cm;
    }
  }
`;

const Grade = styled.div`
  font-family: 'Kalam', cursive;
  color: ${(props) => props.color};
  font-size: 3rem;
  padding: 1.5rem 0;
`;

const radarOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: false,
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
};

const chartOptions = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback: (label) => `$${label}`,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${data.datasets[tooltipItems.datasetIndex].label}: $${
          tooltipItems.yLabel
        }`,
    },
  },
};

const Note = styled.div`
  max-width: 40rem;
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 0.5rem auto;
`;

const ScoreHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 6rem;
`;
