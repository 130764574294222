import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Divider, Table, Popup, Button } from 'semantic-ui-react';
import { maxNumberOfFundsToCompare } from 'utils/constants';
import { colors, chartingToolColorsLessOpacity } from 'utils/colors';
import Fade from 'react-reveal/Fade';
import { isGlobeInvestor } from 'utils/helpers';

const EMPTY_CELL_BACKGROUND_COLOR = '#F9FAFB';
class KeyInfo extends Component {
  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker, exchangeSymbol) =>
    `${ticker.split(':')[0]}-${exchangeSymbol}`;
  handleClick = (event, ticker, exchangeSymbol) => {
    event.preventDefault();
    window.open(
      this.createUrl(
        this.gbmConvert(
          this.appendExchangeSymbol(ticker, exchangeSymbol.toUpperCase())
        )
      )
    ),
      '_blank';
  };
  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  renderEmptyHeaderCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => <Table.HeaderCell />);
  renderEmptyCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => (
      <Table.Cell style={{ backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }} />
    ));

  render() {
    const {
      securityData,
      securityTickerList,
      isOpen,
      toggleOpen,
      partnerName,
    } = this.props;

    const numEmptyCols = maxNumberOfFundsToCompare - securityTickerList.length;

    return (
      <div>
        <Header textAlign="left">
          <div style={{ display: 'flex' }}>
            <StyledButton icon onClick={toggleOpen} isOpen={isOpen}>
              {isOpen ? '-' : '+'}
            </StyledButton>
            Key Facts{' '}
          </div>
        </Header>
        <Divider />

        {isOpen && (
          <Fade duration={3500}>
            <Table compact celled definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  {securityTickerList.map((security, index) => (
                    <Table.HeaderCell
                      width={3}
                      style={{
                        backgroundColor: Object.values(
                          chartingToolColorsLessOpacity
                        )[index],
                        color: 'black',
                      }}
                    >
                      {security}
                    </Table.HeaderCell>
                  ))}
                  {this.renderEmptyHeaderCells(numEmptyCols)}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width={3}>Fund Name</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].long_name || 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Inception Date</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].effective_date || 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Category</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].global_asset_class || 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Net Assets</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].fund_net_assets
                        ? `$${Math.round(
                            securityData[security].fund_net_assets
                          ).toLocaleString()}`
                        : 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Fund Benchmark</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].fund_benchmark || 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    Distribution Yield
                    <Popup
                      trigger={<i className="icon question circle" />}
                      position="top center"
                      style={{ height: 'initial' }}
                    >
                      Trailing 12-month income and capital gain distributions
                      divided by the previous {"month's"} net assets.
                    </Popup>
                  </Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security]._yield
                        ? this.parseNum(securityData[security]._yield)
                        : 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Total Number of Holdings</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].total_holdings || 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>P/E Ratio</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].price_to_earnings
                        ? this.parseNum(
                            securityData[security].price_to_earnings
                          )
                        : '-'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Average maturity</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].avg_maturity
                        ? this.parseNum(securityData[security].avg_maturity)
                        : 'N/A'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>

                <Table.Row>
                  <Table.Cell>
                    Management Expense Ratio (MER)
                    <Popup
                      trigger={<i className="icon question circle" />}
                      position="top center"
                      style={{ height: 'initial' }}
                    >
                      MER includes management fees, operating expenses and sales
                      tax.
                    </Popup>
                  </Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>
                      {securityData[security].mer
                        ? `${this.parseNum(securityData[security].mer)}%`
                        : '-'}
                    </Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell>As of Date</Table.Cell>
                  {securityTickerList.map((security) => (
                    <Table.Cell>{securityData[security].as_of_date}</Table.Cell>
                  ))}
                  {this.renderEmptyCells(numEmptyCols)}
                </Table.Row>
              </Table.Body>
            </Table>
          </Fade>
        )}
      </div>
    );
  }
}

KeyInfo.propTypes = {
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

KeyInfo.defaultProps = { partnerName: '' };

export default KeyInfo;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;
