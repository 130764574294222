import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Checkbox, Divider, Header, Form } from 'semantic-ui-react';

import { BuildETF, Storage } from 'actions';
import { colors } from 'utils/colors';
import { Segment, Button, Loading, ErrorLabel } from 'components';

class SelectAssets extends Component {
  state = {
    error: null,
  };

  componentWillMount() {
    const { dispatch, user_region } = this.props;
    dispatch(Storage.setItem('toggle_region', user_region)); // Set toggle region to user_region to avoid interference from Accounts
  }

  toggleAsset = (e, { value }) => {
    const { dispatch, selectedClass } = this.props;

    if (!selectedClass[value.assetclass]) {
      return false;
    }

    this.setState({ error: null });

    return dispatch(BuildETF.toggleAsset({ ...value, weight: 0 }));
  };

  toggleClass = (e, { name }) => {
    const { dispatch } = this.props;

    this.setState({ error: null });

    return dispatch(BuildETF.toggleClass(name));
  };

  handleSubmit = () => {
    const { selectedAssets, history, match } = this.props;

    if (!Object.keys(selectedAssets).length) {
      return this.setState({
        error: 'Please select at least 1 asset to continue',
      });
    }

    return history.push(`/portfolio/build-etf/${match.params.id}/weights`);
  };

  renderCheckboxes = (arr) => {
    const { selectedAssets } = this.props;

    return arr.map((elem) => (
      <Checkbox
        key={elem.symbol}
        name={elem.symbol}
        label={elem.name}
        value={elem}
        checked={!!selectedAssets[elem.symbol]}
        onChange={this.toggleAsset}
        style={{ margin: '2px 0', display: 'block' }}
      />
    ));
  };

  renderSubClass = (data, selectedClass, assetClass) => {
    const renderSubSubClass = (subClass, subSubClass) =>
      Object.keys(subSubClass).map((subSubClassName) => (
        <div>
          <SubHeader>{subSubClassName}</SubHeader>
          {this.renderCheckboxes(data[subClass][subSubClassName])}
        </div>
      ));

    return Object.keys(data).map((subClass) => (
      <Segment basic compact disabled={!selectedClass[assetClass]}>
        <Header size="small">
          {subClass.toUpperCase() === 'US' ? 'U.S.' : subClass.toUpperCase()}
        </Header>
        <Divider fitted />
        {renderSubSubClass(subClass, data[subClass])}
      </Segment>
    ));
  };

  render() {
    const { data, selectedClass } = this.props;
    const { error } = this.state;

    return !data ? (
      <Loading active />
    ) : (
      <Form onSubmit={this.handleSubmit} style={{ padding: '1rem' }}>
        <Header
          textAlign="center"
          size="medium"
          style={{ padding: '1rem 0 2rem 0' }}
        >
          Select Assets
          <Header.Subheader>
            Passive, index-based categories with minimum 10 years of history,
            ideal for creating core portfolios and benchmarks.
          </Header.Subheader>
          <Header.Subheader style={{marginTop: '1rem'}}>
            Should you choose a Canadian or U.S.-listed ETF? Read our short
            article,
            <br />
            <a
              href="https://www.wealthscope.ca/foreignwithholdingtax"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                Investing Abroad - Canadian or U.S. Listed ETFs? Some Tax
                Considerations
              </b>
            </a>
          </Header.Subheader>
        </Header>
        <StyledDiv>
          <Header size="medium" textAlign="center">
            Bonds
            <Form.Checkbox
              inline
              name="Fixed Income"
              checked={selectedClass['Fixed Income']}
              onChange={this.toggleClass}
              toggle
            />
          </Header>
          {this.renderSubClass(
            data['Fixed Income'],
            selectedClass,
            'Fixed Income'
          )}
        </StyledDiv>

        <StyledDiv>
          <Header size="medium" textAlign="center">
            Stocks
            <Form.Checkbox
              inline
              name="Equity"
              checked={selectedClass.Equity}
              onChange={this.toggleClass}
              toggle
            />
          </Header>
          {this.renderSubClass(data.Equity, selectedClass, 'Equity')}
        </StyledDiv>

        <StyledDiv>
          <Header size="medium" textAlign="center">
            Alternative
            <Form.Checkbox
              inline
              name="Alternative"
              checked={selectedClass.Alternative}
              onChange={this.toggleClass}
              toggle
            />
          </Header>
          {this.renderSubClass(data.Alternative, selectedClass, 'Alternative')}
        </StyledDiv>

        <ErrorWrapper>
          <Button
            className="right"
            color={colors.orange}
            icon="chevron right"
            floated="right"
          >
            Next
          </Button>
          <ErrorLabel className="left" msg={error} />
        </ErrorWrapper>

        <div style={{ clear: 'both' }} />
      </Form>
    );
  }
}

SelectAssets.propTypes = {
  data: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedAssets: PropTypes.object.isRequired,
  selectedClass: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user_region: PropTypes.string,
};

SelectAssets.defaultProps = {
  data: null,
  user_region: 'CA',
};

export default connect((state) => ({
  data: state.BuildETF.data,
  selectedAssets: state.BuildETF.selectedAssets,
  selectedClass: state.BuildETF.selectedClass,
  user_region: state.Storage.user_region || 'CA',
}))(SelectAssets);

const SubHeader = styled.div`
  border: 0;
  margin: 0.5rem 0;
  padding: 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
`;

const StyledDiv = styled.div`
  float: left;
  width: 33.3%;

  @media only screen and (max-width: 850px) {
    width: 70%;
    margin: 0 auto;
    float: none;
  }
`;

const ErrorWrapper = styled.div`
  position: relative;
  float: right;
  clear: both;
`;
