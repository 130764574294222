import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Header, Divider } from 'semantic-ui-react';
import { decideCurrency } from 'utils/helpers';
import { YouTubePlayer, Score, Table } from 'components';
import {
  translateBenchmarkHeading,
  translateBenchmarkDescription,
} from '../../../../utils/helpers';

@connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))
export default class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: false,
    };
  }

  getPercent = (num) => (!num ? '-' : `${(num * 100).toFixed(2)} %`);

  toggleVideo = (id) =>
    this.setState((prevState) => ({
      video: id === prevState.video ? false : id,
    }));

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  parseDate = (portfolio, short) => {
    const { french } = this.props;
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = french
      ? {
          '01': 'Janvier',
          '02': 'Février',
          '03': 'Mars',
          '04': 'Avril',
          '05': 'Mai',
          '06': 'Juin',
          '07': 'Juillet',
          '08': 'Août',
          '09': 'Septembre',
          10: 'Octobre',
          11: 'Novembre',
          12: 'Décembre',
        }
      : {
          '01': 'January',
          '02': 'February',
          '03': 'March',
          '04': 'April',
          '05': 'May',
          '06': 'June',
          '07': 'July',
          '08': 'August',
          '09': 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };

    return short
      ? `${months[end[1]]} ${end[0]}`
      : `${months[init[1]]} ${init[0]} ${french ? 'à' : 'to'} ${
          months[end[1]]
        } ${end[0]}`;
  };

  render() {
    const { benchmark, data, user_region, french } = this.props;
    const { video } = this.state;

    return (
      <Container>
        <Score
          name="Performance "
          score={`${data.score.Performance[benchmark][0]}%`}
          grade={data.score.Performance[benchmark][1]}
          description={
            french
              ? 'Noté sur la base du rendement annualisé de votre portefeuille (par rapport à l’indice de référence) et des trois ratios ajustés au risque : le ratio de Sharpe, le critère de sécurité de Roy et le ratio de Sortino (par rapport à ceux de l’indice de référence). Si votre portefeuille ne fait qu’égaler l’indice de référence de base sans le dépasser, il obtiendra une note de passage, soit 65 %.'
              : "Scored using your portfolio's annualized return (relative to the benchmark) and the three risk-adjusted ratios: the Sharpe, Roy's Safety First, and the Sortino (relative to those of the benchmark).<br />If your portfolio is just matching (rather than outperforming) the simple benchmark, it will get a pass, at 65%."
          }
          portfolio={data.portfolio}
        />
        <p>
          {french
            ? 'Les informations ci-dessous donnent un aperçu de la performance de votre portefeuille. Vous y trouverez les indicateurs traditionnels, tels que les rendements annualisés pour différents horizons de placement. Plus importants encore, nous vous présentons les ratios ajustés au risque de votre portefeuille, qui vous indiquent le niveau de risque auquel vous vous exposez pour générer ces rendements.'
            : 'The information below provides a snapshot of how well your portfolio is doing. You will find the traditional measures, such as annualized returns for different investment horizons. Most importantly, we show you your portfolio’s risk adjusted ratios, which tell you how much risk was taken to generate the returns.'}
          {!french && (
            <Icon
              name="youtube play"
              onClick={() => this.toggleVideo('RFk8D46sszc')}
              link
              style={{ color: 'rgb(255,2,2)' }}
            />
          )}
        </p>
        {!french && (
          <YouTubePlayer isOpen={video === 'RFk8D46sszc'} id="RFk8D46sszc" />
        )}
        <p>
          {french
            ? 'Si vous investissez dans des titres qui comportent un certain niveau de risque (qu’il soit élevé ou faible), les ratios ajustés au risque sont des mesures plus appropriées de la performance, car les rendements ne sont pas garantis. Il va sans dire que des ratios '
            : 'As long as you invest in securities that involve some level of risk - however high or low – the risk adjusted ratios are more appropriate measures of performance, because the returns are not guaranteed. Needless to say, the '}
          <b>{french ? 'élevés' : 'higher'}</b>
          {french
            ? ' en particulier par rapport à l’indice de référence de votre portefeuille, sont '
            : " the ratios, especially relative to your portfolio's benchmark, the "}
          <b>{french ? 'préférables' : 'better'}</b>.
          {!french && (
            <Icon
              name="youtube play"
              onClick={() => this.toggleVideo('MHms0ZGgKg0')}
              link
              style={{ color: 'rgb(255,2,2)' }}
            />
          )}
        </p>
        {!french && (
          <YouTubePlayer isOpen={video === 'MHms0ZGgKg0'} id="MHms0ZGgKg0" />
        )}

        <Header>
          <Header.Content>
            {french ? 'Statistiques clés' : 'Key Statistics'}
          </Header.Content>
          <Header.Subheader>
            {french
              ? 'Selon la composition actuelle de votre portefeuille'
              : 'Based on the current composition of your portfolio'}
            &nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              content={
                french
                  ? 'En utilisant les mêmes avoirs et les mêmes pondérations.'
                  : 'Using the same holdings and the same weights.'
              }
              wide
            />
          </Header.Subheader>
          <Header.Subheader>
            {`${this.parseDate(data.portfolio)} ${
              french ? '(inclusivement) en' : '(inclusive) in'
            } ${decideCurrency(data, user_region || 'CA')}`}
          </Header.Subheader>
        </Header>
        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3} />
              <Table.HeaderCell>
                {french ? 'Rendements annualisés' : 'Annualized Return'}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french ? 'Ratio de Sharpe' : 'Sharpe Ratio'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={
                    french
                      ? 'Ce ratio mesure la « rentabilité » du portefeuille par unité de « risque », où la rentabilité représente le rendement moyen du portefeuille au-delà d’un taux sans risque, et le risque étant l’écart-type (ou la volatilité) du portefeuille. Le taux sans risque auquel nous nous référons est le rendement des bons du Trésor de 12 mois.'
                      : "This ratio measures the 'reward' of the portfolio per unit of 'risk', where reward is the average return of the portfolio over and above the risk-free rate, and risk is the standard deviation (or volatility) of the portfolio. The risk-free rate we use is the 12-month Treasury bill yield."
                  }
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french
                  ? 'Le Critère de sécurité de Roy'
                  : "Roy's Safety First Criterion"}
                &nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                >
                  {french
                    ? 'Comme le ratio de Sharpe, ce ratio mesure le rendement du portefeuille par unité de risque. Cependant, la rentabilité est définie comme le rendement moyen du portefeuille par rapport à votre objectif de rendement (ou taux minimum acceptable), au lieu du taux sans risque. Actuellement, l’objectif de rendement est fixé à zéro, c’est-à-dire à la préservation de l’argent (ne pas perdre d’argent en termes nominaux).'
                    : "Like the Sharpe Ratio, this ratio measures the portfolio's reward per unit of risk. However, the reward is defined as the portfolio's average return over your target return (or minimum acceptable rate), instead of the risk-free rate. Currently, the target return is set to zero, i.e., money preservation (not losing money in nominal terms)."}
                </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french ? 'Ratio de Sortino' : 'Sortino Ratio'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                >
                  {french
                    ? 'Ce ratio mesure la rentabilité du portefeuille par unité de risque de perte. La rentabilité est le rendement moyen du portefeuille par rapport à votre objectif de rendement (ou taux minimum acceptable). Le risque de perte est la volatilité des rendements du portefeuille en deçà de votre objectif. Actuellement, l’objectif de rendement est fixé à zéro, c’est-à-dire la préservation de l’argent (ne pas perdre d’argent en termes nominaux).'
                    : "This ratio measures the portfolio's reward per unit of downside risk. The reward is the portfolio's average return over your target return (or minimum acceptable rate). Downside risk is the volatility of the portfolio returns below your target. Currently, the target return is set to zero, i.e., money preservation (not losing money in nominal terms)."}
                </Popup>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell width={3}>
                {french ? 'Mon portefeuille' : 'My Portfolio'}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.annualizedRet)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.sharpe)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.roys)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.sortino)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {translateBenchmarkHeading(
                  data.fratios[benchmark].name,
                  french
                )}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={translateBenchmarkDescription(
                    data.fratios[benchmark].description,
                    french
                  )}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].annualizedRet)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].sharpe)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].roys)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].sortino)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header>
          <Header.Content>
            {french ? 'Rendement annualisé' : 'Annualized Returns'}
          </Header.Content>
          <Header.Subheader>
            {this.parseDate(data.portfolio, 'short')}
            {french ? ' en CAD' : ' in CAD'}
          </Header.Subheader>
        </Header>
        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3} />
              <Table.HeaderCell>{french ? 'CDA' : 'YTD'}</Table.HeaderCell>
              <Table.HeaderCell>{french ? '1 an' : '1-year'}</Table.HeaderCell>
              <Table.HeaderCell>{french ? '3 ans' : '3-year'}</Table.HeaderCell>
              <Table.HeaderCell>{french ? '5 ans' : '5-year'}</Table.HeaderCell>
              <Table.HeaderCell>{french ? '7 ans' : '7-year'}</Table.HeaderCell>
              <Table.HeaderCell>
                {french ? '10 ans' : '10-year'}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell width={3}>
                {french ? 'Mon portefeuille' : 'My Portfolio'}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.retYTD)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret1)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret3)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret5)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret7)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret10)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {translateBenchmarkHeading(
                  data.fratios[benchmark].name,
                  french
                )}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={translateBenchmarkDescription(
                    data.fratios[benchmark].description,
                    french
                  )}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].retYTD)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret1)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret3)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret5)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret7)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret10)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

Performance.propTypes = {
  benchmark: PropTypes.string,
  data: PropTypes.object,
  user_region: PropTypes.string,
  french: PropTypes.bool,
};

Performance.defaultProps = {
  benchmark: '',
  data: {},
  user_region: 'CA',
  french: false,
};

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
