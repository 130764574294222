import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';

const convertPropToClassName = (prop, className) => prop && className;

const Segment = ({
  children,
  className,
  basic,
  compact,
  flex,
  disabled,
  responsive,
  fixed,
  expand,
  ...props
}) => {
  const classes = cn(
    className,
    convertPropToClassName(basic, 'basic'),
    convertPropToClassName(compact, 'compact'),
    convertPropToClassName(flex, 'flex'),
    convertPropToClassName(disabled, 'disabled'),
    convertPropToClassName(responsive, 'responsive'),
    convertPropToClassName(fixed, 'fixed'),
    convertPropToClassName(expand, 'expand')
  );

  return (
    <StyledSegment className={classes} {...props}>
      {children}
    </StyledSegment>
  );
};

export default Segment;

Segment.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  basic: PropTypes.bool,
  compact: PropTypes.bool,
  flex: PropTypes.bool,
  disabled: PropTypes.bool,
  responsive: PropTypes.bool,
  fixed: PropTypes.bool,
  expand: PropTypes.bool,
};

Segment.defaultProps = {
  className: '',
  basic: false,
  compact: false,
  flex: false,
  disabled: false,
  responsive: false,
  fixed: false,
  expand: false,
};

const StyledSegment = styled.div`
  padding: 2rem;
  margin: ${(props) => props.margin || '1rem'};
  box-shadow: none;
  border-radius: 8px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: white;
  position: relative;
  text-align: ${(props) => props.textAlign || 'inherit'};
  font-size: 1rem;

  &.flex {
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &.basic {
    background: none;
    border-radius: 0;
  }

  &.disabled {
    opacity: 0.2 !important;
    color: rgba(40, 40, 40, 0.3);
  }

  &.compact {
    margin: 1rem 0;
    padding: 1rem;
  }

  &.fixed {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgb(236, 236, 240);
    top: 59px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  &.expand {
    min-height: 550px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
`;
