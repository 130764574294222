import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default class Tip extends Component {
  state = {
    open: false,
  };

  render() {
    const { content } = this.props;
    const { open } = this.state;

    return (
      <StyledTip
        collapsed={open}
        onClick={() => this.setState({ open: !this.state.open })}
      >
        <Header>
          <i className="lightbulb icon" />
          Tip
          <i className={open ? 'icon chevron up' : 'icon chevron down'} />
        </Header>
        <p>{content}</p>
      </StyledTip>
    );
  }
}

Tip.propTypes = {
  content: PropTypes.string.isRequired,
};

const StyledTip = styled.div`
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset;
  background-color: #f8f8f9;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  max-height: ${(props) => (props.collapsed ? 'auto' : '33px')};
  overflow: hidden;
  transition: all 300ms ease;
  margin: 0.5rem 0;
  cursor: pointer;

  i {
    padding: 0;
    font-size: 1.6rem;
    vertical-align: top;

    &.chevron {
      margin: 0;
      font-size: 0.8rem;
      float: right;
      font-weight: 100;
    }
  }

  p {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 100;
    font-size: 1rem;
    padding: 0 7px;
  }
`;

const Header = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;
