import 'Getting_Started_Guide_VB.pdf';
import 'User_Guide_NBDB_En_Dec_5_2024.pdf';
import 'User_Guide_NBDB_Fr_Dec_5_2024.pdf';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from 'modules/App';
import Unauthorized from 'modules/Unauthorized';
import Authenticate from 'modules/Authenticate';
import { PrivateRoute } from 'components';

import SdkMessageService from './services/SdkMessageService';
import store from './store';
import './fonts/fontello.css';
import './styles';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://92490964968747d28354c973cd74249f@o890487.ingest.sentry.io/5841184',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path="/auth/" component={Authenticate} />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <PrivateRoute path="/" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('app')
);

// Initialize the SdkMessageService and attach it to window
window.SdkMessageService = SdkMessageService;
window.SdkMessageService.init(store, history);

// set event listeners for iframes
if (window === window.top) {
  // we're in the parent. don't listen to any events.
  // eslint-disable-next-line no-console
  console.log('Detected top-level frame. Disabling SdkMessageService');
} else {
  // We're in the iFrame. Start listening to window events.
  window.SdkMessageService.listen();
}
