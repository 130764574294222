import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';

import { Message } from 'components';
import { arrangeSector } from 'utils/helpers';
import { ExposureTable, ExposureChart } from '../components';

export default class GlobalSector extends Component {
  state = {
    video: false,
  };

  getTableData = (data) =>
    Object.keys(data).reduce((total, key) => {
      total.push({
        ticker: key,
        value: data[key],
      });

      return total;
    }, []);

  toggleVideo = (id) =>
    this.setState({ video: id === this.state.video ? false : id });

  render() {
    const { user_region, toggle_region } = this.props;
    const { data } = this.props.analytics;

    return (
      <Container>
        <Header size="medium" dividing>
          Holdings and Risk Exposure Analysis
          <Header.Subheader>
            By Global Stock Sector&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="bottom right"
              wide
            >
              Global stock market sectors as defined by the Global Industry
              Classification Standard (GICS).
            </Popup>
          </Header.Subheader>
        </Header>

        <p>
          Watch a short video that explains the difference between holdings
          breakdown and risk exposure or contribution by global stock sector
          from a total portfolio perspective.&nbsp;
        </p>

        {typeof data.sectBreakdown === 'object' && (
          <div>
            <Header
              size="medium"
              content="Holdings Breakdown"
              subheader="Stock Sub-Portfolio"
            />
            <p>
              This breakdown is the value-weighted count of the stock holdings
              in each global stock sector within your stock sub-portfolio (or your
              total portfolio if you have no other asset class).
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.sectBreakdown))}
                    data={Object.values(arrangeSector(data.sectBreakdown))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={['Sector', 'Holdings']}
                    totals={['Total', <div>100%</div>]}
                    data={this.getTableData(data.sectBreakdown)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}

        {data.riskExp && typeof data.riskExp['S.EQ'] === 'object' ? (
          <div>
            <Header
              size="medium"
              content="Risk Exposure"
              subheader="Stock Sub-Portfolio (excluding Balanced/Asset Allocation Funds)"
            />
            <p>
              These exposures are estimated using statistical analysis. They
              reflect the volatility and the diversification effects of your
              stock sub-portfolio. It provides a different picture from holdings
              breakdown, as the latter does not take into account the fact that
              some companies may hedge their sector risk, or have operations in
              more than one sector. Importantly, certain sector risks may be
              reduced or diversified away if you hold multiple assets in your
              portfolio.
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.riskExp['S.EQ']))}
                    data={Object.values(arrangeSector(data.riskExp['S.EQ']))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      <div>
                        Global Stock Sector
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          {user_region === 'US' || toggle_region === 'US'
                            ? 'A global stock sector is made up of large firms in that sector from around the world.'
                            : 'A global stock sector is made up of large firms in that sector from around the world. Note that if you have Canadian holdings in a particular sector, it does not necessarily mean that you have high RISK EXPOSURE to that sector. For example, Canadian banks had a low correlation with the global financial sector during the financial crisis, because they weathered the storm much better than the banks in other countries.'}
                        </Popup>
                      </div>,
                      <div>
                        Sector Risk Exposure&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          A negative number means that your portfolio is hedged
                          against that sector. The risk of your portfolio is
                          negatively related to movements that global stock
                          market sector.
                          <br />
                          <br />
                          Factors with {'"-"'} risk exposure are statistically
                          insignificant.
                        </Popup>
                      </div>,
                    ]}
                    totals={['Total', <div>100%</div>]}
                    data={this.getTableData(data.riskExp['S.EQ'])}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div style={{ margin: '2rem 0' }}>
            <Header
              size="medium"
              content="Risk Exposure"
              subheader="Stock Sub-Portfolio (excluding Balanced/Asset Allocation Funds)"
            />
            <p>
              These exposures are estimated using statistical analysis. They
              reflect the volatility and the diversification effects of your
              stock sub-portfolio. It provides a different picture from holdings
              breakdown, as the latter does not take into account the fact that
              some companies may hedge their sector risk, or have operations in
              more than one sector. Importantly, certain sector risks may be
              reduced or diversified away if you hold multiple assets in your
              portfolio.
            </p>
            <Message visible warning>
              {data.riskExp['S.EQ']}
            </Message>
          </div>
        )}

        {data.riskExp && typeof data.riskExp.S === 'object' ? (
          <div>
            <Header
              size="medium"
              content="Risk Exposure"
              subheader="Total Portfolio"
            />
            <p>
              These exposures are estimated using statistical analysis. They
              represent the volatility and diversification effect of your total
              portfolio. If you have bonds or alternative assets in your
              portfolio, these figures will be different from those in the
              previous table, as additional global stock sector risk may be added or
              diversified away.
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.riskExp.S))}
                    data={Object.values(arrangeSector(data.riskExp.S))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      <div>
                        Global Stock Sector
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          {user_region === 'US' || toggle_region === 'US'
                            ? 'A global stock sector is made up of large firms in that sector from around the world.'
                            : 'A global stock sector is made up of large firms in that sector from around the world. Note that if you have Canadian holdings in a particular sector, it does not necessarily mean that you have high RISK EXPOSURE to that sector. For example, Canadian banks had a low correlation with the global financial sector during the financial crisis, because they weathered the storm much better than the banks in other countries.'}
                        </Popup>
                      </div>,
                      <div>
                        Sector Risk Exposure&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          A negative number means that your portfolio is hedged
                          against that sector. The risk of your portfolio is
                          negatively related to movements that global stock
                          market sector.
                          <br />
                          <br />
                          Factors with {'"-"'} risk exposure are statistically
                          insignificant.
                        </Popup>
                      </div>,
                    ]}
                    totals={['Total', <div>100%</div>]}
                    data={this.getTableData(data.riskExp.S)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div>
            <Header
              size="medium"
              content="Risk Exposure"
              subheader="Total Portfolio"
            />
            <p>
              These exposures are estimated using statistical analysis. They
              represent the volatility and diversification effect of your total
              portfolio. If you have bonds or alternative assets in your
              portfolio, these figures will be different from those in the
              previous table, as additional global stock sector risk may be added or
              diversified away.
            </p>
            <Message visible warning>
              {data.riskExp.S}
            </Message>
          </div>
        )}
      </Container>
    );
  }
}

GlobalSector.propTypes = {
  analytics: PropTypes.object,
  user_region: PropTypes.string.isRequired,
  toggle_region: PropTypes.string.isRequired,
};

GlobalSector.defaultProps = {
  analytics: {},
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
