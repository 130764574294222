import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { colors, nbGraph, nbColors } from 'utils/colors';
import { isNB } from '../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
  partnerName: state.Storage['partner-name'],
}))
class PortfolioHistoryChart extends Component {
  state = {
    maxOfChart: null,
  };
  getGreyBars = (data, crisisRets, max) => {
    const financialCrisis =
      crisisRets &&
      Object.keys(crisisRets['Financial Crisis'].rets).length !== 0
        ? [
            '2007-12',
            '2008-01',
            '2008-02',
            '2008-03',
            '2008-04',
            '2008-05',
            '2008-06',
            '2008-07',
            '2008-08',
            '2008-09',
            '2008-10',
            '2008-11',
            '2008-12',
            '2009-01',
            '2009-02',
            '2009-03',
            '2009-04',
            '2009-05',
          ]
        : [];
    const oilCollapse =
      crisisRets && Object.keys(crisisRets['Oil Price Shock'].rets).length !== 0
        ? [
            '2014-06',
            '2014-07',
            '2014-08',
            '2014-09',
            '2014-10',
            '2014-11',
            '2014-12',
            '2015-01',
            '2015-02',
            '2015-03',
            '2015-04',
            '2015-05',
            '2015-06',
            '2015-07',
            '2015-08',
            '2015-09',
            '2015-10',
            '2015-11',
            '2015-12',
          ]
        : [];
    const covid19Pandemic =
      crisisRets &&
      Object.keys(crisisRets['Covid-19 Pandemic'].rets).length !== 0
        ? [
            '2020-02',
            '2020-03',
            '2020-04',
            '2020-05',
            '2020-06',
            '2020-07',
            '2020-08',
            '2020-09',
            '2020-10',
            '2020-11',
            '2020-12',
            '2021-01',
            '2021-02',
            '2021-03',
            '2021-04',
            '2021-05',
          ]
        : [];

    const ukraineWar =
      crisisRets && Object.keys(crisisRets['Ukraine War'].rets).length !== 0
        ? ['2022-03', '2022-04', '2022-05', '2022-06', '2022-07']
        : [];

    return data.reduce((result, date) => {
      if (
        financialCrisis.includes(date) ||
        oilCollapse.includes(date) ||
        covid19Pandemic.includes(date) ||
        ukraineWar.includes(date)
      ) {
        result.push(max);
      } else {
        result.push(0);
      }

      return result;
    }, []);
  };

  getChartData = (data) => {
    const { maxOfChart } = this.state;
    const { benchmark, compare, displayDataSet, partnerName } = this.props;
    const { displayPortfolio, displayCompare } = displayDataSet;

    return {
      labels: Object.keys(data.cumrets.portfolio),
      datasets: [
        {
          type: 'line',
          data: displayPortfolio
            ? Object.values(data.cumrets.portfolio).map(
                (value) => Math.round(value * 1000 * 100) / 100
              )
            : [],
          borderWidth: -1,
          label: 'Portfolio',
          lineTension: 0,
          fill: false,
          backgroundColor: isNB(partnerName) ? nbGraph.mediumDark : colors.blue,
          borderColor: isNB(partnerName) ? nbGraph.mediumDark : colors.blue,
          pointBorderColor: isNB(partnerName)
            ? nbGraph.mediumDark
            : colors.blue,
          pointBackgroundColor: isNB(partnerName)
            ? nbGraph.mediumDark
            : colors.blue,
          pointRadius: 0,
          pointHitRadius: 5,
        },
        {
          type: 'line',
          data: displayCompare
            ? Object.values(data.cumrets[compare][benchmark]).map(
                (value) => Math.round(value * 1000 * 100) / 100
              )
            : [],
          borderWidth: -1,
          label: compare,
          lineTension: 0,
          fill: false,
          backgroundColor: isNB(partnerName) ? nbColors.red : colors.red,
          borderColor: isNB(partnerName) ? nbColors.red : colors.red,
          pointBorderColor: isNB(partnerName) ? nbColors.red : colors.red,
          pointBackgroundColor: isNB(partnerName) ? nbColors.red : colors.red,
          pointRadius: 0,
          pointHitRadius: 5,
        },
        {
          type: 'bar',
          data: this.getGreyBars(
            Object.keys(data.cumrets.portfolio),
            data.crisisRets,
            maxOfChart
          ),
          backgroundColor: colors.lightGrey,
          borderColor: colors.lightGrey,
          hoverBackgroundColor: colors.lightGrey,
          hoverBorderColor: colors.lightGrey,
        },
      ],
    };
  };

  setChartMax = (label, index, values) => {
    const { maxOfChart } = this.state;

    if (values[0] > maxOfChart) {
      this.setState({ maxOfChart: values[0] });
    }

    return `$${label}`;
  };

  render() {
    const { portfolioData, compare, benchmark } = this.props;

    return (
      <div>
        <Bar
          setChartMax={this.setChartMax}
          data={this.getChartData(portfolioData)}
          options={{
            hover: {
              animationDuration: 0,
              onHover: (e) => {
                e.target.style.cursor = 'pointer';
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  barPercentage: 2,
                },
              ],
              yAxes: [
                {
                  ticks: {
                    callback: (label, index, values) =>
                      this.setChartMax(label, index, values),
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            tooltips: {
              custom: (tooltipItems) => {
                const tooltipDataPoints = tooltipItems.dataPoints
                  ? tooltipItems.dataPoints[0]
                  : null;
                const datasetIndex = tooltipDataPoints
                  ? tooltipDataPoints.datasetIndex
                  : null;

                if (datasetIndex < 2) {
                  tooltipItems.displayColors = true;
                } else {
                  tooltipItems.displayColors = false;
                }
              },
              callbacks: {
                title: (tooltipItems) => {
                  const tooltip = tooltipItems ? tooltipItems[0] : null;
                  const datasetIndex = tooltip ? tooltip.datasetIndex : null;
                  const label = tooltip
                    ? tooltip.label || tooltip.xLabel
                    : null;

                  if (datasetIndex < 2) {
                    return label;
                  }

                  return null;
                },
                label: (tooltipItems, data) => {
                  const { french } = this.props;
                  if (tooltipItems.datasetIndex < 2) {
                    let heading =
                      data.datasets[tooltipItems.datasetIndex].label;
                    heading =
                      french && heading === 'benchmark'
                        ? 'Référence'
                        : french && heading === 'Portfolio'
                        ? 'Portefuille'
                        : heading;
                    return `${heading[0].toUpperCase() + heading.slice(1)}: $${
                      tooltipItems.yLabel
                    }`;
                  }

                  const { crisisRets } = portfolioData;
                  const label = tooltipItems.label
                    ? tooltipItems.label
                    : tooltipItems.xLabel;
                  const ret = Object.keys(crisisRets).reduce(
                    (result, eventName) => {
                      const event = crisisRets[eventName];
                      const currentDate = new Date(label).getTime();
                      const eventStart = new Date(event.start).getTime();
                      const eventEnd =
                        event.end === 'Present'
                          ? currentDate
                          : new Date(event.end).getTime();

                      if (
                        eventStart <= currentDate &&
                        currentDate <= eventEnd
                      ) {
                        let compareRet = null;

                        if (
                          compare === 'benchmark' &&
                          benchmark === 'domestic'
                        ) {
                          compareRet = event.rets.benchmark.domestic;
                        } else if (
                          compare === 'benchmark' &&
                          benchmark === 'global'
                        ) {
                          compareRet = event.rets.benchmark.global;
                        } else if (
                          compare === 'benchmark' &&
                          benchmark === 'custom'
                        ) {
                          compareRet = event.rets.benchmark.custom;
                        } else if (
                          compare === 'market' &&
                          benchmark === 'domestic'
                        ) {
                          compareRet = event.rets.market.domestic;
                        } else if (
                          compare === 'market' &&
                          benchmark === 'global'
                        ) {
                          compareRet = event.rets.market.global;
                        } else if (
                          compare === 'market' &&
                          benchmark === 'custom'
                        ) {
                          compareRet = event.rets.market.custom;
                        }

                        const start = new Date(event.start);
                        const end =
                          event.end === 'Present'
                            ? 'Present'
                            : new Date(event.end);

                        const eventLabel = (name) =>
                          event.end === 'Present'
                            ? `${name.toUpperCase()} (${start.toLocaleDateString()} to Present)`
                            : `${name.toUpperCase()} (${start.toLocaleDateString()} to ${end.toLocaleDateString()})`;

                        if (eventName === 'Ukraine War') {
                          result[0] = eventLabel(
                            french
                              ? 'Début de la guerre en Ukraine'
                              : 'ONSET OF UKRAINE WAR'
                          );
                        } else if (eventName === 'Financial Crisis') {
                          result[0] = eventLabel(
                            french ? 'Crise financière' : 'Financial Crisis'
                          );
                        } else if (eventName === 'Oil Price Shock') {
                          result[0] = eventLabel(
                            french
                              ? 'Choc des prix du pétrole'
                              : 'Oil Price Shock'
                          );
                        } else if (eventName === 'Covid-19 Pandemic') {
                          result[0] = eventLabel(
                            french
                              ? 'Pandémie de Covid-19'
                              : 'Covid-19 Pandemic'
                          );
                        } else {
                          result[0] = eventLabel(eventName);
                        }

                        result[1] = `${
                          french ? 'Portefuille' : 'Portfolio'
                        }: ${(100 * event.rets.portfolio).toFixed(2)}%`;
                        const convertCompare =
                          french && compare === 'benchmark'
                            ? 'Référence'
                            : compare;
                        result[2] = `${
                          convertCompare[0].toUpperCase() +
                          convertCompare.slice(1)
                        }: ${(100 * compareRet).toFixed(2)}%`;
                      }

                      return result;
                    },
                    []
                  );

                  return ret;
                },
              },
            },
          }}
        />
      </div>
    );
  }
}

PortfolioHistoryChart.propTypes = {
  portfolioData: PropTypes.object.isRequired,
  compare: PropTypes.string.isRequired,
  benchmark: PropTypes.string.isRequired,
  displayDataSet: PropTypes.object,
  french: PropTypes.bool,
  partnerName: PropTypes.string,
};

PortfolioHistoryChart.defaultProps = {
  displayDataSet: {
    displayPortfolio: true,
    displayCompare: true,
  },
  french: false,
  partnerName: '',
};

export default PortfolioHistoryChart;
