import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header, Grid, Image, Statistic, Icon, Popup } from 'semantic-ui-react';
import { Line } from 'react-chartjs-2';
import cn from 'classnames';

import { colors, graph } from 'utils/colors';
import { Planning, Storage } from 'actions';
import { Button, Message } from 'components';
import Fifty from 'images/50.png';
import SeventyFive from 'images/75.png';
import Ninety from 'images/90.png';
import TaxImplications from './TaxImplications';

const optionSelected = {
  firstOption: 0.5,
  secondOption: 0.75,
  thirdOption: 0.9,
};

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = { maxOfChart: null };
    this.savingsResultDiv = null;
    this.savingsGraph = null;
    this.scrollToResult = this.scrollToResult.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { result, monthlySavingChosen } = this.props;
    if (prevProps.result !== result)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        maxOfChart: null,
      });

    if (monthlySavingChosen !== prevProps.monthlySavingChosen) {
      this.scrollToGraph();
    }
    if (result !== prevProps.result) {
      this.scrollToResult();
    }
  }

  componentWillUnmount() {
    const { dispatch, history, result } = this.props;

    if (history.location.pathname.includes('target_wealth') && result) {
      dispatch(Storage.removeItem('cacheSR'));
      dispatch(Storage.setItem('cacheSR', result));
    }
  }

  scrollToResult = () => {
    if (this.savingsResultDiv) {
      this.savingsResultDiv.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  scrollToGraph = () => {
    if (this.savingsGraph) {
      this.savingsGraph.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  getChartData = (paths) => {
    if (paths) {
      const { current, high, low } = paths;

      return {
        labels: new Array(Object.keys(current).length)
          .fill(0)
          .map((n, index) => Object.keys(current).length - index - 1),
        datasets: [
          {
            data: Object.values(current),
            borderWidth: 3,
            label: 'Meets Goal',
            lineTension: 0,
            fill: false,
            backgroundColor: graph.darkBlue,
            borderColor: graph.darkBlue,
            pointBackgroundColor: graph.darkBlue,
            pointBorderColor: graph.darkBlue,
            pointRadius: 0,
            pointHitRadius: 5,
          },
          {
            data: Object.values(low),
            borderWidth: 3,
            label: 'Low - 5th Percentile',
            lineTension: 0,
            fill: false,

            backgroundColor: graph.teal,
            borderColor: graph.teal,
            pointBackgroundColor: graph.teal,
            pointBorderColor: graph.teal,
            pointRadius: 0,
            pointHitRadius: 5,
          },
          {
            data: Object.values(high),
            borderWidth: 3,
            label: 'High - 95th Percentile',
            lineTension: 0,
            fill: false,
            backgroundColor: graph.lightBlue,
            borderColor: graph.lightBlue,
            pointBackgroundColor: graph.lightBlue,
            pointBorderColor: graph.lightBlue,

            pointRadius: 0,
            pointHitRadius: 5,
          },
        ],
      };
    }

    return false;
  };

  setChartMax = (label, index, values) => {
    const { maxOfChart } = this.state;

    if (values[0] > maxOfChart) {
      this.setState({ maxOfChart: values[0] });
    }
  };

  updateMonthlySavingChosen = (value) => {
    const { dispatch, cacheSavingChanged, cacheMSChanged } = this.props;
    dispatch(
      Planning.updateBlueprintData('targetSaving', {
        monthlySavingChosen: value,
      })
    );

    if (!cacheSavingChanged) {
      dispatch(Storage.setItem('cacheSavingChanged', true));
    }
    if (!cacheMSChanged) {
      dispatch(Storage.setItem('cacheMSChanged', true));
    }

    // this.scrollToBottom();
  };

  // scrollToBottom = () => this.scrollHandler(document.body.scrollHeight, 500);

  // scrollHandler = (to, duration) => {
  //   if (duration <= 0) return false;
  //   const el = document.scrollingElement || document.documentElement;
  //   const difference = to - el.scrollTop;
  //   const perTick = (difference / duration) * 10;

  //   return setTimeout(() => {
  //     el.scrollTop += perTick;
  //     if (el.scrollTop === to) {
  //       return false;
  //     }
  //     return this.scrollHandler(to, duration - 10);
  //   }, 10);
  // };

  displayYearMonth = (month) => {
    const year = month / 12;

    let str = '';

    if (year === 1) str = '1 year ';
    else str = `${year} years `;

    return (str += `(${month} months)`);
  };

  render() {
    const {
      result,
      isFetching,
      monthlySavingChosen,
      isCouplePlan,
      match,
      dispatch,
      history,
      setRef,
    } = this.props;
    const { maxOfChart } = this.state;
    const requiredSavings = !result ? null : result.prob_without_savings !== 1;
    const paths = result && result.paths ? result.paths : null;
    const chartOptions = {
      showTooltips: true,
      onAnimationComplete() {
        this.showTooltip(this.datasets[0].points, true);
      },
      tooltipEvents: [],
      hover: {
        animationDuration: 0,
        onHover: (e) => {
          e.target.style.cursor = 'pointer';
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: 'Months to Target Date',
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (label) => `$${label.toLocaleString()}`,
              max: maxOfChart,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => `${data.labels[tooltipItem[0].index]}`,
          label: (tooltipItems, data) =>
            `${data.datasets[tooltipItems.datasetIndex].label}: $${Math.ceil(
              Number(tooltipItems.yLabel)
            ).toLocaleString()}`,
        },
        mode: 'index',
        bodySpacing: 5,
      },
    };
    const { firstOption, secondOption, thirdOption } = optionSelected;
    if (setRef) {
      setRef(this.scrollToResult);
    }

    return !result || !Object.keys(result).length ? null : (
      <div id="savingsResult" ref={(el) => (this.savingsResultDiv = el)}>
        <BlueprintContent style={{ opacity: isFetching ? 0.4 : 1 }}>
          <div
            style={{
              visibility: 'hidden',
              height: '2rem',
            }}
          >
            <Line
              setChartMax={this.setChartMax}
              data={() => {
                const dataSets = [];
                const values = Object.values(paths || {});

                values.forEach((v) => {
                  if (v) dataSets.push(...Object.values(v));
                });
                const temp = dataSets.map((d, index) => ({
                  data: Object.values(d),
                  borderWidth: 2,
                  label: index,
                  lineTension: 0,
                  fill: false,
                  backgroundColor: graph.red,
                  borderColor: graph.red,
                  pointBackgroundColor: graph.red,
                  pointBorderColor: graph.red,
                  pointRadius: 0,
                  pointHitRadius: 5,
                }));

                return {
                  datasets: temp,
                };
              }}
              options={{
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        callback: (label, index, values) =>
                          this.setChartMax(label, index, values),
                      },
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    title: (tooltipItem, data) =>
                      `${data.labels[tooltipItem[0].index]}`,
                    label: (tooltipItems, data) =>
                      `${
                        data.datasets[tooltipItems.datasetIndex].label
                      }: $${Math.ceil(
                        Number(tooltipItems.yLabel)
                      ).toLocaleString()}`,
                  },
                },
              }}
            />
          </div>

          <Header textAlign="center" size="medium">
            How Much to Save per Month
            <Header.Subheader style={{ margin: '.3rem auto .6rem' }}>
              Add this amount to your portfolio. Since the portfolio has
              uncertain outcomes, how much you need to save is associated with a
              probability. The probability represents the likelihood of reaching
              your goal by the target date. The results are generated based on
              10,000 simulations.
            </Header.Subheader>
          </Header>
          {/* {this.isChangedFromPrevious() && !isFetching && (
          <div
            style={{
              marginTop: '1.5rem',
              color: colors.red,
              fontSize: '1.2rem',
            }}
          >
            Note: These values have been updated to reflect changes made in the
            previous section(s).
          </div>
        )} */}
          {!!requiredSavings ? (
            <Grid style={{ width: '100%', margin: '1rem auto' }}>
              <Grid.Row>
                <Grid.Column>
                  <LessHeader>Likelihood of Success</LessHeader>
                  <Header size="medium">
                    <Header.Subheader style={{ margin: '.3rem auto .6rem' }}>
                      Without additional savings, the probability of reaching
                      your target wealth is{' '}
                      <span
                        style={{
                          margin: '0 0.2rem 0 0.2rem',
                          color: colors.navyBlue,
                          fontWeight: 'bold',
                          fontSize: '1.3rem',
                        }}
                      >
                        {`${Math.trunc(result.prob_without_savings * 100)} %`}
                      </span>
                      .
                      <br />
                      To improve the probability of reaching your goal, please
                      select a monthly savings target below:
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row
                columns={3}
                style={{ padding: 0, marginTop: '10px', cursor: 'pointer' }}
              >
                <Grid.Column
                  onClick={
                    result['0.5'] === 0
                      ? () => {}
                      : () => {
                          this.updateMonthlySavingChosen(firstOption);
                        }
                  }
                  style={{
                    cursor: result['0.5'] === 0 ? 'default' : 'pointer',
                  }}
                >
                  <Image
                    src={Fifty}
                    style={{ width: '70%', margin: '0 auto' }}
                  />
                  <Statistic size="mini" style={{ display: 'block' }}>
                    <Statistic.Label>Required Monthly Savings</Statistic.Label>
                    <Statistic.Value>{`$ ${result[
                      '0.5'
                    ].toLocaleString()}`}</Statistic.Value>
                  </Statistic>

                  <Button
                    className={cn('left', { disabled: result['0.5'] === 0 })}
                    style={{ margin: '0 auto' }}
                    name="monthlySavingChosen"
                    value={result['0.5']}
                    color={
                      monthlySavingChosen === 0.5
                        ? colors.teal
                        : colors.lightGrey
                    }
                    icon={
                      monthlySavingChosen === 0.5 ? 'check' : 'circle outline'
                    }
                  >
                    {monthlySavingChosen === 0.5 ? 'Selected' : 'Select'}
                  </Button>
                </Grid.Column>
                <Grid.Column
                  onClick={
                    result['0.75'] === 0
                      ? () => {}
                      : () => {
                          this.updateMonthlySavingChosen(secondOption);
                        }
                  }
                  style={{
                    cursor: result['0.75'] === 0 ? 'default' : 'pointer',
                  }}
                >
                  <Image
                    src={SeventyFive}
                    style={{ width: '70%', margin: '0 auto' }}
                  />
                  <Statistic size="mini" style={{ display: 'block' }}>
                    <Statistic.Label>Required Monthly Savings</Statistic.Label>
                    <Statistic.Value>{`$ ${result[
                      '0.75'
                    ].toLocaleString()}`}</Statistic.Value>
                  </Statistic>

                  <Button
                    className={cn('left', { disabled: result['0.75'] === 0 })}
                    style={{ margin: '0 auto' }}
                    name="monthlySavingChosen"
                    value={result['0.75']}
                    color={
                      monthlySavingChosen === 0.75
                        ? colors.lightBlue
                        : colors.lightGrey
                    }
                    icon={
                      monthlySavingChosen === 0.75 ? 'check' : 'circle outline'
                    }
                  >
                    {monthlySavingChosen === 0.75 ? 'Selected' : 'Select'}
                  </Button>
                </Grid.Column>
                <Grid.Column
                  onClick={
                    result['0.9'] === 0
                      ? () => {}
                      : () => {
                          this.updateMonthlySavingChosen(thirdOption);
                        }
                  }
                  style={{
                    cursor: result['0.9'] === 0 ? 'default' : 'pointer',
                  }}
                >
                  <Image
                    src={Ninety}
                    style={{ width: '70%', margin: '0 auto' }}
                  />
                  <Statistic size="mini" style={{ display: 'block' }}>
                    <Statistic.Label>Required Monthly Savings</Statistic.Label>
                    <Statistic.Value>{`$ ${result[
                      '0.9'
                    ].toLocaleString()}`}</Statistic.Value>
                  </Statistic>

                  <Button
                    className={cn('left grey-focus2', {
                      disabled: result['0.9'] === 0,
                    })}
                    style={{ margin: '0 auto' }}
                    name="monthlySavingChosen"
                    value={result['0.9']}
                    color={
                      monthlySavingChosen === 0.9
                        ? colors.blue
                        : colors.lightGrey
                    }
                    icon={
                      monthlySavingChosen === 0.9 ? 'check' : 'circle outline'
                    }
                  >
                    {monthlySavingChosen === 0.9 ? 'Selected' : 'Select'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <Message
              visible
              warning
              style={{ maxWidth: '44rem', margin: '0 auto', marginTop: '2rem' }}
            >
              <Message.Header>
                It appears that you do not require additional savings for your
                retirement
              </Message.Header>
            </Message>
          )}
          {(result['0.5'] === 0 ||
            result['0.75'] === 0 ||
            result['0.90'] === 0) &&
            requiredSavings && (
              <Message
                visible
                warning
                style={{
                  maxWidth: '44rem',
                  margin: '0 auto',
                  marginTop: '2rem',
                }}
              >
                <Message.Header>
                  For scenarios with $0, no additional savings are required.
                </Message.Header>
              </Message>
            )}

          {result &&
          monthlySavingChosen &&
          result[monthlySavingChosen] &&
          result.paths &&
          result.paths[monthlySavingChosen] ? (
            <div style={{ marginBottom: '2rem', marginTop: '6rem' }}>
              <Header as="h3" style={{ margin: '0rem auto 0.25rem' }}>
                {'Simulated saving outcomes'.toUpperCase()}
              </Header>
              <Header as="h4" style={{ margin: '0 auto 0.25rem' }}>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    color: colors.darkGrey,
                  }}
                >
                  {Math.round(monthlySavingChosen * 100)}%
                </span>{' '}
                of the final outcomes fall between the middle and the top series
              </Header>
              {paths[0.5] ? (
                <Header.Subheader style={{ marginBottom: '2rem' }}>
                  The future value of your goal is $
                  {Math.ceil(
                    paths[0.5].current[paths[0.5].current.length - 1]
                  ).toLocaleString()}{' '}
                  in {this.displayYearMonth(paths[0.5].current.length)}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    wide
                  >
                    Assuming an average annual inflation rate of 2%
                  </Popup>
                </Header.Subheader>
              ) : null}
              <div id="savingsGraph" ref={(el) => (this.savingsGraph = el)}>
                <Line
                  data={this.getChartData(paths[monthlySavingChosen])}
                  options={chartOptions}
                />
              </div>
            </div>
          ) : null}
          <div style={{ clear: 'both' }} />
        </BlueprintContent>{' '}
        {result &&
        monthlySavingChosen &&
        result[monthlySavingChosen] &&
        result.paths &&
        result.paths[monthlySavingChosen] ? (
          <TaxImplications
            isCouplePlan={isCouplePlan}
            tax_implications={result.tax_implications}
            result={result}
            history={history}
            dispatch={dispatch}
            match={match}
            monthlySavingChosen={monthlySavingChosen}
            isFetching={isFetching}
          />
        ) : null}
      </div>
    );
  }
}

Result.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  result: PropTypes.object,
  monthlySavingChosen: PropTypes.number,
  cacheSavingChanged: PropTypes.bool,
  cacheMSChanged: PropTypes.bool,
  isCouplePlan: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
};

Result.defaultProps = {
  result: null,
  monthlySavingChosen: null,
  history: {},
  match: {},
  cacheSavingChanged: false,
  cacheMSChanged: false,
  isCouplePlan: false,
};

export default connect((state) => ({
  cacheSR: state.Storage.cacheSR,
  cacheSavingChanged: state.Storage.cacheSavingChanged,
  cacheMSChanged: state.Storage.cacheMSChanged,
  cacheWealthChanged: state.Storage.cacheWealthChanged,
}))(Result);

const LessHeader = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  font-size: 1.2em;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 64%;
    background-color: rgba(0, 0, 0, 0.4);
    left: 18%;
  }
`;

const BlueprintContent = styled.div`
  padding: ${(props) =>
    props.isClientMode ? '2rem 3rem 4rem 3rem' : '2rem 3rem'};
  text-align: center;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 2rem;

  .header {
    .sub.header {
      font-size: 1.2rem;
    }
  }
  .mini.statistic {
    font-size: 16px;
    .label {
      text-transform: none !important;
      line-height: 2rem;
    }
    .value {
      font-size: 21px !important;
    }
  }
`;
