import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon, Popup, Form, Radio } from 'semantic-ui-react';
import cn from 'classnames';

import { AccountsDropdown } from 'components';
import { Planning } from 'actions';
import { colors } from 'utils/colors';

@connect((state) => ({
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class AccountValues extends Component {
  state = {
    messageHasAppeared: {
      spouse: false,
      spouse2: false,
    },
  };
  setMessageHasAppeared = (activeSpouse) => {
    this.setState({
      messageHasAppeared: {
        ...this.state.messageHasAppeared,
        [activeSpouse]: true,
      },
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch } = this.props;

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'cpp2',
        'oas',
        'oas2',
        'pension',
        'pension2',
        'other',
        'other2',
        'initRegular',
        'initRegular2',
        'initRRIF',
        'initRRIF2',
        'initTFSA',
        'initTFSA2',
        'endValue',
        'endValue2',
      ];

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (inputs, portfolios) => {
    const { activeSpouse } = inputs;
    const isRiskFreeField =
      activeSpouse === 'spouse' ? 'isRiskFree' : 'isRiskFree2';
    const portfolioField =
      activeSpouse === 'spouse' ? 'portfolio' : 'portfolio2';
    if (inputs[isRiskFreeField] === undefined) return false;
    if (inputs[isRiskFreeField]) return false;
    if (inputs[portfolioField] === undefined) return false;

    return !portfolios.find((p) => p.id === inputs[portfolioField]);
  };

  handleRadioChange = (e, { value, name }) => {
    const { drawdown, dispatch } = this.props;
    const { inputs } = drawdown;
    const nameListMapping = {
      initRegular: 'initRegAccList',
      initRRIF: 'initRRIFAccList',
      totalTFSA: 'initTFSAAccList',
    };
    this.setState({ [name]: value });
    dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: value },
        errors: { [name]: false },
        modified: true,
      })
    );
    const type = name.split('FromAcc')[0];
    const selectedType = nameListMapping[type].concat(
      inputs.activeSpouse !== 'spouse' ? '2' : ''
    );

    if (!value) {
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: { [selectedType]: [] },
          modified: true,
        })
      );
    }
  };

  render() {
    const { inputs, errors } = this.props.drawdown;
    const { accountList } = this.props;
    const { activeSpouse } = inputs;

    const initRegularField =
      activeSpouse === 'spouse' ? 'initRegular' : 'initRegular2';
    const initRegularFromAccField =
      activeSpouse === 'spouse' ? 'initRegularFromAcc' : 'initRegularFromAcc2';
    const initRegAccListField =
      activeSpouse === 'spouse' ? 'initRegAccList' : 'initRegAccList2';
    const initRRIFFromAccField =
      activeSpouse === 'spouse' ? 'initRRIFFromAcc' : 'initRRIFFromAcc2';
    const initRRIFField = activeSpouse === 'spouse' ? 'initRRIF' : 'initRRIF2';
    const initRRIFAccListField =
      activeSpouse === 'spouse' ? 'initRRIFAccList' : 'initRRIFAccList2';
    const totalTFSAFromAccField =
      activeSpouse === 'spouse' ? 'totalTFSAFromAcc' : 'totalTFSAFromAcc2';
    const initTFSAAccListField =
      activeSpouse === 'spouse' ? 'initTFSAAccList' : 'initTFSAAccList2';
    const initTFSAField = activeSpouse === 'spouse' ? 'initTFSA' : 'initTFSA2';
    const endValueField = activeSpouse === 'spouse' ? 'endValue' : 'endValue2';
    const isRiskFreeField =
      activeSpouse === 'spouse' ? 'isRiskFree' : 'isRiskFree2';
    const divYieldField = activeSpouse === 'spouse' ? 'divYield' : 'divYield2';
    const pctEligibleDivField =
      activeSpouse === 'spouse' ? 'pctEligibleDiv' : 'pctEligibleDiv2';
    const pctCapGainField =
      activeSpouse === 'spouse' ? 'pctCapGain' : 'pctCapGain2';

    return (
      <div>
        <Section single>
          <div>
            <div className="title">
              Total non-registered (taxable)
              <Popup
                trigger={<Icon name="question circle outline" />}
                position="right center"
                content="If you have a joint account, please enter the equivalent value of your share."
              />
            </div>
            {inputs[initRegularFromAccField] ? (
              <AccountsDropdown
                inputs={inputs}
                error={errors[initRegularField]}
                accountList={accountList}
                selectedAccList={inputs[initRegAccListField] || []}
                excludedList={[]}
                savedAccountsValue={inputs[initRegularField] || 0}
                assignAccountsValue={(selectedAccounts, accountsValue) => {
                  this.props.dispatch(
                    Planning.drawdownUpdateInput({
                      inputs: {
                        [initRegAccListField]: selectedAccounts,
                        [initRegularField]: accountsValue,
                      },

                      errors: { [initRegularField]: false },
                      modified: true,
                    })
                  );
                }}
                float
                messageHasAppeared={this.state.messageHasAppeared}
                setMessageHasAppeared={this.setMessageHasAppeared}
              />
            ) : (
              <Input
                className={cn('large', {
                  invalid: !!errors[initRegularField],
                })}
                name={initRegularField}
                type="number"
                placeholder="$"
                value={
                  inputs[initRegularField] === 0
                    ? 0
                    : inputs[initRegularField] || ''
                }
                onChange={this.handleChange}
              />
            )}
            <div style={{ margin: '0 0 0.5rem 1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name={initRegularFromAccField}
                    value={false}
                    checked={!inputs[initRegularFromAccField]}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name={initRegularFromAccField}
                    value
                    checked={inputs[initRegularFromAccField]}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          {!inputs[isRiskFreeField] ? (
            <ul>
              <li className="visible">
                <label>
                  Roughly what % of this value is from capital gains?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="right center"
                    content="To calculate this percentage, subtract total book costs from total market value (giving you the amount of capital gains) and divide by the total market value. We require this information as capital gains are taxed differently from dividends."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid:
                      errors[pctCapGainField] || errors[pctCapGainField]
                        ? 'true'
                        : undefined,
                  })}
                  name={pctCapGainField}
                  type="number"
                  placeholder="%"
                  value={inputs[pctCapGainField] || ''}
                  onChange={this.handleChange}
                />
              </li>

              <li className="visible">
                <label>
                  What is the dividend yield?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="right center"
                    content="We assume that all dividends are withdrawn from taxable accounts for spending. The dividend yield is pre-populated given the portfolio you selected. This figure is used to calculate the dividend tax."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors[divYieldField] ? 'true' : undefined,
                  })}
                  name={divYieldField}
                  type="number"
                  placeholder="%"
                  value={inputs[divYieldField] || ''}
                  onChange={this.handleChange}
                />
              </li>

              <li className="visible">
                <label>
                  Percentage of dividends from Canadian stocks?
                  <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="right center"
                    content="Canadian dividends are eligible for a tax credit."
                    wide
                  />
                </label>
                <Input
                  className={cn({
                    invalid: errors[pctEligibleDivField] ? 'true' : undefined,
                  })}
                  name={pctEligibleDivField}
                  type="number"
                  placeholder="%"
                  value={inputs[pctEligibleDivField] || ''}
                  onChange={this.handleChange}
                />
              </li>
            </ul>
          ) : null}

          <div>
            <div>
              <div className="title">
                Total Registered (RRIF/LIF)
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  position="right center"
                  content="Your RRSP/Defined Contribution (DC) Plan turn into an income fund (RRIF/LIF) at retirement."
                  wide
                />
              </div>
              {inputs[initRRIFFromAccField] ? (
                <AccountsDropdown
                  inputs={inputs}
                  error={errors[initRRIFField]}
                  accountList={accountList}
                  excludedList={[]}
                  selectedAccList={inputs[initRRIFAccListField] || []}
                  savedAccountsValue={inputs[initRRIFField] || 0}
                  assignAccountsValue={(selectedAccounts, accountsValue) => {
                    this.props.dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          [initRRIFAccListField]: selectedAccounts,
                          [initRRIFField]: accountsValue,
                        },
                        errors: { [initRRIFFromAccField]: false },
                        modified: true,
                      })
                    );
                  }}
                  float
                  messageHasAppeared={this.state.messageHasAppeared}
                  setMessageHasAppeared={this.setMessageHasAppeared}
                />
              ) : (
                <Input
                  className={cn('large', {
                    invalid: errors[initRRIFField] ? 'true' : undefined,
                  })}
                  name={initRRIFField}
                  type="number"
                  placeholder="$"
                  value={
                    inputs[initRRIFField] === 0
                      ? 0
                      : inputs[initRRIFField] || ''
                  }
                  onChange={this.handleChange}
                />
              )}
            </div>

            <div style={{ marginLeft: '1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name={initRRIFFromAccField}
                    value={false}
                    checked={!inputs[initRRIFFromAccField]}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name={initRRIFFromAccField}
                    value
                    checked={inputs[initRRIFFromAccField]}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          <div>
            <div>
              <div className="title">Total TFSA</div>
              {inputs[totalTFSAFromAccField] ? (
                <AccountsDropdown
                  inputs={inputs}
                  error={errors[initTFSAField]}
                  accountList={accountList}
                  excludedList={[]}
                  selectedAccList={inputs[initTFSAAccListField] || []}
                  savedAccountsValue={inputs[initTFSAField] || 0}
                  assignAccountsValue={(selectedAccounts, accountsValue) => {
                    this.props.dispatch(
                      Planning.drawdownUpdateInput({
                        inputs: {
                          [initTFSAAccListField]: selectedAccounts,
                          [initTFSAField]: accountsValue,
                        },
                        errors: { [initTFSAField]: false },
                        modified: true,
                      })
                    );
                  }}
                  float
                  messageHasAppeared={this.state.messageHasAppeared}
                  setMessageHasAppeared={this.setMessageHasAppeared}
                />
              ) : (
                <Input
                  className={cn('large', {
                    invalid: errors[initTFSAField] ? 'true' : undefined,
                  })}
                  name={initTFSAField}
                  type="number"
                  placeholder="$"
                  value={
                    inputs[initTFSAField] === 0
                      ? 0
                      : inputs[initTFSAField] || ''
                  }
                  onChange={this.handleChange}
                />
              )}
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <Form>
                <Form.Field>
                  <Radio
                    name={totalTFSAFromAccField}
                    value={false}
                    checked={!inputs[totalTFSAFromAccField]}
                    onChange={this.handleRadioChange}
                    label="Input Value"
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    name={totalTFSAFromAccField}
                    value
                    checked={inputs[totalTFSAFromAccField]}
                    onChange={this.handleRadioChange}
                    label="Select From Accounts"
                  />
                </Form.Field>
              </Form>
            </div>
          </div>

          <div className="title">
            Bequest
            <Popup
              trigger={<Icon name="question circle outline" />}
              position="right center"
              content="This is your legacy, for your family and charitable donations."
              wide
            />
          </div>

          <ul>
            <li className="visible">
              <label>
                How much would you like to leave (in today's dollars)?
              </label>
              <Input
                className={cn({
                  invalid: errors[endValueField] ? 'true' : undefined,
                })}
                name={endValueField}
                type="number"
                placeholder="$"
                value={
                  inputs[endValueField] === 0 ? 0 : inputs[endValueField] || ''
                }
                onChange={this.handleChange}
              />
            </li>
          </ul>
        </Section>
      </div>
    );
  }
}

AccountValues.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  accountList: PropTypes.array,
};

AccountValues.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  accountList: [],
};

const Section = styled.section`
  padding-left: 2rem;
  position: relative;
  text-align: left;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const Input = styled.input`
  float: right;
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
