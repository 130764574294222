import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Header, Icon, Divider } from 'semantic-ui-react';
import { decideCurrency } from 'utils/helpers';
import { Score, Table } from 'components';

@connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
}))
export default class Downside extends Component {
  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '-');
  getDownside = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0%');

  parseDate = (portfolio) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  render() {
    const { benchmark, data, user_region } = this.props;

    return (
      <Container>
        <Score
          name="Downside "
          score={`${data.score.Volatility[benchmark][0]}%`}
          grade={data.score.Volatility[benchmark][1]}
          description="Scored on three downside measures of risk: maximum drawdown, downside risk, and downside capture (relative to those of the benchmark).<br />If your portfolio is just matching (rather than outperforming) the simple benchmark, it will get a pass, at 65%."
          portfolio={data.portfolio}
        />

        <p>
          Downside protection speaks to your {"portfolio's"} ability to limit
          losses when there is a market downturn.
        </p>
        <p>
          In portfolio management, the traditional measure of risk (or
          volatility) is the standard deviation of a portfolio’s historical
          returns. It is a measure of the {"portfolio's"} <strong>total</strong>{' '}
          risk. It is reported as an annualized percentage for easy comparison
          across portfolios and time periods.
        </p>
        <p>
          It is important to note that total risk has an upside and a{' '}
          <strong>downside</strong>. Over time, portfolio returns will fluctuate
          above the average (or your target return), as well as{' '}
          <strong>below</strong>. It is human nature and often rational to be
          more concerned about downside risk than upside risk.
        </p>

        <Header>
          <Header.Content>Key Statistics</Header.Content>
          <Header.Subheader>
            {`${this.parseDate(data.portfolio)} (inclusive) in ${decideCurrency(
              data,
              user_region || 'CA'
            )}`}
          </Header.Subheader>
        </Header>
        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                Total Risk&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This is the variability of your portfolio's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Downside Risk&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This is the variability of your portfolio's returns below a target. The target used here is capital preservation; that is, a non-negative return."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Maximum Drawdown&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This is the maximum peak to trough loss observed during the period in question. It gives a different perspective of a portfolio's downside risk."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Downside Capture&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This measures whether your portfolio lost less than the stock market when there was a market downturn. A downside capture of less than 100% indicates that your portfolio lost less than the stock market during those periods. A downside capture that is negative indicates that your portfolio actually generated positive returns when the stock market was in the red."
                  wide
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell>My Portfolio</Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.stdDev)}
              </Table.Cell>
              <Table.Cell>
                {this.getDownside(data.fratios.portfolio.dsr)}
              </Table.Cell>
              <Table.Cell>
                {this.getDownside(data.fratios.portfolio.maxDd)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.dscapture[benchmark])}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {data.fratios[benchmark].name}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={data.fratios[benchmark].description}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].stdDev)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].dsr)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].maxDd)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].dscapture)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

Downside.propTypes = {
  data: PropTypes.object.isRequired,
  benchmark: PropTypes.string.isRequired,
  user_region: PropTypes.string,
};

Downside.defaultProps = {
  user_region: 'CA',
};

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
