import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import { RebalanceAccount } from 'actions';
import { colors } from 'utils/colors';
import { Button, Modal } from 'components';
import { notWealthica } from 'utils/helpers';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))
export default class DeleteModal extends Component {
  state = {
    loading: false,
  };

  deleteRebalancing = () => {
    const { history, accountId, dispatch } = this.props;

    this.setState({ loading: true });

    return dispatch(RebalanceAccount.removeSystem(accountId)).then((res) => {
      if (res.success) {
        history.push(`/accounts/${accountId}`);
      }
    });
  };

  render() {
    const { open, partnerName } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        dimmerClick={this.props.closeModal}
        basic
        open={open}
        vb={notWealthica(partnerName)}
      >
        <Modal.Header>
          <Header
            icon="warning sign"
            content="Delete Rebalancing System"
            style={{ color: colors.red }}
          />
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete the rebalancing system?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: loading }
            )}
            color={colors.red}
            icon="cancel"
            margin="0 0.5rem 0 0"
            onClick={this.props.closeModal}
          >
            Cancel
          </Button>
          <Button
            className={cn(
              `${notWealthica(partnerName) ? 'left' : 'inverted left'}`,
              { disabled: loading }
            )}
            icon="checkmark"
            color={colors.green}
            onClick={this.deleteRebalancing}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  partnerName: PropTypes.string,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

DeleteModal.defaultProps = {
  dispatch: () => false,
  history: {},
  partnerName: '',
};
