import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Header, Segment, Feature, NeuCard } from 'components';
import { colors } from 'utils/colors';
import { planningLimit } from 'utils/constants';
import { Planning } from 'actions';
import {
  PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN,
  PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_TARGET_SPENDING,
  PLANNING_DECUMULATION_REVISIT_DRAWDOWN,
  PLANNING_DECUMULATION_PORTFOLIO_LONGEVITY,
  PLANNING_DECUMULATION_RUIN_PROBABILITY,
  PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_COUPLES,
} from 'utils/features';
import { notWealthica } from 'utils/helpers';
import asset4 from 'images/asset_4.png';

@withRouter
@connect((state) => ({
  account: state.CustomAccount.account,
  drawdownPlans: state.Planning.savedDrawdownPlans,
  drawdownTargetPlans: state.Planning.savedTargetDrawdownPlans,
  partnerName: state.Storage['partner-name'],
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  wealthicaUser: state.Planning.wealthicaUser,
}))
class Landing extends Component {
  componentDidMount() {
    const { dispatch, is_wealthica_trial } = this.props;

    dispatch(Planning.clearStore());
    dispatch(Planning.clearPlanning());
    dispatch(Planning.fetchAllDrawdownPlans());
    dispatch(Planning.fetchAllTargetDrawdownPlans());

    if (is_wealthica_trial) dispatch(Planning.fetchWealthicaUser());
  }

  isReachDrawdownLimit = () => {
    const { drawdownPlans, partnerName } = this.props;

    if (notWealthica(partnerName)) return false;

    return drawdownPlans.length >= planningLimit;
  };

  isReachTargetDrawdownLimit = () => {
    const { drawdownTargetPlans, partnerName } = this.props;

    if (notWealthica(partnerName)) return false;

    return drawdownTargetPlans.length >= planningLimit;
  };

  isReachTrialDrawdownLimit = () => {
    const { partnerName, wealthicaUser, is_wealthica_trial } = this.props;
    const { planning_restrictions } = wealthicaUser;

    if (notWealthica(partnerName)) return false;
    return (
      is_wealthica_trial &&
      planning_restrictions &&
      !planning_restrictions.drawdown_allowed
    );
  };

  isReachTrialTargetDrawdownLimit = () => {
    const { partnerName, wealthicaUser, is_wealthica_trial } = this.props;
    const { planning_restrictions } = wealthicaUser;

    if (notWealthica(partnerName)) return false;
    return (
      is_wealthica_trial &&
      planning_restrictions &&
      !planning_restrictions.target_drawdown_allowed
    );
  };

  render() {
    return (
      <Segment expand>
        <Feature name={PLANNING_DECUMULATION_REVISIT_DRAWDOWN}>
          <Link to="/planning/drawdown-plans">
            <MyButton
              className="left"
              icon="line chart"
              style={{ position: 'absolute', top: '1rem', right: '4.5rem' }}
              onClick={this.handleRedirect}
            >
              <i className="folder open icon" style={{ float: 'left' }} />
              See Saved Drawdowns
            </MyButton>
          </Link>
        </Feature>

        <div>
          <img src={asset4} height="80" width="120" alt="Portfolio" />
          <Header size="1.5rem" color="black">
            Decumulation
          </Header>
          <P>
            Here are a couple of simple-to-use tools to help you calculate your
            target wealth at retirement and how much you need to save in order
            to reach a particular goal, such as your children’s education or
            retirement.
          </P>
          {this.isReachDrawdownLimit() ? (
            <p style={{ color: colors.red, margin: '0 auto 1rem' }}>
              Note: You have reached the limit of 5 Optimal Drawdown Plans.
              <br /> If you would like to add a new one, please delete an
              existing saved Drawdown.
            </p>
          ) : null}
          {this.isReachTargetDrawdownLimit() ? (
            <p style={{ color: colors.red, margin: '0 auto 1rem' }}>
              Note: You have reached the limit of 5 Drawdown plans with Target
              Spending.
              <br /> If you would like to add a new one, please delete an
              existing saved Drawdown with Target Spending.
            </p>
          ) : null}

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Feature name={PLANNING_DECUMULATION_PORTFOLIO_LONGEVITY}>
              <NeuCard
                to="/planning/longevity"
                iconName="road"
                title="Portfolio Longevity"
                description="See how long your savings will last if you are retired with
                    different investment options."
                color={colors.orange}
              />
            </Feature>
            <Feature name={PLANNING_DECUMULATION_RUIN_PROBABILITY}>
              <NeuCard
                to="/planning/ruin-probability"
                iconName="warning sign"
                title="Probability of Ruin"
                description="What are the chances that you will outlive your money? We show you the results by comparing your human longevity and your portfolio longevity."
                color={colors.orange}
              />
            </Feature>

            <Feature name={PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN}>
              <NeuCard
                to="/planning/drawdown/new"
                iconName="warning sign"
                title="Optimal Drawdown"
                description="See how much you can afford to spend per year given your
                    actual savings and retirement income, and from which sources
                    (registered and non-registered savings)."
                color={colors.orange}
                disabled={this.isReachTrialDrawdownLimit()}
                popup={{
                  position: 'top center',
                  content:
                    "You've reached your limit of 2 Drawdown calculations during your 7 day trial",
                  wide: true,
                }}
              />
            </Feature>

            <Feature
              name={PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_TARGET_SPENDING}
            >
              <NeuCard
                to="/planning/drawdown-target-spending/new"
                iconName="target"
                title="Optimal Drawdown with Target Spending"
                description="You have an annual spending amount in mind. Use this tool to
                    see how to optimally withdraw from your registered and
                    non-registered savings to minimize taxes."
                color={colors.orange}
                disabled={this.isReachTrialTargetDrawdownLimit()}
                popup={{
                  position: 'top center',
                  content:
                    "You've reached your limit of 2 Drawdown with Target Spending calculations during your 7 day trial",
                  wide: true,
                }}
              />
            </Feature>

            <Feature name={PLANNING_DECUMULATION_OPTIMAL_DRAWDOWN_COUPLES}>
              <NeuCard
                to="/planning/drawdown-couples/new"
                color={colors.orange}
                width="50%"
                iconName="signal"
                title="Optimal Drawdown for Couples"
                description="See if there is a tax advantage to filing your and your spouse/partner's returns as a couple. This tool checks for eligible pension income splitting and credit transfers."
              />
            </Feature>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
  drawdownPlans: PropTypes.array,
  drawdownTargetPlans: PropTypes.array,
  partnerName: PropTypes.string,
  is_wealthica_trial: PropTypes.bool,
  wealthicaUser: PropTypes.object,
};

Landing.defaultProps = {
  drawdownPlans: [],
  drawdownTargetPlans: [],
  partnerName: '',
  is_wealthica_trial: false,
  wealthicaUser: {},
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 650px;
  margin: 0 auto 1rem auto;

  &.strike {
    text-decoration: line-through;
  }
`;

const MyButton = styled.button`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 10px 0;
  padding: 0px;
  height: 50px;
  border-width: 0;
  color: black;
  background-color: transparent;
  text-decoration: none;
  line-height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  overflow: visible;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
    color: ${colors.blue};
  }
`;
