import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Popup, Icon, Header, Dropdown, Label } from 'semantic-ui-react';
import cn from 'classnames';
import Fade from 'react-reveal/Fade';

import { Portfolio, Security, ChartTool } from 'actions';
import { Segment, SearchTicker } from 'components';
import { chartingToolColors, colors } from 'utils/colors';
import Graph from './Graph';

class ChartingTool extends Component {
  state = { headerFixed: false, warningMessage: '' };
  componentWillMount = () => {
    const { dispatch, portfolios } = this.props;

    if (!portfolios.length) {
      dispatch(Portfolio.fetchModel());
      dispatch(Portfolio.fetchUser());
    }
    return false;
  };

  componentDidMount = () => {
    const { inputs } = this.props;
    const { securityList, portfolioList } = inputs;

    if (
      securityList &&
      portfolioList &&
      (securityList.length || portfolioList.length)
    ) {
      this.drawChart();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { inputs } = this.props;

    if (JSON.stringify(prevProps.inputs) !== JSON.stringify(inputs)) {
      this.drawChart();
    }
  };

  getResults = () => {
    const { inputs } = this.props;
    const { search } = this.props.security;
    const { allNamesList } = inputs;

    return search.reduce((total, security) => {
      if (security) {
        if (!total[security.asset_type]) {
          total[security.asset_type] = {
            name: security.asset_type,
            results: [
              {
                title: security.ticker,
                description: security.long_name,
                price: security.exchange_name,
              },
            ],
          };
        } else {
          total[security.asset_type].results.push({
            title: security.ticker,
            description: security.long_name,
            price: security.exchange_name,
          });
        }
      }

      if (total.ETF !== undefined) {
        const etfArray = total.ETF.results;
        const filteredETFs = etfArray.filter(
          (securityItem) => !allNamesList.includes(securityItem.title)
        );
        total.ETF.results = filteredETFs;
      }

      if (total.Stock !== undefined) {
        const stockArray = total.Stock.results;
        const filteredStocks = stockArray.filter(
          (securityItem) => !allNamesList.includes(securityItem.title)
        );
        total.Stock.results = filteredStocks;
      }
      return total;
    }, {});
  };

  getDropOptions = () => {
    const { portfolios, inputs, user_region } = this.props;
    const { allNamesList } = inputs;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    const filteredPortfolios = portfolios.filter(
      (portfolio) => !allNamesList.includes(portfolio.name)
    );

    return filteredPortfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      text:
        user_region === 'US' ? this.nameForUsVersion(portf.name) : portf.name,
    }));
  };

  handleSearchChange = (value) => {
    const { dispatch, user_region } = this.props;
    const { warningMessage } = this.state;

    if (
      warningMessage !==
      'Maximum number of securities reached. Please remove one before adding.'
    ) {
      this.setState({ warningMessage: '' });
    }

    return (
      !!value.trim() &&
      dispatch(
        Security.searchAll(window.encodeURIComponent(value), user_region)
      )
    );
  };

  handleDeletion = (item) => {
    const { dispatch, inputs } = this.props;
    const {
      portfolioNames,
      portfolioList,
      securityList,
      allNamesList,
      periodStart,
      periodEnd,
    } = inputs;
    function matchesItemName(element) {
      return element.name === item || element.id === Number(item);
    }

    const portfolioLoc = portfolioNames.findIndex(matchesItemName);
    const newPortfolioNames = portfolioNames;
    let newPortfolioList = portfolioList;
    let newSecurityList = securityList;
    let newAllNamesList = allNamesList.filter((val) => val !== item);

    if (portfolioLoc !== -1) {
      const portfolioId = portfolioNames[portfolioLoc].id;
      const deletedPort = newPortfolioNames.splice(portfolioLoc, 1);
      newPortfolioList = portfolioList.filter((val) => val !== portfolioId);
      newAllNamesList = newAllNamesList.filter(
        (val) => val !== deletedPort[0].name
      );
    } else {
      newSecurityList = securityList.filter((val) => val !== item);
    }

    const emptyList = newAllNamesList.length === 0;
    const newPeriodStart = emptyList ? '' : periodStart;
    const newPeriodEnd = emptyList ? '' : periodEnd;

    dispatch(
      ChartTool.updateInputs({
        allNamesList: newAllNamesList,
        securityList: newSecurityList,
        portfolioList: newPortfolioList,
        portfolioNames: newPortfolioNames,
        periodStart: newPeriodStart,
        periodEnd: newPeriodEnd,
      })
    );
    this.setState({ warningMessage: '' });
  };

  handleFiveLimit = () => {
    this.setState({
      warningMessage:
        'Maximum number of securities reached. Please remove one before adding.',
    });
  };

  handleSearchResultSelect = (title) => {
    const { dispatch, inputs } = this.props;
    const { securityList, allNamesList } = inputs;
    const { warningMessage } = this.state;
    const hasReachedLimit = allNamesList.length >= 5;

    if (!hasReachedLimit) {
      dispatch(
        ChartTool.updateInputs({
          securityList: securityList.concat(title),
          allNamesList: allNamesList.concat(title),
        })
      );
    } else if (
      warningMessage !==
      'Maximum number of securities reached. Please remove one before adding.'
    ) {
      this.setState({ warningMessage: '' });
    }
  };

  handleDropdownSelection = (e, data) => {
    const { dispatch, inputs } = this.props;
    const { portfolioNames, allNamesList, portfolioList } = inputs;
    const hasReachedLimit = allNamesList.length >= 5;

    if (!hasReachedLimit) {
      const { value } = data;
      const index = data.options.find((o) => o.value === value);
      const portfolioName = { name: index.text, id: value };

      dispatch(
        ChartTool.updateInputs({
          portfolioList: portfolioList.concat(value),
          portfolioNames: portfolioNames.concat(portfolioName),
          allNamesList: allNamesList.concat(index.text),
        })
      );
    } else {
      this.handleFiveLimit();
    }
  };

  updateStartEndDate = (startDate, endDate) => {
    const { dispatch } = this.props;

    dispatch(
      ChartTool.updateInputs({ periodStart: startDate, periodEnd: endDate })
    );
  };

  drawChart = () => {
    const { dispatch, portfolios, user_region, inputs } = this.props;
    const { securityList, portfolioList } = inputs;

    if (securityList.length || portfolioList.length) {
      dispatch(ChartTool.fetchChartData(inputs, portfolios, user_region)).then(
        () => {
          if (this.props.errorEntity !== '') {
            const itemToDelete = this.props.errorEntity;
            this.handleDeletion(itemToDelete);
          }
        }
      );
    }
  };

  nameForUsVersion = (name) => {
    if (name === 'WS All-In') {
      return '100 Equities / 0 Bonds';
    } else if (name === 'WS Adventurous') {
      return '80 Equities / 20 Bonds';
    } else if (name === 'WS Classic') {
      return '60 Equities / 40 Bonds';
    } else if (name === 'WS Cautious') {
      return '40 Equities / 60 Bonds';
    } else if (name === 'WS Playing-It-Safe') {
      return '20 Equities / 80 Bonds';
    }

    return name;
  };

  render() {
    const { warningMessage } = this.state;
    const { security, user_region, inputs, portfolios } = this.props;
    const {
      isOnPortfolio,
      allNamesList,
      securityList,
      portfolioList,
      portfolioNames,
    } = inputs;
    const hasReachedLimit = allNamesList.length >= 5;
    const showChartingToolContent =
      !!securityList.length || !!portfolioList.length;

    return (
      <Segment
        padding="0rem"
        textAlign="center"
        style={{
          position: 'relative',
          minHeight: '700px',
        }}
      >
        <HeaderContainer
          showChartingToolContent={showChartingToolContent}
          scrollYPos={window.scrollY}
          headerFixed={this.state.headerFixed}
        >
          <Header size="large" textAlign="center">
            <Header.Content>Charting Tool</Header.Content>
            <Header.Subheader>
              <segment style={{ color: 'black' }}>
                Add up to <strong>five</strong> securities or portfolios;
                minimum 12 months of history.
              </segment>
            </Header.Subheader>
          </Header>

          <Note>
            {user_region === 'CA'
              ? 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'
              : 'Note: Currently we only have data for US-listed stocks and ETFs, and US mutual funds.'}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
            />
            Always check the fund code or ticker symbol to ensure you have the
            correct share class. Use US instead of U.S..
          </Note>

          <div>
            <WarningContainer>
              {warningMessage && (
                <WarningMessage>
                  <Icon name="warning sign" /> {warningMessage}
                </WarningMessage>
              )}
            </WarningContainer>
          </div>

          <PortfoliosToggle>
            {isOnPortfolio ? (
              <div>
                {portfolios.length === 0 && (
                  <div style={{ marginBottom: '5px', color: 'red' }}>
                    You currently have no saved portfolios.
                  </div>
                )}
                <div
                  className="wrapper"
                  style={{ width: '100%', marginBottom: '0.5rem' }}
                >
                  <Popup
                    content="Securities"
                    trigger={
                      <span
                        className={cn('toggle', { active: !isOnPortfolio })}
                        onClick={() =>
                          this.props.dispatch(
                            ChartTool.updateInputs({ isOnPortfolio: false })
                          )
                        }
                      >
                        Search Tickers
                        <Icon
                          fitted
                          className="icon-money"
                          style={{ marginLeft: '0.3rem' }}
                        />
                      </span>
                    }
                    position="top center"
                    style={{ padding: '0.4rem 0.5rem', pointerEvents: 'none' }}
                  />
                  <Popup
                    content="Portfolios"
                    trigger={
                      <span
                        className={cn('toggle', { active: !!isOnPortfolio })}
                        onClick={() =>
                          this.props.dispatch(
                            ChartTool.updateInputs({ isOnPortfolio: true })
                          )
                        }
                      >
                        <Icon
                          fitted
                          name="folder open"
                          style={{ marginRight: '0.3rem' }}
                        />
                        Saved Portfolios
                      </span>
                    }
                    position="top center"
                    style={{ padding: '0.4rem 0.5rem', pointerEvents: 'none' }}
                  />
                </div>
                <Dropdown
                  placeholder="Select a portfolio"
                  selection
                  options={this.getDropOptions()}
                  disabled={
                    portfolios.length === 0 ||
                    this.getDropOptions().length === 0
                  }
                  selectOnBlur={false}
                  value=""
                  onChange={this.handleDropdownSelection}
                  style={{ width: '100%', margin: '0.7rem', maxWidth: '20rem' }}
                />
              </div>
            ) : (
              <div>
                <div
                  className="wrapper"
                  style={{ width: '100%', marginBottom: '0.5rem' }}
                >
                  <Popup
                    content="Securities"
                    trigger={
                      <span
                        className={cn('toggle', { active: !isOnPortfolio })}
                        onClick={() =>
                          this.props.dispatch(
                            ChartTool.updateInputs({ isOnPortfolio: false })
                          )
                        }
                      >
                        Search Tickers
                        <Icon
                          fitted
                          className="icon-money"
                          style={{ marginLeft: '0.3rem' }}
                        />
                      </span>
                    }
                    position="top center"
                    style={{ padding: '0.4rem 0.5rem', pointerEvents: 'none' }}
                  />
                  <Popup
                    content="Portfolios"
                    trigger={
                      <span
                        className={cn('toggle', { active: !!isOnPortfolio })}
                        onClick={() =>
                          this.props.dispatch(
                            ChartTool.updateInputs({ isOnPortfolio: true })
                          )
                        }
                      >
                        <Icon
                          fitted
                          name="folder open"
                          style={{ marginRight: '0.3rem' }}
                        />
                        Saved Portfolios
                      </span>
                    }
                    position="top center"
                    style={{ padding: '0.4rem 0.5rem', pointerEvents: 'none' }}
                  />
                </div>
                <SearchTicker
                  loading={security.isFetching}
                  results={this.getResults()}
                  customResultSelect={this.handleSearchResultSelect}
                  customSearchChange={this.handleSearchChange}
                  handleDeletion={this.handleDeletion}
                  hasReachedLimit={hasReachedLimit}
                  handleFiveLimit={this.handleFiveLimit}
                  setMargin
                  style={{
                    display: 'inline-block',
                    margin: '0.7rem',
                    textAlign: 'left',
                  }}
                />
              </div>
            )}
          </PortfoliosToggle>

          <Labels>
            {allNamesList.map((item, index) => (
              <Label
                style={{
                  backgroundColor: Object.values(chartingToolColors)[index],
                  color: 'white',
                }}
                key={index}
              >
                {item}{' '}
                <Icon
                  name="delete"
                  link
                  onClick={() => this.handleDeletion(item)}
                />
              </Label>
            ))}
          </Labels>
        </HeaderContainer>
        {showChartingToolContent && (
          <Fade duration={3500}>
            <ContentContainer>
              <Graph
                allNamesList={allNamesList}
                portfolioNames={portfolioNames}
                updateStartEndDate={(startMonth, endMonth) =>
                  this.updateStartEndDate(startMonth, endMonth)
                }
              />
            </ContentContainer>
          </Fade>
        )}
      </Segment>
    );
  }
}

const PortfoliosToggle = styled.div`
  height: 3rem;
  position: relative;
  margin: 0 0 1rem 0;

  img {
    height: 17px;
    width: 17px;
    vertical-align: middle;
    margin: 0 0 2px 7px;
    transform: rotate(43deg);
  }

  .wrapper {
    width: 20rem;
    top: 0;
    left: 0;
  }

  .toggle {
    padding: 0.3rem 0.7rem;
    cursor: pointer;
    font-size: 1rem;
    background-color: white;
    border: 1px solid #dcdcdc;
    user-select: none;
    -moz-box-shadow: inset 0 0 4px ${colors.lightGrey};
    -webkit-box-shadow: inset 0 0 4px ${colors.lightGrey};
    box-shadow: inset 0 0 4px ${colors.lightGrey};

    &.active {
      background-color: ${colors.teal};
      border: 1px solid transparent;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      border: 1px solid ${colors.teal};
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`;

const Labels = styled.div`
  height: 3rem;
  padding-top: 2.5rem;
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 1rem auto 0rem auto;
  max-width: 40rem;
`;

ChartingTool.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  security: PropTypes.object.isRequired,
  errorEntity: PropTypes.string.isRequired,
  user_region: PropTypes.string,
  inputs: PropTypes.object,
};
ChartingTool.defaultProps = {
  portfolios: [],
  user_region: 'CA',
  inputs: {},
};

export default connect((state) => ({
  user: state.Auth.user,
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  security: state.Security,
  errorEntity:
    state.ChartTool.errorEntity !== undefined
      ? state.ChartTool.errorEntity
      : '',
  user_region: state.Storage.user_region || 'CA',
  inputs: state.ChartTool.inputs,
}))(ChartingTool);

const HeaderContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  height: 100px;
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: ${(props) =>
    !props.showChartingToolContent
      ? 'translate(-50%, 180%)'
      : 'translate(-50%, 0)'};
  transition: transform 1s;
  background-color: white;
  z-index: 2;
  width: 100%;
`;

const ContentContainer = styled(({ className, children, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
))`
  margin-top: 310px;
`;

const WarningMessage = styled.div`
  color: ${colors.red};
  font-weight: bold;
  margin: 0.7rem 0 0 0;
`;

const WarningContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
