import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Header, Popup, Icon } from 'semantic-ui-react';

import { YouTubePlayer, Message } from 'components';
import { arrangeSector } from 'utils/helpers';
import { ExposureChart, ExposureTable } from '../components';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class GlobalSector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: false,
    };
  }

  getTableData = (data) =>
    Object.keys(data).reduce((total, key) => {
      total.push({
        ticker: key,
        value: data[key],
      });

      return total;
    }, []);

  toggleVideo = (id) =>
    this.setState((prevState) => ({
      video: id === prevState.video ? false : id,
    }));

  render() {
    const { data, user_region, toggle_region, french } = this.props;
    const { video } = this.state;

    return (
      <Container>
        <Header size="medium" dividing>
          {french
            ? "Analyse des avoirs et de l'exposition au risque"
            : 'Holdings and Risk Exposure Analysis'}
          <Header.Subheader>
            {french ? 'Par secteur boursier mondial' : 'By Global Stock Sector'}
            &nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="bottom right"
              wide
            >
              {french
                ? 'Secteurs du marché boursier mondial tels que définis par le Global Industry Classification Standard (GICS).'
                : 'Global stock market sectors as defined by the Global Industry Classification Standard (GICS).'}
            </Popup>
          </Header.Subheader>
        </Header>

        <p>
          {french
            ? ''
            : 'Watch a short video that explains the difference between holdings breakdown and risk exposure or contribution by global stock sector from a total portfolio perspective.'}
          {!french && (
            <Icon
              name="youtube play"
              onClick={() => this.toggleVideo('KvAoXxw3SZs')}
              link
              style={{ color: 'rgb(255,2,2)' }}
            />
          )}
        </p>

        {!french && (
          <YouTubePlayer isOpen={video === 'KvAoXxw3SZs'} id="KvAoXxw3SZs" />
        )}

        {typeof data.sectBreakdown === 'object' && (
          <div>
            <Header
              size="medium"
              content={french ? 'Répartition des avoirs' : 'Holdings Breakdown'}
              subheader={
                french ? "Sous-portefeuille d'actions" : 'Stock Sub-Portfolio'
              }
            />
            <p>
              {french
                ? "Cette répartition est le compte pondéré en valeur des avoirs en actions dans chaque secteur boursier mondial au sein de votre sous-portefeuille d'actions (ou de votre portefeuille total si vous n'avez pas d'autre classe d'actifs)."
                : 'This breakdown is the value-weighted count of the stock holdings in each global stock sector within your stock sub-portfolio (or your total portfolio if you have no other asset class).'}
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.sectBreakdown))}
                    data={Object.values(arrangeSector(data.sectBreakdown))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={['Sector', 'Holdings']}
                    totals={['Total', <div>100%</div>]}
                    data={this.getTableData(data.sectBreakdown)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}

        {data.riskExp && typeof data.riskExp['S.EQ'] === 'object' ? (
          <div>
            <Header
              size="medium"
              content={french ? 'Exposition au risque' : 'Risk Exposure'}
              subheader={
                french
                  ? "Sous-portefeuille d'actions (excluant les fonds équilibrés/à allocation d'actifs)"
                  : 'Stock Sub-Portfolio (excluding Balanced/Asset Allocation Funds)'
              }
            />
            <p>
              {french
                ? "Ces expositions sont estimées à l'aide de l'analyse statistique. Elles reflètent la volatilité et les effets de diversification de votre sous-portefeuille d'actions. Elles fournissent une image différente de celle de la répartition des avoirs, car cette dernière ne tient pas compte du fait que certaines entreprises peuvent couvrir leur risque sectoriel ou avoir des opérations dans plus d'un secteur. Il est important de noter que certains risques sectoriels peuvent être réduits ou diversifiés si vous détenez plusieurs actifs dans votre portefeuille."
                : 'These exposures are estimated using statistical analysis. They reflect the volatility and the diversification effects of your stock sub-portfolio. It provides a different picture from holdings breakdown, as the latter does not take into account the fact that some companies may hedge their sector risk, or have operations in more than one sector. Importantly, certain sector risks may be reduced or diversified away if you hold multiple assets in your portfolio.'}
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.riskExp['S.EQ']))}
                    data={Object.values(arrangeSector(data.riskExp['S.EQ']))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      <div>
                        {french
                          ? 'Secteur boursier mondial'
                          : 'Global Stock Sector'}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          {user_region === 'US' || toggle_region === 'US'
                            ? french
                              ? 'Un secteur boursier mondial est composé de grandes entreprises de ce secteur du monde entier.'
                              : 'A global stock sector is made up of large firms in that sector from around the world.'
                            : french
                            ? "Un secteur boursier mondial est composé de grandes entreprises de ce secteur du monde entier. Notez que si vous avez des avoirs canadiens dans un secteur particulier, cela ne signifie pas nécessairement que vous avez une forte exposition au risque de ce secteur. Par exemple, les banques canadiennes avaient une faible corrélation avec le secteur financier mondial pendant la crise financière, car elles ont mieux résisté que les banques d'autres pays."
                            : 'A global stock sector is made up of large firms in that sector from around the world. Note that if you have Canadian holdings in a particular sector, it does not necessarily mean that you have high RISK EXPOSURE to that sector. For example, Canadian banks had a low correlation with the global financial sector during the financial crisis, because they weathered the storm much better than the banks in other countries.'}
                        </Popup>
                      </div>,
                      <div>
                        {french
                          ? 'Exposition au risque sectoriel'
                          : 'Sector Risk Exposure'}
                        &nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          {french
                            ? 'Un nombre négatif signifie que votre portefeuille est couvert contre ce secteur. Le risque de votre portefeuille est négativement lié aux mouvements de ce secteur boursier mondial. Les facteurs avec une exposition au risque de "-" sont statistiquement insignifiants.'
                            : 'A negative number means that your portfolio is hedged against that sector. The risk of your portfolio is negatively related to movements that global stock market sector. Factors with "-" risk exposure are statistically insignificant.'}
                        </Popup>
                      </div>,
                    ]}
                    totals={['Total', <div>100%</div>]}
                    data={this.getTableData(data.riskExp['S.EQ'])}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div style={{ margin: '2rem 0' }}>
            <Header
              size="medium"
              content={french ? 'Exposition au risque' : 'Risk Exposure'}
              subheader={
                french
                  ? "Sous-portefeuille d'actions (excluant les fonds équilibrés/à allocation d'actifs)"
                  : 'Stock Sub-Portfolio (excluding Balanced/Asset Allocation Funds)'
              }
            />
            <p>
              {french
                ? "Ces expositions sont estimées à l'aide de l'analyse statistique. Elles reflètent la volatilité et les effets de diversification de votre sous-portefeuille d'actions. Elles fournissent une image différente de celle de la répartition des avoirs, car cette dernière ne tient pas compte du fait que certaines entreprises peuvent couvrir leur risque sectoriel ou avoir des opérations dans plus d'un secteur. Il est important de noter que certains risques sectoriels peuvent être réduits ou diversifiés si vous détenez plusieurs actifs dans votre portefeuille."
                : 'These exposures are estimated using statistical analysis. They reflect the volatility and the diversification effects of your stock sub-portfolio. It provides a different picture from holdings breakdown, as the latter does not take into account the fact that some companies may hedge their sector risk, or have operations in more than one sector. Importantly, certain sector risks may be reduced or diversified away if you hold multiple assets in your portfolio.'}
            </p>
            <Message visible warning>
              {data.riskExp['S.EQ']}
            </Message>
          </div>
        )}

        {data.riskExp && typeof data.riskExp.S === 'object' ? (
          <div>
            <Header
              size="medium"
              content={french ? 'Exposition au risque' : 'Risk Exposure'}
              subheader={french ? 'Portefeuille total' : 'Total Portfolio'}
            />
            <p>
              {french
                ? "Ces expositions sont estimées à l'aide de l'analyse statistique. Elles représentent la volatilité et l'effet de diversification de votre portefeuille total. Si vous avez des obligations ou des actifs alternatifs dans votre portefeuille, ces chiffres seront différents de ceux du tableau précédent, car des risques sectoriels boursiers mondiaux supplémentaires peuvent être ajoutés ou diversifiés."
                : 'These exposures are estimated using statistical analysis. They represent the volatility and diversification effect of your total portfolio. If you have bonds or alternative assets in your portfolio, these figures will be different from those in the previous table, as additional global stock sector risk may be added or diversified away.'}
            </p>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <ExposureChart
                    title=""
                    labels={Object.keys(arrangeSector(data.riskExp.S))}
                    data={Object.values(arrangeSector(data.riskExp.S))}
                  />
                </Grid.Column>
                <Grid.Column>
                  <ExposureTable
                    headers={[
                      <div>
                        {french
                          ? 'Secteur boursier mondial'
                          : 'Global Stock Sector'}
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          {user_region === 'US' || toggle_region === 'US'
                            ? french
                              ? 'Un secteur boursier mondial est composé de grandes entreprises de ce secteur du monde entier.'
                              : 'A global stock sector is made up of large firms in that sector from around the world.'
                            : french
                            ? "Un secteur boursier mondial est composé de grandes entreprises de ce secteur du monde entier. Notez que si vous avez des avoirs canadiens dans un secteur particulier, cela ne signifie pas nécessairement que vous avez une forte exposition au risque de ce secteur. Par exemple, les banques canadiennes avaient une faible corrélation avec le secteur financier mondial pendant la crise financière, car elles ont mieux résisté que les banques d'autres pays."
                            : 'A global stock sector is made up of large firms in that sector from around the world. Note that if you have Canadian holdings in a particular sector, it does not necessarily mean that you have high RISK EXPOSURE to that sector. For example, Canadian banks had a low correlation with the global financial sector during the financial crisis, because they weathered the storm much better than the banks in other countries.'}
                        </Popup>
                      </div>,
                      <div>
                        {french
                          ? 'Exposition au risque sectoriel'
                          : 'Sector Risk Exposure'}
                        &nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="bottom right"
                          wide
                        >
                          {french
                            ? 'Un nombre négatif signifie que votre portefeuille est couvert contre ce secteur. Le risque de votre portefeuille est négativement lié aux mouvements de ce secteur boursier mondial. Les facteurs avec une exposition au risque de "-" sont statistiquement insignifiants.'
                            : 'A negative number means that your portfolio is hedged against that sector. The risk of your portfolio is negatively related to movements that global stock market sector. Factors with "-" risk exposure are statistically insignificant.'}
                        </Popup>
                      </div>,
                    ]}
                    totals={['Total', <div>100%</div>]}
                    data={this.getTableData(data.riskExp.S)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div>
            <Header
              size="medium"
              content={french ? 'Exposition au risque' : 'Risk Exposure'}
              subheader={french ? 'Portefeuille total' : 'Total Portfolio'}
            />
            <p>
              {french
                ? "Ces expositions sont estimées à l'aide de l'analyse statistique. Elles représentent la volatilité et l'effet de diversification de votre portefeuille total. Si vous avez des obligations ou des actifs alternatifs dans votre portefeuille, ces chiffres seront différents de ceux du tableau précédent, car des risques sectoriels boursiers mondiaux supplémentaires peuvent être ajoutés ou diversifiés."
                : 'These exposures are estimated using statistical analysis. They represent the volatility and diversification effect of your total portfolio. If you have bonds or alternative assets in your portfolio, these figures will be different from those in the previous table, as additional global stock sector risk may be added or diversified away.'}
            </p>
            <Message visible warning>
              {data.riskExp.S}
            </Message>
          </div>
        )}
      </Container>
    );
  }
}

GlobalSector.propTypes = {
  data: PropTypes.object.isRequired,
  user_region: PropTypes.string.isRequired,
  toggle_region: PropTypes.string.isRequired,
  french: PropTypes.bool,
};

GlobalSector.defaultProps = { french: false };

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
