import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Popup,
  Icon,
  Dropdown,
  Header,
  Divider,
  Form,
  Checkbox,
} from 'semantic-ui-react';
import cn from 'classnames';

import { Analytics, CompareAccount } from 'actions';
import { request } from 'actions/api';
import { colors } from 'utils/colors';
import { nestData } from 'utils/helpers';
import AllocateWeights from './AllocateWeights';
import Modal from './Modal';
import Segment from './Segment';
import Button from './Button';

@connect((state) => ({
  analytics: state.Analytics,
  partnerName: state.Storage['partner-name'],
  user_region: state.Storage.user_region || 'CA',
  toggle_region: state.Storage.toggle_region || 'CA',
  french: state.Storage.language === 'fr',
}))
export default class CustomBenchmarkDashboard extends Component {
  state = {
    isOpen: false,
    data: null,
    selectAssets: true,
  };

  componentWillMount = () => {
    const { dispatch } = this.props;
    const decideRegion = () => {
      if (this.props.toggle_region === 'US') {
        return 'US';
      }
      return 'CA';
    };

    return dispatch(
      request(
        'get',
        `/portfolio/byoetf/?region=${decideRegion()}&benchmark=true`
      )
    ).then((data) => {
      this.setState({ data: nestData(data) });
    });
  };
  getDropOptions = () => {
    const { replaceDomestic, partnerName, french } = this.props;

    const domesticText = french ? 'Domestique' : 'Domestic';
    const globalText = french ? 'Global' : 'Global';
    const customText = french ? 'Personnalisé' : 'Custom';
    const usText = french ? 'États-Unis' : 'US';

    if (replaceDomestic === 'US' && partnerName !== 'quotemedia_pro') {
      return [
        {
          key: 'dom',
          value: 'domestic',
          text: replaceDomestic || domesticText,
        },
        { key: 'glb', value: 'global', text: globalText },
      ];
    }
    return [
      {
        key: 'dom',
        value: 'domestic',
        text: replaceDomestic || domesticText,
      },
      { key: 'glb', value: 'global', text: globalText },
      {
        key: 'cus',
        value: 'custom',
        text: partnerName === 'quotemedia_pro' ? customText : usText,
      },
    ];
  };

  getPortfolioRatio = () => {
    const { analytics, french } = this.props;

    if (!analytics || !analytics.data || !analytics.data.fratios) {
      return '';
    }

    const ratioStr = analytics.data.fratios.domestic.name.split(' ');
    /* All possibilities:
      Stock Market
      Bond Market
      90/10 Portfolio
      80/20 Portfolio ...
      10/90 Portfolio
    */

    if (ratioStr[1] === 'Market') {
      if (ratioStr[0] === 'Bond') {
        return french
          ? "L'allocation d'actifs de votre portefeuille est la plus proche de 100 % d'obligations."
          : "Your portfolio's asset allocation is closest to 100% Bonds.";
      }

      return french
        ? "L'allocation d'actifs de votre portefeuille est la plus proche de 100 % d'actions."
        : "Your portfolio's asset allocation is closest to 100% Equity.";
    } else if (ratioStr[1] === 'Portfolio') {
      const ratio = ratioStr[0].split('/');
      return french
        ? `L'allocation d'actifs de votre portefeuille est la plus proche de ${ratio[0]} % d'actions et ${ratio[1]} % d'obligations.`
        : `Your portfolio's asset allocation is closest to ${ratio[0]}% Equity and ${ratio[1]}% Bonds.`;
    }

    return '';
  };

  decideRegion = () => {
    if (this.props.user_region === 'US') {
      return 'US';
    }
    return this.props.toggle_region || 'CA';
  };

  close = () => this.setState({ isOpen: false });

  changeBenchmark = (e, { value }) => {
    const { dispatch, feature } = this.props;

    if (value === 'custom' && this.isAbleToUseCustomBenchmark()) {
      return this.setState({ isOpen: true });
    }

    return feature === 'compareAccounts'
      ? dispatch(CompareAccount.updateBenchmark(value))
      : dispatch(Analytics.updateParameter('benchmark', value));
  };

  decideCountry = (data, userRegion) => {
    const { french } = this.props;
    if (french) {
      return 'La partie capitaux propres par défaut de l’indice de référence de votre portefeuille est un FNB composé plafonné au TSX. Les pondérations (capitaux propres ou revenu fixe) sont automatiquement attribuées. L’indice de référence est utilisé pour évaluer la performance et la protection contre le risque de pertes de votre portefeuille.';
    }
    if (userRegion === 'US')
      return "The default equity portion of your portfolio's benchmark is an S&P 500 ETF. The weights (equity vs fixed income) are automatically assigned. The benchmark is used to gauge the performance and downside protection of your portfolio.";

    const { paramsPassed } = data;

    if (paramsPassed.region === 'US')
      return "The default equity portion of your portfolio's benchmark is an S&P 500 ETF. The weights (equity vs fixed income) are automatically assigned. The benchmark is used to gauge the performance and downside protection of your portfolio.";

    return "The default equity portion of your portfolio's benchmark is a TSX Capped Composite ETF. The weights (equity vs fixed income) are automatically assigned. The benchmark is used to gauge the performance and downside protection of your portfolio.";
  };

  toggleClass = (e, { name }) => {
    const { dispatch, analytics } = this.props;
    const { selectedAssets, selectedClass } = analytics;

    dispatch(
      Analytics.updateParameter('selectedClass', {
        ...selectedClass,
        [name]: !selectedClass[name],
      })
    );

    dispatch(
      Analytics.updateParameter(
        'selectedAssets',
        Object.values(selectedAssets).reduce((newAssets, asset) => {
          if (asset.assetclass !== name) {
            newAssets[asset.symbol] = asset;
          }

          return newAssets;
        }, {})
      )
    );
  };

  toggleAsset = (e, { value }) => {
    const { dispatch, analytics } = this.props;
    const { selectedAssets, selectedClass } = analytics;

    if (!selectedClass[value.assetclass]) {
      return false;
    }

    return dispatch(
      Analytics.updateParameter(
        'selectedAssets',
        !selectedAssets[value.symbol]
          ? { ...selectedAssets, [value.symbol]: { ...value, weight: 0 } }
          : {
              ...Object.values(selectedAssets).reduce((result, item) => {
                if (item.symbol !== value.symbol) {
                  result[item.symbol] = item;
                }

                return result;
              }, {}),
            }
      )
    );
  };

  updateWeight = (name, value) => {
    const { dispatch, analytics } = this.props;
    const { selectedAssets } = analytics;

    dispatch(
      Analytics.updateParameter('selectedAssets', {
        ...selectedAssets,
        [name]: {
          ...selectedAssets[name],
          weight: Number(value) > 100 ? 100 : Math.round(value * 100) / 100,
        },
      })
    );
  };

  isAbleToUseCustomBenchmark = () => {
    const { partnerName } = this.props;

    if (partnerName === 'quotemedia_pro') {
      return true;
    }

    return false;
  };

  renderCheckboxes = (arr) => {
    const { analytics } = this.props;
    const { selectedAssets } = analytics;

    return arr
      .map((elem) =>
        elem.is_benchmark ? (
          <Checkbox
            key={elem.symbol}
            name={elem.symbol}
            label={elem.name}
            value={elem}
            checked={!!selectedAssets[elem.symbol]}
            onChange={this.toggleAsset}
            style={{ margin: '2px 0', display: 'block' }}
          />
        ) : null
      )
      .filter((elm) => elm !== null);
  };

  renderSubClass = (data, selectedClass, assetClass) => {
    const renderSubSubClass = (subClass, subSubClass) =>
      Object.keys(subSubClass)
        .map((subSubClassName) => {
          const checkboxes = this.renderCheckboxes(
            data[subClass][subSubClassName]
          );

          return checkboxes.length ? <div>{checkboxes}</div> : null;
        })
        .filter((elm) => elm !== null);

    return Object.keys(data).map((subClass) => {
      const subSubClass = renderSubSubClass(subClass, data[subClass]);

      return subSubClass.length ? (
        <Segment basic compact disabled={!selectedClass[assetClass]}>
          <Header size="small">
            {subClass.toUpperCase() === 'US' ? 'U.S.' : subClass.toUpperCase()}
          </Header>
          <Divider fitted style={{ marginBottom: '0.75rem' }} />
          {subSubClass}
        </Segment>
      ) : null;
    });
  };

  render() {
    const { benchmark, feature, analytics, replaceDomestic, french } =
      this.props;
    const { selectedClass, selectedAssets } = analytics;
    const ableToUseCustomBenchmark = this.isAbleToUseCustomBenchmark();
    const { isOpen, data, selectAssets } = this.state;

    return !data ? null : (
      <div>
        <Benchmark feature={feature}>
          <p>
            {french
              ? 'Modification de l’indice de référence des capitaux propres'
              : 'Change Equity Benchmark'}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top right"
              wide
              content={this.decideCountry(analytics.data, 'CA')}
            />
          </p>
          <OptionsWrapper>
            <StyledDropdown
              selection
              placeholder="Benchmark"
              options={this.getDropOptions()}
              text={
                isOpen
                  ? 'Custom'
                  : benchmark === 'domestic'
                  ? replaceDomestic || 'Domestic'
                  : benchmark === 'global'
                  ? 'Global'
                  : ableToUseCustomBenchmark
                  ? 'Custom'
                  : 'US'
              }
              onChange={this.changeBenchmark}
            />
          </OptionsWrapper>
        </Benchmark>

        <Modal
          open={isOpen}
          dimmerClick={() => this.close()}
          style={{ padding: '2rem' }}
        >
          <CloseIcon onClick={() => this.close()}>+</CloseIcon>
          <Header
            size="large"
            textAlign="center"
            style={{ marginBottom: '2rem' }}
          >
            {french
              ? 'Définir un indice de référence personnalisé'
              : 'Define a Custom Benchmark'}
            <Header.Subheader>{this.getPortfolioRatio()}</Header.Subheader>
          </Header>
          <Form>
            {selectAssets ? (
              <div>
                <Header
                  textAlign="center"
                  size="medium"
                  content={french ? 'Sélectionner les actifs' : 'Select Assets'}
                  style={{ padding: '0 0 2rem 0' }}
                />
                <StyledDiv>
                  <Header size="medium" textAlign="center">
                    {french ? 'Obligations' : 'Bonds'}
                    <Form.Checkbox
                      inline
                      name="Fixed Income"
                      checked={selectedClass['Fixed Income']}
                      onChange={this.toggleClass}
                      toggle
                    />
                  </Header>
                  {this.renderSubClass(
                    data['Fixed Income'],
                    selectedClass,
                    'Fixed Income'
                  )}
                </StyledDiv>

                <StyledDiv>
                  <Header size="medium" textAlign="center">
                    {french ? 'Actions' : 'Stocks'}
                    <Form.Checkbox
                      inline
                      name="Equity"
                      checked={selectedClass.Equity}
                      onChange={this.toggleClass}
                      toggle
                    />
                  </Header>
                  {this.renderSubClass(data.Equity, selectedClass, 'Equity')}
                </StyledDiv>

                <StyledDiv>
                  <Header size="medium" textAlign="center">
                    {french ? 'Alternatif' : 'Alternative'}
                    <Form.Checkbox
                      inline
                      name="Alternative"
                      checked={selectedClass.Alternative}
                      onChange={this.toggleClass}
                      toggle
                    />
                  </Header>
                  {this.renderSubClass(
                    data.Alternative,
                    selectedClass,
                    'Alternative'
                  )}
                </StyledDiv>

                <ButtonWrapper>
                  <Button
                    className={cn('right', {
                      disabled: !Object.keys(selectedAssets).length,
                    })}
                    color={colors.orange}
                    icon="chevron right"
                    floated="right"
                    onClick={() => this.setState({ selectAssets: false })}
                  >
                    {french ? 'Suivant' : 'Next'}
                  </Button>
                </ButtonWrapper>

                <div style={{ clear: 'both' }} />
              </div>
            ) : (
              <AllocateWeights
                selectedAssets={selectedAssets}
                previousTab={() => this.setState({ selectAssets: true })}
                updateWeight={this.updateWeight}
                close={this.close}
                feature={feature}
              />
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}

CustomBenchmarkDashboard.propTypes = {
  benchmark: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  analytics: PropTypes.object,
  feature: PropTypes.string,
  replaceDomestic: PropTypes.string,
  toggle_region: PropTypes.string,
  partnerName: PropTypes.string,
  user_region: PropTypes.string,
  french: PropTypes.bool,
};

CustomBenchmarkDashboard.defaultProps = {
  dispatch: () => false,
  analytics: {},
  feature: '',
  replaceDomestic: '',
  toggle_region: 'CA',
  partnerName: '',
  user_region: 'CA',
  french: false,
};

const Benchmark = styled.div`
  font-size: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 1rem;
    text-align: center;
  }
`;

const StyledDropdown = styled(({ className, children, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 22px !important;
  min-width: 100px !important;
  min-height: 22px !important;
  padding: 1px 0 0 10px !important;
  .icon {
    padding: 2px 0 0 0 !important;
  }
`;

const OptionsWrapper = styled.div`
  padding: 3px 0;
`;

const StyledDiv = styled.div`
  float: left;
  width: 33.3%;
  @media only screen and (max-width: 1150px) {
    width: 70%;
    margin: 0 auto;
    float: none;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  float: right;
  clear: both;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;
