import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { Button, Modal } from 'components';

@withRouter
@connect()
export default class ConfirmModal extends Component {
  state = {};

  closeModal = () => {
    const { close } = this.props;

    return close();
  };

  render() {
    const { open } = this.props;

    return (
      <Modal dimmerClick={this.closeModal} basic open={open}>
        <Modal.Header>
          <Header
            icon="warning sign"
            content="Please note"
            style={{ color: colors.red }}
          />
        </Modal.Header>
        <Modal.Content style={{ paddingBottom: 0 }}>
          <p>This functionality has been retired for demo accounts.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={cn('left inverted', {})}
            icon="checkmark"
            color={colors.green}
            onClick={this.closeModal}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {};
