import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import { Feature, HeadwayWidget } from 'components';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { colors } from 'utils/colors';
import { PORTFOLIOS, PLANNING, TOOLS } from 'utils/features';

class QtNavBar extends Component {
  getUrl = (module) => {
    const { location, cacheUrl, user_region } = this.props;
    let landingUrl = module === 'accounts' ? '/accounts' : `/${module}/landing`;

    // For US version, skip planning landing page
    landingUrl =
      module !== 'planning'
        ? landingUrl
        : user_region === 'CA'
        ? landingUrl
        : '/planning/accumulation';

    return location.pathname.includes(module)
      ? landingUrl
      : cacheUrl[module] || landingUrl;
  };

  getGsgUrl = () => {
    const { partnerName, user_region } = this.props;

    if (partnerName === 'quotemedia_pro')
      return 'https://www.wealthscope.ca/getting-started-qm-can';

    if (user_region === 'US')
      return 'https://www.wealthscope.ca/getting-started-qm-us';

    return 'https://www.wealthscope.ca/getting-started-qm-can';
  };

  render() {
    const { match, location } = this.props;

    return (
      <Menu hidden={match.isExact || location.pathname.includes('pdf')}>
        <SubMenu single>
          <Tab top active={window.location.pathname.indexOf('accounts') !== -1}>
            <NavLink to={this.getUrl('accounts')}>Portfolios</NavLink>
          </Tab>
        </SubMenu>

        <Feature name={PORTFOLIOS}>
          <SubMenu single>
            <Tab
              top
              active={window.location.pathname.split('/')[1] === 'portfolio'}
            >
              <NavLink to={this.getUrl('portfolio')}>Simulations</NavLink>
            </Tab>
          </SubMenu>
        </Feature>

        <Feature name={PLANNING}>
          <SubMenu single>
            <Tab
              top
              active={window.location.pathname.split('/')[1] === 'planning'}
            >
              <NavLink to={this.getUrl('planning')}>Planning</NavLink>
            </Tab>
          </SubMenu>
        </Feature>

        <Feature name={TOOLS}>
          <SubMenu single>
            <Tab
              top
              active={window.location.pathname.split('/')[1] === 'tools'}
            >
              <NavLink to={this.getUrl('tools')}>Tools</NavLink>
            </Tab>
          </SubMenu>
        </Feature>
        <SubMenu single style={{ float: 'right' }}>
          <Popup
            trigger={
              <Tab top title="Step-by-step Guide">
                <a
                  href={this.getGsgUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    name="book"
                    style={{ fontSize: '30px', color: colors.blue }}
                  />
                </a>
              </Tab>
            }
            position="bottom center"
            wide
          >
            Getting Started Guide
          </Popup>
        </SubMenu>
        <Updates id="updates" style={{ cursor: 'pointer' }}>
          Updates
          <HeadwayWidget />
        </Updates>
      </Menu>
    );
  }
}

QtNavBar.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  cacheUrl: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
};

QtNavBar.defaultProps = {
  match: {},
  location: {},
  user_region: 'CA',
  partnerName: '',
};

export default withRouter(
  connect((state) => ({
    cacheUrl: state.CacheInputs.cacheUrl,
    user_region: state.Storage.user_region || 'CA',
    partnerName: state.Storage['partner-name'],
  }))(QtNavBar)
);

const Menu = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  height: 60px;
  width: 100%;
  font-size: 0.9rem;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 101;
  border-bottom: 1px solid #cbcdd0;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
`;

const SubMenu = styled.div`
  float: left;
  background: white;
  width: 15%;
  max-width: 15rem;
  min-width: 13rem;
  height: 60px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  z-index: 2;
  border-bottom: 1px solid #cbcdd0;

  &:hover {
    height: ${(props) => (props.single ? '60px' : 'auto')};
  }
`;

const Tab = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
  text-align: center;
  border-bottom: 1px solid rgb(236, 236, 240);
  border-left: 1px solid #cbcdd0;
  border-right: 1px solid #cbcdd0;
  position: relative;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${colors.grey};
  line-height: 60px;

  &:first-child {
    font-size: 0.95rem;
    border: 0;
    border-bottom: 1px solid rgb(236, 236, 240);
  }

  &:last-child {
    border-bottom: none;
  }

  ${(props) =>
    props.left &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 60px;
        background: transparent;
      }
    `}

  ${(props) =>
    props.top &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: transparent;
      }
    `}

  ${(props) =>
    props.active &&
    !props.submenu &&
    css`
      color: ${colors.black};
      font-weight: 700;
      border-left: none;

      &:after {
        background: ${colors.red};
      }
    `}

  ${(props) =>
    props.active &&
    props.submenu &&
    css`
      color: ${colors.black};
      font-weight: 700;
      border-left: none;
    `}
  

  &:hover {
    color: ${colors.black};
  }

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: inherit;
    margin: 0;
    padding: 0;
  }
`;

const Updates = styled.div`
  display: block;
  color: ${colors.grey};
  line-height: 4rem;
  text-transform: uppercase;
  background: white;
  float: left;
  width: 13rem;
  height: 100%;
  text-align: center;
  position: relative;
`;
