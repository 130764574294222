import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal } from 'components';
import CppEstimator from './CppEstimator';

export default class CppModal extends Component {
  state = {
    tableOpen: false,
  };

  setTableOpen = (tableOpen) => this.setState({ tableOpen });

  render() {
    const { isOpen, toggle, updateCppInfo, cppDataset } = this.props;
    const { tableOpen } = this.state;

    return (
      <Modal open={isOpen} dimmerClick={() => !tableOpen && toggle(false)}>
        <Modal.Content style={{ position: 'relative', padding: '2rem' }}>
          <CloseIcon onClick={() => toggle(false)}>+</CloseIcon>

          <CppEstimator
            toggle={toggle}
            updateCppInfo={updateCppInfo}
            cppDataset={cppDataset}
            setTableOpen={this.setTableOpen}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

CppModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  updateCppInfo: PropTypes.func.isRequired,
  cppDataset: PropTypes.array,
};

CppModal.defaultProps = {
  cppDataset: [],
};

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;
