import { CompareAccount } from 'actions';
import { convertToTickersAndWeights } from 'utils/helpers';

const initialState = {
  isFetching: false,
  isFetchingSearchResults: false,
  search: [],
  holdings: {},
  original: { gic_info: {} },
  compareResult: null,
  compareEsgResult: null,
  benchmark: 'domestic',
  gic_new_generic_ticker: null, // the GIC#:MKT that the user will be editing in what-if
  port1: {},
  port2: {},
  addBtcToggle: false,
  addGicToggle: false,
  esgToggle: false,
  digitalCurrency: {},
  gic_info: {},
  name: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CompareAccount.COMPAREACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CompareAccount.COMPAREACCOUNT_SEARCH_REQUEST:
      return {
        ...state,
        isFetchingSearchResults: true,
      };
    case CompareAccount.COMPAREACCOUNT_FETCH_ID: {
      const { holdings } = action.account;
      const { tickers, weights } = convertToTickersAndWeights(holdings);
      const tickersArray = tickers.split(',');
      const weightsArray = weights.split(',');
      const gicFormat = /^GIC\d*:MKT$/;

      const gic_info = holdings
        .filter((holding) => gicFormat.test(holding.security))
        .reduce((prev, curr) => {
          prev[curr.security] = {
            gic_rate: curr.gic_rate,
            gic_ticker: curr.gic_ticker,
            book_value: curr.book_value,
          };
          return prev;
        }, {});

      // figuring out what generic ticker  (ie. GIC#:MKT) to use when a user
      // wants to add a new GIC/HISA to their what-if portfolio
      let gic_new_generic_ticker = 'GIC1:MKT';
      if (Object.keys(gic_info).length > 0) {
        const gicSample = [
          'GIC1:MKT',
          'GIC2:MKT',
          'GIC3:MKT',
          'GIC4:MKT',
          'GIC5:MKT',
          'GIC6:MKT',
          'GIC7:MKT',
          'GIC8:MKT',
          'GIC9:MKT',
          'GIC10:MKT',
        ];
        const possible_generic_tickers = gicSample.filter(
          (key) => !Object.keys(gic_info).includes(key)
        );

        gic_new_generic_ticker =
          possible_generic_tickers.length > 0
            ? possible_generic_tickers[0]
            : null;
      }
      const keyedHoldings = tickersArray.reduce((acc, val, idx) => {
        const holding = holdings.find(
          (h) => h.security_detail && h.security_detail.ticker === val
        );

        acc[val] = {
          ...holding,
          weight: Number.parseFloat(
            ((weightsArray[idx] * 10000) / 100).toFixed(2)
          ),
          ...holding.security_detail,
        };
        return acc;
      }, {});

      return {
        ...state,
        isFetching: false,
        holdings: keyedHoldings,
        gic_info,

        gic_new_generic_ticker,
        name: 'What-if',
        original: {
          gic_info,
          tickers,
          weights,
          holdings: keyedHoldings,
          name:
            action.account.user_institution && action.account.label
              ? `${action.account.user_institution}: ${action.account.label}`
              : action.account.user_institution
              ? `${action.account.user_institution}`
              : 'Accounts Overview',
        },
      };
    }
    case CompareAccount.COMPAREACCOUNT_ADD_SUCCESS:
      return !action.security
        ? { ...state, isFetching: false }
        : {
            ...state,
            isFetching: false,
            holdings: {
              ...state.holdings,
              [action.security.ticker]: {
                ...action.security,
                weight: 0,
                book_value: 0,
              },
            },
          };
    case CompareAccount.COMPAREACCOUNT_COMPARE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        compareResult: {
          ...(action.analysis1 && {
            analysis1: action.analysis1,
          }),
          ...(action.analysis2 && {
            analysis2: action.analysis2,
          }),
        },
      };
    case CompareAccount.COMPAREACCOUNT_ESG_SUCCESS:
      return {
        ...state,
        compareEsgResult: {
          portfolio1esg: action.portfolio1esg,
          portfolio2esg: action.portfolio2esg,
        },
      };
    case CompareAccount.COMPAREACCOUNT_UPDATE:
      return {
        ...state,
        holdings: {
          ...state.holdings,
          [action.ticker]: {
            ...state.holdings[action.ticker],
            [action.field]: action.value,
          },
        },
      };
    case CompareAccount.COMPAREACCOUNT_SEARCH_SUCCESS:
      return {
        ...state,
        isFetchingSearchResults: false,
        search: action.data,
      };

    case CompareAccount.COMPAREACCOUNT_REMOVE_SECURITY:
      return {
        ...state,
        isFetching: false,
        holdings: Object.keys(state.holdings).reduce((newHoldings, key) => {
          if (key !== action.ticker) {
            newHoldings[key] = state.holdings[key];
          }

          return newHoldings;
        }, {}),
        gic_info: Object.keys(state.gic_info)
          .filter((key) => key !== action.ticker)
          .reduce((prev, curr) => {
            prev[curr] = state.gic_info[curr];
            return prev;
          }, {}),
      };
    case CompareAccount.COMPAREACCOUNT_REMOVE_DIGITALCURRENCY:
      return {
        ...state,
        isFetching: false,
        digitalCurrency: Object.keys(state.digitalCurrency).reduce(
          (newHoldings, key) => {
            if (key !== action.ticker) {
              newHoldings[key] = state.digitalCurrency[key];
            }

            return newHoldings;
          },
          {}
        ),
      };
    case CompareAccount.COMPAREACCOUNT_SAVE_COMPAREPORTS:
      return {
        ...state,
        port1: action.port1,
        port2: action.port2,
      };
    case CompareAccount.COMPAREACCOUNT_REMOVE_ALL:
      return {
        ...state,
        isFetching: false,
        holdings: {},
      };
    case CompareAccount.COMPAREACCOUNT_REMOVE_ALL_HOLDINGS:
      return {
        ...state,
        isFetching: false,
        holdings: Object.keys(state.holdings)
          .filter(
            (key) =>
              /^GIC\d*:MKT$/.test(key) || ['~BTCUSDT', '~ETHUSDT'].includes(key)
          )
          .reduce((obj, key) => {
            obj[key] = state.holdings[key];
            return obj;
          }, {}),
      };
    case CompareAccount.COMPAREACCOUNT_REMOVE_ALL_GICS:
      return {
        ...state,
        isFetching: false,
        holdings: Object.keys(state.holdings)
          .filter(
            (key) =>
              !/^GIC\d*:MKT$/.test(key) ||
              ['~BTCUSDT', '~ETHUSDT'].includes(key)
          )
          .reduce((obj, key) => {
            obj[key] = state.holdings[key];
            return obj;
          }, {}),
        gic_new_generic_ticker: 'GIC1:MKT',
        gic_info: {},
      };
    case CompareAccount.COMPAREACCOUNT_CLEAR_RESULT:
      return {
        ...state,
        compareResult: null,
        compareEsgResult: null,
        esgToggle: false,
      };

    case CompareAccount.COMPAREACCOUNT_UPDATE_GIC:
      return {
        ...state,
        gic_info: state.gic_new_generic_ticker
          ? {
              ...state.gic_info,
              [state.gic_new_generic_ticker]: {
                ...(state.gic_info[state.gic_new_generic_ticker] || {}),
                [action.field]: action.value,
              },
            }
          : { ...state.gic_info },
      };

    case CompareAccount.COMPAREACCOUNT_UPDATE_INTEREST:
      return {
        ...state,
        gic_info: {
          ...state.gic_info,
          [action.ticker]: {
            ...state.gic_info[action.ticker],
            gic_rate: action.interest_rate,
          },
        },
      };
    case CompareAccount.COMPAREACCOUNT_UPDATE_WEIGHT:
      return {
        ...state,
        holdings: {
          ...state.holdings,
          [action.ticker]: {
            ...state.holdings[action.ticker],
            weight: action.value,
          },
        },
      };
    case CompareAccount.COMPAREACCOUNT_UPDATE_TOGGLE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case CompareAccount.COMPAREACCOUNT_UPDATE_DIGITALCURRENCY:
      return {
        ...state,
        digitalCurrency: {
          ...state.digitalCurrency,
          [action.ticker]: action.value,
        },
      };
    case CompareAccount.COMPAREACCOUNT_UPDATE_BENCHMARK:
      return {
        ...state,
        benchmark: action.benchmark,
      };

    case CompareAccount.COMPAREACCOUNT_CLEAR:
      return initialState;

    case CompareAccount.COMPAREACCOUNT_ROUND_WEIGHTS_TO_WHOLE_NUMBERS:
      return {
        ...state,
        holdings: Object.keys(state.holdings).reduce((res, key) => {
          res[key] = {
            ...state.holdings[key],
            weight: Math.round(state.holdings[key].weight || 0),
          };
          return res;
        }, {}),
      };
    default:
      return state;
  }
}
