import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import { Radio, Label, Popup, Icon } from 'semantic-ui-react';
import {
  Accounts,
  CompareAccount,
  CustomAccount,
  Portfolio,
  RebalanceAccount,
  Storage,
} from 'actions';
import {
  Button,
  Card,
  Segment,
  Loading,
  Feature,
  Modal,
} from '../../../components';
import { colors, nbColors } from '../../../utils/colors';
import { TOOLS_STOCK_EXPOSURE } from '../../../utils/features';
import { isNB, isWealthica, translateText } from '../../../utils/helpers';

@connect((state) => ({
  accounts: state.Accounts.accountList,
  isFetching: state.Accounts.isFetching,
  selectedAccountList: state.Accounts.selectedAccountList,
  partnerName: state.Storage['partner-name'],
  overview: state.Accounts.overview,
  exchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region,
  ui_exposure_checked: state.Storage.ui_exposure_checked,
  french: state.Storage.language === 'fr',
}))
export default class AccountsList extends Component {
  state = {
    multipleSelect: !!this.props.selectedAccountList.length,
    invalidSubmission: false,
    reminderIsOpen: false,
    disclaimerIsOpen: false,
  };

  componentWillMount = () => {
    const { accounts, dispatch, ui_exposure_checked } = this.props;

    dispatch(Portfolio.setEditing('', '', ''));
    dispatch(CompareAccount.clearResult());
    dispatch(CompareAccount.removeAll());
    dispatch(RebalanceAccount.removeAll());
    dispatch(CustomAccount.clearCustomAccount());
    if (!ui_exposure_checked) {
      this.setReminderVisibility(true);
    }
    if (!accounts.length) {
      dispatch(Accounts.fetchAll());
    }

    return false;
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (!history.location.pathname.includes('/accounts/')) {
      dispatch(Accounts.toggleMultipleMode());
    }
  }

  setReminderVisibility = (isOpen) => this.setState({ reminderIsOpen: isOpen });

  canCreateAccount = (match, accountList) => {
    const { id } = match.params;
    const customAccounts = accountList.filter(
      ({ provider }) => provider === 'wsc'
    );

    // Only check accounts if there isn't a target account.
    // Otherwise, the second step to creating an account could be blocked.
    if (!Number(id) && customAccounts.length >= 10) {
      return false;
    }

    return true;
  };

  canUseStockExposure = (accountList) => accountList.length > 0;

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  handleReminderClick = () => {
    const { dispatch } = this.props;
    dispatch(Storage.setItem('ui_exposure_checked', true));
    dispatch(Accounts.checkUiExposure());
    this.setState({ reminderIsOpen: false });
  };

  render() {
    const {
      accounts,
      isFetching,
      history,
      dispatch,
      selectedAccountList,
      match,
      partnerName,
      overview,
      exchangeRate,
      user_region,
      french,
    } = this.props;
    const {
      multipleSelect,
      invalidSubmission,
      reminderIsOpen,
      disclaimerIsOpen,
    } = this.state;

    return (
      <Segment style={{ height: '100%', overflow: 'auto' }}>
        <Loading active={isFetching} french={french} />
        <StyledHeader size="1.5rem" color="black">
          <div>
            {french ? (
              <SubHeader long>
                Notre analyse vous montre les valeurs nutritives de votre ou vos
                portefeuilles <strong>ACTUELS</strong>. Depuis un compte,
                sélectionnez le bouton Analyser en haut à droite. <br /> <br />
                <strong>Notez :</strong> Actuellement, nous disposons de données
                sur les actions/FNB canadiens et américains, les fonds communs
                de placement et les fonds distincts canadiens, ainsi que les
                fonds de CÉIÉ en CAD/USD.
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  content="Les fonds de CÉIÉ en CAD/USD sont inclus dans l’analyse en utilisant le rendement historique d’un fonds de CÉIÉ en CAD/USD représentatif détenu par une banque."
                />
                Les comptes en USD seront convertis en CAD lorsque vous
                regroupez des comptes libellés en devises différentes.
              </SubHeader>
            ) : isWealthica(partnerName) ? (
              <SubHeader long>
                Our analysis shows you the nutrition facts of your{' '}
                <strong>CURRENT </strong>
                portfolio(s). In an account, click the Analyze button in the top
                right. <br /> <br />
                <strong>Note:</strong>Currently, we have data for Canadian and
                US stocks/ETFs, Canadian mutual and segregated funds, and
                CAD/USD HISA funds.
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
                />
                Any other securities will be excluded from the analysis. USD
                accounts will be converted to CAD when you group accounts in
                different currencies.
              </SubHeader>
            ) : (
              <SubHeader long>
                Our analysis shows you the nutrition facts of your{' '}
                <strong>CURRENT </strong>
                portfolio(s). In an account, click the Analyze button in the top
                right. <br /> <br />
                <strong>Note:</strong> Currently, we have data for Canadian and
                US stocks/ETFs, Canadian mutual and segregated funds, and
                CAD/USD HISA funds.
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
                />
                Any other securities will be excluded from the analysis. USD
                accounts will be converted to CAD when you group accounts in
                different currencies.
              </SubHeader>
            )}
          </div>
        </StyledHeader>
        <Button
          className={cn('left', {
            disabled: !this.canCreateAccount(match, accounts),
          })}
          icon="line chart"
          onClick={() => history.push('/accounts/custom/new')}
          color={nbColors.red}
          style={{
            position: 'absolute',
            top: '2rem',
            right: '2rem',
            width: '15rem',
          }}
          popup={{
            position: 'left center',
            content: french
              ? 'Enregistre jusqu’à 10 comptes saisis manuellement'
              : 'There is a custom account limit of 10.',
            wide: true,
          }}
          enabledPopup={{
            position: 'left center',
            content: french
              ? 'Enregistre jusqu’à 10 comptes saisis manuellement'
              : 'Save up to 10 manually entered accounts',
            wide: true,
          }}
        >
          {french ? 'Saisie manuelle' : 'Manually Enter'}
        </Button>
        {isWealthica(partnerName) && !isNB(partnerName) ? (
          <Feature name={TOOLS_STOCK_EXPOSURE}>
            <Popup
              className="stockPopup"
              onClick={this.handleReminderClick}
              style={{ position: 'static' }}
              trigger={
                <div
                  style={{
                    position: 'absolute',
                    top: '5.5rem',
                    right: '2rem',
                  }}
                >
                  <Button
                    className={cn('left', {
                      disabled: !this.canUseStockExposure(accounts),
                    })}
                    icon="line chart"
                    onClick={() => {
                      this.handleReminderClick();
                      history.push('/accounts/stock-exposure');
                    }}
                    color={colors.blue}
                    popup={{
                      position: 'left center',
                      content:
                        'This tool requires you to have saved accounts in Wealthscope. Please Manually Enter or Link your Accounts to get started.',
                      wide: true,
                    }}
                    style={{ width: '15rem' }}
                  >
                    Stock Exposure Lookup
                  </Button>
                  <span
                    className="notification heartbeat"
                    style={{
                      backgroundColor: `${colors.orange}`,
                      display: `${reminderIsOpen ? 'flex' : 'none'}`,
                    }}
                  >
                    !
                    {/* You can put the notification count or any other content here */}
                  </span>
                </div>
              }
              position="bottom center"
              open={reminderIsOpen}
            >
              <Popup.Header>New Tool!</Popup.Header>
              <Popup.Content>
                <br />
                Use it to discover your exposure to any stock across all of your
                accounts. Be sure to go deeper and unveil the breakdown of your
                direct and indirect (within a fund) holdings.
                <br />
                <br />
                <span
                  style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Got it.
                </span>
              </Popup.Content>
            </Popup>
          </Feature>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Paragraph
            style={{ paddingRight: '0.9rem', paddingTop: '0.8rem' }}
            disabled={accounts.length < 2}
          >
            {french
              ? 'Regrouper les comptes aux fins de l’analyse?'
              : 'Group accounts for analysis?'}
          </Paragraph>
          <ToggleWrapper
            onClick={() => {
              if (accounts.length > 1) {
                this.setState({
                  multipleSelect: !multipleSelect,
                  invalidSubmission: false,
                });
                dispatch(Accounts.toggleMultipleMode());
              }
            }}
            groupDisabled={accounts.length < 2}
          >
            {accounts.length < 2 ? (
              <Popup
                hoverable
                trigger={
                  <CustomRadio
                    toggle
                    checked={multipleSelect}
                    onClick={() => {
                      dispatch(Accounts.saveSingleAccInfo(''));
                    }}
                    disabled
                  />
                }
                position="top center"
                content={
                  french
                    ? 'Nous pouvez utiliser cette fonction lorsque vous avez 2 comptes ou plus.'
                    : 'You can use this function when you have 2 or more accounts.'
                }
              />
            ) : (
              <CustomRadio
                toggle
                checked={multipleSelect}
                onClick={() => {
                  dispatch(Accounts.saveSingleAccInfo(''));
                }}
              />
            )}
          </ToggleWrapper>
          <Button
            className="left"
            icon="book"
            color={nbColors.blue}
            onClick={() => {
              if (selectedAccountList.length >= 2) {
                return history.push('/accounts/multiple');
              }
              return this.setState({ invalidSubmission: true });
            }}
            style={{
              display: `${multipleSelect ? 'inline-block' : 'none'}`,
              transform: 'translate(15px, 3px)',
            }}
          >
            {french ? 'Voir groupe' : 'View Group'}
          </Button>
          <Label
            basic
            color="red"
            pointing="left"
            content={
              french
                ? 'Veuillez sélectionner au moins deux comptes.'
                : 'Please select at least two accounts.'
            }
            style={{
              display: `${invalidSubmission ? 'inline-block' : 'none'}`,
              transform: 'translate(18px, -3px)',
            }}
          />
        </div>
        {!accounts.length ? null : (
          <Card
            to="/accounts/overview"
            color={nbColors.darkBlue}
            hoverside={nbColors.darkBlue}
            hover={nbColors.darkerLightBlue}
            header={french ? "Vue d'ensemble" : 'Overview'}
            content={
              french ? "Vue d'ensemble des comptes" : 'Accounts overview'
            }
            currency={user_region === 'US' ? 'USD' : 'CAD'}
            style={{
              opacity: `${multipleSelect ? '0.4' : '1'}`,
              pointerEvents: `${multipleSelect ? 'none' : 'auto'}`,
            }}
            onClick={() => {
              dispatch(Accounts.saveSingleAccInfo('overview'));
            }}
            balance={this.parseNum(overview.balance)}
          />
        )}
        {!accounts.length ? (
          <div>
            {french
              ? "Vous n'avez aucun compte enregistré."
              : "You don't have any saved accounts"}
          </div>
        ) : (
          accounts.map((acc) => (
            <Card
              to={multipleSelect ? '/accounts/user' : `/accounts/${acc.id}`}
              accoundId={acc.id}
              selectable={multipleSelect}
              selected={selectedAccountList.includes(acc)}
              key={acc.id}
              icon="chart line"
              color={nbColors.darkBlue}
              hoverside={nbColors.darkBlue}
              hover={nbColors.darkerLightBlue}
              header={acc.user_institution}
              content={acc.label}
              warning={acc.rebal_alert || acc.rebal_update} //(rebalancing disabled for NB)
              rebal={acc.rebal_set}
              balance={this.parseNum(acc.balance)}
              currency={user_region === 'US' ? 'USD' : acc.currency}
              linked={acc.provider !== 'wsc' && isWealthica(partnerName)}
              isActive={() => {
                if (multipleSelect) {
                  return selectedAccountList.includes(acc);
                }

                return false;
              }}
              onClick={() => {
                dispatch(
                  Accounts.saveSingleAccInfo(
                    acc.id.toString(),
                    acc.user_institution,
                    acc.label
                  )
                );

                if (multipleSelect) {
                  if (selectedAccountList.includes(acc)) {
                    return dispatch(
                      Accounts.deselectMultipleAccount(
                        acc,
                        selectedAccountList,
                        user_region,
                        exchangeRate
                      )
                    );
                  }

                  this.setState({ invalidSubmission: false });
                  return dispatch(
                    Accounts.selectMultipleAccount(
                      acc,
                      selectedAccountList,
                      user_region,
                      exchangeRate
                    )
                  );
                }

                return false;
              }}
            />
          ))
        )}
        <Footer>
          Copyright © 2025 PW Portfolio Analytics Inc.
          <TermsLink
            href={
              french
                ? 'https://www.wealthscope.ca/terms-of-use-french'
                : 'https://www.wealthscope.ca/terms-of-use'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {translateText('Terms of Service', french)[0].toUpperCase() +
              translateText('Terms of Service', french).substring(1)}
          </TermsLink>{' '}
          <TermsLink
            href={
              french
                ? 'https://www.wealthscope.ca/privacy-policy-french'
                : 'https://www.wealthscope.ca/privacy-policy'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {translateText('Privacy Policy', french)[0].toUpperCase() +
              translateText('Privacy Policy', french).substring(1)}
          </TermsLink>
          <LinkButton onClick={() => this.setState({ disclaimerIsOpen: true })}>
            {french ? 'Avis de non-responsabilité' : 'Legal Notice'}
          </LinkButton>
          <Modal
            disclaimer
            vb
            open={disclaimerIsOpen}
            dimmerClick={() => this.setState({ disclaimerIsOpen: false })}
            style={{
              display: 'flex',
              alignItems: 'end',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              paddingBottom: '3rem',
            }}
          >
            <div style={{ marginBottom: '-3rem' }}>
              <Modal.Content>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    paddingBottom: '1rem',
                  }}
                >
                  {french ? 'Avis de non-responsabilité' : 'Legal Notice'}
                  <Icon
                    name="close"
                    style={{
                      fontSize: '20px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.setState({ disclaimerIsOpen: false })}
                  />
                </div>
                {!french ? (
                  <span>
                    The information appearing in Wealthscope is provided by PW
                    Portfolio Analytics Inc. ("PWPA"), a third-party content
                    provider that is not affiliated with National Bank Direct
                    Brokerage ("NBDB"), a division of National Bank Financial
                    Inc. ("NBF"), National Bank of Canada ("NBC") or any of
                    NBC's affiliates. The information provided herein has been
                    independently prepared by PWPA and/or its content providers
                    and is not endorsed, approved, or controlled by NBDB, NBC or
                    any other entity of its group. <br /> <br />
                    The information contained herein: <br />
                    (1) is the property of PWPA and/or its content providers;{' '}
                    <br />
                    (2) may not be copied or distributed, and <br />
                    (3) make no warranty as to its accuracy, completeness, or
                    timeliness. <br /> <br />
                    The information contained herein is for informational
                    purposes only and should not be construed, considered, or
                    used as a recommendation, offer or solicitation to sell or
                    buy any product or service of any kind, or as investment,
                    accounting, tax, legal or other advice. Neither NBDB, NBC or
                    any of its affiliates, nor PWPA and/or its content providers
                    shall be liable for any loss or damage of any kind that may
                    arise from the use of the information contained herein. The
                    contents hereof do not create any legal or contractual
                    obligation for NBDB or any of the entities of the NBC group
                    or for NBC itself. <br />
                    <br />
                    Financial data, as well as historical and benchmark indices,
                    are not reliable indicators of future investment trends or
                    outcomes. None of the information contained herein is
                    intended to be a forecast and certainly not a guarantee or
                    commitment as to future conditions, performance or returns.{' '}
                    <br />
                    <br />
                    The information contained herein may be protected by
                    copyright or intellectual property rights laws and may not
                    be reproduced without the consent of PWPA.
                  </span>
                ) : (
                  <span>
                    Les informations apparaissant dans Wealthscope sont fournies
                    par PW Portfolio Analytics Inc. (« PWPA »), un tiers
                    fournisseur de contenu qui n’est pas affilié à Banque
                    Nationale Courtage direct (« BNCD »), une division de la
                    Financière Banque Nationale inc. (« FBN »), ni à la Banque
                    Nationale du Canada (« BNC ») ni à aucune des sociétés du
                    groupe de la BNC. Les renseignements fournis aux présentes
                    ont été préparées de manière indépendante par PWPA et/ou par
                    ses fournisseurs de contenu et ne sont pas avalisés,
                    approuvés ou contrôlés par BNCD, BNC ou toute autre entité
                    de son groupe. <br /> <br />
                    Les informations contenues aux présentes : <br />
                    (1) sont la propriété de PWPA et/ou ses fournisseurs de
                    contenu; <br />
                    (2) ne peuvent être copiées ou distribuées, et <br />
                    (3) ne s’assortissent d’aucune garantie quant à leur
                    exactitude, exhaustivité ou caractère opportun.
                    <br />
                    <br /> Les informations contenues aux présentes sont
                    fournies à titre informatif seulement et ne doivent pas être
                    interprétées, considérées ou utilisées comme une
                    recommandation, offre ou sollicitation de vente ou d’achat
                    de quelque produit ou service de quelque nature que ce soit,
                    ni comme des conseils en matière de placement ou de nature
                    comptable, fiscale, juridique ou autre. Ni BNCD, BNC ou les
                    entités du groupe de la BNC, ni PWPA et/ou ses fournisseurs
                    de contenu ne sauraient être tenus responsables de toute
                    perte ou dommage quelconque pouvant découler de
                    l'utilisation des informations contenues aux présentes. Le
                    contenu des présentes ne crée aucune obligation légale ou
                    contractuelle pour BNCD ni pour aucune des entités du groupe
                    de la BNC ou pour la BNC elle-même. <br />
                    <br />
                    Les données financières tout comme les indices historiques
                    et de référence ne sont pas des indicateurs fiables des
                    tendances ou résultats d’investissements futurs. Aucune
                    information contenue aux présentes ne se veut une prévision
                    et encore moins une garantie ou un engagement quant aux
                    conditions, performance et rendements à venir. <br />
                    <br />
                    Les informations contenues aux présentes peuvent être
                    protégées par les lois sur les droits d’auteur ou les droits
                    de propriété intellectuelle et ne peuvent être reproduites
                    sans le consentement de PWPA.
                  </span>
                )}
              </Modal.Content>
            </div>
          </Modal>
        </Footer>
      </Segment>
    );
  }
}

AccountsList.propTypes = {
  accounts: PropTypes.array,
  dispatch: PropTypes.func,
  isFetching: PropTypes.bool,
  history: PropTypes.object,
  selectedAccountList: PropTypes.array,
  match: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  overview: PropTypes.object,
  exchangeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user_region: PropTypes.string,
  ui_exposure_checked: PropTypes.bool,
  french: PropTypes.bool,
};

AccountsList.defaultProps = {
  accounts: [],
  dispatch: () => false,
  isFetching: false,
  history: {},
  selectedAccountList: [],
  partnerName: '',
  overview: {},
  exchangeRate: 1,
  user_region: 'CA',
  ui_exposure_checked: false,
  french: false,
};

const Paragraph = styled.p`
  color: black;
  opacity: 0.9;
  font-size: 1.14rem;
  max-width: 16rem;
  font-weight: 600;
  display: inline-block;
`;

const ToggleWrapper = styled.div`
  display: inline-block;
  transform: translateY(5px);

  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background: ${(props) =>
      props.groupDisabled ? 'rgba(0, 0, 0, 0.25) !important' : ''};
  }

  .ui.toggle.checkbox input:focus:checked~.box:before,
  .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: ${nbColors.blue} !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: ${nbColors.blue} !important;
`;

const CustomRadio = styled(({ className, children, ...rest }) => (
  <Radio className={className} {...rest}>
    {children}
  </Radio>
))`
  label:before {
    background: rgba(0, 0, 0, 0.25) !important;
  }

  label:hover:before {
    background: rgba(0, 0, 0, 0.15) !important;
  }import { isQuoteMedia } from '../../utils/helpers';

`;

const StyledHeader = styled.div`
  margin: 0 0 2rem 0;
  color: ${(props) => props.color || 'inherit'};
  font-size: ${(props) => props.size || '1rem'};
`;

const SubHeader = styled.div`
  margin-top: 8px;
  font-size: 1.14rem;
  max-width: ${(props) => (props.long ? '47rem' : '39rem')};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  width: 100%;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  padding-top: 1rem;
`;

const LinkButton = styled.button`
  border: none;
  color: ${nbColors.blue};
  background: white;
  font-weight: bold;

  &: hover {
    text-decoration: underline;
  }
`;

const TermsLink = styled.a`
  color: ${nbColors.blue}
  
  &: hover {
    text-decoration: underline;
  }
`;
