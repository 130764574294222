import { ChartTool } from 'actions';

const initialState = {
  isFetching: false,
  securityChartData: [],
  portfolioChartData: [],
  youngestDate: '',

  inputs: {
    isOnPortfolio: false,
    securityList: [],
    portfolioList: [],
    portfolioNames: [],
    allNamesList: [],
    periodStart: '',
    periodEnd: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ChartTool.CHART_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ChartTool.CHART_DATA_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        securityChartData: action.data.security_chart_list,
        portfolioChartData: action.data.portfolio_chart_list,
        startDate: action.data.youngest_start_date,
        absoluteYoungestStartDate: action.data.absolute_youngest_start_date,
        endDate: action.data.oldest_end_date,
        absoluteOldestEndDate: action.data.absolute_oldest_end_date,
        errorEntity: '',
      };
    case ChartTool.CHART_PARTIAL_FAIL:
      return {
        ...state,
        isFetching: false,
        errorEntity: action.data.error_entity,
      };
    case ChartTool.CHART_DATA_CLEAR:
      return {
        ...state,
        isFetching: false,
        securityChartData: [],
        portfolioChartData: [],
        startDate: '',
        absoluteYoungestStartDate: '',
        endDate: '',
        absoluteOldestEndDate: '',
        errorEntity: '',
      };
    case ChartTool.CHART_UPDATE_INPUTS:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          ...action.inputs,
        },
      };
    case ChartTool.CHART_TOOL_CLEAR:
      return initialState;
    case ChartTool.CHART_FAIL:
      return initialState;
    default:
      return state;
  }
}
