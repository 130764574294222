import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Statistic, Header, Popup, Icon } from 'semantic-ui-react';
import { Score, Table } from 'components';
import { translation } from '../localization';
import { colors } from '../../../../utils/colors';
import { translateText } from '../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class Diversification extends Component {
  getName = (ticker) => {
    const { analytics } = this.props;
    const { data } = analytics;
    const { tickersOriginal, tickersValid } = data;
    const matchedTicker = tickersOriginal.find(
      (val) => val.replacedBy.proxyTicker === ticker
    );

    if (matchedTicker) {
      return matchedTicker.wsName;
    }

    return tickersValid[ticker].wsName;
  };

  getSplits = (corr, num) => {
    // split the correlation list into groups of num so that stacked tables
    // are rendered if there are too many correlations
    const splits = [];
    let curr = [];
    let cash = false;

    Object.keys(corr).forEach((ticker) => {
      if (ticker === 'CASH:MKT') {
        cash = true;
      }
      curr.push(ticker);
      if (curr.length === num) {
        splits.push(curr);
        curr = [];
      }
    });
    if (curr.length > 0) {
      splits.push(curr);
    }

    return { splits, cash };
  };

  shouldHighlight = (corr) => corr >= 0.7 && corr < 1;

  // Based off isExecuteVisible function that decides if the
  // "View ETFs" button is visible on the scorecard.
  // Determines if corr matrix should show names or tickers
  showNames = (portfolio) => {
    if (!portfolio) {
      return false;
    }
    if (
      portfolio.is_linked ||
      portfolio.portfolio_type === 'custom' ||
      portfolio.portfolio_type === 'quantified'
    ) {
      return false;
    }

    return true;
  };

  parseDate = (portfolio) => {
    const { french } = this.props;
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const language = french ? 'fr' : 'en';

    return `${translation[language].MONTH[[init[1]]]} ${init[0]} ${
      french ? 'à' : 'to'
    } ${translation[language].MONTH[[end[1]]]} ${end[0]}`;
  };

  formatNum = (num) => (num ? num.toFixed(2) : null);

  renderMatrix = (data) => {
    const { french } = this.props;
    const labelText = french
      ? 'CORRÉLATION MOYENNE PONDÉRÉE DU PORTEFEUILLE TOTAL'
      : 'TOTAL PORTFOLIO WEIGHTED AVERAGE CORRELATION';
    const cashText = translateText(
      'Note: for cash, while the covariance between cash and any asset is zero, the correlation coefficient is undefined because it has the standard deviation of cash in the denominator, which is also zero.',
      french
    );
    const showNames = this.showNames(data.portfolio);
    const { splits, cash } = this.getSplits(data.corr, showNames ? 5 : 8);

    return (
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <Statistic
          label={labelText}
          value={Math.round(data.ipc * 100) / 100}
          size="tiny"
          style={{ marginBottom: '0.5rem' }}
        />
        <Header style={{ marginTop: 0, marginBottom: '1rem' }}>
          <Header.Subheader>
            {translateText(
              'Correlation coefficients greater than or equal to 0.70 will be flagged.',
              french
            )}
          </Header.Subheader>
        </Header>
        <TableList>
          {splits.map((split) => (
            <TableWrapper showNames={showNames}>
              <Table celled definition textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {split.map((ticker) => (
                      <Table.HeaderCell key={ticker}>
                        {showNames ? this.getName(ticker) : ticker}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {Object.keys(data.corr).map((rowTicker, i) => {
                    if (data.corr[rowTicker][split[0]] !== null) {
                      // don't show rows without correlation data
                      return (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {showNames ? this.getName(rowTicker) : rowTicker}
                          </Table.Cell>
                          {split.map((columnTicker, j) => {
                            const val = data.corr[rowTicker][columnTicker];
                            return (
                              <Table.Cell
                                key={j}
                                style={{
                                  background:
                                    this.shouldHighlight(val) &&
                                    colors.negative,
                                  color:
                                    this.shouldHighlight(val) && colors.hintRed,
                                }}
                              >
                                {val === 0 ? '-' : this.formatNum(val)}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      );
                      // eslint-disable-next-line no-else-return
                    } else return null;
                  })}
                </Table.Body>
              </Table>
            </TableWrapper>
          ))}
        </TableList>
        {cash && <Note>{cashText}</Note>}
      </div>
    );
  };

  renderSubHeader = (data, language) => (
    <div className="sub header">
      <div>{`${this.parseDate(data.portfolio)} (${
        translation[language].INCLUSIVE
      })`}</div>
    </div>
  );

  renderPairs = (data) => {
    const { french } = this.props;
    const parsedData = Object.keys(data.corr).reduce((total, key) => {
      Object.keys(data.corr[key]).forEach((key2) => {
        if (
          data.corr[key][key2] &&
          data.corr[key][key2] < 1 &&
          data.corr[key][key2] >= 0.7
        ) {
          if (!total[key]) {
            total[key] = [
              {
                name: data.tickersValid[key].name,
                ticker: key,
                matchTicker: key2,
                matchName: data.tickersValid[key2].name,
                corr: data.corr[key][key2],
                rowSpan: 1,
              },
            ];
          } else {
            total[key][0].rowSpan += 1;
            total[key].push({
              name: data.tickersValid[key].name,
              ticker: key,
              matchTicker: key2,
              matchName: data.tickersValid[key2].name,
              corr: data.corr[key][key2],
              exclude: true,
            });
          }
        }
      });

      return total;
    }, {});

    const filtered = Object.values(parsedData).reduce((total, value, index) => {
      if (value.length === 1) {
        total.push({ key: index, item: value[0] });
      } else {
        value.forEach((item, idx) =>
          total.push({ key: `${index}${idx}`, item })
        );
      }

      return total;
    }, []);

    return (
      <div style={{ textAlign: 'center' }}>
        <Statistic
          label={
            french
              ? 'CORRÉLATION MOYENNE PONDÉRÉE DU PORTEFEUILLE TOTAL'
              : 'TOTAL PORTFOLIO WEIGHTED AVERAGE CORRELATION'
          }
          value={Math.round(data.ipc * 100) / 100}
          size="tiny"
        />

        <Table correlation>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan="3">
                {french
                  ? 'Paires d’actifs avec une corrélation supérieure ou égale à 0,70'
                  : 'Asset pairs with correlation greater than or equal to 0.70'}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  content={
                    french
                      ? 'La matrice de corrélation est trop grande pour être affichée pour ce portefeuille. Lorsqu’un portefeuille contient plus de 15 actifs, nous vous montrons uniquement les paires qui sont fortement corrélées.'
                      : 'The correlation matrix is too large to display for this portfolio. When a portfolio has more than 15 assets, we show you only the pairs that are highly correlated.'
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={6}>
                {french ? 'Actif' : 'Asset'}
              </Table.HeaderCell>
              <Table.HeaderCell width={6}>
                {french ? 'Actif' : 'Asset'}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {french ? 'Corrélation' : 'Correlation'}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filtered.length ? (
              filtered.map((item) => (
                <Table.Row key={item.key}>
                  {!item.item.exclude && (
                    <Table.Cell
                      rowSpan={
                        item.item.rowSpan > 1 ? item.item.rowSpan : undefined
                      }
                    >
                      {item.item.ticker}
                      <SecurityName>{item.item.name}</SecurityName>
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    {item.item.matchTicker}
                    <SecurityName>{item.item.matchName}</SecurityName>
                  </Table.Cell>
                  <Table.Cell>
                    {Math.round(item.item.corr * 100) / 100}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell>N/A</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  render() {
    const { analytics, french } = this.props;
    const { data } = analytics;
    const language = french ? 'fr' : 'en';

    return (
      <Container>
        <Score
          name={`${translation[language].DIVERSIFICATION} `}
          score={`${data.score.Diversification[0]}%`}
          grade={data.score.Diversification[1]}
          description={
            french
              ? "Évalué en utilisant la corrélation moyenne de votre portefeuille et quatre dimensions de diversification à travers a) la classe d'actifs, b) la région géographique, c) les secteurs d'activité, et d) les facteurs macroéconomiques."
              : "Scored using your portfolio's average correlation and four dimensions of diversification across a) asset class, b) geographical region, c) business sectors, and d) macroeconomic factors."
          }
          portfolio={data.portfolio}
          pdf
        />

        <p>
          {french
            ? 'Devriez-vous abandonner un déjeuner gratuit ?'
            : 'Should you give up a free lunch?'}
        </p>
        <p>
          {french
            ? "La réponse est un NON retentissant lorsqu'il s'agit de vos investissements. En diversifiant correctement et en choisissant des actifs qui ne bougent pas ensemble, vous pouvez réduire le risque de votre portefeuille sans sacrifier les rendements."
            : 'The answer is a resounding NO when it comes to your investments. By diversifying properly and choosing assets that do not move together in locked steps, you can reduce the amount of risk in your portfolio without sacrificing returns.'}
        </p>
        <p>
          {french
            ? "Soyez conscient que le simple ajout de plus d'actifs à votre portefeuille ne signifie pas nécessairement que vous diversifiez efficacement. Par exemple, si tous vos actifs sont exposés au même facteur de risque, alors la diversification est superficielle. Il est important de diversifier à travers différents types d'expositions au risque."
            : 'Be aware that merely adding more assets to your portfolio does not necessarily mean that you are diversifying effectively. For example, if all of your assets are exposed to the same risk factor, then the diversification is superficial. It is important to diversify across different types of risk exposures. '}
        </p>

        <Header
          dividing
          size="medium"
          subheader={this.renderSubHeader(data, language)}
          content={
            Object.keys(data.corr).length < 15 ? (
              <div>
                {french ? 'Matrice de corrélation' : 'Correlation Matrix'}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  content={
                    french
                      ? "Les coefficients de corrélation prennent une valeur entre -1 et +1. Dans le contexte de l'analyse de portefeuille, ils mesurent comment les rendements de deux actifs évoluent l'un par rapport à l'autre au fil du temps. Plus un coefficient de corrélation se rapproche de +1, moins il y a de bénéfices à la diversification, ce qui signifie qu'inclure les deux actifs dans le portefeuille n'est pas efficace pour réduire le risque du portefeuille."
                      : "Correlation coefficients take on a value between -1 and +1. In the context of portfolio analysis, they measure how two assets' returns move in relation to one another over time. The closer a correlation coefficient is to +1, the less benefit there is to diversification, meaning that including both assets in the portfolio are not effective in lowering portfolio risk."
                  }
                />
              </div>
            ) : (
              <div>
                {french
                  ? 'Coefficients de corrélation'
                  : 'Correlation Coefficients'}
                &nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  content={
                    french
                      ? "Les coefficients de corrélation prennent une valeur entre -1 et +1. Dans le contexte de l'analyse de portefeuille, ils mesurent comment les rendements de deux actifs évoluent l'un par rapport à l'autre au fil du temps. Plus un coefficient de corrélation se rapproche de +1, moins il y a de bénéfices à la diversification, ce qui signifie qu'inclure les deux actifs dans le portefeuille n'est pas efficace pour réduire le risque du portefeuille."
                      : "Correlation coefficients take on a value between -1 and +1. In the context of portfolio analysis, they measure how two assets' returns move in relation to one another over time. The closer a correlation coefficient is to +1, the less benefit there is to diversification, meaning that including both assets in the portfolio are not effective in lowering portfolio risk."
                  }
                />
              </div>
            )
          }
        />

        {Object.keys(data.corr).length < 15
          ? this.renderMatrix(data)
          : this.renderPairs(data)}
      </Container>
    );
  }
}

Diversification.propTypes = {
  analytics: PropTypes.object,
  french: PropTypes.bool,
};

Diversification.defaultProps = {
  analytics: {},
  french: false,
};

const TableWrapper = styled.div`
  .table {
    display: table-caption;
  }

  tr,
  th {
    min-width: ${(props) => (props.showNames ? '170px' : '115px')};
    max-width: ${(props) => (props.showNames ? '170px' : '115px')};
    word-wrap: break-word;
    overflow-wrap: break-word; /* For modern browsers */
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;

const TableList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const Note = styled.div`
  padding-top: 1rem;
  text-align: left;
  text-wrap: wrap;
  max-width: 60%;
`;
