import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import cn from 'classnames';
import { connect } from 'react-redux';

import { notWealthica } from 'utils/helpers';

const convertPropToClassName = (prop, className) => prop && className;

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))
class Modal extends Component {
  static Header = ({ children, className, close, closeIcon, ...rest }) => (
    <div className={cn('modal-header', className)} {...rest}>
      {closeIcon && <Icon name="close" onClick={close} />}
      {children}
    </div>
  );
  static Content = ({ children, className, ...rest }) => (
    <div className={cn('modal-content', className)} {...rest}>
      {children}
    </div>
  );
  static Actions = ({ children, className, ...rest }) => (
    <div className={cn('modal-actions', className)} {...rest}>
      {children}
    </div>
  );

  closeModal = (e) => {
    const { ...rest } = this.props;

    return rest.dimmerClick && e.target.id === 'modal'
      ? rest.dimmerClick()
      : false;
  };

  render() {
    const {
      children,
      open,
      popupOnPopup,
      basic,
      basicTop,
      basicFlex,
      rebalance,
      className,
      size,
      partnerName,
      ...rest
    } = this.props;
    const classes = cn(
      className,
      size,
      convertPropToClassName(basic, 'basic'),
      convertPropToClassName(basicTop, 'basicTop'),
      convertPropToClassName(basicFlex, 'basicFlex'),
      convertPropToClassName(popupOnPopup, 'popupOnPopup'),
      convertPropToClassName(rebalance, 'rebalance')
    );

    if (!open) return null;

    return (
      <StyledModal
        onClick={this.closeModal}
        id="modal"
        className={classes}
        vb={notWealthica(partnerName)}
        {...rest}
      >
        <ModalBody className={classes} vb {...rest}>
          {children}
        </ModalBody>
      </StyledModal>
    );
  }
}

Modal.propTypes = {
  partnerName: PropTypes.string,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  size: PropTypes.string,
  basic: PropTypes.bool,
  basicTop: PropTypes.bool,
  basicFlex: PropTypes.bool,
  popupOnPopup: PropTypes.bool,
  rebalance: PropTypes.bool,
  bottom: PropTypes.string,
};

Modal.defaultProps = {
  partnerName: '',
  className: '',
  open: false,
  basic: false,
  basicTop: false,
  basicFlex: false,
  popupOnPopup: false,
  rebalance: false,
  size: '',
  bottom: '',
};

export default Modal;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  z-index: 1000;
  overflow: auto;
  background-color: ${(props) =>
    props.disclaimer
      ? 'rgba(0, 0, 0, 0)'
      : props.terms
      ? 'rgba(0, 0, 0, 0.5)'
      : props.vb
      ? 'rgba(0, 0, 0, 0.8)'
      : 'rgba(0, 0, 0, 0.8)'};
  &.popupOnPopup {
    background-color: rgba(0, 0, 0, 0.015);
  }

  &.rebalance {
    position: absolute;
  }
`;

const ModalBody = styled.div`
  position: relative;
  background-color: ${(props) => (props.vb ? '#fff' : 'transparent')};
  color: ${(props) => (props.vb ? '#000' : '#fff')};
  border: none;
  text-align: left;
  width: 70%;
  margin: 3.5rem auto;
  border-radius: 7px;
  overflow: hidden;
  .modal-content {
    color: ${(props) => (props.vb ? 'black' : 'white')};
  }

  &.large {
    width: 80%;
  }

  &.full {
    width: 100%;
  }

  @media only screen and (max-width: 1200px) {
    width: 75%;
  }

  @media only screen and (max-width: 1000px) {
    width: 80%;
  }

  &.basic {
    width: 47rem;
    margin: 0 auto;
    padding: 0.75rem 1.5rem 0.75rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 125px;
    bottom: ${(props) => props.bottom || '125px'};

    .modal-actions {
      background-color: transparent;
    }
  }

  &.basicTop {
    width: 47rem;
    margin: 0 auto;
    padding: 0.75rem 1.5rem 0.75rem;
    position: absolute;
    top: 15rem;
    left: 0;
    right: 0;
    .modal-actions {
      background-color: transparent;
    }
  }

  &.basicFlex {
    width: 47rem;
    margin: 13rem auto 0 auto;
    padding: 0.75rem 1.5rem 0.75rem;
    position: absolute;
    top: ${(props) => props.offsetY || 0}px;
    left: 0;
    right: 0;

    .modal-actions {
      background-color: transparent;
    }
  }

  &.fullscreen {
    background-color: transparent;
    color: #fff;
    margin: 2rem;
    padding: 0;
    width: 96%;
    color: black;
    background: white;
  }

  .modal-header {
    background-color: transparent;
    padding: 1.25rem 1.5rem;
    box-shadow: none;
    border-bottom: 1px solid rgba(34, 36, 39, 0.15);
    font-weight: 700;
    line-height: 1.3em;
    position: relative;

    .ui.header {
      margin-top: initial;
    }

    .close {
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      font-size: 2rem;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-content {
    width: 100%;
    font-size: 1em;
    padding: 1.5rem;
    background-color: transparent;
  }

  .modal-actions {
    padding: 1rem;
    text-align: right;
    background-color: transparent;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
  }

  &.rebalance {
    text-align: center;
    background-color: #fff;
  }
`;
