import { CacheInputs } from 'actions';

const initialState = {
  cacheUrl: {
    accounts: null,
    portfolio: null,
    planning: null,
    tools: null,
  },
  tools: {
    fund_allocator: {
      value: '',
      investment: null,
      commission: null,
      commissionfree: null,
      cachePortfolio: {},
      cacheOutput: [],
      error: {},
    },
  },
  planning: {
    target_saving: {
      targ: null,
      init: null,
      horz: null,
      portID: '',
      errors: {},
    },
    target_wealth: {
      portfolioID: 0,
      annuityRate: null,
      age: null,
      retAge: null,
      gender: null,
      cf3: null,
      video: false,
      modelType: null,
      declinePattern: false,
      declinePatternPercent: null,
      declinePatternDuration: null,
      errors: {},
    },
    portfolio_longevity: {
      lifeExp: null,
      portfolio: null,
      annuityRate: null,
      retAge: null,
      init: null,
      cf: null,
      riskFree: null,
      useDefault: true,
      age: null,
      gender: null,
      errors: {},
    },
  },
  portfolios: {
    smart_risk_builder: {
      activeIndex: 0,
      maxIndex: 0,
      selection: {},
      error: false,
    },
    fund_allocator: {
      value: '',
      investment: null,
      commission: null,
      commissionfree: null,
      cachePortfolio: {},
      cacheOutput: [],
      error: {},
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CacheInputs.CACHEINPUTS_CAPTURE_URL:
      return {
        ...state,
        cacheUrl: {
          ...state.cacheUrl,
          [action.module]: action.url,
        },
      };
    case CacheInputs.CACHEINPUTS_UPDATE_FIELD:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          [action.feature]: {
            ...state[action.module][action.feature],
            ...action.value,
          },
        },
      };
    case CacheInputs.CACHEINPUTS_CLEAR_FIELD:
      return {
        ...state,
        [action.module]: {
          ...state[action.module],
          [action.feature]: initialState[action.module][action.feature],
        },
      };
    case CacheInputs.CACHEINPUTS_CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
