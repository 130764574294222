export const exchange = {
  // eslint-disable-line
  ARCA: 'NYSE ARCA',
  BATS: 'BATS',
  CDX: 'TSX Venture',
  CMF: 'CDN Mutual Funds',
  NSD: 'Nasdaq',
  NSC: 'Nasdaq',
  NGS: 'Nasdaq',
  NLS: 'Nasdaq',
  NYE: 'NYSE',
  TSX: 'TSX',
  TSV: 'TSX Venture',
  AMX: 'NYSE AMEX',
};

export const marginOfError = 0.1;
export const displayLineChartDots = 38;
export const saveModalCharLimits = {
  name: 60,
  description: 30,
  institution: 60,
  accountType: 30,
};
export const planningLimit = 5;
export const maxNumberOfFundsToCompare = 4;
