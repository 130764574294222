import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Divider, Table, Popup, Icon, Button } from 'semantic-ui-react';
import { maxNumberOfFundsToCompare } from 'utils/constants';
import { chartingToolColorsLessOpacity, colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';

const RATIO_NAME_ROWS_BACKGROUND_COLOR = '#F9FAFB';
const EMPTY_CELL_BACKGROUND_COLOR = '#F9FAFB';
class Risk extends Component {
  state = {};

  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker, exchangeSymbol) =>
    `${ticker.split(':')[0]}-${exchangeSymbol}`;

  handleClick = (event, ticker, exchangeSymbol) => {
    event.preventDefault();
    window.open(
      this.createUrl(
        this.gbmConvert(
          this.appendExchangeSymbol(ticker, exchangeSymbol.toUpperCase())
        )
      )
    ),
      '_blank';
  };

  renderEmptyHeaderCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => (
      <Table.HeaderCell
        style={{ backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }}
      />
    ));
  renderEmptyCells = (numEmptyCols) =>
    [...Array(numEmptyCols)].map(() => (
      <Table.Cell style={{ backgroundColor: EMPTY_CELL_BACKGROUND_COLOR }} />
    ));
  renderRatioNameCells = (ratioName, popUpContent, numEmptyCols) => (
    <React.Fragment>
      <Table.Cell
        style={{ backgroundColor: RATIO_NAME_ROWS_BACKGROUND_COLOR }}
        width={3}
      >
        {ratioName}
        <Popup
          trigger={
            <Icon
              name="question circle outline"
              style={{
                fontSize: '15px',
                verticalAlign: 'initial',
                color: 'dimgrey',
              }}
            />
          }
          position="top center"
          content={popUpContent}
          wide
        />
      </Table.Cell>
      {[...Array(numEmptyCols)].map(() => (
        <Table.Cell
          style={{ backgroundColor: RATIO_NAME_ROWS_BACKGROUND_COLOR }}
        />
      ))}
    </React.Fragment>
  );

  render() {
    const { securityData, securityTickerList, isOpen, toggleOpen, partnerName } = this.props;

    const numEmptyCols = maxNumberOfFundsToCompare - securityTickerList.length;

    return (
      <div>
        <Header textAlign="left">
          <div style={{ display: 'flex' }}>
            <StyledButton onClick={toggleOpen} isOpen={isOpen}>
              {isOpen ? '-' : '+'}
            </StyledButton>
            Fund Metrics
          </div>
        </Header>
        <Divider />
        {isOpen && (
          <Table compact celled definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {securityTickerList.map((security, index) => (
                  <Table.HeaderCell
                    width={3}
                    style={{
                      backgroundColor: Object.values(
                        chartingToolColorsLessOpacity
                      )[index],
                      color: 'black',
                    }}
                  >
                    {isGlobeInvestor(partnerName) ? (
                      <a
                        style={{
                          zIndex: '9999999',
                          display: 'block',
                          cursor: 'pointer',
                          pointerEvents: 'all',
                          color: `${
                            window.location.pathname.includes('pdf')
                              ? 'black'
                              : 'auto'
                          }`,
                        }}
                        onClick={() =>
                          this.handleClick(
                            event,
                            security,
                            securityData[security].exchange_symbol
                          )
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {security}
                      </a>
                    ) : (
                      security
                    )}
                  </Table.HeaderCell>
                ))}
                {this.renderEmptyHeaderCells(numEmptyCols)}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* TOTAL RISK ROWS */}
              <Table.Row>
                {this.renderRatioNameCells(
                  'Total Risk',
                  "This is the variability of this fund's returns over time, as measured by the standard deviation. It includes upside as well as downside variability, and hence the name total risk.",
                  securityTickerList.length
                )}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  width={3}
                  style={{
                    fontWeight: 'normal',
                    paddingLeft: '3rem',
                    backgroundColor: 'white',
                  }}
                >
                  3-year
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell>
                    {securityData[security].mpt_risk_data.three_year
                      .std_deviation
                      ? `${securityData[security].mpt_risk_data.three_year.std_deviation}%`
                      : '-'}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  width={3}
                  style={{
                    fontWeight: 'normal',
                    paddingLeft: '3rem',
                    backgroundColor: 'white',
                  }}
                >
                  5-year
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell>
                    {securityData[security].mpt_risk_data.five_year
                      .std_deviation
                      ? `${securityData[security].mpt_risk_data.five_year.std_deviation}%`
                      : '-'}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  width={3}
                  style={{
                    fontWeight: 'normal',
                    paddingLeft: '3rem',
                    backgroundColor: 'white',
                  }}
                >
                  10-year
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell>
                    {securityData[security].mpt_risk_data.ten_year.std_deviation
                      ? `${securityData[security].mpt_risk_data.ten_year.std_deviation}%`
                      : '-'}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              {/* END RISK ROWS */}

              {/* START SHARPE RATIO ROWS */}
              <Table.Row>
                {this.renderRatioNameCells(
                  'Sharpe Ratio',
                  "This ratio measures the 'reward' of the fund per unit of 'risk', where reward is the average return of the fund over and above the risk-free rate, and risk is the standard deviation (or volatility) of the fund. The risk-free rate we use is the 12-month Treasury bill yield.",
                  securityTickerList.length
                )}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  width={3}
                  style={{
                    fontWeight: 'normal',
                    paddingLeft: '3rem',
                    backgroundColor: 'white',
                  }}
                >
                  3-year
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell>
                    {securityData[security].mpt_risk_data.three_year
                      .sharpe_ratio || '-'}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  width={3}
                  style={{
                    fontWeight: 'normal',
                    paddingLeft: '3rem',
                    backgroundColor: 'white',
                  }}
                >
                  5-year
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell>
                    {securityData[security].mpt_risk_data.five_year
                      .sharpe_ratio || '-'}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  width={3}
                  style={{
                    fontWeight: 'normal',
                    paddingLeft: '3rem',
                    backgroundColor: 'white',
                  }}
                >
                  10-year
                </Table.Cell>
                {securityTickerList.map((security) => (
                  <Table.Cell>
                    {securityData[security].mpt_risk_data.ten_year
                      .sharpe_ratio || '-'}
                  </Table.Cell>
                ))}
                {this.renderEmptyCells(numEmptyCols)}
              </Table.Row>
              {/* END SHARPE RATIO ROWS */}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}

Risk.propTypes = {
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

Risk.defaultProps = { partnerName: '' };

export default Risk;

const StyledButton = styled(Button)`
  &&& {
    font-size: 1.3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.isOpen ? colors.blue : 'white'} !important;
    color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
    border: ${(props) =>
      props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
    border-radius: 50% !important;
    height: 2rem !important;
    width: 2rem !important;
    padding: 0 !important;
    transition: all 0.9s ease-in-out !important;
    margin: 0 0.75rem 0 0;
  }
`;
