import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Input, Form, Header } from 'semantic-ui-react';
import cn from 'classnames';
import { isGlobeInvestor } from 'utils/helpers';
import { BuildETF } from 'actions';
import { colors } from 'utils/colors';
import { Button } from 'components';
import TotalWeight from './TotalWeight';
import SectorWeight from './SectorWeight';

class AllocateWeights extends Component {
  componentDidMount = () => {
    const { dispatch, selectedAssets } = this.props;

    if (selectedAssets && Object.keys(selectedAssets).length === 1) {
      dispatch(
        BuildETF.updateWeight(
          selectedAssets[Object.keys(selectedAssets)[0]].symbol,
          100
        )
      );
    }
  };

  handleChange = (e, { name, value }) =>
    this.props.dispatch(BuildETF.updateWeight(name, value));

  calcTotal = () => {
    const { selectedAssets } = this.props;

    return Object.values(selectedAssets).reduce((total, value) => {
      total += Number(value.weight);

      return Math.round(total * 100) / 100;
    }, 0);
  };

  calcSectorWeight = (type) =>
    Object.values(this.props.selectedAssets).reduce((total, value) => {
      if (value.assetclass === type) {
        total += Number(value.weight);
      }

      return Math.round(total * 100) / 100;
    }, 0);

  handleSubmit = () => {
    const { history, match } = this.props;

    return history.push(`/portfolio/build-etf/${match.params.id}/history`);
  };

  isDisabled = (assetclass) =>
    !Object.values(this.props.selectedAssets).filter(
      (value) => value.assetclass === assetclass
    ).length;

  renderAssets = (type) =>
    Object.values(this.props.selectedAssets).map(
      (value) =>
        value.assetclass === type && (
          <Form.Field
            key={value.symbol}
            inline
            style={{ margin: '0 0 0.2em', position: 'relative' }}
          >
            <Input
              name={value.symbol}
              type="number"
              size="mini"
              label={{ basic: true, content: '%' }}
              labelPosition="right"
              value={value.weight || ''}
              placeholder="0"
              onChange={this.handleChange}
              style={{ maxWidth: 43, zIndex: 1 }}
            />
            <Label>{value.name}</Label>
          </Form.Field>
        )
    );

  render() {
    const { goBack, partnerName } = this.props;
    const assetClassOrder = isGlobeInvestor(partnerName)
      ? ['Equity', 'Fixed Income', 'Alternative']
      : ['Fixed Income', 'Equity', 'Alternative'];

    const displayNameMapping = {
      Equity: 'Stocks',
      'Fixed Income': 'Bonds',
      Alternative: 'Alternative',
    };

    return (
      <Form style={{ padding: '1rem 1rem 2rem' }}>
        <Header
          size="medium"
          textAlign="center"
          content="Indicate Desired Weights"
          style={{ padding: '1rem 0 2rem 0' }}
        />

        {assetClassOrder.map((assetClass) => (
          <Segment
            key={assetClass}
            basic
            compact
            responsive
            disabled={this.isDisabled(assetClass)}
          >
            <SectorWeight
              title={displayNameMapping[assetClass]} // Use the display name from the mapping
              value={this.calcSectorWeight(assetClass)}
            />
            {this.renderAssets(assetClass)}
          </Segment>
        ))}
        <div
          style={{
            clear: 'both',
            width: '100%',
            textAlign: 'center',
            padding: '1rem 0',
          }}
        >
          <TotalWeight value={this.calcTotal()} />
        </div>

        <Button
          className={cn('right', { disabled: this.calcTotal() !== 100 })}
          color={colors.orange}
          icon="chevron right"
          floated="right"
          onClick={this.handleSubmit}
        >
          Next
        </Button>

        <Button
          className="left"
          color={colors.orange}
          icon="chevron left"
          onClick={(e) => (e.preventDefault(), goBack())}
        >
          Back
        </Button>
      </Form>
    );
  }
}

AllocateWeights.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedAssets: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

AllocateWeights.defaultProps = {
  partnerName: '',
};

export default connect((state) => ({
  selectedAssets: state.BuildETF.selectedAssets,
  partnerName: state.Storage['partner-name'],
}))(AllocateWeights);

const Label = styled.label`
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  font-weight: 500 !important;
  line-height: 1rem;
  height: 2.2rem;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 6rem;
`;

const Segment = styled.div`
  display: block;
  width: 33%;
  float: left;
  margin: 1rem 0;
  padding: 1rem;

  @media only screen and (max-width: 850px) {
    width: 400px;
    margin: 1rem auto;
    float: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
      color: rgba(40, 40, 40, 0.3);
    `}
`;
