import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import cn from 'classnames';
import { colors } from 'utils/colors';
import { notWealthica } from 'utils/helpers';
import { Modal, Button, Message } from 'components';
import { Portfolio } from 'actions';

@withRouter
@connect((state) => ({
  partnerName: state.Storage['partner-name'],
  userPortfolios: state.Portfolio.user,
  modelPortfolios: state.Portfolio.model,
}))
export default class SaveModal extends Component {
  state = {
    name: '',
    description: '',
    isLoading: false,
    nameExists: false,
  };

  componentWillMount = () => {
    const { dispatch, modelPortfolios, userPortfolios } = this.props;

    if (!userPortfolios.length) {
      dispatch(Portfolio.fetchUser());
    }

    if (!modelPortfolios.length) {
      dispatch(Portfolio.fetchModel());
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value, nameExists: false });
  };

  save = () => {
    const { closeModal, dispatch, portfolio, userPortfolios, modelPortfolios } =
      this.props;
    const allPortfolios = modelPortfolios.concat(userPortfolios);
    const { name, description } = this.state;
    const body = {
      ...portfolio,
      name,
      description,
      is_linked: true,
    };

    if (
      allPortfolios.find((port) => port.name === name) &&
      allPortfolios.find(
        (port) => (port.description || '').toString() === description.toString()
      )
    ) {
      return this.setState({ nameExists: true });
    }

    this.setState({ isLoading: true });

    return dispatch(Portfolio.save(body, false)).then(() => {
      this.setState({
        isLoading: false,
        name: '',
        description: '',
      });

      closeModal();
    });
  };

  render() {
    const { open, closeModal, partnerName } = this.props;
    const { nameExists } = this.state;

    return (
      <Modal
        vb={notWealthica(partnerName)}
        basic
        open={open}
        dimmerClick={() => closeModal()}
      >
        <Modal.Header>
          <Header icon="briefcase" content="Save Portfolio" color="green" />
        </Modal.Header>
        <Modal.Content>
          <Field vb={notWealthica(partnerName)}>
            Name:
            <input
              placeholder="(required)"
              name="name"
              value={this.state.name || ''}
              onChange={this.handleChange}
            />
          </Field>
          <Field vb={notWealthica(partnerName)}>
            Description:
            <input
              placeholder="(optional)"
              name="description"
              value={this.state.description || ''}
              onChange={this.handleChange}
            />
          </Field>
        </Modal.Content>
        <Modal.Actions>
          {nameExists && (
            <div
              style={{
                textAlign: 'center',
                overflow: 'auto',
                float: 'right',
                margin: '0 0 1rem 0',
              }}
            >
              <Message visible error style={{ margin: '0' }}>
                <Icon
                  name="exclamation circle"
                  style={{ fontSize: '16px', transform: 'translateY(1px)' }}
                />
                Portfolio name already exists
              </Message>
            </div>
          )}
          <div style={{ clear: 'both' }}>
            <Button
              className={notWealthica(partnerName) ? 'left' : 'inverted left'}
              color={colors.red}
              icon="cancel"
              margin="0 0.5rem 0 0"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              className={cn(
                `${
                  notWealthica(partnerName)
                    ? 'left'
                    : 'inverted left grey-focus'
                }`,
                {
                  loading: this.state.isLoading,
                  disabled: !this.state.name || nameExists,
                }
              )}
              color={colors.green}
              icon="checkmark"
              onClick={this.save}
            >
              Save
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

SaveModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolio: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  partnerName: PropTypes.string,
  userPortfolios: PropTypes.array,
  modelPortfolios: PropTypes.array,
};

SaveModal.defaultProps = {
  portfolio: {},
  partnerName: '',
  userPortfolios: null,
  modelPortfolios: null,
};

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;
  line-height: 1.3em;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: ${(props) =>
      props.vb ? '1px solid grey' : '1px solid white'};
  }

  input {
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: ${(props) => (props.vb ? 'rgba(0,0,0,0.6)' : 'white')};
    &:focus {
      outline: 0;
    }
  }
`;
