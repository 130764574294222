export const colors = {
  blue: 'rgb(108,135,252)',
  red: 'rgb(244,94,90)',
  pink: 'rgb(255,184,182)',
  orange: 'rgb(252,196,123)',
  teal: 'rgb(202,232,238)',
  lightBlue: 'rgb(182,193,253)',
  darkBlue: 'rgb(31,48,128)',
  mediumBlue: 'rgb(63, 83, 176)',
  grey: 'rgb(170,172,174)',
  white: 'rgba(255,255,255,0.9)',
  black: 'rgba(0,0,0,0.65)',
  lightGrey: 'rgb(236,236,240)',
  darkGrey: 'rgba(0,0,0,0.5)',
  green: 'rgb(46,204,64)',
  darkGreen: 'rgb(49, 166, 62)',
  periwinkle: 'rgb(97, 124, 246)',
  navyBlue: 'rgb(28, 42, 114)',
  hintRed: '#9F3A38',
  negative: '#fce6e6', // used for red highlighted table cells in correlation matrix
};

export const dark = {
  blue: 'rgb(31,48,128)',
  grey: 'rgb(149,149,149)',
};

export const darker = {
  teal: 'rgb(90,160,179)',
  blue: 'rgb(60,90,210)',
  orange: 'rgb(204,130,33)',
  red: 'rgb(204,67,63)',
};

export const lighter = {
  grey: 'rgb(236,236,240)',
  teal: 'rgb(159,222,234)',
  orange: 'rgb(255,213,157)',
};

export const hover = {
  blue: 'rgba(108,135,252,0.4)',
  red: 'rgba(255,180,180,0.4)',
  pink: 'rgba(255,184,182,0.4)',
  orange: 'rgba(252,196,123,0.4)',
  grey: 'rgba(170,172,174,0.4)',
  teal: 'rgba(202,232,238,0.4)',
};

export const graph = {
  blue: 'rgb(108,135,252)',
  red: 'rgb(244,94,90)',
  pink: 'rgb(255,184,182)',
  teal: 'rgb(202,232,238)',
  orange: 'rgb(252,196,123)',
  lightBlue: 'rgb(182,193,253)',
  yellow: 'rgb(251,227,57)',
  darkBlue: 'rgb(31,48,128)',
  red2: 'rgb(242,153,146)',
  lightBlue2: 'rgb(159,208,237)',
  grey: 'rgb(170,170,170)',
  green: 'rgb(76,189,116)',
  darkBlue2: 'rgb(63,94,204)',
  darkPink: 'rgb(194,126,126)',
};

export const chartingToolColors = {
  blue: 'rgb(108,135,252)',
  red: 'rgb(244,94,90)',
  orange: 'rgb(252,196,123)',
  lightBlue2: 'rgb(159,208,237)',
  darkBlue2: 'rgb(63,94,204)',
  darkGrey: 'rgb(17,17,21)',
  grey: 'rgb(149,149,149)',
  lightGrey: 'rgb(236,236,240)',
  skyBlue: 'rgb(202,232,238)',
};

export const chartingToolColorsLessOpacity = {
  blue: 'rgba(108,135,252, 0.3)',
  red: 'rgba(244,94,90, 0.3)',
  orange: 'rgba(252,196,123, 0.3)',
  lightBlue2: 'rgba(159,208,237, 0.3)',
  darkBlue2: 'rgb(63,94,204, 0.3)',
  darkGrey: 'rgba(17,17,21, 0.3)',
  grey: 'rgba(149,149,149, 0.3)',
  lightGrey: 'rgba(236,236,240, 0.3)',
  skyBlue: 'rgba(202,232,238, 0.3)',
};

export const correlationMatrixToolColors = {
  blue: 'rgb(108,135,252)',
  red: 'rgb(244,94,90)',
  orange: 'rgb(252,196,123)',
  lightBlue2: 'rgb(159,208,237)',
  darkBlue2: 'rgb(63,94,204)',
  darkBlue: 'rgb(31,48,128)',
  grey: 'rgb(149,149,149)',
  lightGrey: 'rgb(236,236,240)',
  skyBlue: 'rgb(202,232,238)',
};

export const nbColors = {
  darkBlue: '#000950',
  red: '#E41C23',
  lightBlue: '#EEF6FB',
  darkerLightBlue: '#dfeef7',
  lightPink: '#FBE7EE',
  blue: '#1572C5',
  pastelBlue: '#BBDDE6',
};

export const nbGraph = {
  gray: 'rgba(202, 239, 218, 1)',
  lightest: 'rgba(178,235,203,255)',
  lighter: 'rgba(158,224,197,255)',
  light: 'rgba(119,195,193,255)',
  mediumLight: 'rgba(79,172,191,255)',
  medium: 'rgba(32,141,194,255)',
  mediumDark: 'rgba(2,116,193,255)',
  dark: 'rgba(5,92,150,255)',
  darker: 'rgba(6,68,109,255)',
  darkest: 'rgba(7,42,65,255)',
  black: 'rgb(1, 3, 5)',
};
