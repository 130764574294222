import * as Alert from './alert';
import { request } from './api';
import { setItem } from './storage';

/**
 * Action constants
 */
export const ANALYTICS_REQUEST = 'ANALYTICS_REQUEST';

export const ANALYTICS_FETCH_SUCCESS = 'ANALYTICS_FETCH_SUCCESS';
export const ANALYTICS_COMPARE_SUCCESS = 'ANALYTICS_COMPARE_SUCCESS';
export const ANALYTICS_UPDATE_PORTFOLIO = 'ANALYTICS_UPDATE_PORTFOLIO';
export const ANALYTICS_UPDATE_BENCHMARK = 'ANALYTICS_UPDATE_BENCHMARK';
export const ANALYTICS_UPDATE_PARAMETER = 'ANALYTICS_UPDATE_PARAMETER';
export const ANALYTICS_UPDATE_PRINTPARAMS = 'ANALYTICS_UPDATE_PRINTPARAMS';
export const ANALYTICS_FETCH_ESG = 'ANALYTICS_FETCH_ESG';
export const ANALYTICS_COMPARE_ESG_SUCCESS = 'ANALYTICS_COMPARE_ESG_SUCCESS';

export const ANALYTICS_ESG_ALERT = 'ANALYTICS_ESG_ALERT';
export const ANALYTICS_CLEAR = 'ANALYTICS_CLEAR';
export const ANALYTICS_FAIL = 'ANALYTICS_FAIL';
export const ANALYTICS_PORTFOLIO_HISTORY_COMPARETO =
  'ANALYTICS_PORTFOLIO_HISTORY_COMPARETO';

/**
 * Action creators
 */

export function fetchId(
  id,
  checkHistory,
  customBenchmark,
  region,
  rebalanceInterval = 1
) {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_REQUEST });

    return dispatch(request('get', `/portfolio/${id}/`)).then(
      (params) =>
        dispatch(
          request('post', '/portfolio/analytics/', {
            body: {
              ...params,
              check_history:
                checkHistory === null || checkHistory === undefined
                  ? params.check_history
                  : !checkHistory,
              ...(customBenchmark && { custom_bm: customBenchmark }),
              region: region || 'CA',
              rebalance_interval: rebalanceInterval,
            },
          })
        ).then(
          (data) => {
            if (!Object.keys(data).length) {
              return dispatch({
                type: ANALYTICS_FETCH_SUCCESS,
                data,
                portfolio: {},
              });
            }

            const { symbols, weights, ...rest } = data.paramsAnalyzed;

            dispatch(
              setItem(
                'pwpa-check-history',
                checkHistory === null || checkHistory === undefined
                  ? params.check_history
                  : !checkHistory
              )
            );

            return dispatch({
              type: ANALYTICS_FETCH_SUCCESS,
              data,
              portfolio: { ...params, ...rest },
            });
          },
          (error) => {
            dispatch({ type: ANALYTICS_FAIL });

            return dispatch(
              Alert.show({ type: 'error', msg: JSON.stringify(error) })
            );
          }
        ),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: ANALYTICS_FAIL });

        if (error.data && error.data.detail === 'Not found.') {
          return dispatch({ type: ANALYTICS_FETCH_SUCCESS, data: error.data });
        }

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchIdUs(
  id,
  checkHistory,
  customBenchmark,
  rebalanceInterval = 1
) {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_REQUEST });

    return dispatch(request('get', `/portfolio/${id}/`)).then(
      (params) =>
        dispatch(
          request('post', '/portfolio/analytics/', {
            body: {
              ...params,
              check_history:
                checkHistory === null || checkHistory === undefined
                  ? params.check_history
                  : !checkHistory,
              ...(customBenchmark && { custom_bm: customBenchmark }),
              region: 'US',
              rebalance_interval: rebalanceInterval,
            },
          })
        ).then(
          (data) => {
            if (!Object.keys(data).length) {
              return dispatch({
                type: ANALYTICS_FETCH_SUCCESS,
                data,
                portfolio: {},
              });
            }

            const { symbols, weights, ...rest } = data.paramsAnalyzed;

            dispatch(
              setItem(
                'pwpa-check-history',
                checkHistory === null || checkHistory === undefined
                  ? params.check_history
                  : !checkHistory
              )
            );

            return dispatch({
              type: ANALYTICS_FETCH_SUCCESS,
              data,
              portfolio: { ...params, ...rest },
            });
          },
          (error) => {
            dispatch({ type: ANALYTICS_FAIL });

            return dispatch(
              Alert.show({ type: 'error', msg: JSON.stringify(error) })
            );
          }
        ),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: ANALYTICS_FAIL });

        if (error.data && error.data.detail === 'Not found.') {
          return dispatch({ type: ANALYTICS_FETCH_SUCCESS, data: error.data });
        }

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchParams(
  params,
  objective,
  checkHistory,
  region,
  rebalanceInterval = 1
) {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_REQUEST });
    params = {
      ...params,
      check_history:
        checkHistory === null || checkHistory === undefined
          ? true
          : !checkHistory,
      weights: params.weights
        .split(',')
        .reduce((result, weight) => {
          result.push(parseFloat(weight).toFixed(4));

          return result;
        }, [])
        .join(','),
      region: region || 'CA',
      rebalance_interval: rebalanceInterval,
    };

    return dispatch(
      request('post', '/portfolio/analytics/', { body: params })
    ).then(
      (data) => {
        if (!Object.keys(data).length || data.detail !== undefined) {
          return dispatch({
            type: ANALYTICS_FETCH_SUCCESS,
            data,
            portfolio: {},
          });
        }

        const { symbols, weights, ...rest } = data.paramsAnalyzed;

        return dispatch({
          type: ANALYTICS_FETCH_SUCCESS,
          data,
          portfolio: { ...params, ...rest },
          checkHistory:
            checkHistory === null || checkHistory === undefined
              ? true
              : !checkHistory,
          objective,
        });
      },
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: ANALYTICS_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchParamsUs(
  params,
  objective,
  checkHistory,
  rebalanceInterval = 1
) {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_REQUEST });
    params = {
      ...params,
      check_history:
        checkHistory === null || checkHistory === undefined
          ? true
          : !checkHistory,
      weights: params.weights
        .split(',')
        .reduce((result, weight) => {
          result.push(parseFloat(weight).toFixed(4));

          return result;
        }, [])
        .join(','),
      region: 'US',
      rebalance_interval: rebalanceInterval,
    };

    return dispatch(
      request('post', '/portfolio/analytics/', { body: params })
    ).then(
      (data) => {
        if (!Object.keys(data).length || data.detail !== undefined) {
          return dispatch({
            type: ANALYTICS_FETCH_SUCCESS,
            data,
            portfolio: {},
          });
        }

        const { symbols, weights, ...rest } = data.paramsAnalyzed;

        return dispatch({
          type: ANALYTICS_FETCH_SUCCESS,
          data,
          portfolio: { ...params, ...rest },
          checkHistory:
            checkHistory === null || checkHistory === undefined
              ? true
              : !checkHistory,
          objective,
        });
      },
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: ANALYTICS_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchCompare(port1, port2, region, rebalanceInterval = 1) {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_REQUEST });

    return dispatch(
      request('post', '/portfolio/analytics/compare/', {
        body: {
          ...getPayload(port1, port2),
          region: region || 'CA',
          rebalance_interval: rebalanceInterval,
        },
      })
    ).then(
      ({ portfolio1, portfolio2 }) =>
        dispatch({
          type: ANALYTICS_COMPARE_SUCCESS,
          analysis1: {
            ...portfolio1,
            // back-end doesnt return portfolio name, id, etc...
            ...(portfolio1 &&
              Object.keys(portfolio1).length && { portfolio: port1 }),
          },
          analysis2: {
            ...portfolio2,
            // back-end doesnt return portfolio name, id, etc...
            ...(portfolio2 &&
              Object.keys(portfolio2).length && { portfolio: port2 }),
          },
        }),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: ANALYTICS_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchEsg(portfolio, fromToggle, esgToggle) {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_REQUEST });

    const { symbols, weights, gic_info, is_mp } = portfolio;

    return dispatch(
      request('post', '/portfolio/esg/', {
        body: {
          symbols,
          weights,
          ...(gic_info &&
            Object.keys(gic_info).length && {
              gic_info,
            }),
          is_mp,
        },
      })
    ).then(
      (esg) =>
        dispatch(request('get', '/portfolio/esg/dist/')).then(
          (data) => {
            dispatch({ type: ANALYTICS_FETCH_ESG, esg, distribution: data });

            if (esg.portfolio.esg_score === null && fromToggle) {
              dispatch({ type: ANALYTICS_FETCH_ESG, esg, distribution: data });

              return dispatch(setEsgAlert());
            }

            dispatch(updatePrintParams({ ESG: true }));

            return dispatch(updateParameter('esgToggle', esgToggle));
          },
          (error) => {
            const parsedError =
              error.msg || error.detail || error.error || 'Server Error';
            dispatch({ type: ANALYTICS_FAIL });

            return dispatch(Alert.show({ type: 'error', msg: parsedError }));
          }
        ),
      (error) => {
        dispatch({ type: ANALYTICS_FAIL });

        return dispatch(
          Alert.show({ type: 'error', msg: JSON.stringify(error) })
        );
      }
    );
  };
}

export function fetchCompareEsg(port1, port2, is_mp1, is_mp2) {
  return (dispatch) =>
    dispatch(
      request('post', '/portfolio/esg/compare/', {
        body: {
          ...getPayload(port1, port2),
          ...(is_mp1 && { is_mp1 }),
          ...(is_mp2 && { is_mp2 }),
        },
      })
    ).then(
      ({ portfolio1esg, portfolio2esg }) =>
        dispatch({
          type: ANALYTICS_COMPARE_ESG_SUCCESS,
          portfolio1esg,
          portfolio2esg,
        }),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: ANALYTICS_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
}

export function updatePortfolio(portfolio) {
  return {
    type: ANALYTICS_UPDATE_PORTFOLIO,
    portfolio,
  };
}

export function updateParameter(field, value) {
  return {
    type: ANALYTICS_UPDATE_PARAMETER,
    field,
    value,
  };
}

export function updatePorfolioHistoryCompareto(portfolioHistoryCompareto) {
  return {
    type: ANALYTICS_PORTFOLIO_HISTORY_COMPARETO,
    portfolioHistoryCompareto,
  };
}

export function setEsgAlert() {
  return (dispatch) => {
    dispatch({ type: ANALYTICS_ESG_ALERT, esgAlert: true });

    return setTimeout(
      () => dispatch({ type: ANALYTICS_ESG_ALERT, esgAlert: false }),
      5000
    );
  };
}

export function clearAnalytics() {
  return { type: ANALYTICS_CLEAR };
}

export function updatePrintParams(value) {
  return {
    type: ANALYTICS_UPDATE_PRINTPARAMS,
    value,
  };
}

/**
 * Helper Functions
 */
function getPayload(port1, port2) {
  const port1Symbols = port1.symbols.split(',');
  const port2Symbols = port2.symbols.split(',');
  const gicFormat = /^GIC\d*:MKT$/;
  const port1GicTicker = port1Symbols.filter((holding) =>
    gicFormat.test(holding)
  );
  const port2GicTicker = port2Symbols.filter((holding) =>
    gicFormat.test(holding)
  );

  port1 = {
    ...port1,
    weights: port1.weights
      .split(',')
      .reduce((result, weight) => {
        result.push(parseFloat(weight).toFixed(4));

        return result;
      }, [])
      .join(','),
  };

  port2 = {
    ...port2,
    weights: port2.weights
      .split(',')
      .reduce((result, weight) => {
        result.push(parseFloat(weight).toFixed(4));

        return result;
      }, [])
      .join(','),
  };

  if (port1GicTicker.length && port2GicTicker.length) {
    if (port1GicTicker.includes(port2GicTicker[0])) {
      const gicSample = [
        'GIC1:MKT',
        'GIC2:MKT',
        'GIC3:MKT',
        'GIC4:MKT',
        'GIC5:MKT',
        'GIC6:MKT',
        'GIC7:MKT',
        'GIC8:MKT',
        'GIC9:MKT',
        'GIC10:MKT',
      ];
      const port1GicOccupied = gicSample.reduce((result, gicSampleTicker) => {
        result[gicSampleTicker] = port1GicTicker.includes(gicSampleTicker);

        return result;
      }, {});
      const gicVacancy = Object.keys(port1GicOccupied).find(
        (gic) => !port1GicOccupied[gic]
      );
      const updatedPort2Symbol = port2Symbols
        .map((p2) => (p2 === port2GicTicker[0] ? gicVacancy : p2))
        .join(',');

      return {
        symbols1: port1.symbols,
        weights1: port1.weights,
        symbols2: updatedPort2Symbol,
        weights2: port2.weights,
        gic_info: {
          ...port1.gic_info,
          [gicVacancy]: port2.gic_info[port2GicTicker[0]],
        },
      };
    }

    return {
      symbols1: port1.symbols,
      weights1: port1.weights,
      symbols2: port2.symbols,
      weights2: port2.weights,
      gic_info: {
        ...port1.gic_info,
        ...port2.gic_info,
      },
    };
  } else if (!port1GicTicker.length && !port2GicTicker.length) {
    return {
      symbols1: port1.symbols,
      weights1: port1.weights,
      symbols2: port2.symbols,
      weights2: port2.weights,
    };
  } else if (port1GicTicker.length) {
    return {
      symbols1: port1.symbols,
      weights1: port1.weights,
      symbols2: port2.symbols,
      weights2: port2.weights,
      gic_info: port1.gic_info,
    };
  }

  return {
    symbols1: port1.symbols,
    weights1: port1.weights,
    symbols2: port2.symbols,
    weights2: port2.weights,
    gic_info: port2.gic_info,
  };
}
