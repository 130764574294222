import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Api } from 'actions';
import { Table } from 'components';
import { isGlobeInvestor } from 'utils/helpers';

@connect((state) => ({
  partnerName: state.Storage['partner-name'],
}))
export default class Execute extends Component {
  state = {
    tickersValidExchanges: {},
    tickersValidWsMatches: {},
  };
  componentDidMount = () => {
    const { data, dispatch } = this.props;
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${Object.keys(data.tickersValid).join()}`,
      })
    ).then((result) => {
      this.setState({ tickersValidExchanges: result });
    });
    const matchTickerValues = Object.values(data.tickersValid)
      .flatMap(({ wsMatches }) => wsMatches)
      .map(({ matchTicker }) => matchTicker)
      .join();

    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${matchTickerValues}`,
      })
    ).then((result) => {
      this.setState({ tickersValidWsMatches: result });
    });
  };

  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker) => {
    const { tickersValidWsMatches } = this.state;
    const exchangeSymbol = tickersValidWsMatches.find(
      (item) => item.ticker === ticker
    ).exchange_symbol;
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };
  appendExchangeSymbol2 = (ticker) => {
    const { tickersValidExchanges } = this.state;
    const exchangeSymbol = tickersValidExchanges.find(
      (item) => item.ticker === ticker
    ).exchange_symbol;
    return `${ticker.split(':')[0]}-${exchangeSymbol}`;
  };

  handleClick = (event, ticker) => {
    event.preventDefault();
    window.open(
      this.createUrl(this.gbmConvert(this.appendExchangeSymbol(ticker)))
    ),
      '_blank';
  };
  handleClick2 = (event, ticker) => {
    event.preventDefault();
    window.open(
      this.createUrl(this.gbmConvert(this.appendExchangeSymbol2(ticker)))
    ),
      '_blank';
  };

  getMaxCorETFs = (data) => {
    const wsMatches = Object.values(data.tickersValid).map(
      ({ wsMatches }) => wsMatches.length
    );
    return Math.max(...wsMatches);
  };

  isLinked = (data) => {
    switch (data.portfolio.portfolio_type) {
      case 'custom':
      case 'quantified':
        return true;
      case 'other':
        return data.portfolio.is_linked;
      default:
        return false;
    }
  };

  sortTicker = (data) =>
    Object.keys(data)
      .map((ticker) => ({
        ticker,
        corEtfsLength: data[ticker].wsMatches.length,
      }))
      .sort((a, b) => b.corEtfsLength - a.corEtfsLength)
      .map(({ ticker }) => ticker);

  renderDummyCell = (corEtfsLength, maxCorETFs) =>
    Array.from({ length: maxCorETFs - corEtfsLength }, (_, i) => (
      <Table.Cell key={i} />
    ));

  render() {
    const { data, partnerName } = this.props;
    const maxCorETFs = this.getMaxCorETFs(data);

    return (
      <Container>
        <Header size="large" content="Execute" />
        <p>
          To execute your portfolio, you need to choose an ETF for each asset
          class, and purchase the ETFs through your broker.
        </p>
        <p>
          Please read the prospectus of each ETF (available on the ETF
          provider&#8217;s website) carefully before making a decision and
          consult a financial advisor. The list below should not be construed as
          a recommendation for any security.
        </p>
        {this.props.user_region === 'CA' && (
          <p>
            Should you choose a Canadian or U.S.-listed ETF? Read our short
            article,
            <br />
            <a
              href="https://www.wealthscope.ca/foreignwithholdingtax"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>
                Investing Abroad - Canadian or U.S. Listed ETFs? Some Tax
                Considerations
              </b>
            </a>
          </p>
        )}

        <StyledDiv>
          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                {(maxCorETFs
                  ? [
                      'Asset',
                      'Weight (%)',
                      'Fund Benchmark',
                      'Proxy ETF',
                      'Highly Correlated ETFs',
                    ]
                  : ['Asset', 'Weight (%)', 'Fund Benchmark', 'Proxy ETF']
                ).map((header) => (
                  <Table.HeaderCell key={header}>
                    {header === 'Proxy ETF' ? (
                      <div>
                        Proxy <br />
                        ETF
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content="The actual ETF that was used in the analytics."
                          wide
                          style={{ display: 'inline-block' }}
                        />
                      </div>
                    ) : (
                      header
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.sortTicker(data.tickersValid).map((key) => (
                <Table.Row key={key}>
                  <Table.Cell>
                    {this.isLinked(data)
                      ? data.tickersValid[key].name
                      : data.tickersValid[key].wsName}
                  </Table.Cell>
                  <Table.Cell>{`${
                    Math.round(data.tickersValid[key].weight * 10000) / 100
                  }%`}</Table.Cell>
                  <Table.Cell>{data.tickersValid[key].wsBnmk}</Table.Cell>
                  <Table.Cell>
                    <strong>
                      {isGlobeInvestor(partnerName) ? (
                        <a
                          style={{
                            zIndex: '9999999',
                            display: 'block',
                            cursor: 'pointer',
                            pointerEvents: 'all',
                          }}
                          onClick={() => this.handleClick2(event, key)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {key}
                        </a>
                      ) : (
                        key
                      )}
                    </strong>
                    <div style={{ lineHeight: '1.2rem', fontSize: '0.9rem' }}>
                      {data.tickersValid[key].name}
                    </div>
                  </Table.Cell>
                  {data.tickersValid[key].wsMatches.map((item) => (
                    <Table.Cell key={item}>
                      <strong>
                        {isGlobeInvestor(partnerName) ? (
                          <a
                            style={{
                              zIndex: '9999999',
                              display: 'block',
                              cursor: 'pointer',
                              pointerEvents: 'all',
                            }}
                            onClick={() =>
                              this.handleClick(event, item.matchTicker)
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.matchTicker}
                          </a>
                        ) : (
                          item.matchTicker
                        )}
                      </strong>
                      <div style={{ lineHeight: '1.2rem', fontSize: '0.9rem' }}>
                        {item.matchName}
                      </div>
                    </Table.Cell>
                  ))}
                  {this.renderDummyCell(
                    data.tickersValid[key].wsMatches.length,
                    maxCorETFs
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </StyledDiv>
      </Container>
    );
  }
}

Execute.propTypes = {
  data: PropTypes.object,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  dispatch: PropTypes.func,
};

Execute.defaultProps = {
  data: {},
  user_region: 'CA',
  partnerName: '',
  dispatch: () => false,
};

const StyledDiv = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 2rem;

  th {
    padding: 1rem !important;
  }

  th,
  td {
    padding: 0.7rem;
    min-width: 100px;
    max-width: 100px;
  }

  thead tr {
    display: block;
    background: #ffedd6;
  }

  tbody {
    display: block;
    max-height: 520px;
  }

  .table {
    width: initial;
  }
`;

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
