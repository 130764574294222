import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Popup, Header, Divider } from 'semantic-ui-react';

import { DateLabel, YouTubePlayer, Score, Table } from 'components';

export default class Performance extends Component {
  state = {
    video: false,
  };

  getPercent = (num) => (!num ? '-' : `${(num * 100).toFixed(2)} %`);

  toggleVideo = (id) =>
    this.setState({ video: id === this.state.video ? false : id });

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  parseDate = (portfolio, short) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return short
      ? `${months[end[1]]} ${end[0]}`
      : `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  render() {
    const { benchmark, data } = this.props;

    return (
      <Container>
        <Score
          name="Performance "
          score={`${data.score.Performance[benchmark][0]}%`}
          grade={data.score.Performance[benchmark][1]}
          description="Scored using your portfolio's annualized return (relative to the benchmark) and the three risk-adjusted ratios: the Sharpe, Roy's Safety First, and the Sortino (relative to those of the benchmark).<br />If your portfolio is just matching (rather than outperforming) the simple benchmark, it will get a pass, at 65%."
          portfolio={data.portfolio}
        />
        <p>
          The information below provides a snapshot of how well your portfolio
          is doing. You will find the traditional measures, such as annualized
          returns for different investment horizons. Most importantly, we show
          you your portfolio’s risk adjusted ratios, which tell you how much
          risk was taken to generate the returns.
          <Icon
            name="youtube play"
            onClick={() => this.toggleVideo('RFk8D46sszc')}
            link
            style={{ color: 'rgb(255,2,2)' }}
          />
        </p>
        <YouTubePlayer
          isOpen={this.state.video === 'RFk8D46sszc'}
          id="RFk8D46sszc"
        />
        <p>
          As long as you invest in securities that involve some level of risk -
          however high or low – the risk adjusted ratios are more appropriate
          measures of performance, because the returns are not guaranteed.
          Needless to say, the <b>higher</b> the ratios, especially relative to
          your portfolio's benchmark, the <b>better</b>.
          <Icon
            name="youtube play"
            onClick={() => this.toggleVideo('MHms0ZGgKg0')}
            link
            style={{ color: 'rgb(255,2,2)' }}
          />
        </p>
        <YouTubePlayer
          isOpen={this.state.video === 'MHms0ZGgKg0'}
          id="MHms0ZGgKg0"
        />

        <Header>
          <Header.Content>Key Statistics</Header.Content>
          <Header.Subheader>
            Based on the current composition of your portfolio&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="top center"
              content="Using the same holdings and the same weights."
              wide
            />
          </Header.Subheader>
          <Header.Subheader>
            <DateLabel str={`${this.parseDate(data.portfolio)} (inclusive)`} />
          </Header.Subheader>
        </Header>
        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3} />
              <Table.HeaderCell>Annualized Return</Table.HeaderCell>
              <Table.HeaderCell>
                Sharpe Ratio&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This ratio measures the 'reward' of the portfolio per unit of 'risk', where reward is the average return of the portfolio over and above the risk-free rate, and risk is the standard deviation (or volatility) of the portfolio. The risk-free rate we use is the 12-month Treasury bill yield."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Roy&#8217;s Safety First Criterion&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                >
                  Like the Sharpe Ratio, this ratio measures the
                  portfolio&#8217;s reward per unit of risk. However, the reward
                  is defined as the portfolio&#8217;s average return over your
                  target return (or minimum acceptable rate), instead of the
                  risk-free rate. Currently, the target return is set to zero,
                  i.e., money preservation (not losing money in nominal terms).
                </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell>
                Sortino Ratio&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                >
                  This ratio measures the portfolio&#8217;s reward per unit of
                  downside risk. The reward is the portfolio&#8217;s average
                  return over your target return (or minimum acceptable rate).
                  Downside risk is the volatility of the portfolio returns below
                  your target. Currently, the target return is set to zero,
                  i.e., money preservation (not losing money in nominal terms).
                </Popup>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell width={3}>My Portfolio</Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.annualizedRet)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.sharpe)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.roys)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.sortino)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {data.fratios[benchmark].name}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={data.fratios[benchmark].description}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].annualizedRet)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].sharpe)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].roys)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].sortino)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header>
          <Header.Content>Annualized Returns</Header.Content>
          <Header.Subheader>
            <DateLabel str={`${this.parseDate(data.portfolio, 'short')}`} />
          </Header.Subheader>
        </Header>
        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3} />
              <Table.HeaderCell>YTD</Table.HeaderCell>
              <Table.HeaderCell>1-year</Table.HeaderCell>
              <Table.HeaderCell>3-year</Table.HeaderCell>
              <Table.HeaderCell>5-year</Table.HeaderCell>
              <Table.HeaderCell>7-year</Table.HeaderCell>
              <Table.HeaderCell>10-year</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell width={3}>My Portfolio</Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.retYTD)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret1)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret3)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret5)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret7)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret10)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {data.fratios[benchmark].name}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={data.fratios[benchmark].description}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].retYTD)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret1)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret3)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret5)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret7)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret10)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

Performance.propTypes = {
  benchmark: PropTypes.string,
  data: PropTypes.object,
};

Performance.defaultProps = {
  benchmark: '',
  data: {},
};

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
