import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import { Auth } from 'actions';
import { isGlobeInvestor } from 'utils/helpers';

@connect((state) => ({
  auth: state.Auth,
  partnerName: state.Storage['partner-name'],
  globe_destination: state.Storage.globe_destination,
}))
export default class Authenticate extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    const { token } = qs.parse(window.location.search);

    return !token ? false : dispatch(Auth.validateToken(token));
  };

  renderRoute = (token, partnerName, globe_destination) => {
    if (token === 'invalid') {
      return <Redirect to="/unauthorized/?token=invalid'" />;
    }

    if (partnerName === 'globeandmail_test' || partnerName === 'globeandmail') {
      return <Redirect to="/globe" />;
    }

    if (isGlobeInvestor(partnerName)) {
      switch (globe_destination) {
        case 'OASEstimator':
          return <Redirect to="/tools/oas-estimator" />;
        case 'CPPEstimator':
          return <Redirect to="/tools/cpp-estimator" />;
        case 'FundAllocator':
          return <Redirect to="/tools/new-account-setup" />;
        case 'FundComparison':
          return <Redirect to="/tools/fund-comparison" />;
        case 'ETFPortfolioBuilder':
          return <Redirect to="/portfolio/build-etf/new/assets" />;
        case 'ViewModelPortfolios':
          return <Redirect to="/portfolio/model" />;
        case 'SmartRiskPortfolioBuilder':
          return <Redirect to="/portfolio/find" />;
        case 'TargetWealth':
          return <Redirect to="/planning/wealth" />;
        case 'TargetSavings':
          return <Redirect to="/planning/saving" />;
        case 'PortfolioLongevity':
          return <Redirect to="/planning/longevity" />;
        case 'RetirementBlueprint':
          return <Redirect to="/planning/blueprint/new/overview" />;
        case 'OptimalDrawdown':
          return <Redirect to="/planning/drawdown/new" />;
        default:
          return <Redirect to="/planning/landing" />;
      }
    }
    if (partnerName === 'nbdb_sandbox' || partnerName === 'nbdb') {
      return <Redirect to="/accounts/user" />;
    }
    return <Redirect to="/accounts" />;
  };

  render() {
    const { auth, partnerName, globe_destination } = this.props;

    if (!auth.isFetching && !auth.token) {
      return null;
    }

    if (auth.isFetching) {
      return null;
    }

    return this.renderRoute(auth.token, partnerName, globe_destination);
  }
}

Authenticate.propTypes = {
  dispatch: PropTypes.func,
  auth: PropTypes.object,
  partnerName: PropTypes.string,
  globe_destination: PropTypes.string,
};

Authenticate.defaultProps = {
  dispatch: () => false,
  auth: {},
  partnerName: '',
  globe_destination: '',
};
