import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Header, Form, Icon, Divider, Button } from 'semantic-ui-react';

import { colors } from 'utils/colors';
import { filterOutKeysFromObject } from 'utils/helpers';
import { Planning, Storage } from 'actions';
import { YouTubePlayer, ErrorLabel } from 'components';
import { NavigationButtons } from './components';
import numSubpagesInEachSection from './numSubPages';
import DeleteWarningModal from './components/DeleteWarningModal';
import { isGlobeInvestor } from '../../../../utils/helpers';

@withRouter
class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAssumptionsOpen: false,
      errors: {},
      numSubPages: numSubpagesInEachSection.retirementBlueprint,
      deleteWarningModalOpen: false,
    };
  }

  componentWillMount() {
    const {
      dispatch,
      data,
      match,
      blueprintDeleteWarningSuppress,
      partnerName,
    } = this.props;
    const { currentSubPage } = data.retirementBlueprint || {};
    const { numSubPages } = this.state;

    // user must have made edits to subsequent sections
    if (
      data.personalDetails &&
      data.personalDetails.currentSubPage >= 1 &&
      match.params.id === 'new'
    ) {
      if (!blueprintDeleteWarningSuppress) {
        this.setDeleteWarningModalVisibility(true);
      }
    }

    const modifiedOverviewData = {
      currentSubPage: currentSubPage
        ? Math.min(numSubPages, currentSubPage)
        : isGlobeInvestor(partnerName)
        ? 2
        : 1,
    };

    dispatch(
      Planning.updateBlueprintData('retirementBlueprint', modifiedOverviewData)
    );

    // ensure all *completed* sections that the user is not actively clicked on
    // are on page number numSubPages[<section>] + 1 (to display the full red progress bar)
    dispatch(
      Planning.updateCompletedSectionsPageNumbers('retirementBlueprint', {
        ...data, // ensure we are using most recent data (store might not update in time)
        retirementBlueprint: {
          ...data.retirementBlueprint,
          ...modifiedOverviewData,
        },
      })
    );
  }

  componentDidUpdate(prevProp) {
    const { dispatch, data } = this.props;

    if (prevProp.data && data) {
      const excludedKeys = ['currentSubPage'];

      const prev = filterOutKeysFromObject(
        prevProp.data.retirementBlueprint,
        excludedKeys
      );
      const curr = filterOutKeysFromObject(
        data.retirementBlueprint,
        excludedKeys
      );
      if (
        JSON.stringify(prev, Object.keys(prev).sort()) !==
        JSON.stringify(curr, Object.keys(prev).sort())
      ) {
        dispatch(Planning.clearBlueprintSection('personalDetails'));
        dispatch(Planning.clearBlueprintSection('targetWealth'));
        dispatch(Planning.clearBlueprintSection('wealthResult'));
        // TS values no longer need to be cleared if other values are modified.
        // dispatch(Planning.clearBlueprintSection('targetSaving'));
        dispatch(Planning.clearBlueprintSection('savingResult'));
        dispatch(Planning.clearBlueprintSection('summary'));
      }
    }
  }

  setDeleteWarningModalVisibility = (isOpen) =>
    this.setState({ deleteWarningModalOpen: isOpen });

  toggleVideo = (id) =>
    this.setState((prevState) => ({
      video: id === prevState.video ? false : id,
    }));

  handleChange = (e, { name, value }) => {
    const { cacheInputChanged, dispatch } = this.props;

    if (!cacheInputChanged) {
      dispatch(Storage.setItem('cacheInputChanged', true));
    }
    dispatch(
      Planning.updateBlueprintData('retirementBlueprint', {
        is_couple_plan: value,
      })
    );
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [name]: null },
    }));
  };

  handleSubmit = () => {
    const { dispatch, history, match, data } = this.props;

    if (this.hasEmptyValue(data.retirementBlueprint.is_couple_plan)) {
      return false;
    }

    dispatch(Planning.nextSubPage('retirementBlueprint'));
    dispatch(
      Planning.updateBlueprintData('personalDetails', {
        currentSubPage: 1,
      })
    );

    return history.push(
      `/planning/blueprint/${match.params.id}/personal_details`
    );
  };

  hasEmptyValue = (values) => {
    if (values === null || values === undefined) {
      this.setState({ errors: { is_couple_plan: 'This field is required' } });

      return true;
    }

    return false;
  };

  handleNextPage = () => {
    const { dispatch } = this.props;

    dispatch(Planning.nextSubPage('retirementBlueprint'));
  };

  handlePreviousPage = () => {
    const { dispatch } = this.props;

    dispatch(Planning.previousSubPage('retirementBlueprint'));
  };

  renderIntroPage = (
    headerTitle,
    subSectionStyles,
    nextButtonSubmits = false,
    hidePreviousButton = false
  ) => {
    const { isAssumptionsOpen, video } = this.state;
    return (
      <BlueprintContent>
        <div style={{ ...subSectionStyles, marginBottom: '-2.36rem' }}>
          <Header
            textAlign="center"
            size="large"
            style={{ marginTop: '-2rem' }}
          >
            {headerTitle}
            <Header.Subheader
              style={{
                marginTop: '1rem',
                letterSpacing: '.08px',
                textAlign: 'left',
                color: colors.darkerBlack,
              }}
            >
              This <b>Retirement Blueprint</b> is designed to tailor to your
              personal circumstances and investment choices.
              <Icon
                link
                name="youtube play"
                onClick={() => this.toggleVideo('T7RY0_ecq1E')}
                style={{ color: 'rgb(255,2,2)' }}
              />
              <YouTubePlayer
                isOpen={video === 'T7RY0_ecq1E'}
                id="T7RY0_ecq1E"
                style={{ maxWidth: '48rem', width: '48rem', margin: '10px 0' }}
              />
            </Header.Subheader>
            <Header.Subheader
              style={{ textAlign: 'left', color: colors.darkerBlack }}
            >
              In the <b>Personal Details</b> tab, we will help you calculate
              your annual spending needs in retirement. This amount will depend
              on your desired lifestyle. Next, we will add up the annual income
              you expect to receive in retirement. The difference between your
              spending needs and your income is the amount that has to be
              financed by your savings.
            </Header.Subheader>
            <Header.Subheader>&nbsp;</Header.Subheader>
            <Header.Subheader
              style={{ textAlign: 'left', color: colors.darkerBlack }}
            >
              In the <b>Target Wealth</b> tab, we will show you how much you
              need to have saved by the time you retire. In the{' '}
              <b>Target Saving</b> tab, we will also show you how much you need
              to save per month, starting now. Finally, we will help you
              calculate how much tax you may have to pay next year as a result
              of investment income/gains.
              <Icon
                name="youtube play"
                onClick={() => this.toggleVideo('3QM5prvkO0M')}
                link
                style={{ color: 'rgb(255,2,2)', marginLeft: '3px' }}
              />
              <YouTubePlayer
                isOpen={video === '3QM5prvkO0M'}
                id="3QM5prvkO0M"
                style={{ margin: '10px 0' }}
              />
            </Header.Subheader>
          </Header>
          <Divider style={{ width: '94%' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CollapseWrapper open={isAssumptionsOpen}>
              <Header
                size="medium"
                style={{
                  position: 'absolute',
                  top: isAssumptionsOpen ? '-11px' : '3px',
                  left: '.5rem',
                  background: 'white',
                  transition: 'all 300ms ease',
                  padding: '0 .75rem 0 .5rem',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledButton
                    icon
                    onClick={() =>
                      this.setState({ isAssumptionsOpen: !isAssumptionsOpen })
                    }
                    isOpen={isAssumptionsOpen}
                  >
                    {isAssumptionsOpen ? '-' : '+'}
                  </StyledButton>
                  Model Features & Assumptions
                </div>
              </Header>

              <Assumptions>
                <Item>
                  <Header size="small" content="Target Audience" />
                  <ul>
                    <li>
                      The Blueprint is best suited for individuals that have
                      RRSP and TFSA contribution room. In 2024, the maximum
                      combined contribution room is $31,560 + $7,000 = $38,560
                      for the calendar year. This is equivalent to saving $3,213
                      per month. If the contribution room is exhausted and part
                      of the savings are held in a non-registered investment
                      account, the Blueprint will calculate the expected taxes
                      owing for the coming year.
                    </li>
                  </ul>
                </Item>
                <Item>
                  <Header size="small" content="Key Features" />
                  <ul>
                    <li>
                      <b>Investment returns:</b> Actual portfolios are used to
                      estimate returns; users are not asked to provide an
                      expected rate of return.
                    </li>
                    <li>
                      <b>Investment risk: </b>Since portfolios have uncertain
                      outcomes, three figures are presented for{' '}
                      <b>Target Wealth</b> and for <b>Target Saving</b>. They
                      correspond to different likelihoods of success (50%, 75%
                      and 90%), based on 10,000 simulations using historical
                      monthly returns.
                    </li>
                    <li>
                      <b>Spending needs in retirement: </b>Users can choose
                      different methods to calculate this amount, including one
                      using Statistics Canada’s Survey of Household Spending.
                    </li>
                    <li>
                      <b>Longevity risk: </b>Conditional mortality rates from
                      Statistics Canada based on current age and sex are used.
                      The probability of survival does not reach zero until age
                      110.
                    </li>
                    <li>
                      <b>CPP/QPP and OAS: </b>Calculators are provided to
                      estimate future benefits.
                    </li>
                  </ul>
                </Item>
                <Item>
                  <Header size="small" content="Target Wealth" />
                  <ul>
                    <li>
                      Defined as the present value of future spending needs in
                      retirement, taking into account longevity and investment
                      risks.
                    </li>
                    <li>
                      Simulate post-retirement investments using a Wealthscope
                      model portfolio or a fixed-rate security.
                    </li>
                    <li>
                      Spending needs in retirement stay constant in real terms
                      (i.e., increasing only by the rate of inflation). The
                      default rate of inflation is 2%.
                    </li>
                  </ul>
                </Item>
                <Item>
                  <Header size="small" content="Target Saving" />
                  <ul>
                    <li>
                      Defined as the amount of savings required per month (to be
                      added to a user’s pre-retirement portfolio) to reach the{' '}
                      <b>target wealth</b> at retirement.
                    </li>
                    <li>
                      Simulate pre-retirement investments using a Wealthscope
                      model portfolio or any portfolios saved from an actual
                      investment account or from our Portfolio Builders.
                    </li>
                  </ul>
                </Item>
                <Item>
                  <Header size="small" content="Important Note" />
                  <ul>
                    <li>
                      Users may wish to revisit the Blueprint at a later date as
                      their <b>personal details</b> change, and/or as their
                      portfolio’s return history lengthens.
                    </li>
                  </ul>
                </Item>
              </Assumptions>
            </CollapseWrapper>
          </div>
        </div>
        <NavigationButtons
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
          handleSubmit={this.handleSubmit}
          showPreviousButton={!hidePreviousButton}
          nextButtonSubmits={nextButtonSubmits}
        />
      </BlueprintContent>
    );
  };

  renderIndivCoupleChoicePage = (
    headerTitle,
    subSectionStyles,
    nextButtonSubmits = false,
    hidePreviousButton = false
  ) => {
    const { data, match } = this.props;
    const { is_couple_plan } = data.retirementBlueprint || {};
    const { errors, deleteWarningModalOpen } = this.state;

    return (
      <BlueprintContent>
        <Header textAlign="left" size="large" content={headerTitle} />
        <div style={subSectionStyles}>
          <div>
            <Form.Radio
              label="Individual"
              value="false"
              name="is_couple_plan"
              disabled={match.params.id !== 'new'}
              checked={
                is_couple_plan !== undefined && is_couple_plan === 'false'
              }
              onChange={this.handleChange}
            />
            <Form.Radio
              label="Couple"
              value="true"
              name="is_couple_plan"
              disabled={match.params.id !== 'new'}
              checked={is_couple_plan === 'true'}
              onChange={this.handleChange}
            />
            <ErrorLabel
              className="bottom"
              style={{ left: '10rem', top: '15rem', fontSize: '1.2rem' }}
              msg={errors.is_couple_plan}
            />
          </div>
        </div>
        <DeleteWarningModal
          isOpen={deleteWarningModalOpen}
          changeModalVisibility={this.setDeleteWarningModalVisibility}
          isSavedBlueprint={match.params.id !== 'new'}
        />
        <NavigationButtons
          handlePreviousPage={this.handlePreviousPage}
          handleNextPage={this.handleNextPage}
          handleSubmit={this.handleSubmit}
          showPreviousButton={!hidePreviousButton}
          nextButtonSubmits={nextButtonSubmits}
        />
      </BlueprintContent>
    );
  };

  render() {
    const { data, partnerName } = this.props;
    const subSectionStyles = {
      minHeight: '250px',
      marginTop: '2rem',
    };

    return data.retirementBlueprint.currentSubPage === 1
      ? this.renderIntroPage(
          'Retirement Blueprint',
          subSectionStyles,
          false,
          true
        )
      : this.renderIndivCoupleChoicePage(
          isGlobeInvestor(partnerName)
            ? 'Who do you want to create a roadmap for?'
            : 'Who do you want to create a blueprint for?',
          subSectionStyles,
          true,
          isGlobeInvestor(partnerName)
        );
  }
}

Overview.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  blueprintDeleteWarningSuppress: PropTypes.bool.isRequired,
  partnerName: PropTypes.string,
  cacheInputChanged: PropTypes.bool,
};

Overview.defaultProps = {
  dispatch: () => false,
  data: {},
  match: {},
  history: {},
  partnerName: '',
  cacheInputChanged: false,
};

export default connect((state) => ({
  blueprintDeleteWarningSuppress: state.Storage.blueprintDeleteWarningSuppress,
  partnerName: state.Storage['partner-name'],
  cacheInputChanged: state.Storage.cacheInputChanged,
}))(Overview);

const BlueprintContent = styled.div`
  width: 100%;
  text-align: left;
  padding: 2.5rem;
  min-height: 350px;

  .radio > label {
    font-size: 1.2rem !important;
  }
  .radio {
    margin: 0.5rem 2rem;
  }
`;

const CollapseWrapper = styled.div`
  position: relative;
  width: 80%;
  padding: ${(props) => (props.open ? '0rem 1rem 0' : '2rem 1rem 0')};
  margin: 2rem 2rem 2rem 0;
  border: ${(props) =>
    props.open ? `1px solid ${colors.blue}` : '1px solid white'};
  border-radius: 8px;
  overflow: ${(props) => (props.open ? 'visible' : 'hidden')};
  max-height: ${(props) => (props.open ? '2000px' : '28px')};
  transition: max-height 300ms ease, padding 300ms ease, border 300ms ease 300ms;
`;

const Item = styled.div`
  display: block;
  width: 100%;
  background: #f7f7f7;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0.5% 0;
  padding: 5px;
  border-radius: 4px;
`;

const Assumptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  text-align: left;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);

  .header {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isOpen ? colors.blue : 'white'} !important;
  color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
  border: ${(props) =>
    props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
  border-radius: 50% !important;
  height: 2rem;
  width: 2rem;
  transition: all 0.9s ease-in-out !important;
  font-size: 1.3rem !important;
`;
