/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { colors } from 'utils/colors';
import { ComparePortfolio, Analytics } from 'actions';
import { withRouter } from 'react-router-dom';
import { Loading, Button } from 'components';

import ExistingPortfolioSelection from './ExistingPortfolioSelection';
import WhatIfCustomization from './WhatIfCustomization';

import Intro from './Intro';
import Result from './Result';

class Compare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'Intro',
    };
  }

  componentDidMount() {
    const { comparePortfolioState } = this.props;
    if (comparePortfolioState.compareResult) {
      this.setState({ view: 'Result' });
    }
  }

  setView = (name) =>
    this.setState({
      view: name,
    });

  handleReset = () => {
    const { dispatch, history } = this.props;
    const portfolioRegex = /^\/portfolio\/.*$/;
    dispatch(ComparePortfolio.clearComparison());
    if (!portfolioRegex.test(history.location.pathname)) {
      dispatch(Analytics.clearAnalytics());
    }
    this.setView('Intro');
  };

  renderView() {
    const { dispatch } = this.props;
    const { view } = this.state;
    switch (view) {
      case 'Intro':
        return <Intro setView={this.setView} dispatch={dispatch} />;
      case 'ExistingPortfolioSelection':
        return <ExistingPortfolioSelection setView={this.setView} />;
      case 'WhatIfCustomization':
        return <WhatIfCustomization setView={this.setView} />;
      case 'Result':
        return <Result setView={this.setView} />;
      default:
        return <Loading fixed active />;
    }
  }

  render() {
    const { view } = this.state;

    return (
      <CompareContent>
        {view !== 'Intro' ? (
          <Popup
            position="left center"
            trigger={
              <Button
                className="left reset button"
                color={colors.blue}
                icon="refresh"
                onClick={this.handleReset}
              >
                Start over
              </Button>
            }
            content="Clear & Restart"
          />
        ) : null}

        {this.renderView()}
      </CompareContent>
    );
  }
}

Compare.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
  comparePortfolioState: PropTypes.object.isRequired,
};

Compare.defaultProps = {
  dispatch: () => null,
  history: {},
};

export default withRouter(
  connect((state) => ({ comparePortfolioState: state.ComparePortfolio }))(
    Compare
  )
);

const CompareContent = styled.div`
  position: relative;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  min-height: 400px;
  padding-top: 4rem;

  button.reset {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1;
  }
`;
