import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { CompareAccount } from 'actions';
import { ErrorLabel } from 'components';

// The 'editable' interest-rate column int the GIC table's 'interest rate' column.
class InterestRate extends Component {
  state = {
    focused: false,
    triggered: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = (e) => {
    const { dispatch, ticker, value } = this.props;

    // Firefox issue
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return false;
    }

    if (value === '') {
      dispatch(CompareAccount.updateInterestRate(ticker, 0));
    }

    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;

    e.target.value = '';

    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    const valueSplit = value.split('.');
    // eslint-disable-next-line prefer-const
    let [integer, fraction] =
      valueSplit.length >= 2 ? valueSplit : [valueSplit[0], ''];

    // ex. 1.155555 -> 1.15
    fraction = fraction.substring(0, 2);

    let num = Number(`${integer}.${fraction}`);
    if (num > 100) num = 100;

    return dispatch(
      CompareAccount.updateInterestRate(
        name,
        value === '' ? '' : (num / 100).toFixed(4)
      )
    );
  };

  focus = () => {
    const { value, ticker, dispatch } = this.props;

    if (!value) {
      dispatch(CompareAccount.updateInterestRate(ticker, ''));
    }

    return this.setState({ focused: true, triggered: true });
  };

  render() {
    const { ticker, value } = this.props;
    const { focused, triggered } = this.state;

    return !focused ? (
      <Value onClick={this.focus}>
        {value}
        <ErrorLabel
          msg={!value && !triggered ? 'Update Interest Rate' : null}
        />{' '}
        %
      </Value>
    ) : (
      <Input
        name={ticker}
        size="mini"
        type="number"
        value={value}
        onChange={this.handleChange}
        style={{ maxWidth: 80 }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    );
  }
}

InterestRate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticker: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect()(InterestRate);

const Value = styled.span`
  position: relative;
  cursor: pointer;
  color: #2185d0;
  padding: 11px 5px;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;
