import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal } from 'components';
import OasEstimator from './OasEstimator';

const OasModal = ({ isOpen, toggle, updateOasInfo }) => (
  <Modal open={isOpen} dimmerClick={() => toggle(false)}>
    <Modal.Content style={{ position: 'relative' }}>
      <CloseIcon onClick={() => toggle(false)}>+</CloseIcon>

      <OasEstimator toggle={toggle} updateOasInfo={updateOasInfo} />
    </Modal.Content>
  </Modal>
);

OasModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  updateOasInfo: PropTypes.func.isRequired,
};

export default OasModal;

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;
