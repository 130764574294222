import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header as SemanticHeader, Flag, Popup, Icon } from 'semantic-ui-react';
import { Segment } from 'components';
import { colors } from 'utils/colors';
import cn from 'classnames';

@withRouter
@connect((state) => ({
  overview: state.Accounts.overview,
  partnerName: state.Storage['partner-name'],
  is_wealthica_trial: state.Storage.is_wealthica_trial,
  wealthica_plan: state.Storage.wealthica_plan,
  user_region: state.Storage.user_region,
}))
class InfoPanel extends Component {
  socialMediaIcon = (name) => (
    <Icon className={cn(name)} style={{ color: colors.blue }} />
  );
  showLabel = (labelId) => {
    const label = document.getElementById(labelId);
    label.style.display = 'inline-block';
    setTimeout(() => {
      label.style.display = 'none';
    }, 3000);
  };

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  render() {
    const { user_region, overview } = this.props;
    return (
      <Segment
        style={{
          backgroundColor: `rgb(108, 135, 252)`,
          height: '30%',
          minHeight: '11.5rem',
          margin: '0px 1rem 0 1rem',
        }}
      >
        <div>
          <SemanticHeader
            style={{
              fontSize: '1.5rem',
              color: 'white',
              display: 'block',
              margin: '0rem',
              lineHeight: '1rem',
            }}
            content="Welcome to"
          />
          <SemanticHeader
            style={{
              fontSize: '3.5rem',
              color: 'white',
              display: 'block',
              margin: '0rem',
              lineHeight: '4rem',
            }}
            content={`Wealthscope`}
          />
          <SemanticHeader
            style={{
              fontSize: '1rem',
              color: 'white',
              display: 'block',
              margin: '0rem',
            }}
            content="Here are your stats."
          />
        </div>
        <Aggregates left="25%" top="10%">
          <SemanticHeader size="small">
            Total Account Value{' '}
            {user_region !== 'US' && (
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'white',
                    }}
                  />
                }
                position="top center"
                content="USD accounts are converted to CAD in the total."
              />
            )}
          </SemanticHeader>
          <SemanticHeader
            style={{ fontSize: '2.5rem', display: 'inline-block' }}
            content={`$${this.parseNum(overview.balance)}`}
          />
          <Flag
            name={user_region === 'CA' ? 'ca' : 'us'}
            style={{ position: 'relative', bottom: '7px', marginLeft: 7 }}
          />
          <SemanticHeader size="small" content="Daily Change" />
          <SemanticHeader
            style={{ fontSize: '2.5rem' }}
            size="huge"
            content={`+ 5%`}
          />
        </Aggregates>
        <Aggregates left="65%" top="8%">
          <SemanticHeader size="small" content="Portfolios" />
          <SemanticHeader content={`10`} />
          <div style={{ opacity: '1' }}>
            <SemanticHeader size="small" content="Retirement Blueprints" />
            <SemanticHeader content={`4`} />
            <SemanticHeader size="small" content="Drawdown Plans" />
            <SemanticHeader content={`3`} />
          </div>
        </Aggregates>
      </Segment>
    );
  }
}

export default InfoPanel;

InfoPanel.propTypes = {
  history: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  is_wealthica_trial: PropTypes.bool,
  wealthica_plan: PropTypes.string,
  user_region: PropTypes.string,
  overview: PropTypes.object.isRequired,
};

InfoPanel.defaultProps = {
  partnerName: '',
  is_wealthica_trial: false,
  wealthica_plan: '',
};

const BottomP2 = styled.p`
  margin-top: 0 !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 1rem;
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 750px;

  &.strike {
    text-decoration: line-through;
  }
`;

const SocialMediaList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  margin-bottom: 0;
  text-decoration: none;
  list-style-type: none;
  padding: 0;
  transition: font-size 0.3s;

  li {
    display: inline-block;
    cursor: pointer;
    position: relative;

    :not(:last-child) {
      margin-right: 1rem;
    }

    a i.icon,
    a i.icons {
      font-size: 1.8rem !important;
      transition: transform 0.3s;
      z-index: 1;
    }

    a:hover i.icon,
    a:hover i.icons {
      opacity: 70%;
      transform: scale(1.2);
    }
  }

  .ui.label {
    position: absolute;
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: none;
  }
`;

const Aggregates = styled.div`
  position: absolute;
  display: inline-block;
  margin: 0% 10%;
  top: ${(props) => props.top};
  left: ${(props) => props.left};

  .header {
    color: white;
    margin: 0;
  }
`;
