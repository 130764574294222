import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { notWealthica, isWealthica, isGlobeInvestor } from 'utils/helpers';
import { colors, nbColors } from 'utils/colors';
import { PORTFOLIOS, PLANNING, TOOLS } from 'utils/features';
import { Feature, HeadwayWidget } from '../../components';
import ChatbotNav from './ChatbotNav';
import { isNB, translateText } from '../../utils/helpers';

class NavBar extends Component {
  getUrl = (module) => {
    const { location, cacheUrl, featureRestrictions } = this.props;
    let landingUrl = module === 'accounts' ? '/accounts' : `/${module}/landing`;

    if (module === 'planning') {
      landingUrl = featureRestrictions.includes('PLANNING_DECUMULATION')
        ? `/${module}/accumulation`
        : `/${module}/landing`;
    }

    return location.pathname.includes(module)
      ? landingUrl
      : cacheUrl[module] || landingUrl;
  };

  render() {
    const { match, location, partnerName, french } = this.props;

    // bookmark
    return (
      <Menu
        hidden={
          match.isExact ||
          location.pathname.includes('pdf') ||
          location.pathname.includes('dashboard') ||
          isGlobeInvestor(partnerName)
        }
      >
        {/* <SubMenu single>
          <Tab
            top
            active={window.location.pathname.split('/')[1] === 'dashboard'}
          >
            <NavLink to="/dashboard">Dashboard</NavLink>
          </Tab>
        </SubMenu> */}
        {isGlobeInvestor(partnerName) ||
        partnerName === 'nbdb_sandbox' ||
        partnerName === 'nbdb' ? null : (
          <SubMenu>
            <Tab
              top
              active={window.location.pathname.indexOf('accounts') !== -1}
            >
              <NavLink to={this.getUrl('accounts')}>
                {french ? 'Comptes' : 'Accounts'}
              </NavLink>
            </Tab>
            <Tab
              top
              submenu
              active={window.location.pathname.indexOf('user') !== -1}
            >
              <NavLink to="/accounts/user">
                {french ? 'Mes Comptes' : 'My Accounts'}
              </NavLink>
            </Tab>
          </SubMenu>
        )}

        {(partnerName === 'nbdb_sandbox' || partnerName === 'nbdb') && (
          <SubMenu>
            <Tab
              isNB
              top
              active={window.location.pathname.indexOf('user') !== -1}
            >
              <NavLink to="/accounts/user">
                {french ? 'Mes Comptes' : 'My Accounts'}
              </NavLink>
            </Tab>
          </SubMenu>
        )}

        <Feature name={PORTFOLIOS}>
          <SubMenu single>
            <Tab
              top
              active={window.location.pathname.split('/')[1] === 'portfolio'}
            >
              <NavLink to={this.getUrl('portfolio')}>Portfolios</NavLink>
            </Tab>
          </SubMenu>
        </Feature>

        <Feature name={PLANNING}>
          <SubMenu single>
            <Tab
              top
              active={window.location.pathname.split('/')[1] === 'planning'}
            >
              <NavLink to={this.getUrl('planning')}>Planning</NavLink>
            </Tab>
          </SubMenu>
        </Feature>

        <Feature name={TOOLS}>
          <SubMenu single>
            <Tab
              top
              active={window.location.pathname.split('/')[1] === 'tools'}
            >
              <NavLink to={this.getUrl('tools')}>Tools</NavLink>
            </Tab>
          </SubMenu>
        </Feature>
        {isNB(partnerName) && (
          <SubMenu single style={{ float: 'right', width: '60px' }}>
            <Popup
              trigger={
                <Tab style={{ width: '60px', alignSelf: 'center' }}>
                  <a
                    href={
                      french
                        ? '/User_Guide_NBDB_Fr_Dec_5_2024.pdf'
                        : '/User_Guide_NBDB_En_Dec_5_2024.pdf'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      name="book"
                      style={{
                        fontSize: '28px',
                        color: nbColors.blue,
                        margin: '0 0 0 0',
                      }}
                    />
                  </a>
                </Tab>
              }
              position="top center"
              style={{ marginRight: '10px' }}
            >
              {translateText('Go to User Guide', french)}
            </Popup>
          </SubMenu>
        )}
        {notWealthica(partnerName) &&
        partnerName !== 'nbdb_sandbox' &&
        partnerName !== 'nbdb' ? (
          <div>
            <SubMenu single style={{ float: 'right' }}>
              <Popup
                trigger={
                  <Tab top title="Step-by-step Guide">
                    <a
                      href="http://wealthscope.ca/getting-started-ci"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        name="book"
                        style={{
                          fontSize: '28px',
                          color: colors.blue,
                          transform: 'translateY(5px)',
                        }}
                      />
                    </a>
                  </Tab>
                }
                position="top center"
                wide
              >
                Getting Started Guide
              </Popup>
            </SubMenu>
          </div>
        ) : partnerName !== 'nbdb_sandbox' && partnerName !== 'nbdb' ? (
          <RightIcons>
            {isWealthica(partnerName) ? <ChatbotNav /> : null}
            <Popup
              trigger={
                <IconLink
                  href="https://www.wealthscope.ca/getting-started-wealthica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    name="book"
                    style={{
                      fontSize: '28px',
                      color: colors.blue,
                      transform: 'translateY(5px)',
                    }}
                  />
                </IconLink>
              }
              position="top center"
              wide
            >
              Getting Started Guide
            </Popup>

            <Popup
              trigger={
                <IconLink href="mailto:contact@wealthscope.ca" target="_blank">
                  <Icon
                    name="mail"
                    style={{
                      fontSize: '28px',
                      color: colors.orange,
                      transform: 'translateY(5px)',
                    }}
                  />
                </IconLink>
              }
              position="top center"
              wide
            >
              Contact Us
            </Popup>
          </RightIcons>
        ) : null}
        {partnerName !== 'nbdb_sandbox' && partnerName !== 'nbdb' && (
          <Updates id="updates" style={{ cursor: 'pointer' }}>
            Updates
            <HeadwayWidget />
          </Updates>
        )}
      </Menu>
    );
  }
}

NavBar.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  partnerName: PropTypes.string,
  cacheUrl: PropTypes.object.isRequired,
  featureRestrictions: PropTypes.array,
  french: PropTypes.bool,
};

NavBar.defaultProps = {
  match: {},
  location: {},
  partnerName: '',
  featureRestrictions: [],
  french: false,
};

export default withRouter(
  connect((state) => ({
    cacheUrl: state.CacheInputs.cacheUrl,
    featureRestrictions: state.Storage['feature-restrictions'],
    french: state.Storage.language === 'fr',
  }))(NavBar)
);

const Menu = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  height: 60px;
  width: 100%;
  font-size: 0.9rem;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 101;
  border-bottom: 1px solid #cbcdd0;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
`;

const SubMenu = styled.div`
  float: left;
  background: white;
  width: 15%;
  max-width: 15rem;

  height: 60px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  z-index: 2;
  border-bottom: 1px solid #cbcdd0;

  &:hover {
    height: ${(props) => (props.single ? '60px' : 'auto')};
  }

  @media only screen and (max-width: 1100px) {
    width: 100px !important;
  }
`;

const Tab = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
  text-align: center;
  border-bottom: 1px solid rgb(236, 236, 240);
  border-left: 1px solid #cbcdd0;
  border-right: 1px solid #cbcdd0;
  position: relative;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${colors.grey};
  line-height: 60px;
  transition: all 0.1s ease;

  &:first-child {
    font-size: 0.95rem;
    border: 0;
    border-bottom: 1px solid rgb(236, 236, 240);
  }

  &:last-child {
    border-bottom: none;
  }

  ${(props) =>
    props.left &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 60px;
        background: transparent;
      }
    `}

  ${(props) =>
    props.top &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: transparent;
      }
    `}

  ${(props) =>
    props.active &&
    !props.submenu &&
    css`
      color: ${colors.black};
      font-weight: 700;
      border-left: none;

      &:after {
        background: ${props.isNB ? nbColors.red : colors.red};
      }
    `}

  ${(props) =>
    props.active &&
    props.submenu &&
    css`
      color: ${colors.black};
      font-weight: 700;
      border-left: none;
    `}
  

  &:hover {
    color: ${colors.black};
  }

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: inherit;
    margin: 0;
    padding: 0;
  }
`;

const RightIcons = styled.div`
  float: right;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 12rem;
  height: 100%;
  margin-right: 1rem;
`;

const IconLink = styled.a`
  color: inherit;
`;

const Updates = styled.div`
  display: block;
  color: ${colors.grey};
  line-height: 4rem;
  text-transform: uppercase;
  background: white;
  float: left;
  width: 13rem;
  height: 100%;
  text-align: center;
  position: relative;
`;
