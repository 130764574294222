import { StockExposure } from 'actions';

const initialState = {
  isFetching: false,
  security: null,
  search: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StockExposure.STOCKEXPOSURE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case StockExposure.STOCKEXPOSURE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        security: action.data,
      };
    case StockExposure.STOCKEXPOSURE_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        search: action.data,
      };
    case StockExposure.STOCKEXPOSURE_PREV_SEARCH:
      return {
        ...state,
        prevSearch: action.ticker,
      };
    case StockExposure.STOCKEXPOSURE_CLEAR:
      return initialState;
    case StockExposure.STOCKEXPOSURE_FAIL:
      return initialState;
    default:
      return state;
  }
}
