import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, Icon } from 'semantic-ui-react';
import { Table, Message } from 'components';
import { chartingToolColorsLessOpacity } from 'utils/colors';

export default class Diversification extends Component {
  getLongestText = (obj) =>
    Object.keys(obj).reduce((acc, key) => {
      const name = this.getName(key);

      if (name.length > acc.length) {
        acc = name;
      }

      return acc;
    }, '');

  getName = (ticker) => {
    const { correlationData } = this.props;

    return Object.keys(correlationData.corr);
  };

  parseDate = (portfolio) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  parseCorrDate = (correlationData) => {
    const init = correlationData.init_date.split('-');
    const end = correlationData.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  formatNum = (num) => (num ? num.toFixed(2) : null);

  renderMatrix = (correlationData) => (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      <TableWrapper>
        <Table celled definition toolset textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              {Object.keys(correlationData.corr).map((key, index) => (
                <Table.HeaderCell
                  key={key}
                  style={{
                    backgroundColor: Object.values(
                      chartingToolColorsLessOpacity
                    )[index],
                    color: 'black',
                  }}
                >
                  {correlationData.included[key].name}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Object.keys(correlationData.corr).map((key, index) => (
              <Table.Row key={key}>
                <Table.Cell
                  style={{
                    backgroundColor: Object.values(
                      chartingToolColorsLessOpacity
                    )[index],
                    color: 'black',
                  }}
                >
                  {correlationData.included[key].name}
                </Table.Cell>
                {
                  /* eslint-disable */
                  Object.values(correlationData.corr[key]).map((value, idx) => (
                    <Table.Cell key={idx}>
                      {value === 0 ? '-' : this.formatNum(value)}
                    </Table.Cell>
                  ))
                  /* eslint-enable */
                }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </TableWrapper>
    </div>
  );

  renderPairs = (data) => {
    const parsedData = Object.keys(data.corr).reduce((total, key) => {
      Object.keys(data.corr[key]).forEach((key2) => {
        if (
          data.corr[key][key2] &&
          data.corr[key][key2] < 1 &&
          data.corr[key][key2] > 0.8
        ) {
          if (!total[key]) {
            total[key] = [
              {
                name: data.tickersValid[key].name,
                ticker: key,
                matchTicker: key2,
                matchName: data.tickersValid[key2].name,
                corr: data.corr[key][key2],
                rowSpan: 1,
              },
            ];
          } else {
            total[key][0].rowSpan += 1;
            total[key].push({
              name: data.tickersValid[key].name,
              ticker: key,
              matchTicker: key2,
              matchName: data.tickersValid[key2].name,
              corr: data.corr[key][key2],
              exclude: true,
            });
          }
        }
      });

      return total;
    }, {});

    const filtered = Object.values(parsedData).reduce((total, value, index) => {
      if (value.length === 1) {
        total.push({ key: index, item: value[0] });
      } else {
        value.forEach((item, idx) =>
          total.push({ key: `${index}${idx}`, item })
        );
      }

      return total;
    }, []);

    return (
      <div style={{ textAlign: 'center' }}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Asset</Table.HeaderCell>
              <Table.HeaderCell>Asset</Table.HeaderCell>
              <Table.HeaderCell>Correlation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filtered.length ? (
              filtered.map((item) => (
                <Table.Row key={item.key}>
                  {!item.item.exclude && (
                    <Table.Cell
                      rowSpan={
                        item.item.rowSpan > 1 ? item.item.rowSpan : undefined
                      }
                      width={4}
                    >
                      {item.item.ticker}
                      <SecurityName>{item.item.name}</SecurityName>
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    {item.item.matchTicker}
                    <SecurityName>{item.item.matchName}</SecurityName>
                  </Table.Cell>
                  <Table.Cell>
                    {Math.round(item.item.corr * 100) / 100}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell>N/A</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  render() {
    const { correlationData, hasErrorMessage } = this.props;

    if (
      !correlationData.corr ||
      Object.keys(correlationData.corr).length === 0 ||
      !correlationData.init_date ||
      !correlationData.end_date
    ) {
      return (
        <Message warning>
          <Message.Header>Insufficient Data</Message.Header>
          <p>There is no correlation data available to display.</p>
        </Message>
      );
    }
    return (
      <Container>
        <DateContainer hasErrorMessage={hasErrorMessage}>
          <Note>
            {this.parseCorrDate(correlationData)} (inclusive){' '}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ verticalAlign: 'initial' }}
                />
              }
              position="right center"
              wide
              content="Earliest common start date to the present."
            />
          </Note>
        </DateContainer>
        <div
          className="myDiv"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {Object.keys(correlationData.corr).length < 15
            ? this.renderMatrix(correlationData)
            : this.renderPairs(correlationData)}
        </div>
      </Container>
    );
  }
}

Diversification.propTypes = {
  correlationData: PropTypes.object.isRequired,
  hasErrorMessage: PropTypes.bool.isRequired,
};

const TableWrapper = styled.div`
  .table {
    display: table-caption;
  }
  tr,
  th,
  td {
    min-height: 150px !important;
    min-width: 150px;
    vertical-align: middle;
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Container = styled.div`
  min-height: 100%;
`;

const Note = styled.div`
  font-size: 1.14rem;
  color: black;
  margin: 1rem 0 3rem 0;
`;

const DateContainer = styled.div`
  margin-top: ${(props) => (props.hasErrorMessage ? '3rem' : '0')};
`;
