import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'semantic-ui-react';

import { ErrorLabel } from 'components';

class TotalWeight extends Component {
  getMsg = (value) => {
    const remainder = Math.round((100 - value) * 100) / 100;

    return remainder > 0
      ? `(${remainder}% under)`
      : `(${Math.abs(remainder)}% over)`;
  };

  render() {
    const { value } = this.props;

    return (
      <Statistic
        size="small"
        color={value !== 100 ? 'red' : 'green'}
        style={{ position: 'relative' }}
      >
        <Statistic.Value>{value}%</Statistic.Value>
        <Statistic.Label>TOTAL WEIGHT</Statistic.Label>

        <ErrorLabel
          className="bottom"
          msg={
            value !== 100 ? (
              <div>
                Total Weight must equal 100%
                <div style={{ marginTop: '0.4rem' }}>{this.getMsg(value)}</div>
              </div>
            ) : null
          }
        />
      </Statistic>
    );
  }
}

export default TotalWeight;

TotalWeight.propTypes = {
  value: PropTypes.number.isRequired,
};
