import { request } from './api';
import * as Alert from './alert';

/**
 * Action constants
 */
export const CORRELATION_DATA_REQUEST = 'CORRELATION_DATA_REQUEST';

export const CORRELATION_DATA_FETCH_SUCCESS = 'CORRELATION_DATA_FETCH_SUCCESS';

export const CORRELATION_UPDATE_INPUTS = 'CORRELATION_UPDATE_INPUTS';

export const CORRELATION_FAIL = 'CORRELATION_FAIL';

export const CORRELATION_DATA_CLEAR = 'CORRELATION_DATA_CLEAR';

export const CORRELATION_PARTIAL_FAIL = 'CORRELATION_PARTIAL_FAIL';

/**
 * Action creators
 */
export function fetchCorrelationParams(allNamesList) {
  return (dispatch) => {
    dispatch({ type: CORRELATION_DATA_REQUEST });
    const params = {
      symbols: allNamesList,
    };

    return dispatch(
      request('post', '/portfolio/correlation/', { body: params })
    ).then(
      (data) => {
        if (!Object.keys(data).length || data.detail !== undefined) {
          return dispatch({
            type: CORRELATION_DATA_FETCH_SUCCESS,
            data,
          });
        }

        return dispatch({
          type: CORRELATION_DATA_FETCH_SUCCESS,
          data,
        });
      },
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: CORRELATION_PARTIAL_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function updateInputs(inputs) {
  return (dispatch) => {
    dispatch({ type: CORRELATION_UPDATE_INPUTS, inputs });
  };
}

export function clearCorrelationData() {
  return (dispatch) => {
    dispatch({ type: CORRELATION_DATA_CLEAR });
  };
}
