import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header } from 'semantic-ui-react';

import { colors } from 'utils/colors';

class ObjectiveSummary extends Component {
  getChartData = (data) => ({
    labels: this.convertData(data).values.map((value) =>
      value.toLocaleString()
    ),
    datasets: [
      {
        backgroundColor: 'rgb(210,219,231)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgb(193,213,251)',
        data: [...this.convertData(data).values],
      },
    ],
  });

  convertData = (data) =>
    Object.keys(data).reduce(
      (total, key, index) => {
        const lookup = {
          none: 'None',
          low: 'Low',
          med: 'Medium',
          high: 'High',
          no: 'No',
          yes: 'Yes',
          'N/A': 'N/A',
          energy: 'Energy',
          infotech: 'IT',
          fin: 'Financial',
          materials: 'Materials',
          allin: 'All in',
          adventurous: 'Adventurous',
          classic: 'Classic',
          cautious: 'Cautious',
          safe: 'Playing it Safe',
          usDevEmer: 'US, Developed & Emerging Markets',
          usDev: 'US and Developed Markets',
          usEmer: 'US and Emerging Markets',
          devEmer: 'Developed & Emerging Markets',
          emer: 'Emerging Markets',
          dev: 'Developed Markets',
          us: 'United States',
        };
        total.values.push({ key: index, value: data[key][1] });
        total.objectives.push({ key: index, title: lookup[data[key][0]] });

        return total;
      },
      { values: [], objectives: [] }
    );

  render() {
    const { data, ratios } = this.props;

    return (
      <Container>
        <Header size="medium">
          <Header.Content>
            Selection Summary
            <Header.Subheader>
              Of the{' '}
              <span style={{ color: colors.blue }}>
                {this.convertData(data).values[5].value.toLocaleString()}
              </span>{' '}
              matching portfolios, your portfolio below has the highest average
              ranking in the following three risk-adjusted ratios:
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Field>
          <Number>{ratios.sharpe}</Number>
          <Description>Sharpe Ratio</Description>
        </Field>
        <Field>
          <Number>{ratios.roys}</Number>
          <Description short>Roy&#39;s Safety First Criterion</Description>
        </Field>
        <Field>
          <Number>{ratios.sortino}</Number>
          <Description short>Sortino Ratio</Description>
        </Field>
        <Field>
          <Number>24,479,112</Number>
          <Description>Total number of portfolios in our database</Description>
        </Field>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'inline-block' }}>
            <Header
              size="small"
              content="Your Objective"
              style={{ margin: '0 0 0 2.4rem' }}
            />
            <div style={{ float: 'left', paddingTop: '1rem', width: '12rem' }}>
              <P>Risk profile</P>
              <P>Exclude sectors</P>
              <P>Exposure to oil</P>
              <P>Exposure to int. rates</P>
              <P>Exclude REITs</P>
              <P>Foreign market exposure</P>
            </div>
            <div
              style={{
                display: 'inline-block',
                width: '12rem',
                borderLeft: '1px solid lightgrey',
                borderRight: '1px solid lightgrey',
                paddingTop: '1rem',
                float: 'left',
              }}
            >
              {this.convertData(data).objectives.map((item) => (
                <P key={item.key}>{item.title}</P>
              ))}
            </div>
            <div
              style={{
                display: 'inline-block',
                width: '9rem',
                paddingTop: '1rem',
                float: 'left',
              }}
            >
              <Header
                size="small"
                content="# of matching portfolios"
                style={{
                  margin: 0,
                  position: 'absolute',
                  top: 0,
                  left: '24.5rem',
                }}
              />
              {Object.keys(data).map((key, idx) => (
                <P key={key} className={idx === 5 ? 'highlighted' : ''}>
                  {data[key][1].toLocaleString()}
                </P>
              ))}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

ObjectiveSummary.propTypes = {
  data: PropTypes.object.isRequired,
  ratios: PropTypes.object.isRequired,
};

export default ObjectiveSummary;

const P = styled.p`
  margin: 0;
  color: dimgrey;
  height: 2rem;
  line-height: 1rem;
  font-size: 0.9rem;
  text-align: center;

  &.highlighted {
    color: ${colors.blue};
  }
`;

const Field = styled.div`
  display: inline-block;
  margin: 0 2rem 2rem 0;
  vertical-align: top;

  &.total {
    position: absolute;
    bottom: 2rem;
    right: 10rem;
  }
`;

const Number = styled.div`
  font-size: 2rem;
  margin: 10px 0;
`;

const Description = styled.p`
  font-size: 12px;
  max-width: ${(props) => (props.short ? '6rem' : '10rem')};
  color: dimgrey;
  line-height: 12px;
`;

const Container = styled.div`
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
`;
