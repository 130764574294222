import * as Alert from './alert';
import { request } from './api';

/**
 * Action constants
 */
export const SECURITY_REQUEST = 'SECURITY_REQUEST';

export const SECURITY_FETCH_SUCCESS = 'SECURITY_FETCH_SUCCESS';
export const SECURITY_SEARCH_SUCCESS = 'SECURITY_SEARCH_SUCCESS';

export const SECURITY_CLEAR = 'SECURITY_CLEAR';
export const SECURITY_FAIL = 'SECURITY_FAIL';

/**
 * Action creators
 */
export function fetch(id) {
  return (dispatch) => {
    dispatch({ type: SECURITY_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) => dispatch({ type: SECURITY_FETCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: SECURITY_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: SECURITY_REQUEST });

    return dispatch(
      request(
        'get',
        `/security/search?term=${id}&type=MutualFund&type=ETF&region=${region}`
      )
    ).then(
      (data) => dispatch({ type: SECURITY_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: SECURITY_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function searchAll(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: SECURITY_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: SECURITY_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: SECURITY_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function clearSecurity() {
  return (dispatch) => dispatch({ type: SECURITY_CLEAR });
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
