import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, Form, Header } from 'semantic-ui-react';
import cn from 'classnames';

import { Analytics, CompareAccount } from 'actions';
import { colors } from 'utils/colors';
import Button from './../Button';
import TotalWeight from './TotalWeight';
import SectorWeight from './SectorWeight';

@withRouter
class AllocateWeights extends Component {
  componentDidMount = () => {
    const { selectedAssets, updateWeight } = this.props;

    if (selectedAssets && Object.keys(selectedAssets).length === 1) {
      updateWeight(selectedAssets[Object.keys(selectedAssets)[0]].symbol, 100);
    }
  };

  handleChange = (e, { name, value }) => this.props.updateWeight(name, value);

  handleAnalyticsSubmit = () => {
    const {
      close,
      dispatch,
      location,
      selectedAssets,
      pwpaPortfolio,
      pwpaCheckHistory,
      user_region,
      toggle_region,
    } = this.props;
    const checkHistory = pwpaCheckHistory === null ? false : !pwpaCheckHistory;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );
    const updatedPortfolio = {
      ...pwpaPortfolio,
      custom_bm: {
        symbols: Object.keys(selectedAssets).join(','),
        weights: benchmarkWeights.join(','),
      },
    };

    dispatch(Analytics.updateParameter('benchmark', 'custom'));
    if (toggle_region === 'US') {
      dispatch(
        Analytics.fetchParamsUs(
          updatedPortfolio,
          location.state,
          checkHistory,
          user_region
        )
      );
    } else {
      dispatch(
        Analytics.fetchParams(
          updatedPortfolio,
          location.state,
          checkHistory,
          user_region
        )
      );
    }

    return close();
  };

  handleCompareSubmit = () => {
    const { close, dispatch, selectedAssets, compareAcc, user_region } =
      this.props;
    const { holdings, original, gic_info } = compareAcc;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );

    dispatch(CompareAccount.updateBenchmark('custom'));
    dispatch(
      CompareAccount.fetchCompare(
        original.holdings,
        holdings,
        original.gic_info,
        gic_info,
        user_region,
        {
          symbols: Object.keys(selectedAssets).join(','),
          weights: benchmarkWeights.join(','),
        } // the custom benchmark
      )
    );

    return close();
  };

  calcTotal = () => {
    const { selectedAssets } = this.props;

    return Object.values(selectedAssets).reduce((total, value) => {
      total += Number(value.weight);

      return Math.round(total * 100) / 100;
    }, 0);
  };

  calcSectorWeight = (type) =>
    Object.values(this.props.selectedAssets).reduce((total, value) => {
      if (value.assetclass === type) {
        total += Number(value.weight);
      }

      return Math.round(total * 100) / 100;
    }, 0);

  isDisabled = (assetclass) =>
    !Object.values(this.props.selectedAssets).filter(
      (value) => value.assetclass === assetclass
    ).length;

  renderAssets = (type) =>
    Object.values(this.props.selectedAssets).map(
      (value) =>
        value.assetclass === type && (
          <Form.Field
            key={value.symbol}
            inline
            style={{ margin: '0 0 0.2em', position: 'relative' }}
          >
            <Input
              name={value.symbol}
              type="number"
              size="mini"
              label={{ basic: true, content: '%' }}
              labelPosition="right"
              value={value.weight || ''}
              placeholder="0"
              onChange={this.handleChange}
              style={{ maxWidth: 43, zIndex: 1 }}
            />
            <Label>{value.name}</Label>
          </Form.Field>
        )
    );

  render() {
    const { previousTab, feature } = this.props;

    return (
      <Form
        onSubmit={
          feature === 'compareAccounts'
            ? this.handleCompareSubmit
            : this.handleAnalyticsSubmit
        }
      >
        <Header
          size="medium"
          textAlign="center"
          content="Indicate Desired Weights"
          style={{ padding: '0 0 2rem 0' }}
        />

        <Segment
          basic
          compact
          responsive
          disabled={this.isDisabled('Fixed Income')}
        >
          <SectorWeight
            title="Bonds"
            value={this.calcSectorWeight('Fixed Income')}
          />
          {this.renderAssets('Fixed Income')}
        </Segment>
        <Segment basic compact responsive disabled={this.isDisabled('Equity')}>
          <SectorWeight
            title="Stocks"
            value={this.calcSectorWeight('Equity')}
          />
          {this.renderAssets('Equity')}
        </Segment>
        <Segment
          basic
          compact
          responsive
          disabled={this.isDisabled('Alternative')}
        >
          <SectorWeight
            title="Alternative"
            value={this.calcSectorWeight('Alternative')}
          />
          {this.renderAssets('Alternative')}
        </Segment>

        <div
          style={{
            clear: 'both',
            width: '100%',
            textAlign: 'center',
            padding: '1rem 0 6rem 0',
          }}
        >
          <TotalWeight value={this.calcTotal()} />
        </div>

        <Button
          className="left"
          color={colors.orange}
          icon="chevron left"
          onClick={previousTab}
        >
          Back
        </Button>
        <Button
          className={cn('right', { disabled: this.calcTotal() !== 100 })}
          color={colors.orange}
          icon="chevron right"
          floated="right"
        >
          Next
        </Button>
      </Form>
    );
  }
}

AllocateWeights.propTypes = {
  selectedAssets: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  previousTab: PropTypes.func.isRequired,
  updateWeight: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  compareAcc: PropTypes.object,
  feature: PropTypes.string,
  pwpaPortfolio: PropTypes.object,
  pwpaCheckHistory: PropTypes.bool,
  user_region: PropTypes.string,
  toggle_region: PropTypes.string,
};

AllocateWeights.defaultProps = {
  dispatch: () => false,
  compareAcc: {},
  feature: '',
  pwpaPortfolio: {},
  pwpaCheckHistory: null,
  user_region: 'CA',
  toggle_region: 'CA',
};

export default connect((state) => ({
  compareAcc: state.CompareAccount,
  pwpaPortfolio: state.Storage['pwpa-portfolio'],
  pwpaCheckHistory: state.Storage['pwpa-check-history'],
  user_region: state.Storage.user_region || 'CA',
  toggle_region: state.Storage.toggle_region || 'CA',
}))(AllocateWeights);

const Label = styled.label`
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  font-weight: 500 !important;
  line-height: 1rem;
  height: 2.2rem;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 6rem;
`;

const Segment = styled.div`
  display: block;
  width: 33%;
  float: left;
  margin: 1rem 0;
  padding: 1rem;

  @media only screen and (max-width: 850px) {
    width: 400px;
    margin: 1rem auto;
    float: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
      color: rgba(40, 40, 40, 0.3);
    `}
`;
