import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Radar } from 'react-chartjs-2';
import { nbGraph } from 'utils/colors';
import { Header } from 'semantic-ui-react';
import { Score } from 'components';
import { getOverAllScore, getOverAllGrade } from 'utils/helpers';
import Bar from './Bar';
import { translation } from '../localization';
import { translateBenchmarkDescription } from '../../../../../utils/helpers';

@connect((state) => ({
  initHasExcludedHolding: state.Storage['pwpa-has-exclude-holding'],
  checkHistory:
    state.Storage['pwpa-check-history'] === undefined
      ? true
      : state.Storage['pwpa-check-history'],
  partnerName: state.Storage['partner-name'],
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))
export default class Overview extends Component {
  getRadarData = (score) => {
    const { analytics, esg, french } = this.props;
    const { benchmark, esgToggle, income } = analytics;
    const { portfolio } = esg;
    const language = french ? 'fr' : 'en';

    const hash = () => ({
      Diversification: translation[language].DIVERSIFICATION,
      Fees: translation[language].LOW_FEES,
      Performance: translation[language].PERFORMANCE,
      ...(income && { Income: translation[language].INCOME }),
    });

    const data = Object.keys(hash()).reduce((total, key) => {
      total[hash()[key]] =
        key === ('Performance' || 'Volatility')
          ? score[key][benchmark][0]
          : score[key][0];

      return total;
    }, {});

    const labels = esgToggle
      ? ['Downside Protection', ...Object.keys(data), 'ESG']
      : ['Downside Protection', ...Object.keys(data)];

    const translatedLabels = labels.map((label) => {
      switch (label) {
        case 'Downside Protection':
          return translation[language].DOWNSIDE_PROTECTION;
        case 'ESG':
          return 'ESG';
        default:
          return label;
      }
    });

    return {
      labels: translatedLabels,
      datasets: [
        {
          backgroundColor: 'rgba(2,116,193,0.2)',
          borderColor: nbGraph.medium,
          pointBackgroundColor: nbGraph.medium,
          pointBorderColor: nbGraph.medium,
          lineTension: 0.3,
          data:
            portfolio && portfolio.ws_grade[0] && esgToggle
              ? [
                  score.Volatility[benchmark][0],
                  ...Object.values(data),
                  portfolio.ws_grade[0],
                ]
              : [score.Volatility[benchmark][0], ...Object.values(data)],
        },
      ],
    };
  };

  isLinked = (port) => {
    if (!port) {
      return false;
    }

    if (
      port.portfolio_type === 'custom' ||
      port.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return port.is_linked;
  };

  render() {
    const { analytics, esg, french } = this.props;
    const { esgToggle, income, benchmark, data, portfolioHistoryCompareto } =
      analytics;
    const { paramsAnalyzed } = data;
    const sortedHoldings = data.tickersOriginal.sort(
      (a, b) => b.weight - a.weight
    );
    const holdingPlaceholder =
      sortedHoldings.length < 20
        ? new Array(20 - sortedHoldings.length)
            .fill({})
            .map(() => ({ ticker: '', name: '' }))
        : [];
    const leftHoldings = sortedHoldings.concat(holdingPlaceholder).slice(0, 10);
    const rightHoldings = sortedHoldings
      .concat(holdingPlaceholder)
      .slice(10, 20);
    const rebalancingFrequency = paramsAnalyzed.rebalance_interval || 1;
    const language = french ? 'fr' : 'en';

    return (
      <div>
        <Container margin="2rem 3rem 2rem 19rem">
          <RightColumn>
            <Header
              size="medium"
              style={{ display: 'inline-block', margin: '0' }}
            >
              &nbsp;
            </Header>
            <Divider style={{ margin: '12px 0' }} />
            <Wrapper>
              {rightHoldings.map((val) => (
                <Bar
                  key={val.ticker}
                  ticker={this.isLinked(data.portfolio) ? val.ticker : ''}
                  name={this.isLinked(data.portfolio) ? val.name : val.wsName}
                  weight={val.weight !== undefined ? val.weight : null}
                  error={val.isExcluded}
                  replaced={val.replacedBy}
                />
              ))}
            </Wrapper>
          </RightColumn>
          <LeftColumn>
            <Header size="medium" style={{ position: 'relative' }}>
              {`${translation[language].PORTFOLIO_OVERVIEW} ${
                sortedHoldings.length > 20 ? ' - Top 20 holdings' : ''
              }`}
            </Header>

            <Divider style={{ margin: '12px 0' }} />

            <Wrapper>
              {leftHoldings.map((val) => (
                <Bar
                  key={val.ticker}
                  ticker={this.isLinked(data.portfolio) ? val.ticker : ''}
                  name={this.isLinked(data.portfolio) ? val.name : val.wsName}
                  weight={val.weight !== undefined ? val.weight : null}
                  error={val.isExcluded}
                  replaced={val.replacedBy}
                />
              ))}
            </Wrapper>
          </LeftColumn>

          <div style={{ marginTop: '5rem' }}>
            <Score
              score={getOverAllScore({
                income,
                benchmark,
                esgToggle,
                data,
                esg,
              })}
              grade={getOverAllGrade({
                income,
                benchmark,
                esgToggle,
                data,
                esg,
              })}
              portfolio={data.portfolio}
              pdf
            />

            <p style={{ fontSize: '1.15rem' }}>
              {translation[language].BENCHMARK}:&nbsp;
              {portfolioHistoryCompareto === 'market'
                ? translateBenchmarkDescription(
                    data.cumrets.market[`${benchmark}Desc`],
                    french
                  )
                : translateBenchmarkDescription(
                    data.cumrets.benchmark[`${benchmark}Desc`],
                    french
                  )}
            </p>
            <p style={{ fontSize: '1.15rem' }}>
              {translation[language].REBALANCING_FREQUENCY}:&nbsp;
              {rebalancingFrequency === 1
                ? translation[language].MONTHLY
                : rebalancingFrequency === 3
                ? translation[language].QUARTERLY
                : translation[language].ANNUALLY}
            </p>
            <div style={{ marginBottom: '1rem' }}>
              <Radar
                id="radarChart"
                data={this.getRadarData(data.score)}
                options={chartOptions}
                height="140px"
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

Overview.propTypes = {
  analytics: PropTypes.object,
  esg: PropTypes.object,
  french: PropTypes.bool,
};

Overview.defaultProps = {
  analytics: {},
  esg: {},
  french: false,
};

const chartOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${Number(
          data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
        )}%`,
    },
  },
  layout: {
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
};

const RightColumn = styled.div`
  width: 48%;
  display: inline-block;
  float: right;
  position: relative;
`;

const LeftColumn = styled.div`
  border-radius: 8px;
  width: 48%;
  background: white;
`;

const Container = styled.div`
  min-height: 100%;
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
  overflow: hidden;
`;

const Divider = styled.hr`
  border: none;
  width: 100%;
  height: 1px;
  background: rgb(236, 236, 240);
`;

const Wrapper = styled.div`
  height: auto;
`;
