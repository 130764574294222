import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Storage, Portfolio } from 'actions';
import { Segment, Feature, NeuCard } from 'components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors, dark } from 'utils/colors';
import styled from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';

import {
  PORTFOLIOS_CURATE,
  PORTFOLIOS_CREATE_ETF,
  PORTFOLIOS_CREATE_CUSTOM,
  PORTFOLIOS_VIEW_SAVED,
  PORTFOLIOS_CREATE_ASSET_ALLOCATION,
} from 'utils/features';

@connect((state) => ({
  editing: state.Portfolio.editing,
  partnerName: state.Storage['partner-name'],
}))
class Create extends Component {
  componentWillMount = () => {
    const { dispatch } = this.props;

    dispatch(Portfolio.setEditing('', '', ''));

    return false;
  };

  render() {
    const { dispatch, partnerName } = this.props;

    return (
      <Segment style={{ minHeight: '550px' }}>
        <Feature name={PORTFOLIOS_VIEW_SAVED}>
          <Link to="/portfolio/user">
            <MyButton
              className="left"
              icon="line chart"
              style={{ position: 'absolute', top: '1rem', right: '4.5rem' }}
            >
              <i className="folder open icon" style={{ float: 'left' }} />
              See Saved Portfolios
            </MyButton>
          </Link>
        </Feature>
        <Header size="medium" style={{ margin: 0 }}>
          Create a New Portfolio
          <Header.Subheader
            style={{
              color: dark.grey,
              marginBottom: '1rem',
              maxWidth: '45rem',
            }}
          >
            You will be able to analyze, save, revisit and edit the portfolio,
            as well as use it for the Compare ( <Icon name="law" />) feature in
            the Portfolio Scorecard and for use in the Planning segment.
            <br />
            <br />
            <span style={{ fontStyle: 'italic' }}>
              Please choose an option below.
            </span>
          </Header.Subheader>
        </Header>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '3rem',
          }}
        >
          <Feature name={PORTFOLIOS_CURATE}>
            <NeuCard
              to="/portfolio/find"
              iconName="magic"
              title="SmartRisk Portfolio Builder"
              description="Let us curate an ETF Portfolio for you. Use this questionnaire for tactical asset allocation. See how machine learning can help you optimize your portfolio's risk exposures using ETFs."
              color={colors.teal}
              onClick={() => dispatch(Storage.removeItem('pwpa-portfolio'))}
              big
              logo
              partnerName={partnerName}
            />
          </Feature>
          <Feature name={PORTFOLIOS_CREATE_ETF}>
            <NeuCard
              to="/portfolio/build-etf/new/assets"
              iconName="cubes"
              title="ETF Portfolio Builder"
              description="Create and analyze your own portfolio from a list of passive stocks, bonds, and alternative ETFs with minimum 10 years of history; ideal as model portfolios for comparison and for planning."
              color={colors.teal}
              onClick={() => dispatch(Storage.removeItem('pwpa-portfolio'))}
              big
              logo
              partnerName={partnerName}
            />
          </Feature>

          <Feature name={PORTFOLIOS_CREATE_ASSET_ALLOCATION}>
            <NeuCard
              to="/portfolio/build-ml/new"
              iconName="cogs"
              title="Asset Allocation Using Machine Learning"
              description="Select your holdings first and then use this tool to determine the portfolio
            weights. It uses a concept called Hierarchical Risk Parity to help you create
            a diversified portfolio that lowers risk."
              color={colors.teal}
              big
              logo
              partnerName={partnerName}
            />
          </Feature>
          <Feature name={PORTFOLIOS_CREATE_CUSTOM}>
            <NeuCard
              to="/portfolio/build-custom/new"
              iconName="edit"
              title="DIY Portfolio Builder"
              description="Create and analyze your own portfolio using Canadian/U.S. stocks, ETFs, and Canadian mutual and segregated funds."
              color={colors.teal}
              big
              infoPopupContent='New name for the "Custom Portfolio Builder"'
            />
          </Feature>
        </div>
      </Segment>
    );
  }
}

export default Create;

Create.propTypes = {
  dispatch: PropTypes.func,
  partnerName: PropTypes.string,
};

Create.defaultProps = {
  dispatch: () => false,
  partnerName: '',
};

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 1rem 0;
  max-width: 45rem;
`;

const MyButton = styled.button`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 10px 0;
  padding: 0px;
  height: 50px;
  border-width: 0;
  color: black;
  background-color: transparent;
  text-decoration: none;
  line-height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  overflow: visible;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
    color: ${colors.blue};
  }
`;
