import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { colors } from 'utils/colors';

export default class TaxesBar extends Component {
  getChartData = (data) => {
    const validKeys = Object.keys(data).filter((key) => Number(key));

    return {
      labels: validKeys,
      datasets: [
        {
          label: 'Taxes',
          stack: 'Dataset 1',
          backgroundColor: colors.blue,
          data: validKeys.map((key) => data[key].taxBoth),
        },
        {
          label: 'OAS Clawback',
          stack: 'Dataset 1',
          backgroundColor: colors.red,
          data: validKeys.map((key) => data[key].taxOAS),
        },
      ],
    };
  };

  render() {
    const { data } = this.props;

    return <Bar data={this.getChartData(data)} options={barChartOptions} />;
  }
}

TaxesBar.propTypes = {
  data: PropTypes.object.isRequired,
};

const barChartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItems, dataItem) => {
        const { index, datasetIndex } = tooltipItems;
        return `${dataItem.datasets[datasetIndex].label}: $${dataItem.datasets[
          datasetIndex
        ].data[index].toLocaleString()}`;
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: (value) => `$${value.toLocaleString()}`,
        },
      },
    ],
  },
};
