import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Statistic, Header, Popup, Icon } from 'semantic-ui-react';

import { Score, Table } from 'components';
import { colors } from '../../../../utils/colors';

export default class Diversification extends Component {
  getName = (ticker) => {
    const { analytics } = this.props;
    const { data } = analytics;
    const { tickersOriginal, tickersValid } = data;
    const matchedTicker = tickersOriginal.find(
      (val) => val.replacedBy.proxyTicker === ticker
    );

    if (matchedTicker) {
      return matchedTicker.wsName;
    }

    return tickersValid[ticker].wsName;
  };

  getSplits = (corr, num) => {
    // split the correlation list into groups of num so that stacked tables
    // are rendered if there are too many correlations
    const splits = [];
    let curr = [];
    let cash = false;

    Object.keys(corr).forEach((ticker) => {
      if (ticker === 'CASH:MKT') {
        cash = true;
      }
      curr.push(ticker);
      if (curr.length === num) {
        splits.push(curr);
        curr = [];
      }
    });
    if (curr.length > 0) {
      splits.push(curr);
    }

    return { splits, cash };
  };

  shouldHighlight = (corr) => corr >= 0.7 && corr < 1;

  // Based off isExecuteVisible function that decides if the
  // "View ETFs" button is visible on the scorecard.
  // Determines if corr matrix should show names or tickers
  showNames = (portfolio) => {
    if (!portfolio) {
      return false;
    }
    if (
      portfolio.is_linked ||
      portfolio.portfolio_type === 'custom' ||
      portfolio.portfolio_type === 'quantified'
    ) {
      return false;
    }

    return true;
  };

  parseDate = (portfolio) => {
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  formatNum = (num) => (num ? num.toFixed(2) : null);

  renderMatrix = (data) => {
    const showNames = this.showNames(data.portfolio);
    const { splits, cash } = this.getSplits(data.corr, showNames ? 5 : 8);

    return (
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <Statistic
          label="TOTAL PORTFOLIO WEIGHTED AVERAGE CORRELATION"
          value={Math.round(data.ipc * 100) / 100}
          size="tiny"
          style={{ marginBottom: '0.5rem' }}
        />
        <Header style={{ marginTop: 0, marginBottom: '1rem' }}>
          <Header.Subheader>
            Correlation coefficients greater than or equal to 0.70 will be
            flagged.
          </Header.Subheader>
        </Header>
        <TableList>
          {splits.map((split) => (
            <TableWrapper showNames={showNames}>
              <Table celled definition textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {split.map((ticker) => (
                      <Table.HeaderCell key={ticker}>
                        {showNames ? this.getName(ticker) : ticker}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {Object.keys(data.corr).map((rowTicker, i) => {
                    if (data.corr[rowTicker][split[0]] !== null) {
                      // don't show rows without correlation data
                      return (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {showNames ? this.getName(rowTicker) : rowTicker}
                          </Table.Cell>
                          {split.map((columnTicker, j) => {
                            const val = data.corr[rowTicker][columnTicker];
                            return (
                              <Table.Cell
                                key={j}
                                style={{
                                  background:
                                    this.shouldHighlight(val) &&
                                    colors.negative,
                                  color:
                                    this.shouldHighlight(val) && colors.hintRed,
                                }}
                              >
                                {val === 0 ? '-' : this.formatNum(val)}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      );
                      // eslint-disable-next-line no-else-return
                    } else return null;
                  })}
                </Table.Body>
              </Table>
            </TableWrapper>
          ))}
        </TableList>
        {cash && (
          <Note>
            Note: for cash, while the covariance between cash and any asset is
            zero, the correlation coefficient is undefined because it has the
            standard deviation of cash in the denominator, which is also zero.
          </Note>
        )}
      </div>
    );
  };

  renderSubHeader = (data) => (
    <div className="sub header">
      <div>{this.parseDate(data.portfolio)} (inclusive)</div>
    </div>
  );

  renderPairs = (data) => {
    const parsedData = Object.keys(data.corr).reduce((total, key) => {
      Object.keys(data.corr[key]).forEach((key2) => {
        if (
          data.corr[key][key2] &&
          data.corr[key][key2] < 1 &&
          data.corr[key][key2] >= 0.7
        ) {
          if (!total[key]) {
            total[key] = [
              {
                name: data.tickersValid[key].name,
                ticker: key,
                matchTicker: key2,
                matchName: data.tickersValid[key2].name,
                corr: data.corr[key][key2],
                rowSpan: 1,
              },
            ];
          } else {
            total[key][0].rowSpan += 1;
            total[key].push({
              name: data.tickersValid[key].name,
              ticker: key,
              matchTicker: key2,
              matchName: data.tickersValid[key2].name,
              corr: data.corr[key][key2],
              exclude: true,
            });
          }
        }
      });

      return total;
    }, {});

    const filtered = Object.values(parsedData).reduce((total, value, index) => {
      if (value.length === 1) {
        total.push({ key: index, item: value[0] });
      } else {
        value.forEach((item, idx) =>
          total.push({ key: `${index}${idx}`, item })
        );
      }

      return total;
    }, []);

    return (
      <div style={{ textAlign: 'center' }}>
        <Statistic
          label="TOTAL PORTFOLIO WEIGHTED AVERAGE CORRELATION"
          value={Math.round(data.ipc * 100) / 100}
          size="tiny"
        />

        <Table correlation>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan="3">
                Asset pairs with correlation greater than or equal to 0.70
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  content="The correlation matrix is too large to display for this portfolio. When a portfolio has more than 15 assets, we show you only the pairs that are highly correlated."
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={6}>Asset</Table.HeaderCell>
              <Table.HeaderCell width={6}>Asset</Table.HeaderCell>
              <Table.HeaderCell>Correlation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filtered.length ? (
              filtered.map((item) => (
                <Table.Row key={item.key}>
                  {!item.item.exclude && (
                    <Table.Cell
                      rowSpan={
                        item.item.rowSpan > 1 ? item.item.rowSpan : undefined
                      }
                    >
                      {item.item.ticker}
                      <SecurityName>{item.item.name}</SecurityName>
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    {item.item.matchTicker}
                    <SecurityName>{item.item.matchName}</SecurityName>
                  </Table.Cell>
                  <Table.Cell>
                    {Math.round(item.item.corr * 100) / 100}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell>N/A</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  render() {
    const { analytics } = this.props;
    const { data } = analytics;

    return (
      <Container>
        <Score
          name="Diversification "
          score={`${data.score.Diversification[0]}%`}
          grade={data.score.Diversification[1]}
          description="Scored using your portfolio's average correlation and four dimensions of diversification across a) asset class, b) geographical region, c) business sectors, and d) macroeconomic factors."
          portfolio={data.portfolio}
          pdf
        />

        <p>Should you give up a free lunch?</p>
        <p>
          The answer is a resounding NO when it comes to your investments. By
          diversifying properly and choosing assets that do not move together in
          locked steps, you can reduce the amount of risk in your portfolio
          without sacrificing returns.
        </p>
        <p>
          Be aware that merely adding more assets to your portfolio does not
          necessarily mean that you are diversifying effectively. For example,
          if all of your assets are exposed to the same risk factor, then the
          diversification is superficial. It is important to diversify across
          different types of risk exposures.
        </p>

        <Header
          dividing
          size="medium"
          subheader={this.renderSubHeader(data)}
          content={
            Object.keys(data.corr).length < 15 ? (
              <div>
                Correlation Matrix&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  content="Correlation coefficients take on a value between -1 and +1. In the context of portfolio analysis, they measure how two assets' returns move in relation to one another over time. The closer a correlation coefficient is to +1, the less benefit there is to diversification, meaning that including both assets in the portfolio are not effective in lowering portfolio risk."
                />
              </div>
            ) : (
              <div>
                Correlation Coefficients&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="right center"
                  wide
                  content="Correlation coefficients take on a value between -1 and +1. In the context of portfolio analysis, they measure how two assets' returns move in relation to one another over time. The closer a correlation coefficient is to +1, the less benefit there is to diversification, meaning that including both assets in the portfolio are not effective in lowering portfolio risk."
                />
              </div>
            )
          }
        />

        {Object.keys(data.corr).length < 15
          ? this.renderMatrix(data)
          : this.renderPairs(data)}
      </Container>
    );
  }
}

Diversification.propTypes = {
  analytics: PropTypes.object,
};

Diversification.defaultProps = {
  analytics: {},
};

const TableWrapper = styled.div`
  .table {
    display: table-caption;
  }

  tr,
  th {
    min-width: ${(props) => (props.showNames ? '170px' : '115px')};
    max-width: ${(props) => (props.showNames ? '170px' : '115px')};
    word-wrap: break-word;
    overflow-wrap: break-word; /* For modern browsers */
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;

const TableList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const Note = styled.div`
  padding-top: 1rem;
  text-align: left;
  text-wrap: wrap;
  max-width: 60%;
`;
