import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { Header, Segment, Feature, NeuCard } from 'components';
import { colors } from 'utils/colors';
import { BuildCustom, BuildETF, CacheInputs } from 'actions';
import {
  PORTFOLIOS_CREATE_NEW,
  PORTFOLIOS_VIEW_SAVED,
  PORTFOLIOS_VIEW_MODEL,
} from 'utils/features';
import asset7 from 'images/asset_7.png';

@withRouter
@connect((state) => ({
  account: state.CustomAccount.account,
}))
class Landing extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;

    dispatch(BuildCustom.removeAll());
    dispatch(BuildCustom.updateToggle('addGicToggle', false));
    dispatch(BuildCustom.updateToggle('addBtcToggle', false));
    dispatch(BuildETF.clearBuildETF());

    return dispatch(CacheInputs.clearField('portfolios', 'smart_risk_builder'));
  };

  render() {
    return (
      <Segment expand>
        <Feature name={PORTFOLIOS_VIEW_SAVED}>
          <Link to="/portfolio/user">
            <MyButton
              className="left"
              icon="line chart"
              style={{ position: 'absolute', top: '1rem', right: '4.5rem' }}
            >
              <i className="folder open icon" style={{ float: 'left' }} />
              See Saved Simulations
            </MyButton>
          </Link>
        </Feature>
        <div>
          <img src={asset7} height="80" width="120" alt="Portfolio" />
          <Header size="1.5rem" color="black">
            Welcome to Simulations
          </Header>
          <P>
            Let us help you build and analyze a new portfolio. Once created, you
            will see the Wealthscope Portfolio Scorecard and all the relevant
            analytics. You will be able to save it here in the Wealthscope app,
            and revisit and edit it in the future. Note: Portfolios saved here
            will not appear in Quotestream.
          </P>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Feature name={PORTFOLIOS_CREATE_NEW}>
              <NeuCard
                to="/portfolio/create"
                iconName="file"
                title="Create New"
                description="Build and analyze a new simulation."
                color={colors.teal}
              />
            </Feature>
            <Feature name={PORTFOLIOS_VIEW_MODEL}>
              <NeuCard
                to="/portfolio/model"
                iconName="flag"
                title="View our Model Portfolios"
                description="See five simple ETF portfolios that have been optimized for different investor risk profiles."
                color={colors.teal}
              />
            </Feature>
          </div>
        </div>
      </Segment>
    );
  }
}

export default Landing;

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const P = styled.p`
  color: rgba(0, 0, 0, 0.5) !important;
  max-width: 650px;
  margin: 0 auto 3rem auto;

  &.strike {
    text-decoration: line-through;
  }
`;

const MyButton = styled.button`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 10px 0;
  padding: 0px;
  height: 50px;
  border-width: 0;
  color: black;
  background-color: transparent;
  text-decoration: none;
  line-height: 50px;
  cursor: pointer;
  font-size: 1.1rem;
  overflow: visible;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
    color: ${colors.blue};
  }
`;
