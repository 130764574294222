/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import { ComparePortfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';

class Bar extends Component {
  componentDidMount() {
    if (this.props.error) {
      Storage.setItem('pwpa-has-exclude-holding', 'true');
    }
  }
  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };

  render() {
    const {
      name,
      ticker,
      weight,
      error,
      history,
      id,
      dispatch,
      comparePortfolioState,
      partnerName,
      exchange_symbol,
      french,
    } = this.props;
    return (
      <Container width={weight * 100} error={error}>
        <div
          style={{
            zIndex: '9999999',
            display: 'block',
            width: '20%',
          }}
        >
          {isGlobeInvestor(partnerName) &&
          name !== 'GIC or HISA' &&
          name !== 'Ethereum' &&
          name !== 'Bitcoin' &&
          !error ? (
            <a
              style={{
                zIndex: '9999999',
                display: 'block',
                width: '20%',
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              href={this.createUrl(
                this.gbmConvert(`${ticker.split(':')[0]}-${exchange_symbol}`)
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ticker}
            </a>
          ) : (
            ticker
          )}
        </div>
        <Name>{name}</Name>

        <Weight>{Math.round(weight * 10000) / 100}%</Weight>
        <PopupWrapper style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            {comparePortfolioState.replacementTickers.includes(ticker) &&
            !error ? (
              <button
                style={{ border: 'none', background: 'none' }}
                onClick={() => {
                  dispatch(ComparePortfolio.setBarCompare(true));
                  history.push(`/portfolio/${id}/compare-replace`);
                  dispatch(ComparePortfolio.setSnapshotLoad(true));
                  dispatch(ComparePortfolio.loadSnapshotAfterReplace());
                }}
              >
                <Popup
                  trigger={
                    <Icon name="exchange" style={{ color: `${colors.blue}` }} />
                  }
                  position="bottom center"
                  wide
                  content={
                    <div>
                      {french
                        ? 'Cette position est un remplacement pour une position qui a été exclue lors de l\'analyse précédente. Cliquez ici pour annuler et sélectionner un autre remplacement.'
                        : 'This holding is a replacement for a holding that was excluded in the previous analysis. Click here to undo and select a different replacement.'}
                    </div>
                  }
                />
              </button>
            ) : null}
            {!error ? null : (
              <div>
                <Popup
                  trigger={<Icon name="exclamation circle" />}
                  position="bottom center"
                  wide
                  content={
                    <div>
                      <strong>{ticker}</strong>{' '}
                      {french
                        ? 'a été exclu de l\'analyse car il a un historique insuffisant. Voir le bouton "Guidelines" en haut pour plus d\'informations.'
                        : 'has been excluded in the analysis because it has insufficient history. See the Guidelines button at the top for more information.'}
                    </div>
                  }
                />
                <button
                  style={{
                    border: 'none',
                    background: 'none',
                    color: `${colors.red}`,
                  }}
                  onClick={() => {
                    dispatch(ComparePortfolio.setBarCompare(true));
                    history.push(`/portfolio/${id}/compare-replace`);
                  }}
                >
                  <Popup
                    trigger={<Icon name="exchange" />}
                    position="bottom center"
                    wide
                    content={
                      <div>
                        {french
                          ? 'Cliquez pour remplacer par un titre similaire qui a un historique plus long (idéalement 5 ans) pour une analyse plus complète.'
                          : 'Click to replace with a similar security that has a longer history (ideally 5 years) for a more comprehensive analysis.'}
                      </div>
                    }
                  />
                </button>
              </div>
            )}
          </div>
        </PopupWrapper>
      </Container>
    );
  }
}

Bar.propTypes = {
  name: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  id: PropTypes.string.isRequired,
  comparePortfolioState: PropTypes.object.isRequired,
  partnerName: PropTypes.string,
  exchange_symbol: PropTypes.string,
  french: PropTypes.bool,
};
Bar.defaultProps = {
  dispatch: () => false,
  history: {},
  partnerName: '',
  exchange_symbol: '',
  french: false,
};

export default withRouter(
  connect((state) => ({
    comparePortfolioState: state.ComparePortfolio,
    partnerName: state.Storage['partner-name'],
    french: state.Storage.language === 'fr',
  }))(Bar)
);

const Container = styled.div`
  position: relative;
  background: rgb(241, 242, 247);
  margin: 0.5rem 0;
  border: 1px solid transparent;
  padding: 0.3rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 18px;
  min-height: 46px;
  pointer-events: all;
  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.width}%;
    background: ${colors.darkBlue};
    opacity: 0.2;
  }
  ${(props) =>
    props.error &&
    css`
      background: #fff6f6;
      color: ${colors.hintRed};
      &:before {
        background: ${colors.red};
      }
    `}
`;

const Weight = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
`;

const Name = styled.div`
  font-weight: 100;
  font-size: 1rem;
`;

const PopupWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 70px;
  font-size: 1.4rem;
  color: ${colors.red};
`;
