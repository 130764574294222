import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Table, Divider, Button } from 'semantic-ui-react';
import { Pie } from 'react-chartjs-2';
import { graph, colors, chartingToolColorsLessOpacity } from 'utils/colors';
import { maxNumberOfFundsToCompare } from 'utils/constants';
import { isGlobeInvestor } from 'utils/helpers';

const CANVAS_SIZE = 225;

export default class Breakdown extends Component {
  gbmConvert = (ticker) => {
    const myTicker = ticker.replace(/\./g, '-');
    const myArray = [
      { ws: 'TSX', gbm: 'T' },
      { ws: 'CDX', gbm: 'X' },
      { ws: 'AQNL', gbm: 'NE' },
      { ws: 'AQLL', gbm: 'NE' },
      { ws: 'NYE', gbm: 'N' },
      { ws: 'NGS', gbm: 'Q' },
      { ws: 'NSC', gbm: 'Q' },
      { ws: 'NSD', gbm: 'Q' },
      { ws: 'AMX', gbm: 'A' },
      { ws: '-CMF', gbm: '.CF' },
      { ws: 'ARCA', gbm: 'A' },
      { ws: 'BATS', gbm: 'A' },
      { ws: '-TSX', gbm: '-T' },
    ];
    for (let i = 0; i < myArray.length; i++) {
      if (myTicker.includes(myArray[i].ws)) {
        return myTicker.replace(myArray[i].ws, myArray[i].gbm);
      }
    }
    return myTicker;
  };
  createUrl = (item) => {
    const myUrl = 'https://www.theglobeandmail.com/investing/markets/stocks/';
    const myFundsUrl =
      'https://www.theglobeandmail.com/investing/markets/funds/';
    return item.includes('.CF') ? `${myFundsUrl + item}` : `${myUrl + item}`;
  };
  appendExchangeSymbol = (ticker, exchangeSymbol) =>
    `${ticker.split(':')[0]}-${exchangeSymbol}`;
  handleClick = (event, ticker, exchangeSymbol) => {
    event.preventDefault();
    window.open(
      this.createUrl(
        this.gbmConvert(
          this.appendExchangeSymbol(ticker, exchangeSymbol.toUpperCase())
        )
      )
    ),
      '_blank';
  };

  getChartData = (data) => ({
    labels: Object.keys(data).map((key) => mappings[key]),
    datasets: [
      {
        data: Object.values(data).map((value) => (value < 0 ? 0 : value * 100)),
        backgroundColor: [...Object.values(graph)].slice(
          0,
          Object.values(data).length
        ),
      },
    ],
  });

  getUnavailableData = () => ({
    labels: ['N/A'],
    datasets: [
      {
        data: [100],
        backgroundColor: ['rgba(128, 128, 128, 0.2)'],
      },
    ],
  });

  isAvailable = (data) => !!Object.values(data).filter((value) => value).length;

  generateRegionLegends = (labels) => {
    const legendColors = [...Object.values(graph)].slice(0, 6);
    const legends = (tags, colorOffset) =>
      tags.map((tag, index) => (
        <div style={{ width: '150px', display: 'inline-block' }}>
          <Legend color={legendColors[index + colorOffset]}>{tag}</Legend>
        </div>
      ));

    const firstRow = labels.slice(0, Math.floor(labels.length / 2));
    const secondRow = labels.slice(
      Math.floor(labels.length / 2),
      labels.length
    );

    return (
      <div>
        <div style={{ width: '140%' }}>{legends(firstRow, 0)}</div>
        <div style={{ width: '140%' }}>{legends(secondRow, 3)}</div>
      </div>
    );
  };

  generateAssetLegends = (labels) => {
    const legendColors = [...Object.values(graph)].slice(0, 4);
    const legends = (tags, colorOffset) =>
      tags.map((tag, index) => (
        <div style={{ width: '160px', display: 'inline-block' }}>
          <Legend color={legendColors[index + colorOffset]}>{tag}</Legend>
        </div>
      ));

    const firstRow = labels.slice(0, Math.floor(labels.length / 2));
    const secondRow = labels.slice(
      Math.floor(labels.length / 2),
      labels.length
    );

    return (
      <div>
        <div style={{ width: '140%' }}>{legends(firstRow, 0)}</div>
        <div style={{ width: '140%' }}>{legends(secondRow, 2)}</div>
      </div>
    );
  };

  generateSectorLegends = (labels) => {
    const legendColors = [...Object.values(graph)].slice(0, 11);
    const legends = (tags, colorOffset) =>
      tags.map((tag, index) => (
        <div style={{ width: '190px', display: 'inline-block' }}>
          <Legend color={legendColors[index + colorOffset]}>{tag}</Legend>
        </div>
      ));

    const firstRow = labels.slice(0, 4);
    const secondRow = labels.slice(4, 8);
    const thirdRow = labels.slice(8);

    return (
      <div>
        <div style={{ width: '200%' }}>{legends(firstRow, 0)}</div>
        <div style={{ width: '200%' }}>{legends(secondRow, 4)}</div>
        <div style={{ width: '200%' }}>{legends(thirdRow, 8)}</div>
      </div>
    );
  };

  renderTable = (data, params) => (
    <Table
      collapsing
      textAlign="center"
      compact="very"
      style={{ width: '100%' }}
    >
      <Table.Header>
        <Table.Row>
          {params.headers.map((header) => (
            <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(data).map((key) => (
          <Table.Row key={key}>
            <Table.Cell>{mappings[key]}</Table.Cell>
            <Table.Cell>
              {data[key] === null
                ? 'N/A'
                : data[key] < 0
                ? '0.0%'
                : `${(data[key] * 100).toFixed(1)}%`}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );

  render() {
    const {
      securityTickerList,
      securityData,
      isOpen,
      toggleOpen,
      partnerName,
    } = this.props;
    let regionLegendList = [];
    let assetLegendList = [];
    let sectorLegendList = [];

    securityTickerList.forEach((ticker) => {
      const data = securityData[ticker];

      regionLegendList = this.getChartData(data.regional_exposure).labels;
      assetLegendList = this.getChartData(data.asset_allocation).labels;
      sectorLegendList = this.getChartData(data.sector_allocation).labels;
    });

    const dummyTickers = new Array(
      maxNumberOfFundsToCompare - securityTickerList.length
    )
      .fill(0)
      .map(() => (
        <div
          style={{
            width: '25%',
            display: 'inline-block',
            textAlign: 'center',
            verticalAlign: 'top',
          }}
        >
          <div
            style={{
              backgroundColor: Object.values(chartingToolColorsLessOpacity)[7],
              color: 'black',
              padding: '.62857143em .78571429em',
              borderLeft: '1px solid rgba(236,236,240)',
            }}
          >
            &nbsp;
          </div>
        </div>
      ));

    return (
      <React.Fragment>
        <Header textAlign="left" style={{ marginTop: '0' }}>
          <div style={{ display: 'flex' }}>
            <StyledButton onClick={toggleOpen} isOpen={isOpen}>
              {isOpen ? '-' : '+'}
            </StyledButton>
            Breakdown
          </div>
        </Header>
        <Divider />
        {isOpen && (
          <div
            style={{
              textAlign: 'left',
              borderLeft: '1px solid rgba(0, 0, 0, 0.15)',
              borderRight: '1px solid rgba(0, 0, 0, 0.15)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
            }}
          >
            {securityTickerList.map((ticker, index) => (
              <div
                style={{
                  width: '25%',
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: Object.values(
                      chartingToolColorsLessOpacity
                    )[index],
                    color: 'black',
                    padding: '.62857143em .78571429em',
                  }}
                >
                  {isGlobeInvestor(partnerName) ? (
                    <strong>
                      <a
                        style={{
                          zIndex: '9999999',
                          display: 'block',
                          cursor: 'pointer',
                          pointerEvents: 'all',
                          color: `${
                            window.location.pathname.includes('pdf')
                              ? 'black'
                              : 'auto'
                          }`,
                        }}
                        onClick={() =>
                          this.handleClick(
                            event,
                            ticker,
                            securityData[ticker].exchange_symbol
                          )
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {ticker}
                      </a>
                    </strong>
                  ) : (
                    <strong>{ticker}</strong>
                  )}
                </div>
              </div>
            ))}

            {dummyTickers}

            <div
              style={{
                padding: '2rem 1rem 0 1rem',
              }}
            >
              <Header size="medium" content="Holdings by Region" />
              {this.generateRegionLegends(regionLegendList)}
            </div>

            {securityTickerList.map((ticker) => {
              const data = securityData[ticker];

              return (
                <div
                  style={{
                    width: '25%',
                    display: 'inline-block',
                    textAlign: 'center',
                    padding: '1rem',
                  }}
                >
                  {!this.isAvailable(data.regional_exposure) ? (
                    <div>
                      <div
                        style={{ marginBottom: '1rem', position: 'relative' }}
                      >
                        <Pie
                          data={this.getUnavailableData()}
                          height={CANVAS_SIZE}
                          options={options}
                        />
                      </div>
                      <div>
                        {this.renderTable(data.regional_exposure, {
                          headers: ['Region', 'Holdings'],
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{ marginBottom: '1rem' }}>
                        <Pie
                          data={this.getChartData(data.regional_exposure)}
                          height={CANVAS_SIZE}
                          options={options}
                        />
                      </div>
                      <div>
                        {this.renderTable(data.regional_exposure, {
                          headers: ['Region', 'Holdings'],
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div style={{ pageBreakAfter: 'always' }} />

            <div
              style={{
                padding: '2rem 1rem 0 1rem',
              }}
            >
              <Header size="medium" content="Asset Allocation" />
              {this.generateAssetLegends(assetLegendList)}
            </div>

            {securityTickerList.map((ticker) => {
              const data = securityData[ticker];

              return (
                <div
                  style={{
                    width: '25%',
                    display: 'inline-block',
                    textAlign: 'center',
                    padding: '1rem',
                  }}
                >
                  {!this.isAvailable(data.asset_allocation) ? (
                    <div>
                      <div
                        style={{ marginBottom: '1rem', position: 'relative' }}
                      >
                        <Pie
                          height={CANVAS_SIZE}
                          data={this.getUnavailableData()}
                          options={options}
                        />
                      </div>
                      <div>
                        {this.renderTable(data.asset_allocation, {
                          headers: ['Asset Type', 'Holdings'],
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{ marginBottom: '1rem' }}>
                        <Pie
                          height={CANVAS_SIZE}
                          data={this.getChartData(data.asset_allocation)}
                          options={options}
                        />
                      </div>
                      <div>
                        {this.renderTable(data.asset_allocation, {
                          headers: ['Asset Type', 'Holdings'],
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div
              style={{
                padding: '2rem 1rem 0 1rem',
              }}
            >
              <Header size="medium" content="Equity Sector Allocation" />
              {this.generateSectorLegends(sectorLegendList)}
            </div>

            {securityTickerList.map((ticker) => {
              const data = securityData[ticker];

              return (
                <div
                  style={{
                    width: '25%',
                    display: 'inline-block',
                    textAlign: 'center',
                    padding: '1rem',
                  }}
                >
                  {!this.isAvailable(data.sector_allocation) ? (
                    <div>
                      <div
                        style={{ marginBottom: '1rem', position: 'relative' }}
                      >
                        <Pie
                          height={CANVAS_SIZE}
                          data={this.getUnavailableData()}
                          options={options}
                        />
                      </div>
                      <div>
                        {this.renderTable(data.sector_allocation, {
                          headers: ['Sector', 'Holdings'],
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{ marginBottom: '1rem' }}>
                        <Pie
                          height={CANVAS_SIZE}
                          data={this.getChartData(data.sector_allocation)}
                          options={options}
                        />
                      </div>
                      <div>
                        {this.renderTable(data.sector_allocation, {
                          headers: ['Sector', 'Holdings'],
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

Breakdown.propTypes = {
  securityData: PropTypes.object.isRequired,
  securityTickerList: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

Breakdown.defaultProps = { partnerName: '' };

const options = {
  showAllTooltips: true,
  maintainAspectRatio: true,
  cutoutPercentage: 65,
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems, chartData) =>
        `${
          Math.round(
            chartData.datasets[tooltipItems.datasetIndex].data[
              tooltipItems.index
            ] * 10
          ) / 10
        }%`,
    },
  },
  hover: {
    animationDuration: 0,
    onHover: (e) => {
      e.target.style.cursor = 'pointer';
    },
  },
};

const mappings = {
  other: 'Other',
  usa: 'US',
  developed: 'Developed ex. US',
  emerging: 'Emerging',
  canada: 'Canada',
  frontier: 'Frontier',
  stock: 'Stock',
  cash: 'Cash & Equiv.',
  bond: 'Bond',
  financials: 'Financials',
  utilities: 'Utilities',
  healthcare: 'Healthcare',
  materials: 'Materials',
  energy: 'Energy',
  telecom: 'Telecom',
  info_tech: 'IT',
  consumer_staples: 'Consumer Staples',
  consumer_discretionary: 'Consumer Discretionary',
  real_estate: 'Real Estate',
  industrials: 'Industrials',
  misc: 'Misc.',
};

const Legend = styled.span`
  position: relative;
  color: black;
  font-size: 0.9rem;
  cursor: default;
  margin: 0 0 0 3rem;
  vertical-align: center;
  text-decoration: ${(props) => (props.lineThrough ? 'line-through' : 'none')};

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: -2.8rem;
    height: 0.8rem;
    width: 2.2rem;
    background: ${(props) => props.color || 'grey'};
    vertical-align: middle;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    font-size: 1.3rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.isOpen ? colors.blue : 'white'} !important;
    color: ${(props) => (props.isOpen ? 'white' : colors.blue)} !important;
    border: ${(props) =>
      props.isOpen ? 'none' : `2px solid ${colors.blue}`} !important;
    border-radius: 50% !important;
    height: 2rem !important;
    width: 2rem !important;
    padding: 0 !important;
    transition: all 0.9s ease-in-out !important;
    margin: 0 0.75rem 0 0;
  }
`;
