import React from 'react';
import PropTypes from 'prop-types';
import { Header, Label } from 'semantic-ui-react';

const SectorWeight = ({ title, value }) => (
  <div>
    <Header size="medium">
      <Label
        size="large"
        style={{
          margin: '0 0.7em 0.3em 0',
          minWidth: '74px',
          textAlign: 'center',
        }}
      >
        {`${value} %`}
      </Label>
      {title}
    </Header>
  </div>
);

SectorWeight.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
};

SectorWeight.defaultProps = {
  value: 0,
};

export default SectorWeight;
