import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { colors } from 'utils/colors';

const widths = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
];
const convertPropToClassName = (prop, className) => prop && className;

class Table extends Component {
  static Header = ({ children, ...rest }) => (
    <Header {...rest}>{children}</Header>
  );
  static Body = ({ children, ...rest }) => <tbody {...rest}>{children}</tbody>;
  static Row = ({
    children,
    className,
    positive,
    negative,
    warning,
    ...rest
  }) => {
    const classes = cn(
      className,
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(warning, 'warning')
    );

    return (
      <Row className={classes} {...rest}>
        {children}
      </Row>
    );
  };
  static Cell = ({
    children,
    className,
    positive,
    negative,
    warning,
    ...rest
  }) => {
    const classes = cn(
      className,
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(warning, 'warning'),
      { [`${widths[rest.width - 1]}`]: rest.width }
    );

    return (
      <Cell className={classes} {...rest}>
        {children}
      </Cell>
    );
  };
  static HeaderCell = ({
    children,
    className,
    positive,
    negative,
    warning,
    hidden,
    ...rest
  }) => {
    const classes = cn(
      className,
      convertPropToClassName(positive, 'positive'),
      convertPropToClassName(negative, 'negative'),
      convertPropToClassName(warning, 'warning'),
      convertPropToClassName(hidden, 'hidden'),
      { [`${widths[rest.width - 1]}`]: rest.width }
    );

    return (
      <HeaderCell className={classes} {...rest}>
        {children}
      </HeaderCell>
    );
  };

  render() {
    const { children, className, celled, definition, fixedColumn, ...rest } =
      this.props;
    const classes = cn(
      'table',
      className,
      convertPropToClassName(celled, 'celled'),
      convertPropToClassName(definition, 'definition'),
      convertPropToClassName(fixedColumn, 'fixed-column')
    );

    return (
      <StyledTable {...rest} className={classes}>
        {children}
      </StyledTable>
    );
  }
}

Table.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  celled: PropTypes.bool,
  definition: PropTypes.bool,
  fixedColumn: PropTypes.bool,
};

Table.defaultProps = {
  className: '',
  celled: false,
  definition: false,
  fixedColumn: false,
};

export default Table;

const StyledTable = styled.table`
  width: 100%;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0;
  text-align: ${(props) => props.textAlign || 'inherit'};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  thead {
    tr:first-child {
      & > th:first-child {
        border-radius: 0.28571429rem 0 0;
      }

      & > th:last-child {
        border-radius: 0 0.28571429rem 0 0;
      }
    }

    th {
      cursor: auto;
      background: #f9fafb;
      color: rgba(0, 0, 0, 0.87);
      padding: 0.9em 0.8em;
      vertical-align: inherit;
      font-weight: 700;
      text-transform: none;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      border-left: none;
    }
  }

  td {
    border-top: 1px solid rgba(34, 36, 38, 0.1);

    &.positive {
      box-shadow: 0 0 0 #a3c293 inset;
      background: #f5fbfd !important;
    }

    &.negative {
      box-shadow: 0 0 0 #e0b4b4 inset;
      background: #fff6f6 !important;
      color: ${colors.hintRed} !important;
    }

    &.warning {
      box-shadow: 0 0 0 #c9ba9b inset;
      background: #fffaf3 !important;
      color: #573a08 !important;
    }
  }

  tr {
    &:first-child td {
      border-top: none;
    }

    &.positive {
      box-shadow: 0 0 0 #a3c293 inset;
      background: #f5fbfd !important;
    }

    &.negative {
      box-shadow: 0 0 0 #e0b4b4 inset;
      background: #fff6f6 !important;
      color: ${colors.hintRed} !important;
    }

    &.warning {
      box-shadow: 0 0 0 #c9ba9b inset;
      background: #fffaf3 !important;
      color: #573a08 !important;
    }
  }

  ${widths.map(
    (width, index) => css`
      td.${width}, th.${width} {
        width: ${6.25 * (index + 1)}%;
      }
    `
  )};

  &.celled {
    border-collapse: collapse;

    tr td,
    tr th {
      border-left: 1px solid rgba(34, 36, 38, 0.1);
    }

    tr td:first-child,
    tr th:first-child {
      border-left: none;
    }

    &.definition {
      thead th:first-child {
        box-shadow: 0 -1px 0 1px #fff;
      }
    }
  }

  &.definition {
    thead {
      th {
        background: #ffedd6;

        &:first-child {
          pointer-events: none;
          background: 0 0;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          box-shadow: -1px -1px 0 1px #fff;
        }

        &:nth-child(2) {
          border-left: 1px solid rgba(34, 36, 38, 0.15);
        }
      }
    }

    tr {
      td {
        &:first-child {
          background: #ffedd6;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.95);
        }

        &:nth-child(2) {
          border-left: 1px solid rgba(34, 36, 38, 0.15);
        }
      }
    }
  }

  &.fixed-column {
    position: absolute;
    width: 172px;
    border: 0px;
    margin-bottom: 0;
    border-bottom: 1px solid lightgrey;
    border-radius: 0;

    th {
      background: white;
      color: white;
      padding: 0.9em 0.8em;
      user-select: none;
    }

    tbody {
      background-color: #fff;
    }

    tr th {
      border-right: 1px solid rgba(34, 36, 38, 0.1) !important;
    }

    tr td {
      border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
      border-right: 1px solid rgba(34, 36, 38, 0.1) !important;
    }
  }
`;

const Header = styled.thead`
  box-shadow: none;
`;

const Row = styled.tr`
  text-align: ${(props) => props.textAlign || 'inherit'};
`;

const Cell = styled.td`
  padding: 0.78571429em;
  text-align: ${(props) => props.textAlign || 'inherit'};
`;

const HeaderCell = styled.th`
  text-align: ${(props) => props.textAlign || 'inherit'};
`;
