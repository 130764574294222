import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radar } from 'react-chartjs-2';
import { Popup, Dropdown, Icon } from 'semantic-ui-react';
import { Analytics, Portfolio, Storage } from 'actions';
import { connect } from 'react-redux';
import { colors } from 'utils/colors';
import {
  Score,
  CustomBenchmarkDashboard,
  SelectionDashboard,
} from 'components';
import Holdings from './Holdings';
import PortfolioHistory from './PortfolioHistory';
import ObjectiveSummary from './ObjectiveSummary';

@connect((state) => ({
  toggle_region: state.Storage.toggle_region || 'CA',
  user_region: state.Storage.user_region || 'CA',
  checkHistory:
    state.Storage['pwpa-check-history'] === undefined
      ? true
      : state.Storage['pwpa-check-history'],
  pwpaCheckHistory: state.Storage['pwpa-check-history'],
}))
export default class Overview extends Component {
  getRadarData = (score) => {
    const { income, benchmark } = this.props;
    const hash = () => {
      if (income) {
        return {
          Diversification: 'Diversification',
          Fees: 'Low Fees',
          Income: 'Income',
          Performance: 'Performance',
        };
      }
      return {
        Diversification: 'Diversification',
        Fees: 'Low Fees',
        Performance: 'Performance',
      };
    };

    const data = Object.keys(hash()).reduce((total, key) => {
      total[hash()[key]] =
        key === ('Performance' || 'Volatility')
          ? score[key][benchmark][0]
          : score[key][0];

      return total;
    }, {});

    return {
      labels: ['Downside Protection', ...Object.keys(data)],
      datasets: [
        {
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data: [score.Volatility[benchmark][0], ...Object.values(data)],
        },
      ],
    };
  };

  getClass = (row) => {
    if (row.isExcluded) {
      return 'negative';
    }

    return Object.keys(row.replacedBy).length ? 'warning' : '';
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  changeIncome = (income) => {
    const { dispatch } = this.props;

    return dispatch(Analytics.updateParameter('income', income));
  };

  changeCheckHistory = (newSelcheckHistory) => {
    const { location, dispatch, match, check_history, data, pwpaPortfolio } =
      this.props;
    const { portfolio, paramsAnalyzed } = data;
    const rebalanceInterval = paramsAnalyzed.rebalance_interval || 1;
    if (newSelcheckHistory === !check_history) {
      return false;
    }

    dispatch(Storage.setItem('pwpa-check-history', !newSelcheckHistory));

    if (portfolio && portfolio.name) {
      dispatch(
        Portfolio.setEditing(
          portfolio.id,
          portfolio.name,
          portfolio.description
        )
      );
    }

    if (Number(match.params.id)) {
      dispatch(
        Analytics.fetchId(
          match.params.id,
          check_history,
          'CA',
          rebalanceInterval
        )
      );
    }

    if (pwpaPortfolio) {
      dispatch(
        Analytics.fetchParams(
          pwpaPortfolio,
          location.state,
          check_history,
          'CA',
          rebalanceInterval
        )
      );
    }

    return false;
  };

  changeRebalancingFrequency = (e, { value }) => {
    const { location, dispatch, match, data, pwpaPortfolio, pwpaCheckHistory } =
      this.props;
    const { portfolio } = data;
    const checkHistory =
      pwpaCheckHistory === null || pwpaCheckHistory === undefined
        ? null
        : !pwpaCheckHistory;

    if (portfolio && portfolio.name) {
      dispatch(
        Portfolio.setEditing(
          portfolio.id,
          portfolio.name,
          portfolio.description
        )
      );
    }

    if (Number(match.params.id)) {
      dispatch(Analytics.fetchId(match.params.id, checkHistory, 'CA', value));
    }

    if (pwpaPortfolio) {
      dispatch(
        Analytics.fetchParams(
          pwpaPortfolio,
          location.state,
          checkHistory,
          'CA',
          value
        )
      );
    }

    return false;
  };

  render() {
    const {
      income,
      benchmark,
      objective,
      data,
      history,
      match,
      dispatch,
      initHasExcludedHolding,
      check_history,
    } = this.props;
    const { paramsAnalyzed, percentFI } = data;
    const sortedHoldings = data.tickersOriginal.sort(
      (a, b) => b.weight - a.weight
    );
    const hasExcludedHolding = sortedHoldings.find(
      (holding) => holding.isExcluded
    );
    const rebalancingFrequency = paramsAnalyzed.rebalance_interval || 1;
    return (
      <div>
        {!!Object.keys(objective).length && (
          <ObjectiveSummary data={objective} ratios={data.fratios.portfolio} />
        )}
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {initHasExcludedHolding ? (
              <StyledCard style={{ minWidth: '210px' }}>
                <SelectionDashboard
                  text="Include short-history holdings"
                  popuptext="Including short-history holdings in the analysis will shorten the evaluation period. Note that holdings with history less than a month will still be excluded."
                  selected={!check_history}
                  changeSelection={this.changeCheckHistory}
                  style={{ fontSize: '1.15rem' }}
                  popupBottom
                />
              </StyledCard>
            ) : null}

            <StyledCard>
              <SelectionDashboard
                text="Include income score"
                popuptext="The default includes the income score in the calculation of the overall score. If you are investing for the long term and/or do not need income (dividends and other distributions) from your investment portfolio, choose NO."
                selected={income}
                changeSelection={this.changeIncome}
                style={{ fontSize: '1.15rem' }}
              />
            </StyledCard>
            {percentFI !== 1 && (
              <StyledCard style={{ minWidth: '190px' }}>
                <CustomBenchmarkDashboard
                  benchmark={benchmark}
                  feature="scorecard"
                />
              </StyledCard>
            )}

            <StyledCard style={{ minWidth: '180px' }}>
              <p
                style={{
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginBottom: '1em',
                }}
              >
                Rebalancing Frequency
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                        marginRight: '1rem',
                      }}
                    />
                  }
                  position="top left"
                  wide
                >
                  Rebalancing refers to the process of adjusting the holding
                  weights back to the desired allocation. As investments grow or
                  decline in value, their weights in the portfolio may deviate
                  from their targets. Rebalancing will bring the portfolio back
                  in line with its intended risk and return profile.
                </Popup>
              </p>
              <OptionsWrapper>
                <StyledDropdown
                  selection
                  placeholder="Monthly"
                  options={[
                    {
                      key: 'mon',
                      value: '1',
                      text: 'Monthly',
                    },
                    { key: 'qua', value: '3', text: 'Quarterly' },
                    { key: 'ann', value: '12', text: 'Annually' },
                  ]}
                  text={
                    rebalancingFrequency === 1
                      ? 'Monthly'
                      : rebalancingFrequency === 3
                      ? 'Quarterly'
                      : 'Annually'
                  }
                  onChange={this.changeRebalancingFrequency}
                />
              </OptionsWrapper>
            </StyledCard>
          </div>
        </div>
        <Container margin="2rem 3rem 2rem 19rem">
          <StyledChart>
            <Score
              score={
                income
                  ? `${data.score.Overall5[benchmark][0]}%`
                  : `${data.score.Overall4[benchmark][0]}%`
              }
              grade={
                income
                  ? data.score.Overall5[benchmark][1]
                  : data.score.Overall4[benchmark][1]
              }
              description={[
                <div
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  {' '}
                  <p
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    Your overall portfolio score is the average of the component
                    scores below. Each component score is out of 100% for the
                    specific time period in question. A higher score always
                    means a more desireable characteristic.
                    <br /> <br />{' '}
                    <a
                      href="https://www.wealthscope.ca/whyscores"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: '1rem',
                      }}
                    >
                      Learn more here.
                    </a>
                  </p>{' '}
                </div>,
              ]}
              portfolio={data.portfolio}
              boldDate={!check_history}
            />

            <Radar
              id="radarChart"
              data={this.getRadarData(data.score)}
              options={chartOptions}
            />
          </StyledChart>

          <Holdings
            dispatch={dispatch}
            portfolio={data.portfolio}
            holdings={sortedHoldings}
            hasExcludedHolding={!!hasExcludedHolding}
            history={history}
            match={match}
          />

          <PortfolioHistory
            dispatch={dispatch}
            benchmark={benchmark}
            data={data}
          />
        </Container>
      </div>
    );
  }
}

Overview.propTypes = {
  dispatch: PropTypes.func.isRequired,
  benchmark: PropTypes.string.isRequired,
  income: PropTypes.bool.isRequired,
  data: PropTypes.object,
  objective: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  initHasExcludedHolding: PropTypes.bool,
  check_history: PropTypes.bool,
  pwpaPortfolio: PropTypes.object,
  location: PropTypes.object,
  pwpaCheckHistory: PropTypes.bool,
};

Overview.defaultProps = {
  data: {},
  objective: {},
  history: {},
  match: {},
  initHasExcludedHolding: false,
  check_history: false,
  pwpaPortfolio: {},
  location: {},
  pwpaCheckHistory: null,
};

const chartOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) =>
        `${Number(
          data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
        )}%`,
    },
  },
  layout: {
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
};

const StyledChart = styled.div`
  width: 48%;
  display: inline-block;
  float: right;
  position: relative;
`;

const Container = styled.div`
  min-height: 100%;
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-radius: 15px;
  background-color: #fff;
  ${
    '' /* box-shadow: 0 8px 15px 0 rgb(59 105 142 / 15%);
transition: box-shadow 0.2s ease-in-out; */
  }
  margin: 1rem 0.5rem 1rem 1rem;
`;
const StyledDropdown = styled(({ className, children, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 22px !important;
  min-width: 100px !important;
  min-height: 22px !important;
  padding: 1px 0 0 10px !important;
  font-size: 1rem;
  .icon {
    padding: 2px 0 0 0 !important;
  }
`;
const OptionsWrapper = styled.div`
  padding: 3px 0;
`;
