import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import Loading from 'components/Loading';

@connect((state) => ({
  auth: state.Auth,
}))
export default class PrivateRoute extends React.Component {
  render() {
    const { auth, component: Component, ...rest } = this.props;

    if (!auth.token) {
      return <Redirect to="/unauthorized" />;
    }

    if (auth.isFetching) {
      return <Loading />;
    }

    return <Route {...rest} component={Component} />;
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  auth: PropTypes.object,
  pwpaToken: PropTypes.string,
};

PrivateRoute.defaultProps = {
  auth: {},
  pwpaToken: null,
};
