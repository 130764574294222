import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popup, Statistic, Icon, Header } from 'semantic-ui-react';

import { Modal } from 'components';

export default class FullScreenModal extends Component {
  formatNum = (num) => {
    const options = {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    const format = new Intl.NumberFormat('en-US', options);

    return `$${format.format(num)}`;
  };

  renderSubheader = () => (
    <div className="sub header">
      All figures in the table are nominal, reflecting future values, except{' '}
      <strong>real spending</strong>.
    </div>
  );

  render() {
    const { data, filtered, open, closeFullscreen, result } = this.props;

    return (
      <Modal open={open} className="fullscreen">
        <Modal.Content>
          <CloseIcon onClick={closeFullscreen}>+</CloseIcon>
          <Header
            size="medium"
            content="Year by year details"
            subheader={this.renderSubheader}
            style={{ margin: 0 }}
          />
          <div style={{ position: 'relative', marginTop: '2rem' }}>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th />
                    {filtered.map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>

                <Header size="medium" content="Spending" textAlign="left" />
                <tbody>
                  <tr>
                    <td className="title">Gross spending</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incTotal)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">Net spending</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incNet)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">Real net spending</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incPlot)}</td>
                    ))}
                  </tr>
                </tbody>

                <Header
                  size="medium"
                  content="Retirement income"
                  textAlign="left"
                  style={{ margin: '25px 0 14px 0' }}
                />
                <tbody>
                  <tr>
                    <td className="title">CPP/QPP</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incCPP)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">OAS</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incOAS)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">GIS</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incGIS)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">Employment pension</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incPension)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">Other</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incOther)}</td>
                    ))}
                  </tr>
                </tbody>

                <Header
                  size="medium"
                  content="Investments"
                  textAlign="left"
                  style={{ margin: '25px 0 14px 0' }}
                />
                <tbody>
                  <tr>
                    <td className="title">Minimum RRIF</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].wdReqMin)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">Non-registered</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].wdRegular)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">RRIF</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].wdRRIF)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">TFSA</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].wdTFSA)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">
                      Dividends
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        position="top center"
                        content="From non-registered accounts."
                        wide
                      />
                    </td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].incDividend)}</td>
                    ))}
                  </tr>
                </tbody>

                <Header
                  size="medium"
                  content="Taxes"
                  textAlign="left"
                  style={{ margin: '25px 0 14px 0' }}
                />
                <tbody>
                  <tr>
                    <td className="title">Taxes</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].taxBoth)}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="title">OAS clawback</td>
                    {filtered.map((key) => (
                      <td key={key}>{this.formatNum(data[key].taxOAS)}</td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </TableWrapper>
          </div>

          <Header
            size="medium"
            content="Ending Values"
            style={{ textAlign: 'center' }}
            // subheader="After tax, in today's dollars"
          />
          <div
            style={{
              textAlign: 'center',
              margin: '1rem',
              // transform: 'translateX(-40px)',
            }}
          >
            <Statistic
              size="tiny"
              style={{ marginRight: '4rem', marginLeft: '4rem' }}
            >
              <Statistic.Label>
                Non-registered
                {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
              </Statistic.Label>
              <Statistic.Value>
                {this.formatNum(
                  result.yearlyDetails[
                    Object.keys(result.yearlyDetails)[
                      Object.keys(result.yearlyDetails).length - 1
                    ]
                  ].endingRegular
                )}
              </Statistic.Value>
            </Statistic>
            <Statistic
              size="tiny"
              style={{ marginRight: '4rem', marginLeft: '4rem' }}
            >
              <Statistic.Label>
                RRIF
                {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
              </Statistic.Label>
              <Statistic.Value>
                {this.formatNum(
                  result.yearlyDetails[
                    Object.keys(result.yearlyDetails)[
                      Object.keys(result.yearlyDetails).length - 1
                    ]
                  ].endingRRIF
                )}
              </Statistic.Value>
            </Statistic>
            <Statistic
              size="tiny"
              style={{ marginRight: '4rem', marginLeft: '4rem' }}
            >
              <Statistic.Label>
                TFSA
                {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
              </Statistic.Label>
              <Statistic.Value>
                {this.formatNum(
                  result.yearlyDetails[
                    Object.keys(result.yearlyDetails)[
                      Object.keys(result.yearlyDetails).length - 1
                    ]
                  ].endingTFSA
                )}
              </Statistic.Value>
            </Statistic>
          </div>
          <div style={{ textAlign: 'center', margin: '1rem 0' }}>
            <Statistic size="tiny">
              <Statistic.Label>
                Total
                {/* <Popup
                    trigger={<Icon name="question circle outline" />}
                    position="top center"
                    content="Adjusted for inflation"
                    wide
                  /> */}
              </Statistic.Label>
              <Statistic.Value>
                {this.formatNum(result.sumEndingValues)}
              </Statistic.Value>
            </Statistic>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

FullScreenModal.propTypes = {
  data: PropTypes.object.isRequired,
  filtered: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  closeFullscreen: PropTypes.func.isRequired,
  result: PropTypes.object,
};

FullScreenModal.defaultProps = {
  result: {},
};

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: scroll;
`;

const Table = styled.table`
  text-align: center;
  padding: 0;
  width: 100%;

  tr {
    width: 100%;
  }

  td,
  th {
    border: 1px solid #eaecef;
    padding: 6px 12px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      color: rgba(0, 0, 0, 0.8);
      width: 168px;
    }

    &:last-child {
      border-right: 0;
    }

    &.title {
      font-weight: 700;
      min-width: 12rem;
      text-align: left;
    }

    &.short {
      width: 15%;
      max-width: 11rem;
      min-width: 7rem;
    }

    &.text {
      font-size: 0.9rem;
    }
  }

  th {
    background: #fafbfc;

    &:first-child {
      background: white;
      border-top: 0;
    }
  }

  &.fixed {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 168px;
    background: white;
    padding-top: 79px;

    td {
      border-right: 1px solid #eaecef;
    }
  }
`;
