import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Api, Analytics } from 'actions';
import { withRouter } from 'react-router';
import { Button as CustomButton, Modal } from 'components';
import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import Bar from './Bar';

class Holdings extends Component {
  state = {
    tickerExchanges: {},
  };
  componentWillMount = () => {
    const { holdings, dispatch } = this.props;
    const tickers_string = holdings
      .filter((item) => item.name !== 'GIC or HISA')
      .map((item) => item.ticker)
      .join(',');
    dispatch(
      Api.request('get', '/security/', {
        query: `?symbols=${tickers_string}`,
      })
    ).then((result) => {
      this.setState({ tickerExchanges: result });
    });
  };
  getExchangeSymbol(ticker, tickerExchanges) {
    for (let i = 0; i < tickerExchanges.length; i++) {
      if (tickerExchanges[i].ticker === ticker) {
        return tickerExchanges[i].exchange_symbol;
      }
    }
    return '';
  }

  isLinked = (port) => {
    if (!port) {
      return false;
    }

    if (
      port.portfolio_type === 'custom' ||
      port.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return port.is_linked;
  };

  generatePDF = () => {
    const { history } = this.props;

    return history.push(`/portfolio/unsaved/pdf`);
  };

  handlePrint = () => {
    const { partnerName, dispatch, history } = this.props;

    if (isGlobeInvestor(partnerName)) {
      dispatch(
        Analytics.updatePrintParams({
          printLetterGrade: true,
        })
      );
      return history.push(`/portfolio/unsaved/pdf`);
    }
    return dispatch(
      Analytics.updatePrintParams({
        printModal: true,
      })
    );
  };

  renderCheckboxes = () => {
    const { dispatch, printParams, esg, portfolio } = this.props;
    const leftArr = [
      'Overview',
      'Historical Performance Graph',
      'Performance',
      'Downside',
      'Fees',
      'Income',
    ];
    const rightArr = [
      'Diversification',
      'Correlation',
      'Asset Class',
      'Individual',
      'Region',
      'Global Stock Sector',
      'Macro Factor',
      'Equity Style',
      'ESG',
    ];
    const subClass = [
      'Correlation',
      'Asset Class',
      'Individual',
      'Region',
      'Global Stock Sector',
      'Macro Factor',
      'Equity Style',
    ];
    const isSubclass = (name) => subClass.includes(name);
    const fromToggle = true;
    const checkbox = (name) => (
      <div
        className="ui checkbox"
        style={{
          display: 'block',
          margin: isSubclass(name) ? '1rem 0 0 2rem' : '1rem 0 0 0',
        }}
      >
        <input
          type="checkbox"
          // If esg has been retrieved, default checkes as others
          // If not, esg default to unchecked
          checked={printParams[name]}
          onChange={() => {
            if (
              name === 'ESG' &&
              Object.keys(esg).length === 0 &&
              !printParams.ESG
            ) {
              dispatch(Analytics.fetchEsg(portfolio, fromToggle));
            }

            dispatch(
              Analytics.updatePrintParams({
                [name]: !printParams[name],
              })
            );
          }}
        />
        <label style={{ fontSize: '1.2em', fontWeight: '700' }}>{name}</label>
      </div>
    );

    return (
      <div style={{ padding: '0.5rem 1.5rem' }}>
        <div style={{ width: '49%', float: 'left' }}>
          {(leftArr || []).map((elem) => checkbox(elem))}
        </div>
        <div style={{ width: '49%', float: 'right' }}>
          {(rightArr || []).map((elem) => checkbox(elem))}
        </div>
      </div>
    );
  };

  render() {
    const {
      dispatch,
      holdings,
      portfolio,
      hasExcludedHolding,
      printParams,
      history,
      partnerName,
    } = this.props;
    const { tickerExchanges } = this.state;
    const { printModal, printLetterGrade } = printParams;
    const letterGradeInclHint =
      printLetterGrade === null
        ? ''
        : printLetterGrade
        ? 'Letter grades and percentage scores included'
        : 'Letter grades and percentage scores not included';

    return (
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Header size="medium" style={{ position: 'relative' }}>
            {'Portfolio Overview'}
            {portfolio.account && Object.keys(portfolio.account).length ? (
              <Header.Subheader style={{ transform: 'translateY(5px)' }}>
                {portfolio.account.user_institution ===
                'Overview; All Accounts' ? (
                  <React.Fragment>
                    Overview <br />
                    All Accounts
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {portfolio.account.user_institution ? (
                      <Value
                        className={
                          portfolio.account.user_institution &&
                          portfolio.account.user_institution.length > 35
                            ? 'long'
                            : ''
                        }
                      >
                        {portfolio.account.user_institution}
                      </Value>
                    ) : null}
                    <br />
                    {portfolio.account.label}
                  </React.Fragment>
                )}
              </Header.Subheader>
            ) : portfolio.name ? (
              <Header.Subheader style={{ transform: 'translateY(5px)' }}>
                {portfolio.name}
                {portfolio.description ? (
                  <div>{portfolio.description}</div>
                ) : null}
              </Header.Subheader>
            ) : null}
          </Header>
          <CustomButton
            className="right"
            color={colors.red}
            onClick={this.handlePrint}
            icon="download"
            style={{ fontWeight: 'bold' }}
          >
            {isGlobeInvestor(partnerName)
              ? 'Print/PDF Full Report'
              : 'Print/PDF'}
          </CustomButton>
        </div>

        <Divider style={{ margin: '12px 0' }} />

        <Wrapper limitHeight={holdings.length > 8}>
          {holdings.map((val) => (
            <Bar
              key={val.ticker}
              ticker={this.isLinked(portfolio) ? val.ticker : ''}
              name={this.isLinked(portfolio) ? val.name : val.wsName}
              exchange_symbol={this.getExchangeSymbol(
                val.ticker,
                tickerExchanges
              )}
              weight={val.weight}
              error={val.isExcluded}
              replaced={val.replacedBy}
              dispatch={dispatch}
              id="undefined"
              history={history}
            />
          ))}
        </Wrapper>
        {hasExcludedHolding ? (
          <p style={{ color: colors.hintRed, fontSize: '1rem' }}>
            Please check the list of holdings to see if any of your holdings
            have been excluded from the analysis. Hover over "Guidelines" above
            for our rules. To use all of the holdings in the analysis, use the
            toggle at the top left.
          </p>
        ) : null}

        <Modal open={printModal}>
          <CloseIcon
            onClick={() => {
              dispatch(
                Analytics.updatePrintParams({
                  printModal: false,
                  printLetterGrade: null,
                })
              );
            }}
          >
            +
          </CloseIcon>
          <Header
            content="Would you like to include the letter grades and percentage scores in the PDF of your scorecard?"
            subheader='Note: To save the scorecard to your computer, please select "Save
              as PDF" as your Print destination.'
            style={{ padding: '2rem 0 0 2rem' }}
          />
          <div style={{ padding: '1rem', textAlign: 'right' }}>
            <CustomButton
              className="left"
              color={colors.red}
              icon="cancel"
              margin="0 0.5rem 0 0"
              onClick={() =>
                dispatch(
                  Analytics.updatePrintParams({
                    printLetterGrade: false,
                  })
                )
              }
            >
              No
            </CustomButton>
            <CustomButton
              className="left"
              color={colors.green}
              icon="checkmark"
              onClick={() =>
                dispatch(
                  Analytics.updatePrintParams({
                    printLetterGrade: true,
                  })
                )
              }
            >
              Yes
            </CustomButton>
          </div>

          <Header
            size="small"
            style={{ textAlign: 'right', margin: '0 1rem 0 0' }}
          >
            <i>{letterGradeInclHint}</i>
          </Header>

          {printLetterGrade !== null ? (
            <div style={{ padding: '2rem' }}>
              <Header>Which pages would you like to include?</Header>
              {this.renderCheckboxes()}
              <div
                style={{
                  clear: 'both',
                  paddingTop: '2rem',
                  marginBottom: '2rem',
                  textAlign: 'center',
                }}
              >
                <CustomButton
                  className="left"
                  color={colors.green}
                  icon="checkmark"
                  onClick={() => {
                    dispatch(
                      Analytics.updatePrintParams({
                        printModal: false,
                      })
                    );
                    this.generatePDF();
                  }}
                >
                  Done
                </CustomButton>
              </div>
            </div>
          ) : null}
        </Modal>
      </Container>
    );
  }
}

Holdings.propTypes = {
  portfolio: PropTypes.object.isRequired,
  holdings: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hasExcludedHolding: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  printParams: PropTypes.object,
  esg: PropTypes.object,
  partnerName: PropTypes.string,
};

Holdings.defaultProps = {
  printParams: {},
  esg: {},
  partnerName: '',
};

export default withRouter(
  connect((state) => ({
    printParams: state.Analytics.printParams,
    esg: state.Analytics.esg,
    partnerName: state.Storage['partner-name'],
  }))(Holdings)
);

const Container = styled.div`
  border-radius: 8px;
  width: 50%;
  background: white;
  margin-bottom: 50px;
`;

const Wrapper = styled.div`
  height: ${(props) => (props.limitHeight ? '430px' : '350px')};
  overflow: auto;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 19px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;

const Value = styled.span`
  position: absolute;
  left: 0rem;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  transition: background 0.1s ease;
  z-index: 9999;
  &.long:hover {
    background: ${colors.orange};
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 3px;
    width: auto;
    max-width: unset;
    white-space: normal;
    overflow: unset;
  }
`;
