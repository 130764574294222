import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header, Popup, Progress, Icon } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';

import { Analytics } from 'actions';
import { Table, Score, Loading } from 'components';
import { colors, graph } from 'utils/colors';
import esgDistributionData from './static/esg-data.json';

@connect((state) => ({
  esg: state.Analytics.esg,
  analytics: state.Analytics,
}))
export default class ESG extends Component {
  componentWillMount() {
    const { analytics, dispatch, esg } = this.props;
    const { data } = analytics;

    if (!Object.keys(esg).length) {
      dispatch(Analytics.fetchEsg(data.portfolio));
    }

    return false;
  }

  getDate = (lastUpdated) => {
    const date = lastUpdated.split('-');
    const year = date[0];
    const month = date[1];
    const day = date[2];

    const monthName = {
      '01': 'Jan.',
      '02': 'Feb.',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'Aug.',
      '09': 'Sept.',
      10: 'Oct.',
      11: 'Nov.',
      12: 'Dec.',
    };

    return `${monthName[month]} ${day}, ${year}`;
  };

  progressBarColor = (value) => {
    if (value < 20) return '#F57673';
    if (value >= 20 && value < 40) return '#FAA158';
    if (value >= 40 && value < 60) return '#FCC984';
    if (value >= 60 && value < 80) return '#BEE3A5';
    return '#7EC74D';
  };

  barChartData = () => ({
    labels: Object.keys(esgDistributionData),
    datasets: [
      {
        label: 'Stock',
        stack: 'Dataset 1',
        backgroundColor: graph.blue,
        data: Object.values(esgDistributionData).map(
          (obj) => obj['Stock Percent']
        ),
      },
      {
        label: 'Fund',
        stack: 'Dataset 2',
        backgroundColor: graph.darkBlue,
        data: Object.values(esgDistributionData).map(
          (obj) => obj['Fund Percent']
        ),
      },
    ],
  });

  render() {
    const { esg } = this.props;

    if (!Object.keys(esg).length) {
      return <Loading active />;
    }

    const { portfolio } = esg || {};
    const { esg_score: portfolioEsgScore, ws_grade, last_updated } = portfolio;
    const tickers = Object.keys(esg)
      .filter((t) => t !== 'portfolio')
      .sort((a, b) => Object.keys(esg[b]).length - Object.keys(esg[a]).length);
    const withEsg = new Set(
      Object.keys(esg).filter((t) => Object.keys(esg[t]).length > 2)
    );

    return (
      <Container>
        <Score
          name="Environmental &#65372; Social &#65372; Governance&nbsp;&nbsp;"
          score={ws_grade[0] ? `${ws_grade[0]}%` : null}
          grade={ws_grade[1]}
          pdf
        />

        <p>
          ESG data is powered by{' '}
          <a
            href="https://owlesg.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            OWL ESG
          </a>
          .<br /> <br />
          OWL employs a big data approach to ESG metrics and sustainable
          investing. They incorporate data from a variety of sources including
          corporate disclosures and filings, news sources, NGOs, research firms,
          and more. OWL’s consensus modelling optimizes multiple viewpoints to
          avoid subjective scoring. Mutual fund and ETF data is aggregated from
          individual holdings’ scores.
          <br /> <br />
          How should you interpret the ESG data below? There are two pieces of
          information you should consider for each category: a raw OWL score on
          a 0-100 scale, and a percentile that compares the score of a stock or
          fund to its peers'. The former is an absolute number, and the latter
          is a relative standing. In both cases, a higher number is better. The
          overall Wealthscope grade at the top considers the tight distribution
          of the OWL scores for funds (see chart below), if you have any in your
          portfolio.{' '}
          <Popup
            trigger={
              <Icon
                name="question circle outline"
                style={{
                  fontSize: '15px',
                  verticalAlign: 'initial',
                  color: 'dimgrey',
                }}
              />
            }
            position="right center"
            content="ESG scores for mutual funds and ETFs have a tighter distribution because they diversify by holding many securities."
          />
        </p>
        <p style={{ textalign: 'center' }}>
          Percentage of holdings with ESG scores by weight:{' '}
          <span style={{ color: colors.blue }}>
            <b>{(portfolio.weight * 100).toFixed(2)}%</b>
          </span>
        </p>

        {portfolioEsgScore ? (
          <div>
            <span style={{ color: 'grey' }}>
              As of {this.getDate(last_updated)}
            </span>
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="right center"
              content="Date when the ESG data is received from Owl Analytics."
            />
          </div>
        ) : null}
        <div style={{ maxWidth: '47rem' }}>
          <Header
            size="medium"
            content="Distribution of OWL's Individual Stock and Fund ESG Scores"
          />
          <Bar data={this.barChartData()} options={chartOptions} />
        </div>
        <Header dividing size="medium">
          <span>
            Individual ESG Scores
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="right center"
              content="The higher the score the better. Hover over the information icon (i) to see the sub-category scores."
            />
          </span>
          <Header.Subheader>
            Regional sector percentiles for stocks
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                    margin: '0',
                  }}
                />
              }
              position="bottom center"
              content="A higher percentile indicates a higher ranking amongst the firm’s regional sector peers."
            />
            ; Asset class percentiles for funds
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              position="bottom center"
              content="A higher percentile indicates a higher ranking amongst the fund’s asset class peers."
            />
          </Header.Subheader>
        </Header>

        <Table basic fixed textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
              <Table.HeaderCell width={2}>Weight</Table.HeaderCell>
              <Table.HeaderCell width={2}>ESG</Table.HeaderCell>
              <Table.HeaderCell width={2}>E</Table.HeaderCell>
              <Table.HeaderCell width={2}>S</Table.HeaderCell>
              <Table.HeaderCell width={2}>G</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tickers.map((ticker) => {
              const esgData = esg[ticker];
              const {
                esg_score,
                esg_percentile,
                e_score,
                e_percentile,
                e1score,
                e1percentile,
                e2score,
                e2percentile,
                e3score,
                e3percentile,
                s_score,
                s_percentile,
                emp1score,
                emp1percentile,
                emp2score,
                emp2percentile,
                emp3score,
                emp3percentile,
                cit1score,
                cit1percentile,
                cit2score,
                cit2percentile,
                cit3score,
                cit3percentile,
                g_score,
                g_percentile,
                g1score,
                g1percentile,
                g2score,
                g2percentile,
                g3score,
                g3percentile,
              } = esgData;

              return (
                <Table.Row>
                  <Table.Cell textAlign="left">
                    {esgData.name || ticker}
                  </Table.Cell>
                  <Table.Cell>{`${(esgData.weight * 100).toFixed(
                    2
                  )}%`}</Table.Cell>
                  <Table.Cell>
                    {esg_score}&nbsp;&nbsp;
                    {esg_percentile ? (
                      <ColoredProgress
                        color={this.progressBarColor(
                          (esg_percentile * 100).toFixed(0)
                        )}
                      >
                        <Progress
                          percent={(esg_percentile * 100).toFixed(0)}
                          size="small"
                          progress
                          style={{ margin: '0' }}
                        />
                      </ColoredProgress>
                    ) : withEsg.has(ticker) ? null : (
                      '—'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {e_score}
                    &nbsp;
                    {withEsg.has(ticker) ? (
                      <Popup
                        trigger={<Icon name="info circle" />}
                        position="bottom center"
                        wide
                        content={
                          <div>
                            <div>
                              Pollution Prevention
                              <ProgressBarInPopup>
                                {e1score}
                                <br />
                                {e1percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (e1percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(e1percentile * 100).toFixed(0)}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Environmental Transparency
                              <ProgressBarInPopup>
                                {e2score}
                                <br />
                                {e2percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (e2percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(e2percentile * 100).toFixed(0)}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Resource Efficiency
                              <ProgressBarInPopup>
                                {e3score}
                                <br />
                                {e3percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (e3percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(e3percentile * 100).toFixed(0)}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                          </div>
                        }
                      />
                    ) : null}
                    {e_percentile ? (
                      <ColoredProgress
                        color={this.progressBarColor(
                          (e_percentile * 100).toFixed(0)
                        )}
                      >
                        <Progress
                          percent={(e_percentile * 100).toFixed(0)}
                          size="small"
                          progress
                          style={{ margin: '0' }}
                        />
                      </ColoredProgress>
                    ) : withEsg.has(ticker) ? null : (
                      '—'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {s_score}
                    &nbsp;
                    {withEsg.has(ticker) ? (
                      <Popup
                        trigger={<Icon name="info circle" />}
                        position="bottom center"
                        wide
                        content={
                          <div>
                            <div>
                              Compensation & Satisfaction
                              <ProgressBarInPopup>
                                {emp1score}
                                <br />
                                {emp1percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (emp1percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(emp1percentile * 100).toFixed(
                                        0
                                      )}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Diversity & Rights
                              <ProgressBarInPopup>
                                {emp2score}
                                <br />
                                {emp2percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (emp2percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(emp2percentile * 100).toFixed(
                                        0
                                      )}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Education & Work Conditions
                              <ProgressBarInPopup>
                                {emp3score}
                                <br />
                                {emp3percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (emp3percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(emp3percentile * 100).toFixed(
                                        0
                                      )}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Community & Charity
                              <ProgressBarInPopup>
                                {cit1score}
                                <br />
                                {cit1percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (cit1percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(cit1percentile * 100).toFixed(
                                        0
                                      )}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Human Rights
                              <ProgressBarInPopup>
                                {cit2score}
                                <br />
                                {cit2percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (cit2percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(cit2percentile * 100).toFixed(
                                        0
                                      )}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Sustainability Integration
                              <ProgressBarInPopup>
                                {cit3score}
                                <br />
                                {cit3percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (cit3percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(cit3percentile * 100).toFixed(
                                        0
                                      )}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                          </div>
                        }
                      />
                    ) : null}
                    {s_percentile ? (
                      <ColoredProgress
                        color={this.progressBarColor(
                          (s_percentile * 100).toFixed(0)
                        )}
                      >
                        <Progress
                          percent={(s_percentile * 100).toFixed(0)}
                          size="small"
                          progress
                          style={{ margin: '0' }}
                        />
                      </ColoredProgress>
                    ) : withEsg.has(ticker) ? null : (
                      '—'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {g_score}
                    &nbsp;
                    {withEsg.has(ticker) ? (
                      <Popup
                        trigger={<Icon name="info circle" />}
                        position="left center"
                        wide
                        content={
                          <div>
                            <div>
                              Board Effectiveness
                              <ProgressBarInPopup>
                                {g1score}
                                <br />
                                {g1percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (g1percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(g1percentile * 100).toFixed(0)}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Management Ethics
                              <ProgressBarInPopup>
                                {g2score}
                                <br />
                                {g2percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (g2percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(g2percentile * 100).toFixed(0)}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                            <br />
                            <div>
                              Disclosure & Accountability
                              <ProgressBarInPopup>
                                {g3score}
                                <br />
                                {g3percentile ? (
                                  <ColoredProgress
                                    color={this.progressBarColor(
                                      (g3percentile * 100).toFixed(0)
                                    )}
                                    style={{ float: 'right' }}
                                  >
                                    <Progress
                                      percent={(g3percentile * 100).toFixed(0)}
                                      size="small"
                                      progress
                                      style={{ margin: '0' }}
                                    />
                                  </ColoredProgress>
                                ) : null}
                              </ProgressBarInPopup>
                            </div>
                          </div>
                        }
                      />
                    ) : null}
                    {g_percentile ? (
                      <ColoredProgress
                        color={this.progressBarColor(
                          (g_percentile * 100).toFixed(0)
                        )}
                      >
                        <Progress
                          percent={(g_percentile * 100).toFixed(0)}
                          size="small"
                          progress
                          style={{ margin: '0' }}
                        />
                      </ColoredProgress>
                    ) : withEsg.has(ticker) ? null : (
                      '—'
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <div style={{ display: 'blcok', margin: '0 0 2em' }}>
          <div
            style={{
              width: '33.3333%',
              display: 'inline-flex',
              margin: '1em 0',
              flex: ' 0 1 auto',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                fontSize: '2rem',
                textAlign: 'center',
                lineHeight: '1em',
              }}
            >
              {portfolio.e_score}
            </div>
            <div
              style={{
                width: '100%',
                fontSize: '1rem',
                textAlign: 'center',
                fontWeight: '700',
                color: 'rgba(0,0,0,.87)',
                marginTop: '0',
              }}
            >
              WEIGHTED AVERAGE
              <span style={{ fontSize: '1.4em', color: colors.blue }}> E </span>
              SCORE
            </div>
          </div>
          <div
            style={{
              width: '33.3333%',
              display: 'inline-flex',
              margin: '1em 0',
              flex: ' 0 1 auto',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                fontSize: '2rem',
                textAlign: 'center',
                lineHeight: '1em',
              }}
            >
              {portfolio.s_score}
            </div>
            <div
              style={{
                width: '100%',
                fontSize: '1rem',
                textAlign: 'center',
                fontWeight: '700',
                color: 'rgba(0,0,0,.87)',
                marginTop: '0',
              }}
            >
              WEIGHTED AVERAGE{' '}
              <span style={{ fontSize: '1.4em', color: colors.blue }}> S </span>{' '}
              SCORE
            </div>
          </div>
          <div
            style={{
              width: '33.3333%',
              display: 'inline-flex',
              margin: '1em 0',
              flex: ' 0 1 auto',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                fontSize: '2rem',
                textAlign: 'center',
                lineHeight: '1em',
              }}
            >
              {portfolio.g_score}
            </div>
            <div
              style={{
                width: '100%',
                fontSize: '1rem',
                textAlign: 'center',
                fontWeight: '700',
                color: 'rgba(0,0,0,.87)',
                marginTop: '0',
              }}
            >
              WEIGHTED AVERAGE{' '}
              <span style={{ fontSize: '1.4em', color: colors.blue }}> G </span>{' '}
              SCORE
            </div>
          </div>
          <div>
            <div
              style={{
                width: '100%',
                fontSize: '2rem',
                textAlign: 'center',
                lineHeight: '1em',
              }}
            >
              {portfolio.esg_score}
            </div>
            <div
              style={{
                width: '100%',
                fontSize: '1rem',
                textAlign: 'center',
                fontWeight: '700',
                color: 'rgba(0,0,0,.87)',
                marginTop: '0',
              }}
            >
              AVERAGE PORTFOLIO
              <span style={{ fontSize: '1.4em', color: colors.blue }}>
                {' '}
                ESG{' '}
              </span>{' '}
              SCORE
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const chartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItem) => `${tooltipItem.yLabel}%`,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          callback: (value) => `${value}%`,
        },
        scaleLabel: {
          display: true,
          labelString: 'Percentage of Stocks/Funds',
        },
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'ESG Score',
        },
      },
    ],
  },
};

ESG.propTypes = {
  esg: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  analytics: PropTypes.object.isRequired,
};

ESG.defaultProps = { dispatch: () => false };

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;

const ColoredProgress = styled.div`
  width: 110px;
  margin: 0 auto;

  .ui.progress .bar {
    background: ${(props) => props.color};
  }

  .ui.progress .bar > .progress {
    right: 0.3em;
    color: ${colors.darkGrey};
  }
`;

const ProgressBarInPopup = styled.div`
  display: inline-block;
  text-align: center;
  float: right;
  margin-left: 1rem;
`;
