import React from 'react';
import WS_Loader from 'images/WS_Loader.gif';
import PropTypes from 'prop-types';

const defaultStyles = {
  display: 'flex',
  width: '100%',
  // height: '100%',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.7)',
  zIndex: '999',
};

const LogoSpinner = ({ style, logoSideLength }) => (
  <div style={{ ...defaultStyles, ...style }}>
    <img
      src={WS_Loader}
      height={logoSideLength}
      width="auto"
      alt="Wealthscope"
    />
  </div>
);

LogoSpinner.propTypes = {
  style: PropTypes.object,
  logoSideLength: PropTypes.number,
};

LogoSpinner.defaultProps = {
  style: {},
  logoSideLength: 80,
};

export default LogoSpinner;
