import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Statistic, Header, Popup, Icon } from 'semantic-ui-react';

import { colors } from 'utils/colors';
import { Score } from 'components';
import { Table } from '../components';
import { translateText } from '../../../../../utils/helpers';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class Diversification extends Component {
  getLongestText = (obj) =>
    Object.keys(obj).reduce((acc, key) => {
      const name = this.getName(key);

      if (name.length > acc.length) {
        acc = name;
      }

      return acc;
    }, '');

  getName = (ticker) => {
    const { data } = this.props;
    const { tickersValid } = data;

    return this.isLinked(data)
      ? tickersValid[ticker].name
      : tickersValid[ticker].wsName;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  parseDate = (portfolio) => {
    const { french } = this.props;
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const months = french
      ? {
          '01': 'Janvier',
          '02': 'Février',
          '03': 'Mars',
          '04': 'Avril',
          '05': 'Mai',
          '06': 'Juin',
          '07': 'Juillet',
          '08': 'Août',
          '09': 'Septembre',
          10: 'Octobre',
          11: 'Novembre',
          12: 'Décembre',
        }
      : {
          '01': 'January',
          '02': 'February',
          '03': 'March',
          '04': 'April',
          '05': 'May',
          '06': 'June',
          '07': 'July',
          '08': 'August',
          '09': 'September',
          10: 'October',
          11: 'November',
          12: 'December',
        };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  shouldHighlight = (corr) => corr >= 0.7 && corr < 1;

  formatNum = (num) => (num ? num.toFixed(2) : null);

  renderMatrix = (data) => {
    const { french } = this.props;
    return (
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <Statistic size="tiny">
          <Statistic.Label>
            {french
              ? 'CORRÉLATION MOYENNE PONDÉRÉE TOTALE DU PORTEFEUILLE'
              : 'TOTAL PORTFOLIO WEIGHTED AVERAGE CORRELATION'}
          </Statistic.Label>
          <Statistic.Value>{Math.round(data.ipc * 100) / 100}</Statistic.Value>
        </Statistic>
        <Header style={{ marginTop: 0, marginBottom: '1rem' }}>
          <Header.Subheader>
            {translateText(
              'Correlation coefficients greater than or equal to 0.70 will be flagged.',
              french
            )}
          </Header.Subheader>
        </Header>
        <TableWrapper>
          <Table definition fixedColumn textAlign="center">
            <Table.Row>
              <Table.HeaderCell hidden>
                {this.getLongestText(data.corr)}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Body>
              {Object.keys(data.corr).map((key) => (
                <Table.Row key={key}>
                  <Table.Cell>{this.getName(key)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Table celled definition textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {Object.keys(data.corr).map((key) => (
                  <Table.HeaderCell key={key}>
                    {this.getName(key) === 'Cash' ? (
                      <div>
                        {french ? 'Argent' : 'Cash'}&nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="right center"
                          wide
                          content={
                            french
                              ? "Bien que la covariance entre l'argent et n'importe quel actif soit nulle, le coefficient de corrélation est indéfini car il a l'écart type de l'argent au dénominateur, qui est également nul."
                              : 'While the covariance between cash and any asset is zero, the correlation coefficient is undefined because it has the standard deviation of cash in the denominator, which is also zero.'
                          }
                        />
                      </div>
                    ) : (
                      this.getName(key)
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Object.keys(data.corr).map((key) => (
                <Table.Row key={key}>
                  <Table.Cell>{this.getName(key)}</Table.Cell>
                  {
                    /* eslint-disable */
                    Object.values(data.corr[key]).map((value, idx) => (
                      <Table.Cell
                        key={idx}
                        style={{
                          background:
                            this.shouldHighlight(value) && colors.negative,
                          color: this.shouldHighlight(value) && colors.hintRed,
                        }}
                      >
                        {value === 0 ? '-' : this.formatNum(value)}
                      </Table.Cell>
                    ))
                    /* eslint-enable */
                  }
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </TableWrapper>
      </div>
    );
  };

  renderPairs = (data) => {
    const { french } = this.props;
    const parsedData = Object.keys(data.corr).reduce((total, key) => {
      Object.keys(data.corr[key]).forEach((key2) => {
        if (
          data.corr[key][key2] &&
          data.corr[key][key2] < 1 &&
          data.corr[key][key2] >= 0.7
        ) {
          if (!total[key]) {
            total[key] = [
              {
                name: data.tickersValid[key].name,
                ticker: key,
                matchTicker: key2,
                matchName: data.tickersValid[key2].name,
                corr: data.corr[key][key2],
                rowSpan: 1,
              },
            ];
          } else {
            total[key][0].rowSpan += 1;
            total[key].push({
              name: data.tickersValid[key].name,
              ticker: key,
              matchTicker: key2,
              matchName: data.tickersValid[key2].name,
              corr: data.corr[key][key2],
              exclude: true,
            });
          }
        }
      });

      return total;
    }, {});

    const filtered = Object.values(parsedData).reduce((total, value, index) => {
      if (value.length === 1) {
        total.push({ key: index, item: value[0] });
      } else {
        value.forEach((item, idx) =>
          total.push({ key: `${index}${idx}`, item })
        );
      }

      return total;
    }, []);

    return (
      <div style={{ textAlign: 'center' }}>
        <Statistic size="tiny" style={{ marginBottom: '1rem' }}>
          <Statistic.Label>
            {french
              ? 'CORRÉLATION MOYENNE PONDÉRÉE TOTALE DU PORTEFEUILLE'
              : 'TOTAL PORTFOLIO WEIGHTED AVERAGE CORRELATION'}
          </Statistic.Label>
          <Statistic.Value>{Math.round(data.ipc * 100) / 100}</Statistic.Value>
        </Statistic>

        <Table correlation>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell colSpan="3">
                {french
                  ? "Paires d'actifs avec une corrélation supérieure ou égale à 0.70"
                  : 'Asset pairs with correlation greater than or equal to 0.70'}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={6}>
                {french ? 'Actif' : 'Asset'}
              </Table.HeaderCell>
              <Table.HeaderCell
                width={6}
                style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }} // Border styles from table were not applying, so adding here
              >
                {french ? 'Actif' : 'Asset'}
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}
              >
                {french ? 'Corrélation' : 'Correlation'}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filtered.length ? (
              filtered.map((item) => (
                <Table.Row key={item.key}>
                  {!item.item.exclude && (
                    <Table.Cell
                      rowSpan={
                        item.item.rowSpan > 1 ? item.item.rowSpan : undefined
                      }
                    >
                      {item.item.ticker}
                      <SecurityName>{item.item.name}</SecurityName>
                    </Table.Cell>
                  )}
                  <Table.Cell
                    style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}
                  >
                    {item.item.matchTicker}
                    <SecurityName>{item.item.matchName}</SecurityName>
                  </Table.Cell>
                  <Table.Cell
                    style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}
                  >
                    {Math.round(item.item.corr * 100) / 100}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell>N/A</Table.Cell>
                <Table.Cell
                  style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}
                >
                  N/A
                </Table.Cell>
                <Table.Cell
                  style={{ borderLeft: '1px solid rgba(34, 36, 38, 0.1)' }}
                >
                  N/A
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  render() {
    const { data } = this.props;
    const { french } = this.props;

    return (
      <Container>
        <Score
          name="Diversification "
          score={`${data.score.Diversification[0]}%`}
          grade={data.score.Diversification[1]}
          description={
            french
              ? 'Notée sur la base de la corrélation moyenne de votre portefeuille et des quatre dimensions de la diversification entre différentes a) classes d’actifs, b) régions géographiques, c) secteurs d’activité et d) facteurs macroéconomiques.'
              : "Scored using your portfolio's average correlation and four dimensions of diversification across a) asset class, b) geographical region, c) business sectors, and d) macroeconomic factors."
          }
          portfolio={data.portfolio}
        />

        <p>
          {french
            ? 'Faudrait-il refuser un repas gratuit?'
            : 'Should you give up a free lunch?'}
        </p>
        <p>
          {french
            ? 'La réponse est un NON catégorique lorsqu’il s’agit de vos investissements. En diversifiant correctement votre portefeuille et en choisissant des actifs avec des cours distincts les uns des autres, vous pouvez réduire le niveau de risque de votre portefeuille sans en sacrifier le rendement. Notre article '
            : 'The answer is a resounding NO when it comes to your investments. By diversifying properly and choosing assets that do not move together in locked steps, you can reduce the amount of risk in your portfolio without sacrificing returns. Read a non-technical discussion of the benefits of diversification in our article, '}
          <a
            href={
              french
                ? 'https://www.wealthscope.ca/financial-advice-from-shakespeare-fr'
                : 'https://www.wealthscope.ca/financial-advice-from-shakespeare'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {french
              ? 'Conseils financiers de Shakespeare'
              : 'Financial Advice from Shakespeare'}
          </a>
          {french
            ? ' présente une analyse vulgarisée des avantages de la diversification'
            : null}
          .
        </p>
        <p>
          {french
            ? 'Sachez que le simple fait d’ajouter de nouveaux actifs à votre portefeuille n’est pas nécessairement synonyme d’une diversification efficace. Par exemple, si tous vos actifs sont exposés au même facteur de risque, la diversification demeure superficielle. Il est important de diversifier votre capture de risque à travers différents types de risques, comme nous l’expliquons dans notre bref article '
            : 'Be aware that merely adding more assets to your portfolio does not necessarily mean that you are diversifying effectively. For example, if all of your assets are exposed to the same risk factor, then the diversification is superficial. It is important to diversify across different types of risk exposures, as we explain in our short article, '}
          <a
            href={
              french
                ? 'https://www.wealthscope.ca/not-all-risks-are-created-equal-fr'
                : 'https://www.wealthscope.ca/not-all-risks-are-created-equal'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {french
              ? 'Tous les risques ne se valent pas'
              : 'Not all Risks are Created Equal'}
          </a>
          .
        </p>

        <Header dividing size="medium">
          {Object.keys(data.corr).length < 15 ? (
            <div>
              {french ? 'Matrice de corrélation' : 'Correlation Matrix'}&nbsp;
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="right center"
                wide
                content={
                  french
                    ? "Les coefficients de corrélation prennent une valeur comprise entre -1 et +1. Dans le contexte de l'analyse de portefeuille, ils mesurent comment les rendements de deux actifs évoluent l'un par rapport à l'autre au fil du temps. Plus un coefficient de corrélation est proche de +1, moins il y a de bénéfice à la diversification, ce qui signifie que l'inclusion des deux actifs dans le portefeuille n'est pas efficace pour réduire le risque du portefeuille."
                    : "Correlation coefficients take on a value between -1 and +1. In the context of portfolio analysis, they measure how two assets' returns move in relation to one another over time. The closer a correlation coefficient is to +1, the less benefit there is to diversification, meaning that including both assets in the portfolio are not effective in lowering portfolio risk."
                }
              />
            </div>
          ) : (
            <div>
              {french
                ? 'Coefficients de corrélation'
                : 'Correlation Coefficients'}
              &nbsp;
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="right center"
                wide
                content={
                  french
                    ? "Les coefficients de corrélation prennent une valeur comprise entre -1 et +1. Dans le contexte de l'analyse de portefeuille, ils mesurent comment les rendements de deux actifs évoluent l'un par rapport à l'autre au fil du temps. Plus un coefficient de corrélation est proche de +1, moins il y a de bénéfice à la diversification, ce qui signifie que l'inclusion des deux actifs dans le portefeuille n'est pas efficace pour réduire le risque du portefeuille."
                    : "Correlation coefficients take on a value between -1 and +1. In the context of portfolio analysis, they measure how two assets' returns move in relation to one another over time. The closer a correlation coefficient is to +1, the less benefit there is to diversification, meaning that including both assets in the portfolio are not effective in lowering portfolio risk."
                }
              />
            </div>
          )}

          <Header.Subheader style={{ float: !data.allStock ? 'left' : 'none' }}>
            {this.parseDate(data.portfolio)}{' '}
            {french ? '(inclusif)' : '(inclusive)'}
          </Header.Subheader>
          <br />
          {!data.allStock ? (
            <Header.Subheader
              style={{
                textAlign: 'center',
                color: colors.hintRed,
              }}
            >
              {french
                ? "Veuillez noter que dans le cadre d'un fonds spécifique, les corrélations de ses avoirs sous-jacents ne sont pas utilisées."
                : 'Please note that within a specific fund, the correlations of its underlying holdings are not used.'}
            </Header.Subheader>
          ) : null}
        </Header>

        {Object.keys(data.corr).length < 15
          ? this.renderMatrix(data)
          : this.renderPairs(data)}
      </Container>
    );
  }
}

Diversification.propTypes = {
  data: PropTypes.object.isRequired,
  french: PropTypes.bool,
};

Diversification.defaultProps = { french: false };

const TableWrapper = styled.div`
  overflow-x: auto;
  padding-bottom: 10px;

  .table {
    display: table-caption;
  }

  tr,
  th {
    width: 172px;
    min-width: 170px;
  }
`;

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
