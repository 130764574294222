import React, { Component } from 'react';
import { Pie, HorizontalBar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import assignColors from 'utils/assignColors';

@connect((state) => ({
  french: state.Storage.language === 'fr',
  exchangeRate: state.Storage['pwpa-exchange-rate'],
}))
class AssetChart extends Component {
  getChartData = (accounts) => {
    const filteredAccounts = accounts.filter((account) =>
      Object.values(account.asset_allocation).some((value) => value !== 0)
    );

    const hasCADAccounts = filteredAccounts.some(
      (account) => account.currency === 'CAD'
    );

    const totalHoldings = filteredAccounts.reduce((total, account) => {
      const { exchangeRate } = this.props;
      const balance =
        account.currency === 'USD' && hasCADAccounts
          ? (account.balance - account.cash) * Number(exchangeRate)
          : account.balance - account.cash;

      return total + balance;
    }, 0);

    const categories = ['Equity', 'Fixed Income', 'Alternative', 'Misc'];
    const allHoldings = filteredAccounts.reduce((result, account) => {
      const { exchangeRate } = this.props;
      const { asset_allocation } = account;
      const accountTotalHoldings =
        account.currency === 'USD' && hasCADAccounts
          ? (account.balance - account.cash) * Number(exchangeRate)
          : account.balance - account.cash;
      const weight = accountTotalHoldings / totalHoldings;

      categories.forEach((category) => {
        const label = category === 'Misc' ? 'Misc.' : category;
        result[label] += asset_allocation[category] * weight;
      });

      return result;
    }, Object.fromEntries(categories.map((category) => [category === 'Misc' ? 'Misc.' : category, 0])));

    const filteredHoldings = Object.entries(allHoldings)
      .filter(([category, value]) => {
        if (totalHoldings <= 0) {
          return value !== 0;
        }
        return Math.round(value * 1000) / 100 !== 0;
      })
      .reduce((obj, [category, value]) => {
        obj[category] = value;
        return obj;
      }, {});
    const { french } = this.props;

    const translatedLabels = Object.keys(filteredHoldings).map((label) => {
      switch (label) {
        case 'Equity':
          return french ? 'Capitaux propres' : 'Equity';
        case 'Fixed Income':
          return french ? 'Revenu fixe' : 'Fixed Income';
        case 'Alternative':
          return french ? 'Alternatif' : 'Alternative';
        case 'Misc.':
          return french ? 'Autres' : 'Misc.';
        default:
          return label;
      }
    });

    return {
      labels: translatedLabels,
      datasets: [
        {
          data: Object.values(filteredHoldings),
          backgroundColor: assignColors(translatedLabels),
        },
      ],
    };
  };

  render() {
    const { accounts, french } = this.props;
    const chartData = this.getChartData(accounts);
    const otherCategoryIndex = chartData.labels.findIndex(
      (label) => label === (french ? 'Divers' : 'Misc.')
    );
    const isOtherNegative =
      otherCategoryIndex !== -1 &&
      chartData.datasets[0].data[otherCategoryIndex] < 0;

    const chartOptions = {
      showAllTooltips: !isOtherNegative,
      maintainAspectRatio: true,
      cutoutPercentage: isOtherNegative ? 50 : 65,
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        },
      },
      legend: {
        display: !isOtherNegative,
        position: 'left',
        labels: isOtherNegative ? { display: false } : { display: true },
      },
      tooltips: {
        callbacks: {
          title: (tooltipItems, data) => data.labels[tooltipItems[0].index],
          label: (tooltipItems, data) =>
            `${
              Math.round(
                data.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ] * 10000
              ) / 100
            }%`,
        },
      },
      hover: {
        onHover: (e) => {
          e.target.style.cursor = 'pointer';
        },
      },
    };

    return (
      <div>
        {isOtherNegative ? (
          <HorizontalBar
            data={chartData}
            options={chartOptions}
            style={{
              margin: '0 4rem 0 0',
            }}
          />
        ) : (
          <Pie data={chartData} options={chartOptions} />
        )}
      </div>
    );
  }
}

AssetChart.propTypes = {
  accounts: PropTypes.array,
  french: PropTypes.bool,
  exchangeRate: PropTypes.number,
};

AssetChart.defaultProps = {
  accounts: [],
  french: false,
  exchangeRate: 0,
};

export default AssetChart;
