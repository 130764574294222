import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, Form, Header, Popup, Icon } from 'semantic-ui-react';

import { colors } from 'utils/colors';
import { ErrorLabel, Button } from 'components';
import { Analytics, BuildETF, Storage } from 'actions';

class History extends Component {
  state = {
    error: null,
  };

  componentDidMount = () => {
    const { selectedAssets, dispatch } = this.props;
    dispatch(BuildETF.updateOldestCommonInceptionDate(selectedAssets));
  };

  getEndMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let month = null;

    if (today.getDate() > 10) {
      // Use current month
      month = new Date(today.setMonth(currentMonth));
    } else {
      // Use previous month
      month = new Date(today.setMonth(currentMonth - 1));
    }

    return month.getFullYear() * 12 + month.getMonth() + 1;
  };

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    if (name === 'initMonth') {
      dispatch(BuildETF.setInitMonth(value));
    } else {
      dispatch(BuildETF.setInitYear(value));
    }

    this.setState({ error: null });
  };

  filterMonths = (months) => {
    const { buildETF } = this.props;
    // filter out the months that come before the oldestCommonInceptionDate's month if the user is currently selecting
    // the oldestCommonInceptionDate's year
    if (buildETF.oldestCommonInceptionDateYear === buildETF.initYear) {
      return months.filter(
        (month) => month.value >= buildETF.oldestCommonInceptionDateMonth
      );
    }
    // don't filter out any months for any other year
    return months;
  };

  filterYears = (years) => {
    const { buildETF } = this.props;

    // filter out the years that come before the oldestCommonInceptionDate's month
    return years.filter(
      (year) => year.value >= buildETF.oldestCommonInceptionDateYear
    );
  };

  handleSubmit = () => {
    const {
      selectedAssets,
      history,
      dispatch,
      initMonth,
      initYear,
      esg,
      is_esg_enabled,
    } = this.props;
    const start = Number(initYear * 12) + Number(initMonth);
    const end = this.getEndMonth();
    const params = Object.values(selectedAssets).reduce(
      (result, item) => {
        if (item.weight) {
          result.symbols.push(item.symbol);
          result.weights.push(item.weight / 100);
        }

        return result;
      },
      { symbols: [], weights: [] }
    );

    if (end - start < 48)
      return this.setState({
        error: 'Please select a minimum of 4 years',
      });

    if (is_esg_enabled && Object.keys(esg).length) {
      dispatch(
        Analytics.fetchEsg({
          symbols: params.symbols.join(','),
          weights: params.weights.join(','),
        })
      );
    }

    dispatch(
      Storage.setItem('pwpa-portfolio', {
        init_date: `${initYear}-${initMonth}-01`,
        region: 'CA',
        symbols: params.symbols.join(','),
        weights: params.weights.join(','),
      })
    );

    return history.push('/portfolio/unsaved/overview');
  };

  render() {
    const { goBack, initMonth, initYear } = this.props;
    const { error } = this.state;
    const months = [
      { key: '1', text: 'January', value: '01' },
      { key: '2', text: 'Febuary', value: '02' },
      { key: '3', text: 'March', value: '03' },
      { key: '4', text: 'April', value: '04' },
      { key: '5', text: 'May', value: '05' },
      { key: '6', text: 'June', value: '06' },
      { key: '7', text: 'July', value: '07' },
      { key: '8', text: 'August', value: '08' },
      { key: '9', text: 'September', value: '09' },
      { key: '10', text: 'October', value: '10' },
      { key: '11', text: 'November', value: '11' },
      { key: '12', text: 'December', value: '12' },
    ];
    const years = [
      { key: '1', text: '2007', value: '2007' },
      { key: '2', text: '2008', value: '2008' },
      { key: '3', text: '2009', value: '2009' },
      { key: '4', text: '2010', value: '2010' },
      { key: '5', text: '2011', value: '2011' },
      { key: '6', text: '2012', value: '2012' },
      { key: '7', text: '2013', value: '2013' },
      { key: '8', text: '2014', value: '2014' },
      { key: '9', text: '2015', value: '2015' },
      { key: '10', text: '2016', value: '2016' },
      { key: '11', text: '2017', value: '2017' },
      { key: '12', text: '2018', value: '2018' },
      { key: '13', text: '2019', value: '2019' },
      { key: '14', text: '2020', value: '2020' },
      { key: '15', text: '2021', value: '2021' },
      { key: '16', text: '2022', value: '2022' },
    ];

    return (
      <Form style={{ padding: '1rem' }}>
        <Header size="medium" textAlign="center" style={{ padding: '1rem 0' }}>
          <Header.Content>
            How much history would you like to base your analysis on?
            <Header.Subheader>
              Please specify a start date (minimum 4 years)&nbsp;
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{ fontSize: '15px', verticalAlign: 'initial' }}
                  />
                }
                position="bottom center"
                wide
                style={{ height: 'initial' }}
              >
                If you do not have a strong preference, we recommend starting
                with the maximum history to capture a full market cycle.
              </Popup>
            </Header.Subheader>
          </Header.Content>
        </Header>

        <Form.Field
          inline
          style={{
            position: 'relative',
            textAlign: 'center',
            margin: '2rem auto',
          }}
        >
          <label>Start Date:</label>
          <Dropdown
            name="initMonth"
            placeholder="Month"
            search
            selection
            options={this.filterMonths(months)}
            value={initMonth}
            onChange={this.handleChange}
          />{' '}
          <Dropdown
            name="initYear"
            placeholder="Year"
            search
            selection
            options={this.filterYears(years)}
            value={initYear}
            onChange={this.handleChange}
          />
          <ErrorLabel className="bottom" msg={error} />
        </Form.Field>

        <Button
          className="left"
          color={colors.orange}
          icon="chevron left"
          onClick={(e) => (e.preventDefault(), goBack())}
        >
          Back
        </Button>

        <Button
          className="right"
          color={colors.orange}
          icon="chevron right"
          floated="right"
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </Form>
    );
  }
}

History.propTypes = {
  history: PropTypes.object.isRequired,
  buildETF: PropTypes.object.isRequired,
  selectedAssets: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  initMonth: PropTypes.string.isRequired,
  initYear: PropTypes.string.isRequired,
  is_esg_enabled: PropTypes.bool,
  esg: PropTypes.object,
};

History.defaultProps = {
  dispatch: () => false,
  is_esg_enabled: false,
  esg: {},
};

export default withRouter(
  connect((state) => ({
    buildETF: state.BuildETF,
    selectedAssets: state.BuildETF.selectedAssets,
    initMonth: state.BuildETF.initMonth,
    initYear: state.BuildETF.initYear,
    is_esg_enabled: state.Storage.is_esg_enabled,
    esg: state.Analytics.esg,
  }))(History)
);
