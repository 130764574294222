import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Header, Dropdown, Icon, Popup } from 'semantic-ui-react';
import cn from 'classnames';

import { Analytics, Portfolio, ComparePortfolio } from 'actions';
import { colors } from 'utils/colors';
import { withRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { ErrorLabel, Button, Loading } from 'components';

class ExistingPortfolioSelection extends Component {
  state = {
    id: null,
    error: null,
  };

  componentDidMount() {
    const { dispatch, analytics } = this.props;
    const { data } = analytics;
    dispatch(Portfolio.fetchModel());
    dispatch(Portfolio.fetchUser());
    dispatch(
      ComparePortfolio.convertAnalyticsPortfolioObject(
        data.portfolio,
        data.paramsAnalyzed,
        true
      )
    );
  }

  componentDidUpdate() {
    const { comparePortfolioState, setView } = this.props;
    if (comparePortfolioState.compareResult) setView('Result');
  }

  getPercent = (num) => (num ? `${(num * 100).toFixed(2)} %` : '0 %');

  getChartData = (port1, port2) => ({
    labels: Object.keys(port1.cumrets.portfolio),
    datasets: [
      {
        data: Object.values(port1.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: 'Current Portfolio',
        lineTension: 0,
        fill: false,
        backgroundColor: colors.blue,
        borderColor: colors.blue,
        pointBackgroundColor: colors.blue,
        pointBorderColor: colors.blue,
        pointRadius: 0,
        pointHitRadius: 5,
      },
      {
        data: Object.values(port2.cumrets.portfolio).map(
          (value) => Math.round(value * 1000 * 100) / 100
        ),
        borderWidth: 0,
        label: port2.portfolio.name,
        lineTension: 0,
        fill: false,
        backgroundColor: colors.red,
        borderColor: colors.red,
        pointBackgroundColor: colors.red,
        pointBorderColor: colors.red,
        pointRadius: 0,
        pointHitRadius: 5,
      },
    ],
  });

  getPortfolios = (portfolios) => {
    const { id } = this.state;

    return portfolios.reduce((total, portfolio) => {
      if (portfolio) {
        total.push({
          key: portfolio.id,
          value: portfolio.id,
          selected: portfolio.id === id,
          icon: portfolio.is_global ? (
            <Icon name="briefcase" style={{ color: colors.red }} />
          ) : (
            'briefcase'
          ),
          text: portfolio.name,
        });
      }

      return total;
    }, []);
  };

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  handleChange = (e, { value }) => {
    const { dispatch } = this.props;
    dispatch(ComparePortfolio.fetchId(value, false));

    this.setState({ id: value, error: null });
  };

  decideRegion = () => {
    if (this.props.user_region === 'US') {
      return 'US';
    } else if (this.props.toggle_region === 'US') {
      return 'US';
    }
    return 'CA';
  };

  submit = () => {
    const { dispatch, comparePortfolioState, analytics } = this.props;
    const { paramsPassed } = analytics.data || {};

    const { portfolio, original, gic_info } = comparePortfolioState;

    const customBenchmark =
      analytics && analytics.benchmark === 'custom'
        ? paramsPassed
          ? paramsPassed.custom_bm
          : null
        : null;
    const rebalanceInterval = analytics.data.paramsPassed.rebalance_interval || 1;

    return dispatch(
      ComparePortfolio.fetchCompare(
        original.portfolio,
        portfolio,
        original.gic_info,
        gic_info,
        this.decideRegion(),
        customBenchmark,
        paramsPassed.check_history || false,
        rebalanceInterval
      )
    );
  };

  fetchEsg = (port1, port2) => {
    const { dispatch } = this.props;

    return dispatch(Analytics.fetchCompareEsg(port1, port2));
  };

  parseDate = () => {
    const { init_date, end_date } = this.props.compare.analysis1.paramsAnalyzed;
    const init = init_date.split('-');
    const end = end_date.split('-');
    const months = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return `${months[init[1]]} ${init[0]} to ${months[end[1]]} ${end[0]}`;
  };

  isCompareButtonDisabled = () => {
    const { comparePortfolioState } = this.props;
    return this.state.id === null || comparePortfolioState.isFetching;
  };

  generatePDF = () => {
    const { match, history } = this.props;

    return history.push(`/portfolio/${match.params.id}/compare/pdf`);
  };

  displayEsg = (esg) => {
    if (!esg) return '-';

    const { portfolio } = esg;

    if (!portfolio.esg_score) return '-';

    return !portfolio.esg_score ? '-' : portfolio.esg_score;
  };

  esgCoverage = (esg) => {
    if (!esg) return '(0%)';

    const { portfolio } = esg;
    const weight = (portfolio.weight * 100).toFixed(2);

    if (!weight || !Number(weight)) return '(0%)';

    const convertWeight = weight.split('.');

    if (convertWeight[1][0] === '0' && convertWeight[1][1] === '0')
      return `(${convertWeight[0]}%)`;
    if (convertWeight[1][1] === '0')
      return `(${convertWeight[0]}.${convertWeight[1][0]}%)`;

    return `(${weight}%)`;
  };

  renderSubheader = () => (
    <div className="sub header">
      {this.props.french ? 'Période de comparaison' : 'Comparison Period'}: {this.parseDate()}
      <Popup
        trigger={
          <Icon
            name="info circle"
            style={{ fontSize: '15px', verticalAlign: 'initial' }}
          />
        }
        position="bottom center"
        wide
      >
        {this.props.french
          ? 'La période de comparaison est déterminée par l\'histoire commune la plus longue des deux portefeuilles.'
          : 'The comparison period is determined by the longest common history of the two portfolios.'}
      </Popup>
    </div>
  );

  render() {
    const { portfolios, comparePortfolioState } = this.props;
    const { error, id } = this.state;
    const { isFetching } = comparePortfolioState;

    return (
      <div>
        {isFetching ? <Loading style={{ top: '-3rem' }} /> : null}
        <Fade>
          <StyledDiv>
            <CompareContent>
              <Header
                size="medium"
                content={
                  this.props.french
                    ? 'Sélectionnez un portefeuille à comparer'
                    : 'Select a Portfolio to Compare'
                }
                style={{ margin: 0 }}
              />
              <Header.Subheader
                style={{ marginBottom: '2rem' }}
                content={
                  this.props.french
                    ? 'Tous les portefeuilles enregistrés apparaîtront dans la liste déroulante ci-dessous'
                    : 'All saved portfolios will appear in the dropdown below'
                }
              />
              <div
                style={{
                  position: 'relative',
                  width: '60%',
                  marginTop: '-1rem',
                }}
              >
                <div style={{ textAlign: 'left', marginBottom: '2rem' }}>
                  <Icon name="warning sign" />
                  {this.props.french
                    ? 'La période de comparaison est définie par l\'histoire commune la plus longue des deux portefeuilles que vous comparez. Par conséquent, les scores du portefeuille actuel peuvent changer, car son histoire peut maintenant être plus courte.'
                    : 'The comparison period is set to the longest common history of the two portfolios you are comparing. Therefore, the scores of the current portfolio may change, as its history may now be shorter.'}
                  <br />
                  <Icon name="warning sign" style={{ marginTop: '1rem' }} />
                  {this.props.french
                    ? 'Les scores de performance et de risque à la baisse de Wealthscope sont déterminés par rapport au benchmark d\'un portefeuille. Par conséquent, vous devriez ignorer ces scores et ne comparer que les métriques financières si les deux portefeuilles ont une allocation d\'actifs différente, correspondant à un benchmark différent.'
                    : 'The Wealthscope Performance and Downside Risk scores are determined relative to a portfolio’s benchmark. As such, you should ignore these scores and compare only the financial metrics if the two portfolios have a different asset allocation, corresponding to a different benchmark.'}
                </div>
                {portfolios.length === 0 && (
                  <div style={{ marginBottom: '5px', color: 'red' }}>
                    {this.props.french
                      ? 'Vous n\'avez actuellement aucun portefeuille enregistré.'
                      : 'You currently have no saved portfolios.'}
                  </div>
                )}
                <Dropdown
                  fluid
                  selection
                  disabled={portfolios.length === 0}
                  placeholder={
                    this.props.french
                      ? 'Portefeuille de comparaison...'
                      : 'Comparison Portfolio...'
                  }
                  options={this.getPortfolios(portfolios)}
                  text={
                    id !== null &&
                    portfolios.find((port) => port.id === id).name
                  }
                  selectOnBlur={false}
                  onChange={this.handleChange}
                />
                <ErrorLabel msg={error} />
              </div>
              <Button
                className={cn('left', {
                  disabled: this.isCompareButtonDisabled(),
                })}
                icon="line chart"
                color={colors.darkBlue}
                margin="1rem 0"
                onClick={this.submit}
              >
                {this.props.french ? 'Comparer' : 'Compare'}
              </Button>
            </CompareContent>
          </StyledDiv>
        </Fade>
      </div>
    );
  }
}

ExistingPortfolioSelection.propTypes = {
  analytics: PropTypes.object,
  setView: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  compare: PropTypes.object,
  history: PropTypes.object.isRequired,
  comparePortfolioState: PropTypes.object,
  match: PropTypes.object.isRequired,
  user_region: PropTypes.string,
  toggle_region: PropTypes.string,
  french: PropTypes.bool,
};

ExistingPortfolioSelection.defaultProps = {
  portfolios: [],
  compare: {},
  comparePortfolioState: {},
  analytics: {},
  user_region: 'CA',
  toggle_region: 'CA',
  french: false,
};

export default withRouter(
  connect((state) => ({
    comparePortfolioState: state.ComparePortfolio,
    portfolios: state.Portfolio.user,
    compare: state.Analytics.compare,
    analytics: state.Analytics,
    compareEsg: state.Analytics.compareEsg,
    esgToggle: state.Analytics.esgToggle,
    user_region: state.Storage.user_region,
    toggle_region: state.Storage.toggle_region || 'CA',
    french: state.Storage.language === 'fr',
  }))(ExistingPortfolioSelection)
);

const StyledDiv = styled.div`
  & {
    // border: 2px solid ${colors.blue};
    // border-radius: 4px;

    width: 100%;
    height: 100%;
    display: flex;
    padding: 3rem 0;
    margin-bottom: 9rem;
    flex-direction: column;
    justify-content: flex-start;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      div.search {
        .results {
          .category {
            align-items: flex-start;
          }
        }
      }
    }
    div.compare-whatif-toggles {
      width: 50%;
      display: block;
    }
  }
`;

const CompareContent = styled.div`
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;
