import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { RebalanceAccount } from 'actions';

class Deviation extends Component {
  state = {
    focused: false,
  };

  handleKeyPress = (e) => (e.key !== 'Enter' ? false : this.handleBlur());

  handleBlur = () => {
    const { dispatch, ticker, value } = this.props;

    if (value === '') {
      dispatch(RebalanceAccount.updateDeviation(ticker, 0));
    }

    return this.setState({ focused: false });
  };

  handleFocus = (e) => {
    const temp = e.target.value;

    e.target.value = '';

    return (e.target.value = Number(temp) === 0 ? '' : temp);
  };

  handleChange = (e, { name, value }) => {
    const { dispatch } = this.props;

    if (Number(value) < -100) {
      return dispatch(RebalanceAccount.updateDeviation(name, -100));
    }

    if (Number(value) > 200) {
      return dispatch(RebalanceAccount.updateDeviation(name, 200));
    }

    return dispatch(RebalanceAccount.updateDeviation(name, value));
  };

  focus = () => {
    const { value, ticker, dispatch } = this.props;

    if (!value) {
      dispatch(RebalanceAccount.updateDeviation(ticker, ''));
    }

    return this.setState({ focused: true });
  };

  render() {
    const { ticker, value, isEditing } = this.props;
    const { focused } = this.state;

    return !focused ? (
      <Value
        className={`${isEditing ? 'edit' : ''}`}
        onClick={isEditing ? this.focus : () => false}
      >
        {value || 0} %
      </Value>
    ) : (
      <Input
        name={ticker}
        size="mini"
        type="number"
        value={value}
        onChange={this.handleChange}
        style={{ maxWidth: 80 }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onKeyPress={this.handleKeyPress}
        autoFocus
      />
    );
  }
}

Deviation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ticker: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect()(Deviation);

const Value = styled.span`
  position: relative;
  cursor: default;
  color: black;
  padding: 11px 5px;

  &.edit {
    cursor: pointer;
    color: #2185d0;

    &:hover {
      color: #1e70bf;
      text-decoration: underline;
    }
  }
`;
