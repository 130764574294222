import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import logoImage from 'images/favicon.png';

const NeuCard = ({
  iconName,
  title,
  description,
  color,
  to,
  disabled,
  popup,
  big,
  narrow,
  logo,
  infoPopupContent,
  partnerName,
}) => (
  <CardContainer
    style={{
      opacity: disabled ? '0.45' : '1',
      cursor: disabled ? 'auto' : 'default',
      height: big ? '300px' : '250px',
      width: narrow ? `230px` : '290px',
      position: 'relative', // Added for positioning logo
    }}
  >
    {disabled && popup ? (
      <Popup
        style={{ transform: 'translateY(-2.1rem)' }}
        trigger={
          <div onClick={() => false}>
            <div
              className="top"
              style={{
                height: big ? '125px' : '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <div
                className="icon-container"
                style={{
                  background: color,
                  borderRadius: '20%',
                  minHeight: '60px',
                  minWidth: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0 8px 15px 0 rgb(59 105 142 / 15%)',
                }}
              >
                <Icon
                  name={iconName}
                  size="large"
                  fitted
                  style={{
                    color: determineIconColor(color),
                    margin: `0.25rem`,
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '10px 14px',
                }}
              >
                <h3
                  style={{
                    fontSize: '1.1rem',
                    fontWeight: '800',
                    color: '#707070',
                    marginTop: '10px',
                  }}
                >
                  {title}
                  {infoPopupContent !== null && (
                    <Popup
                      trigger={
                        <Icon
                          name="info circle"
                          color="grey"
                          style={{ marginLeft: '5px', cursor: 'pointer' }}
                        />
                      }
                      content={infoPopupContent}
                      position="top center"
                    />
                  )}
                </h3>
              </div>
            </div>
            <div
              className="mid"
              style={{
                height: big ? '175px' : '100px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        }
        position={popup.position}
        content={<div style={{ textAlign: 'center' }}>{popup.content}</div>}
        wide={popup.wide || false}
      />
    ) : disabled ? (
      <div onClick={() => false}>
        <div
          className="top"
          style={{
            height: big ? '125px' : '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '20px',
          }}
        >
          <div
            className="icon-container"
            style={{
              background: color,
              borderRadius: '20%',
              minHeight: '60px',
              minWidth: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 8px 15px 0 rgb(59 105 142 / 15%)',
            }}
          >
            <Icon
              name={iconName}
              size="large"
              style={{
                color: determineIconColor(color),
                margin: `0.25rem`,
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px 14px',
            }}
          >
            <h3
              style={{
                fontSize: '1.1rem',
                fontWeight: '800',
                color: '#707070',
                marginTop: '10px',
              }}
            >
              {title}
              {infoPopupContent !== null && (
                <Popup
                  trigger={
                    <Icon
                      name="info circle"
                      color="grey"
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                    />
                  }
                  content={infoPopupContent}
                  position="top center"
                />
              )}
            </h3>
          </div>
        </div>
        <div
          className="mid"
          style={{
            height: big ? '175px' : '100px',
            padding: narrow && '0 1.5rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <InnerText>{description}</InnerText>
        </div>
      </div>
    ) : (
      <Link to={to}>
        <div
          className="top"
          style={{
            height: big ? '125px' : '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '20px',
          }}
        >
          <div
            className="icon-container"
            style={{
              background: color,
              borderRadius: '20%',
              minHeight: '60px',
              minWidth: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0 8px 15px 0 rgb(59 105 142 / 15%)',
            }}
          >
            <Icon
              name={iconName}
              size="large"
              style={{
                color: determineIconColor(color),
                margin: `0.25rem`,
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px 14px',
              textAlign: 'center',
            }}
          >
            <h3
              style={{
                fontSize: '1.1rem',
                fontWeight: '800',
                color: '#707070',
                marginTop: '10px',
              }}
            >
              {title}
              {infoPopupContent !== null && (
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        color: 'dimgrey',
                      }}
                    />
                  }
                  content={infoPopupContent}
                  position="center right"
                />
              )}
            </h3>
          </div>
        </div>
        <div
          className="mid"
          style={{
            height: big ? '175px' : '100px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              textAlign: 'center',
              width: narrow ? '200px' : '230px',
              marginTop: narrow ? '0' : '10px',
              fontWeight: 'normal',
            }}
          >
            {description}
          </p>
        </div>
        {logo && partnerName !== '' && partnerName !== 'wealthscope_test' && (
          <Popup
            trigger={
              <LogoImage
                src={logoImage}
                alt="Logo"
                style={{
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                  width: '25px',
                  height: 'auto',
                  opacity: '0.8',
                }}
              />
            }
            position="top center"
            content="Built by Wealthscope"
          />
        )}
      </Link>
    )}
  </CardContainer>
);

NeuCard.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.node,
  color: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  popup: PropTypes.string,
  big: PropTypes.bool,
  narrow: PropTypes.bool,
  logo: PropTypes.bool,
  infoPopupContent: PropTypes.string,
  partnerName: PropTypes.string,
};

NeuCard.defaultProps = {
  iconName: '',
  title: '',
  description: '',
  color: '',
  to: '',
  disabled: false,
  popup: '',
  big: false,
  narrow: false,
  logo: false,
  infoPopupContent: null,
  partnerName: '',
};

export default NeuCard;

const CardContainer = styled.div`
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 8px 15px 0 rgb(59 105 142 / 15%);
  transition: box-shadow 0.2s ease-in-out;
  margin: 1.5rem;
  opacity: ${(props) => (props.disabled ? `0.45` : '1')};
  &:hover {
    box-shadow: 0 12px 20px 0 rgb(59 105 142 / 33%);
  }
`;

const LogoImage = styled.img`
  // You can style your logo image here if needed
`;

const InnerText = styled.p`
  font-size: 14px;
  color: #707070;
  text-align: center;
  width: ${(props) => (props.narrow ? `200px` : '230px')};
  margin-top: 10px;
  font-weight: normal !important;
`;

const determineIconColor = (color) => {
  switch (color) {
    case colors.darkBlue:
    case colors.red:
    case colors.blue:
    case colors.green:
    case colors.darkGrey:
      return 'white';
    default:
      return 'rgba(0,0,0,0.6)';
  }
};
