import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Modal, Button } from 'components';
import { Icon } from 'semantic-ui-react';
import { Storage } from 'actions';
import { connect } from 'react-redux';

// eslint-disable-next-line react/prefer-stateless-function
class DeleteWarningModal extends Component {
  handleButtonClick = () => {
    const { dispatch } = this.props;
    dispatch(Storage.setItem('blueprintDeleteWarningSuppress', true));
    this.props.changeModalVisibility(false);
  };
  render() {
    const { isOpen, changeModalVisibility, isSavedBlueprint } = this.props;

    return (
      <CustomModal
        basic
        open={isOpen}
        dimmerClick={() => changeModalVisibility(false)}
        style={{ width: 'fit-content', height: 'fit-content', top: '20%' }}
      >
        <Modal.Content>
          <span style={{ width: '48rem' }}>
            <Icon name="lightbulb outline" style={{ fontSize: '1.2rem' }} />
            {!isSavedBlueprint
              ? 'If you change the inputs in this section, you will be required to re-estimate your Target Wealth and Target Saving in the next steps. You may wish to save your current Retirement Blueprint first in the "Summary" section.'
              : 'If you change the inputs in this section, you will be required to re-estimate your Target Wealth and Target Saving in the next steps.'}
          </span>
          <div className="button-div">
            <Button
              style={{ boxShadow: `inset 0 0 0 1.5px ${colors.green}` }}
              color={colors.green}
              icon="check"
              className="left inverted"
              onClick={this.handleButtonClick}
              isQM
            >
              Do not show again
            </Button>
          </div>
        </Modal.Content>
      </CustomModal>
    );
  }
}

DeleteWarningModal.propTypes = {
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  changeModalVisibility: PropTypes.func.isRequired,
  isSavedBlueprint: PropTypes.bool,
};
DeleteWarningModal.defaultProps = {
  isSavedBlueprint: false,
  dispatch: () => false,
};

export default connect(() => ({}))(DeleteWarningModal);

const CustomModal = styled(({ children, className, ...rest }) => (
  <Modal className={className} {...rest}>
    {children}
  </Modal>
))`
  & {
    top: 0;
    left: 0;
    font-size: 1.2rem;

    button: {
      margin-top: 2rem;
      color: ${colors.white} !important;
      backgroundcolor: ${colors.red};
      textalign: center;
      width: 120px;
      font-size: 1.2rem;
    }
    .modal-content {
      text-align: center;
      display: flex;
      padding: 3rem 2rem;
      position: relative;

      flex-direction: column;
      gap: 2rem;
    }
  }
`;
