import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import KeyInfo from './KeyInfo';
import Graph from './Graph';
import Performance from './Performance';
import Risk from './Risk';
import Breakdown from '../ComparisonContent/Breakdown';
import TopHoldings from './TopHoldings';

class ComparisonContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isKeyOpen: true || this.props.pdf,
      isGraphOpen: true || this.props.pdf,
      isPerformanceOpen: false || this.props.pdf,
      isBreakdownOpen: false || this.props.pdf,
      isTopHoldingsOpen: false || this.props.pdf,
      isRiskOpen: false || this.props.pdf,
    };
  }

  toggleComponent = (componentName) => {
    this.setState((prevState) => {
      if (componentName === 'isKeyOpen') {
        return { [componentName]: !prevState[componentName] };
      }

      // Close all other components except KeyInfo
      return {
        isKeyOpen: prevState.isKeyOpen,
        isGraphOpen:
          componentName === 'isGraphOpen' ? !prevState.isGraphOpen : false,
        isPerformanceOpen:
          componentName === 'isPerformanceOpen'
            ? !prevState.isPerformanceOpen
            : false,
        isBreakdownOpen:
          componentName === 'isBreakdownOpen'
            ? !prevState.isBreakdownOpen
            : false,
        isTopHoldingsOpen:
          componentName === 'isTopHoldingsOpen'
            ? !prevState.isTopHoldingsOpen
            : false,
        isRiskOpen:
          componentName === 'isRiskOpen' ? !prevState.isRiskOpen : false,
      };
    });
  };

  render() {
    const {
      securityTickerList,
      securityData,
      chartUpdateStartEndDate,
      user_region,
      partnerName,
      pdf,
    } = this.props;

    return (
      <div>
        <KeyInfo
          securityData={securityData}
          securityTickerList={securityTickerList}
          isOpen={this.state.isKeyOpen}
          toggleOpen={() => this.toggleComponent('isKeyOpen')}
          partnerName={partnerName}
        />
        <Spacer />

        <Graph
          allNamesList={securityTickerList}
          portfolioNames={[]}
          updateStartEndDate={(startMonth, endMonth) =>
            chartUpdateStartEndDate(startMonth, endMonth)
          }
          isOpen={this.state.isGraphOpen}
          toggleOpen={() => this.toggleComponent('isGraphOpen')}
        />
        <div style={{ pageBreakAfter: 'always' }} />
        <Spacer />

        <Performance
          securityData={securityData}
          securityTickerList={securityTickerList}
          isOpen={this.state.isPerformanceOpen}
          toggleOpen={() => this.toggleComponent('isPerformanceOpen')}
          user_region={user_region}
          partnerName={partnerName}
        />
        <Spacer />
        <Breakdown
          securityData={securityData}
          securityTickerList={securityTickerList}
          isOpen={this.state.isBreakdownOpen}
          toggleOpen={() => this.toggleComponent('isBreakdownOpen')}
          partnerNamer={partnerName}
          pdf={pdf}
        />
        <div style={{ pageBreakAfter: 'always' }} />
        <Spacer />

        <TopHoldings
          securityData={securityData}
          securityTickerList={securityTickerList}
          isOpen={this.state.isTopHoldingsOpen}
          toggleOpen={() => this.toggleComponent('isTopHoldingsOpen')}
        />
        <Spacer />
        <Risk
          securityData={securityData}
          securityTickerList={securityTickerList}
          isOpen={this.state.isRiskOpen}
          toggleOpen={() => this.toggleComponent('isRiskOpen')}
          partnerNamer={partnerName}
        />
      </div>
    );
  }
}

ComparisonContent.propTypes = {
  securityTickerList: PropTypes.array.isRequired,
  securityData: PropTypes.object.isRequired,
  chartUpdateStartEndDate: PropTypes.func.isRequired,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  pdf: PropTypes.bool,
};

ComparisonContent.defaultProps = {
  user_region: 'CA',
  partnerName: '',
  pdf: false,
};

export default ComparisonContent;

const Spacer = styled.div`
  width: 100%;
  height: 4rem;
`;
