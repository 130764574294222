import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Dropdown } from 'semantic-ui-react';
import { Planning, Portfolio, Accounts } from 'actions';
import cn from 'classnames';

@connect((state) => ({
  portfolios: state.Portfolio.user.concat(state.Portfolio.model),
  drawdown: state.Planning.drawdown,
  cppDataset: state.Planning.cppDataset,
  oasDataset: state.Planning.oasDataset,
  accountList: state.Accounts.accountList,
  cppEstimator: state.Planning.cppEstimator,
}))
export default class InvestmentPortfolio extends Component {
  state = {
    scenario: null,
    warning: {},
  };
  componentWillMount = () => {
    const { dispatch, portfolios, drawdown } = this.props;
    const { inputs } = drawdown;

    dispatch(Planning.getCppOasDataset());
    dispatch(Accounts.fetchAll());

    if (!portfolios.length) {
      dispatch(Portfolio.fetchUser());
      dispatch(Portfolio.fetchModel());
    }

    if (!inputs.isRiskFree && inputs.scenario) {
      this.setState({
        scenario: inputs.scenario,
      });
    }

    return false;
  };

  getDropOptions = () => {
    const { portfolios } = this.props;
    const { inputs } = this.props.drawdown;

    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return portfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      selected:
        inputs.portfolio !== undefined && portf.id === Number(inputs.portfolio),
      text: portf.name,
    }));
  };

  getPortfolio = (portfolios, portfolioId) =>
    portfolios.find((port) => port.id === Number(portfolioId));

  changeSelection = (field, value) => {
    const { drawdown, dispatch } = this.props;

    if (field === 'isRiskFree') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            [field]: value,
            pctCapGain: 0,
            divYield: 0,
            pctEligibleDiv: 0,
            ...(!value && { portfolio: drawdown.inputs.portfolio }),
          },
          errors: {
            pctCapGain: false,
            divYield: false,
            pctEligibleDiv: false,
            portfolio: false,
            constantRets: false,
          },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [field]: value },
        errors: {},
        modified: true,
      })
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { dispatch, drawdown, cppDataset, oasDataset, cppEstimator } =
      this.props;
    const { inputs } = drawdown;
    let overMaxPrompt = null;
    let money = null;
    let max = Number.MIN_SAFE_INTEGER;

    const parsedValue = (field, val) => {
      const currency = [
        'cpp',
        'oas',
        'pension',
        'other',
        'initRegular',
        'initRRIF',
        'initTFSA',
        'endValue',
      ];

      if (field === 'horizon') {
        const maxHorizon = 80;
        if (Number(val) >= maxHorizon) {
          // Display an error message for horizon exceeding the maximum
          this.setState({
            warning: {
              ...this.state.warning,
              horizon:
                'Please input the number of years you are expected to be retired for, rather than the age you expect to live until.',
            },
          });
          return maxHorizon;
        }
      }

      if (currency.indexOf(field) === -1 && Number(val) > 100) {
        return 100;
      }

      return val;
    };

    const setAge = (age, isOas) => {
      if (!age) {
        return 70;
      }

      if (isOas && Number(age) < 65) {
        return 65;
      }

      if (Number(age) < 60) {
        return 60;
      } else if (Number(age) > 70) {
        return 70;
      }

      return age;
    };

    if (name === 'age') {
      const cppMax = cppDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'CPP' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), false))
      ).amount;
      const oasMax = oasDataset.find(
        (item) =>
          item.amount_type === 'maximum' &&
          item.pension_name === 'OAS' &&
          Number(item.age) === Number(setAge(parsedValue(name, value), true))
      ).amount;
      if (Number(value) < 60) {
        this.changeSelection('cppStarted', false);
        this.changeSelection('oasStarted', false);
      } else if (Number(value) < 65) {
        this.changeSelection('oasStarted', false);
        this.changeSelection('cppStarted', true);
      } else {
        this.changeSelection('oasStarted', true);
        this.changeSelection('cppStarted', true);
      }
      dispatch(
        Planning.drawdownUpdateInput({
          inputs: {
            age: parsedValue(name, value),
            ...(Number(value) > 60 && { cppAge: parsedValue(name, value) }),
            ...(Number(value) > 65 && { oasAge: parsedValue(name, value) }),
          },
          errors: {
            age: false,
            ...(Number(value) > 60 && inputs.cppAge && { cppAge: false }),
            ...(Number(value) > 65 && inputs.oasAge && { oasAge: false }),
            // ...(inputs.cppStarted &&
            //   inputs.cpp > cppMax && {
            //     cpp:
            //       'The benefit amount you entered exceeded the maximum for your current age.',
            //   }),
            ...(inputs.oasStarted &&
              inputs.oas > oasMax && {
                oas: 'The benefit amount you entered exceeded the maximum for your current age.',
              }),
            ...(inputs.cppStarted && inputs.cpp <= cppMax && { cpp: null }),
            ...(inputs.oasStarted && inputs.oas <= oasMax && { oas: null }),
            ...(!inputs.cppStarted && { cppAge: null }),
            ...(!inputs.oasStarted && { oasAge: null }),
          },
          modified: true,
        })
      );
    }

    if (name === 'cppAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { cppAge: parsedValue(name, value), cpp: '' },
          errors: { cppAge: false, cpp: false },
          modified: true,
        })
      );
    }

    if (name === 'oasAge') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oasAge: parsedValue(name, value), oas: '' },
          errors: { oasAge: false, oas: false },
          modified: true,
        })
      );
    }

    if (name === 'cpp') {
      if (!inputs.cppAge && inputs.cppStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cppAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      const currentYear = new Date().getFullYear();

      const body = {
        retirement_year:
          Number(currentYear) + Number(inputs.cppAge) - Number(inputs.age),
        start_cpp_age: inputs.cppAge,
        years_contrib: '45',
        primary_caregiver: false,
        years_primary_caregiver: 0,
        is_earning_over_average: true,
        is_earning_14pct_higher: true,
      };
      if (inputs.cppAge !== '' && inputs.cppAge >= 60) {
        dispatch(Planning.calcCpp({ ...body }));
        max = cppEstimator.result;

        if (value > max && max > 100) {
          money = max;
          overMaxPrompt = `This value is the maximum annual benefits for your CPP/QPP start age and year.`;
        } else {
          money = value;
        }

        this.setState({
          warning: { ...this.state.warning, cpp: overMaxPrompt },
        });

        return dispatch(
          Planning.drawdownUpdateInput({
            inputs: { cpp: money },
            errors: { cpp: false },
            modified: true,
          })
        );
      }
    }

    if (name === 'oas') {
      if (!inputs.oasAge && inputs.oasStarted) {
        dispatch(
          Planning.drawdownUpdateInput({
            inputs: { oasAge: inputs.age || 0 },
            modified: true,
          })
        );
      }

      if (!inputs.oasAge || inputs.oasAge > 70) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 70
        ).amount;
      } else if (inputs.oasAge < 65) {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === 65
        ).amount;
      } else {
        max = oasDataset.find(
          (item) =>
            item.amount_type === 'maximum' &&
            item.pension_name === 'OAS' &&
            item.age === Number(inputs.oasAge)
        ).amount;
      }

      if (value > max) {
        money = max;
        overMaxPrompt = `This value is the maximum annual benefits for age ${setAge(
          inputs.oasAge,
          true
        )}.`;
      } else {
        money = value;
      }

      this.setState({
        warning: { ...this.state.warning, oas: overMaxPrompt },
      });

      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { oas: money },
          errors: { oas: false },
          modified: true,
        })
      );
    }

    if (name === 'other') {
      return dispatch(
        Planning.drawdownUpdateInput({
          inputs: { other: parsedValue(name, value) },
          errors: { otherHorizon: false },
          modified: true,
        })
      );
    }

    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { [name]: parsedValue(name, value) },
        errors: { [name]: false },
        modified: true,
      })
    );
  };

  handlePortfolioChange = (e, { value }) => {
    this.props.dispatch(
      Planning.drawdownUpdateInput({
        inputs: {
          portfolio: value,
          constantRets: null,
          divYield: this.prepopDividendYield(value),
        },
        errors: { portfolio: false, divYield: false },
        modified: true,
      })
    );
  };

  portfolioIsDeleted = (inputs, portfolios) => {
    const { isRiskFree } = inputs;
    if (isRiskFree === undefined) return false;
    if (isRiskFree) return false;
    if (inputs.portfolio === undefined) return false;

    return !portfolios.find((p) => p.id === inputs.portfolio);
  };

  prepopDividendYield = (portfolioId) => {
    const { portfolios } = this.props;

    if (this.getPortfolio(portfolios, portfolioId)) {
      if (this.getPortfolio(portfolios, portfolioId).dividend_yield !== 0) {
        return this.getPortfolio(portfolios, portfolioId).dividend_yield;
      }

      return '0';
    }

    return '';
  };

  render() {
    const { inputs, errors } = this.props.drawdown;
    const { portfolios } = this.props;
    return (
      <div>
        <Section single style={{ marginBottom: 0 }}>
          {/* <div className="heading">Investment Portfolio</div> */}

          <div>
            <div
              className="title"
              style={{ position: 'relative', width: '100%' }}
            >
              <div style={{ width: '80%' }}>
                Are you putting your retirement savings in a risk-free
                investment?
              </div>
              <OptionsWrapper style={{ top: 0 }}>
                <div
                  className={cn('select', { active: inputs.isRiskFree })}
                  onClick={() => {
                    this.setState({ scenario: null });
                    this.changeSelection('isRiskFree', true);
                    this.changeSelection('scenario', '');
                  }}
                  style={{
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                  }}
                >
                  Yes
                </div>
                <div
                  className={cn('select', { active: !inputs.isRiskFree })}
                  onClick={() => {
                    if (!this.state.scenario) {
                      this.setState({ scenario: null });
                    }
                    this.changeSelection('isRiskFree', false);
                    this.changeSelection('constantRets', null);
                  }}
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
            </div>

            <div
              style={{
                display: !inputs.isRiskFree ? 'flex' : 'none',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="description">
                Use a portfolio close to your desired asset allocation. Pick a
                model portfolio, or save a portfolio in the "Portfolios"
                segment. You will see them in the drop down on the right.
              </div>
              <StyledDropdown
                style={{}}
                className={cn({
                  invalid:
                    errors.portfolio ||
                    this.portfolioIsDeleted(inputs, portfolios)
                      ? 'true'
                      : undefined,
                })}
                placeholder="Select a portfolio"
                selection
                disabled={portfolios.length === 0}
                options={this.getDropOptions()}
                long
                value={inputs.portfolio}
                text={
                  inputs.portfolio !== undefined &&
                  portfolios.length !== 0 &&
                  !inputs.isRiskFree &&
                  this.getPortfolio(portfolios, inputs.portfolio) &&
                  this.getPortfolio(portfolios, inputs.portfolio).name
                }
                selectOnBlur={false}
                onChange={this.handlePortfolioChange}
              />
            </div>

            <div style={{ display: inputs.isRiskFree ? 'block' : 'none' }}>
              <div className="description">
                What is the expected rate of return?
              </div>
              <Input
                className={cn({
                  invalid: errors.constantRets ? 'true' : undefined,
                })}
                name="constantRets"
                type="number"
                placeholder="%"
                value={inputs.constantRets}
                onChange={this.handleChange}
              />
            </div>
          </div>
          {this.portfolioIsDeleted(inputs, portfolios) && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '10px',
                color: 'red',
              }}
            >
              Your portfolio has been removed. Please select a new portfolio.
            </div>
          )}
        </Section>
      </div>
    );
  }
}

InvestmentPortfolio.propTypes = {
  dispatch: PropTypes.func,
  drawdown: PropTypes.object,
  portfolios: PropTypes.array,
};

InvestmentPortfolio.defaultProps = {
  dispatch: () => false,
  drawdown: {},
  portfolios: [],
};

const Section = styled.section`
  display: flex; /* Add this line */
  flex-direction: column;
  padding-left: 2rem;
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: 0 auto 2rem auto;
  line-height: 1.5rem;
  max-width: ${(props) => (props.single ? '52rem' : '100%')};
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: disabled;
  }

  .custom {
    top: 20px;
    right: 10px;
  }

  .select {
    display: inline-block;
    padding: 0.3rem 0.7rem;
    line-height: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.1) inset;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset,
        0 0 0 0 rgba(0, 0, 0, 0.3) inset;
      color: rgba(0, 0, 0, 0.5);
    }

    &.active {
      box-shadow: none;
      background-color: ${colors.teal};
      color: black;
    }
  }
`;

const StyledDropdown = styled(({ children, className, ...rest }) => (
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  &.invalid {
    border: 1px solid ${colors.red} !important;
    background: rgb(255, 240, 240) !important;
  }
  &.large {
    margin: 0.7rem 0;
    clear: both;
  }
`;

const Input = styled.input`
  padding: 0.4rem;
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: all 200ms ease;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  max-width: 73px;
  font-size: 0.9rem;
  text-align: right;
  outline: none;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid rgb(132, 183, 217);

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  &.large {
    margin: 0.7rem 0;
    clear: both;
  }

  &.invalid {
    border: 1px solid ${colors.red};
    background: rgb(255, 240, 240);
  }

  &.absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
