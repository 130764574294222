import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Header, Popup } from 'semantic-ui-react';
import cn from 'classnames';
import { notWealthica } from 'utils/helpers';
import { Planning } from 'actions';
import { colors } from 'utils/colors';
import { Modal, Button } from 'components';

class SaveModal extends Component {
  constructor(props) {
    super(props);
    const { name, description } = this.props;
    this.state = {
      saveModalIsOpen: false,
      loading: false,
      name: name || '',
      description: description || '',
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value });
  };

  handleEditClick = () => {
    const { dispatch } = this.props;
    dispatch(Planning.navigateDrawdown(1));
    this.handleSpouseChange('spouse');
  };

  handleSpouseChange = (spouse) => {
    const { dispatch } = this.props;
    return dispatch(
      Planning.drawdownUpdateInput({
        inputs: { activeSpouse: spouse },
        errors: { activeSpouse: false },
        modified: true,
      })
    );
  };

  handleSave = () => {
    const { dispatch, drawdown, match, history } = this.props;
    const { name, description } = this.state;
    const {
      isRiskFree,
      isRiskFree2,
      portfolio,
      portfolio2,
      constantRets,
      constantRets2,
      initRegAccList,
      initRRIFAccList,
      initTFSAAccList,
      spouse,
      spouse2,
    } = drawdown.inputs;
    const body = {
      ...drawdown.inputs,
      name,
      spouse,
      spouse2,
      description,
      coupleOutput: drawdown.coupleDrawdown.result,
      outputData: drawdown.coupleDrawdown.spouse,
      outputData2: drawdown.coupleDrawdown.spouse2,
      rrifAge: drawdown.inputs.cppAge || 0,
      rrifAge2: drawdown.inputs.cppAge2 || 0,
      other: drawdown.inputs.other || 0,
      other2: drawdown.inputs.other2 || 0,
      otherHorizon: drawdown.inputs.otherHorizon || 0,
      otherHorizon2: drawdown.inputs.otherHorizon2 || 0,
      selectedRegular:
        initRegAccList && initRegAccList.length ? initRegAccList.join(',') : '',
      selectedRRIF:
        initRRIFAccList && initRRIFAccList.length
          ? initRRIFAccList.join(',')
          : '',
      selectedTFSA:
        initTFSAAccList && initTFSAAccList.length
          ? initTFSAAccList.join(',')
          : '',
      ...(isRiskFree
        ? {
            isRiskFree: true,
            constantRets: Number(constantRets) / 100,
            portfolio: undefined,
          }
        : {
            isRiskFree: false,
            portfolio,
          }),
      ...(isRiskFree2
        ? {
            isRiskFree2: true,
            constantRets2: Number(constantRets2) / 100,
            portfolio2: undefined,
          }
        : {
            isRiskFree2: false,
            portfolio2,
          }),
    };

    if (Number(match.params.id)) {
      dispatch(Planning.updateCoupleDrawdownPlan(match.params.id, body));
    } else {
      dispatch(Planning.saveCoupleDrawdownPlan(body, history));
    }
    dispatch(Planning.updateJustsaveOrFetch(true));

    return this.setState({ saveModalIsOpen: false, loading: false });
  };

  handleClick = () => this.setState({ saveModalIsOpen: true });

  handleCancel = () => {
    const { name, description } = this.props;

    return this.setState({
      saveModalIsOpen: false,
      name: name || '',
      description: description || '',
    });
  };

  generatePDF = () => {
    const { match, history } = this.props;

    return history.push(`/planning/drawdown-couples/${match.params.id}/pdf`);
  };

  render() {
    const { match, drawdown, partialSuccess, partnerName } = this.props;
    const { saveModalIsOpen, name, description, loading } = this.state;

    return (
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              right: '1rem',
            }}
          >
            {!partialSuccess && (
              <Popup
                trigger={
                  <RoundItem
                    className={cn('left', {
                      disabled: drawdown.modified || drawdown.justSaveOrFetch,
                    })}
                    onClick={
                      match.params.id === 'new'
                        ? this.handleClick
                        : this.handleSave
                    }
                  >
                    <i
                      className="ui icon save outline"
                      style={{ transform: 'translateY(-8px)', margin: 0 }}
                    />
                  </RoundItem>
                }
                position="bottom center"
                content="Save your Plan"
              />
            )}
            <Popup
              trigger={
                <RoundItem onClick={this.handleEditClick}>
                  <i
                    className="ui icon pencil"
                    style={{ transform: 'translateY(-8px)', margin: 0 }}
                  />
                </RoundItem>
              }
              position="bottom center"
              content="Edit your Plan"
            />
            {!partialSuccess && (
              <Popup
                trigger={
                  <RoundItem
                    className={cn('left', { disabled: drawdown.modified })}
                    onClick={this.generatePDF}
                  >
                    <i
                      className="ui icon print"
                      style={{ transform: 'translateY(-8px)', margin: 0 }}
                    />
                  </RoundItem>
                }
                position="bottom center"
                content="Print / PDF your Plan"
              />
            )}
          </div>
        </div>

        <Modal open={saveModalIsOpen} vb={notWealthica(partnerName)}>
          <Modal.Header>
            <Header icon="briefcase" content="Save your Plan" color="green" />
          </Modal.Header>
          <Modal.Content>
            <Field>
              Name:
              <input
                placeholder="(required)"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Field>
            <Field>
              Description:
              <input
                placeholder="(optional)"
                name="description"
                value={description}
                onChange={this.handleChange}
              />
            </Field>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="left inverted"
              icon="cancel"
              color={colors.red}
              margin="0 0.5rem 0 0"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={cn('left inverted', {
                loading,
                disabled: !name || !name.trim(),
              })}
              icon="checkmark"
              color={colors.green}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

SaveModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  drawdown: PropTypes.object,
  partialSuccess: PropTypes.bool,
  partnerName: PropTypes.string,
};

SaveModal.defaultProps = {
  name: '',
  description: '',
  drawdown: {},
  partialSuccess: false,
  partnerName: '',
};

export default withRouter(
  connect((state) => ({
    partnerName: state.Storage['partner-name'],
  }))(SaveModal)
);

const Field = styled.div`
  padding: 1rem 0 0 0;
  font-size: 1.14rem;

  &:first-child {
    padding: 0 0 1rem 0;
    border-bottom: 1px solid grey;
  }

  input {
    background: orange;
    float: right;
    border: 0;
    background: transparent;
    width: 80%;
    color: white;

    &:focus {
      outline: 0;
    }
  }
`;

const RoundItem = styled.div`
  background: ${colors.red};
  z-index: 2;
  color: white;
  text-align: center;
  width: 45px;
  margin: 1.2rem 0.55rem;
  min-height: 45px;
  border-radius: 50%;
  font-size: 1.5rem;
  line-height: 40px;
  transition: all 200ms ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
