import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Header, Grid, Divider, Popup, Flag } from 'semantic-ui-react';

import { colors } from 'utils/colors';
import { ACCOUNTS_ANALYZE, ACCOUNTS_ADDITIONAL_FEATURES } from 'utils/features';
import { Accounts, Portfolio, Storage } from 'actions';
import { Segment, Button, Loading, Feature } from 'components';
import { HoldingTable, GicTable, AssetChart } from './components';

class Overview extends Component {
  componentWillMount = () => {
    const { dispatch, overview } = this.props;

    if (!overview) {
      return dispatch(Accounts.fetchAll());
    }

    return false;
  };

  componentWillUnmount() {
    const { dispatch, history } = this.props;

    if (
      history.location.pathname !== '/accounts/user' &&
      history.location.pathname !== '/portfolio/unsaved/overview'
    ) {
      dispatch(Accounts.saveSingleAccInfo(''));
    }
  }

  round = (num) => (!num ? 0 : Math.round(num * 10000) / 10000);

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  analyze = (holdings) => {
    const { dispatch, history } = this.props;
    const gicHoldings = holdings.filter((holding) => holding.gic_ticker);
    Storage.removeItem('pwpa-has-exclude-holding');

    return dispatch(
      Accounts.analyze(
        holdings,
        history,
        { user_institution: 'Overview; All Accounts' },
        gicHoldings
      )
    );
  };

  render() {
    const {
      dispatch,
      overview,
      history,
      accountList,
      pwpaExchangeRate,
      user_region,
    } = this.props;
    const excludeGicHoldings =
      overview && overview.holdings.filter((holding) => !holding.gic_ticker);
    const gicHoldings =
      overview && overview.holdings.filter((holding) => holding.gic_ticker);

    return !overview ? (
      <Loading active />
    ) : (
      <Segment>
        <Header
          size="large"
          content="Overview"
          subheader="Key portfolio information"
        />
        <Feature name={ACCOUNTS_ANALYZE}>
          <Button
            className="left"
            icon="line chart"
            color={colors.darkBlue}
            onClick={() => {
              this.analyze(overview.holdings);
              dispatch(Portfolio.notSaveUrlSc());
            }}
            style={{ position: 'absolute', top: '1.6rem', right: '5rem' }}
          >
            Analyze
          </Button>
        </Feature>
        <Feature name={ACCOUNTS_ADDITIONAL_FEATURES}>
          <MoreActions>
            <div className="trigger">...</div>
            <div className="action-item">
              <Popup
                trigger={
                  <Button
                    className="left"
                    color={colors.red}
                    style={{
                      backgroundColor: 'white',
                      display: 'block',
                      width: '130px',
                    }}
                    icon="law"
                    // onClick commented out per request to remove this functionality. Leaving as comment for future reference. Requires dispatch import and Alert import from Actions.
                    // onClick={() => {
                    //   if (excludeGicHoldings.length !== 0) {
                    //     history.push('/accounts/overview/compare');
                    //   } else {
                    //     dispatch(
                    //       Alert.show({
                    //         type: 'warning',
                    //         msg: 'Not enough matched holdings to compare this account',
                    //       })
                    //     );
                    //   }
                    // }}
                  >
                    What-if
                  </Button>
                }
                position="bottom"
                wide
              >
                <Popup.Header>
                  The “What-if” scenario analysis has been moved to the
                  Portfolio Scorecard
                </Popup.Header>
                <Popup.Content>
                  <br />
                  Analyze your account(s) first, and then click the{' '}
                  <Icon
                    name="balance scale"
                    size="medium"
                    color={colors.red}
                  />{' '}
                  icon on the left-hand-side of the Scorecard.
                </Popup.Content>
              </Popup>
              <Button
                className="left"
                color={colors.red}
                style={{
                  backgroundColor: 'white',
                  display: 'block',
                  width: '130px',
                  marginTop: '5px',
                }}
                icon="money"
                onClick={() => history.push('/accounts/overview/cash')}
              >
                Cash
              </Button>
            </div>
          </MoreActions>
        </Feature>
        <Divider fitted />

        {!overview.holdings.length ? (
          <Header
            size="small"
            content="When you add holdings to your accounts, they will be listed here."
          />
        ) : (
          <div>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={7}>
                  <div style={{ marginTop: '1.3rem' }}>
                    <div style={{ float: 'left', padding: '0 2rem 1rem 0' }}>
                      <Header
                        size="medium"
                        content="Total Accounts Value:"
                        sub
                        style={{ margin: 0, fontWeight: 700 }}
                      />
                      <Value className="main">
                        {`$${this.parseNum(overview.balance)}`}
                        <Flag
                          name={user_region === 'CA' ? 'ca' : 'us'}
                          style={{
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: 7,
                          }}
                        />
                      </Value>
                    </div>

                    <div style={{ float: 'left', padding: '0 0 1rem 0' }}>
                      <Header size="medium" sub style={{ margin: 0 }}>
                        Daily Change
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{ fontSize: '1rem', verticalAlign: 'top' }}
                            />
                          }
                          position="right center"
                          content="Of holdings that are matched only"
                        />
                      </Header>
                      <Value>{`${overview.change}%`}</Value>
                    </div>
                  </div>

                  <div style={{ clear: 'both' }}>
                    <Header
                      size="medium"
                      content="Total Holdings Value:"
                      sub
                      style={{ margin: 0 }}
                    />
                    <Value>{`$${this.parseNum(
                      overview.balance - overview.cash
                    )}`}</Value>
                  </div>
                  <div style={{ marginTop: '1.3rem' }}>
                    <Header
                      size="medium"
                      content="Total Cash Value:"
                      sub
                      style={{ margin: 0 }}
                    />
                    <Value>{`$${this.parseNum(overview.cash)}`}</Value>
                  </div>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Segment basic compact>
                    <Header
                      size="medium"
                      textAlign="center"
                      style={{ margin: '0 0 1rem 0' }}
                    >
                      Holdings by Asset Class&nbsp;
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        content="This is your net asset allocation, reflecting any balanced funds/asset allocation funds that you may have."
                        wide
                      />
                    </Header>
                    <AssetChart accounts={accountList} overview={overview} />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <HoldingTable
              accountList={accountList}
              totalMarketValue={overview.balance - overview.cash}
              holdings={excludeGicHoldings.slice(0, 10)}
              accountName=""
              title="Top 10 Holdings"
              rate={pwpaExchangeRate}
              user_region={user_region}
            />
            <GicTable
              user_region={user_region}
              gicHoldings={gicHoldings}
              totalMarketValue={overview.balance - overview.cash}
              title="GIC/HISAs"
              rate={pwpaExchangeRate}
            />
            {excludeGicHoldings.length !== 0 && (
              <P>
                {user_region === 'CA' ? (
                  <React.Fragment>
                    Currently, we have data for Canadian and US stocks/ETFs,
                    Canadian mutual and segregated funds, and CAD/USD HISA
                    funds.
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{ fontSize: '15px', verticalAlign: 'initial' }}
                        />
                      }
                      position="top center"
                      content="CAD/USD HISA funds are included using the historical rates of a representative bank-owned CAD/USD HISA fund."
                    />
                    <br />
                    Any other securities will be excluded from the analysis.
                  </React.Fragment>
                ) : (
                  'Currently we only have data for U.S. listed stocks and ETFs, U.S. mutual funds, Bitcoin, and Ethereum. Any other security will be excluded from the analysis.'
                )}
              </P>
            )}
            {excludeGicHoldings.length !== 0 && user_region === 'CA' && (
              <P>USD/CAD exchange rate used is {pwpaExchangeRate || 1.3}</P>
            )}
          </div>
        )}
      </Segment>
    );
  }
}

Overview.propTypes = {
  history: PropTypes.object.isRequired,
  overview: PropTypes.object,
  dispatch: PropTypes.func,
  accountList: PropTypes.array,
  pwpaExchangeRate: PropTypes.number,
  user_region: PropTypes.string,
};

Overview.defaultProps = {
  overview: {},
  dispatch: () => {},
  accountList: [],
  pwpaExchangeRate: 0,
  user_region: 'CA',
};

export default connect((state) => ({
  overview: state.Accounts.overview,
  accountList: state.Accounts.accountList,
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region || 'CA',
}))(Overview);

const Value = styled.div`
  font-size: 1.8rem;
  padding: 7px 0;

  &.main {
    font-size: 2.4rem;
    font-weight: 700;
    color: ${colors.orange};
  }
`;

const MoreActions = styled.div`
  .action-item {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 0;
    padding: 2rem 0.5rem 0.5rem 0.5rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, top 150ms ease;

    &:hover {
      opacity: 1;
      visibility: visible;
      top: 40px;
    }
  }

  .trigger {
    z-index: 2;
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    font-size: 30px;
    background: white;
    color: rgba(0, 0, 0, 0.6);
    height: 35px;
    width: 35px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
      border: 1px solid ${colors.darkBlue};
      color: ${colors.darkBlue};

      & ~ .action-item {
        opacity: 1;
        visibility: visible;
        top: 40px;
      }
    }
  }

  &:hover ~ .trigger {
    border: 1px solid ${colors.darkBlue};
    color: ${colors.darkBlue};
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  margin: 0.7rem auto;
  max-width: 100%;
  line-height: 1.1rem;
  text-align: center;
`;
