import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const YouTubePlayer = ({ isOpen, id, ...rest }) => (
  <Section isOpen={isOpen} {...rest}>
    {isOpen && (
      <iframe
        src={`https://www.youtube.com/embed/${id}?rel=0&amp;controls=0&amp;showinfo=0`}
        title="YouTube"
        frameBorder="0"
      />
    )}
  </Section>
);

export default YouTubePlayer;

YouTubePlayer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

const Section = styled(({ className, children, isOpen, ...rest }) => (
  <section className={className} {...rest}>
    {children}
  </section>
))`
  position: relative;
  height: ${(props) => (props.isOpen ? '27rem' : '0')};
  max-width: 47rem;
  transition: all 0.5s ease;
  margin: ${(props) => (props.isOpen ? '0 0 1rem 0' : '0')};
  overflow: hidden;

  & iframe {
    height: 100%;
    min-height: 27rem;
    width: 100%;
  }
`;
