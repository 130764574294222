import { Portfolio } from 'actions';

const initialState = {
  isFetching: false,
  user: [],
  model: [],
  editing: { id: '', name: '', description: '' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Portfolio.PORTFOLIO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Portfolio.PORTFOLIO_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.data,
      };
    case Portfolio.PORTFOLIO_MODEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        model: action.data,
      };
    case Portfolio.PORTFOLIO_UPDATE_SUCCESS: {
      const newPortfolios = state.user.map((port) => {
        if (port.id === action.data.id) {
          return action.data;
        }

        return port;
      });

      return {
        ...state,
        isFetching: false,
        user: newPortfolios,
      };
    }
    case Portfolio.PORTFOLIO_REMOVE_SUCCESS:
      return {
        ...state,
        user: state.user.filter((portfolio) => portfolio.id !== action.id),
      };
    case Portfolio.PORTFOLIO_FAIL:
      return initialState;
    case Portfolio.PORTFOLIO_EDITING_UPDATE:
      return {
        ...state,
        editing: {
          id: action.id,
          name: action.name,
          description: action.description,
        },
      };
    default:
      return state;
  }
}
