import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Icon,
  Dropdown,
  Form,
  Input,
  Header,
  Popup,
  Radio,
  Divider,
} from 'semantic-ui-react';
import cn from 'classnames';

import { colors } from 'utils/colors';
import { isGlobeInvestor } from 'utils/helpers';
import { Accounts, CacheInputs, Planning, Portfolio } from 'actions';
import {
  AccountsDropdown,
  Message,
  Segment,
  ErrorLabel,
  Button,
} from 'components';
import Result from './Result';
import PortfoliosList from '../components/PortfoliosList';

class TargetSaving extends Component {
  state = {
    isChanged: false,
  };
  componentWillMount = () => {
    const { dispatch, targetSavingInputs, partnerName } = this.props;
    const { inf } = targetSavingInputs;

    dispatch(Portfolio.fetchModel());
    if (!isGlobeInvestor(partnerName)) {
      dispatch(Portfolio.fetchUser());
      dispatch(Accounts.fetchAll());
    }
    if (!inf) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          inf: 2,
          errors: { inf: null },
        })
      );
    }

    return false;
  };

  getPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    const date = !port ? [0] : port.init_date.split('-');

    if (Number(date[0]) < Number(date[0]) - 5) {
      return false;
    }

    return `${date[1]}/${date[0]}`;
  };

  getNewPortDate = (port) => {
    if (port && !port.init_date) {
      return false;
    }

    if (!port || !port.init_date) {
      return false;
    }
    const date = new Date(port.init_date);
    const today = new Date();
    const monthDifference =
      today.getMonth() -
      date.getMonth() +
      12 * (today.getFullYear() - date.getFullYear());
    if (monthDifference > 60) {
      return false;
    }

    const init = date.toDateString().toString().split(/\s+/);

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sept: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    return `${months[init[1]]} ${init[3]}`;
  };

  getDropOptions = () => {
    const { portfolios, user_region, partnerName } = this.props;
    const icons = {
      'WS All-In': 'lightning',
      'WS Adventurous': 'map signs',
      'WS Classic': 'leaf',
      'WS Cautious': 'cloud',
      'WS Playing-It-Safe': 'anchor',
    };

    return portfolios.map((portf) => ({
      key: portf.id,
      value: portf.id,
      icon: portf.is_mp ? icons[portf.name] : 'briefcase',
      text:
        user_region === 'US' || isGlobeInvestor(partnerName)
          ? this.nameForUsVersion(portf.name)
          : portf.name,
    }));
  };

  nameForUsVersion = (name) => {
    if (name === 'WS All-In') {
      return '100 Equities / 0 Bonds';
    } else if (name === 'WS Adventurous') {
      return '80 Equities / 20 Bonds';
    } else if (name === 'WS Classic') {
      return '60 Equities / 40 Bonds';
    } else if (name === 'WS Cautious') {
      return '40 Equities / 60 Bonds';
    } else if (name === 'WS Playing-It-Safe') {
      return '20 Equities / 80 Bonds';
    }
    return name;
  };

  handleSubmit = () => {
    const { dispatch, portfolios, targetSavingInputs } = this.props;
    const { targ, inf, init, horz, portID, annuityRate } = targetSavingInputs;

    if (this.hasEmptyValue(Object.keys({ targ, init, inf, horz, portID }))) {
      return false;
    }

    const portfolio = portfolios.find(
      (item) => item.id.toString() === portID.toString()
    );
    const params = {
      symbols: portfolio.symbols,
      weights: portfolio.weights,
      region: portfolio.region,
      gic_info: portfolio.gic_info,
      init_date: portfolio.init_date,
      annuity_rate: annuityRate / 100,
      targ,
      inf: inf === 0 ? 0 : inf / 100 || 0.02,
      init,
      horz,
      is_targ_nominal: false,
    };
    this.setState({ isChanged: false });
    return dispatch(Planning.analyzeSaving(params)).then(() =>
      this.scrollHandler(
        document.documentElement,
        document.getElementById('ref').offsetHeight,
        600
      )
    );
  };

  handleRadioChange = (e, { name, value }) => {
    const { dispatch, targetSavingInputs } = this.props;
    const { errors } = targetSavingInputs;
    dispatch(Planning.clearSectionData('wealthResult'));

    dispatch(
      CacheInputs.updateField('planning', 'target_saving', {
        portID: value,
        modelType: name,
        annuityRate: null,
        errors: { ...errors, portfolioID: null },
      })
    );
  };

  handlePortfolioChange = (e, { name, value }) => {
    const { dispatch, targetSavingInputs } = this.props;

    dispatch(Planning.clearSectionData('savingResult'));

    const limitRegex = /^[0-9]{1,9}$/;

    if (name === 'annuityRate') {
      if (value === '' || !limitRegex.test(value)) {
        dispatch(
          CacheInputs.updateField('planning', 'target_saving', {
            ...targetSavingInputs,
            riskFree: true,
            useDefault: true,
            portfolio: null,
            modelType: 'annuityRate',
            annuityRate: null,
          })
        );
        return;
      }
      const num = Number(value) > 100 ? 100 : Number(value);
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          ...targetSavingInputs,
          modelType: 'annuityRate',
          annuityRate: num,
        })
      );
    } else
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          name,
          modelType: name,
          portID: value,
          errors: { portID: null },
        })
      );
  };

  isCalculateDisabled = () => {
    const { targetSavingInputs } = this.props;
    const { initFromAcc, initAccList } = targetSavingInputs;

    if (initAccList && initAccList.length === 0 && initFromAcc) return true;
    return false;
  };

  hasEmptyValue = (values) =>
    values.some((field) => {
      const { dispatch, targetSavingInputs } = this.props;

      if (!targetSavingInputs[field] && targetSavingInputs[field] !== 0) {
        return dispatch(
          CacheInputs.updateField('planning', 'target_saving', {
            errors: {
              [field]:
                field === 'portID'
                  ? 'Please select a portfolio'
                  : 'This field is required',
            },
          })
        );
      }

      return false;
    });

  scrollHandler = (element, to, duration) => {
    if (duration <= 0) return false;
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    return setTimeout(() => {
      element.scrollTop += perTick;
      // for safari compatibility
      if (element.scrollTop === 0) {
        document.body.scrollTop += perTick;
      }
      if ((element.scrollTop || document.body.scrollTop) >= to) return false;
      return this.scrollHandler(element, to, duration - 10);
    }, 10);
  };

  handleChange = (e, { name, value }) => {
    const { dispatch, targetSavingInputs } = this.props;
    const { errors } = targetSavingInputs;
    const num = Number(value);
    const limitRegex = /^[0-9]{1,9}$/;
    if (value !== '' && !limitRegex.test(value)) return;
    if (name === 'horz' && value === '0') return;
    if (name === 'inf' && num < -100) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: 0,
          errors: { ...errors, inf: null },
        })
      );
      this.setState({ isChanged: true });
    } else if (name === 'inf' && num > 100) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: 100,
          errors: { ...errors, inf: null },
        })
      );
      this.setState({ isChanged: true });
    } else if (name === 'horz' && num > 100) {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: 100,
          errors: { ...errors, horz: null },
        })
      );
      this.setState({ isChanged: true });
    } else {
      dispatch(
        CacheInputs.updateField('planning', 'target_saving', {
          [name]: value ? Number(value) : undefined,
          errors: { ...errors, [name]: null },
        })
      );
      this.setState({ isChanged: true });
    }
  };

  render() {
    const {
      accountList,
      portfolios,
      isFetching,
      targetSavingInputs,
      dispatch,
      user_region,
      partnerName,
      modelPortfolios,
      result,
    } = this.props;
    const {
      targ,
      inf,
      init,
      initFromAcc,
      initAccList,
      horz,
      portID,
      errors,
      modelType,
      annuityRate,
    } = targetSavingInputs;
    const portDate = this.getNewPortDate(
      portfolios.find((port) => Number(port.id) === portID)
    );

    return (
      <div
        style={{
          border: isGlobeInvestor(partnerName)
            ? '1px solid rgba(0, 0, 0, 0.2)'
            : 'none',
          overflowX: 'hidden',
        }}
      >
        <Segment>
          <div id="ref">
            {!isGlobeInvestor(partnerName) && (
              <React.Fragment>
                <Header
                  textAlign="center"
                  size="large"
                  content="Target Saving"
                  subheader="In this segment, you can determine how much money you will need to save each month, with the chosen portfolio, to reach a target amount in your future. This target could be for your retirement, or for your childrens’ education, etc."
                />
                <Section globe={isGlobeInvestor(partnerName)}>
                  <Form>
                    <Header
                      size="medium"
                      content="Enter Your Inputs"
                      style={{
                        margin: '1rem 0 0 0',
                        transform: 'translateX(6rem)',
                      }}
                    />
                    <Form.Group
                      grouped
                      style={{
                        width: '100%',
                        maxWidth: '20rem',
                        display: 'inline-block',
                        textAlign: 'left',
                        marginTop: '1rem',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          transform: 'translateX(-6rem)',
                        }}
                      >
                        <Form.Field
                          style={{
                            position: 'relative',
                            marginRight: '3rem',
                          }}
                        >
                          <label>Target amount in today&#8217;s dollars:</label>
                          <Input
                            name="targ"
                            value={targ === 0 ? '0' : targ || ''}
                            label={{ basic: true, content: '$' }}
                            onChange={this.handleChange}
                          />
                          <ErrorLabel msg={errors.targ} />
                        </Form.Field>
                        <Form.Field style={{ position: 'relative' }}>
                          <label>
                            Expected annual inflation rate:
                            <Popup
                              trigger={
                                <Icon
                                  name="question circle outline"
                                  style={{
                                    fontSize: '15px',
                                    verticalAlign: 'initial',
                                    color: 'dimgrey',
                                  }}
                                />
                              }
                              position="top center"
                              wide
                            >
                              Central Bank&#8217;s target is 2% for a general
                              basket of goods and services. Adjust it for your
                              specific savings goal or leave it at 2%.
                            </Popup>
                          </label>
                          <Input
                            name="inf"
                            value={inf === 0 ? '0' : inf || ''}
                            label={{ basic: true, content: '%' }}
                            labelPosition="right"
                            defaultValue="2"
                            onChange={this.handleChange}
                          />
                          <ErrorLabel msg={errors.inf} />
                        </Form.Field>
                      </div>

                      {/* Pending cleanup of this section. Remove GlobeInvestor content. */}

                      <div>
                        <Form.Field style={{ position: 'relative' }}>
                          <label
                            style={{
                              width: '100vw',
                              transform: 'translateX(-5rem)',
                              marginTop: 'auto',
                            }}
                          >
                            How much you have now:{' '}
                            <Form style={{ display: 'inline-block' }}>
                              <div>
                                <Form.Field
                                  style={{
                                    display: 'inline-block',
                                    margin: '0 1rem',
                                  }}
                                >
                                  <Radio
                                    name="initFromAcc"
                                    checked={!initFromAcc}
                                    onChange={() =>
                                      dispatch(
                                        CacheInputs.updateField(
                                          'planning',
                                          'target_saving',
                                          {
                                            init: 0,
                                            initFromAcc: false,
                                            initAccList: [],
                                          }
                                        )
                                      )
                                    }
                                    label="Input Value"
                                  />
                                </Form.Field>
                                <Form.Field style={{ display: 'inline-block' }}>
                                  <Radio
                                    name="initFromAcc"
                                    checked={initFromAcc}
                                    onChange={() =>
                                      dispatch(
                                        CacheInputs.updateField(
                                          'planning',
                                          'target_saving',
                                          {
                                            init: 0,
                                            initFromAcc: true,
                                            initAccList: [],
                                          }
                                        )
                                      )
                                    }
                                    label={
                                      partnerName === 'quotemedia_sandbox' ||
                                      partnerName === 'quotemedia_retail' ||
                                      partnerName === 'quotemedia_pro'
                                        ? 'Select from Portfolios'
                                        : 'Select from Accounts'
                                    }
                                  />
                                </Form.Field>
                              </div>
                            </Form>
                          </label>
                          {initFromAcc ? (
                            <AccountsDropdown
                              style={{ transform: 'translateX(5rem)' }}
                              inputs={{}}
                              accountList={accountList}
                              selectedAccList={initAccList || []}
                              excludedList={[]}
                              savedAccountsValue={init || 0}
                              assignAccountsValue={(
                                selectedAccounts,
                                accountsValue
                              ) => {
                                dispatch(
                                  CacheInputs.updateField(
                                    'planning',
                                    'target_saving',
                                    {
                                      init: accountsValue,
                                      initAccList: selectedAccounts,
                                      errors: {
                                        ...errors,
                                        init: accountsValue
                                          ? null
                                          : errors.init,
                                      },
                                    }
                                  )
                                );
                              }}
                              float={false}
                              error={errors.init}
                              zIndex={0}
                              isQuoteMedia={
                                partnerName === 'quotemedia_sandbox' ||
                                partnerName === 'quotemedia_retail' ||
                                partnerName === 'quotemedia_pro'
                              }
                            />
                          ) : (
                            <div>
                              <Input
                                name="init"
                                value={init === 0 ? '0' : init || ''}
                                label={{ basic: true, content: '$' }}
                                onChange={this.handleChange}
                              />
                              <ErrorLabel msg={errors.init} />
                            </div>
                          )}
                        </Form.Field>
                        <Form.Field style={{ zIndex: '0' }}>
                          <label
                            style={{
                              zIndex: '-1',
                              width: '100vw',
                              marginTop: !isGlobeInvestor(partnerName)
                                ? '1rem'
                                : 'auto',
                            }}
                          >
                            Years to target date:{' '}
                          </label>
                          <Input
                            name="horz"
                            value={horz || ''}
                            label={{ basic: true, content: 'years' }}
                            labelPosition="right"
                            onChange={this.handleChange}
                            style={{ width: '100%', position: 'static' }}
                          />
                          <ErrorLabel msg={errors.horz} />
                        </Form.Field>
                      </div>
                    </Form.Group>
                  </Form>
                </Section>
              </React.Fragment>
            )}

            {/* Globe Investor Section Starts */}
            {isGlobeInvestor(partnerName) && (
              <Section globe={isGlobeInvestor(partnerName)}>
                <div className="heading">Inputs</div>
                <Form>
                  <GridContainer>
                    <GridItem>
                      <Form.Field style={{ flex: 1, marginRight: '1rem' }}>
                        <label>Target amount in today&#8217;s dollars:</label>
                        <Input
                          name="targ"
                          value={targ === 0 ? '0' : targ || ''}
                          label={{ basic: true, content: '$' }}
                          onChange={this.handleChange}
                          style={{ maxWidth: '200px' }}
                        />
                        <ErrorLabel msg={errors.targ} />
                      </Form.Field>
                    </GridItem>
                    <GridItem>
                      <Form.Field style={{ flex: 1 }}>
                        <label>
                          Expected annual inflation rate:
                          <Popup
                            trigger={
                              <Icon
                                name="question circle outline"
                                style={{
                                  fontSize: '15px',
                                  verticalAlign: 'initial',
                                  color: 'dimgrey',
                                }}
                              />
                            }
                            position="top center"
                            wide
                          >
                            Central Bank&#8217;s target is 2% for a general
                            basket of goods and services. Adjust it for your
                            specific savings goal or leave it at 2%.
                          </Popup>
                        </label>
                        <Input
                          name="inf"
                          value={inf === 0 ? '0' : inf || ''}
                          label={{ basic: true, content: '%' }}
                          labelPosition="right"
                          defaultValue="2"
                          onChange={this.handleChange}
                          style={{ maxWidth: '200px' }}
                        />
                        <ErrorLabel msg={errors.inf} />
                      </Form.Field>
                    </GridItem>
                    <GridItem>
                      <Form.Field style={{ flex: 1, marginRight: '1rem' }}>
                        <label>How much you have now: </label>
                        <div>
                          <Input
                            name="init"
                            value={init === 0 ? '0' : init || ''}
                            label={{ basic: true, content: '$' }}
                            onChange={this.handleChange}
                            style={{ maxWidth: '200px' }}
                          />
                          <ErrorLabel msg={errors.init} />
                        </div>
                      </Form.Field>
                    </GridItem>
                    <GridItem>
                      <Form.Field style={{ flex: 1 }}>
                        <label>Years to target date: </label>
                        <Input
                          name="horz"
                          value={horz || ''}
                          label={{ basic: true, content: 'years' }}
                          labelPosition="right"
                          onChange={this.handleChange}
                          style={{ maxWidth: '150px' }}
                        />
                        <ErrorLabel msg={errors.horz} />
                      </Form.Field>
                    </GridItem>
                  </GridContainer>
                </Form>
              </Section>
            )}
            {/* Globe Investor Section end */}

            {!isGlobeInvestor(partnerName) && (
              <Section globe={isGlobeInvestor(partnerName)} select>
                <Header
                  size="medium"
                  style={{ margin: '2rem 0 0 0' }}
                  textAlign="center"
                >
                  {partnerName === 'quotemedia_pro' ||
                  partnerName === 'quotemedia_sandbox' ||
                  partnerName === 'quotemedia_retail'
                    ? 'Select Simulation'
                    : 'Select Portfolio'}
                  <Header.Subheader>
                    <div
                      style={{
                        textAlign: 'center',
                        maxWidth: '45rem',
                        margin: '0 auto 1rem auto',
                        color: 'rgba(0,0,0,0.6)',
                      }}
                    >
                      {user_region === 'US'
                        ? 'This is the portfolio you will be investing in for your financial goal. In addition to five simple models (using a combination of S&P 500 and U.S. Aggregate Bonds ETFs), you can also use your saved portfolios from the "Portfolios" segment here. You will see them in the dropdown list below.'
                        : isGlobeInvestor(partnerName)
                        ? 'There are five simple index model portfolios in the dropdown list below. They hold a combination of Canadian universe bond and equity (50% Canadian and 50% U.S.) ETFs.'
                        : `This is the portfolio you will be investing in for your financial goal. In addition to Wealthscope (WS) model portfolios, you can also use your saved portfolios from the ${
                            partnerName === 'quotemedia_pro' ||
                            partnerName === 'quotemedia_retail' ||
                            partnerName === 'quotemedia_sandbox'
                              ? '"Simulations"'
                              : '"Portfolios"'
                          } segment here. You will see them in the dropdown list below.`}
                    </div>
                  </Header.Subheader>
                </Header>
                <div
                  style={{
                    position: 'relative',
                    width: '20rem',
                    margin: '0 auto',
                  }}
                >
                  <Dropdown
                    upward
                    placeholder={
                      partnerName === 'quotemedia_sandbox' ||
                      partnerName === 'quotemedia_retail' ||
                      partnerName === 'quotemedia_pro'
                        ? 'Simulation'
                        : 'Portfolio'
                    }
                    selection
                    options={this.getDropOptions(portfolios)}
                    value={portID}
                    onChange={this.handlePortfolioChange}
                    style={{
                      width: '100%',
                      maxWidth: '20rem',
                      margin: '0 auto',
                    }}
                  />
                  <ErrorLabel msg={errors.portID} />
                </div>
                <Message
                  icon
                  visible={!!portDate}
                  warning
                  style={{
                    margin: '1rem auto 0 auto',
                    maxWidth: '45rem',
                    textAlign: 'left',
                  }}
                >
                  <Icon name="exclamation" />
                  <Message.Content>
                    This portfolio has a start date of{' '}
                    <strong>{portDate}</strong>. You may wish to use a portfolio
                    with a longer history. You can use the ETF Portfolio Builder
                    in the "Portfolios" segment. Once saved, the portfolio will
                    appear in the dropdown list here.
                  </Message.Content>
                </Message>
              </Section>
            )}
            {isGlobeInvestor(partnerName) && (
              <Section select globe={isGlobeInvestor(partnerName)}>
                <div className="heading">Select a Model Portfolio</div>
                <div
                  style={{
                    textAlign: 'center',
                    maxWidth: '45rem',
                    margin: '0 auto',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  This is the portfolio you will be investing in for your
                  financial goal. Equity is half Canadian and half U.S.
                  large-cap equity; bonds are Canadian universe bonds.
                </div>
                <Divider style={{ width: '100%' }} />
                <div style={{ position: 'relative', width: '100%' }}>
                  <PortfoliosList
                    portfolios={modelPortfolios}
                    portfolioID={portID ? portID.toString() : null}
                    annuityRate={annuityRate}
                    handlePortfolioChange={this.handlePortfolioChange}
                    handleRadioChange={this.handleRadioChange}
                    modelType={modelType || ''}
                    style={{ margin: 'initial' }}
                    user_region={user_region}
                    partnerName={partnerName}
                    saving
                  />
                  <ErrorLabel
                    className="bottom"
                    msg={errors.portfolioID}
                    style={{ top: '130%' }}
                  />
                </div>
              </Section>
            )}
            <Button
              className={cn('centered right', {
                loading: isFetching,
                disabled:
                  this.isCalculateDisabled() ||
                  (result && !this.state.isChanged),
              })}
              color={colors.orange}
              icon="calculator"
              onClick={this.handleSubmit}
            >
              Calculate
            </Button>
          </div>
        </Segment>
        <Result inf={inf} />
      </div>
    );
  }
}

TargetSaving.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  targetSavingInputs: PropTypes.object,
  accountList: PropTypes.array,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  modelPortfolios: PropTypes.array,
  result: PropTypes.object,
};

TargetSaving.defaultProps = {
  portfolios: [],
  targetSavingInputs: {},
  accountList: [],
  user_region: 'CA',
  partnerName: '',
  modelPortfolios: [],
  result: null,
};

export default connect((state) => ({
  isFetching: state.Planning.isFetching,
  modelPortfolios: state.Portfolio.model,
  portfolios: state.Portfolio.model.concat(state.Portfolio.user),
  targetSavingInputs: state.CacheInputs.planning.target_saving,
  accountList: state.Accounts.accountList,
  user_region: state.Storage.user_region,
  partnerName: state.Storage['partner-name'],
  result: state.Planning.savingResult,
}))(TargetSaving);

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.select ? 'column' : '')};
  border: ${(props) => (props.globe ? '1px solid rgba(0, 0, 0, 0.2)' : 'none')};
  padding: ${(props) => (props.globe ? '2rem' : '0')};
  border-radius: 8px;
  position: relative;
  text-align: left;
  margin: ${(props) => (props.globe ? '2rem auto' : '0')};
  line-height: 1.5rem;
  transition: all 200ms ease;

  &.invalid {
    border: 1px solid ${colors.red};
  }

  .heading {
    position: absolute;
    top: -19px;
    left: 20px;
    padding: 0.5rem;
    background: white;
    font-size: 1.28em;
    font-weight: 700;
  }

  .title {
    font-size: 1.2rem;
    margin: 1rem 0;
    display: inline-block;
  }

  ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      clear: both;
      height: 0;
      opacity: 0;
      overflow: hidden;
      line-height: 15px;
      transition: all 200ms ease;

      &.visible {
        height: 35px;
        opacity: 1;
      }
    }

    label {
      font-size: 1rem;
      display: inline-block;
      padding-left: 1rem;
      padding-top: 5px;
      padding-right: 3rem;
    }
  }

  .description {
    display: inline-block;
    font-size: 1rem;
    padding: 3px 0 0.7rem 1rem;
    line-height: 1rem;
    width: 61%;
    clear: both;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 250px;
  transform: translateX(-5rem);
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    transform: translateX(0);
  }
`;

const GridItem = styled.div`
  margin-top: 1rem;
`;
