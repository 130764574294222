/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon, Popup, Header, Flag } from 'semantic-ui-react';
import cn from 'classnames';

import { Analytics, BuildCustom, Portfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import { getTickerSearchResult } from 'utils/helpers';
import { Table, Button, Segment, SearchTicker } from 'components';
import Weight from './Weight';

@withRouter
@connect((state) => ({
  buildcustom: state.BuildCustom,
  user_region: state.Storage.user_region || 'CA',
  esg: state.Analytics.esg,
}))
export default class BuildCustomPortfolio extends Component {
  componentWillMount = () => {
    const { dispatch, match, user_region } = this.props;
    const { id } = match.params;
    dispatch(Storage.setItem('toggle_region', user_region)); // Set toggle region to user_region to avoid interference from Accounts
    dispatch(Storage.removeItem('pwpa-check-history')); // Clear local storage for include all holdings toggle cache
    dispatch(Storage.removeItem('pwpa-has-exclude-holding'));

    return Number(id) ? dispatch(BuildCustom.fetchId(id)) : false;
  };

  componentWillUnmount = () => {
    const { history, dispatch } = this.props;
    const url = history.location.pathname;
    const portfolioRegex = /^\/portfolio\/.*$/;
    const createRegex = /^\/portfolio\/create$/;
    const modelRegex = /^\/portfolio\/model$/;
    const userRegex = /^\/portfolio\/user$/;
    const portfolioRootRegex = /^\/portfolio$/;
    const cleanCache =
      createRegex.test(url) ||
      modelRegex.test(url) ||
      userRegex.test(url) ||
      portfolioRootRegex.test(url);

    if (!portfolioRegex.test(history.location.pathname)) {
      dispatch(Portfolio.setEditing('', '', ''));
    }

    if (cleanCache) {
      dispatch(Portfolio.setEditing('', '', ''));
    }
  };

  // Coulbe be 'GIC1:MKT', '~BTCUSDT', or '~ETHUSDT'
  getNonTypicalWeight = (ticker) => {
    const { buildcustom } = this.props;
    const nonTypicalTicker = Object.values(buildcustom.portfolio).find(
      (port) => port.ticker === ticker
    );

    return nonTypicalTicker ? nonTypicalTicker.weight : null;
  };

  handleChange = (e) => {
    const { dispatch } = this.props;
    const { name, value } = e.target;
    const correctNum = value === '' ? '' : Number(value);
    const num =
      correctNum === ''
        ? ''
        : Number.isInteger(correctNum)
        ? correctNum
        : value.split('.')[1].length >= 2
        ? Number(
            `${value.split('.')[0]}.${value.split('.')[1].substring(0, 2)}`
          )
        : correctNum;
    const limitRegex = /^\d*\.?\d*$/;
    const negative = !limitRegex.test(num);

    if (
      name === 'gic_weight' ||
      name === 'btc_weight' ||
      name === 'eth_weight'
    ) {
      const ticker =
        name === 'btc_weight'
          ? '~BTCUSDT'
          : name === 'eth_weight'
          ? '~ETHUSDT'
          : 'GIC1:MKT';
      dispatch(BuildCustom.addGicBtcEth(ticker));
      if (!num) {
        dispatch(BuildCustom.remove(ticker));
      } else if (num > 100) {
        dispatch(BuildCustom.updateWeight(ticker, 100));
      } else if (!negative) {
        dispatch(BuildCustom.updateWeight(ticker, num));
      }

      return false;
    } else if (name === 'gic_rate') {
      if (num > 100) {
        dispatch(BuildCustom.updateGicInfo(name, 100));
      } else if (!negative) {
        dispatch(BuildCustom.updateGicInfo(name, num));
      }

      return false;
    }

    return dispatch(BuildCustom.updateGicInfo(name, value));
  };

  filterPortfolioGIC = () => {
    const { buildcustom } = this.props;
    const gicFormat = /^GIC\d*:MKT$/;

    return Object.keys(buildcustom.portfolio)
      .filter((key) => !gicFormat.test(key))
      .filter((key) => key !== '~BTCUSDT' && key !== '~ETHUSDT')
      .reduce((obj, key) => {
        obj[key] = buildcustom.portfolio[key];
        return obj;
      }, {});
  };

  round = (num) => Math.round(num * 100) / 100;

  labelData = () => {
    const { buildcustom } = this.props;
    const totalValue = Object.values(buildcustom.portfolio).reduce(
      (total, value) => (total += Number(value.weight)),
      0
    );

    if (!totalValue || this.round(totalValue) === 100) {
      return false;
    }

    return {
      val: this.round(Math.abs(100 - totalValue)),
      msg: 100 - totalValue > 0 ? 'under' : 'over',
    };
  };

  analyze = () => {
    const { dispatch, esg, history, buildcustom, user_region } = this.props;
    const { gic_rate, gic_ticker, gic_name } = buildcustom.gic_info;
    const gicWeight = this.getNonTypicalWeight('GIC1:MKT');
    const params = Object.values(buildcustom.portfolio).reduce(
      (total, item) => {
        if (
          (item.weight && item.ticker === 'GIC1:MKT') ||
          (item.weight && item.ticker === '~BTCUSDT') ||
          (item.weight && item.ticker === '~ETHUSDT') ||
          (item.weight &&
            item.unadjusted_closing_price &&
            item.unadjusted_closing_price.CAD)
        ) {
          const weight = item.weight / 100;

          total.symbols.push(item.ticker);
          total.weights.push(weight);
        }

        return total;
      },
      { symbols: [], weights: [] }
    );

    if (!params.symbols.length) {
      return false;
    }

    if (gicWeight && gic_rate !== undefined && gic_rate !== '') {
      dispatch(
        Storage.setItem('pwpa-portfolio', {
          portfolio_type: 'custom',
          region: user_region,
          symbols: params.symbols.join(','),
          weights: params.weights.join(','),
          gic_info: {
            'GIC1:MKT': {
              gic_ticker,
              gic_name,
              gic_rate: Number(gic_rate) / 100,
            },
          },
        })
      );
    } else {
      dispatch(
        Storage.setItem('pwpa-portfolio', {
          portfolio_type: 'custom',
          region: user_region,
          symbols: params.symbols.join(','),
          weights: params.weights.join(','),
          gic_info: {},
        })
      );
    }

    if (Object.keys(esg).length) {
      dispatch(
        Analytics.fetchEsg({
          symbols: params.symbols.join(','),
          weights: params.weights.join(','),
        })
      );
    }

    return history.push('/portfolio/unsaved/overview');
  };

  remove = (ticker) => {
    const { dispatch } = this.props;

    return dispatch(BuildCustom.remove(ticker));
  };

  removeAll = () => this.props.dispatch(BuildCustom.removeAll());

  isDisabled = () => {
    const { buildcustom } = this.props;
    const gicWeight = this.getNonTypicalWeight('GIC1:MKT');
    const { portfolio, gic_info } = buildcustom;
    const { gic_rate, gic_ticker } = gic_info;
    const totalValue = Object.values(portfolio).reduce(
      (total, value) => (total += Number(value.weight)),
      0
    );

    // eslint-disable-next-line no-mixed-operators
    return (
      !totalValue ||
      this.round(totalValue) !== 100 ||
      ((gicWeight || gic_rate || gic_ticker) &&
        !(gicWeight && gic_rate && gic_ticker))
    );
  };

  render() {
    const { buildcustom, dispatch, user_region } = this.props;
    const { gic_ticker, gic_name, gic_rate } = buildcustom.gic_info;
    const { search, addGicToggle, addBtcToggle } = buildcustom;
    const gicWeight = this.getNonTypicalWeight('GIC1:MKT');
    const btcWeight = this.getNonTypicalWeight('~BTCUSDT');
    const ethWeight = this.getNonTypicalWeight('~ETHUSDT');

    return (
      <Segment
        style={{ minHeight: '100%', textAlign: 'center', padding: '2rem 8rem' }}
      >
        <Header
          size="medium"
          content="Build and Analyze a Custom Portfolio"
          style={{ margin: 0 }}
        />
        <div style={{ textAlign: 'left', marginTop: '3rem' }}>
          <Header
            style={{ fontSize: '15.5px', marginBottom: '.3rem' }}
            content="Add Holdings"
          />
          <Note>
            {user_region === 'CA'
              ? 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'
              : 'Note: Currently we only have data for US-listed stocks and ETFs, and US mutual funds.'}
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{
                    fontSize: '15px',
                    verticalAlign: 'initial',
                    color: 'dimgrey',
                  }}
                />
              }
              content="CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund."
            />
            Always check the fund code or ticker symbol to ensure you have the
            correct share class. Use US instead of U.S..
          </Note>
        </div>

        <SearchTicker
          loading={buildcustom.isFetching}
          results={getTickerSearchResult(search)}
          featureAction={BuildCustom}
          region={user_region}
        />

        {!!Object.keys(this.filterPortfolioGIC()).length && (
          <Segment
            className="basic compact"
            style={{
              opacity: buildcustom.isFetching ? 0.5 : 1,
              marginBottom: '0',
              paddingBottom: '0',
            }}
          >
            <RemoveAll onClick={this.removeAll}>clear</RemoveAll>

            <Table
              textAlign="center"
              style={{
                marginBottom: '0rem',
                borderColor: !!this.labelData() && colors.red,
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ background: 'rgba(203,232,238,0.3)' }}
                  >
                    Asset
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(203,232,238,0.3)' }}
                  >
                    Exchange
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(203,232,238,0.3)' }}
                  >
                    Class
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(203,232,238,0.3)' }}
                  >
                    Price
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    // colSpan={3}
                    style={{ background: 'rgba(203,232,238,0.3)' }}
                  >
                    Start Date{' '}
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{ fontSize: '1rem', verticalAlign: 'top' }}
                        />
                      }
                      position="right center"
                      content="The start date is the month after the inception/IPO date of your holding or April 2007, whichever comes later."
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ background: 'rgba(203,232,238,0.3)' }}
                  >
                    Weight
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.values(this.filterPortfolioGIC()).map((security) => (
                  <StyledRow
                    negative={!security.unadjusted_closing_price.CAD}
                    key={security.ticker}
                    style={{ height: 52, position: 'relative' }}
                  >
                    <Table.Cell width={5} style={{ position: 'relative' }}>
                      <i
                        className="close"
                        onClick={() => this.remove(security.ticker)}
                        style={{
                          zIndex: '1',
                          position: 'absolute',
                          top: '50%',
                          left: '0',
                        }}
                      >
                        +
                      </i>

                      {security.ticker || 'N/A'}
                      <SecurityName>{security.long_name || 'N/A'}</SecurityName>
                      {!security.unadjusted_closing_price.CAD && (
                        <Error>
                          <Popup
                            trigger={
                              <Icon
                                style={{
                                  transform: 'translate(-20px, -4px)',
                                  position: 'absolute',
                                  display: 'block',
                                  marginTop: '0',
                                }}
                                name="exclamation circle"
                              />
                            }
                            position="left center"
                            content="Unfortunately, this security cannot be added as its price is $0"
                          />
                        </Error>
                      )}
                    </Table.Cell>
                    <Table.Cell>{security.exchange_symbol || 'N/A'}</Table.Cell>
                    <Table.Cell>
                      {security.global_asset_class || 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      $
                      {this.round(
                        security.unadjusted_closing_price[
                          security.default_currency
                        ] || 0
                      )}
                      <Flag
                        name={security.default_currency === 'CAD' ? 'ca' : 'us'}
                        style={{ marginLeft: '0.3rem' }}
                      />
                    </Table.Cell>
                    <Table.Cell style={{ width: '170px' }}>
                      {security.start_date || 'N/A'}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      {!!security.unadjusted_closing_price.CAD ? (
                        <Weight
                          ticker={security.ticker}
                          value={security.weight}
                        />
                      ) : (
                        'N/A'
                      )}
                    </Table.Cell>
                  </StyledRow>
                ))}
              </Table.Body>
            </Table>
            <Segment style={{ margin: '0px', padding: '0px' }}>
              {!!this.labelData() && (
                <Label>{`${this.labelData().val}% ${
                  this.labelData().msg
                }`}</Label>
              )}
            </Segment>
          </Segment>
        )}

        <div style={{ marginTop: '3rem' }}>
          <div style={{ textAlign: 'left' }}>
            <div>
              <div style={{ display: 'inline-block' }}>
                <Header
                  style={{
                    fontSize: '14.72px',
                    margin: '0',
                  }}
                >
                  {user_region === 'US' ? 'Add CD' : 'Add GIC/HISA'}
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    wide
                  >
                    <Popup.Content>
                      {user_region === 'US'
                        ? 'This category is for fixed interest securities such as Certificates of Deposits (CDs) and term deposits (TDs).'
                        : 'This category is for fixed interest securities such as Guaranteed Investment Certificates (GICs) or High Interest Savings Accounts (HISAs).'}
                    </Popup.Content>
                  </Popup>
                </Header>

                <Header.Subheader
                  style={{ color: 'rgba(0,0,0,0.5)', fontSize: '0.9rem' }}
                >
                  (Optional)
                </Header.Subheader>
              </div>
              <OptionsWrapper>
                <div
                  className={cn('select left', { active: addGicToggle })}
                  onClick={() =>
                    dispatch(BuildCustom.updateToggle('addGicToggle', true))
                  }
                >
                  Yes
                </div>
                <div
                  className={cn('select right', { active: !addGicToggle })}
                  onClick={() => {
                    dispatch(BuildCustom.updateToggle('addGicToggle', false));
                    dispatch(BuildCustom.remove('GIC1:MKT'));
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
              {addGicToggle ? (
                <div>
                  <Field>
                    <label>Weight</label>
                    <input
                      name="gic_weight"
                      type="number"
                      placeholder="%"
                      value={gicWeight || ''}
                      onChange={this.handleChange}
                    />
                    {gic_rate && !gicWeight ? (
                      <ErrorLabel>Update weight</ErrorLabel>
                    ) : null}
                  </Field>
                  <Field>
                    <label style={{ textAlign: 'left' }}>
                      Interest Rate
                      <br />
                      <span
                        style={{
                          color: 'rgba(0,0,0,0.5)',
                          fontSize: '0.9rem',
                        }}
                      >
                        (e.g., 0.75%)
                      </span>
                    </label>
                    <input
                      name="gic_rate"
                      type="number"
                      placeholder="%"
                      value={gic_rate || ''}
                      onChange={this.handleChange}
                    />
                    {gicWeight &&
                    (gic_rate === undefined || gic_rate === '') ? (
                      <ErrorLabel>Update interest rate</ErrorLabel>
                    ) : null}
                  </Field>
                  <Field center>
                    <label>
                      Ticker
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                      >
                        <Popup.Content>
                          For display in the Portfolio Scorecard.
                        </Popup.Content>
                      </Popup>
                    </label>
                    <input
                      name="gic_ticker"
                      value={gic_ticker || ''}
                      onChange={(e) =>
                        dispatch(
                          BuildCustom.updateGicInfo(
                            e.target.name,
                            e.target.value
                          )
                        )
                      }
                    />
                    {(gicWeight || gic_rate) && !gic_ticker ? (
                      <ErrorLabel>Update ticker</ErrorLabel>
                    ) : null}
                  </Field>
                  <Field center>
                    <label>
                      Name
                      <Popup
                        trigger={
                          <Icon
                            name="question circle outline"
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'initial',
                              color: 'dimgrey',
                            }}
                          />
                        }
                        position="top center"
                        wide
                      >
                        <Popup.Content>
                          For display in the Portfolio Scorecard.
                        </Popup.Content>
                      </Popup>
                    </label>
                    <input
                      name="gic_name"
                      placeholder="(Optional)"
                      value={gic_name || ''}
                      onChange={(e) =>
                        dispatch(
                          BuildCustom.updateGicInfo(
                            e.target.name,
                            e.target.value
                          )
                        )
                      }
                    />
                  </Field>
                </div>
              ) : null}
            </div>
            <div style={{ marginTop: `${addGicToggle ? '2rem' : '1rem'}` }}>
              <div style={{ display: 'inline-block' }}>
                <Header
                  style={{
                    fontSize: '14.72px',
                    margin: '0',
                  }}
                >
                  Add Bitcoin and/or Ethereum
                  <Popup
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{
                          fontSize: '15px',
                          verticalAlign: 'initial',
                          color: 'dimgrey',
                        }}
                      />
                    }
                    position="top center"
                    wide
                  >
                    <Popup.Content>
                      Bitcoin and Ethereum can be added to test hypothetical
                      portfolio ideas. Please note that our analysis is based on
                      historical prices in the coin market, as cryptocurrency
                      ETFs are too new. Our data goes back to October 2014 for
                      Bitcoin and to September 2015 for Ethereum.
                    </Popup.Content>
                  </Popup>
                </Header>

                <Header.Subheader
                  style={{ color: 'rgba(0,0,0,0.5)', fontSize: '0.9rem' }}
                >
                  (Optional)
                </Header.Subheader>
              </div>
              <OptionsWrapper btc>
                <div
                  className={cn('select left', { active: addBtcToggle })}
                  onClick={() =>
                    dispatch(BuildCustom.updateToggle('addBtcToggle', true))
                  }
                >
                  Yes
                </div>
                <div
                  className={cn('select right', { active: !addBtcToggle })}
                  onClick={() => {
                    dispatch(BuildCustom.remove('~BTCUSDT'));
                    dispatch(BuildCustom.remove('~ETHUSDT'));
                    dispatch(BuildCustom.updateToggle('addBtcToggle', false));
                  }}
                >
                  No
                </div>
              </OptionsWrapper>
              {addBtcToggle ? (
                <div>
                  <Field>
                    <label>Bitcoin Weight</label>
                    <input
                      name="btc_weight"
                      type="number"
                      placeholder="%"
                      value={btcWeight || ''}
                      onChange={this.handleChange}
                    />
                  </Field>
                  <Field>
                    <label>Ethereum Weight</label>
                    <input
                      name="eth_weight"
                      type="number"
                      placeholder="%"
                      value={ethWeight || ''}
                      onChange={this.handleChange}
                    />
                  </Field>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Button
          className={cn('left centered', { disabled: this.isDisabled() })}
          style={{ marginTop: '4rem' }}
          color={colors.darkBlue}
          icon="line chart"
          onClick={this.analyze}
        >
          Analyze
        </Button>
      </Segment>
    );
  }
}

BuildCustomPortfolio.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  buildcustom: PropTypes.object,
  dispatch: PropTypes.func,
  user_region: PropTypes.string,
  esg: PropTypes.object,
};

BuildCustomPortfolio.defaultProps = {
  dispatch: () => false,
  buildcustom: {},
  user_region: 'CA',
  esg: {},
};

const SecurityName = styled.div`
  font-size: 0.9rem;
  line-height: 1rem;
  color: ${(props) =>
    props.negative ? 'rgba(159,58,56, 0.6)' : 'rgba(0,0,0,0.5)'};
`;

const StyledRow = styled(({ className, children, ...rest }) => (
  <Table.Row className={className} {...rest}>
    {children}
  </Table.Row>
))`
  position: relative !important;

  .close {
    display: block;
    margin: -10px;
    z-index: 100;
    transform: rotate(45deg);
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    border-radius: 18px;
    height: 20px;
    width: 20px;
    line-height: 17px;
    padding: 0 1px;
    background: white;
    border: 1px solid #e4e5e5;
    color: black;
    cursor: pointer;
    transition: background 0.1s ease, border 0.1s ease;

    &:hover {
      background: #fff6f6;
      border: 1px solid #db2828;
    }
  }
`;

const RemoveAll = styled.div`
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  margin: auto;
  background: white;
  width: 50px;
  font-size: 0.9rem;
  border-radius: 10px;
  line-height: 20px;
  border: 1px solid #dededf;
  cursor: pointer;
  transition: border 0.2s ease;

  &:hover {
    border: 1px solid #db2828;
    background: #fff6f6;
  }
`;

const Label = styled.div`
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  color: white;
  transform: translateX(-50%);
  padding: 1px 20px;
  font-size: 0.9rem;
  background: ${colors.red};
  text-align: center;
  border-radius: 12px;
`;

const ErrorLabel = styled.div`
  position: absolute;
  white-space: nowrap;
  background-color: #fff;
  color: #db2828;
  border: 1px solid #db2828;
  font-size: 0.8rem;
  line-height: 1;
  padding: 11px 13px;
  font-weight: 700;
  z-index: 1;
  border-radius: 4px;
  left: calc(50% + 58px);
  text-transform: initial;

  &:not(.basic):before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    transform: translate(3%, 0) rotate(45deg);
    background: inherit;
    left: -5px;
    margin-top: -4px;
    top: 50%;
    border-width: 0 0 1px 1px;
    border-style: solid;
  }
`;

const Error = styled.div`
  font-size: 1.7rem;
  position: absolute;
  top: 50%;
  left: -2.3rem;
  margin: -0.8rem auto;
  color: ${colors.red};
`;

const Note = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 1rem 0;
`;

const Field = styled.fieldset`
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  min-height: 32px;
  label {
    display: block;
    float: left;
  }
  input {
    position: absolute;
    left: calc(50% - 47px);
    padding: 0.4rem;
    border-radius: 3px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: all 200ms ease;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 93px;
    font-size: 0.9rem;
    text-align: right;
    outline: none;
    &:focus,
    &:active {
      outline: none;
      border: 1px solid rgb(132, 183, 217);
      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
      text-align: ${(props) => (props.center ? 'center' : 'right')};
    }
    &.invalid {
      border: 1px solid ${colors.red};
      background: rgb(255, 240, 240);
    }
  }
`;

const OptionsWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 0.25rem;
  .select {
    display: inline-block;
    padding: 0.1rem 0.7rem;
    cursor: pointer;
    font-size: 0.8rem;
    background-color: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset,
      0 1px 4px 0 rgba(34, 36, 38, 0.15) inset;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
        0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    }
    &.active {
      box-shadow: none;
      background-color: ${colors.blue};
      color: white;
    }
    &.left {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.right {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
