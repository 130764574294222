import { nestData, stringToDate } from 'utils/helpers';
import * as Alert from './alert';
import { request } from './api';

/**
 * Action constants
 */
export const BUILDETF_REQUEST = 'BUILDETF_REQUEST';

export const BUILDETF_FETCH_SUCCESS = 'BUILDETF_FETCH_SUCCESS';
export const BUILDETF_TOGGLE_ASSET = 'BUILDETF_TOGGLE_ASSET';
export const BUILDETF_TOGGLE_CLASS = 'BUILDETF_TOGGLE_CLASS';
export const BUILDETF_UPDATE_WEIGHT = 'BUILDETF_UPDATE_WEIGHT';

export const BUILDETF_CLEAR = 'BUILDETF_CLEAR';
export const BUILDETF_FAIL = 'BUILDETF_FAIL';
export const BUILDETF_UPDATE_OLDEST_COMMON_INCEPTION_DATE =
  'BUILDETF_UPDATE_OLDEST_COMMON_INCEPTION_DATE';
export const BUILDETF_SET_INITMONTH = 'BUILDETF_SET_INITMONTH';
export const BUILDETF_SET_INITYEAR = 'BUILDETF_SET_INITYEAR';

/**
 * Action creators
 */
export function fetchId(id) {
  return (dispatch) => {
    dispatch({ type: BUILDETF_REQUEST });

    return dispatch(request('get', '/portfolio/byoetf/')).then(
      (data) =>
        dispatch(request('get', `/portfolio/${id}/`)).then(
          (params) =>
            dispatch({
              type: BUILDETF_FETCH_SUCCESS,
              ...buildState(data, params),
            }),
          (error) => {
            const parsedError =
              error.msg || error.detail || error.error || 'Server Error';
            dispatch({ type: BUILDETF_FAIL });

            return dispatch(Alert.show({ type: 'error', msg: parsedError }));
          }
        ),
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: BUILDETF_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function fetchParams(params) {
  return (dispatch) => {
    dispatch({ type: BUILDETF_REQUEST });

    return dispatch(request('get', '/portfolio/byoetf/')).then(
      (data) => {
        if (!params) {
          return dispatch({
            type: BUILDETF_FETCH_SUCCESS,
            data: nestData(data),
            selectedAssets: {},
            selectedClass: { bonds: false, equity: false, alt: false },
          });
        } else if (
          params.selectedAssets &&
          params.selectedClass &&
          (Object.keys(params.selectedAssets) ||
            Object.keys(params.selectedClass).length)
        ) {
          return dispatch({
            type: BUILDETF_FETCH_SUCCESS,
            data: nestData(data),
            selectedAssets: params.selectedAssets,
            selectedClass: params.selectedClass,
          });
        }

        return dispatch({
          type: BUILDETF_FETCH_SUCCESS,
          ...buildState(data, params),
        });
      },
      (error) => {
        const parsedError =
          error.msg || error.detail || error.error || 'Server Error';
        dispatch({ type: BUILDETF_FAIL });

        return dispatch(Alert.show({ type: 'error', msg: parsedError }));
      }
    );
  };
}

export function toggleAsset(asset) {
  return {
    type: BUILDETF_TOGGLE_ASSET,
    asset,
  };
}

export function toggleClass(symbol) {
  return {
    type: BUILDETF_TOGGLE_CLASS,
    symbol,
  };
}

export function updateWeight(symbol, weight) {
  return {
    type: BUILDETF_UPDATE_WEIGHT,
    symbol,
    weight,
  };
}

export function clearBuildETF() {
  return { type: BUILDETF_CLEAR };
}

export function updateOldestCommonInceptionDate(selectedAssets) {
  const selectedAssetsDates = [];
  Object.keys(selectedAssets).forEach((asset) => {
    selectedAssetsDates.push(
      stringToDate(selectedAssets[asset].inception_date)
    );
  });
  // user doesn't have any selected assets so go back to inital dates
  if (selectedAssetsDates.length === 0) {
    return {
      type: BUILDETF_UPDATE_OLDEST_COMMON_INCEPTION_DATE,
      month: '04',
      year: '2007',
    };
  }

  // sort the dates in ascending order
  selectedAssetsDates.sort((a, b) => a - b);

  const oldestCommonInceptionDate =
    selectedAssetsDates[selectedAssetsDates.length - 1];

  // the default value for the initMonth and initYear (before the user selects any month/year on the history page) will be
  // the oldestCommonInceptionDate
  const monthInt = oldestCommonInceptionDate.getMonth() + 1; // +1 since months are 0-indexed
  const month =
    monthInt.toString().length === 2
      ? monthInt.toString()
      : `0${monthInt.toString()}`;

  const yearInt = oldestCommonInceptionDate.getYear() + 1900;
  const year = yearInt.toString();

  return { type: BUILDETF_UPDATE_OLDEST_COMMON_INCEPTION_DATE, month, year };
}

export function setInitMonth(month) {
  return { type: BUILDETF_SET_INITMONTH, month };
}

export function setInitYear(year) {
  return { type: BUILDETF_SET_INITYEAR, year };
}

/**
 * Helper functions
 */
function buildState(data, portfolio) {
  const selectedAssets = {};
  const symbols = portfolio.symbols.split(',');
  const weights = portfolio.weights.split(',');
  const parsedData = Object.values(data).map((value) => {
    const idx = symbols.indexOf(value.symbol);

    if (idx > -1) {
      selectedAssets[value.symbol] = { ...value, weight: weights[idx] * 100 };
      return { ...value, weight: weights[idx] * 100 };
    }

    return { ...value, weight: null };
  });

  return {
    data: nestData(parsedData),
    selectedAssets,
    selectedClass: updateSelectedClass(selectedAssets),
  };
}

function updateSelectedClass(data) {
  return Object.values(data).reduce(
    (result, item) => {
      result[item.assetclass] = true;

      return result;
    },
    {
      'Fixed Income': false,
      Equity: false,
      Alternative: false,
    }
  );
}
