import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';

import { ExposureTable, ExposureChart } from '../components';

export default class AssetClass extends Component {
  render() {
    const { analytics } = this.props;
    const { data } = analytics;
    const assetAllocation = Object.values(data.tickersValid).reduce(
      (total, value) => {
        if (total[value.class]) {
          total[value.class] += value.weight;
        } else {
          total[value.class] = value.weight;
        }

        return total;
      },
      {}
    );
    const assetAllocationClass = {
      Alternative:
        'Alternative' in data.assetAllocation
          ? data.assetAllocation.Alternative
          : 0,
      Equity:
        'Equity' in data.assetAllocation ? data.assetAllocation.Equity : 0,
      'Fixed Income':
        'Fixed Income' in data.assetAllocation
          ? data.assetAllocation['Fixed Income']
          : 0,
      Cash: 'Cash' in data.assetAllocation ? data.assetAllocation.Cash : 0,
      'Misc.': 'Misc' in data.assetAllocation ? data.assetAllocation.Misc : 0,
    };
    const riskContClass = {
      Alternative:
        'Alternative' in data.riskCont.class
          ? data.riskCont.class.Alternative
          : 0,
      Equity: 'Equity' in data.riskCont.class ? data.riskCont.class.Equity : 0,
      'Fixed Income':
        'Fixed Income' in data.riskCont.class
          ? data.riskCont.class['Fixed Income']
          : 0,
      Cash: 'Cash' in data.riskCont.class ? data.riskCont.class.Cash : 0,
      Other: 'Other' in data.riskCont.class ? data.riskCont.class.Other : 0,
    };

    const customOrder = [
      'Alternative',
      'Equity',
      'Fixed Income',
      'Cash',
      'Other',
      'Misc',
    ];
    const sortedData = customOrder.map((key) => ({
      ticker: key,
      assetAllocation: assetAllocation[key] || 0,
      riskCont: riskContClass[key] || 0,
      retCont: data.retCont.class[key] || 0,
    }));
    const customSortOrder = (a, b) =>
      customOrder.indexOf(a.ticker) - customOrder.indexOf(b.ticker);

    sortedData.sort(customSortOrder);
    const filteredAssetAllocationClass = Object.entries(assetAllocationClass)

      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => value !== 0)

      .reduce((obj, [key, value]) => {
        obj[key] = value;

        return obj;
      }, {});

    return (
      <Container>
        <Header
          dividing
          size="medium"
          content="Asset Class Analysis"
          style={{ fontSize: '1.38rem' }}
        />
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header size="medium" style={{ margin: 0 }}>
                Net Asset Allocation
              </Header>
              <p style={{ marginTop: '1rem' }}>
                Your portfolio’s net asset allocation reflects the asset
                allocation of any balanced funds/funds of funds that you may
                have.
                <br />
                <br />
                For example, if you put 50% of your money in a 60/40 fund and
                50% in an 80/20 fund, then your net asset allocation is 70/30.
                <br />
                <br />
                Cash is non-interest bearing cash in your account if you chose
                to analyze with cash.
                <br />
                <br />
                Miscellaneous includes funds of funds, convertibles assets
                (excluding bonds and preferred shares), derivative contracts and
                others.
              </p>
            </Grid.Column>
            <Grid.Column>
              <Header size="medium" style={{ margin: 0 }}>
                Holdings by Asset Class
              </Header>
              <ExposureChart
                title=""
                labels={Object.keys(filteredAssetAllocationClass)}
                data={Object.values(filteredAssetAllocationClass)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header
          size="medium"
          content="Risk Contribution"
          subheader="By Asset Class"
          style={{ marginTop: 0 }}
        />
        <p>
          If you have balanced/asset allocation funds: please note that they are
          classified as "Other" by data vendors. Unfortunately, we are not able
          to estimate the risk contribution of the sub-portfolios within a fund
          (e.g., the equity portion of a balanced fund) because we do not have
          separate sub-portfolio returns data. In this case, your asset
          allocation ring chart below will be different from the one above.
        </p>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                size="medium"
                content="Asset Allocation"
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                labels={Object.keys(riskContClass)}
                data={Object.keys(riskContClass).map((key) =>
                  key in assetAllocation ? assetAllocation[key] : 0
                )}
              />
            </Grid.Column>

            <Grid.Column>
              <Header
                size="medium"
                content="Risk Contribution"
                style={{ margin: 0 }}
              />
              <ExposureChart
                title=""
                labels={Object.keys(riskContClass)}
                data={Object.values(riskContClass)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ExposureTable
          headers={[
            <div>
              Class
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                    }}
                  />
                }
                position="top center"
                wide
                content="Security / fund level classification"
              />
            </div>,
            'Allocation',
            'Risk Contribution',
            'Return Contribution',
          ]}
          totals={['Total', <div>100%</div>, <div>100%</div>, <div>100%</div>]}
          data={sortedData}
        />
      </Container>
    );
  }
}

AssetClass.propTypes = {
  analytics: PropTypes.object,
};

AssetClass.defaultProps = {
  analytics: {},
};

const Container = styled.div`
  padding: 2rem 2rem 1rem 2rem;
`;
