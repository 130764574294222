import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Grid, Divider, Flag, Popup, Icon } from 'semantic-ui-react';
import cn from 'classnames';
import { Accounts, Portfolio, Storage } from 'actions';
import { colors, nbColors } from 'utils/colors';
import {
  notWealthica,
  hasNegativeWeight,
  getHoldTotalMarketValue,
} from 'utils/helpers';
import { Segment, Button } from 'components';
import { HoldingTable, GicTable, AssetChart } from '../components';

class MultipleAccount extends Component {
  state = {
    tablePage: 1,
  };

  componentDidMount = () => {
    const { selectedAccountList, history } = this.props;

    if (selectedAccountList.length === 0) {
      history.push('/accounts/user');
    }
  };

  getFlag = (currency) => (currency === 'USD' ? 'us' : 'ca');

  round = (num) => (!num ? 0 : Math.round(num * 10000) / 10000);

  analyze = (holdings, region) => {
    const { dispatch, history, selectedAccountList } = this.props;
    const gicHoldings = holdings.filter((holding) => holding.gic_ticker);
    Storage.removeItem('pwpa-has-exclude-holding');

    const concatAccountNames = selectedAccountList
      .map((acc) => acc.user_institution)
      .join(' - ');
    return dispatch(
      Accounts.analyze(
        holdings,
        history,
        { user_institution: concatAccountNames },
        gicHoldings,
        region
      )
    );
  };

  parseNum = (num) => {
    num = !num ? 0 : Math.round(num * 100) / 100;
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  increaseTablePage = () => {
    this.setState({ tablePage: this.state.tablePage + 1 });
  };

  decreaseTablePage = () => {
    this.setState({ tablePage: this.state.tablePage - 1 });
  };

  render() {
    const {
      dispatch,
      history,
      selectedAccountOverview,
      selectedAccountList,
      pwpaExchangeRate,
      user_region,
      partnerName,
      french,
    } = this.props;

    const validHoldings =
      selectedAccountOverview !== null
        ? selectedAccountOverview.holdings.filter(
            (holding) => !!holding.security_detail
          )
        : null;
    const usdHolding = validHoldings.find((holding) => {
      const price = holding.security_detail
        ? holding.security_detail.unadjusted_closing_price.USD
        : holding.unadjusted_closing_price.USD;

      return holding.default_currency === 'USD' && !!price;
    });
    const usdAccount = selectedAccountList.find(
      (account) => account.currency === 'USD'
    );
    const excludeGicHoldings =
      selectedAccountOverview &&
      selectedAccountOverview.holdings.filter((holding) => !holding.gic_ticker);
    const gicHoldings =
      selectedAccountOverview &&
      selectedAccountOverview.holdings.filter((holding) => holding.gic_ticker);
    const hasNegativeWeights = hasNegativeWeight(
      selectedAccountOverview.holdings
    );

    return (
      <div>
        {selectedAccountOverview !== null && selectedAccountList.length !== 0 && (
          <Segment>
            <Header
              size="large"
              content={french ? 'Comptes groupés' : 'Grouped Accounts'}
              subheader={`${selectedAccountList.length} ${
                french ? 'comptes sélectionnés' : 'accounts selected'
              }`}
            />
            <Divider fitted />
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={7}>
                  <div style={{ marginTop: '1.3rem' }}>
                    <div style={{ float: 'left', padding: '0 2rem 1rem 0' }}>
                      <Header
                        size="medium"
                        content={french ? 'Valeur du Compte' : 'Account Value:'}
                        sub
                        style={{ margin: 0, fontWeight: 700 }}
                      />
                      <Value className="main">
                        {`$${this.parseNum(selectedAccountOverview.balance)}`}
                        <Flag
                          name={this.getFlag(selectedAccountOverview.currency)}
                          style={{
                            position: 'relative',
                            bottom: '7px',
                            marginLeft: 7,
                          }}
                        />
                      </Value>
                    </div>

                    <div style={{ float: 'left', padding: '0 0 1rem 0' }}>
                      <Header
                        size="medium"
                        content={
                          french ? 'Variation quotidienne' : 'Daily Change:'
                        }
                        sub
                        style={{ margin: 0 }}
                      />
                      <Value>{`${selectedAccountOverview.change || 0}%`}</Value>
                    </div>
                  </div>

                  <div style={{ clear: 'both' }}>
                    <Header
                      size="medium"
                      content={
                        french ? 'Valeur totale des avoirs' : 'Holdings Value:'
                      }
                      sub
                      style={{ margin: 0 }}
                    />
                    <Value>{`$${this.parseNum(
                      selectedAccountOverview.balance -
                        selectedAccountOverview.cash
                    )}`}</Value>
                  </div>
                  <div style={{ marginTop: '1.3rem' }}>
                    <Header
                      sub
                      size="medium"
                      style={{ margin: 0 }}
                      content={
                        <div>
                          {french ? 'Valeur en espèces' : 'Cash value:'}
                        </div>
                      }
                    />
                    <Value>{`$${this.parseNum(
                      selectedAccountOverview.cash
                    )}`}</Value>
                  </div>
                </Grid.Column>
                <Grid.Column width={9}>
                  {!selectedAccountOverview.holdings.length ||
                  !validHoldings.length ? null : (
                    <Segment basic compact>
                      <Header
                        size="medium"
                        textAlign="center"
                        style={{ margin: '0 0 1rem 0' }}
                      >
                        {french
                          ? 'Avoirs par classe d’actifs'
                          : 'Holdings by Asset Class'}
                        &nbsp;
                        <Popup
                          trigger={
                            <Icon
                              name="question circle outline"
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'initial',
                                color: 'dimgrey',
                              }}
                            />
                          }
                          position="top center"
                          content={
                            french
                              ? 'Il s’agit de votre répartition d’actifs nette, tenant compte des actifs sous-jacents des fonds équilibrés et des fonds profilés dont vous possédez des titres.'
                              : 'This is your net asset allocation, reflecting any balanced funds/asset allocation funds that you may have.'
                          }
                          wide
                        />
                      </Header>
                      <AssetChart
                        accounts={selectedAccountList}
                        overview={selectedAccountOverview}
                      />
                    </Segment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {hasNegativeWeights ? (
              getHoldTotalMarketValue(selectedAccountOverview) < 0 ? (
                <p
                  style={{
                    color: `${colors.red}`,
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '2rem',
                    right: '15rem',
                  }}
                >
                  <Icon name="warning" style={{}} />
                  Analysis will not be performed for net short portfolio(s).
                </p>
              ) : (
                <p
                  style={{
                    color: `${colors.red}`,
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '2rem',
                    right: '15rem',
                  }}
                >
                  <Icon name="warning" style={{}} />
                  Note that short positions will be excluded from the analysis.
                </p>
              )
            ) : null}
            <Button
              className={cn('left', {
                disabled: getHoldTotalMarketValue(selectedAccountOverview) < 0,
              })}
              icon="line chart"
              color={colors.darkBlue}
              onClick={() => {
                dispatch(
                  Storage.setItem('toggle_region', this.props.user_region)
                );
                this.analyze(selectedAccountOverview.holdings, user_region);
                dispatch(Portfolio.notSaveUrlSc());
              }}
              style={{
                position: 'absolute',
                top: '1.6rem',
                right: '5rem',
                zIndex: 3,
              }}
            >
              {french ? 'Analyser' : 'Analyze'}
            </Button>
            <MoreActions>
              <div className="trigger">...</div>
              <div className="action-item">
                <Button
                  className="left"
                  color={nbColors.red}
                  style={{
                    backgroundColor: 'white',
                    display: 'block',
                    width: '100%',
                    margin: '5px',
                    fontSize: french ? '11px' : '12px',
                  }}
                  icon="money"
                  onClick={() => history.push('/accounts/multiple/cash')}
                >
                  {french ? 'Argent liquide' : 'Cash'}
                </Button>
              </div>
            </MoreActions>
            <div style={{ clear: 'both' }} />

            {!notWealthica(partnerName) && (
              <p
                style={{
                  fontWeight: 'bold',
                  position: 'absolute',
                  right: '7rem',
                  transform: 'translateY(2rem)',
                }}
              >
                {this.state.tablePage} {french ? 'sur' : 'of'} 2
              </p>
            )}

            <HoldingTable
              accountList={selectedAccountList}
              totalMarketValue={
                selectedAccountOverview.balance - selectedAccountOverview.cash
              }
              holdings={excludeGicHoldings.slice(0, 10)}
              accountName=""
              title={
                french ? 'Vos 10 plus grandes positions' : 'Top 10 Holdings'
              }
              isCombined
              increaseTablePage={this.increaseTablePage}
              decreaseTablePage={this.decreaseTablePage}
            />
            <GicTable
              user_region={user_region}
              gicHoldings={gicHoldings}
              totalMarketValue={
                selectedAccountOverview.balance - selectedAccountOverview.cash
              }
              title="GIC/HISAs"
              rate={pwpaExchangeRate}
            />
            {excludeGicHoldings.length !== 0 && (
              <P>
                {selectedAccountOverview.currency === 'CAD' ? (
                  <React.Fragment>
                    {french
                      ? 'Actuellement, nous disposons de données sur les actions/FNB canadiens et américains, les fonds communs de placement et les fonds distincts canadiens, ainsi que les fonds de CÉIÉ en CAD/USD.'
                      : 'Currently, we have data for Canadian and US stocks/ETFs, Canadian mutual and segregated funds, and CAD/USD HISA funds.'}
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                          }}
                        />
                      }
                      content={
                        french
                          ? 'Les fonds de CÉIÉ en CAD/USD sont inclus dans l’analyse en utilisant le rendement historique d’un fonds de CÉIÉ en CAD/USD représentatif détenu par une banque.'
                          : 'CAD/USD HISA funds are included in the analysis using the historical rates of a representative bank-owned CAD/USD HISA fund.'
                      }
                      position="top center"
                    />
                    <br />
                    {french
                      ? 'Toute autre valeur mobilière sera exclue de l’analyse.'
                      : 'Any other securities will be excluded from the analysis.'}
                  </React.Fragment>
                ) : french ? (
                  'Nous disposons actuellement de données sur les actions/FNB canadiens et américains, les fonds communs de placement et les fonds distincts canadiens, ainsi que les fonds de CÉIÉ en CAD/USD.  Toute autre valeur mobilière sera exclue de l’analyse.'
                ) : (
                  'Currently we only have data for U.S. listed stocks and ETFs, U.S. mutual funds, Bitcoin, and Ethereum. Any other security will be excluded from the analysis.'
                )}
              </P>
            )}
            {(!!usdHolding || !!usdAccount) && excludeGicHoldings.length !== 0 && (
              <P>
                {french
                  ? 'Le taux de change USD/CAD utilisé est de '
                  : 'USD/CAD exchange rate used is '}
                {pwpaExchangeRate || 1.3}
              </P>
            )}
          </Segment>
        )}
      </div>
    );
  }
}

MultipleAccount.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  selectedAccountList: PropTypes.array,
  selectedAccountOverview: PropTypes.object,
  pwpaExchangeRate: PropTypes.number,
  user_region: PropTypes.string,
  partnerName: PropTypes.string,
  french: PropTypes.bool,
};

MultipleAccount.defaultProps = {
  dispatch: () => {},
  history: {},
  selectedAccountOverview: {},
  selectedAccountList: [],
  pwpaExchangeRate: 0,
  user_region: 'CA',
  partnerName: '',
  french: false,
};

export default connect((state) => ({
  selectedAccountList: state.Accounts.selectedAccountList,
  selectedAccountOverview: state.Accounts.selectedAccountOverview,
  pwpaExchangeRate: state.Storage['pwpa-exchange-rate'],
  user_region: state.Storage.user_region,
  partnerName: state.Storage['partner-name'],
  french: state.Storage.language === 'fr',
}))(MultipleAccount);

const Value = styled.div`
  font-size: 1.8rem;
  padding: 7px 0;

  &.main {
    font-size: 2.4rem;
    font-weight: 700;
    color: ${nbColors.darkBlue};
  }
`;

const MoreActions = styled.div`
  .action-item {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 2rem 0.5rem 0.5rem 0.5rem;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, top 150ms ease;

    &:hover {
      opacity: 1;
      visibility: visible;
      top: 40px;
    }
  }

  .trigger {
    z-index: 2;
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    font-size: 30px;
    background: white;
    color: rgba(0, 0, 0, 0.6);
    height: 35px;
    width: 35px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease;

    &:hover {
      border: 1px solid ${colors.darkBlue};
      color: ${colors.darkBlue};

      & ~ .action-item {
        opacity: 1;
        visibility: visible;
        top: 40px;
      }
    }
  }

  &:hover ~ .trigger {
    border: 1px solid ${colors.darkBlue};
    color: ${colors.darkBlue};
  }
`;

const P = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  margin: 0.7rem auto;
  max-width: 100%;
  line-height: 1.1rem;
  text-align: center;
`;
