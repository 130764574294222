import { request } from './api';
import * as Alert from './alert';

/**
 * Action constants
 */
export const BUILDML_REQUEST = 'BUILDML_REQUEST';

export const BUILDML_SEARCH_SUCCESS = 'BUILDML_SEARCH_SUCCESS';
export const BUILDML_ADD_SUCCESS = 'BUILDML_ADD_SUCCESS';
export const BUILDML_BUILDML_FETCH_ASSETALLOCATION_SUCCESS =
  'BUILDML_BUILDML_FETCH_ASSETALLOCATION_SUCCESS';
export const BUILDML_REMOVE_SECURITY = 'BUILDML_REMOVE_SECURITY';
export const BUILDML_REMOVE_ALL = 'BUILDML_REMOVE_ALL';
export const BUILDML_UPDATE_TOGGLE = 'BUILDML_UPDATE_TOGGLE';
export const BUILDML_UPDATE_FIXEDINCOME = 'BUILDML_UPDATE_FIXEDINCOME';

export const BUILDML_CLEAR_PREV_ASSET_ALLOCATION =
  'BUILDML_CLEAR_PREV_ASSET_ALLOCATION';
export const BUILDML_CLEAR = 'BUILDML_CLEAR';

/**
 * Action creators
 */
export function fetchAssetAllocation(params) {
  return (dispatch) => {
    dispatch({ type: BUILDML_REQUEST });

    return dispatch(
      request('post', '/portfolio/hrp-allocation/', {
        body: { ...params },
      })
    ).then(
      (data) =>
        dispatch({
          type: BUILDML_BUILDML_FETCH_ASSETALLOCATION_SUCCESS,
          data,
        }),
      (error) => {
        dispatch({
          type: BUILDML_BUILDML_FETCH_ASSETALLOCATION_SUCCESS,
        });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function add(id) {
  return (dispatch) => {
    dispatch({ type: BUILDML_REQUEST });

    return dispatch(request('get', `/security/${id}/`)).then(
      (data) => dispatch({ type: BUILDML_ADD_SUCCESS, security: data }),
      (error) => {
        dispatch({ type: BUILDML_ADD_SUCCESS, security: null });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function search(id, region = 'CA') {
  return (dispatch) => {
    dispatch({ type: BUILDML_REQUEST });

    return dispatch(
      request('get', `/security/search?term=${id}&region=${region}`)
    ).then(
      (data) => dispatch({ type: BUILDML_SEARCH_SUCCESS, data }),
      (error) => {
        dispatch({ type: BUILDML_SEARCH_SUCCESS, data: [] });

        return dispatch(Alert.show({ type: 'error', msg: parseErr(error) }));
      }
    );
  };
}

export function updateToggle(field, value) {
  return {
    type: BUILDML_UPDATE_TOGGLE,
    field,
    value,
  };
}

export function updateFixedIncome(value) {
  return {
    type: BUILDML_UPDATE_FIXEDINCOME,
    value,
  };
}

export function remove(ticker) {
  return {
    type: BUILDML_REMOVE_SECURITY,
    ticker,
  };
}

export function removeAll() {
  return {
    type: BUILDML_REMOVE_ALL,
  };
}

export function clearPrevSavedAssetAllocation() {
  return { type: BUILDML_CLEAR_PREV_ASSET_ALLOCATION };
}

// Clears all local buildml data when leaving client view
export function clearBuildML() {
  return { type: BUILDML_CLEAR };
}

/**
 * Helper Functions
 */
function parseErr(err) {
  if (err.non_field_errors) {
    return err.non_field_errors[0];
  }

  return err.msg || err.detail || 'Server Offline';
}
