import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Segment, ErrorLabel } from 'components';
import { CacheInputs } from 'actions';
import { isGlobeInvestor } from 'utils/helpers';
import RiskProfile from './RiskProfile';
import ExcludeSector from './ExcludeSector';
import OilExposure from './OilExposure';
import InterestRisk from './InterestRisk';
import Reit from './Reit';
import ForeignMarkets from './ForeignMarkets';
import pData from './static/portfolio-data.json';

class FindETF extends Component {
  componentWillUnmount = () => clearTimeout(this.msgId);

  updateSelection = (updateValue) => {
    const { dispatch, smartRiskBuilder } = this.props;
    const { activeIndex, selection } = smartRiskBuilder;

    return dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        ...smartRiskBuilder,
        selection: { ...selection, ...updateValue },
        maxIndex: activeIndex + 1,
      })
    );
  };

  moveToNextSection = (newSelection, newMaxIndex) => {
    const { dispatch, smartRiskBuilder } = this.props;
    const { activeIndex, selection } = smartRiskBuilder;
    const index = activeIndex + 1;

    return dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        selection: { ...selection, ...newSelection },
        activeIndex: index,
        maxIndex: newMaxIndex,
        error: false,
      })
    );
  };

  showErrorMsg = () => {
    const { dispatch } = this.props;

    dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        error: true,
      })
    );

    return (this.msgId = setTimeout(
      () =>
        dispatch(
          CacheInputs.updateField('portfolios', 'smart_risk_builder', {
            error: false,
          })
        ),
      4000
    ));
  };

  previousTab = (e) => {
    e.preventDefault();
    const { dispatch, smartRiskBuilder } = this.props;
    const { activeIndex } = smartRiskBuilder;

    return dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        activeIndex: activeIndex - 1,
        error: false,
      })
    );
  };

  handleTabChange = (e, { activeIndex }) => {
    const { smartRiskBuilder } = this.props;
    const { maxIndex } = smartRiskBuilder;

    if (activeIndex === 0 && maxIndex === 0) return false;
    if (activeIndex > maxIndex) return this.showErrorMsg();

    return this.props.dispatch(
      CacheInputs.updateField('portfolios', 'smart_risk_builder', {
        activeIndex,
        error: false,
      })
    );
  };

  render() {
    const { smartRiskBuilder } = this.props;
    const { selection, error, activeIndex } = smartRiskBuilder;
    const { risk, sector, oil, interest, reit, foreign } = selection;
    const panes = [
      {
        menuItem: 'Risk Profile',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <RiskProfile
              selection={selection}
              pData={pData}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              risk={risk ? risk[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Exclude sector',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <ExcludeSector
              data={pData}
              pData={pData[risk[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              sector={sector ? sector[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Oil exposure',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <OilExposure
              pData={pData[risk[0]][sector[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              oil={oil ? oil[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Interest rate risk',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <InterestRisk
              pData={pData[risk[0]][sector[0]][oil[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              interest={interest ? interest[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'REIT',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <Reit
              pData={pData[risk[0]][sector[0]][oil[0]][interest[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              reit={reit ? reit[0] : null}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Foreign Markets',
        render: () => (
          <Tab.Pane as={Segment} style={{ margin: '4rem 1rem 0' }}>
            <ForeignMarkets
              pData={pData[risk[0]][sector[0]][oil[0]][interest[0]][reit[0]]}
              updateSelection={this.updateSelection}
              moveToNextSection={this.moveToNextSection}
              previousTab={this.previousTab}
              selection={selection}
              foreign={
                foreign || {
                  isForeign: false,
                  markets: {
                    us: false,
                    dev: false,
                    emer: false,
                  },
                  error: null,
                }
              }
            />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <div>
        <Tab
          menu={{ secondary: true, pointing: true, widths: 6 }}
          onTabChange={this.handleTabChange}
          panes={panes}
          activeIndex={activeIndex}
        />
        {error && (
          <ErrorLabel
            className="basic"
            msg="Select your answer and click next."
            style={{ left: '55vw', top: '8rem', bottom: 'initial' }}
          />
        )}
      </div>
    );
  }
}

FindETF.propTypes = {
  dispatch: PropTypes.func,
  smartRiskBuilder: PropTypes.object,
  partnerName: PropTypes.string,
};

FindETF.defaultProps = {
  dispatch: () => false,
  smartRiskBuilder: {},
  partnerName: '',
};

export default connect((state) => ({
  smartRiskBuilder: state.CacheInputs.portfolios.smart_risk_builder,
  partnerName: state.Storage['partner-name'],
}))(FindETF);
