import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Statistic, Header, Popup, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Table, Score } from 'components';
import { colors } from 'utils/colors';
import { translation } from '../localization';

@connect((state) => ({
  french: state.Storage.language === 'fr',
}))
export default class Fees extends Component {
  getClass = (row) => {
    if (row.isExcluded) {
      return 'negative';
    }

    return false;
  };

  isLinked = (data) => {
    if (!data || !data.portfolio) {
      return false;
    }

    if (
      data.portfolio.portfolio_type === 'custom' ||
      data.portfolio.portfolio_type === 'quantified'
    ) {
      return true;
    }

    return data.portfolio.is_linked;
  };

  // eslint Marked as unused property.
  // renderExcludedTickerHeader = () => (
  //   <div>
  //     Securities excluded from analysis due to insufficient history
  //     <Popup
  //       trigger={
  //         <Icon
  //           name="question circle outline"
  //           style={{
  //             fontSize: '15px',
  //             verticalAlign: 'initial',
  //             color: 'dimgrey',
  //           }}
  //         />
  //       }
  //       position="top center"
  //       style={{ maxWidth: '650px' }}
  //     >
  //       <Popup.Content>See "Overview" page for more information</Popup.Content>
  //     </Popup>
  //   </div>
  // );

  render() {
    const { analytics } = this.props;
    const { data, printLetterGrade } = analytics;
    const { french } = this.props;
    const language = french ? 'fr' : 'en';

    return (
      <Container>
        <Score
          name={`${translation[language].FEES} `}
          score={`${data.score.Fees[0]}%`}
          grade={data.score.Fees[1]}
          portfolio={data.portfolio}
          pdf
        />
        {french ? (
          <p>
            Dans cette section, nous vous indiquons le ratio de frais de gestion
            (RFG) moyen de votre portefeuille. Le RFG est une commission
            prélevée par les FNB et les fonds communs. Selon la série (par
            exemple, A), une commission de suivi qui rémunère votre conseiller
            peut être comprise. Notez que les frais de consultation ou de
            négociation supplémentaires qui pourraient être perçus ne sont pas
            inclus. Le RFG est exprimé en pourcentage de la valeur de votre
            fonds.
          </p>
        ) : (
          <p>
            In this segment, we show you your portfolio’s average Management
            Expense Ratio (MER). MER is a fee charged by ETFs and mutual funds.
            Depending on the series (e.g., A), it may include a trailing
            commission that compensates your advisor. Note that any additional
            advisory or trading fees you may incur are not included. MER is
            expressed as a percentage of your fund value.
          </p>
        )}

        {printLetterGrade ? (
          french ? (
            <p>
              Il va sans dire que plus le RFG de votre portefeuille est faible,
              plus la note obtenue est élevée.
            </p>
          ) : (
            <p>
              Needless to say, the lower the MER of your portfolio, the higher
              score it will receive.&nbsp;
              <Popup
                trigger={
                  <Icon
                    name="question circle outline"
                    style={{
                      fontSize: '15px',
                      verticalAlign: 'initial',
                      color: 'dimgrey',
                      marginRight: '1rem',
                    }}
                  />
                }
                position="right center"
                wide
                content="Calculated assuming a maximum possible portfolio MER of 3%."
              />
            </p>
          )
        ) : null}

        <Header
          dividing
          size="medium"
          content={translation[language].FEE_IN_PORTFOLIO}
        />

        <Table basic fixed textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">
                {translation[language].ASSET}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {translation[language].WEIGHT}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {translation[language].MER}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.tickersOriginal.map((row) => (
              <Table.Row key={row.ticker} negative={row.isExcluded}>
                <Table.Cell textAlign="left">
                  {this.isLinked(data) ? row.name : row.wsName}
                </Table.Cell>
                <Table.Cell>
                  {this.getClass(row) ? (
                    <Error>
                      <span>{Math.round(row.weight * 10000) / 100}%</span>
                      <Popup
                        trigger={<Icon name="exclamation circle" />}
                        position="bottom center"
                        wide
                        content={
                          <div>
                            <strong>{row.name}</strong> has been excluded in the
                            analysis because it has insufficient history.
                          </div>
                        }
                      />
                    </Error>
                  ) : (
                    `${Math.round(row.weight * 10000) / 100}%`
                  )}
                </Table.Cell>
                <Table.Cell>
                  {Math.round(row.mer * 10000) / 100}%
                  {row.merProxied ? (
                    <Popup
                      trigger={
                        <Icon
                          name="question circle outline"
                          style={{
                            fontSize: '15px',
                            verticalAlign: 'initial',
                            color: 'dimgrey',
                            marginRight: '1rem',
                          }}
                        />
                      }
                      position="right center"
                      content="MER for this holding is missing and has been replaced by the category average."
                    />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Statistic size="tiny" style={{ display: 'block' }}>
          <Statistic.Value>{`${(data.portMER * 100).toFixed(
            2
          )}%`}</Statistic.Value>
          <Statistic.Label>
            {translation[language].WEIGHTED_AVERAGE_MER}&nbsp;
            <Popup
              trigger={
                <Icon
                  name="question circle outline"
                  style={{ color: 'dimgrey' }}
                />
              }
              content="Management Expense Ratio (MER) is a product-related fee, paid to the fund company. It excludes advisory fees that you may pay separately to your (human or robo) investment advisor. You will not see MER on your statements as gains/losses are reported net of MER."
              style={{ height: 'initial' }}
              wide
            />
          </Statistic.Label>
        </Statistic>
      </Container>
    );
  }
}

Fees.propTypes = {
  analytics: PropTypes.object.isRequired,
  french: PropTypes.bool.isRequired,
};

const Error = styled.div`
  position: relative;
  text-align: center;

  span {
    font-size: 1rem;
  }

  i {
    color: ${colors.red};
    font-size: 1.4rem;
    position: absolute;
    top: 0;
    left: -1.5rem;
  }
`;

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
