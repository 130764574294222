import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { Popup, Dropdown, Icon } from 'semantic-ui-react';
import { Analytics, Portfolio, Storage } from 'actions';
import { colors } from 'utils/colors';
import { getOverAllScore, getOverAllGrade } from 'utils/helpers';
import {
  Score,
  CustomBenchmarkDashboard,
  SelectionDashboard,
} from '../../../../components';
import Holdings from './Holdings';
import PortfolioHistory from './PortfolioHistory';
import ObjectiveSummary from './ObjectiveSummary';

@connect((state) => ({
  toggle_region: state.Storage.toggle_region || 'CA',
  user_region: state.Storage.user_region || 'CA',
  checkHistory:
    state.Storage['pwpa-check-history'] === undefined
      ? true
      : state.Storage['pwpa-check-history'],
  pwpaCheckHistory: state.Storage['pwpa-check-history'],
  french: state.Storage.language === 'fr',
}))
export default class Overview extends Component {
  getRadarData = (score) => {
    const { analytics, esg, french } = this.props;
    const { benchmark, esgToggle, income } = analytics;
    const { portfolio } = esg;

    const hash = () => {
      if (income) {
        return {
          Diversification: french ? 'Diversification' : 'Diversification',
          Fees: french ? 'Frais faibles' : 'Low Fees',
          Income: french ? 'Revenu' : 'Income',
          Performance: french ? 'Performance' : 'Performance',
        };
      }
      return {
        Diversification: french ? 'Diversification' : 'Diversification',
        Fees: french ? 'Frais faibles' : 'Low Fees',
        Performance: french ? 'Performance' : 'Performance',
      };
    };

    const data = Object.keys(hash()).reduce((total, key) => {
      total[hash()[key]] =
        key === ('Performance' || 'Volatility')
          ? score[key][benchmark][0]
          : score[key][0];

      return total;
    }, {});

    const labels = esgToggle
      ? ['Downside Protection', ...Object.keys(data), 'ESG']
      : ['Downside Protection', ...Object.keys(data)];

    const translatedLabels = labels.map((label) => {
      switch (label) {
        case 'Downside Protection':
          return french
            ? 'Protection contre les baisses'
            : 'Downside Protection';
        case 'ESG':
          return 'ESG';
        default:
          return label;
      }
    });

    return {
      labels: translatedLabels,
      datasets: [
        {
          backgroundColor: 'rgba(182,193,253,0.6)',
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.blue,
          lineTension: 0.3,
          data:
            portfolio && portfolio.ws_grade[0] && esgToggle
              ? [
                  score.Volatility[benchmark][0],
                  ...Object.values(data),
                  portfolio.ws_grade[0],
                ]
              : [score.Volatility[benchmark][0], ...Object.values(data)],
        },
      ],
    };
  };

  changeIncome = (income) => {
    const { dispatch } = this.props;

    return dispatch(Analytics.updateParameter('income', income));
  };

  changeCheckHistory = (newSelcheckHistory) => {
    const {
      analytics,
      history,
      location,
      dispatch,
      match,
      pwpaPortfolio,
      check_history,
      toggle_region,
      user_region,
    } = this.props;
    const { data, selectedAssets, benchmark } = analytics;
    const { paramsAnalyzed } = data;
    const { portfolio } = data;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );
    const customBenchmark = {
      symbols: Object.keys(selectedAssets).join(','),
      weights: benchmarkWeights.join(','),
    };

    if (newSelcheckHistory === !check_history) {
      return false;
    }

    // dispatch(ComparePortfolio.clearComparison());

    dispatch(Storage.setItem('pwpa-check-history', !newSelcheckHistory));
    const rebalanceInterval = paramsAnalyzed.rebalance_interval || 1;

    const decideRegion = () => {
      if (user_region === 'US') {
        return 'US';
      }
      return toggle_region || 'CA';
    };

    if (portfolio && portfolio.name) {
      dispatch(
        Portfolio.setEditing(
          portfolio.id,
          portfolio.name,
          portfolio.description
        )
      );
    }

    if (Number(match.params.id)) {
      if (benchmark === 'custom') {
        dispatch(
          Analytics.fetchId(
            match.params.id,
            check_history,
            customBenchmark,
            decideRegion(),
            rebalanceInterval
          )
        );
      } else {
        dispatch(
          Analytics.fetchId(
            match.params.id,
            check_history,
            undefined,
            decideRegion(),
            rebalanceInterval
          )
        );
      }
    }

    if (pwpaPortfolio) {
      const updatedPortfolio = {
        ...pwpaPortfolio,
        ...(benchmark === 'custom' && { custom_bm: customBenchmark }),
      };

      dispatch(
        Analytics.fetchParams(
          updatedPortfolio,
          location.state,
          check_history,
          decideRegion(),
          rebalanceInterval
        )
      );
    }

    return history.push('/portfolio/unsaved/overview');
  };

  decideRegion = () => {
    const { user_region, toggle_region } = this.props;
    if (user_region === 'US') {
      return 'US';
    }
    return toggle_region || 'CA';
  };

  // eslint-disable-next-line consistent-return
  changeRebalancingFrequency = (e, { value }) => {
    const {
      analytics,
      location,
      dispatch,
      pwpaCheckHistory,
      pwpaPortfolio,
      match,
    } = this.props;
    const { data, selectedAssets, benchmark } = analytics;
    const { portfolio, paramsAnalyzed } = data;
    const benchmarkWeights = Object.keys(selectedAssets).map(
      (symbol) => selectedAssets[symbol].weight / 100
    );

    const checkHistory =
      pwpaCheckHistory === null || pwpaCheckHistory === undefined
        ? null
        : !pwpaCheckHistory;
    const customBenchmark = {
      symbols: Object.keys(selectedAssets).join(','),
      weights: benchmarkWeights.join(','),
    };
    const rebalancingFrequency = paramsAnalyzed.rebalance_interval || 1;

    if (Number(value) === Number(rebalancingFrequency)) return false;

    dispatch(Analytics.updateParameter('rebalance_interval', value));
    if (portfolio && portfolio.name) {
      dispatch(
        Portfolio.setEditing(
          portfolio.id,
          portfolio.name,
          portfolio.description
        )
      );
    }
    if (Number(match.params.id)) {
      if (benchmark === 'custom') {
        dispatch(
          Analytics.fetchId(
            match.params.id,
            checkHistory,
            customBenchmark,
            this.decideRegion(),
            value
          )
        );
      } else {
        dispatch(
          Analytics.fetchId(
            match.params.id,
            checkHistory,
            undefined,
            this.decideRegion(),
            value
          )
        );
      }
    }
    if (pwpaPortfolio) {
      const updatedPortfolio = {
        ...pwpaPortfolio,
        ...(benchmark === 'custom' && { custom_bm: customBenchmark }),
      };
      dispatch(
        Analytics.fetchParams(
          updatedPortfolio,
          location.state,
          checkHistory,
          this.decideRegion(),
          value
        )
      );
    }
  };

  replaceCountry = (data, userRegion) => {
    if (userRegion === 'US') return 'US';

    const { paramsPassed } = data;

    if (paramsPassed.region === 'US') return 'US';

    return 'Canada';
  };

  changeEsgToggle = (esgToggle) => {
    const { analytics, dispatch, esg } = this.props;
    const { data } = analytics;

    if (!Object.keys(esg).length) {
      return dispatch(Analytics.fetchEsg(data.portfolio, true, esgToggle));
    }
    if (esg.portfolio.esg_score === null) {
      return dispatch(Analytics.setEsgAlert());
    }

    return dispatch(Analytics.updateParameter('esgToggle', esgToggle));
  };

  render() {
    const {
      analytics,
      history,
      match,
      dispatch,
      check_history,
      initHasExcludedHolding,
      esg,
      is_esg_enabled,
      esgAlert,
      checkHistory,
      user_region,
      french,
    } = this.props;

    const {
      income,
      benchmark,
      esgToggle,
      objective,
      data,
      portfolioHistoryCompareto,
    } = analytics;
    const { paramsAnalyzed, percentFI } = data;
    const sortedHoldings = data.tickersOriginal.sort(
      (a, b) => b.weight - a.weight
    );
    const hasExcludedHolding = sortedHoldings.find(
      (holding) => holding.isExcluded
    );
    const rebalancingFrequency = paramsAnalyzed.rebalance_interval || 1;

    return (
      <div>
        {objective && !!Object.keys(objective).length && (
          <ObjectiveSummary data={objective} ratios={data.fratios.portfolio} />
        )}
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {initHasExcludedHolding ? (
              <StyledCard style={{ minWidth: '210px' }}>
                <SelectionDashboard
                  text={
                    french
                      ? 'Inclure les avoirs à court historique'
                      : 'Include short-history holdings'
                  }
                  popuptext={
                    french
                      ? 'Le fait d’inclure des avoirs à court historique raccourcira la période analysée. Notez que les avoirs avec un historique de moins d’un mois seront tout de même exclus.'
                      : 'Including short-history holdings in the analysis will shorten the evaluation period. Note that holdings with history less than a month will still be excluded.'
                  }
                  selected={!checkHistory}
                  changeSelection={this.changeCheckHistory}
                  style={{
                    fontSize: '1.15rem',
                    height: '100%',
                    justifyContent: 'end',
                  }}
                  popupBottom
                  french={french}
                />
              </StyledCard>
            ) : null}

            <StyledCard>
              <SelectionDashboard
                text={
                  french ? 'Inclure la note de revenu' : 'Include income score'
                }
                popuptext={
                  french
                    ? 'Par défaut, la note de revenu est comprise dans le calcul de la note globale. Si vous investissez à long terme et/ou n’avez pas besoin des revenus (dividendes et autres distributions) de votre portefeuille d’investissement, choisissez NON.'
                    : 'The default includes the income score in the calculation of the overall score. If you are investing for the long term and/or do not need income (dividends and other distributions) from your investment portfolio, choose NO.'
                }
                selected={income}
                changeSelection={this.changeIncome}
                style={{
                  fontSize: '1.15rem',
                  height: '100%',
                  justifyContent: 'end',
                }}
                french={french}
              />
            </StyledCard>

            {is_esg_enabled ? (
              <StyledCard>
                <SelectionDashboard
                  text={french ? 'Inclure la note ESG' : 'Include ESG score'}
                  popuptext={
                    french
                      ? 'Consultez l’onglet ESG ci-dessus pour vérifier si la plupart de vos avoirs ont une cote ESG.'
                      : 'Check the ESG tab above to see if most of your holdings have ESG rating coverage first.'
                  }
                  selected={esgToggle}
                  changeSelection={this.changeEsgToggle}
                  style={{
                    fontSize: '1.15rem',
                    height: '100%',
                    justifyContent: 'end',
                  }}
                  french={french}
                />
              </StyledCard>
            ) : null}
            {percentFI !== 1 && (
              <StyledCard style={{ minWidth: '190px' }}>
                <CustomBenchmarkDashboard
                  benchmark={benchmark}
                  feature="scorecard"
                  replaceDomestic={this.replaceCountry(
                    analytics.data,
                    user_region || 'CA'
                  )}
                />
              </StyledCard>
            )}

            <StyledCard style={{ minWidth: '180px' }}>
              <p
                style={{
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginBottom: '1em',
                }}
              >
                {french
                  ? 'Fréquence de rééquilibrage'
                  : 'Rebalancing Frequency'}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                        marginRight: '1rem',
                      }}
                    />
                  }
                  position="top left"
                  wide
                >
                  {french
                    ? 'Le rééquilibrage est le processus qui consiste à ajuster les pondérations des avoirs pour retrouver la répartition souhaitée. Au fur et à mesure que les investissements prennent ou perdent de la valeur, leur pondération dans le portefeuille peut s’écarter des proportions désirées. Le rééquilibrage permet de réaligner le portefeuille avec le profil de risque et de rendement voulu.'
                    : 'Rebalancing refers to the process of adjusting the holding weights back to the desired allocation. As investments grow or decline in value, their weights in the portfolio may deviate from their targets. Rebalancing will bring the portfolio back in line with its intended risk and return profile.'}
                </Popup>
              </p>
              <OptionsWrapper>
                <StyledDropdown
                  selection
                  placeholder={french ? 'Mensuel' : 'Monthly'}
                  options={[
                    {
                      key: 'mon',
                      value: '1',
                      text: french ? 'Mensuel' : 'Monthly',
                    },
                    {
                      key: 'qua',
                      value: '3',
                      text: french ? 'Trimestriel' : 'Quarterly',
                    },
                    {
                      key: 'ann',
                      value: '12',
                      text: french ? 'Annuel' : 'Annually',
                    },
                  ]}
                  text={
                    french
                      ? rebalancingFrequency === 1
                        ? 'Mensuel'
                        : rebalancingFrequency === 3
                        ? 'Trimestriel'
                        : 'Annuel'
                      : rebalancingFrequency === 1
                      ? 'Monthly'
                      : rebalancingFrequency === 3
                      ? 'Quarterly'
                      : 'Annually'
                  }
                  onChange={this.changeRebalancingFrequency}
                />
              </OptionsWrapper>
            </StyledCard>
          </div>
        </div>
        <Container margin="2rem 3rem 2rem 19rem">
          <StyledChart>
            <Score
              score={getOverAllScore({
                income,
                benchmark,
                esgToggle,
                data,
                esg,
              })}
              grade={getOverAllGrade({
                income,
                benchmark,
                esgToggle,
                data,
                esg,
              })}
              description={[
                <div
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  {' '}
                  <p
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    {french
                      ? 'La note globale de votre portefeuille est la moyenne des notes des composantes ci-dessous. Chaque composante se voit attribuer une note sur 100 pour la période en question. Une note plus élevée signifie toujours une caractéristique plus souhaitable.'
                      : 'Your overall portfolio score is the average of the component scores below. Each component score is out of 100% for the specific time period in question. A higher score always means a more desireable characteristic.'}
                    <br /> <br />{' '}
                    <a
                      href={
                        french
                          ? 'https://www.wealthscope.ca/why-scores-fr'
                          : 'https://www.wealthscope.ca/whyscores'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: '1rem',
                      }}
                    >
                      {french
                        ? ' Vous pouvez en apprendre davantage à ce sujet ici.'
                        : 'Learn more here.'}
                    </a>
                  </p>{' '}
                </div>,
              ]}
              portfolio={data.portfolio}
              boldDate={!check_history}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height:
                  hasExcludedHolding && sortedHoldings.length > 8
                    ? '430px'
                    : '350px',
                transform: 'scale(1.1)',
                marginTop:
                  data.portfolio.description &&
                  data.portfolio.description.length > 90
                    ? '3rem'
                    : 0,
              }}
            >
              <Radar
                id="radarChart"
                data={this.getRadarData(data.score)}
                options={chartOptions}
              />
            </div>

            <StyledAlert active={esgAlert}>
              <AlertHeader>Warning</AlertHeader>
              <AlertMsg>
                {french
                  ? 'Désolé, les scores ESG ne sont pas disponibles pour les titres de ce portefeuille.'
                  : 'Sorry, ESG scores are not available for the holdings in this portfolio.'}
              </AlertMsg>
            </StyledAlert>
          </StyledChart>

          <Holdings
            portfolio={data.portfolio}
            holdings={sortedHoldings}
            hasExcludedHolding={!!hasExcludedHolding}
            history={history}
            match={match}
            dispatch={dispatch}
          />

          <PortfolioHistory
            dispatch={dispatch}
            benchmark={benchmark}
            data={data}
            compare={portfolioHistoryCompareto}
          />
        </Container>
      </div>
    );
  }
}

Overview.propTypes = {
  analytics: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  check_history: PropTypes.bool,
  pwpaPortfolio: PropTypes.object,
  esg: PropTypes.object,
  esgAlert: PropTypes.bool,
  is_esg_enabled: PropTypes.bool,
  initHasExcludedHolding: PropTypes.bool,
  toggle_region: PropTypes.string,
  user_region: PropTypes.string,
  checkHistory: PropTypes.bool,
  pwpaCheckHistory: PropTypes.bool,
  french: PropTypes.bool,
};

Overview.defaultProps = {
  history: {},
  match: {},
  location: {},
  check_history: true,
  pwpaPortfolio: {},
  esg: {},
  esgAlert: false,
  is_esg_enabled: false,
  initHasExcludedHolding: false,
  toggle_region: 'CA',
  user_region: 'CA',
  checkHistory: null,
  pwpaCheckHistory: null,
  french: false,
};

const chartOptions = {
  maintainAspectRatio: true,
  scale: {
    gridLines: {
      circular: true,
      color: [
        'rgba(255,180,180,0.8)',
        'rgba(255,180,180,0.4)',
        'rgba(242,196,123,0.4)',
        'rgba(186,219,96,0.5)',
        'rgba(76,189,116,0.3)',
      ],
    },
    angleLines: { display: false },
    ticks: { beginAtZero: true, stepSize: 20, max: 100 },
    pointLabels: { fontSize: 15 },
  },
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: (tooltipItems, tooltipData) =>
        tooltipData.labels[Number(tooltipItems[0].index)],
      label: (tooltipItems) =>
        `${Number(tooltipItems.value || tooltipItems.yLabel)}%`,
    },
  },
  layout: {
    padding: {
      top: 3,
      bottom: 3,
      left: 3,
      right: 3,
    },
  },
};

const StyledChart = styled.div`
  width: 48%;
  display: inline-block;
  float: right;
  position: relative;
`;

const Container = styled.div`
  min-height: 100%;
  position: relative;
  padding: 2rem 2rem 1rem 2rem;
  overflow: hidden !important;
`;

const StyledAlert = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
  width: 500px;
  float: right;
  margin-top: 2rem;
  border-radius: 6px;
  padding: 1rem 3rem;
  text-align: center;
  background-color: #f8ffff;
  box-shadow: 0 0 0 1px #0e566c inset, 0 0 0 0 transparent;
`;

const AlertHeader = styled.div`
  color: #0e566c;
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`;

const AlertMsg = styled.div`
  opacity: 0.85;
  color: #0e566c;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  width: 22%;
  border-radius: 15px;
  background-color: #fff;
  margin: 1rem 0.5rem 1rem 1rem;
`;
const StyledDropdown = styled(({ className, children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown className={className} {...rest}>
    {children}
  </Dropdown>
))`
  height: 22px !important;
  min-width: 100px !important;
  min-height: 22px !important;
  padding: 1px 0 0 10px !important;
  font-size: 1rem;
  .icon {
    padding: 2px 0 0 0 !important;
  }
`;
const OptionsWrapper = styled.div`
  padding: 3px 0;
`;
