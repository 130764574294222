import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';

import { darker, hover, colors } from 'utils/colors';
import { ACCOUNTS_REBALANCE } from '../utils/features';

const FeatureCard = ({
  className,
  children,
  rebal,
  selectable,
  selected,
  warning,
  header,
  content,
  icon,
  iconColor,
  ...rest
}) => (
  <StyledCard className={className} {...rest}>
    <CardHeader>
      {icon && (
        <Icon
          style={{ marginRight: '8px', color: iconColor || 'inherit' }}
          name={icon}
        />
      )}
      {header}
      <Icons>
        <Feature name={ACCOUNTS_REBALANCE}>
          <div>
            {warning && (
              <Popup
                trigger={
                  <Icon
                    name="exclamation triangle"
                    className="warn"
                    style={{ fontSize: '20px' }}
                  />
                }
                position="top center"
                content="Your attention is required for the rebalancing system."
              />
            )}
            {rebal && (
              <Popup
                trigger={
                  <Icon
                    name="random"
                    className="warn"
                    style={{ fontSize: '20px' }}
                  />
                }
                position="top center"
                content="A rebalancing system has been set up."
                wide
              />
            )}
          </div>
        </Feature>
        {selectable && (
          <Icon
            name={selected ? 'check square' : 'square outline'}
            className="checkbox"
            size="large"
          />
        )}
      </Icons>
    </CardHeader>
    <CardContent>{content}</CardContent>
    {children}
  </StyledCard>
);

FeatureCard.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string,
  warning: PropTypes.bool,
  rebal: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
};

FeatureCard.defaultProps = {
  warning: false,
  rebal: false,
  content: '',
  icon: '',
  iconColor: '',
  children: null,
  className: '',
  selected: false,
  selectable: false,
};

export default FeatureCard;

const StyledCard = styled(
  ({
    warning,
    remove,
    className,
    children,
    content,
    header,
    transparent,
    ...rest
  }) => (
    <NavLink className={className} {...rest}>
      {children}
    </NavLink>
  )
)`
  position: relative;
  display: inline-block;
  min-height: 10rem;
  max-height: 12rem;
  background-color: ${(props) =>
    props.transparent ? 'transparent' : colors[props.color]};
  width: 100%;
  max-width: ${(props) => props.width || '100%'};
  min-width: 20rem;
  padding: 1.5rem 1.2rem 1rem 2rem;
  color: inherit;
  border-bottom: 1px solid rgba(250, 250, 250, 0.5);
  vertical-align: top;

  &.active {
    background-color: ${(props) =>
      props.transparent ? 'rgba(255,255,255,0.2)' : hover[props.color]};

    &:after {
      background-color: ${(props) => colors[props.hover] || colors.red};
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    background-color: ${(props) =>
      props.transparent ? 'rgba(255,255,255,0.2)' : hover[props.color]};
    color: inherit;
    cursor: pointer;
    opacity: 1;

    &:after {
      background-color: ${(props) => colors[props.hover] || colors.red};
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: ${(props) =>
      props.transparent ? 'transparent' : darker[props.color]};
  }

  aside {
    position: absolute;
    bottom: 6px;
    right: 10px;
    color: ${colors.darkBlue};
    font-style: italic;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 0 8px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.5);
  }

  @media only screen and (max-width: 850px) {
    min-width: initial;
    max-width: initial;
  }
`;

const CardHeader = styled.div`
  width: 90%;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  word-wrap: break-word;

  img {
    height: 17px;
    width: 17px;
    vertical-align: middle;
    margin: 0 0 2px 13px;
    transform: rotate(43deg);
  }
`;

const CardContent = styled.div`
  font-size: 0.95rem;
  line-height: 1.3rem;
  opacity: 0.8;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
`;

const Icons = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0.5rem;

  .warn {
    color: ${colors.orange};
  }

  .checkbox {
    margin: -5px 0px 0px 10px;
  }
`;
