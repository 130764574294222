import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Icon, Popup, Header, Divider } from 'semantic-ui-react';
import { DateLabel, Score, Table } from 'components';
import {
  translateBenchmarkHeading,
  translateBenchmarkDescription,
} from '../../../../../utils/helpers';
import { translation } from '../localization';

@connect((state) => ({
  user_region: state.Storage.user_region || 'CA',
  french: state.Storage.language === 'fr',
}))
export default class Performance extends Component {
  getPercent = (num) => (!num ? '-' : `${(num * 100).toFixed(2)} %`);

  formatNum = (num) => (!num ? '-' : num.toFixed(2));

  parseDate = (portfolio, short) => {
    const { french } = this.props;
    const init = portfolio.init_date.split('-');
    const end = portfolio.end_date.split('-');
    const language = french ? 'fr' : 'en';

    return short
      ? `${translation[language].MONTH[[end[1]]]} ${end[0]}`
      : `${translation[language].MONTH[init[1]]} ${init[0]} to ${
          translation[language].MONTH[end[1]]
        } ${end[0]}`;
  };

  shouldDrawBar = () => {
    const { analytics } = this.props;
    const { data } = analytics;

    return (
      data &&
      data.crisisRets &&
      Object.values(data.crisisRets).reduce((result, event) => {
        result = Object.keys(event.rets).length ? result + 1 : result;

        return result;
      }, 0)
    );
  };

  renderCrisisTable = () => {
    const crisisAmount = this.shouldDrawBar();
    const { french, analytics } = this.props;

    if (crisisAmount) {
      const { benchmark, data, portfolioHistoryCompareto } = analytics;
      const dummy = crisisAmount === 1 ? 9 : crisisAmount === 2 ? 6 : 5;
      const language = french ? 'fr' : 'en';

      return (
        <div style={{ marginTop: '2.5rem' }}>
          <Header>
            <Header.Content>
              {translation[language].CRISIS_PERIOD_RETURNS}
            </Header.Content>
          </Header>
          <Divider />
          <Table basic textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3} />
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.HeaderCell>
                    {translation[language].FINANCIAL_CRISIS}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2007-12 to 2009-05)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.HeaderCell>
                    {translation[language].OIL_PRICE_SHOCK}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2014-06 to 2016-01)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.HeaderCell>
                    {translation[language].COVID_19_PANDEMIC}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2020-02 to 2021-06)
                    </span>
                  </Table.HeaderCell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.HeaderCell>
                    {translation[language].UKRAINE_WAR_ONSET}
                    <br />
                    <span style={{ color: 'rgba(0,0,0,.6)' }}>
                      (2022-03 to 2023-03)
                    </span>
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell width={dummy} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row positive>
                <Table.Cell width={3}>
                  {translation[language].MY_PORTFOLIO}
                </Table.Cell>
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Financial Crisis'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Oil Price Shock'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Covid-19 Pandemic'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Ukraine War'].rets.portfolio * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                <Table.Cell width={dummy} />
              </Table.Row>
              <Table.Row>
                <Table.Cell width={3}>
                  {translateBenchmarkHeading(
                    portfolioHistoryCompareto[0].toUpperCase() +
                      portfolioHistoryCompareto.slice(1),
                    french
                  )}
                </Table.Cell>
                {Object.keys(data.crisisRets['Financial Crisis'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Financial Crisis'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Oil Price Shock'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Oil Price Shock'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Covid-19 Pandemic'].rets)
                  .length !== 0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Covid-19 Pandemic'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                {Object.keys(data.crisisRets['Ukraine War'].rets).length !==
                  0 && (
                  <Table.Cell>
                    {(
                      data.crisisRets['Ukraine War'].rets[
                        portfolioHistoryCompareto
                      ][benchmark] * 100
                    ).toFixed(2)}
                    %
                  </Table.Cell>
                )}
                <Table.Cell width={dummy} />
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      );
    }

    return null;
  };

  render() {
    const { analytics } = this.props;
    const { benchmark, data } = analytics;
    const { french } = this.props;
    const language = french ? 'fr' : 'en';

    return (
      <Container>
        <Score
          name="Performance "
          score={`${data.score.Performance[benchmark][0]}%`}
          grade={data.score.Performance[benchmark][1]}
          description="Scored using your portfolio's annualized return (relative to the benchmark) and the three risk-adjusted ratios: the Sharpe, Roy's Safety First, and the Sortino (relative to those of the benchmark).<br />If your portfolio is just matching (rather than outperforming) the simple benchmark, it will get a pass, at 65%."
          portfolio={data.portfolio}
          pdf
        />
        <p>{translation[language].PERFORMANCE_DESCRIPTION_1}</p>
        {french ? (
          <p>
            Si vous investissez dans des titres qui comportent un certain niveau
            de risque (qu’il soit élevé ou faible), les ratios ajustés au risque
            sont des mesures plus appropriées de la performance, car les
            rendements ne sont pas garantis. Il va sans dire que des ratios
            élevés en particulier par rapport à l’indice de référence de votre
            portefeuille, sont préférables
          </p>
        ) : (
          <p>
            As long as you invest in securities that involve some level of risk
            - however high or low – the risk adjusted ratios are more
            appropriate measures of performance, because the returns are not
            guaranteed. Needless to say, the <b>higher</b> the ratios,
            especially relative to your portfolio's benchmark, the <b>better</b>
            .
          </p>
        )}

        <Header>
          <Header.Content>
            {translation[language].KEY_STATISTICS}
          </Header.Content>
          <Header.Subheader>
            <DateLabel
              str={`${this.parseDate(data.portfolio)} (${
                translation[language].INCLUSIVE
              })`}
            />
          </Header.Subheader>
        </Header>

        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3} />
              <Table.HeaderCell>
                {translation[language].ANNUALIZED_RETURN}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].SHARPE_RATIO}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content="This ratio measures the 'reward' of the portfolio per unit of 'risk', where reward is the average return of the portfolio over and above the risk-free rate, and risk is the standard deviation (or volatility) of the portfolio. The risk-free rate we use is the 12-month Treasury bill yield."
                  wide
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].ROY_SAFETY}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                >
                  Like the Sharpe Ratio, this ratio measures the
                  portfolio&#8217;s reward per unit of risk. However, the reward
                  is defined as the portfolio&#8217;s average return over your
                  target return (or minimum acceptable rate), instead of the
                  risk-free rate. Currently, the target return is set to zero,
                  i.e., money preservation (not losing money in nominal terms).
                </Popup>
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].SORTINO_RATIO}&nbsp;
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  wide
                >
                  This ratio measures the portfolio&#8217;s reward per unit of
                  downside risk. The reward is the portfolio&#8217;s average
                  return over your target return (or minimum acceptable rate).
                  Downside risk is the volatility of the portfolio returns below
                  your target. Currently, the target return is set to zero,
                  i.e., money preservation (not losing money in nominal terms).
                </Popup>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell width={3}>
                {translation[language].MY_PORTFOLIO}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.annualizedRet)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.sharpe)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.roys)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios.portfolio.sortino)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {translateBenchmarkHeading(
                  data.fratios[benchmark].name,
                  french
                )}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={translateBenchmarkDescription(
                    data.fratios[benchmark].description,
                    french
                  )}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].annualizedRet)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].sharpe)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].roys)}
              </Table.Cell>
              <Table.Cell>
                {this.formatNum(data.fratios[benchmark].sortino)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header>
          <Header.Content>
            {translation[language].ANNUALIZED_RETURNS}
          </Header.Content>
          <Header.Subheader>
            <DateLabel str={`${this.parseDate(data.portfolio, 'short')}`} />
          </Header.Subheader>
        </Header>

        <Divider />

        <Table basic textAlign="center">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3} />
              <Table.HeaderCell>{translation[language].YTD}</Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].ONE_YEAR}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].THREE_YEAR}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].FIVE_YEAR}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].SEVEN_YEAR}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translation[language].TEN_YEAR}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row positive>
              <Table.Cell width={3}>
                {translation[language].MY_PORTFOLIO}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.retYTD)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret1)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret3)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret5)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret7)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios.portfolio.ret10)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>
                {translateBenchmarkHeading(
                  data.fratios[benchmark].name,
                  french
                )}
                <Popup
                  trigger={
                    <Icon
                      name="question circle outline"
                      style={{
                        fontSize: '15px',
                        verticalAlign: 'initial',
                        color: 'dimgrey',
                      }}
                    />
                  }
                  position="top center"
                  content={translateBenchmarkDescription(
                    data.fratios[benchmark].description,
                    french
                  )}
                  wide
                />
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].retYTD)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret1)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret3)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret5)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret7)}
              </Table.Cell>
              <Table.Cell>
                {this.getPercent(data.fratios[benchmark].ret10)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {this.renderCrisisTable()}
      </Container>
    );
  }
}

Performance.propTypes = {
  analytics: PropTypes.object,
  french: PropTypes.bool,
};

Performance.defaultProps = {
  analytics: {},
  french: false,
};

const Container = styled.div`
  min-height: 100%;
  padding: 2rem 2rem 1rem 2rem;
`;
